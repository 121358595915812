import {
  NxButton,
  NxButtonLink,
  NxButtonVariant,
  NxLoader,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxStack,
  NxTable,
  NxTableActionProps,
  NxTableColumn
} from '@nextbank/ui-components';
import axios from 'axios';
import useAxios from 'axios-hooks';
import CommandAccess from 'command/CommandAccess';
import {useCommand} from 'command/CommandService';
import {Collateral} from 'customer/collateral/Collateral';
import {Dictionary} from 'dictionary/DictionaryType';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import NxLoadingButton from 'NxLoadingButton';
import React, {ReactElement, useMemo} from 'react';
import {useHistory, useParams} from 'react-router';
import {HttpError, PageResult} from 'tools/HttpTypes';

interface CollateralDetails extends Collateral {
  assignedLoans?: string[];
}

interface CollateralRow extends CollateralDetails {
  no: number;
  formattedAppraisalValue?: string;
  loanSecurity?: string;
  formattedAssignedLoans?: string;
}

const columns: NxTableColumn<CollateralDetails>[] = [{
  title: "No",
  field: "no"
}, {
  title: "Name",
  field: "name"
}, {
  title: "Type",
  field: "loanSecurity"
}, {
  title: "Appraisal date",
  field: "appraisalDate"
}, {
  title: "Appraisal value",
  field: "formattedAppraisalValue"
}, {
  title: "Assigned loans",
  field: "formattedAssignedLoans",
}];

const CollateralList = (): ReactElement => {
  const {customerId} = useParams<{customerId: string}>();
  const execute = useCommand();
  const history = useHistory();
  const [{data: dictionaries, loading}] = useAxios<Dictionary[], HttpError>('/dictionaries?code=LOAN_SECURITY');

  const loanSecurities = useMemo(() => {
    if(!dictionaries || dictionaries.length !== 1) {
      return undefined;
    }
    const loanSecurityMap : Record<number, string | undefined> = {};
    dictionaries[0].entries.forEach(e => {
      const englishEntry = e.values.find(v => v.languageCode === 'en');
      loanSecurityMap[e.id] = englishEntry?.value;
    });
    return loanSecurityMap;
  }, [dictionaries]);


  const DeleteCollateral = ({data: collateral, api}: NxTableActionProps<CollateralDetails>): ReactElement => {
    return <CommandAccess commandName="DeleteCollateral">
      <NxLoadingButton
        variant={NxButtonVariant.DELETE}
        onClick={async (): Promise<void> => {
          const response = await execute({
            name: 'DeleteCollateral',
            input: {
              id: collateral.id
            }
          });

          if(!response.approvalRequired) {
            api.onDataUpdated();
          }
        }}
      >
        Delete
      </NxLoadingButton>
    </CommandAccess>;
  };

  const UpdateCollateral = ({data: collateral}: NxTableActionProps<CollateralDetails>): ReactElement => {
    return <>
      <CommandAccess commandName="UpdateCollateral">
        <NxButtonLink to={`/customer/${customerId}/collaterals/${collateral.id}`}>Update</NxButtonLink>
    </CommandAccess>
    </>;
  };

  if(loading || !loanSecurities) {
    return <NxPage><NxHeader>Collaterals</NxHeader><NxLoader/></NxPage>
  }

  return <NxPage>
    <NxHeader>Collaterals</NxHeader>

    <NxStack>
      <NxRow position={NxRowPosition.END}>
        <NxButtonLink to={`/customer/${customerId}/collaterals/add`}>Add</NxButtonLink>
      </NxRow>
      <NxTable columns={columns}
               rowActions={[DeleteCollateral, UpdateCollateral]}
               data={async (query: NxQuery): Promise<NxQueryResult<CollateralDetails>> => {
        const {data} = await axios.get<PageResult<CollateralDetails>>(`/customers/${customerId}/collaterals/paged`, {
          params: {
            pageNo: query.page,
            pageSize: query.pageSize
          }
        });

        const result: CollateralRow[] = data.result.map((collateral, index) => {
          return {
            ...collateral,
            no: query.pageSize * data.pageNo + index + 1,
            formattedAppraisalValue: collateral.appraisalValue?.toLocaleString(),
            loanSecurity: loanSecurities[collateral.securityId],
            formattedAssignedLoans: collateral.assignedLoans?.join(', ')
          }
        });

        return {
          ...data,
          result: result
        };
      }}/>
      <NxRow position={NxRowPosition.END}>
        <NxButton variant={NxButtonVariant.PREVIOUS} onClick={() : void => history.goBack()}>
          Back
        </NxButton>
      </NxRow>
    </NxStack>
  </NxPage>
}

export default CollateralList;