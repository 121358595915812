export const confirmationMessage = ({
                                      amount,
                                      startingPeriod,
                                      endingPeriod,
                                      moneyFormatter
                                    }) => {
  const text = [{
    label: 'TOTAL',
    value: amount,
    formatter: moneyFormatter
  }, {
    label: 'APP PERIOD',
    value: `${startingPeriod} - ${endingPeriod}`,
    formatter: text => text
  }];

  return text
    .filter(item => item.value)
    .map(({label, value, formatter}) => `${label}: ${formatter(value)}`)
    .join('<br/>');
};