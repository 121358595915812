import axios from 'axios';
import useAxios, {UseAxiosResult} from 'axios-hooks';
import {CustomFieldCategoryValue} from 'custom-field/CustomFieldCategoryTreeView';
import {
  CustomFieldCategory,
  CustomFieldCategoryDetails,
  CustomFieldDefinition,
  CustomFieldDefinitionCriteria,
  CustomFieldGroup,
  CustomFieldRestriction,
  CustomFieldRestrictionSearchCriteria,
  CustomFieldValueCriteria
} from 'custom-field/CustomFieldDefinitionTypes';
import qs from 'qs';
import {NxTreeNode} from 'tree/NxTreeNode';

export const useDefinitions = (criteria: CustomFieldDefinitionCriteria): UseAxiosResult<CustomFieldDefinition[]> => {
  return useAxios<CustomFieldDefinition[]>({
    url: '/custom-field/definitions/search',
    method: 'POST',
    data: criteria
  });
};

export const useDefinitionRestrictions = (criteria: CustomFieldRestrictionSearchCriteria): UseAxiosResult<CustomFieldRestriction[]> => {
  return useAxios<CustomFieldRestriction[]>({
    url: '/custom-field/restrictions/search',
    method: 'POST',
    data: criteria
  });
}

export class CustomFieldService {

  public async readDefinitions(criteria: CustomFieldDefinitionCriteria): Promise<CustomFieldDefinition[]> {
    const {data} = await axios.post<CustomFieldDefinition[]>('/custom-field/definitions/search', criteria);
    return data;
  }

  public async readDefinition(id: number): Promise<CustomFieldDefinition> {
    const {data} = await axios.get<CustomFieldDefinition>(`/custom-field/definitions/${id}`);
    return data;
  }

  public async readCategories(definitionIds?: number[]): Promise<CustomFieldCategory[]> {
    const {data} = await axios.get<CustomFieldCategory[]>(`/custom-field/categories/values`, {
      params: {
        definitionIds: definitionIds ?? []
      },
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });

    return data;
  }

  public async readCategoryDetails(group: CustomFieldGroup, definitionId?: number, asForest?: boolean): Promise<CustomFieldCategoryDetails[]> {
    const {data} = await axios.get<CustomFieldCategoryDetails[]>(`/custom-field/categories/details`, {
      params: {
        definitionId: definitionId,
        group: group,
        asForest: !!asForest
      }
    });
    return data;
  }

  public async readValuesMap(criteria: CustomFieldValueCriteria): Promise<Record<number, string>> {
    const {data} = await axios.get<Record<number, string>>(`/custom-field/values/map`, {
      params: {
        ...criteria
      }
    });
    return data;
  }

  public async readRestrictions(criteria: CustomFieldRestrictionSearchCriteria): Promise<CustomFieldRestriction[]> {
    const {data} = await axios.post<CustomFieldRestriction[]>('/custom-field/restrictions/search', criteria);
    return data;
  }

  public mapCategoryToNode(category: CustomFieldCategory, definitions: CustomFieldDefinition[]): NxTreeNode<CustomFieldCategoryValue> {
    const customField = definitions.find(d => d.id === category.customFieldDefinitionId);
    return {
      value: {
        id: category.id,
        definitionId: category.definitionId,
        label: customField ? customField.name : category.value,
        code: category.code ?? '',
        remarks: category.remarks,
        customField: customField
      },
      children: category.children ? category.children.filter(c => c.enabled).map(child => this.mapCategoryToNode(child, definitions)) : []
    };
  }
}

export default new CustomFieldService();
