import nxModule from 'nxModule';

import templateUrl from './push-failed-sod.template.html';
import {Branch} from "management/BranchTypes";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import Popup from "shared/common/popup";
import {HttpService} from "shared/utils/httpService";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import RolesCache from "components/service/roles.cache";
import {BranchService} from "components/service/branch.service";

class PushFailedSodComponent {

  public branches: Branch[] = [];
  public branch?: Branch;

  constructor(
    private confirmationTemplate: ConfirmationTemplate,
    private popup: Popup,
    private http: HttpService,
    private $filter: NxIFilterService,
    private $route: NxRouteService,
    private rolesCache: RolesCache,
    private branchService: BranchService) {
  }

  async $onInit(): Promise<void> {
    this.branches = await this.branchService.toPromise();
  }

  async execute(): Promise<void> {
    if(!this.branch) {
      throw new Error('Missing branch');
    }

    const confirmed = await this.confirmationTemplate({
      question: `Do you want to push failed start of day for branch ${this.branch.name}?`
    });

    if (confirmed) {
      await this.http.post(`/batch-jobs/push-job/${this.branch.id}/START_DAY`).toPromise();
      await this.popup({text: `Pushed branch sod`});
      this.$route.reload();
    }
  }
}

nxModule.component('pushFailedSod', {
  templateUrl,
  controller: PushFailedSodComponent
});
