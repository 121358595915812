import nxModule from 'nxModule';
import _ from 'lodash';

import {addAccountLabels} from 'components/general-ledger/common/gl.utils';
import moment from "moment";

const templateUrl = require('./depository-account-details.template.html');

nxModule.component('depositoryAccountDetails', {
  templateUrl,
  controller: function ($route, $location, $filter, dict, glMappingsService, command, depositoryAccountCache, branchService, authentication) {

    const that = this;
    const accountId = $route.current.params['accountId'];

    that.account = {id: accountId};
    that.banks = undefined;
    that.ledgerAccounts = undefined;

    const accountTypesEnum = ['SAVINGS', 'CHECKING', 'CLEARING', 'TERM_DEPOSIT', 'POS'];

    const miscTransactionCommands = [
      'MiscCashToBank',
      'MiscInterbranchCashToBank',
      'MiscCashFromBank',
      'MiscInterbranchCashFromBank',
      'MiscBatchCheckDepositToBank',
      'MiscInterbranchBatchCheckDepositToBank'
    ];

    that.allowedTransactionOptions = miscTransactionCommands.map(command => ({
      'label': $filter('translateEnum')(command, 'COMMAND'),
      'value': command
    }));

    // For new depository account, we default to all
    if (!that.account.id) {
      that.account.allowedMiscCommands = miscTransactionCommands;
    }

    that.accountTypes = _.map(accountTypesEnum, (e) => {
      return {id: e, description: $filter('prettyEnum')(e)};
    });

    that.bankSelectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    that.accountTypeSelectConfig = {
      placeholder: 'Select account type',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    that.ledgerAccountSelectConfig = {
      placeholder: 'Select ledger account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    that.categoryOptions = [];

    const s1 = glMappingsService.accounts.toObservable().subscribe(glAccounts => {
      that.ledgerAccounts = addAccountLabels(
        _.filter(glAccounts, function (glAccount) {
          return ['ASSET'].includes(glAccount.accountGroup);
        })
      );
    });

    const s2 = depositoryAccountCache.toObservable().subscribe((accounts) => {
      if (accountId && accounts) {
        that.account = _.find(accounts, a => Number(a.id) === Number(accountId));
        that.account.bankId = String(that.account.bankId);
        if(!that.account.allowedMiscCommands) that.account.allowedMiscCommands = [];
      }
    });

    const s3 = branchService.toObservable().subscribe( (branches) => {
      that.branches = branches;
      if(!that.account.id) {
        that.account.branchId = authentication.context.branchId;
      }
    });

    that.redirectBack = () => $location.path('/admin/organization/depository-accounts');

    that.createOrUpdate = () => {
      const commandName = that.account.id ? 'UpdateDepositoryAccount' : 'CreateDepositoryAccount';
      command.execute(commandName, that.account).success(() => {
        depositoryAccountCache.refetch();
        that.redirectBack();
      })
    };

    that.calculateMaturityDate = () => {
      that.account.maturityDate =  moment(that.account.dateOpened).add(that.account.term, 'days').toDate();
    };

    that.onAccountTypeChange = () => {
      if(that.account.accountType !== 'CHECKING') {
        that.account.allowCheckManagement = false;
      }
    };

    // On init set form as submitted to highlight invalid fields
    that.$onInit = async () => {
      if (that.form) that.form.$setSubmitted();
      that.calculateMaturityDate();

      await dict.onLoadingCompleteAsync();
      that.banks = dict['BANK'];
      const allCategories = _.cloneDeep(dict['DAC_CATEGORY']);

      [that.categoryOptions, that.flattenedCategoryOptions] = that.dictionaryToHierarchy(allCategories);
    };

    that.dictionaryToHierarchy = values => {
      const idMap = new Map();
      for(let value of values) {
        idMap.set(value.id, value);
      }

      const roots = [];
      for(let id of [...idMap.keys()].sort()) {
        const value = idMap.get(id);
        const parentId = value.attributes && value.attributes.parentDictEntryId && value.attributes.parentDictEntryId[0];
        if(!parentId) {
          roots.push(value);
        } else {
          const parent = idMap.get(parentId);
          if(!parent.children) {
            parent.children = [value];
          } else {
            parent.children.push(value);
          }
        }
      }

      return [roots, [...idMap.values()]];
    };

    that.categoryLabel = node => node.description;

    that.isTermDepositAccount = () => {
      return ['TERM_DEPOSIT'].includes(that.account.accountType);
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
      s2.unsubscribe();
      s3.unsubscribe();
    };
  }
});
