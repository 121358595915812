import nxModule from 'nxModule';
import $ from 'jquery';

const templateUrl = require('./cash-amount-picker.template.html');
nxModule.component('cashAmountPicker', {
  templateUrl: templateUrl,
  bindings: {
    'onSelect': '&',
    'values': '<',
    'inactiveButtonClass': '<',
    'selectedButtonClass': '<'
  },
  controller: function ($scope, $timeout) {
    var that = this;

    if (!this.inactiveButtonClass) this.inactiveButtonClass = 'btn-primary';
    if (!this.selectedButtonClass) this.selectedButtonClass = 'btn-selected';

//    $scope.isDeposit = null;

    $scope.selectedAmount = null;
    $scope.otherValue = '';

    $scope.isSelected = function (v) {
      return v == $scope.selectedAmount;
    };

    $scope.selectAmount = function (v) {
      $scope.selectedAmount = v;
      that.onSelect({value: v});
    };

    $scope.onChangeOtherField = function () {
      that.onSelect({value: $scope.otherValue});
    };

    $scope.clickOther = function () {
      $scope.selectedAmount = 'other';
      that.onSelect({value: $scope.otherValue});
      $timeout(function () {
        $('.other-input input').focus();
      });
    };

  }
});