import React, {ReactElement} from "react";
import currentBranchService from "management/currentBranchService";
import NxForm from "form/NxForm";
import {NxFormik, NxFormikSubmitButton, NxFormikDatePicker, NxRow} from "@nextbank/ui-components";
import * as Yup from "yup";
import NxFormikBranchSelect from 'management/NxFormikBranchSelect';


const CheckPreparationHistoryInputSchema = Yup.object().shape({
  branchId: Yup.mixed()
    .required(),
  dateFrom: Yup.date()
    .required('Date From is required')
    .typeError('Invalid date format')
    .max(Yup.ref('dateTo'), 'Must not be greater than Date To'),
  dateTo: Yup.date()
    .required('Date To is required')
    .typeError('Invalid date format')
    .min(Yup.ref('dateFrom'), 'Must not be lesser then Date From')
});

interface Props {
  availableReports: string[];
  defaultBranchSystemDate: string;
  onSubmit: (value: OnSubmitInput) => Promise<unknown>;
}

export interface OnSubmitInput {
  branchId: number;
  dateFrom: string;
  dateTo: string;
}

const CheckPreparationHistoryTabFilter = (props: Props): React.ReactElement => {
  return <NxFormik<OnSubmitInput>
    initialValues={{
      branchId: currentBranchService.getBranchId(),
      dateFrom: props.defaultBranchSystemDate,
      dateTo: props.defaultBranchSystemDate
    }}
    onSubmit={props.onSubmit}
    validationSchema={CheckPreparationHistoryInputSchema}>
    {(): ReactElement => (
      <NxForm>
        <NxRow>
          <NxFormikBranchSelect
            name="branchId"
            label="Branch"
          />
          <NxFormikDatePicker
            name="dateFrom"
            label="Date From"
          />
          <NxFormikDatePicker
            name="dateTo"
            label="Date To"
          />
          <NxFormikSubmitButton>
            Generate
          </NxFormikSubmitButton>
        </NxRow>
      </NxForm>
    )}
  </NxFormik>
}

export default CheckPreparationHistoryTabFilter;