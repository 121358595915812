import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';
import systemPropertyService from '../../../../react/system/systemPropertyService';
import {incomeSourceTypes} from './constants';

import './income.style.less';
import templateUrl from './income.template.html';

class CustomerProfileIncome {

  constructor(dict, propertyConfigService, $scope) {
    this.dict = dict;
    this.cfg = propertyConfigService;
    $scope.$watch('$ctrl.profile.incomeSources',  () => {
      this.updateTotalIncome();
    }, true);
  }

  // use getter so that ng-model binding is possible and we won't need to update customer profile
  get totalMonthlyIncome() {
    const annual = this.profile.customerType === 'INDIVIDUAL' ?
      this.profile?.individualData?.totalAnnualIncome :
      this.profile?.corporateData?.totalAnnualIncome;

    if(annual === undefined || annual === null) {
      return annual;
    }

    return new BigNumber(annual).div(12).toNumber();
  }

  async $onInit() {
    this.types = this.module === 'agent' ? incomeSourceTypes.filter(i => ['BUSINESS', 'OTHER_BANK_ACCOUNTS'].includes(i.code)) : incomeSourceTypes;
    const minSections = systemPropertyService.getProperty('CIF_MIN_INCOME_SOURCE_SECTIONS');
    this.minSections = minSections ? Number(minSections) : 0;
  }

  updateTotalIncome(){
    if(!this.profile.incomeSources) {
      return;
    }

    const annualIncome = sum(this.profile.incomeSources.map(income => income.annualIncome)).toNumber();
    if(this.profile.customerType === 'INDIVIDUAL') {
      this.profile.individualData.totalAnnualIncome = annualIncome;
    } else {
      this.profile.corporateData.totalAnnualIncome = annualIncome;
    }
  }

  addIncomeSource(type) {
    // mark the first income source as primary
    const primary = this.profile.incomeSources.length === 0;
    this.profile.incomeSources.push({
      type: type,
      primary: primary
    });
  }

  removeIncomeSource(source) {
    const deletedPrimary = source.primary;
    const index = this.profile.incomeSources.indexOf(source);
    if (index > -1) {
      this.profile.incomeSources.splice(index, 1);
    }
    if (deletedPrimary && this.profile.incomeSources.length !== 0) {
      this.profile.incomeSources[0].primary = true;
    }
  }
}

nxModule.component('customerProfileIncome', {
  templateUrl,
  controller: CustomerProfileIncome,
  bindings: {
    profile: '=',
    editMode: '<',
    module: '<'
  }
});
