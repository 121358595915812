import {NxButton, NxButtonProps} from "@nextbank/ui-components";
import {ReactElement, useState} from "react";


type NxLoadingButtonProps = Omit<NxButtonProps, 'loaded' | 'onClick'> & {
  onClick: () => Promise<unknown>
}

/**
 * Button that automatically displays loader when onClick handler is running
 */
const NxLoadingButton = (props: NxLoadingButtonProps): ReactElement => {
  const [loaded, setLoaded] = useState(true);
  return <NxButton disabled={!loaded} {...props} onClick={async (): Promise<void> => {
    if(!props.onClick) {
      return;
    }

    setLoaded(false);
    try {
      await props.onClick();
    } finally {
      setLoaded(true);
    }
  }} loaded={loaded}>
    {props.children}
  </NxButton>;
};

export default NxLoadingButton;