import nxModule from 'nxModule';
import _ from 'lodash';
import $ from 'jquery';

const templateUrl = require('./customer-picker.template.html');
nxModule.component('customerPicker', {
  templateUrl,
  bindings: {
    'onChoose': '&',
    'searchCriteria': '<'
  },
  controller: function ($scope, $element, http, branchService) {
    const that = this;

    const showPopup = event => {
      const isCurrentElChild = $element.find(event.target).length > 0;

      if(!isCurrentElChild) {
        $scope.$apply(function () {
          that.visible = false;
        });
      }
    };

    $(document).click(showPopup);

    that.visible = false;
    that.searchResults = [];

    branchService.toObservable().subscribe(branches => that.branches = branches);

    that.search = () => {
      if (that.query) {
        const criteria = {
          effectiveName: that.query,
          customerStatus: 'ACTIVE',
          pageSize: 50,
          ...that.searchCriteria
        };
        http.post(`/customers/full-text-search`, criteria, {nxLoaderText: 'Searching for customer...'})
          .success((data) => {
            that.visible = true;
            that.query = null;
            _.forEach(data.result, (r) => Object.assign(r, {branch: _.find(that.branches, {id: r.branchId})}));
            that.searchResults = data.result;
          });
      }
    };

    that.choose = async (fullSearchResult, $event) => {
      $event.stopPropagation();

      const customer = await http.get(`/customers/${fullSearchResult.id}?includeCoMadeLoans=true`).toPromise();

      that.onChoose({customer: customer});
      that.visible = false;
      that.searchResults = [];
    };

    that.$onDestroy = () => {
      $(document).off('click', showPopup);
    }
  }
});
