import nxModule from 'nxModule';
import AuthenticationService from '../../../react/authentication/AuthenticationService';
import tokenStore from '../../../react/authentication/tokenStore';
import currentBranchService from '../../../react/management/currentBranchService';
import {ILocationService} from "angular";

const authService = new AuthenticationService();

export default class Authentication {

  get permissions() {
    return tokenStore.getTokenDetails().permissions;
  }

  get context() {
    const tokenDetails = tokenStore.getTokenDetails();
    return {
      roleIds: tokenDetails.roleIds,
      branchIds: tokenDetails.branchIds,
      id: tokenDetails.userId,
      userBranchId: tokenDetails.userBranchId,
      branchId: currentBranchService.getBranchId(),
      permissions: tokenDetails.permissions
    };
  }

  constructor(private $location: ILocationService) {
  }

  logoutAndKeepPath(error: string) {
    authService.logoutKeepPath(error);
  }

  logout(...target: Parameters<typeof authService.logout>) {
    authService.logout(...target);
  }

  refresh(successCallback = null, errorCallback = null) {
    authService.refresh()
      .then(successCallback, errorCallback);
  }

  override(branchId: number, successCallback: null | (() => unknown) = null) {
    currentBranchService.setBranchId(branchId);

    if (successCallback) {
      successCallback();
    }
  }

  isSessionExpired() {
    return tokenStore.isSessionExpired();
  }
}

nxModule.service('authentication', Authentication);
