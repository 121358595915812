import nxModule from 'nxModule';
import ParameterizedLocalCache from '../../../../shared/utils/parameterized-local-cache';

nxModule.factory('creditLineCustomersCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (creditLineIds) => http.get(`/credit-lines/customers?creditLineIds=${creditLineIds}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.creditLineCustomers,
    cacheName: 'creditLineCustomers'
  })
);