import nxModule from 'nxModule';

import {ApprovalHierarchy} from '../../../common/approval-hierarchy/approval-hierarchy.types';
import {HttpService} from "shared/utils/httpService";

export class AccessRuleApprovalHierarchyService {
  constructor(private readonly http: HttpService) { }

  getApprovalHierarchy(ruleId: number): Promise<ApprovalHierarchy[]> {
    return this.http.get<ApprovalHierarchy[]>(`/access/rules/${ruleId}/approval-hierarchy`).toPromise();
  }
}

nxModule.service('accessRuleApprovalHierarchyService', AccessRuleApprovalHierarchyService);