import nxModule from 'nxModule';
import StatusBasedService from "./statusBasedService";
import {StatusService} from "./status.service";

export interface AssetCounter {
  id: number;
  branchId: number;
  totalCash: number;
  totalCheck: number;
  userId?: number;
  domainId: number;
  subtypeId: number;
  type: string;
}

export interface AssetCounterSummary {
  totalCash: number;
  totalCheck: number;
  totalAtmCash: number;
}

nxModule.factory('userCounterService', (statusService: StatusService) =>
  new StatusBasedService({
    property: 'userCounter',
    statusService: statusService
  }));

type UserCounterService = StatusBasedService<AssetCounterSummary>;
export default UserCounterService;