import {ILocationService} from 'angular';
import DepositAccountTypeService from 'components/administration/deposit-account/common/deposit-account-type-service';
import {BranchQueryService} from 'components/service/branch-query.service';
import {BranchService} from 'components/service/branch.service';
import {NxIFilterService} from 'components/technical/angular-filters';
import {Branch} from 'management/BranchTypes';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import Authentication from 'shared/utils/authentication';
import {CommandService} from 'shared/utils/command/command.types';
import ReportService, {ReportRequest} from '../../../report/report.service';
import templateUrl from './deposit-account-reservation.template.html';

interface DepositAccountType {
  id: number;
  productName: string;
  productSubtype: string;
  productDefinition: {
    id: number;
    productAvailability: 'AVAILABLE_IN_ALL_BRANCHES' | 'AVAILABLE_IN_SELECTED_BRANCHES' | 'NOT_AVAILABLE',
    availableInBranchIds : number[];
  }
}

interface ProductReservations {
  requestUuid: string;
  productNumbers: [string];
}

interface DepositAccountReservationInput {
  branchIds: number[];
  productDefinitionId: number;
  size: number;
}

class DepositAccountReservation {
  currentBranch: Branch | undefined;
  branches : Branch[] = [];
  depositAccountTypes: DepositAccountType[] = [];
  selectedProductType?: DepositAccountType;
  input: Partial<DepositAccountReservationInput> = {};
  productReservations: ProductReservations | null = null;
  reportContent: unknown;

  constructor(
    private readonly branchService: BranchService,
    private readonly branchQueryService: BranchQueryService,
    private readonly authentication: Authentication,
    private readonly confirmation: Confirmation,
    private readonly command: CommandService,
    private readonly reportService: ReportService,
    private readonly depositAccountTypeService: DepositAccountTypeService,
    private readonly $filter: NxIFilterService,
    private readonly $location: ILocationService
  ) {
    this.initialize();
  }

  async $onInit() : Promise<void> {
    const allBranches = await this.branchService.toPromise();
    this.branches = allBranches.filter((b: Branch) => this.authentication.context.branchIds.includes(b.id));
    this.currentBranch = this.branches.find(b => b.id === this.authentication.context.branchId);
    const accounts = await this.depositAccountTypeService.toPromise();
    this.depositAccountTypes = accounts.map(account => ({
      ...account,
      productName: account.productDefinition.productName,
      productSubtype:  this.$filter('prettyEnum')(account.accountSubtype),
    }));
  }

  initialize() : void {
    this.input = {};
    this.input.branchIds = [this.authentication.context.branchId];
    this.input.size = 100;
    this.selectedProductType = undefined;
    this.productReservations = null;
    this.reportContent = null;
  }

  async submit() : Promise<void> {
    const confirmed = await this.confirmation(
      `${this.input.size} deposit account numbers will be reserved for ${this.selectedProductType?.productName} product type. Proceed?`
    );
    if (confirmed) {
      this.input.productDefinitionId = this.selectedProductType?.productDefinition.id;
      const response = await this.command.execute<unknown, ProductReservations>("AccountProductNumberReservation", this.input).toPromise();

      if (response && !response.approvalRequired) {
        this.productReservations = response.output;
        await this.loadReportContent();
      }
    }
  }

  async loadReportContent() : Promise<void> {
    this.reportContent = await this.reportService.downloadJson(this.generateReportRequest());
  }

  print() : void {
    window.print();
  }

  async downloadXls() : Promise<void> {
    await this.reportService.downloadXls(this.generateReportRequest());
  }

  generateReportRequest() : ReportRequest {
    const reportParams = {
      'branchId': this.input.branchIds,
      'productDefinitionId': [this.input.productDefinitionId],
      'requestUuid' : this.productReservations?.requestUuid,
      'date_range[from]': null,
      'date_range[to]': null,
    };
    return {
      reportCode: 'ProductNumberReservationReport',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params: reportParams
    };
  }

  allowedAccounts(): DepositAccountType[] {
    if (!this.depositAccountTypes) return [];
    return this.depositAccountTypes
      .filter(a => ['Checking', 'Combo'].includes(a.productSubtype))
      .filter(a => {
        switch(a.productDefinition.productAvailability) {
          case 'AVAILABLE_IN_ALL_BRANCHES': return true;
          case 'NOT_AVAILABLE': return false;
          case 'AVAILABLE_IN_SELECTED_BRANCHES': {
            const productBranchIds: number[] = a.productDefinition.availableInBranchIds;
            // Check if any of selected branches is present on product's available branches
            const difference = this.input.branchIds?.filter(id => productBranchIds.indexOf(id) < 0);
            return difference?.length === 0;
          }
        }
      });
  }

  goBack(): void {
    const navigation = (): void => {
      this.$location.path('/admin/deposit-accounts');
    };

    this.confirmation('Do you want to cancel? Canceling will discard all changes.', navigation);
  }
}

nxModule.component('depositAccountReservation', {
  templateUrl,
  controller: DepositAccountReservation
});
