import nxModule from "nxModule";
import _ from "lodash";
import moment from "moment";

import templateUrl from "./custom-report-filter.template.html";
import "../../common/filters/report-filter.style.less";

export interface CustomReportFilterDetail {
  name: string; 
  code: string | Date | moment.Moment;
}

export interface CustomReportFilterConfig {
  button: {
    text: string, 
    isDisabled?: boolean, 
    click?: Function, 
  }, 
  dateFilter: {
    change?: Function
  }
}

class CustomReportFilter {} 

nxModule.component('customReportFilter', {
  templateUrl,
  controller: CustomReportFilter, 
  bindings: {
    config: "<", 
    filter: "<"
  }
});
