import nxModule from 'nxModule';
import moment from 'moment';

import './product-owners.style.less'

const templateUrl = require('./product-owners.template.html');

nxModule.component('productOwners', {
  templateUrl: templateUrl,
  bindings: {
    'ownership': '=',
    'editMode': '<'
  },
  controller: function ($scope, $route, notification, branchService) {

    const that = this;

    that.$onInit = async () => {
      const branches = await branchService.toPromise();
      that.addOwner = (customer, type) => {

        if(!that.ownership.find(o => o.customerId === customer.id)) {
          that.ownership.push({
            birthDate: customer.individualData?.birthDate ? moment(customer.individualData.birthDate).format('YYYY-MM-DD') : null,
            branchName: branches.find(b => b.id === customer.branchId).name,
            customerId: customer.id,
            customerNumber: customer.customerNumber,
            effectiveName: customer.effectiveName,
            ownershipType: type,
            productAccess: true
          });
        } else {
          notification.show("Error", "Customer already added!");
        };
      };

      that.onRemoveOwner = (idx) => {
        that.ownership.splice(idx, 1);
      };

    }
  }
});
