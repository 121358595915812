import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './close-account.template.html';
import CheckMicrService from '../../../service/check-micr.service';
import {IHttpService, ILocationService} from 'angular';
import {AccountInterestForecast} from '../../../service/account.types';
import {Account, Fee} from '../../../service/product.types';
import LocalCache from '../../../../shared/utils/localCache';
import {ProductDefinitionService} from '../../../service/product-definition.service';
import {SelectConfig} from '../../../common/types/selectize.types';
import AccountForecast from './account-forecast.service';
import Authentication from "shared/utils/authentication";
import DepositoryAccount from "check/DepositoryAccountTypes";
import {AccountOperationService} from "components/customer/accounts/common-operations/account-operation-service.types";
import {CustomerCache} from 'components/service/customer.cache.types';
import Notification from "shared/utils/notification";

enum CloseMode {
  CLOSE_WITH_CASH = 'CloseWithCash',
  CLOSE_WITH_CHECK = 'CloseWithCheck',
  CLOSE_WITH_CHECK_ENCASH = 'CloseWithCheckEncash',
  CLOSE_ZERO_BALANCE = 'CloseZeroBalance'
}

interface CloseCommandInput {
  customerId: number;
  productId: number;
  amount: number;
  entryType: 'CREDIT' | 'DEBIT';
  terminationFeesOverride: Fee[];
  validFrom: Date;
  depositoryAccountId: number;
  micrNumber?: string;
  checkNumber?: string;
  payee: string;
  remarks: string;
}

interface OverriddenFee extends Fee {
  override: boolean
}

class CloseAccountController {
  private mode!: CloseMode;
  private command: Partial<CloseCommandInput> = {};
  private depositAccount!: Account;
  private interestForecast?: AccountInterestForecast;
  private originalFeeAmount: Map<Fee, number> = new Map();
  private checkingAccounts: Array<DepositoryAccount> = [];
  private selectConfig: SelectConfig;
  readonly customerId!: number;
  readonly productId!: number;

  constructor(private $location: ILocationService,
              private http: IHttpService,
              private customerCache: CustomerCache,
              private depositoryAccountCache: LocalCache<DepositoryAccount[], DepositoryAccount[]>,
              private productDefinitionService: ProductDefinitionService,
              private notification: Notification,
              private accountOperationService: AccountOperationService,
              private accountForecast: AccountForecast,
              private checkMicrService: CheckMicrService,
              private authentication: Authentication) {
    this.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };
  }

  readInterestForecast(): void {
    this.accountForecast.getTerminationForecast(this.depositAccount.id, this.mode === CloseMode.CLOSE_ZERO_BALANCE)
      .then((forecast: AccountInterestForecast) => {
        this.interestForecast = forecast;
        this.originalFeeAmount = new Map();
        forecast.terminationFees.forEach((fee: Fee) => this.originalFeeAmount.set(fee, fee.amount));
        this.command.amount = this.accountForecast.calculateTerminationAmount(this.depositAccount, forecast);
        this.command.terminationFeesOverride = this.interestForecast.terminationFees;
      })
      .catch((ignored: unknown) => {
        this.notification.show('Error', 'Failed to load interest forecast.');
      });
  }

  terminationFeeChanged(): void {
    this.command.amount = this.accountForecast.calculateTerminationAmount(this.depositAccount, this.interestForecast);
  }

  changeFeeOverride(changedFee: OverriddenFee): void {
    if (!changedFee.override) {
      changedFee.amount = this.originalFeeAmount.get(changedFee) || 0;
    }
    this.terminationFeeChanged();
  }

  close(): void {
    switch (this.mode) {
      case CloseMode.CLOSE_WITH_CASH:
        this.accountOperationService.closeWithCash(this.command, () => this.redirectBack());
        break;
      case CloseMode.CLOSE_WITH_CHECK:
        this.accountOperationService.closeWithCheck(this.command, () => this.redirectBack());
        break;
      case CloseMode.CLOSE_WITH_CHECK_ENCASH:
        this.accountOperationService.closeWithCheckEncash(this.command, () => this.redirectBack());
        break;
      case CloseMode.CLOSE_ZERO_BALANCE:
        this.accountOperationService.closeZeroBalance(this.command, this.depositAccount.accruedInterest, () => this.redirectBack());
        break;
    }
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.productId}`);
  }

  onMicrNumberChange(micrNumber: string): void {
    if (micrNumber && micrNumber.length === 31) {
      this.command.checkNumber = micrNumber.substr(0, 10);
    } else {
      this.command.checkNumber = undefined;
    }
  }

  updateMicr(): void {
    this.command.micrNumber = this.checkMicrService.createMicr(
      this.command.checkNumber,
      Number(this.command.depositoryAccountId),
      this.checkingAccounts
    )
  }

  async $onInit(): Promise<void> {
    this.command.productId = this.productId;
    this.command.customerId = this.customerId;

    const [customer, depositAccounts, products, depositoryAccounts] = await Promise.all([
        this.customerCache.profile(this.customerId).toPromise(),
        this.customerCache.depositAccounts(this.customerId).toPromise(),
        this.productDefinitionService.toPromise(),
        this.depositoryAccountCache.toPromise()
      ]
    );
    this.depositAccount = depositAccounts.find((account: Account) => account.id === this.productId)!;
    this.checkingAccounts = depositoryAccounts.filter((a: DepositoryAccount) => a.accountType === 'CHECKING' && a.brstn && a.branchId === this.authentication.context.branchId);
    this.command.remarks = 'Closing of account no. ' + this.depositAccount.productNumber;
    this.command.payee = customer.effectiveName;

    const accountDefinition = _.find(products, {id: this.depositAccount.definitionId});
    Object.assign(this.depositAccount, {
      productName: accountDefinition ? accountDefinition.productName : '<Unknown product>'
    });

    this.readInterestForecast();
  }
}

nxModule.component('closeAccount', {
  templateUrl,
  controller: CloseAccountController,
  bindings: {
    mode: '@',
    productId: '<',
    customerId: '<',
    pageHeader: '@'
  }
});
