import clsx from 'clsx';
import React from 'react';
import {DraggableProvided} from 'react-beautiful-dnd';
import DragDropIcon from './icon-drag-drop.svg';
import styles from './DragDropItem.scss';

interface Props {
  className?: string;
  // Content of the draggable row. Will be displayed in the same row as the dragging icon.
  item: React.ReactNode;
  // Can be used to render additional components other than the item, not aligned with the dragging icon.
  children?: React.ReactNode;
  provided: DraggableProvided;
  isDragging: boolean;
}

export default function DragDropItem({className, children, item, isDragging, provided}: Props): React.ReactElement {
  const itemClassName = clsx(
    className,
    styles.item,
    {
      [styles.isDragging]: isDragging
    }
  );

  return (
    <div className={itemClassName} ref={provided.innerRef} {...provided.draggableProps}>
      <div className={styles.row}>
        <div className={styles.handle} {...provided.dragHandleProps}>
          <DragDropIcon />
        </div>
        {item}
      </div>
      {children}
    </div>
  );
}
