import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import {AccountWithLabel, addAccountLabels, filterNonContingentAccounts} from '../../../general-ledger/common/gl.utils';
import {sum} from "../../../../shared/common/MathUtils";

import templateUrl from './create-cashiers-check.template.html';
import './create-cashiers-check.style.less';
import {BreadcrumbsService} from "../../../common/breadcrumbs/breadcrumbs.service";
import {setBreadcrumbs} from "../../../../shared/utils/set-breadcrumbs";
import {Branch} from "management/BranchTypes";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {UserCache} from "components/service/users/user.cache";
import GlMappingsService from "components/administration/gl-mappings/gl-mappings.service";
import {Breadcrumbs} from "angular-breadcrumbs";
import {MiscCommandService} from "components/dashboard/miscellaneous-transactions/misc-command.service";
import {BranchService} from "components/service/branch.service";
import {NxIFilterService} from "components/technical/angular-filters";
import Authentication from "shared/utils/authentication";
import {ActionCategory} from "components/administration/transactions/action-category.types";

interface BranchUnit {
  targetBranchId: number,
  amount: number,
  fullCode: string
}

interface CategoryUnit {
  amount: number,
  fullCode: string
}

interface Transaction {
  number: string,
  taxUnits: Partial<{
    categoryId: number,
    units: Partial<CategoryUnit>[]
  }>,
  branchUnits: Partial<BranchUnit>[]
}

class CreateCashiersCheck {
  private header!: string;
  private interbranch!: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private currentBranch: Branch;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private otherBranches: Branch[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private targetBranches: Branch[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private allBranches: Branch[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private taxCategory: ActionCategory;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private category: ActionCategory;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private glAccounts: AccountWithLabel[];
  private cashiersCheckAmount: number = 0;
  private transactionAmount: number = 0;

  private readonly branchSelectConfig = {
    labelField: ['name'],
    valueField: 'id',
    sortField: 'name',
    searchField: ['name']
  };

  private transaction: Partial<Transaction> = {
    taxUnits: {
      units: []
    },
    branchUnits: []
  };

  constructor(private $filter: NxIFilterService, private authentication: Authentication, private branchService: BranchService,
              private userCache: UserCache, private actionCommand: ActionCommand,
              private glMappingsService: GlMappingsService, private miscCommandService: MiscCommandService, private breadcrumbsService: BreadcrumbsService,
              private breadcrumbs: Breadcrumbs) {
  }

  async $onInit(): Promise<void> {
    this.header = this.interbranch ? this.$filter('translateEnum')('CREATE_INTERBRANCH_CASHIER_CHECK', 'LABEL') : this.$filter('translateEnum')('CREATE_CASHIER_CHECK', 'LABEL');
    const [glAccounts, branches] = await Promise.all([
      this.glMappingsService.accounts.toPromise(),
      this.branchService.toPromise()
    ]);

    setBreadcrumbs(this.breadcrumbs, 'create-cashiers-check-label', this.header);

    const [otherIdBranches, sameIdBranches] = _.partition(branches, b => Number(b.id) !== Number(this.authentication.context.branchId));
    this.allBranches = branches;
    this.otherBranches = otherIdBranches;
    this.currentBranch = sameIdBranches[0];
    this.glAccounts = addAccountLabels(filterNonContingentAccounts(glAccounts));
  }

  calculateTransactionAmount(): number {
    return sum((this.transaction.branchUnits ?? []).map(unit => unit.amount)).toNumber();
  }

  calculateCashiersCheckAmount(): number {
    const tax = sum((this.transaction.taxUnits?.units ?? []).map(unit => unit.amount));
    return new BigNumber(this.calculateTransactionAmount()).minus(new BigNumber(tax)).toNumber();
  }

  updateAmount(): void {
    this.cashiersCheckAmount = this.calculateCashiersCheckAmount();
    this.transactionAmount = this.calculateTransactionAmount();
  }

  updateCategoryUnits(): void {
    const units = [];
    const fullCodes = this.category ? this.category.ledgerAccountFullCodes : [];
    if (this.interbranch) {
      for (const branchId of (this.targetBranches || [])) {
        for (const fullCode of fullCodes) {
          units.push({
            amount: 0,
            targetBranchId: Number(branchId),
            fullCode: fullCode
          });
        }
      }
    } else {
      for (const fullCode of fullCodes) {
        units.push({
          amount: 0,
          fullCode: fullCode
        });
      }
    }

    this.transaction.branchUnits = units;
    this.updateAmount();
  }

  updateTaxCategoryUnits(): void {
    this.transaction.taxUnits = {};
    if(this.taxCategory) {
      this.transaction.taxUnits = {
        categoryId: this.taxCategory.id,
        units: (this.taxCategory.ledgerAccountFullCodes || []).map(code => ({
          fullCode: code,
          amount: 0
        }))
      };
      this.updateAmount();
    }
  }

  getBranchName(id: number): string | null {
    const branch = this.allBranches.find(b => b.id === id);
    return branch ? branch.name : null;
  }

  branchChanged(): void {
    this.updateCategoryUnits();
  }

  cancelChanges(): void {
    this.actionCommand.cancelChanges();
  }

  save(): void {
    const commandName = this.interbranch ? 'MiscInterbranchCreateCashiersCheck' : 'MiscCreateCashiersCheck';
    this.miscCommandService.executeCommand(commandName, {
      ...this.transaction,
      units: this.transaction.branchUnits
    });
  }
}

nxModule.component('createCashiersCheck', {
  templateUrl,
  bindings: {
    interbranch: '<',
  },
  controller: CreateCashiersCheck
});
