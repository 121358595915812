import BigNumber from "bignumber.js";
import nxModule from 'nxModule';


const templateUrl = require('./transaction-units.template.html');
nxModule.component('transactionUnits', {
  templateUrl: templateUrl,
  bindings: {
    'options': '<',
    'transaction': '=',
    'selectConfig': '<'
  },
  controller: function () {
    let that = this;

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.plus(item[itemProperty] || 0), new BigNumber(0));
    };

    that.calculateDebitSum = () => {
      return bigSumBy(that.transaction.units, 'amount');
    };

    that.addAccountRow = () => {
      that.transaction.units.push({});
    };

    that.removeAccount = (index) => {
      that.transaction.units.splice(index, 1);
    };
  }
});