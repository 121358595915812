import nxModule from 'nxModule';
import Authentication from 'shared/utils/authentication';
import templateUrl from './batch-remittance.template.html';

export class BatchRemittanceComponent {
  constructor(
    private authentication: Authentication,
  ) {
  }
}

nxModule.component('batchRemittance', {
  templateUrl: templateUrl,
  controller: BatchRemittanceComponent
});
