import nxModule from 'nxModule';
import {IController, ILocationService} from 'angular';
import {NgTableParams} from 'ng-table';
import {LoanPDCDetails, LoanPDCStatus} from '../../../service/loan-pdc.types';
import {LoanType} from '../../../service/loan-type.types';

import templateUrl from './loan-pdc-list.template.html';
import {getStatusStyle} from '../../../customer/loans/pdc/loan-pdc-status-styling';
import {Branch} from "management/BranchTypes";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {BranchService} from "components/service/branch.service";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import Authentication from "shared/utils/authentication";
import {UserService} from "components/service/users/user.service";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";
import {PageResult} from 'tools/HttpTypes';

interface PDCFilter {
  branchId: number;
  loanTypeIds: number[];
  status: (LoanPDCStatus | null)[];
  dateRange: { from: string; to: string; } | null
}

type LabelValue = { label: string; value: string };
type LoanPDCDetailsExtended = LoanPDCDetails & { details: LabelValue[] }

class LoanPdcList implements IController {

  private readonly pdcUrl: string = '/products/loans/post-dated-checks';

  tableConfig: NgTableParams<LoanPDCDetailsExtended> = new NgTableParams<LoanPDCDetailsExtended>({
    count: 20
  }, {
    counts: [],
    paginationMaxBlocks: 8,
    paginationMinBlocks: 3,
    getData: (params: NgTableParams<LoanPDCDetailsExtended>): Promise<LoanPDCDetailsExtended[]> => this.fetchData(params)
  });

  filter?: PDCFilter;
  availableBranches: Branch[] = [];
  availableLoanTypes: (LoanType & { productName: string | undefined })[] = [];
  checkStatuses: { label: string; value: string }[] = [];

  selectedCheckId: number | null = null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  selectedCheck: LoanPDCDetailsExtended;

  constructor(private readonly $filter: NxIFilterService,
              private readonly $location: ILocationService,
              private readonly http: HttpService,
              private readonly branchService: BranchService,
              private readonly loanProductsCache: LoanProductsCache,
              private readonly authentication: Authentication,
              private readonly userService: UserService,
              private readonly modalPrintPreviewService: ModalPrintPreviewService) {
  }

  async $onInit(): Promise<void> {
    await this.setupFilters();
  }

  private async setupFilters(): Promise<void> {
    const [branches, loanTypes] = await Promise.all([
      this.branchService.toPromise(),
      this.loanProductsCache.toPromise()
    ]);
    this.availableBranches = branches.filter(branch => this.authentication.context.branchIds.includes(branch.id));
    this.availableLoanTypes = loanTypes.map(loanType => ({
      ...loanType,
      productName: loanType.productDefinition.productName
    }))
      .sort((loanType1, loanType2) => loanType1.productName!.localeCompare(loanType2.productName!));
    this.checkStatuses = Object.keys(LoanPDCStatus).map(key => ({ label: this.$filter('prettyEnum')(key), value: key }));

    this.filter = {
      branchId: this.authentication.context.branchId,
      loanTypeIds: this.availableLoanTypes.map(loanType => loanType.id),
      status: [null],
      dateRange: null
    };
    this.onBranchFilterChange();
  }

  private fetchData(params: NgTableParams<LoanPDCDetailsExtended>): Promise<LoanPDCDetailsExtended[]> {
    return this.http.post<PageResult<LoanPDCDetails>>(this.pdcUrl, this.filterToPayload(this.filter, params) )
      .toPromise()
      .then(pageResult => {
        this.tableConfig.total(pageResult.totalCount);
        return Promise.all(
          pageResult.result
            .map(async (pdc: LoanPDCDetails) => ({ ...pdc, details: await this.buildDetails(pdc) }))
        );
      });
  }

  private async buildDetails(pdc: LoanPDCDetails): Promise<LabelValue[]> {
    const details: LabelValue[] = [];
    const pushDetail = (label: string, value: string | number, include: boolean = true) => {
      if (include) {
        details.push({ label: label, value: value ? `${ value }` : '-' });
      }
    };

    const isPosted = this.isPaymentPerformed(pdc);
    const postedByUsername = await this.userService.getUserName(pdc.postedBy);
    pushDetail('Status', this.$filter('prettyEnum')(pdc.status));
    pushDetail('Amount', this.$filter('nxCurrency')(pdc.amount));
    pushDetail('Type', this.$filter('prettyEnum')(pdc.type));
    pushDetail('Due date', this.$filter('prettyDate')(pdc.amortizationDueDate));
    pushDetail('Valid from', this.$filter('prettyDate')(pdc.validFrom));
    pushDetail('Cleared on', this.$filter('prettyDate')(pdc.clearedOn));
    pushDetail('Payment transaction no.', pdc.encashmentOperationId, isPosted);
    pushDetail('Posted by', postedByUsername ?? '', isPosted);
    pushDetail('Line numbers', pdc.amortizationNo);
    pushDetail('Interest amount', this.$filter('nxCurrency')(pdc.interestAmount), isPosted);
    pushDetail('Principal amount', this.$filter('nxCurrency')(pdc.principalAmount), isPosted);
    pushDetail('Outstanding balance after', this.$filter('nxCurrency')(pdc.outstandingBalanceAfter), isPosted);

    return details;
  }

  private isPaymentPerformed(pdc: LoanPDCDetails): boolean {
    return !!pdc.encashmentOperationId;
  }

  private filterToPayload(filter: PDCFilter | undefined, params: NgTableParams<LoanPDCDetailsExtended>): Record<string, unknown> {
    return {
      pageNo: params.page() - 1,
      pageSize: params.count(),
      branchId: filter?.branchId ? [filter?.branchId] : [],
      loanTypeId: filter?.loanTypeIds ?? [],
      status: filter?.status && filter.status[0] !== null ? filter.status : [],
      dueDateFrom: this.$filter('nxDate')(filter?.dateRange?.from),
      dueDateTo: this.$filter('nxDate')(filter?.dateRange?.to),
    };
  }

  onBranchFilterChange(): void {
    if (!this.filter || !this.filter.branchId) {
      return;
    }

    const branch = this.availableBranches.find(branch => Number(branch.id) === Number(this.filter?.branchId))!;
    this.filter.dateRange = { from: `${branch.systemDate}`, to: `${branch.systemDate}` };
  }

  doFilter(): void {
    // reset paging right before request (ngTable counts pages starting from 1)
    this.tableConfig.page(1);
    this.tableConfig.reload();
    this.hideInlinePanel();
  }

  isDataPresent(): boolean {
    return this.tableConfig.data.length > 0;
  }

  itemClicked(check: LoanPDCDetailsExtended, $event: JQueryEventObject): void {
    $event.stopPropagation();
    this.selectedCheck = check;
    this.selectedCheckId = check.id;
  }

  hideInlinePanel(): void {
    this.selectedCheckId = null;
  }

  goToLoan(pdc: LoanPDCDetailsExtended): void {
    this.$location.path(`/customer/${pdc.customerId}/loans/${pdc.loanId}`).search({showClosed: 'true'});
  }

  isPaymentReceiptAvailable(pdc: LoanPDCDetailsExtended): boolean {
    return this.isPaymentPerformed(pdc);
  }

  async showPaymentReceipt(pdc: LoanPDCDetailsExtended): Promise<void> {
    await this.modalPrintPreviewService.showAsync({
      printDescription: 'LOAN_PAYMENT_RECEIPT',
      printProviderInput: {
        operationId: pdc.encashmentOperationId
      }
    });
  }

  getStatusStyle(status: LoanPDCStatus): string {
    return getStatusStyle(status);
  }
}

nxModule.component('loanPdcList', {
  templateUrl,
  controller: LoanPdcList
});
