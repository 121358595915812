import {
  NxButtonVariant,
  NxFormik,
  NxFormikButton,
  NxFormikSubmitButton,
  NxFormikNumberInput,
  NxRow,
  NxRowPosition,
  NxStack
} from '@nextbank/ui-components';
import {CommandOutputWrapper} from 'command/CommandTypes';
import NxForm from 'form/NxForm';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement} from 'react';
import {useHistory, useParams} from 'react-router';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import {useCommand} from '../../command/CommandService';
import NxHeader from '../../form/NxHeader';
import NxPage from '../../form/NxPage';

interface CheckBatchFormInput {
  checkbookNumber: number;
  numberOfCheckbooks: number;
  checkStartNumber: number;
  checksCount: number;
}

interface CheckBatchInput extends CheckBatchFormInput {
  depositoryAccountId: number;
}

const RequiredNumber = Yup.number()
  .required()
  .positive();

const CheckBatchInputSchema: SchemaOf<CheckBatchFormInput> = Yup.object().shape({
  checkbookNumber: RequiredNumber,
  numberOfCheckbooks: RequiredNumber,
  checkStartNumber: RequiredNumber,
  checksCount: RequiredNumber,
});

const CreateCheckbookBatch = (): ReactElement => {
  const {depositoryAccountId} = useParams<{depositoryAccountId: string}>();
  const history = useHistory();
  const execute = useCommand();

  return <NxPage>
    <NxHeader>
      Create checkbooks
    </NxHeader>
    <NxFormik<CheckBatchFormInput>
      initialValues={{
        checkbookNumber: 1,
        numberOfCheckbooks: 1,
        checkStartNumber: 1,
        checksCount: 50
      }}
      validationSchema={CheckBatchInputSchema}
      onSubmit={async (values: CheckBatchFormInput): Promise<void> => {
          const response: CommandOutputWrapper<void> = await execute<CheckBatchInput, void>({
            name:'BatchCreateCheckbook',
            input: {
              ...values,
              depositoryAccountId: Number(depositoryAccountId)
          }});

          if(!response.approvalRequired) {
            history.push(`/check-preparation/check-management/depository-accounts/${depositoryAccountId}/checkbooks`);
          }
        }
      }>
      {(): ReactElement => (
        <NxForm>
          <NxStack>
            <NxFormikNumberInput label="Booklet start number" name="checkbookNumber" />
            <NxFormikNumberInput label="Number of booklets" name="numberOfCheckbooks" />
            <NxFormikNumberInput label="Check start number" name="checkStartNumber" />
            <NxFormikNumberInput label="Checks in booklet" name="checksCount" />
            <NxRow position={NxRowPosition.END}>
              <NxCancelButton />
              <NxFormikSubmitButton
                variant={NxButtonVariant.ADD}>
                Create
              </NxFormikSubmitButton>
            </NxRow>
          </NxStack>
        </NxForm>
      )}
    </NxFormik>
  </NxPage>;
};

export default CreateCheckbookBatch;