import nxModule from 'nxModule';
import templateUrl from './update-loan-metadata-mode.template.html';
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {AttachedFile} from "components/service/file.service";

interface UpdateLoanMetadataModeInput {
  manualMetadata: boolean;
  fileId: number;
}

class UpdateLoanMetadataMode {
  private contentFile: AttachedFile[] = [];

  private manualMetadata: boolean | undefined;
  private readonly loanMetadata = [{ label: 'Automatic', value: false }, { label: 'Manual', value: true }];

  private readonly fileExample = [
    'product_number',
    '001-0100-00001-0',
    '002-0200-00002-1'
  ].join('\n');

  private readonly sampleFile = new Blob([this.fileExample], {type: 'text/csv'});

  constructor(private readonly command: CommandService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly popup: Popup,
              private readonly $route: NxRouteService) {

  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed on updating loan metadata mode?`,
    });

    if (!confirmed) {
      return;
    }

    const {output} = await this.command.execute<UpdateLoanMetadataModeInput, {executionErrors: {productNumber: string, errorMessage: string}[]}>('UpdateLoanMetadataMode', {
      fileId: this.contentFile[0].id,
      manualMetadata: this.manualMetadata
    } as UpdateLoanMetadataModeInput).toPromise();

    if (output.executionErrors && output.executionErrors.length > 0) {
      const header = '<p><span class="red"><strong>WARNING!</strong> Loans listed below resulted in errors and will not update its metadata mode:</span></p>';
      const body: string[] = [];
      output.executionErrors.forEach(err => {
        body.push(...Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
      });
      const errorDetails = [header, ...body].join('');
      await this.popup({
        header: 'Update result',
        text: errorDetails,
        renderHtml: true
      });
    }
    this.$route.reload();
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'UpdateLoanMetadataMode.csv';
    a.click();
  }
}

nxModule.component('updateLoanMetadataMode', {
  templateUrl,
  controller: UpdateLoanMetadataMode
});
