import templateUrl from './customer-type-transaction-bracket-section.template.html';
import nxModule from 'nxModule';

class ComplianceCustomerTypeTransactionBracketSection {
}

nxModule.component('complianceCustomerTypeTransactionBracketSection', {
  templateUrl,
  bindings: {
    redFlagTagType: '<',
    config: '<',
  },
  controller: ComplianceCustomerTypeTransactionBracketSection
});
  