export const CHECKBOOK_ACCOUNT_NUMBER_LENGTH = 12;
class CheckbookService {
  generateCheckbookAccountNumber(productNumber: string): string | null {
    const regex = /[^0-9]+/g;
    const accountNumber = productNumber.replace(regex, '');
    if (accountNumber.length !== CHECKBOOK_ACCOUNT_NUMBER_LENGTH) {
      return null;
    }
    return accountNumber;
  }
}

export default new CheckbookService();
export const serviceType = CheckbookService;
