import _ from "lodash";

import nxModule from 'nxModule';
import {setBreadcrumbs} from '../../shared/utils/set-breadcrumbs';

const templateUrl = require('./report-details.template.html');

nxModule.component('reportDetails', {
  templateUrl,
  bindings: {
    reportType: '<',
    reportCode: '<',
    customerId: '<'
  },
  controller: function ($scope, $location, http, breadcrumbs, reportService, modalPrintPreviewService) {
    const that = this;
    const reportTypeName = that.reportType && that.reportType.replace(/-/g, ' ');

    that.descriptor = {};
    that.reportGenerated = false;
    that.reportContent = null;
    that.reportColumnsNum = null;
    that.filterConfig = {reportCode: that.reportCode};

    that.onModalReady = ({api}) => {
      that.rowModalApi = api;
    };


    that.params = !that.customerId ? {} : { customerId: that.customerId };

    const isPrintDisabled = () => that.isReportEmpty();

    that.isReportEmpty = () => {
      if (that.descriptor.async) {
        return !that.reportContent || !that.reportContent.hasOwnProperty("detail");
      }

      return !that.reportContent || that.reportContent.filter(c => c.rows.length !== 0).length === 0;
    };

    that.showEmptyReportMessage = () => {
      return that.reportContent && that.isReportEmpty();
    };

    const outputSupported = (type) => {
      return that.descriptor
        && that.descriptor.outputTypes
        && that.descriptor.outputTypes.includes(type);
    };

    const hasReportPrints = () => {
      return that.descriptor
        && (that.descriptor.prints || []).length > 0;
    };

    const showRowModal = async (printCode) => {
      that.start = 1;
      that.end = that.reportContent[0].rows.length;
      const result = await that.rowModalApi.show();
      if(result?.accepted) {
        showPrint(printCode);
      }
    }

    const showPrint = async (printCode) => {
      const reportContent = that.reportContent[0].rows.slice(that.start - 1, that.end);
      const hasSinglePrintConfig = () => {
        const parameters = reportContent.map(row => {
          const rowPrintConfiguration = row.prints.find(p => p.printCode === printCode);
          return rowPrintConfiguration?.parameters ?? [];
        });
        return _.uniq(parameters.map(p => Object.values(p)).flat()).length === Object.keys(parameters[0]).length
      }

      if (hasSinglePrintConfig()) {
        const inputs = reportContent.map(row => {
          const rowPrintConfiguration = row.prints.find(p => p.printCode === printCode);
          return rowPrintConfiguration?.inputValues ?? [];
        });

        const parameters = reportContent.find(row => 
          row.prints.some(p => p.printCode === printCode)
        )?.prints.find(p => p.printCode === printCode)?.parameters || {};
    
        await modalPrintPreviewService.showAsync({
          printDescription: {
            code: printCode,
            parameters
          },
          printProviderInput: {
            inputValues: inputs
          }
        });
      } else {
        const modalInput = [];
        reportContent.forEach(row => {
          row.prints.forEach(p => modalInput.push({
            inputs: {
              inputValues: p.inputValues ?? []
            },
            parameters: p.parameters
          }));
        })

        await modalPrintPreviewService.batchShowAsync({
          printDescription: printCode,
          input: modalInput
        });
      }
    };

    const setupButtons = async () => {
      const reportPrints = await (hasReportPrints() ? Promise.all(that.descriptor.prints.map(async printCode => {
        const canPrint = await modalPrintPreviewService.canReprintAsync(printCode);
        return {
          isVisible: outputSupported('UI_MODEL') && canPrint,
          isDisabled: isPrintDisabled(),
          action: () => showRowModal(printCode),
          text: 'DOWNLOAD_' + printCode
        };
      })) : []);

      that.filterConfig.buttons = {
        filter: {
          isVisible: outputSupported('UI_MODEL'),
          isDisabled: false,
          action: () => that.loadReportContent(),
          text: 'View'
        }, download: {
          isVisible: outputSupported('XLS'),
          isDisabled: false,
          action: () => that.downloadXls(),
          text: that.descriptor?.outputLabel['XLS'] ?? 'Download xls'
        }, downloadCsv: {
          isVisible: outputSupported('CSV'),
          isDisabled: false,
          action: () => that.downloadCsv(),
          text: that.descriptor?.outputLabel['CSV'] ?? 'Download csv'
        }, print: {
          isVisible: outputSupported('UI_MODEL'),
          isDisabled: isPrintDisabled(),
          action: () => window.print(),
          text: 'Print'
        }, reportPrints
        , downloadCustomFile: {
          isVisible: outputSupported('CUSTOM_FILE'),
          isDisabled: false,
          action: () => that.downloadCustomFile(),
          text: that.descriptor?.outputLabel['CUSTOM_FILE'] ?? 'Download file'
        },
        downloadAsyncCustomFile: {
          isVisible: outputSupported('ASYNC_CUSTOM_FILE'),
          isDisabled: false,
          action: () => that.downloadAsyncCustomFile(),
          text: that.descriptor?.outputLabel['ASYNC_CUSTOM_FILE'] ?? 'Download file'
        },
      };
    };

    http.get(`/reports/descriptors/${that.reportCode}`)
      .success(d => {
        that.descriptor = d;
        that.descriptor.params = _.union([], d.mandatoryParams, d.optionalParams);
        setupButtons();
        setBreadcrumbs(breadcrumbs, 'report-list', reportTypeName);
        setBreadcrumbs(breadcrumbs, 'report-details', that.descriptor.name);
      });

    that.loadReportContent = async () => {
      try{
        that.reportContent = await reportService.downloadJson({reportCode: that.reportCode, params: that.params});
      } catch (error){
        console.log('Error when loading report content: ' + error);
        if(error.errorCode === 'JSON_RESPONSE_TOO_LARGE'){
          this.filterConfig.buttons.filter.isDisabled = true;
        }
      }

      that.filterConfig.buttons.print.isDisabled = isPrintDisabled();
      that.filterConfig.buttons.reportPrints.forEach(letter => letter.isDisabled = isPrintDisabled());
    };

    that.convertToNewReportFormat = report => {
      return report;
    };

    that.reportRightPadding = (sheet) => {
      return that.reportColumnsNum - sheet.headers.length;
    };

    that.downloadXls = () => {
      reportService.downloadXls({reportCode: that.reportCode, params: that.params, reportName: that.descriptor.name});
    };

    that.downloadCsv = () => {
      reportService.downloadCsv({reportCode: that.reportCode, params: that.params, reportName: that.descriptor.name});
    };

    that.downloadCustomFile = () => {
      reportService.downloadCustomFile({reportCode: that.reportCode, params: that.params});
    };

    that.downloadAsyncCustomFile = () => {
      reportService.downloadAsyncCustomFile({reportCode: that.reportCode, params: that.params});
    };

    that.displaySummary = (report) => {
      if (report && report.summaryRow) {
        return _.some(report.summaryRow, (cell) => cell !== null);
      }
      return false;
    };

    that.months = [{
      month: 1, label: 'January'
    }, {
      month: 2, label: 'February'
    }, {
      month: 3, label: 'March'
    }, {
      month: 4, label: 'April'
    }, {
      month: 5, label: 'May'
    }, {
      month: 6, label: 'June'
    }, {
      month: 7, label: 'July'
    }, {
      month: 8, label: 'August'
    }, {
      month: 9, label: 'September'
    }, {
      month: 10, label: 'October'
    }, {
      month: 11, label: 'November'
    }, {
      month: 12, label: 'December'
    }];

    that.$onDestroy = () => {
      $location.search({});
    };
  }
});
