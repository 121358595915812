import {ICacheFactoryService} from 'angular';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import LocalCache, {LocalCacheArgs} from 'shared/utils/localCache';
import {Loan} from './loan.types';

export interface LoanCache {
  loan: (loanId: number | string) => LocalCache<Loan, Loan>

  refetch(): unknown;
}

nxModule.factory('loanCache', function (http: HttpService, CacheFactory: ICacheFactoryService) {
  const that = this;
  const service: Partial<LoanCache> & {refetch?: () => void} = {};

  that.cacheMap = {};

  function retrieveCacheObject(loanId: number | string, cacheName: string, cacheOptions: Partial<LocalCacheArgs<Loan, Loan>>): LocalCache<Loan, Loan> {
    // adds default params

    const fullCacheOptions: Partial<LocalCacheArgs<Loan, Loan>> & {cacheName: string} = {
      ...cacheOptions,
      cacheName: cacheName,
      cacheStorage: 'memory',
      cacheFactory: CacheFactory
    };

    if (!that.cacheMap[cacheName]) {
      that.cacheMap[cacheName] = new LocalCache(fullCacheOptions);
    }
    return that.cacheMap[cacheName];
  }

  service.loan = (loanId: number | string): LocalCache<Loan, Loan>  =>
    retrieveCacheObject(loanId, 'loan' + loanId, {
      provider: () => http.get<Loan[]>(`/products/loans/${loanId}`)
    });

  service.refetch = () => {
    let keys = Object.keys(that.cacheMap);
    for (let key of keys) {
      that.cacheMap[key].refetch();
    }
  };

  return service;
});