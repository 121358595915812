import nxModule from 'nxModule';
import {INgModelController} from 'angular';

import templateUrl from './prints-details-template-upload.template.html'

import {FileReaderService} from "../../../shared/utils/file-reader.service";
import './prints-details-template-upload.style.less';

class PrintsDetailsTemplateUpload {
  private ngModel!: INgModelController;
  private template = '';

  constructor(private fileReader: FileReaderService) {
  }

  $onInit(): void {
    this.ngModel.$render = (): void => {
      this.template = this.ngModel.$modelValue;
    };
  }

  async onFilesAdded(files: File[]): Promise<void> {
    this.template = await this.fileReader.readAsText(files[0]);
    this.onTemplateChange();

  }

  onTemplateChange(): void {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.template);
  }
}

nxModule.component('printsDetailsTemplateUpload', {
  templateUrl,
  require: {
    ngModel: '^ngModel'
  },
  controller: PrintsDetailsTemplateUpload
});