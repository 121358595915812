import LocalCache from 'shared/utils/localCache';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import {ICacheFactoryService} from 'angular';
import config from 'config';

export type ComplianceConfigCache = LocalCache<unknown, ComplianceConfigInterface>;

export interface ComplianceConfigInterface {
  forceUpdateSchedule: {
    [key: string]: number;
  };
  unusualTransactionAmountThreshold: number;
  individualDailyMultipleTransactionThreshold: number;
  bdaDailyMultipleTransactionThreshold: number;
  restrictedDailyMultipleTransactionThreshold: number;
  corporateDailyMultipleTransactionThreshold: number;
  shortTermAccountThreshold: number;
  shortTermDepositThreshold: number;
}

nxModule.factory('complianceConfigCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: (): unknown => http.get(`/management/compliance-config`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.complianceConfig,
    cacheName: 'complianceConfig'
  })
);
