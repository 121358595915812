import {NxRouteService} from 'routes/NxRouteService';
import Authentication from 'shared/utils/authentication';
import {CommandAccessChecker} from 'shared/utils/command/command.types';

const hasAccessToCommands = (commandAccessChecker: CommandAccessChecker, commandNames: string[]): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return commandAccessChecker.canExecuteCommandAsync()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .then(canExecuteCommand => commandNames.every((command: string) => canExecuteCommand(command)));
};

const errorPageRoute = '/error-page/ACCESS';

/**
 * Redirect to error page if user has no access to command.
 */
export const accessCheckByCommand = (...commandNames: string[]): ((accessChecker: CommandAccessChecker) => Promise<string | undefined>) => {
  return /* @ngInject */ (commandAccessChecker: CommandAccessChecker): Promise<string | undefined> =>
    hasAccessToCommands(commandAccessChecker, commandNames)
      .then(canExecute => canExecute ? undefined : errorPageRoute);
};

/**
 * Redirect to error page if user has no access to command
 * or branch mentioned in url.
 */
export const accessCheckByCommandAndBranchId = (...commandNames: string[]): ((accessChecker: CommandAccessChecker, authentication: Authentication, $route: NxRouteService) => Promise<string | undefined>) => {
  return /* @ngInject */ (commandAccessChecker: CommandAccessChecker, authentication: Authentication, $route: NxRouteService): Promise<string | undefined> =>
    hasAccessToCommands(commandAccessChecker, commandNames)
      .then(canExecuteCommand => {
        const authBranchId: number = Number($route.current.params['branchId']);
        return canExecuteCommand && authentication.context.branchIds.includes(authBranchId) ?
          undefined : errorPageRoute;
      });
};