import {IFormController, ILocationService} from 'angular';
import {AgentsCustomerService} from 'components/agent-banking/service/agents-customer.service';
import {ProfileUpdateService} from 'components/customer/profile/profile-update.service.types';
import {ProfileUtilityService} from 'components/customer/profile/profile-utility.service';
import {BranchService} from 'components/service/branch.service';
import {CustomerCache} from 'components/service/customer.cache.types';
import {CustomerService} from 'components/service/customer.service';
import {cloneDeep} from 'lodash/fp';
import {Branch} from 'management/BranchTypes';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Confirmation} from 'shared/common/confirmation.types';
import Authentication from 'shared/utils/authentication';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';
import {CustomerProfile, CustomerType} from '../../customer/profile/customer-profile.types';
import {NxIFilterService} from '../../technical/angular-filters';
import templateUrl from './agent-candidacy.template.html';
import {AgentApplicationStatus, AgentCandidacy} from './agent-candidacy.types';

class AgentCandidacyComponent {

  editMode!: boolean;
  editStatus!: boolean;
  processMode!: boolean;
  candidacyForm!: IFormController;
  customerForm!: IFormController;
  individualDataForm!: IFormController;
  addressesForm!: IFormController;
  application!: AgentCandidacy;
  branches: Branch[] = [];

  constructor(private customerCache: CustomerCache,
              private profileUtilityService: ProfileUtilityService,
              private customerService: CustomerService,
              private notification: Notification,
              private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private branchService: BranchService,
              private $route: NxRouteService,
              private http: HttpService,
              private profileUpdateService: ProfileUpdateService,
              private agentsCustomerService: AgentsCustomerService,
              private $filter: NxIFilterService,
              private authentication: Authentication) {
  }

  async $onInit() {
    const candidacyId = Number(this.$route.current.params['id']);

    if (!isNaN(candidacyId)) {
      this.application = await this.http.get<AgentCandidacy>(`/agents/candidacy/${candidacyId}`).toPromise();
      this.application.customer = await this.getCustomer(this.application.customerId);
    }
    this.branches = await this.branchService.toPromise();
  }

  private async getCustomer(customerId: number): Promise<CustomerProfile> {
    if (customerId) {
      return await this.agentsCustomerService.fetchCustomer(this.application.customerId);
    } else {
      return this.customerService.extractPhones(this.application.customer);
    }
  }

  async initCustomer() {
    if (!this.application || await this.waitForConfirmation('This action will reset the form completely. Do you want to continue?')) {
      this.application = {} as AgentCandidacy;
      this.application.customer = await this.profileUtilityService.initCustomerProfile();
      this.application.customer.idDocuments = [{primary: true}];
      this.application.customer.incomeSources = [];
      this.application.branchId = this.authentication.context.branchId;
    }
  }

  async chooseExisting(customer: { id: number, customerType: CustomerType }) {
    if (!this.application || await this.waitForConfirmation('This action will override all inputted data. Do you want to continue?')) {
      this.application = {} as AgentCandidacy;
      this.application.customer = await this.agentsCustomerService.fetchCustomer(customer.id);
      this.application.branchId = this.authentication.context.branchId;
    }
  }

  async saveApplication() : Promise<void> {
    const msg = [AgentApplicationStatus.DISAPPROVED, AgentApplicationStatus.APPLICANT_BACK_OUT].includes(this.application.status)  ?
        `You are about to ${AgentApplicationStatus.DISAPPROVED === this.application.status ? 'Disapprove' : 'Back out'} agent application. You will no longer be able to edit the application. This action is irreversible. Do you want to proceed?`:
        'Do you want to save the application?';

    if (await this.waitForConfirmation(msg)) {
      const customer = cloneDeep(this.application.customer);
      if(this.application.customer.id) {
        // Fix for the following scenario:
        // 1. We use existing customer with primary non agent available income source
        // 2. we create agent application, which fails because we have multiple primary income sources
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customer.idDocuments = await this.agentsCustomerService.addNonAgentIdDocuments(this.application.customer.id, customer.idDocuments ?? []);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customer.incomeSources = await this.agentsCustomerService.addNonAgentIncomeSources(this.application.customer.id, customer.incomeSources ?? []);
      }

      const request = {
        id: this.application.id,
        status: this.application.status,
        brdReportId: this.application.brdReportId,
        customerId: this.application.customer.id,
        branchId: this.application.branchId,
        customerInput: this.profileUpdateService.convertToCommandInput(customer)
      };

      const commandName = this.application.id != null ? 'UpdateAgentCandidacy' : 'CreateAgentCandidacy';
      const response = await this.command.execute(commandName, request).toPromise();

      if (!response?.approvalRequired) {
        this.notification.show('Successfully saved application');
        if (this.application.customer.id) {
          this.customerCache.profile(this.application.customer.id).refetch();
        }
        this.redirectBack();
      }
    }
  }

  private async waitForConfirmation(warningMessage: string): Promise<boolean> {
    return await this.confirmation(warningMessage);
  }

  async approveApplication() : Promise<void> {
    if (await this.waitForConfirmation('Do you want to approve the application?')) {
      await this.command.execute('ApproveAgentCandidacy', {id: this.application.id}).toPromise();
      this.$route.reload(); // refreshes buttons
    }
  }

  async disapproveApplication() : Promise<void> {
    if (await this.waitForConfirmation('Do you want to disapprove the application?')) {
      await this.command.execute('DisapproveAgentCandidacy', {id: this.application.id}).toPromise();
      this.$route.reload(); // refreshes buttons
    }
  }

  showWarningAboutIncompleteData(): boolean {
    return ((this.individualDataForm && this.individualDataForm.$invalid)
        || (this.addressesForm && this.addressesForm.$invalid))
        && !!(this.application.customer && this.application.customer.id && this.application.customer.status === 'ACTIVE');
  }

  async goToCustomerProfile() {
    const warningMsg = 'You are going to exit application form. Do you want to proceed?';
    const proceed = await this.confirmation(warningMsg);
    if (proceed) this.$location.path(`/customer/${this.application.customer.id}/profile`);
  }

  async cancelEdit() : Promise<void> {
    if (await this.waitForConfirmation('Are you sure you want to cancel and discard all changes?')) {
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path('/agent-banking/application');
  }
}

nxModule.component('agentCandidacy', {
  templateUrl,
  bindings: {
    editMode: '<',
    editStatus: '<',
    processMode: '<'
  },
  controller: AgentCandidacyComponent
});
