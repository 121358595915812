import {
    NxButtonVariant,
    NxFormik,
    NxFormikButton,
    NxFormikSubmitButton,
    NxFormikCashInput,
    NxFormikInput,
    NxLoader,
    NxRow,
    NxRowPosition,
    NxStack,
} from "@nextbank/ui-components";
import React, {ReactElement, useEffect} from "react";
import {useHistory, useParams} from "react-router";
import NxForm from "form/NxForm";
import {useCommand} from "command/CommandService";
import {CommandOutputWrapper} from "command/CommandTypes";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import {Loan} from "components/service/loan.types";
import systemPropertyService from "system/systemPropertyService";
import tokenStore from "authentication/tokenStore";
import {OfficialReceipt} from "components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types";
import HttpErrorHandler from "misc-transaction/encash-cashiers-check/HttpErrorHandler";
import NxCancelButton from 'NxCancelButton';

interface UidRebateInput {
  loanId: number;
}

interface RebateAmounts {
  interestToApply: number;
  rebateAmount: number;
}

interface UidRebateData {
  interestEarned: number;
  rebateAmount: number;
  principalRemaining: number;
  officialReceipt: string;
}

const UidRebate = (): ReactElement => {
  const {customerId, loanId} = useParams<{ customerId: string, loanId: string }>();
  const history = useHistory();
  const execute = useCommand();

  const [{data: loan, loading}] = useAxios<Loan>(`/products/loans/${loanId}`);
  const [{data: rebateAmounts}] = useAxios<RebateAmounts>(`/products/loans/${loanId}/rebate-amounts`);
  const userId = tokenStore.getTokenDetails().userId;
  const [{data: officialReceipt, error}, fetchOfficialReceipt] = useAxios<OfficialReceipt>(`/official-receipt/next-available?userId=${userId}`, {
    manual: true
  })
  const autoOr = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
  // Only fetch official receipt if autoOr is enabled
  useEffect(() => {
    if (autoOr) fetchOfficialReceipt()
  }, [autoOr, fetchOfficialReceipt])

  const pageHeader = 'UID rebate';
  if (error && autoOr) {
    return <HttpErrorHandler error={error} pageHeader={pageHeader} />
  }

  if (!loan || !loan.uidLedger || loading || !rebateAmounts || (!officialReceipt && autoOr)) {
    return <NxPage><NxHeader>{pageHeader}</NxHeader><NxLoader/></NxPage>;
  }

  return <NxPage>
    <NxHeader>{pageHeader}</NxHeader>
    <NxFormik<UidRebateData>
      initialValues={{
        interestEarned: rebateAmounts.interestToApply,
        rebateAmount: rebateAmounts.rebateAmount,
        principalRemaining: loan.principalBalance - rebateAmounts.rebateAmount,
        officialReceipt: officialReceipt?.receiptNumber ?? ''
      }}
      onSubmit={async (): Promise<void> => {

        const response: CommandOutputWrapper<void> = await execute<UidRebateInput, void>({
          name: 'UidRebate',
          input: {
            loanId: Number(loanId),
          }
        });

        if (!response.approvalRequired) {
          history.push(`/customer/${customerId}/loans/${loanId}`);
        }
      }
      }>
      {(): ReactElement => (
        <NxForm>
          <NxStack>
            <NxFormikCashInput disabled label="Interest earned" name="interestEarned"/>
            <NxFormikCashInput disabled label="Rebate amount" name="rebateAmount"/>
            <NxFormikCashInput disabled label="Principal after rebate" name="principalRemaining"/>
            {autoOr && <NxFormikInput disabled={true} label="Official receipt" name="officialReceipt"/>}
          </NxStack>
          <NxRow position={NxRowPosition.END}>
            <NxCancelButton />
            <NxFormikSubmitButton
              variant={NxButtonVariant.ADD}>
              Apply rebate
            </NxFormikSubmitButton>
          </NxRow>
        </NxForm>
      )}
    </NxFormik>
  </NxPage>;
};

export default UidRebate;