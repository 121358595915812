import nxModule from 'nxModule';

nxModule.factory('nav', ($location) => {
  let source = null;

  return {
    back: (step = 1) => {
      const path = $location.path();
      const depth = path.split("/").length - 1;
      if (depth < step) {
        console.log('No ancestor page found. Not navigating anywhere...');
      } else {
        let destination = path;
        for (let i = 0; i < step; i++) {
          destination = destination.substring(0, destination.lastIndexOf('/'));
        }
        $location.path(destination);
      }
    },

    /**
     * Sets the page the user came from
     */
    setSource: (page) => source = page,

    /**
     * Clears the source page
     */
    clearSource: () => source = null,

    /**
     * Retrieves the source page
     */
    getSource: () => source
  }
});