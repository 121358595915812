import React, {MutableRefObject, ReactElement, useRef, useState} from "react";
import {
  NxButton,
  NxButtonLink,
  NxButtonVariant,
  NxPopup,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxTable,
  NxTableAction,
  NxTableColumn
} from "@nextbank/ui-components";
import {useParams} from "react-router";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";
import CommandAccess from "command/CommandAccess";
import {useCommand} from "command/CommandService";
import {NxTableRef} from "@nextbank/ui-components/dist/nxTable/NxTableTypes";
import {CheckPreparation, CheckPreparationStatus} from "components/service/check.type";
import NxLoadingButton from "NxLoadingButton";
import {CommandOutputWrapper} from "command/CommandTypes";

export interface CheckPreparationDetails extends CheckPreparation {
  no: number;
  formattedReleaseAmount: string;
}
const columns: NxTableColumn<CheckPreparationDetails>[] = [{
  title: "No",
  field: "no",
  width: 10
}, {
  title: "CIF",
  field: "customerNumber",
  width: 40,
}, {
  title: "Loan Product",
  field: "productName",
  width: 150,
},{
  title: "Date Applied",
  field: "check.registeredOn",
  width: 70
}, {
  title: "Check Date",
  field: "check.validFrom",
  width: 70
}, {
  title: "Check Number",
  field: "check.number",
  width: 70
}, {
  title: "Net Proceeds",
  field: "formattedReleaseAmount",
  width: 100
}];

interface CheckPreparationListProps<CommandOutput> {
  tableActions: NxTableAction<CheckPreparationDetails>[];
  cancelRedirectUrl: string;
  checkPreparationStatus: CheckPreparationStatus;
  checkTransition : {
    command: string;
    actionLabel: string;
    confirmationMessage: string;
    onCommandExecuted?: (commandOutput: CommandOutput) => Promise<unknown>;
  }
}


const CheckPreparationList = function<CommandOutput>(props: CheckPreparationListProps<CommandOutput>) : ReactElement {
  const params = useParams<{branchId: string}>();
  const [selectedData, setSelectedData] = useState<CheckPreparationDetails[]>([]);
  const [transitionCompleted, setTransitionCompleted] = useState<boolean>(true);
  const execute = useCommand();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const ref : MutableRefObject<NxTableRef | null> = useRef<NxTableRef | null>(null);

  const transitionSelectedChecks = async (): Promise<void> => {
    setTransitionCompleted(false);
    const selectedCheckPreparationIds = selectedData.map(datum => datum.checkPreparationId);

    setShowPopup(false);
    try {
      const response: CommandOutputWrapper<CommandOutput> = await execute({
        name: props.checkTransition.command,
        input: {
          checkPreparationIds: selectedCheckPreparationIds
        }
      });

      if (!response.approvalRequired) {
        if(props.checkTransition.onCommandExecuted) {
          await props.checkTransition.onCommandExecuted(response.output);
        }
        ref.current?.onQueryChange();
      }
    } finally {
      setSelectedData([]);
      setTransitionCompleted(true);
    }
  };

  return <>
      <NxTable
        ref={ref}
        columns={columns}
        selection={true}
        onSelectionChange={(selectedItems: CheckPreparationDetails[]) : void => {
          setSelectedData(selectedItems);
        }}
        rowActions={props.tableActions}
        data={async (query: NxQuery): Promise<NxQueryResult<CheckPreparationDetails>> => {
          let subPath = '';
          if (props.checkPreparationStatus === 'ASSIGNED') {
            subPath = 'assigned-checks';
          } else if (props.checkPreparationStatus === 'FOR_OUTGOING') {
            subPath = 'outgoing-checks';
          }

          const {data} = await axios.get<PageResult<CheckPreparation>>(`/check-preparation/branches/${params.branchId}/${subPath}/approved-loans`, {
            params: {
              pageNo: query.page,
              pageSize: query.pageSize
            }
          });

          const result: CheckPreparationDetails[] = data.result.map((checkPreparation, index) => {
            return {
              ...checkPreparation,
              no: query.pageSize * data.pageNo + index + 1,
              formattedReleaseAmount: checkPreparation.releaseAmount.toLocaleString()
            }
          });

          return {
            ...data,
            result: result
          };
        }}/>
      <NxRow position={NxRowPosition.END}>
        <NxButtonLink variant={NxButtonVariant.CLOSE} to={props.cancelRedirectUrl}>
          Cancel
        </NxButtonLink>
        <CommandAccess commandName={props.checkTransition.command}>
          <NxButton
            variant={NxButtonVariant.CONTAINED}
            onClick={() : void => setShowPopup(true)}
            disabled={selectedData.length < 1 || !transitionCompleted}
            loaded={transitionCompleted}
          >
            {props.checkTransition.actionLabel}
          </NxButton>
        </CommandAccess>
      </NxRow>
      <NxPopup header={`Confirm`}
               open={showPopup}
               description={props.checkTransition.confirmationMessage}>
        <NxRow position={NxRowPosition.END}>
          <NxButton variant={NxButtonVariant.CLOSE}
                    onClick={() : void => setShowPopup(false)}>
            No
          </NxButton>
          <NxLoadingButton variant={NxButtonVariant.ADD}
                    onClick={transitionSelectedChecks}>
            Yes
          </NxLoadingButton>
        </NxRow>
      </NxPopup>
    </>;
};

export default CheckPreparationList;