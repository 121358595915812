import nxModule from 'nxModule';
import BigNumber from "bignumber.js";
import {HttpService} from "shared/utils/httpService";

export interface TermDepositCalculatedTax {
  netInterest: number,
  withholdingTax: number
}

export class TermDepositCalculator {

  constructor(private readonly http: HttpService) {
  }

  async calculateModifiedInterest(depositId: number, grossInterest: number): Promise<TermDepositCalculatedTax> {
    const calculatedWTax = await this.http.get<number>(`/products/deposits/${depositId}/interest/calculate-withholding-tax?interest=${grossInterest}`).toPromise();

    return Promise.resolve(
      <TermDepositCalculatedTax>{
        netInterest: new BigNumber(grossInterest).minus(calculatedWTax).toNumber(),
        withholdingTax: calculatedWTax
      }
    );
  }

  recalculateGrossInterest(netInterest: number, wTax: number) : number {
    return new BigNumber(netInterest).plus(wTax).toNumber();
  }
}

nxModule.service('termDepositCalculator', TermDepositCalculator);
