import nxModule from 'nxModule';

import templateUrl from './cash-transfer.template.html';
import {MiscCommandService} from "../misc-command.service";
import {Branch} from "management/BranchTypes";
import Authentication from "shared/utils/authentication";
import {BranchService} from "components/service/branch.service";
import {UserCache, UserDetails} from "components/service/users/user.cache";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {CommandRoleMapCache} from "components/service/access/command-role-map.cache.types";
import {MiscTransactionsService} from "components/service/misc-transaction/miscellaneous-transactions.service.types";

class CashTransfer {
  commandName!: string;
  interbranch!: boolean;
  header!: string;

  private otherBranches: Branch[] = [];
  private approvalUsers: UserDetails[] = [];
  private allApprovalUsers: UserDetails[] = [];
  private targetBranchId?: number;
  private transaction: {
    targetUserId?: boolean,
    amount?: number
  } = { };

  private readonly targetBranchSelectConfig = {
    placeholder: 'Select a branch',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  constructor(private actionCommand: ActionCommand,
              private userCache: UserCache,
              private commandRoleMapCache: CommandRoleMapCache,
              private branchService: BranchService,
              private authentication: Authentication,
              private miscCommandService: MiscCommandService,
              private miscTransactionsService: MiscTransactionsService) {
  }

  async $onInit() {
    const [allUsers, branches] = await Promise.all([
      this.userCache.withParam().toPromise(),
      this.branchService.toPromise()
    ]);
    const users = allUsers.filter((user: UserDetails) => user.id !== this.authentication.context.id);

    this.otherBranches = branches.filter((b: Branch) => Number(b.id) !== Number(this.authentication.context.branchId));
    this.allApprovalUsers = await this.miscTransactionsService.filterApprovalUsersForCommand(this.commandName, users);

    if (!this.interbranch) {
      this.approvalUsers = this.allApprovalUsers.filter(user => {
        return (user.branchId === this.authentication.context.branchId);
      });
    }
  }

  onTargetBranchChange(branchId: number): void {
    this.transaction.targetUserId = undefined;
    this.approvalUsers = this.allApprovalUsers.filter(user => Number(user.branchId) === Number(branchId));
  }

  cancel(): void {
    this.actionCommand.cancelChanges();
  }

  async save() {
    this.miscCommandService.executeCommand(this.commandName, this.transaction);
  }
}


nxModule.component('cashTransfer', {
  templateUrl,
  bindings: {
    interbranch: '<',
    commandName: '<',
    header: '<'
  },
  controller: CashTransfer
});
