import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./role-read-access.template.html');

nxModule.component('roleReadAccess', {
  templateUrl: templateUrl,
  bindings: {
    // INPUT: role
    'role': '=',

    // "OUTPUT" param - list of permissions for the provided role
    'rolePermissions': '='
  },
  controller: function ($scope, permissionCache) {
    const that = this;

    that.permissions = null;

    if (!that.rolePermissions) that.rolePermissions = [];

    permissionCache.toObservable().first().subscribe(permissions => {
      that.permissions = permissions.filter(p => !p.hidden);
      mapPermissions();
    });

    $scope.$watch('$ctrl.role', () => {
      that.rolePermissions = [];
      mapPermissions();
    });

    function mapPermissions() {
      if (!that.role || !that.permissions) return;

      that.permissions = that.permissions.map(p => {
        p.roleHasPermission = !!_.find(that.role.permissions, {id: p.id});
        if (p.roleHasPermission) that.rolePermissions.push(p.id);
        return p;
      });
    }

    that.updatePermission = (p) => {
      if (p.roleHasPermission) {
        // add permission
        if (!that.rolePermissions.includes(p.id)) that.rolePermissions.push(p.id)

      } else {
        // remove permission
        const idx = that.rolePermissions.indexOf(p.id);
        if (idx > -1) that.rolePermissions.splice(idx, 1);
      }
    }

  }

});
