import nxModule from 'nxModule';

nxModule.factory('miscTransactionsService', function (actionCommand, http, authentication) {

  const service = {};
  /**
   * Approval user must meet following criteria:
   * 1. Is from the branch specified in the argument
   * 2. Has appropriate role (as of command)
   */
  service.filterApprovalUsersForCommand = async (command, users, branchId) => {
    const userIdsThatCanApprove = new Set(await http.get(`/access/approving-users?commandName=${command}`).toPromise());

    return users
      .filter(u => !branchId || u.branchId === branchId)
      .filter(u => u.roles && u.roles.length > 0)
      .filter(u => userIdsThatCanApprove.has(u.id));
  };

  service.filterApprovalUsersForAction = async (actionTemplate, users, branchId) => {
    actionTemplate.commandName = actionCommand.actionTypeToCommand(actionTemplate.type);
    return await service.filterApprovalUsersForCommand(actionTemplate.commandName, users, branchId);
  };

  return service;
});
