import nxModule from 'nxModule';

import templateUrl from './related-non-customers.template.html';
import './related-non-customers.style.less'

class CustomerProfileRelatedNonCustomers {

  constructor($scope, dict) {
    this.dict = dict;
    this.typeOptions = [];
    $scope.$watch('$ctrl.customerType', () => {
      this.setRelationshipTypeOptions();
    });
  }

  $onInit() {
    this.dict.onLoadingComplete(() => {
      this.setRelationshipTypeOptions();
    })
  }

  addRelative() {
    if (!this.relatives)
      this.relatives = [];
    this.relatives.push({
      typeId : null,
      beneficiary : false,
      dependent : false
    });
  }

  removeRelative(rel) {
    var array = this.relatives;
    var index = array.indexOf(rel);
    array.splice(index, 1);
  }

  setRelationshipTypeOptions() {
    const relationshipTypes = this.customerType ?
      this.dict.filterByAttribute('RELATIVE_TYPE', 'customerType', this.customerType, 'ANY') : [];

    this.typeOptions = this.customerType === 'CORPORATE' ?
      relationshipTypes.filter(rt => !['CO_BORROWER', 'CO_MAKER'].includes(rt.code)) : relationshipTypes;
  }
}

nxModule.component('customerProfileRelatedNonCustomers', {
  templateUrl: templateUrl,
  bindings: {
    'relatives': '=',
    'editMode': '<',
    'customerType': '<'
  },
  controller: CustomerProfileRelatedNonCustomers
});
