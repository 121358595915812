import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';
import {Subject} from 'rxjs/Subject'
import 'rxjs/add/operator/combineLatest';

import CashIcon from './cash_icon.png';
import CheckIcon from './check_icon.jpeg';
import DateIcon from './date_icon.png';
import PostingDateIcon from './posting_date_icon.png';

// copied from breadcrumbs.template.html
import systemPropertyService from '../../../../react/system/systemPropertyService';

const templateUrl = require('./top-bar.template.html');
nxModule.component('topBar', {
  templateUrl,
  controller: function ($element, $location, $filter, config, loader, confirmation, authentication, http,
                        singleUserCache, branchService, userCache, userCounterService, formattingService,
                        cashTransferCheckService, refreshService, recentlyApprovedCommandService, commandPrintService) {
    const that = this;

    that.contextBranches = [];
    that.branchContextId = null;
    that.userOnHomeBranch = authentication.context.branchId === authentication.context.userBranchId;
    that.template = null;

    that.selectizeConfig = {
      maxItems: 1,
      labelField: ['name'],
      valueField: 'id',
      sortField: 'name',
      searchField: ['name'],
      onChange: that.onBranchContextUpdate
    };

    const cashTransferSub = cashTransferCheckService.toObservable()
      .subscribe(shouldBlock => this.blockActions = shouldBlock);

    const recentlyApprovedCommandSub = recentlyApprovedCommandService.toObservable()
      .subscribe(commandDescriptor => {
        if (!commandDescriptor) {
          that.approvedCommand = null;
          return;
        }

        const displayName = $filter('translateEnum')(commandDescriptor.simpleName, 'COMMAND');
        that.approvedCommand = {
          displayName: displayName ? displayName : $filter('startCase')(commandDescriptor.type),
          ...commandDescriptor
        };
      });

    that.approvedCommandPrint = (command) => {
      commandPrintService.displayPrints(command);
    };

    that.logout = () => {
      authentication.logout();
    };

    const eraseCache = clearToken => {
      userCounterService.refresh();
      refreshService.refreshPage({ clearToken });
    }

    that.refresh = () => {
      confirmation('Do you want to perform a full page reload?', () => {
        loader.show('Reloading page...');
        authentication.refresh(() => {
          // success
          const uiCommaSeparator = systemPropertyService.getProperty('UI_CASH_COMMA_SEPARATOR');
          formattingService.setUiCommaSeparator(uiCommaSeparator === 'TRUE');
          eraseCache(false);
        }, () => {
          // error
          eraseCache(true);
        });
      });
    };

    that.onBranchContextUpdate = () => {
      if (!that.branchContextId) {
        return;
      }

      confirmation('Do you want to switch branch context (it will reload page)?', () => {
        loader.show('Reloading page...');
        switchToBranch(that.branchContextId);
      });
    };

    that.switchContextToHomeBranch = async () => {
      const proceed = await confirmation(`Do you want to switch to your home branch (it will reload the page)?`);

      if (proceed) {
        loader.show('Reloading page...');
        switchToBranch(authentication.context.userBranchId);
      }
    };

    const switchToBranch = branchId => {
      authentication.override(branchId, () => {
        console.log('Context branch switched to: ' + that.branchContextId);
        window.location.reload();
      });
    };

    const postLinkSubject = new Subject();
    that.$postLink = () => {
      postLinkSubject.next();
      postLinkSubject.complete();
    };

    const obs = singleUserCache.user(authentication.context.id).toObservable()
      .combineLatest(userCounterService.toObservable(), (user, userCounter) => {
        return Object.assign(user, {userCounter: userCounter});
      })
      .combineLatest(branchService.toObservable(), (user, branches) => {
        // Read list of context branches based on [branchIds]
        const branchIds = authentication.context.branchIds;
        that.contextBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));
        // Extend user data with branch details
        const userBranch = _.find(branches, {id: user.branchId});
        if (!that.branchContextId) that.branchContextId = authentication.context.branchId;

        const imposedBranchId = authentication.context.branchId;
        if (imposedBranchId) {
          that.imposedBranch = branches.find(b => b.id === imposedBranchId);
        }

        return Object.assign(user, {branch: userBranch, currentBranch: that.imposedBranch || userBranch});
      })
      //wait for $postLink cycle (this controller's element and its children have been linked)
      .combineLatest(postLinkSubject, (user) => user);

    const subscription = obs
      .subscribe(async user => {
        that.user = user;
        that.user.fullName = `${user.firstName} ${user.middleName || ''} ${user.lastName}`;
        that.template = that.createInfoPopup(user);

        const element = $element.find('.breadcrumb__info-details--more');
        element.popover('destroy');
        element.popover({
          html: true,
          content: that.template,
          container: '.top-bar__info-container',
          trigger: 'click',
        });
      });

    that.getLabel = value => (typeof value === 'object') ? $filter('translate')(value.value, value.type) : value;

    that.shouldTrimLabel = value => value.length > 70;

    that.createInfoPopup = (user) => {
      const templateAttrs = [];

      // Add common attributes
      templateAttrs.push(...[{
        icon: CashIcon,
        label: 'Total cash',
        value: $filter('nxCurrency')(user.userCounter.totalCash),
      }, {
        icon: CheckIcon,
        label: 'Total check',
        value: $filter('nxCurrency')(user.userCounter.totalCheck),
      }]);

      // add total ATM cash if value returned (has permission)
      if (Number.isFinite(user.userCounter.totalAtmCash)) {
        templateAttrs.push({
          icon: CashIcon,
          label: 'Total ATM cash',
          value: $filter('nxCurrency')(user.userCounter.totalAtmCash),
        });
      }

      // Add branch-related dates
      templateAttrs.push(...[{
        icon: DateIcon,
        label: 'System date',
        value: moment(user.currentBranch.systemDate).format('MM/DD/YYYY'),
      }, {
        icon: PostingDateIcon,
        label: 'Posting date',
        value: moment(user.currentBranch.postingDate).format('MM/DD/YYYY'),
      }]);

      const templateBody = templateAttrs.map(item =>
        `<div class="breadcrumb__more-popup-item">
          <img class="breadcrumb__more-popup-icon" src="${item.icon}">
          <div class="breadcrumb__more-popup-label">${item.label}:</div>
          <div class="breadcrumb__more-popup-value">${item.value}</div>
          </div>`).join('');

      const template = `
        <div class="breadcrumb__more-popup-container">
        ${templateBody}
      </div>
        `;
      return template;
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
      cashTransferSub.unsubscribe();
      recentlyApprovedCommandSub.unsubscribe();
    };
  }
})
;
