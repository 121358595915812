import nxModule from 'nxModule';

import ParameterizedLocalCache from "../../../shared/utils/parameterized-local-cache";

nxModule.factory('agentsBranchCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (branchId) => http.get(`/agents/management/details/${branchId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.agents,
    cacheName: 'agentsBranchCache'
  })
);
