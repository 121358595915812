import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';
import {incomeSourceTypes} from './constants';
import {employerTypes} from './constants';

import templateUrl from './deped-income-source.template.html';

class DepEdIncomeSource {

  constructor(dict, propertyConfigService)  {
    this.dict = dict;
    this.propertyConfigService = propertyConfigService;
  }

  $onInit() {
    this.cfg = this.propertyConfigService;
    this.types = incomeSourceTypes;
    this.employerTypes = employerTypes;
    // default employer type for DEP-ED
    this.sourceOfIncome.employerType = 'GOVERNMENT';
  }

  set monthlyIncome(newValue) {
    this.sourceOfIncome.annualIncome = new BigNumber(newValue).multipliedBy(12).toNumber();
  }

  // use getter so that ng-model binding is possible and we won't need to update income source
  get monthlyIncome() {
    const annual = this.sourceOfIncome.annualIncome;

    if(annual === undefined || annual === null) {
      return annual;
    }

    return new BigNumber(annual).div(12).toNumber();
  }
}

nxModule.component('depEdIncomeSource', {
  templateUrl,
  controller: DepEdIncomeSource,
  bindings: {
    incomeFormTitle: '<',
    editMode: '=',
    removeIncomeSource: '&',
    sourceOfIncome: '<'
  }
});