import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('schemaCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/system/schema/tables'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.schemaTables,
    cacheName: 'schemaTables'
  })
);