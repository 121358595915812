import nxModule from 'nxModule';
import systemPropertyService from '../../../../react/system/systemPropertyService';

class UserIdleCheck {
  constructor(authentication, $timeout, notification) {
    this.authentication = authentication;
    this.$timeout = $timeout;
    this.notification = notification;
    this.loggedOut = false;
    this.events = ['mousemove', 'mousedown', 'keypress', 'touchmove', 'mousewheel'];

    const idleTimePropValue = systemPropertyService.getProperty('USER_IDLE_TIME_MINUTES');
    this.userIdleTime = idleTimePropValue ? parseInt(idleTimePropValue) : 0;

    for (let event of this.events) {
      document.addEventListener(event, () => this.resetTimer(), {passive: true});
    }

    this.resetTimer();
  }

  startTimer() {
    if (this.userIdleTime !== 0 && !this.loggedOut) {
      this.timeoutP = this.$timeout(() => {
        this.notification.show('Logged out due to inactivity');
        console.info('Logged out due to inactivity');
        this.loggedOut = true;
        this.authentication.logoutAndKeepPath('Logged out due to inactivity');
      }, this.userIdleTime * 60 * 1000);  // property value in minutes
    }

  }

  resetTimer() {
    this.stopTimer();
    this.startTimer();
  }

  stopTimer() {
    if (this.timeoutP) {
      this.$timeout.cancel(this.timeoutP);
      this.timeoutP = null;
    }
  }
}

nxModule.component('userIdleCheck', {
  controller: UserIdleCheck,
});
