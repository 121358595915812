import nxModule from 'nxModule';

import templateUrl from './user-select.template.html';

class UserSelect {
  constructor($filter, $attrs) {
    this.$filter = $filter;
    this.$attrs = $attrs;
    this.options = [];
  }

  hasEmptyOption() {
    return !!this.emptyOption;
  }

  updateNgModel() {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.innerModel);
  };

  $onInit() {
    this.ngModel.$render = () => {
      this.innerModel = this.ngModel.$viewValue;
    };

    this.updateUsers();
  }

  $onChanges() {
    this.updateUsers();
  }

  updateUsers() {
    this.options = (this.users || []).filter(user => user.enabled)
      .map(user => {
        const role = user.roles.length > 0 ? this.$filter('prettyEnum')(user.roles[0].name): '';
        return {
          ...user,
          label: [user.lastName, [user.firstName, user.middleName, this.hideRole ? '' : `(${role})`].join(' ')].join(', ')
        };
      }).sort((a, b) => a.label.localeCompare(b.label));
  }
}

nxModule.component('userSelect', {
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    users: '<',
    hideRole: '<',
    emptyOption: '<'
  },
  controller: UserSelect
});
