import {
  ComplianceRedFlagConfigType,
  RED_FLAG_TAG_TYPE
} from 'components/administration/compliance/compliance-red-flag-config-types';
import {CustomerSubtype} from 'components/customer/profile/customer-profile.types';
import {CustomerType} from 'customer/CustomerTypes';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import templateUrl from './customer-type-config.template.html';
import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const isAmountBlank = (amount: ComplianceRedFlagConfigType['maxAmount']): boolean => {
  return amount === undefined || String(amount) === '' || amount === null;
};

const zeroIfBlank = (amount: ComplianceRedFlagConfigType['maxAmount']): number => {
  if (isAmountBlank(amount)) {
    return 0;
  }
  return amount || 0;
};

class CustomerTypeConfig {

  private config!: ComplianceRedFlagConfigType[];
  private complianceConfigsToDelete!: ComplianceRedFlagConfigType[];
  private customerType!: CustomerType;
  private customerSubtype!: CustomerSubtype | null;
  private redFlagTagType!: RED_FLAG_TAG_TYPE;
  private areEntriesValid: boolean = true;
  private errorMessages: string [] = [];

  constructor(private confirmationTemplate: ConfirmationTemplate) {
  }

  $onInit(): void {
    this.validateEntries();
  }

  add(): void {
    const newConfig: ComplianceRedFlagConfigType = {
      redFlagTagType: this.redFlagTagType,
      customerType: this.customerType,
      customerSubtype: this.customerSubtype,
      minAmount: 0.01,
      maxAmount: null
    };
    this.config.push(newConfig);
    this.validateEntries();
  }

  async remove(index: number): Promise<void> {
    if (!this.config[index].id) {
      this.config.splice(index, 1);
    } else {
      const confirmed = await this.confirmationTemplate({
        question: 'Deleting this bracket will affect current CIFs linked to it. Do you wish to proceed?'
      });
      if (!confirmed) {
        return;
      }
      this.complianceConfigsToDelete.push(this.config[index]);
      this.config.splice(index, 1);
    }
    this.validateEntries();
  }

  validateEntries(): void {
    this.errorMessages = [];
    const hasMinimumAmountSet = this.hasMinimumAmountSet();
    if (!hasMinimumAmountSet) {
      this.errorMessages.push('Should start at the minimum 0.01.');
    }
    const hasMaximumAmountSetToNull = this.hasMaximumAmountSetToNull();
    if (!hasMaximumAmountSetToNull) {
      this.errorMessages.push('Should end with only one blank denoting there is no limit.');
    }
    const hasUniqueBrackets = this.hasUniqueBrackets();
    if (!hasUniqueBrackets) {
      this.errorMessages.push('Duplicate brackets are not allowed.');
    }
    const hasNoGapsInBrackets = this.hasNoGapsAndOverlapsInBrackets();
    if (!hasNoGapsInBrackets) {
      this.errorMessages.push(
        'Gaps and overlaps in brackets are not allowed, difference for next bracket min amount should be 0.01');
    }
    this.areEntriesValid = hasMinimumAmountSet
      && hasMaximumAmountSetToNull
      && hasUniqueBrackets
      && hasNoGapsInBrackets;
  }

  sortByMinAmount(): void {
    this.config = this.getSortedConfig();
  }

  private getSortedConfig(): ComplianceRedFlagConfigType[] {
    return _.sortBy(this.config, c => c.minAmount);
  }

  private hasMinimumAmountSet(): boolean {
    //bracket should start from 0.01
    return this.config.some(c => c.minAmount === 0.01);
  }

  private hasMaximumAmountSetToNull(): boolean {
    //should only have one null maxAmount, should be assigned to greatest minAmount
    const highestBracket = _.maxBy<ComplianceRedFlagConfigType>(this.config, 'minAmount');
    return this.config.filter(c => isAmountBlank(c.maxAmount)).length === 1
      && !!highestBracket && isAmountBlank(highestBracket.maxAmount);
  }

  private hasUniqueBrackets(): boolean {
    //brackets should have unique min and max amounts
    return _.uniqWith(this.config, (x, y) =>
      zeroIfBlank(x.minAmount) === zeroIfBlank(y.minAmount) &&
      zeroIfBlank(x.maxAmount) === zeroIfBlank(y.maxAmount))
      .length === this.config.length;
  }

  private hasNoGapsAndOverlapsInBrackets(): boolean {
    //difference of next bracket min amount to current bracket max amount should be 0.01
    const sortedConfig = this.getSortedConfig();
    for (let i = 0; i < sortedConfig.length - 1; i++) {
      const nextMinAmount = new BigNumber(zeroIfBlank(sortedConfig[i + 1].minAmount));
      const thisMaxAmount = new BigNumber(zeroIfBlank(sortedConfig[i].maxAmount));
      if (!nextMinAmount.minus(thisMaxAmount).isEqualTo(0.01)) {
        return false;
      }
    }
    return true;
  }
}

nxModule.component('customerTypeConfig', {
  templateUrl,
  bindings: {
    config: '=',
    redFlagTagType: '<',
    customerType: '<',
    customerSubtype: '<',
    complianceConfigsToDelete: '<'
  },
  controller: CustomerTypeConfig
});