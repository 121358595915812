import {NxRouteService} from "routes/NxRouteService";
import angular from "angular-route";

export const generalLedgerRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/general-ledger/accounts', {
    template: '<gl-accounts/>',
    label: 'GL accounts'
  }], ['/general-ledger/accounts/:accountId', {
    template: '<gl-account-details/>',
    label: 'gl-account-details-label'
  }], ['/general-ledger/transactions', {
    template: '<gl-transactions/>',
    label: 'GL tickets'
  }], ['/general-ledger/transactions/:accountId/create', {
    template: '<gl-transaction-create backdated="false"/>',
    label: 'gl-transaction-create-label'
  }], ['/general-ledger/transactions/:accountId/create-interbranch', {
    template: '<gl-transaction-interbranch-create source-ledger-id="$resolve.ledgerId"/>',
    label: 'Create manual interbranch GL transaction',
    resolve: {
      ledgerId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['accountId'])
    }
  }], ['/general-ledger/transactions/:accountId/create-backdated-interbranch', {
    template: '<gl-transaction-interbranch-create backdated="true" source-ledger-id="$resolve.ledgerId"/>',
    label: 'Create backdated manual interbranch GL transaction',
    resolve: {
      ledgerId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['accountId'])
    }
  }], ['/general-ledger/transactions/:accountId/modify-interbranch/:transactionId', {
    template: '<gl-transaction-interbranch-create source-ledger-id="$resolve.sourceLedgerId" source-transaction-id="$resolve.sourceTransactionId"/>',
    label: 'Modify manual interbranch transaction',
    resolve: {
      sourceLedgerId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['accountId']),
      sourceTransactionId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['transactionId'])
    }
  }], ['/general-ledger/transactions/:accountId/create-backdated', {
    template: '<gl-transaction-create backdated="true"/>',
    label: 'Create backdated GL transaction'
  }], ['/general-ledger/transactions/:accountId/modify/:transactionId', {
    template: '<gl-transaction-create/>',
    label: 'gl-transaction-create-label'
  }], ['/general-ledger/transactions/:accountId', {
    template: '<gl-transaction-list/>',
    label: 'gl-transaction-list-label'
  }], ['/general-ledger/reports', {
    template: '<gl-reports/>'
  }]
];