import nxModule from 'nxModule';

class TransactionHistoryService {

  constructor(http, notification, confirmation, popup) {
    this.http = http;
    this.notification = notification;
    this.confirmation = confirmation;
    this.popup = popup;
  }

  getDefaultStatusLabel(status) {
    return {
      'PENDING': 'Pending approval',
      'PROCESSED': 'Processed',
      'REJECTED': 'Rejected',
      'REVERTED': 'Reverted',
      'ERROR': 'Error'
    }[status];
  };

  getStatusLabel(operation, productGroup) {
    if (operation.operationGroup === 'CLEAR_CHECK' && operation.status === 'PENDING') {
      return 'Pending clearing';
    } else {
      return this.getDefaultStatusLabel(operation.status);
    }
  };
}

nxModule.service('transactionHistoryService', TransactionHistoryService);
