import nxModule from 'nxModule';
import {Loan} from '../../service/loan.types';
import {CustomerProfile} from '../profile/customer-profile.types';
import {HttpService} from "shared/utils/httpService";

interface GroupLoanResponse {
  id: number;
  customerGroupId: number;
  centerId: number;
  officerId: number;
  name: string;
  closed: boolean;
  loanIds: number[];
  totalPrincipalAmount: number;
  totalReleaseAmount: number;
  customerId: number;
}

export interface GroupLoan {
  id: number;
  officerId?: number;
  name: string;
  closed: boolean;
  loanIds: number[];
  totalPrincipalAmount: number;
  totalReleaseAmount: number;
  customerId: number;
  individualLoans: LoanWithCustomer[]
}

export interface LoanWithCustomer extends Loan {
  customer: string;
  forRelease?: boolean;
}

export class GroupLoanService {

  constructor(private http: HttpService) { }

  async fetchGroupLoan(id: number, excludeReleased = false): Promise<GroupLoanResponse & {individualLoans : LoanWithCustomer[]}> {
    const groupLoan = await this.http.get<GroupLoanResponse>(`/products/loans/group-loan/${id}?excludeReleased=${excludeReleased}`).toPromise();

    const individualLoans = await this.http.get<Loan[]>(`/products/loans`, {
      params: {
        ids: groupLoan.loanIds
      }
    }).toPromise();

    const customerIds = new Set<number>(individualLoans.map(loan => loan.customerId));
    const customers = await this.http.get<CustomerProfile[]>('/customers/all', {
      params: {
        ids: [...customerIds]
      }
    }).toPromise();

    return {
      ...groupLoan,
      individualLoans: individualLoans.map(loan => ({
        ...loan,
        customer: customers.find(c => c.id === loan.customerId)!.effectiveName
      }))
    };
  }
}

nxModule.service('groupLoanService', GroupLoanService);
