import angular, {ILocationService} from 'angular';
import {LetterType} from 'components/administration/loan/letter-type/letter.types';
import {LoanService} from 'components/service/loan.service';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import './letter-type-list.style.less';
import templateUrl from './letter-type-list.template.html';

class LetterTypeList {
  private types: LetterType[] = [];
  private typeToEdit?: LetterType | null = null;

  constructor(private http: HttpService, private loanService: LoanService, private confirmation: Confirmation,
              private command: CommandService, private $location: ILocationService) {
  }

  async $onInit(): Promise<void> {
    this.types = await this.loanService.getLetterTypes();
  }

  getForm(index: number): unknown {
    const name = `form_${index}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this[name];
  }

  edit(index: number): void {
    this.typeToEdit = angular.copy(this.types[index]);
    this.types[index].inputMode = true;
  }

  async save(index: number): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.getForm(index).$setSubmitted();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!this.getForm(index).$valid) {
      return;
    }
    const isCreateMode = this.types[index].id === undefined || this.types[index].id === null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmed = await this.confirmation(`Do you want to ${isCreateMode ? 'create' : 'update'} this type?`);
    if (!confirmed) {
      return;
    }

    const commandName = isCreateMode ? 'CreateLetterType' : 'UpdateLetterType';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await this.command.execute<unknown, {id: number}>(commandName, this.types[index]).toPromise();
    if (response && response.output) {
      this.types[index].id = isCreateMode ? response.output.id : this.types[index].id;
    }
    this.types[index].inputMode = false;
  }

  cancel(index: number): void {
    if (this.types[index].id) {
      this.types[index] = this.typeToEdit ?? this.types[index];
      this.types[index].inputMode = false;
    } else {
      this.types.pop();
    }
  }

  async remove(index: number): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmed = await this.confirmation("Do you want to remove this type?");
    if (!confirmed) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await this.command.execute('DeleteLetterType', this.types[index].id).toPromise();
    if (response) {
      this.types.splice(index, 1);
    }
  }

  add(): void {
    this.types.push({
      name: '',
      description: '',
      inputMode: true
    });
  }


  private goBack(): void {
    this.$location.path('/admin/loan-products');
  }

}

nxModule.component('letterTypeList', {
  templateUrl,
  controller: LetterTypeList
});
