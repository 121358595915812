import {pluck} from 'rxjs/operators';
import {Observable} from "rxjs";
import {StatusService} from "./status.service";

/**
 * Instantiate this class to make use of StatusService.
 *
 * Example:
 * new StatusBasedService({
 *   property: 'informationMessage',
 *   statusService: statusService,
 *   optional: true
 * })
 */
export default class StatusBasedService<T> {
  private property: string;
  private statusService: StatusService;
  /**
   * @param property - property of status bundle, which contains object that we want to fetch with instance of this service.
   * @param statusService - reference to StatusService instance
   */
  constructor({property, statusService}: {property: string, statusService: StatusService}) {
    this.property = property;
    this.statusService = statusService;
  }

  /**
   * @returns Observable with given part of status bundle or default value if defined
   */
  toObservable(): Observable<T> {
    return this.statusService.getStatusObs().pipe(
      pluck(this.property)
    );
  }

  toPromise(): Promise<T> {
    return this.toObservable()
      .first()
      .toPromise();
  }

  /**
   * Refetch status bundle from server
   */
  refresh(): void {
    this.statusService.refresh();
  }
}