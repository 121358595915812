import nxModule from "nxModule";
import templateUrl from "./loan-letters.template.html";
import {Loan} from "components/service/loan.types";
import {Customer} from "components/service/center/center.types";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";
import {ILocationService} from "angular";
import {HttpService} from "shared/utils/httpService";
import {Letter, LetterType} from "components/administration/loan/letter-type/letter.types";
import {LoanService} from "components/service/loan.service";
import {CustomerCache} from "components/service/customer.cache.types";

class LoanLetters {
  private loan!: Loan;
  private customer!: Customer;
  private allCustomers: Customer[] = [];
  private letters: LetterType[] = [];
  private loanLetters: Letter[] = [];
  private selectedLetter?: number;
  private selectedCustomerId?: number;

  constructor(private customerCache: CustomerCache,
              private modalPrintPreviewService: ModalPrintPreviewService,
              private $location: ILocationService,
              private http: HttpService,
              private loanService: LoanService) {
  }

  async $onInit() {
    const comarkerId: number[] = this.loan.coMakers.map(c => c.customerId);
    const [letterTypes, comakers]: [LetterType[], Customer[]] = await Promise.all([
      this.loanService.getLetterTypes(),
      this.http.get<Customer[]>('/customers/all?ids=' + comarkerId.join(',')).toPromise()
    ]);
    this.letters = letterTypes.filter(t => this.loanLetters.some(l => l.typeId === t.id));
    this.selectedCustomerId = this.customer.id;
    this.allCustomers.push(this.customer, ...comakers);
  }

  async displayPrint(): Promise<void> {
    const input = {
      loanId: this.loan.id,
      targetCustomerId: this.selectedCustomerId,
      letterTypeId: this.selectedLetter
    }

    await this.modalPrintPreviewService.showAsync({
      printDescription: {
        code: 'LOAN_COLLECTION_LETTER',
        parameters: {
          'LETTER_TYPE': this.selectedLetter,
          'LETTER_BORROWER_TYPE': this.isComaker(this.selectedCustomerId!) ? 'COMAKER' : 'PRIMARY'
        }
      },
      printProviderInput: {
        inputValues: input
      }
    });
  }

  isComaker(customerId: number): boolean {
    return this.loan.coMakers.some(c => c.customerId === customerId);
  }

  close(): void {
    this.$location.path(`/customer/${this.customer.id}/loans/${this.loan.id}`);
  };
}

nxModule.component('loanLetters', {
  templateUrl,
  bindings: {
    loan: '<',
    customer: '<',
    loanLetters: '<'
  },
  controller: LoanLetters
});
