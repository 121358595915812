// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createIndentStyle = (Quill: Quill)  => {
  const Parchment = Quill.import('parchment');

  class IndentAttributor extends Parchment.Attributor.Style {
    add (node: unknown, value: unknown) {
      if (value === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.remove(node);
        return true;
      } else {
        return super.add(node, `${value}em`);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const IndentStyle = new IndentAttributor('indent', 'text-indent', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
  });

  return IndentStyle;
};
