import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './transfer-to-product.template.html';

class TransferToProduct {
  constructor($location, $filter, http, customerCache, confirmation, command, productDefinitionService) {
    this.$location = $location;
    this.$filter = $filter;
    this.http = http;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.command = command;
    this.productDefinitionService = productDefinitionService;

    this.input = {};
  }

  async $onInit() {
    const productGroupVariables = {
      'DEPOSIT': {
        command: 'DepositWithdrawalTransferToProduct',
        print: 'TERM_DEPOSIT',
        redirect: 'term-deposits'
    },
      'ACCOUNT': {
        command: 'AccountTransferToProduct',
        print: 'DEPOSIT_ACCOUNT',
        redirect: 'accounts'
    }};
    this.productVariables = productGroupVariables[this.productGroup];
    this.productTypes = await this.productDefinitionService.toPromise();

    let products = [];
    if(this.productGroup === 'ACCOUNT') {
      products = await this.customerCache.depositAccounts(this.customerId).toPromise();
    } else {
      products = await this.customerCache.termDeposits(this.customerId).toPromise();
    }

    this.product = products.find(a => a.id === this.productId);
  }

  async readProductId() {
    this.input.productId =  null;

    if (!this.productNumber) {
      return;
    }

    const data = await this.http.post(`/products/search`, {
      productNumber: [this.productNumber],
      exactProductNumber: true
    }, {nxLoaderText: 'Searching for product...'}).toPromise();

    if (!data.result || data.result.length === 0) {
      this.cause = " - cannot find product";
    } else if (data.result.length > 1) {
      this.cause = " - product number ambiguous"
    } else {
      // Exactly one search result in data
      const product = data.result[0];
      this.productDefinition = _.find(this.productTypes, {id: product.definitionId});
      if (this.productDefinition.productGroup === 'LOAN') {
        this.cause = " - cannot transfer to Loan"
      } else {
        this.cause = null;
        this.input.productId = product.id;
      }
    }
  }

  async execute() {
    let message = `Do you want to transfer ${this.$filter('nxCurrency')(this.input.amount)} to product ${this.productNumber}?`;
    if (this.productGroup === 'DEPOSIT') {
      message = message + `<br><br><strong>This operation will rollover the deposit and cannot be reverted safely.
       Please make sure that the data is correct.</strong>`
    }
    const confirm = await this.confirmation(message, true);
    if (!confirm) {
      return;
    }

    const command = await this.command.execute(this.productVariables.command, {
      productId: this.productId,
      targetProductId: this.input.productId,
      amount: this.input.amount,
      remarks: this.input.remarks,
      entryType: 'DEBIT'
    }, {nxLoaderText: 'Transferring'}).toPromise();

    this.customerCache.depositAccounts(this.customerId).refetch();
    this.customerCache.termDeposits(this.customerId).refetch();
    this.redirectBack();
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/${this.productVariables.redirect}/${this.productId}`);
  }
}

nxModule.component('transferToProduct', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    productId: '<',
    productGroup: '<'
  },
  controller: TransferToProduct
});
