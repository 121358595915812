import {IController, ILocationService, IScope} from 'angular';

import nxModule from 'nxModule';
import {DiscountChargesLedger, Loan} from '../../../service/loan.types';
import templateUrl from './recreate-discount-charges-ledger.template.html';

import './recreate-discount-charges-ledger.style.less';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import {sum} from 'shared/common/MathUtils';
import {Fee} from '../../../service/product.types';
import {FeeDefinition} from "components/service/fees/fee.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {LoanService} from "components/service/loan.service";
import FeeDefinitionCache from "components/service/fee-definition.cache";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CustomerCache} from "components/service/customer.cache.types";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";

interface AccretionFeeInput extends Fee {
  currentAmount: number;
  overrideAmount: number;
}

interface Request {
  loanId: number;
  accretedFeesOverride: AccretionFeeInput[];
}

type TotalCalculationMode = 'CURRENT' | 'OVERRIDDEN';

class RecreateDiscountChargesLedger implements IController {

  private loan!: Loan;
  private customerId!: number;

  private accretedFees!: AccretionFeeInput[];
  protected forAccretionFeesConfigured!: boolean;
  protected simulatedDiscountChargesLedger?: DiscountChargesLedger;
  protected hasNoSimulatedLedger: boolean = false;

  constructor(private readonly command: CommandService,
              private readonly $location: ILocationService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly customerCache: CustomerCache,
              private readonly $filter: NxIFilterService,
              private readonly http: HttpService,
              private readonly $scope: IScope,
              private readonly loanService: LoanService,
              private readonly feeDefinitionsCache: FeeDefinitionCache) {
  }

  async $onInit(): Promise<void> {
    const loanId = this.loan.id;

    const [feeDefinitions, accretedFees]: [FeeDefinition[], Fee[]] = await Promise.all([
      this.feeDefinitionsCache.toPromise(),
      this.loanService.getAccretedFees(loanId, true)
    ]);

    this.accretedFees = RecreateDiscountChargesLedger.overrideFeesAmounts(accretedFees);
    this.forAccretionFeesConfigured = feeDefinitions.filter(f => f.productDefinitionId == this.loan.definitionId)
      .filter(f => f.enabled)
      .some(f => f.forAccretion);
  }

  /**
   * In case fee is new (not included in existing ledger or the ledger did not exist at all),
   * the current amount of the fee will be 0. Otherwise it will equal fee amount.
   */
  private static overrideFeesAmounts(accretedFees: Fee[]): AccretionFeeInput[] {
    return accretedFees.map(f => ({
      ...f,
      overrideAmount: f.amount,
      currentAmount: f.id ? f.amount : 0,
    }));
  }

  async save(): Promise<void> {
    const details = this.accretedFees
      .filter(fee => fee.overrideAmount != null)
      .map(fee => {
        return {
          label: fee.feeName!,
          description: this.$filter('nxCurrency')(fee.overrideAmount)
        };
      });

    const totalAccretedAmount = sum(this.accretedFees.map(fee => fee.overrideAmount != null ? fee.overrideAmount : fee.amount)).toNumber();
    const proceed = await this.confirmationTemplate({
      question: `Discount charges ledger will be recreated for loan ${this.loan.productNumber}. Do you wish to proceed?`,
      details: [
        {label: 'New accreted amount', description: this.$filter('nxCurrency')(totalAccretedAmount)},
        ...details
      ]
    });

    if (!proceed) {
      return;
    }

    const request: Request = this.createRequest();
    const resp = await this.command.execute('RecreateDiscountChargesLedger', request).toPromise();
    if (!resp.approvalRequired) {
      this.customerCache.loans(this.customerId).refetch();
      this.redirectBack();
    }
  }

  hasAnyFeeChanged(): boolean {
    return this.accretedFees?.map(f => this.calculateDiff(f)).some(diff => diff !== 0);
  }

  isTotalOverriddenAmountZero(): boolean {
    return this.accretedFees?.every(f => f.overrideAmount === 0);
  }

  async simulateDiscountCharges(): Promise<void> {
    const request: Request = this.createRequest();
    this.simulatedDiscountChargesLedger = await this.http.post<DiscountChargesLedger>('/products/loans/simulate/discount-charges', request).toPromise();
    this.hasNoSimulatedLedger = !this.simulatedDiscountChargesLedger && this.isTotalOverriddenAmountZero() && this.hasAnyFeeChanged();
  }

  canPerformSimulation(): boolean {
    return this.hasAnyFeeChanged() || !this.isTotalOverriddenAmountZero();
  }

  createRequest(): Request {
    const accretedFeesOverride: AccretionFeeInput[] = _.cloneDeep(this.accretedFees);

    return {
      loanId: this.loan.id,
      accretedFeesOverride: accretedFeesOverride
    };
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  calculateDiff(fee: AccretionFeeInput): number {
    const overrideAmount = new BigNumber(fee.overrideAmount);
    const currentAmount = new BigNumber(fee.currentAmount);
    return overrideAmount.minus(currentAmount).toNumber();
  }

  hasNewlyCalculatedFees(): boolean {
    return this.accretedFees && this.accretedFees.some(fee => !fee.id);
  }

  calculateTotal(mode: TotalCalculationMode): number {
    if (!this.accretedFees) {
      return 0;
    }

    switch (mode) {
      case 'CURRENT':
        return sum(this.accretedFees.map(fee => fee.currentAmount)).toNumber();
      case 'OVERRIDDEN':
        return sum(this.accretedFees.map(fee => fee.overrideAmount)).toNumber();
      default:
        console.warn('Unknown mode provided', mode);
        return 0;
    }
  }
}

nxModule.component('recreateDiscountChargesLedger', {
  templateUrl,
  controller: RecreateDiscountChargesLedger,
  bindings: {
    loan: '<',
    customerId: '<'
  }
});
