import {OperationGroup} from 'components/general-ledger/common/gl.types';
import {ProductHook, ProductStatus} from "../product.types";
import {RoundingScale} from "../loan-type.types";
import {PaymentInterval} from "components/common/charge/pledge-deposit-deviation-interval-fee-board.component";

/**
 * Initial version of FeeClass. Other FeeClass values can be added here when needed.
 */
export type FeeClass = 'CUSTOM' | 'DOC_STAMP' | 'CBU' | 'PF' | 'TP' | 'WITHHOLDING_TAX' | 'VAT' | 'DORMANCY' | 'ACTIVATION'
  | 'CLOSING' | 'MAINTAINING' | 'UNFUNDED_SERVICE_CHARGE' | 'FIXED_PENALTY_PER_CHECK' | 'VARIABLE_PENALTY_PER_CHECK'
  | 'PRESENTING_BANK_CHARGE_ON_RETURN' | 'BANK_CHARGE_ON_SPO' | 'BANK_CHARGE_ON_DAUD' | 'SPO_PLACEMENT_CHARGE'
  | 'AGENT_BALANCE_INQUIRY' | 'AGENT_DEPOSIT' | 'AGENT_WITHDRAWAL' | 'AGENT_TOP_UP' | 'AGENT_BILL' | 'AGENT_PREPAID'
  | 'ATM_BALANCE_INQUIRY' | 'ATM_WITHDRAWAL' | 'ATM_INTER_BANK_FUND_TRANSFER' | 'ATM_TRANSACTION_FEE' | 'TRANSACTION_LIMIT'
  | 'NOTARIAL_FEE' | 'APPLICATION_FEE' | 'CREDIT_INVESTIGATION_FEE' | 'SERVICE_CHARGE' | 'PRETERMINATION_FEE'
  | 'EXTRA_BANK_FEE' | 'MEMBERSHIP_FEE' | 'ID_FEE' | 'PENALTY' | 'PAST_DUE_INTEREST' | 'INSURANCE_FEE' | 'INSURANCE_SERVICE_FEE'
  | 'INSURANCE_PROCESSING_FEE' | 'SELF_TRANSFER' | 'INTERNAL_TRANSFER' | 'INTERBRANCH_RENEWAL_CHARGE';

type FeeOverdraftStrategy = 'ERROR' | 'SKIP' | 'DRAIN' | 'OVERDRAFT';

export type CalculationMethod =
  'FIXED_AMOUNT'
  | 'FORMULA'
  | 'PERCENTAGE_OF_OPERATION'
  | 'PERCENTAGE_BASED_ON_YEAR_TERM'
  | 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'
  | 'PERCENTAGE_OF_ORIGINAL_INTERESTS'
  | 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL'
  | 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR'
  | 'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL'
  | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS'
  | 'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL'
  | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL'
  | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES'
  | 'PERCENTAGE_OF_INSURANCE_FEE'
  | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS'
  | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL'
  | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL'
  | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS'
  | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL'
  | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL'
  | 'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE';

export type TransactionLimitPeriod = 'MONTH' | 'QUARTER' | 'YEAR';

export type FeeType = 'FIXED' | 'PERCENTAGE' | 'ARBITRARY' | 'FORMULA';

export interface FeePredicate {
  value: number | string;
  compareMode: string;
  type: string;
}

export type PledgeDepositDeviationIntervalFeeBoardCellType = {
  paymentInterval: PaymentInterval | null,
  fixedAmount: number,
  percentageAmount: number
}

export type PledgeDepositDeviationIntervalFeeBoardType = {
  defaultFixedAmount: number,
  defaultPercentageAmount: number,
  cells: PledgeDepositDeviationIntervalFeeBoardCellType[]
}

export type TransactionLimitConfigType = {
  limit: number,
  period: TransactionLimitPeriod,
  amount: number,
  restrictedCommands: any[];
}

export type AmortizedFeeConfigType = {
  spreadEvery: number | null,
  spreadPercentage: number | null,
  applyFeeFor: number | null,
  diminishingAmortizationNumber: number | null
}

export type FeeExtraOptions = {
  PLEDGE_DEPOSIT_DEVIATION_INTERVAL_FEE_BOARD: PledgeDepositDeviationIntervalFeeBoardType,
  TRANSACTION_LIMIT_CONFIG: TransactionLimitConfigType,
  PENALTY_DAYS_CALCULATION_TYPE: 'DAYS_LATE' | 'STATUS_CHANGE',
  AMORTIZED_FEE_CONFIG: AmortizedFeeConfigType,
  LOAN_BOARD: LoanBoard
};

export interface FeeDefinition {
  id: number;
  feeClass: FeeClass;
  overdraftStrategy: FeeOverdraftStrategy;
  feeType: FeeType;
  feeName: string;
  feeAlias?: string;
  calculationOrder: number;
  productDefinitionId: number;
  calculateOnStatuses: ProductStatus[];
  applyOnStatuses: ProductStatus[];
  operationGroup: OperationGroup;
  calculationMethod: CalculationMethod;
  fixedAmount?: number;
  percentageAmount?: number;
  formula?: string;
  forAccretion: boolean;
  includedInEirComputation: boolean;
  calculateOn?: ProductHook;
  applyOn: ProductHook;
  roundingScale: RoundingScale;
  extraOptions: FeeExtraOptions;
  displayOnProductCreation: boolean;
  predicates: FeePredicate[];
  enabled: boolean;
  bankExpense: boolean;
  amortizedFeeConfig?: FeeExtraOptions;
}

export type FeeCollectionType = 'FULL' | 'PROPORTIONAL_TO_TERM' | 'PROPORTIONAL_TO_DAYS_DELAYED';

export const feeCollectionScheduleOptions = Object.freeze([
  'DUE_DATE',
  'MONTH_END'
]);

export interface LoanBoard {
  defaultFixedAmount: number;
  defaultPercentageAmount: number;
  cells: LoanBoardCell[];
}

export interface LoanBoardCell {
  rateTypeId: number;
  minTerm: number;
  maxTerm?: number;
  minPrincipal: number;
  maxPrincipal?: number;
  fixedAmount?: number;
  percentageAmount?: number;
  minAmount: number;
  maxAmount?: number;
  minCustomerAge?: number;
  maxCustomerAge?: number;
  exemptionAmount?: number;
  paymentIntervals?: number[];
  productStatuses?: ProductStatus[];
  collectionType: FeeCollectionType;
  calculationMethod: CalculationMethod;
}

export interface ProductAndFeeDefintion extends FeeDefinition {
  productId: number;
}

export interface FeeValue {
  fixedAmount?: number;
  percentageAmount?: number;
  collectionType: FeeCollectionType;
  calculationMethod: CalculationMethod;
  minAmount: number;
  maxAmount?: number;
  exemptionAmount?: number;
}
