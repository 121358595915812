import nxModule from 'nxModule';
import templateUrl from './multiselect-null-wrapper.template.html';

class MultiselectNullWrapper {

  constructor() {
    this.internalModel = null;
  }

  $onInit() {
    this.ngModelCtrl.$render = () => {
      const allValues = this.getAllValues();
      const externalValue = this.ngModelCtrl.$modelValue || [];
      this.internalModel = this.isAllSelected(externalValue, allValues) ? allValues : externalValue;
    };
  }

  getLabelProperty() {
    return this.labelProperty || 'label';
  }

  getValueProperty() {
    return this.valueProperty || 'value';
  }

  getAllValues() {
    return this.items.map(item => item[this.getValueProperty()]);
  }

  isAllSelected(values, availableValues) {
    return (values.length === 1 && values[0] === null) || availableValues.length === values.length;
  }

  onInternalModelChange() {
    const availableValues = this.getAllValues();

    if (this.isAllSelected(this.internalModel, availableValues)) {
      this.ngModelCtrl.$setViewValue([null]);
    } else {
      this.ngModelCtrl.$setViewValue(this.internalModel);
    }
  }

}

nxModule.component('multiselectNullWrapper', {
  templateUrl,
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    items: '<',
    ngDisabled: '<',
    ngRequired: '<',
    labelProperty: '<',
    valueProperty: '<'
  },
  controller: MultiselectNullWrapper
});