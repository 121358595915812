import Accounts from 'routes/Accounts';
import Organization from 'routes/Administration';
import CustomerOperations from 'routes/CustomerOperations';
import DashboardMiscellaneousTransactions from 'routes/DashboardMiscellaneousTransactions';
import Deposits from 'routes/Deposits';
import Loan from 'routes/Loan';
import Check from './Check';
import CheckPreparation from './CheckPreparation';
import Support from './Support';

/**
 * Aggregation of all routes in the application
 */
export default [
  ...DashboardMiscellaneousTransactions,
  ...CustomerOperations,
  ...CheckPreparation,
  ...Accounts,
  ...Deposits,
  ...Loan,
  ...Organization,
  ...Check,
  ...Support
];
