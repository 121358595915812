import nxModule from 'nxModule';

const templateUrl = require('./form-section-field.template.html');

nxModule.component('formSectionField', {
  templateUrl,
  transclude: true,
  bindings: {
    fieldTitle: '<',
    autoWidthTitle: '<',
    fitContent: '<'
  }
});
