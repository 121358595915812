import {branchResolver} from "routes/resolvers";
import angular from "angular-route";

export const checkPreparationRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/check-preparation/assignment', {
    template: '<check-assignment-branch-list/>',
    label: 'Check assignment'
  }],
  ['/check-preparation/assignment/:branchId', {
    template: '<check-assignment branch="$resolve.branch"/>',
    label: 'Check assignment',
    resolve: {
      ...branchResolver,
    }
  }]
];