import nxModule from 'nxModule';
import moment from 'moment';

import templateUrl from './date.template.html';
import './date.component.less';

const MAX_DATE = '9999-12-31';

class DateWrapper {
  constructor($attrs, systemDateService) {
    this.$attrs = $attrs;
    this.systemDateService = systemDateService;
    this.dateFormat = 'YYYY-MM-DD';
  }

  // eg. '2018-12-12T08:15:00+0800' -> '2018-12-12T00:00:00+0800'
  static trimTimeFromDate(date) {
    if (!date) return null;
    const inputMoment = moment(date);
    return new Date(inputMoment.year(), inputMoment.month(), inputMoment.date());
  }

  // eg. '2018-12-12T08:15:00+0800' -> '2018-12-12T00:00:00Z' (UTC)
  static trimTimeWithTimeZone(date) {
    if (!date) return null;
    const inputMoment = moment(date);
    return new Date(Date.UTC(inputMoment.year(), inputMoment.month(), inputMoment.date()))
  }

  // eg. '2018-12-12T08:15:00+0800' -> '2018-12-12'
  toStringDate(date) {
    return date ? moment(date).format(this.dateFormat) : null;
  }

  $onInit() {
    this.ngModel.$validators.min = (modelValue) => {
      if (!modelValue || !this.min) return true;
      const normalizedMin = DateWrapper.trimTimeFromDate(this.min);
      const normalizedModel = DateWrapper.trimTimeFromDate(modelValue);
      return moment(normalizedModel).isSameOrAfter(normalizedMin);
    };

    this.ngModel.$validators.max = (modelValue) => {
      if (!modelValue) return true;
      const effectiveMax = this.max ? this.max : MAX_DATE;
      const normalizedMax = DateWrapper.trimTimeFromDate(effectiveMax);
      const normalizedModel = DateWrapper.trimTimeFromDate(modelValue);
      return moment(normalizedModel).isSameOrBefore(normalizedMax);
    };

    this.ngModel.$parsers.push(viewValue => {
      return this.convertDateFormat(viewValue);
    });

    this.ngModel.$formatters.push(modelValue => {
      return DateWrapper.trimTimeFromDate(modelValue);
    });

    this.ngModel.$render = () => {
      if (this.ngModel.$modelValue || this.initWithNull) {
        this.syncDateModel();
      } else {
        this.populateWithSystemDate();
      }
    };
  }

  getType() {
    return this.type || 'date';
  }

  async populateWithSystemDate() {
    const defaultDate = await this.systemDateService.getCurrentUserBranchSystemDate();

    const offset = parseInt(this.defaultDateOffset);
    if (offset) {
      defaultDate.add(offset, 'days');
    }

    this.dateModel = DateWrapper.trimTimeFromDate(defaultDate);
    this.ngModel.$setViewValue(this.dateModel);

    this.validateNgModel();
  }

  convertDateFormat(date) {
    if (!date) return null;

    let formattedDate = null;
    switch (this.outputFormat) {

      // Previously directive called format-date
      case 'date':
        formattedDate = DateWrapper.trimTimeWithTimeZone(date);
        break;

      // Previously directive called string-date
      case 'local-date':
      default:
        // default to local-date
        formattedDate = this.toStringDate(date);
    }

    return formattedDate;
  }

  $onChanges() {
    this.refreshMinMaxBoundaries();
    this.syncNgModel();
    this.validateNgModel();
  }

  refreshMinMaxBoundaries() {
    if (typeof this.min === 'object') {
      this.minBoundary = this.toStringDate(this.min);
    } else {
      this.minBoundary = this.min;
    }

    if (typeof this.max === 'object') {
      this.maxBoundary = this.toStringDate(this.max);
    } else {
      this.maxBoundary = this.max ? this.max : MAX_DATE;
    }
  }

  onBlur() {
    if (this.ngModel.$modelValue) {
      this.dateModel = DateWrapper.trimTimeFromDate(this.ngModel.$viewValue);
    }
  }

  validateNgModel() {
    if(this.ngModel) {

      // Just a little hack - prevents situation that ngModel has viewValue set, modelValue undefined and is marked as valid
      this.ngModel.$setValidity('min',false);
      this.ngModel.$setValidity('max',false);

      this.ngModel.$validate();
    }
  }

  syncNgModel() {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.dateModel);
    }
  }

  syncDateModel() {
    this.dateModel = DateWrapper.trimTimeFromDate(this.ngModel.$modelValue);
    this.ngModel.$setViewValue(this.dateModel);
    this.validateNgModel();
  }
}

/**
 * Wraps input type="date". Provides formatting output and fallback if ngModel is undefined/null.
 *
 * This component supports two types of date formats (look at outputFormat param):
 * - string-format - default (and strongly RECOMMENDED) format, example: '2018-09-11'
 * - date - example: '2017-12-08T00:00:00.000Z', standard js Date type, be careful with that format,
 *          because it is returned in UTC timezone (therefore you may end up with different date
 *          after conversion to your timezone i.e. '2017-12-07T22:00:00.000-02:00' in case given above)
 *
 *
 * @param min - optional - string date format. Triggers native html and ngModelController validation.
 *              May be timestamp or string (String must be passed in single quotes i.e. min="'2018-07-07'")
 *
 * @param max - optional - string date format. Triggers native html and ngModelController validation.
 *              May be timestamp or string (String must be passed in single quotes i.e. min="'2018-07-07'")
 *
 * @param initWithNull - optional - if true and ng-model is undefined then date component is initialized with null,
 *                       otherwise system date of current branch is fetched and set as an initial value.
 *
 * @param defaultDateOffset - optional - number of the days to shift default date.
 *                            Given default date '2017-12-08':
 *                              - for defaultDateOffset of "1" the actual date is gonna be '2017-12-09'
 *                              - for defaultDateOffset of "0" (or not specified) the actual date is gonna be unchanged - '2017-12-08'
 *                              - for defaultDateOffset of "-1" the actual date is gonna be '2017-12-07'
 *
 * @param outputFormat - optional - string which determine output format. Default output is local-date format.
 */
nxModule.component('date', {
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    min: '<',
    max: '<',
    initWithNull: '<',
    defaultDateOffset: '@',
    outputFormat: '@',
    type: '@'
  },
  templateUrl,
  controller: DateWrapper
});
