import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './edit-account-signature-card.template.html';
import {Product} from '../../../service/product.types';
import {ILocationService} from "angular";
import {CustomerCache} from "components/service/customer.cache.types";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from "shared/utils/command/command.types";

class CustomerAccountEditSignatureCard {
  customerId!: number;
  accountId!: number;
  account?: Product & { signatureCardFileId?: number };
  signatureCardFiles: {id: number}[] = [];

  constructor(readonly $location: ILocationService,
              readonly customerCache: CustomerCache,
              readonly confirmation: Confirmation,
              readonly command: CommandService) {
  }

  async $onInit(): Promise<void> {
    const accounts = await this.customerCache.depositAccounts(this.customerId).toPromise();
    this.account = _.find(accounts, {id: Number(this.accountId)})!;
    this.signatureCardFiles = this.account.signatureCardFileId ? [{id: this.account.signatureCardFileId}] : [];
  }

  redirect(): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}`);
  }

  redirectBack(): void {
    this.confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
      this.redirect();
    });
  }

  async update(): Promise<void> {
    const confirmed = await this.confirmation(`Do you want to update the "${this.account?.productNumber}" account?`);

    if (!confirmed) {
      return;
    }

    const commandInput = {
      productId: this.account?.id,
      signatureCardFileId: (this.signatureCardFiles || []).map(f => f.id)[0]
    };

    this.command.execute('UpdateAccountSignatureCard', commandInput, {nxLoaderText: 'Processing operation...'}).success(() => {
      this.customerCache.depositAccounts(this.customerId).refetch();
      this.redirect();
    });
  }
}

nxModule.component('customerAccountEditSignatureCard', {
  templateUrl,
  controller: CustomerAccountEditSignatureCard,
  bindings: {
    customerId: '<',
    accountId: '<'
  }
});