import nxModule from 'nxModule';
import moment from "moment";

export class RevertCommandService {

  constructor(commandMetadataCache) {
    commandMetadataCache.toObservable()
      .subscribe(commandMetaData => this.commandMetaData = commandMetaData);

    this.revertCommands = {
      'DEPOSIT_CASH': "PayLoanByCashRevert",
      'DEPOSIT_CHECK': "PayLoanByCheckRevert",
      'DEPOSIT_CUTOFF_CHECK': "PayLoanByCheckRevert",
      'CREDIT_MEMO': "PayLoanByGLRevert",
      'TRANSFER_FUNDS': "PayLoanByTransferRevert",
      'CREDIT_CHECK_ON_US': "PayLoanByOnUsCheckRevert",
      'CREDIT_POST_DATED_CHECK_ON_US': "PayLoanByPostDatedOnUsCheckRevert"
    };

    // Map for LoanHistoryEntries - new transaction history
    this.revertEntryCommands = {
      'CASH_PAYMENT': "PayLoanByCashRevert",
      'CHECK_PAYMENT': 'PayLoanByCheckRevert',
      'LATE_CHECK_PAYMENT': 'PayLoanByCheckRevert',
      'GL_PAYMENT': "PayLoanByGLRevert",
      'DEP_ED_GL_PAYMENT': "PayLoanByGLRevert",
      'TRANSFER_PAYMENT': "PayLoanByTransferRevert",
      'ATA_TRANSFER_PAYMENT': "PayLoanByTransferRevert",
      'ON_US_CHECK_PAYMENT': "PayLoanByOnUsCheckRevert",
      'POST_DATED_ON_US_CHECK_PAYMENT': "PayLoanByPostDatedOnUsCheckRevert",
      'ADA_TRANSFER_PAYMENT': "PayLoanByAutomaticDebitRevert"
    };

    this.revertToCustomGLCommands = {
      'DEPOSIT_CASH': "PayLoanByCashRevertToCustomGl",
      'DEPOSIT_CHECK': "PayLoanByCheckRevertToCustomGl",
      'DEPOSIT_CUTOFF_CHECK': "PayLoanByCheckRevertToCustomGl",
      'CREDIT_MEMO': "PayLoanByGLRevertToCustomGl",
      'TRANSFER_FUNDS': "PayLoanByTransferRevertToCustomGl",
      'ADA_TRANSFER_FUNDS': "PayLoanByAutomaticDebitRevertToCustomGl",
      'CREDIT_CHECK_ON_US': "PayLoanByOnUsCheckRevertToCustomGl",
      'CREDIT_POST_DATED_CHECK_ON_US': "PayLoanByPostDatedOnUsCheckRevertToCustomGl"
    };

    // Map for LoanHistoryEntries - new transaction history
    this.revertEntryToCustomGLCommands = {
      'CASH_PAYMENT': "PayLoanByCashRevertToCustomGl",
      'CHECK_PAYMENT': 'PayLoanByCheckRevertToCustomGl',
      'LATE_CHECK_PAYMENT': 'PayLoanByCheckRevertToCustomGl',
      'GL_PAYMENT': "PayLoanByGLRevertToCustomGl",
      'DEP_ED_GL_PAYMENT': "PayLoanByGLRevertToCustomGl",
      'TRANSFER_PAYMENT': "PayLoanByTransferRevertToCustomGl",
      'ATA_TRANSFER_PAYMENT': "PayLoanByTransferRevertToCustomGl",
      'ADA_TRANSFER_PAYMENT': "PayLoanByAutomaticDebitRevertToCustomGl",
      'ON_US_CHECK_PAYMENT': "PayLoanByOnUsCheckRevertToCustomGl",
      'POST_DATED_ON_US_CHECK_PAYMENT': "PayLoanByPostDatedOnUsCheckRevertToCustomGl"
    };
  }

  /**
   * Operation must contain additional field with branch working day before validating with revertAllowed.
   * This filed is not there initially and must be added before calling the service. See transaction-history component.
   * */
  revertAllowed(operation, commandName) {

    if (!operation || !operation.commandId) {
      return false;
    }

    if (!operation.branchWorkingDay) {
      throw "No branchWorkingDay was passed in operation"
    }

    if (operation.status === 'REVERTED') {
      return false;
    }

    const workingDay = operation.branchWorkingDay;
    let branchDate = moment(workingDay.systemDate).format('YYYY-MM-DD');
    // Legend: operation -> registrationTime, loan history entry -> systemTime
    const operationDate = moment(operation.registrationTime || operation.systemTime).format('YYYY-MM-DD');

    let skipSameDayValidation = false;

    // commandName can be undefined
    if(commandName) {
       const metadata = this.commandMetaData.find(command => command.alias === commandName);
       skipSameDayValidation = metadata ? metadata.skipSameDayValidation : false;
    }

    if (branchDate !== operationDate && !skipSameDayValidation) {
      return false;
    }

    return true;
  }

  revertCommandName(operation, purpose) {
    let operationGroupToCommandMap = null;
    switch (purpose) {
      case "LOAN_CUSTOM_GL_REVERT":
        operationGroupToCommandMap = this.revertToCustomGLCommands;
        break;
      case "LOAN_PAYMENT_REVERT":
        operationGroupToCommandMap = this.revertCommands;
        break;
      default:
        throw `Unknown revert type`;
    }

    // Special cases where operation group is not enough
    if (operation.operationGroup === 'TRANSFER_FUNDS') {
      if (operation.targetCode.includes("CREDIT_CHECK_ON_US")) {
        if (operation.attributes['LOAN_PDC_ID'] && !isNaN(operation.attributes['LOAN_PDC_ID'])) {
          return operationGroupToCommandMap['CREDIT_POST_DATED_CHECK_ON_US'];
        }
        return operationGroupToCommandMap['CREDIT_CHECK_ON_US'];
      }
      if (operation.attributes['AUTOMATIC_DEBIT']) {
        return operationGroupToCommandMap['ADA_TRANSFER_FUNDS'];
      }
    }
    return operationGroupToCommandMap[operation.operationGroup];
  }

  revertEntryCommandName(entry, purpose) {
    let operationGroupToCommandMap = null;
    switch (purpose) {
      case "LOAN_CUSTOM_GL_REVERT":
        operationGroupToCommandMap = this.revertEntryToCustomGLCommands;
        break;
      case "LOAN_PAYMENT_REVERT":
        operationGroupToCommandMap = this.revertEntryCommands;
        break;
      default:
        throw `Unknown revert type`;
    }

    return operationGroupToCommandMap[entry.entryType];
  }

}

nxModule.service('revertCommandService', RevertCommandService);
