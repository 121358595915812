import nxModule from 'nxModule';
import {IDynamicTableColDef, ISortingValues, NgTableParams} from "ng-table";

import templateUrl from './product-list.template.html';
import './product-list.style.less'
import {NxIFilterService} from "components/technical/angular-filters";
import {InterestBoardService} from "components/administration/casa/interest-board/interest-board.service.types";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";


type LinkColumnProperty = {
  access: string;
  label: string;
}

export type Columns = (IDynamicTableColDef & {link?: LinkColumnProperty} & {field?: string})[];
export type Sorting = ISortingValues;

class ProductList  {
  columns!: Columns;
  data!: unknown[];
  sorting!: Sorting;
  private tableConfig!: NgTableParams<unknown>;

  constructor(private depositAccountTypeService: DepositAccountTypeService, private interestBoardService: InterestBoardService, private $filter: NxIFilterService) {}

  $onInit() {
    this.initTable();
  }

  initTable() {
    const data : unknown[] = this.data || [];
    this.tableConfig = new NgTableParams({
      count: data.length,
      sorting: this.sorting || {}
    }, {
      counts: [],
      dataset: data
    });
  }

  $onChanges() {
    this.initTable();
  }
}

nxModule.component('productList', {
  templateUrl,
  bindings: {
    columns: '<',
    data: '<',
    sorting: '<'
  },
  controller: ProductList
});
