export enum PaymentIntervalType {
  INTERVAL = 'Interval',
  SET_DAYS_OF_THE_MONTH = 'Set days of the month',
  SEMIMONTHLY_GRANT_DATE = 'Semimonthly (rel. grant date)',
  SINGLE_PAYMENT = 'Single payment'
}

export enum BasePaymentInterval {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month'
}

export enum CicPaymentPeriodicity {
  DAILY = "D - Daily installments - 1 day",
  WEEKLY = "W - Weekly installments - 7 days",
  FORTNIGHT = "F - fortnight installments - 15 days",
  MONTHLY_30_DAYS = "M - monthly installments - 30 days",
  BIMONTHLY_60_DAYS = "B - bimonthly installments - 60 days",
  QUARTERLY_90 = "Q - quarterly installments - 90 days",
  TRIMESTER_FOUR_MONTHLY_INSTALLMENTS_120_DAYS = "T - trimester installments - 120 days",
  INSTALLMENTS_EVERY_FIVE_MONTHS_150_DAYS = "C - installments every five months - 150 days",
  INSTALLMENTS_EVERY_SIX_MONTHS_180_DAYS = "S - installments every six months - 180 days",
  YEARLY_INSTALLMENTS_360_DAYS = "Y - yearly installments - 360 days",
  IRREGULAR_INSTALLMENTS = "I - irregular installments"
}

export enum MidasPaymentPeriodicity {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  FORTNIGHTLY = "FORTNIGHTLY",
  SEMIMONTHLY_15_31 = "15-31-MONTHLY",
  MONTHLY = "MONTHLY",
  BI_MONTHLY = "BI-MONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_YEARLY = "SEMI-YEARLY",
  YEARLY = "YEARLY",
  ENDOFTERM = "ENDOFTERM"
}

export interface PaymentIntervalDefinition {
  id: number;
  name: string;
  accrualLimit: number;
  paymentIntervalType: PaymentIntervalType;
  basePaymentInterval: BasePaymentInterval;
  intervalCount: number;
  paymentDays: number[];
  cicPaymentPeriodicity: CicPaymentPeriodicity;
  midasPaymentPeriodicity: MidasPaymentPeriodicity;
}