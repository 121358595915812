import nxModule from 'nxModule';
import templateUrl from './update-branch-system-date.template.html';
import {BranchService} from '../../../service/branch.service';
import moment, {max} from 'moment';
import {WorkingDay, WorkingDayStatus} from '../../../../../react/management/WorkingDayType';
import {Branch} from "management/BranchTypes";
import {NxRouteService} from "routes/NxRouteService";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CommandService} from "shared/utils/command/command.types";
import {BranchWorkingDayCache, WorkingDaysCache} from "components/service/working-days-cache";

interface BranchFilterEntry {
  /**
   * Filter option label
   */
  code: string;
  /**
   * Filter option value
   */
  id: number;
  /**
   * Branch name
   */
  name: string;
  /**
   * Branch system date
   */
  systemDate: string;
  /**
   * Branch working day status
   */
  workingDayStatus: WorkingDayStatus;
}

class UpdateBranchSystemDateComponent {
  availableBranches: BranchFilterEntry[] = [];
  validSelectedBranches: BranchFilterEntry[] = [];
  selectedBranchIds: number[] = [];

  newSystemDateValid: boolean = false;
  minimumRequiredSystemDate: Date | null = null;
  newSystemDate: string = '';

  constructor(private $route: NxRouteService,
              private branchService: BranchService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private workingDaysCache: WorkingDaysCache,
              private branchWorkingDayCache: BranchWorkingDayCache) {
  }

  async $onInit(): Promise<void> {
    const [availableBranches, workingDays] = await Promise.all([this.branchService.toPromise(), this.workingDaysCache.toPromise()]);

    this.availableBranches = availableBranches.map(branch => {
      const workingDayStatus = workingDays.find((w: WorkingDay) => w.branchId === branch.id)!.status;
      return {
        code: `${branch.name} (${branch.systemDate}) - ${workingDayStatus}`,
        id: branch.id,
        name: `${branch.name}`,
        systemDate: branch.systemDate,
        workingDayStatus: workingDayStatus
      };
    });

    this.onFormChange();
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Please confirm following settings:`,
      details: [
        {
          label: `Branches to update (${this.validSelectedBranches.length})`,
          description: this.getBranchNames(this.validSelectedBranches)
        },
        {label: 'New system date', description: this.newSystemDate}
      ]
    });

    const request = {
      branchIds: this.validSelectedBranches.map(b => b.id),
      newSystemDate: this.newSystemDate
    };

    if (confirmed) {
      await this.command.execute('UpdateBranchSystemDate', request).toPromise();
      await this.branchService.refetch();
      await this.workingDaysCache.refetch();
      await Promise.all(this.validSelectedBranches.map(b => this.branchWorkingDayCache.withParam(b.id).refetch()));
      this.$route.reload();
    }
  }

  onFormChange(): void {
    this.minimumRequiredSystemDate = this.getLatestSystemDateOfSelectedBranches();
    this.validSelectedBranches = this.getValidSelectedBranches();
    this.newSystemDateValid = this.isNewSystemDateValid();
  }

  getInvalidSelectedBranches(): BranchFilterEntry[] {
    const branchesToUpdate = this.getValidSelectedBranches();
    return this.getSelectedBranches().filter(branch => branchesToUpdate.every(b => b.id !== branch.id));
  }

  getValidSelectedBranches(): BranchFilterEntry[] {
    return this.getSelectedBranches()
    .filter(b => this.isSystemDateValid(b))
    .filter(b => this.isWorkingDayStatusValid(b));
  }

  getSelectedBranches(): BranchFilterEntry[] {
    if (!this.selectedBranchIds) {
      return [];
    }
    if (this.isAllBranchesSelected()) {
      return this.availableBranches;
    }
    return this.availableBranches.filter(b => this.isBranchSelected(b));
  }

  isAllBranchesSelected(): boolean {
    return this.selectedBranchIds[0] === null;
  }

  isBranchSelected(branch: BranchFilterEntry): boolean {
    return this.selectedBranchIds.some(b => b === branch.id)
  }

  isSystemDateValid(branch: BranchFilterEntry): boolean {
    return moment(branch.systemDate).isBefore(moment(this.newSystemDate));
  }

  isWorkingDayStatusValid(branch: BranchFilterEntry): boolean {
    return branch.workingDayStatus === 'STARTED';
  }

  isNewSystemDateValid(): boolean {
    if(!this.minimumRequiredSystemDate) {
      return false;
    }

    return moment(this.minimumRequiredSystemDate).isBefore(moment(this.newSystemDate));
  }

  getBranchNames(branchEntries: BranchFilterEntry[]): string {
    return branchEntries.map(b => b.name).join(', ');
  }

  getLatestSystemDateOfSelectedBranches(): Date {
    return max([
      ...this.getSelectedBranches()
      .map(b => moment(b.systemDate)),
      moment('1900-01-01') // fallback when no branches yet selected
    ])
    .toDate();
  }

  getBranchLabel(branch: Branch): string {
    return `${branch.name} : ${branch.systemDate}`;
  }
}

nxModule.component('updateBranchSystemDate', {
  templateUrl,
  controller: UpdateBranchSystemDateComponent
});
