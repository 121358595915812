import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import Notification from 'shared/utils/notification';

import templateUrl from './synchronize-scheduled-jobs.template.html';

class SynchronizeScheduledJobs {
  constructor(private confirmation: Confirmation,
              private command: CommandService,
              private notification: Notification) {
  }

  async synchronize():Promise<void> {
    const proceed = await this.confirmation("Are you sure you want to synchronize scheduled jobs?");
    if (!proceed) {
      return;
    }

    try{
      await this.command.execute('SynchronizeScheduledSystemPropertyJobs', {}).toPromise();
    } catch(error) {
      this.notification.show("Error", "Failed synchronizing scheduled jobs");
      console.error(error);
    }
  }
}

nxModule.component('synchronizeScheduledJobs', {
  templateUrl,
  controller: SynchronizeScheduledJobs
});
