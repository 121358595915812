import nxModule from "nxModule";
import templateUrl from "./loan-letter-history.template.html";
import {NgTableParams} from "ng-table";
import {HttpService} from "shared/utils/httpService";
import {UserCache, UserDetails} from "components/service/users/user.cache";
import {LetterType} from "components/administration/loan/letter-type/letter.types";
import {Loan} from "components/service/loan.types";

interface LetterPrintHistory {
  productId: number
  letterType: LetterType,
  registrationTime: Date
  amortizationNumber: number
  printedBy: number
}

class LoanLetterHistory {
  private loan!: Loan;
  private tableConfig!: NgTableParams<LetterPrintHistory[]>;
  private users: UserDetails[] = [];

  constructor(private http: HttpService, private userCache: UserCache) {

  }

  async $onInit(): Promise<void> {
    this.users = await this.userCache.withParam().toPromise();
  }

  async $onChanges() {
    if (this.loan) {
      this.initTableConfig();
    }
  }

  initTableConfig(): void {
    this.tableConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<LetterPrintHistory[]>) => {
        const response: any = await this.http.get(`/products/loans/${this.loan.id}/letters/history`, {
          params: {
            pageNo: params.page() - 1,
            pageSize: params.count()
          }
        }).toPromise();
        return response.result;
      }
    });
  }

  getUserEffectiveName(userId: number): string | undefined {
    const user = this.users.find(u => u.id === userId)
    return user?.effectiveName;
  }
}

nxModule.component('letterHistory', {
  templateUrl,
  bindings: {
    loan: '<'
  },
  controller: LoanLetterHistory,
});
