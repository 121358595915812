import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import _ from "lodash";

nxModule.factory('singleUserCache', (http, CacheFactory, config) => {
  let that = {};

  let singleUser = {};

  let singleUserCache = (id) => {
    const cache = new LocalCache({
      provider: () => http.get(`/management/users/${id}`, {nxLoaderSkip: true}),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.singleUser,
      cacheName: `user_${id}`,
      postProcessor: u => {
        const middle = (u.middleName ? u.middleName + ' ' : '');
        u.effectiveName = `${u.username} (${u.firstName} ${middle}${u.lastName})`;
        u.fullName = `${u.firstName} ${middle}${u.lastName}`;

        // Create user full name with roles, e.g. 'Joe H. Doe (teller, collector)'
        u.fullNameWithRoles = u.fullName;
        if (u.roles && u.roles.length > 0) {
          const userRoles = `(${_.map(u.roles, 'name').join(', ')})`;
          u.fullNameWithRoles = u.fullName + ' ' + userRoles;
        }
        return u;
      }
    });
    singleUser[id] = cache;
    return cache;
  };

  that.user = (id) => {
    if (!singleUser[id]) {
      singleUserCache(id);
    }
    return singleUser[id];
  };

  return that;
});
