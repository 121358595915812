import nxModule from 'nxModule';

import templateUrl from './atm-batch-acquirers-upload-history.template.html'
import {AtmBatchAcquirersService, AtmBatchFileOutput} from "./atm-batch-acquirers.service";
import {NgTableParams} from 'ng-table';
import {ReportModal} from "components/report/modal/report-modal.service.types";

class AtmBatchAcquirersUploadHistoryComponent {
  tableConfig: NgTableParams<AtmBatchFileOutput>;
  totalRowCount?: number;

  constructor(private atmBatchAcquirersService: AtmBatchAcquirersService,
              private reportModal: ReportModal) {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      total: 0,
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: async (params: NgTableParams<AtmBatchFileOutput>) => {
        const request = {
          pageNo: params.page() - 1,
          pageSize: params.count()
        };
        const response = await this.atmBatchAcquirersService.readAtmBatchFilesOutput(request);
        this.totalRowCount = response.totalCount;
        params.total(this.totalRowCount);
        return response.result;
      }
    });
  }

  showDetails(batchFileId: number): void {
    this.reportModal.display({
      params: {batchFileId},
      reportCode: 'AtmBatchAcquirersUploadReport',
      hideXls: false
    });
  }
}

nxModule.component('atmBatchAcquirersUploadHistory', {
  templateUrl,
  controller: AtmBatchAcquirersUploadHistoryComponent,
});