import nxModule from 'nxModule';
import templateUrl from './group-picker.template.html';
import _ from "lodash";
import './group-picker.style.less';
import {UserCache, UserDetails} from "../../../service/users/user.cache";
import {Center} from "../../../service/center/center.types";
import LocalCache from "../../../../shared/utils/localCache";
import {IAugmentedJQuery, IScope} from "angular";
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";
import {Confirmation} from "shared/common/confirmation.types";

export interface CustomerGroupSearchResult {
  customerId: number;
  customerGroupName: string;
  customerBranchId: number;
  members: CustomerGroupSearchResultMember[];
  customerGroupId: number;
  customerGroupCenter: Center;
}

export interface CustomerGroupSearchResultMember {
  id: number;
  customerNumber: string;
  effectiveName: string;
  activeLoanCount: number;
}

class CenterGroupPicker {
  private readonly resultLimit = 51;
  private readonly onAdd!: (center: CustomerGroupSearchResult) => boolean;
  private readonly onTransferHere!: (center: CustomerGroupSearchResult) => boolean;
  private readonly filterResult!: (center: CustomerGroupSearchResult) => boolean;
  private visible = false;
  private name: string | null = null;
  private searchResults: CustomerGroupSearchResult[] = [];
  private branchNames: Record<number, string> = {};
  private users: Record<number, UserDetails> = {};

  constructor(private readonly $scope: IScope,
              private readonly $element: IAugmentedJQuery,
              private readonly http: HttpService,
              private readonly branchService: LocalCache<Branch[], Branch[]>,
              private readonly userCache: UserCache,
              private readonly confirmation: Confirmation) {

  }

  async $onInit(): Promise<void> {
    const [branches, usersArr]: [Branch[], UserDetails[]] = await Promise.all([
      this.branchService.toPromise(),
      this.userCache.withParam().toPromise()
    ]);

    this.users = _.keyBy(usersArr, user => user.id);
    branches.forEach(b => this.branchNames[b.id] = b.name);
  }

  enableSearch(): boolean {
    return !!this.name;
  }

  async search(): Promise<void> {
    this.searchResults = await this.http.get<CustomerGroupSearchResult[]>('/customers/group', {
      nxLoaderText: 'Searching groups...',
      params: {
        groupName: this.name,
        resultLimit: this.resultLimit
      }
    }).toPromise();

    if (this.filterResult) {
      this.searchResults = this.searchResults.filter(this.filterResult);
    }
    this.visible = true;
  }

  async onActionButtonClick($event: Event, result: CustomerGroupSearchResult): Promise<void> {
    $event.stopPropagation();

    if (result.customerGroupCenter) {
      const confirmed = await this.confirmation(`Are you sure you want transfer this group [${result.customerGroupName}] into this center? Please note that the said group is currently assigned in another center [${result.customerGroupCenter.name}].`);
      if (!confirmed) {
        return;
      }
    }

    if (result.customerGroupCenter) {
      await this.onTransferHere(result)
    } else {
      await this.onAdd(result)
    }

    this.searchResults = this.searchResults.filter(r => r !== result);
    if (!this.searchResults.length) {
      this.name = null;
      this.visible = false;
    }
  }

  getGroupCenterStatus(center: Center): string {
    if (center) {
      return center.active ? 'Active' : 'Inactive';
    }
    return '';
  }

  clear() {
    this.searchResults = [];
    this.name = null;
    this.visible = false;
  }
}

nxModule.component('centerGroupPicker', {
  templateUrl,
  bindings: {
    'onAdd': '<',
    'onTransferHere': '<',
    'filterResult': '<',
    'disabled': '<'
  },
  controller: CenterGroupPicker
});