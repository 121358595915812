import currency from 'currency.js';
import systemPropertyService from "../system/systemPropertyService";
import {isNil} from 'lodash/fp';

export default class CurrencyFormatter {
  format(number?: number, dashOnZero?: boolean): string {
    if(isNil(number)) {
      return '-';
    }

    if (dashOnZero && number === 0) {
      return '-';
    }

    return currency(number, {
      symbol: systemPropertyService.getProperty('DEFAULT_CURRENCY') ?? 'PHP',
      pattern: '# !'
    }).format();
  }
}