import nxModule from 'nxModule';

import './dynamic-report-file.style.less';
import templateUrl from './dynamic-report-file.template.html';
import {ReportMapping} from "../mapping/edit/dynamic-report-mapping-edit.component";
import {markDuplicatedRows} from "../dynamic-report-mapping-util";


class DynamicReportFile {
  constructor(confirmation, command, dynamicReportService, $location, http, popup) {
    this.confirmation = confirmation;
    this.command = command;
    this.dynamicReportService = dynamicReportService;
    this.$location = $location;
    this.http = http;
    this.popup = popup;

    this.fileSources = ['PROVIDED', 'UPLOAD'];
  }

  async $onInit() {
    if(this.reportFile.filePath) {
      this.fileSource = 'PROVIDED';
    } else if(this.reportFile.attachedFilesId) {
      this.fileSource = 'UPLOAD';
    }

    this.dynamicReportService.updateBreadcrumb(this.report, this.reportFile);
    this.diskFiles = await this.dynamicReportService.getDiskFiles();

    this.mapping = this.reportFile.mapping.map(item => { return new ReportMapping(item)});
    markDuplicatedRows(this.mapping);

  }

  async save() {
    const mapping = this.mapping.map(item => ({
        ...item,
      dynamicReportFileId: this.reportFile.id
    }));

    const resp = await this.command.execute('UpdateDynamicReportFile', {
      reportFile: this.reportFile,
      mapping,
    }).toPromise();

    if(!this.reportFile.id) {
      this.$location.path(`/admin/dynamic-report/${this.report.id}/file/${resp.output.id}`);
    }
  }

  async testMapping() {
    const response = await this.http.post(`/reports/test-mapping`, this.mapping).toPromise();
    if (_.size(response) > 0) {
      const message = response.map(r => _.escape(r.address + " " + r.errorMessage)).join('<br>');
      this.popup({header: "Errors found", text: message, renderHtml: true});
    } else {
      this.popup({text: "Mapping test successful"})
    }
  }

  cancelChanges() {
    this.$location.path(`/admin/dynamic-report/${this.report.id}`);
  }

  uploadDownloadMapping() {
    this.$location.path(`/admin/dynamic-report/${this.report.id}/file/${this.reportFile.id}/upload-download-mapping`);
  }

  clearFiles() {
    this.reportFile.attachedFilesId = null;
    this.reportFile.filePath = null;
  }
}

nxModule.component('dynamicReportFile', {
  templateUrl,
  bindings: {
    reportFile: '<',
    report: '<'
  },
  controller: DynamicReportFile
});