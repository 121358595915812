import {INgModelController, IOnChangesObject, IScope} from 'angular';
import InspireTree, {NodeConfig, TreeNode, TreeNodes} from 'inspire-tree';
import InspireTreeDOM from 'inspire-tree-dom';
import $ from 'jquery';
import _ from 'lodash';
import nxModule from 'nxModule';
import {Observable, Subject, Subscription} from 'rxjs';
import {bufferTime} from 'rxjs/operators/bufferTime';
import {filter} from 'rxjs/operators/filter';
import {ReplaySubject} from 'rxjs/ReplaySubject';

import './ent-horde.style.less';
import templateUrl from './ent-horde.template.html';

export type NodeLabel = (node: unknown) => string;

export interface EntHordeNode {
  id: string;
  children: EntHordeNode[];
}

interface NxInspireTree extends InspireTree {
  load(loader: Promise<TreeNodes>): unknown;
  load(nodes: NodeConfig[]): unknown;
  select(): TreeNodes;
  select(ids: string[]): unknown;
}

class EntHorde {
  private tree?: NxInspireTree;
  private ngModel?: INgModelController;
  private nodeLabel!: NodeLabel;
  private treeData!: EntHordeNode[];
  private readonly itemAdditions: Subject<TreeNode> = new ReplaySubject<TreeNode>();
  private readonly itemRemovals: Subject<TreeNode> = new ReplaySubject<TreeNode>();
  private itemAdditionsSubscription?: Subscription;
  private itemRemovalsSubscription?: Subscription;
  showDropdown: boolean;
  labelRenderer!: () => string;

  constructor(private $element: JQuery, private $scope: IScope) {
    this.showDropdown = false;
  }

  $onInit(): void {
    this.tree = <NxInspireTree> new InspireTree({
      selection: {
        mode: 'checkbox',
      },
      pagination: {
        limit: 40
      }
    });

    if(!this.ngModel) {
      throw new Error('Missing ng model');
    }

    this.ngModel.$render = (): void => {
      const externalValues = this.modelValue() || [];
      this.tree?.select(externalValues);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.tree?.invoke('check', externalValues);
    };

    $(document.body).on('click', this.hideDropdownListener);

    this.itemAdditionsSubscription = this.subscribeAndBuffer(this.itemAdditions)
      .subscribe((): void => {
        if(!this.tree) {
          return;
        }
        const newViewValue = this.tree.selected(false).map(node => (<any> node).id);
        this.triggerNgModelUpdate(newViewValue);
      }
    );

    this.itemRemovalsSubscription = this.subscribeAndBuffer(this.itemRemovals)
      .subscribe(() => {
        if(!this.tree) {
          return;
        }
        const newViewValue = this.tree.selected(false).map(node => (<any>node).id);
        this.triggerNgModelUpdate(newViewValue);
      }
    );

  }

  subscribeAndBuffer(subject: Subject<TreeNode>): Observable<TreeNode[]> {
    return subject.asObservable()
      .pipe(
        bufferTime(100),
        filter(items => items.length > 0)
      );
  }

  hideDropdownListener = (e: JQueryEventObject): void => {
    const $clickedItem = $(e.target);

    if ($clickedItem.closest(this.$element).length > 0) {
      return;
    }

    this.$scope.$apply(() => {
      this.showDropdown = false;
    });
  };

  collectIndeterminateNodes(sourceNode: EntHordeNode, indeterminateCheckedNodes: Set<string>): boolean {
    let foundSelectedNode = false;
    for(const node of (sourceNode.children || [])) {
      if(this.collectIndeterminateNodes(node, indeterminateCheckedNodes)) {
        indeterminateCheckedNodes.add(sourceNode.id);
        foundSelectedNode = true;
      }
    }

    return foundSelectedNode || this.modelValue().includes(sourceNode.id);
  }

  prepareNodeConfig(sourceNode: EntHordeNode, indeterminateCheckedNodes: Set<string>): NodeConfig {
    return {
      id: sourceNode.id,
      text: this.nodeLabel(sourceNode),
      children: (sourceNode.children || []).map(node => this.prepareNodeConfig(node, indeterminateCheckedNodes)),
      itree: {
        state: {
          indeterminate: indeterminateCheckedNodes.has(sourceNode.id),
          selectable: true,
          selected: this.modelValue().includes(sourceNode.id),
          checked: this.modelValue().includes(sourceNode.id)
        },
      }
    };
  }

  normalizedTreeModel(): NodeConfig[] {
    const indeterminateCheckedNodes = new Set<string>();
    for(const node of this.treeData) {
      this.collectIndeterminateNodes(node, indeterminateCheckedNodes);
    }

    return Object.values(this.treeData || [])
      .map(node => this.prepareNodeConfig(node, indeterminateCheckedNodes));
  }

  modelValue(): string[] {
    return this.ngModel?.$modelValue || [];
  }

  selectModelValue(): void {
    if(!this.tree) {
      return;
    }

    const externalIds = this.modelValue();
    if(externalIds && externalIds.length > 0) {
      const nodes = this.tree.nodes(externalIds);

      nodes.expandParents();
      nodes.select();
      nodes.invoke('check');
    }
  }

  triggerNgModelUpdate(ids: number[]): void {
    this.$scope.$apply(() => {
      if(!this.ngModel) {
        return;
      }

      this.ngModel.$setTouched();
      this.ngModel.$setViewValue(ids);
    });
  }

  triggerNodeAdded(addedNode: TreeNode): void {
    this.itemAdditions.next(addedNode);
  }

  triggerNodeRemoved(removedNode: TreeNode): void {
    this.itemRemovals.next(removedNode);
  }

  $onChanges(changes: IOnChangesObject): boolean | undefined {
    const previousTreeData = _.get(changes, 'treeData.previousValue', {});
    const currentTreeData = _.get(changes, 'treeData.currentValue', {});
    // here I'm trying to defer initialization of  tree as it's rendering engine is asynchronous
    if(changes.treeData && changes.treeData.isFirstChange() && !_.isEmpty(currentTreeData)) {
      return;
    }

    // I'm explicitly checking for emptiness as two instances of empty objects
    // using different constructors may not be equal
    if(_.isEmpty(previousTreeData) && _.isEmpty(currentTreeData)) {
      return true;
    }

    if(!_.isEqual(previousTreeData, currentTreeData) && this.tree) {
      this.tree.load(this.normalizedTreeModel());
    }
  }

  $postLink() {
    this.$element.click(_evt => this.ngModel?.$setTouched());

    const root = this.$element.find('.ent-horde__tree-element');
    new InspireTreeDOM(this.tree, {
      target: root.get(0),
      deferredRendering: true
    });

    if(!this.tree) {
      return;
    }

    this.tree.on('node.selected', (node, _isLoadEvent) => {
      this.triggerNodeAdded(node);
    });

    this.tree.on('node.deselected', (node, _isLoadEvent) => {
      this.triggerNodeRemoved(node);
    });

    this.tree.on('model.loaded', _nodes => {
      this.selectModelValue();
    });

    if(this.treeData) {
      this.tree.load(this.normalizedTreeModel());
    }
  }

  $onDestroy(): void {
    $(document).off('click', this.hideDropdownListener);
    this.itemAdditionsSubscription?.unsubscribe();
    this.itemRemovalsSubscription?.unsubscribe();
  }
}


nxModule.component('entHorde', {
  templateUrl,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    treeData: '<',
    nodeLabel: '<',
    labelRenderer: '<'
  },
  controller: EntHorde
});