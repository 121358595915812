import 'rxjs/add/operator/map';

import nxModule from 'nxModule';

import templateUrl from './depository-account-category-list.template.html';

interface Category {
  id: number;
  description: string;
  code: string;
  attributes?: {
    parentDictEntryId: Number[]
  }
}

interface CategoryView {
  id: number,
  name: string;
  code: string;
  depth: number;
}

class DepositoryAccountCategoryList {
  private categoryView: CategoryView[] = [];

  constructor(private dict: unknown) {
}

async $onInit(): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await this.dict.onLoadingCompleteAsync();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const categories: Category[] = this.dict['DAC_CATEGORY'];
  const categoriesMap = new Map<number, Category>();
  for(const category of categories) {
    categoriesMap.set(category.id, category);
  }

  const categoryView = [];
  for(const category of categories) {
    categoryView.push(this.resolveCategory(category, categoriesMap));
  }

  this.categoryView = categoryView.sort((catA, catB) => catA.id - catB.id);
}

private resolveCategory(category: Category, categoryMap: Map<number, Category>): CategoryView {
  const ids = [];
  let tmp = category;

  while(tmp) {
    ids.push(tmp.id);
    const parentId = tmp.attributes ? tmp.attributes.parentDictEntryId[0]: null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tmp = categoryMap.get(parentId);
  }

  return {
    id: category.id,
    name: category.description,
    code: category.code,
    depth: ids.length
  };
}

}

nxModule.component('depositoryAccountCategoryList', {
  templateUrl,
  controller: DepositoryAccountCategoryList
});
