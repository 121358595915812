import nxModule from 'nxModule';

interface DataUriDetails {
  mimeType: string;
  data: string;
}

const notDataUriError = (uri: string) => {
  throw new Error('Not a data uri ' + uri);
};

export class Base64Service {

  parseDataUri(uri: string): DataUriDetails {
    if(!uri) {
      notDataUriError(uri);
    }

    const prefix = 'data:';
    const suffix = ';base64';
    if(!uri.startsWith('data:')) {
      notDataUriError(uri);

    }
    const parts = uri.split(',');
    if(parts.length !== 2) {
      notDataUriError(uri);

    }

    const headerPart = parts[0];
    const mimeAndSuffix = headerPart.substring(prefix.length);
    const mimeType = mimeAndSuffix.substring(0, mimeAndSuffix.length - suffix.length);

    return {
      mimeType,
      data: parts[1]
    };
  }
}

nxModule.service('base64Service', Base64Service);
