import nxModule from 'nxModule';

import {IController, ILocationService} from 'angular';

import templateUrl from './access-rule-approval-hierarchy.template.html';
import {ApprovalHierarchy} from '../../../common/approval-hierarchy/approval-hierarchy.types';
import {AccessRule, AccessRuleCache, CommandGroup} from "../../../service/access/access-rule.types";
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {NxIFilterService} from "components/technical/angular-filters";

class AccessRuleApprovalHierarchy implements IController {
  approvalHierarchy!: ApprovalHierarchy[];
  accessRule!: AccessRule;

  private roleId!: string;
  private commandGroup!: CommandGroup;

  constructor(private readonly $location: ILocationService,
              private readonly $route: NxRouteService,
              private readonly http: HttpService,
              private readonly command: CommandService,
              private readonly $filter: NxIFilterService,
              private readonly accessRuleCache: AccessRuleCache) {
  }

  $onInit(): void {
    this.roleId = this.$route.current.params['roleId'] as string;
    this.commandGroup = this.$route.current.params['group'] as CommandGroup;
  }

  accessName(): string {
    const commandName = this.$filter('translateEnum')(this.accessRule.command, 'COMMAND');
    return `Approval hierarchy for ${commandName}`;
  }

  save = async (approvalHierarchy: ApprovalHierarchy[]): Promise<void> => {
    await this.command.execute('UpdateAccessRuleHierarchy', {
      approvalHierarchy,
      accessRuleId: this.accessRule.id
    }).toPromise();
    this.accessRuleCache.evict();
    this.$location.path(`/admin/security/roles/${this.roleId}/access-rules/${this.commandGroup}`);
  };
}

nxModule.component('accessRuleApprovalHierarchy', {
  templateUrl,
  controller: AccessRuleApprovalHierarchy,
  bindings: {
    approvalHierarchy: '<',
    accessRule: '<'
  }
});