import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';
import {
  isCorporate,
  mapSignatoryToOwnership,
  multipleAccountOwnershipsEnabled,
  validateOwnerConfiguration
} from '../../common/casa-product-util';
import CASA_CLASS_CODES from '../../common/types/casa-class-codes.ts';
import BigNumber from 'bignumber.js';

import './new-term-deposit-details.style.less';
import {cycleType, pledgeAgreementCycleTypes, pledgePaymentIntervals} from 'constants/deposit';

const templateUrl = require('./new-term-deposit-details.template.html');

nxModule.component('customerTermDepositCreateDetails', {
  templateUrl: templateUrl,
  controller: function ($route, $location, confirmationTemplate, authentication, command, casaTypesCache, interestBoardService,
                        customerCache, termDepositsService, customerDepositService, branchService, $scope, dict, http,
                        notification) {
    const that = this;

    that.customerId = $route.current.params['customerId'];
    const termDepositProductId = $route.current.params['termDepositTypeId'];
    const preterminationFirstHalfMultipler = new BigNumber(0.25).toNumber();
    const preterminationSecondHalfMultipler = new BigNumber(0.50).toNumber();

    that.casaClassCodes = CASA_CLASS_CODES;
    that.depositAccounts = [];
    that.termDepositProduct = {};
    that.termDepositInterestBoard = {};
    that.overrideTaxExemption = false;
    that.overrideInterestRate = false;
    that.pdicCasaTypes = [];
    that.termDeposit = undefined;
    that.accounts = [];
    that.selectedRelatedAccount = {};
    that.hasValidOwnerConfiguration = false;

    $scope.$watchCollection('$ctrl.termDeposit.ownership', () => {
      if (that.termDeposit) {
        that.cifIds = that.termDeposit.ownership.map(o => Number(o.customerId));
        if (that.profile.relatedCustomers && that.profile.relatedCustomers.length > 0) {
          let relativesCifIds = that.profile.relatedCustomers.map(r => Number(r.relativeCustomerId));
          that.cifIds = that.cifIds.concat(relativesCifIds);
        }
        searchAccounts(that.termDepositProduct.interCustomerTransferAllowed);

        that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.termDeposit, that.pdicCasaTypes);
      }
    });

    $scope.$watch('$ctrl.termDeposit.depositInterest.interestRate', () => {
      if (that.termDeposit && that.termDeposit.depositInterest.specialInterestRate && that.termDeposit.depositInterest.interestRate) {
        const interestRate = new BigNumber(that.termDeposit.depositInterest.interestRate);
        that.termDeposit.depositInterest.preterminationFirstHalfInterestRate = interestRate.multipliedBy(preterminationFirstHalfMultipler).toNumber();
        that.termDeposit.depositInterest.preterminationSecondHalfInterestRate = interestRate.multipliedBy(preterminationSecondHalfMultipler).toNumber();
      }
    });

    /**
     * Multiple account ownership enabled when:
     * 1. Customer is INDIVIDUAL
     * 2. Casa PDIC casa types allows this feature.
     */
    that.multipleAccountOwnershipEnabled = false;

    that.pledgePaymentIntervals = pledgePaymentIntervals;
    that.selectAccountConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'label',
      maxItems: 1
    };

    const searchAccounts = (fetchRelatedCustomerAccounts) => {
      customerDepositService.searchDeposits(
        // term deposit owner, co-owner, and related customers Ids
        that.cifIds,
        // valid deposit account statuses
        ['ACTIVE', 'INACTIVE'],
        // if true -> fetch deposit accounts of related customers,
        fetchRelatedCustomerAccounts,
        // search success callback
        deposits => that.depositAccounts = deposits
      );
    };

    that.findSignatoryNames = () => {
      let signatoryTypeId = _.find(dict.RELATIVE_TYPE, {code: 'SIGNATORY'}).id;
      that.signatoryRelatives = _.filter(that.profile.relatedCustomers, {typeId: signatoryTypeId});
    };

    that.createInterestBoard = (termDepositProduct) => {
      if (termDepositProduct) {
        that.termDepositInterestBoard.basic = termDepositProduct.interestBoards.find(board => board.rateType === 'BASIC');
        that.termDepositInterestBoard.preterminationFirstHalf = termDepositProduct.interestBoards.find(board => board.rateType === 'PRETERMINATION_ON_FIRST_HALF');
        that.termDepositInterestBoard.preterminationSecondHalf = termDepositProduct.interestBoards.find(board => board.rateType === 'PRETERMINATION_ON_SECOND_HALF');
        that.termDepositInterestBoard.overmature = termDepositProduct.interestBoards.find(board => board.rateType === 'OVERMATURE');
      }
    };

    const s1 = customerCache.profile(that.customerId).toObservable()
      .combineLatest(termDepositsService.toObservable(), (profile, products) => {
        that.profile = profile;

        dict.onLoadingComplete(() => {
          that.findSignatoryNames();
        });

        return products;
      }).combineLatest(branchService.toObservable(), (products, branches) => {
        const authBranch = _.find(branches, {id: authentication.context.branchId});
        if (authBranch) that.systemDate = moment(authBranch.systemDate).toDate();
        that.ownerBranch = branches.find(b => Number(b.id) === Number(that.profile.branchId));
        return products;
      })
      .combineLatest(casaTypesCache.toObservable(), (products, casaTypes) => {
        that.casaTypes = casaTypes;
        that.pdicCasaTypes = _.filter(that.casaTypes, {
          'regulatorType': 'PDIC',
          customerType: that.profile.customerType
        });
        if (that.profile?.corporateData?.businessTypeId !== dict.getId('BUSINESS_TYPE', 'SOLE_PROPRIETORSHIP')) {
          that.pdicCasaTypes = that.pdicCasaTypes.filter(t => t.code !== 'CORP_SSP');
        }
        return products;
      })
      .subscribe(products => {
        const termDepositProduct = _.find(products, {id: ~~termDepositProductId});
        that.termDepositProduct = termDepositsService.enrichWithStrategiesDescription(termDepositProduct);
        that.termDepositProduct.interestRange = interestBoardService.formatInterestRateRangeForRateType(that.termDepositProduct.interestBoards);
        that.cycleTypes = that.termDepositProduct.depositSubgroup === 'PLEDGE' ? pledgeAgreementCycleTypes : cycleType;

        that.createInterestBoard(that.termDepositProduct);

        const owner = {
          birthDate: that.profile.individualData ? that.profile.individualData.birthDate : null,
          branchId: that.profile.branchId,
          branchName: that.ownerBranch.name,
          customerId: that.customerId,
          customerNumber: that.profile.customerNumber,
          effectiveName: that.profile.effectiveName,
          ownershipType: 'OWNER'
        };

        that.termDeposit = {
          customerId: that.customerId,
          typeId: that.termDepositProduct.id,
          pdicTypeId: null,
          depositTerm: null,
          maturityStrategy: _.get(that.termDepositProduct, 'maturityStrategies[0].value'),
          creditingStrategy: _.get(that.termDepositProduct, 'creditingStrategies[0].value'),
          relatedAccountId: null,
          taxExempted: false,
          creditingInterval: null,
          creditingCycleType: null,
          ownership: [owner],
          depositInterest: {
            specialInterestRate: false,
            interestRate: null,
            preterminationFirstHalfInterestRate: null,
            preterminationSecondHalfInterestRate: null
          },
          keepSpecialInterestRateEnabled: termDepositProduct.keepSpecialInterestRateEnabled
        };
      });

    that.onDepositTermChange = () => {

      const deposit = that.termDeposit;
      const term = deposit.depositTerm;

      // If tax exemption marker is set to true -> keep it
      // Otherwise set marker value to false (to prevent undefined/null)
      deposit.taxExempted = deposit.taxExempted || false;

      // Update tax exemption marker
      if (term && !that.overrideTaxExemption) {
        that.termDeposit.taxExempted = term > that.termDepositProduct.taxExemptionThreshold;
      }

      // Recalculate maturity date
      that.termDeposit.maturityDate = null;
      if (term && term > 0 && that.systemDate) {
        that.termDeposit.maturityDate = moment(that.systemDate).add(term, 'days').toDate();
      }
    };

    that.redirectBack = () => $location.path(`/customer/${that.customerId}/term-deposits/create`);

    that.open = () => {

      if (!that.termDeposit.depositInterest.specialInterestRate) {
        that.resetInterestRates();
      }

      that.termDeposit.signatoryIds = that.termDeposit.signatoryIds || [];
      const deposit = angular.copy(that.termDeposit);

      deposit.signatureCardFileId = _.map(that.signatureCardFiles, f => f.id)[0];

      // Add signatories to owners
      const signatories = that.signatoryRelatives
        .filter(s => that.termDeposit.signatoryIds.includes(s.id))
        .map(sig => mapSignatoryToOwnership(sig));
      deposit.ownership = deposit.ownership.concat(signatories);

      // Calculate interest range for the deposit
      const boards = that.termDepositProduct.interestBoards;
      const rateRange = interestBoardService.calculateInterestRateRange(boards, deposit.depositTerm);

      confirmationTemplate({
        question: `Do you want to open the term deposit?`,
        details: [
          {label: 'Product name', description: that.termDepositProduct.productDefinition.productName},
          {label: 'Deposit term', description: `${deposit.depositTerm} days`},
          {label: 'Min interest rate', description: `${rateRange.minRate}%`},
          {label: 'Max interest rate', description: `${rateRange.maxRate}%`},
          {label: 'Special interest rate', description: deposit.depositInterest.specialInterestRate},
          {label: 'Interest rate', description: `${deposit.depositInterest.interestRate}%`}
        ],
        yesCallback: () => {
          command.execute('CreateDeposit', deposit, {nxLoaderText: 'Creating deposit'}).success(() => {
            termDepositsService.refetch();
            customerCache.termDeposits(that.customerId).refetch();
            customerCache.depositAccounts(that.customerId).refetch();
            $location.path(`/customer/${that.customerId}/term-deposits`)
          });
        }
      });
    };

    that.pdicCasaTypesChanged = () => {
      that.multipleAccountOwnershipEnabled = multipleAccountOwnershipsEnabled(that.profile, that.termDeposit, that.pdicCasaTypes);

      if (!that.multipleAccountOwnershipEnabled) {
        that.termDeposit.ownership = that.termDeposit.ownership.filter(owner => {
          return owner.ownershipType === 'OWNER'
        });
      }

      that.isCorporate = isCorporate(that.profile, that.termDeposit, that.pdicCasaTypes);
      if (!that.isCorporate) {
        that.termDeposit.signatoryIds = [];
      }
      that.hasValidOwnerConfiguration = validateOwnerConfiguration(that.profile, that.termDeposit, that.pdicCasaTypes);

    };

    that.resetInterestRates = () => {
      that.termDeposit.depositInterest.interestRate = that.termDepositInterestBoard.basic.defaultRate;
      that.termDeposit.depositInterest.preterminationFirstHalfInterestRate = that.termDepositInterestBoard.preterminationFirstHalf.defaultRate;
      that.termDeposit.depositInterest.preterminationSecondHalfInterestRate = that.termDepositInterestBoard.preterminationSecondHalf.defaultRate;
    };

    that.simulatePledge = async () => {
      const input = {
        depositId: that.depositId,
        depositTypeId: that.termDepositProduct.id,
        pledgeInstallmentNumber: that.termDeposit.pledgeInstallmentNumber,
        pledgeAmount: that.termDeposit.pledgeAmount,
        pledgePaymentInterval: that.termDeposit.pledgePaymentInterval,
        calculationType: that.termDepositProduct.pledgeAmountCalculationType,
        pledgeTermCalculationMethod: that.termDepositProduct.pledgeTermCalculationMethod,
        branchId: authentication.context.branchId,
        depositTerm: that.termDeposit.depositTerm
      };
      const pledgeInstallmentSchedule = await http.post('/products/deposits/pledge/simulate', input).toPromise();

      if (!_.isEmpty(pledgeInstallmentSchedule.installments)) {
        that.termDeposit.pledgeInstallments  = pledgeInstallmentSchedule.installments;
        that.termDeposit.depositTerm = pledgeInstallmentSchedule.depositTerm;
        that.termDeposit.maturityDate = pledgeInstallmentSchedule.depositMaturityDate;
      }

      let creditingScheduler = {};
      if(that.termDeposit.creditingStrategy !== 'NOT_ALLOWED') {
        creditingScheduler = {
          enabled: true,
          interval: that.termDeposit.creditingInterval,
          type: 'INTEREST_CREDITING',
          cycleType: that.termDeposit.creditingCycleType,
          referenceDate: that.ownerBranch.systemDate
        }
      } else {
        creditingScheduler = {
          enabled: false,
          interval: 1,
          referenceDate: that.ownerBranch.systemDate,
          type: 'INTEREST_CREDITING'
        }
      }

      const maturityInput = {
        depositTypeId: termDepositProductId,
        creditingScheduler: creditingScheduler,
        activationAmount: 0,
        activatedOn: that.ownerBranch.systemDate,
        maturityDate: pledgeInstallmentSchedule.depositMaturityDate,
        creditingStrategy: that.termDeposit.creditingStrategy,
        depositInterest: that.termDeposit.depositInterest,
        pledgeInstallments: pledgeInstallmentSchedule.installments
      }

      try {
        const maturityValueOutput = await http.post('/products/deposits/maturity-value', maturityInput).toPromise();
        that.termDeposit.maturityValue = maturityValueOutput.maturityValue;
      } catch (e) {
        notification.show("Error", "Failed to calculate maturity value")
      }
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    };

    that.resetTerm = () => {
      that.termDeposit.depositTerm = null;
      that.termDeposit.maturityDate = null;
    };

    that.pledgePaymentIntervalChanged = (pledgePaymentInterval) => {
      that.resetTerm();

      if (pledgePaymentInterval === 'SINGLE_PAYMENT') {
        that.termDeposit.pledgeInstallmentNumber = 1;
      }

    }

    that.getPledgeAmountLabel = () => {
      return `Pledge ${that.termDepositProduct.pledgeAmountCalculationType === 'INSTALLMENT_AMOUNT' ? 'installment': 'total'} amount:`;
    }
  }
});
