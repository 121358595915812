
/**
 * @deprecated Use Customer from CustomerTypes in react folder
 */
export interface CustomerProfile {
  id:                          number;
  branchId:                    number;
  userId?:                     number;
  approvalUserId?:             number;
  extraNumber?:                string;
  effectiveName:               string;
  extension?:                  string;
  extensionId?:                number;
  status:                      CustomerStatus;
  systemDateCreated?:          string;
  customerNumber?:             string;
  customerType:                CustomerType;
  customerSubtype?:            CustomerSubtype;
  // bdaIndividualData  is copy of individualData on customer create or save when customerSubtype == 'BDA'
  bdaIndividualData?:          IndividualData;
  // restrictedIndividualData  is copy of individualData on customer create or save when customerSubtype == 'RESTRICTED'
  restrictedIndividualData?:   IndividualData;
  individualData?:             IndividualData;
  corporateData?:              CorporateData;
  phones:                      Phone[];
  // exists only on frontend
  extractedPhones?:            {home: {number: string}, work: {number: string}, mobile: {number: string}}
  mobilePhoneType:             PhoneType,
  idDocuments:                 IDDocument[];
  addresses:                   Address[];
  totalAnnualIncome:           number;
  incomeSources:               IncomeSource[];
  relatedCustomers:            RelatedCustomer[];
  relatedNonCustomers:         RelatedNonCustomer[];
  pictureFileId:               number;
  referredBy:                  string;
  // exists only on frontend
  pictureFile:                 IFile[];
  externalAccounts:            any[];
  hasInvalidData:              boolean;
  mergedInto:                  number;
  mergedCustomers:             number[];
  creditLineAllowed:           boolean,
  shouldUpdateProfile:         boolean,
  maxComakerTagLimit:          number,
  coMadeLoanIds?:              number[],
  dosri?:                      boolean,
  dosriType?:                  DosriType,
  membershipTypeId:            number;
  isPwd:                       boolean;
  categoryIds:                 number[];
}

export type CustomerType = 'INDIVIDUAL' | 'CORPORATE' | 'GROUP';
export type CustomerSubtype = 'BDA' | 'RESTRICTED';
export type CustomerStatus = 'PROSPECT' | 'REJECTED' | 'ACTIVE' | 'CLOSED';
export type AddressOwnership = 'OWNED' | 'RENTED' | 'MORTGAGED' | 'SHARED';
export type PhoneType = 'BASIC' | 'ANDROID' | 'IOS'
export type BusinessStructure = 'SOLE_PROPRIETORSHIP' | 'PARTNERSHIP' | 'CORPORATION'
export enum DosriType {
  DIRECTOR = 'DIRECTOR',
  OFFICER = 'OFFICER',
  STOCKHOLDER = 'STOCKHOLDER',
  RELATED_INTEREST = 'RELATED_INTEREST'
}

export enum RelatedPartyTransactionType {
  SUBSIDIARY = 'SUBSIDIARY',
  AFFILIATES = 'AFFILIATES',
  INTERLOCKING_DIRECTORSHIP = 'INTERLOCKING_DIRECTORSHIP',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  STRATEGIC_PARTNER = 'STRATEGIC_PARTNER',
  OTHERS = 'OTHERS'
}

export interface Address {
  id?:         number;
  customerId?: number;
  primary:     boolean;
  type:        string;
  stayingSoFar?: number;
  ownership?:    AddressOwnership;
  rentContract?: boolean;
  rentAmount?:   number;
  street:      string;
  barangay:    string;
  postalCode:  string;
  city:        string;
  province:    string;
  countryId:   number;
}

export interface IDDocument {
  id:                  number;
  customerId:          number;
  primary:             boolean;
  documentNumber:      string;
  issuingAuthority:    string;
  documentTypeId:      number;
  countryId:           number;
  documentStatusId:    number;
  dateSubmitted:       string;
  // exists only on frontend
  expirationSupported: boolean;
  expirationDate:      string;
  // exists only on frontend
  photos:              IFile[];
  photoFileIds:        number[];
  remarks:             string;
}

export interface IncomeSource {
  id:                               number;
  customerId:                       number;
  primary:                          boolean;
  type:                             string;
  monthlyIncome:                    number;
  annualIncome:                     number;
  businessType:                     string;
  companyName:                      string;
  noOfPaidEmployees?:               number;
  startOfBusinessDate?:             string;
  description:                      string;
  position:                         string;
  companyTotalAssets:               number;
  landlineNumber?:                  string;
  mobileNumber?:                    string;
  sssNumber:                        string;
  sssPensionTargetAccountId:        number;
  companyAddress:                   Address;
  depEdRegionCode:                  string;
  depEdDivisionCode:                string;
  depEdStationCode:                 string;
  depEdEmployeeNumber:              string;
  businessTin?:                     string;
  businessStructure?:               BusinessStructure
  principalPlaceOfOperations?:      string
  noOfBranches?:                    number;
  avgDailySales?:                   number;
  avgMonthlySales?:                 number;
  avgMonthlyExpenses?:              number;
  otherExistingBankAccounts?:       boolean;
  otherExistingBankAccountsBankId?: number;
  employerName?:                    string;
  employerBranch?:                  string;
  employerType?:                    string;
  employmentStartDate?:             string;
  psicId?:                          number;
}

export interface IndividualData {
  id:                                 number;
  firstName:                          string;
  middleName:                         string;
  lastName:                           string;
  extension?:                         string;
  extensionId?:                       number;
  nickname?:                          string;
  birthDate:                          string;
  birthPlace:                         string;
  birthCountryId:                     number;
  email:                              string;
  mothersFirstName:                   string;
  mothersMiddleName:                  string;
  mothersLastName:                    string;
  pep:                                boolean;
  riskLevelId:                        number;
  vip:                                boolean;
  relatedPartyTransaction:            RelatedPartyTransactionType;
  relatedPartyTransactionDescription: string;
  dosri:                              boolean;
  titleId:                            number;
  genderId:                           number;
  civilStatusId:                      number;
  citizenshipId:                      number;
  residenceId:                        number;
  signatureFileId:                    number;
  totalAnnualIncome:                  number;
  expectedMonthlyTransactionId:       number;
  // exists only on frontend
  signatureFile?:                     IFile[];
  heightMajor:                        number;
  heightMinor:                        number;
  weightMajor:                        number;
  weightMinor:                        number;
}

export interface CorporateData {
  id:                                 number;
  customerId:                         number;
  businessName:                       string;
  email:                              string;
  registrationDate:                   string;
  registrationNumber:                 string;
  registrationCountryId:              number;
  placeOfIncorporation:               string;
  sourceOfFunds:                      string;
  businessTypeId:                     number;
  conglomerateId:                     number;
  riskLevelId:                        number;
  totalAnnualIncome:                  number;
  expectedMonthlyTransactionId:       number;
  relatedPartyTransaction:            RelatedPartyTransactionType;
  relatedPartyTransactionDescription: string;
}

export interface Phone {
  id?:         number;
  customerId?: number;
  primary:     boolean;
  type:        string;
  number:      string;
  planId?:     number;
  monthlyExpenseId?: number;
}

export interface RelatedCustomer {
  id:                 number;
  customerId:         number;
  relativeCustomerId: number;
  typeId:             number;
  withdrawalLimit:    number;
  beneficiary:        boolean;
  dependent:          boolean;
  birthDate:          string;
  customerNumber:     string;
  branchId:           number;
  effectiveName:      string;
}

export interface RelatedNonCustomer {
  id:          number;
  customerId:  number;
  typeId:      number;
  firstName:   string;
  middleName:  string;
  lastName:    string;
  beneficiary: boolean;
  dependent:   boolean;
  birthDate:   string;
}

export interface IFile {
  id?:        number;
  uploaded?:  boolean;
}
