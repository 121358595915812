import nxModule from 'nxModule';
import {printParameterTranslation} from "constants/print";

import './prints-customization.style.less';
import templateUrl from './prints-customization.template.html';


class PrintsCustomization {
  constructor($location) {
    this.$location = $location;
  }

  translate(param) {
    return param in printParameterTranslation ? printParameterTranslation[param] : `<${param}>`;
  }

  configurePrint() {
    this.$location.path(`/admin/prints/${this.printCode}`);
    this.$location.search(this.parameters);
  }
}

nxModule.component('printsCustomization', {
  templateUrl,
  bindings: {
    basePrint: '<',
    printCode: '<',
    printParameters: '<',
  },
  controller: PrintsCustomization
});
