import nxModule from 'nxModule';

import templateUrl from './agent-candidacy-list.template.html';
import {
  AgentApplicationStatus,
  AgentApplicationSystemStatus,
  AgentCandidacy,
  AgentCandidacyDetails
} from './agent-candidacy.types';
import {NgTableParams} from 'ng-table';
import moment, {max, min, Moment} from 'moment';
import {Branch} from 'management/BranchTypes';
import {PageResult} from "tools/HttpTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {ILocationService} from "angular";
import {NxRouteService} from "routes/NxRouteService";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import {BranchService} from "components/service/branch.service";

interface DateRange {
  from: Date;
  to: Date;
}

interface Filter {
  branchIds: number[];
  statuses: string[];
  dateRange: DateRange;
  agentFullNames: string[];
}

interface LabelValue {
  label: string;
  value: string;
}

interface BranchFilter {
  code: string;
  id: number;
  systemDate: string;
}

class AgentCandidacyList {
  candidacyStatuses: LabelValue[] = [];
  availableBranches: BranchFilter[] = [];
  agentsFullNames: LabelValue[] = [];
  filter!: Filter;
  tableConfig!: NgTableParams<AgentCandidacy>;

  constructor(private $location: ILocationService,
              private $route: NxRouteService,
              private authentication: Authentication,
              private confirmation: Confirmation,
              private http: HttpService,
              private command: CommandService,
              private customerCache: CustomerCache,
              private branchService: BranchService,
              private $filter: NxIFilterService) {
  }

  async $onInit(): Promise<void> {
    await this.initFilters();
  }

  async initFilters(): Promise<void> {
    this.agentsFullNames = await this.getAgentCandidaciesFullNames();
    const userBranchIds = this.authentication.context.branchIds;
    const branches = await this.branchService.toPromise();
    this.availableBranches = branches.filter(branch => userBranchIds.includes(branch.id)).map(branch => {
      return {
        id: branch.id,
        code: branch.name,
        systemDate: branch.systemDate
      }
    });

    this.candidacyStatuses = Object.values(AgentApplicationStatus).map(v => {
      return {
        'label': this.$filter('prettyEnum')(v),
        'value': v
      }
    });
    this.filter = {
      branchIds: [this.authentication.context.branchId],
      dateRange: this.getDateRangeForSelectedBranches([this.authentication.context.branchId]),
      statuses: this.candidacyStatuses.map(s => s.value),
      agentFullNames: this.agentsFullNames.map(n => n.value)
    };

    this.tableConfig = new NgTableParams({
      count: 10
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<AgentCandidacy>) => {
        return await this.filterCandidacies(params);
      }
    });
  }

  getDateRangeForSelectedBranches(branchIds: number[]): DateRange {
    const systemDates: Moment[] = this.availableBranches.filter(b => !branchIds[0] || branchIds.includes(b.id))
      .map(b => moment(b.systemDate));
    return {
      from: min(...systemDates).toDate(),
      to: max(...systemDates).toDate()
    };
  }

  onBranchChange(): void {
    this.filter.dateRange = this.getDateRangeForSelectedBranches(this.filter.branchIds);
  }

  confirmAction(action: string): Promise<boolean> {
    return this.confirmation(`Are you sure you want to ${action} this application?`);
  }

  async getAgentCandidaciesFullNames(): Promise<LabelValue[]> {
    const agentNames = await this.http.get<string[]>('/agents/candidacy/agent-names').toPromise();
    return agentNames.map(n => {
      return {label: n, value: n}
    });
  }

  mapToCandidacyDetails(candidacy: AgentCandidacy, branches: Branch[]): AgentCandidacy & AgentCandidacyDetails {
    return {
      ...candidacy,
      branchName: branches.find(b => b.id === candidacy.branchId)!.name,
    }
  }

  isEditable(candidacy: AgentCandidacy): boolean {
    return AgentApplicationStatus.WAITING_FOR_ACCOUNT_ACTIVATION !== candidacy.status
      && ![
        AgentApplicationSystemStatus.CLOSED,
        AgentApplicationSystemStatus.SENT_FOR_APPROVAL
      ].includes(candidacy.systemStatus);
  }

  edit(candidacy: AgentCandidacy): void {
    if (candidacy.status === AgentApplicationStatus.APPLICATION_FORM_COMPLETE) {
      this.$location.path(`agent-banking/application/edit/${candidacy.id}`)
    } else if (this.isEditable(candidacy)) {
      this.$location.path(`agent-banking/application/edit/status/${candidacy.id}`)
    } else {
      console.error('Non-editable application', candidacy.status, candidacy.systemStatus);
    }
  }

  view(candidacy: AgentCandidacy): void {
    this.$location.path(`agent-banking/application/view/${candidacy.id}`)
  }

  process(candidacy: AgentCandidacy): void {
    this.$location.path(`agent-banking/application/process/${candidacy.id}`)
  }

  async delete(candidacy: AgentCandidacy): Promise<void> {
    if (await this.confirmAction('delete')) {
      const response = await this.command.execute('DeleteAgentCandidacy', {id: candidacy.id}).toPromise();
      if (!response?.approvalRequired) {
        this.$route.reload();
      }
    }
  }

  createAgent(candidacy: AgentCandidacy): void {
    this.$location.path(`/agent-banking/agents/${candidacy.branchId}/agent/create/${candidacy.id}`);
  }

  openCIF(candidacy: AgentCandidacy): void {
    this.$location.path(`/customer/${candidacy.customerId}/profile`);
  }

  canCreateAgent(application: AgentCandidacy): boolean {
    return [AgentApplicationStatus.APPROVED_FOR_DEPLOYMENT].includes(application.status);
  }

  createApplication(): void {
    this.$location.path('agent-banking/application/create');
  }

  async sendForApproval(application: AgentCandidacy): Promise<void> {
    if (await this.confirmation('Do you want send the application for an approval?')) {
      const response = await this.command.execute('SendForApproval', {id: application.id}).toPromise();
      if (!response?.approvalRequired) {
        this.$route.reload();
      }
    }
  }

  async filterCandidacies(params: NgTableParams<AgentCandidacy>): Promise<(AgentCandidacy & AgentCandidacyDetails)[]> {
    const branches: Branch[] = await this.branchService.toPromise();
    const searchResults = await this.http.post<PageResult<AgentCandidacy>>(`/agents/candidacy/search`, {
      pageNo: params.page() - 1,
      pageSize: params.count(),
      branchIds: this.filter.branchIds[0] ? this.filter.branchIds : [],
      createdOnFrom: this.$filter('nxDate')(this.filter.dateRange.from),
      createdOnTo: this.$filter('nxDate')(this.filter.dateRange.to),
      statuses: this.filter.statuses,
      agentFullNames: this.filter.agentFullNames
    }).toPromise();
    params.total(searchResults.totalCount);
    return searchResults.result.map(c => this.mapToCandidacyDetails(c, branches));
  }

  reloadTable() {
    this.tableConfig.page(1);
    this.tableConfig.reload();
  }
}

nxModule.component('agentCandidacyList', {
  templateUrl,
  controller: AgentCandidacyList
});
