import nxModule from 'nxModule';

import './dynamic-report.style.less';
import templateUrl from './dynamic-report.template.html';


class DynamicReport {
  constructor(confirmation, command, dynamicReportService, notification, $location, $route) {
    this.confirmation = confirmation;
    this.command = command;
    this.dynamicReportService = dynamicReportService;
    this.notification = notification;
    this.$location = $location;
    this.$route = $route;
  }

  $onInit() {
    this.dynamicReportService.updateBreadcrumb(this.report);
  }

  addNew() {
    this.$location.path(`/admin/dynamic-report/${this.report.id}/file/create`);
  }

  edit(file) {
    this.$location.path(`/admin/dynamic-report/${this.report.id}/file/${file.id}`);
  }

  async delete(file) {
    const actionApproved = await this.confirmation(`Do you want to remove file ${file.name}?`);
    if(!actionApproved) {
      return;
    }

    await this.command.execute('DeleteDynamicReportFile', {id: file.id}).toPromise();
    this.$route.reload();
  }

  async save() {
    const message = this.report.id ? "Successfully edited dynamic report" : "Successfully created dynamic report";
    const resp = await this.command.execute('UpdateDynamicReport', {report: this.report})
      .success(() => this.notification.show(message), true)
      .toPromise();
    if(!this.report.id) {
      this.$location.path(`/admin/dynamic-report/${resp.output.id}`);
    }
  }

  cancelChanges() {
    this.goBack();
  }

  goBack() {
    this.$location.path('/admin/dynamic-report');
  }
}

nxModule.component('dynamicReport', {
  templateUrl,
  bindings: {
    report: '<'
  },
  controller: DynamicReport
});