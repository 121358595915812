import React, {ReactElement, ReactNode, useState} from "react";
import {
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikSubmitButton,
  NxFormikSelect,
  NxInput,
  NxLoader,
  NxPopup,
  NxRow,
  NxRowPosition,
  NxStack
} from "@nextbank/ui-components";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import {CommandOutputWrapper} from "command/CommandTypes";
import {useCommand} from "command/CommandService";
import NxForm from "form/NxForm";
import {Account, ProductDefinition} from "components/service/product.types";
import {useHistory, useParams} from "react-router";
import useAxios from "axios-hooks";
import {CustomerProfile} from "components/customer/profile/customer-profile.types";
import {AccountType} from "components/service/account.types";
import NxCancelButton from 'NxCancelButton';

interface ChangeAccountTypeInput {
  productId: number;
  targetProductDefinitionId: number;
}

interface ChangeAccountTypeFormInput {
  targetProductDefinitionId: number;
}

const RequiredNumber = Yup.number()
  .required()
  .positive();


const ChangeAccountType = () : ReactElement => {
  const {accountId, customerId} = useParams<{accountId: string, customerId: string}>();
  const execute = useCommand();
  const history = useHistory();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [{data: account}] = useAxios<Account>(`/products/accounts/${accountId}`);
  const [{data: customer}] = useAxios<CustomerProfile>(`/customers/${customerId}`);
  const [{data: allProductDefinitions}] = useAxios<ProductDefinition[]>(`/products/definitions`);
  const [{data: accountTypes}] = useAxios<AccountType[]>(`/products/accounts/types`);


  const header = <NxHeader>Change account type</NxHeader>;
  if(!account || !allProductDefinitions || !customer || !accountTypes) {
    return <NxPage>{header}<NxLoader/></NxPage>;
  }

  const accountTypeDefinitions = allProductDefinitions.filter(pd => pd.productGroup === 'ACCOUNT')
    .filter(pd => !pd.customerTypeConstraints || pd.customerTypeConstraints.length === 0 || pd.customerTypeConstraints.includes(customer.customerType))
    .filter(pd => !pd.expired)
    .filter(pd => pd.productAvailability === 'AVAILABLE_IN_ALL_BRANCHES' || (pd.productAvailability === 'AVAILABLE_IN_SELECTED_BRANCHES' && pd.availableInBranchIds.includes(account.branchId)));

  const ChangeAccountTypeFormSchema: SchemaOf<ChangeAccountTypeFormInput> = Yup.object().shape({
    targetProductDefinitionId: RequiredNumber.notOneOf([account.definitionId], "Target account type must be different from account's current type")
  });

  return <NxPage>
          {header}
          <NxFormik<ChangeAccountTypeFormInput>
            initialValues={{
              targetProductDefinitionId: account.definitionId
            }}
            validationSchema={ChangeAccountTypeFormSchema}
            onSubmit={async (input: ChangeAccountTypeFormInput): Promise<void> => {
              setShowPopup(false);
              const response: CommandOutputWrapper<void> = await execute<ChangeAccountTypeInput, void>({
                name:'ChangeAccountType',
                input: {
                  ...input,
                  productId: Number(accountId)
                }});

              if(!response.approvalRequired) {
                history.goBack();
              }
            }}
          >
            {({
                isValid,
                values,
                submitForm,
              }): ReactElement => {
              return <NxForm>
                  <NxStack>
                    <NxInput label="Product number" value={account.productNumber} disabled />
                    <NxFormikSelect<number>
                      name='targetProductDefinitionId'
                      label='New Product name'
                      options={accountTypeDefinitions.map(pd => (
                        {
                          label: pd.productName,
                          value: pd.id!
                        }
                      ))}/>
                    <NxRow position={NxRowPosition.END}>
                      <NxCancelButton />
                      <NxFormikSubmitButton
                        variant={NxButtonVariant.SAVE}
                        onClick={() : void =>   setShowPopup(true)}
                      >
                        Update
                      </NxFormikSubmitButton>
                    </NxRow>
                  </NxStack>
                  <NxPopup header='Confirm'
                           open={showPopup && isValid}
                           description={createConfirmationMessage(accountTypeDefinitions, accountTypes, values.targetProductDefinitionId)}>
                    <NxRow position={NxRowPosition.END}>
                      <NxButton variant={NxButtonVariant.CLOSE}
                                onClick={() : void => setShowPopup(false)}>
                        No
                      </NxButton>
                      <NxFormikSubmitButton
                        variant={NxButtonVariant.SAVE}
                        onClick={(): Promise<void> => submitForm()}>
                        Yes
                      </NxFormikSubmitButton>
                    </NxRow>
                  </NxPopup>
                </NxForm>;
            }
          }
        </NxFormik>
  </NxPage>;
};

const createConfirmationMessage = (accountTypeDefinitions: ProductDefinition[], accountTypes: AccountType[], targetProductDefinitionId: number): ReactNode => {
  const targetProductDefinition = accountTypeDefinitions.find(pd => pd.id === targetProductDefinitionId);
  const targetAccountType = accountTypes.find(at => at.productDefinition.id === targetProductDefinitionId);
  const isCsAccount = targetAccountType && ['CONTRACTUAL_SAVINGS_CBU', 'CONTRACTUAL_SAVINGS_PF', 'CONTRACTUAL_SAVINGS_TP'].includes(targetAccountType?.purpose);
  return <>
      <div>Are you sure that you want to change the account type to {targetProductDefinition?.productName}?</div>
      {isCsAccount && <div>Changing to a CS Account type cannot be reverted.</div>}
    </>;
};

export default ChangeAccountType;
