import CollateralList from 'customer/collateral/CollateralList';
import CreateCollateral from 'customer/collateral/CreateCollateral';
import UpdateCollateral from 'customer/collateral/UpdateCollateral';
import RouteDefinition from "routes/RouteDefinition";
import {CustomerProfile} from "components/customer/profile/customer-profile.types";
import axios from "axios";

const routes: RouteDefinition[] = [
  {
    name: async ({customerId}: {customerId: string}): Promise<string> => {
      const {data: customer} = await axios.get<CustomerProfile>(`/customers/${customerId}`);
      return customer.effectiveName ?? '??';
    },
    path: '/customer/:customerId'
  },
  {
    name: 'Collaterals',
    path: '/customer/:customerId/collaterals',
    Component: CollateralList
  },
  {
    name: 'Add collateral',
    path: '/customer/:customerId/collaterals/add',
    Component: CreateCollateral,
    commandAccess: ['CreateCollateral']
  },
  {
    name: 'Update collateral',
    path: '/customer/:customerId/collaterals/:collateralId',
    Component: UpdateCollateral,
    commandAccess: ['UpdateCollateral']
  }
];

export default routes;