import nxModule from 'nxModule';
import BigNumber from "bignumber.js";
import _ from 'lodash';
import {GenerationStrategies} from 'constants/numberGenerator';

const templateUrl = require('./term-deposit-section.template.html');

nxModule.component('termDepositSection', {
  templateUrl,
  bindings: {
    sectionType: '<',
    generalForm: '=',
    interestsForm: '=',
    chargesForm: '=',
    advancedForm: '=',
    deposit: '<',
    branches: '<'
  },
  controller: function ($scope, casaTypesCache, brisReportingTypesCache) {

    const that = this;

    that.isNewDeposit = true;

    that.casaTypes = [];
    that.pdicCasaTypes = [];

    that.depositGroups = [{
      label: 'Time deposit',
      value: 'TD'
    }, {
      label: 'Special savings account',
      value: 'SSA'
    }];

    that.depositSubgroups = [{
      label: 'Standard',
      value: 'STANDARD'
    }, {
      label: 'Pledge agreement',
      value: 'PLEDGE'
    }, {
      label: 'Advance interest',
      value: 'ADVANCE_INTEREST'
    }];

    that.interestCalculationStrategies = [{
      label: 'Average daily balance',
      value: 'ADB'
    }, {
      label: 'Pledge agreement schedule',
      value: 'PLEDGE_AGREEMENT_SCHEDULE_AND_ACCRUED_INTEREST'
    }];

    that.cycleTypes = [{
      label: 'Daily',
      value: 'DAY'
    }, {
      label: 'Monthly',
      value: 'MONTH'
    }, {
      label: 'Month End',
      value: 'MONTH_END'
    }];

    that.maturityStrategyOptions = [{
      label: 'Wait for customer arrival',
      value: 'NONE'
    }, {
      label: 'Auto renewal with interests',
      value: 'RENEW_WITH_INTEREST'
    }, {
      label: 'Auto renewal w/o interests',
      value: 'RENEW_WITHOUT_INTEREST'
    }];

    that.creditingStrategyOptions = [{
      label: 'Cash on arrival',
      value: 'CASH_WITHDRAWAL'
    }, {
      label: 'Check on arrival',
      value: 'CHECK_WITHDRAWAL'
    }, {
      label: 'Post-dated checks on opening',
      value: 'PDC_WITHDRAWAL'
    }, {
      label: 'Product transfer',
      value: 'PRODUCT_TRANSFER'
    }, {
      label: 'Compounded to principal',
      value: 'COMPOUNDED'
    }];

    that.creditingStrategies = [{
      label: 'Not allowed',
      model: false
    }, {
      label: 'Allowed',
      model: true
    }];

    that.placementStrategies = [{
      label: 'Only on activation',
      value: 'NONE'
    }, {
      label: 'Anytime',
      value: 'ADHOC'
    }, {
      label: 'Only after interest crediting',
      value: 'AFTER_CREDITING'
    }];

    that.passbookConfigOptions = [{
      label: 'Yes',
      value: 'PASSBOOK_NOT_REQUIRED'
    }, {
      label: 'No',
      value: 'PASSBOOK_REQUIRED'
    }, {
      label: 'With override',
      value: 'WITH_OVERRIDE'
    }];

    that.certificateNumberGenerationStrategy = GenerationStrategies;

    that.certificateNumberGenerationOptions = [{
      header: 'Separator',
      iconClass: 'icon-minus',
      text: '\u2212',
      pattern: '-'
    }, {
      header: 'Branch code',
      iconClass: 'icon-home',
      pattern: 'B',
      text: 'B',
    }, {
      header: 'Sequence number branch',
      iconClass: 'icon-asterisk',
      pattern: 'N',
      text: 'N',
    }];


    that.daysInYearOptions = [360, 365];
    that.maxDaysInYearOption = Math.max(...that.daysInYearOptions);

    that.passbookSupportOptions = [{
      label: 'Passbook',
      value: true
    }, {
      label: 'Certificate',
      value: false
    }];

    that.pledgeAmountCalculationTypes = [{
      label: 'By pledge installment amount',
      value: 'INSTALLMENT_AMOUNT',
    },{
      label: 'By total deposit',
      value: 'TOTAL_AMOUNT',
    }];

    that.initialPlacementTypes = [{
      label: 'Fixed amount',
      value: 'FIXED_AMOUNT'
    }, {
      label: 'First pledge amount',
      value: 'FIRST_PLEDGE_AMOUNT'
    }, {
      label: 'On creation date',
      value: 'ON_CREATION_DATE'
    }];

    that.advancePlacementTypes = [{
      label: 'None',
      value: 'NONE'
    }, {
      label: 'Current Pledge Installment',
      value: 'CURRENT_PLEDGE_INSTALLMENT'
    }, {
      label: 'Total Pledge Installment',
      value: 'TOTAL_PLEDGE_INSTALLMENT'
    }];

    const casaTypesSub = casaTypesCache.toObservable().subscribe(casaTypes => {
      that.casaTypes = casaTypes;
      that.pdicCasaTypes = _.filter(that.casaTypes, {'regulatorType': 'PDIC'});
    });

    const brisReportingTypesSub = brisReportingTypesCache.withParam('DEPOSIT').toObservable().subscribe(brisTypes => {
      that.brisTypes = brisTypes;
    });

    $scope.$watch('$ctrl.deposit', () => {
      if (that.deposit) {
        that.isNewDeposit = !that.deposit.id;

        if (!that.deposit.maturityStrategies) that.deposit.maturityStrategies = [];
        if (that.deposit.creditingStrategies) {

          if (that.deposit.creditingStrategies.length === 1 &&
            that.deposit.creditingStrategies[0] === 'NOT_ALLOWED') {
            that.creditingStrategiesValue = false;
          } else {
            that.creditingStrategiesValue = true;
          }
        }
        that.initialPretermInterestRate = that.deposit.pretermInterestRate;
        that.initialPretermSecondInterestRate = that.deposit.pretermSecondInterestRate;

        if (that.deposit.passbookConfig && that.deposit.passbookConfig !== 'PASSBOOK_NOT_SUPPORTED') {
          that.passbookSupported = true;
          that.passbookConfig = that.deposit.passbookConfig;
        } else {
          that.passbookSupported = false;
        }
      } else {
        that.isNewDeposit = false;
      }
    });

    $scope.$watch('$ctrl.deposit.interestRate', () => {
      if (that.deposit)
        that.reevaluatePretermInterests();
    });

    that.reevaluatePretermInterests = () => {
      const interestRate = that.deposit.interestRate;
      if (!that.overridePretermInterestRate) {
        if (that.initialPretermInterestRate) {
          that.deposit.pretermInterestRate = that.initialPretermInterestRate;
        } else if (interestRate) {
          that.deposit.pretermInterestRate = new BigNumber(interestRate).dividedBy(4).dp(0).toNumber();
        }
      }
      if (!that.overridePretermSecondInterestRate) {
        if (that.initialPretermSecondInterestRate) {
          that.deposit.pretermSecondInterestRate = that.initialPretermSecondInterestRate;
        } else if (interestRate) {
          that.deposit.pretermSecondInterestRate = new BigNumber(interestRate).dividedBy(2).dp(0).toNumber();
        }
      }
    };

    that.onCreditingInterestsChange = () => {
      if (that.creditingStrategiesValue === false) {
        that.deposit.creditingStrategies = ['NOT_ALLOWED'];
      } else {
        that.deposit.creditingStrategies = [];
      }
    };

    that.transferCreditingStrategyEnabled = () => {
      return that.deposit
        && that.deposit.creditingStrategies
        && that.deposit.creditingStrategies.length > 0
        && _.includes(that.deposit.creditingStrategies, 'PRODUCT_TRANSFER');
    };

    that.passbookConfigChange = () => {
      if (!that.passbookSupported) {
        that.deposit.passbookConfig = 'PASSBOOK_NOT_SUPPORTED';
      } else {
        that.deposit.passbookConfig = that.passbookConfig;
      }
    };

    that.depositSubgroupChanged = () => {
      if(that.deposit.depositSubgroup === 'ADVANCE_INTEREST') {
        that.deposit.accrualEnabled = false;
      }
    }

    // Release subscriptions
    that.$onDestroy = () => {
      casaTypesSub.unsubscribe();
      brisReportingTypesSub.unsubscribe();
    };

    that.isPledgeAgreementConfigurationValid = () => {
      if (that.deposit?.depositSubgroup === 'PLEDGE') {
        return ['ADHOC', 'AFTER_CREDITING'].includes(that.deposit.placementStrategy);
      }
      return true;
    };

    that.isPlacementConfigurationValid = () => {
      return that.deposit?.depositSubgroup !== 'PLEDGE' || !that.deposit?.partialInitialPlacementAllowed;
    };

    that.isAdvanceInterestConfigurationValid = () => {
      if(that.deposit?.depositSubgroup !== 'ADVANCE_INTEREST') {
        return true;
      }

      return !that.deposit.partialWithdrawalAllowed
        && !that.deposit.partialInitialPlacementAllowed
        && that.deposit.placementStrategy === 'NONE';
    };

    that.isTermCalculationConfigValid = () => {
      if(that.deposit?.termCalculationMethod !== 'INTERVAL') {
        return true;
      }
      //only 1 should be enabled
      return that.deposit?.accrualEnabled ? !that.creditingStrategiesValue : that.creditingStrategiesValue;
    }

    that.isInterestAndTaxConfigurationValid = () => {
      if(that.deposit?.depositSubgroup !== 'ADVANCE_INTEREST') {
        return true;
      }
      return !that.deposit?.collectAdvanceWithholdingTax
        && !that.deposit?.accrualEnabled;
    }

    that.isCreditingStrategyConfigurationValid = () => {
      if(that.deposit?.creditingStrategies.includes('COMPOUNDED')) {
        return that.deposit.depositSubgroup === 'PLEDGE';
      }
      return true;
    };

    that.isInterestCalculationBasisValid = () => {
      if (that.deposit?.initialPlacementType === 'ON_CREATION_DATE') {
        return that.deposit?.interestCalculationBasis === 'PLEDGE_AGREEMENT_SCHEDULE_AND_ACCRUED_INTEREST';
      }
      return that.deposit?.interestCalculationBasis !== 'PLEDGE_AGREEMENT_SCHEDULE_AND_ACCRUED_INTEREST'
        || (that.deposit?.initialPlacementType === 'FIRST_PLEDGE_AMOUNT'
        && ['MONTH', 'MONTH_END'].includes(that.deposit?.accrualCycleType)
        && that.deposit?.withholdingTax?.percentageAmount === 0
        && that.deposit?.withholdingTax?.extraOptions?.WITHHOLDING_TAX_BOARD == null);
    }

    that.updatePlacementConfiguration = () => {
      if(that.deposit?.depositSubgroup !== 'PLEDGE') {
        that.deposit.pledgeAmountCalculationType = null;
        that.deposit.initialPlacementType = null;
        that.deposit.advancePlacementType = null;
        that.deposit.pledgeGracePeriodDays = null;
        that.deposit.applyOvermatureInterestRateOnMissedPaymentEnabled = false;
      }
    }
  },
});
