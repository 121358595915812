import nxModule from 'nxModule';

import './resolve-processing-issues.style.less';
import templateUrl from './resolve-processing-issues.template.html';
import {Branch} from "management/BranchTypes";
import {CommandOutputWrapper} from "command/CommandTypes";
import {NxRouteService} from "routes/NxRouteService";
import {BranchService} from "components/service/branch.service";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

interface ProcessingIssue {
  id: number;
  branchId: number;
  systemDate: Date;
  registrationTime: string;
  taskType: string;
  executionContext: string;
  errorCode?: string;
  stackTrace: string;
  resolved: boolean;
}

interface ProcessingIssueDetails extends ProcessingIssue {
  branch: Branch,
  shortenedStackTrace: string
}

class ResolveProcessingIssues {
  processingIssues: ProcessingIssueDetails[] = [];

  constructor(private http: HttpService, private branchService: BranchService, private confirmation: Confirmation,
              private command: CommandService, private $route: NxRouteService) {
  }

  async $onInit(): Promise<void> {
    const [issues, branches]: [ProcessingIssue[], Branch[]] = await Promise.all([
      this.http.get<ProcessingIssue[]>('/system/unresolved-processing-issue').toPromise(),
      this.branchService.toPromise()
    ]);

    this.processingIssues = issues.map(issue => ({
      ...issue,
      shortenedStackTrace: issue.stackTrace.substring(0, 400),
      branch: branches.find(b => b.id === issue.branchId)!
    }));
  }

  async resolveIssue(processingIssue: ProcessingIssueDetails): Promise<void> {
    const proceed = await this.confirmation("Are you sure you want to mark issue as resolved?");

    if (!proceed) {
      return;
    }

    const output: CommandOutputWrapper<any> = await this.command.execute('MarkProcessingIssueAsResolved', {
      processingIssueId: processingIssue.id
    }).toPromise();

    if(!output.approvalRequired) {
      this.processingIssues = this.processingIssues.filter(p => p.id !== processingIssue.id);
    }
  }
}

nxModule.component('resolveProcessingIssues', {
  templateUrl,
  controller: ResolveProcessingIssues
});
