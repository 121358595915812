import nxModule from 'nxModule';

import templateUrl from './prints-details-custom-variable-configuration.template.html'
import {reorderLines} from "./print.utils";

import './prints-details-label-configuration.style.less';
import {
  CustomVariableConfig,
  EffectiveNameFormat,
  nameFormatTypes,
  Print,
  PrintLine,
  PrintLineType,
  printVariableTypes,
  VariableType
} from "print/PrintType";

class PrintsDetailsCustomVariableConfiguration {
  private print!: Print;
  private onChange!: () => void;
  private printVariableType: VariableType = 'USER';
  private nameFormat?: EffectiveNameFormat;
  private field!: string | undefined;
  private text: string | undefined;
  private required: boolean = false;

  protected printVariableTypes = printVariableTypes;
  protected nameFormatTypes = nameFormatTypes;

  getCustomVariables(): PrintLine[] {
    return this.print.lines.filter(line => line.type === 'CUSTOM');
  }

  addCustomVariable(): void {
    if(!this.field || !this.printVariableType || !this.text) {
      return;
    }
    const customVariableConfig : CustomVariableConfig = {
      'CUSTOM_VARIABLE_TYPE': this.printVariableType,
      'REQUIRED': this.required
    };

    if(['CUSTOMER', 'USER'].includes(this.printVariableType) && this.nameFormat) {
      customVariableConfig.EFFECTIVE_NAME_FORMAT = this.nameFormat;
    }

    this.print.lines.push({
      field: this.field,
      type:  PrintLineType.CUSTOM,
      fontSize: 8,
      x: 1,
      y: 1,
      maxLength: 0,
      paddingCharacter: '',
      fieldLengthIncludingPadding: 0,
      active: false,
      text: this.text,
      exampleValue: this.field,
      customVariableConfig: customVariableConfig
    });

    this.printVariableType = 'USER';
    this.field = undefined;
    this.required = false;
    this.text = undefined;
    this.nameFormat = 'INVERTED';
  }

  hasDuplicateNames(): boolean {
    if (!this.field) {
      return false;
    }
    return this.print.lines.findIndex(l => l.field === this.field) !== -1;
  }

  isFieldNameValid(): boolean {
    if (!this.field) {
      return false;
    }
    return !this.hasDuplicateNames();
  }

  removeLine(line: PrintLine): boolean {
    this.print.lines.splice(this.print.lines.indexOf(line), 1);

    reorderLines(this.print);
    return true;
  }

  clearItem(printLine: PrintLine): void {
    if(!printLine.printVariableType || !['CUSTOMER', 'USER'].includes(printLine.printVariableType)) {
      delete printLine.customVariableConfig.EFFECTIVE_NAME_FORMAT;
    }
  }

  clear(): void {
    if(!this.printVariableType || !['CUSTOMER', 'USER'].includes(this.printVariableType)) {
      this.nameFormat = undefined;
    }
  }
}

nxModule.component('printsDetailsCustomVariableConfiguration', {
  templateUrl,
  bindings: {
    print: '=',
    onChange: '<',
    newLabel: '='
  },
  controller: PrintsDetailsCustomVariableConfiguration,
});