import nxModule from 'nxModule';
import {storagePrefix} from './localCache';

class RefreshService {
  constructor(customerCache, groupCustomerCache, branchHolidayCache, CacheFactory) {
    this.customerCache = customerCache;
    this.groupCustomerCache = groupCustomerCache;
    this.branchHolidayCache = branchHolidayCache;
    this.CacheFactory =  CacheFactory;
  }

  clearCaches() {
    this.CacheFactory.keys()
      .forEach(key => this.clearCache(key));

    this.clearLocalStorage();

    // the following has internal map of caches that we also need to clear
    this.customerCache.evictWithoutCustomerId();
    this.groupCustomerCache.evictWithoutCustomerId();
    this.branchHolidayCache.evict();
  }

  clearCache(cacheName) {
    this.CacheFactory.get(cacheName).removeAll();
  }

  clearLocalStorage = () => {
    // evict cache from local storage in case cache reference is lost
    const cacheKeyPrefix = `${storagePrefix}`;
    const nonVolatileKeyPrefix = `nx__nonvolatile__`;

    for(let key of Object.keys(localStorage)) {
      if (key.startsWith(cacheKeyPrefix)
          && !key.startsWith(nonVolatileKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }
  }

  refreshPage({clearCache = true, clearToken = false} = {}) {
    if (clearCache) {
      this.clearCaches();
    }

    if (clearToken) {
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
    }

    window.location.reload(true);
  }
};

export default RefreshService;

nxModule.service('refreshService', RefreshService);