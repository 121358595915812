import nxModule from 'nxModule';

const templateUrl = require('./related-people.template.html');

class CustomerProfileRelatedPeople {

  constructor() {}

  getMinRelatedPeople() {
    return this.minRelatedPeople || 0;
  }

  countRelatedPeople() {
    // Corporate customers need related customers to pass validation.
    if (this.profile.customerType === 'CORPORATE') {
      return this.profile.relatedCustomers?.length
    }
    return this.profile.relatedCustomers?.length + this.profile.relatedNonCustomers?.length
  }

}

nxModule.component('customerProfileRelatedPeople', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'minRelatedPeople' : '<'
  },
  controller: CustomerProfileRelatedPeople
});
