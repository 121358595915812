import React, {ReactElement} from 'react';
import {StatusDetails} from "./StatusType";
import StatusView from "./StatusView";
import statusService from "../statusService";
import {NxLoader} from "@nextbank/ui-components";

type State = {status: null | StatusDetails};
class StatusContainer extends React.Component<Record<string, unknown>, State> {
  state: State = {
    status: null
  };

  async componentDidMount(): Promise<void> {
    const status = await statusService.getStatus();
    this.setState({
      status,
    });
  }

  render(): ReactElement {
    return this.state.status ? <StatusView status={this.state.status} /> : <NxLoader />;
  }
}

export default StatusContainer;