import nxModule from 'nxModule';

nxModule.directive('validateUploaded', function(){
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attr, ngModel) {
      if(!ngModel) return;

      scope.$watch(attr.ngModel, function() {
        validate();
      });

      attr.$observe('validateUploaded', function (val) {
        validate();
      });

      let validate = function() {
        const files = ngModel.$modelValue || [];
        ngModel.$setValidity('validateUploaded', files.filter(file => file.uploaded === false).length === 0);
      };
    }
  }
});
