import ReportService from 'components/report/report.service';
import {AttachedFile} from 'components/service/file.service';
import {UPDATE_GEO_LOCATION_SAMPLE_FILE} from 'components/support/misc/update-geo-location-dictionary/update-geo-location-types';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './update-geo-location-dictionary.template.html';

class UpdateGeoLocationDictionary {

  protected contentFile: AttachedFile[] = [];

  constructor(private reportService: ReportService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private $route: NxRouteService) {

  }

  async downloadXls(): Promise<void> {
    const sampleFileUrl = window.URL.createObjectURL(UPDATE_GEO_LOCATION_SAMPLE_FILE);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = "update-geo-location.xlsx";
    a.click();
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to update geo location dictionary?',
    });

    if (!confirmed) {
      return;
    }

    await this.command.execute('UpdateGeoLocationDictionary', {
      fileId: this.contentFile[0].id
    }).toPromise();
    this.$route.reload();

  }
}

nxModule.component('updateGeoLocationDictionary', {
  controller: UpdateGeoLocationDictionary,
  templateUrl
})