import nxModule from 'nxModule';

import 'shared/utils/notification';

const templateUrl = require('./internet-check.template.html');

nxModule.component('internetCheck', {
  templateUrl: templateUrl,
  controller: function ($scope, $timeout, $http, notification, internetCheckService) {

    $scope.internetCheck = internetCheckService;

    let checkFrequency = 1000;

    function init() {
      $timeout(function () {
        internetCheckService.retrySecondsLeft = internetCheckService.retrySecondsLeft - 1;

        if (internetCheckService.retrySecondsLeft <= 0) {
          $scope.check(false);
        } else {
          // reschedule
          init();
        }

      }, checkFrequency);
    }

    $scope.check = function (verbose) {
      $http.get('/no-cache/ping.json', {timeout: 5000, headers: {'Authorization': undefined}, nxLoaderSkip: true})
        .then(() => {
          internetCheckService.internetConnection = true;
          internetCheckService.reportSuccess();
          if (verbose) {
            notification.show('Success', 'Internet connectivity restored!');
          }
          init();
        })
        .catch(() => {
          internetCheckService.internetConnection = false;
          internetCheckService.retrySecondsLeft = internetCheckService.retryInterval;
          if (verbose) {
            notification.show('Error', 'Could not connect to server!');
          }
          init();
        })
    };

    init();
  }
});