import {NxButtonLink, NxButtonVariant, NxTable, NxTableColumn} from "@nextbank/ui-components";
import React, {ReactElement, useEffect, useState} from "react";
import NxHeader from "../form/NxHeader";
import NxPage from "../form/NxPage";
import DepositoryAccount from "./DepositoryAccountTypes";
import useAxios from "axios-hooks";
import CurrencyFormatter from "../tools/CurrencyFormatter";
import tokenStore from "../authentication/tokenStore";
import {Branch} from "management/BranchTypes";

interface DepositoryAccountDetails extends DepositoryAccount {
  no: number;
  amountText: string;
  branch: Branch
}

const columns: NxTableColumn<DepositoryAccountDetails>[] = [{
  title: "No",
  field: "no",
}, {
  title: "Branch",
  field: "branch.name",
}, {
  title: "Account name",
  field: "accountName",
}, {
  title: "Amount",
  field: "amountText",
}, {
  title: "Account number",
  field: "accountNumber",
}, {
  title: "BRSTN",
  field: "brstn",
}, {
  title: "Clearing",
  field: "clearing",
}, {
  title: "Ledger Account Code",
  field: "ledgerAccountCode",
}, {
  title: "Date opened",
  field: "dateOpened",
}];

const currencyFormatter = new CurrencyFormatter();
const DepositoryAccountList = (): ReactElement => {
  const [depositoryAccounts, setDepositoryAccounts] = useState<DepositoryAccountDetails[] | null>(null);
  const [{data: accounts}] = useAxios<DepositoryAccount[]>('/ledger/depository-accounts');
  const [{data: branches}] = useAxios<Branch[]>('/management/branches');

  useEffect(() => {
    if(!accounts || !branches) {
      return;
    }

    const tokenDetails = tokenStore.getTokenDetails();
    const depositoryAccounts: DepositoryAccountDetails[] = accounts
      .filter(acc => tokenDetails.branchIds.includes(acc.branchId))
      .filter(acc => acc.allowCheckManagement && acc.clearing)
      .map((acc, i) => ({
          no: i + 1,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          branch: branches.find(b => b.id === acc.branchId)!,
          amountText: currencyFormatter.format(acc.amount),
          ...acc
        }));

    setDepositoryAccounts(depositoryAccounts);
    }, [accounts, branches]
  );

  const LinkToCheckDisbursement = ({data}: {data: DepositoryAccountDetails}): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`/check-preparation/check-management/depository-accounts/${data.id}/checkbooks`}>Checks</NxButtonLink>


  return <NxPage>
    <NxHeader>
      Depository accounts
    </NxHeader>
    <NxTable
      columns={columns}
      rowActions={[LinkToCheckDisbursement]}
      data={depositoryAccounts ?? []}
      isLoading={depositoryAccounts === null} />
  </NxPage>;
};

export default DepositoryAccountList;