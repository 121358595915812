import nxModule from 'nxModule';
import templateUrl from './issue-passbook.template.html';

class IssuePassbook {

  constructor($route, $location, customerCache, productDefinitionService, confirmationTemplate, command, passbookService,
              modalPrintPreviewService) {
    this.$route = $route;
    this.$location = $location;
    this.customerCache = customerCache;
    this.productDefinitionService = productDefinitionService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.passbookService = passbookService;
    this.modalPrintPreviewService = modalPrintPreviewService;
  }

  $onInit() {
    this.initProductInfo();
    this.fillProductData();
  }

  initProductInfo() {
    this.customerId = this.$route.current.params['customerId'];

    const productTypes = {
      ACCOUNT: 'accounts',
      TERM_DEPOSIT: 'term-deposits'
    };

    if (this.productGroup === 'DEPOSIT_ACCOUNT') {
      this.productType = productTypes.ACCOUNT;
      this.productId = this.$route.current.params.accountId;
      this.productsCache = this.customerCache.depositAccounts(this.customerId);
      this.passbookCoverPrintCode = 'DEPOSIT_ACCOUNT_PASSBOOK_COVER';
      this.commandName = 'CreateDepositAccountPassbook';
    } else {
      this.productType = productTypes.TERM_DEPOSIT;
      this.productId = this.$route.current.params.depositId;
      this.productsCache = this.customerCache.termDeposits(this.customerId);
      this.passbookCoverPrintCode = 'TERM_DEPOSIT_PASSBOOK_COVER';
      this.commandName = 'CreateTermDepositPassbook';
    }

    this.passbook = {productId: this.productId};
  }

  async fillProductData() {
    const [products, productDefinitions] = await Promise.all([this.productsCache.toPromise(), this.productDefinitionService.toPromise()]);

    const product = products.find(product => Number(product.id) === Number(this.productId));
    const productType = productDefinitions.find(definition => Number(definition.id) === Number(product.definitionId));

    this.product = {...product, productName: productType ? productType.productName : '-', productDefinition: productType};
    this.activePassbook = await this.passbookService.readByProductId(this.productId);
  }

  async issuePassbook() {
    this.confirmationTemplate({
      question: `Do you want to issue a new passbook?`,
      warning: this.activePassbook ? 'Active passbook will be replaced' : null,
      details: this.getDetails(),
      yesCallback: async () => {
        await this.command.execute(this.commandName, this.passbook).toPromise();
        this.refreshCacheAndRedirectBack();
      }
    });
  }

  updatePassbookNumber() {
    if (this.productGroup === 'TERM_DEPOSIT')
      this.commandName = 'UpdateTermDepositPassbookNumber';
    else if (this.productGroup === 'DEPOSIT_ACCOUNT')
      this.commandName = 'UpdateDepositAccountPassbookNumber';

    this.confirmationTemplate({
      question: `Do you want to change the passbook number?`,
      details: this.getDetails(),
      yesCallback: async () => {
        const res = await this.command.execute(this.commandName, this.passbook).toPromise();

        if (res.output) {
          const print = {
            code: this.passbookCoverPrintCode,
            parameters: {
              'PRODUCT_DEFINITION': this.product.productDefinition.id
            }
          };

          await this.modalPrintPreviewService.showAsync({
            printDescription: print,
            printProviderInput: {
              passbookId: res.output.id
            }});

          this.refreshCacheAndRedirectBack();
        }
      }
    });
  }

  refreshCacheAndRedirectBack() {
    this.productsCache.refetch();
    this.redirectBack();
  }

  getDetails() {
    const details = [];
    if (this.activePassbook) {
      details.push({label: 'Active passbook number', description: this.activePassbook.number});
    }
    details.push({label: 'New passbook number', description: this.passbook.number});
    return details;
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/${this.productType}/${this.productId}`);
  }
}

nxModule.component('issuePassbook', {
  templateUrl,
  bindings: {
    editMode: '<',
    productGroup: '<'
  },
  controller: IssuePassbook
});
