import _ from 'lodash';
import nxModule from 'nxModule';
import {sum} from '../../../../shared/common/MathUtils';

const templateUrl = require('./deposit-memo.template.html');
nxModule.component('depositMemo', {
  templateUrl: templateUrl,
  bindings: {
    memoMode: '<',
    memoType: '<'
  },
  controller: function($route, $location, command, customerCache, branchService, productDefinitionService, confirmation, notification, $filter) {
    let that = this;
    that.termDeposit = {};

    // form input
    that.remarks = null;
    that.amount = null;
    that.selectedAccount = null;
    that.categoryUnits = [];

    let customerId = $route.current.params['customerId'];
    let accountId = $route.current.params['accountId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${accountId}`);

    customerCache.termDeposits(customerId).toObservable()
      // Extend product instance with product name
      .combineLatest(productDefinitionService.toObservable(), (termDeposits, products) =>
        termDeposits.map(d => {
          let definition = _.find(products, {id: d.definitionId});
          return Object.assign(d, {productDefinition: definition});
        })
      )
      // Extend product instance with branch name
      .combineLatest(branchService.toObservable(), (termDeposits, branches) =>
        termDeposits.map(d => {
          return Object.assign(d, {branch: _.find(branches, {id: d.branchId})});
        })
      )
      .subscribe(termDeposits => {
        // fetch product mappings for account product type id
        that.termDeposit = _.find(termDeposits, (a) => String(a.id) === accountId);
        return that.termDeposit;
      });

    that.calculateAmount = () => {
      return sum(that.categoryUnits.map(u => u.amount));
    };

    that.doOperation = () => {
      confirmation(`Do you want to ${that.memoMode === 'CREDIT' ? 'credit' : 'debit'} ${$filter('nxCurrency')(that.calculateAmount())}?`, () => {
        let commandName = that.getCommandName();

        command.execute(commandName, {
          productId: accountId,
          amount: that.calculateAmount(),
          entryType: that.memoMode,
          categoryUnits: {
            categoryId: that.category.id,
            units: that.categoryUnits
          },
          remarks: that.remarks
        }, {
          nxLoaderText: 'Processing operation...'
        }).success(() => {
          notification.show("The selected amount has been successfully " + (that.memoMode === 'CREDIT' ? 'credited.' : 'debited.'));
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        }, true);
      });
    };

    that.getCommandName = () => {
      if (that.memoType === 'REGULAR') {
        return that.memoMode === 'CREDIT' ? 'DepositTermDepositFundsByMemo' : 'WithdrawTermDepositFundsByMemo';
      }
      return 'DepositMemoPlacement';
    }
  }
});
