import tokenStore from 'authentication/tokenStore';
import axios from 'axios';
import useAxios, {Options, UseAxiosResult} from 'axios-hooks';
import {Branch} from 'management/BranchTypes';
import currentBranchService from 'management/currentBranchService';

export const useCollection = (options? : Options): UseAxiosResult<Branch[]> => {
  const result = useAxios<Branch[]>('/management/branches', options);
  const [head, ] = result;
  if(head.data){
    head.data.sort((b1, b2) => b1.name.localeCompare(b2.name));
  }

  return result;
};

export const useUserBranches = (options? : Options): UseAxiosResult<Branch[]> => {
  const result = useCollection(options);
  const userBranchIds = tokenStore.getTokenDetails().branchIds;
  const [head, ...tail] = result;
  if(head.data) {
    return [{
      ...head,
      data: head.data.filter(b => userBranchIds.includes(b.id))
    }, ...tail];
  }

  return result;
};


export default class BranchService {
  public async read(id: number): Promise<Branch> {
    const {data} = await axios.get<Branch>(`/management/branches/${id}`);
    return data;
  }

  public async readCollection(): Promise<Branch[]> {
    const {data}: { data: Branch[] } = await axios.get('/management/branches');
    return data.sort((b1, b2) => b1.name.localeCompare(b2.name));
  }

  public async readUserBranches(): Promise<Branch[]> {
    const branches = await this.readCollection();
    const userBranchIds = tokenStore.getTokenDetails().branchIds;
    return branches.filter(b => userBranchIds.includes(b.id));
  }

  public async readCurrentBranch(): Promise<Branch> {
    const branches = await this.readCollection();
    const currentBranch = branches.find(b => b.id === currentBranchService.getBranchId());
    if (!currentBranch) {
      throw new Error(`Cannot find current branch ${currentBranchService.getBranchId()}`);
    }

    return currentBranch;
  }
}