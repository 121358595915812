import nxModule from 'nxModule';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';

const templateUrl = require('./group-loans.template.html');
nxModule.component('groupLoans', {
  templateUrl: templateUrl,
  controller: function ($route, $location, groupCustomerCache, loanProductsCache) {
    let that = this;
    let customerId = $route.current.params['customerId'];
    let batchId = $route.current.params['loanId'];
    that.batch = null;
    that.batches = [];

    that.createNew = () => $location.path(`/customer/${customerId}/group-loans/create`);

    const batchSub = groupCustomerCache.batch(customerId, false)
      .toObservable()
      .combineLatest(loanProductsCache.toObservable(), (batches, loanTypes) => {
          return batches.map(batch => ({
            ...batch,
            loans: batch.loans.map(loan => {
              const type = _.find(loanTypes, {id: loan.typeId});
              return Object.assign(loan, {
                loanType: type
              });
            }),
          }));
        }
      )
      .combineLatest(groupCustomerCache.profile(customerId).toObservable(), (batches, groupCustomer) => {
        that.customerMap = {};
        if (groupCustomer.customerType === 'GROUP' && groupCustomer.customerGroup) {
          for (let el of groupCustomer.customerGroup.customers) {
            that.customerMap[el.id] = el;
          }
        }

        batches.forEach(batch => {
          batch.loans.forEach(loan => {
            loan.customer = that.customerMap[loan.customerId]
          }) // for individual
        });

        return batches;
      })
      .subscribe(batches => {

          // select first account when none selected
          if (!batchId && batches.length > 0) {
            $location.path(`/customer/${customerId}/group-loans/${batches[0].id}`);

            // replace location to make back navigation work
            $location.replace();
          }

          if (batchId) {
            const batch = _.find(batches, (l) => String(l.id) === batchId);
            if (batch) {
              that.batch = batch;
            } else {
              $location.path(`/customer/${customerId}/group-loans`);
            }
          }
          return that.batches = batches;
        }
      );

    that.onBatchChange = ({batch,}) => that.batch = batch;

    that.$onDestroy = () => {
      batchSub.unsubscribe();
    };

  }
});