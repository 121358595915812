import nxModule from 'nxModule';

import templateUrl from './device-details.template.html';
import {Dict} from "shared/common/dict.types";

export interface Device {
  id: number;
  userId: number;
  code: string;
  activated: boolean;
  activatedOn: string;
  activatedBy: number;
  pin: string;
  pinExpirationTime: string;
  serialNumber: string;
  bluetoothPrinterSerialNumber: string;
  creationDate: string;
  modificationDate: string;
  warrantyExpiryDate: string;
  telcoProviderId: number;
  remarks: string;
}

class DeviceDetails {
  public device!: Device;
  public telcoProviders: unknown;

  constructor(private dict: Dict) {}

  async $onInit(): Promise<void> {
    await this.dict.onLoadingCompleteAsync();
    this.telcoProviders = this.dict['TELCO_PROVIDER'];
  }
}

nxModule.component('deviceDetails', {
  templateUrl: templateUrl,
  bindings: {
    device: '='
  },
  controller: DeviceDetails
});
