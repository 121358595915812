import nxModule from 'nxModule';
import $ from 'jquery';
import _ from 'lodash';
import {Subject} from 'rxjs/Subject'
import 'rxjs/add/operator/auditTime';

import './prints-details.style.less';
import templateUrl from './prints-details.template.html';
import angular from "angular";
import {getAttr, getAttrBoolValue, reorderLines} from "./print.utils";

const emptyLabel = {
  type: "LABEL",
  active: false,
  x: 0,
  y: 0,
  fontSize: 8,
  maxLength: 0
};

nxModule.component('printsDetails', {
  templateUrl,
  bindings: {
    printParameters: '<',
    print: '<'
  },
  controller: function ($location, $timeout, $scope, printsCache, confirmation, http, notification,
                        printService, $route, productDefinitionService, fileService) {
    const that = this;

    // always initialize with more powerful editor
    that.print.templateEditorType = 'HTML';

    that.newLabel = {
      ...emptyLabel
    };

    that.setDefaultSampleText = () => {
      that.getAllVariables()
        .filter(line => !line.text)
        .forEach(line => line.text = line.exampleValue);
    };

    that.getAllVariables = () => that.print.lines.filter(line => line.type !== 'LABEL');


    that.$onInit = () => {
      that.setDefaultSampleText();
      reorderLines(that.print);

      $scope.prints = [that.print];
      that.preview();
    };

    if(that.productDefinitionId) {
      productDefinitionService.toPromise()
        .then(defs => defs.find(def => String(def.id) === String(that.productDefinitionId)))
        .then(def => that.productDefinitionName = def.productName);
    }

    that.getPrintName = () => {
      if(!that.print) {
        return "";
      }

      let text = that.print.name;
      if(that.productDefinitionName) {
        text += ` for ${that.productDefinitionName}`;
      }

      return text;
    };



    that.$postLink = () => {
      that.printForm.$setSubmitted();
    };

    const onLoad = () => {
      //Since I couldn't find a way to check if given pdf was rendered (iframe.onLoad is not enough), the
      //500 ms seems to be enough for chrome to render given pdf.  Without this there is visible blinking
      //during previews switching.
      const pdfRenderingTimeout = 500;
      $timeout(() => {
        let p = $('.prints-details__iframe-preview');
        $('.prints-details__iframe-hidden').toggleClass('prints-details__iframe-preview').toggleClass('prints-details__iframe-hidden');
        p.toggleClass('prints-details__iframe-preview').toggleClass('prints-details__iframe-hidden');
        $('.prints-details__iframe-hidden').one('load', onLoad);
      }, pdfRenderingTimeout);
    };
    $('.prints-details__iframe-hidden').one('load', onLoad);


    //Wait 500ms for change event. After given timeout has passed the preview action is called.
    const changeSubject = new Subject();
    const changeSub = changeSubject
      .auditTime(500)
      .subscribe(() => that.preview());

    that.onChange = () => {
      that.setDefaultSampleText();
      changeSubject.next();
    };

    const preparePdf = doc => {
      const lines = doc.lines.concat([that.newLabel]);

      if (getAttr(that.print, 'MULTILINE_Y_SPACING') !== undefined) {
        //for multiline printing generate duplicate lines for better preview experience
        const defaultLineCount = 10;
        let lineCount = parseInt(getAttr(that.print, 'MULTILINE_LINE_COUNT').value) || defaultLineCount;
        let yOffset = parseInt(getAttr(that.print, 'MULTILINE_Y_SPACING').value);
        const pageSheetBreakHeightAfterLineNo = getAttrBoolValue(that.print, 'PAGE_SHEET_BREAK_ENABLE') ?
          parseInt(getAttr(that.print, 'PAGE_SHEET_BREAK_AFTER_LINE_NO').value) : 0;

        for (let lineNo = 0; lineNo < lineCount; lineNo++) {
          for (let line of doc.lines) {
            let copy = _.cloneDeep(line);
            copy.y = line.y + yOffset * lineNo;

            if (pageSheetBreakHeightAfterLineNo && lineNo + 1 > pageSheetBreakHeightAfterLineNo) {
              // Add page sheet break offset
              copy.y += parseInt(getAttr(that.print, 'PAGE_SHEET_BREAK_HEIGHT').value);
            }

            lines.push(copy);
          }
        }
      }

      const backgroundCount = (that.print.backgroundFileIds || []).length;
      const pageHeight = doc.orientation === 'PORTRAIT' ? doc.longEdge : doc.shortEdge;

      if(doc.type === 'TEMPLATE') {
        doc.pages = [{
          lines,
        }];

        return doc;
      }

      const pageCount = Math.max(1, backgroundCount, ...lines.map(line => 1 + Math.floor(line.y / pageHeight)));

      doc.pages = _.range(pageCount).map(page => {
        const pageStartHeight = page * pageHeight;
        const pageEndHeight = (page + 1) * pageHeight;
        const currentPageLines =
          lines.filter(line => line.y >= pageStartHeight - line.fontSize && line.y <= pageEndHeight + line.fontSize)
                .map(line => ({
                  ...line,
                  y: line.y - pageStartHeight
                }));

        return { lines: currentPageLines };
      });

      return doc;
    };

    that.preview = async () => {
      if(!that.print) {
        return;
      }

      let print = preparePdf(that.print);
      const backgroundThumbnails = await that.getBackgroundFilesThumbnails(that.print, that.print.backgroundFileIds);
      printService.printToContainer([print], {
        autoPrint: false,
        backgroundThumbnails: backgroundThumbnails,
        backgroundEnabled: true,
        container: $('.prints-details__iframe-hidden')
      });
    };

    that.cancel = () => {
      const goBack = () => $location.path('/admin/prints/');
      if (that.printForm.$dirty) {
        confirmation('Do you want to cancel? Canceling will discard all changes.', goBack);
      } else {
        goBack();
      }
    };

    // that's memory a leak, but periodically removing content will make implementation more
    // difficult, so let's see if that's a problem for users
    const backgroundFileThumbnailCache = new Map();

    that.downloadFile = async id => {
      const blob = await fileService.downloadFile(id, true, false).toPromise();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result);
        });

        reader.addEventListener('error', reject);
        reader.readAsDataURL(blob);
      });
    };

    that.getBackgroundFilesThumbnails = async (print, fileIds) => {
      if(print.type === 'TEMPLATE') {
        return [];
      }

      const ids = fileIds || [];
      const backgroundFetchers = ids.map(id => {
        const existingValueForKey = backgroundFileThumbnailCache.get(id);
        if(existingValueForKey) {
          return existingValueForKey;
        }

        const thumbnailFetcher = that.downloadFile(id);
        backgroundFileThumbnailCache.set(id, thumbnailFetcher);
        return thumbnailFetcher;
      });

      return await Promise.all(backgroundFetchers);
    };

    that.save = () => {
      that.print.isProcessing = true;
      const updatePrint = (print) => {
        if(print.template) {
          const trimmedTemplate = print.template.trim();
          print.template = trimmedTemplate ? trimmedTemplate : null;
        }

        if(print.id) {
          return http.put(`/print/${print.id}`, print, {nxLoaderText: 'Updating print'});
        } else {
          return http.post(`/print`, print, {nxLoaderText: 'Updating print'});
        }
      };

      const successCb = () => {
        that.print.isProcessing = false;
        notification.show("Success", "Print updated successfully");
        printsCache.evict();
        $location.path("/admin/prints");
      };
      const errorCb = () => {
        that.print.isProcessing = false;
        notification.show("Error", "Failed to update print");
      };

      that.trimEmptyPaddingCharacters(that.print);
      updatePrint(that.print).success(successCb).error(errorCb);
    };

    // prevent from sending empty padding character to backend
    that.trimEmptyPaddingCharacters = (print) => {
      if (print.lines) {
        print.lines.forEach(line => {
          if (line.paddingCharacter === '') {
            line.paddingCharacter = null;
          }
        });
      }
    };

    that.addLabel = () => {
      const newRow = angular.copy(that.newLabel);
      that.print.lines.push(newRow);
      that.newLabel = angular.copy(emptyLabel);

      reorderLines(that.print);
      return true;
    };

    that.$onDestroy = () => {
      changeSub.unsubscribe();
    };
  }

});
