import nxModule from 'nxModule';
import {NgTableParams} from 'ng-table';

const templateUrl = require('./product-locks.template.html');

class ProductLock {
  constructor(http) {
    this.locksTable = null;
    this.http = http;
  }

  buildTable() {
    if (this.product && this.product.id) {
      this.fetchLocks().then(locks => {
        this.locksTable = new NgTableParams({
          count: 20,
        }, {
          counts: [],
          paginationMaxBlocks: 5,
          paginationMinBlocks: 5,
          dataset: locks
        });
      })
    }
  }

  fetchLocks() {
    return this.http.get(`/products/${this.product.id}/locks`, {nxLoaderText: 'Loading locks'}).toPromise();
  }

  $onInit() {
    this.buildTable();
  }
}

nxModule.component('productLocks', {
  templateUrl: templateUrl,
  bindings: {
    product: '<'
  },
  controller: ProductLock
});
