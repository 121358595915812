import nxModule from 'nxModule';
import templateUrl from './center-header.template.html';
import {ILocationService} from "angular";
import './center-header.style.less';
import {Center} from "../../../service/center/center.types";
import {Confirmation} from "shared/common/confirmation.types";
import {NxRouteService} from "routes/NxRouteService";
import {BranchService} from "components/service/branch.service";
import {CommandService} from "shared/utils/command/command.types";

class CenterHeader {
  private center!: Center;
  private centerBranchName: string = '';

  constructor(private readonly command: CommandService,
              private readonly $location: ILocationService,
              private readonly confirmation: Confirmation,
              private readonly $route: NxRouteService,
              private readonly branchService: BranchService) {

  }

  async $onInit() {
    const branches = await this.branchService.toPromise();
    this.centerBranchName = branches.find(b => b.id === this.center.branchId)!.name;
  }

  async activateCenter(): Promise<void> {
    const response = await this.command.execute('ActivateCenter', {id: this.center.id},
      {nxLoaderText: 'Activating center...'}).toPromise();
    if (!response.approvalRequired) {
      this.$route.reload();
    }
  }

  async deactivateCenter(): Promise<void> {
    const message = 'Are you sure you want to deactivate this center? '
      + 'Please note that when a center is deactivated, it will still show up but no operations can be performed with it.';
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      const response = await this.command.execute('DeactivateCenter', {id: this.center.id}, {nxLoaderText: 'Deactivating center...'}).toPromise();
      if (!response.approvalRequired) {
        this.$route.reload();
      }
    }
  }

  async deleteCenter(): Promise<void> {
    const message = 'Are you sure you want to delete this center? Please note that this operation is irreversible.';
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      const response = await this.command.execute('DeleteCenter', {id: this.center.id}, {nxLoaderText: 'Deleting center...'}).toPromise();
      if (!response.approvalRequired) {
        this.$location.path('/dashboard/lookup-center');
      }
    }
  }
}

nxModule.component('centerHeader', {
  templateUrl,
  transclude: true,
  controller: CenterHeader,
  bindings: {
    center: '<'
  }
});