import _ from 'lodash';

import nxModule from 'nxModule';
import templateUrl from './loan-account-information.template.html';
import {cicContractTypes, loanCreationTypes, loanInformationTypes} from 'constants/loan';

class LoanAccountInformation {
  constructor(dict, fileService, notification, misGroupsCache, loanIndustryPurposeCache, loanCache) {
    this.dictionary = dict;
    this.misGroupsCache = misGroupsCache;
    this.loanIndustryPurposeCache = loanIndustryPurposeCache;
    this.fileService = fileService;
    this.notification = notification;
    this.loanCache = loanCache;
    this.files = {};
  }

  $onChanges(changes) {
    this.info = _.get(this.loan, 'loanInformation');
    this.creationType = _.get(this.loan, 'creationType');
    this.collateralFileIds = _.get(this.loan, 'collateralFileIds');

    if (this.creationType) {
      if (this.creationType === 'RESTRUCTURED' || this.creationType === 'RECONSTRUCTED') {
        this.getRemadeProductNumber();
      }
      const found = loanCreationTypes.find(loanType => loanType.value === this.creationType);
      this.creationType = found ? found.label : '-';
    }

    if (this.info) {
      this.dictionary.onLoadingComplete(() => this.gatherInfo());
    }

    if (this.collateralFileIds) {
      this.prepareFileList();
    }
  }

  async getRemadeProductNumber() {
    const loan = await this.loanCache.loan(this.loan.remadeFromLoanIds[0]).toPromise();
    this.restructuredLoanProductNumber = loan.productNumber;
  }

  gatherInfo() {
    const cicFound = cicContractTypes.find(cicContract => cicContract.value === this.info.cicContractType);
    this.cicContractType = cicFound ? cicFound.label : '-';

    this.getMisGroups();
    this.getLoanIndustryPurpose();

    this.loanIndustryPurpose = loanIndustryId => {
      const found = that.misGroups.find(group => group.id === loanIndustryId);
      return found ? found.name : '-';
    };

    this.loanClass = this.getItemDescription({
      type: loanInformationTypes.LOAN_CLASS,
      id: this.info.loanClassId
    });

    this.loanEconomicActivity = this.getItemDescription({
      type: loanInformationTypes.LOAN_ECONOMIC_ACTIVITY,
      id: this.info.loanEconomicActivityId
    });

    this.loanPurpose = this.getItemDescription({
      type: loanInformationTypes.LOAN_PURPOSE,
      id: this.info.loanPurposeId
    });

    this.loanSecurity = this.getItemDescription({
      type: loanInformationTypes.LOAN_SECURITY,
      id: this.info.loanSecurityId
    });

    this.loanRateType = this.getItemDescription({
      type: loanInformationTypes.LOAN_RATE_TYPE,
      id: this.info.loanRateTypeId
    });

    this.loanBorrowerType = this.getItemDescription({
      type: loanInformationTypes.LOAN_BORROWER_TYPE,
      id: this.info.loanBorrowerTypeId
    });

    this.loanTransactionType = this.getItemDescription({
      type: loanInformationTypes.LOAN_TRANSACTION_TYPE,
      id: this.info.loanTransactionTypeId
    });

    this.loanMicrofinanceClassification = this.getItemDescription({
      type: loanInformationTypes.LOAN_MICROFINANCE_CLASSIFICATION,
      id: this.info.loanMicrofinanceClassificationId
    });

    this.customCategories = this.loan.customCategories;
  }

  prepareFileList() {
    this.collateralFileIds.forEach(fileId => {
      this.showFilePreview(fileId);
    });
  }

  showFilePreview(fileId) {
    if (!this.files[fileId]) this.files[fileId] = {};
    this.fileService.getMetadata(fileId)
      .success(fileInfo => {
        this.files[fileId]['remarks'] = fileInfo.remarks;
      });
    this.fileService.downloadFile(fileId, false, false)
      .success(file => {
        this.files[fileId]['fileUrl'] = window.URL.createObjectURL(file);
      })
      .error(error => {
        this.notification.show("Error", "Could not load file");
        console.error(error);
      });
  }

  showPreview(fileData) {
    this.currentFile = fileData;
    this.previewOn = true;
  }

  getItemDescription({type, id}) {
    const found = this.dictionary[type].find(item => item.id === id);
    return found ? found.description : '-';
  }

  async getLoanIndustryPurpose() {
    const loanIndustryPurpose = await this.loanIndustryPurposeCache.toPromise();
    const loanPurposeFound = loanIndustryPurpose.find(loanPurpose => loanPurpose.id === this.info.loanIndustryPurposeId);

    this.loanIndustryPurpose =  loanPurposeFound ? loanPurposeFound.name : '-';
  }

  async getMisGroups() {
    const misGroups = await this.misGroupsCache.toPromise();
    const misFound = misGroups.find(misGroup => misGroup.id === this.info.misGroupId);

    this.misGroup = misFound ? misFound.name : '-';
  }
}

nxModule.component('loanAccountInformation', {
  templateUrl,
  bindings: {
    loan: '<',
    collateralFileIds: '<'
  },
  controller: LoanAccountInformation
});
