import {AccessRule} from "access/AccessRuleTypes";
import tokenStore from "authentication/tokenStore";
import axios from "axios";

/**
 * Checks if user has access to a given command. Doesnt support checking 'approve' rights and command predicates.
 */
class AccessRuleService {
    private accessRules: AccessRule[] = [];

    async init(): Promise<void> {
        console.log('AccessRuleService: Loading access rules');
        const roleIds = tokenStore.getTokenDetails().roleIds;
        const {data: accessRules} = await axios.get<AccessRule[]>(`/access/roles/${roleIds}/rules`);
        this.accessRules = accessRules;
        console.log('AccessRuleService: Access rules loaded');
    }

    public accessRuleForLoggedInUserRole(cmd: string): AccessRule | undefined {
      return this.accessRules.find(accessRule => accessRule.command === cmd);
    }
}

export default new AccessRuleService();