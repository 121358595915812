import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('productStatusMigrationCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/products/status-migrations`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.userCounter,
    cacheName: 'productStatusMigration'
  }));
