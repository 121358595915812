import nxModule from 'nxModule';

const templateUrl = require('./charge.template.html');
nxModule.component('charge', {
  templateUrl,
  bindings: {
    charge: '=',
    types: '<',
    disableType: '<',
    disableRate: '<'
  },
  controller: function () {

  }
});
