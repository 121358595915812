export const multipleAccountOwnershipsEnabled = (profile, product, pdicCasaTypes) => {
  const casaType = pdicCasaTypes.find(t => t.id === product.pdicTypeId);
  return profile.customerType === 'INDIVIDUAL' && casaType && casaType.multipleOwnership;
};

export const isCorporate = (profile, product, pdicCasaTypes) => {
  const casaType = pdicCasaTypes.find(t => t.id === product.pdicTypeId);
  return profile.customerType === 'CORPORATE' && casaType && casaType.code === 'CORPORATE';
};

export const validateOwnerConfiguration = (profile, product, pdicCasaTypes) => {
  if(!product.pdicTypeId) {
    return true;
  }
  const groupedOwners = _.groupBy(product.ownership, owner => { return owner.ownershipType; });
  const corporateCustomer = isCorporate(profile, product, pdicCasaTypes);
  const multipleOwnershipsForProduct = multipleAccountOwnershipsEnabled(profile, product, pdicCasaTypes);
  const hasSecondary = groupedOwners.SECONDARY_OWNER && groupedOwners.SECONDARY_OWNER.length > 0;
  const hasSingleOwner = groupedOwners.OWNER && groupedOwners.OWNER.length === 1;
  const hasSignatory =  product.signatoryIds && product.signatoryIds.length > 0;

  return (multipleOwnershipsForProduct && hasSingleOwner && hasSecondary)
    || (!multipleOwnershipsForProduct && hasSingleOwner && !hasSecondary)
    || (corporateCustomer && hasSignatory);

};

export const enrichOwnersWithRelatives = (product, relatives) => {
  if (product && relatives) {
    product.ownership.forEach(o => {
      if (o.ownershipType === 'SIGNATORY') {
        o.relative = relatives.find(r => r.id === o.relativeId);
      }
    })
  }
};

export const mapSignatoryToOwnership = (sig) => {
  return {
    birthDate: sig.birthDate,
    branchId: sig.branchId,
    customerNumber: sig.customerNumber,
    customerId: sig.relativeCustomerId,
    effectiveName: sig.effectiveName,
    ownershipType: 'SIGNATORY',
    relativeId: sig.id,
    productAccess: false
  };
};