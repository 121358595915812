import nxModule from 'nxModule';

import templateUrl from './other-bank-accounts-income-source.template.html';
import {incomeSourceTypes} from './constants';
import {IncomeSource} from '../../../components/customer/profile/customer-profile.types';
import {DictionaryIssuingBank} from '../../../components/dashboard/miscellaneous-transactions/funds-movement/funds-movement.component';
import {IncomeSourceType} from './constants.types';

class OtherBankAccountsIncomeSource {

  cfg: unknown;
  types: ReadonlyArray<IncomeSourceType> = incomeSourceTypes;
  banks: DictionaryIssuingBank[] = [];
  incomeFormTitle!: string;
  sourceOfIncome!: IncomeSource;

  bankSelectConfig: unknown = {
    placeholder: 'Select bank',
    searchField: ['description'],
    valueField: 'id',
    labelField: 'description',
    maxItems: 1
  };

  constructor(private dict: unknown, private propertyConfigService: unknown) {
    this.cfg = this.propertyConfigService;
  }

  $onInit(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.dict.onLoadingComplete(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.banks = this.dict['BANK'];
    });
  }

  updateBankId(): void {
    if (!this.sourceOfIncome.otherExistingBankAccounts) {
      this.sourceOfIncome.otherExistingBankAccountsBankId = undefined;
    }
  }
}

nxModule.component('otherBankAccountsIncomeSource', {
  templateUrl,
  controller: OtherBankAccountsIncomeSource,
  bindings: {
    incomeFormTitle: '<',
    editMode: '<',
    module: '<',
    removeIncomeSource: '&',
    sourceOfIncome: '<'
  }
});
