import {NxCashInput, NxInput, NxPercentageInput, NxTabs} from '@nextbank/ui-components';
import {BasePaymentInterval} from 'components/administration/loan/intervals/payment-interval.types';
import {Loan} from 'components/service/loan.types';
import NxHeader, {HeaderVariant} from 'form/NxHeader';
import AmortizationSchedulePreview from 'loan/simulation/AmortizationSchedulePreview';
import moment from 'moment';
import React, {ReactElement} from 'react';

interface SimulationProps {
  loan: Loan,
  showOutstandingPrincipal: boolean
}
const Simulation = (props: SimulationProps): ReactElement => {

  return <>
    <NxHeader variant={HeaderVariant.H3}>Simulation</NxHeader>
    <NxTabs
      tabs={[
        {
          key: 'amortization-schedule',
          label: 'Amortization Schedule',
          tabPanel: <AmortizationSchedulePreview loan={props.loan} showOutstandingPrincipal={props.showOutstandingPrincipal}/>
        }
      ]}
    />

    <NxInput disabled label="Term"
             value={props.loan.term + (props.loan.termAdjustment > 0 ? ' (+' + props.loan.termAdjustment + ')' : '')}/>
    <NxInput disabled label="Calendar days"
             value={moment(props.loan.maturityDate, 'YYYY-MM-DD').diff(moment(props.loan.grantDate, 'YYYY-MM-DD'),
               'days') + (props.loan.termAdjustment > 0 ? ' (+' + props.loan.termAdjustment + ')' : '')}/>
    <NxCashInput disabled label={'Principal'} value={props.loan.principalAmount}/>
    {props.loan.advanceInterestAmount !== 0 &&
    <NxCashInput disabled label={'Advance interest amount'} value={props.loan.advanceInterestAmount}/>}

    <NxCashInput disabled label={'Release amount'} value={props.loan.releaseAmount}/>
    <NxPercentageInput disabled label="Effective interest rate (periodic)" value={props.loan.eirPeriodic}/>
    {props.loan.paymentIntervalDefinition.basePaymentInterval !== BasePaymentInterval.DAY &&
    <NxPercentageInput disabled label="Effective interest rate (monthly)" value={props.loan.eirMonthly}/>
    }
    <NxPercentageInput disabled label="Effective interest rate (annual)" value={props.loan.eirAnnual}/>
  </>;
};

export default Simulation
