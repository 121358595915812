import nxModule from 'nxModule';
import LocalCache from "../../../../shared/utils/localCache";
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from "config";

export type CreditLineTypesCache = LocalCache<unknown, unknown>;

nxModule.factory('creditLineTypesCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get(`/credit-lines/types`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.creditLineTypes,
    cacheName: 'creditLineTypes'
  })
);
