import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {HttpService} from "shared/utils/httpService";
import config from '../../../react/config';
import {ICacheFactoryService} from 'angular';
import {Role} from "user/UserTypes";

type RolesCache = LocalCache<unknown, Role[]>;
export default RolesCache;

nxModule.factory('rolesCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get(`/management/roles`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.rolesConfig,
    cacheName: `rolesConfig`
  })
);
