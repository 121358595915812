import _ from 'lodash';
import nxModule from 'nxModule';
import ParameterizedLocalCache from '../../../shared/utils/parameterized-local-cache';
import {User as UserResponse} from "user/UserTypes";
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from "config";

export interface UserDetails extends UserResponse {
  fullName: string,
  fullNameWithRoles: string
}

export type UserCache = ParameterizedLocalCache<UserResponse[], UserDetails[]>;

/**
 * Parameterized cache for users.
 *
 * includeDeleted with default value of false, use true when a deleted user is needed to be displayed.
 * e.g. Loan History, Transaction History, GL Transactions.
 * */
nxModule.factory('userCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new ParameterizedLocalCache({
    provider: (includeDeleted=false) => http.get(`/management/users?includeDeleted=${includeDeleted}`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.users,
    cacheName: 'users',
    cacheStorage: 'memory',
    postProcessor: (users: UserDetails[]) =>
      users.map(u => {
        const middle = (u.middleName ? u.middleName + ' ' : '');
        u.fullName = `${u.firstName} ${middle}${u.lastName}`;

        // Create user full name with roles, e.g. 'Joe H. Doe (teller, collector)'
        u.fullNameWithRoles = u.fullName;
        if (u.roles && u.roles.length > 0) {
          const userRoles = `(${_.map(u.roles, 'name').join(', ')})`;
          u.fullNameWithRoles = u.fullName + ' ' + userRoles;
        }
        return u;
      })
  })
);