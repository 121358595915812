import nxModule from 'nxModule';
import moment, {Moment} from "moment";
import {Holiday, SearchMode} from "../../../react/management/WorkingDayType";

class HolidayService {
  constructor(private readonly branchHolidayCache: any) {
  }

  public async fetchHoliday(branchId: number, date: Moment, searchMode: SearchMode): Promise<Holiday[]> {
    return await this.branchHolidayCache.withParam({
      branchId: branchId,
      types: ['HOLIDAY'],
      date: date.format('YYYY-MM-DD'),
      mode: searchMode
    }).toPromise();
  };
}

nxModule.service('holidayService', HolidayService);
export default HolidayService;
