import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('translationCache', (http, CacheFactory, config) => {
    return new LocalCache({
      provider: () => {
        let lang = config.defaultLanguage;
        return http.get(`/translations?language=${lang}`)
      },
      cacheFactory: CacheFactory,
      cacheTime: config.cache.translation,
      cacheName: 'translation'
    })
  }
);

