export const productStatus = Object.freeze([
  {
    label: 'Pending',
    value: 'PENDING'
  },{
    label: 'Approved',
    value: 'APPROVED'
  },{
    label: 'Inactive',
    value: 'INACTIVE'
  },{
    label: 'Active',
    value: 'ACTIVE'
  },{
    label: 'Mature',
    value: 'MATURE'
  },{
    label: 'Closed',
    value: 'CLOSED'
  },{
    label: 'Canceled',
    value: 'CANCELED'
  },{
    label: 'Invalid',
    value: 'INVALID'
  },{
    label: 'Past due performing',
    value: 'PAST_DUE_PERFORMING'
  },{
    label: 'Past due non-performing',
    value: 'PAST_DUE_NON_PERFORMING'
  },{
    label: 'Past due litigation',
    value: 'PAST_DUE_LITIGATION'
  },{
    label: 'Past due write off',
    value: 'PAST_DUE_WRITE_OFF'
  }
]);

export const productHooks = Object.freeze([
  'CREATE',
  'LOAN_RELEASE'
]);