import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from 'system/systemPropertyService';
import templateUrl from './stop-order.template.html';

class CustomerAccountsStopOrder {

  constructor($location, $route, confirmation, customerCache, feeDefinitionsCache,
              productDefinitionService, command, depositoryAccountCache) {
    this.$location = $location;
    this.$route = $route;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.feeDefinitionsCache = feeDefinitionsCache;
    this.productDefinitionService = productDefinitionService;
    this.command = command;
    this.depositoryAccountCache = depositoryAccountCache;
    this.isPchcDirectClearing = systemPropertyService.getProperty('PCHC_DIRECT_CLEARING_MEMBER') === 'TRUE';

    this.depositoryAccountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };
    this.account = {};
    this.customerId = null;
    this.productId = null;
    this.accountNumber = null;
    this.checkNumber = null;
    this.depositoryAccountId = null;
    this.overrideFee = false;
    this.feeOverrideAmount = null;
    this.remarks = null;
  }

  async $onInit() {
    this.customerId = this.$route.current.params['customerId'];
    this.productId = this.$route.current.params['accountId'];

    const [depositoryAccounts, customerAccounts,
      productDefinitions, feeDefinitions] = await Promise.all([
      this.depositoryAccountCache.toPromise(),
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.productDefinitionService.toPromise(),
      this.feeDefinitionsCache.toPromise()
    ]);

    this.depositoryAccounts = depositoryAccounts.filter(account => account.clearing);

    const account = customerAccounts.find(account => Number(account.id) === Number(this.productId));
    const accountProductDefinition = productDefinitions.find(definition => Number(definition.id) === Number(account.definitionId));
    this.account = {
      ...account,
      productName: accountProductDefinition.productName || '-'
    };

    const spoPlacementChargeDef = _(feeDefinitions).find(feeDef => {
      return Number(feeDef.productDefinitionId) === Number(accountProductDefinition.id) && feeDef.feeClass === 'SPO_PLACEMENT_CHARGE';
    });
    if (!!spoPlacementChargeDef && spoPlacementChargeDef.enabled) {
      // can be null - then charge is not applied and can't be overridden
      this.fee = spoPlacementChargeDef.fixedAmount;
    }
  }

  onDepositoryAccountChange() {
    this.depositoryAccount = _.find(this.depositoryAccounts, (a) => Number(a.id) === Number(this.depositoryAccountId));
  };

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.productId}`);
  }

  async createStopOrder() {
    if (await this.confirmation(`Do you want to crate stop order?`)) {
      const req = {
        productId: this.productId,
        accountNumber: this.accountNumber,
        checkNumber: this.checkNumber,
        depositoryAccountId: this.depositoryAccountId,
        spoFeeOverride: this.overrideFee ? this.feeOverrideAmount : null,
        remarks: this.remarks,
      };
      await this.command.execute('CreateCheckStopOrder', req).toPromise();
      this.redirectBack();
    }
  }

}

nxModule.component('customerAccountsStopOrder', {
  templateUrl: templateUrl,
  controller: CustomerAccountsStopOrder
});
