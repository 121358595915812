import {ICacheFactoryService} from 'angular';
import {Config} from 'config';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import LocalCache from '../../../shared/utils/localCache';

export interface OracleHandoffMapping {
  id: number;
  mappingCode: string;
  mappingName: string;
}

export interface OracleHandoffMappingDetails extends OracleHandoffMapping {
  accountFullCode: string;
  accountName: string;
}

export default class OracleHandoffMappingService {

  public readonly mappings: LocalCache<OracleHandoffMappingDetails[], OracleHandoffMappingDetails[]>;

  constructor(private http: HttpService, private CacheFactory: ICacheFactoryService, private config: Config) {
    this.mappings = new LocalCache({
      provider: () => http.get('/bss/handoff/oracle/mappings'),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.productMappingsAccounts,
      cacheName: 'oracleHandoffMappings'
    });
  }
}

nxModule.service('oracleHandoffMappingService', OracleHandoffMappingService);
