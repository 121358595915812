import nxModule from 'nxModule';

import templateUrl from './loan-product-holidays.template.html';

class LoanHoliday {

  constructor(confirmation) {
    this.confirmation = confirmation;
  }

  addHoliday() {
    this.loanProduct.loanHolidays.push({dirty: true});
  }

  cancelAddHoliday() {
    this.loanProduct.loanHolidays.pop();
  }

  cancelEditHoliday(form, index) {
    form.$setSubmitted();
    this.loanProduct.loanHolidays[index].dirty = false;
  }

  async removeHoliday(idx) {
    const isApproved = await this.confirmation("Do you want to remove this holiday?");
    if (!isApproved) return;

    this.loanProduct.loanHolidays.splice(idx, 1);
  }

  isSaveDisabled(form) {
    return form.$invalid && form.$submitted;
  }

  saveHoliday(form, index) {
    form.$setSubmitted();

    if (form.$valid) {
      this.loanProduct.loanHolidays[index].dirty = false;
    }
  }

  editHoliday(index) {
    this.loanProduct.loanHolidays[index].dirty = true;
  }
}



nxModule.component('loanProductHolidays', {
  templateUrl,
  bindings: {
    loanProduct:'=',
    form: '='
  },
  controller: LoanHoliday
});