import nxModule from 'nxModule';

const templateUrl = require('./teller-cash-shortage.template.html');
nxModule.component('tellerCashShortage', {
  templateUrl: templateUrl,
  controller: function (authentication, actionCommand, userCache, miscTransactionsService, commandAccessChecker, miscCommandService) {

    const that = this;
    that.transaction = {};
    that.approvalUsers = [];

    that.$onInit = async () => {
      const allUsers = await userCache.withParam().toPromise();
      const users = allUsers.filter(user => user.id !== authentication.context.id);
      const branchId = authentication.context.branchId;
      that.approvalUsers = await miscTransactionsService.filterApprovalUsersForCommand('MiscTellerCashShortage', users, branchId);
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = async () => {
      await miscCommandService.executeCommand('MiscTellerCashShortage', this.transaction).toPromise();
    };

  }
});
