import React, {ReactElement} from "react";
import styles from './NxPage.scss';

/**
 * Layout component setting up consistent spacing between page header and further children
 */
const NxPage = ({children}: { children: React.ReactNode[]}): ReactElement => {
  return <div className={styles.container}>
    {children}
  </div>;
};

export default NxPage;