import nxModule from 'nxModule';

const templateUrl = require('./interest-board.template.html');
nxModule.component('interestBoard', {
  templateUrl: templateUrl,
  bindings: {
    board: '<'
  },
  controller: function () {

    const that = this;

    that.create = () => {
      that.board.cells.push({
        rate: null,
        minBalance: null,
        maxBalance: null,
        minTerm: null,
        maxTerm: null
      });
    };

    that.remove = (idx) => {
      that.board.cells = _.reject(that.board.cells, (value, index) => idx === index);
    };
  }
});
