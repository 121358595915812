import nxModule from 'nxModule';

import templateUrl from './batch-edit-loan-accrual.template.html';
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

interface BatchEditLoanAccrualInput {
  fileId: number | null;
}

class BatchEditLoanAccrual {
  protected parameters: BatchEditLoanAccrualInput = {
    fileId: null,
  };

  private readonly sampleFile = new Blob([`1234-123-123,100,1
2345-123-123,25,0`], {type: 'text/csv'});

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private $route: NxRouteService,
              private command: CommandService) {}


  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'edit-loan-accrual-product-numbers.csv';
    a.click();
  }

  async execute() {
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed?`,
    });

    if(confirmed) {
      await this.command.execute('BatchEditLoanInterestAccrual', this.parameters).toPromise();
      this.$route.reload();
    }
  }
}

nxModule.component('batchEditLoanAccrual', {
  templateUrl,
  controller: BatchEditLoanAccrual
});
