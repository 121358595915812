import Fingerprint2 from 'fingerprintjs2';
import $ from 'jquery';

const pageLoaded: Promise<boolean> = new Promise(((resolve) => {
  $(window).on('load', () => resolve(true));
}));

class FingerprintService {
  private readonly fingerprint: Promise<string>;

  constructor() {
    this.fingerprint = this.initializeFingerprint();
  }

  async initializeFingerprint(): Promise<string> {
    await pageLoaded;
    return new Promise((resolve, reject) => {
      // fingerprint can be invalid just after page load, so we should wait some millis after it
      // https://github.com/Valve/fingerprintjs2/issues/307
      // https://github.com/Valve/fingerprintjs2/issues/254

      setTimeout(async () => {
        this.calculateFingerprint().then(resolve, reject);
      }, 1000);
    });
  }

  async calculateFingerprint(): Promise<string> {
    const fingerprintComponents = await Fingerprint2.getPromise();
    const values = fingerprintComponents.map(component => component.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  }

  getFingerprint(): Promise<string> {
    return this.fingerprint;
  }
}

export default new FingerprintService();