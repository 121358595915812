import nxModule from 'nxModule';

export type Detail = {label: string, description: string | number};

interface Input {
  question: string;
  details?: Detail[];
  information?: string;
  warning?: string;
}

type ConfirmationTemplate = (input: Input) => Promise<boolean>;

const confirmationTemplate = /* @ngInject */ (confirmation: unknown): Promise<boolean> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const creator: ConfirmationTemplate = ({question, details, information, warning, /** @deprecated function returns promise, use it */ yesCallback, /** @deprecated function returns promise, use it */ noCallback}) => {
    let detailsHTML = '';
    if (details) {
      detailsHTML = '<br><br>';
      for (const d of details) {
        detailsHTML += `<strong>${d.label}</strong>: ${d.description}<br>`;
      }
    }

    const informationHTML = information ?
      `<br>
       <span class="alert alert--info">
         ${information}
       </span>` : '';

    const warningHTML = warning ?
      `<span class="alert alert--danger">
         ${warning}
       </span>` : '';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return confirmation(
      `<strong>${question}</strong>
       ${detailsHTML}
       ${informationHTML}
       ${warningHTML}`,
      yesCallback,
      noCallback,
      true);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return creator;
};

export default ConfirmationTemplate;
nxModule.factory('confirmationTemplate', confirmationTemplate);
