import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./working-day-check.template.html');

nxModule.component('workingDayCheck', {
  templateUrl: templateUrl,
  controller: function ($scope, $location, $interval, http, authentication,
                        ledgerActionTemplateCache, workingDayService, cashTransferCheckService,
                        commandAccessChecker, actionCommand, userCache) {

    const self = this;
    self.workingDayStatus = null;
    self.workingDayLocked = false;
    self.startDayAvailable = false;

    const cashTransferSub = cashTransferCheckService.toObservable()
      .subscribe(shouldBlock => this.blockActions = shouldBlock);

    const subscription = ledgerActionTemplateCache.toObservable()
        .combineLatest(commandAccessChecker.canExecuteCommandObservable(), (templates, canExecuteCommand) => {
          return _.map(templates, command => ({
              ...command,
              canExecute: canExecuteCommand(actionCommand.actionTypeToCommand(command.type))
          }));
        })
        .subscribe(templates => {
          self.startDayAvailable = false;
          // Search for START_DAY misc transaction
          const t = _.find(templates, {type: 'START_DAY'});
          if (t) {
            self.startDayAvailable = t.canExecute;
          }
    });

    self.$onInit = async () => {
      const users = await userCache.withParam().toPromise();
      const user = users.find(u => u.id === authentication.context.id);
      self.isUserTechnical = user.technical;
    };

    self.logout = () => {
      authentication.logout();
    };

    const statusSub = workingDayService.toObservable()
      .subscribe(workingDay => {
        self.workingDayStatus = workingDay.status;
        self.workingDayLocked = _.includes(['CLOSED', 'END_DAY_PROCESSING'], workingDay.status);
      });


    self.$onDestroy = () => {
      subscription.unsubscribe();
      statusSub.unsubscribe();
      cashTransferSub.unsubscribe();
    };
  }
});
