import nxModule from 'nxModule';

import templateUrl from './credit-scoring-modal-score-chart.template.html';
import './credit-scoring-modal-score-chart.styles.less';
import {CreditScore, findColor, NonPredictionKeys} from "components/customer/credit-scoring/credit-scoring.types";
import {Context} from "chartjs-plugin-datalabels";
import {IRootElementService} from "angular";
import {getChart} from "components/service/chartjs.service";


class CreditScoringModalScoreChart {
  labels!: Record<NonPredictionKeys, string>;
  score!: CreditScore;
  columnOrder: NonPredictionKeys[] = [
    'demographicData', 'creditHistory',
    'accountsHistory', 'loanApplicationData'
  ];

  cleanup?: () => void;

  constructor(private $element: IRootElementService) {
  }

  async $postLink(): Promise<void> {
    const Chart = await getChart();
    const canvas = this.$element.find('canvas')[0] as HTMLCanvasElement | undefined;
    if(!canvas) {
      throw new Error('Cannot find canvas for a chart');
    }

    const categoryValues: number[] = Object.entries(this.score)
      .filter(([key,]) => key !== 'prediction' && key !== 'bestThresholdLGB')
      .map(entry => entry[1]);

    const maxValue = Math.max(...categoryValues);
    const minValue = Math.min(...categoryValues);

    // we would like to keep certain percentage of the space blow and above the chart
    const valueSpan = maxValue - minValue;
    const freeSpaceBelowAndAboveChart = 0.2 * valueSpan;
    const minYAxis = minValue - freeSpaceBelowAndAboveChart;
    const maxYAxis = maxValue + freeSpaceBelowAndAboveChart;
    const chartValueSpan = maxYAxis - minYAxis;

    const canvasStyles = getComputedStyle(canvas);
    const axisColor = canvasStyles.getPropertyValue('--disabled-button-background-color').trim();
    const labelColor = canvasStyles.getPropertyValue('--text-color-light').trim();

    const data = {
      labels: this.columnOrder.map(col => this.labels[col].toUpperCase().split(' ')),
      datasets: [{
        data: this.columnOrder.map(col => this.score[col]),
        backgroundColor: this.columnOrder.map(col => {
          const value = this.score[col];
          const color = findColor(value);
          return canvasStyles.getPropertyValue(`--${color}-color`);
        }),
      }]
    };

    const labelFontSize = 10;
    const chart = new Chart<'bar', number, string>(canvas, {
      type: 'bar',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          },
          datalabels: {
            labels: {
              label: {
                align: 'end',
                anchor: 'end',
                font: {
                  size: 8
                },
                formatter: (value, ctx): string => {
                  return (ctx.chart.data.labels as string[] ?? [])[ctx.dataIndex];
                },
                offset: 2
              },
              value: {
                align: 'center',
                anchor: 'center',
                font: {
                  size: labelFontSize
                },
                color: (context: Context): string => {
                  const value = context.dataset.data[context.dataIndex] as number;
                  // If the space to render an element is smaller than font size, we want to have element
                  if(Math.abs(value) / chartValueSpan * context.chart.chartArea.height < labelFontSize) {
                    return labelColor;
                  }

                  return 'white';
                },
                formatter: (value, ctx): number => {
                  return ctx.dataset.data[ctx.dataIndex] as number;
                }
              }
            },
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            position: {
              y: 0
            },
            alignToPixels: true,
            grid: {
              display: false,
              drawTicks: false,
              drawBorder: true,
              borderColor: axisColor,
              z: -1
            },
            ticks: {
              autoSkip: false,
              display: false
            }
          },
          y: {
            alignToPixels: true,
            min: minYAxis,
            max: maxYAxis,
            grid: {
              drawTicks: false,
              borderWidth: 1,
              borderDash: [2, 2],
              borderColor: axisColor,
              z: -2
            },
            ticks: {
              font: {
                size: 10
              }
            },
          },
        }
      }
    });

    const mediaQueryList = window.matchMedia('print');
    const resizeListener = (): void => {
      chart.resize();
    };

    mediaQueryList.addEventListener('change', resizeListener);
    this.cleanup = (): void => {
      mediaQueryList.removeEventListener('change', resizeListener);
    };
  }

  $onDestroy(): void {
    if(this.cleanup) {
      this.cleanup();
    }
  }
}

nxModule.component('creditScoringModalScoreChart', {
  templateUrl,
  bindings: {
    labels: '<',
    score: '<'
  },
  controller: CreditScoringModalScoreChart
});
