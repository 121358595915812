import jwtDecode from 'jwt-decode';

export default class CmcService {
  getCmcToken(): string | null {
    return localStorage.getItem('cmc');
  }

  getCmc(): string {
    const cmcToken = this.getCmcToken();
    if(!cmcToken) {
      throw new Error('Missing cmc');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token = jwtDecode(cmcToken) as any;
    return token.cmc;
  }

  setCmcToken(cmc: string): void {
    localStorage.setItem('cmc', cmc);
  }
}