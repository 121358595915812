import nxModule from 'nxModule';
import {CustomerProfile, IDDocument, IncomeSource} from '../../customer/profile/customer-profile.types';
import _ from 'lodash';
import {CustomerCache} from "components/service/customer.cache.types";
import {Dict} from "shared/common/dict.types";
import {CustomerService} from "components/service/customer.service";

const withCorrectPrimaryFlag = function<T extends {primary: boolean}>(objs: T[]): T[] {
  const nrOfPrimaryObjects = objs.filter(obj => obj.primary).length;
  if(nrOfPrimaryObjects === 1) {
    return objs;
  }

  if(nrOfPrimaryObjects === 0) {
    return objs.map((val, i) => ({
        ...val,
        primary: i === 0
      })
    );
  }

  const newObjs = [];
  let foundPrimary = false;
  for(const obj of objs) {
    if(foundPrimary) {
      newObjs.push({
        ...obj,
        primary: false
      });
    } else {
      foundPrimary = obj.primary;
      newObjs.push(obj);
    }
  }

  return newObjs;
};

export class AgentsCustomerService {

  readonly agentIncomeTypes = ['BUSINESS', 'OTHER_BANK_ACCOUNTS'];

  constructor(private customerCache: CustomerCache, private customerService: CustomerService, private dict: Dict) {
  }

  async fetchCustomer(customerId: number): Promise<CustomerProfile> {
    const customer = await this.customerCache.profile(customerId).toPromise();
    const cloned = this.customerService.extractPhones(_.cloneDeep(customer));
    return await this.filterAgentDocuments(cloned);
  }

  private async filterAgentDocuments(customer: CustomerProfile) : Promise<CustomerProfile> {
    customer.incomeSources = customer.incomeSources.filter(c => this.agentIncomeTypes.includes(c.type));
    const agentDocumentTypes = await this.getAgentDocumentTypes();
    customer.idDocuments = customer.idDocuments.filter(d => agentDocumentTypes.includes(d.documentTypeId));
    return customer;
  }

  private async getNonAgentDocumentsAndISources(customerId: number): Promise<{
    idDocuments: IDDocument[],
    incomeSources: IncomeSource[]
  }> {
    const customer = await this.customerCache.profile(customerId).toPromise();
    const agentDocumentTypes = await this.getAgentDocumentTypes();
    return {
      idDocuments: customer.idDocuments.filter((d: IDDocument) => !agentDocumentTypes.includes(d.documentTypeId)),
      incomeSources: customer.incomeSources.filter((isc: IncomeSource) => !this.agentIncomeTypes.includes(isc.type))
    };
  }

  public async addNonAgentIdDocuments(customerId: number, idDocuments: IDDocument[]): Promise<IDDocument[]> {
    const {idDocuments: nonAgentIdDocuments} = await this.getNonAgentDocumentsAndISources(customerId);
    return withCorrectPrimaryFlag([...(nonAgentIdDocuments ?? []), ...idDocuments]);
  }

  public async addNonAgentIncomeSources(customerId: number, incomeSources: IncomeSource[]): Promise<IncomeSource[]> {
    const {incomeSources: nonAgentIncomeSources} = await this.getNonAgentDocumentsAndISources(customerId);
    return withCorrectPrimaryFlag([...(nonAgentIncomeSources ?? []), ...incomeSources]);
  }

  private async getAgentDocumentTypes(): Promise<number[]> {
    await this.dict.onLoadingCompleteAsync();
    return this.dict.filterByAttribute('ID_DOCUMENT_TYPE', 'customerType', 'AGENT')
        .map(d => d.id);
  }

}

nxModule.service('agentsCustomerService', AgentsCustomerService);
