import nxModule from 'nxModule';
import './compliance-config-redflag-section.style.less';
import templateUrl from './compliance-config-redflag-section.template.html';

class ComplianceConfigRedflagSection {

}

nxModule.component('complianceConfigRedflagSection', {
  templateUrl,
  bindings: {
    nonCommensurateConfig: '<',
    voluminousAggregateTransactionConfig: '<',
    complianceConfig: '<'
  },
  controller: ComplianceConfigRedflagSection
});
