import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import _ from 'lodash';
import nxModule from 'nxModule';

const templateUrl = require('./report-approvals-filters.template.html');
class ReportApprovalsFilters {
  constructor($route, $location, systemDateService, userCache, reportModal, authentication) {
    this.loanId = $route.current.params['loanId'];
    this.customerId = $route.current.params['customerId'];
    this.users = undefined;
    this.allUsers = undefined;
    this.$location = $location;
    this.systemDateService = systemDateService;
    this.userCache = userCache;
    this.authentication = authentication;
  }

  $onInit() {
    if (!this.date) {
      this.systemDateService.getCurrentUserBranchSystemDate().then(date => this.date = date.clone().format('YYYY-MM-DD'));
    }
    this.usersSub = this.userCache.withParam().toObservable().subscribe(users => {
      this.allUsers = users.map(user => {
        Object.assign(user, {fullName: `${user.lastName}, ${user.firstName} ${user.middleName ? user.middleName : ''}`});
        return user;
      });
      this.users = _.filter(this.allUsers, {'branchId': this.authentication.context.branchId});
    });
  }

  close(){
    this.$location.path(`/customer/${this.customerId}/loans/${this.loanId}`);
  };

  $onDestroy () {
    this.usersSub.unsubscribe();
  };
}

nxModule.component('reportApprovalsFilters', {
  templateUrl: templateUrl,
  bindings: {
    'hideDate': '<',
    'approvedByName': '=',
    'checkedByName': '=',
    'displayReport': '<',
    'enableDate': '<',
    'minDate': '<',
    'date': '='
  },
  controller: ReportApprovalsFilters
});
