import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './users-change-branch.template.html';
import {setBreadcrumbs} from "../../../../shared/utils/set-breadcrumbs";

class UsersChangeBranch {
  constructor($location, breadcrumbs, command, confirmation, notification, branchService, userCache) {
    this.commandRequest = {};
    this.branches = [];
    this.description = 'Move user between branches';

    this.$location = $location;
    this.command = command;
    this.confirmation = confirmation;
    this.notification = notification;
    this.branchService = branchService;
    this.userCache = userCache;
    this.breadcrumbs = breadcrumbs;

    this.loadBranches();
  }

  async $onInit() {
    setBreadcrumbs(this.breadcrumbs, 'users-list-label', this.branch.name);
    setBreadcrumbs(this.breadcrumbs, 'users-details-label', this.user.effectiveName);
  }

  async loadBranches() {
    this.branches = await this.branchService.toPromise();
  };

  async changeBranch() {
    this.commandRequest.userId = this.user.id;

    if (this.commandRequest.targetBranchId === null || this.commandRequest.targetBranchId === undefined) {
      this.notification.show("Error", "Branch not selected!");
      return;
    }

    if (this.commandRequest.targetBranchId == this.user.branchId) {
      this.notification.show("Error", "Target branch same as current branch!");
      return;
    }

    const branch = _.find(this.branches, branch => branch.id === this.commandRequest.targetBranchId);
    const proceed = await this.confirmation(`Do you want to move user <strong>${this.user.effectiveName}</strong> to <strong>${branch.name}</strong> branch?`, true);
    if (proceed) {
      const commandResponse = await this.command.execute('MoveUserBetweenBranches', this.commandRequest).toPromise();
      if (commandResponse) {
        this.userCache.withParam().refetch();
        this.$location.path(this.links.success);
      }
    }
  };

  cancelChanges() {
    this.$location.path(this.links.cancel);
  };
}

nxModule.component('usersChangeBranch', {
  templateUrl,
  bindings: {
    branch: '<',
    links: '<',
    user: '<',
  },
  controller: UsersChangeBranch
});
