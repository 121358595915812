import _ from 'lodash';
import nxModule from 'nxModule';
import {addAccountLabels} from '../../general-ledger/common/gl.utils';
import '../common/account-picker.component';

import templateUrl from './batch-deped-payment.template.html';

class BatchDepEdPayment {
  constructor($filter, $route, http, confirmation, popup, command, glMappingsService) {
    this.$filter = $filter;
    this.$route = $route;
    this.http = http;
    this.confirmation = confirmation;
    this.popup = popup;
    this.command = command;
    this.glMappingsService = glMappingsService;
    this.input = {};
    this.selectConfig = {
      placeholder: 'Select Account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    glMappingsService.accounts.toPromise()
      .then(glAccounts => {
        this.glAccounts = addAccountLabels(
          _.filter(glAccounts, function (glAccount) {
            return ['LIABILITY'].includes(glAccount.accountGroup);
          })
        );
      });

    this.fetchHistory();
  }

  async payBatch() {
    const confirmation = await this.confirmation(`Do you want to upload and process `+this.input.fileIds.length+` file/s?`);
    if (confirmation) {
      this.paymentResult = [];
      const response = await this.command.execute('PayDepEdLoanFileBatch', this.input).toPromise();
      if(!response.approvalRequired) {
        this.paymentResult = response.output;
        const allPaymentsSucceeded = this.paymentResult.every(result => !result.hasError);
        if(allPaymentsSucceeded) {
          this.input = {};
        }

        await this.fetchHistory();
      }
    }
  }

  reset() {
    this.input = {};
    this.paymentResult = null;
  }

  async fetchHistory() {
    this.history = await this.http.get('/deped/files').toPromise();
  }
}

nxModule.component('batchDepEdPayment', {
  templateUrl,
  controller: BatchDepEdPayment
});
