import React, {MutableRefObject, ReactElement, useState} from "react";
import {NxButton, NxButtonVariant, NxQuery, NxQueryResult, NxTable, NxTableActionProps} from "@nextbank/ui-components";
import NxLoadingButton from "NxLoadingButton";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";
import {NxTableRef} from "@nextbank/ui-components/dist/nxTable/NxTableTypes";
import ReportService from "report/ReportService";
import CheckPreparationBatch from "check/preparation/history/CheckPreparationBatch";

interface HistoryRow {
  branchName: string;
  batchId: number;
  transitionDate: string;
}

interface CheckPreparationHistoryListProps {
  availableReports: string[];
  branchId: number;
  dateFrom: string;
  dateTo: string;
  tableRef: MutableRefObject<NxTableRef | null>;
  batchType: 'FOR_RELEASE' | 'FOR_OUTGOING';
  historyReports: string[];
}

const reportService: ReportService = new ReportService();
const CheckPreparationHistoryList = (props: CheckPreparationHistoryListProps): ReactElement => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<number | undefined>();

  const Print = ({data: details}: NxTableActionProps<HistoryRow>): ReactElement | null => {
  const reportsToDownload = props.availableReports.filter(pr => props.historyReports.some(r => r === pr));

  if (reportsToDownload?.length === 0) {
    return null;
  }

    return <NxLoadingButton onClick={async (): Promise<void> => {
      for (const reportName of reportsToDownload) {
        await reportService.downloadFileReport({
          reportName : reportName,
          parameters: { batchId: details.batchId }
        });
      }
    }}>
      Print
    </NxLoadingButton>;
  };

  const View = ({data: details}: NxTableActionProps<HistoryRow>): ReactElement => {
    return <>
      <NxButton
        variant={NxButtonVariant.CONTAINED}
        onClick={() : void => {setBatchId(details.batchId); setShowPopup(true);}}
      >
        View
      </NxButton>
    </>;
  };

  return <>
    <NxTable
      ref={props.tableRef}
      columns={[
        {
          field: "no",
          title: "No.",
        },
        {
          field: "branchName",
          title: "Branch Name",
        },
        {
          field: "batchId",
          title: "Batch Number",
        },
        {
          field: "transitionDate",
          title: "Transition Date",
        },
      ]}

      rowActions={[Print, View]}
      data={async (query: NxQuery): Promise<NxQueryResult<HistoryRow>> => {
        const {data} = await axios.post<PageResult<HistoryRow>>('/check-preparation/history', {
          branchId: props.branchId,
          fromDate: props.dateFrom,
          toDate: props.dateTo,
          batchType: props.batchType,
          pageNo: query.page,
          pageSize: query.pageSize
        });

        const result: HistoryRow[] = data.result.map((details, i) => {
          return {
            no: query.pageSize * data.pageNo + i + 1,
            branchName: details.branchName,
            batchId: details.batchId,
            transitionDate: details.transitionDate
          };
        });

        return {
          ...data,
          result,
        };
      }}
      sortable={false}
    />
      <CheckPreparationBatch batchId={batchId} showPopup={showPopup} batchType={props.batchType} onClose={() : void => {setShowPopup(false)}}/>
    </>
};

export default CheckPreparationHistoryList;