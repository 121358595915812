import nxModule from 'nxModule';
import _ from "lodash";
import moment from 'moment';
import {healthCheckPhase} from 'constants/healthCheckPhase';
import systemPropertyService from '../../../../../react/system/systemPropertyService';

const templateUrl = require('./close-counters.template.html');
nxModule.component('closeCounters', {
  templateUrl: templateUrl,
  controller: function ($location, http, authentication, notification, userCache, branchService,
                        currencyCache, actionCommand, confirmationTemplate, $filter, holidayService,
                        userCounterService, workingDaysCache, branchWorkingDayCache) {

    // JS 'magic'
    let that = this;
    // Branch users
    that.branchUsers = [];
    // Current branch
    that.branchId = authentication.context.branchId;
    that.systemDate = null;
    that.branchName = null;
    // Switch indicating if counters are loaded
    that.countersLoaded = false;
    // Count of unresolved tasks registered by branch
    that.registeredTasksCount = null;
    // Count of unresolved tasks assigned to branch
    that.assignedTasksCount = null;
    // Non-cleared teller counters
    that.nonClearedCounters = [];
    // Close counter transaction date
    that.transaction = {
      endCash: null,
      endCoci: null,
      branchId: that.branchId
    };
    that.denominationDisabled = false;
    that.branchHoliday = null;
    that.bankHoliday = null;

    that.healthCheckPhase = healthCheckPhase.CLOSE_COUNTERS;

    // clear branch cache
    branchService.evict();

    that.denominationLevel = systemPropertyService.getProperty('DENOMINATION_LEVEL');

    const currenciesSub = currencyCache.toObservable().subscribe(currencies => {
      that.currencies = currencies;
      that.defaultCurrency = _.head(that.currencies);
    });

    that.$onInit = async () => {
      const branches = await branchService.toPromise();
      const authBranch = _.find(branches, {id: that.branchId});

      if (authBranch) {
        that.systemDate = moment(authBranch.systemDate).toDate();
        that.branchName = authBranch.name;
        showInfoOnUpcomingHoliday();
      }
    };

    const showInfoOnUpcomingHoliday = async () => {
      const holidays = await holidayService.fetchHoliday(that.branchId, moment(that.systemDate).add(1, 'days'), 'ALL');
      that.branchHoliday = holidays.find(h => h.branchId = that.branchId);
      that.bankHoliday = holidays.find(h => !h.branchId);
    };

    /**
     * Read users associated with current branch
     */
    that.userCacheSubscription = userCache.withParam().toObservable().subscribe(users => {
      // Filter users matching [branchId]
      that.branchUsers.push(..._.filter(users, {branchId: that.branchId}));
      // Extend branch users with [fullName] property
      _.forEach(that.branchUsers, (user) => {
        user.fullName = `${user.firstName} ${user.middleName} ${user.lastName}`;
      })
    });

    that.getUserFullName = (userId) => {
      let user = _.find(that.branchUsers, {id: userId});
      return user ? user.fullName : 'Unknown user';
    };

    /**
     * Read counter values of users associated with current branch
     */
    http.get(`/management/users/counters?branchId=${that.branchId}`, {}, {nxLoaderText: 'Loading branch counters values'})
      .success((counters) => {
        that.nonClearedCounters = _.filter(counters, (c) => c && (c.totalCash || c.totalCheck));
        that.countersLoaded = true;
      })
      .error(() => notification.show("Failed to load branch counter values"));

    /**
     * Close counter action should be available if branch
     * counter values are loaded and there is no non-cleared
     * TELLER counters.
     */
    that.validateCounterState = () => {
      return that.countersLoaded && (!that.nonClearedCounters || that.nonClearedCounters.length === 0);
    };

    that.validateChecks = () => {
      if(that.checks) {
        return Object.values(that.checks).every(checks => checks.every(check => check.status === 'OK'));
      }
      return true;
    };

    /**
     * Read pending tasks registered by branch
     */
    http.get(`/tasks/counter?branchId=${that.branchId}&displayMode=OWNED&status=PENDING`, {}, {nxLoaderText: 'Loading registered tasks'})
      .success((count) => that.registeredTasksCount = count)
      .error(() => notification.show("Failed to load registered tasks"));

    /**
     * Read pending tasks assigned to branch
     */
    http.get(`/tasks/counter?branchId=${that.branchId}&displayMode=ASSIGNED&status=PENDING`, {}, {nxLoaderText: 'Loading assigned tasks'})
      .success((count) => that.assignedTasksCount = count)
      .error(() => notification.show("Failed to load assigned tasks"));

    http.get(`/system/health-details?includeStatement=true&phases=${that.healthCheckPhase}&branchId=${that.branchId}`)
      .success(data => {
        that.checks = data;
      })
      .error(error => {
        const generalInfo = `Close counters validation COULD NOT BE PERFORMED. This can be due to connectivity error or lack of HEALTH_CHECK_READ permission.
        Operation cannot be safely performed.`;
        that.error = {info: generalInfo};
        if (error.errorMessage) {
          that.error.details = `Original error message: ${error.errorMessage}`
        }
      });

    /**
     * Close counter action should be available if number of unresolved tasks
     * both: registered-by and assigned-to is 0
     */
    that.validateTasks = () => {
      return that.registeredTasksCount === 0 && that.assignedTasksCount === 0;
    };

    const fetchAutoDenomination = () => {
      // If currency is not given or denomination is disabled -> return null as data provider result
      if (!that.denominationLevel || that.denominationLevel === 'NONE') return null;
      // Otherwise fetch current branch denomination
      // TODO = fetch currency value by ISO code
      const currencyId = that.defaultCurrency.id;
      http.get(`/currencies/denomination/branches/${that.branchId}?currencyId=${currencyId}`, {}, {nxLoaderText: 'Loading auto denomination'})
        .success((branchDenomination) => {
          // Transform branch denomination into denomination bundle
          that.autoDenomination = {
            incoming: {
              denomination: {
                currencyId: 2,
                units: branchDenomination.summary
              }
            }
          };
        })
        .error(() => {
          notification.show("Failed to load auto denomination");
          that.denominationDisabled = false;
        });
    };

    /**
     * Read vault ending cash, ending COCI, and ending ATM cash.
     * Fetched values must be passed as transaction data.
     */
    http.get(`/management/branches/${that.branchId}/counter/summary`, {}, {nxLoaderText: `Loading branch's counters summary`})
      .success((counter) => {
        that.transaction.endCash = counter.totalCash;
        that.transaction.endCoci = counter.totalCheck;
        that.transaction.endAtmCash = counter.totalAtmCash;
        fetchAutoDenomination();
      })
      .error(() => notification.show("Failed to load branch's counters summary"));

    /**
     * Close counter action should be enabled
     * if both: ending cash and ending COCI are given
     */
    that.closeActionAvailable = () => {
      return that.transaction.endCash !== null && that.transaction.endCoci !== null;
    };

    that.cancel = () => {
      $location.path("/dashboard/miscellaneous-transactions");
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      confirmationTemplate({
        question: 'Do you want to close counters?',
        details: [
          {label: 'Branch', description: that.branchName},
          {label: 'Date', description:  $filter('prettyDate')(that.systemDate)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the counter can be closed.',
        yesCallback: () => actionCommand.execute('CLOSE_COUNTER', {
          endCash: that.transaction.endCash,
          endCoci: that.transaction.endCoci,
          branchId: that.transaction.branchId,
          endAtmCash: that.transaction.endAtmCash
        }, () => {
          userCounterService.refresh();
          workingDaysCache.refetch();
          branchWorkingDayCache.withParam(authentication.context.branchId).refetch();
          $location.path("/dashboard/miscellaneous-transactions");
        })
      });

    };

    that.$onDestroy = () => {
      currenciesSub.unsubscribe();
      that.userCacheSubscription.unsubscribe();
    }
  }
});
