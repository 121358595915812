import nxModule from 'nxModule';
import _ from 'lodash';
import {ActionCategory} from "../../administration/transactions/action-category.types";
import {IController, INgModelController, IAttributes} from "angular";
import templateUrl from './gl-category-select.template.html';
import './gl-category-select.style.less';
import {ActionCategoryCache} from "components/service/action/action-category.cache.types";
import Authentication from "shared/utils/authentication";

export class GlCategorySelectComponent implements IController {
  private actionType!: string;
  private singleAccountsOnly!: boolean;
  private categories: ActionCategory[] = [];
  private isSelectize: boolean = false;
  private categoryId?: ActionCategory["id"];

  private readonly actionCategorySelectizeConfig = {
    placeholder: 'Select a category',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1,
    maxOptions: Number.MAX_SAFE_INTEGER
  };

  category?: ActionCategory;
  ngModel!: INgModelController;

  constructor(
    private readonly actionCategoryCache: ActionCategoryCache,
    protected $attrs: IAttributes,
    private authentication: Authentication) {}

  async $onInit(): Promise<void> {
    if (this.ngModel) {
      this.ngModel.$render = () => {
        this.category = this.ngModel.$viewValue;
      };
    }

    this.initCategories();
  }

  async initCategories(): Promise<void> {
    if(!this.actionType) {
      this.categories = [];
      return;
    }

    const allCategories: ActionCategory[] = await this.actionCategoryCache.toPromise();
    this.categories = allCategories
      .filter(cat => cat.actionType === this.actionType)
      .filter(cat => this.singleAccountsOnly ? (cat.ledgerAccountFullCodes || []).length === 1 : true)
      .filter(cat => _.isEmpty(cat.allowedBranchIds) || cat.allowedBranchIds.includes(this.authentication.context.branchId));
  }

  valueChanged(): void {
    if (this.ngModel) {

      if(this.isSelectize) {
        this.category = this.categories.find(c=> Number(c.id) === Number(this.categoryId))
      }
      this.ngModel.$setViewValue(this.category);
    }
  }

  $onChanges(onChangesObj: angular.IOnChangesObject): void {
    this.initCategories();
  }
}

nxModule.component('glCategorySelect', {
  controller: GlCategorySelectComponent,
  require: {
    ngModel: '^ngModel'
  },
  bindings: {
    'actionType': '<',
    'singleAccountsOnly': '<',
    'isSelectize' : '<'
  },
  templateUrl,
});