import nxModule from 'nxModule';
import templateUrl from "./cash-to-from-bank.template.html";
import {MiscCommandService} from "../misc-command.service";
import {SelectConfig} from "../../../common/types/selectize.types";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import Authentication from "shared/utils/authentication";
import {BranchService} from "components/service/branch.service";
import {DepositoryAccountCache} from "components/service/misc-transaction/depository-account.cache.types";
import DepositoryAccount from "check/DepositoryAccountTypes";
import {Branch} from "management/BranchTypes";

type DIRECTION = 'FROM' | 'TO';


interface ViewContext {
  actionBtnClass: 'btn-danger' | 'btn-success',
  type: 'Withdraw' | 'Deposit',
  header: () => string,
  commandName: () => string,
}

interface Transaction {
  depositoryAccountId: number,
  targetBranchId: number,
  amount: number,
  remarks: string
}

class CashToFromBank {
  private direction!: DIRECTION;
  private interbranch!: boolean;

  private readonly selectConfig: SelectConfig = {
    placeholder: 'Select account',
    searchField: ['accountName'],
    valueField: 'id',
    labelField: 'accountName',
    maxItems: 1
  };
  private readonly viewContexts: { [dir in DIRECTION]: ViewContext; } = {
    'FROM': {
      actionBtnClass: 'btn-danger',
      type: 'Withdraw',
      header: () => this.interbranchPrefixOrPass('Cash withdrawal from depository bank'),
      commandName: () => `Misc${this.interbranchOrEmpty()}CashFromBank`,
    },
    'TO': {
      actionBtnClass: 'btn-success',
      type: 'Deposit',
      header: () => this.interbranchPrefixOrPass('Cash to depository bank'),
      commandName: () => `Misc${this.interbranchOrEmpty()}CashToBank`,
    }
  };

  private viewContext!: ViewContext;
  private header!: string;
  private transaction: Partial<Transaction> = {};
  private allDepositoryAccounts: DepositoryAccount[] = [];
  private depositoryAccounts: DepositoryAccount[] = [];
  private allowedBranches: Branch[] = [];

  private readonly targetBranchSelectConfig = {
    placeholder: 'Select a branch',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  constructor(private readonly actionCommand: ActionCommand,
              private readonly authentication: Authentication,
              private readonly branchService: BranchService,
              private readonly depositoryAccountCache: DepositoryAccountCache,
              private readonly miscCommandService: MiscCommandService) {
  }

  async $onInit(): Promise<void> {
    const [depositoryAccounts, branches] = await Promise.all([
      this.depositoryAccountCache.toPromise(),
      this.branchService.toPromise()
    ]);

    this.allowedBranches = branches.filter(b => Number(b.id) !== Number(this.authentication.context.branchId));
    this.allDepositoryAccounts = depositoryAccounts;
    this.viewContext = this.viewContexts[this.direction];
    this.header = this.viewContext.header();

    this.transaction = <Transaction>{};

    if (!this.interbranch) {
      this.transaction.targetBranchId = Number(this.authentication.context.branchId);
      this.updateDepositoryAccounts();
    }
  }

  cancel(): void {
    this.actionCommand.cancelChanges();
  }

  save(): void {
    this.miscCommandService.executeCommand(this.viewContext.commandName(), this.transaction);
  }

  updateDepositoryAccounts(): void {
    this.transaction.depositoryAccountId = undefined;
    this.depositoryAccounts = this.allDepositoryAccounts.filter(account =>
      account.allowedMiscCommands?.includes(this.viewContext.commandName())
      && Number(account.branchId) === Number(this.transaction.targetBranchId)
    );
  }

  private interbranchPrefixOrPass(nonInterbranchHeader: string): string {
    return this.interbranch ? 'Interbranch ' + nonInterbranchHeader.toLowerCase() : nonInterbranchHeader;
  }

  private interbranchOrEmpty(): string {
    return this.interbranch ? 'Interbranch' : '';
  }
}

nxModule.component('cashToFromBank', {
  templateUrl,
  bindings: {
    interbranch: '<',
    direction: '<'
  },
  controller: CashToFromBank
});
