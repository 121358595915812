import nxModule from 'nxModule';

import templateUrl from './credit-scoring-modal-features.template.html';
import './credit-scoring-modal-features.styles.less'
import {CreditScoreDescription} from "components/customer/credit-scoring/credit-scoring.types";
import {getChart} from "components/service/chartjs.service";

class CreditScoringModalFeatures {
  featureName!: string;
  scoreDescription!: CreditScoreDescription;

  async $onInit(): Promise<void> {
    await this.createFeaturesChart();
  }

  private async createFeaturesChart(): Promise<void> {
    const Chart = await getChart();
    const canvas = document.querySelector<HTMLCanvasElement>('#' + this.featureName);
    if (!canvas) {
      throw new Error('Could not find chart component');
    }
    const features = this.scoreDescription.featureImportance[this.featureName];
    const labels = features.map(f => f.feature);
    const values = features.map(f => f.value);

    new Chart(canvas, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Importance score',
          data: values,
          backgroundColor: [
            getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
          ],
          borderWidth: 0,
          maxBarThickness: 35
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        indexAxis: 'y',
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            align: "center"
          },
          tooltip: {
            enabled: false
          },
        }
      }
    });
  }
}

nxModule.component('creditScoringModalFeatures', {
  templateUrl,
  bindings: {
    scoreDescription: '<',
    featureName: '<'
  },
  controller: CreditScoringModalFeatures
});
