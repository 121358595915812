import nxModule from 'nxModule';
import templateUrl from './center-groups.template.html';
import {ILocationService} from 'angular';
import {
  AddCustomerGroupToCenterInput,
  CenterService,
  TransferCustomerGroupToAnotherCenterInput
} from '../../../service/center/center.service';
import {Center, CustomerGroupWithFundDetails} from '../../../service/center/center.types';
import './center-groups.style.less';
import {CustomerGroupSearchResult} from '../group-picker/group-picker.component';
import {NxRouteService} from "routes/NxRouteService";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from "shared/utils/command/command.types";
import {GroupCustomerCache} from "components/service/group-customer.cache.types";
import {ModalApi} from "components/technical/modal/modal.component";

class CenterGroupsComponent {
  private readonly center!: Center;

  private groups!: CustomerGroupWithFundDetails[];
  private transferGroup!: CustomerGroupWithFundDetails | null;
  private transferModalApi!: ModalApi;

  constructor(private readonly $location: ILocationService,
              private readonly command: CommandService,
              private readonly confirmation: Confirmation,
              private readonly $route: NxRouteService,
              private readonly centerService: CenterService,
              private readonly groupCustomerCache: GroupCustomerCache) {
  }

  async $onInit(): Promise<void> {
    this.centerService.loadContext(this.center);
    this.groups = await this.centerService.fetchGroups({centerId: this.center.id});
  }

  async remove($event: Event, group: CustomerGroupWithFundDetails): Promise<void> {
    $event.stopPropagation();

    const confirmed = await this.confirmation(`Are you sure you want remove this group [${group.name}] from this center?`);
    if (confirmed) {
      const response = await this.command.execute('RemoveCustomerGroupInCenter', {customerGroupId: group.id},
        {nxLoaderText: 'Removing customer group in center...'}).toPromise();
      if (!response.approvalRequired) {
        this.groupCustomerCache.evict();
        this.$route.reload();
      }
    }
  }

  open($event: Event, group: CustomerGroupWithFundDetails): void {
    $event.stopPropagation();
    this.$location.path(`/customer/${group.customerId}/group-loans`);
  }

  filterGroupPickerResult = (result: CustomerGroupSearchResult): boolean => {
    return result.customerGroupCenter?.id !== this.center.id;
  };

  transfer = async (newCenter: Center): Promise<void> => {
    await this.transferModalApi.onOk();

    const group = this.transferGroup!;
    const input: TransferCustomerGroupToAnotherCenterInput = {
      customerGroupId: group.id,
      newCenterId: newCenter.id
    };

    const response = await this.command.execute('TransferCustomerGroupToAnotherCenter', input,
      {nxLoaderText: 'Transferring customer group to another center...'}).toPromise();
    if (!response.approvalRequired) {
      this.groupCustomerCache.evict();
      this.$route.reload();
    }
  };

  async openTransferModal($event: Event, group: CustomerGroupWithFundDetails): Promise<void> {
    $event.stopPropagation();
    this.transferGroup = group;
    await this.transferModalApi.show();
  }

  onTransferModalApiReady = ({api}: {api: ModalApi}): void => {
    this.transferModalApi = api;
  }

  add = async (result: CustomerGroupSearchResult): Promise<void> => {
    const input: AddCustomerGroupToCenterInput = {
      customerGroupId: result.customerGroupId,
      centerId: this.center.id
    };
    const response = await this.command.execute('AddCustomerGroupToCenter', input,
      {nxLoaderText: 'Adding customer group to center...'}).toPromise();
    if (!response.approvalRequired) {
      this.groupCustomerCache.evict();
      this.$route.reload();
    }
  }

  transferHere = async (result: CustomerGroupSearchResult): Promise<void> => {
    const input: TransferCustomerGroupToAnotherCenterInput = {
      customerGroupId: result.customerGroupId,
      newCenterId: this.center.id
    };
    const response = await this.command.execute('TransferCustomerGroupToAnotherCenter', input,
      {nxLoaderText: 'Transferring group here...'}).toPromise();
    if (!response.approvalRequired) {
      this.groupCustomerCache.evict();
      this.$route.reload();
    }
  };
}

nxModule.component('centerGroups', {
  templateUrl,
  controller: CenterGroupsComponent,
  bindings: {
    center: '<',
    groups: '<'
  }
});
