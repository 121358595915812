import nxModule from 'nxModule';

export class QueryParamsRemover {
  constructor($location) {
    this.$location = $location
  }

  removeQueryOnRedirect(scope, paramKeys) {
    scope.$on('$routeChangeStart', (evt, next, current) => {
      if (current && next && current.originalPath !== next.originalPath) {
        let params = this.$location.search();
        paramKeys.forEach(param => delete params[param]);
        this.$location.search(params).replace();
      }
    });
  }
}

nxModule.factory('queryParamsRemover', QueryParamsRemover);
