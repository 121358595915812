import nxModule from 'nxModule';
import moment from "moment";
import {Holiday} from "../../../../../react/management/WorkingDayType";
import templateUrl from './delete-running-bank-holiday.template.html';
import {Branch} from "management/BranchTypes";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {BranchHolidayCache} from "components/service/branch-holiday.cache.types";
import RefreshService from "shared/utils/refresh.service";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {BranchService} from "components/service/branch.service";

class DeleteRunningBankHoliday {

  branches!: Branch[];
  affectedBranches: Branch[] = [];
  holidays: Holiday[] = [];
  holiday?: Holiday;
  noRunningBankHolidays: boolean = false;

  constructor(private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly branchService: BranchService,
              private readonly branchHolidayCache: BranchHolidayCache,
              private readonly $route: NxRouteService,
              private readonly command: CommandService,
              private readonly refreshService: RefreshService) {
  }

  async $onInit(): Promise<void> {
    const [allBranches, holidays] = await Promise.all([
      this.branchService.toPromise(),
      this.branchHolidayCache.withParam({
        types: ['HOLIDAY'],
        runningHoliday: true,
        mode: 'BANK'
      }).toPromise()]);

    this.holidays = holidays.filter((h: Holiday) => !h.branchId);
    this.noRunningBankHolidays = this.holidays.length === 0;
    this.branches = allBranches;
  }

  onHolidayChange(): void {
    this.affectedBranches = this.branches.filter((b: Branch) => b.systemDate === this.holiday?.date);
  }

  async execute(): Promise<void> {
    if(!this.holiday) {
      return;
    }
    const affectedBranchNames = this.affectedBranches.map((b: Branch) => b.name).join();

    const proceed = await this.confirmationTemplate({
      question: `The running bank holiday will be deleted. The posting date of branches without any branch-specific holiday will reset to ${moment(this.holiday.date).format('MM/DD/YYYY')}.`,
      details: [
        {label: 'Branches', description: affectedBranchNames},
        {label: 'Holiday name', description: this.holiday.name},
        {label: 'Holiday date', description: moment(this.holiday.date).format('MM/DD/YYYY')},
      ]
    });

    if (!proceed) return;

    await this.command.execute('DeleteRunningBankHoliday', {
      id: this.holiday.id
    }).toPromise();

    this.refreshService.refreshPage();
  }
}

nxModule.component('deleteRunningBankHoliday', {
  templateUrl,
  controller: DeleteRunningBankHoliday
});
