import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import Notification from 'shared/utils/notification';
import {sum} from '../../../../shared/common/MathUtils';
import {IComponentController, IFormController, ILocationService, IScope} from 'angular';

import templateUrl from './gl-transaction-interbranch-create.template.html';
import './gl-transaction-interbranch-create.style.less';
import {defaultHeaderLabels, flattenSubAccounts} from '../../common/gl.utils';
import {CONTINGENT_ACCOUNT_NAME} from '../../common/gl.consts';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {Ledger, LedgerAccount, LedgerTransaction, LedgerTransactionUnit} from '../../common/gl.types';
import {Holiday} from '../../../../../react/management/WorkingDayType';
import {Branch, WorkingDay} from "management/BranchTypes";
import {
  BATCH_INTERBRANCH_TRANSACTION_SAMPLE_FILE,
  GL_TRANSACTION_INPUT_MODE,
  NON_CONTINGENT_ACCOUNT_GROUP,
  SimulateGlTransactionResult
} from "components/general-ledger/transactions/gl-transaction.types";
import HolidayService from "components/service/holiday.service";
import moment from 'moment';
import {GlLedgerService} from "components/general-ledger/common/gl-ledger.service.types";
import {GlAccountService} from "components/general-ledger/common/gl-account.service";
import {BranchService} from "components/service/branch.service";
import {ReportModal} from "components/report/modal/report-modal.service.types";
import {Breadcrumbs} from "angular-breadcrumbs";
import {Confirmation} from "shared/common/confirmation.types";
import {HttpService} from "shared/utils/httpService";
import {Loader} from "components/technical/loader.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {GlTransactionService} from "components/general-ledger/common/gl-transaction.service";
import {AttachedFile} from "components/service/file.service";

type TransactionUnit = {
  transactionId?: number;
  categoryId?: number;
  fullCode?: string;
  branchId?: number | string;
  debitAmount: number;
  creditAmount: number;
  remarks: string;
};

type EntryType = 'CREDIT' | 'DEBIT';

function reverse(entryType: EntryType | null): EntryType | null {
  if (!entryType) {
    return null;
  } else {
    return entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT';
  }
}

type TransactionUnitRequest = {
  id?: number;
  transactionId?: number;
  ledgerAccountId: number;
  entryType: EntryType;
  amount: number;
  remarks: string;
};

type LedgerTransactionRequest = {
  id?: number,
  ledgerId: number,
  transactionUnits: TransactionUnitRequest[],
};

type InterbranchTransactionRequest = {
  source: LedgerTransactionRequest,
  targets: LedgerTransactionRequest[],
  backdatedPostingDate?: string | null
};

type Mode = 'EDIT' | 'CREATE';

type BranchWithLabel = Branch & { label: string };

class GlTransactionInterbranchCreate implements IComponentController {
  // bindings
  private sourceLedgerId!: number;
  private sourceTransactionId!: number;
  private backdated!: boolean;


  private mode!: Mode;
  private loaderId: number;
  private allLedgers!: Ledger[];
  createTransactionForm!: IFormController;
  header: {label: string, text: string}[] = [];
  transactionUnits: TransactionUnit[] = [];
  sourceBranchCategory?: ActionCategory | null = null;
  targetBranchCategory?: ActionCategory | null = null;
  allBranches!: BranchWithLabel[];
  nonSourceBranches!: BranchWithLabel[];
  useCategories = false;
  glAccounts!: LedgerAccount[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sourceBranchId: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  maxBackdatedPostingDate: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  minBackdatedPostingDate: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  branchSystemDate: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backdatedPostingDate: string;
  postingDateValid: boolean = true;


  selectizeConfig = {
    maxItems: 1,
    labelField: ['label'],
    valueField: 'id',
    sortField: 'label',
    searchField: ['label']
  };

  private readonly glTransactionInputMode = GL_TRANSACTION_INPUT_MODE;
  private inputMode = 'MANUAL';
  private contentFile : AttachedFile[] = [];

  constructor(private readonly glLedgerService: GlLedgerService,
              private readonly glAccountService: GlAccountService,
              private readonly branchService: BranchService,
              private readonly reportModal: ReportModal,
              private readonly $location: ILocationService,
              private readonly $route: NxRouteService,
              private readonly breadcrumbs: Breadcrumbs,
              private readonly confirmation: Confirmation,
              private readonly command: CommandService,
              private readonly http: HttpService,
              private readonly loader: Loader,
              private readonly glTransactionService: GlTransactionService,
              private readonly holidayService: HolidayService,
              private readonly $scope: IScope,
              private readonly notification: Notification) {
    this.loaderId = loader.show('Loading...');
  }

  async $onInit(): Promise<void> {
    this.mode = this.sourceTransactionId ? 'EDIT' : 'CREATE';

    const [allLedgers, allBranches, glAccounts] = await Promise.all([
      this.glLedgerService.toPromise(),
      this.branchService.toPromise(),
      this.glAccountService.fetchAccountsAsync(this.sourceLedgerId, {leafOnly: true}),
    ]);

    this.transactionUnits = await this.initializeModifiedTransaction(allLedgers);
    this.allBranches = allBranches.map(branch => ({
      label: `${branch.name} - ${branch.systemDate}`,
      ...branch
    }));

    this.allLedgers = allLedgers;

    const sourceBranchLedger = this.allLedgers.find(ledger => ledger.id === this.sourceLedgerId)!;
    this.header = defaultHeaderLabels({
      ...sourceBranchLedger,
      branch: this.getBranchById(sourceBranchLedger.branchId)
    });

    this.sourceBranchId = sourceBranchLedger.branchId;
    this.nonSourceBranches = this.allBranches.filter(branch => branch.id !== sourceBranchLedger.branchId);

    this.glAccounts = this.filterOperationalAccounts(flattenSubAccounts(glAccounts));
    this.loader.dismiss(this.loaderId);

    await this.calculateBackdatedTransactionProperties();
    await this.validatePostingDate();

    this.$scope.$watch('$ctrl.contentFile', () => {
      if (!this.hasUploadedFile() && this.mode === 'CREATE') {
        this.transactionUnits = [];
      }
    });
  }

  getBranchLabel(id: number): string {
    return this.getBranchById(id).label;
  }

  getBranchById(id: number | string): BranchWithLabel {
    return this.allBranches.find(branch => branch.id === Number(id))!;
  }

  filterOperationalAccounts(ledgerAccounts: LedgerAccount[]): LedgerAccount[] {
    return _.filter(ledgerAccounts, glAccount => CONTINGENT_ACCOUNT_NAME !== glAccount.accountGroup);
  }

  async calculateBackdatedTransactionProperties() {
    const calculatedMinAndMaxRange = await this.glTransactionService.calculateBackdatedPostingDateProperties(this.mode === 'EDIT');
    this.maxBackdatedPostingDate = calculatedMinAndMaxRange.maxBackdatedPostingDate;
    this.minBackdatedPostingDate = calculatedMinAndMaxRange.minBackdatedPostingDate;
    this.branchSystemDate = calculatedMinAndMaxRange.branchSystemDate;
  }

  /**
   * If this.sourceTransactionId is defined, it means that transaction is modified.
   * Additionally, this.backdated and this.backdatedPostingDate will be overridden with values from the original transaction.
   */
  async initializeModifiedTransaction(allLedgers: Ledger[]): Promise<TransactionUnit[]> {
    if (!this.sourceTransactionId) {
      return [];
    }

    const [sourceTransaction, targetTransactions] = await Promise.all([
      this.http.get<LedgerTransaction>(`/ledger/transactions/${this.sourceTransactionId}`).toPromise(),
      this.http.get<LedgerTransaction[]>('/ledger/transactions', {
        params: {
          status: ['CREATED', 'PENDING', 'PROCESSED'],
          interbranch: true,
          interbranchSourceTransactionId: this.sourceTransactionId
        }
      }).toPromise()
    ]);

    this.backdated = sourceTransaction.backdated;
    this.backdatedPostingDate = sourceTransaction.postingDate;

    return [
      ...this.toTransactionUnits(sourceTransaction, allLedgers),
      ..._.flatten(targetTransactions.map(transaction => this.toTransactionUnits(transaction, allLedgers)))
    ];
  }

  toTransactionUnits(ledgerTransaction: LedgerTransaction, allLedgers: Ledger[]): TransactionUnit[] {
    return (ledgerTransaction.transactionUnits || [])
      .map(unit => {
        const ledger = allLedgers.find(ledger => ledger.id === ledgerTransaction.ledgerId)!;
        return this.toTransactionUnit(unit, ledger.branchId, ledgerTransaction.id);
      });
  }

  toTransactionUnit(unit: LedgerTransactionUnit, branchId: number, transactionId: number): TransactionUnit {
    return {
      transactionId,
      fullCode: unit.ledgerAccountFullCode,
      branchId,
      remarks: unit.remarks,
      debitAmount: unit.entryType === 'DEBIT' ? unit.amount : 0,
      creditAmount: unit.entryType === 'CREDIT' ? unit.amount : 0
    };
  }

  async fetchLedgerAccounts(ledgerIds: number[]): Promise<Map<number, LedgerAccount[]>> {
    type LedgerWithAccounts = {
      ledgerId: number;
      accounts: LedgerAccount[]
    };

    const ledgersWithAccounts: LedgerWithAccounts[] = await Promise.all(
      ledgerIds.map(async ledgerId => {
        const glAccounts: LedgerAccount[] = await this.glAccountService.fetchAccountsAsync(ledgerId, {leafOnly: true});
        const flattenedAccounts = flattenSubAccounts(glAccounts)
          .filter((account: LedgerAccount): boolean => account.accountGroup !== CONTINGENT_ACCOUNT_NAME);

        return {
          ledgerId,
          accounts: flattenedAccounts
        };
      })
    );

    const ledgerMap = new Map<number, LedgerAccount[]>();
    for (const ledgerWithAccounts of ledgersWithAccounts) {
      ledgerMap.set(ledgerWithAccounts.ledgerId, ledgerWithAccounts.accounts);
    }

    return ledgerMap;
  }

  sourceBranchCategoryChanged() {
    const sourceCategoryTransactions: TransactionUnit[] = [];

    this.sourceBranchCategory?.ledgerAccountFullCodes?.forEach(code => {
      sourceCategoryTransactions.push({
        categoryId: this.sourceBranchCategory?.id,
        branchId: this.sourceBranchId,
        fullCode: code,
        debitAmount: 0,
        creditAmount: 0,
        remarks: ''
      });
    });

    this.transactionUnits = [
      ...sourceCategoryTransactions,
      ...this.transactionUnits.filter(unit => Number(unit.branchId) !== this.sourceBranchId)
    ];
  }

  targetBranchCategoryChanged() {
    const targetCategoryTransactions = [];
    for (const code of (this.targetBranchCategory ? this.targetBranchCategory.ledgerAccountFullCodes : [])) {
      targetCategoryTransactions.push({
        categoryId: this.sourceBranchCategory?.id,
        fullCode: code,
        debitAmount: 0,
        creditAmount: 0,
        remarks: ''
      });
    }

    this.transactionUnits = [
      ...this.transactionUnits.filter(unit => Number(unit.branchId) == this.sourceBranchId),
      ...targetCategoryTransactions
    ];
  }

  calculateDebitSum(filterFn: (t: TransactionUnit) => boolean = () => true): BigNumber {
    const units: TransactionUnit[] = this.transactionUnits.filter(filterFn);
    return sum(units.map(u => u.debitAmount));
  }

  calculateCreditSum(filterFn?: (t: TransactionUnit) => boolean): BigNumber {
    const units: TransactionUnit[] = this.transactionUnits.filter(filterFn || (() => true));
    return sum(units.map(u => u.creditAmount));
  }

  isInterbranchTransactionBalancedOnCreate(): boolean {
    const sourceDebit: BigNumber = this.calculateDebitSum(
      (u: TransactionUnit): boolean => Number(u.branchId) === this.sourceBranchId);
    const sourceCredit: BigNumber = this.calculateCreditSum(
      (u: TransactionUnit): boolean => Number(u.branchId) === this.sourceBranchId);

    const targetsDebit: BigNumber = this.calculateDebitSum(
      (u: TransactionUnit): boolean => Number(u.branchId) !== this.sourceBranchId);
    const targetsCredit: BigNumber = this.calculateCreditSum(
      (u: TransactionUnit): boolean => Number(u.branchId) !== this.sourceBranchId);

    return sourceCredit.minus(sourceDebit).isEqualTo(targetsCredit.minus(targetsDebit).times(-1));
  }

  areAllLedgerTransactionsBalanced(): boolean {
    const byBranchId = _.groupBy(this.transactionUnits, 'branchId');

    return Object.keys(byBranchId).every(branchId => {
      const debit = this.calculateDebitSum(u => Number(u.branchId) === Number(branchId));
      const credit = this.calculateCreditSum(u => Number(u.branchId) === Number(branchId));
      return debit.isEqualTo(credit);
    });
  }

  /**
   * Does both total amounts (debit and credit) are bigger than 0
   */
  hasNonZeroTotalAmounts(): boolean {
    return [this.calculateDebitSum(), this.calculateCreditSum()].every(val => val.isGreaterThan(0));
  }

  isSourceBranchIncluded(): boolean {
    return this.transactionUnits.some(unit => Number(unit.branchId) === this.sourceBranchId);
  }

  getLedgerId(branchId: number): number {
    return this.allLedgers.find(ledger => ledger.branchId === branchId)!.id;
  }

  sourceEffectiveEntryType(): EntryType | null {
    const sourceDebit: BigNumber = this.calculateDebitSum(u => Number(u.branchId) === this.sourceBranchId);
    const sourceCredit: BigNumber = this.calculateCreditSum(u => Number(u.branchId) === this.sourceBranchId);

    return this.resolveEffectiveEntryType(sourceDebit, sourceCredit);
  }

  targetEffectiveEntryTypes(): { branchId: number, entryType: EntryType }[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return _.uniq(this.transactionUnits
      .filter(u => Number(u.branchId) !== this.sourceBranchId)
      .map(u => Number(u.branchId))
    ).map((branchId: number) => {
        return {
          branchId,
          entryType: this.resolveEffectiveEntryType(
            this.calculateDebitSum(u => Number(u.branchId) === branchId),
            this.calculateCreditSum(u => Number(u.branchId) === branchId)
          )
        };
      }
    ).filter(ledgerEntry => ledgerEntry.entryType != null);
  }

  invalidTargetOnCreate() {
    const sourceEntryType = this.sourceEffectiveEntryType();
    return sourceEntryType ? this.targetEffectiveEntryTypes().find(targetEntry => targetEntry.entryType !== reverse(sourceEntryType)) : null;
  }

  /**
   * This warning message is shown when one of target ledgers transaction has same effective entryType (unit DEBIT 200 + unit CREDIT 100 = effective DEBIT)
   * as source ledger transaction
   */
  invalidTargetWarningMessage(): string | null {
    const maybeInvalidTarget = this.invalidTargetOnCreate();
    if (!maybeInvalidTarget) {
      return null;
    }

    const sourceEntryType = this.sourceEffectiveEntryType();

    const sourceBranch = this.getBranchById(this.sourceBranchId);
    const invalidTargetBranch = this.getBranchById(maybeInvalidTarget.branchId);

    return `Branch ${invalidTargetBranch.name} is ${maybeInvalidTarget.entryType}ED, while source branch ${sourceBranch.name} is also ${sourceEntryType}ED. This may cause problems with interbranch transaction distribution. To assure that transaction will be created properly, all branches except of ${sourceBranch.name} should be ${reverse(sourceEntryType)}ED.`
  }

  invalidTargetSolutionMessage(): string | null {
    const sourceEntryType = this.sourceEffectiveEntryType();
    const validTargets = this.targetEffectiveEntryTypes().filter(target => target.entryType === reverse(sourceEntryType));
    if (validTargets.length === 1) {
      const branch = this.getBranchById(validTargets[0].branchId);
      return `Solution: Please initiate this transaction from ${branch.name} branch.`;
    } else if (validTargets.length > 1) {
      return 'Solution: Please add gl ticket in every ledger involved in this transaction manually.';
    }

    return null;
  }

  resolveEffectiveEntryType(debitAmount: BigNumber, creditAmount: BigNumber): EntryType | null {
    if (debitAmount.isGreaterThan(creditAmount)) {
      return 'DEBIT';
    } else if (creditAmount.isGreaterThan(debitAmount)) {
      return 'CREDIT';
    } else {
      return null;
    }
  }

  isFormValid() {
    if (this.transactionUnits.length < 1) {
      return false;
    }

    return this.createTransactionForm.$valid &&
      (this.mode !== 'CREATE' || this.isInterbranchTransactionBalancedOnCreate()) &&
      this.isSourceBranchIncluded() &&
      (this.mode !== 'EDIT' || this.areAllLedgerTransactionsBalanced()) &&
      this.hasNonZeroTotalAmounts()
      && !this.invalidTargetOnCreate();
  }

  canPrint(): boolean {
    return !!this.sourceTransactionId && this.createTransactionForm.$pristine;
  }

  print = async ($event: JQueryEventObject): Promise<void> => {
    if ($event) {
      $event.stopPropagation();
    }
    this.reportModal.display({
      reportCode: 'GeneralLedgerTicketReport',
      params: {transactionId: this.sourceTransactionId, branchId: this.sourceBranchId},
      hideXls: true
    });
  };

  resetDebitAmount(operation: TransactionUnit): void {
    if (operation.creditAmount > 0) {
      operation.debitAmount = 0;
    }
  }

  resetCreditAmount(operation: TransactionUnit): void {
    if (operation.debitAmount > 0) {
      operation.creditAmount = 0;
    }
  }

  removeUnit(index: number): void {
    this.transactionUnits = this.transactionUnits.filter((unit, i) => i !== index);
    this.validatePostingDate();
  }

  async cancelChanges(): Promise<void> {
    const parentPath = _.nth<{ path: string }>(this.breadcrumbs.get(), -2)!.path;
    if (await this.confirmation('Do you want to cancel? Canceling will discard all changes.')) {
      this.$location.path(parentPath);
    }
  }

  createTransactionUnitRequest(transactionUnit: TransactionUnit, accounts: LedgerAccount[]): TransactionUnitRequest {
    return {
      transactionId: transactionUnit.transactionId,
      ledgerAccountId: accounts.find(account => account.fullCode === transactionUnit.fullCode)!.id,
      entryType: transactionUnit.creditAmount > 0 ? 'CREDIT' : 'DEBIT',
      amount: transactionUnit.creditAmount || transactionUnit.debitAmount,
      remarks: transactionUnit.remarks
    };
  }

  async createInterbranchRequest(): Promise<InterbranchTransactionRequest> {
    const byBranchId = _.groupBy(this.transactionUnits, 'branchId');

    const usedBranches = Object.keys(byBranchId).map(branchId => Number(branchId));
    const allUsedLedgers = this.allLedgers
      .filter(ledger => usedBranches.includes(ledger.branchId))
      .map(ledger => ledger.id);

    const ledgerAccounts = await this.fetchLedgerAccounts(allUsedLedgers);
    const sourceBranchUnits = this.transactionUnits
      .filter(u => Number(u.branchId) === this.sourceBranchId);

    const backdatedPostingDate = this.backdated ? this.backdatedPostingDate : null;

    return {
      source: this.createTransactionUnitsRequest(sourceBranchUnits, this.sourceBranchId, ledgerAccounts),
      targets: usedBranches
        .filter((branchId: number): boolean => branchId !== this.sourceBranchId)
        .map(branchId => this.createTransactionUnitsRequest(byBranchId[branchId], branchId, ledgerAccounts)),
      backdatedPostingDate: backdatedPostingDate
    };
  }

  createTransactionUnitsRequest(units: TransactionUnit[], branchId: number, ledgerAccounts: Map<number, LedgerAccount[]>): LedgerTransactionRequest {
    const ledgerId = this.getLedgerId(branchId);
    return {
      id: units[0].transactionId,
      ledgerId,
      transactionUnits: units.map(unit => this.createTransactionUnitRequest(unit, ledgerAccounts.get(ledgerId)!))
    };
  }

  resetForm(): void {
    this.transactionUnits = [];
    this.sourceBranchCategory = null;
    this.targetBranchCategory = null;
    this.contentFile = [];
  }

  addNewUnit(): void {
    this.transactionUnits.push({
      debitAmount: 0,
      creditAmount: 0,
      remarks: ''
    });
  }

  /**
   * Posting date is valid when:
   * - posting dates of every branch involved are equal
   * - posting dates of every branch involved is a working day
   * - in case of a backdated transaction, posting date must be less than posting date of every Branch.
   */
  async validatePostingDate(): Promise<void> {
    const ticketPostingDate = this.backdated ? this.backdatedPostingDate : this.branchSystemDate;
    if (!ticketPostingDate) {
      return;
    }

    const ticketBranchIds = _.uniq(this.transactionUnits.filter(u => u.branchId).map(u => Number(u.branchId)));
    const [holidays, branches] = await Promise.all([
      this.getBranchHolidays(ticketPostingDate, ticketBranchIds),
      this.branchService.toPromise()
    ]);

    const ticketBranches = ticketBranchIds.map(id => branches.find(b => b.id == id)!);
    const ticketWeekDay: WorkingDay = new Date(ticketPostingDate).toLocaleString('en-us', {weekday: 'long'}).toUpperCase() as WorkingDay;
    const isBranchWorkingDay = ticketBranches.every(b => b.workingDays.includes(ticketWeekDay))
    if (this.backdated) {
      const ticketPostingBeforeBranches = ticketBranches.every(b => ticketPostingDate < b.postingDate);
      this.postingDateValid = _.isEmpty(holidays) && isBranchWorkingDay && ticketPostingBeforeBranches;
    } else {
      this.postingDateValid = _.isEmpty(holidays) && isBranchWorkingDay;
    }
  }

  private async getBranchHolidays(ticketPostingDate: string, branchIds: number[]): Promise<Holiday[]> {
    const holidays = await Promise.all(branchIds.map(id => this.holidayService.fetchHoliday(id, moment(ticketPostingDate), 'ALL')));
    return holidays.flatMap(h => h);
  }

  async submit(): Promise<void> {
    const request: InterbranchTransactionRequest = await this.createInterbranchRequest();
    const result = await this.glTransactionService.createOrUpdateInterbranchTransaction(request, this.mode === 'EDIT', this.backdated);
    if (!this.backdated && this.mode === 'CREATE') {
      this.$location.path(`/general-ledger/transactions/${this.sourceLedgerId}/modify-interbranch/${result.output.id}`);
    } else if (!this.backdated && this.mode === 'EDIT') {
      // reload route to set the transaction as $pristine
      this.$route.reload();
    } else {
      //return to list if backdated - because we cannot modify them
      this.notification.show('GL backdated interbranch ticket cannot be modified, redirecting to the GL transaction list');
      this.$location.path(`/general-ledger/transactions/${this.sourceLedgerId}`);
    }
  }

  async inputModeChanged(newInputMode: string, oldInputMode: string) {
    if (this.hasOperations() || this.hasUploadedFile()) {
      const confirmed = await this.confirmation(`Changing mode will disregard all changes. Do you want to proceed?`);
      if (!confirmed) {
        this.inputMode = oldInputMode;
        return;
      }
    }
    this.useCategories = this.inputMode === 'MANUAL';
    this.resetForm();
  }

  hasUploadedFile = () => {
    return this.contentFile != null && this.contentFile.length > 0;
  }

  hasOperations = () => {
    return !_.isEmpty(this.transactionUnits);
  };

  downloadSampleFile() {
    const sampleFileUrl = window.URL.createObjectURL(BATCH_INTERBRANCH_TRANSACTION_SAMPLE_FILE);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'BatchCreateInterbranchLedgerTransaction';
    a.click();
  }

  simulateButtonEnabled() {
    return this.hasUploadedFile()
      && !this.hasOperations()
      && (!this.backdated
      || (this.backdatedPostingDate
      && this.backdatedPostingDateWithinValidRange()
      && this.postingDateValid));
  }

  backdatedPostingDateWithinValidRange(): boolean {
    return this.backdatedPostingDate >= this.minBackdatedPostingDate
    && this.backdatedPostingDate <= this.maxBackdatedPostingDate;
  }

  async simulate() {
    const result = await this.glTransactionService.simulateBatchTransaction({
      ledgerId: this.sourceLedgerId,
      fileId: this.contentFile[0].id,
      interbranch: true,
      accountGroups: NON_CONTINGENT_ACCOUNT_GROUP
    });

    if (!result || !result.output) {
      return;
    }

    this.transactionUnits = result.output.map((o: SimulateGlTransactionResult) => {
      return {
        fullCode: o.account?.fullCode,
        branchId: o.branchId,
        remarks: o.remarks,
        debitAmount: o.debitAmount,
        creditAmount: o.creditAmount
      };
    });
    await this.glTransactionService.displaySimulationErrors(result.output);
  }

  actionButtonsEnabled() : boolean {
    return !this.useCategories && this.inputMode === 'MANUAL';
  }
}

nxModule.component('glTransactionInterbranchCreate', {
  controller: GlTransactionInterbranchCreate,
  bindings: {
    'sourceLedgerId': '<',
    'sourceTransactionId': '<',
    'backdated': '<'
  },
  templateUrl
});
