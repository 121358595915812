import { NxLoader } from '@nextbank/ui-components';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import { useParams } from 'react-router';
import { ROLE_API } from './RoleService';
import RoleForm from './RoleForm';
import { ReactElement } from 'react';
import useAxios from 'axios-hooks';

const EditRole = ():ReactElement => {
  const params = useParams<{ roleId: string }>();
  const  [{ data: role, loading }] =  useAxios(`${ROLE_API}/${Number(params.roleId)}`);

  return (
    loading || !role?  <NxLoader/> :
    <NxPage>
       <NxHeader>{role.name}</NxHeader>
        <RoleForm role={role}/>
    </NxPage>
  )
}

export default EditRole
