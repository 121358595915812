import nxModule from 'nxModule';

import templateUrl from './checkbook-update.template.html';
import {IFormController, ILocationService} from 'angular';
import {Checkbook, CheckbookType} from 'check/CheckbookType';
import {Product} from '../../../../service/product.types';
import {HttpService} from "shared/utils/httpService";
import {CheckbookCache} from "components/service/checkbook-cache.types";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

class CheckbookUpdateComponent {
  public editForm!: IFormController;
  public checkbook?: Checkbook;
  public account?: Product;
  public firstCheckNumber?: string;
  public checkbookTypes: CheckbookType[];
  private customerId!: number;
  private accountId!: number;
  private checkbookId!: number;

  constructor(
    private $route: NxRouteService,
    private $location: ILocationService,
    private http: HttpService,
    private customerCache: CustomerCache,
    private checkbookCache: CheckbookCache,
    private productDefinitionService: ProductDefinitionService,
    private confirmation: Confirmation,
    private command: CommandService
  ) {
    this.checkbookTypes = Object.values(CheckbookType);
  }

  async $onInit(): Promise<void> {
    const [checkbooks, accounts, products] = await Promise.all([
      this.checkbookCache.withParam(this.accountId).toPromise(),
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.productDefinitionService.toPromise()
    ]);

    const checkbook = checkbooks.result.find((c: Checkbook) => c.id === Number(this.checkbookId));
    if(!checkbook) {
      throw new Error('Missing checkboook');
    }

    this.checkbook = checkbook;

    this.onStartNumberChange();

    const account = accounts.find(a => a.id === Number(this.accountId))!;
    const product = products.find(p => p.id === account.definitionId);
    this.account = Object.assign(account, {productName: product ? product.productName : '-'});
  }

  onStartNumberChange(): void {
    if(!this.checkbook) {
      throw new Error('No checkbook');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.checkbook.startNumber = this.padWithZeroes(this.checkbook.startNumber.toString());
  }

  padWithZeroes(startNumber: string): string {
    if (startNumber.length >= 10) {
      return startNumber.slice(startNumber.length - 10);
    } else {
      return '0'.repeat(10 - startNumber.length) + startNumber;
    }
  }

  async edit(): Promise<void> {
    if(!this.checkbook) {
      throw new Error('No checkbook');
    }

    const confirmed = await this.confirmation(`Do you want to update checkbook ${this.checkbook.number}?`);
    if (confirmed) {
      const response = await this.command.execute('UpdateCheckbook', this.checkbook, {nxLoaderText: 'Updating checkbook'}).toPromise();
      if (!response.approvalRequired) {
        this.customerCache.depositAccounts(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}`);
  }
}

nxModule.component('checkbookUpdate', {
  templateUrl: templateUrl,
  controller: CheckbookUpdateComponent,
  bindings: {
    customerId: '<',
    accountId: '<',
    checkbookId: '<'
  }
});
