import axios, {AxiosResponse} from "axios";
import React, {ReactElement, ReactNode, useContext} from "react";
import {CommandDescriptor, CommandOutputWrapper} from "./CommandTypes";
import notificationService from "tools/notificationService";
import {NxButton, NxButtonVariant, NxPopup, NxRow, NxRowPosition} from "@nextbank/ui-components";
import {HttpError, PageResult} from "tools/HttpTypes";
import {RouteComponentProps, withRouter} from "react-router";
import {v4 as uuidV4} from 'uuid';
import {NxIdempotencyKey} from "tools/HttpHeaders";
import {UserDetails} from "user/UserTypes";
import {TranslationMap} from "translation/TranslationTypes";
import moment from "moment";
import _ from "lodash";
import styles from './CommandService.scss'
import {PrintModalApi} from "print/PrintModal";

export interface CommandRequest<Input> {
  input: Input;
  name: string;
}

interface CommandRequestExecution<Input> {
  request: CommandRequest<Input>
  resolve: (value: unknown) => void;
  reject: (error: unknown) => void;
}

interface PendingCommand {
  pendingCommands: CommandDescriptor[];
  userDetails: UserDetails[];
  commandTranslations: TranslationMap;
  resolve: () => void;
  reject: () => void;
}

interface CommandProps {
  children: React.ReactNode | React.ReactNode[];
  printModalApi: PrintModalApi;
}

export interface ContextState {
  execute: <Input, Output>(request: CommandRequest<Input>) => Promise<CommandOutputWrapper<Output>>;
  executing: boolean;
}

type ExecutionState = 'IDLE' | 'EXECUTING_COMMAND' | 'SHOWING_POPUP_ERROR' | 'SHOWING_POPUP_APPROVAL' | 'SHOWING_POPUP_ALREADY_PENDING_COMMANDS';


interface CommandState {
  executionState: ExecutionState;
  error?: Error;
  commandQueue: CommandRequestExecution<unknown>[];
  context: ContextState,
  pendingCommandsQueue: PendingCommand[]
}

export const CommandContext = React.createContext<ContextState>({
  executing: false,
  execute: <Input, Output>(request: CommandRequest<Input>): Promise<CommandOutputWrapper<Output>> => {
    throw new Error(`Command provider not initialized when invoking ${request.name}`);
  }
});

class CommandComponent extends React.Component<CommandProps & RouteComponentProps, CommandState> {
  state: CommandState = {
    executionState: 'IDLE',
    commandQueue: [],
    pendingCommandsQueue: [],
    context: {
      executing: false,
      execute: <Input, Output>(request: CommandRequest<Input>): Promise<CommandOutputWrapper<Output>> => {
        return this.checkPendingCommands(request).then(() => this.execute(request))
      }
    }
  };
  printModalApi!: PrintModalApi;

  constructor(props: CommandProps & RouteComponentProps) {
      super(props);
      this.printModalApi = props.printModalApi;
  }

  private onIdle(): void {
    if (this.state.commandQueue.length === 0) {
      return;
    }

    this.setState({
      executionState: 'EXECUTING_COMMAND',
      context: {
        ...this.state.context,
        executing: true
      },
    }, () => this.onExecutingCommand());
  }

  private async onExecutingCommand(): Promise<void> {
    const {request, resolve} = this.state.commandQueue[0];
    console.log(`Executing command ${request.name}`);

    const idempotencyKey = uuidV4();
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const axiosResponse: AxiosResponse<CommandOutputWrapper<unknown>> = await axios.post<CommandOutputWrapper<unknown>>(`/command/${request.name}`, request.input, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ignoreError: true,
        headers: {
          [NxIdempotencyKey]: idempotencyKey
        }
      });

      const response = axiosResponse.data;

      notificationService({
        text: `Successfully executed: ${request.name}`
      });

      if (response.executionMode === 'ASYNC') {
        throw new Error('Async execution mode is not yet supported');
      }

      if (response.prints && response.prints.length > 0) {
          for (const print of response.prints) {
              await this.printModalApi.show({
                  printDescription: {
                      code: print.code,
                      parameters: print.parameters
                  },
                  printProviderInput: print.input
              });
          }
      }

      if (response.approvalRequired) {
        resolve(response);
        this.setState({
          executionState: 'SHOWING_POPUP_APPROVAL',
        });

        return;
      }

      resolve(response);
      const [, ...tail] = this.state.commandQueue;

      this.setState({
        commandQueue: tail,
        executionState: 'IDLE',
        context: {
          ...this.state.context,
          executing: false
        }
      }, () => this.onIdle());
    } catch (e) {
      console.warn(`Error running command ${request.name}`, JSON.stringify(request.input));

      this.setState({
        error: e,
        executionState: 'SHOWING_POPUP_ERROR',
      });

      return;
    }
  }

  async checkPendingCommands<Input>(request: CommandRequest<Input>): Promise<void> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (request.input.productId) {
        try {
          CommandComponent.getPendingCommands(request).then((commandsResponse: AxiosResponse<PageResult<CommandDescriptor>>) => {
            if (commandsResponse.data.totalCount > 0) {
              this.showAlreadyPendingCommandsPopup(commandsResponse, reject, resolve);
              return;
            }
            resolve();
          });
          return;
        } catch (e) {
          console.warn(`Error running command ${request.name}`, JSON.stringify(request.input));
          reject(e);
          return;
        }
      }
      resolve();
    });
  }

  private static getPendingCommands<Input>(request: CommandRequest<Input>): Promise<AxiosResponse<PageResult<CommandDescriptor>>> {
    return axios.post(
      `/command/search`,
      {
        page: {
          pageNo: 0,
          pageSize: 5
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        productId: request.input.productId,
        statuses: ['PENDING_OTP', 'PENDING_APPROVAL']
      },
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ignoreError: true
      });
  }

  private showAlreadyPendingCommandsPopup(commands: AxiosResponse<PageResult<CommandDescriptor>>,
                                          reject: () => void,
                                          resolve: () => void): void {
    const userIds = [...new Set(commands.data.result.map(r => r.executedBy))];
    Promise.all([CommandComponent.getUserDetails(userIds),CommandComponent.getCommandTranslations()]).then(
      ([usersResponse, translations]) => {
      this.setState(prev => ({
        ...prev,
        executionState: "SHOWING_POPUP_ALREADY_PENDING_COMMANDS",
        pendingCommandsQueue: [...prev.pendingCommandsQueue, {
          pendingCommands: commands.data.result,
          userDetails: usersResponse.data,
          commandTranslations: translations.data,
          reject: reject,
          resolve: resolve
        }]
      }));
    });
  }

  private static getUserDetails(userIds: number[]): Promise<AxiosResponse<UserDetails[]>> {
    return axios.post(
      `/management/users/details`,
      {
        userIds: userIds
      });
  }

  private static getCommandTranslations(): Promise<AxiosResponse<TranslationMap>> {
    return axios.get(
      '/translations',
      {
        params: {
          language: 'en',
          type: 'COMMAND'
        }
      });
  }

  async execute<Input, Output>(commandRequest: CommandRequest<Input>): Promise<CommandOutputWrapper<Output>> {
    return new Promise((resolve, reject) => {
      this.setState(prev => ({
        commandQueue: [...prev.commandQueue, {
          request: commandRequest,
          resolve,
          reject
        }]
      }), () => {
        if (this.state.executionState === 'IDLE') {
          this.onIdle();
        }
      });
    });
  }

  closePendingCommandsPopup(confirmed: boolean): void {
    const [head, ...tail] = this.state.pendingCommandsQueue;
    confirmed ? head.resolve() : head.reject();
    this.setState(prev => ({
      ...prev,
      pendingCommandsQueue: tail,
      executionState: 'IDLE',
    }));
  }

  closeErrorPopup(): void {
    const error = this.state.error;
    const [head, ...tail] = this.state.commandQueue;
    head.reject(error);

    this.closePopup(tail);
  }

  closeApprovalPopup(): void {
    const [, ...tail] = this.state.commandQueue;

    this.props.history.push('/dashboard/actions/sent-by-me');
    this.closePopup(tail);
  }

  private closePopup(tail: CommandRequestExecution<unknown>[]): void {
    this.setState({
      error: undefined,
      executionState: 'IDLE',
      commandQueue: tail,
    }, () => this.onIdle());
  }

  createErrorMessage(error: Error): ReactNode {
    const addPrefix = (text: string | null, prefix: string): string | null => {
      if (!text) {
        return null;
      }

      return `${prefix}: ${text}`;
    };

    const errorResponse = error instanceof HttpError ? error.backendError : null;

    if (errorResponse) {
      const codes = [
        addPrefix(errorResponse.commandId, 'c'),
        addPrefix(errorResponse.requestUuid ? errorResponse.requestUuid.substr(errorResponse.requestUuid.length - 8) : null, 'r')
      ];

      return <>
        <div>Details: {errorResponse.errorMessage ?? "An unknown error occurred"}</div>
        {codes.length > 0 && <div>Error code: {codes.filter(Boolean).join('/')} </div>}
      </>;
    } else {
      return <>
        <div>Name: {error.name}</div>
        <div>Details: {error.message}</div>
      </>;
    }
  }

  render(): ReactElement {
    return <CommandContext.Provider value={this.state.context}>
      {this.state.executionState === 'SHOWING_POPUP_ALREADY_PENDING_COMMANDS' &&
        <NxPopup header={this.createPendingCommandsDetailsHeader()}
                 open={true}
                 description={this.createPendingCommandsDetailsMessage()}>
          <NxRow position={NxRowPosition.END}>
            <NxButton variant={NxButtonVariant.CLOSE}
                      onClick={(): void => this.closePendingCommandsPopup(false)}>
              No
            </NxButton>
            <NxButton variant={NxButtonVariant.SAVE}
                      onClick={(): void => this.closePendingCommandsPopup(true)}>
              Yes
            </NxButton>
          </NxRow>
        </NxPopup>
      }
      {this.state.executionState === 'SHOWING_POPUP_ERROR' &&
        <NxPopup
          header={`Error executing ${this.state.commandQueue[0].request.name}`}
          onClose={(): void => this.closeErrorPopup()}
          description={this.state.error ? this.createErrorMessage(this.state.error) : ''}
          open={true}>
          <NxButton
            variant={NxButtonVariant.CLOSE}
            onClick={(): void => this.closeErrorPopup()}>
            Close
          </NxButton>
        </NxPopup>
      }
      {this.state.executionState === 'SHOWING_POPUP_APPROVAL' &&
        <NxPopup
          header='Approval required'
          onClose={(): void => this.closeApprovalPopup()}
          description={
            `Operation ${this.state.commandQueue[0].request.name} requires approval. Once the task is approved, the operation will be processed automatically`
          }
          open={true}>
          <NxButton
            variant={NxButtonVariant.CONTAINED}
            onClick={(): void => this.closeApprovalPopup()}>
            Ok
          </NxButton>
        </NxPopup>
      }
      {this.props.children}
    </CommandContext.Provider>;
  }

  private createPendingCommandsDetailsHeader(): React.ReactNode {
    const message = `There are already
      ${this.state.pendingCommandsQueue[0].pendingCommands.length} pending actions for this product.`;
    return <div>
      <div>
        {message}
      </div>
      <div>
        Do you want to continue?
      </div>
    </div>
  }

  private createPendingCommandsDetailsMessage(): React.ReactNode {
    const head = this.state.pendingCommandsQueue[0];
    return  head.pendingCommands.map(c => {
      const commandName = head.commandTranslations.COMMAND.find(t => t.code === c.simpleName) || _.startCase(c.simpleName);
      const effectiveName = head.userDetails.find(u => u.id === c.executedBy)?.effectiveName;
      const registrationTime = moment(c.registrationTime).format('MMM Do YYYY, h:mm:ss a');
      const message = `registered on ${registrationTime} by user ${effectiveName}`;
      return <div key={message}><div className={styles.textBold}>{commandName}: </div>{message}</div>
    });
  }
}

export const Component = withRouter(CommandComponent);

/**
 * Hook allowing to execute commands
 */
export const useCommand = (): (<Input, Output>(request: CommandRequest<Input>) => Promise<CommandOutputWrapper<Output>>) => {
  const {execute} = useContext(CommandContext);
  return execute;
};
