import nxModule from 'nxModule';
import _ from 'lodash';

import systemPropertyService from '../../../react/system/systemPropertyService';

const templateUrl = require('./documents.template.html');
nxModule.component('customerProfileDocuments', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '<',
    'module': '<',
    'idDocuments': '=',
    'editMode': '='
  },
  controller: function ($scope, dict, propertyConfigService, systemDateService) {
    let that = this;
    that.dict = dict;
    that.cfg = propertyConfigService;
    that.allowedDocumentTypes = [];

    // BDA customers does not require any document
    $scope.$watch('$ctrl.profile.customerSubtype', (newVal, oldVal) => {
      that.documentRequired = that.profile && that.profile.customerSubtype !== 'BDA';
    });

    const getAllowedDocumentTypes = customerType => {
      if (that.module === 'agent') {
        return dict.filterByAttribute('ID_DOCUMENT_TYPE', 'customerType', 'AGENT');
      }
      return customerType ? dict.filterByAttribute('ID_DOCUMENT_TYPE', 'customerType', customerType, 'ANY') : [];
    };

    $scope.$watch('$ctrl.profile.customerType', (newVal, oldVal) => {
      that.allowedDocumentTypes = getAllowedDocumentTypes(newVal);
    });

    $scope.$watch('$ctrl.idDocuments', () => {
      // Show expiration field if required
      _.forEach(that.idDocuments, async doc => {
        dict.onLoadingComplete(() => {
          doc.expirationSupported = isExpirationSupported(doc.documentTypeId);
          doc.documentNumberOptional = isDocumentNumberOptional(doc.documentTypeId);
          doc.selectedDocumentType = that.allowedDocumentTypes.find(d =>
            d.id === doc.documentTypeId && (d.code !== 'ID23' || doc.attributes.ID_TYPE === d.description)
          );

          // Set document photo file ids
          doc.photos = doc.photos || [];
          doc.photoFileIds = doc.photoFileIds || [];
          doc.photoFileIds.forEach((photoId) => {
            if (!doc.photos.some(photo => photo.id === photoId)) {
              doc.photos.push({id: photoId});
            }
          });
        });
      });
    });

    const minDocs = systemPropertyService.getProperty('CIF_MIN_DOCUMENT_SECTIONS');
    that.minDocsSections = minDocs ? Number(minDocs) : 0;

    that.documentNumberRules = {
      'DEFAULT': {minLength: 0, maxLength: 64},
      'GSIS': {docTypeId: null, stripDashes: true, maxLength: 11},
      'UMID': {docTypeId: null, stripDashes: false, minLength: 12}
    };

    dict.onLoadingComplete(() => {
      that.documentNumberRules['GSIS'].docTypeId = dict.getId('ID_DOCUMENT_TYPE', 'GSIS');
      that.documentNumberRules['UMID'].docTypeId = dict.getId('ID_DOCUMENT_TYPE', 'UMID');

      // Allowed statuses for new documents
      that.allowedStatusesForNew = dict.filterByAttribute('ID_DOCUMENT_STATUS', 'allowedForNewDocuments', true);
      const customerType = that.profile ? that.profile.customerType : null;
      that.allowedDocumentTypes = getAllowedDocumentTypes(customerType);
    });

    that.allDocumentNumberAreValid = () => (that.idDocuments ?? []).every(document => that.isDocumentNumberValid(document));

    that.isDocumentNumberValid = document => {
      let documentNumber = document.documentNumber ?? '';

      const rule = Object.values(that.documentNumberRules || [])
        .filter(r => r.docTypeId === document.documentTypeId)
        .map(r => ({...that.documentNumberRules['DEFAULT'], ...r}))[0];
      if (!rule) {
        return documentNumber.length <= that.documentNumberRules['DEFAULT'].maxLength;
      }

      documentNumber = rule.stripDashes ? documentNumber.replace(/-/g, '') : documentNumber
      return documentNumber.length >= (rule.minLength ?? 0)
        && documentNumber.length <= rule.maxLength;
    }

    that.getStatusDict = (document) => {
      return document && document.id ? dict['ID_DOCUMENT_STATUS'] : that.allowedStatusesForNew;
    };

    that.addDocument = async () => {
      let docs = that.idDocuments;
      // mark the first document as primary
      let primary = false;
      if (!docs) {
        docs = that.idDocuments = [];
      }
      if (docs.length === 0) {
        primary = true;
      }
      docs.push({
        dateSubmitted: await systemDateService.getCurrentUserBranchSystemDate(),
        countryId: that.readDefaultCountry(),
        primary: primary,
        photos: []
      });
    };

    that.removeDocument = function (doc) {
      const deletedPrimary = doc.primary;
      let array = that.idDocuments;
      let index = array.indexOf(doc);
      array.splice(index, 1);
      if (deletedPrimary && that.idDocuments.length !== 0) {
        that.idDocuments[0].primary = true;
      }
    };

    const isExpirationSupported = (docTypeId) => {
     return getAttributeValue(docTypeId, 'supportsExpiration');
    };

    const isDocumentNumberOptional = (docTypeId) => {
      return getAttributeValue(docTypeId, 'documentNumberOptional');
    };

    function getAttributeValue(docTypeId, attributeName) {
      const dict = that.dict['ID_DOCUMENT_TYPE'];
      if (dict && dict.entries) {
        const entry = _.find(dict, {id: docTypeId});
        if (entry && entry.attributes) {
          const value = entry.attributes[attributeName];
          return value && value.length && value[0];
        }
      }
      return false;
    }

    that.documentTypeChanged = function (document) {
      document.documentTypeId = document.selectedDocumentType.id;
      // if National ID is selected, assign its type into the document's attributes, else leave attributes empty
      document.attributes =
        (document.selectedDocumentType.id === that.allowedDocumentTypes.find(d => d.code === 'ID23').id)
          ? {ID_TYPE: document.selectedDocumentType.description} : {};
      document.expirationSupported = isExpirationSupported(document.documentTypeId);
      document.documentNumberOptional = isDocumentNumberOptional(document.documentTypeId);
      document.documentNumber = null;
      document.issuingAuthority = null;
      document.countryId = that.readDefaultCountry();
    };

    that.readDefaultCountry = () => {
      let defaultCountryCode = that.cfg.defaultValue('customer', 'document', 'issuing_country');
      if (defaultCountryCode) {
        let country = _.find(that.dict['COUNTRY'], {code: defaultCountryCode});
        return country && country.id ? country.id : null;
      }
      return null;
    };
  }
});
