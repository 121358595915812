import 'rxjs/add/operator/filter';

import nxModule from 'nxModule';

import templateUrl from './holiday-table.template.html';
import './holiday-table.style.less';

class HolidayTable {

  constructor(){
  }

  addHoliday() {
    this.onAdd();
  }

  removeHoliday(idx) {
    this.onDelete({idx})
  }

  cancelHolidayForm(index) {
    if (this.updateEnabled && this.holidays[index].update) {
      this.cancelEditholiday(index)
    } else {
      this.cancelAddHoliday();
    }
  }

  cancelAddHoliday() {
    this.onCancelAdd();
  }

  cancelEditholiday(index) {
    const form = this.getForm(index);
    this.onCancelEdit({form, index})
  }

  saveHoliday(index) {
    const form = this.getForm(index);
    this.onSave({form, index});
  }

  editHoliday(index) {
    this.holidays[index].update = true;
    this.onEdit({index})
  }

  getForm(index) {
    return this[`form_${index}`];
  }

  isSaveDisabled(index) {
    const form = this.getForm(index);
    this.saveDisabled({form});
  }
}


nxModule.component('holidayTable', {
  templateUrl,
  bindings: {
    holidays: '<',
    onAdd: '&',
    onDelete: '&',
    onSave: '&',
    onCancelAdd: '&',
    updateEnabled: '<',
    onEdit: '&',
    onCancelEdit: '&',
    saveDisabled: '&',
    form: '='
  },
  controller: HolidayTable
});