import nxModule from 'nxModule';
import statusService from "../../../../react/management/statusService";
import {StatusDetails} from "../../../../react/management/status/StatusType";
import {Observable, ReplaySubject} from "rxjs";

/**
 * DO NOT inject this class directly to components. Use classes that inherits StatusBasedService.
 *
 * This service is responsible for making periodical call to /management/status endpoint.
 * It contains bundle with vulnerable data that must be synchronized with frontend app.
 *
 * Status endpoint contains:
 * - workingDay - workingDay data of current branch (depending on authentication context)
 * - userCounter - user counter of current user (depending on authentication context)
 * - waitForCashTransfer
 * - informationMessage - message that should be shown on the top bar
 * - recentlyApprovedCommand - the user's commands that have been recently approved
 */

export class StatusService {
  subject = new ReplaySubject<StatusDetails>(1);

  constructor() {
    statusService.registerForStatusUpdate(statusUpdate => {
      this.subject.next(statusUpdate);
    });
  }

  async refresh() {
    return statusService.refresh();
  }

  /**
   * @returns Observable that contains status bundle
   */
  getStatusObs(): Observable<StatusDetails> {
    return this.subject;
  }
}

nxModule.service('statusService', StatusService);
