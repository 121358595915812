import nxModule from 'nxModule';
import {Note, NoteSearchCriteria} from "./note.types";
import {PageResult} from "tools/HttpTypes";

export class NoteHistoryService {

  constructor(private http: any) {
  }

  async getNotes(params: NoteSearchCriteria): Promise<PageResult<Note[]>> {
    return this.http.get('/products/notes', params).toPromise();
  }
}

nxModule.service('noteHistoryService', NoteHistoryService);