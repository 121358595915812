import nxModule from 'nxModule';
import {NgTableParams} from 'ng-table';
import templateUrl from './selectable-list.template.html';
import './selectable-list.style.less';
import {PageResult} from "tools/HttpTypes";

export interface PageSettings {
  pageNo: number;
  pageSize: number;
}

/**
 * Represents single row in the table.
 *
 * ID is required - the selection mechanism
 * is based on it.
 */
export interface Record {
  id: number;
}

/**
 * Class describing a column in the table:
 * - label - column description in the UI
 * - field - name of the Record's field
 *           where the data is
 * - align - optional parameter to specify
 *           row cell alignment
 */
export class Column<T extends Record> {
  constructor(public label: string,
              public field: keyof T,
              public align?: ColumnAlignment) {}
}

export enum ColumnAlignment {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center'
}

/**
 * API that can be used by the parent element
 * to trigger data reload.
 */
export class SelectableListAPI {
  constructor(private tableConfig: NgTableParams<Record>,
              private component: SelectableList) {}

  /**
   * Go to first page and
   * fetch data again.
   */
  reload(): void {
    this.tableConfig.page(1);
    this.tableConfig.reload();
  }

  /**
   * Reset selection and
   * reload data.
   */
  reset(): void {
    this.component.uncheckAllRecords();
    this.reload();
  }
}

class SelectableList {

  // Table configuration
  private currentPage: number = 0;
  private total: number = 0;
  private tableConfig!: NgTableParams<Record>;
  private tableAPI!: SelectableListAPI;
  private checkedRecords: Record[] = [];
  private recordsOnCurrentPage: Record[] = [];

  // BINDINGS
  columns!: Column<Record>[];
  // description of the column with checkboxes
  selectableColumnLabel!: string;
  recordsPerPage!: number;
  getRecordsPage!: (pageSettingsWrapped: { pageSettings: PageSettings }) => Promise<PageResult<Record>>;
  onChange!: ({ checkedRecords }: {checkedRecords: unknown}) => void;
  onApiReady!: (apiWrapped: { tableAPI: SelectableListAPI }) => void;

  $onInit() {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: this.recordsPerPage,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: (params: NgTableParams<Record>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.currentPage = params._params.page - 1;

        const pageSettings: PageSettings = {
          pageNo: this.currentPage,
          pageSize: this.recordsPerPage
        };
        return this.getRecordsPage({ pageSettings })
          .then(res => {
            params.total(res.totalCount);
            this.total = res.totalCount;
            this.recordsOnCurrentPage = res.result;

            return res.result;
          });
      }
    });

    // create API and inform parent about it
    this.tableAPI = new SelectableListAPI(this.tableConfig, this);
    this.onApiReady({ tableAPI: this.tableAPI });
  }

  isEveryRecordChecked(): boolean {
    return this.recordsOnCurrentPage.every(record => this.isRecordChecked(record));
  }

  isRecordChecked(record: Record): boolean {
    return this.checkedRecords.some(checkedRecord => checkedRecord.id === record.id);
  }

  toggleAllRecords(event: JQuery.ChangeEvent) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.checkAllRecords();
    } else {
      this.recordsOnCurrentPage.forEach(record => {
        this.removeSingleRecord(record);
      })
    }

    this.onChange({ checkedRecords: this.checkedRecords });
  }

  private checkAllRecords(): void {
    let uncheckedRecords = [];

    if (this.checkedRecords.length > 0) {
      uncheckedRecords = this.recordsOnCurrentPage
        .filter(recordOnPage => !this.checkedRecords.some(record => recordOnPage.id === record.id));
    } else {
      uncheckedRecords = this.recordsOnCurrentPage;
    }

    uncheckedRecords.forEach(record => {
      this.addSingleRecord(record);
    });
  }

  toggleSingleRecord(event: JQuery.ChangeEvent, record: Record) {
    const isChecked = event.target.checked;
    if (isChecked) {
      this.addSingleRecord(record);
    } else {
      this.removeSingleRecord(record);
    }
    this.onChange({ checkedRecords: this.checkedRecords });
  }

  private addSingleRecord(record: Record): void {
    this.checkedRecords.push(record);
  }

  private removeSingleRecord(record: Record): void {
    const recordIndex = this.checkedRecords.findIndex(checkedRecord => checkedRecord.id === record.id);
    this.checkedRecords.splice(recordIndex, 1);
  }

  uncheckAllRecords() {
    this.checkedRecords = [];
    this.onChange({ checkedRecords: this.checkedRecords });
  }
}

nxModule.component('selectableList', {
  bindings: {
    columns: '<',
    selectableColumnLabel: '@',
    recordsPerPage: '<',
    getRecordsPage: '&',
    onChange: '&',
    onApiReady: '&'
  },
  templateUrl,
  controller: SelectableList
});