import {isFieldRequired, useValidationSchema} from '@nextbank/ui-components';
import {useField, useFormikContext} from 'formik';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {NxTreeNode} from 'tree/NxTreeNode';
import SingleSelectTree, {SingleSelectTreeProps} from 'tree/singleSelectTree/SingleSelectTree';

export type NxFormikTreeContainerProps<VALUE> = Omit<SingleSelectTreeProps<VALUE>, 'onChange' | 'value' | 'onBlur' | 'children' | 'variant'> & {
  name: string;
};

export interface SingleSelectCategoryValue {
  id: number | undefined,
  label: string;
}

const NxFormikSingleSelectTree = (props: NxFormikTreeContainerProps<SingleSelectCategoryValue>) : ReactElement => {
  const context = useFormikContext();
  const validationSchema = useValidationSchema();
  const [field, meta] = useField(props);
  const [value, setValue] = useState<SingleSelectCategoryValue>();

  const {validateOnChange, setFieldValue, getFieldProps} = context;

  const getSelectedNode = (node: NxTreeNode<SingleSelectCategoryValue>) : NxTreeNode<SingleSelectCategoryValue> | undefined => {
    if (node.children.length === 0) {
      if (node.value.id === getFieldProps(props.name).value) {
        return node;
      }
    }
    for (const child of node.children) {
      const selectedNode = getSelectedNode(child);
      if (selectedNode) {
        return selectedNode;
      }
    }
  }

  const onChange = useCallback((value: SingleSelectCategoryValue | undefined): void => {
    setFieldValue(props.name, value?.id, validateOnChange);
    setValue(value)
  }, [validateOnChange, setFieldValue, props.name]);

  useEffect(() => {
    if (!props.nodes) {
      return;
    }

    for (const node of props.nodes) {
      const selectedNode = getSelectedNode(node);
      if (selectedNode) {
        setValue(selectedNode.value)
        onChange(selectedNode.value);
        return;
      } else {
        setValue(undefined);
        onChange(undefined);
      }
    }
  }, [props.nodes]);

  return <SingleSelectTree<SingleSelectCategoryValue>
    {...props}
    inputLabel={value?.label ?? ''}
    error={meta.error}
    onChange={onChange}
    selectedValue= {value}
    disabled={props.disabled || context.isSubmitting}
    required={props.required || isFieldRequired(validationSchema, field.name)}  />;
};

export default NxFormikSingleSelectTree;