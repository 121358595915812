import nxModule from 'nxModule';
import templateUrl from './deposit-memo-form.template.html';
import {Unit} from '../../../general-ledger/common/gl-category-input.component';
import {sum} from "../../../../shared/common/MathUtils";
import {ActionCategory} from "components/administration/transactions/action-category.types";

export type MemoMode = 'DEBIT' | 'CREDIT'
export type MemoType = 'REGULAR' | 'PLACEMENT'

interface Limits {
  debit: MinMaxAmount,
  credit: MinMaxAmount
}

interface MinMaxAmount {
  min: number,
  max: number
}

class DepositMemoForm {

  public category!: ActionCategory;
  public categoryUnits: Unit[] = [];
  public remarks!: string;
  public limits!: Limits;
  public memoMode!: MemoMode;
  public memoType!: MemoType;
  public actionType!: string;

  public validateAmount!: () => boolean;

  $onInit() {
    if (!this.validateAmount) {
      this.validateAmount = (): boolean => {
        return this.calculateAmount() > 0;
      }
    }

    if (this.memoMode === 'CREDIT') {
      this.actionType = this.memoType === 'REGULAR' ? 'CREDIT_DEPOSIT_BY_MEMO' : 'DEPOSIT_PLACEMENT_BY_MEMO'
    } else {
      this.actionType = 'DEBIT_DEPOSIT_BY_MEMO'
    }
  }

  calculateAmount() {
    return sum(this.categoryUnits.map(u => u.amount)).toFixed(2);
  }

  categoryChanged(): void {
    this.categoryUnits = [];
    if(this.category) {
      this.categoryUnits = this.category.ledgerAccountFullCodes.map(fullCode => ({fullCode, amount: 0}));
    }
  }
}

nxModule.component('depositMemoForm', {
  templateUrl: templateUrl,
  bindings: {
    memoMode: '<',
    memoType: '<',
    limits: '<',
    category: '=',
    categoryUnits: '=',
    remarks: '=',
    validateAmount: '<'
  },
  controller: DepositMemoForm
});

