import nxModule from 'nxModule';

import templateUrl from './atm-cash-withdrawal.template.html';
import {Transaction, TransactionUnit} from "../cash-in-out/cash-in-out.component";
import {MiscCommandService} from '../misc-command.service';

import {ActionCategory} from "components/administration/transactions/action-category.types";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {CommandService} from "shared/utils/command/command.types";
import {IScope} from "angular";
import {AtmTerminal, AtmTerminalBranchCache} from "components/atm/service/atm-terminal-branch.cache.types";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {ActionCategoryCache} from "components/service/action/action-category.cache.types";
import {BranchService} from "components/service/branch.service";
import {AssetCounter} from "components/technical/status/user-counter.service";

class AtmCashWithdrawal {
  private atmTerminal?: (AtmTerminal & {totalCash?: number});
  private atmTerminals: (AtmTerminal & {totalCash?: number})[] = [];
  private categories: ActionCategory[] = [];
  private category!: ActionCategory;
  private blockedUnits: TransactionUnit[] = [];
  private transaction: Transaction = {
    units: [],
    amount: 0,
    cashAmountAfter: 0,
    remarks: ''
  };

  constructor(
    private command: CommandService,
    private actionCommand: ActionCommand,
    private actionCategoryCache: ActionCategoryCache,
    private branchService: BranchService,
    private authentication: Authentication,
    private miscCommandService: MiscCommandService,
    private http: HttpService,
    private $scope: IScope,
    private atmTerminalBranchCache: AtmTerminalBranchCache
  ) {
  }

  async $onInit() {
    this.atmTerminals = await this.atmTerminalBranchCache.withParam(this.authentication.context.branchId).toPromise();

    // Add ATM total cash
    if (this.authentication.permissions['MNG_ATM_BALANCE_READ']) {
      const atmTerminalsCounters = await this.http.get<AssetCounter[]>('/management/atm-terminals/counters/', {
        nxLoaderSkip: true,
        params: {branchId: Number(this.authentication.context.branchId)}
      }).toPromise();

      this.atmTerminals.forEach(atm => {
        const counter = atmTerminalsCounters.find(c => c.domainId === atm.id)!;
        atm.totalCash = counter.totalCash;
      });
    }

    this.$scope.$watch('$ctrl.transaction.amount', () => {
      this.updateCashAmountAfter();
    });

    const allCategories = await this.actionCategoryCache.toPromise();

    this.categories = allCategories.filter(cat => cat.actionType === 'ATM_CASH_WITHDRAWAL');
    this.transaction.amount = 0;
  }

  updateUnits() {
    const currentUnits = this.transaction.units;
    const newAccounts = [];
    const ledgerAccountFullCodes = this.category.ledgerAccountFullCodes;
    for (const fullCode of (ledgerAccountFullCodes || [])) {
      const currentUnit: {amount: number} | undefined = currentUnits.find(unit => unit.accountCode === fullCode);
      newAccounts.push({
        accountCode: fullCode,
        amount: currentUnit ? currentUnit.amount : 0,
        entryType: 'DEBIT'
      });
    }
    this.transaction.amount = 0;
    this.blockedUnits = newAccounts;
  }

  updateCashAmountAfter() {
    this.transaction.cashAmountAfter = (this.atmTerminal?.totalCash ?? 0)- (this.transaction.amount ?? 0);
  }

  cancel() {
    this.actionCommand.cancelChanges();
  }

  async save() {
    const units = this.transaction.units
      .filter(unit => unit.accountCode)
      .map(unit => ({
        ...unit,
        fullCode: unit.accountCode
      }));

    this.miscCommandService.executeCommand('MiscAtmCashWithdrawal', {
      ...this.transaction,
      atmId: this.atmTerminal?.id,
      categoryUnits: {
        categoryId: this.category ? this.category.id : null,
        units
      }
    });
  }
}

nxModule.component('atmCashWithdrawal', {
  templateUrl,
  bindings: {},
  controller: AtmCashWithdrawal
});
