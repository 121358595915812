import nxModule from 'nxModule';
import _ from 'lodash';
import 'rxjs/add/operator/combineLatest';
import BigNumber from 'bignumber.js';

import templateUrl from './interests-transfer-to-product.template.html';
import {Account, Deposit} from "components/service/product.types";
import {AccountType} from "components/service/account.types";
import {IFormController, ILocationService} from "angular";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {Confirmation} from "shared/common/confirmation.types";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";
import {CommandService} from "shared/utils/command/command.types";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {CustomerDepositService} from 'components/service/customer-deposit.service.types';
import {CustomerCache} from "components/service/customer.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {DepositType, TermDepositService} from 'components/administration/term-deposit/common/term-deposit.types';
import {DepositInterestForecast} from "components/customer/term-deposits/common/term-deposit-override.service";
import Notification from "shared/utils/notification";

type CommandInput = {
  productId: number,
  targetProductId: number,
  customerId: number,
  entryType: string,
  amount: number,
  override: number,
}

class InterestTransferToProduct {

  private interestOverrideEnabled = false;
  private termDeposit!: Deposit;
  private termDepositProduct!: DepositType;

  private taxRecalculated = false;
  private depositAccounts : Account[] = [];

  private accountSelectConfig = {
    placeholder: 'Select account',
    searchField: ['label'],
    valueField: 'id',
    labelField: 'label',
    maxItems: 1
  };

  private depositAccountId?: number;
  protected withdrawalAmount: number = 0;

  private override = {
    interest: 0,
    netInterest: 0,
    withholdingTax: 0,
    amount: 0
  };

  private customerId: number;
  private depositId: number;

  private interestForecast?: DepositInterestForecast;
  private transactionForm!: IFormController;

  constructor(
    private $route: NxRouteService,
    private $location: ILocationService,
    private $filter: NxIFilterService,
    private http: HttpService,
    private customerCache: CustomerCache,
    private productDefinitionService: ProductDefinitionService,
    private confirmation: Confirmation,
    private notification: Notification,
    private command: CommandService,
    private modalPrintPreviewService: ModalPrintPreviewService,
    private customerDepositService: CustomerDepositService,
    private termDepositsService: TermDepositService,
    private depositAccountTypeService: DepositAccountTypeService
  ) {
    this.customerId = Number(this.$route.current.params['customerId']);
    this.depositId = Number(this.$route.current.params['depositId']);
  }

  async searchAccounts(accountTypes: AccountType[]): Promise<void> {
    this.customerDepositService.searchDeposits(
      // term deposit owner, co-owner, and related customers Ids
      [this.customerId],
      // valid deposit account statuses
      ['ACTIVE', 'INACTIVE', 'PENDING'],
      // if true -> fetch deposit accounts of related customers,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.termDepositProduct.interCustomerTransferAllowed,
      // search success callback
      (deposits : Account[]) =>  {
        this.depositAccounts = deposits.filter(dpt => {
          // All pending target account should allow InterestsTransferToProduct as initial deposit operation
          if (dpt.status === 'PENDING') {
            const type = accountTypes.find((type : AccountType) => type.id === dpt.typeId);
            return type?.initialDepositOperations?.includes('InterestsTransferToProduct');
          }
          return true;
        });
      }
    );
  }

  onDepositAccountChange(): void {
    this.depositAccountId = _.find(this.depositAccounts, (a) => Number(a.id) === Number(this.depositAccountId))!.id;
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.depositId}`);
  }

  async $onInit(): Promise<void> {
    const [deposits, products, termDeposits, forecast, accountTypes] = await Promise.all([
      this.customerCache.termDeposits(this.customerId).toPromise(),
      this.productDefinitionService.toPromise(),
      this.termDepositsService.toPromise(),
      this.http.post<DepositInterestForecast>(`/products/deposits/${this.depositId}/interest`, {mode: 'STANDARD'}).toPromise(),
      this.depositAccountTypeService.toPromise()
    ]);
    const deposit = _.find(deposits, {id: this.depositId})!;
    const product = _.find(products, {id: deposit.definitionId});
    const termDepositProduct = _.find(termDeposits, {id: deposit.typeId})!;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.termDeposit = {...deposit, productName: product ? product.productName : '<Unknown product>'};
    this.termDepositProduct = termDepositProduct;
    this.interestForecast = forecast;
    this.override.interest = forecast.interest;
    this.override.netInterest = forecast.netInterest;
    this.override.withholdingTax = forecast.withholdingTax;
    this.withdrawalAmount = this.calculateWithdrawalAmount();
    this.searchAccounts(accountTypes);
  }

  updateOverrides(): void {
    this.taxRecalculated = false;
  }

  recalculateTax = () => {
    this.http.get<number>(`/products/deposits/${this.depositId}/interest/calculate-withholding-tax?interest=${this.override.interest}`)
        .success((newTax) => {
          this.override.netInterest = new BigNumber(this.override.interest).minus(newTax).toNumber();
          this.override.withholdingTax = newTax;
          this.override.amount = this.calculateWithdrawalAmount();
          this.taxRecalculated = true;
        });
  };

  calculateWithdrawalAmount() : number {
    if(this.termDeposit.depositSubgroup === 'ADVANCE_INTEREST') {
      return BigNumber.max(0, new BigNumber(this.termDeposit.balance).minus(this.termDeposit.activationAmount))
        .decimalPlaces(2)
        .toNumber();
    }

    return new BigNumber(0)
      .plus(this.override.netInterest)
      .decimalPlaces(2).toNumber();
  };

  transferEnabled(): boolean {
    return !this.transactionForm.invalid && this.withdrawalAmount > 0;
  }

  async transfer(): Promise<void> {
    const commandInput: CommandInput = {
      productId: this.depositId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      targetProductId: this.depositAccountId,
      customerId: this.customerId,
      entryType: "DEBIT",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      amount: this.interestOverrideEnabled ? this.override.amount : this.withdrawalAmount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      override: this.interestOverrideEnabled ? this.override.interest : null,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const confirmed = await this.confirmation(`Do you want to transfer ${this.$filter('nxCurrency')(this.interestOverrideEnabled ? this.override.amount : this.withdrawalAmount)}?`);
    if (confirmed) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await this.command.execute('InterestsTransferToProduct', commandInput).toPromise();
      if(response && !response.approvalRequired) {
        this.customerCache.termDeposits(this.customerId).refetch();
        this.customerCache.depositAccounts(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }
}

nxModule.component('customerTermDepositInterestsTransferToProduct', {
  templateUrl,
  controller: InterestTransferToProduct
});
