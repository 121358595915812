import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import React, {ReactElement} from 'react';
import styles from './TreeExpandingToggle.scss';

const TreeExpandingToggle = (props: {node: TreeNode}): ReactElement => {
  if (props.node.hasChildren()) {
    return <a className={clsx('toggle', 'icon', props.node.expanded() ? 'icon-collapse' : 'icon-expand', styles.toggle)}
              onClick={(): TreeNode => {
                return props.node.toggleCollapse();
              }}/>;
  }
  return <></>;
};

export default TreeExpandingToggle;