import nxModule from 'nxModule';
import templateUrl from "./sss-contributions.template.html";
import {Confirmation} from "shared/common/confirmation.types";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";

class SSSContributions {
  private payorTypes : string[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private selectedPayorType : string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private contributions: number[];
  private newContribution: number | null;

  constructor(
    private http: HttpService,
    private command: CommandService,
    private confirmation: Confirmation
  ) {
    this.newContribution = null;
  }

  async $onInit() : Promise<void> {
    this.payorTypes = await this.http.get<string[]>('/sss/contributions/payor-types').toPromise();
  }

  addNewContribution() : void {
    if (this.newContribution) {
      this.contributions.push(this.newContribution);
      this.newContribution = null;
    }
  }

  deleteContribution(index: number) : void {
    if (index >= 0) {
      this.contributions.splice(index, 1);
    }
  }

  async updateContributionTable() : Promise<void> {
    const confirmed = await this.confirmation('Update SSS Contribution table?');
    if (confirmed) {
      await this.command.execute("UpdateSSSContribution", {
        payorType: this.selectedPayorType,
        amounts: this.contributions
      }).toPromise();
      await this.fetchContributions();
    }
  }

  async fetchContributions() : Promise<void> {
    const result = await this.http.get<{amounts: number[]}>(`/sss/contributions/${this.selectedPayorType}`).toPromise();
    this.contributions = result.amounts;
  }
}

nxModule.component('sssContributions', {
  templateUrl,
  controller: SSSContributions,
  bindings: {
    payorGroupId: '<'
  }
});
