import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {allRoutes} from 'custom-field/CustomFieldRoutes';
import LoansConfiguration from 'loan/LoansConfiguration';
import UidRebate from 'loan/rebate/UidRebate';
import Reprice from 'loan/reprice/Reprice';
import WriteOffLoan from 'loan/write-off/WriteOffLoan';
import RouteDefinition from 'routes/RouteDefinition';

const routes: RouteDefinition[] = [
  {
    name: 'Loans',
    path: '/customer/:customerId/loans'
  },
  {
      name: 'UID rebate',
      path: '/customer/:customerId/loans/:loanId/rebate',
      commandAccess: 'UidRebate',
      Component: UidRebate
  },
  {
    name: 'Write off',
    path: '/customer/:customerId/loans/:loanId/write-off',
    Component: WriteOffLoan
  },
  {
    name: 'Reprice',
    path: '/customer/:customerId/loans/:loanId/reprice',
    commandAccess: 'RepriceLoan',
    Component: Reprice
  },
  {
    name: 'Loans configuration',
    path: '/admin/loan-products',
    Component: LoansConfiguration
  },
  ...allRoutes('/admin/loan-products', CustomFieldGroup.LOAN, false)
];

export default routes;
