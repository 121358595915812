import _ from 'lodash';
import nxModule from 'nxModule';
import {defaultHeaderLabels, flattenSubAccounts, mergedLedger} from '../common/gl.utils';
import {updateRefreshTime} from 'components/common/refresh-button/refresh-button.component';

import templateUrl from './gl-account-details.template.html';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

class GlAccountDetails {

  constructor($scope, $rootScope, $routeParams, $location, glLedgerService, confirmation, command, glMappingsService,
              branchService, glAccountService, breadcrumbs, notification, commandAccessChecker, http) {
    this.$scope = $scope;
    this.$routeParams = $routeParams;
    this.$location = $location;
    this.glLedgerService = glLedgerService;
    this.branchService = branchService;
    this.confirmation = confirmation;
    this.command = command;
    this.glMappingsService = glMappingsService;
    this.glAccountService = glAccountService;
    this.breadcrumbs = breadcrumbs;
    this.notification = notification;
    this.commandAccessChecker = commandAccessChecker;
    this.http = http;

    this.ledgerId = parseInt($routeParams.accountId);
    this.header = [];
    this.parentFullCode = "";
    this.displayAfterOptions = [];
    this.input = {};
    this.modal = false;
    this.editingEnabled = false;
    this.createAccountMode = false;
    this.editAccountMode = false;
    this.hasEditAccess = false;
    this.hasCreateAccess = false;

    this.onModalApiReady = ({api,}) => {
      this.modalApi = api;
    };
  }

  async $onInit() {
    const canApproveCommandChecker = this.commandAccessChecker.canApproveCommandAsync();
    canApproveCommandChecker.then(
      checker => {
        this.hasCreateAccess = checker('CreateLedgerAccountTemplate');
        this.hasEditAccess = checker('EditLedgerAccountTemplate');
      }
    );

    const ledger = await mergedLedger({
      glLedgerService: this.glLedgerService,
      branchService: this.branchService,
      accountId: this.ledgerId
    });

    setBreadcrumbs(this.breadcrumbs, 'gl-account-details-label', ledger.template.name);

    this.header = defaultHeaderLabels(ledger);
  }

  toggleEditing() {
    this.editingEnabled = !this.editingEnabled;
  }

  isNotLeafOrAtLeastHasNoTransactions(account) {
    return !account.leaf || !account.hasTransactions;
  }

  async refetchAccounts() {
    const leafsWithoutTransactions = await this.http.get('/ledger/templates/primary/accounts?hasTransactions=false&leafOnly=true').toPromise();
    const leafsWithoutTransactionsByFullCode = _.groupBy(leafsWithoutTransactions, acc => acc.fullCode);

    try {
      const data = await this.glAccountService.fetchAccountsAsync(this.ledgerId);
      this.accounts = flattenSubAccounts(data)
        .map(account => Object.assign(account, {
          hasSubAccounts: !!account.subAccounts,
          hasTransactions: !leafsWithoutTransactionsByFullCode[account.fullCode]?.[0],
          collapsed: !!account.subAccounts,
          visible: account.accountDepth === 1,
          accountGroupShort: account.accountGroup.substring(0, 2)
        }));
      this.notification.show("Accounts loaded successfully");
      updateRefreshTime(this.$scope);
    } catch(error) {
      if (error) {
        this.notification.show("Error", "An error occurred during loading of accounts.");
      }
    }
  }

  async modalAction() {
    const modalResponse = await this.modalApi.show();
    if (modalResponse.accepted) {
      let message;
      if (this.editAccountMode) {
        message = `<b>Change will affect this ledger account in all branches.</b><br/>
                   Apply changes to account ${this.editingAccount.fullCode}? <br/><br/>
                   Name change: ${this.editingAccount.name} -> ${this.input.name}`;
      } else {
        const newFullCode = this.input.parentFullCode + "/" + this.input.code;
        message = `<span class="red"><b>Warning! This action cannot be undone.</span><br/>
                   Accounts will be created in ledgers of all branches.</b><br/><br/>
                   Confirm creation of new Ledger Account ${newFullCode} with name ${this.input.name}
                   right after ${this.input.displayAfter}?`;
      }
      const confirm = await this.confirmation(message, true);
      if (confirm) {
        if (this.createAccountMode) {
          await this.command.execute('CreateLedgerAccountTemplate', {
            parentFullCode: this.input.parentFullCode,
            code: this.input.code,
            name: this.input.name,
            displayAfter: this.input.displayAfter,
            reversedEntryType: this.input.isReversed
          }).toPromise();
        } else if (this.editAccountMode) {
          await this.command.execute('EditLedgerAccountTemplate', {
            templateId: this.editingAccount.templateId,
            name: this.input.name,
            reversedEntryType: this.input.isReversed
          }).toPromise();
        }
        this.glMappingsService.accounts.refetch();
        this.glMappingsService.allAccounts.refetch();
        await this.refetchAccounts();
      }
    }
    this.exitEdit();
  }

  async createLeafAccount(a, e) {
    e.stopPropagation();
    this.createAccountMode = true;
    this.editAccountMode = false;
    this.input = {};
    this.input.parentFullCode = a.fullCode;
    this.displayAfterOptions = [a.fullCode, ...(a.subAccounts ?? []).map(sa => sa.fullCode)];
    this.modalAction();
  }

  async edit(a, e) {
    e.stopPropagation();
    this.editAccountMode = true;
    this.createAccountMode = false;
    this.input.code = a.fullCode;
    this.input.name = a.name;
    this.input.isReversed = a.reversedEntryType;
    this.editingAccount = a;
    this.modalAction();
  }

  exitEdit() {
    this.input = {};
    this.editingAccount = {};
    this.createAccountMode = false;
    this.editAccountMode = false;
  }

  collapseSubtree(account) {
    account.collapsed = true;
    (account.subAccounts || []).forEach(a => {
      a.visible = false;
      this.collapseSubtree(a);
    });
  }

  toggleCollapsed(account) {
    if (!account.collapsed) {
      this.collapseSubtree(account);
    } else {
      account.collapsed = false;
      (account.subAccounts || []).forEach(a => a.visible = true);
    }
  };
}

nxModule.component('glAccountDetails', {
  templateUrl,
  controller: GlAccountDetails
});
