import nxModule from 'nxModule';
import moment from 'moment';
import './group-loans-batch.style.less';

const templateUrl = require('./group-loans-batch.template.html');
nxModule.component('groupLoansBatch', {
  templateUrl: templateUrl,
  bindings: {
    batches: '<',
    batch: '<',
    batchChangeAction: '<',
  },
  controller: function ($route, $location, $scope, $element, http, breadcrumbs, groupCustomerCache, notification, confirmationTemplate,
                        productDefinitionService, userCache, glMappingsService, reportModal, loanProductsCache, loanService, command,
                        systemDateService, operationService, popup) {
    let that = this;
    let customerId = $route.current.params['customerId'];

    this.$onChanges = (changes) => {
      if (!changes.batch || !that.batch) return;

      systemDateService.getSystemDateByBranchId(that.batch.loans[0].branchId)
        .then(date => that.branchSystemDate = date);
    };

    that.selectBatch = (batch) => {
      // NOTE: we don't update the url with the chosen batchId because it would cause a full page reload :(
      this.batchChangeAction({
        batch,
      });
    };

    that.disbursmentForm = () => {
      const params = {groupLoanId: that.batch.id};
      let reportCode = "group-loan-disbursment";
      reportModal.display({
        params: params,
        reportCode: reportCode
      });
    };

    that.disbursmentWithSignatures = () => {
      $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/disbursment`);
    };

    that.assignLoanBatchToGroup = () => {
      $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/assign`);
    };

    that.paymentReport = () => {
      let params = {groupLoanId: that.batch.id};
      let reportName = 'LoanPaymentsPerLoanReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };

    that.getUndeletableLoans = async () => {
      const unrevertedPaymentOperations = await operationService.fetchOperationsByCriteria({
          params: {
            productIds: that.batch.loans.map(p => p.id),
            status: ['PENDING', 'PROCESSED'],
            subgroups: ['LOAN_PAYMENT'],
            excludeGroup: ['REVERT_OPERATION']
          }
        }
      );

      return that.batch.loans
        .filter(l => unrevertedPaymentOperations.result.some(o => o.target.id === l.id) || l.lateCheckAwaitingAmount !== 0);
    }

    that.deleteBatch = async () => {
      const undeletableLoans = await that.getUndeletableLoans();
      const deletableLoans = that.batch.loans.filter(l => undeletableLoans.every(ul => ul.id !== l.id));

      if (_.isEmpty(deletableLoans)) {
        popup({
          header: 'Error',
          text: 'Cannot delete selected group loan. All of the loans have existing payment transactions. Please revert payment transactions in order to delete them.',
          renderHtml: true
        });
        return;
      }

      const confirmed = await confirmationTemplate({
        question: `Do you want to delete the selected group loan?`,
        details: that.getOldGroupDetails(deletableLoans, undeletableLoans),
        warning: _.isEmpty(undeletableLoans) ? '' :
          'Loans with existing payment transactions can\'t be deleted. Please revert payment transactions in order to delete them.' +
          '<br>This action is irreversible. Do you want to proceed?',
      });

      if (!confirmed) {
        return;
      }

      const response = await command.execute('DeleteGroupLoan', {
        id: that.batch.id,
        skipLoanIds: undeletableLoans.map(l => l.id)
      }).toPromise();

      if (!response?.approvalRequired) {
        groupCustomerCache.evict();
        $route.reload();
      }
    };

    that.getOldGroupDetails = (deletableLoans, undeletableLoans) => {
      const details = [{
        label: 'Will be deleted',
        description: deletableLoans.map(l => l.productNumber).join(', ')
      }];

      if (!_.isEmpty(undeletableLoans)) {
        details.push({
          label: 'Cannot be deleted',
          description: undeletableLoans.map(l => l.productNumber).join(', ')
        });
      }
      return details;
    }

    that.releaseGroupByCC = () => {
      if (that.isGrantDateValid()) {
        $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/release/check`);
      } else {
        that.showGrantDateErrorNotification();
      }
    };

    that.releaseGroupByCash = () => {
      if (that.isGrantDateValid()) {
        $location.path(`/customer/${customerId}/group-loans/${that.batch.id}/release/cash`);
      } else {
        that.showGrantDateErrorNotification();
      }
    };

    that.isGrantDateValid = () => {
      return moment(that.branchSystemDate)
        .isSameOrAfter(that.batch.grantDate);
    };

    that.showGrantDateErrorNotification = () => {
      notification.show("Error", "Unable to perform release because date granted is in the future.");
    };

    that.$postLink = () => {
      $element.on('show.bs.collapse', () => {
        $element.find('.collapse.in').collapse('hide');
      });
    }

    that.atLeastOneUnreleasedLoan = (loans) => {
      if (loans) {
        return loans.some(l => !l.releaseDate);
      }
      return false;
    }
  }
});
