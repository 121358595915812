import nxModule from 'nxModule';

import ParameterizedLocalCache from "../../../shared/utils/parameterized-local-cache";

nxModule.factory('agentsDevicesCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (agentId) => http.get(`/agents/management/${agentId}/devices`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.agents,
    cacheName: 'agentsDevicesCache'
  })
);
