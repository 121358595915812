import {AxiosError} from 'axios';
import useAxios, {UseAxiosResult} from 'axios-hooks';
import {Area} from 'components/administration/areas/area.types';
import {useUserBranches} from 'management/BranchService';
import {Branch} from 'management/BranchTypes';
import React, {ReactElement, useRef} from 'react';
import MultiselectTree, {TreeNodeViewProps} from 'tree/multiselectTree/MultiselectTree';
import {NxTreeNode} from 'tree/NxTreeNode';
import {TreeApi} from 'tree/TreeApi';

interface Props {
  name: string;
  label: string;
  error?: string;
  onChange: (branchIds: number[]) => unknown;
  onBlur: () => unknown;
  value: number[];
  disabled?: boolean;
  required?: boolean;
}

interface Value {
  id?: number;
  name: string;
  type: 'branch' | 'area'
}

type BranchAreaNode = NxTreeNode<Value>;

const useAreas = (): UseAxiosResult<Area[]> => {
  return useAxios<Area[]>('/management/areas');
};

const gatherBranchesInAreas = (areas: Area[], branchesInAreas: Set<number>): void => {
  for(const area of areas) {
    for(const branchId of (area.branchIds ?? [])) {
      branchesInAreas.add(branchId);
    }

    gatherBranchesInAreas(area.children ?? [], branchesInAreas);
  }
};

const gatherAreasWithNoBranches = (areas: Area[], userBranches: Map<number, Branch>, areasWithNoBranches: Set<number>): void => {
  for(const area of areas) {
    if(area.children) {
      gatherAreasWithNoBranches(area.children, userBranches, areasWithNoBranches);
    }

    const hasUserBranch = (area.branchIds ?? []).some(branchId => userBranches.get(branchId));
    if(hasUserBranch) {
      continue;
    }

    const allChildrenWithNoBranches = (area.children ?? [])
      .every(child => areasWithNoBranches.has(child.id));
    if(allChildrenWithNoBranches) {
      areasWithNoBranches.add(area.id);
    }
  }
};

const createAreaTree = (areas: Area[], userBranch: Map<number, Branch>,
                        areasWithNoBranches: Set<number>,
                        branchValueFactory: (id: number, name: string) => Value): BranchAreaNode[] => {
  return areas
    .filter(area => !areasWithNoBranches.has(area.id))
    .map(area => ({
      value: {
        name: area.name,
        type: 'area'
      },
      children: [
        ...createAreaTree(area.children ?? [], userBranch, areasWithNoBranches, branchValueFactory),
        ...(area.branchIds ?? [])
          .map(branchId => userBranch.get(branchId))
          .filter((b: Branch | undefined): b is Branch => !!b)
          .map((branch: Branch): BranchAreaNode => ({
            value: branchValueFactory(branch.id, branch.name),
            children: []
          }))
      ]
    }));
};

const createTree = (areas: Area[], userBranches: Branch[]): {
  tree: BranchAreaNode;
  branchIds: Set<number>;
} => {
  const branchesInAreas = new Set<number>();
  const areasWithNoBranches = new Set<number>();
  const userBranchMap = new Map(userBranches.map(b => [b.id, b]));
  const branchValues = new Map<number, Value>();
  gatherBranchesInAreas(areas, branchesInAreas);
  gatherAreasWithNoBranches(areas, userBranchMap, areasWithNoBranches);

  const branchesWithoutAreas = userBranches
    .filter(branch => !branchesInAreas.has(branch.id));

  const getOrCreateBranch = (id: number, name: string): Value => {
    const existingNode = branchValues.get(id);
    if(existingNode) {
      return existingNode;
    }

    const value: Value = {
      id: id,
      name: name,
      type: 'branch'
    };

    branchValues.set(id, value);
    return value;
  };

  const branchNodes: BranchAreaNode[] = branchesWithoutAreas.map((b: Branch): BranchAreaNode => ({
    value: getOrCreateBranch(b.id, b.name),
    children: []
  }));

  const tree = [
    ...createAreaTree(areas, userBranchMap, areasWithNoBranches, getOrCreateBranch),
    ...branchNodes
  ];

  const finalTree: BranchAreaNode = tree.length > 1 ? {
    value: {
      name: 'All branches',
      type: 'area'
    },
    children: tree
  } : tree[0];

  return {
    tree: finalTree,
    branchIds: new Set([...userBranchMap.keys()]),
  };
};

const useBranchTree = (): {loading: boolean, data?: {
    tree: BranchAreaNode;
    branchIds: Set<number>;
  }, error?: AxiosError} => {

  const [{
    loading, error, data: branches
  }] = useUserBranches();

  const [{
    loading: loadingAreas, error: areasError, data: areas
  }] = useAreas();

  if (loading || loadingAreas || !branches || !areas) {
    return {
      loading: true,
    };
  }

  if(error) {
    console.error('Failed to fetch branches', error);
    return {
      loading: false,
      error: error
    };
  }

  if(areasError) {
    console.error('Failed to fetch areas', areasError);
    return {
      loading: false,
      error: areasError
    };
  }

  return {
    loading: false,
    data: createTree(areas, branches)
  };
};

const TreeNodeView = (props: TreeNodeViewProps<Value>): ReactElement =>
  <div>{props.value.name}</div>;

const findValue = (tree: BranchAreaNode[], id: number): Value | undefined => {
  for(const node of tree) {
    if(node.value.id === id) {
      return node.value;
    }

    const childValue = findValue(node.children, id);
    if(childValue) {
      return childValue;
    }
  }

  return undefined;
};

const NxBranchMultiselect = (props: Props): React.ReactElement | null => {
  const {loading, data, error} = useBranchTree();
  const treeApi = useRef<TreeApi | null>(null);

  if(error) {
    throw error;
  }

  if(loading || !data) {
    return null;
  }

  const values = props.value.flatMap(branchId => {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
    return findValue([data.tree], branchId)!;
  });

  const inputLabel = values.length === 0 ? 'No branches selected'
    : values.length === data.branchIds.size ? 'All branches'
    : `Selected ${values.length} branches`;

  return <MultiselectTree<Value>
        api={treeApi}
        required={props.required}
        error={props.error}
        disabled={props.disabled}
        value={values}
        nodes={data.tree}
        onBlur={props.onBlur}
        inputLabel={inputLabel}
        onChange={(values: Value[]): void => {
          const ids = values
            .filter((value: Value): value is Value & {id: number} => !!value.id)
            .filter(value => data.branchIds.has(value.id))
            .map(value => value.id);

          props.onChange(ids);
        }}
        name={props.name}
        label={props.label}
        TreeNodeView={TreeNodeView}
      />;
};

export default NxBranchMultiselect;
