import nxModule from 'nxModule';

const templateUrl = require('./validator.template.html');
nxModule.component('validator', {
  templateUrl,
  bindings: {
    condition: '<'
  },
  controller: function () {
  }
});
