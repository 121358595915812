import {
  AutomaticDebitAgreementHierarchy, AutomaticDebitSchedule,
  automaticDebitScheduleOptions,
  AutomaticDebitStrategy, automaticDebitStrategyOptions
} from 'account/ada/AutomaticDebitAgreementTypes';
import {IScope} from 'angular';
import {TermDepositService} from 'components/administration/term-deposit/common/term-deposit.types';
import {ProductDefinitionService} from 'components/service/product-definition.service';
import {ProductDefinition} from 'components/service/product.types';
import nxModule from 'nxModule';
import {AccountType} from '../../../service/account.types';

import templateUrl from './deposit-account-ada-configuration.template.html';

class DepositAccountAdaConfiguration {
  protected accountType!: AccountType;
  protected allowAutoCreation!: boolean;
  protected productDefinitions!: ProductDefinition[];
  protected automaticDebitScheduleOptions: {label: string, value: AutomaticDebitSchedule}[];
  protected automaticDebitStrategyOptions : {label: string, value: AutomaticDebitStrategy}[];

  constructor(
    private productDefinitionService: ProductDefinitionService,
    private termDepositsService: TermDepositService,
    private $scope: IScope
  ) {
    this.automaticDebitScheduleOptions = automaticDebitScheduleOptions;
    this.automaticDebitStrategyOptions = automaticDebitStrategyOptions;
  }

  async $onInit(): Promise<void> {
    this.productDefinitions = await this.fetchLoanAndTdProducts();
    this.allowAutoCreation = false;

    // $ctrl.accountType is undefined at the first load
    this.$scope.$watch('$ctrl.accountType', () => {
      this.allowAutoCreation = !!this.accountType?.automaticDebitAgreementConfig;
    });
  }

  async fetchLoanAndTdProducts(): Promise<ProductDefinition[]> {
    const [depositTypes, productDefinitions] = await Promise.all([
      this.termDepositsService.toPromise(),
      this.productDefinitionService.toPromise(),
    ]);

    const pledgeDepositTypesDefinitionIds = depositTypes
      .filter(dt => dt.depositSubgroup === 'PLEDGE')
      .map(dt => dt.productDefinition.id)

    return productDefinitions
      .filter((pd: ProductDefinition) => {
        if (pd.productGroup === 'LOAN') {
          return true;
        }

        return pd.productGroup === 'DEPOSIT' && pledgeDepositTypesDefinitionIds.includes(pd.id);
      })
      .sort((pd1: ProductDefinition, pd2: ProductDefinition) => pd1.productName.localeCompare(pd2.productName))
      .sort((pd1: ProductDefinition, pd2: ProductDefinition) => pd1.productGroup.localeCompare(pd2.productGroup))
  }

  onEnabledChange() : void {
    if (!this.accountType.automaticDebitAgreementEnabled) {
      this.allowAutoCreation = false;
      this.accountType.automaticDebitAgreementConfig = null;
    }
  }

  onAutoCreationChange(): void {
    if (!this.allowAutoCreation) {
      this.accountType.automaticDebitAgreementConfig = null;
    } else {
      this.accountType.automaticDebitAgreementConfig = {
        hierarchies: []
      };
      this.addHierarchy();
    }
  }

  addHierarchy() : void {
    if (!this.accountType.automaticDebitAgreementConfig) {
      return;
    }

    this.accountType.automaticDebitAgreementConfig.hierarchies.push({
      orderNo: this.accountType.automaticDebitAgreementConfig.hierarchies.length + 1,
      keepMaintainingBalance: false,
      executeOnAccountCredit: false
    });
  }

  moveUpHierarchy(index: number): void {
    if (!this.accountType.automaticDebitAgreementConfig) {
      return;
    }

    this.swapHierarchy(index, index - 1, this.accountType.automaticDebitAgreementConfig.hierarchies);
  }

  moveDownHierarchy(index: number): void {
    if (!this.accountType.automaticDebitAgreementConfig) {
      return;
    }

    this.swapHierarchy(index, index + 1, this.accountType.automaticDebitAgreementConfig.hierarchies);

    return;
  }

  swapHierarchy(fromIndex: number, toIndex: number, array: AutomaticDebitAgreementHierarchy[]): void {
    const fromHierarchy = array[fromIndex];
    array[fromIndex] = array[toIndex]
    array[toIndex] = fromHierarchy;
  }

  removeHierarchy(index: number): void {
    if (!this.accountType.automaticDebitAgreementConfig) {
      return;
    }

    this.accountType.automaticDebitAgreementConfig.hierarchies.splice(index, 1);
  }
}

nxModule.component('depositAccountAdaConfiguration', {
  templateUrl,
  controller: DepositAccountAdaConfiguration,
  bindings: {
    accountType: '<',
    form: '='
  }
});
