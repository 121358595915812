import nxModule from 'nxModule';

import './product-status-predicate.style.less';

import templateUrl from './product-status-predicate.template.html';
import {productStatus} from 'constants/product';

class ProductStatusPredicate {
  constructor(accessRuleService) {
    this.accessRuleService = accessRuleService
    this.productStatuses = productStatus;
  }

  $onInit() {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    this.value = this.predicates[this.type][this.inputDefinition];
  }

  valueChanged() {
    this.predicates[this.type][this.inputDefinition] = this.value;
  };

  inputDefinitionChanged() {
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }
}

nxModule.component('productStatusPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    ngDisabled:'<',
    ruleDefinitions: '<',
    accessInput: '<',
    type: '<'
  },
  controller: ProductStatusPredicate
});
