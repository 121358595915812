import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('availableTasksCache', (http, CacheFactory, config, authentication) =>
  new LocalCache({
    provider: () => http.get(`/tasks/counter?userId=${authentication.context.id}&displayMode=ASSIGNED&status=PENDING`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheStorage: 'memory',
    cacheName: 'availableTasks'
  }));
