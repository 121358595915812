import nxModule from 'nxModule';

import templateUrl from './role-write-access-list.template.html';
import './role-write-access-list.style.less';
import {CommandGroup, NON_TECHNICAL_COMMAND_GROUPS} from "../../../service/access/access-rule.types";
import {Role} from "user/UserTypes";
import {ILocationService} from "angular";

class RoleWriteAccessList {

  // bindings
  private role!: Role;

  private readonly commandGroups: CommandGroup[] = NON_TECHNICAL_COMMAND_GROUPS;

  constructor(private $location: ILocationService) {
  }


  openGroup(commandGroup: CommandGroup): void {
    this.$location.path(`/admin/security/roles/${this.role.id}/access-rules/${commandGroup}`);
  }
}


nxModule.component('roleWriteAccessList', {
  templateUrl,
  bindings: {
    role: '<'
  },
  controller: RoleWriteAccessList
});