import {IController, ILocationService} from "angular";
import {Loan} from "../../../service/loan.types";
import nxModule from 'nxModule';
import './loan-ropa-transfer.style.less';
import BigNumber from "bignumber.js";

import templateUrl from './loan-ropa-transfer.template.html';
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {CommandService} from "shared/utils/command/command.types";

export interface RopaInput {
  productId: number;
  landAmount: number;
  buildingAmount: number;
  othersAmount: number;
}

class LoanRopaTransfer implements IController {

  // bindings
  private loan!: Loan;
  private customerId!: number;

  private request!: RopaInput;
  private totalRopaAmount: number = 0;

  // injected
  constructor(private readonly command: CommandService,
              private readonly $location: ILocationService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly customerCache: CustomerCache,
              private readonly $filter: NxIFilterService) {
  }

  $onInit() {
    this.request = {
      productId: this.loan.id,
      landAmount: 0,
      buildingAmount: 0,
      othersAmount: 0
    };
  }

  onRopaAmountChanged(): void {
    const landAmount: BigNumber = this.bn(this.request.landAmount);
    const buildingAmount: BigNumber = this.bn(this.request.buildingAmount);
    const othersAmount: BigNumber = this.bn(this.request.othersAmount);

    this.totalRopaAmount = landAmount.plus(buildingAmount).plus(othersAmount).toNumber();
  }

  isTransferValid(): boolean {
    return this.totalRopaAmount === this.loan.principalBalance;
  }

  private bn(amount: number): BigNumber {
    return !amount ? new BigNumber(0) : new BigNumber(amount);
  }

  private redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  cancel(): void {
    this.redirectBack();
  }

  async transferToRopa(): Promise<void> {
    const details: {label: string, description: string}[] = [
      {label: 'Product number', description: this.loan.productNumber},
      {label: 'Land amount', description: this.$filter('nxCurrency')(this.request.landAmount)},
      {label: 'Building amount', description: this.$filter('nxCurrency')(this.request.buildingAmount)},
      {label: 'Others amount', description: this.$filter('nxCurrency')(this.request.othersAmount)},
    ];

    const proceed = await this.confirmationTemplate({
      question: 'This loan will be transferred to ROPA. Do you wish to proceed?',
      details: details
    });

    if (!proceed) return;

    await this.command.execute('TransferLoanToRopa', this.request, {nxLoaderText: 'Transferring loan to ROPA...'}).toPromise();
    this.customerCache.loans(this.customerId).refetch();
    this.redirectBack();
  }

}

nxModule.component('loanRopaTransfer', {
  templateUrl,
  controller: LoanRopaTransfer,
  bindings: {
    loan: '<',
    customerId: '<'
  }
});