import nxModule from 'nxModule';

nxModule.factory('originHeaderInterceptor',
  () => ({
    request: (config) => {
      config.headers['NX-ORIGIN'] = 'CBS';
      return config;
    }
  })
);
