import nxModule from 'nxModule';
import templateUrl from "./agent-batch-upload.template.html";
import {AccountProductsCache, AccountType} from "../../service/account.types";
import systemPropertyService from '../../../../react/system/systemPropertyService';
import {Branch} from 'management/BranchTypes';
import {BranchService} from "components/service/branch.service";
import Authentication from "shared/utils/authentication";
import {CommandService} from "shared/utils/command/command.types";


interface AgentRegistrationOutput {
  agentId : number;
  registered : boolean;
  username : string;
  password : string;
  rejectionReason : string | null;
}

class AgentBatchUpload {
  private fileId : number | undefined;
  private branchId : number;
  private accountId : number | undefined;
  private sendPasswordUsingSms : boolean = false;
  private appendSequence : boolean = false;
  private usernamePrefix : string | undefined;
  private smsSupported = false;

  private branches : Branch[] = [];
  private accountTypes : AccountType[] = [];

  constructor(
    private branchService : BranchService,
    private authentication : Authentication,
    private accountProductsCache : AccountProductsCache,
    private command : CommandService,
  ) {
    this.branchId = this.authentication.context.branchId;
  }

  async $onInit() : Promise<void> {
    const [branches, accountTypes] = await Promise.all([
      this.branchService.toPromise(),
      this.accountProductsCache.toPromise()
    ]);

    this.branches = branches;
    this.accountTypes = accountTypes;

    const smsSupport = systemPropertyService.getProperty('SMS_SUPPORT');
    this.smsSupported = smsSupport?.toLowerCase() === 'true';

    this.initialize();
  }

  initialize() : void {
    this.fileId = undefined;
    this.branchId = this.authentication.context.branchId;
    this.accountId = undefined;
    this.sendPasswordUsingSms = false;
    this.appendSequence = false;
    this.usernamePrefix = undefined;
  }

  downloadFile(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  downloadSampleFile(): void {
    this.downloadFile(
      new Blob([
          'shortName,fullName,group,username,email,mobileNo,placeOfIncorporation,sourceOfFunds\n'
          + 'juan,Juan Dela Cruz,group,agent-juan,example@null.ph,+639270000000,NCR/Makati,Retail'
        ], {type: 'text/csv'}
      ),
      'agent-batch-upload-example.csv'
    );
  }

  downloadOutput(registrations: AgentRegistrationOutput[]): void {
    const headers = ['agentId', 'registered', 'username', 'password', 'rejectionReason'].join(',');
    const registrationsCsv = registrations
      .map(r => this.stringifyAsCsv(r))
      .join('\n');
    this.downloadFile(
      new Blob(
        [headers + '\n' + registrationsCsv],
        {type: 'text/csv'}
      ),
      'agent-registrations.csv'
    );
  }

  stringifyAsCsv(registration: AgentRegistrationOutput) : string {
    return [
      registration.agentId ?? '',
      registration.registered,
      registration.username,
      registration.password ?? '',
      this.sanitize(registration.rejectionReason ?? '')
    ].join(',');
  }

  sanitize(input : string) : string {
    return input.replace(/(\r\n|\n|\r|,)/gm, '');
  }

  filterByBranchAvailability(types: AccountType[]) : AccountType[] {
    return types.filter(t => {
      switch (t.productDefinition.productAvailability) {
        case 'AVAILABLE_IN_ALL_BRANCHES':
          return true;
        case 'NOT_AVAILABLE':
          return false;
        case 'AVAILABLE_IN_SELECTED_BRANCHES':
          return t.productDefinition.availableInBranchIds.includes(this.branchId);
      }
    });
  }

  async submit() : Promise<void> {
    const input = {
      'fileUploadId': this.fileId,
      'branchId': this.branchId,
      'accountTypeId': this.accountId,
      'sendPasswordUsingSms': this.sendPasswordUsingSms,
      'appendSequence': this.appendSequence,
      'usernamePrefix': this.appendSequence ? this.usernamePrefix : null
    };
    const response = await this.command.execute<unknown, {registrations: AgentRegistrationOutput[]}>('AgentBatchFileUploadRegistration', input).toPromise();

    if (!response.approvalRequired) {
      this.downloadOutput(response.output.registrations);
      this.initialize();
    }
  }
}

nxModule.component('agentBatchUpload', {
  templateUrl: templateUrl,
  controller: AgentBatchUpload
});
