import nxModule from 'nxModule';

import templateUrl from './official-receipt-booklet-branches.template.html';

class OfficialReceiptBookletBranches {
}

nxModule.component('officialReceiptBookletBranches', {
  templateUrl,
  controller: OfficialReceiptBookletBranches
});
