export type CsvSeparator = {
  label: string,
  code: string,
  value: string
};

export const csvSeparators = Object.freeze([
  {
    label: 'Comma',
    code: 'COMMA',
    value: ','
  },
  {
    label: 'Semicolon',
    code: 'SEMICOLON',
    value: ';'
  }
]);
