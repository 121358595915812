import nxModule from 'nxModule';
import _ from 'lodash';
import {IComponentController} from 'angular'
import {NgTableParams} from 'ng-table';
import {NxIFilterService} from '../../technical/angular-filters';

import templateUrl from './action-commands.template.html';
import {UserCache} from "../../service/users/user.cache";
import {ModalPrintPreviewService} from "../../service/print/modal-print-preview.service";
import {PageResult} from "tools/HttpTypes";
import {CommandDescriptor, CommandPrintOutput} from "command/CommandTypes";
import {HttpService} from 'shared/utils/httpService';
import Authentication from 'shared/utils/authentication';
import {PrintsCache} from 'components/service/prints.cache.types';
import {CommandPrintService} from 'shared/utils/command/command-print.service';

interface ExtendedCommandDescriptor extends CommandDescriptor {
  approvedByUser: string;
  rejectedByUser: string;
  displayName: string;
}

class ActionCommands implements IComponentController {

  private tableConfig!: NgTableParams<unknown>;

  constructor(private http: HttpService,
              private authentication: Authentication,
              private userCache: UserCache,
              private printsCache: PrintsCache,
              private commandPrintService: CommandPrintService,
              private $filter: NxIFilterService,
              private modalPrintPreviewService: ModalPrintPreviewService) {
  }

  async $onInit(): Promise<void> {
    // Call service for default filter config
    this.tableConfig = new NgTableParams({
      count: 50
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: () => this.fetchData()
    });
  }

  private async fetchData(): Promise<ExtendedCommandDescriptor[]> {
    const userId = this.authentication.context.id;
    const [output, users] = await Promise.all([
      this.http.get<PageResult<CommandDescriptor>>(`/command/user/${userId}?includePrints=true`).toPromise(),
      this.userCache.withParam().toPromise(),
      this.printsCache.toPromise()
    ]);

    if (!output || !output.result) return [];

    const promises = output.result.map(async r => {
      const approvedByUser = _.find(users, u => u.id == r.approvedBy);
      const rejectedByUser = _.find(users, u => u.id == r.rejectedBy);
      let displayName = this.$filter('translateEnum')(r.simpleName, 'COMMAND');
      displayName = displayName ? displayName : this.$filter('startCase')(r.type);
      return {
        approvedByUser: (approvedByUser ? approvedByUser.effectiveName : '-'),
        rejectedByUser: (rejectedByUser ? rejectedByUser.effectiveName : '-'),
        printCodes: _.map(r.prints, p => p.code),
        displayName,
        ...r,
        prints: await this.filterNonReprintablePrints(r.prints)
      }
    });
    return Promise.all(promises);
  }

  private async filterNonReprintablePrints(prints: CommandPrintOutput[]) {
    if (prints != null) {
      const reprintablePrints: CommandPrintOutput[] = [];
      for (const print of prints) {
        const canReprint = await this.modalPrintPreviewService.canReprintAsync(print.code);
        if (canReprint) {
          reprintablePrints.push(print)
        }
      }
      return reprintablePrints;
    }
    return prints;
  }

  displayPrints(command: CommandDescriptor) {
    this.commandPrintService.displayPrints(command);
  }

  public getRuleAttributeValue(ruleAttributes: any, field: string): any {
    if (!ruleAttributes) return undefined;

    if (typeof ruleAttributes[field] === 'object') {
      return ruleAttributes[field][field];
    }
    return ruleAttributes[field];
  }
}

nxModule.component('actionCommands', {
  controller: ActionCommands,
  templateUrl,
});