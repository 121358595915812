import nxModule from 'nxModule';

import templateUrl from './batch-deped-transaction-list.template.html';
import {ActionType, Columns} from "../../common/dynamic-list/dynamic-list.component";
import {BatchTypes} from "components/batch/common/batch.types";

class BatchDepEdTransactionList implements angular.IComponentController {
  private readonly columns: Columns = [
    { title: 'No'},
    { title: 'Transaction name', field: 'transactionName' },
    { actions: [{
        label: 'Open',
        field: 'href',
        type: ActionType.LINK
      }],
    },
  ];

  private transactions: BatchTypes[] = [];

  constructor(private commandAccessChecker: any) {
  }

  async $onInit(): Promise<void> {
    const canExecute = await this.commandAccessChecker.canExecuteCommandAsync();

    this.transactions = [{
      transactionName: 'Upload payments',
      command: 'PayDepEdLoanFileBatch',
      href: '#!/batch/deped-loans/payments'
    }, {
      transactionName: 'Billing generation',
      href: '#!/batch/deped-loans/generate-billing'
    }].filter(transaction => !transaction.command || canExecute(transaction.command));
  }

}

nxModule.component('batchDepEdTransactionList', {
  controller: BatchDepEdTransactionList,
  templateUrl,
});
