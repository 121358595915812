import {NxRouteService} from "routes/NxRouteService";
import angular from "angular-route";

export const batchRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/batch/atm', {
    template: '<atm-batch-acquirers/>',
    label: 'ATM Batch acquirers'
  }], ['/batch/memo', {
    template: '<batch-memo/>',
    label: 'Batch memo'
  }], ['/batch/sss-pension', {
    template: '<batch-sss-pension/>',
    label: 'SSS pension upload'
  }], ['/batch/sss-pension/files/:fileId', {
    template: '<batch-sss-pension-summary pensions="$resolve.pensions" file-id="$resolve.fileId"/>',
    label: 'SSS pension summary',
    resolve: {
        pensions:  /* @ngInject */ ($route: NxRouteService, sssPensionService: unknown): Promise<unknown> => {
          const fileId = $route.current.params['fileId'];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return sssPensionService.getPensionsByFileId(fileId);
        },
        fileId: /* @ngInject */ ($route: NxRouteService): unknown => $route.current.params['fileId']
    }
  }], ['/batch/loans', {
    template: '<batch-loan-transaction-list/>',
    label: 'Loans'
  }], ['/batch/loans/payments', {
    template: '<batch-loan-payments/>',
    label: 'Loan batch payments'
  }], ['/batch/loans/amortization-movement', {
    template: '<batch-loan-amortization-movement/>',
    label: 'Loan batch amortization movement'
  }], ['/batch/deped-loans/generate-billing', {
    template: `<report-details
                   report-code="'LoanDepEdBillingReport'">
                 </report-details>`,
    label: 'Generate billing'
  }], ['/batch/deped-loans', {
    template: '<batch-dep-ed-transaction-list/>',
    label: 'DepEd loans'
  }], ['/batch/deped-loans/payments', {
    template: '<batch-dep-ed-payment/>',
    label: 'Batch DepEd payment'
  }], ['/batch/deped-loans/payments/files/:fileId', {
    template: '<batch-dep-ed-details file-id="$resolve.fileId"/>',
    label: 'DepEd payment details',
    resolve: {
      fileId: /* @ngInject */ ($route: NxRouteService): unknown => {
        return $route.current.params['fileId'];
      }
    }
  }], ['/batch/remittance', {
    template: '<batch-remittance/>',
    label: 'Remittance'
  }]
];
