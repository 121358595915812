import nxModule from 'nxModule';

import './prints-list.style.less';
import templateUrl from './prints-list.template.html';

class PrintList {
  isParametrized(print) {
    const printParameters = this.availablePrintParameters[print.code];
    const parameters = _.get(printParameters, 'parameters', {});
    return !_.isEmpty(parameters);
  }
}

nxModule.component('printsList', {
  templateUrl,
  bindings: {
    nonParametrizedPrints: '<',
    availablePrintParameters: '<'
  },
  controller: PrintList
});
