import nxModule from 'nxModule';
import {AttachedFile} from '../../service/file.service';
import {Loan} from '../../service/loan.types';
import {PageRequest, PageResult} from "tools/HttpTypes";

interface LoanBatchFile {
  id: number;
  fileId: number;
  officialReceipt?: string;
  startingReceiptNumber?: string;
  remarks?: string;
  paymentMethod: string;
  uploadedOn: string;
  uploadedBy: number;
}

export interface LoanBatchFileOutput {
  loanBatchFile: LoanBatchFile;
  file: AttachedFile;
  rowCount: number;
  errorsCount: number;
}

interface LoanBatchFilePayment {
  id:              number;
  lineNo:          number;
  batchFileId:     number;
  loanId:          number;
  officialReceipt: string;
  amount:          number;
}

export interface LoanBatchFilePaymentsOutput {
  payment: LoanBatchFilePayment;
  loan: Loan;
  customerName: string;
  principal?: number;
  interest?: number;
  penalty?: number;
  pastDueInterest?: number;
}

export class BatchLoanPaymentService {
  constructor(private http: any) { }

  readLoanBatchFilesOutput(pageRequest: PageRequest): Promise<PageResult<LoanBatchFileOutput>> {
    return this.http.get(`/products/loans/batch-files?pageNo=${pageRequest.pageNo}&pageSize=${pageRequest.pageSize}`).toPromise();
  }
}

nxModule.service('batchLoanPaymentService', BatchLoanPaymentService);