import nxModule from 'nxModule';
import templateUrl from './loan-accrual-history.template.html';
import {NgTableParams} from "ng-table";
import {Loan} from "../../../service/loan.types";
import _ from 'lodash';
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {PageResult} from "tools/HttpTypes";

class LoanAccrualHistory {
  private tableConfig!: NgTableParams<unknown>;
  private loan!: Loan;

  constructor(private $filter: NxIFilterService,
              private http: HttpService) { }

  async $onInit() {
    if (this.loan) {
      this.initConfig();
    }
  }

  initConfig() {

    this.tableConfig = new NgTableParams({
      count: 15,
    }, {
      // Values in 'counts' would allow users to change number of entries per page, but button style doesn't match page
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<unknown>) => {
        const response = await this.http.get<PageResult<unknown>>(`/products/loans/accrual-history`, {
          params: {
            productId: this.loan.id,
            pageNo: params.page() - 1,
            pageSize: params.count()
          }
        }).toPromise();
        params.total(response.totalCount);
        return response.result;
      }
    });
  }

  formatAmount(type: string, amount: string): string{
    if (type === 'REVERSE_INTEREST_ACCRUAL' && amount !== '-')  {
      return '(' + amount + ')';
    }
    return amount;
  }

  formatWithDefault(value: string, defaultString: string): string {
    if (_.isEmpty(value) && value === '-') {
      return defaultString
    }
    return value;
  }

}


nxModule.component('loanAccrualHistory', {
  templateUrl,
  bindings: {
    loan: '<'
  },
  transclude: true,
  controller: LoanAccrualHistory
});
