import {Prompt} from "react-router";
import {ReactElement, useEffect, useRef} from "react";
import {v4 as uuid} from 'uuid';
import nxPromptStore from './nxProptStore';

export interface NxPromptProps {
  message: string;
  when?: boolean;
}

/**
 * Replacement for a default Prompt that mitigates incompatibilities between angularjs and react router
 */
const NxPrompt = (props: NxPromptProps): ReactElement => {
  const idRef = useRef(uuid());
  const initialMessage = useRef(nxPromptStore.getMessage());
  const id = idRef.current;
  useEffect(() => {
    const shouldBlock = props.when || props.when === undefined;
    if(shouldBlock) {
      nxPromptStore.block(id);
    } else {
      nxPromptStore.release(id);
    }

    return (): void => {
      nxPromptStore.release(id);
    };
  }, [props.when, id]);

  useEffect(() => {
    nxPromptStore.setMessage(props.message);

    // restore messages like a stack
    const previousMessage = initialMessage.current;
    return (): void => {
      nxPromptStore.setMessage(previousMessage);
    };
  }, [props.message]);

  return <Prompt {...props} />;
};

export default NxPrompt;