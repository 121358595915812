import nxModule from 'nxModule';
import {HttpService} from "shared/utils/httpService";
import {Passbook} from "deposit/DepositType";
import {ErrorResponse} from "tools/HttpTypes";

const ignoreNotFoundErrors = (err: ErrorResponse) => {
  if (err && err.errorCode === 'NOT_FOUND_BY_PK') {
    // happens when item wasn't created or id is invalid
    return;
  }

  return Promise.reject(err);
};


export default class PassbookService {
  constructor(private readonly http: HttpService) {  }

  readByProductId(productId: number): Promise<Passbook> {
    return this.http.get<Passbook>(`/products/${productId}/passbook`)
      .error(ignoreNotFoundErrors)
      .toPromise();
  }
}

nxModule.service('passbookService', PassbookService);