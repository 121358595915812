import {TreeNode} from 'inspire-tree';
import {ReactElement, useState} from 'react';
import SelectSubtreeList from 'tree/SelectSubtreeList';
import styles from 'tree/singleSelectTree/SingleSelectSubtreeView.scss';
import {TreeNodeViewComponentFactory} from 'tree/SubtreeView';
import TreeNodeItem from 'tree/TreeNodeItem';
import TreeNodeItemWrapper from 'tree/TreeNodeItemWrapper';

export type SelectSubtreeVariant = 'MULTI_SELECT' | 'SINGLE_SELECT';

export interface SelectSubtreeViewProps<VALUE> {
  mapping: Map<string, VALUE>;
  treeNode: TreeNode;
  disabled: boolean;
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
  variant: SelectSubtreeVariant;
  onClick: (treeNode: TreeNode) => void;
}


export interface SelectSubtreeTreeListProps<VALUE> {
  treeNodes: TreeNode[];
  mapping: Map<string, VALUE>;
  reverseMapping?: Map<VALUE, string[]>,
  disabled: boolean;
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
  variant: SelectSubtreeVariant;
  onClick: (treeNode: TreeNode) => void;
}

const Component = function<VALUE>({mapping, treeNode, disabled, TreeNodeView, variant, onClick}: SelectSubtreeViewProps<VALUE> & {stateIdentity: string}): ReactElement {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = (): void => {
    setIsHovering(true);
  };

  const handleMouseOut = (): void => {
    setIsHovering(false);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const id = treeNode.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const value = mapping.get(id)!;

  if (treeNode.available()) {
    return <TreeNodeItemWrapper treeNode={treeNode} selectable={!disabled} editable={false}>
      <TreeNodeItem className={isHovering && variant === 'SINGLE_SELECT' ? styles.item : ''} onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut} treeNode={treeNode} checkbox={variant === 'MULTI_SELECT'} disabled={disabled}>
        <a onClick={(): void => {
          if(disabled) {
            return;
          }
          onClick(treeNode);
        }}>
          <TreeNodeView value={value} />
        </a>
      </TreeNodeItem>
      {
        (treeNode.expanded() && treeNode.hasChildren())  ?
          <SelectSubtreeList treeNodes={treeNode.getChildren()}
                             mapping={mapping}
                             disabled={disabled}
                             TreeNodeView={TreeNodeView}
                             variant={variant}
                             onClick={onClick}/> : null
      }
    </TreeNodeItemWrapper>;
  }

  return <></>;
};

const SelectSubtreeView = function<VALUE>(props: SelectSubtreeViewProps<VALUE>): ReactElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const itreeNode = props.treeNode.itree;
  if(itreeNode.dirtyCount === undefined) {
    itreeNode.dirtyCount = 0;
  }

  if(itreeNode.dirty) {
    itreeNode.dirtyCount += 1;
    itreeNode.dirty = false;
  }

  const key = {
    ...itreeNode.state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    id: props.treeNode.id,
    dirtyCount: itreeNode.dirtyCount,
    parent: itreeNode.parent?.id
  };

  // form fields highlight when we expand select box
  // in order to ensure this behaviour, I cannot remove elements
  // when state changes, but need to update them in place
  // in order to force rendering whenever state changes, I'm keeping
  // a dummy, immutable prop that can be used for state comparison
  // and reuse the same dom element
  return <Component {...props} stateIdentity={JSON.stringify(key)}/>
};

export default SelectSubtreeView;