import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';
import {WorkingDay} from "../../../react/management/WorkingDayType";
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from "config";

nxModule.factory('workingDaysCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get('/management/working-days', {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.workingDays,
    cacheName: 'workingDays'
  })
);

export type WorkingDaysCache = LocalCache<any, WorkingDay[]>;

nxModule.factory('branchWorkingDayCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new ParameterizedLocalCache({
    provider: (branchId: number) => http.get(`/management/working-days?branchId=${branchId}`, {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.branchWorkingDay,
    cacheName: 'branchWorkingDay'
  })
);

export type BranchWorkingDayCache = ParameterizedLocalCache<any, WorkingDay>;