import nxModule from 'nxModule';

const templateUrl = require('./cashier-cash-overage.template.html');
nxModule.component('cashierCashOverage', {
  templateUrl: templateUrl,
  controller: function(actionCommand, miscCommandService) {
    this.transaction = {};

    this.denominationIsValid = () => {
      return this.transaction.denominationValid || this.transaction.denominationValid == null;
    };

    this.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    this.save = async () => {
      await miscCommandService.executeCommand('MiscCashierCashOverage', this.transaction).toPromise();
    }
  }
});
