import axios from 'axios';
import _ from 'lodash';

export interface SystemProperty {
  id:    number;
  code:  string;
  value: string;
}

class SystemPropertyService {
  private properties: SystemProperty[] = [];

  public async init(): Promise<void> {
    console.log('Loading system properties');
    this.properties = (await axios.get('/system/properties')).data;
    console.log('System properties loaded');
  }

  public getProperty(code: string): string | null {
    const descriptor = this.getPropertyDescriptor(code);
    if(!descriptor) {
      return null;
    }

    return descriptor.value;
  }

  public getPropertyOrError(code: string): string {
    const descriptor = this.getPropertyDescriptor(code);
    if (descriptor === null) {
      throw new Error(`Cannot find system property: ${code}`);
    }
    return descriptor.value;
  }

  public getPropertyDescriptor(code: string): SystemProperty | null {
    const property: SystemProperty | undefined = this.properties.find(prop => prop.code === code);
    if(!property) {
      return null;
    }

    return property;
  }

  public getPropertyDescriptors(): SystemProperty[] {
    return _.cloneDeep(this.properties);
  }
}

export default new SystemPropertyService();
export const serviceType = SystemPropertyService;