export const productAvailability = Object.freeze({
  allBranches: {
    label: 'Available in all branches',
    value: 'AVAILABLE_IN_ALL_BRANCHES'
  },
  selectedBranches: {
    label: 'Available in selected branches',
    value: 'AVAILABLE_IN_SELECTED_BRANCHES'
  },
  disabled: {
    label: 'Not available',
    value: 'NOT_AVAILABLE'
  }
});