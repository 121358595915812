import nxModule from 'nxModule';

import templateUrl from './batch-deped-history-list.template.html';
import {ILocationService} from "angular";

class BatchDepEdHistoryList {
  constructor(private $location: ILocationService) {}

  seeDetails(result: {id: number}): void {
    this.$location.path(`/batch/deped-loans/payments/files/${result.id}`);
  }
}

nxModule.component('batchDepEdHistoryList', {
  templateUrl,
  bindings: {
    history: '<'
  },
  controller: BatchDepEdHistoryList
});
