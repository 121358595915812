import nxModule from 'nxModule';
import './information-bar.less';

const templateUrl = require('./information-bar.template.html');

nxModule.component('informationBar', {
  templateUrl: templateUrl,
  controller: function (informationMessageService) {
    const that = this;

    informationMessageService.toObservable()
      .subscribe(informationMessage => that.message = informationMessage || null);
  }
});