import React, {ReactElement, useEffect, useState} from "react";
import {
  NxButtonVariant,
  NxTableActionProps
} from "@nextbank/ui-components";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import CommandAccess from "command/CommandAccess";
import {useCommand} from "command/CommandService";
import PrintModal, {PrintModalApi, ShowOutput} from "print/PrintModal";
import NxLoadingButton from "NxLoadingButton";
import CheckPreparationList, {CheckPreparationDetails} from "check/check-preparation/CheckPreparationList";
import ReportService from "report/ReportService";

interface MarkChecksForOutgoingOutput {
  outgoingBatchId: number;
}

const reportService = new ReportService();
const reports : string[] = ['Check Status Report Form', 'Bank Report', 'Check Voucher'];

const AssignedChecksList = (props: {printModalApi: PrintModalApi}): ReactElement => {
  const execute = useCommand();
  const [printableReports, setPrintableReports] = useState<string[]>([]);

  useEffect(() => {
    async function retrieveAvailableReports() : Promise<void> {
      const availableReports : string[] = await reportService.readAvailableFileReports();
      setPrintableReports(availableReports);
    }
    retrieveAvailableReports();
  }, []);

  const printReports = async (output: MarkChecksForOutgoingOutput) : Promise<void> => {
    for (const reportName of printableReports.filter(pr => reports.some(r => r === pr))) {
      await reportService.downloadFileReport({reportName : reportName, parameters: { batchId: output.outgoingBatchId }});
    }
  };

  const PrintCheck = ({data: checkPreparation}: NxTableActionProps<CheckPreparationDetails>): ReactElement => {
    return <NxLoadingButton
      variant={NxButtonVariant.CONTAINED}
      onClick={(): Promise<ShowOutput> => {
        return props.printModalApi.show({
          printDescription: 'LOAN_CHECK_PRINT',
          printProviderInput: {
            loanId: checkPreparation.id
          }
        });
      }}
    >
      Print Check
    </NxLoadingButton>;
  };

  const CancelCheck = ({data: checkPreparation, api}: NxTableActionProps<CheckPreparationDetails>): ReactElement => {
    return <CommandAccess commandName="CancelAssignedCheck">
      <NxLoadingButton
        variant={NxButtonVariant.DELETE}
        onClick={async (): Promise<void> => {
          const response = await execute({
            name: 'CancelAssignedCheck',
            input: {
              checkId: checkPreparation.check.id
            }
          });

          if(!response.approvalRequired) {
            api.onDataUpdated();
          }
        }}
      >
        Cancel Check
      </NxLoadingButton>
    </CommandAccess>;
  };

  return <NxPage>
    <NxHeader>
      Assigned checks
    </NxHeader>
    <CheckPreparationList
      tableActions={[PrintCheck, CancelCheck]}
      checkPreparationStatus={'ASSIGNED'}
      checkTransition={{
        command: 'MarkChecksForOutgoing',
        actionLabel: 'Outgoing',
        confirmationMessage: 'Are you sure that you want to mark these checks and loans for outgoing?',
        onCommandExecuted: printReports
      }}
      cancelRedirectUrl={'/check-preparation/assigned-checks'}
    />
  </NxPage>;
};

const AssignedChecksListContainer = (): ReactElement =>
  <PrintModal>
    {(modalApi: PrintModalApi): ReactElement =>
      <AssignedChecksList printModalApi={modalApi}/>
    }
  </PrintModal>;

export default AssignedChecksListContainer;