import nxModule from "nxModule";
import templateUrl from "components/batch/loans/batch-loan-amortization-movement.template.html";
import {PaymentIntervalDefinition} from "components/administration/loan/intervals/payment-interval.types";
import {HttpService} from "shared/utils/httpService";
import {LoanMoratoriumParameters} from "components/batch/loans/loan-moratorium.types";
import ReportService from "components/report/report.service";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class BatchLoanAmortizationMovement {
  private readonly movementTypeOptions = [
    {
      label: 'Move amortization that is due on the selected date. Maturity date will be retained',
      value: 'SINGLE_AMORTIZATION',
    },
    {
      label: 'Move amortization(s) that is/are due on the selected date to the next due date. Maturity date will be retained.',
      value: 'NEXT_DUE_DATE',
    },
    {
      label: 'Move all amortizations starting selected date.',
      value: 'PAYMENT_INTERVAL',
    }
  ];

  private request!: Omit<LoanMoratoriumParameters, 'fileId'> & {fileId?: number};
  private paymentIntervals: PaymentIntervalDefinition[] = [];
  private commandId: number | null = null;
  private readonly sampleFile = new Blob([`product_number;remarks
LOAN-22-230889-1;remarks`], {type: 'text/plain'});

  constructor(private http: HttpService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private reportService: ReportService,
              private customerCache: CustomerCache) {
  }

  async $onInit() {
    this.paymentIntervals = await this.http.get<PaymentIntervalDefinition[]>(`/products/loans/intervals`).toPromise();
    this.initializeRequest();
  }

  initializeRequest(): void {
    this.request = {
      loanMoratoriumStartDate: null,
      loanMoratoriumMaxDate: new Date('9999-12-31'),
      moveAmortizations: true,
      intervalCount: null,
      interestAdjustmentType: 'NONE',
      interestStaggerEndDate: null,
      principalAdjustmentType: 'NONE',
      principalStaggerEndDate: null,
      customFeeAdjustmentType: 'NONE',
      paymentIntervalDefinitionId: null,
      amortizationMovementType: 'SINGLE_AMORTIZATION',
      recalculatePenalties: false
    }
  }

  onMovementTypeChanged(): void {
    if (this.request.amortizationMovementType == 'NEXT_DUE_DATE') {
      this.request.paymentIntervalDefinitionId = null;
      this.request.intervalCount = null;
    }
  }

  isPaymentIntervalDisplayed(): boolean {
    return this.request.amortizationMovementType && this.request.amortizationMovementType === 'PAYMENT_INTERVAL'
  }

  isNoOfIntervalsDisplayed(): boolean {
    return this.request.amortizationMovementType && this.request.amortizationMovementType !== 'NEXT_DUE_DATE';
  }

  getNoOfIntervalLabel() : string {
    return this.request.amortizationMovementType === 'SINGLE_AMORTIZATION' ? 'Nr of days to be moved' : 'Nr of intervals:';
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'LoanAmortizationMovement';
    a.click();
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed?`,
    });

    if (!confirmed) {
      return;
    }
    const response = await this.command.execute('PerformBatchAmortizationMovement', this.request).toPromise();
    if (!response.approvalRequired && response.commandId) {
      this.commandId = response.commandId;
      this.initializeRequest();

      if (this.customerCache.loadedCustomerId) {
        await this.customerCache.loans(this.customerCache.loadedCustomerId).refetch();
      }
    }
  }

  async downloadSummary(): Promise<void> {
    if (!this.commandId) {
      return;
    }

    await this.reportService.downloadXls({
      reportCode: 'LoanBatchAmortizationMovementReport',
      reportName: 'LoanBatchAmortizationMovementReport.xlsx',
      params: {
        'commandId': this.commandId
      }
    });
  }

}

nxModule.component('batchLoanAmortizationMovement', {
  controller: BatchLoanAmortizationMovement,
  templateUrl
});
