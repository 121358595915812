import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import React, {ReactElement} from 'react';
import styles from './TreeNodeItemWrapper.scss';

export interface TreeNodeItemWrapperProps {
  treeNode: TreeNode;
  selectable: boolean;
  editable: boolean;
  children: (ReactElement | null)[];
}

const TreeNodeItemWrapper = ({editable, selectable, treeNode, children}: TreeNodeItemWrapperProps): ReactElement => {
  return <li className={clsx({
    editable,
    selectable,
    expanded: treeNode.expanded(),
    folder: treeNode.hasChildren(),
    leaf: !treeNode.hasChildren(),
  }, treeNode.hasChildren() ? styles.folder : styles.leaf)}>
    {children}
  </li>;
};


export default TreeNodeItemWrapper;