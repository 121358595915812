import nxModule from 'nxModule';

import templateUrl from './prints-details-label-configuration.template.html'
import {reorderLines} from "./print.utils";

import './prints-details-label-configuration.style.less';
import {Print, PrintLine} from "print/PrintType";

class PrintsDetailsLabelConfiguration {
  private print!: Print;
  private onChange!: () => void;
  private addLabel!: () => void;

  getLabels(): PrintLine[] {
    return this.print.lines.filter(line => line.type === 'LABEL');
  }

  removeLine(line: PrintLine): boolean {
    this.print.lines.splice(this.print.lines.indexOf(line), 1);

    reorderLines(this.print);
    return true;
  }
}

nxModule.component('printsDetailsLabelConfiguration', {
  templateUrl,
  bindings: {
    print: '=',
    onChange: '<',
    addLabel: '<',
    newLabel: '='
  },
  controller: PrintsDetailsLabelConfiguration,
});