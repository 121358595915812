import nxModule from 'nxModule';

import templateUrl from './account-group-details.template.html';

class AccountGroupDetails {

  constructor(command, $location, accountProductsCache, confirmation, authentication, accountGroupService){
    this.command = command;
    this.$location = $location;
    this.accountProductsCache = accountProductsCache;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.accountGroupService = accountGroupService;

    this.creationMode = true;
    this.title = 'Create Account Group';
  }

  async $onInit() {
    const accountTypes = await this.accountProductsCache.toPromise();
    this.accountType = accountTypes.find(t => t.id === this.typeId);

    if (this.groupId) {
      this.title = 'Edit Account Group';
      this.creationMode = false;
      this.accountGroup = await this.accountGroupService.getAccountGroup(this.groupId);
    }

  }

  async saveGroup() {
    if (this.creationMode)  {
      const proceed = await this.confirmation(`A new group for account product ${this.accountType.productDefinition.productName} will be created. Do you wish to proceed?`);

      if (proceed) {
        const request = {
          typeId: this.accountType.id,
          branchId: this.authentication.context.branchId,
          name: this.accountGroup.name
        };
        await this.command.execute('CreateAccountGroup', request).toPromise();
      }
    } else {
      const proceed = await this.confirmation(`Account group ${this.accountGroup.id} will be updated. Do you wish to proceed?`);

      if (proceed) {
        const request = {
          id: this.accountGroup.id,
          name: this.accountGroup.name
        };

        await this.command.execute('UpdateAccountGroup', request).toPromise();
      }
    }

    this.redirectBack();
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}/group`);
  }
}

nxModule.component('accountGroupDetails', {
  controller: AccountGroupDetails,
  templateUrl,
  bindings: {
    groupId: '<',
    typeId: '<',

    // customer details are used for navigational purposes.
    accountId: '<',
    customerId: '<'
  }
});