import {updateRefreshTime} from 'components/common/refresh-button/refresh-button.component';
import $ from 'jquery';
import _ from 'lodash';

import nxModule from 'nxModule';
import {NgTableParams} from 'ng-table';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import './action-commands.component';
import './action-details.component';
import './action.service';

export const typeColor = item => ({
  blue: item.taskGroup === 'CUSTOMER' || item.name === 'ConvertProspectToCustomer',
  green: item.taskGroup === 'OPERATION' || item.amount,
  orange: item.taskGroup === 'LEDGER_ACTION'
});

export const Statuses = {
  PENDING: 'Pending',
  ALL: 'All',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

const templateUrl = require('./action-list.template.html');
nxModule.component('dashboardActionList', {
  templateUrl: templateUrl,
  controller: function ($scope, $routeParams, $location, $element, $timeout, http, userCache, notification,
                        authentication, confirmation, availableTasksCache, dashboardActionService,
                        customerCache, groupCustomerCache, branchService) {
    const that = this;

    Object.assign(that, dashboardActionService.getApprovalLevelCalculator());

    // switch to sent-by-me tab
    $timeout(() => {
      if ($location.path() === '/dashboard/actions/sent-by-me') {
        $('#tab-header-by').click();
      }
    });

    that.branchIds = authentication.context.branchIds;

    that.statuses = Object.keys(Statuses)
      .map(statusName => ({
        value: statusName,
        label: Statuses[statusName]
      }));

    that.branches = [];

    that.filter = {
      status: that.statuses[0],
      branch: null
    };

    that.selectedAction = null;
    that.displayMode = 'NOT_OWNED';
    that.setDisplayMode = newDisplayMode => {
      if (that.displayMode !== newDisplayMode) {
        that.displayMode = newDisplayMode;

        if (that.displayMode === 'NOT_OWNED' || that.displayMode === 'OWNED') {
          that.currentConfig = that.configs[that.displayMode];
          that.currentConfig.reload();
          that.selectedAction = null;
        }
      }
    };

    that.typeColor = typeColor;

    that.taskCounters = {
      'OWNED': 0,
      'NOT_OWNED': 0
    };

    const branchSub = branchService.toObservable()
      .combineLatest(userCache.withParam().toObservable(), (branches, users) => {
        that.user = _.find(users, {id: authentication.context.id});
        if (!that.branchIds || that.branchIds.length === 0) return [];
        that.branches = _.filter(branches, (branch) => {
          return _.includes(that.branchIds, branch.id);
        });
        // Set default filter branch
        if (that.branches && that.branches.length > 0) {
          // First try to find branch of user
          that.filter.branch = _.find(that.branches, {id: authentication.context.branchId});
          // If user branch is not on the list -> use head
          if (!that.filter.branch) that.filter.branch = _.head(that.branches);
        }
      }).subscribe();

    that.hasTaskPermission = (task) => {
      if (!task || !task.taskGroup) return false;

      if (task.taskGroup === 'COMMAND_APPROVAL') {
          if(!task.approveUserIds || !task.approveUserIds.length) {
            return false;
          }

          return task.approveUserIds.some((approveLevelUserIds, index) => {
            if(!approveLevelUserIds.includes(that.user.id)) {
              return false;
            }

            const approvedLevels = task.approvedLevels || [];
            return !approvedLevels.some(approvedLevel => approvedLevel.level === index);
          });
      }

      console.error('Unhandled legacy task group:', task.taskGroup);
      return false;
    };

    /**
     * Task can be approved by logged user if:
     *
     * 1. User have not created the task
     * AND
     * 2a. For task.taskGroup != 'COMMAND_APPROVAL' user is assigned to task
     * 2b. For task.taskGroup == 'COMMAND_APPROVAL' user's roles have approve access rule.
     * OR
     * 3. Task is unassigned and user has required permission
     */
    that.canBeApproved = (task) => {
      if (!task || !task.taskGroup) return false;
      if (task.registrationUserId && task.registrationUserId === authentication.context.id) return false;
      if (task.approvalUserId && task.approvalUserId === authentication.context.id) return true;
      return !task.approvalUserId && that.hasTaskPermission(task);
    };

    /**
     * Task can be rejected by logged user if:
     *
     * 1. User did not create task
     * OR
     * 2. User is assigned to task
     * OR
     * 3. Task is unassigned and user has required permission
     */
    that.canBeRejected = (task) => {
      if (!task || !task.taskGroup) return false;
      if (task.registrationUserId && task.registrationUserId === authentication.context.id) return false;
      if (task.approvalUserId && task.approvalUserId === authentication.context.id) return true;
      return !task.approvalUserId && that.hasTaskPermission(task);
    };

    /**
     * Task can be revoked by logged user if:
     *
     * 1. User created task
     */
    that.canBeRevoked = (task) => {
      if (!task || !task.taskGroup) return false;
      return task.registrationUserId && task.registrationUserId === authentication.context.id;
    };

    const getData = mode => params => {
      let status = that.filter.status.value;
      if (status === 'ALL') {
        status = undefined;
      }

      let branchId = authentication.context.branchId;
      if (that.filter.branch) {
        branchId = that.filter.branch.id;
      }

      const userId = authentication.context.id;
      const modeParams = mode === 'NOT_OWNED' ? 'ASSIGNED&displayMode=UNASSIGNED': mode;

      const tasksPromise = http.get(`/tasks?displayMode=${modeParams}`, {
        params: {
          status,
          branchId,
          userId,
          pageNo: params.page() - 1,
          pageSize: params.count(),
        },
      }).toPromise();

      return Promise.all([tasksPromise, userCache.withParam().toObservable().take(1).toPromise()])
        .then(([tasks, users]) => {
          let totalCount = tasks.totalCount;
          that.configs[mode].total(totalCount);
          that.taskCounters[mode] = totalCount && totalCount >= 0 ? totalCount : 0;
          updateRefreshTime($scope);
          return tasks.result.map(task => Object.assign(task, {
            registrationUser: _.find(users, {id: task.registrationUserId}),
            approvalUser: _.find(users, {id: task.approvalUserId}),
            displayMode: mode,
            canBeApproved: that.canBeApproved(task),
            canBeRejected: that.canBeRejected(task),
            canBeRevoked: that.canBeRevoked(task),
            extraInfo: that.getTaskExtraInfo(task),
            amount: that.getTaskAmount(task)
          }))
        });
    };

    const createConfig = mode => new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: getData(mode)
    });

    that.configs = {
      'OWNED': createConfig('OWNED'),
      'NOT_OWNED': createConfig('NOT_OWNED')
    };

    that.currentConfig = that.configs[that.displayMode];

    that.refresh = () => {
      that.currentConfig.reload();
    };

    that.formatUser = user => `${user.firstName} ${user.lastName}`;

    that.getTaskExtraInfo = (task) => {
      let extraInfo = task.extraInfo;
      if (task.taskGroup === 'COMMAND_APPROVAL') {
        extraInfo = _.get(_.find(task.description, info => info.group === 'EXTRA_INFO'), 'value');
      }
      return extraInfo;
    };

    that.getTaskAmount = (task) => {
      let amount = task.amount;
      if (task.taskGroup === 'COMMAND_APPROVAL') {
        amount = _.get(_.find(task.description, info => info.group === 'AMOUNT'), 'value');
      }
      return amount;
    };

    that.hideDetailsPanel = () => {
      that.selectedAction = null;
    };

    that.rowClicked = (row, $event) => {
      // for dropdowns we let click pass thorough to the document, where we ignore clicks on them in inline panel
      const target = $($event.target);
      if(target.closest('.btn-group, .btn').length > 0 &&
          target.closest('inline-panel').length === 0) {

        that.selectedAction = null;
      } else if(target.closest('inline-panel').length === 0) {

        // we need to select action in another task, otherwise hiding prior task may hide task that should be displayed
        $timeout(() =>
          that.selectedAction = row.id
        );
      }
    };

    let initialFilterChange = true;
    $scope.$watch('$ctrl.filter', () => {
      if (initialFilterChange) {
        initialFilterChange = false;
      } else {
        that.currentConfig.page(1);
        that.currentConfig.reload();
      }
    }, true);

    that.revoke = (task, $event) => dashboardActionService.cancel(task, $event, (task) => {
      that.reloadData();
    });

    that.pinEntered = (task, pin, user) => dashboardActionService.pinEntered(task, pin, user, (task) => {
      that.reloadData();
    });

    that.reject = (task, $event) => dashboardActionService.reject(task, $event, (task) => {
      that.reloadData();
    });

    that.approveAction = (task, $event, level) => dashboardActionService.approve(task, $event, level, (task) => {
      that.reloadData();
    });

    that.localOverrideAction = (task, $event, level) => {
      that.overrideTask = {
        ...task,
        localOverrideLevel: level,
      };
    };

    // reload config and evict cache
    that.reloadData = () => {
      that.refresh();
      evict();
    }

    function evict() {
      availableTasksCache.refetch();
    }

    that.$onDestroy = () => {
      branchSub.unsubscribe();
    };
  }
});
