import React, {ReactElement} from "react";
import {NxTabs} from "@nextbank/ui-components";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import CheckPreparationHistoryTabContainer from "check/preparation/history/CheckPreparationHistoryTab";

const outgoingReports : string[] = ['Check Status Report Form', 'Bank Report', 'Check Voucher'];
const releasedReport : string[] = ['Check Register Report'];
const CheckPreparationHistory = (): ReactElement => {
  return <NxPage>
    <NxHeader>Check Preparation - History</NxHeader>
    <NxTabs
      tabs={[
        {
          key: 'assigned-check',
          label: 'Assigned to Outgoing Checks',
          tabPanel: <CheckPreparationHistoryTabContainer batchType={'FOR_OUTGOING'} historyReports={outgoingReports}/>
        },
        {
          key: 'outgoing-check',
          label: 'Outgoing to Released Checks',
          tabPanel: <CheckPreparationHistoryTabContainer batchType={'FOR_RELEASE'} historyReports={releasedReport}/>
        }
      ]}
    />
  </NxPage>;
};

export default CheckPreparationHistory;