import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./loan-check-payment.template.html');
nxModule.component('customerLoansCheckPayment', {
  templateUrl: templateUrl,
  controller: function ($route, $scope, $location, $filter, http, command, dict, customerCache, loanProductsCache, confirmation, accessRuleService, authentication) {
    let that = this;
    that.banks = [];
    that.loan = null;
    that.paymentRequest = {};
    that.checkModel = {};

    let customerId = $route.current.params['customerId'];
    that.loanId = $route.current.params['loanId'];

    customerCache.loans(customerId).toObservable()
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
        if (!loans) that.redirectBack();
        return loans.map(loan => {
          // add loanProduct to loan object
          const type = _.find(loanTypes, {id: loan.typeId});
          return Object.assign(loan, {
            loanType: type
          });
        });
      })
      .subscribe(loans => {
        that.loan = _.find(loans, (l) => l.id === Number(that.loanId));
      });

    that.updateCommandName = () => {
      that.commandName = that.checkModel.onUsCheck ? 'PayLoanByOnUsCheck' : 'PayLoanByCheck';
    };

    that.updateCanPerformCustomPayment = () => {
      accessRuleService.accessRuleForLoggedInUserRole(that.commandName).then(
        rule => that.canPerformCustomPayment = !rule.predicates['EQUALS'] || rule.predicates['EQUALS']['LOAN_CUSTOM_PAYMENT']
      );
    };

    that.updateCommandName();
    that.updateCanPerformCustomPayment();

    $scope.$watch('$ctrl.checkModel.onUsCheck', () => {
      that.updateCommandName();
      that.updateCanPerformCustomPayment();
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${that.loanId}`);

    that.pay = () => {
      confirmation(`Do you want to pay ${$filter('nxCurrency')(that.checkModel.amount)}?`, () => {
        command.execute(that.commandName, {
          productId: that.loanId,
          ...that.paymentRequest,
          checkOperation: that.checkModel
        }, {nxLoaderText: 'Performing operation...'})
          .success(() => {
            customerCache.loans(customerId).refetch();
            authentication.permissions['CST_CREDIT_LINE_READ'] ?  customerCache.creditLines(customerId).refetch() : Promise.resolve();
            customerCache.depositAccounts(customerId).refetch();
            customerCache.loanPDCs(customerId, that.loanId).refetch();
            that.redirectBack();
          }, false).offline(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        })
      });
    };

    that.print = () => {
      window.print();
    };

    $scope.$watch('$ctrl.paymentRequest', () => {
      that.checkModel.amount = that.paymentRequest.amount;
    }, true);

  }
});
