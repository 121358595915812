import {isFieldRequired, useValidationSchema} from '@nextbank/ui-components';
import {useField, useFormikContext} from 'formik';
import NxBranchMultiselect from 'management/NxBranchMultiselect';
import React, {useCallback} from 'react';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

const NxFormikBranchMultiselect = (props: Props): React.ReactElement | null => {
  const context = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const validationSchema = useValidationSchema();

  const onChange = useCallback((values: number[]): void => {
    helpers.setValue(values, context.validateOnChange);
  }, [helpers, context.validateOnChange]);

  return <NxBranchMultiselect
    {...field}
    {...props}
    onChange={onChange}
    error={meta.error}
    onBlur={(): void => helpers.setTouched(true, context.validateOnBlur)}
    disabled={props.disabled || context.isSubmitting}
    required={props.required || isFieldRequired(validationSchema, field.name)}
  />;
};

export default NxFormikBranchMultiselect;
