import nxModule from 'nxModule';

nxModule.factory('actionCommand', ($location, command, userCounterService, authentication, confirmation) => {
  let service = {};

  const actionTypeToCommandMap = {
    CASHIER_CHECK_CREATION: 'CashierCheckCreation',
    DISTRIBUTED_CASH_IN: 'DistributedCashIn',
    DISTRIBUTED_CASH_OUT: 'DistributedCashOut',
    BATCH_CHECK_DEPOSIT_TO_BANK: 'BatchCheckDepositToBank',
    BATCH_CHECK_TRANSFER_TO_CASHIER: 'BatchCheckTransferToCashier',
    CLOSE_COUNTER: 'CloseCounter',
    END_DAY: 'EndDay',
    MISC_CASH_DISBURSEMENT: 'MiscCashDisbursement',
    POS_CASH_WITHDRAWAL: 'PosCashWithdrawal',
    START_DAY: 'StartDay',
    CREATE_INTERBRANCH_CASHIER_CHECK: 'CreateInterbranchCashierCheck',
    ENCASH_INTERBRANCH_CASHIER_CHECK: 'EncashInterbranchCashierCheck'
  };

  service.actionTypeToCommand = (actionType) => actionTypeToCommandMap[actionType];

  const defaultOnSuccess = () => {
    userCounterService.refresh();
    $location.path("/dashboard/miscellaneous-transactions");
  };

  service.prepareCommand = (actionType, request) => {
    // Set [userId] of transaction to id of authenticated user
    request.userId = authentication.context.id;
    const commandName = service.actionTypeToCommand(actionType);
    const actionRequest = {
      actionType: actionType,
      action: request,
      approvalUserId: request.approvalUserId
    };

    return command.execute(commandName, actionRequest);
  };

  /**
   * @deprecated prefer executeAsync()
   * Executes the action as command, based on internal mapping of actionType to command
   *
   * By default all callbacks attached by `command` service are attached with exception:
   * - if onSuccess is not provided -> default command callback + redirect back to transaction list
   *                                                            + refresh userCounterService
   *
   * When onSuccess is provided the extra callback (all but default command callback) will be overridden.
   */
  service.execute = (actionType, request, onSuccess) => {
    let successCb = onSuccess ? onSuccess : defaultOnSuccess;
    return service.prepareCommand(actionType, request)
      .success(res => successCb(res));
  };

  /**
   * returns a promise from the command, actionType is converted to command based on internal mapping of actionType to
   * command
   *
   * Execution of action-command should prefer to use this instead of service.execute(actionType, request, onSuccess)
   * */
  service.executeAsync = (actionType, request) => {
    return service.prepareCommand(actionType, request).toPromise();
  };

  service.cancelChanges = () => {
    confirmation('Do you want to cancel? Canceling will discard all changes.',
      () => $location.path(`/dashboard/miscellaneous-transactions`));
  };

  return service;
});
