import nxModule from 'nxModule';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';

nxModule.factory('productLockCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/${productId}/locks`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productLock,
    cacheName: 'productLockCache'
  })
);
