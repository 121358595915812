import axios from 'axios';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {prepareFlatReportJson} from 'components/report/prepareFlatReportJson';
import {FileService} from 'components/service/file.service';
import {Loader} from 'components/technical/loader.types';
import nxModule from 'nxModule';
import retry from 'p-retry';
import ReactReportService from 'report/ReportService';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService, HttpServiceResponse} from 'shared/utils/httpService';
import {ErrorResponse} from 'tools/HttpTypes';
import {v4 as uuidV4} from 'uuid';

export type ReportParams = Record<string, string | number | number[] | string[] | {} | null | undefined>;

export interface ReportRequest {
  reportCode: string;
  params: ReportParams;
}

export enum ReportStatus {
  SCHEDULED = 'SCHEDULED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED'
}

export enum StorageType {
  LOCAL = 'LOCAL',
  S3 = 'S3'
}

class ApiError<T extends Partial<ErrorResponse>> extends Error {
  constructor(public errorResponse: T) {
    super(errorResponse.errorMessage);
  }
}

const reactReportService = new ReactReportService();
export default class ReportService {
  constructor(private readonly popup: Popup,
              private readonly http: HttpService,
              private readonly loader: Loader,
              private readonly fileService: FileService,
              private readonly command: CommandService) {
  }

  handleResponseError(requestUuid: string, data: ApiError<Partial<ErrorResponse>>): void {
    const unknownError = 'An unknown error occurred.';

    let msg = data.errorResponse?.errorMessage ? data.errorResponse.errorMessage : unknownError;
    if (data.errorResponse?.errorCode === 'TOO_MANY_REQUESTS') {
      msg = 'Error generating report. Please try again later';
    }
    const errorCode = requestUuid
      ? `Error code: ${requestUuid.substring(requestUuid.length - 8)}`
      : '';
    const processedMessage = msg.replace(/\n/g, '<br>');
    this.popup({header: 'Error', text: processedMessage, finePrint: errorCode, renderHtml: true});
  }

  async retryRequest<T>(request: () => Promise<T>): Promise<T> {
    const loaderId = this.loader.show('Downloading report');

    try {
      // retry request for 20 times until it succeeds
      return await retry(async () => {
        try {
          return await request();
        } catch (err) {
          if (!err) {
            throw new retry.AbortError('Error reading data');
          }

          const responseError: ErrorResponse = err;
          const {errorCode} = responseError;
          if (errorCode !== 'TOO_MANY_REQUESTS') {
            throw new retry.AbortError(new ApiError(responseError));
          }

          console.trace('Too many requests', responseError);
          throw new ApiError(responseError);
        }
      }, {
        maxTimeout: 120000, // retry at least every 2 min
        retries: 20,  // retry with backoff up to 2min, and then try every 2 min
        randomize: true // after 30min we will deny request for report
      });
    } finally {
      this.loader.dismiss(loaderId);
    }
  }

  async downloadReportFile(reportName: string | undefined, httpObject: HttpServiceResponse<unknown>): Promise<void> {
    return new Promise((resolve, reject) => {
      httpObject.success((response, status, headers) => {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const xlsxFileUrl = window.URL.createObjectURL(response);
          const filename = this.fileService.parseHeadersForContentFilename(headers) ?? reportName ?? '';

          this.clickOnLink(xlsxFileUrl, filename);

          window.URL.revokeObjectURL(xlsxFileUrl);
          resolve();
        } catch (err) {
          console.error(err);
          reject({
            errorMessage: err.message
          });
        }
      }).error(async data => {
        if (!data) {
          reject(null);
        }

        if (data instanceof Blob) {
          reject(await new Response(data).json());
        }

        reject(data);
      });
    });
  }

  async downloadJson({reportCode, params}: ReportRequest): Promise<any> {
    let resp = null;
    let requestUuid = '';
    const responseType = 'json';
    try {
      resp = await this.retryRequest(() => {
          requestUuid = uuidV4();
          return this.http
            .http({
              url: `/reports/${reportCode}/json`,
              method: 'POST',
              nxLoaderSkip: true,
              responseType: responseType,
              data: prepareFlatReportJson(params),
              headers: {
                'Content-Type': 'application/json',
                'nx-request-id': requestUuid
              }
            })
            .toPromise();
        }
      );
    } catch (err) {
      this.handleResponseError(requestUuid, err);
      throw err;
    }

    this.nullResponseBodyHandler(requestUuid, resp, responseType);

    return resp;
  }

  async downloadXls({reportCode, params, reportName}: ReportRequest & {reportName?: string}): Promise<void> {
    let requestUuid = '';
    try {
      await this.retryRequest(() => {
          requestUuid = uuidV4();
          return this.downloadReportFile(reportName, this.http.http({
            url: `/reports/${reportCode}/xls`,
            method: 'POST',
            nxLoaderSkip: true,
            responseType: 'blob',
            data: prepareFlatReportJson(params),
            headers: {
              'Content-Type': 'application/json',
              'nx-request-id': requestUuid
            },
            nxLoaderText: 'Downloading report XLS'
          }));
        }
      );
    } catch (err) {
      this.handleResponseError(requestUuid, err);
      throw err;
    }
  }

  async downloadCsv({reportCode, params, reportName}: ReportRequest & {reportName: string}): Promise<void> {
    let requestUuid = '';
    try {
      await this.retryRequest(() => {
          requestUuid = uuidV4();
          return this.downloadReportFile(reportName, this.http.http({
            url: `/reports/${reportCode}/csv`,
            method: 'POST',
            nxLoaderSkip: true,
            responseType: 'blob',
            data: prepareFlatReportJson(params),
            headers: {
              'Content-Type': 'application/json',
              'nx-request-id': requestUuid
            },
            nxLoaderText: 'Downloading report CSV'
          }));
        }
      );
    } catch (err) {
      this.handleResponseError(requestUuid, err);
      throw err;
    }
  }

  async downloadCustomFile({reportCode, params}: ReportRequest): Promise<void> {
    let requestUuid = '';
    try {
      await this.retryRequest(() => {
          requestUuid = uuidV4();
          return this.downloadReportFile(reportCode, this.http.http({
            url: `/reports/${reportCode}/custom-file`,
            method: 'POST',
            nxLoaderSkip: true,
            responseType: 'blob',
            data: prepareFlatReportJson(params),
            headers: {
              'Content-Type': 'application/json',
              'nx-request-id': requestUuid
            },
            nxLoaderText: 'Downloading report custom file'
          }));
        }
      );
    } catch (err) {
      this.handleResponseError(requestUuid, err);
      throw err;
    }
  }

  async downloadAsyncCustomFile({reportCode, params}: ReportRequest): Promise<void> {
    const commandOutputWrapper = await this.http.http<CommandOutputWrapper<unknown>>({
      url: `/reports/${reportCode}/command`,
      method: 'POST',
      data: prepareFlatReportJson(params),
      headers: {'Content-Type': 'application/json'},
      nxLoaderText: 'Downloading report custom file'
    }).toPromise();

    try {
      const response = await this.command.pollForCommand<{attachedFileId: number}>(commandOutputWrapper.commandId);
      await this.fileService.downloadFileToDisk(response.output.attachedFileId, reportCode);
    } catch (e) {
      this.popup({header: 'Error', text: e.errorMessage, renderHtml: false});
    }
  }

  /**
   * Sends a request to generate the reporting tool and blocks the screen until it is downloaded (or fails).
   * */
  async downloadFileReport(reportName: string, parameters: Record<string, unknown>): Promise<number> {
    const loaderId = this.loader.show('Downloading report');
    try {
      return await reactReportService.downloadFileReport({
        reportName,
        parameters
      });
    } catch (e) {
      console.error('Error generating report', e);
      // it's ugly that we have error handling here
      // but it may simplify using the service
      const defaultMessage = 'Failed to generate report';
      if (axios.isAxiosError(e)) {
        const data = e.response?.data;
        this.popup({header: 'Error', text: data.errorMessage ?? defaultMessage});
      } else {
        this.popup({header: 'Error', text: defaultMessage});
      }
      throw e;
    } finally {
      this.loader.dismiss(loaderId);
    }
  }

  clickOnLink(url: string, download: string): void {
    const a = document.createElement('a');
    a.download = download;
    a.href = url;
    a.click();
  }

  nullResponseBodyHandler(requestUuid:string, response: any, responseType: 'blob'|'json'): void {
    if (response === null) {
      // response cannot be null - it always should contain a value
      // empty value happens if there is no connection or we got a streaming error
      // and data conversion failed
      const error = {
        errorMessage: responseType === 'json' ? 'The generated report cannot be displayed because it contains more data than what can be viewed. Please use Download instead.' : 'Error reading data',
        errorCode: responseType === 'json' ? 'JSON_RESPONSE_TOO_LARGE' : 'ERROR_READING_DATA'
      };

      this.handleResponseError(requestUuid, new ApiError(error));
      throw error;
    }
  }
}

nxModule.service('reportService', ReportService);