import nxModule from 'nxModule';
import $ from 'jquery';

interface Input {
  header?: string;
  text?: string;
  finePrint?: string;
  renderHtml?: boolean;
  callback?: () => void;
  actionRequired?: boolean;
}

type Popup = (input: Input) => void;

// actionRequired (OK button should be clicked) is a flag that will disable dismissing the popup by any means unless clicking the 'OK' button.
const popup: Popup = ({header = "Information", text = "", finePrint = "", callback, renderHtml = false, actionRequired = false}: Input): void => {
  if (!$('#popupModal')[0]) {
    $('#modal-container').append(`
				<div id="popupModal" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
						<h2 class="header-text"></h2>
					</div>
					<div class="modal-body">
						<p class="text"></p>
					  <p class="fine-print"></p>
					</div>
					<div class="modal-footer">
						<button class="btn btn-primary modalButton" data-dismiss="modal" aria-hidden="true">OK</button>
					</div>
				</div>`);
  } else {
    $('#popupModal .modalButton').off();
  }

  $('#popupModal .header-text').text(header);
  if (renderHtml) {
    $('#popupModal .text').html(text);
  } else {
    $('#popupModal .text').text(text);
  }
  if (actionRequired)  {
    $('#popupModal').attr('data-backdrop','static');
    $('#popupModal').attr('data-keyboard','false');
    $('#popupModal .close').attr('hidden','hidden');
  }
  $('#popupModal .fine-print').text(finePrint);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  $('#popupModal').modal();
  $('#popupModal .modalButton').click(function () {
    disableButtons();
    if (callback) {
      $('#popupModal').one('hidden', callback);
    }
  });

  function disableButtons() {
    $('#popupModal .modalButton').off();
  }
};


export default Popup;
nxModule.factory('popup', () => popup);
