import nxModule from 'nxModule';

import './dynamic-report-mapping-manual.style.less';
import templateUrl from './dynamic-report-mapping-manual.template.html';

class DynamicReportMappingManual {
  constructor() {
    this.manual = [{
      name: 'General ledger account',
      example: "GL('COH')",
      description: 'Provides balance of account with GL code given as argument'
    }, {
      name: 'General ledger account per branch',
      example: "GL('COH', '001')",
      description: 'Provides balance of account with GL code and branch code given as argument'
    }, {
      name: 'Absolute value',
      example: "ABS(GL('COH'))",
      description: 'Takes absolute value of provided argument'
    }, {
      name: 'Summation',
      example: "GL('COH') + GL('ASSET')",
      description: 'Adds two amounts'
    }, {
      name: 'Subtraction',
      example: "GL('COH') - GL('ASSET')",
      description: 'Calculates difference between two amounts'
    }, {
      name: 'Multiplication',
      example: "GL('COH') * 0.8",
      description: 'Multiplies given amount by number'
    }, {
      name: 'Division by number',
      example: "GL('COH') / 2",
      description: 'Divides given amount by number'
    }, {
      name: 'Division by amount',
      example: "GL('COH') / GL('ASSET')",
      description: 'Divides two amounts. Returns percentage which cannot be operated further upon'
    }, {
      name: 'Parenthesis',
      example: "(GL('COH') + GL('COH')) * 2",
      description: 'Parentheses force specific calculation order'
    }, {
      name: 'Exact value',
      example: "'0.00'",
      description: `Evaluates formula to provided value. Doesn't perform any operations and pastes exact value to excel cell`
    }];
  }
}

nxModule.component('dynamicReportMappingManual', {
  templateUrl,
  bindings: {
    maxHeight: '@',
  },
  controller: DynamicReportMappingManual
});