import nxModule from 'nxModule';
import _ from 'lodash';

import './customer-credit-line-create.style.less';
import templateUrl from './customer-credit-line-create.template.html';

class CustomerCreditLineCreate {

  constructor($route, $location, customerCache, confirmationTemplate, customerService, command, $filter) {
    this.$route = $route;
    this.$location = $location;
    this.customerCache = customerCache;
    this.confirmationTemplate = confirmationTemplate;
    this.customerService = customerService;
    this.command = command;
    this.$filter = $filter;
  }

  $onInit() {
    this.customerId = Number(this.$route.current.params['customerId']);

    this.creditLine = {
      customerId: this.customerId
    };
  }

  postCreditLine() {
    const request = _.cloneDeep(this.creditLine);
    this.confirmationTemplate({
      question: 'Open credit line?',
      details: [
        {label: 'Description', description: this.creditLine.description},
        {label: 'Grant date', description: this.creditLine.grantDate},
        {label: 'Expiration date', description: this.creditLine.expirationDate},
        {label: 'Type', description: this.$filter('prettyEnum')(this.creditLine.replenishmentType)},
        {label: 'Approved credit line', description: this.$filter('nxCurrency')(this.creditLine.approvedCreditLine)},
        {label: 'Loan types allowed', description: this.creditLine.loanTypesAllowed}
      ],
      yesCallback: async() => {
        await this.command.execute('CreateCreditLine', request).toPromise();
        this.customerCache.creditLines(this.customerId).refetch();
        this.redirectBack();
      }
    });
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/credit-lines`);
  }
}

nxModule.component('customerCreditLineCreate', {
  templateUrl: templateUrl,
  controller: CustomerCreditLineCreate
});