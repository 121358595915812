import nxModule from 'nxModule';

import templateUrl from './agents-credentials.template.html';
import {
  PasswordComplexityRules,
  PasswordComplexityService
} from '../../../technical/password/password-complexity.service';

class AgentsCredentials {
  public retypedPassword: string = '';
  public forcedPasswordUpdate!: boolean;
  public complexityRules: PasswordComplexityRules;

  constructor(private passwordComplexityService: PasswordComplexityService) {
    this.complexityRules = this.passwordComplexityService.getComplexityRules();
  }
}

nxModule.component('agentsCredentials', {
  templateUrl: templateUrl,
  bindings: {
    agent: '=',
    forcedPasswordUpdate: '<'
  },
  controller: AgentsCredentials
});
