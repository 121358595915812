type ImportedType = typeof import('libs/chartjs');

let instance: Promise<ImportedType> | null = null;

export const getChart = async (): Promise<ImportedType['default']> => {
  if(!instance) {
    instance = import(/* webpackChunkName: "chartjs" */ 'libs/chartjs');
  }

  return (await instance).default;
};