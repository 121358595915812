import nxModule from 'nxModule';

import templateUrl from './new-access-rule.template.html';
import './new-acces-rule.style.less';
import {
  AccessRule,
  AccessRuleCache,
  AccessRuleExecutionType,
  AccessRuleInput,
  CommandAccessRuleDefinition,
  CommandGroup,
  ExecuteOption,
  NEW_RULE_EXECUTE_OPTIONS
} from "../../../../service/access/access-rule.types";
import {SelectConfig} from "../../../../common/types/selectize.types";
import Notification from "shared/utils/notification";
import {ILocationService} from "angular";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";

class NewAccessRule {

  // bindings
  private roleId!: number;
  private commandGroup!: CommandGroup;
  private accessRulesDefinition!: CommandAccessRuleDefinition[];

  private commands: string[] = [];
  private accessRulesToConfigure: AccessRule[] = [];
  private executionOptions: ReadonlyArray<ExecuteOption> = NEW_RULE_EXECUTE_OPTIONS;

  private readonly commandConfig: SelectConfig = {
    placeholder: 'Select action',
    searchField: ['displayName', 'command'],
    valueField: 'command',
    labelField: 'displayName',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    maxItems: null
  };

  constructor(private $route: NxRouteService,
              private $filter: NxIFilterService,
              private $location: ILocationService,
              private http: HttpService,
              private accessRuleCache: AccessRuleCache,
              private popup: Popup,
              private notification: Notification) {
  }

  addCommands(): void {
    this.commands.forEach(command => {
      const accessRule: AccessRule = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id: null,
        roleId: this.roleId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        predicates: {},
        command: command,
        executionType: AccessRuleExecutionType.NONE,
        approve: false,
        otpRequired: false,
        displayName: this.$filter('translateEnum')(command, 'COMMAND')
      };

      this.accessRulesToConfigure.push(accessRule);
    });

    // filter out the already added command.
    this.accessRulesDefinition = this.accessRulesDefinition.filter(accessRuleDef => !this.commands.includes(accessRuleDef.command));
    this.commands = [];
  }

  navigateBackToGroupedList(): void {
    this.$location.path(`admin/security/roles/${this.roleId}/access-rules/${this.commandGroup}`);
  }

  async save(): Promise<void> {
    const request: AccessRuleInput = {
      accessRules: this.accessRulesToConfigure,
      deleteRuleIds: []
    };

    const response = await this.http.post<{errorMessage?: string}>('/access', request).toPromise();
    if (response.errorMessage) {
      this.popup({text: response.errorMessage});
    } else {
      this.notification.show("Access rule successfully added.");
      this.accessRuleCache.evict();
      this.$location.path(`/admin/security/roles/${this.roleId}/access-rules/${this.commandGroup}`);
    }
  }

}

nxModule.component('newAccessRule', {
  templateUrl,
  controller: NewAccessRule,
  bindings: {
    roleId: '<',
    commandGroup: '<',
    accessRulesDefinition: '<',
  }
});