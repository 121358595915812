import React, {ReactElement} from "react";
import {
  NxButtonVariant,
  NxFormik,
  NxFormikSubmitButton,
  NxFormikInput,
  NxInput,
  NxLoader,
  NxRow,
  NxRowPosition,
  NxStack
} from "@nextbank/ui-components";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import * as Yup from 'yup';
import {SchemaOf} from 'yup';
import {CommandOutputWrapper} from "command/CommandTypes";
import {useCommand} from "command/CommandService";
import systemPropertyService from "system/systemPropertyService";
import NxForm from "form/NxForm";
import {Account} from "components/service/product.types";
import {useHistory, useParams} from "react-router";
import useAxios from "axios-hooks";
import {Customer} from "customer/CustomerTypes";
import NxCancelButton from 'NxCancelButton';

interface CreateAliasInput {
  accountId: number;
  alias: string;
}

interface CreateAliasFormInput {
  alias: string;
}

const CreateAlias = () : ReactElement => {
  const {accountId, customerId} = useParams<{accountId: string, customerId: string}>();
  const execute = useCommand();
  const history = useHistory();
  const [{data: account}] = useAxios<Account>(`/products/accounts/${accountId}`);
  const [{data: customer}] = useAxios<Customer>(`/customers/${customerId}`);
  const aliasLength = systemPropertyService.getProperty('VIRTUAL_ACCOUNT_NUMBER_ALIAS_LENGTH');

  const header = <NxHeader>Create alias</NxHeader>;
  if(!account || !customer || !aliasLength) {
    return <NxPage>{header}<NxLoader/></NxPage>;
  }

  const CreateAliasFormSchema: SchemaOf<CreateAliasFormInput> = Yup.object().shape({
    alias: Yup.string().required("Alias is required")
      .matches(/^[1-9][0-9]{1,16}$/, "Alias must be only numbers and should not start with zero")
      .length(Number(aliasLength), `Alias must be ${aliasLength} numbers long`),
  });

  return <NxPage>
    {header}
    <NxFormik<CreateAliasFormInput>
      initialValues={{
        alias: ""
      }}
      validationSchema={CreateAliasFormSchema}
      onSubmit={async (input: CreateAliasFormInput): Promise<void> => {
        const response: CommandOutputWrapper<void> = await execute<CreateAliasInput, void>({
          name:'CreateAccountAlias',
          input: {
            ...input,
            accountId: Number(accountId)
          }});

        if(!response.approvalRequired) {
          history.goBack();
        }
      }}
    >
      {(): ReactElement => {
        return <NxForm>
          <NxStack>
            <NxInput label="Product number" value={account.productNumber} disabled />
            <NxFormikInput label="Alias" name="alias" />
            <NxRow position={NxRowPosition.END}>
              <NxCancelButton />
              <NxFormikSubmitButton variant={NxButtonVariant.SAVE}>
                Create
              </NxFormikSubmitButton>
            </NxRow>
          </NxStack>
        </NxForm>;
      }
      }
    </NxFormik>
  </NxPage>;
};

export default CreateAlias;