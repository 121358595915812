import nxModule from "nxModule";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {Customer} from "components/service/center/center.types";
import {Loan} from "components/service/loan.types";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {ProductDefinition} from "components/service/product.types";
import {CustomerCache} from "components/service/customer.cache.types";

export interface PermissionContext {
  customerId: number,
  loanId?: number
}

export default class PermissionService {
  private customerToBranchId: Record<string, number> = {};
  private loanToProductDefinition: Record<number, ProductDefinition> = {};

  constructor(private authentication: Authentication,
              private http: HttpService,
              private customerCache: CustomerCache,
              private productDefinitionService: ProductDefinitionService) {
  }

  async hasPermission(requiredPermissions: string[], context?: PermissionContext): Promise<boolean> {
    if (!requiredPermissions) {
      return true;
    }

    for (let i = 0; i < requiredPermissions.length; i++) {
      const requiredPermission = requiredPermissions[i];
      switch (requiredPermission) {
        case 'CST_ALL_BRANCHES_ACCESS':
          return await this.validateCustomerBranch(requiredPermission, context)
        case 'CST_EMPLOYEE_LOANS_READ':
          return await this.validateLoanAvailability(requiredPermission, context)
        default:
          break;
      }

      if (!this.authentication.permissions[requiredPermission]) {
        return false;
      }
    }
    return true;
  }

  private async validateCustomerBranch(permission: string, context?: PermissionContext): Promise<boolean> {
    if (this.authentication.permissions[permission] || context?.customerId == null) {
      return this.authentication.permissions[permission];
    }
    try {
      const branchId = await this.getBranchForCustomer(context.customerId);
      return this.authentication.context.branchIds.includes(branchId);
    } catch (e) {
      console.error(`Unable to find customer with id ${context.customerId}`, e);
      return false;
    }
  }

  private async validateLoanAvailability(permission: string, context?: PermissionContext) {
    if (this.authentication.permissions[permission] || context?.customerId == null || context?.loanId == null) {
      return this.authentication.permissions[permission];
    }

    const productDefinition: ProductDefinition | undefined = await this.getProductDefinitionForLoan(context.customerId, context.loanId);
    return !productDefinition?.employeeProduct;
  }

  private async getBranchForCustomer(customerId: number): Promise<number> {
    if (!this.customerToBranchId[customerId]) {
      const customer: Customer = await this.getCustomerById(customerId);
      this.customerToBranchId[customerId] = customer.branchId;
    }
    return Promise.resolve(this.customerToBranchId[customerId]);
  }

  private async getProductDefinitionForLoan(customerId: number, loanId: number): Promise<ProductDefinition> {
    if (!this.loanToProductDefinition[loanId]) {
      const [loans, definitions]: [Loan[], ProductDefinition[]] = await Promise.all([
        this.customerCache.loans(customerId).toPromise(),
        this.productDefinitionService.toPromise()
      ]);
      const definitionsMap = new Map(definitions.map(i => [i.id, i]));
      const loanMap = new Map(loans.map(i => [i.id, i]));
      const loan: Loan | undefined = loanMap.get(loanId);
      const definition = loan ? definitionsMap.get(loan.definitionId) : null;
      if (definition) {
        this.loanToProductDefinition[loanId] = definition;
      }
    }

    return Promise.resolve(this.loanToProductDefinition[loanId]);
  }

  evict(): void {
    this.customerToBranchId = {};
    this.loanToProductDefinition = {};
  }

  private async getCustomerById(customerId: number | string): Promise<Customer> {
    return await this.http.get<Customer>(`/customers/${customerId}?allowInactive=true`).toPromise();
  }

}

nxModule.service('permissionService', PermissionService);
