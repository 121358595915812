import nxModule from 'nxModule';

import templateUrl from './credit-scoring-modal-loan-application-details.template.html';
import './credit-scoring-modal-loan-application-details.style.less';
import {LoanApplicationDetails} from "components/customer/credit-scoring/credit-scoring.types";

class CreditScoringModalLoanApplicationDetails {
  loanApplicationDetails!: LoanApplicationDetails;
}

nxModule.component('creditScoringModalLoanApplicationDetails', {
  templateUrl,
  bindings: {
    loanApplicationDetails: '<'
  },
  controller: CreditScoringModalLoanApplicationDetails
});
