import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import Notification from 'shared/utils/notification';

import templateUrl from './recalculate-metadata-for-all-loans.template.html';

class RecalculateMetadataForAllLoans {
  constructor(private confirmation: Confirmation,
              private command: CommandService,
              private readonly popup: Popup,
              private notification: Notification) {
  }

  async execute():Promise<void> {
    const proceed = await this.confirmation("Are you sure you want to recalculate all loans metadata that have automatic metadata enabled?");
    if (!proceed) {
      return;
    }

    const {output} = await this.command.execute<any, {executionErrors: {productNumber: string, errorMessage: string}[]}>('UpdateMetadataForAllLoans', {}).toPromise();

    if (output.executionErrors && output.executionErrors.length > 0) {
      const header = '<p><span class="red"><strong>WARNING!</strong> Loans listed below resulted in errors and will not update its metadata:</span></p>';
      const body: string[] = [];
      output.executionErrors.forEach(err => {
        body.push(...Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
      });

      const errorDetails = [header, ...body].join('');
      await this.popup({
        header: 'Update result',
        text: errorDetails,
        renderHtml: true
      });
    }
  }
}

nxModule.component('recalculateMetadataForAllLoans', {
  templateUrl,
  controller: RecalculateMetadataForAllLoans
});
