import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./security.template.html');
nxModule.component('security', {
  templateUrl,
  controller: function (rolesCache) {
    const that = this;

    that.$onInit = () => {
      rolesCache.toObservable().first().subscribe(roles => {
        that.roles = _.filter(roles, r => !r.technical);
      });
    };
  }
});
