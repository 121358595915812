import React, {ReactElement} from 'react';
import styles from './Status.scss';
import {Typography} from "@material-ui/core";
import {StatusDetails} from "./StatusType";
import {InferProps, number, object, oneOfType, string} from 'prop-types';
import {Holiday, WorkingDay} from "../WorkingDayType";
import NxHeader, {HeaderVariant} from "../../form/NxHeader";
import {NxStack} from "@nextbank/ui-components";
import NxPage from '../../form/NxPage';

const elementChildren = {
  children: oneOfType([string, number]).isRequired
};

type elementChildrenType = InferProps<typeof elementChildren>;

const Label = (props: elementChildrenType): ReactElement => <Typography color="textPrimary">
  {props.children}
</Typography>;

Label.propTypes = elementChildren;

const Value = (props: elementChildrenType): ReactElement => <Typography color="textSecondary">
  {props.children}
</Typography>;

Value.propTypes = elementChildren;

const statusViewPropTypes = {
  status: object.isRequired
};

type StatusViewProps = {
  status: {
    workingDay: WorkingDay
  }
};

class StatusView extends React.Component<StatusViewProps, unknown> {
  static propTypes = statusViewPropTypes;

  render(): ReactElement {
    const workingDay = this.props.status.workingDay;
    return <>
      {this.renderWorkingDay(workingDay)}
    </>;
  }

  renderWorkingDay(workingDay: WorkingDay): ReactElement {
    return <NxPage>
          <NxHeader>
            Working day
          </NxHeader>
          <NxStack>
            <div className={styles.grid}>
              <Label>Branch</Label>
              <Value>{workingDay.branchId}</Value>

              <Label>Clearing Day</Label>
              <Value>{workingDay.clearingDay ? 'Yes' : 'No'}</Value>

              <Label>Bsp Approval</Label>
              <Value>{workingDay.bspApproval ? 'Yes' : 'No'}</Value>

              <Label>Start Time</Label>
              <Value>{workingDay.startTime}</Value>

              <Label>Start User</Label>
              <Value>{workingDay.startUserId}</Value>

              <Label>Status</Label>
              <Value>{workingDay.status}</Value>
            </div>
            {(workingDay.branchHoliday || workingDay.bankHoliday) && this.renderHoliday(workingDay)}
          </NxStack>
      </NxPage>;
  }

  renderHoliday(workingDay: WorkingDay): ReactElement {
    return <NxStack>
      <NxHeader variant={HeaderVariant.H3}>
        Holiday
      </NxHeader>
      {workingDay.branchHoliday &&
        <div className={styles.grid}>
          <Label>Holiday name</Label>
          <Value>{workingDay.branchHoliday.name}</Value>
        </div>
      }
      {workingDay.bankHoliday &&
        <div className={styles.grid}>
          <Label>Holiday name</Label>
          <Value>{workingDay.bankHoliday.name}</Value>
        </div>
      }
    </NxStack>;
  }
}

export default StatusView;