import nxModule from 'nxModule';
import templateUrl from './signature-card.template.html';
import {ProductGroup, ProductStatus} from '../../service/product.types';
import systemPropertyService from "../../../../react/system/systemPropertyService";
import Authentication from "shared/utils/authentication";
import {FileService} from "components/service/file.service";
import Notification from "shared/utils/notification";

class SignatureCardComponent {
  private hasSignatureCardReadPermission!: boolean;
  private blockSignatureCardPreview!: boolean;

  showPopup: boolean = false;
  signature: string | null = null;
  signatureType: 'PDF' | 'IMAGE' | undefined;

  productGroup!: ProductGroup;
  productStatus!: ProductStatus;
  fileId!: number;
  useIconButton!: boolean;

  constructor(
    private readonly authentication: Authentication,
    private readonly fileService: FileService,
    private readonly notification: Notification,
    private readonly $element: JQuery
  ) { }

  async $onInit(): Promise<void> {
    this.hasSignatureCardReadPermission = Object.keys(this.authentication.permissions).includes('PRD_SIGNATURE_CARD_READ');

    this.blockSignatureCardPreview = systemPropertyService.getProperty('BLOCK_SIGNATURE_CARD_PREVIEW_WHEN_ACCOUNT_INACTIVE') === 'TRUE';

    // when we don't want to display element, hide it completely from dom
    // so that it wont occupy parent space
    if(!this.hasPermission()) {
      this.$element.addClass('hide');
    }
  }

  hasPermission(): boolean {
    return this.hasSignatureCardReadPermission;
  }

  async showSignature(): Promise<void> {
    this.signature = null;
    if (this.fileId) {
      try {
        const blob = await this.fileService.downloadFile(this.fileId, false).toPromise();
        this.signature = window.URL.createObjectURL(blob);
        this.signatureType = blob.type === 'application/pdf' ? 'PDF' : 'IMAGE';
        this.showPopup = true;
      } catch(error) {
        this.notification.show("Error", "Could not show signature");
        this.showPopup = false;
        console.error(error);
      }
    }
  }

  hideSignature(): void {
    this.showPopup = false;
    this.signature = null;
  }

  isAvailable(): boolean {
    if (this.productGroup == null) {
      return !!this.fileId;
    }

    switch (this.productGroup) {
      case 'ACCOUNT':
        return (this.productStatus !== 'INACTIVE' || !this.blockSignatureCardPreview) // to avoid frauds
          && !!this.fileId;
      case 'DEPOSIT':
        // fall through
      default:
        return !!this.fileId
    }
  }
}

nxModule.component('signatureCard', {
  templateUrl,
  bindings: {
    productGroup: '<',
    productStatus: '<',
    fileId: '<',
    useIconButton: '@'
  },
  controller: SignatureCardComponent
});