import nxModule from 'nxModule';

import templateUrl from './start-the-day-progress.template.html';
import {ILocationService} from "angular";
import {WorkingDaysCache} from "../../../service/working-days-cache";
import {BranchService} from "components/service/branch.service";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import Notification from "shared/utils/notification";

interface BatchStepStatus {
  name:          string;
  status:        string;
  startTime:     string;
  endTime:       string;
  running:       boolean;
  executionTime: number;
  finished:      boolean;
}

const START_DAY_REFRESH_INTERVAL = 15 * 1000;
class StartTheDayProgress {
  branchId!: number;
  systemDate!: string;
  stepStatuses!: BatchStepStatus[];
  refreshStatus: boolean = true;

  constructor (private $filter: NxIFilterService,
               private http: HttpService,
               private $location: ILocationService,
               private workingDaysCache: WorkingDaysCache,
               private notification: Notification,
               private branchService: BranchService
               ) { }

  async $onInit(): Promise<void> {
    const branches = await this.branchService.toPromise();
    const currentBranch = branches.find(b => b.id === this.branchId);
    if (!currentBranch) {
      throw new Error(`Cannot find branch (id = ${this.branchId}`);
    }
    this.systemDate = currentBranch.systemDate;

    await this.refreshSteps();
  }

  async refreshSteps(): Promise<void> {
    if(!this.refreshStatus) {
      return;
    }

    try {
      const batchStatus = await this.http.get<{hasPendingJob: boolean, steps: BatchStepStatus[]}>(`/batch-jobs/${this.branchId}/sod-status?systemDate=${this.systemDate}`).toPromise();
      this.stepStatuses = batchStatus.steps;

      const allCompleted = this.stepStatuses.every(step => step.status === 'COMPLETED');
      if(allCompleted) {
        this.workingDaysCache.refetch();
        this.refreshStatus = false;
      }
    } catch (e) {
      console.error('Failed to load batch job steps', e);
      this.notification.show("Error", "Failed to fetch batch process status");
    } finally {
      if(this.refreshStatus) {
        setTimeout(() => this.refreshSteps(), START_DAY_REFRESH_INTERVAL);
      }
    }
  }

  goBack(): void {
    this.$location.path(`/dashboard/miscellaneous-transactions`);
  }

  $onDestroy(): void {
    this.refreshStatus = false;
  }
}

nxModule.component('startTheDayProgress', {
  templateUrl,
  bindings: {
    branchId: '<'
  },
  controller: StartTheDayProgress
});
