import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './edit-collector.template.html';
import Authentication from "shared/utils/authentication";
import {CenterService} from "components/service/center/center.service";
import {UserDetails} from "components/service/users/user.cache";
import {Center} from "components/service/center/center.types";
import {Account} from "components/service/product.types";
import {ILocationService, IScope} from "angular";

class CustomerAccountEditCollector {
  customerId!: number;
  accountId!: number;
  collectors: UserDetails[] = [];
  officers: UserDetails[] = [];
  allCenters: Center[] = [];
  availableCenters: Center[] = [];
  account!: Account;

  centerConfig = {
    placeholder: 'Select center',
    searchField: ['name', 'id'],
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  constructor(readonly userService: any,
              readonly customerCache: any,
              readonly authentication: Authentication,
              readonly centerService: CenterService,
              readonly $scope: IScope,
              readonly $location: ILocationService,
              readonly confirmation: any,
              readonly command: any) {
  }

  async $onInit(): Promise<void> {
    const accounts = await this.customerCache.depositAccounts(this.customerId).toPromise();
    this.account = _.find(accounts, {id: Number(this.accountId)});
    const [officers, collectors] = await Promise.all([this.userService.getLoanOfficersAssignedToCurrentUserBranches(this.account.collectorId), this.userService.getCollectorsAssignedToCurrentUserBranches(this.account.collectorId)])
    this.allCenters = await this.centerService.fetchCenters({});
    this.updateCenters();

    const branchId = this.account.branchId || this.authentication.context.branchId;
    this.collectors = collectors.filter((user: UserDetails) => user.branchIds.includes(branchId));
    this.officers = officers.filter((user: UserDetails) => user.branchIds.includes(branchId));
  }

  updateCenters(): void {
    if (!this.account.collectorId && !this.account.officerId) {
      this.availableCenters = [];
      this.account.centerId = null;
      return;
    }
    this.availableCenters = this.allCenters
      .filter(c => [c.loanOfficerId, c.loanCollectorId].includes(this.account.collectorId) || [c.loanOfficerId, c.loanCollectorId].includes(this.account.officerId));
    if (!_.find(this.availableCenters, {id: Number(this.account.centerId)})) {
      // The currently selected center is no longer one of the available options (due to a change in selected officer
      // or collector user) - clear center selection
      this.account.centerId = null;
    }
  }

  redirect(): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}`);
  }

  async redirectBack(): Promise<void> {
    const confirmed = await this.confirmation('Do you want to cancel? Canceling will discard all changes.');
    if (confirmed) {
      this.redirect();
    }
  }

  async update(): Promise<void> {
    const confirmed = await this.confirmation(`Do you want to update the "${this.account.productNumber}" account?`);

    if (!confirmed) {
      return;
    }

    const commandInput = {
      accountId: this.account.id,
      collectorId: this.account.collectorId,
      officerId: this.account.officerId,
      centerId: this.account.centerId
    };

    const response = await this.command.execute('UpdateAccountCollector', commandInput, {nxLoaderText: 'Processing operation...'}).toPromise();
    if (response && !response.approvalRequired) {
      this.customerCache.depositAccounts(this.customerId).refetch();
      this.redirect();
    }
  }
}

nxModule.component('customerAccountEditCollector', {
  templateUrl,
  controller: CustomerAccountEditCollector,
  bindings: {
    customerId: '<',
    accountId: '<'
  }
});