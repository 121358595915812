import angular from 'angular';
import stringify from 'json-stable-stringify';
import LocalCache, {storagePrefix} from 'shared/utils/localCache';


/**
 * A wrapper around LocalCache that allows you to specify a param.
 * The response for each param is kept separately under different cache keys.
 */
export default class ParameterizedLocalCache<ProviderResponse, Output> {
  private cacheMap : Record<any, LocalCache<any, any>>;
  private readonly args: any;

  constructor(options: any) {
    this.cacheMap = {};
    this.args = options;
  }

  withParam(param?: any): LocalCache<ProviderResponse, Output> {
    const args = angular.copy(this.args);
    const stringParam = stringify(param, {
      replacer: (key: any, value: any) => {
        return typeof value === 'number' ? value.toString() : value;
      }
    });

    // wrap provider
    const originalProvider = args.provider;
    args.provider = () => originalProvider(param);
    args.cacheKey = 'key-' + stringParam;

    let localCache  = this.cacheMap[stringParam];
    if(!localCache) {
      localCache = new LocalCache<ProviderResponse, Output>(args);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      localCache.param = param;

      this.cacheMap[stringParam] = localCache;
    }
    return localCache;
  }

  evict() {
    const keys = Object.keys(this.cacheMap);
    for (const key of keys) {
      this.cacheMap[key].evict();
    }

    // evict cache from local storage in case cache reference is lost
    const cacheName = this.args.cacheName;
    const cacheKeyPrefix = `${storagePrefix}${cacheName}`;

    for(const key of Object.keys(localStorage)) {
      if (key.startsWith(cacheKeyPrefix)) {
        console.log('Evicting cache for', key);
        localStorage.removeItem(key);
      }
    }
  }

}
