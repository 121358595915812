import nxModule from 'nxModule';

import templateUrl from './agents-branches.template.html';

class AgentsBranchesComponent {
  constructor(private $location : any) { }

  batchUpload() {
    this.$location.path('/agent-banking/batch-upload');
  }
}

nxModule.component('agentsBranches', {
  templateUrl,
  controller: AgentsBranchesComponent
});
