import accessRuleService from 'access/accessRuleService';
import angular, {
  IAngularEvent,
  ICompileProvider,
  IControllerProvider,
  IHttpProvider,
  ILocationProvider,
  IPromise,
  IQService,
  IRootScopeService,
  IScope
} from 'angular';
import ngAnimate, {IAnimateProvider} from 'angular-animate';
import _ from 'lodash';
import ngRoute from 'angular-route';
import ngSanitize from 'angular-sanitize'
import ngCurrency from 'ng-currency';
import moment from 'moment';
import '@nextbank/angular-selectize2';
import {ngTableModule} from 'ng-table';
import routesInit from './routes/app.routes'
import ngBreadcrumbs from 'angular-breadcrumbs';
import angularCache from 'angular-cache';
import angularMoment from 'angular-moment';
import ngFileUpload from 'ng-file-upload';
import uiMask from 'angular-ui-mask';
import dragula from 'angularjs-dragula';
import 'angular-moment-picker';
import uiValidate from 'angular-ui-validate';
import ngInfiniteScroll from 'ng-infinite-scroll';
import checklistModel from 'checklist-model';
import angularGoogleAnalytics from 'angular-google-analytics';
import initializeReact from '../react/initializeReactjs';
import systemPropertyService from "../react/system/systemPropertyService";
import $ from 'jquery';

import 'angular-moment-picker/dist/angular-moment-picker.css';
import currentBranchService from "../react/management/currentBranchService";
import tokenStore from "../react/authentication/tokenStore";
import {TokenDetails} from "../react/authentication/TokenTypes";
import LocationUtil, {RedirectInput} from "../react/tools/LocationUtil";
import promptStore from "form/nxProptStore";
import commandAccessService from "command/commandAccessService";
import {IMomentPickerProvider} from "angular-momentPicker";
import {NxRouteService} from "routes/NxRouteService";
import config from "config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.nx = {};
try {
  tokenStore.loadToken();
} catch (e) {
  console.error('Error reading token', e);
  const locationUtil = new LocationUtil();
  const currentLocation = locationUtil.getCurrentRoutePath();
  const options: RedirectInput = {};
  if(currentLocation) {
    options['target'] = currentLocation;
  }

  locationUtil.redirectToLoginPage(options);
  throw e;
}

/**
 * This file defines the module and configures it at a "pre-construct" stage.
 * All post-construct initialization should happen in the components (preferred) or app.js (if there's no other way)
 */
// TODO: Extract the routes into app.routes.js as in: http://stackoverflow.com/questions/34913594/angularjs-app-module-vs-app-route
// TODO: switch to angular-ui-router
const nxModule = angular.module('nextbank', [
  ngRoute, ngSanitize, 'selectize', 'angular-table', ngCurrency, ngTableModule.name,
  ngBreadcrumbs, angularCache, angularMoment, uiMask, dragula(angular), ngFileUpload,
  'moment-picker', uiValidate, ngAnimate, ngInfiniteScroll, checklistModel,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  angularGoogleAnalytics
])
  .config(($controllerProvider: IControllerProvider, $locationProvider: ILocationProvider, $routeProvider: angular.route.IRouteProvider, $httpProvider: IHttpProvider) => {
    // TODO allow global controller variables. Eventually this should be changed:
    // TODO https://docs.angularjs.org/guide/migration#migrating-from-1-2-to-1-3
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $controllerProvider.allowGlobals();

    // routing
    $locationProvider.hashPrefix('!');
    routesInit($routeProvider);
    configureHttpInterceptors($httpProvider);
    $httpProvider.interceptors.push('httpLoadingInterceptor');
    $httpProvider.interceptors.push('cmcHeaderInterceptor');
    $httpProvider.interceptors.push('originHeaderInterceptor');
  }).config((momentPickerProvider: IMomentPickerProvider) => {
      momentPickerProvider.options({
          format:        'LL',
          minView:       'year',
          maxView:       'day',
          startView:     'day',
          autoclose:     true,
          today:         false,
          keyboard:      true,
          leftArrow:     '&larr;',
          rightArrow:    '&rarr;',
          yearsFormat:   'YYYY',
          monthsFormat:  'MMM',
          daysFormat:    'D',
      });
  }).config(($animateProvider: IAnimateProvider) => {
    $animateProvider.classNameFilter(/angular-animate/);
  }).config(($compileProvider: ICompileProvider) => {
    $compileProvider.preAssignBindingsEnabled(true);
    if(process.env.NODE_ENV === 'production') {
      $compileProvider.debugInfoEnabled(false);
    }
  })
  .config((AnalyticsProvider: angular.google.analytics.AnalyticsProvider) => {
    AnalyticsProvider.delayScriptTag(true);
    AnalyticsProvider.trackPages(true);
    AnalyticsProvider.setAccount({
      tracker: config.googleAnalyticsAccount,
      fields: {
        siteSpeedSampleRate: 100
      }
    });
  })
  .run(($rootScope: IScope, config: Record<string, unknown>, customerCache: any) => {

    // when we want to block navigating away, routers first change url and then display a confirmation popup
    // as a consequence, when we navigate away in react, angularjs already renders a page
    // To block it, we need to synchronize both routers
    $rootScope.$on('$routeChangeStart', (evt: IAngularEvent, next: any, current: any) => {
      //next route does not have $$route info - so it's a react page.
      //we clear the cache to prevent any caching issues when react pages do any changes
      if(!next.$$route && customerCache.loadedCustomerId) {
        customerCache.loans(customerCache.loadedCustomerId).evict();
        customerCache.depositAccounts(customerCache.loadedCustomerId).evict();
      }

      if(!promptStore.isBlocking()) {
        return;
      }

      const confirmed = promptStore.presentChallenge();
      if(!confirmed) {
        evt.preventDefault();
      }
    });

    //clear the customer cache whenever the user navigates away from the /customer/x/loans page
    //to prevent any caching issues
    $rootScope.$on('$locationChangeStart', function(event, next, previous) {
      const loanRegex = /\/customer\/([0-9]+)\/loans/;
      const previousUrl = previous.match(loanRegex);
      const nextUrl = next.match(loanRegex);
      const leftLoansPages = previousUrl && (!nextUrl || previousUrl[1] !== nextUrl[1]);

      if (customerCache.loadedCustomerId && leftLoansPages) {
        customerCache.loans(customerCache.loadedCustomerId).evict();
      }
    });

    if (config.devProfile) {
      $rootScope.$on('$routeChangeError', (evt: IAngularEvent, currentPath, previousPath, error) => {
        console.warn('Route change error on path:', JSON.stringify(currentPath), 'error:', error);
      });
    }
  })
  .run(($route: NxRouteService,  $rootScope: IRootScopeService) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.nx.$route = $route;
    window.nx.$rootScope = $rootScope;
  })
  .run(($q: IQService) => {
    // use angularjs promises to get change detection
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Promise = $q;
    // To work around change detection and use async/await in the past we needed to replace default implementation of
    // promises with $q. As a result, when promise api gets new methods, we need to create polyfills
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    $q.allSettled = (promises: Promise<unknown>[]): IPromise<{status: string, value?: unknown, reason?: unknown}[]> => {
      const nonFailingPromises= promises.map(p => p
        .then(v => ({
          status: 'fulfilled',
          value: v,
        }), e => ({
          status: 'rejected',
          reason: e,
        }))
      );

      return  $q.all(nonFailingPromises);
    };
  })
  .run(() => {
    initializeReact();
  });

/**
 * HTTP interceptor: Goes through all http requests that are being sent and converts dates to their local timezones.
 * Example: 2017-03-20T23:00:00.000Z -> 2017-03-21T00:00:00+01:00
 *
 * Both formats are correct, but the backend would parse the first one as 2017-03-20.
 * Therefore the 2nd format must be used.
 */
function configureHttpInterceptors($httpProvider: IHttpProvider) {
  $httpProvider.interceptors.push(() => {
      return {
        request: function (request) {
          const convertDates = (obj: Record<string, unknown>): unknown[] => {
            let key, type, value;
            const results = [];
            for (key in obj) {
              value = obj[key];
              type = typeof value;
              if (value instanceof Date && _.get(Object.getOwnPropertyDescriptor(obj, key), 'writable')) {
                const momentDate = moment(value.getTime());
                results.push(obj[key] = momentDate.format());
              } else if (type === 'object') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                results.push(convertDates(value));
              } else {
                results.push(void 0);
              }
            }
            return results;
          };
          convertDates(request.data);
          return request;
        }
      };
    });

  $httpProvider.interceptors.push(() => {
      return {
        request: (request: any) => {
          const branchId: number | null = currentBranchService.getBranchId();
          const tokenDetails: TokenDetails = tokenStore.getTokenDetails();
          return {
            ...request,
            headers: {
              ...request.headers,
              'Authorization': 'Bearer ' + tokenDetails.token,
              ...(
                tokenDetails.userBranchId !== branchId ? {
                  'NX-BRANCH': branchId
                } : {}
              )
            }
          };
        }
      };
    }
  );
}

Promise.all([
  systemPropertyService.init(),
  commandAccessService.init(),
  accessRuleService.init()
])
  .then(loaded => {
    $(() => {
      console.log('Initializing angularjs');
      try {
        angular.bootstrap(document, ['nextbank'], {
          strictDi: true
        });
      } catch (e) {
        console.error('Failed to load angular', e);
        const error = encodeURIComponent('Unknown system error');
        window.location.href = `/login?error=${error}`;
      }
    });
  }, err => {
    console.error('Failed to read system properties', err);
    const error = encodeURIComponent('Failed to load configuration');
    window.location.href = `/login?error=${error}`;
  });

export default nxModule;
