import nxModule from 'nxModule';

import _ from 'lodash';
import {NgTableParams} from 'ng-table';
import {IComponentController} from "angular";
import {NxIFilterService} from "../../../technical/angular-filters";

import {AccountWithLabel, addAccountLabels} from "../../../general-ledger/common/gl.utils";
import {MiscTransactionMappingService, MiscTransactionTemplateUnit} from "./misc-transaction-mapping.service";
import GlMappingsService from "../../gl-mappings/gl-mappings.service";

import templateUrl from './misc-transaction-mapping.template.html';
import {CommandService} from "shared/utils/command/command.types";

interface Filter {
  templateName: string
}

interface MiscTransactionTemplateUnitRow extends MiscTransactionTemplateUnit {
  delegate: boolean;
}

class MiscTransactionMapping implements IComponentController {
  private filter: Filter = {templateName: ''};
  private tableConfig: NgTableParams<MiscTransactionTemplateUnitRow> = new NgTableParams({}, {});

  private readonly selectConfig = {
    placeholder: 'Select tag type',
    searchField: ['label'],
    valueField: 'type',
    labelField: 'label',
    maxItems: 1
  };

  private readonly emptyAccountConfig = {
    placeholder: 'Select account'
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private tags: { label: any; type: any }[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private ledgerAccounts: AccountWithLabel[];


  constructor(private readonly miscTransactionMappingService: MiscTransactionMappingService,
              private readonly $filter: NxIFilterService,
              private readonly glMappingsService: GlMappingsService,
              private readonly command: CommandService) {

  }

  async $onInit(): Promise<void> {
    const [units, tags, accounts] = await Promise.all([
      this.miscTransactionMappingService.getTransactionUnits(),
      this.miscTransactionMappingService.getLedgerTags(),
      this.glMappingsService.accounts.toPromise()]);

    this.tableConfig = new NgTableParams({
      page: 1,
      count: 10,
      filter: this.filter,
    }, {
      counts: [],
      dataset: units.map(unit => ({
        delegate: !unit.accountFullCode,
        ...unit
      }))
    });

    const supportedTags = tags.filter(tag => {
      const { params } = tag;
      if(!params) {
        return true;
      }

      // hardcoded list of values from MiscTransactionTagReader fetchParam
      // if tag uses param outside this list, it won't be resolved properly
      const supportedParams = [
        "USER_ID", "BRANCH_ID", "REFERENCE_BRANCH_ID",
        "ASSET_COUNTER_SUBTYPE_ID", "DEPOSITORY_ACCOUNT_ID", "ATM_ID"
      ];

      for(const paramName of Object.keys(params)) {
        if(!supportedParams.includes(paramName)) {
          return false;
        }
      }

      return true;
    });

    const supportedTagTypes =  supportedTags.map(tag => tag.tagType)
      .filter(tagType => tagType !== 'UNDIVIDED_PROFIT_ACCOUNT');

    this.tags = _.uniq(supportedTagTypes).map(tagType => ({
      type: tagType,
      label: this.$filter('prettyEnum')(tagType)
    }));

    this.ledgerAccounts = addAccountLabels(accounts);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onDelegateChange(unit) {
    unit.accountFullCode = null;
    unit.tagType = unit.delegate ? 'UNMAPPED' : null;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  saveUnit(unit) {
    const input = {
      id: unit.id,
      accountFullCode: unit.accountFullCode,
      tagType: unit.tagType
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.command.execute('UpdateMiscTransactionUnitTemplate', input).toPromise();
  }
}

nxModule.component('miscTransactionMapping', {
  templateUrl,
  controller : MiscTransactionMapping
});
