import nxModule from 'nxModule';
import moment from 'moment';

import templateUrl from './loans-payment-revert-to-gl.template.html';
import {BranchService} from '../../../service/branch.service';
import {Amortization, AmortizationSummary, Loan} from '../../../service/loan.types';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {HttpService} from '../../../../shared/utils/httpService';
import {ILocationService} from 'angular';
import {NxIFilterService} from '../../../technical/angular-filters';
import LocalCache from '../../../../shared/utils/localCache';
import OperationService from '../../../service/operation.service';
import {Branch} from "management/BranchTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {RevertCommandService} from 'components/technical/revert-command/revert-command.service';
import {Operation} from "components/service/operation.types";
import Authentication from "shared/utils/authentication";

class LoansPaymentRevertToGl {

  protected paidAmortizations: Amortization[] = [];
  protected paidAmortizationsSummary?: AmortizationSummary;
  protected customerId!: number;
  protected loan!: Loan;
  protected operationId!: number;
  protected operation!: Operation & {categoryId?: number};
  protected simulationError?: string;
  private remarks?: string | null;
  private commandName: string = '';
  private lineNumbers: number[] = [];
  private actionCategories: ActionCategory[] = [];
  private branches: Branch[] = [];
  private branch?: Branch;

  constructor(private http: HttpService,
              private $location: ILocationService,
              private command: CommandService,
              private branchService: BranchService,
              private customerCache: CustomerCache,
              private confirmationTemplate: ConfirmationTemplate,
              private operationService: OperationService,
              private revertCommandService: RevertCommandService,
              private authentication: Authentication,
              private $filter: NxIFilterService,
              private actionCategoryCache: LocalCache<ActionCategory[], ActionCategory[]>) {
  }

  async $onInit(): Promise<void> {
    const [operation, actionCategories, branches] = await Promise.all([
      this.operationService.fetchOperationDetails(this.operationId).toPromise(),
      this.actionCategoryCache.toPromise(),
      this.branchService.toPromise()
    ]);

    this.operation = operation;
    this.actionCategories = actionCategories;
    this.branches = branches;

    this.lineNumbers = operation.attributes.AMORTIZATION_LINE_NUMBERS.split(',')
      .map((number: string) => parseInt(number));

    try {
      this.paidAmortizations = await this.simulateRevert();
    } catch (ex) {
      this.simulationError = ex.errorMessage;
    }

    this.commandName = this.revertCommandService.revertCommandName(this.operation, 'LOAN_CUSTOM_GL_REVERT');
    this.branch = this.branches.find((b: Branch) => b.id === this.loan.branchId);
  }

  simulateRevert(): Promise<Amortization[]> {
    const request = {
      loanId: this.loan.id,
      operationId: this.operation.id,
      lineNumbers: this.lineNumbers
    };
    return this.http.post<Amortization[]>(`/products/loans/revert-simulation`, request).toPromise();
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  async revert(): Promise<void> {
    if(!this.paidAmortizationsSummary) {
      return;
    }

    const category = this.actionCategories.find(cat => cat.id === this.operation.categoryId);
    const accountCode = category?.ledgerAccountFullCodes[0];

    const confirmed = await this.confirmationTemplate({
      question: 'Are you sure you want to proceed with the payment revert to GL?',
      details: [
        {label: ' Product number', description: this.loan.productNumber},
        {label: ' Date of payment', description: moment(this.operation.processedOn).format('MM/DD/YYYY')},
        {label: ' Line numbers', description: this.lineNumbers.map(line => String(line)).join(', ')},
        {label: ' Principal', description: this.paidAmortizationsSummary.principal},
        {label: ' Interest', description: this.paidAmortizationsSummary.interest},
        {label: ' CBU', description: this.paidAmortizationsSummary.cbuCharge},
        {label: ' PF', description: this.paidAmortizationsSummary.pfCharge},
        {label: ' TP', description: this.paidAmortizationsSummary.tpCharge},
        {label: ' PDI', description: this.paidAmortizationsSummary.pastDueInterest},
        {label: ' PDI maturity', description: this.paidAmortizationsSummary.pastDueMaturityInterest},
        {label: ' Penalty', description: this.paidAmortizationsSummary.penalty},
        {label: ' Penalty Maturity', description: this.paidAmortizationsSummary.penaltyMaturity},
        {label: ' Total', description: this.paidAmortizationsSummary.total},
        {label: ' Official Receipt', description: this.operation.attributes.OFFICIAL_RECEIPT},
        {label: ' Revert payment to', description: accountCode ?? ''}
      ],
      warning: 'This operation cannot be reverted safely. Please make sure that the data is correct. '
    });

    if (confirmed) {
      const response = await this.command.execute(this.commandName, {
        operationId: this.operation.id,
        commandId: this.operation.commandId,
        categoryId: this.operation.categoryId,
        remarks: this.remarks
      }).toPromise();

      if (!response.approvalRequired) {
        this.customerCache.loans(this.customerId).refetch();
        this.customerCache.loanPDCs(this.customerId, this.loan.id).refetch();
        this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.customerId).refetch() : Promise.resolve();
        this.customerCache.depositAccounts(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }
}

nxModule.component('loansPaymentRevertToGl', {
  templateUrl,
  bindings: {
    customerId: '<',
    loan: '<',
    operationId: '<'
  },
  controller: LoansPaymentRevertToGl
});
