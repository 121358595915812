import nxModule from 'nxModule';

import templateUrl from './password-expiration-warning.template.html';
import moment from "moment";

import systemPropertyService from '../../../../react/system/systemPropertyService';

class PasswordExpirationWarning {

  constructor($location, authentication, userCache, systemDateService) {
    this.$location = $location;
    this.authentication = authentication;
    this.userCache = userCache;
    this.systemDateService = systemDateService;
  }

  async $onInit() {
    const daysBeforeWarning = systemPropertyService.getProperty('PASSWORD_EXPIRATION_WARNING_NR_OF_DAYS');

    if (Number(daysBeforeWarning) > 0) {
      const expirationNumberOfDays = systemPropertyService.getProperty('EXPIRE_PASSWORD_AFTER_NR_OF_DAYS');

      const users = await this.userCache.withParam().toPromise();
      this.user = users.find(user => user.id === this.authentication.context.id);

      const now = moment();
      const lastModificationDate = moment(this.user.lastPasswordModificationDate);

      this.expirationDate = lastModificationDate.add(Number(expirationNumberOfDays), 'days');
      const startWarningDate = moment(this.expirationDate).subtract(Number(daysBeforeWarning), 'days');
      this.warnPasswordExpiration = !startWarningDate.isAfter(now);
    }
  }

}

nxModule.component('passwordExpirationWarning', {
  templateUrl,
  controller: PasswordExpirationWarning
});
