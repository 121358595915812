import nxModule from 'nxModule';
import _ from 'lodash';
import moment from "moment";

const templateUrl = require('./depository-account-list.template.html');

nxModule.component('depositoryAccountList', {
  templateUrl,
  controller: function ($location, $filter, depositoryAccountCache, confirmationTemplate,
                        command, branchService, authentication) {

    const that = this;
    that.accounts = [];

    const s1 = depositoryAccountCache.toObservable().combineLatest(branchService.toObservable(), (accounts, branches) => {
      const branchesOfUser = authentication.context.branchIds;
      that.accounts = accounts.filter( account => { return branchesOfUser.includes(account.branchId); });
      that.accounts.map(account => {
        account.branchName = branches.find( branch => {return branch.id === account.branchId}).name;
      });
    }).subscribe();

    that.update = (id) => $location.path(`/admin/organization/depository-accounts/${id}/update`);

    that.delete = (id) => {
      const account = _.find(that.accounts, {id: id});
      confirmationTemplate({
        question: `Do you want to remove depository account?`,
        warning: 'This operation cannot be reverted.<br>Please make sure that the account data is correct.',
        details: [
          {label: 'Account name', description: account.accountName},
          {label: 'Account type', description: $filter('prettyEnum')(account.accountType)}
        ],
        yesCallback: () => command.execute('DeleteDepositoryAccount', {id: id}).success(() => depositoryAccountCache.refetch())
      });
    };

    that.calculateMaturityDate = account => {
      if (['TERM_DEPOSIT'].includes(account.accountType)) {
        return moment(account.dateOpened).add(account.term, 'days').toDate();
      }
      return null;
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
