import nxModule from 'nxModule';

class AccountGroupService {

  constructor(userCache, http) {
    this.userCache = userCache;
    this.http = http;
  }

  getAccountGroup(id) {
    return this.http.get(`/account-groups/${id}`).toPromise();
  }

  /**
   * Completes the AccountGroup data by
   * adding the the effective name of the user who added an account to the group
   * and the accountName, which is the name of the customer who owns the account
   * */
  async getCompleteAccountGroupDetails(accountGroup) {
    if (accountGroup.accounts) {
      const [members, users] = await Promise.all([
        this.http.get(`/account-groups/group-customers?groupId=${[accountGroup.id]}`).toPromise(),
        this.userCache.withParam(true).toPromise()
      ]);

      accountGroup.accounts = accountGroup.accounts.map(a => {
        const owner = members.find(m => m.id === a.customerId);
        a.addedByUser = users.find(u => u.id === a.addedToGroupBy).effectiveName;
        a.accountName = owner.effectiveName;
        return a;
      });
    }

    return accountGroup;
  }

  fetchAccountGroupsByCriteria({pageNo, pageSize, typeId, groupName}) {
    return this.http.get(`/account-groups`, {
      params: {
        pageNo,
        pageSize,
        typeId,
        groupName
      }
    }).toPromise();
  }
}

nxModule.service('accountGroupService', AccountGroupService);