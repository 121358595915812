import React, {ReactElement} from 'react';
import SelectSubtreeView, {SelectSubtreeTreeListProps} from 'tree/SelectSubtreeView';
import TreeNodeListWrapper from 'tree/TreeNodeListWrapper';

const SelectSubtreeList = function<VALUE>({mapping, treeNodes, disabled, TreeNodeView, variant, onClick}: SelectSubtreeTreeListProps<VALUE>): ReactElement {
  const nodes = treeNodes.map(treeNode => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <SelectSubtreeView<VALUE> key={treeNode.id}
                                     treeNode={treeNode}
                                     disabled={disabled}
                                     mapping={mapping}
                                     TreeNodeView={TreeNodeView}
                                     variant={variant}
                                     onClick={onClick}/>
  })
  return <TreeNodeListWrapper>
    {nodes}
  </TreeNodeListWrapper>;
}

export default SelectSubtreeList;