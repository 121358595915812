import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';

import "rxjs/add/observable/zip";

const templateUrl = require('./create-lock.template.html');

class CreateProductLock {
  constructor($route, $location, $filter, confirmationTemplate, customerCache, branchService, authentication, productLockService, systemDateService, productLockCache) {

    this.$location = $location;
    this.$filter = $filter;
    this.confirmationTemplate = confirmationTemplate;
    this.customerCache = customerCache;
    this.productLockService = productLockService;
    this.productLockCache = productLockCache;
    this.lock = { autoRelease: false };
    // TODO: Remove comment signs when freeze functionality will be implemented.
    this.lockTypes = [
      { label: 'Blockade', type: 'BLOCKADE' },
      // {label: 'Freeze', type: 'FREEZE'}
    ];

    this.accountId = $route.current.params['accountId'];
    this.depositId = $route.current.params['depositId'];
    this.customerId = $route.current.params['customerId'];
    this.productId = this.accountId || this.depositId;
    this.systemDateService = systemDateService;
  }
  
  async $onInit() {
    const fetchProducts = this.accountId ?
    this.customerCache.depositAccounts(this.customerId) :
    this.customerCache.termDeposits(this.customerId);
    const products = await fetchProducts.toPromise();
    this.product = products.find(p => Number(p.id) === Number(this.productId));
    this.systemDate = await this.systemDateService.getSystemDateByBranchId(this.product.branchId);
    this.lock.autoReleaseOn = moment(this.systemDate).add(1, 'days');
  }

  minAutoReleaseDate() {
      return moment(this.systemDate).add(1, 'days').format('YYYY-MM-DD');
  }

  isAutoReleaseDateValid(date) {
    return date && date >= moment(this.systemDate).add(1, 'days');
  }

  create() {
    let data = {
      productId: this.product.id,
      type: this.lock.type,
      creationRemarks: this.lock.creationRemarks
    };

    if (this.lock.autoRelease) {
      data = { autoReleaseOn: moment(this.lock.autoReleaseOn).format('YYYY-MM-DD'), ...data };
    }

    this.productLockService.createLock(this.product, data, async () => {
      this.customerCache.depositAccounts(this.customerId).refetch();
      await this.productLockCache.withParam(this.productId).refetch();
      this.redirectBack();
    });
  }

  redirectBack() {
    const back = this.accountId ? 'accounts' : 'term-deposits';
    this.$location.path(`/customer/${this.customerId}/${back}/${this.productId}`);
  }
}

nxModule.component('createLock', {
  templateUrl,
  controller: CreateProductLock
});
