import nxModule from 'nxModule';

import templateUrl from './grouped-role-write-access-list.template.html';
import _ from 'lodash';
import {
  AccessRule,
  AccessRuleCache,
  AccessRuleDefinition,
  AccessRuleInput,
  CommandAccessRuleDefinition,
  CommandGroup,
  ExecuteOption,
  EXISTING_RULE_EXECUTE_OPTIONS,
  NEW_RULE_EXECUTE_OPTIONS
} from "../../../../service/access/access-rule.types";
import './grouped-role-write-access-list.style.less';

import {NgTableParams} from "ng-table";
import {AccessRuleService} from "../../../../service/access/access-rule.service";
import {setBreadcrumbs} from "../../../../../shared/utils/set-breadcrumbs";
import {ILocationService} from "angular";
import Notification from "shared/utils/notification";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";
import {Confirmation} from "shared/common/confirmation.types";


interface AccessRuleFilter {
  displayName: string;
}

class GroupedRoleWriteAccessList {

  //bindings
  private readonly roleId!: number;
  private readonly commandGroup!: CommandGroup;
  private readonly accessRulesDefinition!: CommandAccessRuleDefinition[];

  private tableConfig!: NgTableParams<unknown>;
  private commandFilter: AccessRuleFilter = {displayName: ''};

  constructor(private http: HttpService,
              private breadcrumbs: unknown,
              private confirmation: Confirmation,
              private $route: NxRouteService,
              private $location: ILocationService,
              private accessRuleService: AccessRuleService,
              private accessRuleCache: AccessRuleCache,
              private $filter: NxIFilterService,
              private popup: Popup,
              private notification: Notification) {
  }

  async $onInit(): Promise<void> {
    setBreadcrumbs(
      this.breadcrumbs,
      'grouped-access-rules-label',
      this.$filter('translateEnum')(this.commandGroup, 'LABEL')
    );

    this.initTable();
  }

  async initTable(): Promise<void> {
    this.tableConfig = new NgTableParams<unknown>({
      count: 10,
      filter: this.commandFilter
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      dataset: await this.fetchData()
    })
  }

  private async fetchData(): Promise<AccessRule[]> {
    return await this.accessRuleService.getAccessRulesByRoleAndCommandGroup(this.roleId, this.commandGroup);
  }

  getExecuteOptions(accessRule: AccessRule): ReadonlyArray<ExecuteOption> {
    return accessRule.id ? EXISTING_RULE_EXECUTE_OPTIONS : NEW_RULE_EXECUTE_OPTIONS;
  }


  editAccessRule(accessRule: AccessRule): void {
    this.$location.path(`/admin/security/roles/${this.roleId}/access-rules/${this.commandGroup}/${accessRule.id}`);
  }

  addAccessRule(): void {
    this.$location.path(`/admin/security/roles/${this.roleId}/access-rules/${this.commandGroup}/new`);
  }

  async removeAccessRule(accessRule: AccessRule): Promise<void> {
    const proceed = await this.confirmation(`Are you sure you want to delete access rights ${accessRule.displayName}?`);

    if (!proceed) return;

    const request: AccessRuleInput = {
      deleteRuleIds: [accessRule.id],
      accessRules: []
    };

    const response = await this.http.post<{errorMessage?: string}>('/access', request).toPromise();
    if (response.errorMessage) {
      this.popup({text: response.errorMessage});
    } else {
      this.notification.show("Access rule successfully removed.");
      this.accessRuleCache.evict();
      this.initTable();
    }
  }

  getAccessRuleDefinition(command: string): AccessRuleDefinition[] {
    const commandAccessRuleDefinition = _.first(this.accessRulesDefinition.filter(ar => ar.command === command)) as CommandAccessRuleDefinition;
    return commandAccessRuleDefinition.ruleDefinitions as AccessRuleDefinition[];
  }

}

nxModule.component('groupedRoleWriteAccessList', {
  templateUrl,
  controller: GroupedRoleWriteAccessList,
  bindings: {
    roleId: '<',
    commandGroup: '<',
    accessRulesDefinition: '<'
  }
});
