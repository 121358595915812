import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';
import templateUrl from './check-transfer-to-cashier.template.html';

class CheckTransferToCashier {
  constructor(authentication, actionCommand, userCache, ledgerActionTemplateCache, miscTransactionsService, commandAccessChecker) {
    this.authentication = authentication;
    this.branchId = authentication.context.branchId;
    this.userId = authentication.context.id;
    this.userCache = userCache;
    this.ledgerActionTemplateCache = ledgerActionTemplateCache;
    this.miscTransactionsService = miscTransactionsService;
    this.actionCommand = actionCommand;
    this.commandAccessChecker = commandAccessChecker;
    this.approvalUsers = [];
  }

  async $onInit() {
    this.initialize();
    this.approvalUsers = await this.getApprovalUsers();
  }

  initialize() {
    this.transaction = {
      amount: 0,
      userId: this.userId,
      branchId: this.branchId,
      approvalUserId: null,
      checkIds: []
    };
    this.submitting = false;
  }

  async getApprovalUsers() {
    const usersCache = this.userCache.withParam().toPromise();
    const templateCache = this.ledgerActionTemplateCache.toPromise();

    const [allUsers, templates] = await Promise.all([usersCache, templateCache]);
    const template = templates.find(temp => temp.type === 'BATCH_CHECK_TRANSFER_TO_CASHIER');
    const users = allUsers.filter(user => user.id !== this.authentication.context.id);

    return await this.miscTransactionsService.filterApprovalUsersForAction(template, users, this.branchId);
  }

  onCheckChange(checks) {
    this.updateTransaction(checks);
  }

  updateTransaction(checks) {
    let amount = new BigNumber(0);
    const checkIds = [];

    checks.forEach(check => {
      amount = amount.plus(check.amount);
      checkIds.push(check.id);
    });

    this.transaction.amount = amount.toNumber();
    this.transaction.checkIds = checkIds;
  }

  cancelChanges() {
    this.actionCommand.cancelChanges();
  }

  async save() {
    this.submitting = true;
    try {
      await this.actionCommand.executeAsync('BATCH_CHECK_TRANSFER_TO_CASHIER', this.transaction);
    } finally {
      this.initialize();
    }
  }
}

nxModule.component('checkTransferToCashier', {
  templateUrl,
  controller: CheckTransferToCashier
});
