import nxModule from 'nxModule';
import templateUrl from './discount-charges-ledger.template.html';
import {DiscountChargesLedger} from 'components/service/loan.types';

class DiscountChargesLedgerComponent {
  discountCharges!: DiscountChargesLedger;

  hasDiscountCharges(): boolean {
    return this.discountCharges?.rows?.length > 0;
  }
}

nxModule.component('discountChargesLedger', {
  templateUrl: templateUrl,
  bindings: {
    discountCharges: '<',
    showStatus: '<'
  },
  controller: DiscountChargesLedgerComponent
});
