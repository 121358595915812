import {ITimeoutService} from 'angular';
import {
  BatchPrintRequest,
  PrintModalInput,
  PrintModalOutput,
  PrintRequest
} from 'components/service/print/modal-print-preview.service';
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {Customer} from 'customer/CustomerTypes';
import $ from 'jquery';
import _ from 'lodash';
import nxModule from 'nxModule';
import PrintService from 'print/PrintService';
import {Print, PrintLine, PrintWrapper} from 'print/PrintType';
import Authentication from 'shared/utils/authentication';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';
import templateUrl from './modal-print-preview.template.html';

interface ModalInput {
  keyboard: boolean;
  backdrop: string;
}

interface Modal {
  modal: (input: ModalInput | string) => {}
}

const reactPrintService = new PrintService();
class ModalPrintPreview {
  input!: PrintModalInput[];
  onClose!: (output: PrintModalOutput) => {};
  printCode!: string;
  printName!: string;
  printDefinition!: Print;
  customVariables: PrintLine[] = [];
  customVariableInput: any = {};
  signatories: UserDetails[] = [];
  displayPrintInput: boolean = false;
  printed: boolean = false;
  printData?: PrintWrapper;

  constructor(private $timeout: ITimeoutService,
              private printService: any,
              private http: HttpService,
              private authentication: Authentication,
              private userCache: UserCache,
              private notification: Notification) {
  }

  async $onInit(): Promise<void> {
    (<JQuery<HTMLElement> & Modal>$('#printPreview')).modal({
      keyboard: false,
      backdrop: 'static'
    });

    this.printDefinition = await reactPrintService.getPrint({
      printCode: this.printCode,
      printParameters: this.input[0].parameters
    });

    //if request contains more than 1 print definition - do not require custom variable input
    if(this.input.length === 1) {
      const allUsers = await this.userCache.withParam().toPromise();
      this.customVariables = this.printDefinition.lines.filter(l => l.type === 'CUSTOM');
      this.signatories = allUsers
        .filter((user: UserDetails) => !user.technical && user.enabled)
        .filter((user : UserDetails) => user.branchId === this.authentication.context.branchId);
      this.displayPrintInput = this.customVariables.length > 0;
    }

    if(!this.displayPrintInput) {
      this.generatePreview();
    }
  }

  selectCustomer(customer: Customer, printLine: PrintLine): void {
    this.customVariableInput[printLine.field] = {
      customerId: customer.id,
      customerName: customer.effectiveName
    };
  }

  close(): void {
    (<JQuery<HTMLElement> & Modal>$('#printPreview')).modal('hide');
    this.onClose({printed: this.printed, printResult: this.printData});
  }

  async generatePreview(): Promise<void> {
    const printResult = await this.getPrintData();

    if(printResult) {
      this.printData = printResult;
      this.printService.printToContainer(this.printData?.prints, {
        autoPrint: false,
        backgroundEnabled: true,
        container: $("#prev")
      });
    }
  }

  async print(): Promise<void> {
    this.printService.printToContainer(this.printData?.prints, {
      autoPrint: true,
      backgroundEnabled: false,
      container: $("#autoprint")
    });
    this.printed = true;

    if(this.input.length > 0) {
      await reactPrintService.handlePassbook(this.printCode, this.input[0].inputs);
    }
  }

  private async getPrintData(): Promise<PrintWrapper | undefined> {
    const printRequests: PrintRequest[] = this.input.map(input => ({
      ...input
    }));

    const printRequest: BatchPrintRequest = {
      printRequests: printRequests
    };

    this.populateCustomVariableInput(printRequests);

    try {
      const result = <PrintWrapper> await this.http.post(`/print/${this.printCode}/json`, printRequest).toPromise();
      this.displayPrintInput = false;

      if (_.uniq(result.prints.map(d => d.type)).length > 1) {
        console.error('Unable to generate prints with different print type');
        throw new Error('Unable to generate prints with different print type');
      }
      return result;
    } catch (e: any) {
      this.notification.show("Error", e.errorMessage ?? e.message);
      this.close();
    }
  }

  private populateCustomVariableInput(printRequests: PrintRequest[]) : void {
    const printInput: Record<any, any> = {};
    for(const field of Object.keys(this.customVariableInput)) {
      const fieldValue = this.customVariableInput[field];
      printInput[field] = <any> fieldValue.customerId ? fieldValue.customerId : fieldValue;
    }

    printRequests.forEach((p: PrintRequest) => p.customVariables = printInput);
  }
}

nxModule.component('modalPrintPreview', {
  templateUrl: templateUrl,
  bindings: {
    printCode: '<',
    input: '<',
    onClose: '<'
  },
  controller: ModalPrintPreview,
});

