import {map} from 'rxjs/operators';
import _ from 'lodash';
import {CONTINGENT_ACCOUNT_NAME} from "./gl.consts";
import {LedgerAccountGroup} from "../../administration/gl-mappings/gl-mappings.service";
import {Ledger} from "./gl.types";
import {Branch} from "management/BranchTypes";
import {BranchService} from "components/service/branch.service";
import {GlLedgerService} from "components/general-ledger/common/gl-ledger.service.types";

export const mergedLedgers = async ({glLedgerService, branchService}: {glLedgerService: GlLedgerService, branchService: BranchService}) => {
  const [ledgers, branches] = await Promise.all([glLedgerService.toPromise(), branchService.toPromise()]);
  return ledgers.map(ledger => Object.assign(ledger, {
    branch: _.find(branches, branch => branch.id === ledger.branchId)
  }));
}

export const mergedLedger = async ({glLedgerService, branchService, accountId}: {glLedgerService: GlLedgerService, branchService: BranchService, accountId: number}) => {
  const ledgers = await mergedLedgers({glLedgerService, branchService});
  return _.find(ledgers, ledger => ledger.id === accountId);
};

export type FlattenSubAccountsInput<T> = T & {subAccounts: FlattenSubAccountsInput<T>[]};
export const flattenSubAccounts = <T>(roots: FlattenSubAccountsInput<T>[]): T[] =>
  _.flatten(roots.map(root => [root, ...(flattenSubAccounts(root.subAccounts || []))]));

export const defaultHeaderLabels = (ledger: (Ledger & {branch: Branch})) => [{
    label: 'General ledger name',
    text: ledger.template.name,
  },{
    label: 'Branch name',
    text: ledger.branch.name,
  },{
    label: 'Branch code',
    text: ledger.branch.code,
  }];


export interface AccountLike {
  accountGroup: LedgerAccountGroup,
  name: string,
  fullCode: string
}

export interface AccountWithLabel extends AccountLike {
  label: string
}


export const filterNonContingentAccounts = <T extends AccountLike>(glAccounts: T[]): T[] =>
  _.filter(glAccounts, glAccount =>  {
    return ['ASSET', 'LIABILITY', 'INCOME', 'EXPENSE'].includes(glAccount.accountGroup);
  });

/**
 * Enriches GL accounts with selectLabel in format: [AG] name (fullCode)
 */
export const addAccountLabels = (accounts: AccountLike[]): AccountWithLabel[] =>
  accounts.map((a: AccountLike): AccountWithLabel => {
    let s: string = '';
    if (a.accountGroup) s += `[${a.accountGroup.substring(0, 2)}] `;
    s += `${a.name} (${a.fullCode})`;

    return {
      ...a,
      label: s
    };
  });

/**
 * Observable operator for {@link addAccountLabels}.
 */
export const createLabels = map((accounts: AccountLike[]): AccountWithLabel[] => addAccountLabels(accounts));

/**
 * Observable operator for removing Contingent accounts from GL accounts array.
 */
export const filterOutContingencyAccounts = map<any[], any>(accounts => accounts.filter(
  account => account.accountGroup !== CONTINGENT_ACCOUNT_NAME
));
