import {Form, useFormikContext} from 'formik';
import React, {ReactElement, ReactNode} from 'react';
import CommandNavigationBlocker from "../command/CommandNavigationBlocker";

/**
 * Form component automatically blocking navigating away when command from the current form is executed
 */
const NxForm = ({children} : {children: ReactNode | ReactNode[]}): ReactElement => {
  const { isSubmitting } = useFormikContext();
  return <Form>
    <CommandNavigationBlocker block={isSubmitting}>
      {children}
    </CommandNavigationBlocker>
  </Form>;
};

export default NxForm;