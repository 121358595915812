import './lookup-helper.service'
import $ from 'jquery';
import {NgTableParams} from 'ng-table';

import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from '../../../../react/system/systemPropertyService';

import './lookup-customer.style.less';

const templateUrl = require('./lookup-customer.template.html');
nxModule.component('lookupCustomer', {
  templateUrl: templateUrl,
  controller: function (http, $timeout, $location, $filter, authentication, popup,
                        branchService, branchWorkingDayCache, requestFormatter,
                        lookupHelper, propertyConfigService) {
    let that = this;

    that.defaultUrl = '/customer/{}/accounts';
    that.profileUrl = '/customer/{}/profile';
    that.productNumberMask = null;
    that.productNumberMaskSaved = null;
    that.showProductMask = false;

    that.branchNames = {};

    that.searchParams = {
      customerId: null,
      documentNumber: null,
      name: null,
      birthDate: null,
      productNumber: null,
      checkNumber: null,
      statuses: ['ACTIVE', 'PROSPECT'],
      customerTypes: ['INDIVIDUAL', 'CORPORATE'],
      registrationDate: null,
      pageNo: 0,
      pageSize: 20
    };
    that.searchResults = null;
    that.overflow = false;
    that.searchPerformed = false;
    that.createCustomerEnabled = false;
    that.contextBranchId = authentication.context.branchId;
    that.permissions = authentication.permissions;

    that.referenceDate = null;

    that.allDefaultUrls = [
      '/customer/{}/loans',
      '/customer/{}/term-deposits',
      '/customer/{}/accounts'
    ];


    const branchSub = branchService.toObservable().subscribe(branches => {
      return branches.forEach(b => that.branchNames[b.id] = b.name);
    });

    const defaultUrl = systemPropertyService.getProperty('DEFAULT_URL_AFTER_CST_LOOKUP');
    if (defaultUrl) {
      that.defaultUrl = defaultUrl;
    }

    const productNumberMask = systemPropertyService.getProperty('PRODUCT_NUMBER_MASK');

    // Load product number mask (if present it should be applied to UI)
    if (productNumberMask) {
      that.productNumberMask = productNumberMask;
      that.productNumberMaskSaved = that.productNumberMask;
      that.showProductMask = true;
    }

    const workingDaySub = branchWorkingDayCache.withParam(that.contextBranchId).toObservable().subscribe(workingDay => {
      that.workingDay = workingDay;
    });

    that.validSearchRequest = () => {
      if (!that.permissions['CST_ALL_BRANCHES_ACCESS']) {
        return that.searchParams.name
          || that.searchParams.customerNumber
          || that.referenceDate
      }

      return that.searchParams.customerNumber
        || that.searchParams.documentNumber
        || that.searchParams.name
        || that.searchParams.productNumber
        || that.searchParams.checkNumber;
    };

    that.emptyResults = function () {
      return that.searchResults !== null && that.searchResults.length === 0;
    };

    that.showResults = function () {
      return that.searchPerformed && !that.emptyResults();
    };

    that.nullifyParams = function (searchParams) {
      _.each(searchParams, function (value, key) {
        if (value != null && $.trim(value) == '') {
          searchParams[key] = null;
        }
      });
    };

    /**
     * Customer creation is available if:
     *
     * 1. User has appropriate permission
     * 2. Working day is STARTED
     * 3. Customer lookup was already performed
     * 4. Search was performed using customer name
     *
     * @return true if new customer can be created
     */
    const setupCustomerCreation = () => {
      const hasPermission = authentication.permissions['NEW_CUSTOMER_WRITE'];
      const workingDayStatus = that.workingDay && that.workingDay.status === 'STARTED';

      that.createCustomerEnabled = hasPermission
        && workingDayStatus
        && that.searchPerformed === true
        && that.searchParams.name;
    };

    that.fetchCustomers = async (params) => {
      that.searchParams.pageNo = params.page() - 1;
      that.searchParams.pageSize = params.count();

      if (!that.permissions['CST_ALL_BRANCHES_ACCESS']) {
        that.searchParams.birthDate = that.referenceDate;
        that.searchParams.registrationDate = that.referenceDate;
      }

      if (that.permissions['CST_CLOSED_CIF_READ']) {
        that.searchParams.statuses.push('CLOSED');
      }

      that.nullifyParams(that.searchParams);
      const queryParams = requestFormatter.convertToQueryParams(that.searchParams);
      const response = await http.get(`/customers${queryParams}`, {nxLoaderText: 'Searching for customer...'}).toPromise();

      that.searchPerformed = true;
      that.searchResults = response.result.map(c => ({...c, typeDescription: `${c.customerType}` + (c.customerSubtype ? ` (${c.customerSubtype})` : '')}));

      // If search result contains exactly one active customer -> open it
      if (that.searchResults.length === 1 && that.searchResults[0].customerStatus === 'ACTIVE') {
        that.searchResults[0].productGroup
          ? that.openCustomerProduct(that.buildRedirectUrl(that.searchResults[0]))
          : that.openCustomer(that.searchResults[0]);
      } else {
        setupCustomerCreation();
      }

      params.total(response.totalCount);
      return that.searchResults;
    };

    that.search = function () {
      that.tableConfig = new NgTableParams({
        page: 1,
        count: 20,
      }, {
        counts: [],
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        getData: params => that.fetchCustomers(params)
      });
    };

    that.buildRedirectUrl = (data) => {
      if (data.productGroup === 'ACCOUNT' && authentication.permissions['CST_DEPOSIT_ACCOUNT_READ']) {
        return `/customer/${data.customerId}/accounts/${data.productId}`;
      } else if (data.productGroup === 'DEPOSIT' && authentication.permissions['CST_TERM_DEPOSIT_READ']) {
        return `/customer/${data.customerId}/term-deposits/${data.productId}`;
      } else if (data.productGroup === 'LOAN' && authentication.permissions['CST_LOANS_READ']) {
        return `/customer/${data.customerId}/loans/${data.productId}`;
      } else {
        return null;
      }
    };

    that.switchProductMask = () => {
      if (that.showProductMask) {
        that.productNumberMask = that.productNumberMaskSaved;
      } else {
        if (that.searchParams.productNumber) {
          that.searchParams.productNumber = lookupHelper.removeSpecialCharsFromMask(that.searchParams.productNumber, that.productNumberMaskSaved);
        }
        that.productNumberMask = null;
      }
    };

    that.openCustomerProduct = (redirectionUrl) => {
      if (redirectionUrl) {
        $location.path(redirectionUrl);
      }
    };

    that.openCustomer = (customer) => {
      let currentUrl = that.defaultUrl;

      let i = 0;
      while (currentUrl && !that.hasPermissionsForRedirection(currentUrl)) {
        currentUrl = that.allDefaultUrls[i];
        i++;
      }

      //When 'i' is greater than allDefaultUrls length, currentUrl is set undef
      if (!currentUrl) {
        popup({
          text: "Insufficient permissions to open customer profile", callback: () => {
          }
        });
      }

      let redirectionUrl;
      if (customer.customerStatus === 'CLOSED') {
        if (that.permissions['CST_CLOSED_CIF_READ']) {
          redirectionUrl = that.profileUrl.replace('{}', customer.customerId);
        } else {
          $location.path('/error-page/ACCESS');
          return;
        }
      } else {
        redirectionUrl = currentUrl.replace('{}', customer.customerId);
      }

      $location.path(redirectionUrl);
    };

    that.hasPermissionsForRedirection = (defaultRedirectionUrl) => {
      if ('/customer/{}/loans' === defaultRedirectionUrl) {
        return authentication.permissions['CST_LOANS_READ'];
      } else if ('/customer/{}/term-deposits' === defaultRedirectionUrl) {
        return authentication.permissions['CST_TERM_DEPOSIT_READ'];
      } else if ('/customer/{}/accounts' === defaultRedirectionUrl) {
        return authentication.permissions['CST_DEPOSIT_ACCOUNT_READ'];
      } else {
        return true;
      }
    };

    that.isMembershipTypeVisible = () => {
      return propertyConfigService.visible('customer', 'cooperative_data', 'membership_type');
    };

    that.createCustomer = () => {
      $location.path('dashboard/new-customer');
    };

    that.setCorporate = () => {
      if (that.searchParams.isCorporate) {
        that.searchParams.customerType = 'CORPORATE';
      } else {
        that.searchParams.customerType = null;
      }
    };

    that.isOpenButtonEnabled = (customerStatus) => {
      return customerStatus === 'ACTIVE' || (customerStatus === 'CLOSED' && that.permissions['CST_CLOSED_CIF_READ'])
    }

    that.$onDestroy = () => {
      branchSub.unsubscribe();
      workingDaySub.unsubscribe();
    };
  }
});
