import {ILocationService} from 'angular';
import {LoanCreationType} from 'components/service/create-loan-input.types';
import {LoanType} from 'components/service/loan-type.types';
import {Loan} from 'components/service/loan.types';
import nxModule from 'nxModule';

import templateUrl from './customer-loan-create-list.template.html';

class CustomerLoanCreateListComponent {

  protected creationCommand!: string;
  protected creationType!: LoanCreationType;
  protected customerId!: number;
  protected customerType!: string;
  protected loan!: Loan;

  constructor(private $location: ILocationService) {
  }

  $onInit(): void {
    this.creationCommand = this.getCreationCommand();
  }

  private getCreationCommand(): string {
    switch (this.creationType) {
      case 'RECONSTRUCTED':
        return 'CreateReconstructedLoan';
      case 'RESTRUCTURED':
        return 'CreateRestructuredLoan';
      case 'CONSOLIDATION':
        return 'CreateConsolidatedLoan';
      default:
        return 'CreateLoan';
    }
  }

  protected open = (type: LoanType): void => {
    this.$location.path(this.getLocation(type));
  }

  private getLocation(type: LoanType): string {
    switch (this.creationType) {
      case 'RECONSTRUCTED':
        return `/customer/${this.customerId}/loans/reconstruct/${this.loan.id}/create/${type.id}`;
      case 'RESTRUCTURED':
        return `/customer/${this.customerId}/loans/restructure/${this.loan.id}/create/${type.id}`;
      case 'CONSOLIDATION':
        return `/customer/${this.customerId}/loans/consolidate/${type.id}`;
      default:
        return `/customer/${this.customerId}/loans/create/${type.id}`;
    }
  }
}

nxModule.component('customerLoanCreateList', {
  templateUrl: templateUrl,
  bindings: {
    loanType: '=',
    creationType: '<',
    loan: '<',
    customerId: '<',
    customerType: '<'
  },
  controller: CustomerLoanCreateListComponent
});
