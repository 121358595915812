import {NxButton, NxButtonVariant} from '@nextbank/ui-components';
import React, {ReactElement} from 'react';
import {useHistory} from 'react-router';

interface NxCancelButtonProps {
  dataTest?: string;
}
const NxCancelButton = (props: NxCancelButtonProps): ReactElement => {
  const history = useHistory();
  return <NxButton variant={NxButtonVariant.CLOSE}
                   onClick={(): void => history.goBack()}
                   dataTest={props.dataTest}>
    Cancel
  </NxButton>;
};

export default NxCancelButton;
