import nxModule from "nxModule";
import templateUrl from "./loan-documents.template.html";
import {Loan} from "components/service/loan.types";
import {loanDocumentStatuses, loanDocumentTypes} from "constants/loan";

class LoanDocuments {
  constructor(
    private propertyConfigService: any
  ) {}

  loan!: Loan;
  documentTypes = loanDocumentTypes;
  documentStatuses = loanDocumentStatuses;

  $onInit() {
    if (!this.loan.loanDocuments) {
      this.loan.loanDocuments = [];
    }
  }

  addDocument() : void {
    const defaultType = this.propertyConfigService.visible('loan', 'document', 'document_type')
      ? this.propertyConfigService.defaultValue('loan', 'document', 'document_type')
      : undefined;

    const defaultStatus = this.propertyConfigService.visible('loan', 'document', 'document_status')
      ? this.propertyConfigService.defaultValue('loan', 'document', 'document_status')
      : undefined;

    this.loan.loanDocuments.push({
      documentType: defaultType,
      status: defaultStatus
    });
  }

  deleteDocument(idx: number) : void {
    this.loan.loanDocuments.splice(idx, 1);
  }
}

nxModule.component('loanDocuments', {
  templateUrl,
  controller: LoanDocuments,
  bindings: {
    loan: '<'
  }
});
