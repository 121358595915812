import {
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikSubmitButton,
  NxRow,
  NxRowPosition,
  NxStack,
} from "@nextbank/ui-components";
import React, {ReactElement} from "react";
import {useHistory} from "react-router";
import {useCommand} from "command/CommandService";
import {CommandOutputWrapper} from "command/CommandTypes";
import {ColumnConfigurationDetails, UpdateReportConfigurationInput} from "report/ReportType";
import styles from "report/ReportOrderConfigurationView.scss";
import DragDropItems from "drag-drop/DragDropItems";
import DragDropItem from "drag-drop/DragDropItem";
import {cloneDeep} from "lodash";
import {ReportConfigurationProps} from "report/ReportConfiguationContainer";
import {DraggableProvided} from "react-beautiful-dnd";
import Alert from "alert/Alert";
import NxForm from "form/NxForm";

export interface ColumnConfigurationDetailsDraggable extends ColumnConfigurationDetails {
  key: string;
}

interface Form {
  items: ColumnConfigurationDetailsDraggable[]
}

const ReportOrderConfigurationView = ({reportCode, configuration}: ReportConfigurationProps): ReactElement => {
  const history = useHistory();
  const execute = useCommand();
  const allConfigurations = configuration
    .filter(c => c.enabled)
    .map(i => ({
      ...i,
      key: i.id.toString(),
    }))
    .sort((c1, c2) => c1.orderNo - c2.orderNo);

  const executeUpdateCommand = async (values: Form): Promise<void> => {
    // Creat new order numbers sequence from current order.
    const newOrder = cloneDeep(values.items);
    let orderNo = 0;
    for (const conf of newOrder) {
      orderNo += 1;
      conf.orderNo = orderNo;
    }
    const response: CommandOutputWrapper<void> = await execute<UpdateReportConfigurationInput, void>({
      name: 'UpdateReportConfiguration',
      input: {
        reportCode: reportCode,
        configuration: newOrder
      }
    });

    if (!response.approvalRequired) {
      navigateBack();
    }
  };

  const navigateBack = (): void => {
    history.push(`/admin/report-configuration`);
  };

  const itemRendererProvider = (provided: DraggableProvided, isDragging: boolean, item: ColumnConfigurationDetailsDraggable): React.ReactElement =>
    <DragDropItem provided={provided} isDragging={isDragging} item={<span>{item.humanReadableName}</span>}/>;

  return <NxFormik
      initialValues={{
        items: allConfigurations
    }}
      onSubmit={executeUpdateCommand}>
    {({values, setValues, isSubmitting}): ReactElement =>
      <NxForm>
        <NxStack>
          <Alert severity="info">
            Drag columns to reorder.
          </Alert>
          <div className={styles.items}>
            <DragDropItems<ColumnConfigurationDetailsDraggable>
              droppableId={'columnsOrderColumn'}
              render={itemRendererProvider}
              setItems={(values): void => setValues({items: values})}
              items={values.items}/>
          </div>
          <NxRow position={NxRowPosition.END}>
            <NxButton variant={NxButtonVariant.CLOSE}
                      onClick={(): void => navigateBack()}>
              Cancel
            </NxButton>
            <NxFormikSubmitButton
              variant={NxButtonVariant.SAVE}>
              Update
            </NxFormikSubmitButton>
          </NxRow>
        </NxStack>
      </NxForm>
    }
    </NxFormik>;
};
export default ReportOrderConfigurationView;
