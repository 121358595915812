import {BatchRemittanceHistoryRow} from 'components/batch/remittance/batch-remittance-history.component';
import {NgTableParams} from 'ng-table';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import {PageRequest, PageResult} from 'tools/HttpTypes';

export class BatchRemittanceHistoryService {

  private batchRemittanceHistory? : NgTableParams<BatchRemittanceHistoryRow>;
  private totalRowCount : number;

  constructor(private http: HttpService) {
    this.totalRowCount = 0;
  }

  initialize(): void {
    if(!this.batchRemittanceHistory) {
      this.batchRemittanceHistory = new NgTableParams({
        page: 1,
        count: 20
      }, {
        counts: [],
        total: 0,
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        getData: async (params: NgTableParams<BatchRemittanceHistoryRow>): Promise<BatchRemittanceHistoryRow[]> => {
          const request: PageRequest = {
            pageNo: params.page() - 1,
            pageSize: params.count()
          };
          const response = <PageResult<BatchRemittanceHistoryRow>> await this.http.get(`/remittance/history?pageNo=${request.pageNo}&pageSize=${request.pageSize}`).toPromise();
          this.totalRowCount = response.totalCount;
          params.total(this.totalRowCount);
          return response.result;
        }
      });
    }
  }

  getHistory() : NgTableParams<BatchRemittanceHistoryRow> | undefined {
    return this.batchRemittanceHistory;
  }

  getTotalRowCount() : number {
    return this.totalRowCount;
  }
}

nxModule.factory('batchRemittanceHistoryService', BatchRemittanceHistoryService);
