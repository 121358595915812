import nxModule from 'nxModule';
import _ from 'lodash';

nxModule.factory('productLockService', function ($filter, confirmationTemplate, command, productLockCache) {
  const service = this;

  const execCallback = (callback) => {
    if (callback && typeof callback === 'function') callback();
  };

  service.createLock = (product, data, callback) => {
    confirmationTemplate({
      question: 'Do you want to lock the product?',
      details: [
        {label: 'Product number', description: product.productNumber},
        {label: 'Lock type', description: $filter('prettyEnum')(data.type)}
      ],
      yesCallback: () => command.execute('CreateProductLock', data).success(() => execCallback(callback))
    });
  };

  service.releaseProductLocks = (product, remarks, callback) => {
    confirmationTemplate({
      question: 'Do you want to release all of the product locks?',
      details: [
        {label: 'Product number', description: product.productNumber}
      ],
      yesCallback: async () => {
        const locks = await productLockCache.withParam(product.id).toPromise();
        if (!_.isEmpty(locks) && locks.some(lock => !lock.released)) {
          const input = locks.filter(l => !l.released).map(l => {
            return {lockId: l.id, releaseRemarks: remarks};
          });
          const response = await command.execute('BatchReleaseProductLock', {locks: input}).toPromise();
          if (response && !response.approvalRequired) {
            productLockCache.withParam(product.id).refetch();
          }
          execCallback(callback);
        }
      }
    });
  };

  return service;
});
