import nxModule from 'nxModule';

import templateUrl from './mfa-configuration-page.template.html';
import {setBreadcrumbs} from "shared/utils/set-breadcrumbs";
import {ILocationService} from "angular";
import {BranchService} from "components/service/branch.service";
import {UserCache} from "components/service/users/user.cache";
import Authentication from "shared/utils/authentication";
import {OrganizationCache} from "components/service/organization.cache";
import {CommandAccessChecker, CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import {NxRouteService} from "routes/NxRouteService";
import {UserService} from "components/service/users/user.service";
import {Breadcrumbs} from "angular-breadcrumbs";
import {ModalApi} from "components/technical/modal/modal.component";
import {Branch} from "management/BranchTypes";
import {User} from "user/UserTypes";
import {ErrorResponse} from "tools/HttpTypes";

class MfaConfigurationPage {
  private user!: User;
  private links!: {return: string};
  private branch!: Branch;
  private mfaModalDeviceConfigured = false;
  private canSeeMfa = false;
  private authDetails: unknown;
  private canConfigureMfa = false;
  private mfaConfirmationSecurityCode?: number;
  private mfaConfigurationOrganizationName?: string;
  private mfaConfigurationSecret?: string;
  private mfaModalApi?: ModalApi;
  private errorMessage?: string;

  constructor(
    private $location: ILocationService,
    private $route: NxRouteService,
    private command: CommandService,
    private commandAccessChecker: CommandAccessChecker,
    private confirmation: Confirmation,
    private notification: Notification,
    private branchService: BranchService,
    private userCache: UserCache,
    private authentication: Authentication,
    private userService: UserService,
    private organizationCache: OrganizationCache,
    private breadcrumbs: Breadcrumbs
  ) {
  }

  async $onInit() {
    setBreadcrumbs(this.breadcrumbs, 'users-list-label', this.branch.name);
    setBreadcrumbs(this.breadcrumbs,  'users-details-label', this.user.effectiveName);

    const canExecuteCommand = await this.commandAccessChecker.canExecuteCommandAsync();
    // load user auth details
    this.canConfigureMfa = canExecuteCommand('ConfigureMfa', {userId: this.user.id});
    this.canSeeMfa = canExecuteCommand('UpdateUserMfaStatus')
        || this.canConfigureMfa
        || this.user.id === this.authentication.context.id;
    if (this.canSeeMfa) {
      this.authDetails = await this.userService.authDetails(this.user.id);
    }
  }

  return() {
    this.$location.path(this.links.return);
  }

  async mfaConfigurationNextStep() {
    if (!this.mfaModalDeviceConfigured) {
      this.mfaModalDeviceConfigured = true;
      return;
    }

    await this.command.execute('ConfirmMfaConfiguration', {
      userId: this.user.id,
      totpCode: this.mfaConfirmationSecurityCode
    }).error((err: ErrorResponse) => this.errorMessage = err.errorMessage, true)
      .toPromise(true);
    this.mfaModalApi?.onOk();
    // reload authentication details
    this.authDetails = await this.userService.authDetails(this.user.id);
  }

  async enableMfa() {
    const proceed = await this.confirmation('Do you want enable multi-factor authentication for this user?');
    if (!proceed) {
      return;
    }

    await this.command.execute('UpdateUserMfaStatus', {
      userId: this.user.id,
      isMfaEnabled: true
    }).toPromise();

    this.userCache.withParam().refetch();
    this.authDetails = await this.userService.authDetails(this.user.id);
    this.$route.reload();
  }

  async disableMfa() {
    const proceed = await this.confirmation('Do you want disable multi-factor authentication for this user?');
    if (!proceed) {
      return;
    }

    await this.command.execute('UpdateUserMfaStatus', {
      userId: this.user.id,
      isMfaEnabled: false
    }).toPromise();

    this.userCache.withParam().refetch();
    this.authDetails = await this.userService.authDetails(this.user.id);
    this.$route.reload();
  }

  async configureMfa() {
    this.mfaModalDeviceConfigured = false;
    this.mfaConfirmationSecurityCode = undefined;

    const [secretResult, organizations] = await Promise.all([
      this.command.execute<unknown, string>('ConfigureMfa', {
        userId: this.user.id
      }).toPromise(),
      this.organizationCache.toPromise()
    ]);

    this.mfaConfigurationOrganizationName = organizations.find(org => org.id === this.branch.organizationId)?.name;
    this.mfaConfigurationSecret = secretResult.output;
    this.errorMessage = undefined;
    this.mfaModalApi?.show();
  }

  onMfaModalApiReady = ({api}: {api: ModalApi}) => {
    this.mfaModalApi = api;
  };

}

nxModule.component('mfaConfigurationPage', {
  templateUrl,
  bindings: {
    user: '<',
    branch: '<',
    links: '<'
  },
  controller: MfaConfigurationPage
});
