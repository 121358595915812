import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from '../../../../../../react/system/systemPropertyService';
import checkbookService, {CHECKBOOK_ACCOUNT_NUMBER_LENGTH} from './../checkbook-service';

const templateUrl = require('./checkbook-issue.template.html');
nxModule.component('issueCheckbook', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, customerCache, productDefinitionService,
                        requestFormatter, confirmation, command, depositoryAccountCache, checkbookCache,
                        branchService) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.depositoryAccount = undefined;
    that.checkbook = {productId: accountId};
    that.isPchcDirectClearing = systemPropertyService.getProperty('PCHC_DIRECT_CLEARING_MEMBER') === 'TRUE';
    that.checkbookAccountNumberLength = CHECKBOOK_ACCOUNT_NUMBER_LENGTH;

    const defaultCheckbookSizes = {
      'INDIVIDUAL': 0,
      'COMMERCIAL': 0
    };

    that.depositoryAccountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    that.fillBeginningWithZerosUpTo = (noOfChars, word) => {
      if (word.length >= noOfChars) {
        return word.slice(word.length - noOfChars);
      } else {
        return '0'.repeat(noOfChars - word.length) + word;
      }
    };

    that.onFirstCheckNumberChange = () => {
      that.checkbook.startNumber = that.fillBeginningWithZerosUpTo(10, that.checkbook.startNumber);
    };

    const ind = systemPropertyService.getProperty('CHECK_INDIVIDUAL_CHECKBOOK_SIZE');
    // Set default individual checkbook size
    defaultCheckbookSizes['INDIVIDUAL'] = ind && ind > 0 ? Number(ind) : 0;
    // Set default commercial checkbook size
    const com = systemPropertyService.getProperty('CHECK_COMMERCIAL_CHECKBOOK_SIZE');
    defaultCheckbookSizes['COMMERCIAL'] = com && com > 0 ? Number(com) : 0;

    that.$onInit = async () => {
      const [branches, depositoryAccounts, customerAccounts, productDefinitions] = await Promise.all([
        branchService.toPromise(),
        depositoryAccountCache.toPromise(),
        customerCache.depositAccounts(customerId).toPromise(),
        productDefinitionService.toPromise()
      ]);

      that.depositoryAccounts = depositoryAccounts.filter(depositoryAccount => depositoryAccount.clearing);
      const account = customerAccounts.find(account => account.id === Number(accountId));
      const productDefinition = productDefinitions.find(productDefinition => productDefinition.id === account.definitionId);
      that.account = Object.assign(account, {productName: productDefinition ? productDefinition.productName : '-'});

      const accountBranch = branches.find(branch => branch.id === that.account.branchId);
      if (that.isPchcDirectClearing) {
        that.checkbook.number = that.account.productNumber;
        that.brstn = accountBranch.brstn;
        that.accountNumber = checkbookService.generateCheckbookAccountNumber(that.account.productNumber);
      }
    }

    that.onDepositoryAccountChange = () => {
      // Reset start check number
      that.checkbook.startNumber = null;
      // If depository account is selected -> fetch valid start number
      const depositoryAccountId = that.checkbook.depositoryAccountId;
      if (depositoryAccountId && depositoryAccountId > 0) {
        const acc = _.find(that.depositoryAccounts, a => Number(a.id) === Number(depositoryAccountId));
        if (acc && acc.brstn && acc.clearing) {
          that.depositoryAccount = acc;
          that.brstn = that.depositoryAccount.brstn;
          that.accountNumber = that.depositoryAccount.accountNumber;
          const criteria = requestFormatter.nullifyParams({brstn: acc.brstn});
          const queryParams = requestFormatter.convertToQueryParams(criteria);
          http.get(`/checks/checkbooks/highest-check-sequence${queryParams}`, {nxLoaderSkip: true}).success((result) => {
            if (result !== null && Number(result) >= 0) {
              that.checkbook.startNumber = String(Number(result) + 1);
              that.onFirstCheckNumberChange();
            }
          })
        }
      }
    };

    that.issue = async () => {
      const proceed = await confirmation(`Do you want to issue new checkbook ${that.checkbook.number}?`);
      if (proceed) {
        if (that.isPchcDirectClearing) {
          // non pchc should get account number from the depository account.
          that.checkbook.accountNumber = that.accountNumber;
        }
        await command.execute('CreateCheckbook', that.checkbook, {nxLoaderText: 'Issuing checkbook'}).toPromise();
        await customerCache.depositAccounts(customerId).refetch();
        await checkbookCache.withParam(accountId).refetch();
        that.redirectBack();
      }
    };

    that.onTypeChange = (type) => {
      switch (type) {
        case 'INDIVIDUAL':
        case 'COMMERCIAL':
          that.checkbook.checksCount = defaultCheckbookSizes[type];
          break;
        default:
          that.checkbook.checksCount = 0;
          break;
      }
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);
  }
});
