import nxModule from 'nxModule';
import templateUrl from './center-picker.template.html';
import _ from "lodash";
import {CenterService} from "../../service/center/center.service";
import {Center} from "../../service/center/center.types";
import './center-picker.style.less';
import {UserCache, UserDetails} from "../../service/users/user.cache";
import {Branch} from "management/BranchTypes";
import {IAugmentedJQuery, IScope} from "angular";
import {HttpService} from 'shared/utils/httpService';
import {BranchService} from 'components/service/branch.service';

class CenterPicker {
  private onChoose!: (center: Center) => void;
  private filterResult!: (center: Center) => boolean;
  private visible?: boolean;
  private name?: string | null;
  private searchResults: Center[] = [];
  private branches!: Record<number, Branch>;
  private users!: Record<number, UserDetails>;

  constructor(private $scope: IScope,
              private $element: IAugmentedJQuery,
              private http: HttpService,
              private branchService: BranchService,
              private centerService: CenterService,
              private userCache: UserCache) {

  }

  async $onInit(): Promise<void> {
    const [branchesArr, usersArr]: [Branch[], UserDetails[]] = await Promise.all([
      this.branchService.toPromise(),
      this.userCache.withParam().toPromise()
    ]);

    this.visible = false;
    this.searchResults = [];
    this.branches = _.keyBy(branchesArr, branch => branch.id);
    this.users = _.keyBy(usersArr, user => user.id);
  }

  enableSearch(): boolean {
    return !!this.name;
  }

  async search(): Promise<void> {
    this.searchResults = await this.centerService.fetchCenters({name: this.name!});
    if (this.filterResult) {
      this.searchResults = this.searchResults.filter(this.filterResult);
    }
    this.visible = true;
  }

  getBranchName(branchId: number): string {
    return this.branches[branchId]?.name;
  }

  async choose(center: Center, $event: Event): Promise<void> {
    $event.stopPropagation();

    this.visible = false;
    this.searchResults = [];
    this.name = null;

    await this.onChoose(center);
  }

  clear() {
    this.searchResults = [];
    this.name = null;
    this.visible = false;
  }
}

nxModule.component('centerPicker', {
  templateUrl,
  bindings: {
    'onChoose': '<',
    'filterResult': '<',
    'buttonLabel': '<'
  },
  controller: CenterPicker
});