import {Box} from "@material-ui/core";
import React, {ReactElement} from "react";

export enum HeaderVariant {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3"
}

/**
 * Component rendering children to look like a header
 */
const NxHeader = ({children, variant = HeaderVariant.H1}: { children: React.ReactNode, variant?: HeaderVariant }): ReactElement => {
  return <Box
    fontSize={`${variant}.fontSize`}
  >
    {children}
  </Box>;
};


export default NxHeader;