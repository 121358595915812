import {CustomFieldDefinition} from 'custom-field/CustomFieldDefinitionTypes';
import React, {ReactElement} from 'react';
import {TreeNodeViewProps} from 'tree/editableTree/EditableSubtreeView';
import {SingleSelectCategoryValue} from 'tree/singleSelectTree/NxFormikSingleSelectTree';

export interface CustomFieldCategoryValue extends SingleSelectCategoryValue{
  definitionId?: number;
  code: string;
  remarks: string;
  customField?: CustomFieldDefinition;
}

const CustomFieldCategoryTreeView = (props: TreeNodeViewProps<CustomFieldCategoryValue>): ReactElement => {
  const label = props.value.customField ? props.value.customField.name : props.value.label;
  if (props.value.code) {
    return <div>{label} | {props.value.code}</div>;
  }

  return <div>{label}</div>;
};

export default CustomFieldCategoryTreeView;
