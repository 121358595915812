import nxModule from "nxModule";
import templateUrl from "components/common/pdf-view/pdf-view.template.html";
import './pdf-view.style.less';
import {ISCEService} from "angular";

class PdfView {
  sourceUrl!: string;
  trustedSourceUrl!: string;
  description!: string;

  constructor(private $sce: ISCEService) { }

  $onInit(): void {
    this.trustedSourceUrl = this.$sce.trustAsResourceUrl(this.sourceUrl);
  }
}

nxModule.component('pdfView', {
  templateUrl,
  bindings: {
    sourceUrl: '<',
    description: '<'
  },
  controller: PdfView
});
