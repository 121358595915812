import nxModule from 'nxModule';

import templateUrl from './deposit-account-advanced.template.html';
import {AccountType} from "../../../service/account.types";
import './deposit-account-advanced.style.less'

class DepositAccountAdvanced {

  protected accountProduct!: AccountType;
}

nxModule.component('depositAccountAdvanced', {
  templateUrl,
  controller: DepositAccountAdvanced,
  bindings: {
    accountProduct: '<',
    form: '='
  }
});