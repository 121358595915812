import 'jquery-qrcode';
import nxModule from 'nxModule';

nxModule.directive('qrCode', () => {
  return {
    restrict: 'A',
    scope: {
      qrCode: '<'
    },
    link: (scope, element) => {
      scope.$watch('qrCode', (qrCode, oldCode) => {
        if (!qrCode || qrCode === oldCode) {
          // we have no data (or nothing changed) for QR - skip render
          return;
        }

        element.empty();
        element.qrcode(scope.qrCode);
      });
    }
  }
});
