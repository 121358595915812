import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

nxModule.factory('accountOperationService', function ($filter, http, notification, confirmation, customerCache, command, depositAccountTypeService) {
  const that = this;

  const genericDebitMessage = ({present, inProgress, past, amount, belowMaintaining}) => {
    let message = `Do you want to ${present} ${$filter('nxCurrency')(amount)}?`;
    if (belowMaintaining) {
      message = `${message} The balance after this transaction will be lower than the maintaining balance.`;
    }

    return {
      confirmMsg: message,
      loaderMsg: `${_.upperFirst(inProgress)} funds...`,
      successMsg: `The selected amount has been successfully ${past}.`
    };
  };

  const getDebitMessageContext = async (customerId, accountId, amount) => {
    const [accounts, accountTypes] = await Promise.all([
      customerCache.depositAccounts(customerId).toPromise(),
      depositAccountTypeService.toPromise()
    ]);

    const account = accounts.find(a => Number(a.id) === Number(accountId));
    const accountType = accountTypes.find(t => Number(t.id) === account.typeId);
    const balanceAfter = new BigNumber(account.balance).minus(amount).toFixed(2);
    const maintainingBalance = new BigNumber(account.maintainingBalance ?? accountType.maintainingBalance);
    const belowMaintaining = maintainingBalance.isGreaterThan(balanceAfter);

    return {
      balanceAfter,
      maintainingBalance,
      belowMaintaining
    };
  };

  const formatDebitMessage = (customerId, accountId, amount, callback) => {
    customerCache.depositAccounts(customerId)
      .toObservable()
      .map(accounts => _.find(accounts, a => Number(a.id) === Number(accountId)))
      .combineLatest(depositAccountTypeService.toObservable(), (account, types) => {
        account.type = _.find(types, t => Number(t.id) === account.typeId);
        return account;
      })
      .first()
      .subscribe(account => {
        const balanceAfter = new BigNumber(account.balance).minus(amount).toFixed(2);
        const maintainingBalance = new BigNumber(account.maintainingBalance ?? account.type.maintainingBalance);
        const belowMaintaining = maintainingBalance.isGreaterThan(balanceAfter);
        callback(genericDebitMessage({
          present: 'withdraw',
          inProgress: 'withdrawing',
          past: 'withdrawn',
          amount,
          belowMaintaining
        }));
      });
  };

  const executeOperation = (cmd, customerId, productId, amount, request,
        {confirmMsg, loaderMsg, successMsg}, callback = () => {}, skipPassbook) => {
    confirmation(confirmMsg, () => {
      command.execute(cmd, request, {nxLoaderText: loaderMsg})
        .success((res) => {
          notification.show(successMsg);
          customerCache.depositAccounts(customerId).refetch();
          customerCache.depositAccountGroups(customerId).refetch();
          callback();
        }, true);
    });
  };

  const zeroBalanceCloseMessage = (accruedInterest) => {
    return {
      confirmMsg: `Accrued Interest of ${accruedInterest} PHP for this account will be reversed, proceed?`,
      loaderMsg: 'Closing account',
      successMsg: 'Deposit account has been successfully closed'
    };
  }

  that.cashWithdraw = ({customerId, productId, amount, remarks}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('WithdrawAccountFundsByCash', customerId, productId, amount, {
        productId: productId,
        amount: amount,
        entryType: 'DEBIT',
        remarks: remarks
      }, message, callback);
    });
  };

  that.checkWithdraw = ({customerId, productId, amount, validFrom, depositoryAccountId, micrNumber, checkNumber, payee, remarks}, callback) => {
    formatDebitMessage(customerId, productId, amount, (message) => {
      executeOperation('WithdrawAccountFundsByCheck', customerId, productId, amount, {
        productId: productId,
        amount: amount,
        entryType: 'DEBIT',
        validFrom: validFrom,
        depositoryAccountId: depositoryAccountId,
        micrNumber: micrNumber,
        checkNumber: checkNumber,
        payee: payee,
        remarks: remarks
      }, message, callback)
    });
  };

  that.encashOnUsCheck = async ({customerId, productId, amount, validFrom, micrNumber, payee, remarks}, callback) => {
    const {
      belowMaintaining
    } = await getDebitMessageContext(customerId, productId, amount);

    const message = genericDebitMessage({
      amount,
      belowMaintaining,
      present: 'encash',
      past: 'encashed',
      inProgress: 'encashing',
    });

    executeOperation('EncashOnUsCheck', customerId, productId, amount, {
      productId: productId,
      amount: amount,
      validFrom: validFrom,
      micrNumber: micrNumber,
      payee: payee,
      remarks: remarks,
    }, message, callback);
  };

  that.memoDebit = async ({customerId, productId, amount, categoryUnits, remarks}, callback) => {
    const skipPassbook = true;
    const { belowMaintaining } = await getDebitMessageContext(customerId, productId, amount);

    const message = genericDebitMessage({
      amount,
      belowMaintaining,
      present: 'debit',
      past: 'debited',
      inProgress: 'debiting',
    });

    executeOperation('WithdrawAccountFundsByMemo', customerId, productId, amount, {
      productId: productId,
      amount: amount,
      entryType: 'DEBIT',
      categoryUnits,
      remarks: remarks
    }, message, callback, skipPassbook);
  };

  that.memoCredit = ({customerId, productId, amount, categoryUnits, remarks}, callback) => {
    const skipPassbook = true;
    executeOperation('DepositMemoToAccount', customerId, productId, amount, {
      productId: productId,
      amount: amount,
      entryType: 'CREDIT',
      categoryUnits,
      remarks: remarks,
      allowNonActiveAccounts: true
    }, {
      confirmMsg: `Do you want to credit ${$filter('nxCurrency')(amount)}?`,
      loaderMsg: 'Crediting funds...',
      successMsg: 'The selected amount has been successfully credited.'
    }, callback, skipPassbook);
  };

  const closeMessage = {
    confirmMsg: 'Are you sure you want to close account?',
    loaderMsg: 'Closing account',
    successMsg: 'Deposit account has been successfully closed'
  };

  that.closeZeroBalance = ({customerId, productId, amount, terminationFeesOverride}, accruedInterest, callback) => {
    executeOperation('CloseZeroBalanceAccount', customerId, productId, amount, {
      productId: productId,
      entryType: 'DEBIT',
      amount: amount,
      terminationFeesOverride,
    }, zeroBalanceCloseMessage(accruedInterest), callback);
  };

  that.closeWithCash = ({customerId, productId, amount, terminationFeesOverride}, callback) => {
    executeOperation('CloseAccountWithCash', customerId, productId, amount, {
      productId: productId,
      entryType: 'DEBIT',
      amount: amount,
      terminationFeesOverride,
    }, closeMessage, callback);
  };

  that.closeWithCheck = ({customerId, productId, amount, validFrom, depositoryAccountId,
                           micrNumber, checkNumber, payee, remarks, terminationFeesOverride}, callback) => {
    executeOperation('CloseAccountWithCheck', customerId, productId, amount, {
      productId: productId,
      entryType: 'DEBIT',
      amount: amount,
      validFrom: validFrom,
      depositoryAccountId: depositoryAccountId,
      micrNumber : micrNumber,
      checkNumber: checkNumber,
      payee: payee,
      remarks: remarks,
      terminationFeesOverride,
    }, closeMessage, callback);
  };

  that.closeWithCheckEncash = ({customerId, productId, amount, validFrom, micrNumber,
                                 payee, remarks, terminationFeesOverride}, callback) => {
    executeOperation('CloseAccountWithEncash', customerId, productId, amount, {
      productId: productId,
      amount: amount,
      validFrom: validFrom,
      micrNumber : micrNumber,
      payee: payee,
      remarks: remarks,
      terminationFeesOverride,
    }, closeMessage, callback);
  };

  return that;
});
