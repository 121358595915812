import {CommandContext} from "./CommandService";
import {ReactElement, ReactNode, useContext} from "react";
import NxPrompt from "form/NxPrompt";

/**
 * Component that blocks navigating away when command is running and block is true. Both conditions
 * exist to allow detection if a command was triggered on the same page where component is rendered
 */
const CommandNavigationBlocker = ({children, block}: {children: ReactNode, block: boolean}): ReactElement => {
  const { executing } = useContext(CommandContext);
  return <>
    <NxPrompt
      when={executing && block}
      message="Action is still running. Are you sure you want to navigate away?"
    />
    {children}
    </>;
};

export default CommandNavigationBlocker;