import nxModule from 'nxModule';
import _ from "lodash";
import BigNumber from "bignumber.js";
import systemPropertyService from '../../../../react/system/systemPropertyService';

const templateUrl = require('./denomination.template.html');
nxModule.component('denomination', {
  templateUrl,
  bindings: {
    'objectRef': '=',
    'totalValueRef': '<',
    'initialData': '<',
    'group': '<',
    'level': '@level',
    'property': '@property',
    'currencyProperty': '@currencyProperty',
    'validityProperty': '@validityProperty',
    'readOnly': '<',
    'oneSided': '<',
    'currencyIsoCode': '@currencyIsoCode',
    'disableSolver': '<'
  },
  controller: function (authentication, http, currencyCache) {

    const gridColumnsCount = 3;

    let that = this;

    // Supported denomination levels (@see DENOMINATION_LEVEL system property for details)
    const supportedLevels = {'NONE': 0, 'PARTIAL': 1, 'FULL': 2};

    // Currency properties
    that.currencies = [];
    that.currency = null;
    that.currencyAttrName = null;
    that.bundleAttrName = null;

    // Overdraft properties
    that.overdraftValue = 0;
    that.overdraftLabel = '-';
    that.allowOverdraft = false;

    // Denomination bundle (contains incoming and outgoing buckets)
    that.bundle = {
      totalValue: 0,
      incoming: {},
      outgoing: {}
    };

    // Establish [direction] property
    that.group = !that.group ? 'AUDIT' : that.group;
    // By default both sides are allowed (IN/OUT)
    that.incomingEnabled = true;
    that.outgoingEnabled = true;
    // If denomination is explicitly marked as 'oneSided' -> choose enabled side
    if (that.oneSided && that.oneSided === true) {
      that.incomingEnabled = that.group === 'INCOMING';
      that.outgoingEnabled = that.group === 'OUTGOING';
    }


    /**
     * Establish property reference.
     * If given property is given & already exists -> connect it with denomination table
     * If given property is null/undefined/empty -> create it
     *
     * Default denomination property name = 'denomination'
     */
    const initializeAttrNames = () => {

      const initializeAttrName = (prop, defaultName) => {
        const p = prop ? prop : defaultName;
        if (!that.objectRef.hasOwnProperty(p)) that.objectRef[p] = null;
        return p;
      };

      // Establish denomination units property
      that.bundleAttrName = initializeAttrName(that.property, 'denominationBundle');
      // Establish currency property
      that.currencyAttrName = initializeAttrName(that.currencyProperty, 'currencyId');
      // Establish validity flag property
      that.validityAttrName = initializeAttrName(that.validityProperty, 'denominationValid');
    };

    const initializeBundle = (currency) => {
      const initBucket = (bucket) => {

        // Initialize basic properties
        bucket.totalValue = 0;
        bucket.denomination = {currencyId: currency.id, units: []};

        // Initialize grid
        bucket.grid = {columns: {}, units: {}};
        let count = currency.units.length;
        for (let i = 0; i < count; i++) {
          let columnNo = i % gridColumnsCount;
          if (!bucket.grid.columns[columnNo]) bucket.grid.columns[columnNo] = [];
          const cUnit = currency.units[i];
          bucket.grid.columns[columnNo].push(cUnit);
          bucket.grid.units[cUnit.id] = {count: 0, totalValue: 0, currencyUnitId: cUnit.id};
        }
      };

      // Initialize incoming and outgoing denominations
      if (that.incomingEnabled) initBucket(that.bundle.incoming);
      if (that.outgoingEnabled) initBucket(that.bundle.outgoing);
    };

    /**
     * Currency should be obtained in following order:
     *
     *  1. [currencyId] from reference object (if exists)
     *  2. Currency given by ISO code (component input)
     *  3. Default system currency (obtained from system properties)
     *
     * @return currency identified by rules above
     */
    const getCurrency = (currencies) => {
      const refCurrencyId = that.objectRef[that.currencyAttrName];
      if (refCurrencyId) {
        return _.find(currencies, {id: refCurrencyId});
      } else if (that.currencyIsoCode) {
        return _.find(currencies, {isoCode: that.currencyIsoCode});
      }

      return systemPropertyService.getPropertyDescriptor('DEFAULT_CURRENCY');
    };

    /**
     * Returns level of denomination component.
     * Currently 3 levels of denomination are supported: NONE, PARTIAL & FULL.
     *
     * If level is not given at component level -> FULL is used as a default
     */
    const getLevel = () => {
      return that.level && _.includes(Object.keys(supportedLevels), that.level.toUpperCase()) ? that.level.toUpperCase() : 'FULL';
    };

    /**
     * Total value reference is not given -> denomination is considered valid
     * Otherwise compare referenced total value with sum of denomination units
     */
    that.validate = () => {
      if (that.totalValueRef == null || that.allowOverdraft) {
        that.valid = true;
      } else {
        const expected = new BigNumber(that.totalValueRef).dp(2).toNumber();
        that.valid = expected === that.bundle.totalValue;
      }
      that.objectRef[that.validityAttrName] = that.valid;
    };

    const updateOverdraft = () => {

      // Calculate overdraft value
      if (that.totalValueRef == null) return 0;
      const expected = new BigNumber(that.totalValueRef).dp(2).toNumber();
      const overdraft = expected - that.bundle.totalValue;
      that.overdraftValue = Math.abs(overdraft);

      // Update overdraft label
      that.overdraftLabel = 'Overdraft';
      that.bundle.overdraftType = null;
      if (overdraft === 0) {
        that.overdraftLabel = '-';
      } else if (that.group === 'OUTGOING') {
        that.overdraftLabel = overdraft > 0 ? 'Overage' : 'Shortage';
        that.bundle.overdraftType = that.overdraftLabel.toUpperCase();
      } else if (that.group === 'INCOMING') {
        that.overdraftLabel = overdraft > 0 ? 'Shortage' : 'Overage';
        that.bundle.overdraftType = that.overdraftLabel.toUpperCase();
      }

      // If overdraft value is higher than threshold -> disable switch
      if (that.overdraftValue > that.overdraftThreshold) {
        that.allowOverdraft = false;
      }

      // Perform validation
      that.validate();
    };

    const calculateUnitValue = (dUnit, cUnit) => {
      // Get currency unit divisor (for main unit t should be 0 for subunit <> 0)
      const divisor = cUnit.subunit ? that.currency.subunitRatio : 1;

      if(dUnit.count == null) {
        dUnit.totalValue = 0;
      } else {
        // For subunits -> get divisor
        dUnit.totalValue = new BigNumber(dUnit.count)
          .multipliedBy(cUnit.unitValue)
          .dividedBy(divisor)
          .dp(2)
          .toNumber();
      }
    };

    const calculateBucketValue = (bucket) => {
      let uValues = _.map(bucket.grid.units, (u) => u.totalValue);
      bucket.totalValue = new BigNumber(_.sum(uValues)).dp(2).toNumber();
    };

    const calculateBundleValue = () => {

      // Assure that bucket value is defined
      const bucketValue = (bucket) => bucket && bucket.totalValue ? bucket.totalValue : 0;
      const incomingValue = bucketValue(that.bundle.incoming);
      const outgoingValue = bucketValue(that.bundle.outgoing);

      // For IN-OUT transactions total value is absolute value of subtraction
      let totalValue = Math.abs(incomingValue - outgoingValue);
      if (that.group === 'INCOMING') {
        totalValue = incomingValue - outgoingValue;
      } else if (that.group === 'OUTGOING') {
        totalValue = outgoingValue - incomingValue;
      }

      that.bundle.totalValue = totalValue;
    };

    const updateObjectRef = () => {
      // If bundle object is null or empty -> leave immediately
      if (!that.bundle) return;
      // Update model of each denomination in bundle
      _.forEach(Object.keys(that.bundle), (prop) => {
        const bucket = that.bundle[prop];
        if (bucket && bucket.hasOwnProperty('denomination')) {
          bucket.denomination.units = _.filter(bucket.grid.units, (u) => u.count > 0);
        }
      });
      // Assign bundle to referenced attribute
      that.objectRef[that.bundleAttrName] = {
        group: that.group,
        userId: authentication.context.id,
        branchId: authentication.context.branchId,
        allowOverdraft: that.allowOverdraft,
        overdraftAmount: that.overdraftValue && that.overdraftValue > 0 ? Number(that.overdraftValue).toFixed(2) : null,
        overdraftType: that.bundle.overdraftType,
        incoming: that.bundle.incoming ? that.bundle.incoming.denomination : null,
        outgoing: that.bundle.outgoing ? that.bundle.outgoing.denomination : null
      };
    };

    const recalculateBucket = (bucket, refreshUnits = false) => {
      // If bucket grid units are not defined -> leave
      if (!bucket || !bucket.grid || !bucket.grid.units) return;
      // If units should be refreshed -> calculate unit value for each currency unit
      if (refreshUnits) {
        _.forEach(that.currency.units, (cUnit) => {
          const dUnit = bucket.grid.units[cUnit.id];
          calculateUnitValue(dUnit, cUnit);
        })
      }
      // Sum total values of all denomination units (to get total value)
      calculateBucketValue(bucket);
      // Calculate bundles' total value
      calculateBundleValue();
      // Update overdraft & validate denomination after changes
      updateOverdraft();
      // Assign denomination object to denomination ref
      updateObjectRef();
    };

    that.onDenominationCountChange = (bucket, cUnitId) => {
      // If component is used in read-only mode -> leave immediately
      // Otherwise proceed with model update
      const cUnit = _.find(that.currency.units, {id: cUnitId});
      const dUnit = bucket.grid.units[cUnitId];
      // Calculate total value of single denomination unit
      calculateUnitValue(dUnit, cUnit);
      // Recalculate bucket value & update associated objects
      recalculateBucket(bucket);
    };

    that.onAllowOverdraftChange = () => {
      that.validate();
      updateObjectRef();
    };

    const resetGrid = (bucket) => {
      if (!bucket || !bucket.grid || !bucket.grid.units) return;
      bucket.totalValue = 0;
      _.forEach(bucket.grid.units, u => u.count = 0);
    };

    const resetBundle = () => {
      that.bundle.totalValue = 0;
      resetGrid(that.bundle.incoming);
      resetGrid(that.bundle.outgoing);
    };

    // Apply initial data to denomination grid ane evaluate it
    // WARNING! Given initial data must match denomination bundle model!
    const fillGrid = (bucket, data) => {
      // If buckets' grid is not initialized -> leave
      if (!bucket || !bucket.grid) return;
      // If initial data is invalid -> leave
      if (!data || !data.denomination || !data.denomination.units) return;
      // Otherwise iterate over grid cells and fill them
      _.forEach(data.denomination.units, unit => {
        // Find corresponding (same [currencyUnitId] unit in grid
        const cell = _.find(bucket.grid.units, {currencyUnitId: unit.currencyUnitId});
        if (cell) {
          cell.count = unit.count;
          that.onDenominationCountChange(bucket, cell.currencyUnitId)
        }
      })
    };

    const applyInitialData = () => {
      // If [initialData] is not defined -> leave immediately
      const bundle = that.initialData;
      if (!bundle) return;
      // Reset bundle
      resetBundle();
      // Conditionally initialize buckets
      if (bundle.incoming) fillGrid(that.bundle.incoming, bundle.incoming);
      if (bundle.outgoing) fillGrid(that.bundle.outgoing, bundle.outgoing);
      // Recalculate bucket value & update associated objects
      recalculateBucket(that.bundle.outgoing, true);
    };

    const solveOutgoingDenomination = (amount) => {
      // Auto solver is available only for OUTGOING denominations
      if (that.readOnly == true || that.disableSolver == true || that.group !== 'OUTGOING') return;
      // Initialize solver command
      const denominationCommand = {
        "branchId": authentication.context.branchId,
        "currencyId": that.currency.id,
        "amount": amount,
        "roundingScale": "HALF_PESO"
      };
      // Fetch solver result and apply it as a initialData
      http.post('/currencies/denomination/auto', denominationCommand, {nxLoaderSkip: true})
        .success((result) => {
          that.initialData = {
            outgoing: {
              denomination: {
                currencyId: result.currencyId,
                units: result.units
              }
            }
          };
          applyInitialData();
        });
    };

    // After each change of [totalValueRef] update overdraft & validate denomination model
    this.$onChanges = function (changes) {
      if (changes.hasOwnProperty('initialData')) applyInitialData();
      if (changes.hasOwnProperty('totalValueRef')) {
        // Read new value reference
        const amount = new BigNumber(that.totalValueRef).dp(2).toNumber();
        // If amount is null/undefined or negative -> reset bundle
        if (!amount || amount <= 0) {
          resetBundle();
        } else {
          solveOutgoingDenomination(amount);
          updateOverdraft();
        }
        updateObjectRef();
      }
    };

    const sub = currencyCache.toObservable()
      .map((currencies) => {
        // Read DENOMINATION_LEVEL property and match to given level
        that.supportsDenomination = false;
        const componentLevel = getLevel();
        if (componentLevel !== 'NONE') {
          const denominationLevelProperty = systemPropertyService.getPropertyDescriptor('DENOMINATION_LEVEL');
          if (denominationLevelProperty && denominationLevelProperty.value && _.includes(Object.keys(supportedLevels), denominationLevelProperty.value)) {
            // If system denomination level is greater than or equal to component level -> enable component
            that.supportsDenomination = supportedLevels[componentLevel] <= supportedLevels[denominationLevelProperty.value];
          }
          const denominationOverdraftProperty = systemPropertyService.getProperty('DENOMINATION_OVERDRAFT_THRESHOLD');
          if(denominationOverdraftProperty) {
            that.overdraftThreshold = new BigNumber(denominationOverdraftProperty)
                .dividedBy(1000000)
                .toNumber(); //FIXME: CS-25 : SystemProperties endpoint should return type specific and ready to use values
          }
        }
        // Push [currencies] forward
        return currencies;
      })
      .subscribe(async currencies => {
        // Initialize component only if denomination is supported
        if (!that.supportsDenomination) return;
        // Initialize referenced object attribute names (for units & currency)
        initializeAttrNames();
        // Assign currencies
        that.currencies = currencies;
        that.currency = getCurrency(currencies);
        // If currency at refObject is not set -> set is
        if (!that.objectRef[that.currencyAttrName]) that.objectRef[that.currencyAttrName] = that.currency.id;
        // Initialize empty denomination bundle
        initializeBundle(that.currency);
        // If data provider is given -> fetch data and apply it to component
        if (that.initialData) applyInitialData(that.initialData);
        // Perform initial validation
        that.validate();
      });

    that.$onDestroy = () => {
      sub.unsubscribe();
    };
  }
});
