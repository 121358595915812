import nxModule from 'nxModule';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

const templateUrl = require('./customer-breadcrumbs.template.html');

nxModule.component('customerBreadcrumbs', {
    templateUrl: templateUrl,
    bindings: {
      /**
       * Either 'CUSTOMER' or 'GROUP'
       * If null, the default of CUSTOMER is chosen.
       */
      mode: '<'
    },
    controller: function ($route, breadcrumbs, customerCache, groupCustomerCache) {
      const that = this;
      that.breadcrumbsLoaded = false;
      const cache = that.mode === 'GROUP' ? groupCustomerCache : customerCache;
      const customerId = $route.current.params['customerId'];

      that.$onInit = async () => {
        if (!customerId) {
          return;
        }

        let profile;
        if (that.mode === 'GROUP') {
          profile = await cache.profile(customerId).toPromise();
        } else {
          profile = await cache.profile(customerId, true, true).toPromise();
        }

        if (profile) {
          setBreadcrumbs(breadcrumbs, 'customer', profile.effectiveName);
          that.breadcrumbsLoaded = true;
        }
      }
    }
  }
);