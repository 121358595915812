import {IFormController, ILocationService} from 'angular';
import {Breadcrumbs} from 'angular-breadcrumbs';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {Agent, AgentsBranchCache} from 'components/agent-banking/service/agents-branch.cache.types';
import {AgentsCustomerService} from 'components/agent-banking/service/agents-customer.service';
import {CustomerProfile} from 'components/customer/profile/customer-profile.types';
import {ProfileUpdateService} from 'components/customer/profile/profile-update.service.types';
import {CustomerCache} from 'components/service/customer.cache.types';
import {CustomerService} from 'components/service/customer.service';
import {UserCache} from 'components/service/users/user.cache';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import moment from 'moment';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';
import './agents-details.style.less';

import templateUrl from './agents-details.template.html';

class AgentsDetails {

  editMode: boolean = true;
  isDeviceEdited: boolean = false;
  branchId!: number;
  agentId!: number;
  candidacyId!: number;
  agent: Partial<Agent> = {};
  customer: Partial<CustomerProfile> = {};
  branch!: Branch;
  addressesForm!: IFormController;
  individualDataForm!: IFormController;

  constructor(private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private agentsBranchCache: AgentsBranchCache,
              private userCache: UserCache,
              private customerCache: CustomerCache,
              private customerService: CustomerService,
              private profileUpdateService: ProfileUpdateService,
              private http: HttpService,
              private breadcrumbs: Breadcrumbs,
              private agentsCustomerService: AgentsCustomerService) {
  }

  async $onInit(): Promise<void> {
    this.branchId = this.branch.id;
    await (this.agentId ? this.initExistingAgent() : this.initNewAgent());

    setBreadcrumbs(this.breadcrumbs,  'agents-list-label', this.branch.name);
    setBreadcrumbs(
      this.breadcrumbs,
      'agent-details-label',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.agent.id ? this.agent.effectiveName : 'New Agent'
    );

  }

  async initNewAgent() {
    this.agent = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workingHoursFrom: moment('09:00:00', 'HH:mm:ss').toDate(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workingHoursTo: moment('17:00:00', 'HH:mm:ss').toDate(),
      device: {},
      password: {

      }
    };
    const candidacy = await this.http.get(`/agents/candidacy/${this.candidacyId}`).toPromise();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.customer = await this.agentsCustomerService.fetchCustomer(candidacy.customerId);
    this.agent.customerId = this.customer.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.agent.code = candidacy.code;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.agent.brdReportId = candidacy.brdReportId;
  }

  async initExistingAgent(): Promise<void> {
    const agents = await this.agentsBranchCache.withParam(this.branchId).toPromise();
    const agent = _.cloneDeep(agents.find(agent => agent.id === this.agentId));
    if (agent) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      agent.workingHoursFrom = moment(agent.workingHoursFrom, 'HH:mm:ss').toDate();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      agent.workingHoursTo = moment(agent.workingHoursTo, 'HH:mm:ss').toDate();
      this.customer = await this.agentsCustomerService.fetchCustomer(agent.customerId);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.agent = {
      ...agent,
      customerId: this.customer.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      password: {

      }
    };
  }

  hasIncompleteData(): boolean {
    return ((this.individualDataForm && this.individualDataForm.$invalid) || (this.addressesForm && this.addressesForm.$invalid));
  }

  async goToCustomerProfile(): Promise<void> {
    const confirmed = await this.confirmation('You are going to exit application form. Do you want to proceed?');
    if (confirmed) {
      this.$location.path(`/customer/${this.customer.id}/profile`)
    }
  }

  async createOrUpdate(): Promise<void> {
    const request = _.cloneDeep(this.agent);
    const customerInput = this.profileUpdateService.convertToCommandInput(this.customer);
    // Fix for the following scenario:
    // 1. We use existing customer with primary non agent available income source
    // 2. we create agent application, which fails because we have multiple primary income sources
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    request.idDocuments = await this.agentsCustomerService.addNonAgentIdDocuments(this.customer.id, customerInput.idDocuments ?? []);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    request.incomeSources = await this.agentsCustomerService.addNonAgentIncomeSources(this.customer.id, customerInput.incomeSources ?? []);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    request.candidacyId = this.candidacyId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    request.branchId = this.branchId;
    request.workingHoursFrom = moment(request.workingHoursFrom).format('HH:mm:ss');
    request.workingHoursTo = moment(request.workingHoursTo).format('HH:mm:ss');
    if (this.agent.id) {
      await this.update(request);
    } else {
      await this.create(request);
    }
  }

  async update(request: unknown): Promise<void> {
    const confirmed = await this.confirmation('Update Agent?');
    if (confirmed) {
      const response = await this.command.execute('UpdateAgent', request).toPromise();
      this.refreshCacheAndRedirect(response);
    }
  }

  async create(request: unknown): Promise<void> {
    const confirmed = await this.confirmation('You are about to create an agent. You will no longer be able to edit agent application. This action is irreversible. Do you want to proceed?');
    if (confirmed) {
      const response = await this.command.execute('CreateAgent', request).toPromise();
      this.refreshCacheAndRedirect(response);
    }
  }

  refreshCacheAndRedirect(response: CommandOutputWrapper<unknown>): void {
    if (response && !response.approvalRequired) {
      this.agentsBranchCache.withParam(this.branchId).refetch();
      this.userCache.withParam().refetch();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.customerCache.profile(this.agent.customerId).refetch();
      this.redirectBack();
    }
  }

  redirectBack(): void {
    this.$location.path(`/agent-banking/agents/${this.branchId}`);
  }

  configureMfa() {
    this.$location.path(`/agent-banking/agents/${this.branchId}/agent/${this.agent.id}/user/${this.agent.userId}/mfa`);
  }
}

nxModule.component('agentsDetails', {
  templateUrl: templateUrl,
  controller: AgentsDetails,
  bindings: {
    candidacyId: '<',
    agentId: '<',
    branch: '<'
  }
});