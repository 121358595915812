import nxModule from 'nxModule';

const templateUrl = require('./glue-container.template.html');
nxModule.component('glueContainer', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    'prepend': '<',
    'append': '<',
    'appendPercentage': '<'
  }
});