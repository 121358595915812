import nxModule from 'nxModule';

import templateUrl from './batch-deped-details.template.html';
import {ILocationService} from "angular";
import {ReportModal} from "components/report/modal/report-modal.service.types";
import {HttpService} from "shared/utils/httpService";

interface DepEdPaymentLine {
  id?: number;
  paymentFileId: number;
  loanId?: number;
  officialReceipt: string;
  amount: number;
  productNumber: string;
  customerName: string;
  employeeNumber: string;
  policyNumber: string;
  valid: boolean;
  error: string;
}

class BatchDepedDetails {
  // BINDINGS
  fileId!: number;

  lines: DepEdPaymentLine[] = [];
  statuses = ['POSTED', 'UNPOSTED'];
  status = 'POSTED';

  constructor(private $location: ILocationService,
              private http: HttpService,
              private reportModal: ReportModal) {}

  async $onInit() {
    this.lines = await this.http.get<DepEdPaymentLine[]>(`/deped/files/${this.fileId}/details`).toPromise();
  }

  display(): void {
    this.reportModal.display({
      params: { paymentFileId: this.fileId, status: this.status },
      reportCode: 'LoanDepEdPaymentReport',
      hideXls: false
    });
  }

  goBack(): void {
    this.$location.path('/batch/deped-loans/payments');
  }
}


nxModule.component('batchDepEdDetails', {
  templateUrl,
  bindings: {
    fileId: '<'
  },
  controller: BatchDepedDetails
});