import nxModule from 'nxModule';

import templateUrl from './payment-interval-details.template.html';
import {
  BasePaymentInterval,
  CicPaymentPeriodicity,
  MidasPaymentPeriodicity,
  PaymentIntervalDefinition,
  PaymentIntervalType
} from "components/administration/loan/intervals/payment-interval.types";
import {ILocationService} from "angular";
import _ from "lodash";
import {HttpService} from "shared/utils/httpService";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

class PaymentIntervalDetails {
  private paymentIntervalDefinition!: PaymentIntervalDefinition;
  private editMode = false;
  private intervalTypes = Object.entries(PaymentIntervalType);
  private basePaymentIntervals = Object.entries(BasePaymentInterval);
  private cicTypes = Object.entries(CicPaymentPeriodicity);
  private midasTypes = Object.entries(MidasPaymentPeriodicity);
  private daysOfTheMonth = _.range(1, 32).map(number => ({key: number}));

  private selectConfig = {
    placeholder: 'Select payment days',
    valueField: 'key',
    labelField: 'key',
    maxItems: null,
  };
  constructor(private $route: NxRouteService, private $location: ILocationService, private http: HttpService, private confirmation: Confirmation, private command: CommandService) {

  }

  async $onInit(): Promise<void> {
    if (this.editMode) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const intervalId = this.$route.current.params['intervalId'];
      const paymentIntervals : PaymentIntervalDefinition[] = await this.http.get<PaymentIntervalDefinition[]>(`/products/loans/intervals`).toPromise();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.paymentIntervalDefinition = paymentIntervals.find((i : PaymentIntervalDefinition) => Number(i.id) === Number(intervalId));
      this.intervalTypes = this.intervalTypes.filter(k => ['SET_DAYS_OF_THE_MONTH', 'INTERVAL'].includes(k[0]) || k[0] === this.paymentIntervalDefinition.paymentIntervalType);
    } else {
      // Only allow creating new intervals with these two types.
      this.intervalTypes = this.intervalTypes.filter(k => ['SET_DAYS_OF_THE_MONTH', 'INTERVAL'].includes(k[0]));
    }
  }

  async save(): Promise<void> {
    const command = this.editMode ? 'EditPaymentIntervalDefinition' : 'CreatePaymentIntervalDefinition';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await this.command.execute(command, this.paymentIntervalDefinition).toPromise();
    if (response && !response.approvalRequired) {
      this.redirectBack();
    }
  }

  redirectBack(): void {
    this.$location.path('/admin/loan-products');
  }

}
nxModule.component('paymentIntervalDetails', {
  templateUrl,
  bindings: {
    editMode: '<',
  },
  controller: PaymentIntervalDetails
});
