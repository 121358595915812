import nxModule from 'nxModule';
import moment, {Moment} from 'moment';
import _ from 'lodash';

import templateUrl from './date-granted.template.html';
import systemPropertyService from "../../../../../react/system/systemPropertyService";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import Authentication from "shared/utils/authentication";
import {BranchService} from 'components/service/branch.service';
import {SystemDateService} from 'components/service/system-date.service.types';

class DateGrantedComponent {

  public loanTypeId!: number;

  public backdatingAllowed: boolean = false;
  public postdatingAllowed: boolean = false;
  /**
   * Whether to override dateGranted with systemDate
   */
  public override: boolean = false;

  public dateGranted!: string;
  public systemDate?: Moment;

  public minDateGranted: Date | null = null;
  public maxDateGranted: Date | null = null;

  public backdated: boolean = false;
  public disabled: boolean = false;

  constructor(private branchService: BranchService,
              private authentication: Authentication,
              private systemDateService: SystemDateService,
              private loanProductsCache: LoanProductsCache) {
  }

  async $onInit(): Promise<void> {
    const [products, branches] = await Promise.all([
      this.loanProductsCache.toPromise(),
      this.branchService.toPromise()
    ]);

    const product = _.find(products, p => p.id == this.loanTypeId)!;
    this.backdatingAllowed = product.backdatingAllowed;

    this.postdatingAllowed = systemPropertyService.getProperty('LOAN_FUTURE_DATE_GRANTED_ALLOWED') === 'TRUE';

    const userBranch = _.find(branches, {id: this.authentication.context.branchId})!;
    const systemDate = this.systemDateService.getSystemDate(userBranch);
    this.systemDate = systemDate;

    if (this.override) {
      this.dateGranted = systemDate.format('YYYY-MM-DD');
    }

    this.maxDateGranted = this.postdatingAllowed ? null : systemDate.toDate();
    this.minDateGranted = this.backdatingAllowed ? null : systemDate.toDate();
    this.updateBackdated();
  }

  updateBackdated(): void {
    this.backdated = this.dateGranted ? moment(this.dateGranted).isBefore(this.systemDate) : false;
  }
}

nxModule.component('dateGranted', {
  templateUrl: templateUrl,
  bindings: {
    dateGranted: '=',
    override: '<',
    loanTypeId: '<',
    disabled: '<'
  },
  controller: DateGrantedComponent
});
