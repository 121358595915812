import {FeeDefinition} from 'components/service/fees/fee.types';
import {Fee, FeeOverride} from 'components/service/product.types';
import nxModule from 'nxModule';
import BigNumber from "bignumber.js";

export class TermDepositPartialWithdrawalService {

  calculateMaxWithdrawalAmount(balance: number, minimalBalance: number, netInterest: number, preterminationFee: number, beforeRolloverFee = 0, afterRolloverFee = 0) : number {
    const maxWithdrawalAmount =  new BigNumber(0)
      .plus(balance)
      .plus(netInterest)
      .minus(minimalBalance)
      .minus(preterminationFee)
      .minus(beforeRolloverFee)
      .minus(afterRolloverFee)
      .dp(2)
      .toNumber();
    return maxWithdrawalAmount < 0 ? 0 : maxWithdrawalAmount;
  }

  calculateRolloverAmount(balance: number, netInterest: number, withdrawAmount: number, preterminationFee: number, beforeRolloverFee: number, afterRolloverFee: number) : number {
    return new BigNumber(0)
      .plus(balance)
      .plus(netInterest)
      .minus(withdrawAmount)
      .minus(preterminationFee)
      .minus(beforeRolloverFee)
      .minus(afterRolloverFee)
      .dp(2)
      .toNumber();
  }

  sum(fees: PartialWithdrawalFee[], useOverrideFee = false): BigNumber {
    return fees
      .filter(fee => !fee.bankExpense)
      .map(fee => {
        if(useOverrideFee) {
          return fee.overrideAmount;
        }
        return fee.amount;
      })
      .reduce((sum, amount) => sum.plus(amount || 0), new BigNumber(0));
  }

  mapPartialWithdrawalFeeDetails(feeDefs: FeeDefinition[], fee:Fee): PartialWithdrawalFee {
    const feeDefinition = feeDefs.find(fd => fd.id === fee.feeDefinitionId);
    return {
      ...fee,
      feeName: feeDefinition?.feeName,
      bankExpense: feeDefinition?.bankExpense,
      overrideAmount: fee.amount
    }
  }

  applySimulatedPartialWithdrawalFee(partialWithdrawalFees: PartialWithdrawalFee[], simulatedPartialWithdrawalFees: PartialWithdrawalFee[], useOverrideFee = false): PartialWithdrawalFee[]{
    const partialWithdrawalFeesMap = new Map(partialWithdrawalFees?.map(fee => [fee.feeDefinitionId, fee]));
     for (const simulatedFee of simulatedPartialWithdrawalFees) {
       const targetFee = partialWithdrawalFeesMap.get(simulatedFee.feeDefinitionId);
       let updatedFee = null;
       if (targetFee) {
         updatedFee = useOverrideFee ? {...targetFee, overrideAmount: simulatedFee.overrideAmount} : {...targetFee, amount: simulatedFee.amount};
       } else {
         updatedFee = useOverrideFee ? {...simulatedFee, amount: 0} : {...simulatedFee};
       }
       partialWithdrawalFeesMap.set(simulatedFee.feeDefinitionId, updatedFee);

     }
     return Array.from(partialWithdrawalFeesMap)
       .map(([, fee]) => (fee));
  }

  mapFees(fees: PartialWithdrawalFee[], useOverrideFee = false): FeeOverride[] {
    return fees?.map(fee => ({
      feeDefinitionId: fee.feeDefinitionId,
      applyOn: fee.applyOn,
      applyPredicates: fee.applyPredicates,
      paidUpfront: fee.paidUpfront,
      amount: !useOverrideFee ? fee.amount : fee.overrideAmount
    }));
  }
}

export interface PartialWithdrawalFee extends Fee{
  bankExpense?: boolean;
  overrideAmount?: number;
}

nxModule.service('termDepositPartialWithdrawalService', TermDepositPartialWithdrawalService);
