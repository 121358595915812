import nxModule from 'nxModule';
import {productAvailability} from 'constants/productDefinition';

import templateUrl from './product-availability.template.html'

class ProductAvailability {
  constructor() {
    this.productAvailabilityModes = productAvailability;
  }
}

nxModule.component('productAvailability', {
  templateUrl,
  bindings: {
    branchesToSelect: '<',
    productAvailability: '=',
    availableInBranchIds: '='
  },
  controller: ProductAvailability
});