import _ from 'lodash';
import nxModule from 'nxModule';

import reportTypeMapping from './reportTypeMapping';
import templateUrl from './report-list.template.html';
import {setBreadcrumbs} from '../../shared/utils/set-breadcrumbs';

class ReportList {
  constructor($routeParams, http, breadcrumbs, notification) {
    this.$routeParams = $routeParams;
    this.http = http;
    this.breadcrumbs = breadcrumbs;
    this.notification = notification;
  }

  async $onInit() {
    if (!this.reportType) {
      this.reportType = _.get(this.$routeParams, 'reportType');
    }

    this.customerId = _.get(this.$routeParams, 'customerId');

    const reportMappingEntry = reportTypeMapping[this.reportType];
    this.reportTypeName = reportMappingEntry ? reportMappingEntry.label : '';
    this.title = this.reportTypeName;

    setBreadcrumbs(this.breadcrumbs, 'report-list', this.reportTypeName);

    const reports = await this.http.get('/reports/descriptors').toPromise();
    this.reports = _(reports)
      .filter(report => {
        return reportMappingEntry && report.type === reportMappingEntry.type
      })
      .filter(report => report.visible)
      .sortBy(report => report.name)
      .value();
  };

  openLink(report) {
    // if customerId is present in the route then these reports are of CUSTOMER_STATEMENTS type
    if (this.customerId) {
      return `#!/customer/${this.customerId}/statements/${report.code}`;
    }

    return `#!/report/${this.reportType}/reports/${report.code}`;
  }
}

nxModule.component('reportList', {
  templateUrl,
  controller: ReportList,
  bindings: {
    reportType: '<'
  }
});