import config from '../config';
import {NxToken} from "./TokenTypes";
import CmcService from "../system/CmcService";

const cmcService = new CmcService();
class TokenHttpService {
  async logout(token: string): Promise<void> {
    const cmcHeader = this.getCmcHeader();

    const resp = await fetch(config.apiPrefix + '/auth-tokens/logout', {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        ...cmcHeader
      }
    });

    if(!resp.ok) {
      throw new Error(`Unable to logout: ${resp}`);
    }

    return;
  }

  private getCmcHeader(): {'NX-CMC': string} | null {
    const cmc = cmcService.getCmcToken();
    if(!cmc) {
      return null;
    }

    return {'NX-CMC': cmc};
  }

  async refresh(token: string): Promise<NxToken> {
    const cmcHeader = this.getCmcHeader();
    const resp = await fetch(config.apiPrefix + '/auth-tokens', {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${token}`,
        ...cmcHeader
      }
    });

    if(!resp.ok) {
      throw new Error(`Unable to refresh token: ${resp}`);
    }

    return resp.json();
  }
}

export default TokenHttpService;