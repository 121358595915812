export type WorkingDayStatus = 'PENDING' | 'START_DAY_PROCESSING' | 'STARTED' | 'COUNTER_CLOSED' | 'CLOSED' | 'END_DAY_PROCESSING';

export type WorkingDayStatusMapping = {
  code: WorkingDayStatus;
  label: string;
}

export const statusMappings: WorkingDayStatusMapping[] = [{
  code: 'PENDING',
  label: 'Pending start of day'
}, {
  code: 'START_DAY_PROCESSING',
  label: 'Start of day processing'
}, {
  code: 'STARTED',
  label: 'Active'
}, {
  code: 'COUNTER_CLOSED',
  label: 'Counter closed'
}, {
  code: 'CLOSED',
  label: 'Closed'
}, {
  code: 'END_DAY_PROCESSING',
  label: 'End of day processing'
}];

export interface WorkingDay {
  id: number;
  branchId: number;
  clearingDay: boolean;
  clearingPerformed: boolean;
  bspApproval: boolean;
  startTime: string;
  startUserId: number;
  systemDate: string;
  status: WorkingDayStatus;
  endTime?: string | null;
  endUserId?: number | null;
  branchHoliday?: Holiday;
  bankHoliday?: Holiday;
}

export interface Holiday {
  id: number;
  branchId: number;
  date: string;
  type: HolidayType;
  name: string;
}

export type SearchMode = 'BRANCH' | 'BANK' | 'ALL';

export type HolidayType = 'WEEKEND' | 'HOLIDAY';

/**
 * Defines the maximum number of days allowed between system date and posting date upon creating a new branch
 */
export const postingDaysThreshold = 20;
