import _ from 'lodash';
import moment from 'moment';

import nxModule from 'nxModule';
import {Observable} from 'rxjs/Observable';
import {Statuses, typeColor} from './action-list.component';

const templateUrl = require('./action-details.template.html');


nxModule.component('dashboardActionDetails', {
  templateUrl,
  bindings: {
    action: '<',
    onHide: '<',
    onSuccess: '<'
  },
  controller: function($filter, $q, $location, customerCache, productDefinitionService, http, dict, dashboardActionService, availableTasksCache) {
    const that = this;

    that.keyValueDetails = [];
    that.overrideTask = null;

    const formatUser = user => user ? `${user.lastName}, ${user.firstName} ${user.middleName || ''} (${user.username})`: '-';

    const fetchTaskDetails = (actionId, options) =>
      http.get(`/tasks/${actionId}/object`, options);

    Object.assign(that, dashboardActionService.getApprovalLevelCalculator());

    that.localOverrideAction = (action, $event, level) => {
      if ($event) $event.stopPropagation();
      that.overrideTask = {
        ...action,
        localOverrideLevel: level,
      };
    };

    that.approveAction = (task, $event, level) => dashboardActionService.approve(task, $event, level, (task) => {
      that.onSuccess();
    });

    that.reject = (task, $event) => dashboardActionService.reject(task, $event, (task) => {
      that.onSuccess();
    });

    that.pinEntered = (task, pin, user) => dashboardActionService.pinEntered(task, pin, user, (task) => {
      that.onSuccess();
    });

    that.revoke = (task, $event) => dashboardActionService.cancel(task, $event, (task) => {
      that.onSuccess();
    });


    const translateName = (action) => {
      let name = action.name;
      if (action.taskGroup === 'COMMAND_APPROVAL') {
        name = $filter('translateEnum')(name, 'COMMAND');
      }
      return name;
    };

    that.$onInit = async () => {
      const action = that.action;
      const keyValueDetails = [
        {
          label: 'Action no',
          value: action.id,
        }, {
          label: 'Action status',
          value: Statuses[action.approvalStatus],
        }, {
          label: 'Action type',
          value: translateName(action),
          clazz: typeColor(action),
        }, {
          label: 'Initiated by',
          value: formatUser(action.registrationUser),
        }, {
          label: 'Approved/Rejected by',
          value: action.approvalUser ? formatUser(action.approvalUser) : '-',
        }, {
          label: 'Approved/Rejected on',
          value: action.approvalTime ? moment(action.approvalTime).format('MM/DD/YYYY') : '-',
        }
      ];

      that.keyValueDetails = keyValueDetails;

      if (action.taskGroup === 'OPERATION') {
        const deferred = $q.defer();
        that.requestTimeout = deferred;

        const operation = await fetchTaskDetails(action.id, {
          timeout: deferred.promise,
        }).toPromise();

        const customerId = operation.attributes['CUSTOMER_ID'];
        const customerProfile = customerId ? await customerCache.profile(customerId).toPromise() : {};

        const productDefinitions = await productDefinitionService.toPromise();
        const product = _.find(productDefinitions, {id: operation.target.typeId});

        keyValueDetails.push(...[{
            label: 'Total amount',
            value: $filter('nxCurrency')(action.amount),
          }, {
            label: 'Customer name',
            value: customerProfile.effectiveName ?? '-',
          }, {
            label: 'Product name',
            value: product?.productName ?? '-',
          }]
        );

      } else if(action.taskGroup === 'LEDGER_ACTION') {
        throw new Error("Not yet implemented");
      } else if(action.taskGroup === 'COMMAND_APPROVAL') {
        const details = Object.keys(action.description).filter(d => 'DETAIL' === action.description[d].group);
        for (const property of details) {
          const description = action.description[property];
          const value = description.value;

          let valuePromise;
          if (description.type === 'CASH') {
            valuePromise = Promise.resolve($filter('nxCurrency')(value));
          } else if (description.type === 'PERCENTAGE') {
            valuePromise = Promise.resolve($filter('rate')(value));
          } else if (description.type === 'DATE') {
            valuePromise = Promise.resolve(value ? moment(value).format('MM/DD/YYYY') : '-');
          } else if (description.type === 'DICT') {
            valuePromise = new Promise((res, rej) => dict.onLoadingComplete(() => res(dict.getDescription(property, value))));
          } else {
            // show value as is
            valuePromise = Promise.resolve(value);
          }

          keyValueDetails.push({
            id: description.id,
            label: property,
            value: valuePromise,
            translate: description.translate,
            type: description.type
          });
        }

        // resolve values
        keyValueDetails.forEach(async obj => obj.value = await obj.value);

        // restore order
        keyValueDetails.sort((a, b) => a.id - b.id);
      } else if(action.taskGroup === 'CUSTOMER') {
        const deferred = $q.defer();
        that.requestTimeout = deferred;
        that.subscription = Observable.fromPromise(
          fetchTaskDetails(action.id, {
            timeout: deferred.promise,
          }).toPromise())
            .subscribe(details => {
              const individualData = details.individualData;
              if(!individualData) {
                return;
              }

              keyValueDetails.push(...[{
                label: 'Customer name',
                value: formatUser(individualData),
              }, {
                label: 'Customer birthday',
                value: individualData.birthDate ? moment(individualData.birthDate).format('MM/DD/YYYY') : '-',
              }]);

              dict.onLoadingComplete(() => {
                keyValueDetails.push({
                  label: 'Customer risk level',
                  value: dict.getDescription('CUSTOMER_RISK_LEVEL', individualData.riskLevelId),
                });
              });
            });
      }
    };

    that.navigateToCustomerProfile = () => {
      let prospectId = that.action.objectId;
      if (['CreateCustomer', 'ProcessCustomerApplication', 'ProcessAgentApplication', 'ApproveAgentCandidacy'].includes(that.action.name)) {
        prospectId = _.get(that.action, 'commandDescriptor.context.prospectId');
      }
      $location.path(`/dashboard/actions/${that.action.id}/modes/${that.action.displayMode}/objects/${prospectId}/view-profile`)
    };

    that.getCustomerId = () => {
      if (['CreateCustomer', 'ProcessCustomerApplication', 'ProcessAgentApplication', 'ApproveAgentCandidacy'].includes(that.action.name)) {
        return _.get(that.action, 'commandDescriptor.context.prospectId')
      }
      return null;
    }

    that.$onDestroy = () => {
      if(that.requestTimeout) {
        that.requestTimeout.resolve(true);
      }

      if(that.subscription) {
        that.subscription.unsubscribe();
      }
    }
  }
});
