import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';

const templateUrl = require('./loan-mis-group.template.html');
nxModule.component('loanMisGroup', {
  templateUrl,
  bindings: {
    misGroupId: '=',
    ngRequired: '<',
    ngDisabled: '<',
    assignedCategoryIds: '<',
    customerId: '<',
  },
  controller: function ($scope, $timeout, dict, misGroupsCache, hierarchyBuilder, customerCache) {

    let that = this;

    // mis groups
    that.misSelected = null;
    that.customerCategoryIds = [];
    that.restrictions = [];

    that.$onInit = async () => {
      const [groups, restrictions] = await Promise.all([
        misGroupsCache.toPromise(),
        customFieldService.readRestrictions({restrictedFieldType: 'MIS'})
      ]);

      that.restrictions = restrictions;
      $scope.$watchGroup(['$ctrl.assignedCategoryIds'], () => that.applyRestrictions());
      // skip for default application in group/batch loans
      if (this.customerId && this.customerId > 0) {
        const customer = await customerCache.profile(that.customerId).toPromise();
        that.customerCategoryIds = customer.categoryIds;
      }
      // build mis groups hierarchy
      let root = [];
      let idMap = {};
      hierarchyBuilder.buildHierarchy(groups, idMap, root);
      that.availableMisGroups = root;
      that.misGroupIdMap = idMap;
      if(that.misGroupId) {
        that.misGroup = that.misGroupIdMap[that.misGroupId];
      }
      that.applyRestrictions();
    }

    that.applyRestrictions = () => {
      if (that.assignedCategoryIds || that.customerCategoryIds) {
        if(that.misGroupId) {
          return;
        }

        const allAssignedCategoryIds = [...(that.assignedCategoryIds ?? []), ...(that.customerCategoryIds ?? [])];
        for (const restriction of that.restrictions) {
          if (restriction.customFieldCategoryId && allAssignedCategoryIds.includes(restriction.customFieldCategoryId)) {
            that.misGroupId = restriction.restrictedFieldId;
            that.misGroup = that.misGroupIdMap[that.misGroupId];
            break;
          }
        }
      }
    }

    that.onMisGroupChange = () => {
      that.misGroupId = that.misGroup ? that.misGroup.id : null;
    };

    that.$doCheck = () => {
      const misGroup = that.misGroup || {};
      if(that.misGroupId && misGroup.id !== that.misGroupId && that.misGroupIdMap) {
        that.misGroup = that.misGroupIdMap[that.misGroupId];
      }
    };

    that.label = node => node.name;
  }
});
