import nxModule from 'nxModule';
import stringify from 'csv-stringify/lib/sync';


import templateUrl from './force-closing-accounts.template.html';
import {ActionCategory} from "../../../administration/transactions/action-category.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

interface ForceClosingAccountsInput {
  fileId: number | null,
  remarks: string,
  categoryId: number | null
}

type ForceClosingAccountsOutput = {
  rows: {
    productNumber: string,
    failed: boolean,
    failedReason?: string
  }[]
};

class ForceClosingAccounts {
  protected parameters: ForceClosingAccountsInput = {
    fileId: null,
    remarks: 'Force closing accounts',
    categoryId: null
  };

  protected category: ActionCategory | null = null;

  private readonly sampleFile = new Blob([`product_number
1234-123-123
2345-123-123`], {type: 'text/csv'});

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private popup: Popup,
              private $route: NxRouteService,
              private command: CommandService,
              private customerCache: CustomerCache) {}


  downloadSampleFile(): void {
    this.downloadFile(this.sampleFile, 'force-closing-accounts-sample.csv');
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed?`,
    });

    if(confirmed) {
      const commandOutput = await this.command.execute<unknown, ForceClosingAccountsOutput>('ForceClosingAccounts', {
        ...this.parameters,
        categoryId: this.category?.id
      }).toPromise();
      // command is technical, so we should always have access

      await new Promise(resolve => {
        this.popup({
          text: 'Batch closing finished. A file now be downloaded with results.',
          callback: () => resolve(true)
        });
      });

      if (this.customerCache.loadedCustomerId) {
        this.customerCache.depositAccounts(this.customerCache.loadedCustomerId).refetch();
      }

      const rows = commandOutput.output.rows;
      const csv = stringify(rows, {
        bom: false,
        header: true,
        cast: {
          boolean: value => value ? 'yes' : 'no'
        }
      });

      const outputFile = new Blob([csv], {type: 'text/csv'});
      this.downloadFile(outputFile, 'force-closing-accounts.csv');
      this.$route.reload();
    }
  }

  downloadFile(blob: Blob, filename: string): void {
    const fileUrl = window.URL.createObjectURL(blob);
    const a = window.document.createElement('a');
    a.href = fileUrl;
    a.download = filename;
    a.click();
  }
}

nxModule.component('forceClosingAccounts', {
  templateUrl,
  controller: ForceClosingAccounts
});
