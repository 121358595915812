import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('printsCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/print'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.prints,
    cacheName: 'prints'
  }));
