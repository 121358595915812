import clsx from 'clsx';
import React from 'react';
import {DragDropContext, Draggable, DraggableProvided, Droppable, DropResult} from 'react-beautiful-dnd';
import styles from './DragDropItems.scss';

export interface DraggableItem {
  key: string;
}

interface Props<T extends DraggableItem> {
  droppableId: string;
  items: T[];
  setItems: (items: T[]) => void;
  // The rendered item should be DragDropItem as a child of ReactElement.
  render: (provided: DraggableProvided, isDragging: boolean, item: T) => React.ReactElement;
  isDragDisabled?: boolean;
}

const reorder = <T,> (list: T[], startIndex: number, endIndex: number): T[] => {
  const result: T[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function DragDropItems<T extends DraggableItem>(
  {droppableId, items, setItems, render, isDragDisabled = false}: Props<T>): React.ReactElement {

  const onDragEnd = (result: DropResult): void => {
    // dropped inside the list
    if (result.destination) {
      setItems(reorder(items, result.source.index, result.destination.index));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot): React.ReactElement => (
          <div {...provided.droppableProps}
               className={clsx(styles.items, {[styles.isDraggingOver]: snapshot.isDraggingOver})}
               ref={provided.innerRef}>
            {
              items.map((item, index) =>
                <Draggable key={item.key} draggableId={item.key} index={index} isDragDisabled={isDragDisabled}>
                  {
                    (provided, snapshot): React.ReactElement => render(provided, snapshot.isDragging, item)
                  }
                </Draggable>
              )
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
