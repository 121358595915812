import nxModule from 'nxModule';
import {IComponentController, ITimeoutService} from 'angular';

import {StatusService} from "../status/status.service";
import {CommandService} from "shared/utils/command/command.types";
import config from "config";
import {Loader} from "components/technical/loader.types";
import {CommandDescriptor} from "command/CommandTypes";


const waitAsync = ($timeout: ITimeoutService, time: number): Promise<void> => new Promise(resolve => $timeout(resolve, time));

class ExecutingAsyncCommandCheckController implements IComponentController {
  constructor(command: CommandService, loader: Loader, $timeout: ITimeoutService, statusService: StatusService) {
    // if there is a new status emitted, we should ignore old async executing commands
    let lastEmittedStatus = null;
    let unsubscribed = false;
    const failedCommands: CommandDescriptor[] = [];
    const failedCommandIds = new Set<number>();

    const subscription = statusService.getStatusObs()
      .subscribe(async status => {
        lastEmittedStatus = status;

        const unsubscribe = async () => {
          console.log('Stopping waiting for async executing commands');
          subscription.unsubscribe();
          unsubscribed  = true;

          for(const descriptor of failedCommands) {
            await new Promise(resolve => command.defaultErrorCallback(descriptor.simpleName, descriptor, resolve));
          }
        };

        const {asyncExecutingCommands = []} = status;
        console.log('Checking for async executing commands', asyncExecutingCommands);
        if (asyncExecutingCommands.length === 0) {
          unsubscribe();
          return;
        }

        const loaderId = loader.show('Waiting for async command execution');
        for(const descriptorId of asyncExecutingCommands) {
          let executedCommand = false;
          while(!executedCommand && !unsubscribed && lastEmittedStatus === status) {
            const descriptor = await command.getCommandDescriptorById(descriptorId);
            executedCommand = !['PENDING', 'EXECUTING'].includes(descriptor.status);

            if(descriptor.status === 'FAILED' && !failedCommandIds.has(descriptor.id)) {
              failedCommandIds.add(descriptor.id);
              failedCommands.push(descriptor);
            }

            if(!executedCommand) {
              // wait and then try to get descriptor again
              await waitAsync($timeout, config.asyncCommandPollingInterval);
            }
          }
        }

        loader.dismiss(loaderId);
        unsubscribe();
      });
  }
}

nxModule.component('executingAsyncCommandCheck', {
  controller: ExecutingAsyncCommandCheckController,
});