import {IController, INgModelController, IOnChangesObject} from 'angular';
import nxModule from 'nxModule';
import {EntHordeNode} from "../../technical/ent-horde/ent-horde.component";
import {Area} from "../../administration/areas/area.types";
import {FilterValue} from "./filter-value.types";

import templateUrl from './branch-filter.template.html';
import {HttpService} from 'shared/utils/httpService';

type AreaEntHordeNodeType = 'area' | 'branch';

interface AreaEntHordeNode extends EntHordeNode {
  name: string;
  type: AreaEntHordeNodeType;
  children: AreaEntHordeNode[];
}


class BranchFilter implements IController {
  ngModel!: INgModelController;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  values: number[];

  readonly nodeLabel = (node: {name: string}) => node.name;

  treeData: AreaEntHordeNode[] = [];

  private readonly areasPromise: Promise<Area[]>;
  options!: FilterValue[];

  constructor(private http: HttpService) {
    this.areasPromise = this.http.get<Area[]>('/management/areas').toPromise();
  }

  $onInit(): void {
    this.ngModel.$render = () => {
      this.values = this.modelValue();
    };
  }

  private modelValue(): number[] {
    return this.ngModel.$modelValue || [];
  }

  updateModel(): void {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.values);
  }

  uniqueSelectedBranchIds(): Set<number> {
    const sanitizedValues = (this.values || []).filter(value => value && value > 0); // keep only branches - they have positive ids
    return new Set<number>(sanitizedValues);
  }

  $onChanges(changes: IOnChangesObject): void {
    this.rebuildForest();
  }

  buildTree(area: Area): AreaEntHordeNode {
    const areaChildren: AreaEntHordeNode[] = (area.children || []).map(area => this.buildTree(area));
    const branchChildren: AreaEntHordeNode[] = (area.branchIds || []).map((branchId: number): AreaEntHordeNode => {
      const foundBranch = this.options.find(branch => branch.id === branchId);
      if(!foundBranch) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return null;
      }

      return {
        id: foundBranch.id,
        name: foundBranch.code,
        type: 'branch',
        children: []
      };
    }).filter(item => item);

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: area.id * -10, // avoid overlapping area and branch ids
      name: area.name,
      type: 'area',
      children: [...areaChildren, ...branchChildren]
    };
  }

  getBranchesRec(forest: AreaEntHordeNode[], branches: Set<number>): void {
    for(const node of forest) {
      if(node.type === 'branch') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        branches.add(node.id);
      }

      this.getBranchesRec(node.children, branches);
    }
  }

  getBranches(forest: AreaEntHordeNode[]): Set<number> {
    const branches = new Set<number>();
    this.getBranchesRec(forest, branches);
    return branches;
  }

  async rebuildForest(): Promise<void> {
    const areas = await this.areasPromise;
    const forest = areas.map(area => this.buildTree(area));
    const assignedBranches: Set<number> = this.getBranches(forest);
    const unassignedBranches = this.options.filter(option => !assignedBranches.has(option.id));
    for(const unassignedBranch of unassignedBranches) {
      forest.push({
        id: unassignedBranch.id,
        name: unassignedBranch.code,
        type: 'branch',
        children: []
      });
    }

    this.treeData = [{
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: -1,
      name: 'All branches',
      type: 'area',
      children: forest
    }];
  }
}

nxModule.component('branchFilter', {
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    options: '<',
    ngRequired: '<'
  },
  controller: BranchFilter
});
