import templateUrl from './term-deposit-advanced.template.html';
import nxModule from 'nxModule';
import NON_WORKING_DAY_ADJUSTMENT_STRATEGIES, {
  NonWorkingDayAdjustmentTranslation
} from '../../../common/product/types/product.types';
import {IFormController} from 'angular';
import {DepositType} from 'components/administration/term-deposit/common/term-deposit.types';
import './term-deposit-advanced.style.less';

class TermDepositAdvanced {

  protected deposit!: DepositType;
  protected form!: IFormController;

  protected onCreateAdjustmentStrategies: ReadonlyArray<NonWorkingDayAdjustmentTranslation> = NON_WORKING_DAY_ADJUSTMENT_STRATEGIES;

  onHonorHolidaysChange(): void {
    if (!this.deposit.honorHolidays) {
      this.deposit.maturityAdjustmentStrategy = undefined;
    }
  }
}

nxModule.component('termDepositAdvanced', {
  controller: TermDepositAdvanced,
  templateUrl,
  bindings: {
    deposit: '=',
    form: '='
  }
});
