import nxModule from "nxModule";
import templateUrl from "./check-assignment.template.html";
import {NgTableParams} from "ng-table";
import {ILocationService} from "angular";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {PageRequest, PageResult} from "tools/HttpTypes";
import {HttpService} from "shared/utils/httpService";
import {Branch} from "management/BranchTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

interface ApprovedLoan {
  id: number;
  customerId: number;
  customerNumber: string;
  customerName: string;
  productName: string;
  releaseAmount: number;
  shouldAssignCheck?: boolean;
}

interface CheckbookSummary {
  id: number;
  depositoryAccountId: number;
  accountName: string;
  firstAvailableCheckNumber: string;
  usedChecks: number;
  unusedChecks: number;
}

interface SearchParams extends PageRequest {
  branchId?: number;
}

class CheckAssignment {
  private branch!: Branch;
  private tableConfig: NgTableParams<ApprovedLoan>;
  private searchParams: SearchParams;
  private checkbookSummary: CheckbookSummary | null = null;
  private approvedLoans: ApprovedLoan[] = [];
  private checkbookOptions: CheckbookSummary[] = [];
  private shouldAssignCheck: boolean = false;
  private assignedLoans: number = 0;
  private checkDate: Date | null = null;
  private readonly selectConfig = {
    placeholder: 'Select account',
    searchField: ['accountName'],
    valueField: 'id',
    labelField: 'accountName',
    maxItems: 1
  };

  constructor(private http: HttpService,
              private command: CommandService,
              private $location: ILocationService,
              private customerCache: CustomerCache,
              private confirmationTemplate: ConfirmationTemplate) {
    this.searchParams = {
      pageNo: 0,
      pageSize: 20
    };

    this.tableConfig = new NgTableParams<ApprovedLoan>({
      page: 1,
      count: 20,
    }, {
      counts: [],
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: (params: NgTableParams<ApprovedLoan>) => this.getApprovedLoans(params)
    });
  }

  async $onInit(): Promise<void> {
    this.checkbookOptions = <CheckbookSummary[]> await this.http.get(`/check-preparation/branches/${this.branch.id}/checkbook-summary`).toPromise();
  }

  goBack(): void {
    this.$location.path(`/check-preparation/assignment`);
  }

  toggleAssignChecks(): void {
    this.tableConfig.data.forEach(loan => {loan.shouldAssignCheck = this.shouldAssignCheck});
    this.updateAssignedLoanCount();
  }

  updateAssignedLoanCount(): void {
    const loansToAssign: number[] = this.tableConfig.data.filter(l => l.shouldAssignCheck === true).map(l => l.id);
    this.assignedLoans = loansToAssign.length;
  }

  canAssignChecks(): boolean {
    return this.assignedLoans > 0 && this.checkbookSummary !== null && this.assignedLoans <= this.checkbookSummary.unusedChecks;
  }

  async assignChecks(): Promise<void> {
    const loansToAssign: number[] = this.tableConfig.data.filter(l => l.shouldAssignCheck === true).map(l => l.id);
    if(!this.checkbookSummary || loansToAssign.length <= 0) {
      return;
    }

    const proceed = await this.confirmationTemplate({
      question: `Are you sure that you want to assign checks to these loans?`,
      details: [
        {label: 'Depository bank', description: this.checkbookSummary.accountName},
        {label: 'Loans to assign', description: String(loansToAssign.length)}
      ]
    });

    if(proceed) {
      const response = await this.command.execute("AssignChecksToLoans", {loanIds: loansToAssign, depositoryAccountId: this.checkbookSummary.depositoryAccountId, checkDate: this.checkDate}).toPromise();
      if (!response.approvalRequired && this.customerCache.loadedCustomerId) {
        const customerLoan: ApprovedLoan | undefined = this.tableConfig.data.find(l => l.shouldAssignCheck === true && String(this.customerCache.loadedCustomerId) === String(l.customerId));
        if (customerLoan) {
          this.customerCache.loans(this.customerCache.loadedCustomerId).evict();
        }
      }
      this.goBack();
    }
  }

  async getApprovedLoans(params: any): Promise<ApprovedLoan[]> {
    this.searchParams.branchId = this.branch.id;
    this.searchParams.pageNo = params.page() - 1;
    this.searchParams.pageSize = params.count();

    const response = <PageResult<ApprovedLoan>> await this.http.get(`/check-preparation/branches/${this.searchParams.branchId}/unassigned-loans?pageNo=${this.searchParams.pageNo}&pageSize=${this.searchParams.pageSize}`, {nxLoaderText: 'Searching for approved loans...'}).toPromise();
    params.total(response.totalCount);
    return response.result;
  }
}

nxModule.component('checkAssignment', {
  templateUrl,
  controller: CheckAssignment,
  bindings: {
    branch: '<'
  }
});