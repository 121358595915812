import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('casaTypesCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/products/casa/types'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.casaTypes,
    cacheName: 'casaTypes'
  })
);
