import BigNumber from 'bignumber.js';
import nxModule from 'nxModule';
import './transaction-details-builder.service'
import './operation-table-builder.service'
import systemPropertyService from "../../../../react/system/systemPropertyService";

const templateUrl = require('./transaction-history.template.html');
nxModule.component('transactionHistory', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    product: '<',
    groups: '<',
    status: '<',
    item: '=',
    productType: '<',
    hideAvailableBalanceAfter: '<',
    hideTotalBalanceAfter: '<'
  },
  controller: function (operationTableBuilder, reportModal, systemDateService, branchWorkingDayCache, $filter, transactionHistoryService, operationCodeFormatter, reportService) {
    const that = this;

    that.transactionHistoryConfig = undefined;
    that.selectedTransactionId = undefined;
    that.item = {};
    that.displayRealServerDateTime = systemPropertyService.getProperty('DISPLAY_REAL_SERVER_DATE_TIME') === 'TRUE';
    that.reportName = 'TransactionHistoryReport';

    that.initializeDateFilters = async () => {
      // Set default date range parameters to display current month
      if (that.hasFilterAndPrint(that.productType)) {
        await systemDateService.getCurrentUserBranchSystemDate()
          .then(date => {
            that.dateTo = date.clone().format('YYYY-MM-DD');
            that.dateFrom = date.clone().subtract(1, "month").format('YYYY-MM-DD');
          });
      }
    }

    that.getStatusLabel = (operation) => {
      return transactionHistoryService.getStatusLabel(operation, that.productType);
    };

    that.hideInlinePanel = () => {
      that.selectedTransactionId = null;
    };

    /** This method adds branchWorkingDay data to the transaction for later use in revert-command.service */
    that.transactionClicked =  async (transaction, $event) => {
      transaction.branchWorkingDay = await branchWorkingDayCache.withParam(transaction.registrationBranchId).toPromise();
      $event.stopPropagation();
      that.selectedTransactionId = transaction.id;
      that.item = transaction;
    };

    that.abs = (number) => {
      var bigNumber = new BigNumber(number);
      bigNumber = bigNumber.abs();
      return parseFloat(bigNumber);
    };

    that.getOperationCode = (operation) => {
      if (!that.product?.id) {
        return;
      }
      return operationCodeFormatter.format(operation, that.product.id);
    };

    that.translate = operation => {
      let code = that.getOperationCode(operation);
      if(operationCodeFormatter.isCustomFeeApplyOnOperation(code)) {
        return operation.feeName;
      } else if (that.productType === 'LOAN') {
        if ('DEPOSIT_CUTOFF_CHECK' === code) {
          code = 'LOAN_LATE_CHECK_PAYMENT';
        } else if (code === 'DEPOSIT_CHECK_LOAN' && operation.attributes['LATE_CHECK']) {
          code = 'DEPOSIT_LATE_CHECK_LOAN';
        }
      }

      return $filter('translate')(code, 'OPERATION_NAME');
    };

    that.resetTransactions = () => {
      that.hideInlinePanel();

      if (that.product && that.product.id) {
        that.transactionHistoryConfig = operationTableBuilder.buildNgTable({
          productId: that.product.id,
          status: that.status,
          groups: that.groups,
          passbookOnly: false,
          dateFrom: that.dateFrom,
          dateTo: that.dateTo
        });
      }
    };

    that.getReportParams = () => {
      return {
        productId: that.product.id,
        status: that.status,
        groups: that.groups,
        dateFrom: that.dateFrom,
        dateTo: that.dateTo
      };
    };

    that.printTransactions = () => {
      reportModal.display({
        params: that.getReportParams(),
        reportCode: that.reportName,
        hideXls: false
      });
    };

    that.downloadTransactions = async () => {
      await reportService.downloadXls({reportCode: that.reportName, params: that.getReportParams()});
    };

    that.hasFilterAndPrint = (productType) => {
      return ['ACCOUNT', 'TERM-DEPOSIT'].includes(productType);
    };

    that.$onChanges = async function (changes) {
      if (changes.hasOwnProperty('product') && changes.product.currentValue !== null) {
        await that.initializeDateFilters();
        that.resetTransactions();
      }
    };
  }
});
