export default (predicateValue, predicateContext, authentication) => {
  if (predicateValue['PRODUCT_STATUS']) {
    if(!predicateContext.productStatus) {
      console.warn('Missing predicate context for', 'PRODUCT_STATUSES');
      return true;
    }

    const productStatus = predicateContext.productStatus;
    return predicateValue['PRODUCT_STATUS'].includes(productStatus);
  } else if (!!predicateValue['DEPOSITORY_BANK_ACCESS'] || !!predicateValue['PRODUCT_BRANCH_ACCESS']) {
    return true;
  }

  return !!predicateValue['PRODUCT_DEFINITION'];
};
