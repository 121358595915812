import {Config} from 'config';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import LocalCache from 'shared/utils/localCache';
import {ICacheFactoryService} from "angular";

export interface LedgerAccountTemplate {
  id:                number;
  code:              string;
  name:              string;
  accountGroup:      LedgerAccountGroup;
  reversedEntryType: boolean;
  accountDepth:      number;
  fullCode:          string;
  orderNo:           number;
}

export enum LedgerAccountGroup {
  ASSET = "ASSET",
  CAPITAL = "CAPITAL",
  CONTINGENT = "CONTINGENT",
  EXPENSE = "EXPENSE",
  INCOME = "INCOME",
  LIABILITY = "LIABILITY",
}


export default class GlMappingsService {
  private productMappingsCache: any;

  // leaf only accounts
  public readonly accounts: LocalCache<LedgerAccountTemplate[], LedgerAccountTemplate[]>;
  // Contains all accounts (LEAF and NON-LEAF): return type = list of trees
  public readonly allAccounts: LocalCache<LedgerAccountTemplate[], LedgerAccountTemplate[]>;

  constructor(
    private http: HttpService,
    private CacheFactory: ICacheFactoryService,
    private config: Config
  ) {
    this.accounts = new LocalCache({
      provider: () => http.get('/ledger/mappings/product-mappings/accounts'),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.productMappingsAccounts,
      cacheName: 'productMappingsAccounts'
    });

    // Contains all accounts (LEAF and NON-LEAF): return type = list of trees
    this.allAccounts = new LocalCache({
      provider: () => http.get('/ledger/mappings/product-mappings/accounts?leafOnly=false'),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.productMappingsAllAccounts,
      cacheName: 'productMappingsAllAccounts'
    });
  }

  private createProductMappingsCache(id: string | number) {
    const cache = new LocalCache({
      provider: () => this.http.get(`/ledger/mappings/product-mappings?productDefinitionId=${id}`),
      cacheFactory: this.CacheFactory,
      cacheTime: this.config.cache.productMappings,
      cacheName: `productMappings_${id}`
    });

    this.productMappingsCache[id] = cache;
    return cache;
  }

  productMappings(id: string | number) {
    if (!this.productMappingsCache[id]) {
      this.createProductMappingsCache(id);
    }
    return this.productMappingsCache[id];
  }
}

nxModule.service('glMappingsService', GlMappingsService);
