import nxModule from 'nxModule';

import templateUrl from './batch-sss-pension-result-list.template.html';
import {UserCache, UserDetails} from "../../service/users/user.cache";
import {ILocationService} from "angular";
import {SSSPensionService} from "components/batch/sss-pension/batch-sss-pension.service.types";

class BatchSssPensionResultList {
  private users: UserDetails[] = [];

  constructor(private sssPensionService: SSSPensionService, private $location: ILocationService, private userCache: UserCache) {
  }

  async $onInit(): Promise<void> {
    this.users = await this.userCache.withParam().toPromise();
  }

  formatUser(userId: number) : string {
    const user = this.users.find(u => u.id === userId);
    if (user) {
      return user.firstName + " " + user.lastName;
    }
    return "-";
  }

  seeDetails(summary: {fileId: number}) : void {
    this.$location.path(`/batch/sss-pension/files/${summary.fileId}`);
  }
}

nxModule.component('batchSssPensionResultList', {
  templateUrl,
  bindings: {
    'summary': '<'
  },
  controller: BatchSssPensionResultList
});
