import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./create-deposit-hold.template.html');
nxModule.component('createDepositHold', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, branchService, authentication, confirmation, command) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];

    that.deposit = {};
    that.hold = {autoRelease: false};

    const s1 = customerCache.termDeposits(customerId).toObservable().subscribe((deposits) => {
      that.deposit = _.find(deposits, (d) => Number(d.id) === Number(depositId))
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.create = () => {
      confirmation(`Do you want to create new hold for ${$filter('nxCurrency')(that.hold.amount)}?`, () => {
        const input = Object.assign({}, {
          productId: depositId,
          amount: that.hold.amount,
          autoReleaseOn: that.hold.autoReleaseOn ? $filter('nxDate')(that.hold.autoReleaseOn) : null,
          creationRemarks: that.hold.creationRemarks
        });
        command.execute('CreateManualHold', input, {nxLoaderText: 'Creating hold'})
          .success(() => {
            customerCache.termDeposits(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
