import {ILocationService} from 'angular';
import {InterestBoardService} from 'components/administration/casa/interest-board/interest-board.service.types';
import {DepositType, TermDepositService} from 'components/administration/term-deposit/common/term-deposit.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import {depositGroupNames} from 'constants/deposit';
import nxModule from 'nxModule';
import {ActionType, Columns, Sorting} from '../../../common/dynamic-list/dynamic-list.component';

import templateUrl from './term-deposit-list.template.html';

interface View extends DepositType {
  productName?: string;
  productTypeCode?: string;
  productSubtype: string;
  productSubtypeName: string;
  interestRange: string;
  minimalBalanceFormatted: string;
  maximumBalanceFormatted: string;
  href: string;
}

class TermDepositList {
  private termDeposits: View[] = [];
  private readonly columns: Columns = [
    { title: 'No'},
    { title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    { title: 'Product name', field: 'productName', sortable: 'productName'},
    { title: 'Product subtype', field: 'productSubtypeName', sortable: 'productSubtypeName'},
    { title: 'Interest rate', field: 'interestRange'},
    { title: 'Minimum amount', field: 'minimalBalanceFormatted'},
    { title: 'Maximum amount', field: 'maximumBalanceFormatted'},
    { actions: [{
        access: 'UpdateDepositType',
        label: 'Edit',
        field: 'href',
        type: ActionType.LINK
      }]
    },
  ];

  private readonly sorting: Sorting = {
    productSubtypeName: 'asc',
    productTypeCode: 'asc',
    productName: 'asc',
  };

  constructor(private termDepositsService: TermDepositService, private interestBoardService: InterestBoardService,
              private $filter: NxIFilterService, private $location: ILocationService) { }

  async $onInit(): Promise<void> {
    const termDeposits = await this.termDepositsService.toPromise();
    this.termDeposits = termDeposits.map(deposit => {
      const depositGroupTranslation = depositGroupNames.find(item => item.value === deposit.depositGroup);
      return {
        ...deposit,
        productName: deposit.productDefinition.productName,
        productTypeCode: deposit.productDefinition.productTypeCode,
        productSubtype: this.$filter('prettyEnum')(deposit.depositGroup),
        productSubtypeName: depositGroupTranslation ? depositGroupTranslation.label : deposit.depositGroup,
        interestRange: this.interestBoardService.formatInterestRateRangeForRateType(deposit.interestBoards),
        minimalBalanceFormatted: this.$filter('nxCurrency')(deposit.minimalBalance),
        maximumBalanceFormatted: this.$filter('nxCurrency')(deposit.maximumBalance),
        href: `#!/admin/term-deposits/types/${deposit.id}`
      };
    });
  }

  goBack(): void {
    this.$location.path('/admin/term-deposits');
  }
}

nxModule.component('termDepositList', {
  templateUrl,
  controller: TermDepositList
});
