import {NxButtonLink, NxButtonVariant, NxQuery, NxQueryResult, NxTable, NxTableColumn} from "@nextbank/ui-components";
import React, {ReactElement} from "react";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";

export interface BranchSummary {
  id: number;
  name: string;
  noOfApprovedLoans: number;
  noOfAssignedChecks: number;
  noOfOutgoingChecks: number;
}

export interface BranchSummaryRow extends BranchSummary {
  no: number;
}

interface CheckPreparationBranchListProps {
  redirect: (selectedBranch : BranchSummaryRow) => string;
  columns: NxTableColumn<BranchSummaryRow>[];
}

const BranchList = (props: CheckPreparationBranchListProps): ReactElement => {
  const OpenBranch = ({data}: {data: BranchSummaryRow}): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={props.redirect(data)}>Open</NxButtonLink>;

  return <NxTable
      columns={props.columns}
      rowActions={[OpenBranch]}
      data={async (query: NxQuery): Promise<NxQueryResult<BranchSummaryRow>> => {
        const {data} = await axios.get<PageResult<BranchSummary>>('/check-preparation/branches', {
          params: {
            pageSize: query.pageSize,
            pageNo: query.page
          }
        });
        const result: BranchSummaryRow[] = data.result.map((branch, index) => {
          return {
            ...branch,
            no: query.pageSize * data.pageNo + index + 1
          };
        });

        return {
          ...data,
          result: result
        };
      }}/>;
};

export default BranchList;