import nxModule from 'nxModule';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';

nxModule.factory('customFieldCategoryDetailsCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (params) =>
      http.get(`/custom-field/categories/details?group=${params.group}&asForest=${!!params.asForest}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.customFieldCategoryDetails,
    cacheName: 'customFieldCategoryDetails'
  })
);
