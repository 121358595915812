import nxModule from 'nxModule';
import _ from 'lodash';
import {Account, Fee} from 'components/service/product.types';
import {AccountType} from "components/service/account.types";
import {HttpService} from "shared/utils/httpService";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import FeeDefinitionCache from "components/service/fee-definition.cache";
import {FeeDefinition} from 'components/service/fees/fee.types';
import {Loan} from 'components/service/loan.types';
import {LetterType} from "components/administration/loan/letter-type/letter.types";
import {CustomerCache} from "components/service/customer.cache.types";

export class LoanService {
  constructor(private http: HttpService,
              private customerCache: CustomerCache,
              private loanProductsCache: LoanProductsCache,
              private feeDefinitionsCache: FeeDefinitionCache) {
  }

  async getLoansForCustomer(customerId: number): Promise<Loan[]> {
    const loans = await this.customerCache.loans(customerId).toPromise();
    const loanTypes = await this.loanProductsCache.toPromise();
    return loans.map(loan => {
      // add loanProduct to loan object
      const type = _.find(loanTypes, {id: loan.typeId});
      return {
        ...loan,
        loanType: type
      };
    });
  }

  async getLoanForCustomer(customerId: number, loanId: number): Promise<Loan> {
    const loans = await this.getLoansForCustomer(customerId);
    return loans.find(loan => loan.id === Number(loanId))!;
  }

  async getLoanFees(loanId: number): Promise<Fee[]> {
    const [loanFees, feeDefs]: [Fee[], FeeDefinition[]] = await Promise.all([
      <Promise<Fee[]>>this.http.get(`/products/loans/${loanId}/fees`).toPromise(),
      this.feeDefinitionsCache.toPromise()
    ]);

    return loanFees.map(fee => {
      const def = feeDefs.find(def => def.id === fee.feeDefinitionId)!;
      return {
        feeName: def.feeName,
        ...fee
      };
    });
  }

  async getAccretedFees(loanId: number, includeNewFees: boolean): Promise<Fee[]> {
    const [accretedFees, feeDefs]: [Fee[], FeeDefinition[]] = await Promise.all([
      <Promise<Fee[]>>this.http.get(`/products/loans/${loanId}/accreted-fees?includeNewFees=${includeNewFees}`).toPromise(),
      this.feeDefinitionsCache.toPromise()
    ]);

    return accretedFees.map(fee => {
      const def = feeDefs.find(def => def.id === fee.feeDefinitionId)!;
      return {
        feeName: def.feeName,
        ...fee
      };
    });
  }

  getAmortizedFees(loanId: number): unknown {
    return this.http.get(`/products/loans/${loanId}/amortized-fees`).toPromise();
  }

  getAvailableLinkedAccounts(accounts: Account[], accountTypes: AccountType[]): {label: string, id: number}[] {
    return accounts.filter(acc => {
      const accountType = accountTypes.find(type => type.id === acc.typeId);
      if(!accountType){
        return false;
      }

      return this.isValidLinkedAccount(acc, accountType);
    })
      .map(acc => {
        const productNumber = acc.productNumber;
        const accountType = accountTypes.find(type => type.id === acc.typeId);
        const productName = accountType?.productDefinition.productName ?? '<unknown>';
        return {
          id: acc.id,
          label: `${productName} (${productNumber})`
        };
      });
  }

  isValidLinkedAccount(account: Account, accountType: AccountType): boolean {
    if(!accountType?.purpose) {
      return false;
    }

    if(accountType.purpose !== 'GENERAL') {
      return false;
    }

    return accountType.accountSubtype === 'SAVINGS';
  }

  async getLetterTypes(): Promise<LetterType[]> {
    return this.http.get<LetterType[]>('/products/loans/letter/types').toPromise();
  }
}

nxModule.service('loanService', LoanService);
