import clsx from 'clsx';
import InspireTree, {NodeConfig, TreeNode} from 'inspire-tree';
import 'inspire-tree-dom/dist/inspire-tree-light.css';
import React, {Factory, ReactElement, Ref, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {NxTreeNode} from 'tree/NxTreeNode';
import {TreeApi} from 'tree/TreeApi';
import {v4 as uuid} from 'uuid';
import styles from './TreeWrapper.scss';

export interface ChildrenProps<VALUE> {
  treeNodes: TreeNode[];
  mapping: Map<string, VALUE>;
  reverseMapping: Map<VALUE, string[]>;
  tree: InspireTree
}

export interface TreeWrapperProps<VALUE> {
  children: Factory<ChildrenProps<VALUE>>;
  nodes: NxTreeNode<VALUE>[];
  checkedValues?: VALUE[];
  selectedValue?: VALUE;
  onChange: (nodes: TreeNode[], mapping: Map<string, VALUE>) => unknown;
  api?: Ref<TreeApi>;
  variant: 'editable' | 'multiselect' | 'singleselect';
  className?: string;
}

const mapNodeToTreeNode = function <VALUE>(variant: string, node: NxTreeNode<VALUE>, mapping: Map<string, VALUE>,
                                           reverseMapping: Map<VALUE, string[]>, checkedValues: VALUE[],
                                           expandNodes: boolean, selectedValue?: VALUE): NodeConfig {
  const id = uuid();
  mapping.set(id, node.value);
  // some nodes can appear multiple times in a tree - for example the same branch can be in multiple areas
  // we need to manage all of them at once then
  const ids = reverseMapping.get(node.value);
  if(ids === undefined) {
    reverseMapping.set(node.value, [id]);
  } else {
    ids.push(id);
  }

  const children = node.children.map(child => mapNodeToTreeNode(variant, child, mapping, reverseMapping, checkedValues, false, selectedValue));
  const checked = checkedValues.includes(node.value) || (children.length > 0 && children.every(child => child.itree?.state?.checked));
  let selected = false;
  let selectable = false;
  let collapsed = !expandNodes;

  if (variant === 'singleselect') {
    selectable = node.children.length === 0;
    collapsed = !children.some(child => child.itree?.state?.selected);
    selected = node.value === selectedValue || !collapsed;
  }

  return {
    id,
    text: '',
    itree: {
      state: {
        checked,
        indeterminate: !checked && children.some(child => child.itree?.state?.checked),
        collapsed: collapsed,
        selected: selected,
        selectable: selectable
      }
    },
    children,
  };
};

// uncontrolled component
const TreeWrapper =  function<VALUE>(props: TreeWrapperProps<VALUE>): ReactElement {
  const tree = useRef<InspireTree | undefined>();
  const mapping = useRef(new Map<string, VALUE>());
  const reverseMapping = useRef(new Map<VALUE, string[]>());

  const [treeNodes, setTreeNodes] = useState<TreeNode[]>();

  const {nodes, onChange, checkedValues=[], selectedValue, variant} = props;
  useEffect(() => {
    if (tree.current) {
      return;
    }
    const currentTree = new InspireTree({
      editable: variant === 'editable',
      data: nodes.map(node =>
        mapNodeToTreeNode(
          variant,
          node,
          mapping.current,
          reverseMapping.current,
          checkedValues,
          nodes.length === 1,
          selectedValue)
      )
    });

    const nodeCheckedListener = (node: TreeNode): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentTree.batch();
      const events = ['node.checked', 'node.unchecked'];
      const checked = node.checked();
      const nodesPendingUpdate = new Set<string>();
      const updateState = (otherNode: TreeNode): void => {
        otherNode.state('checked', checked);
        otherNode.state('indeterminate', false);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const otherNodeId = otherNode.id!;
        const value = mapping.current.get(otherNodeId)!;
        const otherIds = reverseMapping.current.get(value) ?? [];
        for(const id of otherIds) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if(id !== otherNodeId) {
            nodesPendingUpdate.add(id);
          }

        }

        if (otherNode.hasChildren()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          otherNode.children.recurseDown((child: TreeNode) => {
            updateState(child);
          });
        }

        otherNode.markDirty();
      };

      updateState(node);

      currentTree.mute(events);
      for(const id of nodesPendingUpdate) {
        const otherNode = currentTree.node(id);
        if(otherNode.checked() === checked) {
          continue;
        }

        otherNode.state('checked', checked);
        otherNode.state('indeterminate', false);
        otherNode.markDirty();

        if (otherNode.hasParent()) {
          otherNode.getParent().refreshIndeterminateState();
        }
      }

      if (node.hasParent()) {
        node.getParent().refreshIndeterminateState();
      }

      currentTree.unmute(events);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      currentTree.end();
    };

    setTreeNodes(currentTree.nodes());

    const listener = (): void => {
      const currentNodes = currentTree.nodes();
      setTreeNodes([...currentNodes]);
      if (variant !== 'singleselect') {
        onChange(currentNodes, mapping.current);
      }
    };

    currentTree.on('changes.applied', listener);
    currentTree.on('node.checked', nodeCheckedListener);
    currentTree.on('node.unchecked', nodeCheckedListener);
    currentTree.on('node.selected', () => onChange(currentTree.nodes(), mapping.current))
    tree.current = currentTree;
  }, [nodes, onChange, checkedValues, selectedValue, variant]);

  useImperativeHandle(props.api, () => ({
    collapse(): void {
      tree.current?.collapse();
    }
  }), []);

  const currentTree = tree.current;
  if (!treeNodes || !currentTree) {
    return <></>;
  }

  return <div className={clsx("inspire-tree", styles.treeWrapper, props.className)}>
        <props.children
          treeNodes={treeNodes}
          mapping={mapping.current}
          reverseMapping={reverseMapping.current}
          tree={currentTree}
        />
      </div>;
};

export default TreeWrapper;
