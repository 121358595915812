import config from "../config";

const initialInternetCheckInterval = config.internetCheckInterval / 1000;

class InternetCheckService {
  public internetConnection = true;

  checkInterval = initialInternetCheckInterval;  // in seconds
  retryInterval = 5;  // in seconds

  retrySecondsLeft = initialInternetCheckInterval;

  retry(): void {
    this.retrySecondsLeft = 0;
  }

  reportSuccess(): void {
    this.retrySecondsLeft = this.checkInterval;
    this.internetConnection = true;
  }
}
const instance = new InternetCheckService();

export default instance;
export const serviceType = InternetCheckService;