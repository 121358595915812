import nxModule from 'nxModule';

import templateUrl from './dynamic-report-mapping-upload.template.html';

class DynamicReportMappingUpload {
  constructor(dynamicReportService, dynamicReportFileService, reportService, command, http, popup, confirmation, $location) {
    const reportCode = "DynamicReportMappingStub";
    this.command = command;
    this.http = http;
    this.popup = popup;
    this.confirmation = confirmation;
    this.$location = $location;
    this.filters = {};
    this.dynamicReportFileService = dynamicReportFileService;
    this.dynamicReportService = dynamicReportService;

    const downloadReport = () => {
      reportService.downloadCustomFile({
        reportCode,
        params: {
          fileId: this.reportFile.id
        },
      });
    };

    this.filterConfig = {
      reportCode: reportCode,
      buttons: {
        filter: {
          isVisible: true,
          isDisabled: false,
          action: () => downloadReport(),
          text: 'Download mapping'
        }
      }
    };
  }

  $onInit() {
    this.dynamicReportService.updateBreadcrumb(this.report, this.reportFile);
  }

  async uploadFile() {
    const confirmed = await this.confirmation(`Do you want to update mapping for ${this.reportFile.name}? Old mapping will be overwritten.`);
    if (!confirmed) {
      return;
    }

    try {
      await this.dynamicReportFileService.uploadMapping(this.report.id, this.reportFile.id, this.file);
      this.popup({header: 'Success', text: 'Mapping updated'});
    } catch (e) {
      console.error('Error uploading file', e);
      const processedMessage = e.errorMessage.replace(/\n/g, '<br><br>');
      this.popup({header: 'Error', text: processedMessage, renderHtml: true});
    }
  }

  cancelChanges() {
    this.$location.path(`/admin/dynamic-report/${this.report.id}/file/${this.reportFile.id}`);
  }
}

nxModule.component('dynamicReportMappingUpload', {
  templateUrl,
  bindings: {
    reportFile: '<',
    report: '<'
  },
  controller: DynamicReportMappingUpload
});