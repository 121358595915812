import nxModule from 'nxModule';

const templateUrl = require('./loan-cycle-counter.template.html');
nxModule.component('loanCycleCounter', {
  templateUrl,
  bindings: {
    loanType: '=',
    form: '='
  },
  controller: function ($scope) {
    $scope.$watch('$ctrl.loanType.cycleCounting', () => {
      if (this.loanType.cycleCounting && (!this.loanType.cycles || this.loanType.cycles.length === 0)) {
        this.loanType.cycles = [0];
      }
    });

    this.add = () => {
      if (!this.loanType.cycles) this.loanType.cycles = [];
      this.loanType.cycles.push(0);
      this.form.$setDirty();
    };

    this.remove = () => {
      this.loanType.cycles.splice(this.loanType.cycles.length - 1, 1);
      this.form.$setDirty();
    };

    this.range = function(min, max) {
      let input = [];
      for (let i = min; i <= max; i += 1) {
        input.push(i);
      }
      return input;
    };
  }
});
