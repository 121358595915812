import nxModule from 'nxModule';

import templateUrl from './update-user-asset-counter.template.html';
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";
import {NxIFilterService} from "components/technical/angular-filters";
import {BranchService} from "components/service/branch.service";
import {StatusService} from "components/technical/status/status.service";
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {Branch} from "management/BranchTypes";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

class UpdateUserAssetCounter {
  commandInput = {
    userId: null,
    cashDiff: null,
    checkDiff: null
  };

  branches: Branch[] = [];
  branch: Branch | undefined = undefined;
  users: UserDetails[] = [];
  branchUsers: UserDetails[] = [];
  userCash: number | undefined;
  userCheck: number | undefined;
  user: UserDetails | undefined = undefined;


  constructor(private confirmationTemplate: ConfirmationTemplate, private http: HttpService, private popup: Popup,
              private $filter: NxIFilterService, private $route: NxRouteService, private command: CommandService,
              private branchService: BranchService, private statusService: StatusService, private userCache: UserCache) {
  }


  async $onInit(): Promise<void> {
    const [branches, users] = await Promise.all([
      this.branchService.toPromise(),
      this.userCache.withParam().toPromise()
    ]);

    this.branches = branches;
    this.users = users.filter(u => !u.technical);
  }

  async onBranchChange(branchId: number): Promise<void> {
    this.branch = this.branches.find(b => b.id === branchId);
    if (!this.branch) {
      return;
    }

    this.branchUsers = this.users.filter(u => this.branch?.id  === u.branchId);
  }

  async onUserChange(userId: number): Promise<void> {
    this.userCash = undefined;
    this.userCheck = undefined;
    this.commandInput.cashDiff = null;
    this.commandInput.checkDiff = null;

    this.user = this.branchUsers.find(u => u.id === userId);
    if (!this.user) {
      return;
    }

    const counter = await this.http.get<{totalCash: number, totalCheck: number}>(`/management/users/${this.user.id}/counter`).toPromise();

    this.userCash = counter.totalCash;
    this.userCheck = counter.totalCheck;
  }

  async execute(): Promise<void> {
    if(!this.branch) {
      throw new Error('Missing branch');
    }

    if(!this.user) {
      throw new Error('Missing user');
    }

    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to update the user asset counter?',
      details: [
        {label: 'Branch name', description: this.$filter('prettyEnum')(this.branch.name)},
        {label: 'Username', description: this.user.username},
        {label: 'Increase cash by', description: this.$filter('nxCurrency')(this.commandInput.cashDiff!)},
        {label: 'Increase check by', description: this.$filter('nxCurrency')(this.commandInput.checkDiff!)}
      ],
    });

    if(!confirmed) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await this.command.execute('UpdateUserAssetCounter', this.commandInput).toPromise();
    if (response.approvalRequired) {
      return;
    }

    await this.statusService.refresh();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$route.reload();
  }
}

nxModule.component('updateUserAssetCounter', {
  templateUrl,
  controller: UpdateUserAssetCounter
});
