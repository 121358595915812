import systemPropertyService from "system/systemPropertyService";
import Handlebars from 'handlebars';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default (handlebars: Handlebars) : void => {
  const systemCurrency = systemPropertyService.getProperty('DEFAULT_CURRENCY') ?? 'PHP';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  handlebars.registerHelper('ifCond', function<T> (v1: T, operator: unknown, v2: T, options) {
    switch (operator) {
      case '==':
        return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
        return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '!=':
        return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '!==':
        return (v1 !== v2) ? options.fn(this) : options.inverse(this);
      case '<':
        return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
        return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
        return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
        return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
        return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
        return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });

  handlebars.registerHelper('formatNumber', (num: unknown, fractionDigits: unknown): string => {
    const value = num instanceof Number ? num : Number(num);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fraction = isNaN(fractionDigits) ? 2 : parseInt(fractionDigits);
    return value.toLocaleString(systemCurrency, {"minimumFractionDigits" : fraction});
  });

  handlebars.registerHelper('formatCurrency', (num: unknown, fractionDigits: unknown): string => {
    const value = num instanceof Number ? num : Number(num);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const fraction = isNaN(fractionDigits) ? 2 : parseInt(fractionDigits);
    return value.toLocaleString(systemCurrency, {"minimumFractionDigits" : fraction, "style" : "currency"});
  });

  handlebars.registerHelper('eval', (expr: string, options: unknown) => {
    const reg = new RegExp("\\${(\\S+)}", "g");
    const compiled = expr.replace(reg, (match: string, firstMatchedGroup: string): string => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `(${JSON.stringify(options.hash[firstMatchedGroup])})`;
    });
    return eval(compiled);
  });

  handlebars.registerHelper('json', JSON.stringify);
};
