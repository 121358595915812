import {BatchRemittanceHistoryService} from 'components/batch/remittance/batch-remittance-history.service';
import {branchOfServiceDefs} from 'components/batch/remittance/batch-remittance-upload.component';
import moment from 'moment';
import nxModule from 'nxModule';
import ReportService from 'report/ReportService';
import {Confirmation} from 'shared/common/confirmation.types';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import './batch-remittance-history.template.css';
import templateUrl from './batch-remittance-history.template.html';
import {Loader} from "components/technical/loader.types";

export enum BatchRemittanceStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
  REVERTED = 'REVERTED',
  REVERTING ='REVERTING'
}

export interface BatchRemittanceHistoryRow {
  id : number,
  fileId: number,
  fileName: string,
  branchOfService: string,
  uploadedOn: string,
  applicablePeriod: string,
  officialReceipt: string,
  remittanceMethod: string,
  status: BatchRemittanceStatus,
  remarks: string,
  errorMessage: string,
  rowCount: number,
  processedCount: number,
  failedCount: number
}

export class BatchRemittanceHistoryComponent {

  private totalRowCount : number;
  private reportService : ReportService;
  private passDownloading : number[] = [];
  private failDownloading : number[] = [];
  private tableReload : boolean = false;

  constructor(private command: CommandService,
              private confirmation: Confirmation,
              private popup: Popup,
              private batchRemittanceHistoryService: BatchRemittanceHistoryService,
              private loader: Loader) {
    this.totalRowCount = 0;
    this.reportService = new ReportService();
    this.loader = loader;
  }

  async $onInit(): Promise<void> {
    this.batchRemittanceHistoryService.initialize();
  }

  async downloadPassReport(batchId: number): Promise<void> {
    if(!this.passDownloading.includes(batchId)) {
      //Only allow 1 download request per button action
      this.passDownloading.push(batchId);
      const loaderId = this.loader.show('Generating pass report...');
      const reportId = await this.reportService.downloadFileReport({
        reportName: 'Remittance Transaction Report - Pass',
        parameters: {batchId: batchId}
      });
      this.loader.dismiss(loaderId);
      if (!reportId) {
        this.showReportGenerationErrorPopUp("pass");
      }
      this.passDownloading.splice(this.passDownloading.indexOf(batchId), 1);
    }
  }

  async downloadFailReport(batchId: number): Promise<void> {
    if(!this.failDownloading.includes(batchId)) {
      //Only allow 1 download request per button action
      this.failDownloading.push(batchId);
      const loaderId = this.loader.show('Generating fail report...');
      const reportId = await this.reportService.downloadFileReport({
        reportName: 'Remittance Transaction Report - Fail',
        parameters: {batchId: batchId}
      });
      this.loader.dismiss(loaderId);
      if (!reportId) {
        this.showReportGenerationErrorPopUp("fail");
      }
      this.failDownloading.splice(this.failDownloading.indexOf(batchId), 1);
    }
  }

  async revert(batch: BatchRemittanceHistoryRow): Promise<void> {
    const proceed = await this.confirmation(`Do you want to revert this ${this.getBranchOfServiceLabel(batch.branchOfService)} remittance for the applicable period ${moment(batch.applicablePeriod).format("YYYY-MM-DD")}?`);
    if(!proceed) {
      return;
    }
    await this.command.execute('BatchRemittanceRevert', {"batchId": batch.id}).toPromise();
    this.batchRemittanceHistoryService.getHistory()?.reload();
  }

  getBranchOfServiceLabel(bosCode : string): string {
    return branchOfServiceDefs.find(bos => bos.value === bosCode)?.label ?? '';
  }

  isRevertNotAllowed(status : BatchRemittanceStatus): boolean {
    return [BatchRemittanceStatus.PROCESSING,
      BatchRemittanceStatus.REVERTING,
      BatchRemittanceStatus.REVERTED,
      BatchRemittanceStatus.FAILED].includes(status);
  }

  showErrorDetails(errorMessage : string): void {
    this.popup({text: errorMessage, header: 'Failure Details'});
  }

  showReportGenerationErrorPopUp(report: string): void {
    this.popup({text: `Error encountered on generating ${report} report. Please contact system administrator`, header: 'Report generation'});
  }

  async reloadHistory(): Promise<void> {
    if(!this.tableReload) {
      this.tableReload = true;
      await this.batchRemittanceHistoryService.getHistory()?.reload();
      this.tableReload = false;
    }
  }
}

nxModule.component('batchRemittanceHistory', {
  templateUrl: templateUrl,
  controller: BatchRemittanceHistoryComponent
});
