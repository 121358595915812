import nxModule from 'nxModule';
import templateUrl from './loan-product-letters.template.html'
import {LoanType} from "components/service/loan-type.types";
import './loan-product-letters.style.less'
import {HttpService} from "shared/utils/httpService";
import angular, {IFormController} from "angular";
import {Letter, LetterType} from "components/administration/loan/letter-type/letter.types";
import {LoanService} from "components/service/loan.service";
import {Confirmation} from "shared/common/confirmation.types";

interface Selection {
  id: string,
  value: string
}

class LoanProductLetter {

  private loanProduct!: LoanType;
  private form!: IFormController;
  private letterTypes: LetterType[] = [];
  private lettersToEdit?: Letter[] | null;

  private printBasis: Selection[] = [
    {
      id: 'AMORTIZATION_DUE',
      value: 'Amortization due'
    },
    {
      id: 'LOAN_MATURITY',
      value: 'Loan maturity'
    },
    {
      id: 'ORDER',
      value: 'Order'
    }
  ];

  constructor(private confirmation: Confirmation, private http: HttpService, private loanService: LoanService) {

  }

  async $onInit(): Promise<void> {
    this.letterTypes = await this.loanService.getLetterTypes();
  }

  edit(index: number): void {
    this.lettersToEdit = angular.copy(this.loanProduct.letters);
    this.loanProduct.letters[index].inputMode = true;
  }

  async remove(index: number): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isApproved = await this.confirmation("Do you want to remove this letter?");
    if (!isApproved) return;

    this.loanProduct.letters.splice(index, 1);
    this.reorder();
    this.form.$setDirty();
  }

  add(): void {
    this.loanProduct.letters.push({
      loanTypeId: this.loanProduct.id,
      printBasis: undefined,
      minLoanDaysLate: 0,
      minDaysInterval: 0,
      orderNo: this.loanProduct.letters.length + 1,
      inputMode: true
    } as Letter);
  }

  cancel(index: number): void {
    if (this.loanProduct.letters[index].id) {
      this.loanProduct.letters[index] = this.lettersToEdit?.[index] ?? this.loanProduct.letters[index];
      this.loanProduct.letters[index].inputMode = false;
    } else {
      this.loanProduct.letters.pop();
    }
  }

  getForm(index: number): IFormController {
    this.synchronizeForm();
    const name = `form_${index}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this[name];
  }

  save(index: number): void {
    this.getForm(index).$setSubmitted();
    if (this.getForm(index).$valid) {
      this.loanProduct.letters[index].inputMode = false;
    }
  }

  getPrintBaseSelection(index: number): Selection[] {
    if (index === 0) {
      return this.printBasis.filter(p => p.id !== 'ORDER');
    }
    return this.printBasis.filter(p => p.id === 'ORDER');
  }

  /**
   * Synchronize to avoid returning undefined/null form when retrieved via index.
   */
  private synchronizeForm(): void {
    let hasUndefined = false;
    for(let i = 0; i < this.loanProduct.letters.length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this[`form_${i}`] || hasUndefined) {
        hasUndefined = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[`form_${i}`] = this[`form_${i + 1}`];
      }
    }
  }

  /**
   * Used to make sure that the order of the letters is correct
   * after any modification (e.g delete)
   */
  private reorder(): void {
    let orderNo = 1;
    this.loanProduct.letters.forEach(l => {
      l.orderNo = orderNo;
      orderNo += 1;
    });
  }
}


nxModule.component('loanProductLetter', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: LoanProductLetter
});
