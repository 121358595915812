import nxModule from "nxModule";
import templateUrl from "./bank-holidays.template.html";
import {Holiday} from "management/WorkingDayType";
import {HttpService} from "shared/utils/httpService";
import {Moment} from "moment";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {SystemDateService} from "components/service/system-date.service.types";
import {BranchHolidayCache} from "components/service/branch-holiday.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class BankHolidays {
  year!: number;
  holidays: Holiday[] = [];
  date: Moment | null = null;
  name: string | null = null;

  constructor(private systemDateService: SystemDateService,
              private http: HttpService,
              private command: CommandService,
              private confirmationTemplate: ConfirmationTemplate,
              private branchHolidayCache: BranchHolidayCache) {
  }

  async $onInit(): Promise<void> {
    const systemDate = await this.systemDateService.getCurrentUserBranchSystemDate();
    this.year = systemDate.year();
    await this.getHolidays();
  }

  async getHolidays(): Promise<void> {
    this.holidays = await this.branchHolidayCache.withParam({
      year: this.year,
      type: ['HOLIDAY'],
      mode: 'BANK',
    }).toPromise();
  }

  async createBankHoliday(): Promise<void> {
    if(!this.name || !this.date) {
      return;
    }

    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to create a new bank holiday?',
      details: [
        {label: 'Name', description: this.name},
        {label: 'Date', description: this.date.toLocaleString()}
      ]
    });

    if(!confirmed) {
      return;
    }

    const request = {
      date: this.date,
      name: this.name,
      type: 'HOLIDAY'
    };

    const response = await this.command.execute('CreateBankHoliday', request).toPromise();
    if (!response?.approvalRequired) {
      this.branchHolidayCache.evict();
      await this.getHolidays();
    }
    this.date = null;
    this.name = null;
  }

  async deleteHoliday(holiday: Holiday): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to remove holiday: ${holiday.name}?`
    });

    if(confirmed) {
      const response = await this.command.execute('DeleteBankHoliday', {holidayId: holiday.id}).toPromise();
      if (!response?.approvalRequired) {
        this.branchHolidayCache.evict();
        await this.getHolidays();
      }
    }

  }
}

nxModule.component('bankHolidays', {
  templateUrl: templateUrl,
  controller: BankHolidays
});