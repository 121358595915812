import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Amortization, Loan} from '../../../../../service/loan.types';
import {LoanPdcDetailsWithAmortization} from '../loan-pdc-tab.component';
import templateUrl from './loan-pdc-tab-details.template.html';
import {UserCache} from '../../../../../service/users/user.cache';
import {LoanPDCProcessStatus, LoanPDCStatus} from '../../../../../service/loan-pdc.types';
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {UserService} from "components/service/users/user.service";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import {ILocationService} from "angular";

class LoanPdcTabDetails {
  onHide!: unknown;
  onCancel!: () => unknown;
  onPost!: () => unknown;
  onPostpone!: () => unknown;
  loan!: Loan;
  pdcDetails!: LoanPdcDetailsWithAmortization;
  pdcPropertiesMap: unknown;

  constructor(private readonly $filter: NxIFilterService,
              private readonly $location: ILocationService,
              private readonly userCache: UserCache,
              private readonly confirmation: Confirmation,
              private readonly command: CommandService,
              private readonly userService: UserService,
              private readonly customerCache: CustomerCache,
              private readonly $route: NxRouteService) {
  }

  async $onInit(): Promise<void> {
    this.pdcPropertiesMap = await this.getPdcPropertiesMap();
  }

  private async getPdcPropertiesMap(): Promise<ReadonlyArray<{ key: string, value: string }>> {
    const propertiesMap: { key: string, value: string }[] = [{
      key: 'Type',
      value: this.pdcDetails.type
    },{
      key: 'Added by',
      value: (await this.userService.getUserName(this.pdcDetails.registeredBy))!
    }];

    if (this.pdcDetails.depositOperationId) {
      propertiesMap.unshift({
        key: 'Transaction no',
        value: `${this.pdcDetails.depositOperationId}`
      });
    } else {
      propertiesMap.unshift({
        key: 'From migration',
        value: `true`
      });
    }

    if (this.pdcDetails.updatedBy) {
      propertiesMap.push({
        key: 'Updated by',
        value: (await this.userService.getUserName(this.pdcDetails.updatedBy))!
      });
    }

    propertiesMap.push({
      key: 'Bank',
      value: this.pdcDetails.bankName || '-'
    }, {
      key: 'Status',
      value: this.$filter('prettyEnum')(this.pdcDetails.status)
    });

    if (this.pdcDetails.remarks) {
      propertiesMap.push({
        key: 'Remarks',
        value: this.pdcDetails.remarks
      });
    }

    if (this.pdcDetails.type === 'OUTWARD') {
      propertiesMap.push({
        key: 'Cleared on',
        value: this.pdcDetails.clearedOn ? this.$filter('prettyDate')(this.pdcDetails.clearedOn) : '-'
      });
    }

    if (this.pdcDetails.postponedToDate) {
      propertiesMap.push({
        key: 'Postponed to date',
        value: this.$filter('prettyDate')(this.pdcDetails.postponedToDate)
      });
    }
    return Object.freeze(propertiesMap);
  }

  edit() {
    this.$location.path(`/customer/${this.loan.customerId}/loans/${this.loan.id}/pdc/${this.pdcDetails.id}`);
  }

  async remove(): Promise<void> {
    const proceed = await this.confirmation(`Are you sure you want to delete the PDC for amortization no. ${this.pdcDetails.amortizationNo} and amount ${this.$filter('nxCurrency')(this.pdcDetails.amount)}?`);
    if (!proceed) return;

    await this.command.execute('RemoveLoanPDC', { pdcId: this.pdcDetails.id }).toPromise();
    this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).refetch();
    this.$route.reload();
  }

  canBeCanceled(): boolean {
    return this.pdcDetails.processStatus === LoanPDCProcessStatus.REGISTERED;
  }

  cancel(): void {
    this.onCancel();
  }

  canBePosted(): boolean {
    // note on REJECTED - this status is set as a result of unsuccessful payment, for example because of
    // insufficient funds, so such PDC can be reposted.
    return [LoanPDCStatus.DUE, LoanPDCStatus.OVERDUE, LoanPDCStatus.REJECTED, LoanPDCStatus.POSTPONED].includes(this.pdcDetails.status)
      || this.isReturnedAndValidForReposting();
  }

  private isReturnedAndValidForReposting(): boolean {
    const relatedAmortization: Amortization = this.loan.amortizationSchedule.list
      .find((a: Amortization) => Number(a.id) === Number(this.pdcDetails.amortizationId))!;
    return LoanPDCStatus.RETURNED === this.pdcDetails.status
      && relatedAmortization && relatedAmortization.status !== 'PAID';
  }

  post(): void {
    this.onPost();
  }

  canBePostponed(): boolean {
    return [LoanPDCStatus.DUE, LoanPDCStatus.OVERDUE, LoanPDCStatus.POSTPONED].includes(this.pdcDetails.status);
  }

  async postpone(): Promise<void> {
    const proceed = await this.confirmation(`Are you sure you want to postpone the PDC for amortization no. ${this.pdcDetails.amortizationNo} with due date ${this.$filter('prettyDate')(this.pdcDetails.amortization?.dueDate)}?`);
    if (!proceed) return;

    const response = await this.command.execute('PostponeLoanPDC', { pdcId: this.pdcDetails.id }).toPromise();
    if (!response?.approvalRequired) {
      this.onPostpone();
    }
  }
}

nxModule.component('loanPdcTabDetails', {
  templateUrl,
  controller: LoanPdcTabDetails,
  bindings: {
    onHide: '&',
    loan: '<',
    pdcDetails: '<',
    onCancel: '&',
    onPost: '&',
    onPostpone: '&'
  }
});
