import nxModule from 'nxModule';
import {IController, ILocationService} from 'angular';

import templateUrl from './batch-clearing-history-list.template.html';
import {NgTableParams} from 'ng-table';
import {UserCache, UserDetails} from '../../../service/users/user.cache';
import {PageResult} from "tools/HttpTypes";
import {HttpService} from "shared/utils/httpService";

interface BatchClearingHistory {
  id: number;
  executedOn: string;
  executedBy: number;
  commandId: number;
  count: number;
}

class BatchClearingHistoryList implements IController {

  tableConfig?: NgTableParams<BatchClearingHistory>;

  constructor(private $location: ILocationService,
              private http: HttpService,
              private userCache: UserCache) {}

  async $onInit(): Promise<void> {
    const allUsers: UserDetails[] = await this.userCache.withParam(true).toPromise();

    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: (params: NgTableParams<BatchClearingHistory>) => {
        const queryParams = { pageNo: params.page() - 1, pageSize: params.count() };
        return this.http.get('/checks/outgoing/clearing-history', { params: queryParams }).toPromise()
          .then((response: PageResult<BatchClearingHistory>) => {
            params.total(response.totalCount);
            return response.result.map(batch => {
              const executedByUser = allUsers.find(user => user.id === batch.executedBy)!;
              return {...batch, executedByUsername: executedByUser.fullName};
            } );
          });
      }
    })
  }

  getRowNo(index: number): number {
    if(!this.tableConfig) {
      return 1;
    }

    const currentPage = this.tableConfig.page() - 1; // starting from 0
    const rowsPerPage = this.tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }

  seeDetails(clearingGroup: BatchClearingHistory) {
    this.$location.path(`/inventory/batch-clearing/clearing-history/${ clearingGroup.id }`);
  }
}

nxModule.component('batchClearingHistoryList', {
  templateUrl: templateUrl,
  controller: BatchClearingHistoryList
});