import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('misGroupsCache', (http, CacheFactory, config) =>
  new LocalCache({
      provider: () => http.get(`/products/loans/mis-groups`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.misGroups,
      cacheName: `misGroups`
    }
  )
);
