import nxModule from 'nxModule';
import {CommandService} from "shared/utils/command/command.types";
import UserCounterService from "components/technical/status/user-counter.service";
import {ILocationService} from "angular";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";

export class MiscCommandService {
  constructor(private command: CommandService, private modalPrintPreviewService: ModalPrintPreviewService, private userCounterService: UserCounterService, private $location: ILocationService) { }

  async executeCommand(commandName: string, input: any) {
    await this.command.execute(commandName, input).toPromise();

    this.userCounterService.refresh();
    this.$location.path("/dashboard/miscellaneous-transactions");
  }
}

nxModule.service('miscCommandService', MiscCommandService);
