import nxModule from "nxModule";
import templateUrl from "components/batch/loans/batch-loan-transaction-list.template.html";
import {ActionType, Columns} from "components/common/dynamic-list/dynamic-list.component";
import {BatchTypes} from "components/batch/common/batch.types";

class BatchLoanTransactionList {
  private transactions: BatchTypes[] = [];
  private readonly columns: Columns = [
    { title: 'No'},
    { title: 'Transaction name', field: 'transactionName' },
    { actions: [{
        label: 'Open',
        field: 'href',
        type: ActionType.LINK
      }],
    },
  ];
  constructor(private commandAccessChecker: any) {

  }

  async $onInit(): Promise<void> {
    const canExecute = await this.commandAccessChecker.canExecuteCommandAsync();
    this.transactions = [{
      transactionName: 'Batch Payments',
      command: 'PayLoanByBatch',
      href: '#!/batch/loans/payments'
    }, {
      transactionName: 'Amortization movement',
      command: 'PerformBatchAmortizationMovement',
      href: '#!/batch/loans/amortization-movement'
    }].filter(transaction => !transaction.command || canExecute(transaction.command));
  }
}

nxModule.component('batchLoanTransactionList', {
  controller: BatchLoanTransactionList,
  templateUrl
});
