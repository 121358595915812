import nxModule from 'nxModule';

const templateUrl = require('./cash-placement.template.html');
nxModule.component('customerTermDepositCashPlacement', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, customerCache, productDefinitionService, confirmation, loader,
                        modalPrintPreviewService, customerDepositService, command) {
    let that = this;
    that.deposit = {};

    let customerId = $route.current.params['customerId'];
    let depositId = ~~$route.current.params['depositId'];

    customerDepositService.getDeposit(customerId, depositId, deposit => that.termDeposit = deposit);

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.deposit = () => {
      confirmation(`Do you want to place ${$filter('nxCurrency')(that.selectedAmount)}?`, () => {
        command.execute('DepositCashPlacement', {
            "productId": depositId,
            "amount": that.selectedAmount,
            "entryType": "CREDIT"
          }, {nxLoaderText: 'Performing cash placement'}
        ).success((res) => {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        });
      });
    }
  }
});
