import nxModule from 'nxModule';
import {Center, CenterLeader, CustomerGroupWithFundDetails} from "./center.types";
import {HttpService} from "shared/utils/httpService";

export interface CenterSearchCriteria {
  name?: string;
  referenceNumber?: number;
  branchIds?: number[];
  loanOfficerName?: string;
  loanCollectorName?: string;
  active?: boolean;
}

export interface CreateCenterInput {
  branchId: number;
  name: string;
  loanOfficerId: number;
  loanCollectorId: number | null;
}

export interface UpdateCenterInput extends CreateCenterInput {
  id: number;
  centerLeaders: CenterLeader[];
}

export interface TransferCustomerGroupToAnotherCenterInput {
  customerGroupId: number;
  newCenterId: number;
}

export interface AddCustomerGroupToCenterInput {
  customerGroupId: number;
  centerId: number;
}

export class CenterService {
  contextCenterId: number | null = null;

  constructor(private readonly http: HttpService) {
  }

  fetchCenters(params: CenterSearchCriteria): Promise<Center[]> {
    return this.http.get<Center[]>(`/management/centers`, {
      nxLoaderText: 'Loading centers...',
      params
    }).toPromise();
  }

  fetchCenter(centerId: number): Promise<Center> {
    return this.http.get<Center>(`/management/centers/${centerId}`, {nxLoaderText: 'Loading center...'}).toPromise();
  }

  fetchGroups(params: { centerId: number } | { groupId: number }): Promise<CustomerGroupWithFundDetails[]> {
    return this.http.get<CustomerGroupWithFundDetails[]>('/customers/group-with-fund-details', {
      nxLoaderText: 'Loading groups...',
      params
    }).toPromise();
  }

  loadContext(center?: Center): void {
    if (center?.id) {
      this.contextCenterId = center.id;
    }
  }

  unloadContext(): void {
    this.contextCenterId = null;
  }
}

nxModule.service('centerService', CenterService);