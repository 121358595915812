import {ILocationService} from 'angular';
import {CustomerProfile} from 'components/customer/profile/customer-profile.types';
import {CustomerCache} from 'components/service/customer.cache.types';
import {GroupCustomerCache} from 'components/service/group-customer.cache.types';
import {LoanCache} from 'components/service/loan.cache';
import _ from 'lodash';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {Loan} from '../../../service/loan.types';
import templateUrl from './customer-loans-assign-group.template.html';

interface RequestInput {
  loanId: number;
  targetCustomerId: number | null;
  newGroupLoan: boolean;
  groupLoanId?: number;
}

interface GroupLoanEntry {
  label: number;
  value: number;
}

class CustomerLoansAssignGroup {
  customerId: string;
  loanId: string;
  loan!: Loan;
  /**
   * List of customer groups that the current customer is part of
   */
  assignedGroups: CustomerProfile[] = [];
  allowedGroupLoans: GroupLoanEntry[] = [];
  request: RequestInput;

  constructor(private readonly $route: NxRouteService,
              private readonly $location: ILocationService,
              private readonly http: HttpService,
              private readonly customerCache: CustomerCache,
              private readonly loanCache: LoanCache,
              private readonly groupCustomerCache: GroupCustomerCache,
              private readonly command: CommandService,
              private readonly confirmationTemplate: ConfirmationTemplate) {
    this.customerId = $route.current.params['customerId'] as string;
    this.loanId = $route.current.params['loanId'] as string;
    this.request = {
      loanId: Number(this.loanId),
      targetCustomerId: null,
      newGroupLoan: true
    };
  }

  async $onInit(): Promise<void> {
    const [loan, assignedGroups] = await Promise.all([
      this.loanCache.loan(this.loanId).toPromise(),
      this.http.get<CustomerProfile[]>(`/products/loans/group-customer/matching?customerIds=${this.customerId}`).toPromise()
    ]);

    this.loan = loan;
    this.assignedGroups = assignedGroups;
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loanId}`);
  }

  async updateGroupLoans(): Promise<void> {
    if (!this.request.targetCustomerId || this.request.newGroupLoan) {
      return;
    }

    const groupLoans = await this.groupCustomerCache.batch(this.request.targetCustomerId, false).toPromise();
    this.allowedGroupLoans = _.range(0, groupLoans.length)
      /*
       * Loan released -> show released group loans
       * Loan unreleased -> show unreleased group loans
       *
       * Assigning unreleased loan to released group would block loan from releasing it
       */
      .filter(i => this.loan?.releaseDate ? groupLoans[i].releasedById : !groupLoans[i].releasedById)
      .filter(i => this.loan?.groupLoanId !== groupLoans[i].id)
      .map(i => ({
          label: i + 1,
          value: groupLoans[i].id
        })
    );
  }

  getAllowedGroupLoansHelpTitle(): string {
    return `List of${this.loan?.releaseDate ? ' ' : ' not '}released group loans in a selected group`;
  }

  getOldGroupDetails(): { label: string, description: string }[] {
    return this.getOldGroupLoanName() ? [
      {label: 'Old group', description: this.getOldGroupLoanName()}
    ] : [];
  }

  async assign(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to assign the loan to the new group?',
      details: this.getOldGroupDetails()
    });

    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('AssignLoanToGroup', this.request).toPromise();
    if (!response?.approvalRequired) {
      this.groupCustomerCache.evict();
      this.customerCache.loans(this.customerId).refetch();
      this.redirectBack();
    }
  }

  private getOldGroupLoanName(): string {
    return this.loan?.groupLoanName ?? '';
  }
}

nxModule.component('customerLoansAssignGroup', {
  templateUrl: templateUrl,
  controller: CustomerLoansAssignGroup
});