import nxModule from 'nxModule';
import templateUrl from './recalculate-amortization-schedule.template.html';
import {AmortizationSchedule, Loan} from "components/service/loan.types";
import {ILocationService} from "angular";
import moment from "moment";
import {HttpService} from "shared/utils/httpService";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

class RecalculateAmortizationSchedule {
  private loan!: Loan;
  private customerId!: number;
  private currentAmortizationSchedule!: AmortizationSchedule;
  private newAmortizationSchedule!: AmortizationSchedule;
  private lastAmortizationIndex: number = 0;
  private waiveRequest: unknown = null;

  constructor(private http: HttpService,
              private $location: ILocationService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private customerCache: CustomerCache){
  }

  async $onInit(): Promise<void> {
    const dataEndpoint = `/products/loans/${this.loan.id}/amortizations/recalculate-due-dates`;
    this.currentAmortizationSchedule = this.loan.amortizationSchedule;
    this.lastAmortizationIndex = this.currentAmortizationSchedule.list.length - 1;
    this.newAmortizationSchedule = await this.http.post<AmortizationSchedule>(dataEndpoint, this.loan).toPromise();
    this.loan.amortizationSchedule = this.newAmortizationSchedule;
    this.waiveRequest = {};
  }

  goBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  isAlreadyAdjusted(): boolean {
    if(!this.newAmortizationSchedule) {
      return false;
    }
    const newFirstPaymentDate = moment(this.loan.amortizationSchedule.list[0].dueDate);
    const currentFirstPaymentDate = moment(this.currentAmortizationSchedule.list[0].dueDate);
    return currentFirstPaymentDate.isSame(newFirstPaymentDate);
  }

  async save(): Promise<void> {
    const proceed = await this.confirmationTemplate({
      question: `Are you sure that you want to update the loan's amortization schedule?`,
      details: [
        {label: 'Current first payment date', description: moment(this.currentAmortizationSchedule.list[0].dueDate).format('MM/DD/YYYY')},
        {label: 'New first payment date', description: moment(this.loan.amortizationSchedule.list[0].dueDate).format('MM/DD/YYYY')},
        {label: 'Current maturity date', description: moment(this.currentAmortizationSchedule.list[this.lastAmortizationIndex].dueDate).format('MM/DD/YYYY')},
        {label: 'New maturity date', description: moment(this.loan.amortizationSchedule.list[this.lastAmortizationIndex].dueDate).format('MM/DD/YYYY')},
      ]
    });

    if (proceed) {
      await this.command.execute("RecalculateAmortizationSchedule", {productId: this.loan.id}).toPromise();
      this.customerCache.loans(this.customerId).refetch();
      this.goBack();
    }
  }
}

nxModule.component('recalculateAmortizationSchedule', {
  templateUrl: templateUrl,
  controller: RecalculateAmortizationSchedule,
  bindings: {
    loan: '<',
    customerId: '<'
  }
});