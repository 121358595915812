import nxModule from 'nxModule';
import {IAugmentedJQuery, IController, INgModelController} from 'angular';
import {Quill} from 'quill';
import {createIndentStyle} from "./wysiwyg-editor-indention-style.util";

import templateUrl from './wysiwyg-editor.template.html';
import './wysiwyg-editor.style.less';

enum InitializationStatus {
  LOADING = 'LOADING',
  FAILED = 'FAILED',
  LOADED = 'LOADED'
}

type QuillStaticType = typeof Quill;

const FontSizes = ['8', '9', '10', '11', '12',
  '14', '16', '18', '20', '22', '24', '26', '28',
  '36', '48', '72'].map(size => `${size}px`);

class WysiwygEditor implements IController {
  private initalizationStatus: InitializationStatus = InitializationStatus.LOADING;
  private quill?: Quill;
  private ngModel!: INgModelController;
  private initialText: string = '';
  private txtArea?: HTMLTextAreaElement;

  constructor(private $element: IAugmentedJQuery) {
  }

  async $onInit(): Promise<void> {
    this.ngModel.$render = () => {
      if(!this.quill) {
        this.initialText = this.ngModel.$modelValue || '';
        return;
      }

      this.quill.setText('');
      this.quill.clipboard.dangerouslyPasteHTML(this.ngModel.$modelValue || '');
      if(this.txtArea) {
        this.txtArea.value = this.ngModel.$modelValue || '';
      }
    };

    await this.initializeQuill();
  }

  async initializeQuill(): Promise<void> {
    try {
      const Quill: QuillStaticType = (await import(/* webpackChunkName: "quill" */ 'quill')).default;

      this.replaceClassesWithInlinebootstrapStyles(Quill);

      this.initalizationStatus = InitializationStatus.LOADED;
      console.log('Downloaded quill');

      this.quill = new Quill(this.$element.find('.editor')[0], {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'font': [] }],
            [{ 'header': [] }],
            [{ 'size': FontSizes }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link'],
            ['clean'],
            ['showTemplate']
          ]
        },
      });

      const txtArea: HTMLTextAreaElement = document.createElement('textarea');
      this.txtArea = txtArea
      txtArea.classList.add('editor-showTemplate');
      txtArea.style.display = 'none';

      if(this.initialText) {
        this.quill.setText('');
        this.quill.clipboard.dangerouslyPasteHTML(this.initialText);
        txtArea.value = this.initialText;
      }

      const htmlEditor = this.quill.addContainer('ql-template-editor');
      htmlEditor.appendChild(this.txtArea);

      txtArea.addEventListener('change', () => {
        const html = txtArea.value;
        this.quill?.setText('');
        this.quill?.clipboard.dangerouslyPasteHTML(html);
        this.ngModel.$setTouched();
        this.ngModel.$setViewValue(html);
      });

      this.quill.on('text-change', () => {
        const html = this.quill?.root.innerHTML;
        txtArea.value = html ?? '';
        this.ngModel.$setTouched();
        this.ngModel.$setViewValue(html);
      });

      const showTemplateButton = this.$element.find('.ql-showTemplate');
      showTemplateButton.on('click', () => this.toggleTemplateMode());

    } catch(ex) {
      console.error('Error loading quill', ex);
      this.initalizationStatus = InitializationStatus.FAILED;
    }
  }

  toggleTemplateMode(): void {
    if(this.txtArea) {
      this.txtArea.style.display = this.txtArea.style.display === 'none' ? '' : 'none';
    }
  }

  replaceClassesWithInlinebootstrapStyles(Quill: QuillStaticType): void {
    const AlignStyle = Quill.import('attributors/style/align');
    const ColorStyle = Quill.import('attributors/style/color');
    const DirectionStyle = Quill.import('attributors/style/direction');
    const FontStyle = Quill.import('attributors/style/font');
    const SizeStyle = Quill.import('attributors/style/size');

    // values in word font size selector
    SizeStyle.whitelist = FontSizes;

    const indentStyle = createIndentStyle(Quill);

    Quill.register(AlignStyle, true);
    Quill.register(ColorStyle, true);
    Quill.register(DirectionStyle, true);
    Quill.register(FontStyle, true);
    Quill.register(SizeStyle, true);
    Quill.register(indentStyle, true);
  }
}

nxModule.component('wysiwygEditor', {
  controller: WysiwygEditor,
  templateUrl,
  require: {
    ngModel: '^ngModel'
  },
});
