import nxModule from 'nxModule';
import {IRootScopeService} from "angular";
import {Breadcrumbs} from "angular-breadcrumbs";

export class BreadcrumbsService {

  constructor(private breadcrumbs: Breadcrumbs, private $rootScope: IRootScopeService) {
    $rootScope.$watch(
      () => breadcrumbs.options,
      () => {
        breadcrumbs.generateBreadcrumbs();
      },
      true
    );
  }
}

nxModule.service('breadcrumbsService', BreadcrumbsService);
