import {ICacheFactoryService} from 'angular';
import {Config} from 'config';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import LocalCache from 'shared/utils/localCache';

export interface Organization {
  id: number;
  root: boolean;
  parentId: number;
  name: string;
  code: string;
  sssCode: string;
  bspCode: string;
  depEdDeductionCode: string;
  cicProviderCode: string;
  seqNo: number;
  tinNumber: string;
  bankId: number;
  children: Organization[];
}

export type OrganizationCache = LocalCache<any, Organization[]>;

nxModule.factory('organizationCache', (http: HttpService, CacheFactory: ICacheFactoryService, config: Config) =>
  new LocalCache({
    provider: () => http.get('/management/organizations'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.organizationsConfig,
    cacheName: 'organizationsConfig'
  }));
