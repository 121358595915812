import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';

const templateUrl = require('./check-withdrawal.template.html');
nxModule.component('customerAccountsCheckWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, depositoryAccountCache, productDefinitionService,
                        branchService, authentication, accountOperationService) {
    let that = this;
    that.depositAccount = {};
    that.checkingAccounts = [];
    that.banks = [];

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    // form parameters
    that.checkNo = null;
    that.micrNumber = '';
    that.checkingAccountId = null;
    that.amount = null;
    that.validFrom = null;
    that.payee = null;
    that.remarks = null;
    that.micrUnsupportedBySelectedDepositoryAccount = null;

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    that.onMicrNumberChange = (micrNumber) => {
      if (!isMicrProvided(micrNumber)) {
        that.checkNo = null;
        that.micrUnsupportedBySelectedDepositoryAccount = null;
        return;
      }

      that.checkNo = micrNumber.substr(0, 10);
      that.micrUnsupportedBySelectedDepositoryAccount = isMicrUnsupportedBySelectedDepositoryAccount();
    };

    that.onCheckingAccountChange =  () => {
      that.micrUnsupportedBySelectedDepositoryAccount = isMicrUnsupportedBySelectedDepositoryAccount()
    }

    const isMicrUnsupportedBySelectedDepositoryAccount = () => {
      return isMicrProvided(that.micrNumber)
        && that.checkingAccountId
        && !isSelectedDepositoryAccountAcceptingBrstnAndAccountNumber(that.micrNumber);
    };

    const isMicrProvided = micrNumber => micrNumber && micrNumber.length === 31;

    const isSelectedDepositoryAccountAcceptingBrstnAndAccountNumber = micr => {
      const brstn = micr.substr(10, 9);
      const accountNumber = micr.substr(19);
      const depositoryAccount = that.checkingAccounts.find(account => account.id === Number(that.checkingAccountId));
      return depositoryAccount?.brstn === brstn
        && depositoryAccount?.accountNumber === accountNumber;
    };

    const branchSub = branchService.toObservable().subscribe(branches => {
      const branchId = authentication.context.branchId;
      const authBranch = _.find(branches, {id: branchId});
      if (authBranch) {
        const systemDate = moment(authBranch.systemDate).toDate();
        that.systemDate = systemDate;
        that.validFrom = systemDate;
      }
    });

    const depositoryAccountSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.checkingAccounts = _.filter(depositoryAccounts, account => {
        return (account.accountType === 'CHECKING' || account.accountType === 'CLEARING') && account.branchId === authentication.context.branchId;});
    });

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) => {
        return depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>'
          });
        })
      })
      .combineLatest(customerCache.profile(customerId).toObservable(), (depositAccounts, customer) => {
        that.payee = customer.effectiveName;
        return depositAccounts;
      })
      .first()
      .subscribe(accounts => {
        that.depositAccount = _.find(accounts, (a) => String(a.id) === productId);
        that.remarks = 'Withdrawal from account no. ' + that.depositAccount.productNumber;
      });

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.withdraw = () => {
      accountOperationService.checkWithdraw({
        customerId: customerId,
        productId: productId,
        amount: that.amount,
        validFrom: that.validFrom,
        depositoryAccountId: that.checkingAccountId,
        micrNumber: that.micrNumber,
        checkNumber: that.checkNo,
        payee: that.payee,
        remarks: that.remarks
      }, that.redirectBack)
    }

    that.$onDestroy = () => {
      depositoryAccountSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
