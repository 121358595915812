import templateUrl from './mfa-configuration.template.html';
import nxModule from 'nxModule';
import _ from "lodash";

class MfaConfiguration {
  constructor(notification) {
    this.configureViaQr = true;
    this.notification = notification;
  }

  $onInit() {
    this.notification.show('Warning', 'Any changes made in MFA will take place immediately even without pushing the save or cancel button.');
  }

  $onChanges(changesObj){
    if(changesObj.secret && changesObj.secret.currentValue !== changesObj.secret.previousValue) {
      // regenerate Authenticator URI
      this.secretTotpURI = this.generateTotpURI(changesObj.secret.currentValue);
      this.humanReadableSecret = _(changesObj.secret.currentValue)
        .toArray()
        .chunk(4)
        // no spaces in chunks...
        .map(part => part.join(''))
        // just between them
        .join(' ');
    }
  }

  generateTotpURI(newSecret) {
    return `otpauth://totp/${encodeURIComponent(`${this.organizationName}:${this.username}`)}?secret=${newSecret}`;
  }

  toggleConfigurationWay() {
    this.configureViaQr = !this.configureViaQr;
  }
}

nxModule.component('mfaConfiguration', {
  templateUrl,
  controller: MfaConfiguration,
  bindings: {
    secret: '<',
    organizationName: '<',
    username: '<'
  }
});
