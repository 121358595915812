import nxModule from 'nxModule';
import _ from 'lodash';
import './predicate.style.less';

const templateUrl = require('./predicate.template.html');

class Predicate {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$watchCollection('$ctrl.predicates', () => {
      this.predicateDefined = _.has(this.predicates, this.type);
    });
  }

  close() {
    if (this.accessInput) {
      this.deletePredicateForType();
    } else {
      delete this.predicates[this.type];
    }
  }

  deletePredicateForType() {
    if (Object.keys(this.predicates[this.type]).length > 0) {
      delete this.predicates[this.type][this.accessInput];
    }

    if (Object.keys(this.predicates[this.type]).length === 0) {
      delete this.predicates[this.type];
    }
  }

}

nxModule.component('predicate', {
  templateUrl,
  transclude: true,
  bindings: {
    predicates: '<',
    type: '<',
    ngDisabled: '<',
    accessInput: '<'
  },
  controller: Predicate
});
