import _ from 'lodash'

import nxModule from 'nxModule';


export class CustomerService {
  constructor(fileService, notification, popup, $location) {
    this.fileService = fileService;
    this.notification = notification;
    this.popup = popup;
    this.$location = $location;
  }

  // @deprecated. use customer.effectiveName field
  effectiveName(customer) {
    if (customer.customerType === 'INDIVIDUAL') {
      const middleName = customer.individualData.middleName ? customer.individualData.middleName + ' ' : '';
      return `${customer.individualData.firstName} ${middleName}${customer.individualData.lastName}`;
    } else {
      return customer.corporateData.businessName;
    }
  }

  extractPhones(customer) {
    customer.extractedPhones = {
      home: _.find(customer.phones, {type: 'HOME'}),
      work: _.find(customer.phones, {type: 'WORK'}),
      mobile: _.find(customer.phones, {type: 'MOBILE'})
    };
    return customer;
  }

  formatDates(customer) {
    if (customer.incomeSources) {
      customer.incomeSources.forEach(incomeSource => incomeSource.startOfBusinessDate = new Date(incomeSource.startOfBusinessDate));
    }
  }

  encodeNewLinesInComments(customer) {
    if (customer.comments) {
      customer.comments = customer.comments.replace(new RegExp('\n', 'g'), ';');
    }
  }

  decodeNewLinesInComments(customer) {
    if (customer.comments) {
      customer.comments = customer.comments.replace(new RegExp(';', 'g'), '\n');
    }
  }

  redirectWhenProfileIsInvalid(customer) {
    if (customer.status === 'CLOSED') {
      this.popup({
        text: `Customer is already closed. Please reactivate the customer to proceed with any transactions.`,
        callback: () => this.$location.path(`/customer/${customer.id}/profile`)
      });
    } else if (customer.shouldUpdateProfile) {
      this.popup({
        text: `Customer profile data expired. Please update the profile.`,
        callback: () => this.$location.path(`/customer/${customer.id}/profile/force`),
        renderHtml: false,
        actionRequired: true
      });
    } else if (customer.hasInvalidData) {
      this.popup({
        text: `Customer has incomplete profile data. Please update the profile.`,
        callback: () => this.$location.path(`/customer/${customer.id}/profile`)
      });
    }
  }
}

nxModule.service('customerService', CustomerService);
