import nxModule from 'nxModule';
import ParameterizedLocalCache from "shared/utils/parameterized-local-cache";

nxModule.factory('accessRuleCache', (http, CacheFactory, config, $filter) =>
  new ParameterizedLocalCache({
    provider: roleIds => http.get(`/access/roles/${roleIds}/rules`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.accessRule,
    cacheName: 'accessRuleCommands',
    postProcessor: accessRules => {
      accessRules.forEach(accessRule => {
        accessRule.displayName = $filter('translateEnum')(accessRule.command, 'COMMAND')
      });

      return accessRules;
    }
  })
);
