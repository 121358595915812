import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';
import {Amortization} from '../../../service/loan.types';
import {FeeDefinition, FeePredicate} from "../../../service/fees/fee.types";

import './amortization-custom-fees-inline-panel.style.less'
import templateUrl from './amortization-custom-fees-inline-panel.template.html';


interface CustomFee {
  id: number | null;
  name: string;
  amortizationLineNumber: number;
  balance: number;
  amount: number;
  feeDefinitionId: number;
  type: 'AMORTIZATION_SPECIFIC' | 'AMORTIZED';
  applyPredicates: FeePredicate[];
}

class AmortizationCustomFeesInlinePanelComponent {

  private feeDefinitions!: FeeDefinition[];
  private allowedFeeDefinitions: FeeDefinition[] = [];
  private amortization!: Amortization;
  private customFees!: CustomFee[];
  private onUpdate!: () => void;
  private feeDefinitionId: string | null = null;
  private addEnabled: boolean = false;

  private selectConfig = {
    placeholder: 'Select custom fee',
    searchField: ['feeName'],
    valueField: 'id',
    labelField: 'feeName',
    maxItems: 1
  };

  getFees(lineNumber: number): CustomFee[] {
    return this.customFees?.filter(f => f.amortizationLineNumber === lineNumber);
  }

  updateAmortization(lineNumber: number): void {
    this.amortization.customFeesBalance = this.sumFeePropertyByAmortizationLineNumber(f  => f.balance, lineNumber);
    this.amortization.customFeesAmount = this.sumFeePropertyByAmortizationLineNumber(f => f.amount, lineNumber);

    this.onUpdate();
  }

  stopPropagation(e : Event) : void {
    e.stopPropagation();
  }

  enableAdd(e : Event) : void {
    e.stopPropagation();
    this.addEnabled = true;
    const existingFeeIds = this.customFees.filter(f => f.amortizationLineNumber == this.amortization.lineNumber)
      .map(f => f.feeDefinitionId);
    this.allowedFeeDefinitions = this.feeDefinitions.filter(f => !existingFeeIds.includes(f.id) && f.applyOn === 'LOAN_PAYMENT');
  }

  disableAdd() : void {
    this.addEnabled = false;
  }

  deleteCustomFee(customFee : CustomFee) : void {
    this.customFees.splice(this.customFees.findIndex(fee => fee.feeDefinitionId === customFee.feeDefinitionId), 1);
    this.updateAmortization(customFee.amortizationLineNumber);
  }

  addCustomFee(): void {
    if(!this.feeDefinitionId) {
      return;
    }

    if(!this.customFees) {
      this.customFees = [];
    }
    const feeDefinition = this.feeDefinitions.find(fd => this.feeDefinitionId && fd.id === parseInt(this.feeDefinitionId))!;
    this.customFees.push({
      id: null,
      name: feeDefinition.feeName,
      amortizationLineNumber: this.amortization.lineNumber,
      balance: 0,
      amount: 0,
      feeDefinitionId: feeDefinition.id,
      type: 'AMORTIZATION_SPECIFIC',
      applyPredicates: [{
        value: this.amortization.lineNumber,
        compareMode: 'EQUAL',
        type: 'LoanAmortizationLineNumberFeePredicate'
      }]
    });
    this.feeDefinitionId = null;
    this.addEnabled = false;
  }

  private sumFeePropertyByAmortizationLineNumber(extractor: (fee: CustomFee) => number, amortizationLineNumber: number): number {
    return sum(
      this.customFees.filter(f => f.amortizationLineNumber === amortizationLineNumber)
      .map(extractor)
    ).dp(2)
    .toNumber();
  }
}

nxModule.component('amortizationCustomFeesInlinePanel', {
  templateUrl: templateUrl,
  bindings: {
    'amortization': '=',
    'customFees': '=',
    'feeDefinitions': '<',
    'onHide': '&',
    'onUpdate': '&'
  },
  controller: AmortizationCustomFeesInlinePanelComponent
});
