import nxModule from 'nxModule';

import templateUrl from './logged-in-users.template.html';
import './logged-in-users.style.less'
import {NgTableParams} from 'ng-table';
import _ from 'lodash';

import systemPropertyService from "../../../../../react/system/systemPropertyService";
import {Branch} from "management/BranchTypes";
import {PageResult} from "tools/HttpTypes";
import {Role} from "user/UserTypes";
import Authentication from "shared/utils/authentication";
import RolesCache from "components/service/roles.cache";
import {HttpService} from "shared/utils/httpService";
import {BranchService} from 'components/service/branch.service';

export interface LoggedUserInfo {
  loggedIn: boolean,
  loginTime: Date,
  logoutTime: Date,
  branchName: string,
  userFullName: string,
  userRole: string,
  cmc: string,
  publicIp: string
}

export interface LoggedInUsersRequest {
  pageNo: number,
  pageSize: number,
  branchIds: number[] | null,
  roleIds: number[] | null,
  agentOnly: boolean
}


class LoggedInUsers {

  private tableConfig?: NgTableParams<unknown>;
  private allRoles: Role[] = [];
  agentOnly: boolean = false;
  branches: Branch[] = [];
  roles: Role[] = [];
  isAgencyBankingEnabled: boolean = false;

  // init with [null] (select all)
  private selectedBranchIds: (number | null)[] = [null];
  private selectedRoleIds: (number | null)[] | null = [null];

  constructor(private branchService: BranchService,
              private http: HttpService,
              private authentication: Authentication,
              private rolesCache: RolesCache) {
  }

  async $onInit(): Promise<void> {
    const [branches, roles] = await Promise.all([
      this.fetchAvailableBranches(),
      this.rolesCache.toPromise(),
    ]);

    this.allRoles = roles;
    this.branches = branches;
    this.roles = _.cloneDeep(roles);
    this.isAgencyBankingEnabled = systemPropertyService.getProperty('AGENT_MODULE_ENABLED') === 'TRUE';
  }

  private async fetchAvailableBranches(): Promise<Branch[]> {
    const allBranches = await this.branchService.toPromise();
    const availableBranches = this.authentication.context.branchIds;
    return allBranches.filter(branch => availableBranches.includes(branch.id));
  }

  viewLoggedInUsers(): void {
    this.tableConfig = new NgTableParams({
      count: 20
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: (params: NgTableParams<unknown>) => this.fetchData(params)
    });
  }

  filterAgentRoles(): void {
    this.roles = this.agentOnly ? this.allRoles.filter(r => r.agent) : this.allRoles;
    this.selectedRoleIds = null;
  }

  private async fetchData(params: NgTableParams<unknown>): Promise<LoggedUserInfo[]> {
    let branchIds = this.selectedBranchIds;
    let roleIds = this.selectedRoleIds;
    if (this.selectedBranchIds.length === 1 && this.selectedBranchIds[0] === null) {
      // set the selectedBranchIds to null
      // this is to avoid a big payload being sent to the server
      // this is also handled in the back-end, when selectedBranchIds is null
      // it will only filter the available branches for the AuthUser
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      branchIds = null;
    }
    if (this.selectedRoleIds?.length === 1 && this.selectedRoleIds[0] === null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      roleIds = null;
    }

    const request = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      branchIds: branchIds,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      roleIds: roleIds,
      agentOnly: this.agentOnly,
      pageNo: params.page() - 1,
      pageSize: params.count()
    };

    const response = <PageResult<LoggedUserInfo>>await this.http.get(`/management/users/logged-in`, {
      params: request
    }).toPromise();
    params.total(response.totalCount);
    return response.result;
  }
}

nxModule.component('loggedInUsers', {
  templateUrl,
  controller: LoggedInUsers
});