import BigNumber from 'bignumber.js';
import {
  allPercentageChargeTypes,
  balloonDiminishingAmortizationTypes,
  pastDueInterestChargeTypes
} from 'constants/loan';
import './customer-loan-charges-override.style.less';
import _ from 'lodash';
import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';

import templateUrl from './customer-loan-charges-override.template.html';

nxModule.component('customerLoanChargesOverride', {
  templateUrl: templateUrl,
  bindings: {
    loan: '=',
    formName: '=',
    loanProduct: '<',
    overrideRequired: '<',
    categoryIds: '<',
    customFieldValues: '<',
    onUpdated: '<',
    editMode: '<'
  },
  controller: function ($scope, $route, http, notification, command, $filter, feeDefinitionsCache, customerCache, loanFeeService, customerLoanCreateService) {
    let that = this;
    that.overrideChkbox = false;
    that.modificationMode = false;

    that.canEnableFeeOverride = () => {
      if(!that.loan.principalAmount) {
        return false;
      }

      if(!that.loan.totalAmortizationNumber) {
        return false;
      }


      if (balloonDiminishingAmortizationTypes.includes(that.loanProduct.amortizationType) &&
        !that.loan.diminishingAmortizationNumber) {
        return false;
      }

      return true;
    };

    that.fees = [
      { name: 'Doc stamp', key: 'docStamp', feeClass: 'DOC_STAMP' },
      { name: 'Notarial Fee', key: 'notarialFee', feeClass: 'NOTARIAL_FEE' },
      { name: 'Application Fee', key: 'applicationFee', feeClass: 'APPLICATION_FEE' },
      { name: 'Credit Investigation Fee', key: 'creditInvestigationFee', feeClass: 'CREDIT_INVESTIGATION_FEE' },
      { name: 'Extra Bank Fee', key: 'extraBankFee', feeClass: 'EXTRA_BANK_FEE' },
      { name: 'Service Charge', key: 'serviceCharge', feeClass: 'SERVICE_CHARGE' },
      { name: 'Membership Fee', key: 'membershipFee', feeClass: 'MEMBERSHIP_FEE' },
      { name: 'Id Fee', key: 'idFee', feeClass: 'ID_FEE' },
      { name: 'Insurance Fee', key: 'insuranceFee', feeClass: 'INSURANCE_FEE' },
      { name: 'Insurance Service Fee', key: 'insuranceServiceFee', feeClass: 'INSURANCE_SERVICE_FEE' },
      { name: 'Insurance Processing Fee', key: 'insuranceProcessingFee', feeClass: 'INSURANCE_PROCESSING_FEE' }
    ];

    that.contractualFees = [
      { name: 'CBU Charge', key: 'cbuCharge' },
      { name: 'PF Charge', key: 'pfCharge' },
      { name: 'TP Charge', key: 'tpCharge' }
    ];

    that.allFees = [
      ...that.fees,
      ...that.contractualFees
    ];

    that.pastDueInterestChargeTypes = pastDueInterestChargeTypes;
    that.penaltyChargeTypes = [...allPercentageChargeTypes, {label: 'Fixed amount', value: 'FIXED_AMOUNT'}];

    that.defaultFees = {};
    that.feeVisibility = {};
    that.feeType = {};
    that.customFeeVisibility = {};

    that.allFees.map(el => el.key).forEach(el => that.defaultFees[el] = 0);

    that.diff = _.cloneDeep(that.defaultFees);

    that.diffCalculate = (key) => {
      var currentFee = that.defaultFees[key];
      var loanFee = that.loan.feeOverride[key];
      if (currentFee >= 0 && loanFee >= 0) {
        var current = new BigNumber(currentFee);
        var override = new BigNumber(loanFee);
        that.diff[key] = override.minus(current).toNumber();
      } else {
        that.diff[key] = 0;
      }
    };

    that.filterNonFormulaFees = customFeesOverride => {
      return customFeesOverride?.filter(cf => cf.feeType !== 'FORMULA' && cf.applyOn !== 'FORMULA');
    }

    that.filterAndSortFormulaFees = customFeesOverride => {
      return customFeesOverride
        ?.filter(cf => cf.feeType === 'FORMULA' || cf.applyOn === 'FORMULA')
        ?.sort((c1, c2) => c1.name.localeCompare(c2.name))
    }

    that.amountChanged = () => {
      const formulaRelatedFees = that.filterAndSortFormulaFees(that.loan.customFeesOverride);
      if (!formulaRelatedFees || formulaRelatedFees.length === 0) {
        return;
      }

      formulaRelatedFees.forEach(f => {
        if (f.amount === f.defaultAmount) {
          f.amount = null;
        }
      });
      that.recalculationRequired = true;
      that.warningMessage = 'Fee amount was overridden. Use the Recalculate button to recompute the formula fee(s) amount.';
    }

    that.percentageChanged = (fee) => {
      that.loan.customFeesOverride.find(fo => fo.feeDefinitionId === fee.feeDefinitionId).amount = null;
      that.recalculationRequired = true;
      that.warningMessage = 'Fee percentage was overridden. Use the Recalculate button to recompute the fee amount.';
    }

    that.recalculateDefaultAndCustomFees = async () => {
      const loan = await that.simulateLoan(false);

      that.loan.customFeesOverride.forEach(f => {
        const simulatedFee = loan.simulatedFees.find(sf => sf.feeDefinitionId === f.feeDefinitionId);
        f.amount = simulatedFee.amount
      });

      that.recalculationRequired = false;
      that.warningMessage =  null;
    }

    that.calculateDefaultAndCustomFees = async () => {
      if (!that.loan.principalAmount) return;

      // init fees displayed in "Standard" column
      const loan = await that.calculateStandardFees();

      that.fees.forEach(fee => {
        const feeDef = that.feeDefinitions.find(fd => fd.feeClass === fee.feeClass);
        that.feeVisibility[fee.key] = feeDef.displayOnProductCreation;
        that.feeType[fee.key] = feeDef.feeType;
      });

      that.feeVisibility['cbuCharge'] = that.loanProduct.individualCbuAccountTypeId != null || that.loanProduct.corporateCbuAccountTypeId != null;
      that.feeVisibility['pfCharge'] = that.loanProduct.individualPfAccountTypeId != null || that.loanProduct.corporatePfAccountTypeId != null;
      that.feeVisibility['tpCharge'] = that.loanProduct.individualTpAccountTypeId != null || that.loanProduct.corporateTpAccountTypeId != null;

      // init fees displayed in "Override" column
      that.loan.feeOverride = _.cloneDeep(that.defaultFees);
      await that.includeCustomFees(loan.simulatedFees);
      that.recalculateDiffs();
    };

    that.simulateLoan = async (clearFeeOverride) => {
      const clonedLoan = customerLoanCreateService.createLoanCommandInput(that.loan);
      clonedLoan.collateralFiles = undefined;
      clonedLoan.creationType = clonedLoan.creationType || 'NEW_LOAN';
      clonedLoan.automaticTransfer = false;
      clonedLoan.feeOverride = clearFeeOverride ? null: clonedLoan.feeOverride;
      clonedLoan.customFeesOverride = clearFeeOverride ? null : clonedLoan.customFeesOverride;
      return await http.post('/products/loans/simulate', clonedLoan).toPromise();
    }

    that.calculateStandardFees = async () => {
      const loan = await that.simulateLoan(true);

      for (let el of that.fees) {
        that.defaultFees[el.key] = loan[el.key];
      }

      // set contractual savings fees
      for (let el of that.contractualFees) {
        that.defaultFees[el.key] = calculateContractualSavingsCharge(loan.amortizationSchedule.list, el);
      }

      const annualPdiRate = loan.interestType === 'ADD_ON_RATE' ? loan.grossEirAnnual : loan.interestRate;
      if(that.loanProduct.defaultPastDueToInterestRate) {
        that.loan.pastDueInterestCharge.rate = annualPdiRate;
      }

      if(that.loanProduct.defaultPastDueMaturityToInterestRate) {
        that.loan.pastDueMaturityInterestCharge.rate = annualPdiRate;
      }
      return loan;
    };

    that.includeCustomFees = async (fees) => {
      that.loan.customFeesOverride = fees.filter(fee => !fee.applied)
        .map(fee => {
          const feeDefinition = that.feeDefinitions.find(feeDefinition => feeDefinition.id === fee.feeDefinitionId);
          fee.name = feeDefinition.feeName;
          fee.calculationOrder = feeDefinition.calculationOrder;
          fee.applyOn = feeDefinition.applyOn;
          fee.defaultAmount = fee.amount;
          fee.defaultPercentage = fee.percentage;
          that.customFeeVisibility[fee.feeDefinitionId] = feeDefinition.displayOnProductCreation;
          fee.feeType = feeDefinition.feeType;
          fee.feeAmortizationType = feeDefinition.feeAmortizationType;
          return fee;
        });
    };

    that.shouldAlwaysIncludeOnDiscountCharges = fee => {
      const feeDefinition = that.feeDefinitions.find(feeDefinition => feeDefinition.id === fee.feeDefinitionId);
      return fee.paidUpfront && feeDefinition.alwaysIncludeOnDiscountCharges;
    }

    const getExistingFees = async () => {
      if (that.loan.id && that.loan.principalAmount && that.modificationMode) {
        // get calculated fees and charges
        await that.calculateDefaultAndCustomFees();

        // override them with the existing Fees when the loan was created
        const clonedLoan = _.cloneDeep(that.loan);
        const allLoanFees = await customerCache.customerProductFees(that.loan.customerId, 'LOAN').toPromise();
        const loanCustomFees = allLoanFees.filter(fee => fee.productId === that.loan.id);

        // set the existing fees
        const existingFees = {};
        for (let el of that.fees) {
          existingFees[el.key] = clonedLoan[el.key];
        }

        for (let el of that.contractualFees) {
          existingFees[el.key] = calculateContractualSavingsCharge(clonedLoan.amortizationSchedule.list, el);
        }

        that.loan.feeOverride = _.cloneDeep(existingFees);
        for(let fee of that.fees) {
          that.loan.feeOverride[fee.key + 'AlreadyPaid'] = that.loan[fee.key + 'AlreadyPaid'];
        }

        // clone the calculated customFees from that.calculateDefaultAndCustomFees() to be used to get the default amount of the custom fee
        const calculatedCustomFees = _.cloneDeep(that.loan.customFeesOverride);

        that.loan.customFeesOverride = loanCustomFees.filter(fee => !fee.applied)
          .map(fee => {
            const feeDefinition = that.feeDefinitions.find(feeDefinition => feeDefinition.id === fee.feeDefinitionId);
            const calculatedFee = calculatedCustomFees.find(calculatedCustomFee => calculatedCustomFee.feeDefinitionId === fee.feeDefinitionId);
            fee.name = feeDefinition.feeName;
            fee.defaultAmount = calculatedFee.amount;
            fee.defaultPercentage = calculatedFee.percentage;
            fee.feeType = feeDefinition.feeType;
            fee.feeAmortizationType = feeDefinition.feeAmortizationType;
            return fee;
          });
      }

      that.recalculateDiffs();
    };

    const calculateContractualSavingsCharge = (amortizations, element) => {
      const feeAmounts = amortizations.map(amortization =>
        new BigNumber(parseFloat(amortization[element.key + 'Amount']))
      );

      return sum(feeAmounts).toNumber();
    };


    that.clearFeesOverride = () => {
      that.loan.feeOverride = {};
      that.loan.customFeesOverride = [];

      if(!that.loanProduct.defaultPastDueToInterestRate) {
        that.loan.pastDueInterestCharge = _.clone(that.loanProduct.pastDueInterestCharge);
      }

      if(!that.loanProduct.defaultPastDueMaturityToInterestRate) {
        that.loan.pastDueMaturityInterestCharge = _.clone(that.loanProduct.pastDueMaturityInterestCharge);
      }

      that.loan.penalty = _.clone(that.penalty);

      that.loan.penaltyMaturity = _.clone(that.loanProduct.penaltyMaturity);

      if (that.modificationMode) {
        that.calculateDefaultAndCustomFees();
      }
    };

    that.overrideChkboxChange = () => {
      if (that.overrideChkbox) {
        that.calculateDefaultAndCustomFees();
      } else {
        if (!that.loan.id) {
          // if checkbox is not selected and we are editing not existing loan (without id)
          // turn off modification mode to hide override table
          that.modificationMode = false;
        }
        that.clearFeesOverride();
      }
    };

    that.isPaidUpfrontAllowed = (fee) => {
      return !that.contractualFees.some(f => f.key === fee.key);
    }

    $scope.$watch('$ctrl.loan.creationType', () => {
      const message = 'The creation type has been changed, charges and fees will be recalculated, please fill charges override form again if necessary.';
      if (!that.modificationMode || that.originalCreationType !== that.loan.creationType) {
        that.clearFeesOverrideWithMessage(message);
      }
    });

    $scope.$watch('$ctrl.loan.principalAmount', () => {
      if (that.loan.creationType !== 'RESTRUCTURED') {
        const message = 'The principal amount has been changed, charges and fees will be recalculated, please fill charges override form again if necessary.';
        if (!that.modificationMode || that.originalPrincipal !== that.loan.principalAmount) {
          that.clearFeesOverrideWithMessage(message);
        }
      }
    });

    $scope.$watch('$ctrl.loanProduct', async () => {
      if (that.loanProduct && that.feeDefinitions) {
        that.feeDefinitions = that.feeDefinitions.filter(fd => fd.productDefinitionId === that.loanProduct.productDefinition.id);
        that.penaltyDef = that.feeDefinitions.find(fd => fd.feeClass === 'PENALTY');
        that.penalty = loanFeeService.getChargeFromFeeDefinition(that.penaltyDef);
      }
    })

    $scope.$watch('$ctrl.categoryIds', () => {
      const message = 'A custom field category has been changed, charges and fees will be recalculated, please fill charges override form again if necessary.';
      if (!that.modificationMode || that.originalCreationType !== that.loan.creationType) {
        that.clearFeesOverrideWithMessage(message);
      }
    });

    $scope.$watch('$ctrl.customFieldValues', () => {
      const message = 'A custom field value has been changed, charges and fees will be recalculated, please fill charges override form again if necessary.';
      if (!that.modificationMode || that.originalCreationType !== that.loan.creationType) {
        that.clearFeesOverrideWithMessage(message);
      }
    });

    that.closeAlert = () => {
      that.warningMessage = null;

      if (that.onUpdated) {
        that.onUpdated(null);
      }
    };

    that.clearFeesOverrideWithMessage = (message) => {
      if (that.overrideChkbox || that.modificationMode) {
        that.overrideChkbox = false;
        that.overrideChkboxChange();

        if (that.onUpdated) {
          that.onUpdated(message);
        }

        const oldMessage = that.warningMessage;
        that.warningMessage = message;

        if (oldMessage !== message) {
          notification.show('Warning', message);
        }
      }
    };

    that.recalculateDiffs = () => that.allFees.map(el => el.key).forEach(key => that.diffCalculate(key));

    that.$onInit = async () => {
      that.feeDefinitions = await feeDefinitionsCache.toPromise();
      if (that.loanProduct) {
        that.feeDefinitions = that.feeDefinitions.filter(fd => fd.productDefinitionId === that.loanProduct.productDefinition.id);
        that.penaltyDef = that.feeDefinitions.find(fd => fd.feeClass === 'PENALTY');
        that.penalty = loanFeeService.getChargeFromFeeDefinition(that.penaltyDef);
      }

      // if loan already exists (has id) -> init form in "modification mode"
      if (that.loan && that.loan.id) {
        that.modificationMode = true;
        that.originalPrincipal = that.loan.principalAmount;
        that.originalCreationType = that.loan.creationType;
        await getExistingFees();

        const penalty =  await http.get(`/products/loans/${that.loan.id}/product-fee-definition/evaluate?feeClass=PENALTY`).toPromise();
        const penaltyCharge = {
          rate: penalty.calculationMethod === 'FIXED_AMOUNT' ? penalty.fixedAmount : penalty.percentageAmount,
          type: penalty.calculationMethod
        }
        that.penalty = penaltyCharge;
        that.loan.penalty = _.cloneDeep(penaltyCharge);

      // else if fees are already initialized - initialize only original fees
      // (displayed in "Standard" column)
      } else if (that.loan && (!_.isEmpty(that.loan.feeOverride) && _.values(that.loan.feeOverride).every(fee => !_.isNil(fee)))) {
        that.overrideChkbox = true;
        that.modificationMode = true;
        that.originalPrincipal = that.loan.principalAmount;
        that.originalCreationType = that.loan.creationType;

        // fees from "Override" column are already present
        // and "Standard" column must be initialized
        await that.calculateStandardFees();
        // update diffs
        that.recalculateDiffs();
      }
    };

    that.isEditModeEnabled = () => {
      return _.defaultTo(that.editMode, true);
    }

    that.getCustomFeeName = (fee) => {
      const lineNumberPredicate = fee.applyPredicates?.find(p => p.type === 'LoanAmortizationLineNumberFeePredicate');
      if (lineNumberPredicate) {
        return `${fee.name} (no. ${lineNumberPredicate.value})`;
      }
      return fee.name;
    }
  }
});
