import nxModule from 'nxModule';
import moment from "moment";
import {LoanService} from "components/service/loan.service";
import Authentication from "shared/utils/authentication";
import {ReportModal} from "components/report/modal/report-modal.service.types";
import {SystemDateService} from "components/service/system-date.service.types";
import {NxRouteService} from "routes/NxRouteService";

import templateUrl from './customer-loans-statement-total.template.html';

class CustomerLoansStatementTotal {
  loanId!: number;
  customerId!: number;
  date!: moment.Moment;
  checkedByName: string | null;
  approvedByName: string | null;
  displayReport: () => void;
  private minDate: Date = moment().toDate();
  private isInterbranch: boolean = false;

  constructor(
    private $route: NxRouteService,
    private reportModal: ReportModal,
    private systemDateService: SystemDateService,
    private loanService: LoanService,
    private authentication: Authentication
  ) {
    this.checkedByName = null;
    this.approvedByName = null;
    this.displayReport = () => {
      const params = {
        productId: this.loanId,
        date: this.date,
        checkedBy: this.checkedByName,
        approvedBy: this.approvedByName,
        customerId: this.customerId
      };
      const reportName = 'LoanStatementOfAccountTotalsReport';
      this.reportModal.display({
        params: params,
        reportCode: reportName
      });
    };
  }

  async $onInit() : Promise<void> {
    const loan = await this.loanService.getLoanForCustomer(this.customerId, this.loanId);
    this.date = await this.systemDateService.getSystemDateByBranchId(loan.branchId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.minDate = this.date;
    this.isInterbranch = loan.branchId !== this.authentication.context.branchId;
  }
}

nxModule.component('customerLoansStatementTotal', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    loanId: '<',
    date: '<'
  },
  controller: CustomerLoansStatementTotal
});
