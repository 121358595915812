import nxModule from 'nxModule';
import {centerMemberRoles} from 'constants/center';
import templateUrl from './center-officers.template.html';
import {CenterLeader, CenterLeaderRole, Customer} from "../../../service/center/center.types";

class CenterOfficersController {

  officers: CenterLeader[] = [];
  role: CenterLeaderRole | null = null;
  user: Customer | null = null;
  customers!: Customer[];
  onChange!: () => void;

  addCenterOfficer(): void {
    if (!this.officers) {
      this.officers = [];
    }
    if (!this.user) {
      return;
    }

    this.officers.push({
      role: this.role as CenterLeaderRole,
      customerId: this.user.id,
      effectiveName: this.user.effectiveName
    });

    this.onChange();

    this.user = null;
    this.role = null;
  }

  removeCenterOfficer(officer: {customerId: number}): void {
    const cIndex = this.officers.findIndex(o => o.customerId === officer.customerId);
    this.officers.splice(cIndex, 1);
    this.onChange();
  }

  canAdd(): boolean {
    return !!this.role && !!this.user;
  }

  getUnassignedRoles() {
    const assignedRoles = this.officers.map(o => o.role);
    return centerMemberRoles.filter(role => !assignedRoles.includes(role.value))
      .sort((r1, r2) => r1.label.localeCompare(r2.label));
  }

  getUnassignedCustomers() {
    const officerIds = this.officers.map(o => o.customerId);
    return this.customers.filter(c => !officerIds.includes(c.id))
      .sort((c1, c2) => c1.effectiveName.localeCompare(c2.effectiveName));
  }
}

nxModule.component('centerOfficers', {
  templateUrl: templateUrl,
  bindings: {
    'editMode': '<',
    'officers': '<',
    'customers': '<',
    'onChange': '<'
  },
  controller: CenterOfficersController
});
