import nxModule from 'nxModule';
import _ from 'lodash';
import {CustomerProfile} from '../customer-profile.types';
import templateUrl from './customer-profile-task-page.template.html';
import {Task} from "../../../dashboard/actions/task.types";
import {ILocationService} from "angular";
import {Breadcrumbs} from "angular-breadcrumbs";
import {CustomerService} from "components/service/customer.service";
import {HttpService} from 'shared/utils/httpService';
import {AvailableTasksCache} from 'components/service/available-tasks-cache.types';
import {DashboardActionService} from "components/dashboard/actions/action.service";
import {User} from "user/UserTypes";

class CustomerProfileTaskPage {
  // route params injected
  private taskId!: number;
  private displayMode!: 'OWNED' | 'NOT_OWNED';
  private customerId!: number;

  private profile!: CustomerProfile;
  private task!: Task;
  private overrideTask?: Task & { localOverrideLevel: number};

  private readonly onUpdatedAction: () => void;

  constructor(private $location: ILocationService,
              private http: HttpService,
              private breadcrumbs: Breadcrumbs,
              private customerService: CustomerService,
              private availableTasksCache: AvailableTasksCache,
              private dashboardActionService: DashboardActionService) {
    this.onUpdatedAction = () => {
      this.availableTasksCache.refetch();
      const { path: parentPath } = _.nth(this.breadcrumbs.get(), -2)!;
      this.$location.path(parentPath);
    };

    Object.assign(this, dashboardActionService.getApprovalLevelCalculator());
  }

  async $onInit() {
    let profile;
    [ profile, this.task ] = await Promise.all([
      this.http.get<CustomerProfile>(`/customers/${this.customerId}?allowInactive=true`).toPromise(),
      this.http.get<Task>(`/tasks/${this.taskId}`).toPromise()
    ]);
    this.profile = this.customerService.extractPhones(profile);
  }

  revoke(): void {
    this.dashboardActionService.cancel(this.task, null, this.onUpdatedAction);
  }

  pinEntered(task: Task, pin: string, user: User): void {
    this.dashboardActionService.pinEntered(task, pin, user, this.onUpdatedAction);
  }

  reject(): void {
    this.dashboardActionService.reject(this.task, null, this.onUpdatedAction);
  }

  approveAction = (task: Task, $event: JQueryEventObject, level: number): void => this.dashboardActionService.approve(task, $event, level, this.onUpdatedAction);

  localOverrideAction = (task: Task, $event: JQueryEventObject, level: number): void => {
    this.overrideTask = {
      ...task,
      localOverrideLevel: level,
    };
  };
}

nxModule.component('customerProfileTaskPage', {
  templateUrl,
  controller: CustomerProfileTaskPage,
  bindings: {
    taskId: '<',
    displayMode: '<',
    customerId: '<'
  }
});