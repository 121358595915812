import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import nxModule from 'nxModule';


const templateUrl = require('./loan-payment-report.template.html');
nxModule.component('loanPaymentReport', {
  templateUrl: templateUrl,
  controller: function ($route, reportModal) {
    let that = this;
    that.loanId = $route.current.params['loanId'];

    that.displayReport = () => {
      let params = {
        productId: that.loanId,
        checkedBy: that.checkedByName,
        approvedBy: that.approvedByName
      };
      let reportName = 'LoanPaymentsPerLoanReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };
  }
});
