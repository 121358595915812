import nxModule from 'nxModule';
import templateUrl from './email-notification-config.template.html';


export type EmailMessageType = 'BEFORE_EOD' | 'START_OF_EOD' | 'SUCCESSFUL_EOD' | 'FAILED_EOD' | 'FAILED_BRANCH_EOD' | 'LONG_RUNNING_EOD' | 'SUCCESSFUL_ORACLE_HANDOFF_GENERATION';

export interface EmailNotificationConfig {
  id: number;
  enabled: boolean;
  messageBody: string;
  messageType: EmailMessageType;
  recipients: string[];
  bcc:  string[];
}

interface SelectOption {
  text: string;
  value: string;
}

class EmailNotificationConfigComponent {
  protected config!: EmailNotificationConfig;
  protected recipientsConfig = {
    placeholder: 'Add recipients',
    create: true
  };
  protected bccConfig = {
    placeholder: 'Add bcc',
    create: true
  };

  protected recipientsOptions!: SelectOption[];
  protected bccOptions!: SelectOption[];

  $onInit(): void {
    if(this.config?.recipients?.length > 0) {
      this.recipientsOptions = this.config.recipients.map(r => ({text: r, value: r}));
    }

    if(this.config?.bcc?.length > 0) {
      this.bccOptions = this.config.bcc.map(r => ({text: r, value: r}));
    }
  }
}

nxModule.component('emailNotificationConfig', {
  templateUrl,
  controller: EmailNotificationConfigComponent,
  bindings: {
    config: '<'
  }
});