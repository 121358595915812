import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './center-details.template.html';
import {UserDetails} from "../../../service/users/user.cache";
import {ILocationService, IScope} from "angular";
import {Center} from "../../../service/center/center.types";
import {CenterService} from "../../../service/center/center.service";
import LocalCache from "../../../../shared/utils/localCache";
import {Branch} from "management/BranchTypes";
import {NxRouteService} from "routes/NxRouteService";
import {Breadcrumbs} from "angular-breadcrumbs";
import {UserService} from "components/service/users/user.service";
import {CommandService} from "shared/utils/command/command.types";
import Authentication from "shared/utils/authentication";
import {Confirmation} from "shared/common/confirmation.types";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {CustomerDepositService} from "components/service/customer-deposit.service.types";

class CenterDetails {
  private readonly center?: Center;
  private branches: Record<number, Branch> = {};
  private loanOfficers: UserDetails[] = [];
  private loanCollectors: UserDetails[] = [];
  private selectAccountConfig: unknown;
  private cbuAccountTypeIds: number[] = [];
  private centerChiefCbuAccounts: unknown[] = [];
  private editMode = false;
  private originalBranchId?: number;

  constructor(private $scope: IScope,
              private readonly $route: NxRouteService,
              private readonly $location: ILocationService,
              private readonly branchService: LocalCache<Branch[], Branch[]>,
              private readonly breadcrumbs: Breadcrumbs,
              private readonly centerService: CenterService,
              private readonly userService: UserService,
              private readonly command: CommandService,
              private readonly authentication: Authentication,
              private readonly confirmation: Confirmation,
              private readonly depositAccountTypeService: DepositAccountTypeService,
              private readonly customerDepositService: CustomerDepositService) {

    this.selectAccountConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'label',
      maxItems: 1,
      allowEmptyOption: true
    };
  }

  async $onInit(): Promise<void> {
    const [branchesArr, accountTypes] = await Promise.all([
      this.branchService.toPromise(),
      this.depositAccountTypeService.toPromise(),
    ]);

    this.centerService.loadContext(this.center);
    this.originalBranchId = this.center?.branchId;

    const userBranches = branchesArr.filter(b => this.authentication.context.branchIds.includes(b.id));
    this.branches = _.keyBy(userBranches, branch => branch.id);

    this.cbuAccountTypeIds = accountTypes.filter(accountType => accountType.purpose === 'CONTRACTUAL_SAVINGS_CBU')
      .map(accountType => accountType.id!);

    await this.setLoanOfficersAndCollectors();
    this.retrieveCenterOfficersAccounts();
  }

  retrieveCenterOfficersAccounts = () => {
    if (!this.center?.centerLeaders) {
      return;
    }
    const centerChief = this.center.centerLeaders.find(o => o.role === 'CENTER_CHIEF');
    if (centerChief) {
      this.customerDepositService.searchDeposits(
        [centerChief.customerId],
        ['ACTIVE', 'INACTIVE', 'CLOSED'],
        false,
        accounts => {
          this.centerChiefCbuAccounts = accounts.filter(a => this.cbuAccountTypeIds.includes(a.typeId)).map(a => ({
            ...a,
            label: a.label + ` (${a.status})`
          }));
        }
      );
    } else {
      this.centerChiefCbuAccounts = [];
      this.center.centerFundAccountId = null;
    }
  }

  async onBranchChange() : Promise<void> {
    if (!this.center) {
      return;
    }

    if (!this.center?.loanOfficerId && !this.center?.loanCollectorId) {
      this.originalBranchId = this.center.branchId;
      await this.setLoanOfficersAndCollectors();
      return;
    }

    const message = `Changing the branch of this center will remove the currently assigned Loan Officer and Loan Collector. Click the 'Yes' button to proceed.`;
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      this.center.loanOfficerId = null;
      this.center.loanCollectorId = null;
      this.originalBranchId = this.center.branchId;

      await this.setLoanOfficersAndCollectors();
    } else {
      // no need to reset to original branch id if null.
      if (!this.originalBranchId) {
        return;
      }
      this.center.branchId = this.originalBranchId;
    }
  }

  async setLoanOfficersAndCollectors() : Promise<void> {
    if (!this.center) {
      return;
    }

    const [loanOfficers, loanCollectors] = await Promise.all([
      this.userService.getBranchPermittedUsers('LOAN_OFFICER', [this.center?.branchId]),
      this.userService.getBranchPermittedUsers('COLLECTOR', [this.center?.branchId])
    ]);

    this.loanOfficers = loanOfficers ?? [];
    this.loanCollectors = loanCollectors ?? [];

    const emptyUser: any = {
      id: this.center.loanCollectorId === null ? null : undefined,
      fullName: ''
    };
    this.loanCollectors.unshift(emptyUser); // serves as a way to unselect loan collector
  }

  async cancel(): Promise<void> {
    if (this.hasCenter()) {
      await this.disableEditMode();
    } else {
      this.$location.path('/dashboard/lookup-center');
    }
  }

  enableEditMode(): void {
    this.editMode = true;
  }

  async disableEditMode(): Promise<void> {
    this.editMode = false;
    this.$route.reload();
  }

  getHeaderTitle(): string {
    if (this.editMode) {
      return 'Update center details';
    } else if (!this.hasCenter()) {
      return 'Create new center';
    }
    return 'Center details'
  }

  isFormInputEnabled(): boolean {
    return this.editMode || !this.hasCenter();
  }

  hasCenter(): boolean {
    return !!this.center?.id;
  }

  async save(): Promise<void> {
    if (this.hasCenter()) {
      const response = await this.command.execute('UpdateCenter', this.center, {nxLoaderText: 'Updating center...'}).toPromise();
      if (!response.approvalRequired) {
        this.$route.reload();
      }
    } else {
      const response = await this.command.execute('CreateCenter', this.center, {nxLoaderText: 'Creating center...'}).toPromise();
      if (!response.approvalRequired) {
        this.$location.path(`/center/${response.output}/details`);
      }
    }
  }
}

nxModule.component('centerDetails', {
  templateUrl,
  controller: CenterDetails,
  bindings: {
    center: '<'
  }
});
