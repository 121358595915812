import nxModule from 'nxModule';
import moment from "moment";
import {Holiday} from "../../../../../react/management/WorkingDayType";
import templateUrl from './delete-running-holiday.template.html';
import {Branch} from "management/BranchTypes";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import RefreshService from "shared/utils/refresh.service";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {BranchService} from "components/service/branch.service";
import {BranchHolidayCache} from "components/service/branch-holiday.cache.types";

class DeleteRunningHoliday {

  branches: Branch[] = [];
  branch?: Branch;
  holidays: Holiday[] = [];
  holiday?: Holiday;
  noRunningHolidays: boolean = false;

  constructor(private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly branchService: BranchService,
              private readonly branchHolidayCache: BranchHolidayCache,
              private readonly $route: NxRouteService,
              private readonly command: CommandService,
              private readonly refreshService: RefreshService) {
  }

  async $onInit(): Promise<void> {
    const [allBranches, holidays] = await Promise.all([
      this.branchService.toPromise(),
      this.branchHolidayCache.withParam({
        types: ['HOLIDAY'],
        mode: 'BRANCH',
        runningHoliday: true
      }).toPromise()]);

    this.noRunningHolidays = holidays.length === 0;
    this.branches = allBranches.filter((b: Branch) => holidays.some((h: Holiday) => h.branchId == b.id));
    this.holidays = holidays;
  }

  async onBranchChange(): Promise<void> {
    this.holiday = this.holidays.find(h => h.branchId === this.branch?.id);
  }

  async execute(): Promise<void> {
    if(!this.holiday || !this.branch) {
      return;
    }

    const proceed = await this.confirmationTemplate({
      question: `The running holiday will be deleted. If there is no bank holiday, the posting date of the branch will reset to ${this.holiday?.date}.`,
      details: [
        {label: 'Branch', description: this.branch.name},
        {label: 'Holiday name', description: this.holiday.name},
        {label: 'Holiday date', description: moment(this.holiday.date).format('MM/DD/YYYY')},
      ]
    });

    if (!proceed) return;

    await this.command.execute('DeleteRunningHoliday', {
      id: this.holiday.id
    }).toPromise();

    this.refreshService.refreshPage();
  }
}

nxModule.component('deleteRunningHoliday', {
  templateUrl,
  controller: DeleteRunningHoliday
});
