import moment from 'moment';
import $ from 'jquery';

import nxModule from 'nxModule';

import templateUrl from './date-range-picker.template.html';

class DateRangePicker {
  constructor($element, $scope) {
    this.$element = $element;
    this.$scope = $scope;
    this.currentDateReference = null;
    this.today = null;
  }

  $postLink() {
    this.renderComponent();

    this.$scope.$watch('$ctrl.model', () => {
      this.renderComponent();
    }, true);
  }

  renderComponent() {
    if (this.isModelComplete()) {
      this.initializeCurrentDateReference();
      this.createDateRange();
      this.updateDates();
      this.today = this.model.to;
    }
  }

  isModelComplete() {
    return this.model && this.model.from && this.model.to;
  }

  initializeCurrentDateReference() {
    this.currentDateReference = this.model.to;
  }

  createDateRange() {
    const startDate = moment(this.model.from);
    const endDate = moment(this.model.to);
    const currentDate = moment(this.currentDateReference);
    const today = moment(this.today);

    const dateRange = this.getDateRangeInput();

    dateRange.daterangepicker({
      startDate: startDate,
      endDate: endDate,
      // dateLimit: {days: 31},
      opens: 'right',
      parentEl: '#modal-container',
      ranges: {
        'Today': [today, today],
        '2 days': [currentDate.clone().subtract(1, 'days'), currentDate],
        '1 week': [currentDate.clone().subtract(7, 'days'), currentDate],
        '1 month': [currentDate.clone().subtract(1, 'month'), currentDate]
      },
    }, (start, end) => {
      this.notAppliedModel = this.createModelWithFormat(start, end, 'YYYY-MM-DD');
    });

    dateRange.on('apply.daterangepicker', () => {
      this.$scope.$evalAsync(() => {
        this.model = this.notAppliedModel || this.model;
      });
    });

    // disable manual editing of input
    dateRange.keydown(() => {
      return false;
    });

    return dateRange;
  }

    // TODO: migrate this control to use ng-model or replace with other lib
    // allows for emptying daterange
    // saving for later
    // dateRange.on('cancel.daterangepicker', function(ev, picker) {
    //   dateRange.val('');
    // });

  updateDates() {
    const {from: startDate, to: endDate} = this.model;
    this.getDateRangeInput()
        .val(this.toDateString(startDate, 'MM/DD/YYYY')
            + ' - '
            + this.toDateString(endDate, 'MM/DD/YYYY'));
  }

  getDateRangeInput() {
    return $(this.$element.find('input.daterange'));
  }

  createModelWithFormat(start, end, formatStyle) {
    return {
      from: this.toDateString(start, formatStyle),
      to: this.toDateString(end, formatStyle)
    };
  }

  toDateString(date, formatStyle) {
    return date ? moment(date).format(formatStyle) : null;
  }
}

nxModule.component('dateRangePicker', {
  templateUrl,
  bindings: {
    model: '=',
  },
  controller: DateRangePicker
});
