import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import 'rxjs/add/operator/combineLatest';

import templateUrl from './memo-termination.template.html';
import {DepositInterestForecast, Override, TermDepositOverrideService} from '../common/term-deposit-override.service';
import {IFormController, ILocationService} from 'angular';
import {Deposit, ProductDefinition} from '../../../service/product.types';
import {Unit} from '../../../general-ledger/common/gl-category-input.component';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {sum} from 'shared/common/MathUtils';
import {HttpService} from "shared/utils/httpService";
import {NxIFilterService} from "components/technical/angular-filters";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {TermDepositCalculator} from "components/customer/term-deposits/common/term-deposit-calculator.service";
import {Confirmation} from "shared/common/confirmation.types";
import FeeDefinitionCache from "components/service/fee-definition.cache";
import {DepositType, TermDepositService} from "components/administration/term-deposit/common/term-deposit.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from 'shared/utils/command/command.types';

class MemoTerminationComponent {

  public termDeposit?: Deposit & {productDefinition: ProductDefinition};
  public overrideCheckbox: boolean = false;
  public category!: ActionCategory;
  public categoryUnits: Unit[] = [];
  public terminationForm!: IFormController;
  public depositMemoForm!: IFormController;
  public remarks: string | null = null;
  private customerId!: number;
  private depositId!: number;
  private terminationAmount!: number;
  private interestForecast: DepositInterestForecast = {} as DepositInterestForecast;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private override: Override = {};
  private depositType?: DepositType;

  constructor(private $location: ILocationService,
              private $filter: NxIFilterService,
              private command: CommandService,
              private http: HttpService,
              private customerCache: CustomerCache,
              private productDefinitionService: ProductDefinitionService,
              private confirmation: Confirmation,
              private feeDefinitionsCache: FeeDefinitionCache,
              private termDepositsService: TermDepositService,
              private termDepositCalculator: TermDepositCalculator,
              private termDepositOverrideService: TermDepositOverrideService) {
  }

  async $onInit(): Promise<void> {
    const [deposits, products, forecast, depositTypes] = await Promise.all([
      this.customerCache.termDeposits(this.customerId).toPromise(),
      this.productDefinitionService.toPromise(),
      this.readInterestForecast(this.depositId),
      this.termDepositsService.toPromise()
    ]);

    const deposit = _.find(deposits, {id: Number(this.depositId)})!;
    deposit.productDefinition = _.find(products, {id: deposit.definitionId})!;
    this.termDeposit = deposit;

    this.interestForecast = forecast;
    this.override.grossInterest = this.interestForecast.interest;
    this.override.withholdingTax = this.interestForecast.withholdingTax;
    this.override.netInterest = this.interestForecast.netInterest;
    this.interestForecast.productId = this.depositId;
    this.depositType = _.find(depositTypes, {id: this.termDeposit.typeId});

    this.terminationAmount = this.calculateTerminationAmount();
    this.override.amount = this.terminationAmount;
  }

  async readInterestForecast(depositId: number): Promise<DepositInterestForecast> {
    const input = {mode: 'TERMINATION'};

    const [forecast, feesDef] = await Promise.all([
      this.http.post<DepositInterestForecast>(`/products/deposits/${depositId}/interest`, input).toPromise(),
      this.feeDefinitionsCache.toPromise()]);

    forecast.terminationFees.forEach(f => {
      const fd = feesDef.find(fd => fd.id === f.feeDefinitionId)!;
      if (fd) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        f.name = fd.feeName;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        f.bankExpense = fd.bankExpense;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        f.newAmount = f.amount;
      }
    });

    return forecast;
  }

  calculateTerminationAmount(): number {
    const terminationFees = sum(
      this.interestForecast.terminationFees
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter(f => !f.bankExpense)
        .map(f => f.newAmount)
    );

    let terminationAmount = new BigNumber(0)

      .plus(this.termDeposit?.balance ?? 0)
      .plus(this.override?.netInterest ?? 0)
      .minus(terminationFees);

    if(this.depositType?.deductCreditedInterestOnPretermination) {
      terminationAmount = terminationAmount.minus(this.interestForecast.interestCredited)
        .plus(this.interestForecast.appliedWithholdingTax);
    }
    return terminationAmount.dp(2).toNumber();
  }

  recalculate(): void {
    this.override.grossInterest = this.termDepositCalculator.recalculateGrossInterest(this.override.netInterest ?? 0, this.override.withholdingTax ?? 0);
    this.override.amount = this.calculateTerminationAmount();
  }

  terminationFeeChanged(): void {
    this.override.amount = this.calculateTerminationAmount();
  }

  async terminate(): Promise<void> {
    const confirmed = await this.confirmation(`Do you want to withdraw ${this.$filter('nxCurrency')(this.override.amount ?? 0)} and terminate deposit?`);
    if (confirmed) {
      const request = this.termDepositOverrideService.prepareRequest({
        forecast: this.interestForecast,
        override: this.override,
        terminationAmount: this.terminationAmount,
        isOverridden: this.overrideCheckbox
      });
      const input = {
        ...request,
        debitMemoInput: {
          productId: this.depositId,
          amount: this.override.amount,
          entryType: 'DEBIT',
          commandPurpose: 'PRETERMINATION',
          categoryUnits: {
            categoryId: this.category.id,
            units: this.categoryUnits
          },
          remarks: this.remarks
        }
      };

      const response = await this.command.execute('MemoDepositPretermination', input, {nxLoaderText: 'Withdrawing funds...'}).toPromise();
      if (!response.approvalRequired) {
        this.customerCache.termDeposits(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.depositId}`)
  }

  totalAmountEqualsWithdrawAmount(): boolean {
    return this.override.amount === Number(this.getCategoryUnitsTotal());
  }

  validateTotalAmount(): boolean {
    return this.getCategoryUnitsTotal() >= 0;
  }

  getCategoryUnitsTotal(): number {
    return sum(this.categoryUnits.map(u => u.amount)).toFixed(2);
  }
}

nxModule.component('customerTermDepositMemoTermination', {
  templateUrl: templateUrl,
  controller: MemoTerminationComponent,
  bindings: {
    customerId: '<',
    depositId: '<'
  }
});
