import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';

export default class AccountForecast {
  constructor(http, feeDefinitionsCache) {
    this.http = http;
    this.feeDefinitionsCache = feeDefinitionsCache;
  }

  async getTerminationForecast(accountId, forceInterestReversal) {
    const request = this.http.post(`/products/accounts/${accountId}/interest`, {
        closing: true,
        forceInterestReversal: forceInterestReversal
      },
      {nxLoaderText: 'Calculating interest'});

    const [forecast, feesDef] = await Promise.all([
      request.toPromise(),
      this.feeDefinitionsCache.toPromise()
    ]);

    const fees = forecast.terminationFees;

    fees.forEach(f => {
      let fd = feesDef.find(fd => fd.id === f.feeDefinitionId);
      if (fd) f.name = fd.feeName;
    });

    return forecast;
  }

  calculateTerminationAmount(account, forecast) {
    let terminationAmount = new BigNumber(0)
      .plus(account.balance)
      .plus(forecast.netInterest);
    //subtract termination fee
    forecast.terminationFees.forEach(f => {
      terminationAmount = terminationAmount.minus(f.amount);
    });

    return terminationAmount.dp(2).toNumber();
  };
}

nxModule.service('accountForecast', AccountForecast);
