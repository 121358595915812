import {FormControlLabel} from '@material-ui/core';
import {
    NxButton,
    NxButtonVariant,
    NxCashInput,
    NxFormik,
    NxFormikSubmitButton,
    NxFormikCashInput,
    NxFormikCheckbox,
    NxInput,
    NxLoader,
    NxPopup,
    NxRow,
    NxRowPosition,
    NxStack,
} from "@nextbank/ui-components";
import BigNumber from 'bignumber.js';
import {LedgerTag} from 'components/administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.service';
import {LedgerMapping} from 'components/general-ledger/common/gl.types';
import {IdResponse} from 'components/service/product.types';
import React, {ReactElement, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import NxForm from "form/NxForm";
import {useCommand} from "command/CommandService";
import {CommandOutputWrapper} from "command/CommandTypes";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import {Amortization, Loan} from "components/service/loan.types";
import * as Yup from 'yup';
import NxCancelButton from 'NxCancelButton';

interface WriteOffRequest {
  productId: number;
  loanBalance: number;
  waiveCs: boolean;
  waiveCustomFee: boolean;
}

interface WriteOffForm {
  loanBalance: number;
  waiveCs: boolean;
  waiveCustomFee: boolean;
}

interface AmortizationBalances {
  customFeesBalance: BigNumber;
  totalPenaltyBalance: BigNumber;
  totalPastDueInterestBalance: BigNumber;
  totalCsBalance: BigNumber;
}

const RequiredNumber = Yup.number()
  .required()
  .min(0);

const RequiredBoolean = Yup.boolean().default(false);

const WriteOffFormSchema: Yup.SchemaOf<WriteOffForm> = Yup.object().shape({
  loanBalance: RequiredNumber.oneOf([0,1], 'Loan balance can only be 1 or 0'),
  waiveCs: RequiredBoolean,
  waiveCustomFee: RequiredBoolean
});

const WriteOffLoan = (): ReactElement => {
  const {customerId, loanId} = useParams<{customerId: string, loanId: string}>();
  const history = useHistory();
  const execute = useCommand();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [destinationAccount, setDestinationAccount] = useState<string | undefined>();
  const [{data: loan}] = useAxios<Loan>(`/products/loans/${loanId}`);
  const [{data: ledgerMapping}, fetchLedgerMapping] = useAxios<LedgerMapping[]>({}, {
    manual: true
  });
  const [{data: ledgerTag, loading}, fetchLedgerTag] = useAxios<LedgerTag>({}, { manual: true });

  useEffect(() => {
    if(!loan) {
      return;
    }
    fetchLedgerMapping({
      url: `/ledger/transaction-mapping?productDefinitionId=${loan?.definitionId}`
    });
  }, [loan, fetchLedgerMapping]);

  useEffect(() => {
    if(!ledgerMapping || !loan) {
      return;
    }
    const writeOffMapping = ledgerMapping.find(mapping => mapping.operationGroup === 'WRITE_OFF');
    if(!writeOffMapping) {
      throw new Error(`Could not find WRITE_OFF mapping from retrieved ledger mappings`);
    }

    if(writeOffMapping.debitUnit.direct) {
      setDestinationAccount(writeOffMapping.debitUnit.accountCode);
    } else {
      fetchLedgerTag({
        method: 'POST',
        url: '/ledger/tag/resolve',
        data: {
          productId: loan.id,
          tagType: writeOffMapping.debitUnit.tagType
        }
      });
    }
  }, [ledgerMapping, loan, fetchLedgerTag]);

  useEffect(() => {
    if(!ledgerTag) {
      return;
    }
    setDestinationAccount(ledgerTag.accountCode);
  }, [ledgerTag]);

  if (!loan || !destinationAccount || loading) {
    return <NxPage><NxHeader>Write off</NxHeader><NxLoader/></NxPage>;
  }

  const amortizationSummary = loan.amortizationSchedule.list
    .map((a: Amortization) : AmortizationBalances => {
      return {
        customFeesBalance: new BigNumber(a.customFeesBalance),
        totalCsBalance: new BigNumber(a.cbuChargeBalance).plus(new BigNumber(a.tpChargeBalance))
          .plus(new BigNumber(a.pfChargeBalance)),
        totalPenaltyBalance: new BigNumber(a.totalPenaltyBalance),
        totalPastDueInterestBalance: new BigNumber(a.totalPastDueInterestBalance)
      }
    })
    .reduce((sum: AmortizationBalances, currentValue: AmortizationBalances) => {
      return {
        customFeesBalance: sum.customFeesBalance.plus(currentValue.customFeesBalance),
        totalCsBalance: sum.totalCsBalance.plus(currentValue.totalCsBalance),
        totalPenaltyBalance: sum.totalPenaltyBalance.plus(currentValue.totalPenaltyBalance),
        totalPastDueInterestBalance: sum.totalPastDueInterestBalance.plus(currentValue.totalPastDueInterestBalance)
      };
    });

  return <NxPage>
    <NxHeader>Write off</NxHeader>
    <NxFormik<WriteOffForm>
      initialValues={{
        loanBalance: 0,
        waiveCs: false,
        waiveCustomFee: false
      }}
      validationSchema={WriteOffFormSchema}
      onSubmit={async (input: WriteOffForm): Promise<void> => {
        const response: CommandOutputWrapper<IdResponse> = await execute<WriteOffRequest, IdResponse>({
          name: 'WriteOffLoan',
          input: {
            productId: loan.id,
            loanBalance: input.loanBalance,
            waiveCs: input.waiveCs,
            waiveCustomFee: input.waiveCustomFee
          }
        });

        if (!response.approvalRequired) {
          history.push(`/customer/${customerId}/loans/${loanId}`);
        }
      }
      }>
      {({
         isSubmitting,
         isValid,
         submitForm,
         values
       }): ReactElement => {
        return (
          <NxForm>
            <NxStack>
              <NxInput label='Product number' disabled value={loan.productNumber}/>
              <NxInput label='Status' disabled value={loan.status}/>
              <NxInput label='Target status' disabled value='PAST DUE WRITE OFF'/>
              <NxCashInput label='Principal balance' disabled value={loan.principalBalance}/>
              <NxCashInput label='Interest balance' disabled value={loan.interestBalance}/>
              <NxCashInput label='AIR balance' disabled value={loan.accruedInterest}/>
              <NxCashInput label='CBU/PF/TP fee balance' disabled
                           value={amortizationSummary.totalCsBalance.toNumber()}/>
              <NxCashInput label='Custom fee balance' disabled value={amortizationSummary.customFeesBalance.toNumber()}/>
              <NxCashInput label='Past due interest balance' disabled
                           value={amortizationSummary.totalPastDueInterestBalance.toNumber()}/>
              <NxCashInput label='Penalty balance' disabled value={amortizationSummary.totalPenaltyBalance.toNumber()}/>
              <NxCashInput label='Outstanding allowance' disabled value={loan.lossAllowance}/>
              <NxCashInput label='Write off amount' disabled value={loan.principalBalance - values.loanBalance}/>
              <NxFormikCashInput label="Expected loan book value"
                                 name="loanBalance" decimals={0}/>
              <div>
                <FormControlLabel label="Waive CBU/PF/TP"
                                  control={<NxFormikCheckbox name="waiveCs"/>}/>
              </div>
              <div>
                <FormControlLabel label="Waive custom fee"
                                  control={<NxFormikCheckbox name="waiveCustomFee"/>}/>
              </div>
              <NxInput label='Allowance GL' disabled={true} value={destinationAccount}/>
            </NxStack>
            <NxRow position={NxRowPosition.END}>
              <NxCancelButton />
              <NxButton
                variant={NxButtonVariant.SAVE}
                onClick={(): void => setShowPopup(true)}
                disabled={!isValid || isSubmitting}
                loaded={!isSubmitting}
              >
                Write off
              </NxButton>
            </NxRow>
            <NxPopup header='Confirm'
                     open={showPopup}
                     description={`Are you sure that you want to write off loan ${loan.productNumber}?`}>
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={(): void => setShowPopup(false)}>
                  No
                </NxButton>
                <NxFormikSubmitButton
                  variant={NxButtonVariant.SAVE}
                  onClick={(): void => {
                    setShowPopup(false);
                    submitForm();
                  }}>
                  Yes
                </NxFormikSubmitButton>
              </NxRow>
            </NxPopup>
          </NxForm>
        )}}
    </NxFormik>
  </NxPage>;
};

export default WriteOffLoan;