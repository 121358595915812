import querystring from "querystring";

export type RedirectInput = {
  error?: string,
  target?: string
};

/**
 * Use this class only in services that integrate with legacy code.
 */
export default class LocationUtil {

  public getCurrentRoutePath(): string | null {
    const hash = window.location.hash;
    let strippedLocation: string | null = null;
    if(hash) {
      strippedLocation = hash.substring('#!'.length);
    }

    return strippedLocation;
  }

  public redirectToLoginPage({error, target}: RedirectInput): void {
    const params: Record<string, string> = {};
    if(target) {
      params['target'] = `#!${target}`;
    }

    if(error) {
      params['error'] = error;
    }

    const suffix = querystring.stringify(params, '&', '=', {
      encodeURIComponent: encodeURIComponent
    });

    const queryPart = suffix ? `?${suffix}` : '';
    window.location.replace('/login' + queryPart);
  }
}