import nxModule from 'nxModule';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';

nxModule.factory('checkbookCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/checks/checkbooks`,
      {params: {
        productId: productId,
        checkbookOwner: 'CUSTOMER',
        pageNo: 0,
        pageSize: 20
      }}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.checkbook,
    cacheName: 'checkbookCache'
  })
);
