import nxModule from 'nxModule';

import ParameterizedLocalCache from "../../../shared/utils/parameterized-local-cache";
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from "config";

type TransactionQuotaCache = ParameterizedLocalCache<unknown, unknown>;
export default TransactionQuotaCache;

nxModule.factory('transactionQuotaCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new ParameterizedLocalCache({
    provider: (key: {owner: string, ownerId: number}) => http.get(`/management/transaction-quotas?owner=${key.owner}&ownerId=${key.ownerId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.agents,
    cacheName: 'transactionQuota'
  })
);
