import moment from 'moment';

import nxModule from 'nxModule';

const templateUrl = require('./refresh-button.template.html');

export const updateRefreshTime = $scope => $scope.$broadcast('refreshedData');

// after refreshing, emit 'refreshedData' event
nxModule.component('refreshButton', {
  templateUrl,
  bindings: {
    onRefresh: '&',
  },
  controller: function($scope, $routeParams, $interval) {
    const self = this;
    const updateRefreshTime = () => {
      self.refreshedAgo = self.lastRefreshTime ? self.lastRefreshTime.fromNow()
        .replace('seconds', 'sec.')
        .replace('minutes', 'min.') : 'never';
    };

    const refreshCancellation = $interval(updateRefreshTime, 1000);
    updateRefreshTime();
    self.onRefresh();

    const unsubscribe = $scope.$on('refreshedData', () => {
      self.lastRefreshTime = moment();
      updateRefreshTime();
    });

    self.$onDestroy = () => {
      $interval.cancel(refreshCancellation);
      unsubscribe();
    };
  }
});