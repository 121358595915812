import nxModule from 'nxModule';
import {combineLatest, first, map, shareReplay} from "rxjs/operators";
import accessPredicates from "../access-predicates";
import * as _ from "lodash";

nxModule.factory('commandAccessChecker', (commandRoleMapCache, accessRuleCache, commandMetadataCache, authentication, userCache) => {
  let service = {};
  const commandRoleObservable = commandRoleMapCache.toObservable()
    .pipe(
      combineLatest([
        accessRuleCache.withParam(authentication.context.roleIds).toObservable(),
        commandMetadataCache.toObservable(),
        userCache.withParam(false).toObservable()
      ], (roleMap, accessRules, metadata, users) =>
        ({
        roleMap,
        accessRules,
        accessRuleMap: _.groupBy(accessRules, rule => rule.command),
        commandMetadata: Object.fromEntries(metadata.map(meta => [meta.alias, meta])),
        users: users
      })),
      shareReplay(1)
    );


  /**
   * Checks if roles has access to either execute or approve a certain command
   *
   * @param roleMap - JSON Object containing the commands and allowed users to approve and execute
   * @param accessType - access type of command to check : 'execute' or 'approve'
   * @param command - the command to check if a role has an access to
   * @param roleIds - array of roles id
   * @param predicateContext - initialized by the component controller as commandAccessContext
   * @param commandMetadata - object mapping command name to command metadata
   * @param users - list of users in the system
   *
   * @return boolean
   */

  const hasCommandAccess = ({roleMap, accessType, command, accessRuleMap, predicateContext, commandMetadata, users}) => {
    const roleIds = authentication.context.roleIds;

    let metadata = commandMetadata[command];
    if (metadata?.restricted) {
      return authentication.permissions['NEXTBANK_ONLY'];
    }

    if(!roleMap[command]) {
      return false;
    }
    const allowedRoles = roleMap[command][accessType];
    const accessRules = accessRuleMap[command];
    const matchingRoles = _.intersection(allowedRoles, roleIds);

    if(!predicateContext) {
      return matchingRoles.length > 0;
    }

    for(let matchingRole of matchingRoles) {
      const roleAccessRules = accessRules.filter(accessRule => accessRule.roleId === matchingRole);
      for(const roleAccessRule of roleAccessRules) {
        const predicates = roleAccessRule.predicates;
        const accessGranted = Object.entries(predicates).every(([predicateName, predicateValue]) => {
          if(!accessPredicates[predicateName]) {
            console.error('Predicate', predicateName, 'is not supported');
            return false;
          }

          return accessPredicates[predicateName](predicateValue, predicateContext, authentication);
        });

        // if access is granted, we can return, otherwise we need to keep searching next rules
        if(accessGranted) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Checks if user can do (execute|approve) on given command.
   */
  const canDoCommandObservable = doType => {
    return commandRoleObservable
      .pipe(
        map(({roleMap, accessRules, accessRuleMap, commandMetadata, users}) => {
          return (command, predicateContext) => {
            return hasCommandAccess({roleMap, accessType: doType, command, accessRuleMap, predicateContext, commandMetadata, users});
          };
        })
      );
  };

  /**
   * Function to check if a certain command can be executed by the current user that is logged on.
   * */
  service.canExecuteCommandObservable = () => canDoCommandObservable('execute');

  service.canExecuteCommandAsync = () => {
    return service.canExecuteCommandObservable()
      .pipe(first())
      .toPromise();
  };

  /**
   * Function to check if a certain command can be approved by the current user that is logged on.
   * */
  service.canApproveCommandObservable = () => canDoCommandObservable('approve');

  service.canApproveCommandAsync = () => {
    return service.canApproveCommandObservable()
      .pipe(first())
      .toPromise();
  };

  return service;

});
