import nxModule from 'nxModule';
import angular from 'angular';

import templateUrl from './prints-details-variable-configuration.template.html'
import {reorderLines} from "./print.utils";

import './prints-details-variable-configuration.style.less';
import {Print, PrintLine} from "print/PrintType";

class PrintsDetailsVariableConfiguration {
  private print!: Print;
  private onChange!: () => void;

  getVariables(): PrintLine[] {
    // add custom option so that we configure also custom fields in the upper part of the page
    return this.print.lines.filter(line => ['VARIABLE', 'MULTI_VARIABLE', 'CUSTOM'].includes(line.type));
  }

  addVariable(line: PrintLine): boolean {
    const newLine = angular.copy(line);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newLine.id = null;
    newLine.x = 0;
    newLine.y = 0;
    this.print.lines.push(newLine);

    reorderLines(this.print);
    return true;
  }

  removeLine(line: PrintLine): boolean {
    this.print.lines.splice(this.print.lines.indexOf(line), 1);

    reorderLines(this.print);
    return true;
  }

  isPrimary(line: PrintLine): boolean {
    if(!line.id) {
      return false;
    }

    const minId = this.getVariables()
      .filter(l => l.field === line.field && l.id)
      .map(l => l.id)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .reduce((a, b) => Math.min(a, b));

    return minId === line.id;
  }
}

nxModule.component('printsDetailsVariableConfiguration', {
  templateUrl,
  bindings: {
    print: '=',
    onChange: '<'
  },
  controller: PrintsDetailsVariableConfiguration,
});