import nxModule from 'nxModule';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';

const ignoreNotFoundErrors = err => {
  if (err && err.errorCode === 'NOT_FOUND_BY_PK') {
    // happens when item wasn't created or id is invalid
    return;
  }

  return Promise.reject(err);
};

nxModule.factory('certificateCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (productId) => http.get(`/products/${productId}/certificate`).error(ignoreNotFoundErrors),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.certificate,
    cacheName: 'certificateCache'
  })
);