import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('propertyConfigCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get(`/management/properties-config`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.propertyConfig,
    cacheName: `propertyConfig`
  })
);
