import nxModule from "nxModule";
import templateUrl from "./collections-payment-individual.template.html";
import {NgTableParams} from "ng-table";
import {CollectionsMember} from "components/dashboard/collections/payment/collections-payment.component";
import './collections-payment-individual.styles.less'

class CollectionsPaymentIndividual {
  readonly viewColumns = [
    'No.',
    'Product number',
    'Customer name',
    'Customer address',
    'Product name',
    'Principal amount',
    'Principal balance',
    'Earliest due date',
    'Latest due date',
    'CBU charge balance',
    'Due amount',
    'Overdue amount',
    'Outstanding amount',
    'Status'
  ];

  getRowNo(index: number, tableConfig: NgTableParams<CollectionsMember>): number {
    const currentPage = tableConfig.page() - 1;
    const rowsPerPage = tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }
}

nxModule.component('collectionsPaymentIndividual', {
  templateUrl,
  bindings: {
    'tableConfig': '=',
    'collectionsReceiptType': '=',
    'collectionModuleReceiptRequired': '=',
    'toggleForPayment': '&',
    'getPayment': '&',
    'getPaymentsTotalAmount': '&'
  },
  controller: CollectionsPaymentIndividual
});
