import nxModule from 'nxModule';
import {ActionType, Columns, Sorting} from "../../../common/dynamic-list/dynamic-list.component";
import templateUrl from './new-term-deposit-list.template.html';
import {depositGroupNames} from "constants/deposit";
import {ProductService} from "components/customer/common/product.service";
import {ILocationService} from "angular";
import {DepositType, TermDepositService} from "components/administration/term-deposit/common/term-deposit.types";
import {InterestBoardService} from "components/administration/casa/interest-board/interest-board.service.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";

interface View extends DepositType {
  interestRange: string;
  productTypeCode?: string;
  productName?: string;
  productSubtype: string;
  productSubtypeName: string;
  minimalBalanceFormatted: string;
  maximumBalanceFormatted: string;
  open: () => void;
}

class CustomerTemDepositCreateList {
  private termDeposits: View[] = [];
  private customerId!: string;
  private readonly columns: Columns = [
    { title: 'No'},
    { title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    { title: 'Product name', field: 'productName', sortable: 'productName'},
    { title: 'Product subtype', field: 'productSubtypeName', sortable: 'productSubtypeName'},
    { title: 'Interest rate', field: 'interestRange'},
    { title: 'Minimum amount', field: 'minimalBalanceFormatted'},
    { title: 'Maximum amount', field: 'maximumBalanceFormatted'},
    { actions: [{
        access: 'CreateDeposit',
        label: 'Open',
        field: 'open',
        type: ActionType.BUTTON
      }]
    },
  ];

  private readonly sorting: Sorting = {
    productTypeCode: 'asc',
    productName: 'asc',
    productSubtypeName: 'asc',
  };

  constructor(private $route: NxRouteService, private $location: ILocationService, private termDepositsService: TermDepositService,
              private interestBoardService: InterestBoardService, private productService: ProductService,
              private customerCache: CustomerCache, private $filter: NxIFilterService) { }

  async $onInit() {
    this.customerId = this.$route.current.params['customerId'] as string;

    const [{customerType}, termDeposits] = await Promise.all([
      this.customerCache.profile(this.customerId).toPromise(),
      this.termDepositsService.toPromise()
    ]);

    this.termDeposits = termDeposits.map((deposit: DepositType) => {
        const depositGroupTranslation = depositGroupNames.find(item => item.value === deposit.depositGroup)!;
        const subType = this.$filter('prettyEnum')(deposit.depositGroup);
        return {
          ...deposit,
          interestRange: this.interestBoardService.formatInterestRateRangeForRateType(deposit.interestBoards),
          productTypeCode: deposit.productDefinition.productTypeCode,
          productName: deposit.productDefinition.productName,
          productSubtype: subType,
          productSubtypeName: depositGroupTranslation ? depositGroupTranslation.label : subType,
          minimalBalanceFormatted: this.$filter('nxCurrency')(deposit.minimalBalance),
          maximumBalanceFormatted: this.$filter('nxCurrency')(deposit.maximumBalance),
          open: () => {
            this.$location.path(`/customer/${this.customerId}/term-deposits/create/${deposit.id}`);
          }
        };
      })
      .filter(d => {
        return this.productService.availableForSale(d)
          && this.productService.availableForCustomerType(d.productDefinition.customerTypeConstraints, customerType);
      });
  }
}

nxModule.component('customerTermDepositCreateList', {
  templateUrl: templateUrl,
  controller: CustomerTemDepositCreateList
});
