import {customerPath} from "./customer.routes";
import angular from "angular-route";

const creditLinesPath = `${customerPath}/credit-lines`;

export const creditLinesRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  [creditLinesPath, {
    template: '<customer-credit-line/>',
    label: 'Credit Lines'
  }], [`${creditLinesPath}/create`, {
    template: '<customer-credit-line-create/>',
    label: 'New Credit Line'
  }], [`${creditLinesPath}/:creditLineId`, {
    template: '<customer-credit-line/>',
    label: 'Credit Lines'
  }], [`${creditLinesPath}/:creditLineId/edit`, {
    template: '<customer-credit-line-edit/>',
    label: 'Edit Credit Line'
  }], [`${creditLinesPath}/:creditLineId/manage-borrowers`, {
    template: '<customer-credit-line-borrowers/>',
    label: 'Manage Borrowers'
  }]
];