import nxModule from 'nxModule';

import templateUrl from './start-the-day-new.template.html';
import {WorkingDaysCache} from "../../../service/working-days-cache";
import {BranchService} from "../../../service/branch.service";
import {Holiday, WorkingDay} from "../../../../../react/management/WorkingDayType";
import tokenStore from "../../../../../react/authentication/tokenStore";
import UserCounterService, {AssetCounterSummary} from "../../../technical/status/user-counter.service";
import {ILocationService} from "angular";
import {Branch} from "management/BranchTypes";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {NxRouteService} from "routes/NxRouteService";

interface StartDayAction {
  clearingDay: boolean;
  bspApproval?: boolean;
  userId: number;
  branchId: number;
  startCash: number;
  startCoci: number;
}

class StartTheDayNew {
  workingDay!: WorkingDay;
  holiday: Holiday | undefined;
  branch!: Branch;
  clearingDay: boolean | null = null;
  bspApproval: boolean | null = null;
  counters: AssetCounterSummary | null = null;

  constructor (private $filter: NxIFilterService,
               private http: HttpService,
               private notification: Notification,
               private workingDaysCache: WorkingDaysCache,
               private branchService: BranchService,
               private actionCommand: ActionCommand,
               private $location: ILocationService,
               private $route: NxRouteService,
               private confirmationTemplate: ConfirmationTemplate,
               private userCounterService: UserCounterService) { }

  async $onInit(): Promise<void> {
    this.counters = await this.http.get<AssetCounterSummary>(`/management/branches/${this.branch.id}/counter/summary`).toPromise();
    this.holiday = this.workingDay?.branchHoliday ? this.workingDay?.branchHoliday : this.workingDay?.bankHoliday;
  }

  getDayInfo(): string {
    const isNonWorkingDay = this.branch.systemDate !== this.branch.postingDate;
    if (this.holiday) {
      return `Today is a holiday (${this.holiday.name}).`;
    } else if (isNonWorkingDay) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const dayInWords = this.branch.systemDate.toLocaleString('en-US', {weekday: 'long'});
      return `Today is a non-working day (${dayInWords}).`;
    }

    return '';
  }

  cancelChanges(): void {
    this.actionCommand.cancelChanges();
  }

  async save (): Promise<void> {
    if(this.clearingDay === null) {
      throw new Error('Missing clearing day value');
    }

    if(!this.counters) {
      throw new Error('Missing counters');
    }

    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to start the day?',
      details: [
        {label: 'Branch', description: this.branch.name},
        {label: 'System Date', description: this.$filter('prettyDate')(this.branch.systemDate)},
        {label: 'Posting Date', description: this.$filter('prettyDate')(this.branch.postingDate)},
      ],
      information: this.getDayInfo(),
      warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be started.',
    });

    if(confirmed) {
      const request: StartDayAction = {
        userId: tokenStore.getTokenDetails().userId,
        branchId: this.branch.id,
        clearingDay: this.clearingDay,
        startCash: this.counters.totalCash,
        startCoci: this.counters.totalCheck,
        ...(this.bspApproval != null ? {bspApproval: this.bspApproval} : null),
      };

      const response = await this.actionCommand.executeAsync('START_DAY', request);
      if(!response.approvalRequired) {
        this.userCounterService.refresh();
        this.workingDaysCache.refetch();
        this.$location.path(`/dashboard/miscellaneous-transactions`);
      }
    }

  }
}

nxModule.component('startTheDayNew', {
  templateUrl,
  bindings: {
    branch: '<',
    workingDay: '<'
  },
  controller: StartTheDayNew
});
