import nxModule from 'nxModule';
import moment from 'moment';
import systemPropertyService from "../../../../react/system/systemPropertyService";
import Popup from "shared/common/popup";

export enum StartDayAndCloseCounterMode {
  ALWAYS = 'ALWAYS',
  ON_HOLIDAY = 'ON_HOLIDAY',
  NEVER = 'NEVER'
}

interface AutomatedMode {
  startDay: StartDayAndCloseCounterMode;
  closeCounter: StartDayAndCloseCounterMode
}

interface NotificationContext {
  mode: AutomatedMode;
  nextAutomatedEodTime: moment.Moment;
  showNotification: boolean;
}

class AutomatedEodNotificationComponent {
  /*
   * Stores whether notification was shown recently.
   *
   * Note: It is known that there is up to 29 seconds window when any manual page reload will initialize that component
   * and show notification once again.
   *
   * (max time left to disable notification) - (time interval of notification check) = (time window)
   * 59 - 30 = 29
   */
  private notified: boolean = false;

  private browserToServerMinutesDiff!: number;

  constructor(
    private readonly popup: Popup,
    private readonly $interval: any,
    private readonly appVersionService: any
  ) {
  }

  async $onInit() {
    const enabled = systemPropertyService.getProperty('AUTOMATED_EOD_ENABLED') === 'TRUE';
    if (!enabled) {
      return;
    }

    /*
     * The time received is is most likely in GMT+8 however we can ignore that.
     * What we need is to calculate difference so we can figure out current server time anytime.
     */
    const {webServerTime} = await this.appVersionService.backend();
    this.browserToServerMinutesDiff = moment(webServerTime).diff(moment(), 'minutes', true);

    this.$interval(async () => {
      const {mode, nextAutomatedEodTime, showNotification} = await this.getNotificationContext();
      if (!this.notified && showNotification) {
        this.notified = true;
        this.popup({header: 'Information', text: this.getMessage(mode, nextAutomatedEodTime), renderHtml: true});
      } else if (this.notified && !showNotification) {
        this.notified = false;
      }

    }, 1000 * 30); // Repeat each 30 seconds (to not get lost in it) - has to be <60 seconds due minute resolution. Check shouldShowNotification()
  }

  private async getNotificationContext() : Promise<NotificationContext> {
    const startDayMode = <StartDayAndCloseCounterMode> systemPropertyService.getPropertyOrError('AUTOMATED_EOD_START_DAY');
    const closeCounterMode = <StartDayAndCloseCounterMode> systemPropertyService.getPropertyOrError('AUTOMATED_EOD_CLOSE_COUNTER');
    const performAtTime = moment(systemPropertyService.getPropertyOrError('AUTOMATED_EOD_PERFORM_AT_TIME'), 'HH:mm');
    const nextAutomatedEodTime = this.getNextAutomatedEodTime(performAtTime);

    return {
      mode: {
        startDay: startDayMode,
        closeCounter: closeCounterMode
      },
      nextAutomatedEodTime: nextAutomatedEodTime,
      showNotification: this.shouldShowNotification(nextAutomatedEodTime)
    };
  }

  /**
   * Return next performing time with date in nearest future
   */
  private getNextAutomatedEodTime(performAtTime: moment.Moment): moment.Moment {
    // Use current server's day as initial date
    const performTimeOnServerDate = this.getServerTime()
      .hour(performAtTime.hour())
      .minute(performAtTime.minute())
      .second(0);

    if (performTimeOnServerDate.diff(this.getServerTime(), 'minutes') < 0) {
      // Next performing time is tomorrow (relative to current server time)
      return performTimeOnServerDate.add(1, 'days');
    }

    return performTimeOnServerDate;
  }

  /*
     * Return TRUE whether it is 1 minute (rounded) between current server time and notification time, otherwise FALSE.
     *
     * Note: it should be called only once per minute.
     */
  private shouldShowNotification(performTime: moment.Moment): boolean {
    const notificationBeforeInMinutes = parseInt(systemPropertyService.getPropertyOrError('AUTOMATED_EOD_NOTIFICATION_BEFORE_IN_MINUTES'));

    /*
     * Add 1 minute to difference - notification will then appear just right after the target date
     * Assume: before 5 min, performAt 10th PM => notification will appear X seconds after 9:55 PM instead of X seconds before that.
     */
    return notificationBeforeInMinutes === performTime.diff(this.getServerTime(), 'minutes', false) + 1;
  }

  private getMessage(mode: AutomatedMode, nextAutomatedEodTime: moment.Moment): string {
    const closeCounterMode = mode.closeCounter;
    const startDayMode = mode.startDay;
    const mainMsg = `The automated EOD is scheduled to be run at ${nextAutomatedEodTime.format('hh:mm a')}.`;
    const messages = ['The EOD will be executed for all branches'];

    if (closeCounterMode === StartDayAndCloseCounterMode.NEVER) {
      messages.push('Before that, please perform Close Counter on them before said time');
    } else if (closeCounterMode === StartDayAndCloseCounterMode.ON_HOLIDAY) {
      messages.push('Before that, Close Counter will run automatically if a branch is on holiday otherwise please perform them before said time');
    } else {
      messages.push('Before that, Close Counter will run automatically');
    }

    if (startDayMode === StartDayAndCloseCounterMode.NEVER) {
      messages.push('After EOD, Start Day will have to be done manually');
    } else if (startDayMode === StartDayAndCloseCounterMode.ON_HOLIDAY) {
      messages.push('After EOD, Start Day will run automatically whenever branch is on holiday otherwise it has to be done manually')
    } else {
      messages.push('After EOD, Start Day will be run automatically');
    }

    return `${mainMsg} ${messages.join('. ')}`
  }

  private getServerTime(): moment.Moment {
    return moment().add(this.browserToServerMinutesDiff, 'minutes');
  }
}

nxModule.component('automatedEodNotification', {
  controller: AutomatedEodNotificationComponent,
});
