import nxModule from 'nxModule';

import './group-loan-information.component'
import BigNumber from "bignumber.js";

const templateUrl = require('./group-loan-check-release.template.html');
nxModule.component('customerGroupLoanCheckRelease', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, http, confirmation, notification, customerCache, loanCache, command,
                        groupLoanService) {
    const that = this;

    let customerId = $route.current.params['customerId'];
    let groupLoanId = $route.current.params['groupLoanId'];

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.plus(item[itemProperty] || 0), new BigNumber(0));
    };

    that.$onInit = async () => {
      that.groupLoan = await groupLoanService.fetchGroupLoan(groupLoanId, true);
      // Mark all individual loans for release
      this.groupLoan.individualLoans.forEach(l => l.forRelease = true);
    };

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/group-loans/${groupLoanId}`);
    };

    that.cancelChanges = () => {
      that.redirectBack();
    };

    that.save = async () => {
      const loanIds = this.groupLoan.individualLoans.filter(l => l.forRelease).map(l => l.id);

      await command.execute('ReleaseGroupLoanByCC', {
        cashiersCheckNumber: that.checkNumber,
        remarks: that.remarks,
        groupLoanId: groupLoanId,
        loanIds: loanIds
      }).toPromise();

      notification.show('Cashier\'s check successfully created.');
      customerCache.loans(customerId).refetch();
      that.groupLoan.loanIds.map(loanId => {
        loanCache.loan(loanId).refetch();
      });
      that.redirectBack()
    };

    that.computeTotalReleaseAmount = () => {
      if (that.groupLoan?.individualLoans) {
        const forReleaseLoans = this.groupLoan.individualLoans.filter(l => l.forRelease);
        return bigSumBy(forReleaseLoans, 'releaseAmount').toNumber();
      }
      return 0;
    }
  }
});
