import nxModule from 'nxModule';
import AccessType from 'constants/customerAccessType';
import {ILocationService} from "angular";
import {ActiveCustomerService} from "components/customer/active-customer.service";

export class CustomerContextService {
  public customerId: number | null = null;
  public hasInvalidData: boolean| null = null;

  constructor(private $location: ILocationService,
              private activeCustomerService: ActiveCustomerService) {
  }

  public unloadCustomer(): void {
    this.customerId = null;
    this.hasInvalidData = null;
    this.activeCustomerService.ofType(AccessType.NONGROUP).updateCustomerId(null);
  }

  public loadCustomer(customerId: number, hasInvalidData: boolean) {
    if (!this.isRequestFromCustomerContext()) {
      return;
    }

    this.customerId = customerId;
    this.hasInvalidData = hasInvalidData;
    this.activeCustomerService.ofType(AccessType.NONGROUP).updateCustomerId(customerId);
  }

  private isRequestFromCustomerContext(): boolean {
    const path: string = this.$location.path();
    return path.startsWith('/customer') && !this.isRequestFromGroupProfileContext(path);
  }

  private isRequestFromGroupProfileContext(path: string): boolean {
    return path.endsWith('/group-profile');
  }
}

nxModule.service('customerContextService', CustomerContextService);