import angular from 'angular';
import BigNumber from 'bignumber.js';
import {Loan, LoanDetails} from 'components/service/loan.types';
import {ProductDefinitionService} from 'components/service/product-definition.service';
import {ProductDefinition, ProductStatus} from 'components/service/product.types';
import {ModalApi} from 'components/technical/modal/modal.component';
import {Customer} from 'customer/CustomerTypes';
import {NgTableParams} from 'ng-table';
import nxModule from 'nxModule';
import Authentication from 'shared/utils/authentication';
import {HttpService} from 'shared/utils/httpService';

import './loan-consolidation.styles.less';
import Notification from 'shared/utils/notification';
import templateUrl from './loan-consolidation.template.html';

interface ConsolidatedLoan {
  customerName: string;
  loanId: number;
  productNumber: string;
  productName?: string;
  principalAmount: number;
  principalBalance: number;
  interestBalance: number;
  cbuBalance: number;
  pfBalance: number;
  tpBalance: number;
  pastDueInterest: number;
  penalty: number;
  customFees: number;
  status: ProductStatus;
}

const validStatusesForConsolidation : ProductStatus[] = ['ACTIVE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING', 'PAST_DUE_LITIGATION'];

class ConsolidatedLoansComponent {
  form!: angular.IFormController;
  private loan!: Loan;
  private hasAccessToAllBranches!: boolean;
  protected customerBranchIdRestriction!: number[];
  private customerSearchModal!: ModalApi;
  protected consolidatedLoansTableConfig!: NgTableParams<ConsolidatedLoan>;
  protected customerLoansTableConfig!: NgTableParams<LoanDetails>;
  protected consolidatedLoans: ConsolidatedLoan[] = [];
  protected selectedCustomer?: Customer;
  private productDefinitions!: ProductDefinition[];

  protected principal: BigNumber = new BigNumber(0);
  protected interest: BigNumber = new BigNumber(0);
  protected cbu: BigNumber = new BigNumber(0);
  protected pf: BigNumber = new BigNumber(0);
  protected tp: BigNumber = new BigNumber(0);
  protected pdi: BigNumber = new BigNumber(0);
  protected penalty: BigNumber = new BigNumber(0);
  protected customFee: BigNumber = new BigNumber(0);

  constructor(private http: HttpService, protected notification: Notification, protected authentication: Authentication, private productDefinitionService: ProductDefinitionService) {
  }

  async $onInit(): Promise<void> {
    this.consolidatedLoansTableConfig = new NgTableParams<ConsolidatedLoan>({
      page: 1,
      count: 20,
    });
    this.customerLoansTableConfig = new NgTableParams<LoanDetails>({
      page: 1,
      count: 5,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3
    });
    this.hasAccessToAllBranches = this.authentication.permissions['CST_ALL_BRANCHES_ACCESS'];
    this.customerBranchIdRestriction = this.hasAccessToAllBranches ? [] : this.authentication.context.branchIds;
    this.productDefinitions = await this.productDefinitionService.toPromise();
  }

  onModalReady = ({ api }: {api: ModalApi}) : void => {
    this.customerSearchModal = api;
  };

  async showCustomerSearch() : Promise<void> {
    await this.customerSearchModal.show();
    this.customerLoansTableConfig.settings({
      dataset: []
    });
    this.selectedCustomer = undefined;
  }

  async selectCustomer(customer: Customer): Promise<void> {
    this.selectedCustomer = customer;
    const loans : LoanDetails[] = await this.http.get<LoanDetails[]>(`/products/loans?customerId=${customer.id}`).toPromise();

    const availableLoans = loans.filter(l => validStatusesForConsolidation.includes(l.status) && (this.hasAccessToAllBranches || this.hasBranchAccess(this.authentication.context.branchIds, l.branchId)))
      .map(l => {
        const productDefinition = this.productDefinitions.find(pd => pd.id === l.definitionId);
        if(productDefinition) {
          l.productDefinition = productDefinition;
        }
        return l;
      })

    this.customerLoansTableConfig.settings({
      dataset: availableLoans
    });
  }

  isLoanAlreadyAdded(loan : Loan) : boolean {
    return this.loan.remadeFromLoanIds?.includes(loan.id) ?? false;
  }

  addLoan(loan: LoanDetails): void {
    if(!this.selectedCustomer) {
      return;
    }

    const loanIndex = this.consolidatedLoans.findIndex(l => l.loanId === loan.id);
    if(loanIndex >= 0) {
      this.notification.show("Error", `Loan ${loan.productNumber} has already been added.`);
      return;
    }
    const balance = loan.amortizationSchedule.totalBalance;
    this.consolidatedLoans.push({
      customerName: this.selectedCustomer?.effectiveName,
      loanId: loan.id,
      productNumber: loan.productNumber,
      productName: loan.productDefinition.productName,
      principalAmount: loan.principalAmount,
      principalBalance: loan.principalBalance,
      status: loan.status,
      interestBalance: balance?.interest ?? 0,
      cbuBalance: balance?.cbuCharge ?? 0,
      pfBalance: balance?.pfCharge ?? 0,
      tpBalance: balance?.tpCharge ?? 0,
      pastDueInterest: balance?.totalPastDueInterest ?? 0,
      penalty: balance?.totalPenalty ?? 0,
      customFees: balance?.customFees ?? 0
    });

    this.recalculateTotals();

    if(!this.loan.remadeFromLoanIds) {
      this.loan.remadeFromLoanIds = [];
    }

    this.loan.remadeFromLoanIds.push(loan.id);
  }

  removeRow(index: number, loanId: number): void {
    this.consolidatedLoans.splice(index, 1);
    this.loan.remadeFromLoanIds = this.loan.remadeFromLoanIds?.filter(id => id !== loanId);
    this.recalculateTotals();
  }

  hasConsolidatedLoan() : boolean {
    return (this.loan?.remadeFromLoanIds?.length ?? 0) > 0;
  }

  hasBranchAccess(userBranchIds: number[], loanBranchId: number): boolean {
    return userBranchIds.includes(loanBranchId);
  }

  recalculateTotals(): void {
    this.principal = new BigNumber(0);
    this.interest = new BigNumber(0);
    this.cbu = new BigNumber(0);
    this.pf = new BigNumber(0);
    this.tp = new BigNumber(0);
    this.pdi = new BigNumber(0);
    this.penalty = new BigNumber(0);
    this.customFee = new BigNumber(0);

    for(const loan of this.consolidatedLoans) {
      this.principal = this.principal.plus(new BigNumber(loan.principalBalance));
      this.interest = this.interest.plus(new BigNumber(loan.interestBalance));
      this.cbu = this.cbu.plus(new BigNumber(loan.cbuBalance));
      this.pf = this.pf.plus(new BigNumber(loan.pfBalance));
      this.tp = this.tp.plus(new BigNumber(loan.tpBalance));
      this.pdi = this.pdi.plus(new BigNumber(loan.pastDueInterest));
      this.penalty = this.penalty.plus(new BigNumber(loan.penalty));
      this.customFee = this.customFee.plus(new BigNumber(loan.customFees));
    }
  }
}

nxModule.component('loanConsolidation', {
  templateUrl,
  bindings: {
    loan: '='
  },
  require: {
    form: '?^form',
  },
  controller: ConsolidatedLoansComponent
});
