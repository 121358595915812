import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('ledgerTagCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/tags'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.ledgerTag,
    cacheName: 'ledgerTag'
  })
);
