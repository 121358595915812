const oneSecond = 1000;
const oneMinute = 60 * oneSecond;
const oneHour = 60 * oneMinute;
const twelveHours = 12 * oneHour;
const fiveDays = 5 * 24 * oneHour;
const none = 0;
const defaultCache = none;

/* eslint-disable */
// @ts-ignore
const apiHost = __API_URL;
/* eslint-enable */

const disabledOnDev = (ms: number): number => {
  return (process.env.NODE_ENV !== 'production') ? 99999999999999999 : ms;
};


let apiPrefix = `${apiHost}/api/v2`;
const API_DOMAIN_LOCAL_STORAGE_KEY = 'nx_apiOriginDomain';
const apiDomain = localStorage.getItem(API_DOMAIN_LOCAL_STORAGE_KEY);
if (apiDomain && apiDomain.trim()) {
  // switching to external api endpoint...
  apiPrefix = `${apiDomain}/api/v2`;
  console.log(`Switching to API endpoint: ${apiPrefix}`);
}

export interface CacheConfig {
  printBackgroundCache: number;
  translation: number;
  permissionCache: number;
  dict: number;
  geoLocation: number;
  misGroups: number;
  loanPurposesToIndustry: number;
  productMappingsAccounts: number;
  productMappingsAllAccounts: number;
  productStatusMigration: number;
  propertyConfig: number;
  organizationsConfig: number;
  rolesConfig: number;
  ledgerActionTemplate: number;
  systemProperties: number;
  currency: number;
  actionCategory: number;
  depositoryAccount: number;
  prints: number;
  passbook: number;
  certificate: number;
  casaTypes: number;
  brisReportingTypes: number;
  ata: number;
  commandMetadata: number;
  schemaTables: number;
  holiday: number;
  productCategory: number;
  customFieldCategoryDetails: number;
  accessRule: number;
  singleUser: number;
  users: number;
  commandRoleMapCache: number;
  agentCandidacy: number;
  termDepositTypes: number;
  loanProducts: number;
  accountProducts: number;
  depositAccountTypes: number;
  accountGroup: number;
  branches: number;
  productFees: number;
  ledgerTag: number;
  branchWorkingDay: number;
  atmTerminals: number;
  agents: number;
  productMappings: number;
  ledger: number;
  productDefinitions: number;
  workingDays: number;
  checkbook: number;
  productLock: number;
  creditLineLoans: number;
  creditLineCustomers: number;
  borrowerCreditLines: number;
  creditLineTypes: number;
  complianceConfig: number;
  complianceRedFlagConfig: number;
  fileCache: number;
  userCounter: number;
}

export interface PaginationConfig {
  itemsPerPage: number;
  maxPages: number;
  fillLastPage: boolean;
}

export interface Config {
  apiPrefix: string;
  devProfile: boolean;
  internetCheckInterval: number;
  postingDateValidationInterval: number;
  cashReceiptThreshold: number;
  appVersionCheckInterval: number;
  asyncCommandPollingInterval: number;
  cache: CacheConfig;
  paginationConfig: PaginationConfig;
  defaultLanguage: string;
  statusRefreshInterval: number;
  actionCounterFetchInterval: number;
  batchProcessStatusFetchInterval: number;
  expirationTime: number;
  googleAnalyticsAccount: string;
}

export default Object.freeze<Config>({
  apiPrefix: apiPrefix,
  devProfile: process.env.NODE_ENV !== 'production',
  internetCheckInterval: disabledOnDev(30 * oneSecond),
  postingDateValidationInterval: 65 * oneSecond,
  cashReceiptThreshold: 500000,
  appVersionCheckInterval: oneMinute,
  asyncCommandPollingInterval: oneSecond,
  cache: {
    // 5 days
    printBackgroundCache: fiveDays,
    translation: fiveDays,
    permissionCache: fiveDays,

    // 12 hours
    dict: twelveHours,
    geoLocation: twelveHours,
    misGroups: twelveHours,
    loanPurposesToIndustry: twelveHours,
    productMappingsAccounts: twelveHours,
    productMappingsAllAccounts: twelveHours,
    productStatusMigration: twelveHours,
    propertyConfig: twelveHours,
    organizationsConfig: twelveHours,
    rolesConfig: twelveHours,
    ledgerActionTemplate: twelveHours,
    systemProperties: twelveHours,
    currency: twelveHours,
    actionCategory: twelveHours,
    depositoryAccount: twelveHours,
    prints: twelveHours,
    passbook: twelveHours,
    certificate: twelveHours,
    casaTypes: twelveHours,
    brisReportingTypes: twelveHours,
    ata: twelveHours,
    commandMetadata: twelveHours,
    schemaTables: twelveHours,
    holiday: twelveHours,
    productCategory: twelveHours,
    customFieldCategoryDetails: twelveHours,
    accessRule: twelveHours,

    // 1 hour
    singleUser: oneHour,
    users: oneHour,
    commandRoleMapCache: oneHour,
    agentCandidacy: oneHour,

    // 1 minute
    termDepositTypes: oneMinute,
    loanProducts: oneMinute,
    accountProducts: oneMinute,
    depositAccountTypes: oneMinute,
    accountGroup: oneMinute,
    branches: oneMinute,
    productFees: oneMinute,
    ledgerTag: oneMinute,
    branchWorkingDay: oneMinute,
    atmTerminals: oneMinute,
    agents: oneMinute,

    // Default
    productMappings: defaultCache,
    ledger: defaultCache,
    productDefinitions: defaultCache,
    workingDays: defaultCache,
    checkbook: defaultCache,
    productLock: defaultCache,
    creditLineLoans: defaultCache,
    creditLineCustomers: defaultCache,
    borrowerCreditLines: defaultCache,
    creditLineTypes: defaultCache,
    complianceConfig: defaultCache,
    complianceRedFlagConfig: defaultCache,

    // None
    fileCache: none,  // don't turn this on, it's broken! (TODO)

    // Conditional
    userCounter: disabledOnDev(50 * oneSecond)
  },
  // pagination config for at-table
  paginationConfig: {
    itemsPerPage: 10,
    maxPages: 9,
    fillLastPage: false
  },
  defaultLanguage: 'en',
  statusRefreshInterval: 30 * oneSecond,
  actionCounterFetchInterval: disabledOnDev(60 * oneSecond),
  batchProcessStatusFetchInterval: 10 * oneSecond,
  expirationTime: 30, //how many DAYS must pass to expire user
  googleAnalyticsAccount: 'UA-120636974-1'
});
