import nxModule from 'nxModule';

import templateUrl from './update-branch-asset-counter.template.html';
import {Branch} from "management/BranchTypes";
import {BranchService} from "components/service/branch.service";
import {StatusService} from "components/technical/status/status.service";
import Popup from "shared/common/popup";
import {HttpService} from "shared/utils/httpService";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

class UpdateBranchAssetCounter {
  commandInput = {
    branchId: null,
    cashDiff: 0,
    checkDiff: 0
  };

  branches: Branch[] = [];
  private branch: Branch | undefined = undefined;
  private branchCash: number | undefined;
  private branchCheck: number | undefined;

  constructor(private confirmationTemplate: ConfirmationTemplate, private http: HttpService, private popup: Popup,
              private $filter: NxIFilterService, private $route: NxRouteService, private command: CommandService,
              private branchService: BranchService, private statusService: StatusService) {
  }


  async $onInit(): Promise<void> {
    this.branches = await this.branchService.toPromise();
  }

  async onBranchChange(branchId: number): Promise<void> {
    this.branch = this.branches.find(b => b.id === branchId);
    if (!this.branch) {
     return;
    }

    const counter = await this.http.get<{totalCash: number, totalCheck: number}>(`/management/branches/${this.branch.id}/counter`).toPromise();
    this.branchCash = counter.totalCash;
    this.branchCheck = counter.totalCheck;
  }

  async execute(): Promise<void> {
    if(!this.branch) {
      throw new Error('Missing branch');
    }

    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to update the branch asset counter?',
      details: [
        {label: 'Branch name', description: this.$filter('prettyEnum')(this.branch.name)},
        {label: 'Increase cash by', description: this.$filter('nxCurrency')(this.commandInput.cashDiff)},
        {label: 'Increase check by', description: this.$filter('nxCurrency')(this.commandInput.checkDiff)}
      ],
    });

    if(!confirmed) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = await this.command.execute('UpdateBranchAssetCounter', this.commandInput).toPromise();
    if (response.approvalRequired) {
      return;
    }

    await this.statusService.refresh();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$route.reload();
  }
}

nxModule.component('updateBranchAssetCounter', {
  templateUrl,
  controller: UpdateBranchAssetCounter
});
