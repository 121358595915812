import nxModule from 'nxModule';

import templateUrl from './group-loan-cash-release.template.html';
import {GroupLoan, GroupLoanService} from '../../group-loans/group-loan.service';
import _ from "lodash";
import {ILocationService} from "angular";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {GroupCustomerCache} from "components/service/group-customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class CustomerGroupLoanCashRelease {
  private remarks: string = '';
  private customerId!: number;
  private groupLoanId!: number;
  private groupLoan!: GroupLoan;

  constructor(
    private $location: ILocationService,
    private command: CommandService,
    private confirmation: Confirmation,
    private groupCustomerCache: GroupCustomerCache,
    private groupLoanService: GroupLoanService,
    private customerCache: CustomerCache
  ) {
  }

  async $onInit(): Promise<void> {
    this.groupLoan = await this.groupLoanService.fetchGroupLoan(this.groupLoanId, true);
    // Mark all individual loans for release
    this.groupLoan.individualLoans.forEach(l => l.forRelease = true);
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/group-loans/${this.groupLoanId}`);
  }

  cancelChanges(): void {
    this.redirectBack();
  }

  computeTotalReleaseAmount() : number {
    if (this.groupLoan?.individualLoans) {
      const forReleaseLoans = this.groupLoan.individualLoans.filter(l => l.forRelease);
      return _.sumBy(forReleaseLoans, ln => ln.releaseAmount);
    }
    return 0;
  }

  async save(): Promise<void> {
    const loanIds = this.groupLoan.individualLoans.filter(l => l.forRelease).map(l => l.id);
    if (!loanIds) {
      return;
    }

    const confirmed = await this.confirmation('Are you sure you want to release group loan?');
    if (confirmed) {
      await this.command.execute('ReleaseGroupLoanByCash', {
        remarks: this.remarks,
        groupLoanId: this.groupLoanId,
        loanIds: loanIds
      }).toPromise();

      const loadedCustomer = this.groupLoan.individualLoans.find(l => l.forRelease && String(l.customerId) === String(this.customerCache.loadedCustomerId));
      if(loadedCustomer) {
        await this.customerCache.loans(this.customerCache.loadedCustomerId).refetch();
      }
      await this.groupCustomerCache.refetch();
      this.redirectBack();
    }
  }
}


nxModule.component('customerGroupLoanCashRelease', {
  controller: CustomerGroupLoanCashRelease,
  templateUrl,
  bindings: {
    customerId: '<',
    groupLoanId: '<'
  }
});
