import nxModule from 'nxModule';

import templateUrl from './batch-clearing-history.template.html';
import {IController, ILocationService} from 'angular';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';
import {
  OutgoingCheckClearingError,
  OutgoingCheckClearingService
} from '../../../check/outgoing/outgoing-check-clearing.service';
import {PageResult} from "tools/HttpTypes";
import {HttpService} from "shared/utils/httpService";
import {Breadcrumbs} from "angular-breadcrumbs";

interface OutgoingCheckClearingHistoryView {
  id: number;
  checkId: number;
  registeredOn: string;
  productBalance: number;
  productHoldsBalance: number;
  ataBalance: number;
  ataHoldsBalance: number;
  clearedSuccessFully: boolean;
  clearingErrors: string[];
  clearingChargesAmount: number;

  checkNumber: string;
  micrNumber: string;
  amount: number;
  productNumber: string;
}

class BatchClearingHistory implements IController {
  // bindings
  batchId!: string;

  tableConfig: NgTableParams<OutgoingCheckClearingHistoryView>;

  constructor(private $location: ILocationService,
              private http: HttpService,
              private breadcrumbs: Breadcrumbs,
              private outgoingCheckClearingService: OutgoingCheckClearingService) {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: (params: NgTableParams<OutgoingCheckClearingHistoryView>): Promise<OutgoingCheckClearingHistoryView[]> => {
        const requestParams = { pageNo: params.page() - 1, pageSize: params.count() };
        return this.http.get(`/checks/outgoing/clearing-history/${this.batchId}`, { params: requestParams }).toPromise()
          .then((response: PageResult<OutgoingCheckClearingHistoryView>) => {
            params.total(response.totalCount);
            return response.result;
          });
      }
    });
  }

  getRowNo(index: number): number {
    const currentPage = this.tableConfig.page() - 1; // count from 0
    const rowsPerPage = this.tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }

  formatClearingErrors(errors: OutgoingCheckClearingError[]): string {
    return (errors || []).map(error => this.outgoingCheckClearingService.format(error)).join(', ') || '-';
  }

  goBack() {
    const { path: parentPath } = _.nth(this.breadcrumbs.get(), -2)!;
    this.$location.path(parentPath);
  }

}

nxModule.component('batchClearingHistory', {
  templateUrl: templateUrl,
  bindings: {
    batchId: '<'
  },
  controller: BatchClearingHistory
});
