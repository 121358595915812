import branchAccessModePredicate from './branchAccessMode.predicate';
import collectionPredicate from './collection.predicate';
import userPredicate from './user.predicate';
import equalsPredicate from './equals.predicates';

const accessPredicates = {
  'BRANCH_ACCESS_MODE': branchAccessModePredicate,
  'USER_ACCESS_MODE': userPredicate,
  'IN': collectionPredicate,

  // cash based predicates cannot be checked before executing command, so we allow all of them
  'LESS_THAN_AMOUNT': () => true,
  'LESS_THAN_OR_EQUAL_AMOUNT': () => true,
  'GREATER_THAN_AMOUNT': () => true,
  'GREATER_THAN_OR_EQUAL_AMOUNT': () => true,
  'EQUALS': equalsPredicate
};

export default accessPredicates;
