import nxModule from 'nxModule';
import _ from 'lodash';

import './customer-credit-line-edit.styles.less';
import templateUrl from './customer-credit-line-edit.template.html';

class CustomerCreditLineEdit {

  constructor($route, $location, customerCache, confirmationTemplate, customerService, command) {
    this.$route = $route;
    this.$location = $location;
    this.customerCache = customerCache;
    this.confirmationTemplate = confirmationTemplate;
    this.customerService = customerService;
    this.command = command;
  }

  async $onInit() {
    this.customerId = Number(this.$route.current.params['customerId']);
    this.creditLineId = Number(this.$route.current.params['creditLineId']);

    const creditLines = await this.customerCache.creditLines(this.customerId).toPromise();
    this.creditLine = creditLines.find(cl => cl.id === this.creditLineId);
  }

  async updateCreditLine() {
    const request = _.cloneDeep(this.creditLine);
    this.confirmationTemplate({
      question: 'Update credit line?',
      details: [
        {label: "Description", description: this.creditLine.description},
        {label: "Loan types allowed", description: this.creditLine.loanTypesAllowed}
      ],
      yesCallback: async() => {
        await this.command.execute('UpdateCreditLine', request).toPromise();
        this.customerCache.creditLines(this.customerId).refetch();
        this.redirectBack();
      }
    });

  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.creditLineId}`);
  }

}

nxModule.component('customerCreditLineEdit', {
  templateUrl: templateUrl,
  controller: CustomerCreditLineEdit
});