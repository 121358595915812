import nxModule from 'nxModule';

import templateUrl from "./loan-gl-payment.template.html";

class CustomerLoansGLPayment {
  constructor($location, confirmation, command, branchService, customerCache, modalPrintPreviewService, accessRuleService, $filter, authentication) {
    this.paymentRequest = {};
    this.$location = $location;
    this.confirmation = confirmation;
    this.command = command;
    this.branchService = branchService;
    this.customerCache = customerCache;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.$filter = $filter;
    this.authentication = authentication;

    this.operation = {};
    this.remarks = null;

    this.commandName = 'PayLoanByGL';
    accessRuleService.accessRuleForLoggedInUserRole(this.commandName)
        .then(rule => this.canPerformCustomPayment = !rule.predicates['EQUALS'] || rule.predicates['EQUALS']['LOAN_CUSTOM_PAYMENT']);
  }

  async $onInit() {
    const branches = await this.branchService.toPromise();
    this.branch = branches.find(fetchedBranch => fetchedBranch.id === this.loan.branchId);
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  print() {
    window.print();
  };

  pay() {
    this.confirmation(`Do you want to pay ${this.$filter('nxCurrency')(this.paymentRequest.amount)}?`, () => {
      this.command.execute(this.commandName, {
        productId: this.loan.id,
        remarks: this.remarks,
        categoryId: this.operation.categoryId,
        ...this.paymentRequest,
      }, {nxLoaderText: 'Performing operation...'})
        .success(() => {
          this.customerCache.loans(this.customerId).refetch();
          this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.customerId).refetch() : Promise.resolve();
          this.customerCache.depositAccounts(this.customerId).refetch();
          this.customerCache.loanPDCs(this.customerId, this.loan.id).refetch();
          this.redirectBack();
        }, false).offline(() => {
          this.customerCache.loans(this.customerId).refetch();
          this.redirectBack();
      });
    });
  };
}

nxModule.component('customerLoansGlPayment', {
  templateUrl,
  bindings: {
    customerId: '<',
    loan: '<',
  },
  controller: CustomerLoansGLPayment
});
