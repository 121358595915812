import { NxButtonVariant, NxFormik, NxFormikCheckbox, NxFormikInput, NxRow, NxRowPosition, NxStack, NxFormikConfirmationButton } from '@nextbank/ui-components';
import { useCommand } from 'command/CommandService';
import { CommandOutputWrapper } from 'command/CommandTypes';
import NxForm from 'form/NxForm';
import { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { SchemaOf } from 'yup';
import type { Role } from './Role';
import * as Yup from 'yup';
import NxCancelButton from 'NxCancelButton';

type Props = {
  role: Role
}

const RoleSchema: SchemaOf<Partial<Role>> = Yup.object().shape({
  name: Yup.string().required(),
  id: Yup.number().optional(),
  cashierCounter: Yup.bool().required(),
  agent: Yup.bool().required(),
  enabled: Yup.bool().required(),
});

const RoleForm = (props: Props): ReactElement => {
  const { role } = props;
  const execute = useCommand();
  const history = useHistory();

  return (
    <NxFormik<Role>
    initialValues={{
      name:role.name,
      cashierCounter:role.cashierCounter,
      agent: role.agent,
      enabled: role.enabled
    }}
    validationSchema={RoleSchema}
    onSubmit={async (input: Role): Promise<void> => {
      const response: CommandOutputWrapper<void> = await execute<Role, void>({
        name:'UpdateRole',
        input: {
          ...input,
          id: Number(role.id)
        }});

      if(!response.approvalRequired) {
        history.goBack();
      }
    }
    }>
    {(): ReactElement => {
      return <NxForm>
        <NxStack>
          <NxFormikInput label="Name" name="name" required/>
          <NxFormikCheckbox name="cashierCounter"> <label>Cashier Counter</label></NxFormikCheckbox>
          <NxFormikCheckbox name="agent"><label>Agent</label></NxFormikCheckbox>
          <NxFormikCheckbox name="enabled"><label>Enabled</label></NxFormikCheckbox>
        </NxStack>
        <NxRow position={NxRowPosition.END}>
          <NxCancelButton/>
          <NxFormikConfirmationButton
            variant={NxButtonVariant.SAVE}
            description={`Are you sure that you want to update ${role.name}?`}
          >
            Save
          </NxFormikConfirmationButton>
        </NxRow>
        </NxForm>
      }}
    </NxFormik>
  )
}

export default RoleForm
