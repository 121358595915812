import nxModule from 'nxModule';
import {addAccountLabels} from "../../general-ledger/common/gl.utils";
import _ from 'lodash';

import templateUrl from './batch-sss-pension-upload.template.html';

class BatchSssPensionUpload {
  constructor(glMappingsService, command, confirmationTemplate, $filter, popup) {
    this.glMappingsService = glMappingsService;
    this.command = command;
    this.confirmationTemplate = confirmationTemplate;
    this.$filter = $filter;
    this.popup = popup;

    // GL accounts select config
    this.selectConfig = {
      placeholder: 'Select account',
      searchField: 'label',
      valueField: 'fullCode',
      labelField: 'label',
      maxItems: 1
    };

    // GL accounts select config
    this.selectFormatConfig = {
      placeholder: 'Select file format',
      searchField: 'value',
      valueField: 'value',
      labelField: 'value',
      maxItems: 1
    };

    this.fileFormats = [
      {value: 'MAGFILE'},
      {value: 'PR1'},
    ]

    this.input = {
      fileId: null,
      accountCode: null,
      createAnniversaryHold: true,
      fileFormat: null
    };

    glMappingsService.accounts.toPromise()
      .then(glAccounts => {
        this.glAccounts = addAccountLabels(
          _.filter(glAccounts, function (glAccount) {
            return ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup);
          })
        );
    });
  }

  async submit() {
    const {output} = await this.command.execute('SSSPensionFileUploadSimulation', this.input).toPromise();
    const pensions = output.pensions;
    const errors = output.errors;

    if (!!errors) {
      this.displayParsingErrors(errors);
      return;
    }

    const allCredited = pensions.every(pension => pension.credited);
    const summary = this.prepareSummary(pensions);
    if(!allCredited) {
      this.displayCreditingErrors(summary);
      return;
    }

    const approved = await this.confirmationTemplate({
      question: 'Are you sure you want to proceed with submitting sss pension?',
      details: summary
    });

    if(!approved) {
      return;
    }

    await this.command.execute('SSSPensionFileUpload', this.input).toPromise();
    // Prevent user from deleting attached file after uploading the pension.
    this.input.fileId = null;
    this.onSubmit();
  }

  displayCreditingErrors(summary) {
    const errorDetails = summary
      .filter(summaryItem => !summaryItem.credited)
      .map(
        summaryItem => `<strong>${_.escape(summaryItem.label)}</strong> ${_.escape(summaryItem.description)}`
      )
      .join('<br/>');
    this.popup({
      header: 'Error',
      text: 'Cannot upload file due to the following errors: <br/>' + errorDetails,
      renderHtml: true
    });
  }

  displayParsingErrors(errors) {
    const errorList = Object.keys(errors)
      .map(rowNumber => {
        let rowNumberText = `<strong>${_.escape(rowNumber)}</strong> `;
        return errors[rowNumber].map(
          (details, index) => {
            let nameAndPosition = '';
            if (!!details.property) {
              nameAndPosition = `<em>${_.escape(details.property)}</em> (${_.escape(details.start + 1)} - ${_.escape(details.end)}): `;
            }
            if (index !== 0) {
              rowNumberText = '&emsp;';
            }
            return `${rowNumberText}${nameAndPosition} expected ${_.escape(details.expected)}, found ${_.escape(details.found)}`;
          }
        ).join('<br/>');
      })
      .join('<br/>');
    this.popup({
      header: 'Error',
      text: 'Cannot upload file due to errors. Fix errors and try to upload file again.<br/><br/>' + errorList,
      renderHtml: true
    });
  }

  prepareSummary(pensions) {
    const rowSummary = pensions.map(pension => {
      const {row} = pension;
      const data = [
        ['Sss number', row.sssNumber],
        ['file account number', row.accountNumber],
        ['branch id', pension.branchId],
        ['cif', pension.customerNumber],
        ['account number', pension.productNumber],
        ['amount', this.$filter('nxCurrency')(row.amount)]
      ].filter(arr => arr[1])
        .map(arr => arr.join(' '))
        .join(', ');

      if (pension.credited) {
        return {
          row: row.rowNumber,
          status: 'Successful crediting',
          data,
        };
      } else {
        return {
          row: row.rowNumber,
          status: 'Failed to credit',
          data,
          errorReason: pension.creditingError
        };
      }
    });

    const summary = rowSummary.map(summ => ({
      label: summ.row,
      description: [summ.status, summ.data, summ.errorReason].join('. '),
      credited: !summ.errorReason
    }));
    return summary;
  }
}

nxModule.component('batchSssPensionUpload', {
  templateUrl,
  bindings: {
    'onSubmit': '<'
  },
  controller: BatchSssPensionUpload
});
