import {CommandRoleMapCache} from 'components/service/access/command-role-map.cache.types';
import nxModule from 'nxModule';

import templateUrl from './role-write-access-details.template.html';
import './role-write-access-details.style.less';
import {
  AccessRule,
  AccessRuleApprovalHierarchyInput,
  AccessRuleCache,
  AccessRuleExecutionType,
  AccessRuleInput,
  CommandAccessRuleDefinition,
  CommandGroup,
  CommandPredicates,
  ExecuteOption,
  EXISTING_RULE_EXECUTE_OPTIONS,
  OverrideTypeOption,
  OVERRIDE_TYPE_OPTIONS,
  Predicates
} from "../../../../service/access/access-rule.types";
import _ from 'lodash';
import {ApprovalHierarchy} from "../../../../common/approval-hierarchy/approval-hierarchy.types";
import {Role} from "user/UserTypes";
import {HttpService} from "shared/utils/httpService";
import {ILocationService} from "angular";
import Notification from "shared/utils/notification";
import {
  AccessRuleApprovalHierarchyService
} from "components/administration/security/access-rule-approval-hierarchy/access-rule-approval-hierarchy.service";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import {Confirmation} from "shared/common/confirmation.types";

class RoleWriteAccessDetails {

  // bindings:
  private role!: Role;
  private commandGroup!: CommandGroup;
  private accessRuleDefinition!: CommandAccessRuleDefinition;
  private accessRule!: AccessRule;
  private availablePredicates!: CommandPredicates;

  private approvalHierarchy: ApprovalHierarchy[] = [];
  private executionTypes: ReadonlyArray<ExecuteOption> = EXISTING_RULE_EXECUTE_OPTIONS;
  private overrideTypes: ReadonlyArray<OverrideTypeOption> = OVERRIDE_TYPE_OPTIONS;

  constructor(private http: HttpService,
              private $location: ILocationService,
              private confirmation: Confirmation,
              private accessRuleCache: AccessRuleCache,
              private popup: Popup,
              private notification: Notification,
              private accessRuleApprovalHierarchyService: AccessRuleApprovalHierarchyService,
              private command: CommandService,
              private commandRoleMapCache: CommandRoleMapCache) {
  }

  async $onInit(): Promise<void> {
    if (this.accessRule.executionType === AccessRuleExecutionType.EXECUTE_WITH_APPROVAL_HIERARCHY) {
      this.approvalHierarchy = await this.accessRuleApprovalHierarchyService.getApprovalHierarchy(this.accessRule.id);
    }
  }

  hasAccessRule(command: string): boolean {
    if (!this.accessRuleDefinition) {
      return false;
    }
    return this.accessRuleDefinition.command === command && this.accessRuleDefinition.ruleDefinitions.length > 0;
  }

  addPredicate(rule: AccessRule, predicateName: keyof Predicates, inputDefinition?: string): void {
    if (!rule.predicates[predicateName]) {
      rule.predicates[predicateName] = {};
    }

    // if template provided the inputDefinition
    if (inputDefinition) {
      rule.predicates[predicateName][inputDefinition] = null;
      return;
    }

    const accessInputDefinitions = this.accessRuleDefinition.ruleDefinitions.filter(def => def.predicates.some(predicate => predicate.name === predicateName))
    for (let i = 0; i < accessInputDefinitions.length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!rule.predicates[predicateName].hasOwnProperty(accessInputDefinitions[i].inputName)) {
        rule.predicates[predicateName][accessInputDefinitions[i].inputName] = null;
        break;
      }
    }
  }

  isPredicatePresent(type: keyof Predicates): boolean {
    return this.accessRule.predicates[type] !== undefined;
  }

  hasInputDefinition(inputDefinition: string): boolean {
    return !_.isEmpty(this.accessRuleDefinition.ruleDefinitions.filter(rd => rd.inputName === inputDefinition));
  }

  async save(): Promise<void> {
    const proceed = await this.confirmation("Save access rule?");

    if (!proceed) return;

    if (this.accessRule.executionType === AccessRuleExecutionType.EXECUTE_WITH_APPROVAL_HIERARCHY) {

      const request: AccessRuleApprovalHierarchyInput = {
        accessRule: this.accessRule,
        approvalHierarchy: this.approvalHierarchy
      };

      await this.command.execute('UpdateAccessRuleHierarchy', request).toPromise();
      this.onSaveSuccess();

    } else {

      const request: AccessRuleInput = {
        accessRules: [this.accessRule],
        deleteRuleIds: []
      };

      const response = await this.http.post<{errorMessage?: string}>('/access', request).toPromise();

      if (_.size(response) > 0) {
        this.onSaveSuccess();
      } else {
        this.popup({text: `Unable to update access rule. Error: ${response.errorMessage}`});
      }

    }
  }

  private onSaveSuccess(): void {
    this.notification.show('Updated action successfully');
    this.accessRuleCache.evict();
    this.commandRoleMapCache.refetch();
    this.$location.path(`/admin/security/roles/${this.role.id}/access-rules/${this.commandGroup}`);
  }

  navigateBackToGroupedList(): void {
    this.$location.path(`admin/security/roles/${this.role.id}/access-rules/${this.commandGroup}`);
  }

  executionIsApprovalHierarchy(): boolean {
    return this.accessRule.executionType === 'EXECUTE_WITH_APPROVAL_HIERARCHY'
  }

}

nxModule.component('roleWriteAccessDetails', {
  templateUrl,
  controller: RoleWriteAccessDetails,
  bindings: {
    role: '<',
    accessRuleDefinition: '<',
    commandGroup: '<',
    accessRule: '<',
    availablePredicates: '<'
  }
});
