import nxModule from "nxModule";
import templateUrl from "./check-assignment-branch-list.template.html";
import {HttpService} from "shared/utils/httpService";
import {NgTableParams} from "ng-table";
import {BranchSummary} from "check/check-preparation/BranchList";
import {PageResult} from "tools/HttpTypes";
import {ILocationService} from "angular";

class CheckAssignmentBranchList {
  private tableConfig: NgTableParams<BranchSummary>;
  constructor(private http: HttpService, private $location: ILocationService) {
    this.tableConfig = new NgTableParams<BranchSummary>({
      page: 1,
      count: 25,
    }, {
      counts: [],
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: (params : NgTableParams<BranchSummary>) => this.getBranches(params)
    });
  }

  async getBranches(params: any): Promise<BranchSummary[]> {
    const pageNo = params.page() - 1;
    const pageSize = params.count();

    const response = <PageResult<BranchSummary>> await this.http.get(`/check-preparation/branches?pageNo=${pageNo}&pageSize=${pageSize}`, {nxLoaderText: 'Searching for branches...'}).toPromise();
    params.total(response.totalCount);
    return response.result;
  }

  goToCheckAssignment(branch: BranchSummary): void {
    this.$location.path(`/check-preparation/assignment/${branch.id}`);
  }
}

nxModule.component('checkAssignmentBranchList', {
  templateUrl,
  controller: CheckAssignmentBranchList
});