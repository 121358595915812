import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./branches-list.template.html');

nxModule.component('branchesList', {
  templateUrl,
  bindings: {
    detailLink: '@'
  },
  controller: function (authentication, branchService, organizationCache) {

    const self = this;
    self.branchIds = authentication.context.branchIds;

    branchService.toObservable()
      .combineLatest(organizationCache.toObservable(), (branches, organizations) =>
        branches.map(branch => {
          return Object.assign(branch, {organization: _.find(organizations, {id: branch.organizationId})});
        })
      )
      .subscribe(branches => {
        if (!self.branchIds || self.branchIds.length === 0) return [];
        self.branches = _.filter(branches, (branch) => {
          return _.includes(self.branchIds, branch.id);
        });
      });
  }
});
