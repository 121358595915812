import nxModule from 'nxModule';
import _ from 'lodash';
import * as ng from 'angular';
import templateUrl from './update-product-branch.template.html';
import {BranchService} from "../../../service/branch.service";
import {ProductDefinitionService} from "../../../service/product-definition.service";
import {Product, ProductDefinition} from "../../../service/product.types";
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";
import {PageResult} from "tools/HttpTypes";
import {NxRouteService} from "routes/NxRouteService";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";


type ProductSelectionMode = 'file' | 'ui';

interface ProductDetails extends Product {
  productGroup: string;
  productName: string;
  sourceBranch: string;
}

type ProductDescription = Partial<ProductDetails> | Pick<ProductDetails, 'productNumber'>;

class UpdateProductBranch {
  productSelectionModes: {label: string, value: ProductSelectionMode}[] = [
    {
      label: 'File',
      value: 'file'
    }, {
      label: 'Ui',
      value: 'ui'
    }
  ];

  branches: Branch[] = [];
  sourceBranch: Branch | null = null;
  targetBranch: Branch | null = null;
  productDefinitions: ProductDefinition[] = [];
  validatedBranches: boolean = false;
  productSelectionMode: ProductSelectionMode | null = null;
  productsToBeUpdated: ProductDescription[] = [];
  searchProductNumber: string = "";
  innerFile: string = '';

  get file(): string {
    return this.innerFile;
  }

  set file(text: string) {
    this.innerFile = text;

    this.productsToBeUpdated = (text ?? '')
        .trim()
        .split('\n')
        .map(line => line.trim())
        .map(productNumber => ({
          productNumber,
        }));
  }

  constructor(private http: HttpService, private $scope: ng.IScope, private $route: NxRouteService,
              private branchService: BranchService, private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService, private productDefinitionService: ProductDefinitionService,
              private popup: Popup) {
  }

  async $onInit(): Promise<void> {
    const branchP = this.branchService.toPromise();
    const prodDefP = this.productDefinitionService.toPromise();
    const [branches, productDefinitions] = await Promise.all([branchP, prodDefP]);

    this.branches = _.cloneDeep(branches);
    this.productDefinitions = _.cloneDeep(productDefinitions);
  }

  onProductSelectionChanged(): void {
    this.productsToBeUpdated = [];
  }

  async addProduct(): Promise<void> {
    if(!this.sourceBranch) {
      return;
    }

    if (this.checkIfExisting(this.searchProductNumber)) {
      this.popup({
        text: `Product number ${this.searchProductNumber} is already included in the list.`
      });

      return;
    }

    const searchCriteriaRequest = {
      productNumber: [this.searchProductNumber],
      exactProductNumber: true,
      branchId: this.sourceBranch.id
    };

    const data = <PageResult<Product>> await this.http.post(`/products/search`, searchCriteriaRequest, {nxLoaderText: 'Searching for product...'})
      .toPromise();

    if (data && data.result && data.result.length === 1) {
      this.completeDataAndAddToPool(data.result[0]);
    } else {
      this.popup({
          text: `No product found with product number [${searchCriteriaRequest.productNumber}] and source branch [${this.sourceBranch.name}].
            \n To change the source branch, please reload the page.`
        }
      );
    }
  }

  completeDataAndAddToPool(product: Product): void {
    const def = this.productDefinitions.find(definition =>
      definition.id === product.definitionId);

    const productDetails: ProductDescription = {
      productGroup: def?.productGroup,
      productName: def?.productName,
      sourceBranch: this.sourceBranch?.name,
      ...product
    };

    this.productsToBeUpdated.push(productDetails);
  }

  checkIfExisting(productNumber: string): boolean {
    return !!_.find(this.productsToBeUpdated, {
      productNumber: productNumber
    });
  }

  removeProduct(index: number): void {
    this.productsToBeUpdated.splice(index, 1);
  }

  async execute(): Promise<void> {
    if(!this.sourceBranch || !this.targetBranch) {
      console.error('Missing branches', this.sourceBranch, this.targetBranch);
      return;
    }

    const productNumbers = this.productsToBeUpdated.map(p => p.productNumber);

    const targetBranchId = this.targetBranch.id;
    const sourceBranchId = this.sourceBranch.id;

    const payload = {
      targetBranchId: targetBranchId,
      sourceBranchId: sourceBranchId,
      productNumbers: productNumbers
    };

    const confirmed = await this.confirmationTemplate({
      question: 'These products will be moved to another branch. Do you wish to proceed?',
      details: [
        {label: 'Product numbers', description: productNumbers.toString()},
        {label: 'Branch name', description: this.targetBranch.name}
      ]
    });

    if(confirmed) {
      await this.command.execute('UpdateProductBranch', payload).toPromise();
      await this.popup({
        text: `Mother branch of product(s) successfully changed to ${this.targetBranch.name}`,
      });
      this.$route.reload();
    }
  }

  downloadSample(): void {
    const sampleFileUrl = window.URL.createObjectURL(new Blob(["123-123-123\n123-123-15"],
      {type: 'text/plain'})
    );

    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = "sample-update-product-branch.txt";
    a.click();
  }

  validateBranches(): void {
    if(!this.sourceBranch || !this.targetBranch) {
      return;
    }

    if(this.sourceBranch.id == this.targetBranch.id) {
      this.popup({text: `Source branch ${this.sourceBranch.name} and target branch ${this.targetBranch.name} are the same.`});
      return;
    }

    if (this.sourceBranch.systemDate === this.targetBranch.systemDate) {
      this.validatedBranches = true;
    } else {
      this.popup({text: `Source branch [${this.sourceBranch.name} : ${this.sourceBranch.systemDate}] and target branch [${this.targetBranch.name} | ${this.targetBranch.systemDate}] should have the same system date.`});
    }
  }

  getBranchLabel(branch: Branch): string {
    return `${branch.name} : ${branch.systemDate}`;
  }
}

nxModule.component('updateProductBranch', {
  templateUrl,
  controller: UpdateProductBranch
});
