import nxModule from 'nxModule';
import templateUrl from './telephone.template.html';
import './telephone.style.less'
import PhoneNumber from 'awesome-phonenumber'

class TelephoneController {

  telephoneNumber!: string | undefined;
  disabled!: boolean;
  required!: boolean;
  uiOptions!: string;

  isNumberValid(): boolean {
    if (!this.telephoneNumber) {
      return true;
    }

    return new PhoneNumber(this.telephoneNumber).getNumber('e164') === this.telephoneNumber;
  }

  getUiOptions(): string {
    return this.uiOptions || '{clearOnBlur : false, allowInvalidValue : true}';
  }

  numberChanged(): void {
    if (this.telephoneNumber?.trim() === '') {
      this.telephoneNumber = undefined;
    }
  }
}

nxModule.component('telephone', {
  controller: TelephoneController,
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    telephoneNumber: '=ngModel',
    uiOptions: '@',
    disabled: '<ngDisabled',
    required: '<ngRequired'
  }
});
