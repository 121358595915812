import nxModule from 'nxModule';

import templateUrl from './close-term-deposit.template.html';
import {HttpService} from '../../../../shared/utils/httpService';
import {Deposit, ProductDefinition} from "../../../service/product.types";
import {ProductDefinitionService} from "../../../service/product-definition.service";
import {ILocationService} from "angular";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CustomerCache} from "components/service/customer.cache.types";
import Notification from "shared/utils/notification";

class CloseTermDeposit {

  private readonly customerId!: number;
  private readonly depositId!: number;
  private termDeposit!: Deposit;
  private productDefinition!: ProductDefinition;

  constructor(private readonly http: HttpService,
              private readonly productDefinitionService: ProductDefinitionService,
              private readonly customerCache: CustomerCache,
              private readonly command: CommandService,
              private readonly popup: Popup,
              private readonly notification: Notification,
              private readonly $location: ILocationService,
              private readonly confirmationTemplate: ConfirmationTemplate) {
  }

  async $onInit(): Promise<void> {
    const [productDefinitions, deposits] = await Promise.all([
      this.productDefinitionService.toPromise(),
      this.customerCache.termDeposits(this.customerId).toPromise()
    ]);
    this.termDeposit = deposits.find((d: Deposit) => d.id === this.depositId)!;
    this.productDefinition = productDefinitions.find((pd: ProductDefinition) => pd.id === this.termDeposit.definitionId)!;
  }

  redirectBack() : void {
    this.$location.path(`/customer/${this.termDeposit.customerId}/term-deposits/${this.depositId}`);
  }

  async close(): Promise<void> {
    const proceed = await this.confirmationTemplate({'question': `Close operation will reverse accrued net interest of: ${this.termDeposit.accruedInterestNet}. Do you wish to proceed?`});
    if(proceed) {
      await this.command.execute('CloseTermDeposit', {productId: this.depositId}).toPromise();
      this.customerCache.termDeposits(this.customerId).refetch();
      this.notification.show('Success', 'Successfully closed term deposit.');
      this.redirectBack();
    }
  }
}

nxModule.component('closeTermDeposit', {
  templateUrl,
  controller: CloseTermDeposit,
  bindings: {
    customerId: '<',
    depositId: '<'
  }
});
