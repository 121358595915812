import nxModule from 'nxModule';
import ParameterizedLocalCache from 'shared/utils/parameterized-local-cache';

nxModule.factory('branchHolidayCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (parameters) => http.post('/management/holidays', parameters ?? {}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.holiday,
    cacheName: 'branchHolidayCache'
  })
);
