import $ from 'jquery';

// inspired by: https://chromium.googlesource.com/chromium/src/third_party/+/master/blink/renderer/devtools/front_end/event_listeners/EventListenersUtils.js
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const jQueryFetcher = (node) => {
  if (!node || !(node instanceof Node))
    return {eventListeners: []};
  const jQuery = $;
  if (!jQuery || !jQuery.fn)
    return {eventListeners: []};
  const jQueryFunction = /** @type {function(!Node)} */ (jQuery);
  const data = (<any>jQuery)._data || jQuery.data;

  const eventListeners = [];
  const internalHandlers = [];

  if (typeof data === 'function') {
    const events = data(node, 'events');
    for (const type in events) {
      for (const key in events[type]) {
        const frameworkListener = events[type][key];
        if (typeof frameworkListener === 'object' || typeof frameworkListener === 'function') {
          const listener = {
            handler: frameworkListener.handler || frameworkListener,
            useCapture: true,
            passive: false,
            once: false,
            type: type,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            remove: (handler) => jQueryRemove(node, frameworkListener.selector, handler)
          };

          eventListeners.push(listener);
        }
      }
    }
    const nodeData = data(node);
    if (nodeData && typeof nodeData.handle === 'function')
      internalHandlers.push(nodeData.handle);
  }
  const entry = jQueryFunction(node)[0];
  if (entry) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const entryEvents = entry['$events'];
    for (const type in entryEvents) {
      const events = entryEvents[type];
      for (const key in events) {
        if (typeof events[key] === 'function') {
          const listener = {handler: events[key], useCapture: true, passive: false, once: false, type: type};
          // We don't support removing for old version < 1.4 of jQuery because it doesn't provide API for getting "selector".
          eventListeners.push(listener);
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (entry && entry['$handle'])
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      internalHandlers.push(entry['$handle']);
  }
  return {eventListeners: eventListeners, internalHandlers: internalHandlers};
};


/**
 * @param {string} selector
 * @param {string} type
 * @param {function()} handler
 * @this {?Object}
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const jQueryRemove = (selector, type, handler) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  if (!this || !(this instanceof Node))
    return;
    // eslint-disable-next-line
  const node = /** @type {!Node} */ (this);
  const jQuery = /** @type {?{fn,data,_data}}*/ ($);
  if (!jQuery || !jQuery.fn)
    return;
  const jQueryFunction = /** @type {function(!Node)} */ (jQuery);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  jQueryFunction(node).off(type, selector, handler);
};

window.devtoolsFrameworkEventListeners = window.devtoolsFrameworkEventListeners || [];
window.devtoolsFrameworkEventListeners.push(jQueryFetcher);
