import nxModule from 'nxModule';
import templateUrl from './batch-account-status-update.template.html';
import _ from "lodash";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import Popup from "shared/common/popup";
import {CommandService} from "shared/utils/command/command.types";
import {NxRouteService} from "routes/NxRouteService";
import {CustomerCache} from "components/service/customer.cache.types";
import {AttachedFile} from "components/service/file.service";

class BatchAccountStatusUpdate {
  private contentFile: AttachedFile[] = [];
  private readonly sampleFile = new Blob([`ACCOUNT NUMBER DORMANCY DATE
CB01-22-230889-1 2019-08-31
`], {type: 'text/plain'});

  constructor(private confirmationTemplate: ConfirmationTemplate, private command: CommandService, private $route: NxRouteService, private popup: Popup, private customerCache: CustomerCache) { }

  async execute() {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to process uploaded account?',
    });

    if(confirmed) {
      const executed = await this.command.execute<{fileId: number}, {executionErrors: Record<string, string>}>('UpdateAccountStatusToDormant', {
        fileId: this.contentFile[0].id
      }).toPromise();

      const executionErrors = executed.output.executionErrors;
      if (!_.isEmpty(executionErrors)) {
        const header = '<p><span class="red"><strong>WARNING!</strong> Accounts listed below resulted in errors and will not be updated:</span></p>';
        const body: string[][] = [];
        _.forEach(executionErrors,err => {
          body.push(Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
        });
        const errorDetails = [header, ...body].join('');
        await this.popup({
          header: 'Upload result',
          text: errorDetails,
          renderHtml: true
        });
      }

      if (this.customerCache.loadedCustomerId) {
        this.customerCache.depositAccounts(this.customerCache.loadedCustomerId).refetch();
      }
      this.$route.reload();
    }
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'BatchAccountStatusUpdate';
    a.click();
  }

}

nxModule.component('batchAccountStatusUpdate', {
  templateUrl,
  controller: BatchAccountStatusUpdate
});
