import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {AccountType} from "components/service/account.types";
import {ICacheFactoryService, IHttpService} from "angular";
import config from "../../../../../react/config";


type DepositAccountTypeService = LocalCache<unknown, AccountType[]>;
export default DepositAccountTypeService;


nxModule.factory('depositAccountTypeService', (http: IHttpService, CacheFactory: ICacheFactoryService) => {
  return new LocalCache({
    provider: (): unknown => http.get('/products/accounts/types'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositAccountTypes,
    cacheName: 'depositAccountTypes',
  });
});