import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import 'rxjs/add/operator/combineLatest';

const templateUrl = require('./cash-termination.template.html');
nxModule.component('customerTermDepositCashTermination', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, http, customerCache, productDefinitionService, confirmation,
                        notification, feeDefinitionsCache, termDepositsService, termDepositCalculator, termDepositOverrideService) {
    const that = this;
    that.termDeposit = {};
    that.depositType = null;

    const customerId = $route.current.params['customerId'];
    const depositId = parseInt($route.current.params['depositId']);

    // Interest forecast
    that.interestForecast = termDepositOverrideService.DepositInterestForecast = {};
    that.terminationAmount = null;

    that.override = termDepositOverrideService.Override = {};

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      const input = {mode: 'TERMINATION'};

      Promise.all([http.post(`/products/deposits/${depositId}/interest`, input).toPromise(),
        feeDefinitionsCache.toPromise()])
        .then(([forecast, feesDef]) => {
          //decorate fee with name
          forecast.terminationFees.forEach(f => {
            let fd = feesDef.find(fd => fd.id === f.feeDefinitionId);
            if (fd) {
              f.name = fd.feeName;
              f.bankExpense = fd.bankExpense;
              f.newAmount = f.amount;
            }
          });

          that.interestForecast = forecast;
          that.override.grossInterest = that.interestForecast.interest;
          that.override.withholdingTax = that.interestForecast.withholdingTax;
          that.override.netInterest = that.interestForecast.netInterest;
          that.interestForecast.productId = depositId;

          that.terminationAmount = that.calculateTerminationAmount();
          that.override.amount = that.terminationAmount;
        })
        .catch(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    that.calculateTerminationAmount = () => {
      let terminationAmount = new BigNumber(0)
        .plus(that.termDeposit.balance)
        .plus(that.override.netInterest);
      //subtract termination fee
      that.interestForecast.terminationFees
        .filter(f => !f.bankExpense)
        .forEach(f => {
          const amount = f.newAmount;
          terminationAmount = terminationAmount.minus(amount);
        });

      if(that.depositType.deductCreditedInterestOnPretermination) {
        terminationAmount = terminationAmount
          .minus(that.interestForecast.interestCredited)
          .plus(that.interestForecast.appliedWithholdingTax);
      }
      return terminationAmount.dp(2).toNumber();
    };

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, {id: depositId}))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
        return Object.assign(deposit, {
          "productDefinition": _.find(products, {id: deposit.definitionId})
        });
      })
      .combineLatest(termDepositsService.toObservable(), (deposit, depositTypes) => {
        that.depositType = _.find(depositTypes, {id: deposit.typeId});
        return deposit;
      }).subscribe(deposit => {
      that.termDeposit = deposit;
      that.readInterestForecast(deposit.id);
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);


    that.recalculate = () => {
      that.override.grossInterest = termDepositCalculator.recalculateGrossInterest(that.override.netInterest, that.override.withholdingTax);
      that.override.amount = that.calculateTerminationAmount();
    };

    that.terminationFeeChanged = () => {
      that.override.amount = that.calculateTerminationAmount();
    };

    that.terminate = () => {
      confirmation(`Do you want to withdraw ${$filter('nxCurrency')(that.override.amount)} and terminate deposit?`, () => {
        command.execute('CashDepositPretermination',
          termDepositOverrideService.prepareRequest({
            forecast: that.interestForecast,
            override: that.override,
            terminationAmount: that.terminationAmount,
            isOverridden: that.overrideCheckbox
          }),
          {nxLoaderText: 'Withdrawing funds...'}).success((res) => {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        });
      });
    }
  }
});
