import nxModule from 'nxModule';

const templateUrl = require('./uid-ledger.template.html');
nxModule.component('uidLedger', {
  templateUrl: templateUrl,
  bindings: {
    uidLedger: '<',
    showStatus: '<'
  },
  controller: function () {
  }
});