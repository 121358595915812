import nxModule from 'nxModule';
import _ from 'lodash';
import {v4 as uuidV4} from 'uuid';
import {NxIdempotencyKey} from 'tools/HttpHeaders';

nxModule.factory('command', function (http, popup, notification, $location, $filter, $timeout, commandRoleMapCache,
                                      config, authentication, modalPrintPreviewService, $q, loader, confirmationTemplate, userCache) {
  let service = {};

  service.getCommandDescriptorById = (commandId, skipLoader = true) => {
    return http.get(`/command/${commandId}/descriptor`, {nxLoaderSkip: skipLoader}).toPromise();
  };

  service.readByCriteria = params => {
    return http.post(`/command/search`, params).toPromise();
  }

  service.defaultErrorCallback = (commandName, data, cb) => {
    let details = data.errorMessage
      ? data.errorMessage.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      : "An unknown error occurred.";
    let message = `<strong>Error executing:&nbsp;</strong>'${commandName}'.`;

    if (details) message += `<br><br><strong>Details:&nbsp;</strong>${details}`;
    let finePrint;
    if (data.commandId || data.requestUuid) {
      finePrint = 'Error code: ';
      if (data.commandId) finePrint += data.commandId;
      if (data.commandId && data.requestUuid) finePrint += '/';
      if (data.requestUuid) finePrint += `${data.requestUuid.substring(data.requestUuid.length - 8)}`;
    }
    popup({text: message, renderHtml: true, finePrint: finePrint, callback: cb});
  };

  service.pollForCommand = commandId => {
    const loaderId = loader.show(config.nxLoaderText);
    // poll for command status
    const registerTimeout = (checkCommand, loaderId) => {
      $timeout(() => {
        checkCommand(loaderId);
      }, config.asyncCommandPollingInterval);
    };

    return new Promise((resolve, reject) => {
      const checkCommand = (loaderId) => {
        http.get(`/command/${commandId}/descriptor?includePrints=true`, {nxLoaderSkip: true}).success(descriptorResponse => {
          if (descriptorResponse.status === 'EXECUTED') {
            const wrapper = {
              commandId: descriptorResponse.id,
              output: descriptorResponse.outputObject,
              approvalRequired: false,
              prints: descriptorResponse.prints
            };
            resolve(wrapper);
            loader.dismiss(loaderId);
          } else if (descriptorResponse.status === 'FAILED') {
            reject(descriptorResponse.errorResponse);
            loader.dismiss(loaderId);
          } else if (['PENDING', 'EXECUTING'].includes(descriptorResponse.status)) {
            registerTimeout(checkCommand, loaderId);
          } else {
            console.error(`Unknown command descriptor status: ${descriptorResponse.status}"`);
            notification.show(`Could not execute action: ${commandId}. Please contact Nextbank support.`, 60000);
            loader.dismiss(loaderId);
          }
        }).error(() => registerTimeout(checkCommand, loaderId));
      };

      registerTimeout(checkCommand, loaderId);
    });
  };

  /**
   * Executes the command specified as parameter.
   *
   * By default the following callbacks are attached:
   * - on successful command execution -> shows OK notification
   * - on error -> shows error notification
   * - when offline or received timeout -> shows information about offline status
   * - when command needs approval -> notification + redirect to actions/sent-by-me
   *
   * When additional callbacks are attached, the default callbacks are still executed
   * *unless* overrideDefault = true is passed in as 2nd parameter.
   */
  service.execute = (command, request, arg) => {
    let successCallbacks = [];
    let approvalCallbacks = [];
    let errorCallbacks = [];
    let alwaysCallbacks = [];
    let offlineCallbacks = [];

    let commandName = $filter('startCase')(command);

    let defaultSuccessCallback = async (response) => {
      notification.show(`Successfully executed: ${commandName}`);
    };

    let defaultErrorCallback = (...args) => service.defaultErrorCallback(commandName, ...args);
    let defaultApprovalCallback = () => {
      popup({
        text: `Operation '${commandName}' requires approval. Once the task is approved, the operation will be processed automatically.`,
        callback: () => $location.path('/dashboard/actions/sent-by-me')
      });
    };

    let defaultOfflineCallback = () => {
      let details = 'Connectivity error. Please check your internet connection.';
      let message = `<strong>Error executing:&nbsp;</strong>${commandName}.`;
      message += `<br><br><strong>Details:&nbsp;</strong>${details}`;

      popup({text: message, renderHtml: true});
    };

    console.debug('Executing', command);
    const idempotencyKey = uuidV4();

    new Promise((resolve) => {
      if (request.productId) {
          service.readByCriteria({
            page: {
              pageNo: 0,
              pageSize: 5
            },
            productId: request.productId,
            statuses: ['PENDING_OTP', 'PENDING_APPROVAL']
          })
          .then(commands => resolve(commands));
      } else {
        resolve({totalCount: 0})
      }
    }).then((commands) => {
      if (commands.totalCount > 0) {
        return userCache.withParam().toPromise().then(users => {
          const details = commands.result.map(c => {
            const effectiveName = users.find(u => u.id === c.executedBy).effectiveName;
            return {
              label: $filter('translateEnum')(c.simpleName, 'COMMAND'),
              description: `registered on ${$filter('prettyDateTime')(c.registrationTime)} by user ${effectiveName}`
            }
          });
          return confirmationTemplate({
            question: `There are already ${commands.totalCount} pending actions for this product. Do you want to continue?`,
            details: details
          });
        })
      } else {
        return true;
      }
    }).then(confirmed => {
      if (confirmed) {
        return http.post(`/command/${command}`, request, {
          ...arg,
          headers: {
            ...arg?.headers,
            [NxIdempotencyKey]: idempotencyKey
          }
        }).success(response => {
          // append always callbacks
          successCallbacks = successCallbacks.concat(alwaysCallbacks);
          errorCallbacks = errorCallbacks.concat(alwaysCallbacks);

          if (response.approvalRequired) {
            if (defaultApprovalCallback) defaultApprovalCallback(response);
            processCallbacks(approvalCallbacks, response);
          } else if (response.executionMode === 'ASYNC') {
            service.pollForCommand(response.commandId)
              .then(wrapper => processSuccessCallbacks(defaultSuccessCallback, wrapper, successCallbacks),
                errResponse => processErrorCallbacks(defaultErrorCallback, errResponse, errorCallbacks));
          } else {
            processSuccessCallbacks(defaultSuccessCallback, response, successCallbacks);
          }
        }).error(response => {
          if (!response) {
            // offline
            if (defaultOfflineCallback) defaultOfflineCallback(response);
            processCallbacks(offlineCallbacks, response);
          } else {
            // plain old error
            processErrorCallbacks(defaultErrorCallback, response, errorCallbacks);
          }
        });
      }
    });

    return {
      /**
       *  @deprecated prefer toPromise()
       */
      success: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultSuccessCallback = null;
        successCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      approval: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultApprovalCallback = null;
        approvalCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      error: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultErrorCallback = null;
        errorCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      always: function (callback) {
        alwaysCallbacks.push(callback);
        return this;
      },
      /**
       *  @deprecated prefer toPromise()
       */
      offline: function (callback, overrideDefault = false) {
        if (overrideDefault) defaultOfflineCallback = null;
        offlineCallbacks.push(callback);
        return this;
      },
      toPromise: function (preventDefaults = false) {
        return new $q((resolve, reject) => {

          if (preventDefaults) {
            defaultSuccessCallback = null;
            defaultOfflineCallback = null;
            defaultApprovalCallback = null;
          }

          successCallbacks.push((response) => resolve(response));
          approvalCallbacks.push((response) => resolve(response));
          errorCallbacks.push((response) => reject(response));
          offlineCallbacks.push((response) => reject(response));
        });
      }
    }
  };

  function processCallbacks(callbacks, arg) {
    _.each(callbacks, function (cb) {
      cb(arg);
    })
  }

  let printCallback = async (response) => {
    if (response.prints) {
      for (const print of response.prints) {
        await handlePrintInput(print);
        await modalPrintPreviewService.showAsync({
          printDescription: {
            code: print.code,
            parameters: print.parameters
          },
          printProviderInput: print.input
        });
      }
    }
  };

  async function handlePrintInput(print) {
    // If command is ReprintDepositPassbook, we use operationId exactly as command input, don't change anything
    if (print.input.commandId) {
      const descriptor = await service.getCommandDescriptorById(print.input.commandId, true);
      if (descriptor.simpleName === 'ReprintDepositPassbook') {
        return;
      }
    }

    // If printing passbook, include all unprinted operations instead of just recently executed operation only
    if (['DEPOSIT_ACCOUNT_PASSBOOK', 'TERM_DEPOSIT_PASSBOOK'].includes(print.code)) {
      print.input.operationIds = await http.get(`/print/${print.input.productId}/operation/unprinted`).toPromise();
    }
  }

  async function processSuccessCallbacks(defaultSuccessCallback, response, successCallbacks) {
    await printCallback(response);

    if (defaultSuccessCallback) {
      defaultSuccessCallback(response)
        .then(() => processCallbacks(successCallbacks, response));
    } else {
      processCallbacks(successCallbacks, response);
    }
  }

  function processErrorCallbacks(defaultErrorCallback, response, errorCallbacks) {
    if (defaultErrorCallback) defaultErrorCallback(response);
    processCallbacks(errorCallbacks, response);
  }


  return service;
});

