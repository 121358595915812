import nxModule from 'nxModule';
import _ from 'lodash';

export const flattenSubAccounts = (roots) =>
  _.flatten(roots.map(root => [root, ...(flattenSubAccounts(root.subAccounts || []))]));


const createDomTemplate = () => {
  const div = document.createElement('div');
  div.className = 'account-options';
  const span1 = document.createElement('span');
  span1.className = 'account-group label label-inverse';
  const span2 = document.createElement('span');
  span2.className = 'account-name';
  const span3 = document.createElement('span');
  span3.className = 'account-fullcode';

  div.appendChild(span1);
  div.appendChild(span2);
  div.appendChild(span3);
  return div;
};

const domItemTemplate = createDomTemplate();


const templateUrl = require('./account-select.template.html');
/**
 * Some people say this component is ok. Do not trust them. It's a trap.
 * The magical ugly timewaste starts as you experiment with valueField and queryField.
 * 'ValueField' - as they name `clearly` implies, is an id of an object you pass in 'options' binding.
 * 'QueryField' on the other hand is a fieldName you use for your model in ngModel. Yes, you read that right!
 * If you pass object in ngModel {account: ..}, then queryField can extract value from that field  for you
 * when you pass in queryField 'account'.
 * So, in the end, 'valueField' extracts something from options and 'queryField' from ngModel. ☹️
 */

nxModule.component('accountSelect', {
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    options: '<',
    valueField: '<',
    queryField: '<',
    labelField: '<',
    fieldMapping: '<',
    ngChange: '&',
    ngDisabled: '<'
  },
  controller: function($scope, $attrs) {
    const self = this;
    self.$attrs = $attrs;

    if (!this.queryField) this.queryField = this.valueField;

    const complexRenderer = mapping => (item, escape) => {
      const copy = domItemTemplate.cloneNode(true);
      if(mapping.accountGroup) {
        copy.querySelector('.account-group').innerHTML = escape(item[mapping.accountGroup].substring(0, mapping.accountGroupMaxLength));
      }

      if(mapping.name) {
        copy.querySelector('.account-name').innerHTML = escape(item[mapping.name]);
      }

      if(mapping.fullCode) {
        copy.querySelector('.account-fullcode').innerHTML = `(${escape(item[mapping.fullCode])})`;
      }

      return copy;
    };
//
//    const simpleRenderer = (item, escape) => {
//      let s = "<div>";
//      if (item.accountGroup) s += `[${escape(item.accountGroup.substring(0,2))}] `;
//      s += `${escape(item.name)} (${escape(item.fullCode)})</div>`;
//      return s;
//    };

    this.fieldMapping = this.fieldMapping || {
      accountGroup: 'accountGroup',
      accountGroupMaxLength: 2,
      name: 'name',
      fullCode: 'fullCode',
    };

    const renderer = this.labelField ? undefined : complexRenderer(this.fieldMapping);

    let selectizeElement = null;
    self.config = {
      placeholder: 'Select account',
      searchField: ['name', 'fullCode'],
      valueField: this.valueField,
      labelField: this.labelField,
      maxOptions: 1,
      maxItems: 1,
      render: {
        item: complexRenderer(this.fieldMapping),
        option: renderer,
      },
      onChange: (value) => {
        if(self.ngChange) {
          self.ngChange({value,});
        }
      },
      onBlur: () => {
        self.ngModel.$setTouched();
      },
      onDropdownOpen: () => {
        if(self.config.maxOptions <= 1) {
          selectizeElement.settings.maxOptions = 100000;
          selectizeElement.refreshOptions();
        }
      },
      onDropdownClose: () => {
        selectizeElement.settings.maxOptions = self.config.maxOptions;
        selectizeElement.refreshOptions(false);
      },
      onInitialize: (selectize) => {
        selectizeElement = selectize;
      }
    };

    self.innerModel = '';

    self.$onInit = () => {
      self.ngModel.$render = () => {
        self.innerModel = _.get(self.ngModel.$viewValue, this.queryField);
      };
    };

    $scope.$on('updateInnerModelByQueryFieldValue', () => {
      self.innerModel = self.ngModel.$viewValue[self.queryField];
    });

    $scope.$watch('$ctrl.innerModel', () => {
      if (self.innerModel) {
        if (this.queryField !== this.valueField) {
          const targetOptionId = self.innerModel;
          self.ngModel.$viewValue[this.queryField] = targetOptionId;
          self.ngModel.$setViewValue(self.ngModel.$viewValue);
        } else {
          const targetOptionId = self.innerModel;
          const selectedOption = _.find(self.options, option => String(option[this.valueField]) === String(targetOptionId));
          self.ngModel.$setViewValue(selectedOption);
        }
      } else {
          if (this.queryField !== this.valueField) {
              self.ngModel.$viewValue[this.queryField] = undefined;
              self.ngModel.$setViewValue(self.ngModel.$viewValue);
          } else {
              self.ngModel.$setViewValue(undefined);
          }
      }
    });

  }
});
