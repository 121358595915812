export type CasaClassCode = {
  value: string,
  label: string
}

const CASA_CLASS_CODES: ReadonlyArray<CasaClassCode> = Object.freeze([
      {
        value: 'NATIONAL_GOVERNMENT',
        label: 'National Government'
      },
      {
        value: 'LGU',
        label: 'Lgu'
      },
      {
        value: 'GOCCS_SOCIAL_SECURITY_INSTITUTIONS',
        label: 'Goccs Social Security Institutions'
      },
      {
        value: 'GOCCS_OTHER_FINANCIAL',
        label: 'Goccs Other Financial'
      },
      {
        value: 'GOCCS_NON_FINANCIAL',
        label: 'Goccs Non Financial'
      },
      {
        value: 'UBS_KBS_GOVERNMENT_BANKS',
        label: 'Ubs kbs Government Banks'
      },
      {
        value: 'UBS_KBS_NON_GOVERNMENT_BANKS',
        label: 'Ubs kbs Non Government Banks'
      },
      {
        value: 'OTHER_BANKS',
        label: 'Other Banks'
      },
      {
        value: 'PRIVATE_CORP_FINANCIAL',
        label: 'Private Corp Financial'
      },
      {
        value: 'PRIVATE_CORP_NON_FINANCIAL',
        label: 'Private Corp Non Financial'
      },
      {
        value: 'INDIVIDUALS',
        label: 'Individuals'
      },
      {
        value: 'TRUST_DEPARTMENT',
        label: 'Trust Department'
      }
]);

export default CASA_CLASS_CODES;