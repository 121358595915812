export enum LoanPDCProcessStatus {
  REGISTERED = 'REGISTERED',
  POSTED = 'POSTED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}

export enum LoanPDCType {
  OUTWARD = 'OUTWARD',
  ON_US = 'ON_US'
}

export enum LoanPDCStatus {
  POSTED = 'POSTED',
  PENDING_CLEARING = 'PENDING_CLEARING',
  RETURNED = 'RETURNED',
  REJECTED = 'REJECTED',
  UNPOSTED = 'UNPOSTED',
  DUE = 'DUE',
  OVERDUE = 'OVERDUE',
  CANCELED = 'CANCELED',
  POSTPONED = 'POSTPONED',
  ERROR = 'ERROR'
}

export interface LoanPdc {
  id: number,
  branchId: number,
  loanId: number,
  bankId: number;
  amortizationId: number,
  processStatus: LoanPDCProcessStatus,
  type: LoanPDCType,
  amount: number,
  validFrom: string,
  micrNumber: string,
  depositOperationId: number;
  registeredOn: string,
  registeredBy: number,
  updatedOn: string;
  updatedBy: number;
  postedOn: string,
  postedBy: number,
  rejectedOn: string,
  rejectedBy: number,
  canceledOn: string,
  canceledBy: number,
  postponedToDate: string;
  postponedBy: number;
  postponedOn: string;
  remarks: string;
  checkId: number
}

export interface LoanPDCDetails extends LoanPdc {
  status: LoanPDCStatus,
  clearedOn: string,
  productNumber: string,
  productName: string,
  customerId: number,
  customerName: string,
  amortizationDueDate: string,
  amortizationNo: number,
  productDefinitionId: number,
  loanTypeId: number,
  bankName: string;
  encashmentOperationId: number;
  officialReceiptNumber: string;
  interestAmount: number;
  principalAmount: number;
  outstandingBalanceAfter: number;
}
