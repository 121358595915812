import nxModule from "nxModule";

export class OperationCodeFormatter {

  private readonly APPLY_FEE_CUSTOM_PREFIX = 'APPLY_FEE_CUSTOM';

  format(operation: any, productId?: number ): string {
    const code = !productId || operation.target.id === productId ? operation.targetCode : operation.sourceCode;
    switch (operation.operationGroup) {
      case 'APPLY_FEE':
      case 'ACCRETE_CHARGE':
        return this.formatApplyFeeCode(code, operation)
      default:
        return code;
    }
  }

  isCustomFeeApplyOnOperation(code: string): boolean {
    return code.startsWith(this.APPLY_FEE_CUSTOM_PREFIX);
  }

  private formatApplyFeeCode(code: string, operation: any): string {
    const customFeeOperationCodePrefix = `${this.APPLY_FEE_CUSTOM_PREFIX}_${operation.feeApplyOn}`;
    if (code.startsWith(customFeeOperationCodePrefix)) {
      return customFeeOperationCodePrefix;
    }
    return code;
  }
}


nxModule.service('operationCodeFormatter', OperationCodeFormatter);
