import {
    NxButtonVariant,
    NxFormik,
    NxFormikButton,
    NxFormikSubmitButton,
    NxFormikCashInput,
    NxFormikInput,
    NxRow,
    NxRowPosition,
    NxStack,
    NxLoader, NxFormikAutocomplete, NxAutocompleteDataProviderOutput
} from '@nextbank/ui-components';
import useAxios from 'axios-hooks';
import BigNumber from 'bignumber.js';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import HttpErrorHandler from 'misc-transaction/encash-cashiers-check/HttpErrorHandler';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement} from 'react';
import {useHistory, useParams} from 'react-router';
import systemPropertyService from 'system/systemPropertyService';
import CurrencyFormatter from 'tools/CurrencyFormatter';
import {HttpError} from 'tools/HttpTypes';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';

interface InstapayTransferFormInput {
  externalBank: ExternalFinancialInstitution;
  amount: number;
  externalAccountNumber: string;
  externalCustomerName: string;
  remarks: string;
}

interface InstapayTransferInput{
  externalBankCode: string;
  productId: number;
  amount: number|null;
  externalAccountNumber: string;
  externalCustomerName: string;
  remarks: string;
}

interface ExternalFinancialInstitution {
  name: string,
  code: string,
  channel: 'INSTAPAY';
  enabled: boolean
}

const InstapayTransfer = (): ReactElement => {
  const pageHeader = 'Transfer money via InstaPay'
  const {customerId} = useParams<{customerId: string}>();
  const {accountId} = useParams<{accountId: string}>();
  const history = useHistory();
  const execute = useCommand();
  const maxTransferAmount = systemPropertyService.getPropertyOrError('INSTAPAY_OUTGOING_TRANSFER_LIMIT');

  const transferLimit = new BigNumber(maxTransferAmount).toNumber();
  const InstapayTransferInputSchema: SchemaOf<Partial<InstapayTransferFormInput>> = Yup.object().shape({
    externalBank: Yup.mixed().required("Instapay Bank is required"),
    amount: Yup.number()
      .required("Amount is required")
      .positive()
      .max(transferLimit, "Must not exceed transfer limit of " + new CurrencyFormatter().format(transferLimit)),
    externalAccountNumber: Yup.string().required("Recipient account required").max(34),
    externalCustomerName: Yup.string().required("Recipient name is required").max(140),
    remarks: Yup.string().required("Remarks are required").max(35),
  });

  const [{data: instapayBankOptions, loading, error}] = useAxios<ExternalFinancialInstitution[], HttpError>('/external-transfer/institutions?channel=INSTAPAY');

  if(error){
    return <HttpErrorHandler error={error} pageHeader={pageHeader} />
  }

  if (loading || !instapayBankOptions) {
    return <NxPage><NxHeader>{pageHeader}</NxHeader><NxLoader /></NxPage>;
  }

  const instapayMemberProvider = async(text: string) : Promise<NxAutocompleteDataProviderOutput<ExternalFinancialInstitution>> => {
    return Promise.resolve({
      values: instapayBankOptions.filter((str) => {
        return str.name.toLowerCase().includes(text.toLowerCase());
      })
    });
  }

  return <NxPage>
    <NxHeader>
    {pageHeader}
    </NxHeader>
    <NxFormik<Partial<InstapayTransferFormInput>>
      initialValues={{
        externalAccountNumber: '',
        externalCustomerName: '',
        remarks: "InstaPay transfer"
      }}
      validationSchema={InstapayTransferInputSchema}
      onSubmit={async (values: InstapayTransferFormInput): Promise<void> => {
          const request = {
            name:'OutgoingInstapayTransfer',
            input: {
              externalBankCode: values.externalBank.code,
              productId: Number(accountId),
              amount: values.amount,
              externalAccountNumber: values.externalAccountNumber,
              externalCustomerName: values.externalCustomerName,
              remarks: values.remarks
          }};
          const response: CommandOutputWrapper<void> = await execute<InstapayTransferInput, void>(request);

          if(!response.approvalRequired) {
            history.push(`/customer/${customerId}/accounts/${accountId}`);
          }
        }
      }>
      {(): ReactElement => (
        <NxForm>
          <NxStack>
            <NxFormikCashInput label="Amount" name="amount"/>
            <NxFormikAutocomplete
              name='externalBank'
              label='Instapay Banks'
              dataProvider={instapayMemberProvider}
              labelProvider={(instapayMember: ExternalFinancialInstitution): string => instapayMember.name}/>
            <NxFormikInput label="Recipient account number" name="externalAccountNumber" />
            <NxFormikInput label="Recipient account name" name="externalCustomerName" />
            <NxFormikInput label="Remarks" name="remarks" />
            <NxRow position={NxRowPosition.END}>
              <NxCancelButton />
              <NxFormikSubmitButton
                variant={NxButtonVariant.ADD}>
                Create
              </NxFormikSubmitButton>
            </NxRow>
          </NxStack>
        </NxForm>
      )}
    </NxFormik>
  </NxPage>;
};

export default InstapayTransfer;