import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {listRoute} from 'custom-field/CustomFieldRoutes';
import LinkList, {LinkListRoute} from 'LinkList';
import React, {ReactElement} from 'react';

const links: LinkListRoute[] = [
  {
    name: 'Types',
    path: '/admin/loan-products/types'
  },
  listRoute('/admin/loan-products', CustomFieldGroup.LOAN, false),
  {
    name: 'Mis groups',
    path: '/admin/loan-products/mis-groups'
  },
  {
    name: 'Payment intervals',
    path: '/admin/loan-products/intervals',
    commandAccess: ['EditPaymentIntervalDefinition', 'CreatePaymentIntervalDefinition', 'DisablePaymentIntervalDefinition']
  },
  {
    name: 'Letter types',
    path: '/admin/loan-products/letters'
  }
];

const LoansConfiguration = () : ReactElement => <LinkList links={links} header="Loans configuration"/>;

export default LoansConfiguration;