import {feeCollectionScheduleOptions} from 'components/service/fees/fee.types';
import nxModule from 'nxModule';
import {
  advanceInterestApplicationTypes,
  advancePaymentStrategies,
  amortizationHooks,
  pastDueInterestChargeTypes,
  pdiChargeCollectionTypes
} from 'constants/loan';
import {flattenSubAccounts} from 'components/general-ledger/common/gl.utils';
import _ from 'lodash';
import systemPropertyService from '../../../../../../react/system/systemPropertyService';
import {InterestCalculationMethod, InterestType} from 'components/service/loan-type.types';
import {balloonDiminishingAmortizationTypes, balloonCyclicAmortizationTypes} from 'constants/loan';

const templateUrl = require('./loan-product-amortization.template.html');

const overPaymentStrategy = Object.freeze([
  {
    label: 'Credit to GL',
    value: 'CREDIT_TO_GL'
  },
  {
    label: 'Credit to contractual savings',
    value: 'CREDIT_TO_CBU'
  }
]);


nxModule.component('loanProductAmortizationForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '='
  },
  controller: function ($scope, $timeout, $filter, authentication, glLedgerService, glAccountService, http) {
    const that = this;

    that.overPaymentStrategy = overPaymentStrategy;

    this.advanceInterestApplicationTypes = advanceInterestApplicationTypes;
    that.balloonCyclicAmortizationTypes = balloonCyclicAmortizationTypes;

    this.recalculatePenaltyOptions = [{
      label: 'Return check',
      value: 'RETURN_CHECK'
    }, {
      label: 'Revert to GL',
      value: 'REVERT_TO_GL'
    }];

    this.interestCalculationMethods = [{
      label: 'Simple',
      value: 'SIMPLE'
    }, {
      label: 'Compound',
      value: 'COMPOUND'
    }];

    this.simpleAmortizationTypes = [{
        label: 'Straight',
        value: 'STRAIGHT'
      }, {
        label: 'Balloon',
        value: 'BALLOON'
      }, {
        label: 'Balloon with exact days',
        value: 'BALLOON_EXACT_DAYS'
      }, {
        label: 'Balloon (cyclic)',
        value: 'BALLOON_CYCLIC'
      }, {
        label: 'Balloon (cyclic) with exact days',
        value: 'BALLOON_CYCLIC_EXACT_DAYS'
      }, {
        label: 'Balloon (spread)',
        value: 'BALLOON_SPREAD'
      }
    ];

    this.addOnRateSimpleAmortizationTypes = this.simpleAmortizationTypes.concat([
      {
        label: 'Diminishing w/ equal amortization',
        value: 'DIMINISHING_EQUAL_AMORTIZATION'
      }
    ]);

    this.roundingScales = [{
      label: 'Centavo',
      value: 'CENTAVO'
    }, {
      label: 'Half Peso',
      value: 'HALF_PESO'
    }, {
      label: 'One Peso',
      value: 'ONE_PESO'
    }];

    this.roundingModes = [{
      label: 'Round up',
      value: 'ROUND_UP'
    }, {
      label: 'Round down',
      value: 'ROUND_DOWN'
    }, {
      label: 'Half up',
      value: 'HALF_UP'
    }, {
      label: 'Half even',
      value: 'HALF_EVEN'
    }];

    this.roundingDifferenceTargets = [{
      label: 'Principal',
      value: 'PRINCIPAL'
    }, {
      label: 'Interest',
      value: 'INTEREST'
    }];

    this.straightAmortizationTypes = [{
      label: 'Straight',
      value: 'STRAIGHT'
    }];

    this.compoundAmortizationTypes = [{
      label: 'Balloon (cyclic)',
      value: 'BALLOON_CYCLIC'
    }, {
      label: 'Balloon (spread)',
      value: 'BALLOON_SPREAD'
    }, {
      label: 'Balloon + Diminishing w/ equal amortization',
      value: 'BALLOON_DIMINISHING_EQUAL_AMORTIZATION'
    }, {
      label: 'Balloon + Diminishing w/ equal amortization exact days',
      value: 'BALLOON_DIMINISHING_EQUAL_AMORTIZATION_EXACT_DAYS'
    }, {
      label: 'Diminishing w/ equal amortization',
      value: 'DIMINISHING_EQUAL_AMORTIZATION'
    }, {
      label: 'Diminishing w/ equal amortization exact days',
      value: 'DIMINISHING_EQUAL_AMORTIZATION_EXACT_DAYS'
    }, {
      label: 'Diminishing w/ equal principal',
      value: 'DIMINISHING_EQUAL_PRINCIPAL'
    }];

    this.paymentDirections = [{
      label: 'Horizontal',
      value: 'HORIZONTAL'
    }, {
      label: 'Vertical',
      value: 'VERTICAL'
    }];

    this.amortizationDivisors = [{
      label: '100',
      value: 100
    }, {
      label: '357',
      value: 357
    }, {
      label: '360',
      value: 360
    }, {
      label: '364',
      value: 364
    }, {
      label: '365',
      value: 365
    }];

    this.accrualPeriod = [{
      label: 'Daily',
      value: 'DAILY'
    }, {
      label: 'Monthly',
      value: 'MONTHLY'
    }];

    this.amortizationAdjustmentStrategy = [
      {
        label: 'Keep the date',
        value: 'KEEP_DATE'
      },
      {
        label: 'Set to previous working day',
        value: 'PREVIOUS_WORKING_DAY'
      },
      {
        label: 'Set to next working day',
        value: 'NEXT_WORKING_DAY'
      }
    ];

    this.asEarnedInterestAccrualPeriodOptions = [{
      label: 'Daily',
      value: 'DAILY'
    }, {
      label: 'Every last calendar day of the month',
      value: 'MONTH_END'
    }];

    this.pdiChargeCollectionTypes = pdiChargeCollectionTypes
    this.penaltyApplicationTypes = ['ALL_AMORTIZATIONS', 'OLDEST_AMORTIZATION'];
    this.advancePaymentStrategy = advancePaymentStrategies;
    this.updateAdvancePaymentStrategries = () => {
      const withLinkedDepositAccount = this.loanProduct?.withLinkedDepositAccount;
      this.advancePaymentStrategy = withLinkedDepositAccount ? advancePaymentStrategies : advancePaymentStrategies.filter(s =>
        s.value !== 'TRANSFER_TO_LINKED_ACCOUNT'
      );

      this.userSelectStrategy = this.advancePaymentStrategy.filter(s => s.value !== 'USER_SELECT');
    };

    this.updateAdvancePaymentStrategries();

    this.interestTypes = Object.values(InterestType).map(v => {
      return {
        'label': $filter('prettyEnum')(v),
        'value': v
      }
    });

    this.amortizationHooks = amortizationHooks;
    this.feeCollectionScheduleOptions = feeCollectionScheduleOptions;

    that.$onInit = async () => {
      await loadOptions();
      this.loanMaxPenaltyGracePeriod = systemPropertyService.getProperty('LOAN_MAX_PENALTY_GRACE_PERIOD');
    };

    async function loadOptions() {
      const ledgers = await glLedgerService.toPromise();
      const branchId = authentication.context.branchId;
      const ledgersInLoanBranch = ledgers.filter(ledger => ledger.branchId === branchId);
      const accounts = _.flatten(await Promise.all(ledgersInLoanBranch
        .map(ledger => glAccountService.fetchAccounts(ledger.id, {
          leafOnly: true
        }))
        .map(httpRes => httpRes.toPromise())
      ));

      that.glAccounts = flattenSubAccounts(accounts);
      that.intervalTypes = await http.get(`/products/loans/intervals`).toPromise();
    }


    // charge types available for past due interest
    this.pastDueInterestChargeTypes = pastDueInterestChargeTypes;

    this.setDefaultInterestCalculationMethod = () => {
      this.loanProduct.defaultInterestCalculationParameter = null;
      this.loanProduct.asEarnedPreterminationInterestCalculation = false;
      this.loanProduct.asEarnedInterestCalculation = false;
      this.loanProduct.collectAdvanceInterest = false;
      this.loanProduct.amortizationType = null;
    };

    this.shouldShowRoundingDifferenceTarget = () => {
      return 'CENTAVO' !== this.loanProduct.totalAmortizationRoundingScale
        || ['DIMINISHING_EQUAL_AMORTIZATION',
            'DIMINISHING_EQUAL_AMORTIZATION_EXACT_DAYS',
          ...balloonDiminishingAmortizationTypes].includes(this.loanProduct.amortizationType);
    };

    this.isOverridden = (amortizationPart) => {
      return this.shouldShowRoundingDifferenceTarget()
        && this.loanProduct.roundingDifferenceTarget === amortizationPart;
    };

    function addEvents(bag) {
      $scope.$on(bag + '.drag', function (e, el) {
        el.removeClass('ex-moved');
      });

      $scope.$on(bag + '.drop', function (e, el) {
        that.form.$setDirty();
        el.addClass('ex-moved');
      });

      $scope.$on(bag + '.over', function (e, el, container) {
        container.addClass('ex-over');
      });

      $scope.$on(bag + '.out', function (e, el, container) {
        container.removeClass('ex-over');
      });
    }

    $timeout(() => {
      addEvents('dueHierarchy');
      addEvents('advanceHierarchy');
    });

    this.conditionalWeeksInYearReset = () => {
      if (!(this.hasWeeklyAmortizationOption()) || !this.overrideWeeksInYear) {
        this.overrideWeeksInYear = false;
        this.loanProduct.weeksInYear = undefined;
      }
    };

    this.hasWeeklyAmortizationOption = () => {
      if (!this.intervalTypes) {
        return false;
      }
      const currentOptions = this.intervalTypes.filter(i => this.loanProduct.paymentIntervalOptions.includes(i.id));
      return currentOptions.some(i => i.basePaymentInterval === 'WEEK')
    }

    this.checkAmortizationAdjustment = () => {
      if (this.loanProduct.holidayAmortizationAdjustment !== 'NEXT_WORKING_DAY') {
        this.loanProduct.moveDuplicatedAmortizationHoliday = false;
      }
      if (this.loanProduct.saturdayAmortizationAdjustment !== 'NEXT_WORKING_DAY') {
        this.loanProduct.moveDuplicatedAmortizationSaturday = false;
      }
      if (this.loanProduct.sundayAmortizationAdjustment !== 'NEXT_WORKING_DAY') {
        this.loanProduct.moveDuplicatedAmortizationSunday = false;
      }
    };

    this.refreshRecalculatePenaltyOn = () => {
      // Reset values on 'No'
      if (!this.loanProduct.recalculatePenalty) {
        this.loanProduct.recalculatePenaltyOn = null;
      }

      // Set default values on 'Yes'
      if (this.loanProduct.recalculatePenalty && this.loanProduct.recalculatePenaltyOn == null) {
        this.loanProduct.recalculatePenaltyOn = ['RETURN_CHECK', 'REVERT_TO_GL'];
      }
    };

    this.updateAdvanceInterest = () => {
      if(this.loanProduct.collectAdvanceInterest) {
        this.loanProduct.advanceInterest = {
          maxNo: 0,
          defaultNo: 0
        };
      } else {
        this.loanProduct.advanceInterest = null;
      }
    };

    this.resetPastDueInterest = () => {
      if(this.loanProduct.defaultPastDueToInterestRate) {
        this.loanProduct.pastDueInterestCharge.rate = 0;
      }
    };

    this.resetPastDueMaturityInterest = () => {
      if(this.loanProduct.defaultPastDueMaturityToInterestRate) {
        this.loanProduct.pastDueMaturityInterestCharge.rate = 0;
      }
    };

    this.isAsEarnedAdvanceInterestValid = () => {
      return !(this.loanProduct.asEarnedInterestCalculation && this.loanProduct.collectAdvanceInterest);
    };

    this.isAsEarnedInterestPeriodValid = () => {
      return !this.loanProduct.asEarnedInterestCalculation
        || this.loanProduct.asEarnedInterestAccrualPeriod === 'DAILY'
        || (!this.loanProduct.asEarnedPreterminationInterestCalculation
        && !this.hasInvalidSelectedPaymentIntervals());
    }

    this.hasInvalidSelectedPaymentIntervals = () => {
      if (!this.intervalTypes) {
        return false;
      }
      const selectedPaymentIntervals = this.intervalTypes.filter(i => this.loanProduct.paymentIntervalOptions.includes(i.id));
      // only allow INTERVAL and SET_DAYS_OF_THE_MONTH interval type
      return selectedPaymentIntervals.some(p => !['INTERVAL', 'SET_DAYS_OF_THE_MONTH'].includes(p.paymentIntervalType)
        // only allow SET_DAYS_OF_THE_MONTH if there's only one selected amortization day
        || (p.paymentIntervalType === 'SET_DAYS_OF_THE_MONTH' && p.paymentDays.length !== 1)
        || (p.basePaymentInterval === 'WEEK'
          // only allow DAY base payment interval if interval count is either 30 or 31
          || (p.basePaymentInterval === 'DAY' && ![30,31].includes(p.intervalCount))
          // only allow MONTH base payment interval if the interval count is 1
          || (p.basePaymentInterval === 'MONTH' && p.intervalCount !== 1)));
    }

    this.isInterestCalculationMethodValid = () => {
      const isAddOn = this.loanProduct.interestType === InterestType.ADD_ON_RATE ;
      const isCompound = this.loanProduct.interestCalculationMethod === InterestCalculationMethod.COMPOUND;
      return !(isAddOn && isCompound);
    }

    this.isAsEarnedPreterminationInterestCalculationValid = () => {
      const isAddOn = this.loanProduct.interestType === InterestType.ADD_ON_RATE ;
      const isEarnedPretermination = this.loanProduct.asEarnedPreterminationInterestCalculation === true;
      return !(isAddOn && isEarnedPretermination);
    }



    this.pastDueMaturityInterestApplyOnChanged = (pastDueInterestMaturityCollectionSchedule) => {
      if (pastDueInterestMaturityCollectionSchedule !== 'LAST_AMORTIZATION') {
        this.loanProduct.pastDueInterestMaturityCollectionSchedule = null;
      }
    }


    this.interestTypeOnChanged = (interestType) => {
      if(interestType === InterestType.ADD_ON_RATE) {
        this.loanProduct.interestCalculationMethod = InterestCalculationMethod.SIMPLE;
        this.loanProduct.asEarnedPreterminationInterestCalculation = false;
        this.loanProduct.asEarnedInterestCalculation = false;
      }
      this.loanProduct.amortizationType = null;
    };


    $scope.$watch('$ctrl.loanProduct.withLinkedDepositAccount', () => {
      // when we change linked deposit account, we should allow to set it as excess payment strategy
      this.updateAdvancePaymentStrategries();
    });
  }
});
