import {NxButton, NxButtonVariant, NxGap, NxPopup, NxRow, NxRowPosition, NxStack} from '@nextbank/ui-components';
import axios, {AxiosResponse} from 'axios';
import {ReactElement, useEffect, useState} from 'react';
import {Accept, DropzoneOptions, useDropzone} from 'react-dropzone';
import Webcam from 'react-webcam';
import FileUtil from 'tools/FileUtil';
import notificationService from 'tools/notificationService';
import styles from './FileUpload.scss';

export interface AttachedFile {
  id: number;
  userId?: number;
  customerId?: number;
  productId?: number;
  hookType: string;
  hookId: number;
  storageKey: string;
  fileName: string;
  fileContentType: string;
  fileSizeInBytes: number;
  remarks: string;
  checksum: string;
  tags: string[];
}

export interface IdResponse {
  id: number;
}

export type FileUploadProps = {
  value: number[];
  onFileUploaded: (uploadedFileIds: number[], allFileIds: number[]) => void;
  onFileDeleted: (deletedFileId: number, allFileIds: number[]) => void;
  allowDuplicates: boolean;
  acceptedFiles?: string[];
  disabled: boolean;
  required: boolean;
}

interface FileWithPreview extends File {
  preview: string;
  id: number;
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const downloadFile = async (fileId: number): Promise<void> => {
  if(!fileId) {
    return;
  }

  const [file] = await getFileData([fileId]);
  const fileUtil = new FileUtil();
  fileUtil.downloadFile(file.preview, file.name);
}

const getFileData = async (fileIds: number[]): Promise<FileWithPreview[]> => {
  const requests : Promise<AxiosResponse<Blob>>[] = [];
  for(const id of fileIds) {
    requests.push(axios.get<Blob>(`/files/${id}`, {responseType: 'blob'}));
  }

  const results = await Promise.all(requests);
  const retrievedFiles = [];
  const fileUtil = new FileUtil();
  for(let index = 0; index < results.length; index++) {
    const axiosResponse = results[index];
    const filename = fileUtil.parseHeadersForContentFilename(axiosResponse.headers) ?? 'upload-file';
    const file = new File([axiosResponse.data], filename, {type: axiosResponse.data.type});

    retrievedFiles.push(Object.assign(file, {id: fileIds[index], preview: URL.createObjectURL(axiosResponse.data)}));
  }
  return retrievedFiles;
}

const Preview = (props: {
  removeFile: (fileId: number) => Promise<void>,
  files: FileWithPreview[]
}) : ReactElement => {
  const [showPopup, setShowPopup] = useState(false);
  const [previewImage, setPreviewImage] = useState<FileWithPreview | undefined>(undefined);
  if(!props.files?.length) {
    return <></>;
  }
  return <>
    {props.files.map(file => {
    const previewAvailable = /image/.test(file.type);
    const deleteFileButton : ReactElement = <NxButton variant={NxButtonVariant.DELETE} onClick={() : void => {props.removeFile(file.id)}}>Delete</NxButton>;

    if(previewAvailable) {
      return <NxRow key={file.name}>
          <div className={styles['box-container']}>
            <figure className={styles['thumbnail-inner']}>
              <img
                src={file.preview}
                onClick={() : void => {
                  setPreviewImage(file);
                  setShowPopup(true);
                }}
                className={styles['thumbnail-image']}
              />
              <figcaption>{file.name}</figcaption>
            </figure>
          </div>
          {deleteFileButton}
        </NxRow>;
    }

    return <NxRow key={file.name}>
      <div className={styles['box-container']}
           onClick={() : Promise<void> => downloadFile(file.id)}>
        <i className="icon-download icon-white"/>
      </div>
      {deleteFileButton}
    </NxRow>;
  })}
    <NxPopup open={showPopup && previewImage !== undefined} header={previewImage?.name}>
      <NxStack>
        <div className={styles['preview-outer']}>
          <img
            src={previewImage?.preview}
            className={styles['zoomed-image']}
          />
        </div>
        <NxRow position={NxRowPosition.END}>
          <NxButton variant={NxButtonVariant.CONTAINED}
                    onClick={(): void => setShowPopup(false)}>Close</NxButton>
        </NxRow>
      </NxStack>
    </NxPopup>
  </>;
}

const FileUpload = (props: FileUploadProps) : ReactElement => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);

  const removeFile = async (fileId: number): Promise<void> => {
    if(!fileId) {
      return;
    }

    await axios.delete<IdResponse>(`/files/${fileId}`);

    const deletedIndex = files.findIndex(f => f.id === fileId);
    const newFiles = files.splice(deletedIndex, 1).map(f => f.id);

    props.onFileDeleted(fileId, newFiles);
    notificationService({ text: `File deleted` });
  };

  const uploadToServer = async (filesForUpload: File[]) : Promise<void> => {
    const uploadRequests : Promise<AxiosResponse<IdResponse>>[] = [];
    for(const file of filesForUpload) {

      const formData = new FormData();
      formData.append('file', file);

      uploadRequests.push(axios.post<IdResponse>(`/files`, formData, {
        url: `/files`,
        data: formData,
        transformRequest: (d) => d
      }));
    }

    const results = await Promise.all(uploadRequests);
    const uploadedFileIds = [];
    for(let index = 0; index < results.length; index++) {
      const {data} = results[index];
      files.push(Object.assign(filesForUpload[index], {id: data.id, preview: URL.createObjectURL(filesForUpload[index])}));
      uploadedFileIds.push(data.id);
    }

    setFiles(files);

    props.onFileUploaded(uploadedFileIds, files.map(f => f.id));
    notificationService({ text: `File uploaded` });
  };

  const generatePreviews = async (fileIds: number[]) : Promise<void> => {
    if (!fileIds || !fileIds.length) {
      return;
    }

    //to allow react to detect a "change" in the input for rerender
    const updatedFiles = [...files];
    const results = await getFileData(fileIds);
    for(const fileData of results) {
      updatedFiles.push(fileData);
    }
    setFiles(updatedFiles);
  }

  //run once on initial load only. Succeeding updates to props.value shouldn't
  //need a rerender
  useEffect((): void => {
    generatePreviews(props.value);
  }, []);

  const dropzoneOptions : DropzoneOptions = {
    maxFiles: 1,
    onDrop: acceptedFiles => {
      uploadToServer(acceptedFiles);
    }
  };

  if(props.acceptedFiles?.length) {
    const accept: Accept = {};
    props.acceptedFiles?.forEach(value => accept[value] = []);
    dropzoneOptions.accept = accept;
  }

  const {getRootProps, getInputProps} = useDropzone(dropzoneOptions);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () : void => files.forEach((file: FileWithPreview) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (<>
      <Preview files={files} removeFile={removeFile}/>
      <NxRow gap={NxGap.L}>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <div className={styles['box-container']}>
            <p>Drop or select file</p>
          </div>
        </div>
        <NxButton variant={NxButtonVariant.CONTAINED}
                  onClick={(): void => setShowWebcam(true)}>Take photo</NxButton>
      </NxRow>

      <NxPopup header={''} open={showWebcam}>
        <NxStack>
          <Webcam
            screenshotFormat='image/png'
            videoConstraints={videoConstraints}
          >
            {({getScreenshot}) : ReactElement => (
              <NxRow position={NxRowPosition.CENTER}>
                <NxButton
                  onClick={async (): Promise<void> => {
                    const imageSrc = getScreenshot();
                    if (imageSrc) {
                      const image = await fetch(imageSrc);
                      const blob = await image.blob();
                      const date = new Date().getMilliseconds();
                      const file = new File([blob], `photo-${date}.png`, {type: blob.type});
                      uploadToServer([file]);
                    }
                    setShowWebcam(false);
                  }}
                >
                  Take photo
                </NxButton>
                <NxButton onClick={(): void => setShowWebcam(false)} variant={NxButtonVariant.CLOSE}>Cancel</NxButton>
              </NxRow>
            )}
          </Webcam>
        </NxStack>
      </NxPopup>
    </>
  );
}

export default FileUpload;