import nxModule from 'nxModule';

const templateUrl = require('./term-deposit-fees.template.html');

class TermDepositFees {
  constructor($scope, feeDefinitionsCache) {
    this.$scope = $scope;
    this.feeDefinitionsCache = feeDefinitionsCache;
  }

  $onInit() {
    this.$scope.$watch('$ctrl.deposit', () => {
      this.feeDefinitionsCache.toPromise().then(feeDefs => {
        let depositTypeFeeDefs = feeDefs
          .filter(fd => fd.feeClass === 'CUSTOM' || fd.feeClass === 'DOC_STAMP')
          .filter(fd => fd.enabled)
          .filter(fd => fd.productDefinitionId === this.deposit.productDefinitionId);

        const withholdingTaxFeeDefinition = feeDefs.find(fd => fd.id === this.deposit.withholdingTaxId) || this.deposit.withholdingTax;
        this.feeDefinitions = [
          ...depositTypeFeeDefs,
          withholdingTaxFeeDefinition,
          ...(this.deposit.feeDefinitions || []).filter(feeDef => !feeDef.id)
        ];

        this.deposit.withholdingTax = withholdingTaxFeeDefinition;
        this.deposit.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM' || fd.feeClass === 'DOC_STAMP');
      });

    });

  }

  onSave() {
    this.deposit.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM' || fd.feeClass === 'DOC_STAMP');

    //for property based fees updates values
    for(let feeDefinition of this.feeDefinitions) {
      if (feeDefinition.feeClass === 'WITHHOLDING_TAX') {
        this.deposit.withholdingTax = feeDefinition;
      }
    }
  }
}

nxModule.component('termDepositFees', {
  templateUrl,
  bindings: {
    deposit: '=',
    form: '='
  },
  controller: TermDepositFees
});
