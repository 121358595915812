import nxModule from 'nxModule';
import _ from 'lodash';


nxModule.factory('transactionDetailsBuilder', function ($filter, transactionHistoryService) {
  let service = {};
  const ATM_OPERATION_GROUP = 
  [
    'ATM_DEBIT', 
    'TRANSFER_FUNDS',
    'WITHDRAW_ATM', 
    'WITHDRAW_ATM_NON_ON_US',
    'ATM_FUND_TRANSFER_DEPOSIT', 
    'ATM_FUND_TRANSFER_WITHDRAWAL', 
    'APPLY_FEE',
  ]
  service.buildCurrencyIfNotZero = (label, amount) => {
    if (!amount || amount === '' || amount === 0) {
      return {};
    }

    amount = parseFloat(amount);

    if (isNaN(amount) || !isFinite(amount)) {
      return {}
    }
    return {
      label,
      value: $filter('nxCurrency')(amount)
    }
  };

  service.buildIfNotEmpty = (label, str) => {
    if (str && str !== '') {
      return {
        label,
        value: str
      }
    }
    return {};
  };


  service.build = (currentTransaction, productGroup) => {
    if (!currentTransaction) {
      return [];
    }
    let baseValueDetails = service.buildBase(currentTransaction, productGroup);
    baseValueDetails.push(...service.buildBankSection(currentTransaction));
    baseValueDetails.push(...service.buildTransfer(currentTransaction));
    baseValueDetails.push(...service.buildCheckSection(currentTransaction));
    baseValueDetails.push(...service.buildFees(currentTransaction));
    baseValueDetails.push(...service.buildStatus(currentTransaction));
    baseValueDetails.push(...service.buildExternalTransfer(currentTransaction));
    baseValueDetails.push(...service.buildChangeProductType(currentTransaction));
    baseValueDetails.push(...service.buildPayments(currentTransaction));
    baseValueDetails.push(...service.buildPartnerChannelTransactions(currentTransaction));
    baseValueDetails.push(...service.buildAtmDetails(currentTransaction));
    return baseValueDetails;
  };

  service.buildBase = (currentTransaction, productGroup) => {
    const formatUser = user => user ? `${user.firstName} ${user.lastName}` : '-';
    const formatBranch = branch => branch ? branch.name : '-';


    return [
      service.buildIfNotEmpty('Transaction no', currentTransaction.id),
      service.buildIfNotEmpty('Command id', currentTransaction.commandId),
      {
        label: 'Source branch',
        value: formatBranch(currentTransaction.registrationBranch),
      },
      {
        label: 'User initiating',
        value: formatUser(currentTransaction.registrationUser),
      },
      {
        label: 'Approving user',
        value: formatUser(currentTransaction.approvalUser),
      },
      service.buildCurrencyIfNotZero('Amount', currentTransaction.amount),
      {
        label: 'Status',
        value: transactionHistoryService.getStatusLabel(currentTransaction, productGroup)
      }
    ];
  };

  service.buildStatus = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if ('UPDATE_STATUS' === operationGroup) {
      values.push({
        label: 'Status changed',
        value: `${service.attrByName(currentTransaction, 'SOURCE_STATUS')} -> ${service.attrByName(currentTransaction, 'TARGET_STATUS')}`
      });
    }
    return values;
  };

  service.buildExternalTransfer = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if ('EXTERNAL_TRANSFER_OUTGOING' === operationGroup) {
      values.push({
        label: 'Transfer channel',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_TYPE')} ${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_CHANNEL')}`
      });
      values.push({
        label: 'Transfer recipient name',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_RECIPIENT_NAME')}`
      });
      values.push({
        label: 'Transfer recipient account number',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_RECIPIENT_ACCOUNT_NUMBER')}`
      });
      values.push({
        label: 'Transfer purpose',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_PURPOSE')}`
      });
      values.push({
        label: 'Transfer reference number',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_REFERENCE_NUMBER')}`
      });
    }

    if ('EXTERNAL_TRANSFER_INCOMING' === operationGroup) {
      values.push({
        label: 'Transfer channel',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_TYPE')} ${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_CHANNEL')}`
      });
      values.push({
        label: 'Transfer beneficiary name',
        value: `${service.attrByName(currentTransaction, 'TARGET_INTERNAL_CUSTOMER_NAME')}`
      });
      values.push({
        label: 'Transfer sender name',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_SENDER_NAME')}`
      });
      values.push({
        label: 'Transfer sender account number',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_SENDER_ACCOUNT_NUMBER')}`
      });
      values.push({
        label: 'Transfer purpose',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_PURPOSE')}`
      });
      values.push({
        label: 'Transfer reference number',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_REFERENCE_NUMBER')}`
      });
      values.push({
        label: 'Transfer sender bank',
        value: `${service.attrByName(currentTransaction, 'EXTERNAL_TRANSFER_SENDER_BANK_NAME')}`
      });
    }
    return values;
  };

  service.buildCheckSection = (transaction) => {
    let checkAttrs = [];
    const checkAttributeTypes = ['BANK_NAME', 'CHECK_NO', 'INITIAL_CLEARING_DATE', 'CHECK_AMOUNT', 'CHECK_CLEARING_GROUP'];
    _.forOwn(transaction.attributes, (value, key) => {
      if (value && _.includes(checkAttributeTypes, key)) {
        checkAttrs.push({
          label: $filter('prettyEnum')(key),
          value: $filter('prettyEnum')(value)
        });

        if(key === 'INITIAL_CLEARING_DATE' && transaction.check && transaction.check.estimatedClearingDate){
          checkAttrs.push({
            label: $filter('prettyEnum')('ESTIMATED_CLEARING_DATE'),
            value: $filter('prettyEnum')(transaction.check.estimatedClearingDate)
          });
        }
      }
    });
    return checkAttrs;
  };

  service.buildBankSection = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if (['CREDIT_MEMO', 'DEBIT_MEMO'].includes(operationGroup)) {
      values.push(...[{
        label: 'Remarks',
        value: service.attrByName(currentTransaction, 'REMARKS', 'No remarks'),
      }, service.buildIfNotEmpty('Credit remarks', service.attrByName(currentTransaction, 'CREDIT_REMARKS', ''))]);
    }
    return values;
  };

  service.buildTransfer = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    if ('TRANSFER_FUNDS' === operationGroup) {
      values.push(...[{
        label: 'Source Product',
        value: currentTransaction.source.productNumber
      }, {
        label: 'Target Product',
        value: currentTransaction.target.productNumber
      },
        service.buildIfNotEmpty('Remarks', service.attrByName(currentTransaction, 'REMARKS', ''))
      ]);
    }
    return values;
  };

  service.buildFees = (currentTransaction) => {
    if (currentTransaction.feeName) {
      return [{
        label: 'Fee name',
        value: currentTransaction.feeName
      }]
    }
    return [{}];
  };

  service.buildChangeProductType = (currentTransaction) => {
    if (currentTransaction.operationGroup === 'REALLOCATE_PRODUCT_BALANCE' && service.attrByName(currentTransaction, 'OLD_PRODUCT_NAME', null)) {
      return [{
        label: 'Product type changed',
        value: `${service.attrByName(currentTransaction, 'OLD_PRODUCT_NAME')} -> ${service.attrByName(currentTransaction, 'NEW_PRODUCT_NAME')}`
      }]
    }
    return [{}];
  };

  service.buildPartnerChannelTransactions = (currentTransaction) => {
    const operationGroup = currentTransaction.operationGroup;

    if (!['EXTERNAL_PAYMENT', 'PARTNER_DEPOSIT', 'PARTNER_WITHDRAWAL'].includes(operationGroup)) {
      return [];
    }

    const attributes = currentTransaction.attributes;

    const values = [];

    /**
     * Reference number can be either of the following
     * EXTERNAL_PAYMENT has EXTERNAL_PAYMENT_NO
     * PARTNER_DEPOSIT and PARTNER_WITHDRAWAL have PARTNER_OPERATION_REFERENCE_NO
     *
     * NOTE: `attributes` is used as condition because EXTERNAL_PAYMENT` (subgroup: `BILLS_PAYMENT`) has no
     * reference number.
     */
    if (attributes.PARTNER_OPERATION_REFERENCE_NO || attributes.EXTERNAL_PAYMENT_NO) {
      // used as default
      const partnerReferenceNumber = service.attrByName(currentTransaction, 'PARTNER_OPERATION_REFERENCE_NO');
      values.push(...[{
        label: 'Channel reference number',
        value: service.attrByName(currentTransaction, 'EXTERNAL_PAYMENT_NO', partnerReferenceNumber),
      }]);
    }

    if (operationGroup === 'EXTERNAL_PAYMENT') {
      values.push(...[{
        label: 'Payment service code',
        value: service.attrByName(currentTransaction, 'EXTERNAL_PAYMENT_SERVICE_CODE')
      }]);


      if (['ELOAD', 'EPIN_PAYMENT'].includes(currentTransaction.operationSubgroup)) {
        values.push(...[{
          label: 'Top-up mobile number',
          value: service.attrByName(currentTransaction, 'EXTERNAL_PAYMENT_TOP_UP_MOBILE_NUMBER')
        }]);
      }
    }

    return values;
  };


  service.buildPayments = (currentTransaction) => {
    let values = [];
    const operationGroup = currentTransaction.operationGroup;
    const subgroup = currentTransaction.operationSubgroup;
    const isTransferFunds = operationGroup === 'TRANSFER_FUNDS' && subgroup === 'LOAN_PAYMENT';
    const isCreditLoanMemo = operationGroup === 'CREDIT_MEMO' && subgroup === 'LOAN_PAYMENT';
    if (['DEPOSIT_CASH', 'DEPOSIT_CHECK'].includes(operationGroup) || isTransferFunds || isCreditLoanMemo) {
      values.push(...[
        service.buildCurrencyIfNotZero('Interest payment', service.attrByName(currentTransaction, 'INTEREST_PAYMENT', '')),
        service.buildCurrencyIfNotZero('Principal payment', service.attrByName(currentTransaction, 'PRINCIPAL_PAYMENT', '')),
        service.buildCurrencyIfNotZero('Penalty payment', service.attrByName(currentTransaction, 'PENALTY_PAYMENT', '')),
        service.buildCurrencyIfNotZero('Past due interest payment', service.attrByName(currentTransaction, 'PAST_DUE_INTEREST_PAYMENT', '')),
        service.buildCurrencyIfNotZero('CBU payment', service.attrByName(currentTransaction, 'CBU_PAYMENT', '')),
        service.buildCurrencyIfNotZero('TP payment', service.attrByName(currentTransaction, 'TP_PAYMENT', '')),
        service.buildCurrencyIfNotZero('PF payment', service.attrByName(currentTransaction, 'PF_PAYMENT', '')),
        service.buildIfNotEmpty('Amortizations Lines', service.attrByName(currentTransaction, 'AMORTIZATION_LINE_NUMBER', '')),
        service.buildIfNotEmpty('Temporary receipt', service.attrByName(currentTransaction, 'TEMPORARY_RECEIPT', '')),
        service.buildIfNotEmpty('Official receipt', service.attrByName(currentTransaction, 'OFFICIAL_RECEIPT', '')),
        service.buildIfNotEmpty('Remarks', service.attrByName(currentTransaction, 'REMARKS', '')),
      ]);
    }

    return values;
  };

  service.clean = (arr) => {
    return arr.filter(val => Object.keys(val).length !== 0);
  };

  service.attrByName = (currentTransaction, type, defaultVal = `<MISSING ${type}>`) => {
    return _.get(currentTransaction.attributes, type, defaultVal);
  };

  service.buildAtmDetails = (currentTransaction) => {
    const values = []
    if(!(['CREDIT_MEMO', 'DEBIT_MEMO', 'TRANSFER_FUNDS', 'DEPOSIT_CASH', 'DEPOSIT_CHECK'].includes(currentTransaction.operationGroup))) {
      values.push(service.buildIfNotEmpty('Remarks', service.attrByName(currentTransaction, 'REMARKS', '')))
    }
    if(ATM_OPERATION_GROUP.includes(currentTransaction.operationGroup)) {
      values.push(service.buildIfNotEmpty('Trace No', service.attrByName(currentTransaction, 'TRACE_NUMBER', '')))
    }
    return values;
  };

  return service;
});
