import nxModule from 'nxModule';

import moment from 'moment';

import templateUrl from './schedule-accounting-period-closure.template.html';

class ScheduleAccountingPeriodClosure {
  constructor(command, authentication, systemDateService, $filter, $location, confirmation) {
    this.command = command;
    this.authentication = authentication;
    this.systemDateService = systemDateService;
    this.$filter = $filter;
    this.$location = $location;
    this.confirmation = confirmation;

    systemDateService.getCurrentUserBranchSystemDate()
      .then(systemDate => this.systemDate = systemDate);
  }

  getPeriod() {
    if(!this.year) {
      return;
    }

    return [
      this.$filter('prettyDate')(this.getLastYearDate(this.year.year() - 1).add(1, 'days')),
      this.$filter('prettyDate')(this.getLastYearDate(this.year.year()))
    ].join(' - ');
  }

  getLastYearDate(year) {
    return moment.utc()
      .year(year)
      .month(11) // zero based months
      .date(31);
  }

  getLastYearDateText(year) {
    const date = this.getLastYearDate(year);
    if(!date) {
      return;
    }

    return date.format('YYYY-MM-DD');
  }

  getClosingDate() {
    if(!this.year) {
      return '';
    }

    return this.$filter('prettyDate')(this.getLastYearDate(this.year.year()));
  }

  cancelChanges() {
    this.$location.path('/dashboard/miscellaneous-transactions');
  }

  async save() {
    const confirmed = await this.confirmation('Are you sure you want to approve closure of accounting period?');
    if(!confirmed) {
      return;
    }

    await this.command.execute('ScheduleAccountingPeriodClosure', {
      branchId: this.authentication.context.branchId,
      periodEndDate: this.getLastYearDateText(this.year.year())
    }).toPromise();

    this.$location.path('/dashboard/miscellaneous-transactions');
  }
}

nxModule.component('scheduleAccountingPeriodClosure', {
  templateUrl,
  controller: ScheduleAccountingPeriodClosure
});
