import nxModule from "nxModule";
import templateUrl from "components/dashboard/collections/filter/collections-filter.template.html";
import {UserCache} from "components/service/users/user.cache";
import RolesCache from "components/service/roles.cache";
import {BranchService} from "components/service/branch.service";
import {CenterService} from "components/service/center/center.service";
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {Center, Customer} from "components/service/center/center.types";
import {Branch} from "management/BranchTypes";
import {LoanType} from "components/service/loan-type.types";
import _, {toNumber} from "lodash";
import {User, Role} from "user/UserTypes";
import './collections-filter.less';
import {SystemDateService} from "components/service/system-date.service.types";

type CustomerWithGroup = Customer & { customerGroup: { centerId: number } }

export type SortBy = 'CUSTOMER_NAME' | 'DUE_DATE' | 'DUE_DATE_DESC'
export type SearchBy = 'INDIVIDUAL' | 'GROUP';

export type IndividualFilter = {
  dateTo: string;
  branchId: number;
  officerId?: number;
  collectorId?: number;
  loanTypeIds?: number[];
  customerName?: string;
  productNumber?: string;
  sortBy: SortBy;
}

export type GroupFilter = {
  dateTo: string;
  branchId: number;
  officerId?: number;
  collectorId?: number;
  loanTypeIds?: number[];
  groupCustomerIds?: number[];
  centerIds?: number[];
  sortBy: SortBy;
}

class CollectionsFilter {
  constructor(
    private userCache: UserCache,
    private rolesCache: RolesCache,
    private branchService: BranchService,
    private systemDateService: SystemDateService,
    private centerService: CenterService,
    private loanProductsCache: LoanProductsCache,
    private authentication: Authentication,
    private http: HttpService
  ) {}

  // Selectize configs
  private readonly centerConfig = {
    placeholder: 'Select center',
    searchField: ['name', 'id'],
    valueField: 'id',
    labelField: 'label',
    maxItems: null
  };
  private readonly groupConfig = {
    placeholder: 'Select group',
    searchField: ['effectiveName'],
    valueField: 'id',
    labelField: 'effectiveName',
    maxItems: null
  };

  private allCenters!: Center[];

  // ng-options
  private searchByOptions: SearchBy[] = ['INDIVIDUAL', 'GROUP'];
  private officers!: User[];
  private collectors!: User[];
  private branches!: Branch[];
  private loanTypes!: LoanType[];
  private filteredCenters!: (Center & { label: string })[];
  private filteredGroups!: Customer[];
  private readonly sortByOptions = [
    { label: 'Customer name (Ascending)', value: 'CUSTOMER_NAME' },
    { label: 'Latest due date (Ascending)', value: 'DUE_DATE' },
    { label: 'Latest due date (Descending)', value: 'DUE_DATE_DESC' }
  ];

  // Bindings
  private searchBy!: SearchBy;
  private groupFilter!: GroupFilter;
  private individualFilter!: IndividualFilter;

  async $onInit(): Promise<void> {
    this.searchBy = 'GROUP';
    await this.initializeFilters();

    const [branches, centers, loanTypes, users, roles] = await Promise.all([
      this.branchService.toPromise(),
      this.centerService.fetchCenters({}),
      this.loanProductsCache.toPromise(),
      this.userCache.withParam().toPromise(),
      this.rolesCache.toPromise()
    ]);
    this.allCenters = centers;

    this.branches = this.prepareBranches(branches, this.authentication);
    this.filteredCenters = this.prepareCenters(this.allCenters, this.groupFilter);
    this.filteredGroups = await this.prepareGroups(this.groupFilter);
    this.loanTypes = this.prepareLoanTypes(loanTypes);
    this.officers = this.prepareLoanOfficers(users, roles);
    this.collectors = this.prepareLoanCollectors(users, roles);
  }

  async initializeFilters(): Promise<void> {
    const systemDate = await this.systemDateService.getCurrentUserBranchSystemDate();
    this.individualFilter = {
      dateTo: systemDate.toString(),
      branchId: this.authentication.context.branchId,
      sortBy: "CUSTOMER_NAME"
    }
    this.groupFilter = {
      dateTo: systemDate.toString(),
      branchId: this.authentication.context.branchId,
      sortBy: "CUSTOMER_NAME"
    }
  }

  selectedFilter(): IndividualFilter | GroupFilter {
    return this.searchBy === 'INDIVIDUAL'
      ? this.individualFilter
      : this.groupFilter;
  }

  prepareBranches(branches: Branch[], auth: Authentication): Branch[] {
    return branches.filter(branch => auth.context.branchIds.includes(branch.id));
  }

  prepareCenters(allCenters: Center[], filter: GroupFilter): (Center & { label: string})[] {
    const out = allCenters.map(c => ({
        ...c,
        label: `${c.id} - ${c.name}`
      }
    )).filter(c => filter.branchId === c.branchId);

    // Sync selected centerIds to filtered centers
    filter.centerIds = filter.centerIds?.map(id => toNumber(id)).filter(id => out.map(ctr => ctr.id).includes(id));
    return out;
  }

  async prepareGroups(filter: GroupFilter): Promise<Customer[]> {
    let groups = await this.http.get<CustomerWithGroup[]>(`/customers/group-customers`, { params: { branchIds: [this.groupFilter.branchId] } }).toPromise();

    if ((filter.centerIds?.length ?? 0) > 0) {
      groups = groups.filter(customer => {
        const groupCenterId = customer.customerGroup.centerId;
        return filter.centerIds?.map(centerId => toNumber(centerId)).includes(groupCenterId);
      })
    }

    // Sync selected groupIds to filtered groups
    filter.groupCustomerIds = filter.groupCustomerIds?.map(id => toNumber(id)).filter(id => groups.map(cst => cst.id).includes(id));
    return groups;
  }

  prepareLoanTypes(loanTypes: LoanType[]): LoanType[] {
    return loanTypes.map(p => ({
      ...p,
      name: p.productDefinition.productName
    }));
  }

  prepareLoanOfficers(users: User[], roles: Role[]) : User[] {
    const rolesWithLoanOfficerPermission = roles
      .filter(role => _.some(role.permissions, {name: 'LOAN_OFFICER'}))
      .map(role => role.id);

    return users.filter(
      user => user.roles
        .map(role => role.id)
        .some(roleId => rolesWithLoanOfficerPermission.includes(roleId))
    );
  }

  prepareLoanCollectors(users: User[], roles: Role[]) : User[] {
    const rolesWithCollectorPermission = roles
      .filter(role => _.some(role.permissions, {name: 'COLLECTOR'}))
      .map(role => role.id);

    return users.filter(
      user => user.roles
        .map(role => role.id)
        .some(roleId => rolesWithCollectorPermission.includes(roleId))
    );
  }

  async updateFilterOptions() : Promise<void> {
    if (this.searchBy === 'INDIVIDUAL') {
      this.groupFilter.centerIds = undefined;
      this.groupFilter.groupCustomerIds = undefined;
    } else {
      this.individualFilter.customerName = undefined;
      this.individualFilter.productNumber = undefined;
      this.filteredCenters = this.prepareCenters(this.allCenters, this.groupFilter);
      this.filteredGroups = await this.prepareGroups(this.groupFilter);
    }
  }
}

nxModule.component('collectionsFilter', {
  templateUrl,
  bindings: {
    searchBy: '=',
    individualFilter: '=',
    groupFilter: '='
  },
  controller: CollectionsFilter
});
