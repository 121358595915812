import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './cash-in-out.template.html';
import {MiscCommandService} from "../misc-command.service";
import {Branch} from "management/BranchTypes";
import {IScope} from "angular";
import {CommandService} from "shared/utils/command/command.types";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {AtmTerminal, AtmTerminalBranchCache} from "components/atm/service/atm-terminal-branch.cache.types";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {BranchService} from "components/service/branch.service";
import {ActionCategory} from "components/administration/transactions/action-category.types";
import {AssetCounter} from "components/technical/status/user-counter.service";

export interface Transaction {
  units: TransactionUnit[];
  remarks: string;
  amount?: number;
  cashAmountAfter?: number;
  targetBranchId?: number;
}

export interface TransactionUnit {
  amount: number,
  accountCode: string
}

export type DIRECTION = 'IN' | 'OUT';
export type UNIT_TYPE = 'DEBIT' | 'CREDIT';

class CashInOut {
  private readonly onlyCurrentlyLoggedBranchMessage: string = 'Selected category doesn\'t have any other target branches than currently logged branch.';

  private direction!: DIRECTION;
  private otherBranches: Branch[] = [];

  header: string = '';
  errorMessages: string[] = [];
  interbranch!: boolean;

  category?: ActionCategory;
  availableTargetBranches: Branch[] = [];

  fixedUnitType!: UNIT_TYPE;
  blockedUnits: TransactionUnit[] = [];
  transaction: Transaction = {
    units: [],
    amount: 0,
    cashAmountAfter: 0,
    remarks: ''
  }

  private readonly targetBranchSelectConfig = {
    placeholder: 'Select a branch',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  atmTransaction!: boolean;
  atmTerminal?: AtmTerminal;
  atmTerminals: (AtmTerminal & {totalCash?: number})[] = [];

  constructor(private command: CommandService, private actionCommand: ActionCommand,
              private branchService: BranchService, private authentication: Authentication,
              private miscCommandService: MiscCommandService, private http: HttpService, private $scope: IScope,
              private atmTerminalBranchCache: AtmTerminalBranchCache) {
  }

  async $onInit(): Promise<void> {
    this.header = `Cash ${this.direction.toLowerCase()}`;

    if (this.interbranch) {
      this.header = `Interbranch ${this.header}`;
    }

    if (this.atmTransaction) {
      this.atmTerminals = await this.atmTerminalBranchCache.withParam(this.authentication.context.branchId).toPromise();

      // Add ATM total cash
      if (this.authentication.permissions['MNG_ATM_BALANCE_READ']) {
        const atmTerminalsCounters = await this.http.get<AssetCounter[]>('/management/atm-terminals/counters/', {
          nxLoaderSkip: true,
          params: {branchId: Number(this.authentication.context.branchId)}
        }).toPromise();

        this.atmTerminals.forEach(atm => {
          const counter = atmTerminalsCounters.find(c => c.domainId === atm.id)!;
          atm.totalCash = counter.totalCash;
        });
      }

      this.$scope.$watch('$ctrl.transaction.amount', () => {
        this.updateCashAmountAfter();
      });
    }

    const branches = await this.branchService.toPromise();


    this.otherBranches = branches.filter(b => Number(b.id) !== Number(this.authentication.context.branchId));
    this.transaction.amount = 0;
  }

  onCategoryChange(): void {
    if (this.interbranch) {
      this.transaction.targetBranchId = undefined
    }
    this.blockedUnits = [];
    if (this.category) {
      this.updateUnits();
      this.updateBranches();
      this.verifyCategoryTargetBranchIds();
    }
  }

  private verifyCategoryTargetBranchIds(): void {
    if (this.hasOnlyCurrentlyLoggedBranch()) {
      this.errorMessages.push(this.onlyCurrentlyLoggedBranchMessage);
    } else {
      this.errorMessages = this.errorMessages.filter(v => v !== this.onlyCurrentlyLoggedBranchMessage);
    }
  }

  private hasOnlyCurrentlyLoggedBranch() {
    return this.category
      && this.category.targetBranchIds
      && this.category.targetBranchIds.every(v => v === this.authentication.context.branchId);
  }

  updateUnits(): void {
    const currentUnits = this.transaction.units;
    const newAccounts = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ledgerAccountFullCodes = this.atmTransaction ? [this.atmTerminal.ledgerAccountCode] : this.category.ledgerAccountFullCodes;
    for (const fullCode of (ledgerAccountFullCodes || [])) {
      const currentUnit: { amount: number } | undefined = currentUnits.find(unit => unit.accountCode === fullCode);
      newAccounts.push({
        accountCode: fullCode,
        amount: currentUnit ? currentUnit.amount : 0,
        entryType: this.fixedUnitType === 'DEBIT' ? 'CREDIT' : 'DEBIT'
      });
    }
    this.transaction.amount = 0;
    this.blockedUnits = newAccounts;
  }

  private updateBranches(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.availableTargetBranches = _.isEmpty(this.category.targetBranchIds) ?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.otherBranches : this.otherBranches.filter(b => this.category.targetBranchIds.includes(b.id));
  }

  updateCashAmountAfter(): void {
    if (!this.atmTransaction || !this.atmTerminal) {
      return;
    }

    if (this.direction === 'IN') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.transaction.cashAmountAfter = this.atmTerminal.totalCash - this.transaction.amount;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.transaction.cashAmountAfter = this.atmTerminal.totalCash + this.transaction.amount;
    }
  }

  getActionType(): string {
    if (this.interbranch) {
      return `DISTRIBUTED_CASH_${this.direction}`;
    }

    return `CASH_${this.direction}`;
  }

  cancel() {
    this.actionCommand.cancelChanges();
  }

  async save(): Promise<void> {

    const units = this.transaction.units.filter(unit => unit.accountCode)
        .map(unit => ({
          ...unit,
          fullCode: unit.accountCode,
        }));

    const categoryUnits = this.atmTerminal ? null : {
      categoryId: this.category ? this.category.id : null,
      units,
    };

    this.miscCommandService.executeCommand(this.getCommandName(), {
      ...this.transaction,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      atmId: this.atmTransaction ? this.atmTerminal.id : null,
      categoryUnits: categoryUnits
    });
  }

  getCommandName(): string {
    const nameParts = ['Misc'];
    if (this.atmTransaction) {
      nameParts.push('Atm');
    }
    if (this.interbranch) {
      nameParts.push('Interbranch');
    }

    nameParts.push('Cash');
    nameParts.push(_.capitalize(this.direction));

    return nameParts.join('');
  }
}

nxModule.component('cashInOut', {
  templateUrl,
  bindings: {
    interbranch: '<',
    direction: '<',
    fixedUnitType: '<',
    atmTransaction: '<'
  },
  controller: CashInOut
});
