import _ from 'lodash';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';
import EventEmitter from 'wolfy87-eventemitter';
import observableService from './observableService';

export type LocalCacheArgs<ProviderResponse, Output> = {
  cacheFactory: any,
  cacheName: string,
  cacheKey: string,
  cacheTime: number,
  provider: any,
  cacheStorage: 'memory' | 'localStorage',
  postProcessor: (response: ProviderResponse) => Output
}

export type Listener = (error: Error | undefined, value?: unknown) => unknown;
interface ListenerWrapper extends Function {
  once: boolean,
  listener: Listener
}

export const storagePrefix = 'nx__';

export default class LocalCache<ProviderResponse, Output> {
  private provider: any; // function calling http.get
  private emitter: EventEmitter;
  private currentlyFetching: boolean;
  private postProcessor: any;
  private cacheKey: string;
  private cache: any;
  private cacheName: string;
  private cacheTime: number | undefined;
  private cacheFactory: any;

  constructor({cacheFactory, cacheName, cacheTime, provider, postProcessor, cacheKey = 'key', cacheStorage = 'localStorage'}: Partial<LocalCacheArgs<ProviderResponse, Output>> & {cacheName: string}) {
    this.provider = provider;
    this.emitter = new EventEmitter();
    this.currentlyFetching = false;
    this.postProcessor = postProcessor;

    this.cacheKey = cacheKey;
    this.cacheName = cacheName;
    this.cacheTime = cacheTime;

    this.cache = cacheFactory.get(cacheName);
    this.cacheFactory = cacheFactory;
    if (!this.cache) {
      this.cache = this.createCache(cacheStorage);
    }
  }

  private createCache(cacheStorage: string): LocalCache<any, any> {
    return this.cacheFactory.createCache(this.cacheName, {
      deleteOnExpire: 'aggressive',
      storageMode: cacheStorage,
      storagePrefix: storagePrefix,
      ...this.cacheTime && {maxAge: this.cacheTime}
    });
  }

  private getCacheValue(): Output {
    if (this.inMemoryCache()) {
      return _.cloneDeep(this.cache.get(this.cacheKey));
    }
    return this.cache.get(this.cacheKey);
  }

  subscribe(listener: Listener): void {
    this.emitter.addListener(this.cacheKey, listener);
    const lastValue = this.getCacheValue();
    if (lastValue) {
      listener(undefined, lastValue);
    } else {
      this.refetch();
    }
  }

  inMemoryCache(): boolean {
    return this.cache.info().storageMode === 'memory';
  }

  async refetch(): Promise<Output | void> {
    if (this.fetching()) {
      console.log(`Already fetching ${this.cacheName} ${this.cacheKey}`);
      return Promise.resolve();
    }

    console.log(`Removing cache ${this.cacheName} ${this.cacheKey}`);
    this.cache.remove(this.cacheKey);

    const providerResponse = this.provider();

    if (providerResponse.errorCallback.length === 0) {
      // there is no a single code invocation that handles errors in subscribe, so
      // let's display http error notification
      providerResponse.error((error: unknown, status: unknown) => {
        providerResponse.defaultErrorCallback(error, status);
      });
    }

    this.fetching(true);
    return new Promise((resolve, reject) => {
      providerResponse.success((response: any) => {
        if (this.postProcessor) {
          response = this.postProcessor(response);
        }
        try {
          this.cache.put(this.cacheKey, response);
        } catch (e) {
          if (!this.inMemoryCache()) {
            console.error('Error setting cache, cache name', this.cacheName, e);
            this.cache.destroy(this.cacheName);
            this.cache = this.createCache('memory');
            this.cache.put(this.cacheKey, response);
            console.log('Replaced cache with in memory version, cache name:', this.cacheName);
          }
        }

        this.emit(undefined, this.getCacheValue());
        resolve(this.getCacheValue());
      })
        .error((error: unknown) => {
          // we need to trigger emission in case on an error
          // - otherwise promise will not be rejected if someone is waiting on subscribe or toPromise
          const err: any = new Error('Cache provider error');
          err.response = error;

          this.emit(err);
          reject(error);
        })
        .always(() => {
          this.fetching(false);
        });
    });
  }

  emit(error: undefined | Error, value?: unknown) : void {
    const listeners  = <ListenerWrapper[]> this.emitter.getListeners(this.cacheKey);
    // we need to copy listeners. The list is mutable and can change as a result of calling a listener
    const immutableListeners = Object.freeze([...listeners]);
    for (const listener of immutableListeners) {
      try {
        if (listener.once) {
          console.log('removing listener', this.cacheName);
          this.emitter.removeListener(this.cacheKey, listener.listener);
        }

        listener.listener(error, _.cloneDeep(value));
      } catch (err) {
        console.error(`Error emitting listener. Cache key: ${this.cacheKey} ${this.cacheName}`);
        listener.listener(err);
      }
    }
  }

  evict(): void {
    console.log('Evicting cache for', this.cacheName, '-', this.cacheKey);
    this.cache.remove(this.cacheKey);
  }

  fetching(value?: boolean): boolean {
    if (value !== undefined) {
      this.currentlyFetching = value;
    }

    return !!this.currentlyFetching;
  }

  unsubscribe(listener: Listener): void {
    this.emitter.removeListener(this.cacheKey, listener);
  }

  toObservable(): Observable<Output> {
    return observableService(this);
  }

  toPromise(): Promise<Output> {
    return observableService(this)
      .first()
      .toPromise();
  }
}