import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('accessRuleDefinitionCache', (http, CacheFactory, config, $filter) =>
  new LocalCache({
    provider: () => http.get('/access/commands'),
    cacheStorage: 'memory',
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositoryAccount,
    cacheName: 'accessRuleDefinition',
    postProcessor: accessRuleDefs => {
      accessRuleDefs.forEach(accessRuleDef => {
        accessRuleDef.displayName = $filter('translateEnum')(accessRuleDef.command, 'COMMAND')
      });

      return accessRuleDefs;
    }
  })
);
