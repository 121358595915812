import nxModule from 'nxModule';

import {IController} from "angular";
import templateUrl from './gl-category-input.template.html';
import './gl-category-input.style.less';

export interface Unit {
  fullCode: string;
  amount: number;
}

class GlCategoryInputComponent implements IController {
  units!: Unit[];
}

nxModule.component('glCategoryInput', {
  controller: GlCategoryInputComponent,
  bindings: {
    'units': '=',
    'disableAmount': '<'
  },
  templateUrl,
});