import {LedgerAccount} from "components/general-ledger/common/gl.types";

export const BATCH_TRANSACTION_SAMPLE_FILE = new Blob([`gl_code_path,debit_amount,credit_amount,remarks
ASSET/COH/CSHR,100,0,remarks
`], {type: 'text/plain'});

export const BATCH_INTERBRANCH_TRANSACTION_SAMPLE_FILE = new Blob([`branch_code,gl_code_path,debit_amount,credit_amount,remarks
001,ASSET/COH/CSHR,100,0,remarks
`], {type: 'text/plain'});

export const GL_TRANSACTION_INPUT_MODE = Object.freeze([
  {
    label: 'Manual',
    value: 'MANUAL'
  }, {
    label: 'Batch',
    value: 'BATCH'
  }
]);

export const GL_TRANSACTION_TYPE = Object.freeze([
  {
    label: 'Contingent',
    value: 'CONTINGENT'
  }, {
    label: 'Normal',
    value: 'NORMAL'
  }
]);

export const NON_CONTINGENT_ACCOUNT_GROUP = Object.freeze(['ASSET', 'LIABILITY', 'CAPITAL', 'INCOME', 'EXPENSE', 'VIRTUAL']);

export interface SimulateGlTransactionResult {
  lineNo: number;
  branchId?: number;
  account?: LedgerAccount;
  debitAmount: number;
  creditAmount: number;
  remarks?: string
  errors?: string
}
