import nxModule from 'nxModule';

const templateUrl = require('./sub-header.template.html');
nxModule.component('subHeader', {
  templateUrl,
  transclude: true,
  bindings: {
    label: '<',
    header: '<',
    tab: '<'
  }
});