import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {listRoute} from 'custom-field/CustomFieldRoutes';
import LinkList, {LinkListRoute} from 'LinkList';
import React, {ReactElement} from 'react';

const links: LinkListRoute[] = [
  {
    name: 'Types',
    path: '/admin/term-deposits/types'
  },
  listRoute('/admin/term-deposits', CustomFieldGroup.DEPOSIT, false),
];

const DepositsConfiguration = () : ReactElement => <LinkList links={links}  header="Term deposits configuration"/>;

export default DepositsConfiguration;