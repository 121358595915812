import {
  AdvanceInterestLedger,
  AdvanceInterestLedgerEntry
} from 'components/administration/term-deposit/common/term-deposit.types';
import nxModule from 'nxModule';
import templateUrl from './advance-interest-ledger.template.html';
import BigNumber from "bignumber.js";

interface AdvanceInterestLedgerSummary {
  term: number;
  netInterest: BigNumber;
  netWithholdingTax: BigNumber;
}

class AdvanceInterestLedgerComponent {
  protected ledger!: AdvanceInterestLedger;
  protected totalAdvanceInterest!: number;
  protected totalWithholdingTax!: number;
  protected totalTerm!: number;

  $onInit(): void {
    const totalInterest : AdvanceInterestLedgerSummary = this.ledger.entries
      .map((e: AdvanceInterestLedgerEntry) : AdvanceInterestLedgerSummary => {
        return {
          term: e.term,
          netInterest: new BigNumber(e.interestAmount),
          netWithholdingTax: new BigNumber(e.withholdingTax)
        }
      }).reduce((sum: AdvanceInterestLedgerSummary, currentValue: AdvanceInterestLedgerSummary) : AdvanceInterestLedgerSummary => {
        return {
          term: sum.term + currentValue.term,
          netInterest: sum.netInterest.plus(currentValue.netInterest),
          netWithholdingTax: sum.netWithholdingTax.plus(currentValue.netWithholdingTax)
        }
      });
    this.totalAdvanceInterest = totalInterest.netInterest.toNumber();
    this.totalWithholdingTax = totalInterest.netWithholdingTax.toNumber();
    this.totalTerm = totalInterest.term;
  }
}

nxModule.component('advanceInterestLedger', {
  templateUrl: templateUrl,
  bindings: {
    ledger: '<'
  },
  controller: AdvanceInterestLedgerComponent
});
