import nxModule from 'nxModule';
import templateUrl from './update-product-custom-fields.template.html';
import _ from 'lodash';
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {AttachedFile} from "components/service/file.service";


interface ExecutionError {
  agentId : number;
  registered : boolean;
  username : string;
  password : string;
  rejectionReason : string | null;
}
class UpdateProductCustomFields {
  private contentFile: AttachedFile[] = [];

  private readonly fileExample = [
    'product_number,custom_field_name,custom_field_value',
    '001-0100-00001-0,MIS Group 2,Jewelry Loan'
  ].join('\n')

  private readonly sampleFile = new Blob([this.fileExample], {type: 'text/csv'});

  constructor(private confirmationTemplate: ConfirmationTemplate, private command: CommandService, private $route: NxRouteService, private popup: Popup) {
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to process uploaded custom fields tagging?'
    });

    if (confirmed) {
      const executed = await this.command.execute<{fileId: number}, {executionErrors: ExecutionError[]}>('UpdateProductCustomFields', {
        fileId: this.contentFile[0].id
      }).toPromise();

      const executionErrors = executed.output.executionErrors;
      if (!_.isEmpty(executionErrors)) {
        const header = '<p><span class="red"><strong>WARNING!</strong> Custom Field listed below resulted in errors and will not be updated:</span></p>';
        const body: string[] = [];
        _.forEach(executionErrors, err => {
          body.push(...Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
        });
        const errorDetails = [header, ...body].join('');
        this.popup({
          header: 'Upload result',
          text: errorDetails,
          renderHtml: true
        });
      }
      this.$route.reload();
    }
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'UpdateProductCustomFields.csv';
    a.click();
  }
}

nxModule.component('updateProductCustomFields', {
  templateUrl,
  controller: UpdateProductCustomFields
});
