import nxModule from 'nxModule';
import $ from 'jquery';

import templateUrl from './batch-sss-pension.template.html';

class BatchSSSPension {
  constructor(sssPensionService) {
    this.sssPensionService = sssPensionService;
    this.fetchSummary();

    this.gotoUploadResult = async () => {
      $('[data-target="#tab-results"]').tab('show');
      this.summary = [];
      this.fetchSummary();
    };
  }

  async fetchSummary() {
    this.summary = await this.sssPensionService.getSummary();
  }
}


nxModule.component('batchSssPension', {
  templateUrl,
  controller: BatchSSSPension
});
