import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './miscellaneous-transactions.template.html';

class MiscellaneousTransactions {
  constructor($location, $filter, http, confirmation, authentication, workingDaysCache, notification,
              loader, ledgerActionTemplateCache, commandMetadataCache, command, actionCommand, commandAccessChecker, branchService) {
    this.$location = $location;
    this.$filter = $filter;
    this.http = http;
    this.confirmation = confirmation;
    this.authentication = authentication;
    this.workingDaysCache = workingDaysCache;
    this.notification = notification;
    this.loader = loader;
    this.ledgerActionTemplateCache = ledgerActionTemplateCache;
    this.commandMetadataCache = commandMetadataCache;
    this.command = command;
    this.actionCommand = actionCommand;
    this.commandAccessChecker = commandAccessChecker;
    this.branchService = branchService;

    this.miscTransactionRouter = {
      BATCH_CHECK_DEPOSIT_TO_BANK: 'check-deposit-to-bank',
      CHECK_IN: 'funds-movement/check-in',
      CHECK_OUT: 'funds-movement/check-out',
      BATCH_CHECK_IN: 'funds-movement/batch-check-in',
      BATCH_CHECK_TRANSFER_TO_CASHIER: 'check-transfer-to-cashier',
      CLOSE_COUNTER: 'close-counters',
      END_DAY: `end-the-day`,
      MISC_CASH_DISBURSEMENT: 'misc-cash-disbursement',
      POS_CASH_WITHDRAWAL: 'pos-cash-withdrawal',
      START_DAY: `start-the-day`,
    };

    this.commandRoutes = [
      {
        commandName: 'BatchStartDay',
        name: 'Centralized SOD',
        path: 'batch-start-the-day'
      },
      {
        commandName: 'BatchEndDay',
        name: 'Centralized EOD',
        path: 'batch-end-the-day'
      },
      {
        commandName: 'EndDayWithCloseCounterAndStartDay',
        name: 'End the Day with automatic close counter and start of day',
        path: 'end-the-day-with-auto-cc-sod'
      },
      {
        commandName: 'MiscCashIn',
        name: 'Cash in',
        path: 'cash-in'
      },
      {
        commandName: 'MiscInterbranchCashIn',
        name: 'Interbranch cash in',
        path: 'interbranch-cash-in'
      },
      {
        commandName: 'MiscCashOut',
        name: 'Cash out',
        path: 'cash-out'
      },
      {
        commandName: 'MiscInterbranchCashOut',
        name: 'Interbranch cash out',
        path: 'interbranch-cash-out'
      },
      {
        commandName: 'MiscCheckIn',
        name: 'Misc check in',
        path: 'funds-movement/check-in'
      },
      {
        commandName: 'MiscBatchCheckIn',
        name: 'Misc multiple check in',
        path: 'funds-movement/batch-check-in'
      },
      {
        commandName: 'MiscCheckOut',
        name: 'Misc check out',
        path: 'funds-movement/check-out'
      },
      {
        commandName: 'MiscAtmCashIn',
        name: 'ATM Cash in',
        path: 'atm-cash-in'
      },
      {
        commandName: 'MiscAtmCashOut',
        path: 'atm-cash-out'
      },
      {
        commandName: 'MiscAtmCashWithdrawal',
        name: 'ATM Cash withdrawal',
        path: 'atm-cash-withdrawal'
      },
      {
        commandName: 'MiscCashTransferToTeller',
        name: 'Cash transfer to teller',
        path: 'cash-transfer-to-teller'
      },
      {
        commandName: 'MiscCashTransferToCashier',
        name: 'Cash transfer to cashier',
        path: 'cash-transfer-to-cashier'
      },
      {
        commandName: 'MiscInterbranchCashTransfer',
        name: 'Interbranch cash transfer',
        path: 'interbranch-cash-transfer'
      },
      {
        commandName: 'MiscPosCashWithdrawal',
        name: 'POS cash withdrawal',
        path: 'pos-cash-withdrawal'
      },
      {
        commandName: 'MiscCashToBank',
        name: 'Cash deposit to depository bank',
        path: 'cash-to-depository-bank'
      },
      {
        commandName: 'MiscInterbranchCashToBank',
        name: 'Interbranch cash deposit to depository bank',
        path: 'interbranch-cash-to-depository-bank'
      },
      {
        commandName: 'MiscCashFromBank',
        name: 'Cash withdrawal from depository bank',
        path: 'cash-withdrawal-from-depository-bank'
      },
      {
        commandName: 'MiscInterbranchCashFromBank',
        name: 'Interbranch cash withdrawal from depository bank',
        path: 'interbranch-cash-withdrawal-from-depository-bank'
      },
      {
        commandName: 'MiscBatchCheckDepositToBank',
        name: 'Batch check deposit to depository bank',
        path: 'batch-check-deposit-to-bank'
      },
      {
        commandName: 'MiscInterbranchBatchCheckDepositToBank',
        name: 'Interbranch batch check deposit to depository bank',
        path: 'interbranch-batch-check-deposit-to-bank'
      },
      {
        commandName: 'MiscBatchCheckDepositToBranch',
        name: 'Batch check deposit to branch',
        path: 'batch-check-deposit-to-branch'
      },
      {
        commandName: 'MiscCreateCashiersCheck',
        name: 'Create cashiers check',
        path: 'create-cashiers-check',
      },
      {
        commandName: 'MiscEncashCashiersCheck',
        name: 'Encash cashiers check',
        path: 'encash-cashiers-check',
      },
      {
        commandName: 'MiscInterbranchCreateCashiersCheck',
        name: 'Interbranch create cashiers check',
        path: 'interbranch-create-cashiers-check',
      },
      {
        commandName: 'MiscInterbranchEncashCashiersCheck',
        name: 'Interbranch encash cashiers check',
        path: 'interbranch-encash-cashiers-check',
      },
      {
        commandName: 'PayOnlineSSS',
        path: 'sss-online-payment',
      },
      {
        commandName: 'PayOfflineSSS',
        path: 'sss-offline-payment',
      },
      {
        commandName: 'GenerateIndividualNewPrnSss',
        name: 'SSS - Generate payment reference number',
        path: 'sss-change-individual-prn',
      },
      {
        commandName: 'ScheduleAccountingPeriodClosure',
        path: 'schedule-accounting-period-closure',
      },
      {
        commandName: 'MiscOfficialReceiptInCash',
        name: 'Official receipt in cash',
        path: 'official-receipt-in-cash'
      },
      {
        commandName: 'MiscOfficialReceiptInCheck',
        name: 'Official receipt in check',
        path: 'official-receipt-in-check'
      },
      {
        commandName: 'MiscInterbranchOfficialReceiptInCash',
        name: 'Interbranch official receipt in cash',
        path: 'interbranch-official-receipt-in-cash'
      },
      {
        commandName: 'MiscInterbranchOfficialReceiptInCheck',
        name: 'Interbranch official receipt in check',
        path: 'interbranch-official-receipt-in-check'
      },
      {
        commandName: 'MiscTellerCashOverage',
        name: 'Teller cash overage',
        path: 'teller-cash-overage'
      },
      {
        commandName: 'MiscTellerCashShortage',
        name: 'Teller cash shortage',
        path: 'teller-cash-shortage'
      },
      {
        commandName: 'MiscCashierCashOverage',
        name: 'Cashier cash overage',
        path: 'cashier-cash-overage'
      },
      {
        commandName: 'MiscCashierCashShortage',
        name: 'Cashier cash shortage',
        path: 'cashier-cash-shortage'
      }
    ];

    this.fetchData();
  }

  async fetchData() {
    this.workingDay = null;
    this.filteredTransactions = [];
    this.transactions = [];

    const [templates, workingDays, commands, canExecuteCommand, branches] = await Promise.all([
      this.ledgerActionTemplateCache.toPromise(),
      this.workingDaysCache.toPromise(),
      this.commandMetadataCache.toPromise(),
      this.commandAccessChecker.canExecuteCommandAsync(),
      this.branchService.toPromise()
    ]);

    const additionalRouteBasedCommands = this.commandRoutes.map(route => {
      const command = commands.find(command => command.alias === route.commandName);
      return {
        command,
        route,
      };
    }).filter(cmd => cmd.command);

    this.branch = branches.find(b => b.id === this.authentication.context.branchId);
    // Find working day for current branch
    this.workingDay = _.find(workingDays, {branchId: this.authentication.context.branchId});
    // Check & mark if transaction is available
    // Transaction is available if: working day status matches & transaction is enabled
    _.forEach(templates, (t) => {
      let commandName = this.actionCommand.actionTypeToCommand(t.type);
      Object.assign(t, {
        active: this.transactionActive(t),
        canExecute: canExecuteCommand(commandName),
        redirectUrl: this.miscTransactionRouter[t.type],
        commandName: commandName
      });
    });

    const fullDetailsCommands = _.flatMap(additionalRouteBasedCommands, commandRoute => {
      const {command, route} = commandRoute;
      return {
        ...command,
        active: true,
        canExecute: canExecuteCommand(command.alias),
        commandName: command.alias,
        redirectUrl: route.path,
        isCommand: true,
        name: this.$filter('translateEnum')(command.alias, 'COMMAND') || route.name
      };
    });

    this.disableSodAndCCForAutoEodWithCcAndSod(templates, fullDetailsCommands);

    const enabledTransactions = templates.filter(template => template.enabled);
    this.transactions = [...enabledTransactions, ...fullDetailsCommands];
    this.filteredTransactions = _.filter(this.transactions, (t) => t.canExecute);
  }

  /**
   * When EndDayWithCloseCounterAndStartDay is available for use, then this must be used instead of the individual
   * StartDay, CloseCounter and EndDay to avoid breaking the process flow per business requirements.
   * */
  disableSodAndCCForAutoEodWithCcAndSod(templates, fullDetailsCommands) {
    const endDayWithCcAndSodTemplate = fullDetailsCommands.find(c => c.commandName === 'EndDayWithCloseCounterAndStartDay');
    if (!endDayWithCcAndSodTemplate) {
      return;
    }

    endDayWithCcAndSodTemplate.enabled = this.branch.automaticCloseCounter || this.branch.automaticStartDay;

    templates.filter(t => ['END_DAY', 'CLOSE_COUNTER', 'START_DAY'].includes(t.type))
      .forEach(t => {

        // type must be enabled depending on the branch config
        switch (t.type) {
          case 'CLOSE_COUNTER':
            t.enabled = !this.branch.automaticCloseCounter;
            break;
          case 'START_DAY':
            // additional checking for workingDay.status here because there's a possibility
            // the a branch starts with PENDING status, and the command EndDayWithCloseCounterAndStartDay
            // doesn't start with StartDay execution, thus a possibility to start the day manually
            // is needed in this case.
            t.enabled = !this.branch.automaticStartDay || ['PENDING', 'START_DAY_PROCESSING'].includes(this.workingDay.status);
            break;
          case 'END_DAY':
            t.enabled = !this.branch.automaticCloseCounter && !this.branch.automaticStartDay;
            break;
          default:
            console.error(`Unsupported ActionTemplate type ${t.type}.`);
        }
      });
  }

  transactionActive(t) {
    if(!t?.enabled) {
      return false;
    }

    if(!t.workingDayStatus || !this.workingDay) {
      return false;
    }

    if(this.workingDay.status === t.workingDayStatus) {
      return true;
    }

    // when we press start of day we still want to be able to see start of day progress
    return t.type === 'START_DAY' && this.workingDay.status === 'START_DAY_PROCESSING';
  };

  async redirectToTransaction(t) {
    const redirect = () => this.$location.path(`/dashboard/miscellaneous-transactions/${t.redirectUrl}`);

    if (t.commandName) {
      const canExecuteCommand = await this.commandAccessChecker.canExecuteCommandAsync();
      if (canExecuteCommand(t.commandName)) redirect();
    } else if (t.active && t.canExecute) {
      redirect();
    }
  };
}

nxModule.component('miscellaneousTransactions', {
  templateUrl,
  controller: MiscellaneousTransactions
});
