import React, {ReactElement} from 'react';
import {Redirect} from 'react-router';

interface State {
  routeInitialized: boolean;
}

class RouteFallback extends React.Component<Record<string, unknown>, State> {
  private routeListenerDeregistration: { (): void } | null = null;

  constructor(props: Record<string, unknown>) {
    super(props);

    this.state = {
      routeInitialized: false
    };
  }

  componentDidMount(): void {
    const route = window.nx.$route.current;
    if (!route) {
      console.warn('Waiting for route initialization');
      this.routeListenerDeregistration = window.nx.$rootScope.$on('$routeChangeSuccess', () => {
        console.warn('Route initialized');
        this.setState({
          routeInitialized: true
        });

        this.routeListenerDeregistration?.();
      });
    } else {
      this.setState({
        routeInitialized: true
      });
    }
  }

  componentWillUnmount(): void {
    this.routeListenerDeregistration?.();
  }

  render(): ReactElement {
    const route = window.nx.$route.current;
    if (!this.state.routeInitialized || !route.errorPage) {
      return <React.Fragment/>;
    }

    return <Redirect to="/dashboard/lookup-customer"/>;
  }
}

export default RouteFallback;