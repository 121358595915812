import nxModule from 'nxModule';
import ParameterizedLocalCache from '../../../../shared/utils/parameterized-local-cache';

nxModule.factory('borrowerCreditLinesCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (borrowerId) => http.get(`/credit-lines?borrowerId=${borrowerId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.borrowerCreditLines,
    cacheName: 'borrowerCreditLines'
  })
);