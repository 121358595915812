import nxModule from 'nxModule';
import angular from 'angular';

class DynamicReportFileService {
  constructor(http) {
    this.http = http;
  }

  getReportFile(reportId, reportFileId) {
    return this.http.get(`/reports/dynamic-report/${reportId}/file/${reportFileId}`).toPromise();
  }

  uploadMapping(reportId, reportFileId, file) {
    const fd = new FormData();
    fd.append('file', file);

    return this.http.post(`/reports/dynamic-mapping-upload?reportFileId=${reportFileId}&reportId=${reportId}`, fd, {
      transformRequest: angular.identity,
      nxLoaderText: 'Uploading file...',
      headers: {'Content-Type': undefined}
    }).toPromise();
  }
}

nxModule.service('dynamicReportFileService', DynamicReportFileService);