import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('ledgerActionTemplateCache', (http, CacheFactory, config) =>
  new LocalCache({
      provider: () => http.get(`/ledger/actions/templates`),
      cacheFactory: CacheFactory,
      cacheTime: config.cache.ledgerActionTemplate,
      cacheName: `ledgerActionTemplate`
    }
  )
);
