import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./amortization-inline-panel.template.html');
nxModule.component('amortizationInlinePanel', {
    templateUrl: templateUrl,
    bindings: {
      'item' : '=',
      'selectedLoan' : '<',
      'onHide': '&',
      'onlyData' : '<'
    },
    controller: function ($route, $location, $scope, http, breadcrumbs, customerCache, notification,
                          userCache, glMappingsService, loanProductsCache, $filter) {
      let that = this;
      let customerId = $route.current.params['customerId'];


      that.keyValueDetails = _.memoize(amortizationLineItem => {
        return [
          {
            label: 'Outstanding principal',
            value: `${$filter('nxCurrency')(amortizationLineItem.principalBalance)} / ${$filter('nxCurrency')(amortizationLineItem.principalAmount)}`,
          }, {
            label: 'Outstanding interests',
            value: `${$filter('nxCurrency')(amortizationLineItem.interestBalance)} / ${$filter('nxCurrency')(amortizationLineItem.interestAmount)}`,
          }, {
            label: 'Outstanding CBU',
            value: `${$filter('nxCurrency')(amortizationLineItem.cbuChargeBalance)} / ${$filter('nxCurrency')(amortizationLineItem.cbuChargeAmount)}`,
          }, {
            label: 'Outstanding PF',
            value: `${$filter('nxCurrency')(amortizationLineItem.pfChargeBalance)} / ${$filter('nxCurrency')(amortizationLineItem.pfChargeAmount)}`,
          }, {
            label: 'Outstanding TP',
            value: `${$filter('nxCurrency')(amortizationLineItem.tpChargeBalance)} / ${$filter('nxCurrency')(amortizationLineItem.tpChargeAmount)}`,
          }, {
            label: 'Outstanding custom fees',
            value: `${$filter('nxCurrency')(amortizationLineItem.customFeesBalance)} / ${$filter('nxCurrency')(amortizationLineItem.customFeesAmount)}`,
          }, {
            label: 'Outstanding penalties',
            value: `${$filter('nxCurrency')(amortizationLineItem.penaltyBalance)} / ${$filter('nxCurrency')(amortizationLineItem.penaltyAmount)}`,
          }, {
            label: 'Outstanding maturity penalties',
            value: `${$filter('nxCurrency')(amortizationLineItem.penaltyMaturityBalance)} / ${$filter('nxCurrency')(amortizationLineItem.penaltyMaturityAmount)}`,
          }, {
            label: 'Outstanding past-due Interest',
            value: `${$filter('nxCurrency')(amortizationLineItem.pastDueInterestBalance)} / ${$filter('nxCurrency')(amortizationLineItem.pastDueInterestAmount)}`,
          }, {
            label: 'Outstanding past-due maturity Interest',
            value: `${$filter('nxCurrency')(amortizationLineItem.pastDueMaturityInterestBalance)} / ${$filter('nxCurrency')(amortizationLineItem.pastDueMaturityInterestAmount)}`,
          }, {
            label: 'Temporary receipt',
            value: amortizationLineItem.temporaryReceipt,
          }, {
            label: 'Total outstanding amount',
            value: `${$filter('nxCurrency')(amortizationLineItem.amortizationBalance)} / ${$filter('nxCurrency')(amortizationLineItem.amortizationAmount)}`,
          }
        ];
      });

      that.keyValueDetails.cache = new WeakMap();

      that.waiveDisabled = (amortizationId) => {
        const amortization = _.find(that.selectedLoan.amortizationSchedule.list, a => a.id == amortizationId);
        return amortization?.amortizationBalance === 0;
      };

      that.isLoanActive = () => {
        return !['INACTIVE', 'PENDING', 'CLOSED', 'ROPA',].includes(that.selectedLoan.status);
      };

      that.waive = (amortizationId) => $location.path(`/customer/${customerId}/loans/${that.selectedLoan.id}/amortization/${amortizationId}/waive`);
    }
  }
);
