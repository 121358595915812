import nxModule from 'nxModule';
import templateUrl from './upload-custom-product-categories.template.html';
import {ProductGroup} from './../../../service/product.types';
import {NxRouteService} from "routes/NxRouteService";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CommandService} from "shared/utils/command/command.types";
import {AttachedFile} from "components/service/file.service";

class UploadCustomProductCategories {
  protected group : ProductGroup = 'LOAN';
  protected contentFile: AttachedFile[] = [];
  protected groupOptions = ['ACCOUNT', 'DEPOSIT', 'LOAN' ,'CUSTOMER'];

  constructor(private confirmationTemplate: ConfirmationTemplate, private command: CommandService, private $route: NxRouteService) { }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to process uploaded product categories?',
    });

    if(confirmed) {
      await this.command.execute('BatchUploadCustomCategories', {
        fileId: this.contentFile[0].id,
        group: this.group
      }).toPromise();
      this.$route.reload();
    }
  }
}

nxModule.component('uploadCustomProductCategories', {
  templateUrl,
  controller: UploadCustomProductCategories
});
