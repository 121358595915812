import RouteDefinition from "routes/RouteDefinition";
import DepositoryAccountList from "check/DepositoryAccountList";
import axios from "axios";
import DepositoryAccount from "check/DepositoryAccountTypes";
import CheckbookList from "check/managment/CheckbookList";
import CreateCheckbookBatch from "check/managment/CreateCheckbookBatch";
import {Checkbook} from "check/CheckbookType";
import CheckList from "check/managment/CheckList";
import AssignedChecksBranchList from "check/check-preparation/AssignedChecksBranchList";
import OutgoingChecksBranchList from "check/check-preparation/OutgoingChecksBranchList";
import AssignedChecksListContainer from "check/check-preparation/AssignedChecksList";
import OutgoingChecksList from "check/check-preparation/OutgoingChecksList";
import BranchService from "management/BranchService";
import CheckPreparationHistory from "check/preparation/history/CheckPreparationHistory";

const branchService = new BranchService();
const routes: RouteDefinition[] = [
  {
    name: 'Check Preparation',
    path: '/check-preparation'
  },
  {
    name: 'Check Management',
    path: '/check-preparation/check-management',
  },
  {
    name: 'Depository Accounts',
    path: '/check-preparation/check-management/depository-accounts',
    Component: DepositoryAccountList
  },
  {
    name: async ({depositoryAccountId}: {depositoryAccountId: string}): Promise<string> => {
      const {data: accounts} = await axios.get<DepositoryAccount[]>(`/ledger/depository-accounts`);
      const depositoryAccount = accounts.find(acc => String(acc.id) === depositoryAccountId);
      return depositoryAccount?.accountName ?? '??';
    },
    path: '/check-preparation/check-management/depository-accounts/:depositoryAccountId',
  },
  {
    name: 'Checkbooks',
    path: '/check-preparation/check-management/depository-accounts/:depositoryAccountId/checkbooks',
    Component: CheckbookList
  },
  {
    name: 'New',
    path: '/check-preparation/check-management/depository-accounts/:depositoryAccountId/checkbooks/new',
    Component: CreateCheckbookBatch,
    commandAccess: 'BatchCreateCheckbook'
  },
  {
    name: async ({checkbookId}: {checkbookId: string}): Promise<string> => {
      const {data: checkbook} = await axios.get<Checkbook>(`/checks/checkbooks/${checkbookId}`);
      return checkbook?.number?.toString() ?? '??';
    },
    path: '/check-preparation/check-management/depository-accounts/:depositoryAccountId/checkbooks/:checkbookId',
    Component: CheckList
  },
  {
    name: 'Assigned Checks',
    path: '/check-preparation/assigned-checks',
    Component: AssignedChecksBranchList
  },
  {
    name: 'Outgoing Checks',
    path: '/check-preparation/outgoing-checks',
    Component: OutgoingChecksBranchList
  },
  {
    name: async ({branchId}: {branchId: string}): Promise<string> => {
      const branch = await branchService.read(Number(branchId));
      return branch.name ?? '??';
    },
    path: '/check-preparation/assigned-checks/:branchId',
    Component: AssignedChecksListContainer
  },
  {
    name: async ({branchId}: {branchId: string}): Promise<string> => {
      const branch = await branchService.read(Number(branchId));
      return branch.name ?? '??';
    },
    path: '/check-preparation/outgoing-checks/:branchId',
    Component: OutgoingChecksList
  },
  {
    name: 'Check Preparation - History',
    path: '/check-preparation/history',
    Component: CheckPreparationHistory
  }
];

export default routes;
