import nxModule from 'nxModule';
import _ from "lodash";

const templateUrl = require('./external-accounts.template.html');
nxModule.component('externalAccounts', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '<',
    'minAccounts': '<'
  },
  controller: function ($scope, $timeout, dict) {
    let that = this;
    that.externalAccount = {};
    that.banks = [];
    that.banksMap = {};

    that.setupBanksInExternalAccounts = () => {
      for (let bank of that.banks) {
        that.banksMap[bank.id] = bank;
      }
    };

    dict.onLoadingComplete(() => {
      that.banks = dict['BANK'];
      that.setupBanksInExternalAccounts();
    });

    that.bankSelectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    that.add = () => {
      if (!that.profile.externalAccounts) {
        that.profile.externalAccounts = [];
      }
      const bank = that.banks.find(it => it.id == that.externalAccount.bankId);
      that.externalAccount.bank = bank;
      that.profile.externalAccounts.push(that.externalAccount);
      that.externalAccount = {};
    };

    that.remove = (externalAccount) => {
      let findIndex = _.findIndex(that.profile.externalAccounts, externalAccount);
      if(findIndex > -1) {
        that.profile.externalAccounts.splice(findIndex, 1);
      }
    };

    that.$onDestroy = () => {
    };
  }
});
