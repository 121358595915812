
interface LoanBatchFileDefinition {
  label:string;
  value:string;
  useSeparator:boolean;
  sampleFile:Blob;
  sampleFilename:string;

}

export class LoanBatchUploadFileType  {

  static readonly NEXTBANK = new LoanBatchUploadFileType({
    label: 'Nextbank',
    value: 'NEXTBANK',
    useSeparator: true,
    sampleFile: new Blob([`Loan_No,Amount
111-PRD-111111-1,1121.0`], {type: 'text/csv'}),
    sampleFilename: 'SampleFile.csv'
  });

  static readonly ROBINSONS = new LoanBatchUploadFileType({
    label: "Robinson's",
    value: 'ROBINSONS',
    useSeparator: false,
    sampleFile: new Blob(["PHIL-7198-11-1      Suratos,Myrna,R               000000000242138"], {type: 'text'}),
    sampleFilename: 'SampleFile.txt'
  });

  static readonly NEXTBANK_CUSTOM_PAYMENT = new LoanBatchUploadFileType({
    label: 'Nextbank - custom payment',
    value: 'NEXTBANK_CUSTOM_PAYMENT',
    useSeparator: true,
    sampleFile: new Blob([`Loan_No,Principal_Amount,Interest_Amount,Penalty_Amount,Past_Due_Interest_Amount,CBU_Charge_Amount,PF_Charge_Amount,TP_Charge_Amount,Custom_Fees_Amount
111-PRD-111111-1,1000.0,2000.0,3000.0,4000.0,5000.0,6000.0,7000.0,0`], {type: 'text/csv'}),
    sampleFilename: 'SampleFile.csv'
  });

  static readonly RCBC_CASA = new LoanBatchUploadFileType({
    label: 'RCBC CASA',
    value: 'RCBC_CASA',
    useSeparator: true,
    sampleFile: new Blob([`Finacle_Account_No,Amount
0000000100520231,1121.0`], {type: 'text/csv'}),
    sampleFilename: 'SampleFile.csv'
  });

  static readonly FILE_TYPES = Object.freeze([
    LoanBatchUploadFileType.NEXTBANK,
    LoanBatchUploadFileType.ROBINSONS,
    LoanBatchUploadFileType.NEXTBANK_CUSTOM_PAYMENT,
    LoanBatchUploadFileType.RCBC_CASA
  ]);


  readonly label:string;
  readonly value:string;
  readonly useSeparator:boolean;
  readonly sampleFile:Blob;
  readonly sampleFilename:string;


  private constructor(fileDefinition: LoanBatchFileDefinition) {
    this.label = fileDefinition.label;
    this.value = fileDefinition.value;
    this.useSeparator = fileDefinition.useSeparator;
    this.sampleFile = fileDefinition.sampleFile;
    this.sampleFilename = fileDefinition.sampleFilename;
  }


}

