import nxModule from 'nxModule';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';

const templateUrl = require('./incoming-checks-list.template.html');
nxModule.component('incomingChecksList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation, notification,
                        branchService, command, organizationCache, customerCache) {
    const that = this;

    that.$onInit = async () => {
      const organizations = await organizationCache.toPromise();
      that.rootOrganization = _.find(organizations, {'root': true});
    }

    that.checkStatuses = [
      'PENDING_CUTOFF',
      'PENDING_CLEARING',
      'CLEARED',
      'RETURNED'
    ];

    $scope.$watch('$ctrl.filter.branchId', () => {
      updateDateFilter();
    }, true);

    // Check search criteria
    that.filter = {
      status: null,
      branchId: null,
      dateRange: {from: null, to: null}
    };

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        branchId: that.filter.branchId,
        status: that.filter.status,
        registeredOnFrom: $filter('nxDate')(that.filter.dateRange.from),
        registeredOnTo: $filter('nxDate')(that.filter.dateRange.to),
        clearingGroup: 'OUTWARD'
      });
    };

    const createCheckDetails = (check) => {

      const details = [];
      const pushDetail = (label, value, include = true, nullLabel = '-') => {
        if (include) details.push({label: label, value: value !== '' && value !== null ? value : nullLabel});
      };

      // Prepare check details data
      pushDetail('Check id.', check.id);
      pushDetail('Number', check.number);
      pushDetail('MICR', check.micrNumber);
      pushDetail('Clearing group', $filter('prettyEnum')(check.checkClearingGroup));
      pushDetail('On us', $filter('prettyBoolean')(check.bankId === that.rootOrganization.bankId));
      pushDetail('Status', $filter('prettyEnum')(check.status));
      pushDetail('Post dated', $filter('prettyEnum')(check.postDated.toString()));
      pushDetail('Valid from', $filter('prettyDate')(check.validFrom));
      pushDetail('Amount', $filter('nxCurrency')(check.amount));
      pushDetail('Registered on', $filter('prettyDate')(check.registeredOn));
      pushDetail('Cleared on', $filter('prettyDate')(check.clearedOn));
      pushDetail('Credited on', $filter('prettyDate')(check.creditedOn));
      pushDetail('Initial clearing date', $filter('prettyDate')(check.initialClearingDate));
      pushDetail('Estimated clearing date', $filter('prettyDate')(check.estimatedClearingDate));
      pushDetail('Clearing forced', $filter('prettyBoolean')(check.clearingForced));
      pushDetail('Remarks', check.remarks);

      return details;
    };

    const checkInStatus = (check, statuses) => {
      if (check && check.status && statuses && statuses.length > 0) {
        return _.includes(statuses, check.status);
      }
      return false;
    };

    const createSearchQueryPromise = (params) => {
      const queryParams = convertFilterToQueryString(params);
      return http.get(`/checks/incoming/paged?${queryParams}`, {nxLoaderText: 'Loading checks'}).success(page => {
        that.tableConfig.data = page.result;
        that.tableConfig.total(page.totalCount);
        that.checks = page.result;
        if (that.checks && that.checks.length > 0) {
          _.forEach(that.checks, (check) => {
            check.details = createCheckDetails(check);
            check.canBePostponed = checkInStatus(check, ['PENDING_CUTOFF', 'PENDING_CLEARING']);
          });
        }
      });
    };

    that.itemClicked = (check, $event) => {
      $event.stopPropagation();
      that.selectedCheck = check;
      that.selectedCheckId = check.id;
    };

    that.hideInlinePanel = () => {
      that.selectedCheckId = null;
    };

    that.tableConfig = new NgTableParams({
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => createSearchQueryPromise(params)
    });

    that.onFilterBtnClicked = () => {
      // reset paging right before request (ngTable counts pages starting from 1)
      that.tableConfig.page(1);
      that.filterChecks();
    };

    that.filterChecks = () => {
      that.tableConfig.reload();
      that.hideInlinePanel();
    };

    that.postponeClearing = (check) => {
      confirmation(`Do you want to postpone clearing of check ${check.number} by 1 day?`, async () => {
        await command.execute("PostponeCheckClearing", {id: check.id}).toPromise();
        that.filterChecks();
      });
    };

    that.forceClear = async (check) => {
      const proceed = await confirmation(`Do you want to force clearing of check ${check.number}?`);
      if (proceed) {
        await command.execute("ForceCheckClearing", {checkId: check.id}).toPromise()
        customerCache.refetch();
        that.filterChecks();
      }
    };

    // Read branches available for user
    const branchesSub = branchService.toObservable().subscribe(branches => {

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const systemDate = Date.parse(defaultBranch.systemDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: systemDate, to: systemDate};

      // Load initial checks page
      that.tableConfig.page(1);
      that.tableConfig.reload();
    });

    const updateDateFilter = () => {
      if (!that.filter.branchId) return;
      const selectedBranch = _.find(that.availableBranches, {id: that.filter.branchId});
      const systemDate = Date.parse(selectedBranch.systemDate);
      that.filter.dateRange = {from: systemDate, to: systemDate};
    };

    that.$onDestroy = () => {
      branchesSub.unsubscribe();
    };
  }
});
