import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import nxModule from 'nxModule';


const templateUrl = require('./customer-loans-statement.template.html');
nxModule.component('customerLoansStatement', {
  templateUrl: templateUrl,
  controller: function ($route, systemDateService, reportModal, loanService, authentication) {
    let that = this;

    that.loanId = $route.current.params['loanId'];
    that.customerId = $route.current.params['customerId'];
    that.users = undefined;
    that.allUsers = undefined;

    that.$onInit = async () => {
      const loan = await loanService.getLoanForCustomer(that.customerId, that.loanId);
      that.date = await systemDateService.getSystemDateByBranchId(loan.branchId);
      that.minDate = that.date;
      that.isInterbranch = loan.branchId !== authentication.context.branchId;
    };

    that.displayReport = () => {
      let params = {
        productId: that.loanId,
        date: that.date,
        checkedBy: that.checkedByName,
        approvedBy: that.approvedByName,
        customerId: that.customerId
      };
      let reportName = 'LoanStatementOfAccountReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };
  }
});
