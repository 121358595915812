import nxModule from 'nxModule';
import _ from 'lodash';

import systemPropertyService from '../../react/system/systemPropertyService';

class AnalyticsService {
  // we require external analytics provider
  constructor($rootScope, $location, Analytics, config) {
    this.$rootScope = $rootScope;
    this.analytics = Analytics;
    this.$location = $location;

    const gaEnabled = systemPropertyService.getProperty('GOOGLE_ANALYTICS_ENABLED');
    if (!config.devProfile && gaEnabled === 'TRUE') {
      Analytics.registerScriptTags();
      Analytics.registerTrackers();
      console.log('Google Analytics is enabled');

      this.trackPerformance();
    }
  }

  browserSupportsPerfStats() {
    return window.performance;
  }

  trackPerformance() {
    console.info('Enabled tracking performance');

    let lastChangeRouteStartTime = null;
    this.$rootScope.$on('$routeChangeStart', (event, next, current) => {
      if(this.browserSupportsPerfStats()) {
        lastChangeRouteStartTime = window.performance.now();
      }
    });

    this.$rootScope.$on('$routeChangeSuccess', (event, current, previous) => {
      // set current path here, so that it's not needed to pass it later with every event
      this.analytics.set('page', this.$location.url());

      if(!this.reportedInitialPageLoad) {
        this.reportedInitialPageLoad = true;
        this.trackInitialPageLoad();
      }

      if(lastChangeRouteStartTime && this.browserSupportsPerfStats()) {
        this.analytics.trackTimings('pageLoad', 'routeLoadTime', Math.round(window.performance.now() - lastChangeRouteStartTime));
      }

      lastChangeRouteStartTime = null;
    });

    this.$rootScope.$on('$routeChangeError', (angularEvent, current, previous, rejection) => {
      this.analytics.trackException(rejection.stack, true, {
        page: this.$location.url(),
        nonInteraction: true
      });

      lastChangeRouteStartTime = null;
    });
  }

  trackInitialPageLoad() {
    const timings = _.get(window, 'performance.timing', null);
    if(!timings) {
      return;
    }

    const navigationStart = timings.navigationStart;

    const pageLoadTime = timings.loadEventStart - navigationStart;
    const dns = timings.domainLookupEnd - timings.domainLookupStart;
    const tcp = timings.connectEnd - timings.connectStart;
    const srt = timings.responseStart - timings.requestStart;
    const pdt = timings.responseEnd - timings.responseStart;
    const rrt = timings.fetchStart - navigationStart;
    const dit = timings.domInteractive - navigationStart;
    const clt = timings.domContentLoadedEventStart - navigationStart;

    this.analytics.trackTimings('pageLoad', 'pageLoadTime', pageLoadTime);
    this.analytics.trackTimings('pageLoad', 'dns', dns);
    this.analytics.trackTimings('pageLoad', 'tcp', tcp);
    this.analytics.trackTimings('pageLoad', 'serverResponseTime', srt);
    this.analytics.trackTimings('pageLoad', 'pageDownloadTime', pdt);
    this.analytics.trackTimings('pageLoad', 'roundTripTime', rrt);
    this.analytics.trackTimings('pageLoad', 'domInteractive', dit);
    this.analytics.trackTimings('pageLoad', 'contentLoaded', clt);
  }
}

nxModule.service('analyticsService', AnalyticsService);