import nxModule from 'nxModule';
import {confirmationMessage} from '../sss/sss.utils';

import templateUrl from './sss-online-individual-payment.template.html';

class SssOnlineIndividualPayment {
  constructor(http, command, popup,  confirmation, $filter, systemDateService) {
    this.http = http;
    this.command = command;
    this.popup = popup;
    this.confirmation = confirmation;
    this.$filter = $filter;
    this.paymentReferenceNumber = null;

    systemDateService.getCurrentUserBranchSystemDate().then(date => this.systemDate = date);
  }

  onPrnChanged() {
    this.birthDate = null;
    this.ssNumber = null;
    this.payment = null;
  }

  fetchByPrn() {
    this.http.get(`/sss/online-payment/payor-kind/INDIVIDUAL/prn/${this.paymentReferenceNumber}`)
      .success(payment => {
        this.payment = payment;
      })
      .error(err => {
        this.popup({text: err.errorMessage, header: 'Error'});
      });
  }

  async save() {
    const message = confirmationMessage({
      amount: this.payment.amount,
      startingPeriod: this.payment.startingPeriod,
      endingPeriod: this.payment.endingPeriod,
      moneyFormatter: this.$filter('nxCurrency')
    });

    const proceed = await this.confirmation(message, true);
    if (proceed) {
      await this.command.execute('PayOnlineSSS', {
        "ssNumber": this.payment.ssNumber,
        "paymentReferenceNumber": this.payment.paymentReferenceNumber,
        "amount": this.payment.amount,
        "startingPeriod": this.payment.startingPeriod,
        "endingPeriod": this.payment.endingPeriod,
        "paymentDate": this.systemDate.format('YYYY-MM-DDTHH:mm:ss')
      }).toPromise();

      this.onSaveAction();
    }
  }
}


nxModule.component('sssOnlineIndividualPayment', {
  templateUrl,
  bindings: {
    'payorKind': '=',
    'onSaveAction': '<',
    'cancelAction': '<',
  },
  controller: SssOnlineIndividualPayment
});
