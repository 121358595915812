export const addressTypes = Object.freeze([
  'PERMANENT',
  'PRESENT',
  'BUSINESS'
]);

export const ownershipTypes = Object.freeze([
  'OWNED',
  'RENTED',
  'MORTGAGED',
  'SHARED'
]);
