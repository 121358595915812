import React, {ReactElement, ReactNode} from "react";
import MuiAlert, {Color} from '@material-ui/lab/Alert'
import styles from './Alert.scss';

export interface AlertProps {
  children: ReactNode;
  severity?: Color;
}

const Alert = (props: AlertProps): ReactElement => {
  return <MuiAlert severity={props.severity} className={styles.alert}>
    {props.children}
  </MuiAlert>;
};

export default Alert;