import nxModule from 'nxModule';

import templateUrl from './compliance-config-profile-section.template.html';

class ComplianceConfigProfileSection {

}

nxModule.component('complianceConfigProfileSection', {
  templateUrl,
  bindings: {
    complianceConfig: '<'
  },
  controller: ComplianceConfigProfileSection
});
