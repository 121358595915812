import nxModule from 'nxModule';

// TODO: add check withdrawal support

const templateUrl = require('./check-form.template.html');
nxModule.component('checkForm', {
  templateUrl: templateUrl,
  bindings: {
    'checkModel': '=',
    'productId': '=',
    'entryType': '=',
    'disableAmountInput': '<',
    'onUsCheckAllowed': '<',
    'withoutRemarks': '<'
  },
  controller: function ($scope, $location, http, dict) {
    let that = this;
    that.banks = [];

    $scope.$watch('productId', () => {
      if (that.checkModel) that.checkModel.productId = that.productId;
    });
    $scope.$watch('entryType', () => {
      if (that.checkModel) that.checkModel.entryType = that.entryType;
    });
    $scope.$watch('checkModel', () => {
      if (that.checkModel) that.checkModel.entryType = that.entryType;
      if (that.checkModel) that.checkModel.productId = that.productId;
    });

    dict.onLoadingComplete(() => that.banks = dict['BANK']);

    that.onMicrNumberChange = () => {
      if (that.checkModel.micrNumber)
        that.checkModel.checkNumber = that.checkModel.micrNumber.substr(0, 10);
    };

    that.onOnUsCheckChange = () => {
      if (that.checkModel.onUsCheck) {
        that.checkModel.bankId = undefined;
      }
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.selectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

  }
});
