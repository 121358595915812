import nxModule from 'nxModule';
import templateUrl from './batch-memo-history.template.html';
import Authentication from 'shared/utils/authentication';
import {HttpService} from 'shared/utils/httpService';
import {NgTableParams} from 'ng-table';
import {PageResult} from 'tools/HttpTypes';
import ReportService from 'components/report/report.service';

interface BatchMemoHistory {
  commandId: number;
  entryType: 'CREDIT' | 'DEBIT';
  successfulCount: number;
  failedCount: number;
  registrationTime: Date;
  executedBy: string;
  filename: string;
  remarks: string;
}

class BatchMemoHistoryComponent {
  readonly tableConfig: NgTableParams<BatchMemoHistory> = new NgTableParams({
    page: 1,
    count: 20
  }, {
    counts: [],
    total: 0,
    paginationMinBlocks: 3,
    paginationMaxBlocks: 8,
    getData: (params: NgTableParams<BatchMemoHistory>): Promise<BatchMemoHistory[]> => this.fetchData(params)
  });

  totalRowCount!: number;

  constructor(
    private authentication: Authentication,
    private http: HttpService,
    private reportService: ReportService
  ) {}

  async fetchData(params: NgTableParams<BatchMemoHistory>): Promise<BatchMemoHistory[]> {
    const pageNo = params.page() - 1;
    const pageSize = params.count();
    const response = await this.http.post<PageResult<BatchMemoHistory>>(
      `/products/accounts/history/batch-memo`,
      {
        branchId: this.authentication.context.branchId,
        pageNo,
        pageSize
      }
    ).toPromise();
    this.totalRowCount = response.totalCount;
    params.total(this.totalRowCount);
    return response.result;
  }

  async downloadSummaryReport(reportCode: string, filename: string, commandId: number): Promise<void> {
    await this.reportService.downloadXls({
      reportCode: reportCode,
      reportName: filename,
      params: {
        'batchMemoId': commandId
      }
    });
  }
}

nxModule.component('batchMemoHistory', {
  templateUrl,
  controller: BatchMemoHistoryComponent
});
