import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from "bignumber.js";
import templateUrl from './multiple-checks-deposit.template.html';

class CustomerAccountsMultipleChecksDeposit {
  constructor($route, $location, $filter, customerCache, productDefinitionService, confirmation,
              command, dict) {
    this.$filter = $filter;
    this.$location = $location;
    this.command = command;
    this.confirmation = confirmation;
    this.customerCache = customerCache;
    this.dict = dict;

    this.checkFormModel = {};
    this.userChecks = [];
    this.totalAmount = new BigNumber(0);

    this.customerId = $route.current.params['customerId'];
    this.productId = $route.current.params['accountId'];

    customerCache.depositAccounts(this.customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
          depositAccounts.map(da => {
            const p = _.find(products, {id: da.definitionId});
            return Object.assign(da, {
              productName: p ? p.productName : '<Unknown product>'
            });
          })
        // that.productId is a string
      ).subscribe(accounts => this.depositAccount = _.find(accounts, (a) => String(a.id) === this.productId));
  }

  addCheck() {
    // "weak" comparison to avoid the need for casting
    const bankName = _.find(this.dict['BANK'], bank => bank.id == this.checkFormModel.bankId).description;

    this.userChecks.push({
      ..._.clone(this.checkFormModel),
      bankName
    });

    this.updateTotalAmount();
    this.checkFormModel = {};
  }

  removeCheck(checkIndex) {
    this.userChecks.splice(checkIndex, 1);
    this.updateTotalAmount();
  }

  updateTotalAmount() {
    this.totalAmount = 0;
    if (!_.isEmpty(this.userChecks)) {
      this.totalAmount = this.userChecks.reduce((sum, check) => sum.plus(check.amount || 0), new BigNumber(0)).toNumber();
    }
  }

  async deposit() {
    const proceed = await this.confirmation(`Do you want to deposit ${this.userChecks.length} checks of total ${this.$filter('nxCurrency')(this.totalAmount)}?`);

    if (proceed) {
      const response = await this.command
          .execute('DepositMultipleChecksToAccount', {
            amount: this.totalAmount,
            // strip ui-only parameters
            checks: this.userChecks.map(check => {
              check.entryType = 'CREDIT';
              check.productId = this.productId;

              return _.omit(check, "bankName")
            }),
            entryType: 'CREDIT',
            productId: this.productId
          }, {nxLoaderText: 'Depositing funds...'})
          .toPromise();

      if (response && !response.approvalRequired) {
        this.customerCache.depositAccounts(this.customerId).refetch();
        this.customerCache.depositAccountGroups(this.customerId).refetch();
        this.redirectBack()
      }
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.productId}`)
  }
}

nxModule.component('customerAccountsMultipleChecksDeposit', {
  templateUrl,
  controller: CustomerAccountsMultipleChecksDeposit
});
