import nxModule from 'nxModule';

import templateUrl from './field.template.html';

/**
 * Problem: I keep id of some object in may entity and have select like component returning full item in ngModel.
 * This is quite cumbersome, as I always need to filter out items before passing to select as options and then
 * on form saving need to extract id to send only it.
 *
 * Solution:
     <field
       options="$ctrl.accounts" -- all our options
       field="fullCode" -- field we'are interested in receiving
       ng-model="$ctrl.config.accountFullCode" -- ngModel pointing to entity field
       transcluded-model="$ctrl.tmpField" -- temporary model for passing data to underlying select component
     >
       <account-select options="$ctrl.accounts" -- all our options
         ng-model="$ctrl.tmpField" -- ngModel used by our select component - the same as transcludedModel above
         value-field="'id'" -- bugfix for weirdos of this select component
         required> -- validation
       </account-select>
     </field>

  As a result we will not receive full ngModel used by select component, but only one field - fullCode. Mission accomplished.
 */
class Field {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.ngModel.$render = () => {
      this.renderModel();
    };

    this.$scope.$watch('$ctrl.transcludedModel', () => {
      if(!this.ngModel || !this.options) {
        return;
      }

      if(this.transcludedModel &&
          this.transcludedModel[this.field] === this.ngModel.$viewValue) {
        return;
      }

      this.ngModel.$setTouched();
      if(this.transcludedModel) {
        this.ngModel.$setViewValue(this.transcludedModel[this.field]);
      } else {
        this.ngModel.$setViewValue(null);
      }
    });
  }

  $onChanges(changes) {
    if(this.ngModel && changes.options) {
      this.renderModel();
    }
  }

  renderModel() {
    const modelValue = this.ngModel.$viewValue || null;
    // prevent accidental change
    if(!this.options) {
      return;
    }

    this.transcludedModel = (this.options || []).find(option => option[this.field] === modelValue);
  }
}

nxModule.component('field', {
  templateUrl,
  transclude: true,
  require: {
    ngModel: '^ngModel', // your expected output model == equal to some option[field]
  },
  bindings: {
    transcludedModel: '=', // two binding to your inner transcluded component ngModel
    options: '<',
    field: '@'
  },
  controller: Field
});
