import nxModule from 'nxModule';
import _ from 'lodash';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/combineLatest';

nxModule.factory('customerDepositService', function (customerCache, productDefinitionService, http) {
  let that = this;

  that.getDeposit = (customerId, depositId, cb) => {
    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, d => d.id == depositId))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
          const p = _.find(products, {id: deposit.definitionId});
          return Object.assign(deposit, {
            productName: p ? p.productName : '<Unknown product>'
          });
        }
      ).first()
      .subscribe(deposit => cb(deposit));
  };

  that.searchDeposits = (customerIds, statuses, includeRelatedCustomerAccounts, callback) => {
    const request = {
      pageSize: 50,
      productStatus: statuses,
      customerId: customerIds,
      includeOwnership: true,
      includeRelativesAccount: includeRelatedCustomerAccounts
    };

    http.post('/products/accounts/search', request).success((data) => {
      let depositAccounts = data.result;
      // Add product label to each account
      _.forEach(depositAccounts, d => {
        const owner = _.find(d.ownership, {ownershipType: 'OWNER'});
        d.label = `${owner.effectiveName} ${d.productNumber}`;
      });
      // Execute callback
      callback(depositAccounts)
    });
  };

  /*
   * check if provided oldDeposit has been certificated:
   * if oldDeposit hasn't been certificated (certifiedOn == null)
   *   and newDeposit has certifiedOn != null then this method call cb with true.
   **/
  that.checkIfCertificated = (oldDeposit, cb) => {
    if (!oldDeposit.certifiedOn) {
      that.getDeposit(oldDeposit.customerId, oldDeposit.id, newDeposit => {
        if (newDeposit.certifiedOn) {
          cb(true);
        } else {
          cb(false);
        }
      });
    } else {
      cb(false);
    }
  };

  return that;
});
