import nxModule from 'nxModule';

import templateUrl from './customer-loan-release.template.html';
import {loanCreationTypes} from "constants/loan";
import './customer-loan-release.styles.less'
import {sum} from "../../../../shared/common/MathUtils";
import * as BigNumber from "bignumber.js";
import {flattenSubAccounts} from "../../../general-ledger/common/gl.utils";
import _ from 'lodash';

/**
 * Common component for different type of Loan Release
 *
 * e.g. CASH, CREDIT TO ACCOUNT, CHECK and GL
 * */
class CustomerLoanRelease {

  constructor($route, $filter, $location, command, customerCache, depositAccountTypeService, loanCache,
              confirmation, misGroupsCache, userCounterService, depositoryAccountCache, branchService,
              glLedgerService, glAccountService, customerLoanRenewalService, accessRuleService, authentication, http) {
    this.$route = $route;
    this.$location = $location;
    this.command = command;
    this.customerCache = customerCache;
    this.confirmation = confirmation;
    this.misGroupsCache = misGroupsCache;
    this.$filter = $filter;
    this.userCounterService = userCounterService;
    this.depositAccountTypeService = depositAccountTypeService;
    this.loanCache = loanCache;
    this.depositoryAccountCache = depositoryAccountCache;
    this.branchService = branchService;
    this.glAccountService = glAccountService;
    this.glLedgerService = glLedgerService;
    this.customerLoanRenewalService = customerLoanRenewalService;
    this.accessRuleService = accessRuleService;
    this.authentication = authentication;
    this.http = http;
  }

  async $onInit() {
    this.loanId = this.loan.id;
    this.fetchOtherLoanDetails();
    this.remarks = undefined;
    this.remadeProductNumbers = [];

    switch (this.method) {
      case 'CREDIT_TO_ACCOUNT':
        this.selectedAccount = {};
        this.accounts = await this.fetchDepositAccounts();
        break;
      case 'CHECK':
        this.selectConfig = {
          placeholder: 'Select account',
          searchField: ['accountName'],
          valueField: 'id',
          labelField: 'label',
          maxItems: 1
        };

        const [depositoryAccounts, profile, branches] = await Promise.all([
          this.depositoryAccountCache.toPromise(),
          this.customerCache.profile(this.customerId).toPromise(),
          this.branchService.toPromise()
        ]);

        this.branches = branches;
        this.depositoryAccounts = depositoryAccounts;
        this.payee = profile.effectiveName;
        this.remarks = `Release of net proceeds for Loan ${this.loan.productNumber}`;

        const rule = await this.accessRuleService.accessRuleForLoggedInUserRole('ReleaseLoanByCheck');
        if (rule && !!rule.predicates['IN']) {
          this.allowedDepositoryModes = rule.predicates['IN']['DEPOSITORY_BANK_ACCESS'];
        } else {
          this.allowedDepositoryModes = ['MOTHER_BRANCH'];
        }

        // If only depository interbranch is allowed we enable it
        this.interbranch = this.allowedDepositoryModes.length === 1 && this.allowedDepositoryModes.includes('INTER_BRANCH');

        this.filterCheckingAccounts();

        if (this.loan.checkPreparationId) {
          const approvedLoan = await this.http.get(`/check-preparation/${this.loan.checkPreparationId}/approved-loan`).toPromise();
          this.micrNumber = approvedLoan.check.micrNumber;
          this.checkingAccountId = approvedLoan.check.depositoryAccountId;
        }

        break;
      case 'GENERAL_LEDGER':
        this.ledgerAccounts = await this.fetchLedgerAccounts();
        break;
      case 'CASH':
      case 'RESTRUCTURE':
        break;
      default:
        console.error(`Invalid loan release method ${this.method}`);
        break;
    }

    this.chargesAlreadyPaid = await this.calculateChargesAlreadyPaid();
    await this.fetchRenewedLoan();
  }

  filterCheckingAccounts() {
    this.checkingAccountId = null;
    this.checkingAccounts = this.depositoryAccounts?.filter(da => da.accountType === 'CHECKING' ).filter(da => {
      if (this.interbranch) {
        return da.branchId !== this.authentication.context.branchId;
      } else {
        return da.branchId === this.authentication.context.branchId;
      }
    }).map(da => {
      if (this.interbranch) {
        const branch = this.branches.find(b => b.id === da.branchId);
        return {
          ...da,
          'label': `${branch.name} - ${da.accountName}`
        }
      } else {
        return {
          ...da,
          'label': `${da.accountName}`
        }
      }
    });
  }

  async fetchLedgerAccounts() {
    const ledgers = await this.glLedgerService.toPromise();
    const ledgerInLoanBranch = ledgers.find(ledger => ledger.branchId === this.loan.branchId);

    const glAccounts = await this.glAccountService.fetchAccounts(ledgerInLoanBranch.id, {leafOnly: true}).toPromise();
    return flattenSubAccounts(glAccounts);
  }

  async fetchDepositAccounts() {
    const [depositAccounts, accountTypes] = await Promise.all([
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.depositAccountTypeService.toPromise()
    ]);

    const accountTypeMapById = _.keyBy(accountTypes, type => type.id);

    return depositAccounts
      .filter(account => {
        const isActive = account.status === 'ACTIVE';
        const type = accountTypeMapById[account.typeId];
        const isPendingAndCanReceiveLoanRelease = account.status === 'PENDING'
            && type?.initialDepositOperations?.includes('ReleaseLoanByCreditToAccount');

        return isActive || isPendingAndCanReceiveLoanRelease;
      })
      .map(account => {
        const type = accountTypeMapById[account.typeId];
        if (type) {
          account.name = `${type.productDefinition.productName} [${account.productNumber}]`;
          account.maintainingBalance = account.maintainingBalance ?? type.maintainingBalance;
        }
        return account;
      });
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loanId}`);
  }

  cancelChanges() {
    this.redirectBack();
  }

  async releaseLoan() {
    const request = {
      productId: this.loanId,
      customerId: this.customerId,
      remarks: this.remarks
    };

    const netProceeds = this.$filter('nxCurrency')(this.loan.releaseAmount);

    let proceed;
    const remadeNumbers = this.remadeProductNumbers.join(',');
    switch (this.method) {
      case 'RESTRUCTURE':
        proceed = await this.confirmation(`Loan '${remadeNumbers}' will be closed and
          'Net proceeds' will be transferred to pre-terminate '${remadeNumbers}' loan. Proceed?`);
        if (!proceed) return;
        await this.command.execute('ReleaseRestructuredLoan', request).toPromise();
        break;
      case 'CASH':
        proceed = await this.confirmation(`Net proceeds ${netProceeds} will be deducted from Cash on Hand. Proceed?`);
        if (!proceed) return;
        await this.command.execute('ReleaseLoanByCash', request).toPromise();
        break;
      case 'CREDIT_TO_ACCOUNT':
        proceed = await this.confirmation(`Net proceeds ${netProceeds} will be credited to account ${this.selectedAccount.productNumber}. Proceed?`);
        if (!proceed) return;
        request.accountId = this.selectedAccount.id;
        await this.command.execute('ReleaseLoanByCreditToAccount', request).toPromise();
        this.customerCache.depositAccounts(this.customerId).refetch();
        break;
      case 'CHECK':
        proceed = await this.confirmation(`Net proceeds ${netProceeds} will be released via check`);
        if (!proceed) return;
        if (!this.loan.checkPreparationId){
          request.checkInput = {
            validFrom: this.validFrom,
            depositoryAccountId: this.checkingAccountId,
            micrNumber: this.micrNumber,
            checkNumber: this.checkNo,
            payee: this.payee
          }
        }

        await this.command.execute('ReleaseLoanByCheck', request).toPromise();
        break;
      case 'GENERAL_LEDGER':
        proceed = await this.confirmation(`Net proceeds ${netProceeds} will be released via general ledger. Proceed?`);
        if (!proceed) return;
        request.accountCode = this.ledgerAccount.fullCode;

        await this.command.execute('ReleaseLoanByGL', request).toPromise();
        break;
      default:
        console.error('Loan release method invalid.');
        return;
    }

    this.customerCache.loans(this.customerId).refetch();
    this.customerCache.depositAccounts(this.customerId).refetch();
    this.userCounterService.refresh();
    this.redirectBack();
  }

  fetchOtherLoanDetails() {
    this.getMisGroup();
    this.getCreationType();
    this.getCustomFees();
    this.getRemadeProductNumber();

    this.remadeLoanLabel = this.getRemadeLoanLabel();
    this.grantDate = this.$filter('prettyDate')(this.loan.grantDate);
    this.maturityDate = this.$filter('prettyDate')(this.loan.maturityDate);
    this.firstAmortizationDueDate = this.$filter('prettyDate')(this.loan.firstAmortizationDueDate);
  }

  async getCustomFees() {
    const customFees = await this.customerCache.customerProductFees(this.customerId, 'LOAN').toPromise();
    const totalCustomFees = sum(customFees
      .filter(fee => fee.productId === Number(this.loanId))
      .filter(fee => fee.applyOn === 'LOAN_RELEASE')
      .map(fee => fee.balance));
    this.totalFeesAndCharges = new BigNumber(this.loan.totalDefaultFees).plus(new BigNumber(totalCustomFees)).toNumber();
  }

  async getMisGroup() {
    const misGroups = await this.misGroupsCache.toPromise();
    const misFound = misGroups.find(misGroup => misGroup.id === this.loan.loanInformation.misGroupId);
    this.misGroup = misFound ? misFound.name : '-';
  }

  getCreationType() {
    const found = loanCreationTypes.find(loanType => loanType.value === this.loan.creationType);
    this.creationType = found ? found.label : '-';
  }

  isRemadeLoan() {
    return ['RESTRUCTURED', 'CONSOLIDATION'].includes(this.loan.creationType);
  }

  async getRemadeProductNumber() {
    if (this.isRemadeLoan()) {
      const remadeLoanIds = this.loan.remadeFromLoanIds.join(',');
      const loans = await this.http.get(`/products/loans?ids=${remadeLoanIds}`).toPromise();
      this.remadeProductNumbers = loans.map(l => l.productNumber);
    }
  }

  getRemadeLoanLabel() {
    switch (this.loan.creationType) {
      case 'RESTRUCTURED':
        return 'Restructured';
      case 'CONSOLIDATION':
        return 'Consolidated';
    }
    return null;
  }

  isLoanRenewal() {
    return !!this.loan.renewedLoanId;
  }

  async fetchRenewedLoan() {
    if (this.loan.renewedLoanId) {
      this.renewedLoanDetails = await this.customerLoanRenewalService.getRenewedLoanDetails(this.loan.renewedLoanId);
      this.renewedLoan = this.renewedLoanDetails.loan;
    }
  }

  onMicrNumberChange(micrNumber) {
    if (micrNumber && micrNumber.length === 31) {
      this.checkNo = micrNumber.substr(0, 10);
    } else {
      this.checkNo = null;
    }
  };

  async calculateChargesAlreadyPaid() {
    const customFees = await this.customerCache.customerProductFees(this.customerId, 'LOAN').toPromise();
    const paidCustomFees = customFees
      .filter(fee => fee.productId === Number(this.loanId))
      .filter(fee => fee.amount !== 0 && fee.balance === 0);

    const fees = [
      {paid: this.loan.docStampAlreadyPaid, amount: this.loan.docStamp},
      {paid: this.loan.notarialFeeAlreadyPaid, amount: this.loan.notarialFee},
      {paid: this.loan.applicationFeeAlreadyPaid, amount: this.loan.applicationFee},
      {paid: this.loan.creditInvestigationFeeAlreadyPaid, amount: this.loan.creditInvestigationFee},
      {paid: this.loan.extraBankFeeAlreadyPaid, amount: this.loan.extraBankFee},
      {paid: this.loan.serviceChargeAlreadyPaid, amount: this.loan.serviceCharge},
      {paid: this.loan.membershipFeeAlreadyPaid, amount: this.loan.membershipFee},
      {paid: this.loan.idFeeAlreadyPaid, amount: this.loan.idFee},
      {paid: this.loan.insuranceFeeAlreadyPaid, amount: this.loan.insuranceFee},
      {paid: this.loan.insuranceServiceFeeAlreadyPaid, amount: this.loan.insuranceServiceFee},
      {paid: this.loan.insuranceProcessingFeeAlreadyPaid, amount: this.loan.insuranceProcessingFee},
    ]
    const paidFees = _.filter(fees, {paid: true});
    const alreadyPaidContractual = sum(this.loan.amortizationSchedule.list.map(a => this.getAlreadyPaidContractual(a)));
    if (!paidFees && !paidCustomFees && alreadyPaidContractual === 0) {
      return null;
    }

    return _.sumBy(paidFees, it => it.amount) + _.sumBy(paidCustomFees, it => it.amount) + alreadyPaidContractual.toNumber();
  }

  getAlreadyPaidContractual(amortization) {
    let amount = 0;
    if (amortization.cbuChargeAmount !== amortization.cbuChargeBalance && amortization.cbuChargeBalance === 0) {
      amount += amortization.cbuChargeAmount;
    }
    if (amortization.pfChargeAmount !== amortization.pfChargeBalance && amortization.pfChargeBalance === 0) {
      amount += amortization.pfChargeAmount;
    }
    if (amortization.tpChargeAmount !== amortization.tpChargeBalance && amortization.tpChargeBalance === 0) {
      amount += amortization.tpChargeAmount;
    }
    return amount
  }
}

nxModule.component('customerLoanRelease', {
  templateUrl,
  controller: CustomerLoanRelease,
  bindings: {
    customerId: '<',
    loan: '<',
    method: '@'
  }
});
