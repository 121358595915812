import React from "react";
import {Redirect} from 'react-router';
import CredentialsUpdateService from "./CredentialsUpdateService";
import tokenStore from "../../authentication/tokenStore";
import {InferProps, node} from 'prop-types';

interface State {
  forceUpdateCredentials: boolean;
}

const props = {
  children: node.isRequired
};

const credentialsUpdateService = new CredentialsUpdateService();
class ForceUpdateCredentials extends React.Component<InferProps<typeof props>, State> {
  static propTypes = props;

  state: State = {
    forceUpdateCredentials: credentialsUpdateService.isForceCredentialsUpdate()
  };

  componentDidMount(): void {
    tokenStore.onTokenUpdated(() =>
      this.setState({
        forceUpdateCredentials: credentialsUpdateService.isForceCredentialsUpdate()
      })
    );
  }

  render(): React.ReactNode {
    return this.state.forceUpdateCredentials ?
      <Redirect to={credentialsUpdateService.getRedirectUrl()} /> :
      this.props.children;
  }
}

export default ForceUpdateCredentials;