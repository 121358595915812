import {AccessRule} from 'access/AccessRuleTypes';
import axios from "axios";
import tokenStore from "authentication/tokenStore";
import {groupBy, intersection} from "lodash/fp";

interface CommandRoleMapEntry {
  approve: number[];
  execute: number[];
}

interface CommandRoleMap {
  [commandName: string]: CommandRoleMapEntry
}

interface AccessRuleMap {
  [commandName: string]: AccessRule[]
}

/**
 * Checks if user has access to a given command. Doesnt support checking 'approve' rights and command predicates.
 */
class CommandAccessService {
  private accessRuleMap!: AccessRuleMap;
  private commandRoleMap!: CommandRoleMap;

  async init(): Promise<void> {
    console.log('CommandAccessService: Loading access rules');
    const roleIds = tokenStore.getTokenDetails().roleIds;
    const [commandRoleMap, accessRules] = await Promise.all([
      axios.get<CommandRoleMap>('/access/command-role-map'),
      axios.get<AccessRule[]>(`/access/roles/${roleIds}/rules`)
    ]);

    this.commandRoleMap = commandRoleMap.data;
    this.accessRuleMap = groupBy(rule => rule.command, accessRules.data);
    console.log('CommandAccessService: Access rules loaded');
  }


  // if we provide multiple commands, user should be able to execute all of them
  public canExecute(command: string | string[]): boolean {
    const roleIds = tokenStore.getTokenDetails().roleIds;
    const unifiedCommands = Array.isArray(command) ? command : [command];
    const rolesWithExecuteAccessPerCommand = unifiedCommands.map(cmd => this.commandRoleMap[cmd]?.execute ?? []);
    let matchingRoles = [...roleIds];
    for(const commandExecuteRoles of rolesWithExecuteAccessPerCommand) {
      matchingRoles = intersection(matchingRoles, commandExecuteRoles);
    }

    return matchingRoles.length > 0;
  }
}

export default new CommandAccessService();