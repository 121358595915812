import nxModule from 'nxModule';

//based on http://stackoverflow.com/questions/14012239/password-check-directive-in-angularjs
nxModule.directive('validatePassword', function(){
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attr, ngModel) {
      if(!ngModel) return;

      scope.$watch(attr.ngModel, function() {
        validate();
      });

      attr.$observe('validatePassword', function (val) {
        validate();
      });

      let validate = function() {
        let retypedPassword = ngModel.$viewValue;
        let newPassword = attr.validatePassword;

        if (!newPassword) {
          ngModel.$setValidity('validatePassword', true);
        } else {
          ngModel.$setValidity('validatePassword', !retypedPassword || retypedPassword === newPassword);
        }
      };
    }
  }
});
