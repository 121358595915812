import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('commandMetadataCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/command/metadata'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.commandMetadata,
    cacheName: 'commandMetadata'
  })
);
