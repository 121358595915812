import nxModule from 'nxModule';
import scoreIndicator from 'raw-loader!./credit-scoring-modal-score-indicator.svg';
import templateUrl from './credit-scoring-modal.template.html';
import './credit-scoring-modal.styles.less';
import {ModalApi} from "components/technical/modal/modal.component";
import './credit-scoring-modal-legend.component';
import './credit-scoring-modal-score-chart.component';
import './credit-scoring-modal-loan-application-details.component'
import './credit-scoring-modal-confusion-matrix.component'
import './credit-scoring-modal-features.component'
import './credit-scoring-modal-customer-details.component'
import './credit-scoring-modal-loan-application-details.service'
import {
  CreditScore,
  CreditScoreDescription,
  CreditScoreInput,
  LoanApplicationDetails,
  Metric,
  NonPredictionKeys
} from "components/customer/credit-scoring/credit-scoring.types";
import {IAugmentedJQuery} from "angular";
import {HttpService} from "shared/utils/httpService";
import moment from "moment";
import {Config} from "components/customer/loans/preview/amortization-preview.component";

export class CreditScoringModalApi {
  constructor(private component: CreditScoringReport) {
  }

  /**
   * CSS class name that will be appended to body in order to be able to style it
   */
  private static readonly BODY_CLASS = 'credit-scoring-modal';

  /**
   * Used to credit scoring report. Resolves when modal is hidden
   */
  async show(details: LoanApplicationDetails): Promise<void> {
    await this.component.prepareReportDetails(details);
    document.body.classList.add(CreditScoringModalApi.BODY_CLASS);
    return this.component.getModalApi().show()
      .finally(() => {
        document.body.classList.remove(CreditScoringModalApi.BODY_CLASS)
      });
  }
}

class CreditScoringReport {
  // callback provided by parent component
  onApiReady!: (param: { api: CreditScoringModalApi }) => unknown;
  loanApplicationDetails!: LoanApplicationDetails;
  scoreDescription!: CreditScoreDescription;
  generationTime!: moment.Moment;

  modalApi: ModalApi | undefined;
  labels: Record<NonPredictionKeys, string> = {
    'demographicData': 'Demographics',
    'creditHistory': 'Credit History',
    'accountsHistory': 'Savings History',
    'loanApplicationData': 'Loan Application'
  };

  score!: CreditScore;

  amortizationPreviewConfig: Config = {
    maxAmortizationDisplayed: 5,
    showSummary: false,
    hideContractualColumnsIfZero: true,
    hideCustomFeesIfZero: true
  };

  readonly displayedMetrics = ['F1', 'ROC', 'Precision', 'Recall'];

  constructor(
    private $element: IAugmentedJQuery,
    private http: HttpService) {
  }

  public async prepareReportDetails(details: LoanApplicationDetails): Promise<void> {
    const [score, scoreDescription] = await Promise.all([
      this.calculateScore(details),
      this.http.get<CreditScoreDescription>('/customers/creditscoring/report').toPromise(),
    ]);

    this.loanApplicationDetails = details;
    this.score = score;
    this.scoreDescription = scoreDescription;
    this.generationTime = moment();
    this.addScoreIndicator();
  }

  private async calculateScore(loan: LoanApplicationDetails): Promise<CreditScore> {
    const input: CreditScoreInput = {
      interestRate: loan.interestRate,
      principalAmount: loan.principalAmount,
      paymentIntervalDefinitionId: loan.paymentIntervalDefinitionId,
      loanTypeId: loan.typeId,
      customerId: loan.customerId,
      grantDate: loan.grantDate,
      totalAmortizationNumber: loan.totalAmortizationNumber,
      firstAmortizationDueDate: loan.firstAmortizationDueDate,
    };

    try {
      return await this.http.post<CreditScore>('/customers/creditscoring/score', input).toPromise();
    } catch (e) {
      console.log('Could not calculate credit score', e);
      throw new Error('Calculation of credit score failed');
    }
  }

  getScoreRange(): [number, string, string] {
    const scoreRanges: [number, string, string][] = [
      [0.5, 'bad', 'Unlikely'],
      [0.6, 'poor', 'Neutral'],
      [0.7, 'average', 'Likely'],
      [0.8, 'good', 'Very Likely'],
      [0.9, 'excellent', 'Highly Likely'],
    ];

    return scoreRanges.find(score => score[0] > this.score.prediction) ?? [1, 'outstanding', 'Virtually Certain'];
  }

  protected onModalReady = ({api,}: { api: ModalApi }): void => {
    this.modalApi = api;
    this.onApiReady({api: new CreditScoringModalApi(this)});
  };

  public addScoreIndicator(): void {
    const scorePair = this.getScoreRange();
    const prediction = this.score.prediction;
    const scoreName = scorePair[1];
    const scoreProgressRatio = prediction < 0.5 ?
      (this.score.prediction / 0.5)
      : (prediction - (scorePair[0] - 0.1)) / 0.1;

    const scoreElement = this.$element.find(`score-progress-${scoreName}`);
    const $scoreIndicator = $(scoreIndicator);
    $scoreIndicator.css('left', `${scoreProgressRatio * 100}%`);
    scoreElement.append($scoreIndicator);
  }

  public getModalApi(): ModalApi {
    if (!this.modalApi) {
      throw new Error('Missing modal api');
    }

    return this.modalApi;
  }

  protected featureTitle(count: number, featureName: string): string {
    return `Top ${count} ${featureName} features that impact the result`;
  }

  protected featureDescription(featureName: string): string {
    return `The graph below presents the list of the ${featureName} features with the highest scores that indicate
    how valuable each particular feature was during the construction of the boosted decision trees within the AI model.`;
  }

  public isMetricDisplayed = (metric: Metric): boolean  => {
    return this.displayedMetrics.includes(metric.metric);
  };

  public close(): void {
    this.modalApi?.onCancel();
  }

  public print(): void {
    window.print();
  }
}

nxModule.component('creditScoringModal', {
  bindings: {
    onApiReady: '<', // function receiving {api} as a parameter
    loanType: '<'
  },
  templateUrl,
  controller: CreditScoringReport
});
