export const ssNumberPattern = paymentType => {
  const createBaseRegex = () => {
    if (!paymentType) {
      return ".*";
    }

    let regexPrefix = '\\d{2}';
    if (['CONTRIBUTION', 'MEMBER_LOAN', 'MISC_SOCIAL_SECURITY_CARD'].includes(paymentType)) {
      // example valid account number 0111111111
      regexPrefix = '(0[1-9]|10|33|34)';
    }

    return addMaskToRegex(
      regexPrefix,
      '\\d{7}',
      '\\d'
    );
  };

  return validateStartEndRegex(createBaseRegex());
};

export const validateStartEndRegex = regex => {
  return `^${regex}$`;
};

export const addMaskToRegex = (...regexParts) => {
  const filteredParts = regexParts.filter(part => part);
  return filteredParts.join("-");
};