import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';

//@deprecated
nxModule.directive('percentage', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      if (ngModel) {

        ngModel.$parsers.push(function (value) {
          if (value != null) {
            return new BigNumber(value).multipliedBy(100).toNumber();
          }
        });

        ngModel.$formatters.push(function (value) {
          if (value != null) {
            return new BigNumber(value).div(100).toNumber();
          }
        });

      }
    }
  };
});
