export enum CheckbookType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMMERCIAL = 'COMMERCIAL'
}

export enum CheckbookEntryStatus {
  NOT_ISSUED = 'NOT_ISSUED',
  STOPPED = 'STOPPED',
  CREDITED = 'CREDITED',
  RETURNED = 'RETURNED',
  CANCELED = 'CANCELED'
}

export enum CheckbookClearingMode {
  CONDUIT = 'CONDUIT',
  DIRECT = 'DIRECT'
}

export interface CheckbookEntry {
  lineNo: number,
  checkId: number,
  brstn: string,
  checkNumber: string,
  amount: number,
  registeredOn: Date,
  status: CheckbookEntryStatus,
  micr: string,
  depositoryAccountId: number,
  clearingMode: CheckbookClearingMode,
  accountNumber: string
}

export enum CheckbookStatus {
  ISSUED = 'ISSUED',
  CANCELED = 'CANCELED'
}

export enum CheckbookOwner {
  CUSTOMER = 'CUSTOMER',
  BANK = 'BANK'
}

export interface Checkbook {
  id: number;
  number: string;
  issuedOn: string;
  startNumber: number;
  checksCount: number;
  endNumber: number;
  status: CheckbookStatus;
  hasUsedChecks: boolean;
  batchId: number | null;
  owner: CheckbookOwner;
  clearingMode: CheckbookClearingMode;
  accountNumber: string;
}

export interface CheckbookDetails {
  checkbook: Checkbook;
  usedChecks: number;
  stoppedChecks: number;
}