import {ModalPrintPreviewService} from "../../../components/service/print/modal-print-preview.service";
import nxModule from 'nxModule';
import {CommandDescriptor} from "command/CommandTypes";
import {HttpService} from "shared/utils/httpService";

export class CommandPrintService {

  constructor(private http: HttpService, private modalPrintPreviewService: ModalPrintPreviewService) {
  }

  /**
   * Displays all prints for a given command descriptor
   */
  async displayPrints(command: CommandDescriptor) {
    for (const print of command.prints) {
      await this.modalPrintPreviewService.showAsync({
        printDescription: {
          code: print.code,
          parameters: print.parameters
        },
        printProviderInput: print.input
      });
    }
  }
}

nxModule.service('commandPrintService', CommandPrintService);