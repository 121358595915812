import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {LoanType} from "components/service/loan-type.types";
import {HttpService} from "shared/utils/httpService";
import config from '../../../../../react/config';
import {ICacheFactoryService} from 'angular';

type LoanProductsCache = LocalCache<unknown, LoanType[]>;
export default LoanProductsCache;

nxModule.factory('loanProductsCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache<unknown, LoanType>({
    provider: () => http.get('/products/loans/types?variation=REGULAR'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.loanProducts,
    cacheName: 'loanTypes'
  }));