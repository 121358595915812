

class NxPromptStore {
  private message = '';
  private blocks: Set<string> = new Set<string>();

  public setMessage(message: string): void {
    this.message = message;
  }

  public getMessage(): string {
    return this.message;
  }

  public block(id: string): void {
    console.debug('NxPromptStore: blocking', id);
    this.blocks.add(id);
  }

  public release(id: string): void {
    console.debug('NxPromptStore: releasing', id);
    this.blocks.delete(id);
  }

  public presentChallenge(): boolean {
    const confirmed = window.confirm(this.getMessage());
    if(confirmed) {
      this.releaseAll();
    }

    return confirmed;
  }

  private releaseAll(): void {
    console.debug('NxPromptStore: Releasing all prompts');
    this.blocks.clear();
  }

  public isBlocking(): boolean {
    return this.blocks.size > 0;
  }
}

export default new NxPromptStore();