import nxModule from 'nxModule';

import templateUrl from './working-day-cache-refresher.template.html';

class WorkingDayCacheRefresher {
  constructor(workingDayService, refreshService) {
    const workingDayKey = "nx__working_day";
    this.sub = workingDayService.toObservable()
      .subscribe(workingDay => {
        const currentWorkingDayText = sessionStorage.getItem(workingDayKey);
        if(!currentWorkingDayText) {
          sessionStorage.setItem(workingDayKey, JSON.stringify(workingDay));
          return;
        }

        const currentWorkingDay = JSON.parse(currentWorkingDayText);
        if(currentWorkingDay.id !== workingDay.id ||
          currentWorkingDay.status !== workingDay.status) {
          console.warn("Refreshing caches due to working day change.");
          sessionStorage.setItem(workingDayKey, JSON.stringify(workingDay));
          refreshService.clearCaches();
        }
      });
  }

  $onDestroy() {
    this.sub.unsubscribe();
  }
}

nxModule.component('workingDayCacheRefresher', {
  templateUrl,
  controller: WorkingDayCacheRefresher
});
