import nxModule from 'nxModule';


const templateUrl = require('./file-upload-webcam.template.html');
nxModule.component('fileUploadWebcam', {
  templateUrl,
  bindings: {
    onClosed: '&',
    onError: '&',
    onPhotoTaken: '&',
  },
  controller: function () {
    const that = this;
    that.webcamVisible = false;

    // channel will hold video upon stream has started
    that.channel = {
      videoWidth: 600,
      videoHeight: 600,
    };

    that.showWebcam = () => {
      that.webcamVisible = true;
    };

    that.webcamStream = null; // technically null is a stream too

    that.onWebcamStream = stream => {
      that.webcamStream = stream;
    };

    that.onWebcamError = e => {
      console.error(e);
      that.errorMessage = 'Error when accessing the webcam. Please check if page has appropriate permissions.';
    };

    that.takePhoto = () => {
      // for taking photo we draw video on canvas and use canvas api to create blob out of it
      const hiddenCanvas = document.querySelector('.file-upload-webcam__canvas');
      const video = that.channel.video;
      hiddenCanvas.width = video.width;
      hiddenCanvas.height = video.height;

      const canvasContext = hiddenCanvas.getContext('2d');

      canvasContext.drawImage(video, 0, 0, video.width, video.height);
      hiddenCanvas.toBlob(
        blob => that.onPhotoTaken({blob,}),
        'image/jpeg',
        1); // image quality - we want the best, because we compress it later
    };
  }
});
