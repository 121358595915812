import nxModule from 'nxModule';

nxModule.factory('loanPreTerminateService', function($filter, http, feeDefinitionsCache) {
  let service = {};

  service.getMessage = (totalInfo) => {
    const question = `Do you want to pay ${$filter('nxCurrency')(totalInfo.total)}?`;
    if (!totalInfo.totalWaive) {
      return question;
    }
    return `${question} The amount ${$filter('nxCurrency')(totalInfo.totalWaive)} will be waived.`;
  };

  /**
   * This methods removes any other amount in request aside from the totalAmount
   * because pretermination only supports regular payment
   * */
  service.createPaymentRequest = (request, officialReceipt) => {
    return {
      productId: request.productId,
      commandPurpose: request.commandPurpose,
      amount: request.amount,
      officialReceipt: officialReceipt
    }
  };

  /**
   * Reads the as earned interest amount for the pretermination.
   */
  service.getAsEarnedInterest = async (loanId) => {
    const interest = await http.get(`/products/loans/${loanId}/pretermination-earned-interest`).toPromise();
    return interest;
  };

  /**
   * Fetches all pretermination fees.
   * This includes fees that are not yet persisted,
   * meaning Calculated and Applied both on pretermination.
   * */
  service.getCustomPreterminationFees = async (loanId) => {
    const [productFees, feeDef] = await Promise.all([
      http.get(`/products/loans/${loanId}/termination-fees`).toPromise(),
      feeDefinitionsCache.toPromise()
    ]);

    productFees.forEach(fee => {
      const def = feeDef.find(def => def.id === fee.feeDefinitionId);
      fee.feeName = def.feeName;
    });

    return productFees;
  };

  return service;
});