import {ILocationService} from 'angular';
import {Device} from 'components/agent-banking/details/devices/device-details.component';
import {AgentDetails, AgentsBranchCache} from 'components/agent-banking/service/agents-branch.cache.types';
import {AgentsDevicesCache} from 'components/agent-banking/service/agents-devices.cache.types';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import moment from 'moment';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';
import './agent-activation.style.less';

import templateUrl from './agent-activation.template.html';

class AgentActivation {

  agentId!: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  agent: AgentDetails;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  device: Device = {};
  branch!: Branch;
  isDeviceEdited!: boolean;

  constructor(private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private agentsBranchCache: AgentsBranchCache,
              private breadcrumbs: unknown,
              private agentsDevicesCache: AgentsDevicesCache) {
  }

  async $onInit(): Promise<void> {
    this.agent = await this.initExistingAgent();
    setBreadcrumbs(this.breadcrumbs, 'agents-list-label', this.branch.name);
    setBreadcrumbs(this.breadcrumbs, 'agent-details-label', this.agent.effectiveName);
  }

  async initExistingAgent(): Promise<AgentDetails> {
    const agents = await this.agentsBranchCache.withParam(this.branch.id).toPromise();
    const agent = _.cloneDeep(agents.find(agent => agent.id === this.agentId)!);
    if (agent) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      agent.workingHoursFrom = moment(agent.workingHoursFrom, 'HH:mm:ss').toDate();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      agent.workingHoursTo = moment(agent.workingHoursTo, 'HH:mm:ss').toDate();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      agent.password = {

      };
    }
    return agent;
  }

  async activate(): Promise<void> {
    const request: unknown = {
      id : this.agent.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      password : _.cloneDeep(this.agent.password),
      device : this.device,
      workingHoursFrom : moment(this.agent.workingHoursFrom).format('HH:mm:ss'),
      workingHoursTo : moment(this.agent.workingHoursTo).format('HH:mm:ss')
    };

    if (await this.confirmation(`Do you want to activate Agent '${this.agent.effectiveName}'?`)) {
      const response = await this.command.execute('ActivateAgent', request).toPromise();
      if (!response?.approvalRequired) {
        this.agentsBranchCache.withParam(this.branch.id).evict();
        this.agentsDevicesCache.withParam(this.agent.id).evict();
        this.redirectBack();
      }
    }
  }

  redirectBack(): void {
    this.$location.path(`/agent-banking/agents/${this.branch.id}`);
  }

}

nxModule.component('agentActivation', {
  templateUrl: templateUrl,
  controller: AgentActivation,
  bindings: {
    agentId: '<',
    branch: '<'
  }
});