import nxModule from 'nxModule';
import {setBreadcrumbs} from '../../../../shared/utils/set-breadcrumbs';

const templateUrl = require('./loan-check-release.template.html');
nxModule.component('customerLoanCheckRelease', {
  templateUrl: templateUrl,
  controller: function ($route, $filter, $location, http, confirmation, notification, customerCache, command, breadcrumbs) {
    const that = this;

    let customerId = $route.current.params['customerId'];
    let loanId = Number($route.current.params['loanId']);

    that.$onInit = async () => {
      const loans = await customerCache.loans(customerId).toPromise();
      that.loan = loans.find(loan => loan.id === loanId);

      that.pageLabel = $filter('translateEnum')('RELEASE_LOAN_BY_CC', 'LABEL');

      setBreadcrumbs(breadcrumbs,'customer-loan-check-release-label', that.pageLabel);
    };

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`);
    };
    that.cancelChanges = () => {
      that.redirectBack();
    };

    that.save = async () => {
      const res = await command.execute('ReleaseLoanByCC', {
        cashiersCheckNumber: that.checkNumber,
        remarks: that.remarks,
        customerId: customerId,
        productId: loanId
      }).toPromise();

      if(!res.output) {
        return;
      }

      notification.show('Cashier\'s check successfully created.');

      customerCache.loans(customerId).refetch();
      that.redirectBack()
    };
  }
});
