import nxModule from 'nxModule';

import templateUrl from './batch-clearing-page.template.html';
import {IController} from 'angular';

type DisplayMode = 'CHECKS' | 'DISPOSITIONS' | 'CLEARING_RESULT';

class BatchClearingPage implements IController {
  private displayMode: DisplayMode = 'CHECKS';
  constructor() {
  }

  setDisplayMode(mode: DisplayMode): void {
    this.displayMode = mode;
  }
}

nxModule.component('batchClearingPage', {
  templateUrl: templateUrl,
  controller: BatchClearingPage
});
