import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './official-receipt.template.html';
import {
  EntryType
} from "../../../administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.service";
import {DictionaryIssuingBank, TransactionUnit} from "../funds-movement/funds-movement.component";
import GlMappingsService, {LedgerAccountTemplate} from "../../../administration/gl-mappings/gl-mappings.service";
import Authentication from "shared/utils/authentication";
import systemPropertyService from "../../../../../react/system/systemPropertyService";
import {HttpService} from "shared/utils/httpService";
import {OfficialReceipt} from "components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types";
import {Branch} from "management/BranchTypes";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {Dict} from "shared/common/dict.types";
import {BranchService} from "components/service/branch.service";
import {MiscCommandService} from "components/dashboard/miscellaneous-transactions/misc-command.service";
import config from 'config';
import {ActionCategory} from "components/administration/transactions/action-category.types";


interface ReceiptTransaction {
  transactionId?: number;
  customerName: string;
  customerAddress: string;
  receiptSubject: string;
  receiptNo: string;
  targetBranchId: number;
  branchId: number;
  userId: number;
  remarks: string;
  amount?: number;
}

class OfficialReceiptController {

  constructor(readonly actionCommand: ActionCommand,
              readonly glMappingsService: GlMappingsService,
              readonly dict: Dict,
              readonly branchService: BranchService,
              readonly authentication: Authentication,
              readonly miscCommandService: MiscCommandService,
              readonly http: HttpService) {
  }

  assetType!: string;
  cashReceiptThreshold: number = config.cashReceiptThreshold;
  remarksRequired: boolean = true;
  entryType: EntryType = 'DEBIT';
  fixedAccountLabel: string = 'TELLER CASH ON HAND';
  banks: DictionaryIssuingBank[] = [];
  accounts: LedgerAccountTemplate[] = [];
  private actionCode!: string;
  private officialReceipt: OfficialReceipt | null = null;
  readonly labelPrefix: string = 'Interbranch ';

  private readonly titles: { [key: string]: string } = {
    OFFICIAL_RECEIPT_IN_CASH: 'Official receipt in cash:',
    OFFICIAL_RECEIPT_IN_CHECK: 'Official receipt in check:'
  };

  private descriptions: { [key: string]: string } = {
    OFFICIAL_RECEIPT_IN_CASH: 'Official receipt in cash',
    OFFICIAL_RECEIPT_IN_CHECK: 'Official receipt in check'
  };

  private transaction: Partial<ReceiptTransaction> = {};
  blockedUnits: TransactionUnit[] = [];
  category?: ActionCategory;
  fullActionCode!: string;
  title: string = '';
  description: string = '';
  interbranch!: boolean;
  otherBranches: Branch[] = [];
  units: TransactionUnit[] = [];
  autoReceiptEnabled: boolean = true;
  displayEmptyReceiptMessage: boolean = false;

  private readonly targetBranchSelectConfig = {
    placeholder: 'Select a branch',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  async $onInit(): Promise<void> {
    this.actionCode = 'OFFICIAL_RECEIPT_IN_' + this.assetType;
    this.autoReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    this.title = this.titles[this.actionCode];
    this.description = this.descriptions[this.actionCode];
    this.dict.onLoadingComplete(() => {
      this.banks = this.dict['BANK'];
    });
    this.fullActionCode = this.actionCode;
    if (this.interbranch) {
      const branches = await this.branchService.toPromise();
      this.otherBranches = branches.filter(b => Number(b.id) !== Number(this.authentication.context.branchId));
      this.fullActionCode = 'DISTRIBUTED_' + this.actionCode;
      this.title = this.labelPrefix + this.title;
      this.description = this.labelPrefix + this.description;
    }

    const glAccounts = await this.glMappingsService.accounts.toPromise();
    this.accounts = glAccounts;

    if(this.autoReceiptEnabled) {
      try {
        const officialReceipt = await this.http.get<OfficialReceipt>(`/official-receipt/next-available?userId=${this.authentication.context.id}`).toPromise();
        this.officialReceipt = officialReceipt;
        this.transaction.receiptNo = officialReceipt.receiptNumber;
      } catch (response) {
        if (response?.errorCode === 'NO_AVAILABLE_OFFICIAL_RECEIPT') {
          this.displayEmptyReceiptMessage = true;
        } else {
          throw response;
        }
      }
    }
  }

  isOfficialReceiptInCheck(): boolean {
    return ['OFFICIAL_RECEIPT_IN_CHECK', 'DISTRIBUTED_OFFICIAL_RECEIPT_IN_CHECK'].includes(this.fullActionCode);
  }

  cancelChanges(): void {
    this.actionCommand.cancelChanges();
  }

  isLedgerAccountSpecified(): boolean {
    return this.blockedUnits && this.blockedUnits.length > 0;
  }

  onCategoryChange(): void {
    this.transaction.amount = 0;
    this.blockedUnits = [];
    if (this.category) {
      this.blockedUnits = this.accounts
      .filter(account => this.category?.ledgerAccountFullCodes.includes(account.fullCode))
      .map(account => ({
        accountCode: account.fullCode,
        entryType: 'CREDIT'
      }));
    }
  }

  save(): void {
    const units = this.units.filter(unit => unit.accountCode)
      .map(unit => ({
        ...unit,
        fullCode: unit.accountCode,
      }));

    const categoryUnits = {
      categoryId: this.category ? this.category.id : null,
      units,
    };

    this.miscCommandService.executeCommand(this.getCommandName(), {
      ...this.transaction,
      categoryUnits: categoryUnits
    });
  }

  getCommandName(): string {
    const commandName = this.interbranch ? this.labelPrefix + this.actionCode : this.actionCode;
    return _.camelCase('Misc_' + commandName).replace(/^\w/, function (chr) {
      return chr.toUpperCase();
    });
  }

}

nxModule.component('officialReceipt', {
  templateUrl,
  bindings: {
    interbranch: '<',
    assetType: '<'
  },
  controller: OfficialReceiptController
});
