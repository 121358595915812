import nxModule from 'nxModule';

nxModule.directive('stringToBoolean', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {

      // converts boolean => string
      ngModel.$parsers.push(function(value) {
        return value + '';
      });

      // converts string => boolean
      ngModel.$formatters.push(function(value) {
        return 'true' === value;
      });
    }
  };
});
