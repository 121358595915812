import nxModule from 'nxModule';
import templateUrl from './check-deposit.template.html';
import {sum} from "../../../../shared/common/MathUtils";
import Authentication from "shared/utils/authentication";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {DepositoryAccountCache} from "components/service/misc-transaction/depository-account.cache.types";
import {MiscCommandService} from "components/dashboard/miscellaneous-transactions/misc-command.service";
import {BranchService} from "components/service/branch.service";
import DepositoryAccount from "check/DepositoryAccountTypes";
import {Branch} from "management/BranchTypes";
import {Check} from "components/service/check.type";

interface Transaction {
  depositoryAccountId: number;
  checkIds: number[];
  remarks: string;
  targetBranchId: number;
}

export type CheckDepositTarget = 'DEPOSITORY_BANK' | 'BRANCH';

class CheckDepositToBank {
  interbranch!: boolean;
  target!: CheckDepositTarget;
  private header!: string;
  private amount: number = 0;

  private readonly selectConfig = {
    placeholder: 'Select account',
    searchField: ['accountName'],
    valueField: 'id',
    labelField: 'accountName',
    maxItems: 1
  };

  private depositoryAccounts: DepositoryAccount[] = [];
  private branches: Branch[] = [];
  private allDepositoryAccounts: DepositoryAccount[] = [];


  private transaction: Partial<Transaction> = {
    depositoryAccountId: undefined,
    checkIds: [],
    remarks: '',
    targetBranchId: undefined
  };

  targetBranchSelectConfig = {
    placeholder: 'Select a branch',
    searchField: 'name',
    valueField: 'id',
    labelField: 'name',
    maxItems: 1
  };

  constructor (private actionCommand: ActionCommand, private authentication: Authentication,
               private depositoryAccountCache: DepositoryAccountCache, private branchService: BranchService,
               private miscCommandService: MiscCommandService) {

  }

  async $onInit() : Promise<void> {
    const [depositoryAccounts, branches] = await Promise.all([
      this.depositoryAccountCache.toPromise(),
      this.branchService.toPromise()
    ]);

    this.allDepositoryAccounts = depositoryAccounts;
    if(!this.interbranch) {
      this.transaction.targetBranchId = this.authentication.context.branchId;
    }

    this.header = this.getHeader();
    this.branches = this.getBranches(branches);
    this.updateDepositoryAccounts();
  }

  getHeader() : string {
    if (this.target === 'BRANCH') {
      return 'Batch check deposit to branch';
    }
    const header = 'Batch check deposit to depository bank';
    if (!this.interbranch) {
      return header;
    }
    return `Interbranch ${header.toLowerCase()}`;
  }

  getBranches(branches: Branch[]) : Branch[] {
    if (this.target === 'BRANCH') {
      return branches;
    }
    return branches.filter(b => Number(b.id) !== Number(this.authentication.context.branchId));
  }

  onCheckChange(checks: Check[]) : void {
    this.transaction.checkIds = checks.map(check => check.id);
    this.amount = sum(checks.map(check => check.amount)).toNumber();
  }

  updateDepositoryAccounts(): void {
    if (this.target === 'BRANCH') {
      return;
    }
    this.transaction.depositoryAccountId = undefined;
    this.depositoryAccounts = this.allDepositoryAccounts.filter( account =>
      account.allowedMiscCommands?.includes(this.getCommandName())
        && Number(account.branchId) === Number(this.transaction.targetBranchId)
    );
  }

  cancelChanges() : void {
    this.actionCommand.cancelChanges();
  }

  async save() : Promise<void> {
    await this.miscCommandService.executeCommand(this.getCommandName(), { ...this.transaction });
  }

  getCommandName() : string {
    if (this.target === 'BRANCH') {
      return `MiscBatchCheckDepositToBranch`;
    }
    const interbranchPart = this.interbranch ? 'Interbranch' : '';
    return `Misc${interbranchPart}BatchCheckDepositToBank`;
  }
}

nxModule.component('checkDeposit', {
  bindings: {
    interbranch: '<',
    target: '<'
  },
  templateUrl,
  controller: CheckDepositToBank
});
