import nxModule from 'nxModule';
import templateUrl from './sss-lookup-prn.template.html';
import {ssNumberPattern} from '../sss-offline-payment/sss-offline-payment.utils';
import {HttpService} from "shared/utils/httpService";
import {IHttpParamSerializer} from "angular";
import Popup from "shared/common/popup";

interface OnlinePendingIndividualPayment {
  payorName: string;
  ssNumber: string;
  paymentReferenceNumber: string;
  amount: number;
  startingPeriod: string;
  endingPeriod: string;
}

class SssLookupPrn {
  private sssNumber: string = '';
  private payments: OnlinePendingIndividualPayment[] = [];

  constructor(
    private http: HttpService,
    private popup: Popup,
    private $httpParamSerializer: IHttpParamSerializer
  ) {
  }

  public ssNumberPattern(): string {
    return ssNumberPattern('CONTRIBUTION');
  }

  public async fetchBySsn(): Promise<void> {
    const ssn = this.sssNumber;
    const params = this.$httpParamSerializer({ssn});

    try {
      this.payments = await this.http.get<OnlinePendingIndividualPayment[]>(`/sss/online-payment/payor-kind/INDIVIDUAL/ssn?${params}`).toPromise();
    } catch (err) {
      this.popup({text: err.errorMessage, header: 'Error'});
    }
  }
}

nxModule.component('sssLookupPrn', {
  templateUrl,
  controller: SssLookupPrn
});
