export const ataReplenishStrategies = Object.freeze([
  {
    label: 'Never',
    value: 'NONE'
  }, {
    label: 'Immediately',
    value: 'IMMEDIATE'
  }, {
    label: 'On end-of-day',
    value: 'EOD'
  }
]);

/**
 * Returns ATA replenish strategy (see above) converted to the map form,
 * where: KEY = strategy.value, VALUE = strategy.label
 */
export const ataReplenishStrategiesMap = Object.freeze(
  Object.fromEntries(ataReplenishStrategies.map(obj => [obj.value, obj.label]))
);

export interface AtaLabel {
  replenishmentFlag: string,
  replenishmentStrategy: string,
  replenishmentThreshold: string
}

export const ATA_LABELS: Readonly<AtaLabel> = {
  replenishmentFlag: 'Allow account balance replenishment',
  replenishmentStrategy: 'Execute replenishment on',
  replenishmentThreshold: 'Target balance to maintain'
};

export const ACCOUNT_CUSTOM_FEE_HOOKS: ReadonlyArray<string> = Object.freeze([
  'ACCOUNT_EARLY_CLOSURE',
  'TAGGED_CREDIT_MEMO',
  'TAGGED_DEBIT_MEMO',
  'PARTNER_DEPOSIT',
  'PARTNER_WITHDRAWAL',
  'EXTERNAL_TRANSFER_OUTGOING',
  'EXTERNAL_TRANSFER_INCOMING'
]);

export const ACCOUNT_FEE_CALCULATION_METHODS: ReadonlyArray<string> = Object.freeze([
  'PERCENTAGE_OF_OPERATION'
]);

export const interestCycleTypes = Object.freeze([{
  label: 'Daily',
  value: 'DAY'
}, {
  label: 'Month End',
  value: 'MONTH_END'
}, {
  label: 'Quarter End',
  value: 'QUARTER_END'
}, {
  label: 'Every 30 days',
  value: 'EVERY_30_DAYS'
}]);

export const categoryPurposeCodes = ['INDV' , 'CORP' , 'CASH' , 'LOAN' , 'RMT' , 'EPAY' , 'EPAC' , 'SALA' , 'GOVT' , 'DD'];

export type CategoryPurposeCode = typeof categoryPurposeCodes[number];
