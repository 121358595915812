import nxModule from 'nxModule';
import templateUrl from './value-date.template.html';
import './value-date.less';
import {INgModelController} from "angular";

class ValueDateController {
  private ngModel!: INgModelController;
  private valueDate: Date | null = null;

  $onChanges() {
    this.syncDateModel();
  }

  syncDateModel() {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.valueDate);
    }
  }

}

nxModule.component('valueDate', {
  controller: ValueDateController,
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    ngRequired: '<',
    max: '<'
  }
});