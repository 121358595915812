import nxModule from 'nxModule';

import templateUrl from './toggle-buttons.template.html';

class ToggleButtons {
  $onInit() {
    this.ngModel.$render = () => {
      this.selectedId = this.ngModel.$modelValue;
    };
  }

  updateModel() {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.selectedId);
  }
}

nxModule.component('toggleButtons', {
  templateUrl,
  require: {
    // holds item id
    ngModel: '^ngModel'
  },
  bindings: {
    // each item has format {id: 'id', label: 'label'}
    options: '<'
  },
  controller: ToggleButtons
});