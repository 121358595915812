import nxModule from 'nxModule';

import templateUrl from './deposit-account-fees.template.html';
import {CalculationMethod, FeeDefinition} from "../../../service/fees/fee.types";
import {AccountType} from "../../../service/account.types";
import {IFormController, IOnChangesObject} from "angular";

class DepositAccountFees {

  private accountProduct!: AccountType;
  private form!: IFormController;

  private feeDefinitions: FeeDefinition[] = [];

  $onChanges(changes: IOnChangesObject) {
    if (changes.accountProduct) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.feeDefinitions = this.accountProduct.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');
    }
  }

  onSave(): void {
    this.accountProduct.feeDefinitions = this.feeDefinitions.filter(fd => fd.feeClass === 'CUSTOM');
    this.setCalculationMethod();
  }

  setCalculationMethod(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.accountProduct.feeDefinitions.forEach(feeDef => {
      feeDef.calculationMethod = this.getCalculationMethod(feeDef);
    });
  }

  /**
   * Currently, accounts only support custom fees that can be applied on ACCOUNT_EARLY_CLOSURE, TAGGED_CREDIT_MEMO
   * and TAGGED_DEBIT_MEMO.
   *
   * The calculation methods supported are:
   * FIXED_AMOUNT: for all possible fees. When feeType is FIXED, this should be the value
   * PERCENTAGE_OF_OPERATION: for TAGGED_DEBIT_MEMO and TAGGED_CREDIT_MEMO. When feeType is PERCENTAGE, this should be
   * the value.
   */
  getCalculationMethod(feeDef: FeeDefinition): CalculationMethod {
    switch (feeDef.feeType) {
      case 'FIXED':
        return 'FIXED_AMOUNT';
      case 'PERCENTAGE':
        return 'PERCENTAGE_OF_OPERATION';
      default:
        console.error(`Invalid fee type ${feeDef.feeType} provided`);
        throw new Error('Invalid fee type');
    }
  }
}

nxModule.component('depositAccountFees', {
  templateUrl,
  controller: DepositAccountFees,
  bindings: {
    accountProduct: '<',
    form: '='
  }
});