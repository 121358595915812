import nxModule from 'nxModule';
import templateUrl from './number-pattern.template.html';
import './number-pattern.style.less';

import {IAttributes, IComponentController, INgModelController} from 'angular';

/**
 * On error returns undefined.
 */
const parseText: (text: string, tokens: string[]) => string[] | undefined = (text, tokens) =>  {
  if(!text) {
    return [];
  }

  for(const token of tokens) {
    if(text.startsWith(token)) {
      const tail = text.substring(token.length);
      const parsedTail = parseText(tail, tokens);

      if(parsedTail != undefined) {
        return [token, ...parsedTail];
      } else {
        return parsedTail;
      }
    }
  }

  return undefined;
};

interface Option {
  iconClass: string,
  header: string
  pattern: string,
  text: string,
}

class NumberPatternComponent implements IComponentController {
  private pattern: string | undefined | null;
  private length?: number;
  private ngModel!: INgModelController;
  private readonly options!: Option[];

  constructor(protected $attrs: IAttributes) {
  }

  parse(text: string): string[] | undefined {
    const patterns = this.options.map(item => item.pattern);
    return parseText(text, patterns);
  }

  format(tokens: string[]): string {
    if(!tokens || tokens.length == 0) {
      return '';
    }

    return tokens.join('');
  }

  $onInit(): void {
    this.ngModel.$render = (): void => {
      this.pattern = this.ngModel.$viewValue;
    };

    this.ngModel.$parsers.push(value => {
      return this.parse(value);
    });

    this.ngModel.$formatters.push(value => {
      return this.format(value);
    });

    this.ngModel.$validators.length = (modelValue): boolean => {
      if (!modelValue) return true;

      // handled by required validator
      if(modelValue.length === 0) {
        return true;
      }

      if(this.length === undefined || this.length === null) {
        return true;
      }


      const text = modelValue.join("");
      return text.length == this.length;
    };
  }

  addPattern(item: Option): void {
    if(!this.pattern) {
      this.pattern = '';
    }

    this.pattern += item.pattern;
    this.updateNgModel();
  }

  updateNgModel(): void {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.pattern);
  }
}


nxModule.component('numberPattern', {
  controller: NumberPatternComponent,
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    length: '<',
    options: '<'
  }
});