import nxModule from 'nxModule';
import templateUrl from './upload-instapay-members.template.html';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {CommandService} from 'shared/utils/command/command.types';
import Popup from 'shared/common/popup';
import {AttachedFile} from 'components/service/file.service';
import {NxRouteService} from 'routes/NxRouteService';
import {UPLOAD_INSTAPAY_MEMBERS_SAMPLE_FILE} from './upload-instapay-members.types';

class UploadInstapayMembers {
  membersFile: AttachedFile[] = [];

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private popup: Popup,
              private $route: NxRouteService) {
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(UPLOAD_INSTAPAY_MEMBERS_SAMPLE_FILE);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'sample-upload-instapay-members.xlsx';
    a.click();
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to replace existing Instapay members in the system?`,
      details: [
        {label: 'File', description: this.membersFile[0].fileName}
      ]
    });

    if (!confirmed) {
      return;
    }

    const fileId: number = this.membersFile[0].id;
    const commandResult = await this.command.execute<{fileId: number}, {recordCount: number}>(
      'UploadInstapayMembers',
      {fileId: fileId}).toPromise();
    await this.popup({
      text: `Successfully uploaded. Total Instapay members ${commandResult.output.recordCount} `
    });
    this.$route.reload();
  }

}

nxModule.component('uploadInstapayMembers', {
  templateUrl,
  controller: UploadInstapayMembers
});
