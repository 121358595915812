import nxModule from 'nxModule';
import {IController, IIntervalService, ILocationService} from 'angular';
import _ from 'lodash';
import moment from 'moment';

import templateUrl from './batch-start-the-day.template.html';
import {
  Holiday,
  statusMappings,
  WorkingDay,
  WorkingDayStatusMapping
} from '../../../../../react/management/WorkingDayType';
import {Organization, OrganizationCache} from '../../../service/organization.cache';
import {Subscription} from 'rxjs';
import {Branch} from "management/BranchTypes";
import {WorkingDaysCache} from 'components/service/working-days-cache';
import Notification from "shared/utils/notification";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {Confirmation} from "shared/common/confirmation.types";
import {BranchService} from 'components/service/branch.service';

interface WorkingDayInfo {
  status: WorkingDayStatusMapping;
  holiday: Holiday;
}

interface SelectableProperties {
  selected: boolean;
  clearingDay: boolean;
  bspApproval: boolean;
}

type BranchTableRow = Branch & WorkingDayInfo & SelectableProperties;

type BranchStartDayInput = {
  branchId: number;
  clearingDay: boolean;
  bspApproval: boolean;
}

type CommandOutput = {
  branchIdErrorMap: { [branchId: number]: string; };
}

class BatchStartTheDayComponent implements IController {
  private readonly workingDayStatusMappings: WorkingDayStatusMapping[] = statusMappings;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private rootOrganization: Organization;
  private branches: BranchTableRow[] = [];
  private branchSubscription?: Subscription;

  // master checkboxes
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  processAll: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  clearingDayAll: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bspApprovalAll: boolean;

  constructor(private $location: ILocationService,
              private $interval: IIntervalService,
              private branchService: BranchService,
              private organizationCache: OrganizationCache,
              private workingDaysCache: WorkingDaysCache,
              private confirmation: Confirmation,
              private command: CommandService,
              private $route: NxRouteService,
              private $filter: NxIFilterService,
              private authentication: Authentication,
              private confirmationTemplate: ConfirmationTemplate,
              private http: HttpService,
              private notification: Notification,
              private popup: Popup) {
  }

  $onInit(): void {
    this.initBranchAndWorkingDayObservable();
  }

  private initBranchAndWorkingDayObservable(): void {
    this.branchSubscription = this.branchService.toObservable()
      .combineLatest(this.organizationCache.toObservable(), (branches: Branch[], organizations: Organization[]) => {
          this.rootOrganization = _.find(organizations, { 'root': true })!;
          return branches.map(branch => {
            const organization = _.find(organizations, { id: branch.organizationId });
            return { ...branch, organization };
          });
        }
      )
      .combineLatest(this.workingDaysCache.toObservable(), (branches, workingDays: WorkingDay[]) => {
        return branches.map(branch => {
          const workingDay = workingDays.find(workingDay => Number(workingDay.branchId) === Number(branch.id));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const status: WorkingDayStatusMapping = this.workingDayStatusMappings.find(mapping => mapping.code === workingDay.status);

          return {
            ...branch,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            status: { code: workingDay.status, label: status.label },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            systemDate: workingDay.systemDate,
            selected: false,
            clearingDay: false,
            bspApproval: false,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            holiday: workingDay.holiday
          }
        });
      })
      .subscribe(branches => {
        this.branches = branches
          .filter(branch => this.authentication.context.branchIds.includes(branch.id))
          .sort((a, b) => (a.code ?? '').localeCompare(b.code ?? ''));
      });
  }

  onIndividualSODClicked(branchId: number): void {
    const branch = this.branches.find(branch => Number(branch.id) === Number(branchId))!;
    if (!this.canSOD(branch.status.code)) {
      this.popup({ header: 'Info', text: `Start of day for this branch cannot be performed due to invalid working day status: ${branch.status.code}` });
      return;
    }

    this.$location.path(`/dashboard/miscellaneous-transactions/batch-start-the-day/${ branchId }`);
  }

  canSOD(statusCode: string): boolean {
    return statusCode === 'PENDING';
  }

  isBranchHoliday(holiday: Holiday): boolean {
    return !_.isEmpty(holiday);
  }

  anyRequiresBSPApproval(): boolean {
    return (this.branches || []).some(branch => this.isBranchCheckboxAvailable(branch, 'bspApproval'));
  }

  isBranchCheckboxAvailable(branch: BranchTableRow, propertyName: keyof SelectableProperties): boolean {
    let holidayCondition = true;
    if (propertyName === 'bspApproval') {
      holidayCondition = this.isBranchHoliday(branch.holiday);
    }

    return this.canSOD(branch.status.code) && holidayCondition;
  }

  private readonly updatePropertyMasterCheckbox: { [updatedPropertyName in keyof SelectableProperties]: (newMasterCheckboxValue: boolean) => boolean } = {
    selected: (newMasterCheckboxValue: boolean) => this.processAll = newMasterCheckboxValue,
    clearingDay: (newMasterCheckboxValue: boolean) => this.clearingDayAll = newMasterCheckboxValue,
    bspApproval: (newMasterCheckboxValue: boolean) => this.bspApprovalAll = newMasterCheckboxValue
  };

  onBranchCheckboxClicked(currentValue: boolean, propertyName: keyof SelectableProperties): void {
    if (!currentValue) {
      this.updatePropertyMasterCheckbox[propertyName](false);
    }
  }

  onMasterCheckboxClicked(currentValue: boolean, propertyToUpdate: keyof SelectableProperties): void {
    this.branches.forEach(branch => {
      if (this.isBranchCheckboxAvailable(branch, propertyToUpdate)) {
        branch[propertyToUpdate] = currentValue;
      }
    });
  }

  isMasterCheckboxEnabled(): boolean {
    return (this.branches || []).some(branch => this.canSOD(branch.status.code));
  }

  isStartBatchButtonEnabled(): boolean {
    return (this.branches || []).some(branch => branch.selected);
  }

  async onStartBatchButtonClicked(): Promise<void> {
    if (!await this.showConfirmationWindow()) return;

    const output = await this.executeBatchStartDay();

    if (!_.isEmpty(output.branchIdErrorMap)) {
      this.showErrorsPopup(output);
    } else {
      this.resetMasterCheckboxes();
    }

    this.refetchCache();
  }

  private resetMasterCheckboxes(): void {
    for (const property in this.updatePropertyMasterCheckbox) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.updatePropertyMasterCheckbox[property](false);
    }
  }

  private showErrorsPopup(output: unknown): void {
    let errorDetails: string = `Following branches encountered an error while starting day:<br>`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const [branchId, msg] of Object.entries(output.branchIdErrorMap)) {
      const branch: Branch = this.branches.find(branch => Number(branch.id) === Number(branchId))!;
      errorDetails = `${ errorDetails } <strong>${ branch.name }</strong>: ${ msg }<br>`;
    }
    this.popup({ header: 'Info', text: errorDetails, renderHtml: true });
  }

  private async executeBatchStartDay(): Promise<CommandOutput> {
    const inputs: BranchStartDayInput[] = this.getSelectedBranches()
      .map((branch: BranchTableRow) => ({
        branchId: branch.id,
        clearingDay: branch.clearingDay,
        bspApproval: branch.bspApproval || false
      }));
    const commandInput = { inputs };
    const { output } = await this.command.execute<unknown, CommandOutput>('BatchStartDay', commandInput).toPromise();
    return output;
  }

  private async showConfirmationWindow(): Promise<boolean> {
    const confirmationDetails = this.getSelectedBranches()
      .map(branch =>
        ({
          label: branch.name,
          description: this.$filter('prettyDate')(moment(branch.systemDate).toDate())
        }));

    return await this.confirmationTemplate({
      question: 'Do you want to start the day for the following branches?',
      details: confirmationDetails,
      warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be started.'
    });
  }

  private getSelectedBranches(): BranchTableRow[] {
    return this.branches
      .filter(branch => branch.selected);
  }

  private refetchCache(): void {
    this.branchService.refetch();
    this.workingDaysCache.refetch();
  }

  onExitClicked(): void {
    this.$location.path('/dashboard/miscellaneous-transactions');
  }

  $onDestroy(): void {
    this.branchSubscription?.unsubscribe();
  }
}

nxModule.component('batchStartTheDay', {
  templateUrl,
  controller: BatchStartTheDayComponent
});
