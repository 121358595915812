import nxModule from 'nxModule';
import templateUrl from './error-page.template.html';
import './error-page.style.less';

class ErrorPage {
  // binding
  type!: string;
}

nxModule.component('errorPage', {
  templateUrl,
  bindings: {
    type: '@'
  },
  controller: ErrorPage
});