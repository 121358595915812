import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('currencyCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/currencies'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.currency,
    cacheName: 'currency'
  })
);
