import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import {NxIconButton} from '@nextbank/ui-components';
import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import React, {Factory, ReactElement} from 'react';
import TreeNodeItem from 'tree/TreeNodeItem';
import TreeNodeItemWrapper from 'tree/TreeNodeItemWrapper';
import {v4 as uuid} from 'uuid';
import EditableSubtreeList from './EditableSubtreeList';
import styles from './EditableSubtreeView.scss';
import iconStyles from './iconStyles.scss';

export interface TreeNodeConfirmationProps<Value> {
  treeNode: TreeNode;
  mapping: Map<string, unknown>;
  // undefined when value is invalid
  value: Value | undefined;
}

export type TreeNodeEditComponentFactory<Value> = Factory<TreeNodeEditProps<Value>>;

export interface TreeNodeEditProps<Value> {
  // undefined when node was just added
  value: Value | undefined;
  treeNode: TreeNode;
  mapping: Map<string, Value>;
}

export type TreeNodeViewComponentFactory<Value> = Factory<TreeNodeViewProps<Value>>;

export interface TreeNodeViewProps<Value> {
  value: Value;
}

export const TreeNodeConfirmation = function<Value>({mapping, treeNode, value}: TreeNodeConfirmationProps<Value>): ReactElement {
  return <div>
    <NxIconButton
      className={iconStyles.icon}
      ariaLabel="Close"
      icon={<CloseIcon />}
      onClick={(): void => {
        treeNode.toggleEditing();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if(!mapping.get(treeNode.id)) {
          treeNode.remove(true);
        }}
    }/>
    <NxIconButton
      className={iconStyles.icon}
      ariaLabel="Save"
      disabled={!value}
      icon={<SaveIcon/>}
      onClick={(): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mapping.set(treeNode.id!, value);
        treeNode.toggleEditing();
      }} />
    </div>;
};

const EditableSubtreeView = function <Value>({mapping, treeNode, disabled, TreeNodeEdit, TreeNodeView}: {
  mapping: Map<string, Value>,
  treeNode: TreeNode,
  disabled: boolean,
  TreeNodeEdit: TreeNodeEditComponentFactory<Value>;
  TreeNodeView: TreeNodeViewComponentFactory<Value>;
}): ReactElement {
  const addNode = async (parent: TreeNode): Promise<void> => {
    const id = uuid();
    const children = parent.addChild({
      id,
      text: '',
      itree: {
        state: {
          focused: true,
        },
      }
    });

    children.toggleEditing();
    await parent.expand();

  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const id = treeNode.id;
  const value = mapping.get(id);

  // Only render if node is available
  if (treeNode.available()) {
    return <TreeNodeItemWrapper treeNode={treeNode} selectable={false} editable={!disabled}>
      {!disabled && !treeNode.editing() ?
        <span className={clsx("btn-group", styles.buttons)}>
          <NxIconButton
            className={iconStyles.icon}
            ariaLabel="Edit this node"
            icon={<EditIcon />}
            onClick={(): TreeNode => treeNode.toggleEditing()} />
          <NxIconButton
            className={iconStyles.icon}
            ariaLabel="Add a child node"
            icon={<AddIcon />}
            onClick={(): Promise<void> => addNode(treeNode)} />
          <NxIconButton
            className={iconStyles.icon}
            ariaLabel="Remove this node"
            icon={<RemoveIcon />}
            onClick={(): void => {
              if(mapping.get(id)) {
                mapping.delete(id);
              }

              treeNode.remove();
            }} />
       </span>: null
      }
      <TreeNodeItem treeNode={treeNode} className={treeNode.editing() ? styles.editing: ''} checkbox={false}>
        {treeNode.editing() ?
          <TreeNodeEdit value={value} treeNode={treeNode} mapping={mapping}/>
          : (value ? <TreeNodeView value={value} /> : 'No value')
        }
      </TreeNodeItem>
      {
        (treeNode.expanded() && treeNode.hasChildren()) ?
        <EditableSubtreeList treeNodes={treeNode.getChildren()}
                             mapping={mapping}
                             disabled={disabled}
                             TreeNodeEdit={TreeNodeEdit}
                             TreeNodeView={TreeNodeView} /> : null
      }
    </TreeNodeItemWrapper>;
  }

  return <></>;
};

export default EditableSubtreeView;
