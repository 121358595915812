import nxModule from 'nxModule';
import Spinner from 'spin.js';
import $ from 'jquery';

const loaderFactory = () => {
  var instanceNumber = 0;
  var loaderTexts = [];

  // init loader
  var html = '<div style="z-index:999999999999999"><div class="loader-wrapper"><div class="loader" id="inner_div"><span id="loader_text">Loading...</span></div></div><div class="loader_bg"></div></div>';
  var element = $(html);
  var loaderDiv = $("#inner_div", element);
  var loaderText = $("#loader_text", element);
  element.hide();
  $("#modal-container").append(element);

  // spin.js
  var opts = {
    lines: 9, // The number of lines to draw
    length: 4, // The length of each line
    width: 5, // The line thickness
    radius: 8, // The radius of the inner circle
    corners: 1, // Corner roundness (0..1)
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#000', // #rgb or #rrggbb
    speed: 1, // Rounds per second
    trail: 64, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    hwaccel: true, // Whether to use hardware acceleration
    className: 'spinner', // The CSS class to assign to the spinner
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    top: '50', // Top position relative to parent in px
    left: '10' // Left position relative to parent in px
  };

  var spinner = new Spinner(opts).spin();
  loaderDiv.prepend(spinner.el);


  var loader = {
    show: function (text) {
      instanceNumber++;
      if (text === undefined || text === null) {
        text = "Loading...";
      }
      loaderTexts.push(text);

      loaderText.text(text);
      if(instanceNumber === 1) { // first text always wins
        element.show();
        element.css("opacity", "0");
        element.delay(500); // delay showing loader
        element.animate({'opacity': '1'}, 400);
      }

      return loaderText.length;
    },

    dismiss: function (loaderId) {
      instanceNumber--;
      if(instanceNumber > 0 && loaderId) {
        loaderTexts[loaderId - 1] = undefined;
        const textsLeft = loaderTexts.filter(el => el);
        loaderText.text(textsLeft[textsLeft.length - 1]);
      } else if (instanceNumber === 0) {
        loaderTexts = [];
        element.hide();
        element.finish();
      } else if (instanceNumber < 0) {
        console.log("Assertion Failed: loader.instanceNumber < 0");
        instanceNumber = 0;
      }
    }
  };

  return loader;
};

nxModule.factory('loader', loaderFactory);

