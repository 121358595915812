import contentDisposition from 'content-disposition';

export default class FileUtil {
  parseHeadersForContentFilename(headers: Record<string,string>): string | null {
    const contentDispositionHeader = headers['content-disposition'];
    if (!contentDispositionHeader) {
      return null;
    }
    const disposition = contentDisposition.parse(contentDispositionHeader);
    const {filename,} = disposition.parameters;
    return filename;
  }

  downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.download = filename;
    a.href = url;
    a.click();
  }
}