import nxModule from 'nxModule';
import {IController, IFormController, ILocationService} from 'angular';
import _ from 'lodash';

import templateUrl from './loan-edit-pdc.template.html';
import './loan-edit-pdc.style.less';
import {Amortization, Loan} from '../../../../service/loan.types';
import moment from 'moment';
import {LoanPDCDetails, LoanPDCType} from '../../../../service/loan-pdc.types';
import {Breadcrumbs} from "angular-breadcrumbs";
import {Dict} from "shared/common/dict.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {SystemDateService} from "components/service/system-date.service.types";
import {HttpService} from "shared/utils/httpService";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from "shared/utils/command/command.types";
import {
  DictionaryIssuingBank
} from "components/dashboard/miscellaneous-transactions/funds-movement/funds-movement.component";

interface LoanPDCUpdateRequest {
  pdcId: number;
  amortizationId: number;
  type: LoanPDCType;
  bankId: number;
  amount: number;
  validFrom: string;
  micrNumber: string;
}

class LoanEditPdc implements IController {
  // bindings
  pdcId!: number;
  loan!: Loan;

  // not bindings
  updateForm!: IFormController;
  readonly checkTypes: LoanPDCType[] = [LoanPDCType.ON_US, LoanPDCType.OUTWARD];
  readonly bankSelectConfig = {
    placeholder: 'Select bank',
    searchField: ['description'],
    valueField: 'id',
    labelField: 'description',
    maxItems: 1
  };
  banks: DictionaryIssuingBank[] = [];
  amortizations: Amortization[] = [];
  pdcList: LoanPDCDetails[] = [];
  pdc!: LoanPDCDetails;
  pdcUpdateRequest: Partial<LoanPDCUpdateRequest> = {};

  constructor(private readonly $location: ILocationService,
              private readonly breadcrumbs: Breadcrumbs,
              private readonly dict: Dict,
              private readonly customerCache: CustomerCache,
              private readonly systemDateService: SystemDateService,
              private readonly http: HttpService,
              private readonly confirmation: Confirmation,
              private readonly command: CommandService) {

  }

  async $onInit(): Promise<void> {
    this.dict.onLoadingComplete(() => this.banks = this.dict['BANK']);
    this.pdcList = await this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).toPromise();
    this.pdc = this.pdcList.find(p => Number(p.id) === Number(this.pdcId))!;
    this.pdcUpdateRequest = {
      pdcId: this.pdc.id,
      amortizationId: this.pdc.amortizationId,
      type: this.pdc.type,
      bankId: this.pdc.bankId,
      amount: this.pdc.amount,
      validFrom: this.pdc.validFrom,
      micrNumber: this.pdc.micrNumber
    };
    this.amortizations = await this.getValidAmortizations();
  }

  private async getValidAmortizations(): Promise<Amortization[]> {
    const systemDate = await this.systemDateService.getCurrentUserBranchSystemDate();
    return this.loan.amortizationSchedule.list
      .filter(a => a.amortizationBalance > 0)
      .filter(a => {
        // filter out amortizations that already have PDC
        return !this.pdcList.some(pdc => this.isPDCAssignedToAmortization(pdc, a))
          // but leave amortization of the PDC we are editing
          || this.isPDCAssignedToAmortization(this.pdc, a);
      })
      .filter(a => {
        return this.isPDCAssignedToAmortization(this.pdc, a) || moment(a.dueDate).isAfter(systemDate);
      });
  }

  private isPDCAssignedToAmortization(pdc: LoanPDCDetails, amortization: Amortization): boolean {
    return Number(pdc.amortizationId) === Number(amortization.id);
  }

  onAmortizationChange(): void {
    const amortization: Amortization = this.amortizations.find(a => Number(a.id) === Number(this.pdcUpdateRequest.amortizationId))!;
    this.pdcUpdateRequest.amount = amortization.amortizationBalance;
    this.pdcUpdateRequest.validFrom = amortization.dueDate;
  }

  async onUpdateClicked(): Promise<void> {
    if (await this.confirmation('Are you sure you want to save changes to this PDC?')) {
      const { approvalRequired } = await this.command.execute('UpdateLoanPDC', this.pdcUpdateRequest).toPromise();
      if (!approvalRequired) {
        this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).refetch();
        this.redirectBack();
      }
    }
  }

  onCancelClicked(): void {
    this.redirectBack();
  }

  private redirectBack() {
    const { path: parentPath } = _.nth(this.breadcrumbs.get(), -2)!;
    this.$location.path(parentPath);
  }
}

nxModule.component('loanEditPdc', {
  templateUrl,
  controller: LoanEditPdc,
  bindings: {
    pdcId: '<',
    loan: '<'
  }
});
