import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from "react";
import {Link, match, matchPath, useLocation} from "react-router-dom";
import {Location} from 'history';
import Routes from '../routes/Routes';
import styles from './NxBreadcrumb.scss';
import RightArrow from './IconChevronRight.svg';
import RouteDefinition from "../routes/RouteDefinition";

const nonNull = function<T>(obj: T | null): obj is T {
  return obj !== null;
};

interface RouteDetails {
  route: RouteDefinition,
  match: match<Record<string, string>>
}

const fetchLabels = async (routes: RouteDetails[],
                           setState: (state: NxBreadcrumbState) => void,
                           location: MutableRefObject<Location>): Promise<void> => {
  const initialLocation = location.current;
  const fetchedLabels = await Promise.allSettled(routes.map((r) => {
    if (typeof r.route.name === 'string') {
      return Promise.resolve(r.route.name);
    }

    return r.route.name(r.match.params);
  }));

  const labels = fetchedLabels.map((label, i) => {
    if (label.status === 'fulfilled') {
      return label.value;
    }

    console.error(`Error fetching route label ${routes[i].route.path}`);
    return '??';
  });

  if(initialLocation !== location.current) {
    // location changed, routes don't apply
    return;
  }

  setState({
    routes,
    labels,
  });
};

const renderRoutes = (routes: RouteDetails[], routeLabels: string[]): ReactElement[] => {
  return routes.map(({route, match}, i) => {
    const label = routeLabels[i];

    const text = <span className={styles.item} key={match.path}>
          {label}
        </span>;

    if (route.Component && i !== routes.length - 1) {
      return <Link color="inherit" to={match.url} key={match.path}>
        {text}
      </Link>;
    } else {
      return text;
    }
  });
};

interface NxBreadcrumbState {
  routes: RouteDetails[];
  labels: string[];
}

/** Renders site navigation. If path is not navigable, we display a text
 */
const NxBreadcrumb = (): ReactElement => {
  const location = useLocation();
  const currentLocation = useRef(location);
  currentLocation.current = location;

  const [state, setState] = useState<NxBreadcrumbState>();

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter(Boolean); // remove leading empty path
    const routes = pathnames.map((value, index): RouteDetails | null => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      for(const route of Routes) {
        const routeMatch = matchPath(to, {
          path: route.path,
          exact: true,
        });

        if(routeMatch) {
          return {route, match: routeMatch};
        }
      }

        return null;
      })
      .filter(nonNull);

    setState(undefined);
    fetchLabels(routes, setState, currentLocation);
  }, [location]);

  return <Breadcrumbs separator={<RightArrow/>} aria-label="breadcrumb" className={styles.container}>
    {state ? renderRoutes(state.routes, state.labels) : <span className={styles.item}>&nbsp;</span>}
  </Breadcrumbs>;
};

export default NxBreadcrumb;