import nxModule from 'nxModule';

import templateUrl from './credit-scoring-modal-legend.template.html';
import './credit-scoring-modal-legend.styles.less';
import {CreditScore, findColor, NonPredictionKeys} from "components/customer/credit-scoring/credit-scoring.types";

class CreditScoringLegend {
  readonly colorLabels: Record<string, string> = {
    'bad': 'color-bad',
    'poor': 'color-poor',
    'average': 'color-average',
    'good': 'color-good',
    'excellent': 'color-excellent',
    'outstanding': 'color-outstanding',
  };

  labels!: Record<NonPredictionKeys, string>;
  score!: CreditScore;

  public getScoreColor(score: number): string {
    return findColor(score);
  }

  public getScoreColorLabel(score: number): string {
    return this.colorLabels[this.getScoreColor(score)];
  }
}

nxModule.component('creditScoringModalLegend', {
  templateUrl,
  bindings: {
    labels: '<',
    score: '<'
  },
  controller: CreditScoringLegend
});
