import nxModule from "nxModule";
import {IScope} from "angular";
import {PermissionContext} from "shared/utils/permission.service";

interface PermissionContextScope extends IScope {
  permissionContext: PermissionContext
}

nxModule.directive('permissionContext', function () {
  return {
    scope: {
      permissionContext: '='
    },
    controller: function ($scope: PermissionContextScope) {
      $scope.$watch(scope => (scope as PermissionContextScope).permissionContext, () => {
        this.context = $scope.permissionContext;
      });
    }
  };
});
