import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './file-id-upload.template.html';

class FileIdUpload {
  $onInit() {
    this.idsToInnerModelCache = new Map();
    this.idsCache = new Map();

    this.ngModel.$render = () => {
      let fileIds = this.ngModel.$viewValue || [];

      if(this.singleFile && _.isNumber(fileIds)) {
        fileIds = [fileIds];
      }

      const cacheKey = this.toNormalizedIdsReference(fileIds);
      this.idsCache.set(cacheKey, fileIds);

      if (!this.idsToInnerModelCache.has(cacheKey)) {
        this.idsToInnerModelCache.set(cacheKey, fileIds.map(id => ({id: id})));
      }

      this.innerModel = this.idsToInnerModelCache.get(cacheKey);
    };
  }

  toNormalizedIdsReference(ids) {
    const clonedIds = [...ids];
    clonedIds.sort();
    return JSON.stringify(clonedIds);
  }

  valueChanged() {
    const fileIds = this.innerModel.map(item => item.id);
    const cacheKey = this.toNormalizedIdsReference(fileIds);

    if(!this.idsCache.has(cacheKey)) {
      this.idsCache.set(cacheKey, fileIds);
    }

    this.idsToInnerModelCache.set(cacheKey, this.innerModel);
    const cachedValue = this.idsCache.get(cacheKey);
    this.ngModel.$setViewValue(this.singleFile ? cachedValue[0] : cachedValue);
  }
}

nxModule.component('fileIdUpload', {
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    'fileAdditionalMetadata': '<',
    'validate': '<',
    'maxFiles': '<',
    'maxSize': '<',
    'showFileName': '<',
    'showRemarks': '<',
    'ngDisabled' : '<',
    'enableWebcam': '<',
    'allowDuplicates': '<',
    // true if we want to bind to a single file instead of an array
    // this allows us use ng-model of file-id-upload directly if
    // our dto holds only one instance of a file id
    'singleFile': '<'
  },
  controller: FileIdUpload
});
