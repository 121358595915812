import CustomFieldDefinitionList from 'custom-field/CustomFieldDefinitionList';
import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import CustomFieldDefinitionView from 'custom-field/CustomFieldDefinitionView';
import CustomFieldRestrictionEdit from 'custom-field/CustomFieldRestrictionEdit';
import CustomFieldRestrictionList from 'custom-field/CustomFieldRestrictionList';
import CustomFieldService from 'custom-field/CustomFieldService';
import React, {ReactElement} from 'react';
import RouteDefinition from 'routes/RouteDefinition';
import EnumFormatter from 'tools/EnumFormatter';

export const listRoute = (prefix: string, group: CustomFieldGroup, isTopLevelPath: boolean): RouteDefinition => {
  const CustomGroupFieldDefinitionList = (): ReactElement => <CustomFieldDefinitionList group={group}
                                                                                        pathPrefix={prefix}/>;
  const formatter = new EnumFormatter();
  const humanFriendlyGroup = formatter.format(group);
  return {
    name: isTopLevelPath ? `${humanFriendlyGroup} custom fields` : 'Custom fields',
    path: `${prefix}/custom-fields/groups/${group}`,
    Component: CustomGroupFieldDefinitionList
  };
};

export const allRoutes = (prefix: string, group: CustomFieldGroup, isTopLevelPath: boolean): RouteDefinition[] => {
  const CustomGroupFieldDefinitionView = (): ReactElement => <CustomFieldDefinitionView group={group} pathPrefix={prefix}/>;
  const CustomFieldRestrictionListView = (): ReactElement => <CustomFieldRestrictionList group={group} pathPrefix={prefix}/>;
  const CustomFieldRestrictionEditView = (): ReactElement => <CustomFieldRestrictionEdit group={group}/>;
  const getDefinitionName = async (id: string): Promise<string> => {
    if (id === 'new') {
      return 'new';
    }

    const definition = await CustomFieldService.readDefinition(Number(id));
    return definition.name;
  }
  return [
    listRoute(prefix, group, isTopLevelPath),
    {
      name: async (params: {id: string}): Promise<string> => {
        return getDefinitionName(params.id);
      },
      path: `${prefix}/custom-fields/groups/${group}/fields/:id`,
      Component: CustomGroupFieldDefinitionView,
      commandAccess: 'EditCustomFieldDefinition'
    },
    {
      name: async (params: {id: string}): Promise<string> => {
        return `${await getDefinitionName(params.id)} restrictions`;
      },
      path: `${prefix}/custom-fields/groups/${group}/fields/:id/restrictions`,
      commandAccess: 'EditCustomFieldDefinition',
      Component: CustomFieldRestrictionListView
    },
    {
      name: async (params: {id: string}): Promise<string> => {
        return `${await getDefinitionName(params.id)} restriction`;
      },
      path: `${prefix}/custom-fields/groups/${group}/fields/:id/restrictions/:restrictionId`,
      commandAccess: 'EditCustomFieldDefinition',
      Component: CustomFieldRestrictionEditView
    }
  ];
};