import {InterestBoardService} from 'components/administration/casa/interest-board/interest-board.service.types';
import LoanProductsCache from 'components/administration/loan/common/loan-products.cache';
import {ActionType, Columns, Sorting} from 'components/common/dynamic-list/dynamic-list.component';
import {ProductService} from 'components/customer/common/product.service';
import {AccessRuleService} from 'components/service/access/access-rule.service';
import {AccessRule} from 'components/service/access/access-rule.types';
import {LoanCreationType} from 'components/service/create-loan-input.types';
import {LoanType} from 'components/service/loan-type.types';
import {ProductDefinition} from 'components/service/product.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import _ from 'lodash';
import nxModule from 'nxModule';
import Popup from 'shared/common/popup';
import {HttpService} from 'shared/utils/httpService';
import {CustomerType} from '../../../../profile/customer-profile.types';
import templateUrl from './customer-loan-create-list-table.template.html';

interface LoanTypeDescription {
  productName: string;
  productTypeCode: string;
  minAmountFormatted: string;
  maxAmountFormatted: string;
  open: (loanType: LoanType) => void;
}

interface LimitValidationInput {
  customerIds: number[];
  definitionId: number;
  creationType: LoanCreationType;
  remadeLoanId?: number;
}

interface LimitValidationOutput {
  customerId: number;
  customerNumber: string;
  limitExceeded: boolean;
}

class CustomerLoanCreateListTable {
  protected loanTypes: (LoanType & LoanTypeDescription)[] = [];
  private customerType: CustomerType = 'INDIVIDUAL';
  private creationCommand: string = 'CreateLoan';
  private creationType?: LoanCreationType;
  private readonly openAction!: (productType: LoanType) => void;
  private loanId?: number;
  private customerIds!: number[];

  protected readonly columns: Columns = [
    {title: 'No'},
    {title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    {title: 'Product name', field: 'productName', sortable: 'productName'},
    {title: 'Min. interest rate', field: 'minInterestRate'},
    {title: 'Max. interest rate', field: 'maxInterestRate'},
    {title: 'Minimum amount', field: 'minAmountFormatted'},
    {title: 'Maximum amount', field: 'maxAmountFormatted'},
    {title: 'Minimum term', field: 'minTerm'},
    {title: 'Maximum term', field: 'maxTerm'},
    {
      actions: [{
        access: this.creationCommand,
        label: 'Open',
        field: 'open',
        type: ActionType.BUTTON
      }]
    }
  ];

  protected readonly sorting: Sorting = {
    productTypeCode: 'asc',
    productName: 'asc'
  };

  constructor(private loanProductsCache: LoanProductsCache,
              private productService: ProductService,
              private interestBoardService: InterestBoardService,
              private $filter: NxIFilterService,
              private accessRuleService: AccessRuleService,
              private popup: Popup,
              private http: HttpService) {
  }

  async $onInit(): Promise<void> {
    const [loanTypes, accessRules] = await Promise.all([
      this.loanProductsCache.toPromise(),
      this.accessRuleService.accessRuleForLoggedInUserRole('CreateLoan')]);

    const availableProductDefinitionIds = accessRules ? this.getAvailableProductDefinition(accessRules) : [];

    this.loanTypes = loanTypes.filter(t => {
      return this.productService.availableForSale(t)
        && this.productService.availableForCustomerType(t.productDefinition.customerTypeConstraints, this.customerType)
        && (_.isEmpty(availableProductDefinitionIds) || availableProductDefinitionIds.includes(t.productDefinition.id));
    }).map(loanType => {
        return {
          ...loanType,
          productName: loanType.productDefinition.productName,
          productTypeCode: loanType.productDefinition.productTypeCode,
          minAmountFormatted: this.$filter('nxCurrency')(loanType.minAmount),
          maxAmountFormatted: this.$filter('nxCurrency')(loanType.maxAmount),
          open: async (): Promise<void> => {
            await this.validateLimit(loanType);
          }
        }
      }
    )
  }

  async validateLimit(loanType: LoanType): Promise<void> {
    const customersExceedingLimit = await this.getCustomersExceedingLimit(loanType.productDefinition);
    if (customersExceedingLimit.length === 0) {
      this.openAction(loanType);
      return;
    }

    this.popup({
      text: `Cannot create '${loanType.productDefinition.productName}' Loan. 
             Customers ${customersExceedingLimit.map(c => c.customerNumber).join(', ')} 
             already reached maximum limit of ${loanType.productDefinition.perCustomerLimit} Loans of this type.`
    });
  }

  async getCustomersExceedingLimit(definition: ProductDefinition): Promise<LimitValidationOutput[]> {
    const input: LimitValidationInput = {
      customerIds: this.customerIds,
      definitionId: definition.id,
      remadeLoanId: this.loanId,
      creationType: this.creationType || 'NEW_LOAN'
    };

    const output = await this.http.post<LimitValidationOutput[]>('/products/loans/limit/validate', input).toPromise();
    return output.filter(o => o.limitExceeded);
  }

  getAvailableProductDefinition(accessRules: AccessRule): number[] {
    const predicates = accessRules.predicates;
    if (this.customerType === 'GROUP' || !predicates['IN'] || !predicates['IN']['PRODUCT_DEFINITION']) {
      return [];
    }
    return predicates['IN']['PRODUCT_DEFINITION'];
  }

}

nxModule.component('customerLoanCreateListTable', {
  templateUrl: templateUrl,
  bindings: {
    loanType: '=',
    openAction: '<',
    customerType: '<',
    creationCommand: '<',
    customerIds: '<',
    loanId: '<',
    creationType: '<'
  },
  controller: CustomerLoanCreateListTable
});
