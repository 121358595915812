import _ from 'lodash';
import {ReportMapping} from "./mapping/edit/dynamic-report-mapping-edit.component";


export const markDuplicatedRows = (reportMappings: ReportMapping[]): boolean => {
  const uniqueKeys = _.groupBy(reportMappings, (reportMapping) => {
    return (reportMapping.sheet + reportMapping.row + reportMapping.column).trim();
  });
  let hasDuplicate = false;
  Object.keys(uniqueKeys).forEach((property => {
    const isUnique = uniqueKeys[property].length <= 1;
    uniqueKeys[property].forEach(( rowValue ) => {
      rowValue.isUnique = isUnique;
      hasDuplicate = hasDuplicate || !isUnique;
    });
  }));

  return hasDuplicate;
}