import nxModule from 'nxModule';
import {IController} from 'angular';
import {NgTableParams} from 'ng-table';

import templateUrl from './prints-details-template-variable-configuration.template.html';

import './prints-details-template-variable-configuration.style.less';
import {Print, PrintLine} from "print/PrintType";

class PrintsDetailsTemplateVariableConfiguration implements IController {
  private print!: Print;
  private onChange!: () => void;

  private tableConfig: NgTableParams<PrintLine> = new NgTableParams({
    count: 0,
  }, {
    counts: [],
    dataset: []
  });

  $onInit(): void {
    const variables = this.getTemplateVariables();

    this.tableConfig = new NgTableParams({
      count: variables.length,
    }, {
      counts: [],
      dataset: variables
    });
  }

  getAllVariables(): PrintLine[] {
    return this.print.lines.filter(line => ['MULTI_VARIABLE', 'VARIABLE', 'CUSTOM'].includes(line.type));
  }

  getTemplateVariables(): PrintLine[] {
    const allVariables = this.getAllVariables();
    return allVariables.filter(line => this.isPrimary(line));
  }

  isPrimary(line: PrintLine): boolean {
    const minId = this.getAllVariables()
      .filter(l => l.field === line.field && l.id)
      .map(l => l.id)
      .reduce((a, b) => Math.min(a!, b!));

    return minId === line.id;
  }
}

nxModule.component('printsDetailsTemplateVariableConfiguration', {
  templateUrl,
  bindings: {
    print: '<',
    onChange: '<'
  },
  controller: PrintsDetailsTemplateVariableConfiguration,
});