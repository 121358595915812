type StorageType = "sessionStorage" | "localStorage";

export const getStorageName = (): StorageType | null => {
  const storageElement = document.querySelector<HTMLElement>('[data-nx-token-storage]');
  if (storageElement) {
    const storage = storageElement.dataset.nxTokenStorage;
    if(storage === 'localStorage') {
      return 'localStorage';
    }
  }

  return 'sessionStorage';
};

export const storage = (): Storage => {
  const storageName = getStorageName();
  if(storageName) {
    return window[storageName];
  }

  return sessionStorage;
};