import {BigNumber} from 'bignumber.js';
import {IncomeSource} from 'components/customer/profile/customer-profile.types';
import {CustomerCache} from 'components/service/customer.cache.types';
import {Account} from 'components/service/product.types';
import {PropertyConfigService} from 'components/service/property-config.service';
import _ from 'lodash';
import nxModule from 'nxModule';
import {Dict} from 'shared/common/dict.types';
import {incomeSourceTypes} from './constants';
import {IncomeSourceType} from './constants.types';
import templateUrl from './sss-income-source.template.html';

class SssIncomeSource {
  incomeFormTitle!: string;
  editMode!: boolean;
  removeIncomeSource!: (param: IncomeSource) => void;
  sourceOfIncome!: IncomeSource;
  customerId!: number;

  readonly types: ReadonlyArray<IncomeSourceType> = incomeSourceTypes;
  selectedAccount: any;
  accounts: Account[] = [];

  constructor(
    private readonly dict: Dict,
    private readonly propertyConfigService: PropertyConfigService,
    private readonly customerCache: CustomerCache
  ) {
  }

  async $onInit(): Promise<void> {
    await this.initializeAccounts();
  }

  async initializeAccounts(): Promise<void> {
    if (!this.customerId) {
      return;
    }

    const depositAccounts = await this.customerCache.depositAccounts(this.customerId).toPromise();
    this.accounts = depositAccounts.filter(account => ['PENDING', 'ACTIVE', 'INACTIVE'].includes(account.status));

    if (this.sourceOfIncome.sssPensionTargetAccountId) {
      this.selectedAccount = _.find(this.accounts, acc => acc.id === this.sourceOfIncome.sssPensionTargetAccountId);
    }
  }

  updateSssPensionTargetAccountId(): void {
    this.sourceOfIncome.sssPensionTargetAccountId = this.selectedAccount.id;
  }

  set monthlyIncome(newValue: number) {
    this.sourceOfIncome.annualIncome = new BigNumber(newValue).multipliedBy(12).toNumber();
  }

  // use getter so that ng-model binding is possible and we won't need to update income source
  get monthlyIncome() {
    const annual = this.sourceOfIncome.annualIncome;

    if(annual === undefined || annual === null) {
      return annual;
    }

    return new BigNumber(annual).div(12).toNumber();
  }
}

nxModule.component('sssIncomeSource', {
  templateUrl,
  controller: SssIncomeSource,
  bindings: {
    customerId: '<',
    incomeFormTitle: '<',
    editMode: '=',
    removeIncomeSource: '&',
    sourceOfIncome: '<'
  }
});
