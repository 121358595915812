import nxModule from 'nxModule';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import {BranchService} from 'components/service/branch.service';
import {CustomerProfile} from 'components/customer/profile/customer-profile.types';
import {ILocationService, IScope} from 'angular';
import {HttpService} from 'shared/utils/httpService';
import Authentication from 'shared/utils/authentication';
import templateUrl from './transfer-customer-to-another-group.template.html';
import {Branch} from "management/BranchTypes";

interface CustomersGroupInfo {
  name: string;
  branchId: number;
  groupCustomerId: number;
}

class TransferCustomerToAnotherGroup {
  private readonly subHeaderDescription = 'Transfer customer';

  /**
   * Customer to transfer (CustomerType: INDIVIDUAL)
   * @private
   */
  private customer!: CustomerProfile;
  /**
   * Customer ID of the original group (CustomerType: GROUP)
   * @private
   */
  private groupCustomerId!: number;

  private selectableBranches: Branch[] = [];
  private selectableGroups: CustomersGroupInfo[] = [];
  private groupsByBranchId: Map<number, CustomersGroupInfo[]> = new Map;

  private targetBranchId!: number;
  private targetCustomerGroupId!: number;

  constructor(private readonly $scope: IScope,
              private readonly command: { execute: any },
              private readonly $location: ILocationService,
              private readonly authentication: Authentication,
              private readonly branchService: BranchService,
              private readonly http: HttpService,
              private readonly $route: { current: any },
              private readonly customerCache: { profile: any, refetch(): any },
              private readonly groupCustomerCache: { refetch(): any }) {
  }

  async $onInit(): Promise<void> {
    this.groupCustomerId = Number(this.$route.current.params['groupCustomerId']);
    [this.selectableBranches, this.customer] = await Promise.all([this.branchService.toPromise(), this.customerCache.profile(this.$route.current.params['customerId']).toPromise()]);
    this.targetBranchId = this.customer.branchId;
    await this.onBranchChange();
  }

  async onBranchChange(): Promise<void> {
    await this.fetchMissingGroups();
    this.selectableGroups = this.getGroups(this.targetBranchId);
  }

  async fetchMissingGroups(): Promise<void> {
    if (!this.targetBranchId || this.groupsByBranchId.has(this.targetBranchId)) {
      return;
    }

    const groups: CustomersGroupInfo[] = await <Promise<CustomersGroupInfo[]>>this.http.get(`/customers/group/all?branchId=${this.targetBranchId}`).toPromise();
    const groupsWithoutCurrentOne = (groups || []).filter(g => g.groupCustomerId !== this.groupCustomerId);
    this.groupsByBranchId.set(this.targetBranchId, groupsWithoutCurrentOne);
  }

  getGroups(branchId: number): CustomersGroupInfo[] {
    const result = this.groupsByBranchId.get(branchId);
    return result ?? [];
  }

  async transfer(): Promise<void> {
    const response = await this.command.execute('TransferCustomerToAnotherGroup', {
      customerId: this.customer.id,
      sourceGroupCustomerId: this.groupCustomerId,
      targetGroupCustomerId: this.targetCustomerGroupId
    }).toPromise();

    if (response) {
      await Promise.all([this.groupCustomerCache.refetch(), this.customerCache.refetch()])
      this.redirectBack();
    }
  }

  /**
   * Moves back to origin group customer
   */
  redirectBack(): void {
    this.$location.path(`/customer/${this.groupCustomerId}/group-profile`);
  }
}

nxModule.component('transferCustomerToAnotherGroup', {
  templateUrl: templateUrl,
  controller: TransferCustomerToAnotherGroup
});
