import tokenStore from "../authentication/tokenStore";


// keep branch in session storage, so that after refreshing the page, it's still there
const BRANCH_KEY = "nx_branch";
class CurrentBranchService {
  public setBranchId(branchId: number): void {
    sessionStorage.setItem(BRANCH_KEY, String(branchId));
  }

  public clearBranchId(): void {
    sessionStorage.removeItem(BRANCH_KEY);
  }

  public getBranchId(): number {
    const branchIdText = sessionStorage.getItem(BRANCH_KEY);
    if(!branchIdText) {
      return tokenStore.getTokenDetails().userBranchId;
    }

    const parsedBranch = parseInt(branchIdText);
    if(!parsedBranch || isNaN(parsedBranch)) {
      return tokenStore.getTokenDetails().userBranchId;
    }

    return parsedBranch;
  }
}

export default new CurrentBranchService();
export const serviceType = CurrentBranchService;