import nxModule from 'nxModule';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';

const templateUrl = require('./outgoing-checks-list.template.html');
nxModule.component('outgoingChecksList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation,
                        notification, branchService, confirmationTemplate, command, popup, outgoingCheckClearingService, organizationCache) {
    const that = this;

    that.$onInit = async () => {
      const organizations = await organizationCache.toPromise();
      that.rootOrganization = _.find(organizations, {'root': true});
    }

    that.checkStatuses = [
      'ISSUED',
      'CREDITED',
      'PENDING_CLEARING',
      'CLEARING_ERROR',
      'REVOKED'
    ];

    $scope.$watch('$ctrl.filter.branchId', () => {
      updateDateFilter();
    }, true);

    // Check search criteria
    that.filter = {
      status: null,
      branchId: null,
      dateRange: {from: null, to: null}
    };

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        branchId: that.filter.branchId,
        status: that.filter.status,
        registeredOnFrom: $filter('nxDate')(that.filter.dateRange.from),
        registeredOnTo: $filter('nxDate')(that.filter.dateRange.to),
        clearingGroup: 'INWARD'
      });
    };

    const formatProductNumber = (products, check) => {
      const product = products.find(r => r.id === check.productId) || {};
      return product.productNumber + (product.extraNumber ? ` (${product.extraNumber})` : '');
    };

    const createCheckDetails = (check) => {

      const details = [];
      const pushDetail = (label, value, include = true, nullLabel = '-') => {
        if (include) details.push({label: label, value: value ? value : nullLabel});
      };

      // Prepare check details data
      pushDetail('Check id.', check.id);
      pushDetail('Number', check.number);
      pushDetail('MICR', check.micrNumber);
      pushDetail('Product number', check.productNumber);
      pushDetail('Clearing group', $filter('prettyEnum')(check.checkClearingGroup));
      pushDetail('On us', $filter('prettyBoolean')(check.bankId === that.rootOrganization.bankId));
      pushDetail('Status', $filter('prettyEnum')(check.status));
      pushDetail('Clearing performed', $filter('prettyBoolean')(check.clearingPerformed));
      pushDetail('Post dated', $filter('prettyEnum')(check.postDated.toString()));
      pushDetail('Valid from', $filter('prettyDate')(check.validFrom));
      pushDetail('Amount', $filter('nxCurrency')(check.amount));
      pushDetail('Registered on', $filter('prettyDate')(check.registeredOn));
      pushDetail('Remarks', check.remarks);

      // If inward check clearing failed -> display errors
      const clearingErrors = check.clearingErrors;
      if (clearingErrors && clearingErrors.length > 0) {
        pushDetail('Clearing errors', _.map(clearingErrors, e => $filter('prettyEnum')(e)).join(', '));
      }

      return details;
    };

    const createSearchQueryPromise = async (params) => {
      const queryParams = convertFilterToQueryString(params);
      const page = await http.get(`/checks/outgoing?${queryParams}`, {nxLoaderText: 'Loading checks'}).toPromise();
      const checks = page.result;

      if (checks && checks.length > 0) {
        const productIds = checks.map(c => c.productId);
        const relatedProducts = await http.get(`/products/accounts?ids=${productIds}`).toPromise();

        _.forEach(checks, (check) => {
          check.productNumber = formatProductNumber(relatedProducts, check);

          // Prepare inline panel data (check details)
          check.details = createCheckDetails(check);
          // Set available check actions
          that.setCheckActions(check, check.clearingPerformed);
        });
      }

      that.tableConfig.total(page.totalCount);
      return that.checks = checks;
    };

    that.setCheckActions = (check, clearingPerformed) => {
      check.canBeValidated = !clearingPerformed;
      check.canBeCleared = !clearingPerformed;
      check.canBeCancelled = !clearingPerformed;
    }

    that.itemClicked = (check, $event) => {
      $event.stopPropagation();
      that.selectedCheck = check;
      that.selectedCheckId = check.id;
    };

    that.hideInlinePanel = () => {
      that.selectedCheckId = null;
    };

    that.tableConfig = new NgTableParams({
      count: 20,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: params => createSearchQueryPromise(params)
    });

    that.onFilterBtnClicked = () => {
      // reset paging right before request (ngTable counts pages starting from 1)
      that.tableConfig.page(1);
      that.filterChecks();
      that.hideInlinePanel();
    };

    that.filterChecks = async () => {
      await that.tableConfig.reload();
    };

    that.returnCheck = (check) => {
      confirmation(`Do you want to revoke check ${check.number}?`, () => {
        http.doDelete(`/checks/outgoing/${check.id}`, {nxLoaderText: 'Revoking check'})
          .success(() => {
            notification.show('Success', `Check ${check.number} revoked`);
            that.filterChecks();
          })
          .error(() => {
            notification.show("Error", "Failed to revoke check.");
          });
      });
    };

    // Read branches available for user
    const branchesSub = branchService.toObservable().subscribe(branches => {

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const systemDate = Date.parse(defaultBranch.systemDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: systemDate, to: systemDate};

      // Load initial checks page
      that.tableConfig.page(1);
      that.tableConfig.reload();
    });

    const updateDateFilter = () => {
      if (!that.filter.branchId) return;
      const selectedBranch = _.find(that.availableBranches, {id: that.filter.branchId});
      const systemDate = Date.parse(selectedBranch.systemDate);
      that.filter.dateRange = {from: systemDate, to: systemDate};
    };

    that.validate = (check) => {
      confirmationTemplate({
        question: 'Do you want to perform check clearing validation?',
        details: [
          {label: 'Check number', description: check.number},
          {label: 'MICR', description:  check.micrNumber},
          {label: 'Amount', description:  $filter('nxCurrency')(check.amount)}
        ],
        warning: 'This operation will not incur any clearing charges.',
        yesCallback: () => command.execute('ValidateInwardCheck', {checkId: check.id, failFast: false}).success((res) => {
          // Even if validation failed command will result in a success.
          // Only unexpected system exceptions will cause command to fail
          if (!res.output.valid) {
            popup({text: `Check ${check.number} is invalid for clearing`, callback: that.filterChecks()});
          } else {
            popup({text: `Check ${check.number} is valid for clearing`, callback: that.filterChecks()});
          }
        }, true)
      });
    };


    that.clear = async (check) => {
      const confirmed = await confirmationTemplate({
        question: 'Do you want to perform check clearing?',
        details: [
          {label: 'Check number', description: check.number},
          {label: 'MICR', description:  check.micrNumber},
          {label: 'Amount', description:  $filter('nxCurrency')(check.amount)}
        ],
        warning: 'This operation might incur clearing charges.<br>Please make sure that the check data is valid.'
      });
      if (!confirmed) return;

      const response = await command.execute('ClearInwardCheck', {id: check.id}).toPromise(true);
      if (!response) return;

      // Set available check actions
      that.setCheckActions(check, true);

      if (!response.output.cleared) {
        const charges = $filter('nxCurrency')(response.output.clearingCharges);
        // Convert errors to HTML form
        let popupText = `<p><span class="red"><strong>WARNING!</strong> Failed to clear check: ${check.number} due to:</span></p>`;
        const errors = outgoingCheckClearingService.getClearingErrorsDescribedList(response.output.clearingErrors).join('<br/>');
        popupText += `<p>${errors}</p>`;
        if (charges && charges > 0) popupText += `<p>Total amount of ${charges} was charged.</p>`;

        if(response.output.autoClosed) {
          popupText += `<br/><strong>Account was automatically closed due to inward charges.</strong>`;
        }
        popup({
          header: 'Clearing failed',
          text: popupText,
          callback: that.filterChecks(),
          renderHtml: true
        });

      } else {
        popup({text: `Check ${check.number} cleared successfully`, callback: that.filterChecks});
      }

    };

    that.cancel = async (check) => {
      const confirmed = await confirmationTemplate({
        question: 'Do you want to cancel this check?',
        details: [
          {label: 'Check number', description: check.number},
          {label: 'MICR', description:  check.micrNumber},
          {label: 'Amount', description:  $filter('nxCurrency')(check.amount)}
        ],
        warning: 'This operation will revert the check to \'Not issued\' state and it will be able to be used again.'
      });

      if (confirmed) {
        const response = await command.execute('CancelInwardCheck', {checkId: check.id}).toPromise();
        // if everything went ok and approval isn't required -> show confirmation
        if (response && !response.approvalRequired) {
          popup({text: `Check ${check.number} was cancelled successfully`, callback: that.filterChecks});
        }
      }
    };

    that.goToAccount = (check) => {
      $location.path(`/customer/${check.customerId}/accounts/${check.productId}`).search({showClosed: 'true'});
    };

    that.postManualCheck = () => $location.path('/inventory/checks/outgoing/post-manual-check');

    that.batchRegisterChecks = () => $location.path('/inventory/checks/outgoing/batch-register-inward-check');

    that.$onDestroy = () => {
      branchesSub.unsubscribe();
    };
  }
});
