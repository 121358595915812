import nxModule from 'nxModule';

import templateUrl from './password.template.html';
import {IFormController} from 'angular';
import {PasswordComplexityRules, PasswordComplexityRuleType} from './password-complexity.service';

class Password {

  public complexityRules!: PasswordComplexityRules;
  private form!: IFormController;

  getErrorMessage(): string {
    const validationText = [
      this.form.password.$error.minlength && `${this.complexityRules.minLength} characters`,
      this.form.password.$error.oneNumber && 'one number',
      this.form.password.$error.oneLowercaseLetter && 'one lowercase letter',
      this.form.password.$error.oneUppercaseLetter && 'one uppercase letter',
      this.form.password.$error.oneSpecialCharacter && 'one special character'
    ].filter(val => val);

    return validationText.join(', ');
  }

  validate(value: string, type: PasswordComplexityRuleType): boolean {
    const validation = this.complexityRules?.complexity?.[type];
    if (validation && value) {
      return validation.test(value);
    }
    return true;
  }
}


nxModule.component('password', {
  templateUrl,
  require: {
    'form': '^^form'
  },
  bindings: {
    model: '=',
    ngDisabled: '<',
    ngRequired: '<',
    complexityRules: '<'
  },
  controller: Password
});
