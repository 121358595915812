import nxModule from 'nxModule';

import systemPropertyService from "../../../../react/system/systemPropertyService";

export enum PasswordComplexityRuleType {
  ONE_NUMBER,
  ONE_LOWERCASE_LETTER,
  ONE_UPPERCASE_LETTER,
  ONE_SPECIAL_CHARACTER
}

export type ValidationRulesMap = { [key in keyof typeof PasswordComplexityRuleType]: RegExp};

export interface PasswordComplexityRules {
  complexity: ValidationRulesMap;
  minLength: number;
}

export class PasswordComplexityService {
  readonly commonValidationRules: ValidationRulesMap = {
    ONE_NUMBER: /\p{Nd}/u,
    ONE_LOWERCASE_LETTER: /\p{Ll}/u,
    ONE_UPPERCASE_LETTER: /\p{Lu}/u,
    ONE_SPECIAL_CHARACTER: / |[!-/]|[:-@]|[\^-`]|[{-~]/
  };

  getComplexityRules(): PasswordComplexityRules {
    const minLength = systemPropertyService.getProperty('PASSWORD_MIN_LENGTH');
    const passwordMinLength = minLength ? parseInt(minLength) : 0;

    const complexity = systemPropertyService.getProperty('PASSWORD_COMPLEXITY');
    if(!complexity) {
      throw new Error('Missing password complexity');
    }

    const validations = complexity.split(',') as (keyof typeof PasswordComplexityRuleType)[];

    return {
      minLength: passwordMinLength,
      complexity: Object.fromEntries(validations.map(rule => [rule, this.commonValidationRules[rule]])) as ValidationRulesMap
    };
  }

  getUpdatedComplexityRules(): PasswordComplexityRules {
    return this.getComplexityRules();
  }
}

nxModule.service('passwordComplexityService', PasswordComplexityService);