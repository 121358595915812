import {Config} from 'config';
import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {ProductDefinition} from './product.types';
import {HttpService} from 'shared/utils/httpService';

nxModule.factory('productDefinitionService', (http: HttpService, CacheFactory: any, config: Config) =>
  new LocalCache({
    provider: () => http.get('/products/definitions'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productDefinitions,
    cacheName: 'productDefinitions'
  }));

export type ProductDefinitionService = LocalCache<any, ProductDefinition[]>