import {ICompileService, IRootScopeService, IScope} from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import nxModule from 'nxModule';
import {BatchShowInput, ShowInput} from 'print/PrintModal';
import PrintService from 'print/PrintService';
import {Print, PrintWrapper} from 'print/PrintType';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/first';
import Authentication from 'shared/utils/authentication';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';

export type PrintModalOutput = {
  printed: boolean;
  printResult?: PrintWrapper;
};

export interface PrintModalInput {
  parameters: Record<string, unknown>;
  inputs: Record<string, unknown>;
}

export interface PrintRequest {
  parameters: Record<string, unknown>;
  inputs: Record<string, unknown>;
  customVariables?: Record<string, unknown>;
}

export interface BatchPrintRequest {
  printRequests: PrintRequest[]
}

const printService = new PrintService();
export class ModalPrintPreviewService {
  private readonly permissions: any;

  constructor(private $rootScope: IRootScopeService, private $compile: ICompileService,
              private http: HttpService, private printsCache: any, private notification: Notification, private authentication: Authentication) {
    this.permissions = authentication.permissions;
  }

  private showModal(code: string, input: PrintModalInput[], callback: (output: PrintModalOutput) => Promise<void>): void {
    const scope: IScope & { input?: PrintModalInput[], printCode?: string, closePreview?: (output: PrintModalOutput) => void } = this.$rootScope.$new();
    scope.input = input;
    scope.printCode = code;
    scope.closePreview = (output: PrintModalOutput): void => {
      $('#modalPrintPreview').remove();
      if(callback) callback(output);
    };
    $('#modal-container').append(this.$compile('<modal-print-preview id="modalPrintPreview" input="input" print-code="printCode" on-close="closePreview" />')(scope));
  }

  async show(code: string,
             modalInput: PrintModalInput[],
             callback: (output: PrintModalOutput) => void) {

    console.debug('Showing print', code);

    // filter prints, remove non active prints
    const allPrints: Print[] = await this.printsCache.toPromise();
    const allActivePrints: Print[] = allPrints.filter(p => p.active);

    if (!allActivePrints.some(ap => ap.code === code)) {
      callback({printed: false});
      return;
    }

    this.showModal(code, modalInput, async (output: PrintModalOutput): Promise<void> => {
      const result = output.printResult;
      if(output.printed && result?.printHistoryEntryIds && result.printHistoryEntryIds.length > 0) {
        await printService.registerPrintEvent(result);
      }
      callback({printed: output.printed});
    });
  }

  batchShowAsync(config: BatchShowInput): Promise<PrintModalOutput> {
    return new Promise((resolve) => this.show(config.printDescription, config.input, (output: PrintModalOutput) => resolve(output)));
  }

  showAsync(config: ShowInput): Promise<PrintModalOutput> {
    const code = typeof config.printDescription === 'string' ? config.printDescription : config.printDescription.code;
    const page: PrintRequest = {
      inputs: config.printProviderInput as Record<any, any>,
      parameters: typeof config.printDescription === 'string' ? {} : config.printDescription.parameters
    };
    return new Promise((resolve) => this.show(code, [page], (output: PrintModalOutput) => resolve(output)));
  }

  canReprint(name: string, onSuccess: (result: boolean) => void): void {
    this.printsCache.toObservable().first()
      .subscribe((prints: Print[]) => {
        const active = this.isReprintAvailable(prints, name);
        onSuccess(active);
      });
  }

  async canReprintAsync(name: string): Promise<boolean> {
    const prints = await this.printsCache.toPromise();
    return this.isReprintAvailable(prints, name);
  }

  isReprintAvailable(prints: Print[], name: string): boolean {
    const filtered: Print[] = _.filter(prints, {code: name});
    // take only non parametrized prints
    const p = _.find(filtered, print => Object.keys(print.parameters || {}).length === 0);
    if (p && p.active) {
      return p.canReprint || this.permissions['PRT_REPRINT'] === true;
    }
    return false
  }
}

nxModule.service('modalPrintPreviewService', ModalPrintPreviewService);
