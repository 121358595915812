import nxModule from 'nxModule';

const templateUrl = require('./group-schedule.template.html');
nxModule.component('groupSchedule', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: function (propertyConfigService) {
    let that = this;
    this.propertyConfigService = propertyConfigService;

    this.paymentIntervals = [{
      label: 'Weekly',
      value: 'WEEKLY'
    },{
      label: 'Fortnightly',
      value: 'FORTNIGHTLY'
    }];
  }
});
