import nxModule from 'nxModule';

import './sss-online-employer-payment.component';
import './sss-online-individual-payment.component';

const templateUrl = require('./sss-online-payment.template.html');
nxModule.component('sssOnlinePayment', {
  templateUrl: templateUrl,
  controller: function ($location, actionCommand) {

    this.payorKind = 'INDIVIDUAL';

    this.onCancelChanges = actionCommand.cancelChanges;
    this.onSaved = () => $location.path('/dashboard/miscellaneous-transactions');
  }
});
