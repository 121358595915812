import {
  ComplianceRedFlagConfigType
} from 'components/administration/compliance/compliance-red-flag-config-types';
import LocalCache from 'shared/utils/localCache';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import {ICacheFactoryService} from 'angular';
import config from 'config';

export type ComplianceRedFlagConfigCache = LocalCache<unknown, ComplianceRedFlagConfigType[]>;

nxModule.factory('complianceRedFlagConfigCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: (): unknown => http.get(`/management/compliance-config/red-flag`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.complianceRedFlagConfig,
    cacheName: 'complianceRedFlagConfig'
  })
);
