import nxModule from 'nxModule';

import './pledge-deposit-deviation-interval-fee-board.style.less';
import templateUrl from './pledge-deposit-deviation-interval-fee-board.template.html';
import {
  FeeType,
  PledgeDepositDeviationIntervalFeeBoardCellType,
  PledgeDepositDeviationIntervalFeeBoardType
} from '../../service/fees/fee.types';
import {IOnChangesObject} from "angular";

// Do not use for loans
export type PaymentInterval = 'DAILY'
  | 'WEEKLY'
  | 'FORTNIGHTLY'
  | 'EVERY_30_DAYS'
  | 'MONTHLY'
  | 'MONTHLY_LAST_DAY'
  | 'SEMIMONTHLY_1_16'
  | 'SEMIMONTHLY_15_30'
  | 'SEMIMONTHLY_GRANT_DATE'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'YEARLY'
  | 'CUSTOM'
  | 'SINGLE_PAYMENT';

type PaymentIntervalType = {
  value: PaymentInterval,
  label: string
}

class PledgeDepositDeviationIntervalFeeBoard {
  static PAYMENT_INTERVALS: PaymentIntervalType[] = [
    {value: 'WEEKLY', label: 'Weekly'},
    {value: 'MONTHLY', label: 'Monthly'},
    {value: 'SEMIMONTHLY_1_16', label: 'Semimonthly (1.x & 16.x)'},
    {value: 'SEMIMONTHLY_15_30', label: 'Semimonthly (15.x & 30.x)'},
    {value: 'QUARTERLY', label: 'Quarterly'},
  ];
  private enableAddNewButton: boolean = true;
  private feeType!: FeeType;
  private board!: PledgeDepositDeviationIntervalFeeBoardType;

  $onChanges(changes: IOnChangesObject): void {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('feeDefinitions')) {
      this.enableAddNewButton = this.getAvailablePaymentIntervals().length > 0;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('feeType')) {
      if (this.feeType === 'PERCENTAGE') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.board.defaultFixedAmount = null;
        this.board.cells.forEach(c => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          c.fixedAmount = null;
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.board.defaultPercentageAmount = null;
        this.board.cells.forEach(c => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          c.percentageAmount = null;
        });
      }
    }
  }

  addNewBoardCell(): void {
    const newCell: PledgeDepositDeviationIntervalFeeBoardCellType = {
      paymentInterval: null,
      fixedAmount: 0,
      percentageAmount: 0
    };
    this.board.cells.push(newCell);
    this.enableAddNewButton = this.getAvailablePaymentIntervals().length - 1 > 0;
  }

  removeBoardCell(index: number): void {
    this.board.cells.splice(index, 1);
    this.enableAddNewButton = true;
  }

  getAvailablePaymentIntervals(cellPaymentInterval?: PaymentInterval): PaymentIntervalType[] {
    const selectedIntervals = this.board.cells.map(cell => cell.paymentInterval);
    return PledgeDepositDeviationIntervalFeeBoard.PAYMENT_INTERVALS
      .filter(i => !selectedIntervals.includes(i.value) || (cellPaymentInterval && cellPaymentInterval === i.value));
  }
}

nxModule.component('pledgeDepositDeviationIntervalFeeBoard', {
  templateUrl,
  bindings: {
    board: '=',
    feeType: '<'
  },
  controller: PledgeDepositDeviationIntervalFeeBoard
});
