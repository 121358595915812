import nxModule from 'nxModule';

const templateUrl = require('./mother-branch-predicate.template.html');

class MotherBranchPredicate {

  constructor(accessRuleService) {
    this.accessRuleService = accessRuleService;
    this.options = [{}];
  }

  $onInit() {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    this.initializeOptions(this.inputDefinitions);
    this.value = this.predicates[this.type][this.inputDefinition];
  }

  valueChanged() {
    this.predicates[this.type][this.inputDefinition] = this.value;
  };

  inputDefinitionChanged() {
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }

  initializeOptions(inputDefinition) {
    if (inputDefinition.value === 'DEPOSITORY_BANK_ACCESS') {
      this.options = [{
        label: 'Depository bank from the mother branch',
        value: 'MOTHER_BRANCH'
      }, {
        label: 'Depository bank from different branch',
        value: 'INTER_BRANCH'
      }];
    } else {
      this.options = [{
        label: 'Products from the mother branch',
        value: 'MOTHER_BRANCH'
      }, {
        label: 'Products from different branch',
        value: 'INTER_BRANCH'
      }];
    }
  }
}

nxModule.component('motherBranchPredicate', {
  templateUrl,
  bindings: {
    type: '=',
    predicates: '=',
    ngDisabled: '<',
    ruleDefinitions: '<',
    accessInput: '<'
  },
  controller: MotherBranchPredicate
});
