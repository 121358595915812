import eact, {ReactElement, useEffect, useState} from "react";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import {
    AutomaticDebitAgreement,
    AutomaticDebitAgreementDetails,
    AutomaticDebitSchedule,
    AutomaticDebitStrategy,
    automaticDebitStrategyOptions,
    automaticDebitScheduleOptions
} from "account/ada/AutomaticDebitAgreementTypes";
import {Account, IdResponse, ProductDefinition, ProductGroup} from "components/service/product.types";
import {
    NxButton,
    NxButtonVariant,
    NxFormik,
    NxFormikSelect,
    NxFormikSubmitButton,
    NxLoader,
    NxRow,
    NxRowPosition,
    NxSelectOption,
    NxStack,
} from "@nextbank/ui-components";
import CommandAccess from "command/CommandAccess";
import useAxios from "axios-hooks";
import {useHistory, useParams} from "react-router";
import {Customer} from "customer/CustomerTypes";
import {DepositDetails} from "deposit/DepositType";
import {Loan} from "components/service/loan.types";
import DragDropItems, {DraggableItem} from "drag-drop/DragDropItems";
import {DraggableProvided} from "react-beautiful-dnd";
import DragDropItem from "drag-drop/DragDropItem";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import * as Yup from "yup";
import {SchemaOf} from "yup";
import styles from './AutomaticDebitAgreementConfig.scss'
import Alert from "alert/Alert";
import {useCommand} from "command/CommandService";
import {CommandOutputWrapper} from "command/CommandTypes";
import accessRuleService from "access/accessRuleService";
import tokenStore from "authentication/tokenStore";
import {AccessRule} from "access/AccessRuleTypes";

interface Form {
  schedule: AutomaticDebitSchedule
}

interface TargetForm {
  productGroup: ProductGroup;
  targetId: number;
  strategy: AutomaticDebitStrategy;
  keepMaintainingBalance: boolean;
  executeOnAccountCredit: boolean;
}

interface AdaRequest {
  productId: number;
  schedule: AutomaticDebitSchedule;
  agreements: AutomaticDebitAgreement[];
}

export interface AutomaticDebitAgreementDraggable extends AutomaticDebitAgreement, DraggableItem{
    key : string
}
const AutomaticDebitAgreementConfig = (): ReactElement => {
  const history = useHistory();
  const execute = useCommand();
  const {customerId, accountId} = useParams<{customerId: string, accountId: string}>();
  const [{data: account}] = useAxios<Account>(`/products/accounts/${accountId}`);
  const [{data: customer}] = useAxios<Customer>(`/customers/${customerId}`);
  const [{data: accountAdas}] = useAxios<AutomaticDebitAgreement[]>(`/products/accounts/${accountId}/ada`);
  const [{data: deposits}] = useAxios<DepositDetails[]>(`/products/deposits?customerId=${customerId}&includeOwnership=false&includePledgeInstallment=true&includeAdvanceInterestLedger=false`);
  const [{data: loans}] = useAxios<Loan[]>(`/products/loans?customerId=${customerId}`);
  const [{data: allProductDefinitions}] = useAxios<ProductDefinition[]>(`/products/definitions`);
  const [agreements, setAgreements] = useState<AutomaticDebitAgreement[]>([]);
  const [dialogShow, setDialogShow] = useState(false);


  useEffect(() => {
    if (!accountAdas) {
        return;
    }
    setAgreements(accountAdas);
  }, [accountAdas]);

  const getTargetIds = () : number[] => agreements.map(t => t.targetId);
  const hasAccess = (accessRule: AccessRule | undefined, targetIds: number[]): boolean => {
    if (!accessRule) {
      return false;
    }

    if (!accessRule.predicates['IN'] || !accessRule.predicates['IN']['PRODUCT_BRANCH_ACCESS']) {
      return true;
    }
    const userBranchId = tokenStore.getTokenDetails().userBranchId;
    for (let i = 0; i < targetIds.length; i++) {
      const targetDetails = productDetailsById.get(targetIds[i]);
      if (!targetDetails) {
            return false;
      }
      if (accessRule.predicates['IN']['PRODUCT_BRANCH_ACCESS'].includes('MOTHER_BRANCH') && targetDetails.branchId === userBranchId) {
        continue;
      }

      if (accessRule.predicates['IN']['PRODUCT_BRANCH_ACCESS'].includes('INTER_BRANCH') && targetDetails.branchId !== userBranchId) {
        continue;
      }
        return false;
    }
    return true;
  }

  const hasValidStatus = (agreementDetails: AutomaticDebitAgreementDetails) : boolean => {
    if (agreementDetails.productGroup === 'LOAN') {
      return ['ACTIVE', 'PAST_DUE_PERFORMING', 'PAST_DUE_NON_PERFORMING', 'PAST_DUE_LITIGATION'].includes(agreementDetails.status);
    } else {
      return agreementDetails.status !== 'CLOSED';
    }
  }

  const generateProductDetailsById = (): Map<number, AutomaticDebitAgreementDetails> => {
    if (!deposits || !loans || !customer || !allProductDefinitions) {
      return new Map<number, AutomaticDebitAgreementDetails>();
    }

    const productMap = new Map();
    const definitionsMap = new Map(allProductDefinitions.map(i => [i.id, i]));
    const unpaidStatuses = ['UNPAID', 'DUE', 'OVERDUE'];

    deposits.filter(d => d.depositSubgroup === 'PLEDGE')
      .forEach(i => {
        productMap.set(i.id, {
            ...i,
            productNumber: i.productNumber,
            status: i.status,
            accountName: i.accountName,
            branchId: i.branchId,
            productGroup: definitionsMap.get(i.definitionId)?.productGroup,
            hasUnpaidInstallments: i.pledgeInstallments?.some(d => unpaidStatuses.includes(d.status))
        });
      });
    loans.forEach(i => {
        productMap.set(i.id, {
            ...i,
            productNumber: i.productNumber,
            status: i.status,
            accountName: customer.effectiveName,
            branchId: i.branchId,
            productGroup: definitionsMap.get(i.definitionId)?.productGroup,
            hasUnpaidInstallments: i.amortizationSchedule.list.some(a => unpaidStatuses.includes(a.status))
        });
      });
      return productMap;
  }

  const header =<NxHeader>Automatic debit agreement configuration</NxHeader>
  if(!account || !customer || !accountAdas || !deposits || !loans || !allProductDefinitions) {
    return <NxPage>{header}<NxLoader/></NxPage>;
  }

  const productDetailsById = generateProductDetailsById();
  const hasProductAccessRights: boolean = hasAccess(accessRuleService.accessRuleForLoggedInUserRole('ConfigureAutomaticDebitAgreement'), getTargetIds());
  const TargetFormSchema: SchemaOf<Partial<TargetForm>> = Yup.object().shape({
    productGroup: Yup.mixed().required('Product group is required'),
    targetId: Yup.number().required('Product number is required'),
    strategy: Yup.mixed().required('Strategy is required'),
    keepMaintainingBalance: Yup.boolean().default(false),
    executeOnAccountCredit: Yup.boolean().default(false)
  });

  const FormSchema: SchemaOf<Partial<Form>> = Yup.object().shape({
      schedule: Yup.mixed().required('Schedule is required')
  });

  const itemRendererProvider = (provided: DraggableProvided, isDragging: boolean, item: AutomaticDebitAgreementDraggable): React.ReactElement =>
    <DragDropItem provided={provided} isDragging={isDragging} item={
      <div className={styles.target}>
        <div className={styles.details}>
        <div><label className={styles.label}>Product number: </label></div>
        <div><span className={styles.value}>{productDetailsById.get(item.targetId)?.productNumber}</span></div>
        <div><label className={styles.label}>Status: </label></div>
        <div><span className={styles.value}>{productDetailsById.get(item.targetId)?.status}</span></div>
        <div><label className={styles.label}>Account name: </label></div>
        <div><span className={styles.value}>{ productDetailsById.get(item.targetId)?.accountName}</span></div>
        <div><label className={styles.label}>Keep maintaining balance: </label></div>
        <div><span className={styles.value}>{item.keepMaintainingBalance ? 'Yes' : 'No'}</span></div>
        <div><label className={styles.label}>Strategy: </label></div>
        <div><span className={styles.value}>{ item.strategy}</span></div>
        <div><label className={styles.label}>Execute on account credit for missed payments: </label></div>
        <div><span className={styles.value}>{item.executeOnAccountCredit ? 'Yes' : 'No'}</span></div>
        </div>
        <div className={styles.action} >
          <div hidden={!hasProductAccessRights}>
            <NxButton
              onClick={() : void => removeTarget(item.targetId)}
              variant={NxButtonVariant.DELETE}> Remove
            </NxButton>
          </div>
        </div>
      </div>
    } />;

  const closeDialog = () : void => setDialogShow(false)
  const openDialog = () : void => setDialogShow(true)
  const getProducts = (productGroup : ProductGroup | undefined) : NxSelectOption<number>[] => {
    const accessRule = accessRuleService.accessRuleForLoggedInUserRole('ConfigureAutomaticDebitAgreement');
    const options: NxSelectOption<number>[] = [];
    productDetailsById.forEach((value: AutomaticDebitAgreementDetails, key: number) => {
      if (!getTargetIds().includes(key)
        && productGroup === value.productGroup
        && hasValidStatus(value)
        && value.hasUnpaidInstallments
        && hasAccess(accessRule, [key])) {
          options.push({
              value: key,
              label: value.productNumber
          });
      }
    });

    return options;
  }

  const removeTarget = (targetId: number) : void => {
    const adaToDelete = agreements.find(a => a.targetId === targetId);
    if (!adaToDelete) {
        return;
    }
    const agreementsCopy = [...agreements];
    const index = agreementsCopy.indexOf(adaToDelete);
    agreementsCopy.splice(index,1);
    setAgreements(agreementsCopy);
  }

  const addTarget = (targetId?: number, strategy?: AutomaticDebitStrategy, keepMaintainingBalance: boolean = false, executeOnAccountCredit: boolean = false) : void => {
    if (!account || !targetId || !strategy) {
        return;
    }
    agreements.push({
      accountId: account.id,
      targetId: targetId,
      strategy: strategy,
      keepMaintainingBalance: keepMaintainingBalance,
      orderNo: agreements.length + 1,
      executeOnAccountCredit: executeOnAccountCredit
    });
    setAgreements(agreements);
  }

  const automaticDebitStrategySelect : NxSelectOption<AutomaticDebitStrategy>[] = automaticDebitStrategyOptions;
  const automaticDebitScheduleSelect : NxSelectOption<AutomaticDebitSchedule>[] = automaticDebitScheduleOptions;

  const booleanOption : NxSelectOption<boolean>[] = [
    {
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
      }
  ];

  const productGroupSelect : NxSelectOption<ProductGroup>[] = [
    {
      label: 'Loan',
      value: 'LOAN'
    }, {
      label: 'Deposit',
      value: 'DEPOSIT'
    }
  ];

  return (
    <NxPage>
        {header}
      <NxFormik<Partial<Form>> initialValues={{
          schedule: account.automaticDebitAgreementSchedule
      }}
      validationSchema={FormSchema}
      validateOnMount={true}
      onSubmit={ async (input: Form): Promise<void> => {
        if (!account || !input.schedule) {
          return;
        }
        const response: CommandOutputWrapper<IdResponse> = await execute<AdaRequest, IdResponse>({
          name: 'ConfigureAutomaticDebitAgreement',
          input: {
            productId: account.id,
            schedule: input.schedule,
            agreements: agreements
          }
        })
        if (!response.approvalRequired) {
          history.push(`/customer/${customerId}/accounts/${accountId}`);
        }
      }}>
      {({isValid,
        submitForm}): ReactElement => (
        <NxStack>
          <div hidden={hasProductAccessRights}>
            <Alert severity="info" >
                {'You do not have enough access to configure automatic debit agreement. Please contact the system administrator'}
            </Alert>
          </div>
          <NxFormikSelect<string>
             name='schedule'
             label='Automatic debit schedule'
             options = {automaticDebitScheduleSelect}
             disabled = {!hasProductAccessRights}
          />
          <div hidden={!hasProductAccessRights}>
            <NxRow position={NxRowPosition.END}>
              <NxButton
                onClick={openDialog}
                variant={NxButtonVariant.ADD}>Add target
              </NxButton>
            </NxRow>
          </div>
        <div>
        <div hidden={!hasProductAccessRights}>
          <Alert severity="info">
              {agreements.length > 0 ? 'Drag columns to reorder target products.' : 'No Ada relations to display'}
          </Alert>
        </div>
          <DragDropItems
            droppableId={'targetOrder'}
            items={agreements.map(a => {
                return {
                    ...a,
                    key: a.targetId.toString()
                } as AutomaticDebitAgreementDraggable
            })}
            setItems={(values): void => setAgreements(values)}
            render={itemRendererProvider}
            isDragDisabled={!hasProductAccessRights}/>
        </div>
        <div hidden={!hasProductAccessRights}>
          <NxRow position={NxRowPosition.END}>
            <NxButton
              variant={NxButtonVariant.CLOSE}
              onClick={(): void => history.goBack()}> Cancel
            </NxButton>
            <CommandAccess commandName="ConfigureAutomaticDebitAgreement">
              <NxFormikSubmitButton
                variant={NxButtonVariant.SAVE}
                disabled={!isValid}
                onClick={(): Promise<void> => submitForm()}>Configure
              </NxFormikSubmitButton>
            </CommandAccess>
          </NxRow>
        </div>

        </NxStack>
        )}
      </NxFormik>
        <Dialog open={dialogShow} onClose={closeDialog} maxWidth={"xs"} fullWidth={true}>
          <DialogTitle>Add automatic debit target</DialogTitle>
             <NxFormik<Partial<TargetForm>> initialValues={{
                 keepMaintainingBalance: false,
                 executeOnAccountCredit: false
             }}
               validationSchema={TargetFormSchema}
               validateOnMount={true}
               onSubmit={(values) : void => addTarget(values.targetId, values.strategy, values.keepMaintainingBalance, values.executeOnAccountCredit)}>
             {({
               submitForm,
               values,
               isValid
             }): ReactElement => {
               return (
               <DialogContent>
                 <NxStack>
                  <div hidden={getProducts(values.productGroup).length > 0 || values.productGroup == null}>
                    <Alert severity="warning">
                      All products for the selected group are already added
                    </Alert>
                  </div>
                   <div hidden={!values.executeOnAccountCredit}>
                     <Alert severity="info">
                       Note: Debit may trigger on account credit for targets that are overdue before the agreement schedule
                     </Alert>
                   </div>
                   <NxFormikSelect<AutomaticDebitStrategy>
                     name='strategy'
                     label='Strategy'
                     options = {automaticDebitStrategySelect}
                   />
                   <NxFormikSelect<boolean>
                     name='keepMaintainingBalance'
                     label='Retain maintaining balance'
                     options = {booleanOption}
                   />
                   <NxFormikSelect<ProductGroup>
                      name='productGroup'
                      label='Product group'
                      options = {productGroupSelect}
                   />
                   <NxFormikSelect<number>
                     name='targetId'
                     label='Product'
                     options = {getProducts(values.productGroup)}
                   />
                   <NxFormikSelect<boolean>
                     name='executeOnAccountCredit'
                     label='Execute on account credit for missed payments'
                     options = {booleanOption}
                   />
               </NxStack>
               <DialogActions>
                 <NxButton
                   variant={NxButtonVariant.CLOSE}
                   onClick={closeDialog}>Cancel</NxButton>
                 <NxFormikSubmitButton
                   variant={NxButtonVariant.ADD}
                   onClick={async () : Promise<void> => {
                       await submitForm();
                       closeDialog();
                   }}
                   disabled={!isValid}>Add
                 </NxFormikSubmitButton>
               </DialogActions>
             </DialogContent>
               )}}
             </NxFormik>
        </Dialog>
    </NxPage>
  )
}

export default AutomaticDebitAgreementConfig;