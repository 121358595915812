import nxModule from 'nxModule';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {combineLatest} from 'rxjs/operators/combineLatest';

/**
 * NOTE: this directive will not work with ng-show on the same element and an error will be thrown when we
 *       attempt to use command-access and ng-show on the same element. ng-if, however, works fine.
 *
 * Example context:
 * {
 *   branchId: 1,
 *   productStatus: 'ACTIVE'
 * }
 */
nxModule.directive('commandAccessContext', function () {
  return {
    restrict: 'A',
    scope: {
      commandAccessContext: '<'
    },
    controller: function ($scope) {
      $scope.$watch(scope => scope.commandAccessContext, () => {
        this.context = $scope.commandAccessContext;
      });
    }
  };
});

nxModule.directive('commandAccess', function (commandAccessChecker, authentication) {
  return {
    restrict: 'A',
    require: '?^commandAccessContext',
    link: function (scope, element, attrs, commandAccessContext) {
      const commands = attrs.commandAccess;
      if (attrs.ngShow) {
        throw `The directive 'command-access' cannot be used together with 'ng-show'. Use 'ng-if' instead.`;
      };

      const contextChange = new ReplaySubject(1);

      scope.$watch(() => commandAccessContext ? commandAccessContext.context : null, () => {
        contextChange.next(commandAccessContext ? commandAccessContext.context: null);
      });

      const commandArray = commands.split(',').map(c => c.trim());

      const subscription = commandAccessChecker.canExecuteCommandObservable()
        .pipe(combineLatest(contextChange, (checker, context) => ({checker, context})))
        .subscribe(({checker, context}) => {
          //return the list of allowed users to execute a certain commands
          let showElement = commandArray.some(command => checker(command, context));

          if (showElement) element.show();
          else element.hide();
        });

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }
  };
});
