import {
  NxButton,
  NxButtonLink,
  NxButtonVariant,
  NxFormik,
  NxFormikSubmitButton,
  NxLoader,
  NxPopup,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxStack,
  NxTable,
  NxTableActionProps,
  NxTableColumn
} from '@nextbank/ui-components';
import {NxTableRef} from '@nextbank/ui-components/dist/nxTable/NxTableTypes';
import CommandAccess from 'command/CommandAccess';
import commandAccessService from 'command/commandAccessService';
import {useCommand} from 'command/CommandService';
import {
  CustomFieldDefinition,
  CustomFieldGroup,
  CustomFieldRestriction,
  CustomFieldRestrictionDetails
} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService, {useDefinitionRestrictions} from 'custom-field/CustomFieldService';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import misGroupService from 'loan/MisGroupService';
import HttpErrorHandler from 'misc-transaction/encash-cashiers-check/HttpErrorHandler';
import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import EnumFormatter from 'tools/EnumFormatter';

const columns: NxTableColumn<CustomFieldRestrictionDetails>[] = [{
  title: 'Category',
  field: 'category'
}, {
  title: 'Type',
  field: 'restrictedFieldType'
}, {
  title: 'Restricted field',
  field: 'restrictedField'
}];

interface DeleteRestrictionInput {
  id: number;
}
const formatter = new EnumFormatter();
const CustomFieldRestrictionList = ({group, pathPrefix}: {group: CustomFieldGroup, pathPrefix: string}): ReactElement => {
  const {id} = useParams<{id: string}>();
  const execute = useCommand();
  const addNewRestrictionPath = `${pathPrefix}/custom-fields/groups/${group}/fields/${id}/restrictions/new`
  const editRestrictionPath = `${pathPrefix}/custom-fields/groups/${group}/fields/${id}/restrictions`
  const nxTableApi = useRef<NxTableRef | null>(null);
  const [restrictionToDelete, setRestrictionToDelete] = useState<CustomFieldRestrictionDetails | null>(null);
  const [definition, setDefinition] = useState<CustomFieldDefinition | null>(null);
  const [{data: response, loading, error}] = useDefinitionRestrictions({customFieldIds: [Number(id)]});
  const [restrictions, setRestrictions] = useState<CustomFieldRestriction[]>([]);

  const prepareData = async (): Promise<void> => {
    const definition = await customFieldService.readDefinition(Number(id));
    setDefinition(definition);
  }

  useEffect(() => {
    prepareData();
    setRestrictions(response || []);
  }, [response]);

  const headerText = `${definition?.name} restrictions`;
  const header = <NxHeader>
    {headerText}
  </NxHeader>;

  if (!restrictions ||  loading) {
    return <NxPage>{header}<NxLoader/></NxPage>;
  }

  if (error) {
    return <HttpErrorHandler error={error} pageHeader={headerText}/>;
  }

  const DeleteRestriction = ({data}: NxTableActionProps<CustomFieldRestrictionDetails>): ReactElement =>
    <NxButton variant={NxButtonVariant.DELETE} onClick={(): void => {
      setRestrictionToDelete(data);
    }}>Delete
    </NxButton>;

  const EditDefinition = ({data}: NxTableActionProps<CustomFieldRestrictionDetails>): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`${editRestrictionPath}/${data.id}`}>Open</NxButtonLink>;

  const rowActions = commandAccessService.canExecute('UpsertCustomFieldRestriction')  ? [EditDefinition] : [];
  if (commandAccessService.canExecute('DeleteCustomFieldRestriction')) {
    rowActions.push(DeleteRestriction);
  }
  return (
   <NxPage>
     {header}
     <NxStack>
       <NxRow position={NxRowPosition.END}>
        <CommandAccess commandName="EditCustomFieldDefinition">
          <NxButtonLink variant={NxButtonVariant.ADD} to={addNewRestrictionPath}>Add</NxButtonLink>
        </CommandAccess>
      </NxRow>
       <NxTable
         ref={nxTableApi}
         columns={columns}
         rowActions={rowActions}
         data={async (query: NxQuery): Promise<NxQueryResult<CustomFieldRestrictionDetails>> => {
           const startingIndex = query.page * query.pageSize;
           const restrictedFieldIds: number[] = [];
           restrictions.forEach(r => {
             restrictedFieldIds.push(r.restrictedFieldId);
           })

           const [categories, restrictedFields, misGroups]= await Promise.all([
             customFieldService.readCategoryDetails(group, Number(id)),
             customFieldService.readDefinitions({ids: restrictedFieldIds}),
             misGroupService.readGroups()
           ])
           const categoryNameById = new Map(categories.map(c => [c.id, c.customFieldDefinitionName ?? c.categoryName]));
           const restrictedFieldNameById = new Map(restrictedFields.map(r => [r.id, r.name]));
           const misGroupNameById = new Map(misGroups.map(m => [m.id, m.name]));

           const details : CustomFieldRestrictionDetails[] = restrictions.map(r => {
             return {
               id: r.id,
               category: categoryNameById.get(r.customFieldCategoryId),
               restrictedFieldType: formatter.format(r.restrictedFieldType),
               restrictedField: r.restrictedFieldType === 'CUSTOM' ?
                 restrictedFieldNameById.get(r.restrictedFieldId) : misGroupNameById.get(r.restrictedFieldId)
             } as CustomFieldRestrictionDetails
           })
           const result = details.slice(startingIndex, startingIndex + query.pageSize);
           return {
             pageNo: query.page,
             result,
             totalCount: details.length
           };
         }}
       />
     </NxStack>
     <NxPopup header="Confirm"
              open={restrictionToDelete != null}
              description={`Do you want to delete restriction for '${restrictionToDelete?.restrictedField ?? ''}' on ${restrictionToDelete?.category ?? ''} category?`}>
       <NxFormik initialValues={{}} onSubmit={async (): Promise<void> => {
         try {
           if (!restrictionToDelete) {
             return;
           }
           const {approvalRequired} = await execute<DeleteRestrictionInput, void>({
             name: 'DeleteCustomFieldRestriction',
             input: {
               id: restrictionToDelete.id
             }
           });

           if (!approvalRequired) {
             setRestrictions(restrictions.filter(d => d.id !== restrictionToDelete.id));
             nxTableApi.current?.onQueryChange();
           }
         } finally {
           setRestrictionToDelete(null);
         }
       }}>
        {({
          submitForm
        }): ReactElement => {
        return (
          <NxRow position={NxRowPosition.END}>
            <NxButton variant={NxButtonVariant.CLOSE}
                      onClick={(): void => {
                        setRestrictionToDelete(null);
                      }}>
              No
            </NxButton>
            <NxFormikSubmitButton
              variant={NxButtonVariant.SAVE}
              onClick={(): void => {
                submitForm();
              }}>
              Yes
            </NxFormikSubmitButton>
          </NxRow>
        );
      }}
      </NxFormik>
     </NxPopup>
   </NxPage>
  )
};

export default CustomFieldRestrictionList;