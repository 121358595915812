import nxModule from 'nxModule';

nxModule.directive('validatePin', function(){
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attr, ngModel) {
      if(!ngModel) return;

      scope.$watch(attr.ngModel, function() {
        validate();
      });

      attr.$observe('validatePin', function (val) {
        validate();
      });

      function testForDigits(input) {
          var regExp = /^[0-9]{4}/;
          var validator = regExp.test(input);
          return validator;
      }

      let validate = function() {
        let val = ngModel.$viewValue;
        ngModel.$setValidity('validatePin', !val || (val.length === 4 && testForDigits(val)));
      };
    }
  }
});
