import nxModule from 'nxModule';
import templateUrl from './loan-transfer-payment.template.html';

class CustomerLoansTransferPayment {
  constructor($location, command, customerCache, loanProductsCache, depositAccountTypeService, confirmation,
              modalPrintPreviewService, $filter, accessRuleService, authentication) {
    this.$location = $location;
    this.command = command;
    this.customerCache = customerCache;
    this.loanProductsCache = loanProductsCache;
    this.depositAccountTypeService = depositAccountTypeService;
    this.confirmation = confirmation;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.$filter = $filter;
    this.authentication = authentication;

    this.commandName = 'PayLoanByTransfer';
    accessRuleService.accessRuleForLoggedInUserRole(this.commandName)
        .then(rule => this.canPerformCustomPayment = !rule.predicates['EQUALS'] || rule.predicates['EQUALS']['LOAN_CUSTOM_PAYMENT']);
  }

  async $onInit() {
    this.selectedAccount = {};
    this.paymentRequest = {};

    this.accounts = await this.fetchDepositAccounts();
  }

  /**
   * @returns deposit accounts of given customer (id === this.customerId)
   */
  async fetchDepositAccounts() {
    const [depositAccounts, accountTypes, relatedDepositAccounts] = await Promise.all([
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.depositAccountTypeService.toPromise(),
      this.customerCache.relatedDepositAccounts(this.customerId).toPromise()
    ]);

    const allDepositAccounts = [...depositAccounts, ...relatedDepositAccounts];

    return allDepositAccounts.filter(account => account.status === 'ACTIVE')
      .map(account => {
        const type = accountTypes.find(type => type.id === account.typeId);
        if (type) {
          // attach display name
          account.name = `${type.productDefinition.productName} [${account.productNumber}]`;
          // attach maintaining balance
          account.maintainingBalance = account.maintainingBalance ?? type.maintainingBalance;
        }
        return account;
      });
  }

  async pay() {
    if (await this.confirmationPromise()) {
      this.command.execute(this.commandName, {
        productId: this.loan.id,
        accountId: this.selectedAccount.id,
        commandPurpose: 'LOAN_PAYMENT',
        ...this.paymentRequest
      }, {nxLoaderText: 'Performing operation...'}).success(() => {
        this.refetchCaches();
        this.redirectBack();
      }, false).offline(() => {
        this.refetchCaches();
        this.redirectBack();
      }, false);
    }
  }

  confirmationPromise() {
    const maintainingBalanceBreakdown =
      this.selectedAccount.balance - this.paymentRequest.amount < this.selectedAccount.maintainingBalance;

    const balanceBelowMaintainingBalance =
      this.selectedAccount.balance < this.selectedAccount.maintainingBalance;

    if (balanceBelowMaintainingBalance) {
      return this.confirmation(`Balance of selected account (${this.$filter('nxCurrency')(this.selectedAccount.balance)}) is
        below maintaining balance (${this.$filter('nxCurrency')(this.selectedAccount.maintainingBalance)}).
        Are you sure you want to proceed?`)
    } else if (maintainingBalanceBreakdown) {
      return this.confirmation(`After this payment balance of selected account (${this.$filter('nxCurrency')(this.selectedAccount.balance)})
        will fall below maintaining balance (${this.$filter('nxCurrency')(this.selectedAccount.maintainingBalance)}).
        Are you sure you want to proceed?`)
    } else {
      return this.confirmation(`Do you want to pay ${this.$filter('nxCurrency')(this.paymentRequest.amount)}?`)
    }
  }

  refetchCaches() {
    this.customerCache.loans(this.customerId).refetch();
    this.customerCache.depositAccounts(this.customerId).refetch();
    this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.customerId).refetch() : Promise.resolve();
    this.customerCache.loanPDCs(this.customerId, this.loan.id).refetch();
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  print() {
    window.print();
  }
}

nxModule.component('customerLoansTransferPayment', {
  templateUrl: templateUrl,
  bindings: {
    customerId: '<',
    loan: '<'
  },
  controller: CustomerLoansTransferPayment
});
