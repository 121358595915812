import React from 'react';
import ReactDOM from "react-dom";
import './initializeYup';
import './initializeAxios';

import AppRouter from './routes/Router';
import {StylesProvider, ThemeProvider} from '@material-ui/core';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

import {Theme} from "@nextbank/ui-components";
import '@nextbank/ui-components/dist/index.css';
import './main.scss';
import {Notification} from "tools/notificationService";


export default (): void => {

  ReactDOM.render(
<React.StrictMode>
  <ThemeProvider theme={Theme}>
    <StylesProvider injectFirst>
      <ScopedCssBaseline>
        <Notification />
        <AppRouter/>
      </ScopedCssBaseline>
    </StylesProvider>
  </ThemeProvider>
</React.StrictMode>,
    window.document.getElementById('react-view')
  );
}
