import nxModule from 'nxModule';
import $ from 'jquery';

type Notification = {
  show: (title: string, text?: string, time?: number) => void;
}

const notification = () => {
  const notificationService: Notification = {
    show: function (title, text?, time?) {
      time = (time !== undefined ? time : 2000);

      if (text == null) {
        text = title;
        title = "Success";
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      $.gritter.add({
        // (string | mandatory) the heading of the notification
        title: title,
        // (string | mandatory) the text inside the notification
        text: text,
        // (int | optional) the time you want it to be alive for before fading out (milliseconds)
        time: time,
        // (bool | optional) if you want it to fade out on its own or just sit there
        sticky: false
      });
    }
  };

  return notificationService;
};

export default Notification;
nxModule.factory('notification', notification);
