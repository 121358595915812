import nxModule from 'nxModule';
import templateUrl from "./miscellaneous-transactions-page.template.html";

class MiscellaneousTransactionsPage {
  headerTitle!: string;
  formTitle!: string;
  errorMessages!: string[];
}

nxModule.component('miscellaneousTransactionsPage', {
  templateUrl,
  controller: MiscellaneousTransactionsPage,
  transclude: true,
  bindings: {
    headerTitle: '<',
    formTitle: '<',
    errorMessages: '<'
  },
});
