import nxModule from 'nxModule';
import 'rxjs/add/operator/first';
import PrintService from "print/PrintService";
import $ from 'jquery';

const printService = new PrintService();
nxModule.factory('printService', function (http, notification, printBackgroundCache) {
  return {
    printToContainer: async function (docs, options) {
      if (!options || !options.container) {
        console.error('ERROR: PrintService: No container provided.', docs, options);
        return;
      }

      const isTemplateType = docs[0].type === 'TEMPLATE';
      const backgroundEnabled = options.backgroundEnabled;

      const getBackground = async (doc) => {
        if (options.backgroundThumbnails?.length > 0) {
          if(options.backgroundThumbnails.some(item => !item)) {
            return Promise.reject("Cannot print for empty thumbnail");
          }

          return Promise.resolve(options.backgroundThumbnails
            //for unknown content-type force jpg
            .map(base64 => base64.replace(/^data:application\/octet-stream;base64,/, "data:image/jpg;base64,")));
        }

        const backgroundParams = {
          printCode: doc.code,
          params: {
            ...doc.parameters
          }
        };

        return printBackgroundCache.withParam(backgroundParams).toPromise();
      };

      let backgroundImages = [];
      if(!isTemplateType && backgroundEnabled) {
        const doc = docs[0];
        if(options.backgroundThumbnails?.length > 0 || doc.backgroundFileIds?.length > 0) {
          backgroundImages = await getBackground(doc);
        }
      }

      const url = await printService.generatePrint(docs, {
        backgrounds: backgroundImages
      });

      if(!isTemplateType) {
        options.container.removeAttr('sandbox');
      } else {
        options.container.attr('sandbox', 'allow-same-origin allow-modals');
      }

      options.container.attr('src', url);
      options.container.attr('type', isTemplateType ? 'text/html' : 'application/pdf');
      if(options.autoPrint) {
        options.container.one('load', () => {
          const container = options.container[0];
          container.contentWindow.print();
        });
      }

      $('#pdfWrapper').css('padding-bottom', '');
    }
  };
});