import {agentTransactions} from "constants/deposit";
import {AccountType} from "components/service/account.types";
import {RecursivePartial} from 'shared/utils/RecursivePartial';

export const newAgentFee = (feeClass: string, feeName: string): any => {
  return {
    feeClass: feeClass,
    feeType: 'FIXED',
    operationGroup: 'APPLY_FEE',
    feeName: feeName,
    fixedAmount: 0,
    calculateOnStatuses: ['ACTIVE', 'INACTIVE', 'PENDING'],
    applyOnStatuses: ['ACTIVE', 'INACTIVE', 'PENDING'],
    overdraftStrategy: 'OVERDRAFT',
    enabled: true
  }
};

export const NEW_DEPOSIT_ACCOUNT_TYPE: RecursivePartial<AccountType> = Object.freeze({
  interestMinimalBalance: 0,
  maintainingBalance: 0,
  maintainingCharge: 0,
  maxCustomerAge: 0,
  minCustomerAge: 0,
  supportsGroup: false,
  taxExempted: false,
  purpose: 'GENERAL',
  withholdingTax: 20,
  collectAdvanceWithholdingTax: true,
  applyInterestOnClose: true,
  accrued: true,
  selfTransferCharge: {
    feeType: 'FIXED',
    fixedValue: 0.0,
    overdraftStrategy: 'DRAIN'
  },
  internalTransferCharge: {
    feeType: 'FIXED',
    fixedValue: 0.0,
    overdraftStrategy: 'DRAIN'
  },
  unfundedServiceCharge: 1000.00,
  fixedPenaltyPerCheckCharge: 200.00,
  variablePenaltyPerCheckCharge: 40000.00,
  presentingBankCharge: 1000.00,
  passbookConfig: 'PASSBOOK_NOT_SUPPORTED',
  spoCharge: 0.0,
  daudChargeDelegate: true,
  daudCharge: 0.0,
  enableSpoPlacementCharge: false,
  autoCloseOnInwardCharges: false,
  autoCloseInterval: 'NONE',
  autoCloseContractualSavings: true,
  initialDepositRequired: false,
  dormant: true,
  dormantConfig: {
    dormancyTerm: 365,
    chargeTerm: 1825,
    chargeAmount: 50,
    chargeBalanceThreshold: 0,
    activationFee: 0,
    balanceThreshold: 0,
    allowInterest: true
  },
  productDefinition: {
    productGroup: 'ACCOUNT',
    expired: false,
    daysInYear: 365,
    productAvailability: 'AVAILABLE_IN_ALL_BRANCHES',
    availableInBranchIds: [],
    allowCreationByAgents: false,
    allowedAgentTransactions: [...agentTransactions].map(o => o.value),
  },
  accrualScheduler: {
    cycleType: 'MONTH_END',
    enabled: true,
    executionInterval: 1
  },
  creditingScheduler: {
    cycleType: 'MONTH_END',
    enabled: true,
    interval: 1
  },
  maintainingScheduler: {
    cycleType: 'MONTH',
    enabled: true,
    executionInterval: 1
  },
  dormancyScheduler: {
    cycleType: 'MONTH',
    enabled: true,
    executionInterval: 1
  },
  automaticTransferAgreement: {
    enabled: false,
    maxRelations: 1,
    allowExternalRelations: false,
    replenishEnabled: false,
    replenishStrategy: 'NONE',
  },
  interestBoards: [{
    rateType: 'BASIC',
    defaultRate: 0,
    cells: [{
      rate: 0,
      minBalance: 0,
      minTerm: 0,
    }]
  }],
  feeDefinitions: [],
  maxCreditBalanceType: 'NONE',
  transactionQuota: [{
    period: 'DAILY',
    type: 'WITHDRAWAL_PER_CUSTOMER',
    countQuota: 0,
    cashQuota: 0,
    owner: 'ACCOUNT_TYPE'
  }, {
    period: 'DAILY',
    type: 'DEPOSIT_PER_CUSTOMER',
    countQuota: 0,
    cashQuota: 0,
    owner: 'ACCOUNT_TYPE'
  }],
  agentBalanceInquiryCharge: newAgentFee('AGENT_BALANCE_INQUIRY', 'Agent fee on balance inquiry'),
  agentDepositCharge: newAgentFee('AGENT_DEPOSIT', 'Agent fee on deposit'),
  agentWithdrawalCharge: newAgentFee('AGENT_WITHDRAWAL', 'Agent fee on withdrawal'),
  agentBillCharge: newAgentFee('AGENT_BILL', 'Agent fee on bill payment'),
  agentPrepaidCharge: newAgentFee('AGENT_PREPAID', 'Agent fee on prepaid payment'),
  agentTopupCharge: newAgentFee('AGENT_TOP_UP', 'Agent fee on top-up payment'),
  initialDepositOperations: ['DepositCashToAccount'],
  enableTransactionLimit: false,
  limitByCustomerAge: false,
  allowCenterManagement: false,
  automaticDebitAgreementEnabled: false
});
