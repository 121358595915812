import nxModule from 'nxModule';
import templateUrl from './gl-payment.template.html';
import './gl-payment.style.less';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {Operation} from "components/service/operation.types";

class GlPayment {

  protected operation!: Operation & {categoryId?: number};
  protected category?: ActionCategory;

  categoryChanged(): void {
    this.operation.categoryId = this.category?.id;
  }
}

nxModule.component('glPayment', {
  templateUrl,
  bindings: {
    operation: '=',
    remarks: '=',
    actionType: '<'
  },
  controller: GlPayment
});
