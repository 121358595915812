import nxModule from 'nxModule';

import templateUrl from './credit-scoring-modal-confusion-matrix.template.html';
import './credit-scoring-modal-confusion-matrix.style.less';
import {CreditScoreDescription} from "components/customer/credit-scoring/credit-scoring.types";

interface ConfusionMatrixCell {
  fieldName: string;
  backgroundColorSelector: string,
  gridAreaSelector: string;
  fieldValueKey?: string;
}

class CreditScoringModalModelConfusionMatrix {

  scoreDescription!: CreditScoreDescription;
  confusionMatrixValues: Record<string, number> = {};
  confusionMatrixCells: ConfusionMatrixCell[] = [
    {
      fieldName: 'Predicted class',
      backgroundColorSelector: 'background-description',
      gridAreaSelector: 'tile-description-top'
    },
    {
      fieldName: 'Actual class',
      backgroundColorSelector: 'background-description',
      gridAreaSelector: 'tile-description-left'
    },
    {
      fieldName: 'True positive',
      backgroundColorSelector: 'background-positive',
      gridAreaSelector: 'tile-top-left',
      fieldValueKey: 'True Positive (TP)',
    },
    {
      fieldName: 'False positive',
      backgroundColorSelector: 'background-negative',
      gridAreaSelector: 'tile-bottom-left',
      fieldValueKey: 'False Positive (FP)',
    },
    {
      fieldName: 'True negative',
      backgroundColorSelector: 'background-positive',
      gridAreaSelector: 'tile-bottom-right',
      fieldValueKey: 'True Negative (TN)',
    },
    {
      fieldName: 'False negative',
      backgroundColorSelector: 'background-negative',
      gridAreaSelector: 'tile-top-right',
      fieldValueKey: 'False Negative (FN)',
    }
  ]

  async $onInit(): Promise<void> {
    this.confusionMatrixValues = Object.fromEntries(this.scoreDescription.confusionMatrix.map(m => [m.field, m.value]));
  }

}

nxModule.component('creditScoringModalModelConfusionMatrix', {
  templateUrl,
  bindings: {
    scoreDescription: '<',
  },
  controller: CreditScoringModalModelConfusionMatrix
});
