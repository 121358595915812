import nxModule from 'nxModule';

const templateUrl = require('./create-access-rules.template.html');

nxModule.component('createAccessRules', {
  templateUrl,
  controller: function (confirmationTemplate, popup, http, $filter, $route, rolesCache, accessRuleCache, commandRoleMapCache) {
    const that = this;

    that.command = {
      roleId: null
    };

    rolesCache.toObservable().first().subscribe(roles => that.roles = roles);

    that.execute = () => {
      confirmationTemplate({
        question: 'Do you want to create missing access rules?',
        yesCallback: () => http.post(`/access/rules/create-missing`, that.command).success((ret) => {
          accessRuleCache.evict();
          commandRoleMapCache.refetch();
          popup({text:`Created ${ret.output} rules`, callback: () => $route.reload()});
        })
      })
    };

  }
});
