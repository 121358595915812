import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import AuthenticationService from "./authentication/AuthenticationService";
import CmcService from "./system/CmcService";
import {TokenDetails} from "./authentication/TokenTypes";
import tokenStore from "./authentication/tokenStore";
import currentBranchService from "./management/currentBranchService";
import showNotification from "./tools/notificationService";
import {CancelError, ErrorResponse, HttpError} from "./tools/HttpTypes";
import internetServiceCheck from "./system/internetCheckService";
import config from "./config";
import {configure} from 'axios-hooks'

configure({
  defaultOptions: {
    useCache: false
  }
});


const authenticationService = new AuthenticationService();
const cmcService = new CmcService();

axios.defaults.baseURL = config.apiPrefix;
axios.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const cmc = cmcService.getCmcToken();
  const cmcHeader = cmc ? {
    'NX-CMC' : cmc
  } : null;

  const tokenDetails: TokenDetails = tokenStore.getTokenDetails();
  const branchId: number | null = currentBranchService.getBranchId();
  const branchHeader = branchId !== tokenDetails.userBranchId ? {
    'NX-BRANCH' : String(branchId)
  } : null;

  if(!config.headers) {
    config.headers = {

    };
  }

  config.headers = {
    'Authorization': `Bearer ${tokenDetails.token}`,
    ...cmcHeader,
    ...branchHeader,
    ...config.headers,
  };

  return config;
});


axios.interceptors.response.use(v => {
  internetServiceCheck.reportSuccess();
  return v;
}, (error: AxiosError<ErrorResponse>) => {
  if(axios.isCancel(error)) {
    return new CancelError();
  }

  try {
    const parsedError = error?.response?.data as ErrorResponse;
    const response = error.response;
    if (response?.status === 401 && parsedError.errorMessage === 'Authentication Failed: ACCESS_BLOCKED_DUE_TO_WORKING_HOURS') {
      alert("Access blocked due to working hours");
      authenticationService.logoutKeepPath("Logged out due to working hours");
    } else if(response?.status === 401) {
      console.error("Session expired");
      authenticationService.logoutKeepPath("Session expired");
    } else {
      const message = parsedError.errorMessage ?? "An unknown error occurred.";

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if(!error.config.ignoreError) {
        showNotification({
          type: 'error',
          text: message
        });
      }
    }
  } catch (e) {
    showNotification( {
      type: 'error',
      text: "An error occurred while contacting the server. Please try again later"
    });
  }

  throw new HttpError(error);
});