import nxModule from 'nxModule';
import templateUrl from './amortization-preview.template.html'
import {AmortizationSchedule} from "components/service/loan.types";

export interface Config {
  maxAmortizationDisplayed?: number;
  showSummary: boolean;
  hideContractualColumnsIfZero: boolean;
  hideCustomFeesIfZero: boolean;
}

class AmortizationPreviewController {
  public amortizationPreview!: AmortizationSchedule;
  public config!: Config;
  readonly defaultConfig: Config = {
    maxAmortizationDisplayed: undefined,
    showSummary: true,
    hideContractualColumnsIfZero: false,
    hideCustomFeesIfZero: false
  };

  $onInit(): void {
    if (!this.config) {
      this.config = this.defaultConfig;
    }
  }

  public shouldShowContractual(): boolean {
    if (!this.amortizationPreview) {
      return true;
    }

    if (!this.config.hideContractualColumnsIfZero) {
      return true;
    }

    const anyCbu = this.amortizationPreview.list.some(a => a.cbuChargeAmount > 0);
    const anyTp = this.amortizationPreview.list.some(a => a.tpChargeAmount > 0);
    const anyPf = this.amortizationPreview.list.some(a => a.pfChargeAmount > 0);

    return anyCbu && anyTp && anyPf;
  }

  public shouldShowCustomFees(): boolean {
    if (!this.amortizationPreview) {
      return true;
    }

    if (!this.config.hideCustomFeesIfZero) {
      return true;
    }

    return this.amortizationPreview.list.some(a => a.customFeesAmount > 0);
  }
}

nxModule.component('amortizationPreview', {
  templateUrl: templateUrl,
  bindings: {
    amortizationPreview: '<',
    config: '<'
  },
  controller: AmortizationPreviewController
});
