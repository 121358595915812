import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';

import templateUrl from './paid-amortizations.template.html'
import './paid-amortizations.less'
import {IScope} from 'angular';
import {Amortization, AmortizationSummary} from '../../../service/loan.types';

class PaidAmortizations {

  protected operation!: any;
  protected amortizations!: Amortization[];
  private summary!: AmortizationSummary;

  constructor(private $scope: IScope) {
    $scope.$watch('$ctrl.amortizations', async () => {
      this.summary = await this.calculateSummary()
    })
  }

  async calculateSummary(): Promise<AmortizationSummary> {
    const ZERO = new BigNumber(0);
    let principalTotal = ZERO;
    let interestTotal = ZERO;
    let cbuTotal = ZERO;
    let pfTotal = ZERO;
    let tpTotal = ZERO;
    let pastDueInterestTotal = ZERO;
    let pastDueMaturityInterestTotal = ZERO;
    let penaltiesTotal = ZERO;
    let penaltiesMaturityTotal = ZERO;
    let customFeesTotal = ZERO;
    let total = ZERO;

    this.amortizations?.forEach(a => {
      principalTotal = principalTotal.plus(this.bn(a.principalAmount));
      interestTotal = interestTotal.plus(this.bn(a.interestAmount));
      cbuTotal = cbuTotal.plus(this.bn(a.cbuChargeAmount));
      pfTotal = pfTotal.plus(this.bn(a.pfChargeAmount));
      tpTotal = tpTotal.plus(this.bn(a.tpChargeAmount));
      pastDueInterestTotal = pastDueInterestTotal.plus(this.bn(a.pastDueInterestAmount));
      pastDueMaturityInterestTotal = pastDueMaturityInterestTotal.plus(this.bn(a.pastDueMaturityInterestAmount));
      penaltiesTotal = penaltiesTotal.plus(this.bn(a.penaltyAmount));
      penaltiesMaturityTotal = penaltiesTotal.plus(this.bn(a.penaltyMaturityAmount));
      customFeesTotal = customFeesTotal.plus(this.bn(a.customFeesAmount));
      total = total.plus(this.bn(a.amortizationAmount));
    });

    return {
      principal: principalTotal.toNumber(),
      interest: interestTotal.toNumber(),
      cbuCharge: cbuTotal.toNumber(),
      pfCharge: pfTotal.toNumber(),
      tpCharge: tpTotal.toNumber(),
      pastDueInterest: pastDueInterestTotal.toNumber(),
      pastDueMaturityInterest: pastDueMaturityInterestTotal.toNumber(),
      totalPastDueInterest: pastDueInterestTotal.plus(pastDueMaturityInterestTotal).toNumber(),
      penalty: penaltiesTotal.toNumber(),
      penaltyMaturity: penaltiesMaturityTotal.toNumber(),
      totalPenalty: penaltiesMaturityTotal.plus(penaltiesTotal).toNumber(),
      customFees: customFeesTotal.toNumber(),
      total: total.toNumber()
    };
  }

  bn(amount: number): BigNumber {
    return amount ? new BigNumber(amount) : new BigNumber(0);
  }
}


nxModule.component('paidAmortizations', {
  templateUrl,
  bindings: {
    amortizations: '<',
    operation: '<',
    summary: '='
  },
  controller: PaidAmortizations
});
