import nxModule from 'nxModule';

const templateUrl = require('./cashier-cash-shortage.template.html');
nxModule.component('cashierCashShortage', {
  templateUrl: templateUrl,
  controller: function (actionCommand, miscCommandService) {
    this.transaction = {};

    this.denominationIsValid = () => {
      return this.transaction.denominationValid || this.transaction.denominationValid == null;
    };

    this.cancelChanges = () => actionCommand.cancelChanges();

    this.save = async () => {
      await miscCommandService.executeCommand('MiscCashierCashShortage', this.transaction).toPromise();
    }
  }
});
