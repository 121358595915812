import {customerIdResolver, depositIdResolver, productIdFromDepositIdResolver,} from './resolvers';
import {customerPath} from "./customer.routes";
import angular from "angular-route";

const termDepositsPath = `${customerPath}/term-deposits`;
const termDepositPath = `${termDepositsPath}/:depositId`;

export const termDepositsRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  [termDepositsPath, {
    template: '<customer-term-deposits/>',
    label: 'Term Deposits'
  }], [`${termDepositsPath}/create`, {
    template: '<customer-term-deposit-create-list/>',
    label: 'New Term Deposit'
  }], [`${termDepositsPath}/create/:termDepositTypeId`, {
    template: '<customer-term-deposit-create-details/>',
    label: '<null>'
  }], [`${termDepositPath}/interests-cash-withdrawal`, {
    template: '<customer-term-deposit-interests-cash-withdrawal/>',
    label: 'Interests Cash Withdrawal'
  }], [`${termDepositPath}/interests-check-withdrawal`, {
    template: '<customer-term-deposit-interests-check-withdrawal/>',
    label: 'Interests Check Withdrawal'
  }], [`${termDepositPath}/interests-transfer-to-product`, {
    template: '<customer-term-deposit-interests-transfer-to-product/>',
    label: 'Interests Transfer To Product'
  }], [`${termDepositPath}/cash-placement`, {
    template: '<customer-term-deposit-cash-placement/>',
    label: 'Cash Placement'
  }], [`${termDepositPath}/check-placement`, {
    template: '<customer-term-deposit-check-placement/>',
    label: 'Check Placement'
  }], [`${termDepositPath}/transfer-placement`, {
    template: '<transfer-placement product-id="$resolve.productId" customer-id="$resolve.customerId"/>',
    label: 'Transfer Placement',
    resolve: {
      ...customerIdResolver,
      ...productIdFromDepositIdResolver
    }
  }], [`${termDepositsPath}/:accountId/credit-memo`, {
    template: '<deposit-memo memo-mode="\'CREDIT\'" memo-type="\'REGULAR\'"/>',
    label: 'Credit memo'
  }], [`${termDepositsPath}/:accountId/debit-memo`, {
    template: '<deposit-memo memo-mode="\'DEBIT\'" memo-type="\'REGULAR\'"/>',
    label: 'Debit memo'
  }], [`${termDepositsPath}/:accountId/placement-credit-memo`, {
    template: '<deposit-memo memo-mode="\'CREDIT\'" memo-type="\'PLACEMENT\'"/>',
    label: 'Debit memo'
  }],
  [`${termDepositPath}/cash-termination`, {
    template: '<customer-term-deposit-cash-termination/>',
    label: 'Cash termination'
  }], [`${termDepositPath}/check-termination`, {
    template: '<customer-term-deposit-check-termination/>',
    label: 'Check termination'
  }], [`${termDepositPath}/memo-termination`, {
    template: '<customer-term-deposit-memo-termination customer-id="$resolve.customerId" deposit-id="$resolve.depositId"/>',
    label: 'Memo termination',
    resolve: {
      ...customerIdResolver,
      ...depositIdResolver
    }
  }], [`${termDepositPath}/manual-rollover`, {
    template: '<deposit-manual-rollover customer-id="$resolve.customerId" deposit-id="$resolve.depositId" />',
    label: 'Manual rollover',
    resolve: {
      ...customerIdResolver,
      ...depositIdResolver
    }
  }], [`${termDepositPath}/close`, {
    template: '<close-term-deposit customer-id="$resolve.customerId" deposit-id="$resolve.depositId"/>',
    label: 'Close',
    resolve: {
      ...customerIdResolver,
      ...depositIdResolver
    }
  }], [`${termDepositPath}/partial-withdrawal-cash`, {
    template: '<customer-term-deposit-partial-withdrawal-cash/>',
    label: 'Partial withdrawal by cash'
  }], [`${termDepositPath}/partial-withdrawal-check`, {
    template: '<customer-term-deposit-partial-withdrawal-check/>',
    label: 'Partial withdrawal by check'
  }], [`${termDepositPath}/transfer-to-product`, {
    template: '<transfer-to-product customer-id="$resolve.customerId" product-id="$resolve.productId" product-group="\'DEPOSIT\'"/>',
    label: 'Transfer funds to product',
    resolve: {
      ...customerIdResolver,
      ...productIdFromDepositIdResolver
    }
  }], [`${termDepositPath}/edit`, {
    template: '<customer-term-deposit-edit/>',
    label: 'Edit Term Deposit'
  }], [`${termDepositPath}/edit-signature-card`, {
    template: '<customer-term-deposit-edit-signature-card customer-id="$resolve.customerId" deposit-id="$resolve.depositId"/>',
    label: 'Edit Term Deposit signature card',
    resolve: {
      ...customerIdResolver,
      ...depositIdResolver
    }
  }], [`${termDepositPath}/create-hold`, {
    template: '<create-deposit-hold/>',
    label: 'Create hold'
  }], [`${termDepositPath}/create-lock`, {
    template: '<create-lock/>',
    label: 'Create lock'
  }], [`${termDepositPath}/issue-passbook`, {
    template: '<issue-passbook product-group="\'TERM_DEPOSIT\'"/>',
    label: 'Issue passbook'
  }], [`${termDepositPath}/issue-passbook/edit`, {
    template: '<issue-passbook product-group="\'TERM_DEPOSIT\'" edit-mode="true"/>',
    label: 'Update passbook number'
  }], [`${termDepositPath}/issue-certificate`, {
    template: '<issue-certificate customer-id="$resolve.customerId" product-id="$resolve.productId"/>',
    label: 'Issue certificate',
    resolve: {
      ...customerIdResolver,
      ...productIdFromDepositIdResolver
    }
  }], [`${termDepositPath}`, {
    template: '<customer-term-deposits/>',
    label: '<null>'
  }]
];