import nxModule from 'nxModule';

import templateUrl from './new-agent-devices.template.html';
import _ from 'lodash';
import {ActionStyling, ActionType, Columns} from '../../../common/dynamic-list/dynamic-list.component';
import {Dict} from "shared/common/dict.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {Device} from "components/agent-banking/details/devices/device-details.component";

interface NewDeviceDetails extends Device {
  creationDateFormatted: string;
  modificationDateFormatted: string;
  warrantyExpiryDateFormatted: string;
  telcoProviderIdFormatted: string;
  edit: () => void;
}

class NewAgentDevice {
  public device!: NewDeviceDetails;
  public isDeviceEdited: boolean = false;
  public originalDevice?: NewDeviceDetails;

  readonly columns: Columns = [
    {title: 'No'},
    {title: 'Code', field: 'code'},
    {title: 'Status', field: 'status'},
    {title: 'Phone serial number', field: 'serialNumber'},
    {title: 'Bluetooth printer serial number', field: 'bluetoothPrinterSerialNumber'},
    {title: 'Creation date', field: 'creationDateFormatted'},
    {title: 'Modification date', field: 'modificationDateFormatted'},
    {title: 'Warranty expiry date', field: 'warrantyExpiryDateFormatted'},
    {title: 'Telco provider', field: 'telcoProviderIdFormatted'},
    {
      actions: [{
        label: 'Edit',
        field: 'edit',
        type: ActionType.BUTTON,
        style: ActionStyling.WARNING
      }]
    }
  ];

  constructor(private $filter: NxIFilterService, private dict: Dict) {
  }

  async $onInit(): Promise<void> {
    await this.dict.onLoadingCompleteAsync();
    Object.assign(this.device, this.getDeviceInfo());
    this.device.edit = () => this.edit();
  }

  edit(): void {
    this.originalDevice = _.cloneDeep(this.device);
    this.isDeviceEdited = true;
  }

  save(): void {
    this.isDeviceEdited = false;
    Object.assign(this.device, this.getDeviceInfo());
  }

  cancel(): void {
    this.device = this.originalDevice!;
    this.save();
  }

  getDeviceInfo(): Record<string, string> {
    return {
      creationDateFormatted: this.$filter('prettyDateTime')(this.device.creationDate),
      modificationDateFormatted: this.$filter('prettyDateTime')(this.device.modificationDate),
      warrantyExpiryDateFormatted: this.$filter('prettyDate')(this.device.warrantyExpiryDate),
      telcoProviderIdFormatted: this.dict.getDescription('TELCO_PROVIDER', this.device.telcoProviderId)
    }
  }

  isDeviceValid(): boolean {
    return !!(this.device.warrantyExpiryDate && this.device.telcoProviderId);
  }
}

nxModule.component('newAgentDevice', {
  templateUrl: templateUrl,
  bindings: {
    device: '=',
    isDeviceEdited: '='
  },
  controller: NewAgentDevice
});
