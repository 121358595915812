import nxModule from 'nxModule';
import $ from 'jquery';
import _ from 'lodash';

/**
 * Service returns promise resolved with true when user accepted action and false otherwise.
 */
nxModule.factory('confirmation', function ($q) {
  return function (question,
                   /** @deprecated function returns promise, use it */ yesCallback,
                   /** @deprecated function returns promise, use it */ noCallback,
                   renderHtml = false) {

    // hack to skip requiring all arguments
    if(_.isBoolean(yesCallback)) {
      renderHtml = yesCallback;
      yesCallback = null;
      noCallback = null;
    }

    let questionBody;
    let questionHeader;
    if (typeof(question) === 'string') {
      questionHeader = 'Confirm';
      questionBody = question;
    } else {
      questionHeader = question.header;
      questionBody = question.body;
    }

    const defer = $q.defer();
    $('#confirmationModal').remove();
    $('#modal-container').append(`
      <div id="confirmationModal" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          <h2 id="modalHeader"></h2>
        </div>
        <div class="modal-body">
          <p id="modalQuestion"></p>
        </div>
        <div class="modal-footer">
          <button id="modalCancel" class="btn" data-dismiss="modal" aria-hidden="true">No</button>
          <button id="modalSave" class="btn btn-primary" data-dismiss="modal" aria-hidden="true">Yes</button>
        </div>
      </div>`);

    $('#modalHeader').text(questionHeader);
    if (renderHtml) {
      $('#modalQuestion').html(questionBody);
    } else {
      $('#modalQuestion').text(questionBody);
    }
    $('#confirmationModal').modal();
    $('#modalSave').click(function () {
      disableButtons();

      if(yesCallback) {
        yesCallback();
      }

      defer.resolve(true);
    });
    $('#modalCancel').click(function () {
      if (noCallback !== undefined) {
        disableButtons();

        if(noCallback) {
          noCallback();
        }

      }
      defer.resolve(false);
    });

    function disableButtons() {
      $('#modalSave, #modalCancel').off();
    }

    return defer.promise;
  }
});
