import React, {MutableRefObject, ReactElement, useEffect, useRef, useState} from "react";
import {NxTableRef} from "@nextbank/ui-components/dist/nxTable/NxTableTypes";
import {NxLoader, NxStack} from "@nextbank/ui-components";
import BranchService from "management/BranchService";
import CheckPreparationHistoryTabFilter, {OnSubmitInput} from "check/preparation/history/CheckPreparationHistoryTabFilter";
import CheckPreparationHistoryList from "check/preparation/history/CheckPreparationHistoryList";
import ReportService from "report/ReportService";

const branchService = new BranchService();
const reportService = new ReportService();

interface CheckPreparationHistoryTabProps {
  availableReports: string[];
  defaultBranchSystemDate: string;
  batchType: 'FOR_RELEASE' | 'FOR_OUTGOING';
  historyReports: string[];
}

interface CheckPreparationTabContainerProps {
  batchType: 'FOR_RELEASE' | 'FOR_OUTGOING';
  historyReports: string[];
}

const CheckPreparationHistoryTab = (props: CheckPreparationHistoryTabProps): ReactElement => {
  const tableRef: MutableRefObject<NxTableRef | null> = useRef<NxTableRef | null>(null);
  const [formInput, setFormInput] = useState<OnSubmitInput>();

  const onSubmit = async (values: OnSubmitInput): Promise<void> => {
    setFormInput(values);
    tableRef.current?.onQueryChange()
  }

  return <NxStack>
    <CheckPreparationHistoryTabFilter
      {...props}
      onSubmit={onSubmit}
    />
    {formInput && <CheckPreparationHistoryList
      branchId={formInput.branchId}
      dateFrom={formInput.dateFrom}
      dateTo={formInput.dateTo}
      availableReports={props.availableReports}
      batchType={props.batchType}
      historyReports={props.historyReports}
      tableRef={tableRef} />
    }
  </NxStack>
};

const CheckPreparationHistoryTabContainer = (props: CheckPreparationTabContainerProps): ReactElement => {
  const [branchSystemDate, setBranchSystemDate] = useState<string>();
  const [availableReports, setAvailableReport] = useState<string[]>();

  const initialize = async (): Promise<void> => {
    const [currentBranch, availableReports] = await Promise.all([
      branchService.readCurrentBranch(),
      reportService.readAvailableFileReports()
    ]);

    setBranchSystemDate(currentBranch.systemDate);
    setAvailableReport(availableReports);
  };

  useEffect(() => {
    initialize();
  }, []);

  if (!availableReports || !branchSystemDate) {
    return <NxLoader/>
  }

  return <CheckPreparationHistoryTab
    availableReports={availableReports}
    defaultBranchSystemDate={branchSystemDate}
    batchType={props.batchType}
    historyReports={props.historyReports}
  />
}

export default CheckPreparationHistoryTabContainer;