import axios from 'axios';
import {MisGroup} from 'components/service/loan.types';
import {MisCategoryValue} from 'custom-field/CustomFieldRestrictionEdit';
import {NxTreeNode} from 'tree/NxTreeNode';

export class MisGroupService {

  public async readGroups(): Promise<MisGroup[]> {
    const {data} = await axios.get<MisGroup[]>(`/products/loans/mis-groups`);
    return data;
  }

  public buildHierarchy(misGroups: MisGroup[], idMap: Record<number, MisGroup>, root: MisGroup[]): void {
    const codeMap: Record<string, MisGroup> = {};

    for (const group of misGroups) {
      codeMap[group.code] = group;
      idMap[group.id] = group;
      group.children = [];
    }

    for (const group of misGroups) {
      if (group.parentCode) {
        const parent = codeMap[group.parentCode];
        if (parent && parent.children) {
          parent.children.push(group);
        }
      } else {
        root.push(group);
      }
    }
  }

  public mapGroupToNode(misGroup: MisGroup): NxTreeNode<MisCategoryValue> {
    return {
      value: {
        id: misGroup.id,
        label: misGroup.name,
        code: misGroup.code,
        parentCode: misGroup.parentCode
      },
      children: misGroup.children ? misGroup.children.map(child => this.mapGroupToNode(child)) : []
    };
  }

}

export default new MisGroupService();