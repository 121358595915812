import nxModule from 'nxModule';

const templateUrl = require('./form-section.template.html');

nxModule.component('formSection', {
	templateUrl,
	transclude: true,
	bindings: {
	  formTitle: '<'
	}
});
