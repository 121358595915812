import {isFieldRequired, NxInputProps, useValidationSchema} from '@nextbank/ui-components';
import {useField, useFormikContext} from 'formik';
import {ReactElement} from 'react';
import FileUpload from 'tools/file-upload/FileUpload';

export type NxFormikFileUploadProps = Omit<NxInputProps, 'value'> & {
  name: string;
  value?: string[];
  allowDuplicates: boolean;
};

const NxFormikFileUpload = function(props: NxFormikFileUploadProps): ReactElement {
  const context = useFormikContext();
  const validationSchema = useValidationSchema();
  const [field,,helpers] = useField(props);

  return <>
    <FileUpload {...field}
                {...props}
                disabled={props.disabled || context.isSubmitting}
                required={props.required || isFieldRequired(validationSchema, field.name)}
                onFileUploaded={(uploadedFileIds: number[], allFileIds: number[]) : void => helpers.setValue(allFileIds, context.validateOnChange)}
                onFileDeleted={(): void => helpers.setValue(undefined, context.validateOnChange)}
                allowDuplicates={props.allowDuplicates}/>
  </>
}

export default NxFormikFileUpload;