import nxModule from 'nxModule';
import templateUrl from './new-account-list.template.html';
import _ from "lodash";
import {ActionType, Columns, Sorting} from "../../../common/dynamic-list/dynamic-list.component";
import {NxIFilterService} from "components/technical/angular-filters";
import {InterestBoardService} from "components/administration/casa/interest-board/interest-board.service.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {ILocationService} from "angular";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {NxRouteService} from 'routes/NxRouteService';
import {ProductService} from "components/customer/common/product.service";
import {AccountType} from "components/service/account.types";


class CustomerAccountCreateList {
  private accounts!: (AccountType & Partial<{
    productName: string;
    productTypeCode: string;
    productSubtype: string;
    interestRange: string;
    maintainingBalanceFormatted: string;
    open: () => void
  }>)[];
  private customerId!: string;
  private readonly columns: Columns = [
    {title: 'No'},
    {title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    {title: 'Product name', field: 'productName', sortable: 'productName'},
    {title: 'Product subtype', field: 'productSubtype', sortable: 'productSubtype'},
    {title: 'Interest rate', field: 'interestRange'},
    {title: 'Maintaining balance', field: 'maintainingBalanceFormatted'},
    {
      actions: [{
        access: 'CreateAccount',
        label: 'Open',
        field: 'open',
        type: ActionType.BUTTON
      }],
    },
  ];

  private readonly sorting: Sorting = {
    productTypeCode: 'asc',
    productName: 'asc',
    productSubtype: 'asc',
  };

  constructor(private $route: NxRouteService, private $location: ILocationService,
              private depositAccountTypeService: DepositAccountTypeService, private productService: ProductService,
              private customerCache: CustomerCache, private interestBoardService: InterestBoardService,
              private $filter: NxIFilterService) {
  }

  async $onInit() {
    this.customerId = this.$route.current.params['customerId'] as string;
    const {customerType} = await this.customerCache.profile(this.customerId).toPromise();

    const depositAccounts = await this.depositAccountTypeService.toPromise();
    this.accounts = depositAccounts.filter(da => {
      return this.productService.availableForSale(da)
        && this.productService.availableForCustomerType(da.productDefinition.customerTypeConstraints, customerType);
    });

    _.forEach(this.accounts, account => {
      account.productName = account.productDefinition.productName;
      account.productTypeCode = account.productDefinition.productTypeCode;
      account.productSubtype = this.$filter('prettyEnum')(account.accountSubtype!);
      account.interestRange = this.interestBoardService.formatInterestRateRangeForRateType(account.interestBoards);
      account.maintainingBalanceFormatted = this.$filter('nxCurrency')(account.maintainingBalance);
      account.open = () => {
        this.$location.path(`/customer/${this.customerId}/accounts/create/${account.id}`);
      }

    });
  }
}
nxModule.component('customerAccountCreateList', {
  templateUrl: templateUrl,
  controller: CustomerAccountCreateList
});
