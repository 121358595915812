import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./loan-cash-payment.template.html');
nxModule.component('customerLoansCashPayment', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, http, dict, customerCache, confirmation, notification, loanProductsCache, command,
                        accessRuleService, $filter, authentication) {
    let that = this;

    that.loan = null;
    that.paymentRequest = {};
    that.commandName = 'PayLoanByCash';

    let customerId = $route.current.params['customerId'];
    let loanId = $route.current.params['loanId'];

    accessRuleService.accessRuleForLoggedInUserRole(that.commandName).then(
        rule => that.canPerformCustomPayment = !rule.predicates['EQUALS'] || rule.predicates['EQUALS']['LOAN_CUSTOM_PAYMENT']
    );

    customerCache.loans(customerId).toObservable()
      .combineLatest(loanProductsCache.toObservable(), (loans, loanTypes) => {
        if (!loans) that.redirectBack();
        return loans.map(loan => {
          // add loanProduct to loan object
          const type = _.find(loanTypes, {id: loan.typeId});
          return Object.assign(loan, {
            loanType: type
          });
        });
      })
      .subscribe(loans => {
        that.loan = _.find(loans, (l) => l.id === Number(loanId));
      });


    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${loanId}`);

    that.pay = () => {
      confirmation(`Do you want to pay ${$filter('nxCurrency')(that.paymentRequest.amount)}?`, () => {
        command.execute(that.commandName, {
          productId: loanId,
          ...that.paymentRequest
        }, {nxLoaderText: 'Performing operation...'})
          .success(() => {
          customerCache.loans(customerId).refetch();
          authentication.permissions['CST_CREDIT_LINE_READ'] ?  customerCache.creditLines(customerId).refetch() : Promise.resolve();
          customerCache.depositAccounts(customerId).refetch();
          customerCache.loanPDCs(customerId, loanId).refetch();
          that.redirectBack()
        }, false)
          .offline(() => {
            customerCache.loans(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.print = () => {
      window.print();
    };

  }
});
