export const setBreadcrumbs = (breadcrumbs: unknown, key: unknown, value: unknown) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  breadcrumbs.options = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  ...breadcrumbs.options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  [key]: value,
};
