import {IAttributes, IController, INgModelController} from 'angular';
import {
  ComplianceRedFlagConfigType,
  RED_FLAG_TAG_TYPE
} from 'components/administration/compliance/compliance-red-flag-config-types';
import {ComplianceRedFlagConfigCache} from 'components/administration/compliance/service/compliance-red-flag-config.cache';
import {CustomerSubtype, CustomerType} from 'components/customer/profile/customer-profile.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import templateUrl from './compliance-non-commensurate-select.template.html';
import nxModule from 'nxModule';
import _ from 'lodash';

const NON_COMMENSURATE: RED_FLAG_TAG_TYPE = 'NON_COMMENSURATE';

class ComplianceNonCommensurateSelect implements IController {

  config?: number;
  ngModel!: INgModelController;
  private configs: ComplianceRedFlagConfigType[] = [];
  private customerType!: CustomerType;
  private customerSubtype!: CustomerSubtype | null;

  constructor(
    private readonly complianceRedFlagConfigCache: ComplianceRedFlagConfigCache,
    protected $attrs: IAttributes,
    private $filter: NxIFilterService
  ) {
  }

  async $onInit(): Promise<void> {
    if (this.ngModel) {
      this.ngModel.$render = (): void => {
        this.config = this.ngModel.$viewValue;
      };
    }
    await this.initConfigs();
  }

  async initConfigs(): Promise<void> {
    const allConfigs = await this.complianceRedFlagConfigCache.toPromise();
    const nonCommensurate = allConfigs.filter(c => c.redFlagTagType === NON_COMMENSURATE);
    //being passed as undefined in new customer template if not exists
    const customerSubtype = this.customerSubtype ?? null;
    this.configs = _.sortBy(nonCommensurate, 'minAmount')
      .filter(c => c.customerType === this.customerType && c.customerSubtype === customerSubtype);
  }

  formatLabel(c: ComplianceRedFlagConfigType): string {
    const min = Math.round(c.minAmount);
    if (c.maxAmount === null) {
      return `more than ${this.$filter('nxCurrency')(min)}`;
    }
    const max = Math.round(c.maxAmount || 0);
    if (min <= 0.01) {
      return `${this.$filter('nxCurrency')(max)} and below`;
    }
    return `more than ${this.$filter('nxCurrency')(min)} to ${this.$filter('nxCurrency')(max)}`;

  }

  valueChanged(): void {
    if (this.ngModel) {
      this.ngModel.$setViewValue(this.config);
    }
  }

  async $onChanges(): Promise<void> {
    await this.initConfigs();
  }
}

nxModule.component('complianceNonCommensurateSelect', {
  controller: ComplianceNonCommensurateSelect,
  require: {
    ngModel: '^ngModel'
  },
  bindings: {
    'customerType': '<',
    'customerSubtype': '<'
  },
  templateUrl
});
