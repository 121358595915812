import React, {ReactElement} from "react";
import BranchList, {BranchSummaryRow} from "check/check-preparation/BranchList";
import {NxTableColumn} from "@nextbank/ui-components";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";

const columns: NxTableColumn<BranchSummaryRow>[] = [{
  title: "No",
  field: "no",
}, {
  title: "Branch",
  field: "name",
}, {
  title: "No of assigned checks",
  field: "noOfAssignedChecks",
}];

const AssignedChecksBranchList = (): ReactElement => {
  return <NxPage>
    <NxHeader>
      Assigned Checks
    </NxHeader>
    <BranchList redirect={(selectedBranch : BranchSummaryRow) : string => {return `/check-preparation/assigned-checks/${selectedBranch.id}`}}
                columns={columns}/>
  </NxPage>
};

export default AssignedChecksBranchList;