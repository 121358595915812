import nxModule from 'nxModule';
import templateUrl from './customer-type-constraint.template.html'

class CustomerTypeConstraint {
  constructor() {
    this.customerTypes = [
      {
        label: 'Individual',
        value: 'INDIVIDUAL'
      },
      {
        label: 'Corporate',
        value: 'CORPORATE'
      }
    ];
    this.loanCustomerTypes = [
      ...this.customerTypes,
      {
        label: 'Group',
        value: 'GROUP'
      }
    ];
  }

  $onInit() {
    if (this.onCustomerTypeChange) {
      this.onChangeFunc = this.onCustomerTypeChange;
    } else {
      this.onChangeFunc = () => {};
    }
  }

  onChange() {
    this.onChangeFunc();
  }
}

nxModule.component('customerTypeConstraint', {
  templateUrl,
  bindings: {
    customerTypeConstraints: '=',
    onCustomerTypeChange: '=',
    productType: '<'
  },
  controller: CustomerTypeConstraint
});