import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./group-loan-information.template.html');
nxModule.component('groupLoanInformation', {
  templateUrl: templateUrl,
  bindings: {
    'loans': '<'
  },
  controller: function ($scope) {

    let that = this;

    $scope.$watchCollection('$ctrl.loans', () => {
      if (that.loans) {
        const selectedForReleaseLoans = that.loans.filter(l => l.forRelease);
        that.totalPrincipal = _.sumBy(selectedForReleaseLoans, it => it.principalBalance);
        that.totalInterest = _.sumBy(selectedForReleaseLoans, it => it.interestBalance);

        that.totalDocStamp = _.sumBy(selectedForReleaseLoans, it => it.docStamp);
        that.totalNotarialFee = _.sumBy(selectedForReleaseLoans, it => it.notarialFee);
        that.totalApplicationFee = _.sumBy(selectedForReleaseLoans, it => it.applicationFee);
        that.totalCreditInvestigationFee = _.sumBy(selectedForReleaseLoans, it => it.creditInvestigationFee);
        that.totalExtraBankFee = _.sumBy(selectedForReleaseLoans, it => it.extraBankFee);
        that.totalServiceCharge = _.sumBy(selectedForReleaseLoans, it => it.serviceCharge);
        that.totalMembershipFee = _.sumBy(selectedForReleaseLoans, it => it.membershipFee);
        that.totalIdFee = _.sumBy(selectedForReleaseLoans, it => it.idFee);
        that.totalInsuranceFee = _.sumBy(selectedForReleaseLoans, it => it.insuranceFee);
        that.totalInsuranceServiceFee = _.sumBy(selectedForReleaseLoans, it => it.insuranceServiceFee);
        that.totalInsuranceProcessingFee = _.sumBy(selectedForReleaseLoans, it => it.insuranceProcessingFee);
      }
    });

    that.checkAll = () => {
      if (that.loans) {
        that.loans.forEach(l => l.forRelease = true);
      }
    };

    that.uncheckAll = () => {
      if (that.loans) {
        that.loans.forEach(l => l.forRelease = false);
      }
    };

    that.handleCheckAll = (event) => {
      const isChecked = event.target.checked;
      if (isChecked) {
        that.checkAll();
      } else {
        that.uncheckAll();
      }
    };

    that.allSelected = () => {
      return that.loans && that.loans.every(l => l.forRelease);
    }
  }
});