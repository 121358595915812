import nxModule from 'nxModule';
import templateUrl from './check-list-wrapper.template.html';
import {Column, ColumnAlignment, PageSettings, Record} from '../../../common/selectable-list/selectable-list.component';
import {PageResult} from "tools/HttpTypes";
import Authentication from "shared/utils/authentication";
import {NxIFilterService} from "components/technical/angular-filters";
import {HttpService} from "shared/utils/httpService";
import {IncomingCheck} from "components/service/check.type";

interface CheckView extends Record {
  id: number;
  number: string;
  micrNumber: string;
  amountFormatted: string;
  receivedOnTimestampFormatted: string;
  isLateCheck: string;
  estimatedClearingDateFormatted: string;
}

class CheckListWrapper {

  // binding
  onCheckChange!: (checks: unknown) => void;

  private readonly branchId: number;
  private readonly userId: number;
  private readonly StatusTransfer = {
    TRANSFERRED_TO_CASHIER: 'TRANSFERRED_TO_CASHIER',
    TRANSFERRED_TO_TELLER: 'TRANSFERRED_TO_TELLER'
  };

  private transferStatus!: string;

  columns: Column<CheckView>[] = [
    new Column('Number', 'number'),
    new Column('MICR', 'micrNumber'),
    new Column('Amount', 'amountFormatted', ColumnAlignment.RIGHT),
    new Column('Received on', 'receivedOnTimestampFormatted'),
    new Column('Late check', 'isLateCheck'),
    new Column('Estimated clearing date', 'estimatedClearingDateFormatted'),
  ];

  constructor(private http: HttpService,
              private $filter: NxIFilterService,
              authentication: Authentication) {
    this.branchId = authentication.context.branchId;
    this.userId = authentication.context.id;
  }

  async getChecksOnCurrentPage(pageSettings: PageSettings): Promise<PageResult<CheckView>> {
    const params = this.getQueryParams(pageSettings);
    const pageResponse = await this.http.get<PageResult<IncomingCheck>>('/checks/incoming/paged', { params }).toPromise();
    const result = pageResponse.result.map(check => {
      const isLateCheck: boolean = check.status === 'PENDING_CUTOFF';
      return {
        ...check,
        isLateCheck: this.$filter('prettyBoolean')(isLateCheck),
        amountFormatted: this.$filter('nxCurrency')(check.amount) + (isLateCheck ? ' (0 in terms of COCI)' : ''),
        receivedOnTimestampFormatted: this.$filter('prettyDateTime')(check.receivedOnTimestamp),
        estimatedClearingDateFormatted: this.$filter('prettyDate')(check.estimatedClearingDate)
      }
    });
    return {
      ...pageResponse,
      result,
    };
  }

  getQueryParams(pageSettings: PageSettings) {
    const params = {
      transferStatus: this.transferStatus,
      pageSize: pageSettings.pageSize,
      pageNo: pageSettings.pageNo
    };

    switch(this.transferStatus) {
      case this.StatusTransfer.TRANSFERRED_TO_CASHIER:
        return {
          branchId: this.branchId,
          ...params,
        };
      case this.StatusTransfer.TRANSFERRED_TO_TELLER:
        return {
          heldBy: this.userId,
          ...params,
        };
      default:
        console.error('No transfer status provided!');
    }

    return params;
  }
}

nxModule.component('checkListWrapper', {
  bindings: {
    transferStatus: '<',
    onCheckChange: '&'
  },
  templateUrl,
  controller: CheckListWrapper
});