import RouteDefinition from "routes/RouteDefinition";
import EncashCashierCheck
    from "misc-transaction/encash-cashiers-check/EncashCashierCheck";
import React, {ReactElement} from "react";

const EncashCashierCheckComponent = (): ReactElement => <EncashCashierCheck isInterBranch={false}/>;
const InterbranchEncashCashierCheckComponent = (): ReactElement => <EncashCashierCheck isInterBranch={true}/>;


const dashboardPath = '/dashboard';
const miscTransactionsPath = `${dashboardPath}/miscellaneous-transactions`;
const routes: RouteDefinition[] = [
    {
        name: 'Transactions',
        path: miscTransactionsPath,
    }, {
        name: 'ENCASH CASHIER\'S CHECK',
        path: `${miscTransactionsPath}/encash-cashiers-check`,
        Component: EncashCashierCheckComponent
    },
    {
        name: 'ENCASH INTERBRANCH CASHIER\'S CHECK',
        path: `${miscTransactionsPath}/interbranch-encash-cashiers-check`,
        Component: InterbranchEncashCashierCheckComponent
    }
];

export default routes;