import nxModule from 'nxModule';

import templateUrl from './loan-transaction-history.template.html';
import {NgTableParams} from 'ng-table';
import _ from 'lodash';
import {CheckClearingGroup} from 'components/service/check.type';

class LoanTransactionHistory {

  constructor($filter, http, userCache, branchService, branchWorkingDayCache, operationService) {
    this.$filter = $filter;
    this.http = http;
    this.userCache = userCache;
    this.branchService = branchService;
    this.branchWorkingDayCache = branchWorkingDayCache;
    this.operationService = operationService;
  }

  async $onInit() {
    if (this.loan) {
      this.initConfig();
    }
  }

  async initOperationCheckMap() {
    const [operations, productChecks] = await Promise.all([
      this.operationService.fetchOperationsByCriteria({
        params: {
          productIds: [this.loan.id],
          orderByTransactionId: true,
          attribute: ['INITIAL_CLEARING_DATE']
        }
      }),
      this.http.get("/checks/incoming", {
        params: {
          productId: this.loan.id,
          clearingGroup: [CheckClearingGroup.OUTWARD]
        }
      }).toPromise()]);

    const operationCheckMap = new Map(operations.result.map(operation => {
      const operationCheck = productChecks.find(check => check.id = operation.checkId);
      return [operation.id, operationCheck]
    }));

    this.loan.operationCheckMap = operationCheckMap ? operationCheckMap : new Map();
  }

  initConfig() {
    this.tableConfig = new NgTableParams({
      count: 15,
    }, {
      // Values in 'counts' would allow users to change number of entries per page, but button style doesn't match page
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async params => {
        const response = await this.http.get(`/products/loans/${this.loan.id}/history`, {
          params: {
            pageNo: params.page() - 1,
            pageSize: params.count()
          }
        }).toPromise();
        params.total(response.totalCount);

        const data = _.forEach(response.result,
          entry => entry.details = this.getDetails(entry)
        );
        await this.setAsyncDetails(data);
        return data;
        }
    });
  }

  getDetails(item) {
    const res = [];
    if (item.operationIds) res.push({name: "Operation ids", value: item.operationIds.join(', ')});
    if (item.commandId) res.push({name: "Command id", value: item.commandId});
    if (item.amount !== 0) res.push({name: "Amount", value: this.$filter('nxCurrency')(item.amount)});
    res.push({name: "Status", value: this.$filter('prettyEnum')(item.status)});

    for (const key in item.attributes) {
      let value = item.attributes[key];

      if (key === 'COLLECTION_TIME') {
        res.push({
          name: this.$filter('prettyEnum')(key),
          value: this.$filter('prettyDateTime')(value)
        });
      } else {
        res.push({name: key, value: value});
      }

      if(key === 'INITIAL_CLEARING_DATE'){
        item.operationIds.forEach(operationId => {
          const incomingCheck = this.loan.operationCheckMap?.get(operationId);
          if(incomingCheck && incomingCheck.estimatedClearingDate){
            res.push({
              name: this.$filter('prettyEnum')('ESTIMATED_CLEARING_DATE'),
              value: this.$filter('prettyEnum')(incomingCheck.estimatedClearingDate)
            });
          }
        })
      }
    }
    return res;
  }

  async setAsyncDetails(data) {
    // user & branch name
    const users = await this.userCache.withParam(true).toPromise();
    const branches = await this.branchService.toPromise();

    data.forEach((item) => {
      if (item.registrationUserId) {
        const user = _.find(users, u => u.id === item.registrationUserId);
        item.details.push({name: "Registered by", value: user.effectiveName});
      }

      if (item.registrationBranchId) {
        const branch = _.find(branches, b => b.id === item.registrationBranchId);
        item.details.push({name: "Registered in branch", value: branch.name});
      }
      return _.sortBy(item.details, r => r.name);
    });
  }

  rowClicked(entry, $event) {
    $event.stopPropagation();
    this.selectedEntryId = entry.id;
    this.setItem(entry);
  };

  onInlinePannelHidden() {
    this.selectedEntryId = null;
  };

  async setItem(entry) {
    this.item = null;
    entry.branchWorkingDay = await this.branchWorkingDayCache.withParam(entry.registrationBranchId).toPromise();
    this.item = entry;
  }

  async $onChanges(changes) {
    if (changes.hasOwnProperty('loan') && this.loan) {
      if(!this.loan.operationCheckMap){
        await this.initOperationCheckMap();
      }
      this.initConfig();
    }
  };
}

nxModule.component('loanTransactionHistory', {
  templateUrl,
  bindings: {
    loan: '<',
    item: '='
  },
  transclude: true,
  controller: LoanTransactionHistory
});
