export default (predicateValue, predicateContext, authentication) => {
  const userBranch = authentication.context.branchId;

  if(!predicateContext.branchId) {
    console.warn('Missing predicate context for', 'BRANCH_ACCESS_MODE');
    return true;
  }

  const branchId = predicateContext.branchId;
  if(predicateValue['BRANCH_ID'] === 'MOTHER_BRANCH') {
    return userBranch === branchId;
  } else if(predicateValue['BRANCH_ID'] === 'INTER_BRANCH') {
    return userBranch !== branchId;
  } else {
    console.warn('Unknown BRANCH_ACCESS_MODE predicate value', predicateValue);
  }

  return true;
};