import {
  NxButton,
  NxButtonVariant,
  NxCashInput,
  NxFormik,
  NxFormikSubmitButton,NxFormikCashInput,
  NxFormikDatePicker,
  NxFormikMaskedInput,
  NxInput,
  NxLoader,
  NxPopup,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxStack,
  NxTable,
  NxTableColumn
} from '@nextbank/ui-components';
import {NxTableRef} from '@nextbank/ui-components/dist/nxTable/NxTableTypes';
import styles from 'account/BatchCreditOnsCheck.scss';
import useAxios from 'axios-hooks';
import BigNumber from 'bignumber.js';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {Account, ProductDefinition} from 'components/service/product.types';
import NxForm from 'form/NxForm';
import NxHeader, {HeaderVariant} from 'form/NxHeader';
import NxPage from 'form/NxPage';
import BranchService from 'management/BranchService';
import NxCancelButton from 'NxCancelButton';
import React, {MutableRefObject, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import notificationService from 'tools/notificationService';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';

interface BatchCreditOnUsCheckFormInput {
    micrNumber?: string;
    validFrom: string;
    amount?: number;
}

interface BatchCreditOnUsCheckInput {
    onUsChecks: OnUsCheck[];
}

interface OnUsCheck {
    productId: number;
    micrNumber: string;
    validFrom: string;
    amount: number;
}

const columns: NxTableColumn<OnUsCheck>[] = [{
    title: "MICR Number",
    field: "micrNumber",
}, {
    title: "Valid from",
    field: "validFrom",
}, {
    title: "Amount",
    field: "amount",
}];

const removeMask = (micr: string, mask: string) : string => {
    let unmaskedValue = '';
    for(let ctr = 0; ctr < micr?.length; ctr++) {
        const isValueChar = mask[ctr] === '9' || mask[ctr] === 'a' || mask[ctr] === '*'
        if(isValueChar) {
            unmaskedValue += micr[ctr];
        }
    }
    return unmaskedValue;
};

const isCheckAlreadyAdded = (micr : string, onUsChecks: OnUsCheck[], mask: string) : boolean => {
    return onUsChecks.findIndex(check => check.micrNumber === removeMask(micr, mask)) >= 0;
}

const branchService = new BranchService();
const BatchCreditOnUsChecks = () : ReactElement => {
    const {accountId} = useParams<{accountId: string}>();
    const execute = useCommand();
    const history = useHistory();
    const [{data: allProductDefinitions}] = useAxios<ProductDefinition[]>(`/products/definitions`);
    const [{data: account}] = useAxios<Account>(`/products/accounts/${accountId}`);
    const [onUsChecks, setOnUsChecks] = useState<OnUsCheck[]>([]);
    const [isCrediting, setIsCrediting] = useState<boolean>(false);
    const ref : MutableRefObject<NxTableRef | null> = useRef<NxTableRef | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [branchSystemDate, setBranchSystemDate] = useState<string>();

    const initialize = async (): Promise<void> => {
        const currentBranch = await branchService.readCurrentBranch();
        setBranchSystemDate(currentBranch.systemDate);
    };
    useEffect(() => {
        initialize();
    }, []);

    const productDefinition = useMemo(() => {
        if(!allProductDefinitions || !account) {
            return undefined;
        }
        return allProductDefinitions.find((p : ProductDefinition) => p.id === account.definitionId);
    }, [allProductDefinitions, account]);

    const header = <NxHeader>Multiple credit on-us check</NxHeader>;
    if(!account || !allProductDefinitions || !branchSystemDate || !productDefinition) {
        return <NxPage>{header}<NxLoader/></NxPage>;
    }
    const RemoveCheck = ({data} : {data: OnUsCheck}) : ReactElement =>
    <NxButton variant={NxButtonVariant.DELETE}
              onClick={(): void => {
                setOnUsChecks(onUsChecks.filter(check => check.micrNumber !== data.micrNumber));
                ref.current?.onQueryChange();
              }}>
        Remove
    </NxButton>

    const BatchCreditOnUsCheckSchema: SchemaOf<BatchCreditOnUsCheckFormInput> = Yup.object().shape({
        micrNumber : Yup.string().matches(/^⑈[0-9]{10}⑈[0-9]{5}⑉[0-9]{4}⑆[0-9]{12}⑈$/, 'Is not a valid MICR number')
                .required('MICR Number is required'),
        validFrom : Yup.string().required('Valid from is required'),
        amount: Yup.number().required('Amount is required')
                .positive('Check amount should be greater than 0')
                .typeError('Please provide a valid amount')
    });

    const mask = '⑈9999999999⑈99999⑉9999⑆999999999999⑈';

    return <NxPage>
        {header}
        <NxStack>
            <div className={styles.infoRow}>
                <NxRow>
                    <NxInput disabled label="Product name" value={productDefinition.productName}/>
                    <NxCashInput disabled label="Available balance" value={account.balance}/>
                </NxRow>
            </div>

            <NxHeader variant={HeaderVariant.H3}>Check information</NxHeader>
            <NxFormik<BatchCreditOnUsCheckFormInput>
                    initialValues={{
                        validFrom: branchSystemDate
                    }}
                    validateOnChange={false}
                    validationSchema={BatchCreditOnUsCheckSchema}
                    onSubmit={(values : BatchCreditOnUsCheckFormInput, {setValues, setSubmitting}) : void => {
                        if (!values.micrNumber || !values.validFrom || !values.amount) {
                            return;
                        }
                        const micrNumber = removeMask(values.micrNumber, mask);
                        if (isCheckAlreadyAdded(values.micrNumber, onUsChecks, mask)) {
                            notificationService({
                                text: `MICR number: ${micrNumber} has already been added`
                            });
                            return;
                        }

                        onUsChecks.push({
                            productId: Number(accountId),
                            micrNumber: micrNumber,
                            validFrom: values.validFrom,
                            amount: values.amount
                        });

                        setValues({
                            micrNumber: '',
                            validFrom: branchSystemDate
                        }, false);

                        ref.current?.onQueryChange();
                        setSubmitting(false);
                    }}>
                {(): ReactElement => {
                    return <NxForm>
                        <NxStack>
                            <NxFormikMaskedInput name='micrNumber'
                                                 label={"MICR number"}
                                                 mask={mask}
                                                 alwaysShowMask={false}
                            />
                            <NxFormikDatePicker label='Valid from' name='validFrom'/>
                            <NxFormikCashInput label='Amount' name='amount'/>
                            <NxRow position={NxRowPosition.END}>
                                <NxFormikSubmitButton variant={NxButtonVariant.ADD}>
                                    Add
                                </NxFormikSubmitButton>
                            </NxRow>
                        </NxStack>
                    </NxForm>
                    }
                }
            </NxFormik>
        </NxStack>
        <NxTable<OnUsCheck> columns={columns}
                            data={async (query: NxQuery) : Promise<NxQueryResult<OnUsCheck>> => {
                                const startingIndex = query.page * query.pageSize;
                                const result = onUsChecks.slice(startingIndex, startingIndex + query.pageSize);
                                return Promise.resolve({
                                    result: result,
                                    pageNo:  query.page,
                                    pageSize: query.pageSize,
                                    resultCount: result.length,
                                    totalCount: onUsChecks.length
                                });
                            }}
                            ref={ref}
                            rowActions={[RemoveCheck]}
        />
        <NxRow position={NxRowPosition.END}>
          <NxCancelButton />
          <NxButton variant={NxButtonVariant.SAVE}
                    onClick={(): void => setShowPopup(true)}
                    disabled={isCrediting}
                    loaded={!isCrediting}>
              Credit checks
          </NxButton>
        </NxRow>
        <NxPopup header='Confirm'
                 open={showPopup}
                 description={createConfirmationMessage(onUsChecks)}>
            <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={() : void => setShowPopup(false)}>
                    No
                </NxButton>
                <NxButton
                        variant={NxButtonVariant.SAVE}
                        disabled={isCrediting || onUsChecks.length < 1}
                        loaded={!isCrediting}
                        onClick={async (): Promise<void> => {
                            setShowPopup(false);
                            setIsCrediting(true);
                            try {
                                const response: CommandOutputWrapper<void> = await execute<BatchCreditOnUsCheckInput, void>({
                                    name:'BatchCreditOnUsCheck',
                                    input: {
                                        onUsChecks: onUsChecks
                                    }});

                                if(!response.approvalRequired) {
                                    history.goBack();
                                }
                            } finally {
                                setIsCrediting(false);
                            }
                        }}>
                    Yes
                </NxButton>
            </NxRow>
        </NxPopup>
    </NxPage>;
};

const createConfirmationMessage = (onUsChecks: OnUsCheck[]): string => {
    const totalAmount = onUsChecks.map(check => new BigNumber(check.amount))
                    .reduce((a : BigNumber, b : BigNumber) => a.plus(b), new BigNumber(0));
    return `Do you want to credit ${onUsChecks.length} check(s) of total ${totalAmount} PHP?`;
};

export default BatchCreditOnUsChecks;