import {NxIFilterService} from 'components/technical/angular-filters';
import {memoize, sortBy, startCase, upperFirst} from 'lodash/fp';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';
import templateUrl from './verify-pesonet-transaction.template.html';

interface ExternalTransferDetails {
  status: string;
  registrationTime: string;
  settlementTime: string;
  amount: number;
  remarks: string;
  senderAccountNumber: string;
  senderCustomerEffectiveName: string;
  attributes: Record<string, unknown>;
  referenceNumber: string;
  pesonetStatus: string;
}

class VerifyPesonetTransaction {
  protected transactionReference: string = '';
  protected transaction: ExternalTransferDetails | null = null;


  constructor(
    private http: HttpService,
    private popup: Popup,
    private notification: Notification,
    private $filter: NxIFilterService,
    private command: CommandService,
    private $route: NxRouteService,
    private confirmationTemplate: ConfirmationTemplate
  ) { }

  getDetails = memoize((transaction: ExternalTransferDetails | null): [string, unknown][] => {
    if(!transaction) {
      return [];
    }

    const getEntries = (obj: Record<string, unknown> | ExternalTransferDetails): [string, unknown][] => {
      const keys = Object.keys(obj);
      keys.sort();

      return sortBy(
        el => el[0],
        Object.entries(obj)
          // change object keys from UPPER_CASE format to Upper case.
          // Separate words by dash, lowercase everything except for the first letter
          .map((el: [string, unknown]): [string, unknown] => [upperFirst(startCase(el[0]).toLowerCase()), el[1]])
      );
    };

    const result: [string, unknown][] = [];
    for(const [key, value] of getEntries(transaction)) {
      if(key === 'Attributes') {
        for(const [attr, attrValue] of getEntries(transaction.attributes)) {
          result.push([this.$filter('prettyEnum')(attr), attrValue]);
        }

        continue;
      }

      if(key.endsWith(' time')) {
        result.push([key, this.$filter('prettyDateTime')(value as string)]);
        continue;
      }

      if(key === 'Amount') {
        result.push([key, this.$filter('nxCurrency')(value as number)]);
        continue;
      }

      if(key === 'Status') {
        result.push(['CBS status', value]);
        continue;
      }

      result.push([key, value]);
    }

    return result.map(row => row[1] ? row : [row[0], '<empty>']);
  });

  clearTransaction(): void {
    this.transaction = null;
  }

  async readTransaction(): Promise<void> {
    const reference = this.transactionReference;
    try {
      this.transaction = await this.http.get<ExternalTransferDetails>(`/external-transfer/outgoing/search`, {
        params: {
          channel: 'PESONET',
          referenceNumber: reference
        }
      }).toPromise();
    } catch (e) {
      if(e.errorCode === 'NOT_FOUND_BY_SECONDARY_KEY') {
        this.popup({
          header: 'Error',
          text: `Transaction with reference code '${reference}' doesn't exist`
        });
      } else {
        this.notification.show('Error', e.errorMessage);
      }
    }
  }

  async processPesonetUpdate(): Promise<void> {
    const tran = this.transaction;
    if(!tran) {
      return;
    }

    const confirmed = await this.confirmationTemplate({
      question: `You are about to update the following PESONet transaction: ${tran.referenceNumber}.Do you wish to proceed?`
    });

    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('UpdatePesonetOutwardTransactionStatus', {
      referenceNumber: tran.referenceNumber
    }).toPromise();

    if (!response.approvalRequired) {
      this.$route.reload();
    }
  }

}

nxModule.component('verifyPesonetTransaction', {
  templateUrl,
  controller: VerifyPesonetTransaction
});
