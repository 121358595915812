import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from 'system/systemPropertyService';

const templateUrl = require('./post-outgoing-check.template.html');

nxModule.component('postOutgoingCheck', {
  templateUrl: templateUrl,
  controller: function ($filter, $location, command, confirmationTemplate, depositoryAccountCache, branchService, authentication) {

    const that = this;
    that.accounts = [];
    that.depositoryAccountId = undefined;
    that.input = {};
    that.isPchcDirectClearingEnabled = systemPropertyService.getProperty("PCHC_DIRECT_CLEARING_MEMBER") === 'TRUE';

    that.accountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    that.$onInit = async () => {
      const [depositoryAccounts, branches] = await Promise.all([
        depositoryAccountCache.toPromise(),
        branchService.toPromise()
      ]);
      that.accounts = depositoryAccounts.filter(depositoryAccount => depositoryAccount.clearing);
      that.branches = branches.filter(b => authentication.context.branchIds.includes(b.id));
      that.selectedBranch = that.branches.find(b => b.id === authentication.context.branchId);
      that.onBranchChange();
    }

    that.onAccountChange = () => {
      // Reset account-related input properties
      that.input.brstn = null;
      that.input.accountNumber = null;
      // Find depository account & setup properties
      const account = _.find(that.accounts, a => Number(a.id) === Number(that.input.depositoryAccountId));
      if (account) {
        that.input.brstn = account.brstn;
        that.input.accountNumber = account.accountNumber;
      }
    };

    that.onBranchChange = () => {
      that.input.brstn = that.selectedBranch.brstn;
      that.isBrstnValid();
    }

    that.isBrstnInvalid = () => {
      return that.isPchcDirectClearingEnabled && that.selectedBranch && !that.input.brstn;
    }

    that.redirectBack = () => $location.path('/inventory/checks');

    that.postCheck = () => {
      confirmationTemplate({
        question: `Do you want to post the manual inward check?`,
        details: [
          {label: 'Check number', description: that.input.checkNumber},
          {label: 'BRSTN', description: that.input.brstn},
          {label: 'Account number', description: that.input.accountNumber},
          {label: 'Amount', description: $filter('nxCurrency')(that.input.amount)}
        ],
        yesCallback: () => {
          command.execute('RegisterInwardCheck', that.input).success(() => {
            that.redirectBack()
          })
        }
      });
    };
  }
});