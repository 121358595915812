import templateUrl from './delete-loan-confirmation.template.html';
import nxModule from 'nxModule';
import {ModalApi} from "components/technical/modal/modal.component";

export interface Api {
  show: () => Promise<{
      confirmed: boolean,
      remarks?: string
  }>
}

class DeleteLoanConfirmation {
  remarks: string = '';
  onDeleteLoanConfirmationReady!: (api: Api) => void;
  onModalApiReady = ({api,}: {api: ModalApi}) => {
    this.onDeleteLoanConfirmationReady({
      show: async () => {
        this.remarks = '';
        const {accepted} = await api.show();

        return {
          confirmed: accepted,
          remarks: this.remarks
        }
      }
    });
  }
}

nxModule.component('deleteLoanConfirmation', {
  templateUrl,
  bindings: {
    onDeleteLoanConfirmationReady: '<',
  },
  controller: DeleteLoanConfirmation
});