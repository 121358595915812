import nxModule from 'nxModule';

import templateUrl from './dynamic-report-list.template.html';


class DynamicReportList {
  constructor(dynamicReportService, confirmation, command, $location) {
    this.dynamicReportService = dynamicReportService;
    this.confirmation = confirmation;
    this.command = command;
    this.$location = $location;

    this.fetchReports();
  }

  async fetchReports() {
    this.reports = await this.dynamicReportService.getReports();
  }

  addNew() {
    this.$location.path('/admin/dynamic-report/create');
  }

  edit(report) {
    this.$location.path(`/admin/dynamic-report/${report.id}`);
  }

  async delete(report) {
    const actionApproved = await this.confirmation(`Do you want to remove report ${report.name}?`);
    if(!actionApproved) {
      return;
    }

    await this.command.execute('DeleteDynamicReport', {id: report.id}).toPromise();
    this.fetchReports();
  }
}

nxModule.component('dynamicReportList', {
  templateUrl,
  controller: DynamicReportList
});