import {ILocationService} from 'angular';

import nxModule from 'nxModule';
import 'rxjs/add/operator/map';
import './mis-group-list.style.less';

import templateUrl from './mis-group-list.template.html';

interface MisGroup {
  id: number;
  name: string;
  code: string;
  parentCode: string | null;
}

interface MisGroupView {
  name: string;
  code: string;
  fullCode: string;
  depth: number;
}

class MisGroupList {
  private misGroupView: MisGroupView[] = [];

  constructor(private misGroupsCache: unknown, private $location: ILocationService) {
  }

  async $onInit(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const misGroups: MisGroup[] = await this.misGroupsCache.toPromise();
    const misGroupCodeMap = new Map<string, MisGroup>();
    for(const misGroup of misGroups) {
      misGroupCodeMap.set(misGroup.code, misGroup);
    }

    const misGroupView = [];
    for(const misGroup of misGroups) {
      misGroupView.push(this.resolveGroup(misGroup, misGroupCodeMap));
    }

    this.misGroupView = misGroupView;
  }

  private resolveGroup(misGroup: MisGroup, misGroupCodes: Map<string, MisGroup>): MisGroupView {
    const fullCode = [];
    let tmp = misGroup;

    while(tmp) {
      fullCode.push(tmp.code);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tmp = misGroupCodes.get(tmp.parentCode);
    }

    return {
      name: misGroup.name,
      code: misGroup.code,
      fullCode: fullCode.reverse().join("/"),
      depth: fullCode.length
    };
  }

  private goBack(): void {
    this.$location.path('/admin/loan-products');
  }
}

nxModule.component('misGroupList', {
  templateUrl,
  controller: MisGroupList
});
