import nxModule from 'nxModule';
import _ from 'lodash';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

const templateUrl = require('./roles-config.template.html');
nxModule.component('rolesConfig', {
  templateUrl,
  controller: function ($scope, $timeout, $route, http, rolesCache, breadcrumbs, accessRuleCache, commandRoleMapCache, notification, confirmation, command, permissionService, permissionCache, authentication) {
    const that = this;

    that.$onInit = () => {
      that.roleId = parseInt($route.current.params['roleId']);
      that.rolePermissions = [];
      that.roleAccessRules = [];
      that.roleAccessRulesToDelete = [];

      rolesCache.toObservable().first().subscribe(roles => {
        const nonTechnicalRoles = _.filter(roles, r => !r.technical);

        that.role = _.find(nonTechnicalRoles, role => role.id === that.roleId);
        that.roles = nonTechnicalRoles;
      });

      if (that.roleId && that.role) {
        setBreadcrumbs(breadcrumbs, 'role', that.role.name);
      }
    };

    that.save = () => {
      const refreshRolesAndPermissions = () => {
        rolesCache.refetch();
        permissionService.evict();
        permissionCache.refetch();
        window.location.reload();
      };


      confirmation('Do you want to save new configuration?', () => {
        command.execute('UpdateRolePermissions', {
          roleId: that.role.id,
          permissionIds: that.rolePermissions
        }).success(() => {
          authentication.refresh(refreshRolesAndPermissions, refreshRolesAndPermissions);
        });
      });
    };

    that.saveAccessRules = () => {
      confirmation('Do you want to save new configuration?', () => {
        http.post('/access', {
          accessRules: that.roleAccessRules,
          deleteRuleIds: that.roleAccessRulesToDelete
        }).success(res => {
          accessRuleCache.evict();
          commandRoleMapCache.refetch();
          notification.show('Actions updated successfully');
          $route.reload();
        });
      });
    }
  }
});
