import nxModule from 'nxModule';
import $ from 'jquery'
import _ from 'lodash';

import './local-override.style.less';

const templateUrl = require('./local-override.template.html');

nxModule.component('localOverride', {
  templateUrl,
  bindings: {
    task: '=',
    onPinEntered: '&',
  },
  controller: function ($scope, $timeout, rolesCache, userCache, authentication) {

    const that = this;

    // List of role definitions
    that.roles = [];
    // List of all available users
    that.users = [];
    // List of users authorized to approve/reject task
    that.authorizedUsers = [];
    // Flag defining weather task approval user is known
    that.pinEnabled = false;
    // User selected to be task approver
    that.selectedUser = null;

    /**
     * To approve/reject task user must meet criteria below:
     * 1. User branch matches task registration user branch
     * 2a. For taskGroup != 'COMMAND_APPROVAL' user has permission required for task action
     * 2b. For taskGroup == 'COMMAND_APPROVAL' user's roles have approve access rule.
     * 3. User is not a registration user
     * 4. User is not blocked or deleted
     */
    const usersSub = userCache.withParam().toObservable().subscribe(users => {
      that.users = users.filter(u => u.enabled && !u.isDeleted);
    });

    /**
     * User roles must be fetched to perform permission check
     */
    const rolesSub = rolesCache.toObservable().subscribe(roles => that.roles = roles);

    const hasTaskPermission = (user, task) => {
      // If user or task is null -> return false
      if (!user || !task || !that.roles) return false;
      if (task.taskGroup === 'COMMAND_APPROVAL') {
        // For command approval check if user has approve role id
        return task.approveUserIds && task.approveUserIds[that.task.localOverrideLevel || 0].indexOf(user.id) !== -1;
      }

      console.error('Unhandled legacy task group:', task.taskGroup);
      return false;
    };

    const getFullName = (user) => `${user.firstName} ${user.middleName} ${user.lastName}`;

    const getAuthorizedUsers = (task) => {
      // Search for authorized users only if task is given
      if (task && task.registrationUserId) {
        // Get data of user who created task
        const regUser = _.find(that.users, {id: task.registrationUserId});
        // If registration user is null -> stop filtering
        if (regUser) {
          // Filter branch users (excluding task creator)
          return _.filter(that.users, (u) => {
            // Filter user from matching branch (excluding task creator)
            const branchUser = u.id !== regUser.id && u.branchId === regUser.branchId
              && !u.technical && authentication.context.id !== u.id;
            // Check if user has required permission
            return branchUser && hasTaskPermission(u, task);
          }).map((u) => Object.assign(u, {fullName: getFullName(u)}));
        }
      }
    };

    that.updatePinAvailability = () => {
      that.pinEnabled = that.task && that.task.approvalUserId || that.selectedUser;
    };

    this.resetForm = () => {
      this.field1 = null;
      this.field2 = null;
      this.field3 = null;
      this.field4 = null;
    };

    this.goToNextField = (currentModel, nextField) => {
      if (currentModel.length === 1) {
        $(nextField).focus();
      }
    };

    this.closeDialog = (event) => {
      if (event.keyCode === 27) {
        this.close();
      }
    };

    this.close = () => {
      this.task = null;
      this.resetForm();
    };

    this.save = () => {
      that.onPinEntered({
        pin: this.field1 + this.field2 + this.field3 + this.field4,
        task: that.task,
        user: that.selectedUser
      });

      this.resetForm();
      this.task = null;
      that.selectedUser = null;
      that.authorizedUsers = [];
      that.pinEnabled = false;
    };

    $scope.$watch('$ctrl.task', () => {
      if (that.task) {
        const approvalUserId = that.task.approvalUserId;

        // If [task.approvalUserId] is not set -> get users authorized to approve task
        // Otherwise set [selectedUser] to user identified by [task.approvalUserId]
        if (!approvalUserId) {
          that.authorizedUsers = getAuthorizedUsers(that.task);
        } else {
          that.selectedUser = _.find(that.users, {id: approvalUserId});
        }
        that.updatePinAvailability();
        // Automatically focus on 1st PIN field
        $timeout(() => $('#pinField1').focus());
      }
    });

    that.$onDestroy = () => {
      usersSub.unsubscribe();
      rolesSub.unsubscribe();
    }
  }
});
