import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './customer-credit-line-borrowers.template.html';
import {sum} from "../../../../shared/common/MathUtils";
import {NgTableParams} from 'ng-table';
import './customer-credit-line-borrowers.style.less';

class CustomerCreditLineBorrowers {

  constructor($route, $location, http, customerCache, confirmationTemplate, customerService, command, popup, notification) {
    this.$route = $route;
    this.$location = $location;
    this.http = http;
    this.customerCache = customerCache;
    this.confirmationTemplate = confirmationTemplate;
    this.customerService = customerService;
    this.command = command;
    this.popup = popup;
    this.notification = notification;
  }

  async $onInit() {
    this.customerId = Number(this.$route.current.params['customerId']);
    this.creditLineId = Number(this.$route.current.params['creditLineId']);

    const creditLines = await this.customerCache.creditLines(this.customerId).toPromise();
    this.creditLine = creditLines.find(cl => cl.id === this.creditLineId);
    this.creditLine.loans = await this.fetchLoans();

    this.allowedBorrowers = await this.fetchAllowedBorrowers();
    this.existingBorrowers = _.cloneDeep(this.allowedBorrowers);
    this.existingBorrowerIds = this.allowedBorrowers.map(b => _.clone(b.id));

    this.setupBorrowersData();
  }

  fetchAllowedBorrowers() {
    return this.http.get(`/credit-lines/${this.creditLineId}/borrowers`).toPromise();
  }

  fetchLoans() {
    return this.http.get(`/credit-lines/${this.creditLineId}/loans`).toPromise();
  }

  /**
   * Adds additional data to customer
   * */
  setupBorrowersData() {
    if (!this.allowedBorrowers) return;

    this.allowedBorrowers.forEach(b => {
      if (this.creditLine.loans) {
        const allLoans = this.creditLine.loans
          .filter(l => l.customerId === b.id);
        const activeLoans = allLoans
          .filter(l => !['INVALID', 'CANCELED', 'CLOSED'].includes(l.status));
        b.totalLoans = allLoans.length;
        b.activeLoans = activeLoans.length;
        b.principalAmount = sum(activeLoans.map(loan => loan.principalAmount));
        b.principalBalance = sum(activeLoans.map(loan => loan.principalBalance));
      }
    });
    this.toggleTableData();
  }

  removeBorrower(cif) {
    const cifIndex = this.allowedBorrowers.indexOf(cif);
    if (cifIndex > -1) {
      this.allowedBorrowers.splice(cifIndex, 1);
      this.toggleTableData();
    }
  }

  toggleTableData() {
    this.borrowersTable = new NgTableParams({
      count: 20
    }, {
      counts: [],
      paginationMaxBlocks: 5,
      paginationMinBlocks: 2,
      dataset: this.allowedBorrowers
    })
  }

  async manageBorrowers() {
    const allowedBorrowerIds = this.allowedBorrowers.map(b => b.id);
    const removedBorrowerIds = this.existingBorrowerIds.filter(id => !allowedBorrowerIds.includes(id));
    const addedBorrowerIds = allowedBorrowerIds.filter(id  => !this.existingBorrowerIds.includes(id));

    let removedCIFNames = undefined;
    let addedCIFNames = undefined;

    if (removedBorrowerIds && removedBorrowerIds.length > 0) {
      removedCIFNames = this.existingBorrowers
        .filter(b => removedBorrowerIds.includes(b.id))
        .map(b => b.effectiveName);
    }

    if (addedBorrowerIds && addedBorrowerIds.length > 0) {
      addedCIFNames = this.allowedBorrowers
        .filter(b => addedBorrowerIds.includes(b.id))
        .map(b => b.effectiveName);
    }

    const request = {
      customerId: this.customerId,
      creditLineId: this.creditLineId,
      allowedBorrowerIds: allowedBorrowerIds
    };

    this.confirmationTemplate({
      question: 'Updated credit line borrowers?',
      details: [
        {label: "Added", description: addedCIFNames && addedCIFNames.length > 0  ? addedCIFNames : 'None'},
        {label: "Removed", description: removedCIFNames && removedCIFNames.length > 0  ? removedCIFNames : 'None'}
      ],
      yesCallback: async() => {

        if ((addedBorrowerIds && addedBorrowerIds.length > 0) || (removedBorrowerIds && removedBorrowerIds.length > 0)) {
          await this.command.execute('ManageCreditLineBorrowers', request).toPromise();
          this.customerCache.creditLines(this.customerId).refetch();
        } else {
          this.notification.show('No changes made to Credit Line borrowers.');
        }
        this.redirectBack();
      }
    });
  }

  addBorrower(customer) {
    if (customer.customerType !== 'INDIVIDUAL') {
      this.popup({header: 'Error', text: `Only individual customer can be added as borrower.`});
      return;
    }

    const allowedBorrowerIds = this.allowedBorrowers.map(b => b.id);
    if (!allowedBorrowerIds.includes(customer.id)) {
      this.allowedBorrowers.push(customer);
      this.setupBorrowersData();
    } else {
      this.popup({header: 'Info', text: `Customer ${customer.effectiveName} is already a borrower.`});
    }
  }

  mapCustomer(customer) {
    return {
      id: customer.customerId,
      effectiveName: customer.name,
      customerNumber : customer.customerNumber,
      individualData: {
        birthDate: customer.birthDate
      }
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/credit-lines/${this.creditLineId}`);
  }

}

nxModule.component('customerCreditLineBorrowers', {
  templateUrl,
  controller: CustomerCreditLineBorrowers
});