import nxModule from 'nxModule';
import angular, {ILocationService} from 'angular';
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';
import {BranchService} from '../../../service/branch.service';
import {UserCache, UserDetails} from '../../../service/users/user.cache';

import templateUrl from './users-list.template.html';
import {Branch} from "management/BranchTypes";
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandAccessChecker, CommandService} from "shared/utils/command/command.types";
import {NxRouteService} from "routes/NxRouteService";
import {Breadcrumbs} from "angular-breadcrumbs";

interface UserWithAllRoles extends UserDetails {
  allRoles: string
}

class UsersListComponent {

  private branch!: Branch;
  private users: UserWithAllRoles[] = [];
  private allUsers: UserWithAllRoles[] = [];
  private selectedStatus: null | false | true = null;
  private isPermitted!: boolean;
  private detailLink!: string;

  constructor(private readonly $route: NxRouteService,
              private readonly $filter: NxIFilterService,
              private readonly $location: ILocationService,
              private readonly command: CommandService,
              private readonly userCache: UserCache,
              private readonly branchService: BranchService,
              private readonly confirmation: Confirmation,
              private readonly breadcrumbs: Breadcrumbs,
              private readonly commandAccessChecker: CommandAccessChecker) {
  }

  async $onInit(): Promise<void> {
    this.detailLink = `/admin/organization/users/${this.branch.id}/user`;

    setBreadcrumbs(this.breadcrumbs, 'users-list-label', this.branch.name);

    const accessChecker = await this.commandAccessChecker.canExecuteCommandAsync();
    this.isPermitted = accessChecker('CreateUser') || accessChecker('UpdateUser') || accessChecker('DeleteUser');

    if (this.isPermitted) {
      await this.loadUsers();
    } else {
      // When current user can't create/edit/delete users then user has no reason to access list of users
      console.error('You have no access to the organization/users');
      this.$location.path('/lookup-customer');
    }
  }

  private async loadUsers(): Promise<void> {
    const users: UserDetails[] = await this.userCache.withParam().toPromise();
    this.users = users.filter(u => !u.roles.some(r => r.technical) && u.branchId === this.branch.id) // Exclude users with technical roles
      .map(u => this.collectRoles(u));
    this.allUsers = angular.copy(this.users);
  }

  private collectRoles(user: UserDetails): UserWithAllRoles {
    const allRoles = user.roles
      .map(r => this.$filter('prettyEnum')(r.name))
      .join(', ');

    return {
      ...user,
      allRoles
    };
  }

  updateList(): void {
    this.users = this.allUsers.filter(user =>
      (user.enabled === this.selectedStatus || this.selectedStatus === null));
  }

  addNew(): void {
    this.$location.path(`${this.detailLink}/create`);
  }

  editUser(id: number): void {
    this.$location.path(`${this.detailLink}/${id}`);
  }

  async deleteUser(id: number): Promise<void> {
    const confirmed = await this.confirmation('Are you sure you want to delete this user?' +
      ' Please note that this action cannot be reversed and the username cannot be used again ' +
      'as it must stay in the system for audit purposes.');
    if (confirmed) {
      /*
       * Steps to perform one by one:
       * - delete user
       * - clear users cache
       * - load users
       */
      const response = await this.command.execute('DeleteUser', {id: id}).toPromise();
      if(response.approvalRequired) {
        return;
      }

      await this.userCache.withParam().refetch();
      await this.loadUsers();
    }
  }
}

nxModule.component('usersList', {
  templateUrl,
  bindings: {
    branch: '<'
  },
  controller: UsersListComponent
});
