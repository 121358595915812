import nxModule from 'nxModule';
import _ from 'lodash';
import {sum} from "../../../../shared/common/MathUtils";
import {AccountWithLabel, addAccountLabels} from 'components/general-ledger/common/gl.utils';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {DictionaryAttribute} from '../../../../../react/dictionary/DictionaryType';
import {
  EntryType
} from '../../../administration/general-ledger/misc-transaction-mapping/misc-transaction-mapping.service';
import angular, {IController, IFormController, ITimeoutService} from 'angular';
import {MiscCommandService} from "../misc-command.service";

import './funds-movement.less';
import templateUrl from './funds-movement.template.html';
import {NxIFilterService} from "components/technical/angular-filters";
import GlMappingsService from "components/administration/gl-mappings/gl-mappings.service";
import {ActionCommand} from "components/dashboard/miscellaneous-transactions/common/action-command.types";
import {Dict} from "shared/common/dict.types";

export interface TransactionUnit {
  amount?: number;
  entryType: EntryType;
  accountCode?: string;
}

interface Check {
  amount: number;
  bankId?: string;
  bankName?: string;
  categoryId?: number;
  incomingCheck?: unknown;
  micrNumber?: string;
  totalCreditAmount?: number;
  totalDebitAmount?: number;
  units: TransactionUnit[];
  validFrom?: Date;
  denominationValid?: boolean;
}

interface MiscCategoryInputUnit {
  amount: number;
  fullCode: string;
}

interface MiscCategoryInputUnits {
  categoryId: number;
  units: MiscCategoryInputUnit[];
}

interface MiscCheckInInput {
  bankId : number;
  micrNumber: string;
  validFrom: string;
  categoryUnits: MiscCategoryInputUnits;
  remarks: string;
}

export interface DictionaryIssuingBank {
  attributes: DictionaryAttribute[];
  code: string;
  description: string;
  id: number;
}

class FundsMovement implements IController {
  readonly selectConfig  = {
    placeholder: 'Select bank',
    searchField: ['description'],
    valueField: 'id',
    labelField: 'description',
    maxItems: 1
  };

  transactionForm!: IFormController;
  actionCode!: string;
  actionName!: string;
  checkModel: Partial<Check> = {};
  entryType!: EntryType;
  banks: DictionaryIssuingBank[] = [];
  category?: ActionCategory;
  userChecks: Check[] = [];
  totalAmount: number = 0;
  glAccountLabels: Record<string, string> = {};
  fixedAccountLabel: string = 'User check';
  blockedUnits: TransactionUnit[] = [];
  remarksRequired: boolean = false;

  private actionType!: string;
  private ledgerAccounts: AccountWithLabel[] = [];

  constructor(private $filter: NxIFilterService,
              private $routeParams: angular.route.IRouteParamsService,
              private $timeout: ITimeoutService,
              private glMappingsService: GlMappingsService,
              private actionCommand: ActionCommand,
              private dict: Dict,
              private miscCommandService: MiscCommandService) {
  }

  async $onInit(): Promise<void> {
    this.actionCode = this.actionType.toUpperCase().replace(/-/g, '_');
    this.entryType = ['CASH_IN', 'CHECK_IN', 'BATCH_CHECK_IN'].includes(this.actionCode) ? 'DEBIT' : 'CREDIT';
    const miscTransactionName = this.$filter('translateEnum')(this.actionType.toUpperCase().replace(/-/g, ' '), 'MISC_TRANSACTION');
    this.actionName = miscTransactionName || this.$filter('prettyEnum')(this.actionCode);

    const ledgerAccountMappings = await this.glMappingsService.accounts.toPromise();

    this.ledgerAccounts = addAccountLabels(ledgerAccountMappings);

    this.dict.onLoadingComplete(() => this.banks = this.dict['BANK']);
    this.initCheckModel();
    this.transactionForm.$setSubmitted();
  }

  initCheckModel(): void {
    this.checkModel = {
      amount: 0, units: []
    };

    if (['CHECK_IN', 'BATCH_CHECK_IN'].includes(this.actionCode)) {
      this.checkModel = {...this.checkModel, incomingCheck: {}}
    }
  }

  onCategoryChange(): void {
    this.remarksRequired = false;

    if (this.category) {
      this.checkModel.categoryId = this.category.id;
      this.remarksRequired = this.category && this.category.remarksRequired;

      if (this.category.ledgerAccountFullCodes) {
        this.blockedUnits = this.ledgerAccounts
          .filter(acc => this.category?.ledgerAccountFullCodes.includes(acc.fullCode))
          .map(acc => ({
            accountCode: acc.fullCode,
            entryType: this.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT'
          }));
        return;
      }
    }

    this.blockedUnits = [];
  }

  cancelChanges(): void {
    this.actionCommand.cancelChanges();
  }

  isSaveButtonDisabled(): boolean {
    if (this.actionCode === 'BATCH_CHECK_IN') {
      return !this.userChecks || this.userChecks.length === 0;
    } else {
      return this.transactionForm.$invalid;
    }
  }

  save(): void {
    const {commandName, request} = this.prepareRequest();
    this.miscCommandService.executeCommand(commandName, request);
  }

  private prepareRequest(): { commandName: string, request: { checkItems: MiscCheckInInput[] } | MiscCheckInInput } {
    if (this.actionCode === 'BATCH_CHECK_IN') {
      // Batch takes list of defined checks
      return {
        commandName: 'MiscBatchCheckIn',
        request: {checkItems: this.userChecks.map(c => this.prepareRequestBody(c))}
      };
    }

    // Currently provided check
    return {
      commandName: 'MiscCheck' + (this.actionCode.endsWith('IN') ? 'In' : 'Out'),
      request: this.prepareRequestBody(this.checkModel as Check)
    };
  }

  private prepareRequestBody(checkModel: Check): MiscCheckInInput {
    const units = checkModel.units.filter(unit => unit.accountCode)
      .map(unit => ({
        ...unit,
        amount: unit.amount!,
        fullCode: unit.accountCode!,
      }));

    const check = _.cloneDeep(checkModel);
    check.units = []; // remove property to avoid mislead
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {...check, categoryUnits: {categoryId: checkModel.categoryId!, units: units}};
  }

  removeCheck(checkIndex: number): void {
    this.userChecks.splice(checkIndex, 1);
    this.updateTotalAmount();
  }

  refreshGlAccountLabels(): void {
    const glAccountLabels: Record<string, string> = {};
    this.userChecks
      .flatMap(check => check.units)
      .filter(unit => unit.accountCode)
      .map(unit => ({
        code: unit.accountCode!,
        label: _.find(this.ledgerAccounts, l => l.fullCode === unit.accountCode)!.name
      }))
      .forEach(definition => glAccountLabels[definition.code] = definition.label);

    this.glAccountLabels = glAccountLabels;
  }

  sumUnitsAmount(entryType: EntryType): number {
    return sum((this.checkModel.units ?? [])
      .filter(unit => unit.entryType === entryType)
      .map(unit => unit.amount)).toNumber();
  }

  addCheck(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.userChecks.push({
      ..._.cloneDeep(this.checkModel),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      bankName: _.find(this.banks, bank => bank.id == Number(this.checkModel.bankId)).description,
      totalDebitAmount: this.sumUnitsAmount('DEBIT'),
      totalCreditAmount: this.sumUnitsAmount('CREDIT')
    });

    this.refreshGlAccountLabels();
    this.updateTotalAmount();
    this.initCheckModel();
    this.updateBlockedUnits();
  }

  updateTotalAmount(): void {
    this.totalAmount = _.isEmpty(this.userChecks) ? 0 :
      sum(this.userChecks.map(check => check.amount)).toNumber();
  }

  updateBlockedUnits(): void {
    this.onCategoryChange();
  }
}

nxModule.component('fundsMovement', {
  bindings: {
    actionType: '<'
  },
  templateUrl,
  controller: FundsMovement
});
