import nxModule from 'nxModule';
import templateUrl from './issue-certificate.template.html';

class IssueCertificate {
  constructor($location, customerCache, termDepositsService, confirmationTemplate, command,
              certificateCache, modalPrintPreviewService) {
    this.$location = $location;
    this.customerCache = customerCache;
    this.termDepositsService = termDepositsService;
    this.confirmationTemplate = confirmationTemplate;
    this.command = command;
    this.certificateCache = certificateCache;
    this.modalPrintPreviewService = modalPrintPreviewService;
  }

  async $onInit() {
    this.productsCache = this.customerCache.termDeposits(this.customerId);

    const [products, depositTypes] = await Promise.all([this.productsCache.toPromise(), this.termDepositsService.toPromise()]);

    const product = products.find(product => Number(product.id) === Number(this.productId));
    const depositType = depositTypes.find(type => Number(type.id) === Number(product.typeId));
    this.depositType = depositType;
    this.product = {...product, productName: depositType?.productDefinition?.productName ?? '-'};

    this.certificate = { productId: this.productId };
    this.activeCertificate = await this.certificateCache.withParam(this.productId).toPromise();
  }

  issueCertificate() {
    this.confirmationTemplate({
      question: `Do you want to issue a new certificate?`,
      warning: this.activeCertificate ? 'Active certificate will be replaced' : null,
      details: this.getDetails(),
      yesCallback: () => {
        this.command.execute('CreateTermDepositCertificate', this.certificate)
          .success(async () => {
            await this.modalPrintPreviewService.showAsync({
              printDescription: {
                code: 'CERTIFICATE_OF_TERM_DEPOSIT',
                parameters: {
                  'PRODUCT_DEFINITION': this.product.definitionId
                }
              },
              printProviderInput: {
                depositId: this.productId
              }
            });
            this.refreshCacheAndRedirectBack();
          });
      }
    });
  }

  refreshCacheAndRedirectBack() {
    this.productsCache.refetch();
    this.certificateCache.withParam(this.productId).evict();
    this.redirectBack();
  }

  getDetails() {
    const details = [];
    if (this.activeCertificate) {
      details.push({label: 'Active certificate number', description: this.activeCertificate.number});
    }

    if(this.certificate.number) {
      details.push({label: 'New certificate number', description: this.certificate.number});
    }

    return details;
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.productId}`);
  }
}

nxModule.component('issueCertificate', {
  templateUrl,
  bindings: {
    customerId: '<',
    productId: '<'
  },
  controller: IssueCertificate
});