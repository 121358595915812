export enum PrintOrientation {
  PORTRAIT = "PORTRAIT",
  LANDSCAPE = "LANDSCAPE"
}

export enum PrintLineType {
  VARIABLE = "VARIABLE",
  MULTI_VARIABLE = "MULTI_VARIABLE",
  LABEL = "LABEL",
  CUSTOM = "CUSTOM"
}

export type VariableType = 'USER' | 'CUSTOMER' | 'TEXT';

export type EffectiveNameFormat = 'INVERTED' | 'REGULAR';

export const printVariableTypes = Object.freeze([
  'USER',
  'CUSTOMER',
  'TEXT'
]);

export const nameFormatTypes = Object.freeze([
  'INVERTED',
  'REGULAR'
]);

export interface PrintWrapper {
  prints: Print[];
  printHistoryEntryIds: number[];
}

export interface CustomVariableConfig {
  CUSTOM_VARIABLE_TYPE?: VariableType;
  REQUIRED?: boolean;
  EFFECTIVE_NAME_FORMAT?: EffectiveNameFormat;
}

export interface PrintLine {
  id?: number;
  field: string;
  type: PrintLineType;
  fontSize: number;
  x: number;
  y: number;
  maxLength: number;
  paddingCharacter: string;
  fieldLengthIncludingPadding: number;
  active: boolean;
  text: string;
  exampleValue: string;
  printVariableType?: VariableType;
  customVariableConfig: CustomVariableConfig;
}

export interface Print {
  id: number;
  code: string;
  parameters: Record<string, unknown>
  name: string;
  orientation: PrintOrientation;
  active: boolean;
  canReprint: boolean;
  longEdge: number;
  shortEdge: number;
  backgroundFileIds: number[];
  organizationId: number;
  lines: PrintLine[];
  attributes: PrintAttribute[];
  pages: PrintPage[];
  type: PrintType;
  template?: string;
}

export interface PrintPage {
  lines: PrintLine[];
  itemCount: {
    value: number;
    offset: number;
  }
}

export interface PrintAttribute {
  id: number;
  printId: number;
  type: PrintAttributeType;
  value: unknown;
}

export type PrintAttributeType = 'MULTILINE_Y_SPACING'
  | 'MULTILINE_LINE_COUNT'
  | 'PAGE_SHEET_BREAK_ENABLE'
  | 'PAGE_SHEET_BREAK_HEIGHT'
  | 'PAGE_SHEET_BREAK_AFTER_LINE_NO';

export enum PrintType {
  Coordinate = "COORDINATE",
  Template = "TEMPLATE",
}
