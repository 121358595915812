import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from "config";

nxModule.factory('branchService', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get('/management/branches', {nxLoaderSkip: true}),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.branches,
    cacheName: 'branches',
    cacheStorage: 'memory'
  }));

export type BranchService = LocalCache<any, Branch[]>;