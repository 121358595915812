import nxModule from 'nxModule';
import moment from 'moment';
import BigNumber from 'bignumber.js';

import templateUrl from './customer-credit-line-details.template.html';

class CustomerCreditLineDetails {

  constructor(authentication, systemDateService, branchService, loanProductsCache, productService, creditLineTypesCache, creditLineLoansCache) {
    this.authentication = authentication;
    this.systemDateService = systemDateService;
    this.branchService = branchService;
    this.loanProductsCache = loanProductsCache;
    this.productService = productService;
    this.creditLineTypesCache = creditLineTypesCache;
    this.creditLineLoansCache = creditLineLoansCache;
  }

  $onInit() {
    this.createMode = false;

    this.replenishmentTypes = [{
      label: 'Revolving',
      value: 'REVOLVING'
    }, {
      label: 'Non-revolving',
      value: 'NON_REVOLVING'
    }];

    this.loans = [];

    this.initializeData();

  }

  async initializeData() {
    if (this.creditLine && !this.creditLine.id) {
      this.formTitle = 'Open new credit line';
      this.createMode = true;
      const branches = await this.branchService.toPromise();

      const userBranch = branches.find(branch => branch.id === this.authentication.context.branchId);

      // default grant date is current branch system date
      this.creditLine.grantDate = this.systemDateService.getSystemDate(userBranch);

      this.creditLine.branchId = userBranch.id;
      this.minCash = 0.01;
    } else {
      this.formTitle = 'Edit Credit Line:';
      this.minCash = this.bn(this.creditLine.availedCreditLine).isGreaterThan(this.bn(0)) ? this.creditLine.availedCreditLine : 0.01;
      await this.fetchLoans([this.creditLine.id]);
    }

    this.fetchLoanProducts();
    await this.fetchCreditLineTypeConfig();
    this.setMinAndAndMaxDates();
  }

  setMinAndAndMaxDates() {
    if (this.creditLine && this.creditLine.grantDate) {
      this.minGrantDate = moment(this.creditLine.grantDate).subtract(30, 'days');
      this.maxGrantDate = moment(this.creditLine.grantDate).add(30, 'days');
      this.setExpirationDate();
      if (!this.createMode && this.loans.length > 0) {
        const sorted = this.loans.sort((a, b) => moment(b.maturityDate) - moment(a.maturityDate));
        this.minExpirationDate = sorted[0].maturityDate;
      }
    }
  }

  async fetchLoanProducts() {
    const loanProducts = await this.loanProductsCache.toPromise();
    this.availableLoanProducts = loanProducts.filter(lp => {
      return lp.creditLineAvailable && this.productService.availableForSale(lp);
    });
    this.availableLoanProducts.forEach(lp => {
      lp.productName = lp.productDefinition.productName
    });

    this.setupLoanTypeNames();
  }

  setupLoanTypeNames() {
    if (!this.creditLine || !this.creditLine.loanTypeIds) return;

    this.creditLine.loanTypesAllowed = this.availableLoanProducts
      .filter(lp => this.creditLine.loanTypeIds.includes(lp.id))
      .map(lp => lp.productDefinition.productName)  ;
  }

  $onChanges(changes) {
    if (changes.creditLine.currentValue) {
      this.initializeData();
    }
  }

  bn(amount) {
    return new BigNumber(amount);
  }

  async fetchCreditLineTypeConfig() {
    if (!this.creditLineTypesConfig) {
      this.creditLineTypesConfig = await this.creditLineTypesCache.toPromise();
    }
  }

  async fetchLoans(creditLineIds) {
    this.loans = await this.creditLineLoansCache.withParam(creditLineIds).toPromise();
  }

  setExpirationDate() {
    const grantDate = moment(this.creditLine.grantDate);
    if (this.creditLineTypesConfig && grantDate.isSameOrAfter(this.minGrantDate) && grantDate.isBefore(this.maxGrantDate)) {
      // Getting the first index of the array to get the default credit line type. This is our initial implementation since
      // we don't yet have the screen for credit line type.
      if(this.createMode) { // set as default in create mode only
        this.creditLine.expirationDate = moment(this.creditLine.grantDate).add(this.creditLineTypesConfig[0].defaultExpirationDaysThreshold, 'days');
      }
      this.minExpirationDate = this.creditLine.expirationDate;
    }
  }
}

nxModule.component('customerCreditLineDetails', {
  templateUrl,
  bindings: {
    creditLine: '<',
    form: '='
  },
  controller: CustomerCreditLineDetails
});
