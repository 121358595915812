import nxModule from 'nxModule';
import {Loan} from "../../../../../service/loan.types";
import {HttpService} from "shared/utils/httpService";

export interface RenewedLoanDetails {
  loan: Loan;
  totalTerminationFees: number;
  totalWaivedAmount: number;
  paidPrincipal: number;
  requiredPaidPrincipal: number;
  remainingBalanceWithFees: number;
}

export class CustomerLoanRenewalService {

  constructor(private http: HttpService) {
  }

  async getRenewedLoanDetails(loanId: number): Promise<RenewedLoanDetails> {
    return await this.http.get<RenewedLoanDetails>(`/products/loans/renewed/${loanId}`).toPromise();
  }
}


nxModule.service('customerLoanRenewalService', CustomerLoanRenewalService);