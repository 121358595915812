import nxModule from 'nxModule';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';

const templateUrl = require('./active-stop-order-list.template.html');
nxModule.component('activeStopOrderList', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, $timeout, http, authentication, confirmation, branchService, userCache, command) {
    const that = this;

    // Stop order search criteria
    that.filter = {branchId: null};

    const generateQueryString = (pageNumber) => {
      return $.param({
        pageNo: pageNumber,
        pageSize: 20,
        branchId: that.filter.branchId,
        onlyActive: true
      });
    };

    that.searchStopOrders = (pageNumber) => {
      const queryParams = generateQueryString(pageNumber);
      return http.get(`/checks/stop-orders?${queryParams}`, {nxLoaderText: 'Loading stop orders'}).toPromise();
    };

    that.showBranchStopOrders = async () => {
      const response = await that.searchStopOrders(0);
      that.stopOrders = response.result;
      that.tableConfig = new NgTableParams({
        count: that.stopOrders.length
      }, {
        total: response.totalCount,
        counts: [],
        paginationMaxBlocks: 5,
        paginationMinBlocks: 5,
        data: that.stopOrders,
        getData: getPage
      });
    };

    const getPage = async (params) => {
      const response = await that.searchStopOrders(params.page() -1);
      that.stopOrders = response.result;
      that.tableConfig.total(response.totalCount);
      that.tableConfig.data = response.result;
      if (that.stopOrders && that.stopOrders.length > 0) {
        _.forEach(that.stopOrders, (stopOrder) => {
          const branch = _.find(that.branches, {id: stopOrder.branchId});
          stopOrder.branchName = branch ? branch.name : '-';
          const user = _.find(that.users, {id: stopOrder.createdBy});
          stopOrder.createdByFullName = user ? user.fullName : '-';
        });
      }
      return response.result;
    };

    that.itemClicked = (stopOrder, $event) => {
      $event.stopPropagation();
      that.selectedCheck = stopOrder;
      that.selectedCheckId = stopOrder.id;
    };

    that.remove = async (id) => {
      const proceed = await confirmation(`Do you want to remove stop order?`);
      if(proceed) {
        await command.execute('RemoveCheckStopOrder', {id: id}).toPromise();
        that.showBranchStopOrders();
      }
    };

    that.$onInit = async () => {
      const [users, branches] = await Promise.all([userCache.withParam().toPromise(), branchService.toPromise()]);
      // Read users to map createdBy field to user full name
      that.users = users;
      // Keep complete list of branches to translate stop order branch id to name
      that.branches = branches;

      // Initialize list of branches available to user
      const branchIds = authentication.context.branchIds;
      that.availableBranches = _.filter(that.branches, (b) => _.includes(branchIds, b.id));

      // Prepare default filter settings
      const defaultBranch = _.find(that.availableBranches, {id: authentication.context.branchId});
      const systemDate = Date.parse(defaultBranch.systemDate);
      that.filter.branchId = defaultBranch.id;
      that.filter.dateRange = {from: systemDate, to: systemDate};
      that.showBranchStopOrders();
    };

  }
});
