import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from 'bignumber.js';

const templateUrl = require('./interests-check-withdrawal.template.html');
nxModule.component('customerTermDepositInterestsCheckWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, productDefinitionService, confirmationTemplate, notification,
                        branchService, authentication, depositoryAccountCache, command, checkMicrService) {
    const that = this;
    that.termDeposit = {};
    that.banks = [];
    that.interestForecast = {};


    that.overrideCheckbox = false;
    that.taxRecalculated = false;

    that.override = {
      interest : 0,
      netInterest : 0,
      withholdingTax: 0
    };

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    // form parameters
    that.checkNumber = null;
    that.depositoryAccountId = null;
    that.validFrom = null;
    that.payee = null;
    that.remarks = null;

    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];

    that.$onInit = async () => {
      const [branches, depositoryAccounts, forecast, deposits, productDefinitions, customer] = await Promise.all([
        branchService.toPromise(),
        depositoryAccountCache.toPromise(),
        http.post(`/products/deposits/${depositId}/interest`, {mode: 'STANDARD'}).toPromise(),
        customerCache.termDeposits(customerId).toPromise(),
        productDefinitionService.toPromise(),
        customerCache.profile(customerId).toPromise()
      ]);

      const authBranch = branches.find(b => b.id === authentication.context.branchId);
      if(authBranch) {
        const systemDate = moment(authBranch.systemDate).toDate();
        that.systemDate = systemDate;
        that.validFrom = systemDate;
      }
      that.checkingAccounts = depositoryAccounts.filter(a => a.accountType === 'CHECKING' && a.brstn && a.branchId === authentication.context.branchId);

      that.termDeposit = deposits.find(d => d.id === Number(depositId));
      const productDefinition = productDefinitions.find(pd => pd.id === that.termDeposit.definitionId);

      Object.assign(that.termDeposit, {
        productName: productDefinition ? productDefinition.productName : '<Unknown product>'
      });

      that.payee = customer.effectiveName;
      that.remarks = 'Interest withdrawal from deposit no. ' + that.termDeposit.productNumber;

      that.interestForecast = forecast;
      that.override.interest = that.interestForecast.interest;
      that.override.netInterest = that.interestForecast.netInterest;
      that.override.withholdingTax = that.interestForecast.withholdingTax;
      that.withdrawalAmount = that.calculateWithdrawalAmount();
      that.override.amount = that.withdrawalAmount;
    };

    that.calculateWithdrawalAmount = () => {
      if(that.termDeposit.depositSubgroup === 'ADVANCE_INTEREST') {
        return BigNumber.max(0, new BigNumber(that.termDeposit.balance).minus(that.termDeposit.activationAmount))
          .decimalPlaces(2)
          .toNumber();
      }

      return new BigNumber(0)
        .plus(that.override.netInterest)
        .decimalPlaces(2).toNumber();
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.updateOverrides = () => {
      that.taxRecalculated = false;
    };

    that.recalculateTax = async () => {
      const newTax = await  http.get(`/products/deposits/${depositId}/interest/calculate-withholding-tax?interest=${that.override.interest}`).toPromise();
      that.override.netInterest = new BigNumber(that.override.interest).minus(newTax).toNumber();
      that.override.withholdingTax = newTax;
      that.override.amount = that.calculateWithdrawalAmount();
      that.taxRecalculated = true;
    };


    that.withdrawalEnabled = () => {
      return !that.transactionForm.invalid && that.withdrawalAmount > 0;
    };

    that.updateMicr = () => {
      that.micrNumber = checkMicrService.createMicr(
        that.checkNumber,
        Number(that.depositoryAccountId),
        that.checkingAccounts
      );
    };

    that.withdraw = async () => {
      const confirmed = await confirmationTemplate({
        question: `Do you want to deposit ${$filter('nxCurrency')(that.overrideCheckbox ? that.override.amount : that.withdrawalAmount)}?`,
      });

      if(confirmed) {
        const response = command.execute('WithdrawDepositInterestByCheck', {
          productId: depositId,
          customerId: customerId,
          entryType: "DEBIT",
          amount: that.overrideCheckbox ? that.override.amount : that.withdrawalAmount,
          override: that.overrideCheckbox ? that.override.interest : null,
          validFrom: that.validFrom,
          depositoryAccountId: that.depositoryAccountId,
          micrNumber: that.micrNumber,
          checkNumber: that.checkNumber,
          payee: that.payee,
          remarks: that.remarks
        });

        if (response && !response.approvalRequired) {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        }
      }
    };
  }
});
