import React, {ReactElement, useEffect, useState} from "react";
import CheckPreparationList from "check/check-preparation/CheckPreparationList";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import ReportService from "report/ReportService";

interface MarkChecksForOutgoingReleaseOutput {
  forReleaseBatchId: number;
}

const reportService = new ReportService();
const report = 'Check Register Report';

const OutgoingChecksList = (): ReactElement => {
  const [printableReports, setPrintableReports] = useState<string[]>([]);

  useEffect(() => {
    async function retrieveAvailableReports() : Promise<void> {
      const availableReports : string[] = await reportService.readAvailableFileReports();
      setPrintableReports(availableReports);
    }
    retrieveAvailableReports();
  }, []);

  const printReports = async (output: MarkChecksForOutgoingReleaseOutput) : Promise<void> => {
    if(printableReports.includes(report)){
      await reportService.downloadFileReport({reportName : report, parameters: { batchId: output.forReleaseBatchId }});
    }
  };

  return <NxPage>
    <NxHeader>
      Outgoing checks
    </NxHeader>
    <CheckPreparationList
      tableActions={[]}
      checkPreparationStatus={'FOR_OUTGOING'}
      checkTransition={{
        command: 'MarkChecksForRelease',
        actionLabel: 'For Release',
        confirmationMessage: 'Are you sure that you want to mark these checks and loans for release?',
        onCommandExecuted: printReports
      }}
      cancelRedirectUrl={'/check-preparation/outgoing-checks'}
    />
  </NxPage>;
};

export default OutgoingChecksList;
