import nxModule from 'nxModule';
import _ from 'lodash';
import * as ng from 'angular';
import templateUrl from './recalculate-loan-eir.template.html';
import {BranchService} from "../../../service/branch.service";
import {HttpService} from "../../../../shared/utils/httpService";
import {Loan} from "../../../service/loan.types";
import {Branch} from "management/BranchTypes";
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";


type ProductSelectionMode = 'BRANCH' | 'PRODUCT_NUMBER';

interface BranchFilter {
  code: string;
  id: number;
  systemDate: string;
}

class RecalculateLoanEir {
  productSelectionModes: {label: string, value: ProductSelectionMode}[] = [
    {
      label: 'Branch',
      value: 'BRANCH'
    }, {
      label: 'Product Number',
      value: 'PRODUCT_NUMBER'
    }
  ];

  availableBranches: BranchFilter[] = [];
  branchIds: number[] = [];
  productSelectionMode: ProductSelectionMode | null = null;
  loansToRecalculate: Loan[] = [];
  searchProductNumber: string = "";

  constructor(private http : HttpService,
              private $scope: ng.IScope,
              private $route: NxRouteService,
              private branchService: BranchService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private popup: Popup) {
  }

  async $onInit(): Promise<void> {
    const branches = <Branch[]> await this.branchService.toPromise();
    this.availableBranches = branches.map(branch => {
      return {
        id: branch.id,
        code: branch.name,
        systemDate: branch.systemDate
      };
    });
  }

  resetLoanList(): void {
    this.loansToRecalculate = [];
  }

  async addProduct(): Promise<void> {
    if (this.checkIfExisting(this.searchProductNumber)) {
      this.popup({
        text: `Product number ${this.searchProductNumber} is already included in the list.`
      });

      return;
    }

    const data = <Loan> await this.http.get(`/products/loans/search?productNumber=${this.searchProductNumber}`)
    .error(error => {
      if(error.errorCode === 'NOT_FOUND_BY_SECONDARY_KEY') {
        this.popup({
            text: `No loan with product number ${this.searchProductNumber} found`
          }
        );
      }
    }).toPromise();

    this.loansToRecalculate.push(data);
  }

  checkIfExisting(productNumber: string): boolean {
    return !!_.find(this.loansToRecalculate, {
      productNumber: productNumber
    });
  }

  removeLoan(index: number): void {
    this.loansToRecalculate.splice(index, 1);
  }

  async execute(): Promise<void> {
    const loanIds = this.loansToRecalculate.map(p => p.id);
    const request = {
      productIds: loanIds,
      branchIds : this.branchIds?.length === 1 && this.branchIds[0] === null ? [] : this.branchIds
    };

    await this.command.execute('RecalculateEffectiveInterestRate', request).toPromise();
    this.$route.reload();
  }
}

nxModule.component('recalculateLoanEir', {
  templateUrl,
  controller: RecalculateLoanEir
});
