import nxModule from 'nxModule';

import templateUrl from './missing-access-warning.template.html';
import './missing-access-warning.style.less';
import Authentication from "shared/utils/authentication";

class MissingAccessWarning {
  missingAccess!: string;
  permissions: {[permissionName: string]: boolean};

  constructor(private authentication: Authentication) {
    this.permissions = authentication.permissions;
  }

  isPermissionMissing() {
    return !this.permissions[this.missingAccess];
  }
}

nxModule.component('missingAccessWarning', {
  templateUrl,
  bindings: {
    missingAccess: '@'
  },
  controller: MissingAccessWarning
});