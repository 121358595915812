import nxModule from 'nxModule';
import {NxIFilterService} from "components/technical/angular-filters";

export type OutgoingCheckClearingError =
  'INVALID_CHECK_NUMBER' |
  'DEPOSITORY_ACCOUNT_NOT_FOUND' |
  'ACCOUNT_NOT_FOUND' |
  'ACCOUNT_CLOSED' |
  'CLEARING_ALREADY_PERFORMED' |
  'CHECK_NUMBER_ALREADY_PRESENTED' |
  'INVALID_SPO' |
  'SPO_FUNDED' |
  'SPO_DAUD' |
  'SPO_DAIF' |
  'DAUD' |
  'DAIF';

export class OutgoingCheckClearingService {
  private readonly clearingErrorTranslateMap: {
    [error in OutgoingCheckClearingError]: string;
  };
  private readonly clearingErrorFormattingMap: Partial<{
    [error in OutgoingCheckClearingError]: string;
  }>;

  constructor(private readonly $filter: NxIFilterService) {
    this.clearingErrorTranslateMap = {
      'INVALID_CHECK_NUMBER': 'Checkbook for given check number does not exist. ' +
        'This might happen if check number provided during upload is invalid or checkbook was removed before check was cleared.',

      'DEPOSITORY_ACCOUNT_NOT_FOUND': 'Depository account identified by check BRSTN and account number does not exist or is not configured as valid conduit/clearing account.',

      'ACCOUNT_NOT_FOUND': 'Checking account associated with the check does not exist (it was never created or it was removed using admin options). Check will not be accepted probably due to invalid check data.',

      'ACCOUNT_CLOSED': 'Checking account associated with the check exists but is already closed. Check will not be accepted due to business error.',

      'CLEARING_ALREADY_PERFORMED': 'Clearing can be performed only once per upload. When the check is re-uploaded clearing can be performed again.',

      'CHECK_NUMBER_ALREADY_PRESENTED': 'Inward check can be sent for clearing limited number of times. With each clearing check [clearingCounter] value is incremented. If clearing counter exceeds the allowed value check will not be sent for clearing anymore. This happens mostly due to the input errors.',

      'INVALID_SPO': 'Error occurred during check stop order validation. System was unable to identify more detailed info. This might be caused by invalid SPO data.',

      'SPO_FUNDED': 'Check cannot be cleared due to active stop order. Related account(s) available balance sum is sufficient to cover the check amount.',

      'SPO_DAUD': 'Check cannot be cleared due to active stop order. Related account(s) available balance sum is insufficient to cover the check amount, total balance however can cover check amount in total.',

      'SPO_DAIF': 'Check cannot be cleared due to active stop order. Related account(s) total balance sum is insufficient to cover the check amount.',

      'DAUD': 'Check cannot be cleared due to insufficient available balance of the checking account (and its ATA relations). Total balance however can cover check amount in total.',

      'DAIF': 'Check cannot be cleared due to insufficient total balance of the checking account (and its ATA relations).'
    };

    // list of specially treated values that can't be
    // formatted with prettyEnum filter
    this.clearingErrorFormattingMap = {
      'INVALID_SPO': 'Invalid SPO',
      'SPO_FUNDED': 'SPO funded',
      'SPO_DAUD': 'SPO DAUD',
      'SPO_DAIF': 'SPO DAIF',
      'DAUD': 'DAUD',
      'DAIF': 'DAIF'
    };
  }

  describe(clearingError: OutgoingCheckClearingError) : string {
    return this.clearingErrorTranslateMap[clearingError] || 'Unknown clearing error';
  }

  format(clearingError: OutgoingCheckClearingError): string {
    return this.clearingErrorFormattingMap[clearingError] || this.$filter('prettyEnum')(clearingError as string);
  }

  getClearingErrorsDescribedList(clearingErrors: OutgoingCheckClearingError[]) : string[] {
    return clearingErrors.map((clearingError, index) => `${index + 1}. ${this.format(clearingError)} - ${this.describe(clearingError)}`)
  }
}

nxModule.service('outgoingCheckClearingService', OutgoingCheckClearingService);

