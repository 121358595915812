import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./customer-profile-header.template.html');
nxModule.component('customerProfileHeader', {
  templateUrl: templateUrl,
  transclude: true,
  bindings: {
    /**
     * Either 'CUSTOMER' or 'GROUP'
     * If null, the default of CUSTOMER is chosen.
     */
    mode: '<',
    screen: '='
  },
  controller: function ($scope, $route, branchService, fileService, notification, customerCache, groupCustomerCache, userCache) {
    const that = this;

    let branchId = null;
    const branchNames = {};

    const customerId = $route.current.params['customerId'];

    that.$onInit = async () => {
      const [branches, users, profile] = await Promise.all([
        branchService.toPromise(),
        userCache.withParam().toPromise(),
        that.mode === 'GROUP' ? groupCustomerCache.profile(customerId).toPromise() : customerCache.profile(customerId, true, true).toPromise()
      ])

      branches.forEach(b => branchNames[b.id] = b.name);

      if (!profile) {
        return;
      }
      that.customerProfile = profile;
      if (that.screen === 'PROFILE') {
        const user = _.find(users, {'id': profile.updatedBy});
        if (user) {
          that.updatedBy = `${user.lastName}, ${user.firstName} ${user.middleName ? user.middleName : ''} (${user.username})`
        }
        that.updatedAt = profile.updatedAt;
      }

      that.customerName = profile.effectiveName;
      that.customerNumber = profile.customerNumber;
      branchId = profile.branchId;
      refreshBranchName();
    }

    that.showAvatar = async () => {
      if (!that.profilePicture) {
        try {
          const blob = await fileService.downloadFile(that.customerProfile.pictureFileId, true).toPromise();
          that.profilePicture = window.URL.createObjectURL(blob);
          that.showProfilePopup = true;
        } catch (error) {
          notification.show("Error", "Could not load profile picture");
          console.error(error);
        }
      } else {
        that.showProfilePopup = true;
      }
    }

    that.signatureFileId = () => {
      if(!that.customerProfile) {
        return;
      }

      return that.customerProfile.individualData?.signatureFileId || that.customerProfile.corporateData?.signatureFileId;
    };

    that.showSignature = async () => {
      const signatureFileId = that.signatureFileId();

      if (signatureFileId && !that.signature) {
        try {
          const blob = await fileService.downloadFile(signatureFileId, false).toPromise()
          that.signature = window.URL.createObjectURL(blob);
          that.signatureType = blob.type === 'application/pdf' ? 'PDF' : 'IMAGE';
        } catch (error) {
          notification.show("Error", "Could not show signature");
          that.showPopup = false;
          console.error(error);
        }
      }
      that.showPopup = true;
    };

    function refreshBranchName() {
      if (branchId) {
        that.branchName = branchNames[branchId];
      }
    }

    that.$onDestroy = () => {
      if (that.signature) {
        window.URL.revokeObjectURL(that.signature);
      }
    }

  }
});
