import nxModule from 'nxModule';
import './amount-predicate.style.less';
import templateUrl from './amount-predicate.template.html';

class AmountPredicateComponent {
  constructor(accessRuleService) {
    this.accessRuleService = accessRuleService;
  }

  $onInit() {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    this.value = this.predicates[this.type][this.inputDefinition];
  }

  valueChanged() {
    this.predicates[this.type][this.inputDefinition] = this.value;
  };

  inputDefinitionChanged() {
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }
}

nxModule.component('amountPredicate', {
  templateUrl,
  bindings: {
    predicates: '<',
    /**
     * One of: GREATER_THAN_AMOUNT, GREATER_THAN_OR_EQUAL_AMOUNT, LESS_THAN_AMOUNT, LESS_THAN_OR_EQUAL_AMOUNT
     */
    type: '<',
    ngDisabled: '<',
    ruleDefinitions: '<',
    accessInput: '<'
  },
  controller: AmountPredicateComponent
});
