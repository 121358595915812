import nxModule from 'nxModule';
import _ from "lodash";
import './dynamic-report-mapping-edit.style.less';
import templateUrl from './dynamic-report-mapping-edit.template.html';
import {markDuplicatedRows} from "../../dynamic-report-mapping-util";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";
import Notification from "shared/utils/notification";


interface RowData {

  row: number;
  sheet: string;
  column: string;
  formula: string;
}

interface ReportMappingResult {
  editMode: boolean;
  column: string;
  dynamicReportFileId: number;
  formula: string;
  id: number;
  row: number;
  sheet: string;
  cell: string;
}

export class ReportMapping {

  public editMode: boolean;
  public column: string;
  public dynamicReportFileId: number;
  public formula: string;
  public id: number;
  public row: number;
  public sheet: string;
  public cell: string;
  public isUnique: boolean;

  private oldValues: Partial<RowData> = {};

  constructor(reportMappingResult: ReportMappingResult) {
    this.editMode = reportMappingResult.editMode;
    this.column = reportMappingResult.column;
    this.dynamicReportFileId = reportMappingResult.dynamicReportFileId;
    this.formula = reportMappingResult.formula;
    this.id = reportMappingResult.id;
    this.row = reportMappingResult.row;
    this.sheet = reportMappingResult.sheet;
    this.cell = reportMappingResult.cell;
    this.isUnique = true;
  }

  get cellValue(): string {
    if(this.column && this.row) {
      return this.column + ":" + this.row;
    }

    return '';
  }

  set cellValue(cellValue: string) {
    const columnParts = cellValue.split(':');
    if(columnParts.length === 2) {
      this.column = columnParts[0];
      this.row = parseInt(columnParts[1]);
    }
  }

  update(): void {
    this.editMode = false;
    this.oldValues = {};
  }

  enableUpdate(): void {
    this.editMode = true;
    this.oldValues = {
      row : this.row,
      column : this.column,
      sheet: this.sheet,
      formula: this.formula
    };
  }

  revert(): void {
    this.editMode = false;
    Object.assign(this, this.oldValues);
  }

}

class DynamicReportMappingEditController {

  private rows!: ReportMapping[];
  private cellColumnConfig: any;
  private hasDuplicateRows: boolean = false;

  constructor(private http: HttpService, private popup: Popup, private notification: Notification){
    this.cellColumnConfig = {
      updateOn: 'blur',
      useGetterSetter: true,
      allowInvalid: true
    };

  }

   async validateRow(reportMapping: ReportMapping): Promise<unknown> {
    const response = await this.http.post(`/reports/test-mapping`, [{
      id: reportMapping.id,
      sheet: reportMapping.sheet,
      formula: reportMapping.formula,
      row: reportMapping.row,
      column: reportMapping.column
    }]).toPromise();

     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     // @ts-ignore
    if (_.size(response) > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.popup({text: response[0].errorMessage});
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return _.escape(response[0].errorMessage);
    }

    this.notification.show("No mapping errors found");
  }


  addRow(): void {
    const defaultValues: ReportMappingResult = {
      editMode: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      column: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dynamicReportFileId: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formula: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: null,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row: null,
      sheet: "",
      cell: "",
    };

    if (!this.rows) {
      this.rows = [];
    }

    this.rows.unshift(new ReportMapping(defaultValues));
  }

  updateRow(reportMapping: ReportMapping): void {
    reportMapping.update();
    this.hasDuplicateRows = markDuplicatedRows(this.rows);
  }

  deleteRow(reportMapping: ReportMapping): void {
    const index = this.rows.indexOf(reportMapping);
    this.rows.splice(index,1);
    this.hasDuplicateRows = markDuplicatedRows(this.rows);
  }

}


nxModule.component('dynamicReportMappingEdit', {
  controller: DynamicReportMappingEditController,
  templateUrl,
  bindings: {
    'rows' : '=rowData'
  }
});