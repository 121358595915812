import nxModule from 'nxModule';
import {IController, IFormController} from 'angular';

import templateUrl from './agent-customer-profile-body.template.html';
import './agent-customer-profile-body.style.less';
import {AgentCandidacy} from "./agent-candidacy.types";

class AgentCustomerProfileBody implements IController {
  candidacy!: AgentCandidacy;
  editMode: boolean = false;
  module!: string;
  individualDataForm!: IFormController;
  addressesForm!: IFormController;
  documentsForm!: IFormController;
  incomeForm!: IFormController;
  mainForm!: IFormController;
}

nxModule.component('agentCustomerProfileBody', {
  templateUrl: templateUrl,
  controller: AgentCustomerProfileBody,
  bindings: {
    candidacy: '=',
    editMode: '<',
    module: '<',
    individualDataForm: '=',
    addressesForm: '='
  }
});
