import nxModule from 'nxModule';
import moment from 'moment';

const templateUrl = require('./system-date-validation.template.html');

class SystemDateValidation {
  constructor($scope, notification, config, workingDayService) {
    this.$scope = $scope;
    this.notification = notification;
    this.config = config;
    this.workingDayService = workingDayService;
  }

  $onInit() {
    this.datesOk = true;
    this.oneTimeNotificationShown = false;

    this.workingDaySub = this.workingDayService.toObservable()
      .subscribe(workingDay => this.validate(workingDay));
  }

  $onDestroy() {
    this.workingDaySub.unsubscribe();
  }

  validate(workingDay) {
    const currentDate = moment().format('YYYY-MM-DD');
    this.datesOk = currentDate === workingDay.systemDate;
    if (!this.datesOk && !this.oneTimeNotificationShown) {
      this.notification.show(
        "Error",
        "System date does not match current date. Please adjust system date before performing any transaction.",
        // show the notification until dismissed manually
        Number.MAX_SAFE_INTEGER
      );
      this.oneTimeNotificationShown = true;
    }
  }
}

nxModule.component('systemDateValidation', {
  templateUrl: templateUrl,
  controller: SystemDateValidation
});
