import 'rxjs/add/operator/combineAll';
import 'rxjs/add/operator/toArray';
import nxModule from 'nxModule';
import StateMachine from 'javascript-state-machine';

import './group-loans-create.service';
import './group-loans-create-members.component';
import './group-loans-create-form.component';
import templateUrl from './group-loans-create.template.html';

class GroupLoansCreate {
  constructor($route, $location, groupLoansCreateService, groupCustomerCache) {
    this.customerId = $route.current.params['customerId'];
    this.groupLoansCreateService = groupLoansCreateService;
    this.groupCustomerCache = groupCustomerCache;
    this.$location = $location;
  }

  $onInit() {
    this.defaultMember = {
      id: -1,
      effectiveName: 'Default Configuration',
      default: true
    };

    this.members = [];
    this.membersSub = this.groupLoansCreateService.getMemberLoans(this.customerId)
      .subscribe(members => {
        this.members = members;
      });

    this.groupCustomerCache.profile(this.customerId)
      .toObservable()
      .subscribe(profile => this.groupProfile = profile);

    this.fsm = new StateMachine({
      init: 'membersSelection',
      transitions: [
        {name: 'membersSelected', from: 'membersSelection', to: 'productSelection',},
        {name: 'productSelected', from: 'productSelection', to: 'membersProductConfiguration',},
        {name: 'membersProductConfigured', from: 'membersProductConfiguration', to: 'membersSelection',},
        {name: 'configureMemberLoan', from: 'membersSelection', to: 'membersProductConfiguration'},
        {name: 'editLoan', from: 'membersSelection', to: 'editMemberLoan',},
        {name: 'memberProductConfigurationRevert', from: 'membersProductConfiguration', to: 'membersSelection',}
      ]
    });

    this.loanType = 'group';
    this.productId = null;
    this.editMode = false;

    this.getBatchApplicationHeader = () => {
      return this.editMode ? 'Editing batch application' : 'Creating batch application';
    };

    this.openAction = (loanType) => {
      this.product = loanType;
      this.fsm.productSelected();
    };

    this.memberConfigurationFinishedAction = () => {
      this.fsm.membersProductConfigured();
    };

    this.prepareLoan = () => {
      this.editMode = false;
      this.applyingMembers = this.members.filter(member => member.applying);
      this.applyingMemberIds = this.applyingMembers.map(m => m.id);

      if(this.hasLoanProductConfigured()) {
        this.fsm.configureMemberLoan();
      } else {
        this.defaultMember.loan = null;
        this.defaultMember.newLoan = null;
        this.fsm.membersSelected();
      }
    };

    this.returnToMemberSelection = () => {
      this.editMode = false;
      this.fsm.memberProductConfigurationRevert();
    };

    this.editGroupLoan = () => {
      this.applyingMembers = this.members.filter(member => member?.newLoan);
      this.editMode = true;
      this.fsm.configureMemberLoan();
    };

    this.saveApplication = ({loan, }) => {
      const loanId = loan.id;
      this.groupCustomerCache.refetch();
      this.$location.path(`/customer/${this.customerId}/group-loans/${loanId}`);
    };

    this.editLoan = (member) => {
      this.applyingMembers = [member];
      this.editMode = true;
      this.fsm.configureMemberLoan();
    };

    this.hasLoanProductConfigured = () => {
      return this.members.some(m => m.newProduct);
    }
  }

  $onDestroy() {
    this.membersSub.unsubscribe();
  }
}

nxModule.component('groupLoansCreate', {
  templateUrl,
  controller: GroupLoansCreate
});
