import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./create-account-hold.template.html');
nxModule.component('createAccountHold', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, http, customerCache, branchService, authentication, confirmation, command) {
    const that = this;

    const customerId = $route.current.params['customerId'];
    const accountId = $route.current.params['accountId'];

    that.account = {};
    that.hold = {autoRelease: false};

    const s1 = customerCache.depositAccounts(customerId).toObservable().subscribe((accounts) => {
      that.account = _.find(accounts, (a) => Number(a.id) === Number(accountId))
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);

    that.create = () => {
      confirmation(`Do you want to create new hold for ${$filter('nxCurrency')(that.hold.amount)}?`, () => {
        const input = Object.assign({}, {
          productId: accountId,
          amount: that.hold.amount,
          autoReleaseOn: that.hold.autoReleaseOn ? $filter('nxDate')(that.hold.autoReleaseOn) : null,
          creationRemarks: that.hold.creationRemarks
        });
        command.execute('CreateManualHold', input, {nxLoaderText: 'Creating hold'})
          .success(() => {
            customerCache.depositAccounts(customerId).refetch();
            that.redirectBack();
          })
      });
    };

    that.$onDestroy = () => {
      s1.unsubscribe();
    }
  }
});
