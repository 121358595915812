import nxModule from 'nxModule';

import BigNumber from "bignumber.js";

import templateUrl from './add-group-members.template.html';

nxModule.component('addGroupMembers', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: function ($scope, $timeout, $route, http, dict, notification, commandAccessChecker, $location, branchService) {
    let that = this;

    that.dict = dict;

    that.customerId = $route.current.params['customerId'];
    that.loansCount = {};
    that.summary = {
      active: 0,
      total: 0,
      principalBalance: new BigNumber(0),
      savingsBalance: new BigNumber(0),
    };

    that.$onInit = async () => {
      [that.accessChecker, that.branches] = await Promise.all([
        commandAccessChecker.canExecuteCommandAsync(),
        branchService.toPromise()
      ]);
    };

    that.newMembersCustomerId = [];

    that.canTransferCustomer = (customer) => {
      return that.editMode                                           /* no reason to transfer customer while creating the group */
        && !that.newMembersCustomerId.includes(customer?.id) /* nor when just added but not yet saved */
        && that.accessChecker && that.accessChecker('TransferCustomerToAnotherGroup');
    }

    that.isAnyActionAvailable = () => {
      return !that.editMode || that.canTransferCustomer();
    }

    that.addMember = (customer) => {
      if (!that.profile.customerIds.includes(customer.id)) {
        customer.branch = that.branches.find(b => b.id === customer.branchId);

        that.profile.customers.push(customer);
        that.profile.customerIds.push(customer.id);
        that.newMembersCustomerId.push(customer.id);
      } else {
        notification.show("Error", "Customer already added!");
      }
    };

    if (that.customerId) {
      http.get(`/products/loans/group-loan/${that.customerId}/customers-individual-loans-info`).success(infos => {
          for (const info of infos) {
            that.loansCount[info.customerId] = info;
            const summary = that.summary;

            summary.active += info.active;
            summary.total += info.total;

            if (info.principalBalance) {
              summary.principalBalance = summary.principalBalance.plus(info.principalBalance);
            }

            if (info.savingsBalance) {
              summary.savingsBalance = summary.savingsBalance.plus(info.savingsBalance);
            }
          }
        }
      );
    }

    that.transferMember = (idx) => {
      const customer = that.profile.customers[idx];
      $location.path(`/customer/${that.profile.customerId}/transfer/${customer.id}`);
    }

    that.onRemoveMember = (idx) => {
      const customer = that.profile.customers[idx];
      that.profile.customers.splice(idx, 1);
      const index = that.profile.customerIds.findIndex((el) => customer.id === el);
      that.profile.customerIds.splice(index, 1);
    };
  }
});
