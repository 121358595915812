import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('commandRoleMapCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/access/command-role-map'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.commandRoleMapCache,
    cacheName: 'commandRoleMap'
  })
);
