import nxModule from 'nxModule';
import {LedgerAccount} from "./gl.types";
import {LedgerAccountGroup} from "../../administration/gl-mappings/gl-mappings.service";
import {HttpService} from "shared/utils/httpService";

export interface FetchAccountsParams {
  leafOnly: boolean;
  group: LedgerAccountGroup[]
}

export class GlAccountService {
  constructor(private http: HttpService) { }

  // fetches individual accounts for given ledger id
  // if you want to present fullcode for a user to select from
  // without any details on branch id, it should probably be an account template -
  // ledger account is a specific representation of a ledger account template
  // with data on branch, ledger etc
  fetchAccounts(id: number, params: Partial<FetchAccountsParams> = {leafOnly: false}) {
    return this.http.get<LedgerAccount[]>(`/ledger/${id}/accounts`, {
      params,
      nxLoaderText: 'Loading accounts'
    });
  }

  fetchAccountsAsync(id: number, params: Partial<FetchAccountsParams>): Promise<LedgerAccount[]> {
    return this.fetchAccounts(id, params).toPromise();
  }
}

nxModule.service('glAccountService', GlAccountService);