import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {HttpService} from "shared/utils/httpService";
import config from '../../../react/config';
import {FeeDefinition} from "components/service/fees/fee.types";
import {ICacheFactoryService} from 'angular';

type FeeDefinitionCache = LocalCache<unknown, FeeDefinition[]>;
export default FeeDefinitionCache;

nxModule.factory('feeDefinitionsCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get('/products/fee-definitions'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.productFees,
    cacheName: 'productFees'
  })
);
