import nxModule from 'nxModule';

import {IController, ILocationService} from "angular";
import templateUrl from './transactions-category-list.template.html';
import './transactions-category-list.style.less';
import {NgTableParams} from "ng-table";
import LocalCache from "../../../shared/utils/localCache";
import {ActionCategory} from "./action-category.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from "shared/utils/command/command.types";


interface ActionCategoryDetails extends ActionCategory {
  text: string;
}

class TransactionsCategoryList implements IController {
  filter = {
    text: null
  };

  tableConfig!: NgTableParams<ActionCategoryDetails>;

  constructor(private readonly actionCategoryCache: LocalCache<ActionCategory[], ActionCategory[]>,
              private readonly $location: ILocationService,
              private readonly $filter: NxIFilterService,
              private readonly confirmation: Confirmation,
              private readonly command: CommandService) { }

  async $onInit(): Promise<void> {
    const data = await this.getData();
    this.tableConfig = new NgTableParams({
        page: 1,
        count: 10,
        filter: this.filter
      }, {
        paginationMaxBlocks: 8,
        paginationMinBlocks: 3,
        total: data.length,
        dataset: data
      });
  }

  async getData(): Promise<ActionCategoryDetails[]> {
    const categories = await this.actionCategoryCache.toPromise();
    return categories
      .filter(action => !action.system)
      .map(action => ({
      ...action,
      text: [action.name, this.$filter('translate')(action.actionType, 'ACTION_TYPE')].join(' ')
    }));
  }

  add(): void {
    this.$location.path('/admin/general-ledger/categories/new');
  }

  edit(category: ActionCategory): void {
    this.$location.path(`/admin/general-ledger/categories/${category.id}`);
  }

  async remove(category: ActionCategory): Promise<void> {
    const id = category.id;
    const actionLabel = this.$filter('prettyEnum')(category.actionType);
    const info = `Do you want to delete ${actionLabel} - '${category.name}' action category?`;
    if (await this.confirmation(info)) {
      const result = await this.command.execute('RemoveActionCategory', { id, }, {nxLoaderText: 'Removing category'}).toPromise();
      if(!result.approvalRequired) {
        await this.actionCategoryCache.refetch();

        const data = await this.getData();
        this.tableConfig.settings({
          total: data.length,
          dataset: data
        });
      }
    }
  }
}

nxModule.component('transactionsCategoryList', {
  templateUrl,
  controller: TransactionsCategoryList
});