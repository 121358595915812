import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {Dictionary} from '../../../react/dictionary/DictionaryType';
import {HttpService} from "shared/utils/httpService";
import {ICacheFactoryService} from "angular";
import config from 'config';

export type DictionaryCache = LocalCache<Dictionary[], Dictionary[]>;

nxModule.factory('dictionaryCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new LocalCache({
    provider: () => http.get('/dictionaries'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.dict,
    cacheName: 'dictionaries'
  })
);