import _ from 'lodash';
import nxModule from 'nxModule';
import {HttpService} from "shared/utils/httpService";
import Notification from "shared/utils/notification";
import {Confirmation} from "shared/common/confirmation.types";
import Popup from "shared/common/popup";
import RefreshService from "shared/utils/refresh.service";
import {StatusService} from "components/technical/status/status.service";
import Authentication from "shared/utils/authentication";
import {Task} from "components/dashboard/actions/task.types";
import {User} from "user/UserTypes";
import {ErrorResponse} from "tools/HttpTypes";

type TaskWithOverrideLevel = Task & { localOverrideLevel?: number};

export class DashboardActionService {
  constructor(private http: HttpService, private notification: Notification,
              private confirmation: Confirmation, private popup: Popup,
              private refreshService: RefreshService, private statusService: StatusService,
              private authentication: Authentication) {

  }

  cancel(task: TaskWithOverrideLevel, $event: JQueryEventObject | null, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to cancel the action?`, () => {
      this.http.put(`/tasks/${task.id}/status`, '"REJECTED"', {nxLoaderText: 'Canceling action...'}).success(() => {
        this.notification.show("The selected action was rejected.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  }

  pinEntered(task: TaskWithOverrideLevel, pin: string, user: User, success = _.noop) {
    this.confirmation(`Do you want to approve the action?`, () => {
      this.http.put(`/tasks/${task.id}/status?approvalUserId=${user.id}&level=${task.localOverrideLevel || 0}`,
        {
          taskStatus: "APPROVED",
          pin
        },
        {
          nxLoaderText: 'Approving action...'
        }).success(() => {
        this.notification.show("The selected action was approved.");
        this.refreshService.clearCaches();
        this.statusService.refresh();
        success(task);
      }).error(data => this.errorHandler(data))
    });
  }

  reject(task: TaskWithOverrideLevel, $event: JQueryEventObject | null, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to reject the action?`, () => {
      this.http.put(`/tasks/${task.id}/status`, '"REJECTED"', {nxLoaderText: 'Rejecting action...'}).success(() => {
        this.notification.show("The selected action was rejected.");
        success(task);
      }).error(data => this.errorHandler(data))
    });
  }

  approve(task: TaskWithOverrideLevel, $event: JQueryEventObject, level: number, success = _.noop) {
    if ($event) {
      $event.stopPropagation();
    }

    this.confirmation(`Do you want to approve the action?`, () => {
      this.http.put(`/tasks/${task.id}/status?level=${level | 0}`, '"APPROVED"', {nxLoaderText: 'Approving action...'}).success(() => {
        this.notification.show("The selected action was approved.");
        this.refreshService.clearCaches();

        if(task?.commandDescriptor?.simpleName === 'MiscCashTransferToTeller') {
          this.statusService.refresh();
        }

        success(task);
      }).error(data => this.errorHandler(data))
    });
  }

  errorHandler(data: ErrorResponse) {
    const details = data.errorMessage ? data.errorMessage : "An unknown error occurred.";
    const message = `${details}`;
    let finePrint;
    if (data.commandId || data.requestUuid) {
      finePrint = 'Error code: ';
      if (data.commandId) finePrint += data.commandId;
      if (data.commandId && data.requestUuid) finePrint += '/';
      if (data.requestUuid) finePrint += `${data.requestUuid.substring(data.requestUuid.length - 8)}`;
    }
    this.popup({header: 'Error', text: message, renderHtml: false, finePrint: finePrint});
  }

  getApprovalLevelCalculator() {
    const calculateApprovalLevels = _.memoize((task: TaskWithOverrideLevel) => {
      if (task.taskGroup !== 'COMMAND_APPROVAL') {
        return [];
      }

      const approvedLevels = (task.approvedLevels || []).map(level => level.level);
      const levels = [];
      for (let level = 0; level < task.approveUserIds.length; level++) {
        if (!approvedLevels.includes(level) && task.approveUserIds[level].includes(this.authentication.context.id)) {
          levels.push(level);
        }
      }

      return levels;
    });

    const calculateLocalOverrideLevels = _.memoize((task: TaskWithOverrideLevel) => {
      if (task.taskGroup !== 'COMMAND_APPROVAL') {
        return [];
      }

      const approvedLevels = (task.approvedLevels || []).map(level => level.level);
      const levels = [];
      for (let level = 0; level < task.approveUserIds.length; level++) {
         if (!approvedLevels.includes(level)) {
          levels.push(level);
        }
      }

      return levels;
    });

    // use the same array to avoid triggering infinite change detection cycle
    const emptyArray: never[] = [];

    return {
      getApprovalLevels(task: TaskWithOverrideLevel) {
        if (!task) {
          return emptyArray;
        }

        return calculateApprovalLevels(task);
      },

      getLocalOverrideLevels(task: TaskWithOverrideLevel) {
        if (!task) {
          return emptyArray;
        }

        return calculateLocalOverrideLevels(task);
      }
    };
  }
}

nxModule.service('dashboardActionService', DashboardActionService);
