import {ProductGroup, ProductStatus} from 'components/service/product.types';

export type AutomaticDebitSchedule = 'DAILY' | 'DUE_DATE' | 'MONTH_END';
export type AutomaticDebitStrategy = 'FULL' | 'PARTIAL';

export interface AutomaticDebitAgreement {
  id?: number;
  accountId: number;
  targetId: number;
  strategy: AutomaticDebitStrategy;
  orderNo: number;
  keepMaintainingBalance: boolean;
  executeOnAccountCredit: boolean;
}

export interface AutomaticDebitAgreementDetails extends AutomaticDebitAgreement {
  productNumber: string,
  accountName: string,
  branchId: number,
  productGroup: ProductGroup,
  status: ProductStatus
  hasUnpaidInstallments: boolean
}

export interface AutomaticDebitAgreementConfig {
  schedule?: AutomaticDebitSchedule;
  hierarchies: AutomaticDebitAgreementHierarchy[];
}

export interface AutomaticDebitAgreementHierarchy {
  orderNo: number;
  strategy?: AutomaticDebitStrategy;
  keepMaintainingBalance: boolean;
  executeOnAccountCredit: boolean;
  productDefinitionId?: number;
}

export interface AutomaticDebitAgreementPreview {
  accountProductNumber?: string;
  accountProductName?: string;
  targets: Array<{
    orderNo: number;
    productNumber: string;
    productName: string;
  }>
}

export type AutomaticDebitAgreementConfigMode = 'ACCOUNT' | "TARGET";

export const automaticDebitScheduleOptions : {label: string, value: AutomaticDebitSchedule}[] = [
  {
    label: 'Daily at EOD',
    value: 'DAILY'
  },
  {
    label: 'On due date',
    value: 'DUE_DATE'
  },
  {
    label: 'Month end (Calendar)',
    value: 'MONTH_END'
  }
];

export const automaticDebitStrategyOptions : {label: string, value: AutomaticDebitStrategy}[] = [
  {
    label: 'Always pay in full',
    value: 'FULL'
  },
  {
    label: 'Pay as much as possible',
    value: 'PARTIAL'
  }
];
