import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import nxModule from 'nxModule';


const templateUrl = require('./amortization-schedule-report.template.html');
class AmortizationScheduleReport {
  constructor($route, reportModal) {

    this.displayReport = () => {
      this.loanId = $route.current.params['loanId'];
      let params = {
        productId: this.loanId,
        checkedBy: this.checkedByName,
        approvedBy: this.approvedByName
      };
      let reportName = 'AmortizationScheduleReport';
      reportModal.display({
        params: params,
        reportCode: reportName
      });
    };
  }
}

nxModule.component('amortizationScheduleReport', {
  templateUrl: templateUrl,
  controller: AmortizationScheduleReport
});