import nxModule from 'nxModule';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

class DynamicReportService {
  constructor(http, breadcrumbs) {
    this.http = http;
    this.breadcrumbs = breadcrumbs;
  }

  updateBreadcrumb(report, reportFile) {
    setBreadcrumbs(
      this.breadcrumbs, 'dynamic-report-label', report.name || 'New');
    setBreadcrumbs(
      this.breadcrumbs, 'dynamic-report-file-label', (reportFile && reportFile.name) || 'New' );
  }

  getReports() {
    return this.http.get('/reports/dynamic-report').toPromise();
  }

  getReport(reportId) {
    return this.http.get(`/reports/dynamic-report/${reportId}`).toPromise();
  }

  getDiskFiles() {
    return this.http.get('/reports/disk-files').toPromise();
  }
}

nxModule.service('dynamicReportService', DynamicReportService);