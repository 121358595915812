import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';
import systemPropertyService from '../../../../../react/system/systemPropertyService';
import {healthCheckPhase} from "constants/healthCheckPhase";

const templateUrl = require('./end-the-day.template.html');
nxModule.component('endTheDay', {
  templateUrl: templateUrl,
  bindings: {
    branchId: '<',
    autoCcAndSod: '<'
  },
  controller: function ($interval, $location, $filter, http, config, authentication, notification, productDefinitionService,
                        branchService, confirmationTemplate, holidayService, actionCommand, $timeout,
                        workingDayService, command, popup) {

    // JS 'magic'
    let that = this;
    // Read product definitions
    that.productDefinitions = null;
    // Product mapping configuration (required to determine if end of day process is allowed)
    that.mappingStatus = null;
    // Non empty product mappings (non empty = at least 1 product instance exists)
    that.blockingProductMappings = null;
    // Batch step status
    that.stepStatuses = null;
    // Switch to enable step refetching
    that.refetchEnabled = false;
    // Indicates that the job is pending but not yet started
    that.hasPendingEodJob = true;
    // by default, set to TRUE and simply override the value depending on the HCs retrieved.
    that.hasFailedHC = true;
    that.branchHoliday = null;
    that.bankHoliday = null;

    // clear branch cache
    branchService.evict();

    /**
     * Read product definitions.
     * Definitions are required to map ledger status with human-readable product name.
     */
    const productDefinitionSub = productDefinitionService.toObservable().subscribe(definition => {
      that.productDefinitions = definition;
    });

    const ignoreHealthCheck = systemPropertyService.getProperty('ALLOW_EOD_DESPITE_HEALTH_CHECK_FAIL');

    that.ignoreHealthCheck = ignoreHealthCheck === 'TRUE';
    const ls = systemPropertyService.getProperty('LEDGER_SUPPORT');
    that.ledgerSupported = ls === 'TRUE';

    if (that.ledgerSupported) {
      // TODO = fetch GL mappings status
    } else {
      that.mappingStatus = {};
    }

    that.$onInit = async () => {
      await initialize();
    };

    const initialize = async () => {
      const branches = await branchService.toPromise();
      const authBranch = _.find(branches, {id: that.branchId});

      if (authBranch) {
        that.systemDate = moment(authBranch.systemDate).toDate();
        that.branchName = authBranch.name;
        that.branch = authBranch;

        await initializeJobSystemDate();
        await fetchHealthChecks();
        await showInfoOnUpcomingHoliday();
      }

    };

    const initializeJobSystemDate = async() => {
      const workingDay = await http.get(`/management/working-days?branchId=${that.branchId}`).toPromise();

      // If current working day isn't started nor counter closed, it means
      // the job has already started, we need to get the previous
      let jobSystemDate;
      if (!that.hasPendingEodJob && that.autoCcAndSod && !['STARTED', 'COUNTER_CLOSED'].includes(workingDay.status)) {
        jobSystemDate = moment(workingDay.systemDate).subtract(1, 'days').format('YYYY-MM-DD');
      } else {
        jobSystemDate = moment(workingDay.systemDate).format('YYYY-MM-DD');
      }

      that.jobSystemDate = jobSystemDate;

      await that.fetchStepStatuses();
    };

    const segregateByPhase = (healthChecks) => {
      const closeCounterPhase = {};
      const healthCheckPhase = {};

      Object.keys(healthChecks).forEach(group => {
        const closeCounterHcs = [];
        const healthCheckHcs = [];

        healthChecks[group].forEach(hc => {
          if (hc.phases.includes('CLOSE_COUNTERS')) {
            closeCounterHcs.push(hc);
          } else {
            healthCheckHcs.push(hc);
          }
        });

        if (closeCounterHcs.length > 0) {
          closeCounterPhase[group] = closeCounterHcs;
        }
        if (healthCheckHcs.length > 0) {
          healthCheckPhase[group] = healthCheckHcs;
        }
      });

      return {
        'HEALTH_CHECK': healthCheckPhase,
        'CLOSE_COUNTERS': closeCounterPhase
      }
    };

    const fetchHealthChecks = async () => {
      if (that.refetchEnabled || that.ignoreHealthCheck) {
        return;
      }

      const healthChecks = await http.get(
        `/system/health-details?includeStatement=true&showOnlyFailed=true&phases=HEALTH_CHECK,CLOSE_COUNTERS&branchId=${that.branchId}`,
        { nxLoaderText: 'Validating health checks for End of Day' }
      ).toPromise();
      const segregated = segregateByPhase(healthChecks);

      that.failedHcForCloseCountersPhase = segregated[healthCheckPhase.CLOSE_COUNTERS];
      that.hasFailingCloseCounterHc = Object.values(that.failedHcForCloseCountersPhase).length > 0;

      that.failedHcForHeathCheckPhase = segregated[healthCheckPhase.HEALTH_CHECK];
      that.hasFailingHealthCheckHc = Object.values(that.failedHcForHeathCheckPhase).length > 0;

      that.hasFailedHC = that.hasFailingCloseCounterHc || that.hasFailingHealthCheckHc;
    };

    const showInfoOnUpcomingHoliday = async () => {
      const holidays = await holidayService.fetchHoliday(that.branchId, moment(that.systemDate).add(1, 'days'), 'ALL');
      that.branchHoliday = holidays.find(h => h.branchId === that.branchId);
      that.bankHoliday = holidays.find(h => !h.branchId);
    };

    /**
     * Translates product definition ID into it's name
     */
    that.getProductDefinition = (id) => {
      return that.productDefinitions && _.find(that.productDefinitions, {id: id});
    };

    /**
     * Mappings status are valid if all product definitions having at least
     * one product instance are configured
     */
    that.validateMappingsStatus = () => {
      // TODO = implement mappings validation for new GL mappings model
      return true;
    };

    /**
     * Refetch should be stopped if:
     * - If at least 1 step is STOPPED/FAILED/ABANDONED/UNKNOWN
     * - All steps are COMPLETED or PENDING (null)
     */
    that.fetchStepStatuses = async () => {
      try {
        // Read batch process step statuses from server
        const batchSummary = await http.get(`/batch-jobs/${that.branchId}/eod-status?systemDate=${that.jobSystemDate}`, {nxLoaderSkip: true}).toPromise();
        that.stepStatuses = batchSummary.steps;
        that.hasPendingEodJob = batchSummary.hasPendingJob;

        const stopStep = _.some(that.stepStatuses, (step) => ['STOPPED', 'FAILED', 'ABANDONED', 'UNKNOWN'].includes(step.status));
        const allPendingStatus = _.every(that.stepStatuses, (step) => step.status === null);
        const allCompletedStatus = _.every(that.stepStatuses, (step) => step.status === 'COMPLETED');

        that.refetchEnabled = !(stopStep || (!that.hasPendingEodJob && allPendingStatus) || allCompletedStatus) ;

        if (allCompletedStatus) {
          // Get latest branch and system date
          await branchService.refetch();
          await initialize();
          notifyEodDone();
        }
      } catch (error) {
        console.error('An error occurred', error);
        notification.show("Error", "Failed to fetch batch process status");
      }
    };

    const notifyEodDone = () => {
      popup({
        header: 'End of Day Completed',
        text: `Successfully ran End of Day job. New system date is ${moment(that.systemDate).format('YYYY-MM-DD')}`
      });
    };

    // Refetch steps statuses every [batchProcessStatusFetchInterval] ms
    that.fetchInterval = $interval(async () => {
      if (that.refetchEnabled) {
        await that.fetchStepStatuses();
      }
    }, config.batchProcessStatusFetchInterval);

    /**
     * End process should be available if:
     * - Product GL mappings are valid
     * - All steps are null (PENDING)
     */
    that.endOfDayEnabled = () => {
      const branchDateFetched = !!that.systemDate;
      const health = that.ignoreHealthCheck || !that.hasFailedHC ;
      return that.validateMappingsStatus() && health && !that.refetchEnabled && _.every(that.stepStatuses, {status: null}) && branchDateFetched;
    };

    that.cancelChanges = () => {
      that.refetchEnabled = false;
      $location.path(`/dashboard/miscellaneous-transactions`);
    };

    that.onEodExecuted = () => {
      notification.show('Success', 'Day end process started');
      that.refetchEnabled = true;
    };

    that.doSave = () => {
      const userId = authentication.context.id;

      if (that.autoCcAndSod) {
        command.execute(
          'EndDayWithCloseCounterAndStartDay',
          { branchId: that.branchId, automationMode: 'BRANCH' },
          { nxLoaderText: 'Executing end of day...' }
        ).success(that.onEodExecuted);
      } else {
        actionCommand.execute('END_DAY', {userId, branchId: that.branchId}, that.onEodExecuted);
      }
    };

    that.save = async () => {
      const eodConfirmation = await confirmEod();

      if (eodConfirmation && (that.branchHoliday || that.bankHoliday)) {
        $timeout(confirmUpcomingHoliday, 500); // solves hiding second pop up right after it slides top to bottom
      } else if (eodConfirmation) {
        that.doSave();
      }
    };

    const confirmEod = async () => await confirmationTemplate({
      question: 'Do you want to end the day?',
      details: [
        {label: 'Branch', description: that.branchName},
        {label: 'Date', description: $filter('prettyDate')(that.systemDate)}
      ],
      warning: 'This operation cannot be reverted safely.<br>Please make sure that the day can be closed and you are closing the correct day.',
    });

    const confirmUpcomingHoliday = async () => {
      const details = [{label: 'Date', description: that.branchHoliday?.date ?? that.bankHoliday?.date}];
      if(that.bankHoliday) {
        details.unshift({label: 'Bank holiday', description: that.bankHoliday.name});
      }

      if(that.branchHoliday) {
        details.unshift({label: 'Branch holiday', description: that.branchHoliday.name});
      }

      return await confirmationTemplate({
        question: `Tomorrow is a holiday, please make sure that the holiday's date is correct.`,
        details: details,
        warning: `When holiday is reached all operation's date will match next working day's system date which cannot be reverted safely later.`,
        yesCallback: that.doSave
      });
    };

    that.$onDestroy = () => {
      $interval.cancel(that.fetchInterval);
      productDefinitionSub.unsubscribe();
    }
  }
});
