import nxModule from "nxModule";
import {IAttributes, IAugmentedJQuery, IDirective, IScope} from "angular";
import PermissionService, {PermissionContext} from "shared/utils/permission.service";

interface PermissionAttributes extends IAttributes {
  permissions: string
}

function permissionDirective(permissionService: PermissionService): IDirective {
  return {
    require: '?^permissionContext',
    link: async (scope: IScope, element: IAugmentedJQuery, attributes: PermissionAttributes, permissionContext: any): Promise<void> => {
      const validatePermission = async (context?: PermissionContext) => {
        const requiredPermissions: string[] = attributes.permissions.split(',').map(p => p.trim());
        const hasPermission = await permissionService.hasPermission(requiredPermissions, context)
        if (hasPermission) {
          element.show();
        } else {
          element.hide();
        }
      }
      scope.$watch(() => permissionContext ? permissionContext.context : null, () => {
        if (permissionContext && permissionContext.context) {
          validatePermission(permissionContext.context);
        }
      });
      await validatePermission();
    }
  }
}

nxModule.directive('permissions', permissionDirective);

