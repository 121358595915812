import {centerResolver} from "./resolvers";
import angular from "angular-route";

export const centerRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/centers/create', {
    template: '<center-details />',
    label: 'New center'
  }], ['/center/:centerId/details', {
    template: '<center-details center="$resolve.center"/>',
    label: 'Center details',
    resolve: {
      ...centerResolver
    }
  }], ['/center/:centerId/groups', {
    template: '<center-groups center="$resolve.center"/>',
    label: 'Center groups',
    resolve: {
      ...centerResolver
    }
  }]
];