import nxModule from 'nxModule';

import templateUrl from './agent-candidacy-header.template.html';
import {
  accountActivatedStatuses,
  AgentApplicationStatus,
  AgentApplicationSystemStatus,
  AgentCandidacy,
  newStatuses
} from './agent-candidacy.types';
import {Branch} from "management/BranchTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import Authentication from "shared/utils/authentication";
import {BranchService} from "components/service/branch.service";

class AgentCandidacyHeader {

  branches: Branch[] = [];
  application!: AgentCandidacy;
  editMode!: boolean;
  editStatus!: boolean;
  applicationStatuses: AgentApplicationStatus[] = [];

  constructor(private customerCache: CustomerCache,
              private branchService: BranchService,
              private authentication: Authentication) {}

  async $onInit() : Promise<void> {
    const branches = await this.branchService.toPromise();
    const branchIds = this.authentication.context.branchIds;
    this.branches = branches.filter(b => branchIds.includes(b.id));
    const allStatuses = Object.values(AgentApplicationStatus);

    if (!this.editStatus) {
      this.applicationStatuses = allStatuses;
      return;
    }

    switch (this.application.systemStatus) {
      case AgentApplicationSystemStatus.NEW:
        this.applicationStatuses = [...newStatuses];
        if (this.application.status == AgentApplicationStatus.APPLICATION_FORM_COMPLETE) {
          // Add currently set status (APPLICATION_FORM_COMPLETE) so user can expect what status is gonna be set upon application save
          // Right after status has been changed to anything else it won't be available anymore
          this.applicationStatuses.unshift(AgentApplicationStatus.APPLICATION_FORM_COMPLETE);
        }
        break;
      case AgentApplicationSystemStatus.ACCOUNT_ACTIVATED:
        this.applicationStatuses = [...accountActivatedStatuses];
        break;
    }
  }
}

nxModule.component('agentCandidacyHeader', {
  templateUrl: templateUrl,
  bindings: {
    application: '=',
    editMode: '<',
    editStatus: '<'
  },
  controller: AgentCandidacyHeader
});
