import StatusContainer from '../management/status/StatusContainer';
import RouteDefinition from "./RouteDefinition";

const routes: RouteDefinition[] = [
  {
    name: 'Misc Support',
    path: '/support',
  },
  {
    name: 'System status',
    path: '/support/status',
    Component: StatusContainer
  }
];

export default routes;