import angular from 'angular';
import {FileCache} from 'components/service/file.cache.types';
import contentDisposition from 'content-disposition';
import $ from 'jquery';

import nxModule from 'nxModule';
import {HttpService, HttpServiceResponse} from 'shared/utils/httpService';

export interface AttachedFile {
  id: number;
  userId: number;
  customerId?: number;
  productId?: number;
  hookType: string;
  hookId: number;
  storageKey: string;
  fileName: string;
  fileContentType: string;
  fileSizeInBytes: number;
  tags: string[];
  remarks?: string;
  checksum: string
}

export type UploadFileInput = string | Blob;

export type UploadFileParams = {
/**
 * @deprecated The functionality doesn't work in the backend
 */
  allowDuplicates?: boolean
}

export type UploadFileConfig = {
  uploadEventHandlers?: {
    progress: (e: {loaded: number; total: number}) => {}
  };
  nxLoaderSkip?: boolean;
  additionalData?: {[key: string]: string | Blob};
}

export type UploadFileOutput = {id: number};

export class FileService {
  constructor(private http: HttpService, private fileCache: FileCache) { }

  getMetadata(id: number): HttpServiceResponse<AttachedFile> {
    return this.http.get(`/files/metadata/${id}`);
  }

  uploadFile(file: UploadFileInput, params: UploadFileParams = {}, config: UploadFileConfig = {}): HttpServiceResponse<UploadFileOutput> {
    const fd = new FormData();
    fd.append('file', file);

    const {additionalData = {},} = config;
    Object.keys(additionalData).forEach(key => {
      fd.append(key, additionalData[key]);
    });

    return this.http.post(`/files?${$.param(params)}`, fd, {
      transformRequest: angular.identity,
      nxLoaderText: 'Uploading file...',
      headers: {'Content-Type': undefined},
      ...config,
    });
  }

  updateRemarks(fileId: number, remarks: string, config = {}): HttpServiceResponse<void> {
    return this.http.put(`/files/metadata/${fileId}/remarks`, remarks, config);
  }

  deleteFile(fileId: number): HttpServiceResponse<void> {
    return this.http.doDelete(`/files/${fileId}`);
  }

  downloadFile(fileId: number, skipLoader: boolean, useCache?: boolean): Pick<HttpServiceResponse<Blob>, 'toPromise'> {
    if (skipLoader === undefined) skipLoader = false;

    if (!useCache) {
      return this.http.get<Blob>(`/files/${fileId}`, {
        responseType: 'blob',
        nxLoaderSkip: skipLoader
      })
    } else {
      let successCallback: null | ((file: Blob, status: any, headers: any) => void) = null;
      let errorCallback: null | ((error: Error) => void) = null;

      this.fileCache.withParam(fileId).toObservable().first().subscribe((data: Blob, status?: any, headers?: any) => {
        if (successCallback) successCallback(data, status, headers);
      }, (err: Error) => {
        console.log('Error when fetching file: ', err);
        if (errorCallback) errorCallback(err);
      });

      const callbackWrapper = {
        success: (callback: (data: Blob, status: any, headers: any) => void) => {
          successCallback = callback;
          return callbackWrapper;
        },
        error: (callback: (error: Error) => void) => {
          errorCallback = callback;
          return callbackWrapper;
        },
        toPromise() {
          return new Promise<Blob>((resolve, reject) => {
            successCallback = resolve;
            errorCallback = reject;
          });
        }
      };

      return callbackWrapper;
    }
  }

  downloadFileToDisk(fileId: number, fallbackFileName: string): Promise<boolean> {
    return new Promise(((resolve, reject) => {
      this.http.http({
        url: `/files/${fileId}`,
        method: 'GET',
        responseType: 'blob',
        nxLoaderText: 'Downloading file'
      }).success((data, status, headers) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file = window.URL.createObjectURL(data);

        const filename = this.parseHeadersForContentFilename(headers) ?? fallbackFileName;
        this.clickOnLink(file, filename);

        window.URL.revokeObjectURL(file);
        resolve(true);
      }).error(data => reject(data));
    }));
  }

  parseHeadersForContentFilename(headers: (name: string) => string): string | null {
    const contentDispositionHeader = headers('content-disposition');
    if (!contentDispositionHeader) {
      return null;
    }
    const disposition = contentDisposition.parse(contentDispositionHeader);
    const {filename,} = disposition.parameters;
    return filename;
  }

  clickOnLink(url: string, download: string): void {
    const a = document.createElement('a');
    a.download = download;
    a.href = url;
    a.click();
  }
}

nxModule.service('fileService', FileService);
