import {ReportParams} from 'components/report/report.service';

/*
 * Recursively flattens non-array objects.
 * Implementation follows <code>content-type: application/x-www-form-urlencoded</code> convention for param's name
 * generation which is tightly used by our reports backend.
 *
 * <pre>
 * Source:
 *   {
 *     branchId: [1],
 *     typeId: [null],
 *     roleId: 1,
 *     date_range: {
 *       from: "2017-12-16",
 *       to: "2017-12-16"
 *     }
 *     LEDGER_ACCOUNT_CODE[]: [ASSET/ACC, ASSET/ACC2]
 *   }
 * </pre>
 *
 * <pre>
 * Result:
 *   {
 *     branchId[]: [1],
 *     typeId[]: [null],
 *     roleId: 1,
 *     date_range[from]: "2017-12-16",
 *     date_range[to]: "2017-12-16",
 *     LEDGER_ACCOUNT_CODE[]: [ASSET/ACC, ASSET/ACC2]
 *   }
 * </pre>
 */
export const prepareFlatReportJson = (source: ReportParams): Record<string, unknown> => {
  const result: Record<string, unknown> = {};
  if (source === null) {
    return source;
  }

  if (typeof source !== 'object') {
    return source;
  }

  for (const [key, value] of Object.entries(source)) {
    if (typeof value == 'object' && !Array.isArray(value) && value !== null) {
      const flatten = prepareFlatReportJson(value);
      for (const k in flatten) {

        if (!Object.prototype.hasOwnProperty.call(flatten, k)) {
          continue;
        }

        result[key + `[${k}]`] = flatten[k];
      }
    } else {
      if (!key.endsWith('[]') && Array.isArray(value)) {
        result[key + '[]'] = value;
      } else {
        result[key] = value;
      }

    }
  }
  return result;
};