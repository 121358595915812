import nxModule from 'nxModule';
import {INgModelController} from "angular";

import templateUrl from './micr-number.template.html';

class MicrNumber {
  private ngModel!: INgModelController;

  micrNumber: unknown;

  $onInit(): void {
    this.ngModel.$render = () => {
      this.micrNumber = this.ngModel.$modelValue;
    };
  }

  $onChanges(): void {
    this.updateNgModel();
  }

  updateNgModel(): void {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(this.micrNumber);
  }
}

nxModule.component('micrNumber', {
  templateUrl,
  require: {
    ngModel: '^ngModel'
  },
  controller: MicrNumber
});
