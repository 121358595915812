import nxModule from 'nxModule';
import _ from 'lodash';
import {BranchService} from '../../../../../../service/branch.service';
import {CoMaker, Loan} from '../../../../../../service/loan.types';

import './customer-loan-co-makers.style.less';
import templateUrl from './customer-loan-co-makers.template.html';
import {HttpService} from '../../../../../../../shared/utils/httpService';
import {Branch} from "management/BranchTypes";
import angular, {IFormController} from "angular";
import {Customer, WithCoMadeLoans} from "customer/CustomerTypes";
import {Dict} from "shared/common/dict.types";
import Notification from "shared/utils/notification";

interface CoMakerDetails extends CoMaker {
  customerNumber: string,
  effectiveName: string,
  birthDate: any,
  branchName?: string,
  activeLoans: number,
  numberOfCoMadeLoans: number,
  maxComakerTagLimit: number,
}

class CustomerLoanCoMakers {

  private loan!: Loan;
  protected editMode!: boolean;
  protected coMakerDetails: CoMakerDetails[] = []
  protected branches: Branch[] = []
  private form!: IFormController;
  private originalLoanCoMakers: CoMaker[] = [];

  constructor(private notification: Notification,
              private branchService: BranchService,
              private http: HttpService,
              private dict: Dict) {
  }

  async $onInit(): Promise<void> {
    this.loan.coMakers = this.loan.coMakers || [];
    const [ignored, branches] = await Promise.all([
      this.dict.onLoadingCompleteAsync(),
      this.branchService.toPromise()
    ]);

    this.originalLoanCoMakers = angular.copy(this.loan.coMakers);
    this.branches = branches;
    this.coMakerDetails = await this.fetchCoMakerDetails();
  }

  private async searchCustomers(coMakers: CoMaker[]): Promise<(Customer & WithCoMadeLoans)[]> {
    const customerIds: number[] = coMakers.map(c => c.customerId);
    return await this.http.get<(Customer & WithCoMadeLoans)[]>(`/customers/all?ids=${customerIds.join(',')}&includeCoMadeLoans=true`).toPromise();
  }

  private async fetchCoMakerDetails(): Promise<CoMakerDetails[]> {
    const customers: ReadonlyArray<Customer & WithCoMadeLoans> = await this.searchCustomers(this.loan.coMakers);
    return await Promise.all(this.loan.coMakers.map(async coMaker => {
      const customer = customers.find(c => coMaker.customerId === c.id);
      if(!customer) {
        throw new Error(`Failed to find customer for id ${coMaker.customerId}`);
      }

      return await this.createCoMakerDetails(coMaker, customer);
    }));
  }

  async addCoMaker(customer: Customer & WithCoMadeLoans): Promise<void> {
    if (customer.id === this.loan.customerId) {
      this.notification.show('Warning', 'Selected Customer cannot be relative to himself.');
      return;
    }

    if (_.find(this.coMakerDetails, {customerId: customer.id})) {
      this.notification.show('Warning', 'Selected Customer has already been added.');
      return;
    }

    const coMaker = {customerId: customer.id, loanId: this.loan.id};
    this.loan.coMakers.push(coMaker);
    this.coMakerDetails.push(await this.createCoMakerDetails(coMaker, customer));
  }

  hasReachedComakerLimit(coMaker: CoMakerDetails): boolean {
    if (!coMaker.typeId || coMaker.typeId !== this.dict.getId('LOAN_CO_MAKER_TYPE', 'CO_MAKER')) {
      return false;
    }

    if (coMaker.maxComakerTagLimit == null) {
      return false;
    }

    this.originalLoanCoMakers.find(c => c.customerId === coMaker.customerId);
    return coMaker.maxComakerTagLimit === 0
      || (coMaker.numberOfCoMadeLoans >= coMaker.maxComakerTagLimit
        // only validate tag limit for newly added coMakers
      && !_.some(this.originalLoanCoMakers, c => c.customerId === coMaker.customerId));
  }

  async createCoMakerDetails(coMaker: CoMaker, customer: Customer & WithCoMadeLoans): Promise<CoMakerDetails> {
    const loans: ReadonlyArray<Loan> = await this.http.get<Loan[]>('/products/loans?customerId=' + customer.id).toPromise();

    return {
      customerId: customer.id,
      customerNumber: customer.customerNumber,
      effectiveName: customer.effectiveName,
      birthDate: customer.individualData?.birthDate,
      branchName: this.branches.find(b => Number(b.id) === Number(customer.branchId))?.name,
      activeLoans: CustomerLoanCoMakers.countActiveLoans(loans),
      numberOfCoMadeLoans: customer.coMadeLoanIds ? customer.coMadeLoanIds.length : 0,
      maxComakerTagLimit: customer.maxComakerTagLimit,
      typeId: coMaker.typeId
    }
  }

  private static countActiveLoans(loans: ReadonlyArray<Loan>): number {
    return loans.filter(loan => loan.status === 'ACTIVE').length;
  }

  removeCoMaker(coMakerDetails: CoMakerDetails): void {
    _.remove(this.loan.coMakers, el => el.customerId === coMakerDetails.customerId);
    _.remove(this.coMakerDetails, el => el.customerId === coMakerDetails.customerId);
    this.form.$setDirty();
  }

  coMakerTypeChanged(details: CoMakerDetails): void {
    const coMaker = this.loan.coMakers.find(c => c.customerId === details.customerId);
    if (coMaker) {
      coMaker.typeId = details.typeId;
    }
  }
}

nxModule.component('customerLoanCoMakers', {
  templateUrl: templateUrl,
  bindings: {
    form: '=',
    loan: '=',
    editMode: '<'
  },
  controller: CustomerLoanCoMakers
});
