import {
  branchResolver,
  userResolver,
  branchIdResolver,
  agentIdResolver,
  deviceIdResolver,
  candidacyIdResolver
} from "./resolvers";
import angular from "angular-route";
import {NxRouteService} from "routes/NxRouteService";

export const agentBankingRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/agent-banking/agents', {
    template: '<agents-branches/>',
    label: 'Agents'
  }], ['/agent-banking/application', {
    template: `<new-customer-page module="'agent'"/>`,
    label: 'Agent Application'
  }], ['/agent-banking/application', {
    template: `<agent-candidacy-list/>`,
    label: 'Agent Application'
  }], ['/agent-banking/batch-upload', {
    template: `<agent-batch-upload/>`,
    label: 'Batch Upload Agents'
  }], ['/agent-banking/application/create', {
    template: `<agent-candidacy edit-mode="true"/>`,
    label: 'New Agent Application'
  }], ['/agent-banking/application/edit/:id', {
    template: '<agent-candidacy edit-mode="true" edit-status="true"/>',
    label: 'Edit Agent Application'
  }], ['/agent-banking/application/edit/status/:id', {
    template: '<agent-candidacy edit-mode="false" edit-status="true"/>',
    label: 'Edit Agent Application Status'
  }], ['/agent-banking/application/view/:id', {
    template: '<agent-candidacy edit-mode="false"/>',
    label: 'View Agent Application'
  }], ['/agent-banking/application/process/:id', {
    template: '<agent-candidacy edit-mode="false" process-mode="true"/>',
    label: 'Process Agent Application'
  }], ['/agent-banking/agents/:branchId', {
    template: '<agents-list branch="$resolve.branch"/>',
    label: 'agents-list-label',
    resolve: {
      ...branchResolver,
    }
  }], ['/agent-banking/agents/:branchId/agent/create/:candidacyId', {
    template: '<agents-details branch="$resolve.branch" candidacy-id="$resolve.candidacyId"/>',
    label: 'New Agent',
    resolve: {
      ...branchResolver,
      ...candidacyIdResolver
    }
  }], ['/agent-banking/agents/:branchId/agent/:agentId', {
    template: '<agents-details agent-id="$resolve.agentId" branch="$resolve.branch"/>',
    label: 'agent-details-label',
    resolve: {
      ...agentIdResolver,
      ...branchResolver
    }
  }], ['/agent-banking/agents/:branchId/agent/:agentId/activate', {
    template: '<agent-activation agent-id="$resolve.agentId" branch="$resolve.branch"/>',
    label: 'Activate',
    resolve: {
      ...agentIdResolver,
      ...branchResolver
    }
  }], ['/agent-banking/agents/:branchId/agent/:agentId/user/:userId/mfa', {
    template: '<mfa-configuration-page user="$resolve.user" branch="$resolve.branch" links="$resolve.links"/>',
    label: 'Multi-Factor Authentication Configuration',
    resolve: {
      ...userResolver,
      ...branchResolver,
      links: /* @ngInject */ ($route: NxRouteService) => {
        const {branchId, agentId} = $route.current.params;
        return {
          return: `/agent-banking/agents/${branchId}/agent/${agentId}`,
        };
      },
    }
  }], ['/agent-banking/agents/:branchId/agent/:agentId/devices/:deviceId', {
    template: '<agent-device-details branch-id="$resolve.branchId" agent-id="$resolve.agentId" device-id="$resolve.deviceId"/>',
    label: 'Edit Agent Device',
    resolve: {
      ...agentIdResolver,
      ...branchIdResolver,
      ...deviceIdResolver
    }
  }]
];