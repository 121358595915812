import nxModule from 'nxModule';
import {NgTableParams} from 'ng-table';
import OracleHandoffMappingService, {OracleHandoffMappingDetails} from './oracle-handoff-mapping.service';
import templateUrl from './oralce-handoff-mapping.template.html';
import {CommandService} from "shared/utils/command/command.types";

interface OracleHandoffFilter {
  accountName: string;
}

class OracleHandoffMappingComponent {

  private filter: OracleHandoffFilter = {accountName: ''};
  private tableConfig: NgTableParams<OracleHandoffMappingDetails> = new NgTableParams({}, {});

  constructor(
    private readonly oracleHandoffMappingService: OracleHandoffMappingService,
    private readonly command: CommandService
  ) {
  }

  async $onInit(): Promise<void> {
    await this.setTableConfig();
  }

  async synchronize() {
    await this.command.execute('SynchronizeOracleHandoffMappings', {}).toPromise();
    this.oracleHandoffMappingService.mappings.evict();
    await this.setTableConfig();
  }

  async save(mapping: OracleHandoffMappingDetails) {
    await this.command.execute('UpdateOracleHandoffMapping', mapping).toPromise();
    await this.oracleHandoffMappingService.mappings.refetch();
  }

  private async setTableConfig() {
    this.tableConfig = new NgTableParams({
      page: 1,
      count: 10,
      filter: this.filter
    }, {
      counts: [],
      dataset: await this.oracleHandoffMappingService.mappings.toPromise()
    });
  }
}

nxModule.component('oracleHandoffMapping', {
  templateUrl,
  controller: OracleHandoffMappingComponent
});
