import nxModule from 'nxModule';
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";

export class BranchQueryService {

  constructor(private http: HttpService) {
  }

  /**
   * Returns uncached data from the backend. Used for retrieving up-to-date data.
   * e.g. afterCutoff
   * @param id
   */
  getBranchById(id: number) : Promise<Branch> {
    return this.http.get<Branch>(`/management/branches/${id}`).toPromise();
  }
}

nxModule.service('branchQueryService', BranchQueryService);