import nxModule from 'nxModule';
import templateUrl from './atm-batch-acquirers-upload.template.html';
import './atm-batch-acquirers-upload.style.less';

import {NxIFilterService} from "../../technical/angular-filters";
import {Columns} from "../../common/dynamic-list/dynamic-list.component";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

interface AtmBatchUploadAcquirersOutput {
  rows: RowOutput[];
  formatErrors: FormatErrors;
}

interface RowFormatError {
  lineNo: number ;
  errors: string;
}

interface FormatErrors {
  [key: number]: string[];
}

interface RowOutput {
  row: Row;
  errors: string[];
}

interface TransactionError extends RowOutput {
  errorText: string
}

interface Row {
  lineNo: number;
  cbCode: string;
  date: Date;
  dateFormatted?: string;
  terminalNumber: string;
  accountNumber: string;
  transactionCode: string;
  amount: number;
  amountFormatted?: string;
  traceNumber: string;
  responseCode: string;
  edpTime: Date;
}

type SelectValue = {value: string, label: string};

interface AtmBatchAcquirersUploadView extends AtmBatchUploadAcquirersOutput {
  perRowFormatErrors: RowFormatError[],
  perRowBusinessErrors: TransactionError[],
  perRowSuccessfulPayments: RowOutput[],
}

interface AtmBatchAcquirersUploadInput {
  network: string;
  fileType: string;
  remarks?: string;
  attachedFileId: number;
}

const defaultUploadInput = () => ({
  network: 'BANCNET',
  fileType: 'SWIT',
});

class AtmBatchAcquirersUpload {
  upload: Partial<AtmBatchAcquirersUploadInput> = defaultUploadInput();

  readonly atmNetworks: SelectValue[] = [
    {value: 'BANCNET', label: 'Bancnet'}
  ];

  readonly fileTypes: SelectValue[] = [
    {value: 'SWIT', label: 'SWIT'}
  ];

  private simulation!: AtmBatchAcquirersUploadView | null;

  readonly formatErrorColumns: Columns = [
    { title: 'Line No', field: 'lineNo'},
    { title: 'Errors', field: 'errors'}
  ];

  private readonly successfulColumns: Columns = [
    { title: 'Line No', field: ['row', 'lineNo']},
    { title: 'Cb Code', field: ['row', 'cbCode']},
    { title: 'Date', field: ['row', 'dateFormatted']},
    { title: 'Terminal Number', field: ['row', 'terminalNumber']},
    { title: 'Account Number', field: ['row', 'accountNumber']},
    { title: 'Transaction Code', field: ['row', 'transactionCode']},
    { title: 'Amount', field: ['row', 'amountFormatted']},
    { title: 'Trace Number', field: ['row', 'traceNumber']},
    { title: 'Response Code', field: ['row', 'responseCode']},
    { title: 'Edp Time', field: ['row', 'edpTime']},
  ];

  readonly businessErrorColumns: Columns = [
    ...this.successfulColumns,
    { title: 'Errors', field: 'errorText'}
  ];

  constructor(private command: CommandService, private $filter: NxIFilterService, private $route: NxRouteService) {
  }

  resetForm(): void {
    this.clearSimulation();
    this.upload = defaultUploadInput();
  }

  clearSimulation(): void {
    this.simulation = null;
  }

  async simulatePayment(): Promise<void> {
    const { output } = await this.command.execute<unknown, AtmBatchUploadAcquirersOutput>('SimulateBatchUploadAcquirers', this.upload).toPromise();

    this.simulation = {
      perRowFormatErrors: this.prepareFormatErrors(output),
      perRowBusinessErrors: this.preparePerRowBusinessErrors(output),
      perRowSuccessfulPayments: this.prepareSuccessfulPayments(output),
      ...output
    };
  }

  private prepareSuccessfulPayments(output: AtmBatchUploadAcquirersOutput): RowOutput[] {
    return output.rows
      .filter(rowOutput => rowOutput.errors.length === 0)
      .map(rowOutput => ({
        errors: [],
        row: this.prepareRow(rowOutput.row),
      }));
  }

  private preparePerRowBusinessErrors(output: AtmBatchUploadAcquirersOutput): TransactionError[] {
    return output.rows
      .filter(rowOutput => rowOutput.errors.length > 0)
      .map(rowOutput => ({
        errorText: rowOutput.errors.join(', '),
        errors: rowOutput.errors,
        row: this.prepareRow(rowOutput.row),
      }));
  }

  private prepareRow(row: Row): Row {
    return {
      amountFormatted: this.$filter('nxCurrency')(row.amount),
      dateFormatted : this.$filter('date')(row.date),
      ...row,
    };
  }

  private prepareFormatErrors(output: AtmBatchUploadAcquirersOutput): RowFormatError[] {
    return Object.keys(output.formatErrors) //  lineNo -> listOf errors
      .sort((a, b) => parseInt(a) - parseInt(b)) // sort by line number
      .map((rowNumber: string): RowFormatError => ({
        lineNo: parseInt(rowNumber),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errors: output.formatErrors[rowNumber].join(', ')
      }));
  }

  async submitPayment(): Promise<void> {
    await this.command.execute('BatchUploadAcquirers', this.upload).toPromise();
    this.$route.reload();
  }
}

nxModule.component('atmBatchAcquirersUpload', {
  templateUrl,
  controller: AtmBatchAcquirersUpload,
});
