import {NxAutocompleteDataProviderOutput, NxFormikAutocomplete} from '@nextbank/ui-components';
import {useUserBranches} from 'management/BranchService';
import React from 'react';

interface Props {
  name: string;
  label: string;
}

const NxFormikBranchSelect = (props: Props): React.ReactElement | null => {
  const [{
    loading, error, data: branches
  }] = useUserBranches();

  if (!branches || loading) {
    return null;
  }

  if(error) {
    console.error('Failed to fetch branches', error);
    return null;
  }

  return <NxFormikAutocomplete<number>
    name={props.name}
    label={props.label}
    labelProvider={(value: number): string => {
      return branches.find(b => b.id === value)?.name ?? '';
    }}
    dataProvider={(text: string): Promise<NxAutocompleteDataProviderOutput<number>> => {
      const selectedBranches = text ? branches.filter(b => b.name.toLowerCase().includes(text.toLowerCase())) : branches;
      return Promise.resolve({
        values: selectedBranches
          .slice(0, 20)
          .map(b => b.id)
      });
    }}
  />;
};

export default NxFormikBranchSelect;
