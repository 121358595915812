export type PaymentMethod = {
  label: string,
  value: string
};

export const paymentMethods = Object.freeze([
  {
    label: 'Cash',
    value: 'CASH'
  },
  {
    label: 'GL',
    value: 'MEMO'
  },
  {
    label: 'Transfer',
    value: 'TRANSFER'
  }
]);