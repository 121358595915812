export const feeTypes = Object.freeze([
  'FIXED',
  'PERCENTAGE'
]);

export const agentChargeCollectionTypes = Object.freeze([
  'DEBIT_TO_CUSTOMER_ACCOUNT',
  'CASH'
]);

export const roundingScales = Object.freeze([
  'ONE_PESO',
  'HALF_PESO',
  'CENTAVO'
]);