import ImageCompressor from '@xkeshi/image-compressor';
import './file-upload.style.less';

import nxModule from 'nxModule';
import $ from 'jquery';
import Flipping from 'flipping/dist/flipping.web';
import systemPropertyService from '../../../../react/system/systemPropertyService';

const imageCompressor = new ImageCompressor();
const compressionOptions = {
  quality: '0.8',
};

const isImage = blob => /image\/.*/.test(blob.type);


// supports readonly attribute
/**
 * fileIds - ids of uploaded files, for no uploaded files pass empty array
 * fileAdditionalMetadata - js object will be sent in request body
 * validate - js object with validation https://github.com/danialfarid/ng-file-upload (ngf-validate property)
 */
const templateUrl = require('./file-upload.template.html');
nxModule.component('fileUpload', {
  templateUrl,
  require: {
    ngModel: '^ngModel',
  },
  bindings: {
    'fileAdditionalMetadata': '<',
    'validate': '<',
    'maxFiles': '<',
    'maxSize': '<',
    'showFileName': '<',
    'showRemarks': '<',
    'ngDisabled': '<',
    'enableWebcam': '<',
    'allowDuplicates': '<',
    'skipUpload': '<'
  },
  controller: function ($scope, $attrs, notification, fileService, $element, base64Service) {
    const that = this;

    $element.click(evt => that.ngModel.$setTouched());
    const flipping = new Flipping({
      parent: $element[0],
      duration: 300,
    });

    const centerImage = ($el) => {
      const {top, left} = $el.parent().offset();
      const zoom = '1 / 0.75';

      $el.width(`calc(80vw * ${zoom} - 190px)`);
      $el.css('left', `calc(10vw * ${zoom} - ${left}px + 190px)`);
      $el.css('top', `calc(100px - ${top}px`);

      $el.addClass('file-upload__thumbnail--zoomed-in');
      $el.removeClass('file-upload__thumbnail--zoomed-out');
    };

    const hideImage = ($el) => {
      $el.width('auto');
      $el.css('left', 'auto');
      $el.css('top', 'auto');

      $el.addClass('file-upload__thumbnail--zoomed-out');
      $el.removeClass('file-upload__thumbnail--zoomed-in');
    };

    const hideAllImages = () => {
      $('.file-upload__thumbnail--zoomed-in').each((idx, el) => {
        hideImage($(el));
      });
    };

    $element.on('click', '.file-upload__thumbnail--zoomed-in', el => {
      const $el = $(el.target);
      flipping.read();
      hideImage($el);
      flipping.flip();
    });

    that.downloadFile = file => {
      fileService.downloadFileToDisk(file.id);
    };

    $element.on('click', '.file-upload__thumbnail--zoomed-out', el => {
      const $el = $(el.target);
      hideAllImages();

      flipping.read();
      centerImage($el);
      flipping.flip();
    });

    const repositionAllZoomedInImages = () => {
      $('.file-upload__thumbnail--zoomed-in').each((idx, el) => {
        centerImage($(el));
      });
    };

    $(window).on('resize', repositionAllZoomedInImages);

    const modelValue = () => that.ngModel.$modelValue || [];

    const triggerNgModelUpdate = (newValue) => {
      that.ngModel.$setTouched();
      that.ngModel.$setViewValue(newValue);
    };

    that.$onInit = () => {
      that.initializeFileSizeValidator();
      that.maxFiles = that.maxFiles || Number.MAX_SAFE_INTEGER;
      that.ngModel.$isEmpty = value => !value || value.length === 0;
      that.showRemarks = that.showRemarks === undefined ? true : that.showRemarks;
      that.showFileName = that.showFileName === undefined ? true : that.showFileName;
      that.enableWebcam = that.enableWebcam === undefined ? true : that.enableWebcam;

      that.ngModel.$render = () => {
        modelValue().forEach(async file => {
          try {
            const [blob, metadata] = await Promise.all([
              fileService.downloadFile(file.id, true, false).toPromise(),
              fileService.getMetadata(file.id).toPromise()
            ]);

            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);
            fileReader.onload = () => {
              const base64Image = fileReader.result;
              Object.assign(file, {
                file: blob,
                thumbnail: base64Image
              });
              triggerNgModelUpdate([...modelValue()]); // create new copy of an array - needed for model to discover
                                                       // update
            };

            if (metadata) {
              Object.assign(file, { // here we update file in place
                remarks: metadata.remarks,
                fileName: metadata.fileName,
              });
            }
          } catch (error) {
            console.error(error);
            notification.show('Error', 'Error downloading file');
          }
        });
      };
    };

    $attrs.$observe('readonly', value => {
      that.readonly = value;
    });

    const uploadFileToStorage = (file, blob) => {
      fileService.uploadFile(blob, {allowDuplicates: that.allowDuplicates !== false}, {
        uploadEventHandlers: {
          progress: function (e) {
            file.progress = ~~(100 * e.loaded / e.total);
          }
        },
        nxLoaderSkip: true,
        additionalData: that.fileAdditionalMetadata,
      }).success(data => {
        file.uploaded = true;
        const {id,} = data;
        file.id = id;
        notification.show('Success', 'File uploaded');
        triggerNgModelUpdate([...modelValue()]);
      }).error(e => {
        console.error(e);
        file.uploaded = false;
        file.progress = 0;
        notification.show('Error', e.errorMessage || 'Couldn\'t upload file');
        triggerNgModelUpdate([...modelValue().filter(f => f !== file)]);
      });
    };

    that.addFiles = blobs => {
      const postprocessedBlobs = blobs.map(blob => {
        if (isImage(blob)) {
          return imageCompressor.compress(blob, compressionOptions)
        }

        return blob;
      });

      const addFileToModel = (file, blob) => {
        if (that.skipUpload) {
          // skip upload and model update, return blob file with model
          that.ngModel.$setTouched();
          that.ngModel.$setViewValue(blob);
        } else {
          triggerNgModelUpdate([...modelValue(), file]);
          uploadFileToStorage(file, blob);
        }
      };

      Promise.all(postprocessedBlobs)
        .then(files => {
          files.forEach(blob => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);
            fileReader.onload = () => {
              const base64Image = fileReader.result;
              const image = {
                uploaded: false,
                thumbnail: base64Image,
                fileName: blob.name,
              };

              addFileToModel(image, blob);
            };
          });
        })
        .catch(e => {
          console.error(e);
          notification.show('Error', 'Cannot compress image');
        });
    };

    that.deleteImage = (e, image) => {
      // prevent form from being dirty
      e.stopPropagation();
      e.preventDefault();

      // uploaded flags is only used for files uploaded by this control, check prevents file used from deletion
      if (image.uploaded && image.id) {
        fileService.deleteFile(image.id)
          .success(data => {
            notification.show('Success', 'File deleted');
            triggerNgModelUpdate(modelValue().filter(file => image !== file));
          }).error(e => {
          console.error(e);
          notification.show('Error', 'Error deleting file');
        });

        return;
      }

      triggerNgModelUpdate(modelValue().filter(file => image !== file));
    };

    that.updateRemark = (e, item) => {
      fileService.updateRemarks(item.id, item.remarks, {nxLoaderSkip: true})
        .error(e => {
          notification.show('Error', 'Error updating file');
        });
    };

    that.displayFileName = item => {
      return that.showFileName &&
        //TODO: 'cameraPicture' is constant defined by file-upload-new component.
        item.fileName !== 'blob' && item.fileName !== 'cameraPicture';
    };

    const detectImage = _.memoize(base64 => {
      const uriData = base64Service.parseDataUri(base64);
      return uriData.mimeType.startsWith('image/');
    });

    that.isImage = item => {
      if (!item || !item.thumbnail) {
        return false;
      }

      return detectImage(item.thumbnail);
    };

    that.$onDestroy = () => {
      $(window).off('resize', repositionAllZoomedInImages);
      $(window).off('scroll', repositionAllZoomedInImages);
    };

    that.exceededFileLimit = () => {
      return (that.ngModel.$modelValue || []).length > that.maxFiles;
    };

    that.initializeFileSizeValidator = () => {
      const overriddenMaxSize = that.getMaxSize();
      that.validate = that.validate || {
        size: {
          max: overriddenMaxSize || that.maxSize || '1MB',
        }
      };

      // override max size declared on components that use "validate'{pattern:any, size: any}'"
      if (that.validate.size) {
        that.validate.size = {
          max: overriddenMaxSize || that.validate.size
        }
      }
    };

    that.getMaxSize = () => {
      const maxSizePropertyValue = systemPropertyService.getProperty('FILE_MAX_UPLOAD_SIZE_IN_MB');
      if (!maxSizePropertyValue) {
        return maxSizePropertyValue;
      }
      return parseFloat(maxSizePropertyValue) ? `${parseFloat(maxSizePropertyValue)}MB` : null;
    }
  }
});
