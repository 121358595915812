import nxModule from 'nxModule';

const templateUrl = require('./day-of-week.template.html');
nxModule.component('dayOfWeek', {
  templateUrl: templateUrl,
  bindings: {
    'value': '=',
    'includeWeekend': '<',
    'required': '<'
  },
  controller: function () {
    let that = this;

    that.days = [
      {
        label: 'Monday',
        value: 0
      }, {
        label: 'Tuesday',
        value: 1
      }, {
        label: 'Wednesday',
        value: 2
      }, {
        label: 'Thursday',
        value: 3
      }, {
        label: 'Friday',
        value: 4
      }
    ];

    if (that.includeWeekend) {
      that.days.push({
        label: 'Saturday',
        value: 5
      }, {
        label: 'Sunday',
        value: 6
      })
    }

  }
});
