import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './edit-term-deposit-signature-card.template.html';
import {Product} from '../../../service/product.types';
import {ILocationService, IScope} from "angular";
import {CustomerCache} from "components/service/customer.cache.types";
import {Confirmation} from "shared/common/confirmation.types";
import {TermDepositService} from "components/administration/term-deposit/common/term-deposit.types";
import {CustomerDepositService} from "components/service/customer-deposit.service.types";
import {CommandService} from "shared/utils/command/command.types";

class CustomerTermDepositEditSignatureCard {
  customerId!: number;
  depositId!: number;
  termDeposit!: Product & { signatureCardFileId?: number };
  signatureCardFiles: {id: number}[] = [];
  signatureCardFileId?: number;

  constructor(readonly $scope: IScope,
              readonly $location: ILocationService,
              readonly customerCache: CustomerCache,
              readonly confirmation: Confirmation,
              readonly termDepositsService: TermDepositService,
              readonly customerDepositService: CustomerDepositService,
              readonly command: CommandService) {
  }


  async $onInit(): Promise<void> {
    const deposits = await this.customerCache.termDeposits(this.customerId).toPromise();
    this.termDeposit = _.find(deposits, {id: Number(this.depositId)})!;
    this.signatureCardFiles = this.termDeposit.signatureCardFileId ? [{id: this.termDeposit.signatureCardFileId}] : [];
  }

  redirect(): void {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.depositId}`);
  }

  redirectBack(): void {
    this.confirmation('Do you want to cancel? Canceling will discard all changes.', () => {
      this.redirect();
    });
  }

  async update(): Promise<void> {
    const confirmed = await this.confirmation(`Do you want to update the "${this.termDeposit.productNumber}" term deposit?`);

    if (!confirmed) {
      return;
    }

    const input = {
      productId: this.termDeposit.id,
      signatureCardFileId: (this.signatureCardFiles || []).map(f => f.id)[0]
    };

    this.command.execute('UpdateDepositSignatureCard', input).success(() => {
      this.customerCache.termDeposits(this.customerId).refetch();
      this.redirect();
    });
  }
}

nxModule.component('customerTermDepositEditSignatureCard', {
  templateUrl: templateUrl,
  controller: CustomerTermDepositEditSignatureCard,
  bindings: {
    customerId: '<',
    depositId: '<'
  }
});