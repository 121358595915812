import nxModule from 'nxModule';

import ParameterizedLocalCache from "../../../shared/utils/parameterized-local-cache";

nxModule.factory('atmTerminalBranchCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (branchId) => http.get(`/management/atm-terminals/${branchId}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.atmTerminals,
    cacheName: 'atmTerminalBranchCache'
  })
);
