import nxModule from 'nxModule';
import {Base64} from 'js-base64';


export class FileReaderService {

  readAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(<string> reader.result);
      });

      reader.addEventListener('error', reject);
      reader.readAsDataURL(file);
    });
  }

  async readAsText(file: File): Promise<string> {
    const base64 = await this.readAsBase64(file);
    const base64HtmlPrefix = 'data:text/html;base64,';
    const strippedBase64Html = base64.substring(base64HtmlPrefix.length);
    // we use custom base64 instead of btoa as the former one doesn't work with unicode
    return Base64.decode(strippedBase64Html);
  }
}

nxModule.service('fileReader', FileReaderService);