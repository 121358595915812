import {setLocale} from 'yup';

setLocale({
  number: {
    required: 'Value is required',
    positive: 'Value must be greater or equal to 1',
  },
  mixed: {
    notType: (_ref: {type: string}): string =>{
      switch (_ref.type) {
        case 'number':
          return 'Value should be a number';
        default:
          return 'Value has a wrong type. Expected ${type}';
      }
    }
  }
});