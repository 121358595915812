import nxModule from 'nxModule';
import templateUrl from './approval-hierarchy.template.html';
import _ from 'lodash';

import {IController, IFormController, IScope} from 'angular';
import {ApprovalHierarchy} from './approval-hierarchy.types';
import './approval-hierarchy.style.less';
import {Role} from "user/UserTypes";
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {NxIFilterService} from "components/technical/angular-filters";
import RolesCache from "components/service/roles.cache";
import {DragulaService} from 'angularjs-dragula';

class ApprovalHierarchyController implements IController {
  minLevels!: number;
  approvalHierarchy!: ApprovalHierarchy[];
  roles: Role[] = [];
  users: UserDetails[] = [];
  form!: IFormController;
  save!: (approvalHierarchy: ApprovalHierarchy[]) => void;
  useExternalSaveAction: boolean = false;

  readonly dragulaBagName: string = 'approvalHierarchy';

  userSelectConfig = {
    placeholder: 'Select people...',
    searchField: ['effectiveName'],
    labelField: 'fullName',
    valueField: 'id',
    closeAfterSelect: true,
    render: {
      item: (item: UserDetails, escape: (val: string) => string) => {
        return `
<div>
  <span class="user__full-name">${ escape(item.fullName) }</span>
  <span class="user__username">( ${ escape(item.username) } )</span>
</div>`;
      },
      option: (item: UserDetails, escape: (val: string) => string) => {
        return `
<div>
  <span class="user__full-name">${ escape(item.fullName) }</span>
  <span class="user__username">${ escape(item.username) }</span>
</div>`;
      }
    }
  };

  constructor(private readonly $scope: IScope,
              private readonly $filter: NxIFilterService,
              private readonly dragulaService: DragulaService,
              private readonly rolesCache: RolesCache,
              private readonly userCache: UserCache) {
  }

  async $onInit(): Promise<void> {
    this.dragulaService.options(this.$scope, this.dragulaBagName, {
      moves: function (el, container, handle) {
        return handle.classList.contains('handler');
      }
    });

    this.$scope.$on(this.dragulaBagName + '.drop', (e, el) => {
      this.form.$setDirty();
    });

    const [roles, users]: [Role[], UserDetails[]] = await Promise.all([
      this.rolesCache.toPromise(),
      this.userCache.withParam().toPromise(),
    ]);

    this.roles = roles.filter(role => !role.technical)
      .map(role => ({ ...role, name: this.$filter('prettyEnum')(role.name) }));
    this.users = users.filter(u => !u.technical)
      .sort((userA, userB) => {
      const nameA = userA.fullName.toUpperCase().trim();
      const nameB = userB.fullName.toUpperCase().trim();
      return (nameA > nameB) ? 1 : (nameA < nameB) ? -1 : 0;
    });
  }

  removeLevel(index: number): void {
    this.approvalHierarchy.splice(index, 1);
    this.form.$setDirty();
  }

  addLevel(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.approvalHierarchy.push({ id: null, level: null, authorizedRoleIds: [], authorizedUserIds: [] });
  }

  isApprovalLevelValid({ authorizedRoleIds, authorizedUserIds }: ApprovalHierarchy): boolean {
    return this.rolesAreSetUp(authorizedRoleIds) || this.usersAreSetUp(authorizedUserIds);
  }

  rolesAreSetUp(authorizedRoleIds: number[]): boolean {
    return !_.isEmpty(authorizedRoleIds);
  }

  usersAreSetUp(authorizedUserIds: number[]): boolean {
    return !_.isEmpty(authorizedUserIds);
  }

  isConfigurationValid(): boolean {
    return this.approvalHierarchy && this.approvalHierarchy.length >= this.minLevels &&
      this.approvalHierarchy.every(approval => this.isApprovalLevelValid(approval));
  }
}

nxModule.component('approvalHierarchy', {
  templateUrl,
  controller: ApprovalHierarchyController,
  bindings: {
    save: '<',
    useExternalSaveAction: '<',
    approvalHierarchy: '<',
    minLevels: '@'
  }
});