import nxModule from 'nxModule';

import {NoteHistoryService} from './note-history.service';
import templateUrl from './note-history.template.html';
import {Note} from "./note.types";
import './note-history.style.less';
import {NgTableParams} from 'ng-table';
import {UserCache, UserDetails} from "../../service/users/user.cache";
import {ModalApi} from "components/technical/modal/modal.component";
import _ from "lodash";
import {CommandService} from "shared/utils/command/command.types";

type ModalMode = 'CREATE' | 'UPDATE';

class NoteHistory {
  private productId!: number;
  private productType!: string;
  private noteTableConfig!: NgTableParams<Note[]>;
  private users: UserDetails[] = [];
  private noteModalApi!: ModalApi;
  private modalMode?: ModalMode;
  private note: Note | undefined;

  private readonly createNoteCommands: {
    [productType: string]: string;
  } = {
    'LOAN': "CreateLoanNote",
    'ACCOUNT': "CreateAccountNote",
    'DEPOSIT': "CreateDepositNote"
  };

  private readonly updateNoteCommands: {
    [productType: string]: string;
  } = {
    'LOAN': "UpdateLoanNote",
    'ACCOUNT': "UpdateAccountNote",
    'DEPOSIT': "UpdateDepositNote"
  };

  constructor(private noteHistoryService: NoteHistoryService, private userCache: UserCache, private command: CommandService) {
  }

  async $onInit(): Promise<void> {
    this.users = await this.userCache.withParam().toPromise();
  }

  async $onChanges() {
    if (this.productId) {
      this.noteTableConfig = new NgTableParams({
        count: 10
      }, {
        counts: [],
        total: 0,
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        getData: async (ngParams: NgTableParams<Note[]>) => {
          const request = {
            params: {
              productId: this.productId,
              pageNo: ngParams.page() - 1,
              pageSize: ngParams.count()
            }
          };
          const response = await this.noteHistoryService.getNotes(request);
          ngParams.total(response.totalCount);
          return response.result;
        }
      });
    }
  }

  getUser(userId: number): UserDetails | undefined {
    return this.users.find(u => u.id === userId);
  }

  getCommandName(): string {
    return this.createNoteCommands[this.productType]
  }

  getUpdateCommandName(): string {
    return this.updateNoteCommands[this.productType]
  }

  onNoteModalApiReady = ({api}: {api: ModalApi}) => {
    this.noteModalApi = api;
  };

  async showNoteModal(modalMode: ModalMode, note?: Note) {
    this.modalMode = modalMode;

    if (modalMode === 'UPDATE') {
      this.note = _.cloneDeep(note);
    }

    await this.noteModalApi.show();
  }

  async confirmModal() {
    switch (this.modalMode) {
      case 'CREATE':
        await this.addNote();
        break;
      case 'UPDATE':
        await this.updateNote();
        break;
      default:
        throw new Error(`Unknown modal mode: ${this.modalMode}`)
    }

    this.noteModalApi.onOk();
  }

  async addNote() {
    const input = {
      productId: this.productId,
      description: this.note?.description.split(',')
    };

    const response = await this.command.execute(this.getCommandName(), input).toPromise();
    if (!response?.approvalRequired) {
      this.note = undefined;
      await this.$onChanges();
    }
  }

  async updateNote() {
    if (!this.note) {
      throw new Error("No selected note to update!");
    }

    const input = {
      noteId: this.note.id,
      description: this.note.description.split(',')
    };

    const response = await this.command.execute(this.getUpdateCommandName(), input).toPromise();
    if (!response?.approvalRequired) {
      this.note = undefined;
      await this.$onChanges();
    }
  }
}

nxModule.component('noteHistory', {
  templateUrl,
  bindings: {
    productId: '<',
    productType: '<',
  },
  controller: NoteHistory,
});
