import {
  NxButton,
  NxButtonLink,
  NxButtonVariant,
  NxQuery,
  NxQueryResult,
  NxRow,
  NxRowPosition,
  NxStack,
  NxTable,
  NxTableActionProps,
  NxTableColumn
} from "@nextbank/ui-components";
import React, {ReactElement, useContext, useState} from "react";
import NxHeader from "../../form/NxHeader";
import NxPage from "../../form/NxPage";
import {useParams} from "react-router";
import {Checkbook, CheckbookDetails} from "check/CheckbookType";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";
import {CommandContext} from "command/CommandService";
import CommandNavigationBlocker from "command/CommandNavigationBlocker";
import CommandAccess from "command/CommandAccess";


interface CheckbookViewDetails {
  no: number;
  checkbook: Checkbook;
  totalUnusedChecks: number;
  totalUsedChecks: number;
  totalChecks: number;
}

const columns: NxTableColumn<CheckbookViewDetails>[] = [{
  title: "No",
  field: "no",
}, {
  title: "Batch",
  field: "checkbook.batchId",
}, {
  title: "Checkbook number",
  field: "checkbook.number",
}, {
  title: "Check start number",
  field: "checkbook.startNumber",
}, {
  title: "Check end number",
  field: "checkbook.endNumber",
}, {
  title: "Used checks",
  field: "totalUsedChecks",
}, {
  title: "Unused checks",
  field: "totalUnusedChecks",
}, {
  title: "Total checks",
  field: "totalChecks",
}];


const CheckbookList = (): ReactElement => {
  const params = useParams<{depositoryAccountId: string}>();
  const {execute} = useContext(CommandContext);
  const [deletingCheckbooks, setDeletingCheckbooks] = useState<number[]>([]);

  const pathPrefix = `/check-preparation/check-management/depository-accounts/${params.depositoryAccountId}/checkbooks`;
  const View = ({data: checkbookDetails, disabled}: NxTableActionProps<CheckbookViewDetails>): ReactElement => {
    const isRemovingCheckbook = deletingCheckbooks.includes(checkbookDetails.checkbook.id);
    return <NxButtonLink
      disabled={disabled || isRemovingCheckbook}
      variant={NxButtonVariant.CONTAINED}
      to={`${pathPrefix}/${checkbookDetails.checkbook.id}`}>
      View
    </NxButtonLink>;
  };

  const Remove = ({data: checkbookDetails, api, disabled}: NxTableActionProps<CheckbookViewDetails>): ReactElement | null => {
    if(checkbookDetails.totalUsedChecks > 0) {
      return null;
    }

    const isRemovingCheckbook = deletingCheckbooks.includes(checkbookDetails.checkbook.id);
    return <CommandAccess commandName="RemoveCheckbook">
      <NxButton
        variant={NxButtonVariant.DELETE}
        onClick={async (): Promise<void> => {
          try {
            setDeletingCheckbooks(prev => [...prev, checkbookDetails.checkbook.id]);

            const response = await execute({
              name: 'RemoveCheckbook',
              input: {
                id: checkbookDetails.checkbook.id
              }
            });

            if(!response.approvalRequired) {
              api.onDataUpdated();
            }
          } finally {
            setDeletingCheckbooks(prev => prev.filter(id => id !== checkbookDetails.checkbook.id));
          }
        }}
           disabled={disabled || isRemovingCheckbook}
           loaded={!isRemovingCheckbook}
      >
        Delete
      </NxButton>
    </CommandAccess>;
  };

  return <CommandNavigationBlocker block={deletingCheckbooks.length > 0}>
      <NxPage>
        <NxHeader>
          Checkbooks
        </NxHeader>
        <NxStack>
          <CommandAccess commandName="BatchCreateCheckbook">
            <NxRow position={NxRowPosition.END}>
              <NxButtonLink
                variant={NxButtonVariant.ADD}
                to={`${pathPrefix}/new`}>
                Add checkbook batch
              </NxButtonLink>
            </NxRow>
          </CommandAccess>
          <NxTable
            columns={columns}
            rowActions={[View, Remove]}
            data={async (query: NxQuery): Promise<NxQueryResult<CheckbookViewDetails>> => {
              const {data} = await axios.post<PageResult<CheckbookDetails>>('/checks/checkbooks/details', {
                checkbookOwner: 'BANK',
                depositoryAccountId: Number(params.depositoryAccountId),
                pageNo: query.page,
                pageSize: query.pageSize
              });

              const result: CheckbookViewDetails[] = data.result.map((details, i) => {
                const totalUsedChecks = details.usedChecks + details.stoppedChecks;

                return {
                  no: query.pageSize * data.pageNo + i + 1,
                  stoppedChecks: details.stoppedChecks,
                  checkbook: details.checkbook,
                  totalUsedChecks: totalUsedChecks,
                  totalUnusedChecks: details.checkbook.checksCount - totalUsedChecks,
                  totalChecks: details.checkbook.checksCount,
                };
              });

              return {
                ...data,
                result,
              };
            }}
          />
        </NxStack>
    </NxPage>
  </CommandNavigationBlocker>;
};

export default CheckbookList;