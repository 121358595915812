import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {allRoutes} from 'custom-field/CustomFieldRoutes';
import DepositsConfiguration from 'deposit/DepositConfiguration';
import RouteDefinition from 'routes/RouteDefinition';

const routes: RouteDefinition[] = [
  {
    name: 'Term Deposits configuration',
    path: '/admin/term-deposits',
    Component: DepositsConfiguration
  },
  ...allRoutes('/admin/term-deposits', CustomFieldGroup.DEPOSIT, false)
];

export default routes;
