import {INgModelController, IScope} from 'angular';
import nxModule from 'nxModule';
import _ from 'lodash';
import {FilterValue} from "./filter-value.types";

import templateUrl from './tree-filter.template.html';
import './tree-filter.style.less';

class TreeFilter {
  ngModel!: INgModelController;
  values: number[] = [];

  readonly nodeLabel = (node: {name: string}) => node.name;

  treeData = [];
  options!: FilterValue[];
  uniqueSelectedIds!: () => Set<number>;

  constructor(private $scope: IScope) {
    $scope.$watch('$ctrl.options',  () => {
      this.initializeModel()
    })
  }

  $onInit(): void {
    this.ngModel.$render = () => {
      this.initializeModel()
    };

    this.ngModel.$isEmpty = values => {
      return !values || values.length === 0;
    };
  }

  private initializeModel(): void {
    const uniqueModelIds = new Set<number>(this.ngModel.$modelValue);
    if (_.isEqual(this.uniqueSelectedIds(), uniqueModelIds)) {
      return;
    }

    if (this.isAllOptionsModelValue()) {
      const uniqueOptionIds = this.uniqueOptionIds();
      const uniqueSelectedIds = this.uniqueSelectedIds();
      const isAllOptionSelected = _.isEqual(uniqueOptionIds, uniqueSelectedIds);
      if (isAllOptionSelected) {
        return;
      }
    }

    if (this.isAllOptionsModelValue()) {
      this.values = Array.from(this.uniqueOptionIds());
    } else if (this.ngModel.$modelValue) {
      this.values = this.ngModel.$modelValue;
    } else {
      this.values = [];
    }
  }

  private uniqueOptionIds(): Set<number> {
    const allIds = (this.options || []).map(option => option.id);
    return new Set<number>(allIds);
  }

  private isAllOptionsModelValue() {
    return this.ngModel.$modelValue
      && this.ngModel.$modelValue.length === 1
      && this.ngModel.$modelValue[0] === null;
  }

  updateModel(): void {
    this.ngModel.$setTouched();
    const {uniqueSelectedIds, isAllOptionSelected} = this.isSelectAll();
    if (isAllOptionSelected) {
      this.ngModel.$setViewValue([null]);
    } else {
      this.ngModel.$setViewValue(Array.from(uniqueSelectedIds));
    }
  }

  isSelectAll(): { uniqueSelectedIds: Set<number>; isAllOptionSelected: boolean } {
    const uniqueOptionIds = this.uniqueOptionIds();
    const uniqueSelectedIds = this.uniqueSelectedIds();
    const isAllOptionSelected = uniqueOptionIds.size === uniqueSelectedIds.size;
    return {uniqueSelectedIds: uniqueSelectedIds, isAllOptionSelected};
  }

  renderLabel = (): string => {
    const {uniqueSelectedIds, isAllOptionSelected} = this.isSelectAll();
    if (isAllOptionSelected) {
      return 'All items selected';
    }

    const selectedItems = Array.from(uniqueSelectedIds);
    const length = selectedItems.length;
    if (length === 1) {
      const id = selectedItems[0];
      const option = _.find(this.options, option => option.id === id)!;
      return option.code;
    } else {
      return `${length} items selected`;
    }
  };
}

nxModule.component('treeFilter', {
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  bindings: {
    ngRequired: '<',
    treeData: '<',
    options: '<',
    uniqueSelectedIds: '<'
  },
  controller: TreeFilter
});
