export default (predicateValue, predicateContext, authentication) => {
  const userId = authentication.context.id;

  if(!predicateContext.userId) {
    console.warn('Missing predicate context for', 'USER_ACCESS_MODE');
    return true;
  }

  const predicateUserId = predicateContext.userId;
  switch (predicateValue['USER_ID']) {
    case 'CURRENT_USER_ONLY':
      return userId === predicateUserId;
    default:
      console.warn('Unknown USER_ACCESS_MODE predicate value', predicateValue);
      return true;
  }
};
