import {NxInput, NxSelect, NxSelectOption} from '@nextbank/ui-components';
import {CustomFieldCategoryValue} from 'custom-field/CustomFieldCategoryTreeView';
import {categoryGroupSelect, CustomFieldDefinition} from 'custom-field/CustomFieldDefinitionTypes';
import {CustomFieldDefinitionViewContext} from 'custom-field/CustomFieldDefinitionView';
import customFieldService from 'custom-field/CustomFieldService';
import React, {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {TreeNodeConfirmation, TreeNodeEditProps} from 'tree/editableTree/EditableSubtreeView';
import styles from './CustomFieldCategoryTreeEdit.scss';

export type CATEGORY_CREATION_TYPE = 'NEW' | 'EXISTING';

const CustomFieldCategoryTreeEdit = (props: TreeNodeEditProps<CustomFieldCategoryValue>): ReactElement => {
  const [label, setLabel] = useState(props.value?.label ?? '');
  const [code, setCode] = useState(props.value?.code ?? '');
  const [remarks, setRemarks] = useState(props.value?.remarks ?? '');
  const [customField, setCustomField] = useState(props.value?.customField);
  const [categoryCreationType, setCategoryCreationType] = useState<string>(props.value?.customField ? 'EXISTING' : 'NEW');
  const [definitionId] = useState(props.value?.definitionId);
  const [categoryGroup, setCategoryGroup] = useState<string>('CUSTOMER');
  const [customFieldDefinitions, setCustomFieldDefinitions] = useState<CustomFieldDefinition[]>([]);
  const context = useContext(CustomFieldDefinitionViewContext);
  const categoryCreationTypeSelect : NxSelectOption<CATEGORY_CREATION_TYPE>[] = context.group === 'CUSTOMER' ?
  [{
    label: 'New',
    value: 'NEW'
  }] : [{
    label: 'New',
    value: 'NEW'
  }, {
    label: 'Existing category',
    value: 'EXISTING'
  }]

  const initializeDefinition = async (): Promise<void> => {
    const definitions = await customFieldService.readDefinitions({enabled: true});
    setCustomFieldDefinitions(definitions.filter(d => d.type !== 'CATEGORY'));
  }

  useEffect(() => {
    initializeDefinition();
  }, []);

  return <div className={styles.row}>
    <NxSelect<string>
      label= 'Creation type'
      options= {categoryCreationTypeSelect}
      onChange={(value: string): void => {
        setCategoryCreationType(value);
        setLabel('');
        setCode('');
        setRemarks('');
        setCustomField(undefined);
      }}
      value={categoryCreationType}
      className={styles.select}
    />
    {
      categoryCreationType === 'NEW' ?
        <NxInput type="text"
                 label="Category Name"
                 onChange={(event: ChangeEvent<HTMLInputElement>): void => setLabel(event.target.value)}
                 error={(!label && !customField) ? 'Value is required' : undefined}
                 value={label}/>
        : (
          <>
            <NxSelect<string>
            label="Group"
            options={categoryGroupSelect}
            value={categoryGroup}
            onChange={(value: string): void => setCategoryGroup(value)}
            className={styles.select}/>
            <NxSelect<number>
            label= 'Custom field'
            value={customField?.id}
            onChange={(id: number): void => {
              setCustomField(customFieldDefinitions.find(c => c.id === id))
            }}
            options={customFieldDefinitions.filter(d => d.group === categoryGroup).map(d => (
              {
              label: d.name,
              value: d.id
              }))}
            className={styles.select}
            error={(!label && !customField) ? 'Value is required' : undefined}
            />
          </>
        )
    }
    <NxInput type="text"
             label="Category Code"
             onChange={(event: ChangeEvent<HTMLInputElement>): void => setCode(event.target.value)}
             value={code}/>
    <NxInput type="text"
             label="Remarks"
             onChange={(event: ChangeEvent<HTMLInputElement>): void => setRemarks(event.target.value)}
             value={remarks}/>
    <TreeNodeConfirmation<CustomFieldCategoryValue>
      treeNode={props.treeNode}
      value={label || customField ? {
        id: props.value?.id,
        label,
        code,
        remarks,
        customField: customField,
        definitionId: definitionId
      } : undefined}
      mapping={props.mapping}
    />
  </div>;
};

export default CustomFieldCategoryTreeEdit;