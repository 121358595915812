import nxModule from 'nxModule';
import moment from "moment";
import {DosriType, RelatedPartyTransactionType} from 'components/customer/profile/customer-profile.types';

const templateUrl = require('./individual-data.template.html');
nxModule.component('customerProfileIndividualData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'module': '<'
  },
  controller: function ($scope, $timeout, dict, propertyConfigService, authentication, branchService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.uploadFile;

    that.phoneTypes = [
      {
        type: 'BASIC',
        label: 'Basic'
      },
      {
        type: 'ANDROID',
        label: 'Android'
      },
      {
        type: 'IOS',
        label: 'iOS'
      }];

    that.dosriTypes = Object.values(DosriType)
    that.relatedPartyTransactions = Object.values(RelatedPartyTransactionType).map(r => ({label: r, value: r}))
    that.minBirthDate = new Date().setFullYear(1900, 1, 1);
    that.maxBirthDate = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');

    that.$onInit = async () => {
      await that.dict.onLoadingCompleteAsync();
      that.updateAge();
    };

    $scope.$watch('$ctrl.profile', () => {
      that.updateAge()
    });

    that.mobileNumberChanged = () => {
      if (!that.profile.extractedPhones.mobile.number) {
        that.profile.extractedPhones.mobile.planId = null;
        that.profile.extractedPhones.mobile.monthlyExpenseId = null;
      }
    };

    that.dosriChanged = () => {
      if (!that.profile.dosri) {
        that.profile.dosriType = null;
      }
    };

    that.rptChanged = () => {
      if (that.profile.individualData.relatedPartyTransaction !== 'OTHER') {
        that.profile.individualData.relatedPartyTransactionDescription = null;
      }
    };

    that.updateAge = async () => {
      if (!that.profile.individualData) {
        return;
      }
      if (!that.profile.individualData.birthDate) {
        that.profile.individualData.age = null;
        return;
      }

      that.profile.individualData.age = moment().diff(moment(that.profile.individualData.birthDate), 'years');
    };

    that.isCoMakerMaxTagLimitValid = () => {
      if (!that.profile.maxComakerTagLimit) {
        return true;
      }
      return that.profile.maxComakerTagLimit >= that.profile.coMadeLoanIds.length;
    }

    that.isMarried = () =>
      that.profile
      && that.profile.individualData
      && that.profile.individualData.civilStatusId
      && that.dict['CIVIL_STATUS'].find(entry => entry.code === 'MARRIED'
      && entry.id === that.profile.individualData.civilStatusId);

    const currentBranchSystemDate = async () => {
      const branchId = authentication.context.branchId;
      const branches = await branchService.toPromise();
      const branch = branches.find(branch => branch.id === branchId);
      return moment(branch.systemDate);
    };

    $scope.$watch('$ctrl.profile.customerSubtype', () => {
      if (['customer', 'customer_bda', 'customer_restricted'].includes(that.module)) {
        switch(that.profile.customerSubtype) {
          case "BDA":
            that.module = 'customer_bda';
            break;
          case "RESTRICTED":
            that.module = 'customer_restricted';
            break;
          default:
            that.module = 'customer';
            break;
        }
      }
    });

    $scope.$watch('$ctrl.profile.individualData.signatureFileId', () => {
      if(that.profile.individualData) {
        if (that.profile.individualData.signatureFileId) {
          that.profile.individualData.signatureFile = [{
            id : that.profile.individualData.signatureFileId
          }];
        }
      }
    });

    $scope.$watch('$ctrl.profile.pictureFileId', () => {
      if(that.profile.individualData) {
        if (that.profile.pictureFileId) {
          that.profile.pictureFile = [{
            id : that.profile.pictureFileId
          }];
        }
      }
    });
  }
});
