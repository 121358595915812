import nxModule from 'nxModule';

nxModule.directive('validateDate', function (singleUserCache, systemDateService) {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, elem, attr, ngModel) {
      if (!ngModel) return;

      scope.$watch(attr.ngModel, function () {
        validate();
      });

      attr.$observe('validateDate', function (val) {
        validate();
      });

      const validate = async () => {
        // If selected date is null -> consider input as valid
        // 'Required' should be added to input to perform not null check
        let selectedDate = ngModel.$viewValue;
        let valid = true;

        if (selectedDate) {
          valid = await isValid(selectedDate);
        }

        ngModel.$setValidity('validateDate', valid);
      };

      /** @returns date with zeroed time part */
      const resolveCurrentDate = async (dateSourceStrategy) => {
        switch (dateSourceStrategy) {
          case 'posting-date':
            return await systemDateService.getCurrentUserBranchSystemDate().then(moment => moment.toDate());
          case 'real-date':
            return new Date().setHours(0, 0, 0, 0);
          default:
            console.error(`Date source [${comparisonOperator}] not supported.`);
            return new Date().setHours(0, 0, 0, 0);
        }
      };

      const isValid = async (selectedDate) => {
        const [comparisonOperator, dateSourceStrategy] = attr.validateDate.split(':');
        const inputDate = new Date(selectedDate); // date with zeroed time part
        const currentDate = await resolveCurrentDate(dateSourceStrategy);

        switch (comparisonOperator) {
          case 'lt':
            return inputDate < currentDate;
          case 'lte':
            return inputDate <= currentDate;
          case 'eq':
            return inputDate.getTime() === currentDate.getTime();
          case 'gte':
            return inputDate >= currentDate;
          case 'gt':
            return inputDate > currentDate;
          default:
            console.error(`Comparison operator [${comparisonOperator}] not supported.`);
            return false;
        }
      };
    }
  }
});
