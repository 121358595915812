export const flatten = (tree) => {
  let result = [];
  if (tree) {
    tree.forEach(node => {
      result.push(node);
      if (node.children) {
        result = result.concat(flatten(node.children));
      }
    });
  }
  return result;
};