import nxModule from 'nxModule';
import './close-counters-health-check.component.less';
import templateUrl from './close-counters-health-check.template.html';

class CloseCountersHealthCheck {}

nxModule.component('closeCountersHealthCheck', {
  templateUrl,
  bindings: {
    checks: '<'
  },
  controller: CloseCountersHealthCheck
});