import nxModule from 'nxModule';

nxModule.factory('formattingService', () => {
  let that = this;

  let service = {};

  service.uiCommaSeparator = true;

  service.formatNumber = (x) => {
    if (service.uiCommaSeparator) {
      // OMG http://stackoverflow.com/a/2901298/1358196
      return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return x;
    }
  };

  service.setUiCommaSeparator = (value) => {
    service.uiCommaSeparator = value;
  };

  return service;
});