import TokenHttpService from "./TokenHttpService";
import tokenStore from "./tokenStore";
import LocationUtil from "../tools/LocationUtil";
import currentBranchService from "../management/currentBranchService";


const tokenHttpService = new TokenHttpService();
const locationUtil = new LocationUtil();
class AuthenticationService {
  async logoutKeepPath(error?: string): Promise<void> {
    const strippedLocation = locationUtil.getCurrentRoutePath();

    return this.logout({
      error,
      ...(strippedLocation ? {
          target: strippedLocation,
        } : {}
      )
    });
  }

  async logout(config?: {target?: string, error?: string}): Promise<void> {
    const {target, error} = config || {};
    const tokenDetails = tokenStore.getTokenDetails();

    try {
      await tokenHttpService.logout(tokenDetails.token);
    } catch (e) {
      console.error('Error logging out');
      throw e;
    } finally {
      tokenStore.clearToken();
      currentBranchService.clearBranchId();
      locationUtil.redirectToLoginPage({
        error,
        target,
      });
    }
  }

  async refresh(): Promise<void> {
    const tokenDetails = tokenStore.getTokenDetails();
    const nxToken = await tokenHttpService.refresh(tokenDetails.token);
    tokenStore.updateToken(nxToken);
  }
}

export default AuthenticationService;