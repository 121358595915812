import nxModule from 'nxModule';

const BREADCRUMB_LABEL_LENGTH_LIMIT = 50;

const templateUrl = require('./breadcrumbs.template.html');
nxModule.component('breadcrumbs', {
  templateUrl,
  bindings: {
    breadcrumbsHidden: '<'
  },
  controller: function ($element, $location, $filter, breadcrumbs) {
    this.breadcrumbs = breadcrumbs;

    this.getLabel = value =>
      (typeof value === 'object') ? $filter('translate')(value.value, value.type) : value;

    this.shouldTrimLabel = value => value.length > BREADCRUMB_LABEL_LENGTH_LIMIT;
  }
});
