import nxModule from "nxModule";
import templateUrl from "./collections-payment-members-modal.template.html";
import {NgTableParams} from "ng-table";
import {ModalApi} from "components/technical/modal/modal.component";
import {CollectionsMember} from "components/dashboard/collections/payment/collections-payment.component";
import './collections-payment-members-modal.style.less'

class CollectionsPaymentMembersModal {
  private membersModal!: ModalApi;
  private clearPayments!: () => void;
  private proceedPayment!: ({showConfirmation}: {showConfirmation: boolean}) => void;

  private readonly groupMembersSortByOptions = [
    { label: 'Customer name (Ascending)', value: 'CUSTOMER_NAME' },
    { label: 'Latest due date (Ascending)', value: 'DUE_DATE' },
    { label: 'Latest due date (Descending)', value: 'DUE_DATE_DESC' }
  ];

  onMembersModalReady = ({api}: {api: ModalApi}) => {
    this.membersModal = api;
  };

  getRowNo(index: number, tableConfig: NgTableParams<CollectionsMember>): number {
    const currentPage = tableConfig.page() - 1;
    const rowsPerPage = tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }

  closeModal(): void {
    this.clearPayments();
    this.membersModal.onCancel();
  }

  submitPayment(): void {
    this.proceedPayment({showConfirmation: false});
    this.membersModal.onOk();
  }
}

nxModule.component('collectionsPaymentMembersModal', {
  templateUrl,
  bindings: {
    'tableConfig': '=',
    'membersModal': '=',
    'selectedGroup': '=',
    'selectedPageForPayment': '=',
    'sortBy': '=',
    'collectionsReceiptType': '=',
    'collectionModuleReceiptRequired': '=',
    'valueDateEnabled': '=',
    'valueDate': '=',
    'toggleForPayment': '&',
    'togglePageForPayment': '&',
    'getPayment': '&',
    'getPayments': '&',
    'getPaymentsTotalAmount': '&',
    'clearPayments': '&',
    'isValidPayments': '&',
    'proceedPayment': '&',
    'prepareGroupMembersTable': '&'
  },
  controller: CollectionsPaymentMembersModal
});
