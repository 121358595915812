import axios from "axios";
import {FileReportDetails, FileReportGenerationResponse} from "report/ReportType";
import retry from 'p-retry';
import useAxios, {UseAxiosResult} from "axios-hooks";
import {ReportDescriptor} from "report/ReportConfigurationList";

export interface DownloadFileReportInput {
  reportName: string;
  parameters?: Record<string, unknown>;
}

interface AvailableReportsResponse {
  availableReportTypes: string[];
}

export const useReportDescriptors = (): UseAxiosResult<ReportDescriptor[]> => useAxios<ReportDescriptor[]>('/reports/descriptors');

export default class ReportService {

  async readDescriptors(): Promise<ReportDescriptor[]> {
    const {data: reports} = await axios.get<ReportDescriptor[]>('/reports/descriptors');
    return reports;
  }

  async readDescriptor({reportCode}: {reportCode: string}): Promise<ReportDescriptor> {
    const descriptors = await this.readDescriptors();
    const descriptor: ReportDescriptor | undefined = descriptors.find(des => des.code === reportCode);
    if(!descriptor) {
      throw new Error(`Cannot find descriptor for report ${reportCode}`);
    }

    return descriptor;
  }

  /**
   * Returns names of file reports that can be generated
   * */
  async readAvailableFileReports(): Promise<string[]> {
    const {data: availableReports} = await axios.get<AvailableReportsResponse>(`/reports/file-report-types`);
    return availableReports.availableReportTypes;
  }

  /**
   * Generates file report in S3
   * */
  async generateFileReport({reportName, parameters={}} : DownloadFileReportInput): Promise<number> {
    const {data: generationResponse} = await axios.post<FileReportGenerationResponse>(`/reports/file-reports`, {
      reportName,
      parameters,
    });

    return generationResponse.id;
  }

  /**+
   * Generates file report and downloads it
   * */
  async downloadFileReport({reportName, parameters={}} : DownloadFileReportInput): Promise<number> {
    const generationResponseID = await this.generateFileReport({reportName, parameters});

    await this.downloadFileReportByRequestId(generationResponseID);

    return generationResponseID;
  }

  /**
   * Downloads a report generated by the reporting tool. Param is the request id of the generation.
   */
  private async downloadFileReportByRequestId(requestId: number): Promise<void> {
    await retry(async (): Promise<void> => {
      try {
        const {data: report} = await axios.get<FileReportDetails>(`/reports/file-reports/${requestId}`);

        if (report.status === 'FAILED') {
          return Promise.reject(new retry.AbortError(`Failed to generate report ${report.reportName}`));
        }

        if (report.status === 'COMPLETED') {
          const a = window.document.createElement('a');
          a.href = report.url;
          a.setAttribute('download', report.reportFileName);
          a.click();
          return;
        }

        return Promise.reject(new Error('Report not generated yet'));
      } catch (e) {
        console.error('Failed to generate report', e);
        throw new retry.AbortError('Failed to generate report');
      }
    }, {
      minTimeout: 2000,
      maxTimeout: 10000,
      retries: 20,
      randomize: true
    });
  }
}