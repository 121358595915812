import {AxiosError, AxiosResponse} from "axios";

export interface ErrorResponse {
  errorTimestamp: string;
  errorId: string;
  commandId: string;
  requestUuid: string;
  path: string;
  username: string;
  systemVersion: string;
  errorCode: string;
  errorMessage: string;
}

export class CancelError extends Error {

}

export class HttpError extends Error {
  public backendError?: ErrorResponse;
  public response?: AxiosResponse<unknown>;
  constructor(axiosError: AxiosError<unknown>) {
    super(axiosError.message);

    this.backendError = axiosError.response?.data as ErrorResponse;
    this.response = axiosError.response;
  }
}

export const isHttpError = (obj: unknown): obj is HttpError => {
  return obj instanceof HttpError;
};

export interface PageResult<T> {
  pageNo: number;
  totalCount: number;
  result: T[];
}

export interface PageRequest {
  pageNo: number;
  pageSize: number;
}