import nxModule from 'nxModule';
import templateUrl from './tooltip.template.html';
import './tooltip.component.less';

class Tooltip {
  constructor() {}
}

nxModule.component('tooltip', {
  templateUrl,
  transclude: {
    'wrapper': 'tooltipWrappedElement',
    'body': 'tooltipBody'
  },
  controller: Tooltip
});