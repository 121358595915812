import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./memo.template.html');
nxModule.component('customerAccountsMemo', {
  templateUrl: templateUrl,
  controller: function($route, $location, $filter,  http, customerCache, branchService, productDefinitionService, glMappingsService,
                        accountOperationService) {
    let that = this;
    that.depositAccount = {};

    // form input
    that.remarks = null;
    that.amount = null;
    that.selectedAccount = null;
    that.categoryUnits = [];

    const bigSumBy = (collection, itemProperty) => {
      return collection.reduce((sum, item) => sum.plus(item[itemProperty] || 0), new BigNumber(0));
    };

    that.calculateAmount = () => {
      return bigSumBy(that.categoryUnits, 'amount').toNumber();
    };

    let customerId = $route.current.params['customerId'];
    let accountId = $route.current.params['accountId'];

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${accountId}`);

    that.isCredit = $location.path().endsWith('credit-memo');
    that.isDebit = $location.path().endsWith('debit-memo');
    if (!that.isCredit && !that.isDebit) that.redirectBack();


    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(d => {
          let definition = _.find(products, {id: d.definitionId});
          return Object.assign(d, {productDefinition: definition});
        })
      )
      // Extend product instance with branch name
      .combineLatest(branchService.toObservable(), (depositAccounts, branches) =>
        depositAccounts.map(d => {
          return Object.assign(d, {branch: _.find(branches, {id: d.branchId})});
        })
      )
      // accountId is a string
      .first()
      .subscribe(accounts => {
        // fetch product mappings for account product type id
        that.depositAccount = _.find(accounts, (a) => String(a.id) === accountId);
        return that.depositAccount;
      });

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.doOperation = () => {
      let req = {
        customerId: customerId,
        productId: accountId,
        amount: that.calculateAmount(),
        categoryUnits: {
          categoryId: that.category.id,
          units: that.categoryUnits
        },
        remarks: that.remarks
      };

      if (that.isCredit) {
        accountOperationService.memoCredit(req, that.redirectBack);
      } else {
        accountOperationService.memoDebit(req, that.redirectBack);
      }
    };

    that.canExecute = () => {
      return that.accountsForm.$valid && that.calculateAmount() > 0;
    };

    that.categoryChanged = () =>  {
      that.categoryUnits = (that.category ? that.category.ledgerAccountFullCodes : [])
        .map(fullCode => ({
          fullCode,
          amount: 0
        }));
    };
  }
});
