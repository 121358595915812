import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {listRoute} from 'custom-field/CustomFieldRoutes';
import LinkList, {LinkListRoute} from 'LinkList';
import React, {ReactElement} from 'react';

const links: LinkListRoute[] = [
  {
    name: 'Types',
    path: '/admin/deposit-accounts/types'
  },
  listRoute('/admin/deposit-accounts', CustomFieldGroup.ACCOUNT, false),
  {
    name: 'Reserve product numbers',
    path: '/admin/deposit-accounts/reserve-product-numbers'
  }
];

const AccountsConfiguration = () : ReactElement => <LinkList links={links} header="Deposit accounts configuration"/>;

export default AccountsConfiguration;