import nxModule from 'nxModule';

nxModule.directive('coreModal', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            showCreateModal: '@coreShow',
            maximized: '@maximized'
        },
        template: '<div class="core-modal" style="display: none;">' +
            '       <div class="loader-wrapper">' +
            '           <div class="modal-inner" ng-transclude></div>' +
            '       </div>' +
            '       <div class="loader-bg"></div>' +
            '</div>',
        link: function (scope, element, attrs, controller) {
            attrs.$observe('coreShow', function (value) {
                var div = angular.element(element)
                if (value && value != "false") {
                    div.show();
                    div.css("opacity", 0);
                    div.animate({"opacity": 1}, 300);
                } else {
                    div.hide();
                }
            });

            attrs.$observe('maximized', function (value) {
                var div = angular.element(element).find(".modal-inner");
                if (value == "true") {
                    div.addClass("modal-maximized");
                } else {
                    div.removeClass("modal-maximized");
                }
            });
        }
    }
});
