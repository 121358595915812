import nxModule from 'nxModule';

const templateUrl = require('./term-deposit-check-placement.template.html');
nxModule.component('customerTermDepositCheckPlacement', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, dict, customerCache, confirmation,
                        customerDepositService) {
    let that = this;
    that.termDeposit = {};
    that.banks = [];

    that.checkModel = {};

    let customerId = $route.current.params['customerId'];
    that.depositId = $route.current.params['depositId'];

    that.selectConfig = {
      placeholder: 'Select bank',
      searchField: ['description'],
      valueField: 'id',
      labelField: 'description',
      maxItems: 1
    };

    customerDepositService.getDeposit(customerId, that.depositId, deposit => that.termDeposit = deposit);
    dict.onLoadingComplete(() => that.banks = dict['BANK']);

    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${that.depositId}`);

    that.deposit = () => {
      confirmation(`Do you want to deposit ${$filter('nxCurrency')(that.checkModel.amount)}?`, () => {
        command.execute(that.checkModel.onUsCheck ? 'DepositCheckPlacementByOnUsCheck' : 'DepositCheckPlacement',
          that.checkModel, {nxLoaderText: 'Performing check placement'}).success((res) => {
          customerCache.termDeposits(customerId).refetch();
          customerCache.depositAccounts(customerId).refetch();
          that.redirectBack();
        });
      });
    };
  }
});
