import nxModule from "nxModule";
import {LoanApplicationDetails} from "components/customer/credit-scoring/credit-scoring.types";
import {Loan} from "components/service/loan.types";
import {LoanType} from "components/service/loan-type.types";
import {UserDetails} from "components/service/users/user.cache";
import {PaymentIntervalDefinition} from "components/administration/loan/intervals/payment-interval.types";
import {CustomerProfile} from "components/customer/profile/customer-profile.types";
import {DictEntry} from "dictionary/DictionaryType";

export interface Input {
  dict: { [key: string]: DictEntry[] };
  loan: Loan;
  loanProduct: LoanType;
  officers: UserDetails[];
  paymentIntervals: PaymentIntervalDefinition[];
  customer: CustomerProfile;
}

class CreditScoringModalLoanApplicationDetailsService {

  public createDetails(input: Input): LoanApplicationDetails {
    const loan: Loan = input.loan;
    const loanProduct: LoanType = input.loanProduct;
    const officers: UserDetails[] = input.officers;
    const dict: { [key: string]: DictEntry[] } = input.dict;
    const paymentIntervals: PaymentIntervalDefinition[] = input.paymentIntervals;
    const customer: CustomerProfile = input.customer;

    return {
      typeId: loan.typeId,
      customerId: loan.customerId,
      paymentIntervalDefinitionId: loan.paymentIntervalDefinitionId,
      grantDate: loan.grantDate,
      firstAmortizationDueDate: loan.firstAmortizationDueDate,
      productName: loanProduct.productDefinition.productName,
      creationType: loan.creationType,
      officerName: officers.find(o => o.id === loan.officerId)?.effectiveName,
      loanSecurity: dict.LOAN_SECURITY.find(e => e.id === loan.loanInformation.loanSecurityId)?.description,
      principalAmount: loan.principalAmount,
      interestRate: loan.interestRate,
      paymentIntervalName: paymentIntervals?.find(i => i.id === loan.paymentIntervalDefinitionId)?.name,
      maturityDate: loan.maturityDate,
      totalAmortizationNumber: loan.totalAmortizationNumber,
      amortizationSchedule: loan.amortizationSchedule,

      customerFirstName: customer.individualData?.firstName,
      customerLastName: customer.individualData?.lastName,
      customerEffectiveName: customer.effectiveName,
      customerNumber: customer.customerNumber,
      customerGender: dict.GENDER.find(e => e.id === customer.individualData?.genderId)?.description,
      customerCivilStatus: dict.CIVIL_STATUS.find(e => e.id === customer.individualData?.civilStatusId)?.description,
      customerBirthDate: customer.individualData?.birthDate,
      customerBirthPlace: customer.individualData?.birthPlace,
      customerMobilePhoneNumber: customer.extractedPhones?.mobile?.number,
    };
  }

}

nxModule.service('creditScoringLoanApplicationDetailsService', CreditScoringModalLoanApplicationDetailsService);
