import DepositoryAccount from 'check/DepositoryAccountTypes';
import nxModule from 'nxModule';

class CheckMicrService {

  public createMicr(checkNumber: string | undefined,
                    accountId: number | undefined,
                    accounts: DepositoryAccount[]): string | undefined {
    if (checkNumber?.length !== 10 || !accountId) {
      return undefined;
    }

    const account = accounts.find(a => a.id === accountId);
    if (!account) {
      return undefined;
    }

    return checkNumber + account.brstn + account.accountNumber;
  };
}

nxModule.service('checkMicrService', CheckMicrService);
export default CheckMicrService;
