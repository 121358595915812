import {InterestRateBoard} from 'components/service/loan-type.types';
import nxModule from 'nxModule';
import angular from 'angular';

import './loan-interest-rate-board.style.less';
import templateUrl from './loan-interest-rate-board.template.html';

class InterestRateBoardComponent {
  form!: angular.IFormController;
  board!: InterestRateBoard;
  minInterestRate!: number;
  maxInterestRate!: number;

  $onInit(): void {
    if(!this.board) {
      this.board = {
        cells: [],
        allowOverride: false
      };
    }
  }

  addNewBoardCell(): void {
    const newCell = {};
    if(!this.board.cells) {
      this.board.cells = [];
    }
    this.board.cells.push(newCell);
  }

  removeBoardCell(index: number): void {
    this.board.cells.splice(index, 1);
    this.makeFormDirty();
  }

  private makeFormDirty(): void {
    if(this.form) {
      this.form.$setDirty();
    }
  }
}

nxModule.component('loanInterestRateBoard', {
  templateUrl,
  bindings: {
    board: '=',
    minInterestRate: '<',
    maxInterestRate: '<',
    defaultInterestRate: '<'
  },
  require: {
    form: '?^form',
  },
  controller: InterestRateBoardComponent
});
