import {ILocationService} from 'angular';
import DepositAccountTypeService from 'components/administration/deposit-account/common/deposit-account-type-service';
import {BranchService} from 'components/service/branch.service';
import FeeDefinitionCache from 'components/service/fee-definition.cache';
import {FeeDefinition} from 'components/service/fees/fee.types';
import {Deposit, ProductDefinition} from 'components/service/product.types';
import {Loader} from 'components/technical/loader.types';
import {productAvailability} from 'constants/productDefinition';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';
import {setBreadcrumbs} from '../../../../shared/utils/set-breadcrumbs';
import {TermDepositService} from '../common/term-deposit.types';

import templateUrl from './term-deposit-details.template.html';

class TermDepositDetailsComponent {

  public sectionType: string = 'general';
  public branches: Branch[] = [];
  private readonly productId!: number;
  private termDeposit: Partial<Deposit & {feeDefinitions: FeeDefinition[]}> = {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private productDefinition: ProductDefinition;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private glMappingComponent;

  constructor(private termDepositsService: TermDepositService,
              private depositAccountTypeService: DepositAccountTypeService,
              private $route: NxRouteService,
              private $location: ILocationService,
              private breadcrumbs: unknown,
              private notification: Notification,
              private http: HttpService,
              private loader: Loader,
              private confirmation: Confirmation,
              private command: CommandService,
              private feeDefinitionsCache: FeeDefinitionCache,
              private branchService: BranchService) {
  }

  async $onInit(): Promise<void> {
    const [branches, deposits] = await Promise.all([this.branchService.toPromise(), this.termDepositsService.toPromise()]);
    this.branches = branches;

    const deposit = _.find(deposits, d => Number(d.id) === Number(this.productId));

    if (deposit) {
      setBreadcrumbs(this.breadcrumbs, 'term-deposit-label', deposit.productDefinition.productName);

      // Keep product definition to update GL mappings
      this.productDefinition = deposit.productDefinition;

      // Copy properties with 1:1 mapping
      this.termDeposit = _.pick(deposit, [
        'depositGroup',
        'depositSubgroup',
        'minimalBalance',
        'maximumBalance',
        'minimalTerm',
        'maximumTerm',
        'withholdingTaxId',
        'interestBoards',
        'maturityStrategies',
        'creditingStrategies',
        'deductCreditedInterestOnPretermination',
        'placementStrategy',
        'taxExemptionThreshold',
        'collectAdvanceWithholdingTax',
        'interCustomerTransferAllowed',
        'partialWithdrawalAllowed',
        'partialInitialPlacementAllowed',
        'passbookConfig',
        'printPassbookAfterValidationSlip',
        'enablePassbookSlip',
        'brisReportingProductCategory',
        'useDivisorHigherOrEqualToTerm',
        'honorHolidays',
        'overmatureWtaxCalculationEnforced',
        'prefillManualRollover',
        'pledgeAmountCalculationType',
        'initialPlacementType',
        'advancePlacementType',
        'pledgeGracePeriodDays',
        'applyOvermatureInterestRateOnMissedPaymentEnabled',
        'keepSpecialInterestRateEnabled',
        'recalculateDocStampAndInterestOnTermChange',
        'useOriginalTermOnRollover',
        'certificateNumberStrategy',
        'certificateNumberPattern',
        'calculateCreditingDateBasedOnReferenceDate',
        'interestCalculationBasis',
        'termCalculationMethod'
      ]);

      // Copy nested/complex properties
      Object.assign(this.termDeposit, {
        id: deposit.id,
        expired: deposit.productDefinition.expired,
        productName: deposit.productDefinition.productName,
        productTypeCode: deposit.productDefinition.productTypeCode,
        productAvailability: deposit.productDefinition.productAvailability,
        availableInBranchIds: deposit.productDefinition.availableInBranchIds,
        allowCreationByAgents: deposit.productDefinition.allowCreationByAgents,
        customerTypeConstraints: deposit.productDefinition.customerTypeConstraints || [],
        daysInYear: deposit.productDefinition.daysInYear,
        description: deposit.productDefinition.description,
        productDefinitionId: deposit.productDefinition.id,
        accrualEnabled: deposit.accrualScheduler.enabled,
        accrualInterval: deposit.accrualScheduler.interval,
        accrualCycleType: deposit.accrualScheduler.cycleType,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        feeDefinitions: deposit.feeDefinitions,
        maturityAdjustmentStrategy: deposit.maturityAdjustmentStrategy,
        customFieldDefinitionIds: deposit.productDefinition.customFieldDefinitionIds
      });
    } else {
      setBreadcrumbs(this.breadcrumbs, 'term-deposit-label', 'Create New');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const createInterestBoard = (rateType) => {
        return {
          rateType: rateType,
          defaultRate: 0,
          cells: []
        };
      };

      this.termDeposit = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        daysInYear: 365,
        useDivisorHigherOrEqualToTerm: false,
        productAvailability: productAvailability.disabled.value,
        allowCreationByAgents: false,
        depositSubgroup: 'STANDARD',
        expired: false,
        withholdingTax: {
          feeName: 'Withholding tax',
          feeClass: 'WITHHOLDING_TAX',
          percentageAmount: 20.0,
          feeType: 'PERCENTAGE',
          feeTypes: ['PERCENTAGE']
        },
        taxExemptionThreshold: 1825,
        collectAdvanceWithholdingTax: true,
        maturityStrategies: [],
        creditingStrategies: ['NOT_ALLOWED'],
        deductCreditedInterestOnPretermination: false,
        placementStrategy: 'NONE',
        interCustomerTransferAllowed: false,
        partialWithdrawalAllowed: false,
        partialInitialPlacementDisabled: false,
        accrualEnabled: true,
        accrualInterval: 1,
        accrualCycleType: 'MONTH_END',
        interestBoards: [
          createInterestBoard('BASIC'),
          createInterestBoard('PRETERMINATION_ON_FIRST_HALF'),
          createInterestBoard('PRETERMINATION_ON_SECOND_HALF'),
          createInterestBoard('OVERMATURE')
        ],
        feeDefinitions: [],
        passbookConfig: 'PASSBOOK_NOT_SUPPORTED',
        honorHolidays: false,
        overmatureWtaxCalculationEnforced: false,
        maturityAdjustmentStrategy: {
          holidayAdjustmentStrategy: 'KEEP_DATE',
          saturdayAdjustmentStrategy: 'KEEP_DATE',
          sundayAdjustmentStrategy: 'KEEP_DATE'
        },
        pledgeAmountCalculationType: null,
        initialPlacementType: null,
        advancePlacementType: null,
        pledgeGracePeriodDays: null,
        applyOvermatureInterestRateOnMissedPaymentEnabled: false,
        keepSpecialInterestRateEnabled: true,
        recalculateDocStampAndInterestOnTermChange: false,
        useOriginalTermOnRollover: false,
        certificateNumberStrategy: 'MANUAL',
        calculateCreditingDateBasedOnReferenceDate: false
      };
    }
  }

  async saveDeposit(): Promise<void> {
    if (this.termDeposit.id) {
      const response = await this.command.execute('UpdateDepositType', this.termDeposit).toPromise();
      if (!response?.approvalRequired) {
        this.termDepositsService.refetch();
        this.feeDefinitionsCache.evict();
        const glMappingResponse = await this.glMappingComponent.save();
        if (glMappingResponse) {
          this.$location.path('/admin/term-deposits');
        }
      }

      if (response?.approvalRequired) {
        this.glMappingComponent.save();
      }
    } else {
      const response = await this.command.execute('CreateDepositType', this.termDeposit).toPromise();
      if (!response?.approvalRequired) {
        this.termDepositsService.refetch();
        this.depositAccountTypeService.refetch();
        this.feeDefinitionsCache.evict();
        this.$location.path('/admin/term-deposits');
      }
    }
  }

  async cancelChanges(): Promise<void> {
    const confirmed = await this.confirmation('Do you want to cancel? Canceling will discard all changes.');
    if (confirmed) {
      this.$location.path('/admin/term-deposits/types');
    }
  }

  setGlMappingComponent(instance: unknown): void {
    this.glMappingComponent = instance;
  }
}

nxModule.component('termDepositDetails', {
  templateUrl,
  controller: TermDepositDetailsComponent,
  bindings: {
    productId: '<'
  }
});
