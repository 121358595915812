import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('depositoryAccountCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/depository-accounts'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.depositoryAccount,
    cacheName: 'depositoryAccount'
  })
);
