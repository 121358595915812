import nxModule from 'nxModule';

import templateUrl from './agents-list.template.html';
import './agents-list.style.less';
import {setBreadcrumbs} from "../../../shared/utils/set-breadcrumbs";
import {Branch} from "management/BranchTypes";
import {ILocationService} from "angular";
import {AgentDetails, AgentsBranchCache} from "components/agent-banking/service/agents-branch.cache.types";
import {AgentsDevicesCache} from "components/agent-banking/service/agents-devices.cache.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {Confirmation} from "shared/common/confirmation.types";

class AgentsList {
  private branch!: Branch;
  private detailLink!: string;
  agents: AgentDetails[] = [];

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private http: HttpService,
              private $filter: NxIFilterService,
              private authentication: Authentication,
              private breadcrumbs: unknown,
              private agentsBranchCache: AgentsBranchCache,
              private agentsDevicesCache: AgentsDevicesCache) {
  }

  async $onInit(): Promise<void> {
    setBreadcrumbs(this.breadcrumbs, 'agents-list-label', this.branch.name);

    this.detailLink = `/agent-banking/agents/${this.branch.id}/agent`;
    this.agents = await this.agentsBranchCache.withParam(this.branch.id).toPromise();
  }

  openCIF(agent: AgentDetails): void {
    this.$location.path(`/customer/${agent.customerId}/profile`);
  }

  edit(agent: AgentDetails): void {
    this.$location.path(`${this.detailLink}/${agent.id}`);
  }

  async deactivate(agent: AgentDetails): Promise<void> {
    if (await this.confirmation(`Are you sure you want to deactivate Agent '${agent.effectiveName}'?`)) {
      const response = await this.command.execute('DeactivateAgent', {id: agent.id}).toPromise();
      if (!response?.approvalRequired) {
        this.agentsBranchCache.withParam(this.branch.id).evict();
        this.agentsDevicesCache.withParam(agent.id).evict();
        this.$route.reload();
      }
    }
  }

  activate(agent: AgentDetails): void {
    this.$location.path(`/agent-banking/agents/${this.branch.id}/agent/${agent.id}/activate`);
  }
}

nxModule.component('agentsList', {
  templateUrl,
  bindings: {
    branch: '<'
  },
  controller: AgentsList
});
