import {Observable} from 'rxjs/Observable'
import 'rxjs/add/operator/combineLatest';

export default (service) => {
  return Observable.create(observer => {
    const listener = (err, val) => {
      if(err) {
        // rxjs has a bug in handling multicast observables. https://github.com/ReactiveX/rxjs/issues/1420
        // it rethrows exception to the calee, which causes other listeners to be ignored
        // to workaround it, we swallow the exception because it's also propagated to subscriber
        setTimeout(() => {
          observer.error(err);
        });
      } else {
        observer.next(val);
      }
    };

    service.subscribe(listener);

    return () => service.unsubscribe(listener);
  });
}

