import React from "react";
import {Redirect} from 'react-router';
import statusService from "./statusService";
import {InferProps, node} from 'prop-types';

interface State {
  waitForCashTransfer: boolean;
}

const props = {
  children: node.isRequired
};

class WaitForCashTransfer extends React.Component<InferProps<typeof props>, State> {
  static propTypes = props;

  state: State = {
    waitForCashTransfer: false
  };

  componentDidMount(): void {
    statusService.registerForStatusUpdate(statusUpdate => {
      this.setState({
        waitForCashTransfer: statusUpdate.waitForCashTransfer
      });
    });
  }

  render(): React.ReactNode {
    return this.state.waitForCashTransfer ?
      <Redirect to={'/dashboard/actions'} /> :
      this.props.children;
  }
}

export default WaitForCashTransfer;