import 'rxjs/add/observable/fromPromise';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';

import nxModule from 'nxModule';
import {defaultHeaderLabels, flattenSubAccounts, mergedLedger} from '../common/gl.utils';
import {CONTINGENT_ACCOUNT_NAME} from "../common/gl.consts";
import './gl-transaction-create.style.less';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';
import {
  BATCH_TRANSACTION_SAMPLE_FILE,
  GL_TRANSACTION_INPUT_MODE,
  GL_TRANSACTION_TYPE,
  NON_CONTINGENT_ACCOUNT_GROUP
} from 'components/general-ledger/transactions/gl-transaction.types';

const templateUrl = require('./gl-transaction-create.template.html');

nxModule.component('glTransactionCreate', {
  templateUrl,
  bindings: {
    'backdated': '<'
  },
  controller: function ($scope, $routeParams, $location, $filter, $route, glLedgerService, branchService,
                        breadcrumbs, glAccountService, glTransactionService, reportModal,
                        confirmation, notification, holidayService) {
    const that = this;
    that.ledgerId = parseInt($routeParams.accountId);
    that.minBackdatedPostingDate = null;
    that.maxBackdatedPostingDate = null;
    that.useCategories = true;
    that.header = [];
    that.glTransactionInputMode = GL_TRANSACTION_INPUT_MODE;
    that.glTransactionType = GL_TRANSACTION_TYPE;

    that.model = {
      operations: [],
      backdatedPostingDate: null,
      transactionType: 'NORMAL',
      remarks: '',
      postingDateValid: true
    };

    that.contentFile = [];

    that.inputMode = 'MANUAL'

    that.allAccounts = [];

    that.useCategoriesChanged = () => {
      that.category = null;
      that.model.operations = [];
    };

    that.applyAccountsFilter = () => {
      that.accountOptions = that.allAccounts.filter(account => {
        if (that.model.transactionType === CONTINGENT_ACCOUNT_NAME) {
          return account.accountGroup === CONTINGENT_ACCOUNT_NAME;
        }

        return account.accountGroup !== CONTINGENT_ACCOUNT_NAME;
      });
    };

    that.turnOffUseCategoriesOnContingentMode = () => {
      if (that.model.transactionType === CONTINGENT_ACCOUNT_NAME) {
        that.useCategories = false;
      }
    };

    that.resetForm = () => {
      that.model = Object.assign(that.model, {
        operations: [],
        remarks: '',
      });
      that.contentFile = null;

      $scope.createTransactionForm.$setPristine();
      that.turnOffUseCategoriesOnContingentMode();
      that.applyAccountsFilter();
    };

    that.transactionTypeChanged = async (newType, oldType) => {
      if (that.hasOperations() || that.hasUploadedFile()) {
        const confirmed = await confirmation(`Changing transaction type will disregard all changes. Do you want to proceed?`);
        if (!confirmed) {
          that.model.transactionType = oldType;
          return;
        }
      }

      that.categoryChanged();
      that.resetForm();
      if (that.model.transactionType === 'CONTINGENT') {
        that.model.operations.push({});
      }
    }

    // Copy newest remark value to all operations with empty remark fields
    that.copyRemarks = o => {
      if (o && o.remarks) {
        let defaultRemark = o.remarks;
        that.model.operations.forEach(op => {
          op.remarks = op.remarks || defaultRemark;
        });
      }
    };

    const fetchGlAccounts = async () => {
      const glAccounts = await glAccountService.fetchAccounts(that.ledgerId, {leafOnly: true}).toPromise();
      that.allAccounts = flattenSubAccounts(glAccounts);
      that.applyAccountsFilter();
    };

    const dataUpdates = async () => {
      const ledger = await mergedLedger({
        glLedgerService,
        branchService,
        accountId: that.ledgerId
      });

      setBreadcrumbs(breadcrumbs, 'gl-transaction-list-label', ledger.template.name);
      setBreadcrumbs(breadcrumbs, 'gl-transaction-create-label', 'Add Manual');

      that.branchId = ledger.branchId;
      that.header = defaultHeaderLabels(ledger);
    }

    const bigSumBy = (collection, itemProperty) =>
      collection.reduce((sum, item) => sum.plus(item[itemProperty] || 0), new BigNumber(0));

    that.calculateDebitSum = () => {
      return bigSumBy(that.model.operations, 'debitAmount');
    };

    that.calculateCreditSum = () => {
      return bigSumBy(that.model.operations, 'creditAmount');
    };

    that.isFormValid = () => {
      return $scope.createTransactionForm.$valid &&
        that.isTransactionBalanced() &&
        that.hasOperations() &&
        that.hasNonZeroTotalAmounts();
    };

    that.canPrint = () => {
      return that.transactionId && $scope.createTransactionForm.$pristine;
    };

    that.print = async () => {
      reportModal.display({
        reportCode: 'GeneralLedgerTicketReport',
        params: {transactionId: that.transactionId, branchId: that.branchId},
        hideXls: true
      });
    };

    that.isTransactionBalanced = () => {
      return that.model.transactionType === CONTINGENT_ACCOUNT_NAME || that.calculateCreditSum().isEqualTo(that.calculateDebitSum());
    };

    that.hasOperations = () => {
      return !_.isEmpty(that.model.operations);
    };

    /**
     * Does both total amounts (debit and credit) are bigger than 0
     */
    that.hasNonZeroTotalAmounts = () => {
      let amounts = [this.calculateDebitSum(), this.calculateCreditSum()];
      if (that.model.transactionType === CONTINGENT_ACCOUNT_NAME) {
        return amounts.some(amount => amount.isGreaterThan(0));
      }
      return amounts.every(val => val.isGreaterThan(0));
    };

    that.resetDebitAmount = operation => {
      if (operation.creditAmount > 0) {
        operation.debitAmount = 0;
      }
    };

    that.resetCreditAmount = operation => {
      if (operation.debitAmount > 0) {
        operation.creditAmount = 0;
      }
    };

    that.cancelChanges = () => {
      const parentPath = _.nth(breadcrumbs.get(), -2).path;
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parentPath));
    };

    that.submit = async () => {
      if (!that.isFormValid()) {
        return;
      }

      const confirmationAction = that.modificationMode ? 'modify' : 'create';
      const proceed = await confirmation(`Do you want to ${confirmationAction} the transaction?`);
      if (proceed) {
        const isBackdated = that.modificationMode ?
          moment(that.model.backdatedPostingDate).isBefore(moment(that.branchSystemDate)) : that.backdated;

        const requestData = {
          categoryId: that.category ? that.category.id : null,
          ledgerId: that.ledgerId,
          backdated: isBackdated,
          backdatedPostingDate: $filter('nxDate')(that.model.backdatedPostingDate),
          contingent: that.model.transactionType === CONTINGENT_ACCOUNT_NAME,
          transactionType: 'MANUAL',
          transactionUnits: that.model.operations.map(operation => {
            return {
              id: operation.id,
              ledgerAccountId: operation.account.id,
              entryType: operation.creditAmount ? 'CREDIT' : 'DEBIT',
              amount: operation.creditAmount || operation.debitAmount,
              remarks: operation.remarks
            }
          })
        };

        if (!that.modificationMode) {
          const response = await glTransactionService.createTransaction(requestData).toPromise();
          $location.path(`/general-ledger/transactions/${that.ledgerId}/modify/${response.output.id}`);
          notification.show('Success', 'Transaction created successfully');
        } else {
          requestData.id = that.transactionId;
          const withChanges = $scope.createTransactionForm.$dirty;
          const response = withChanges ? await glTransactionService.updateTransaction(requestData) : null;

          if (response) {
            // reload route to make form pristine (to enable printing after)
            $route.reload();
            notification.show('Success', 'Transaction updated successfully');
          } else {
            notification.show('Information', 'No changes were made');
          }
        }

      }
    };

    $scope.$watch('$ctrl.contentFile', () => {
      // file was removed -> clear operations added by simulation
      if (!that.hasUploadedFile() && !that.modificationMode) {
        if(that.model.transactionType === 'CONTINGENT') {
          that.model.operations = [{}];
        } else {
          that.model.operations = [];
        }

      }
    });

    const setBackdatingDateRange = async () => {
      const calculatedMinAndMaxRange = await glTransactionService.calculateBackdatedPostingDateProperties(that.modificationMode);
      that.maxBackdatedPostingDate = calculatedMinAndMaxRange.maxBackdatedPostingDate;
      that.minBackdatedPostingDate = calculatedMinAndMaxRange.minBackdatedPostingDate;
      that.branchSystemDate = calculatedMinAndMaxRange.branchSystemDate;
    };

    const fetchDataForModification = async () => {
      const [transaction, glAccounts] = await Promise.all([
        glTransactionService.fetchLedgerTransaction(that.transactionId),
        glAccountService.fetchAccounts(that.ledgerId, {leafOnly: true}).toPromise()
      ]);

      that.allAccounts = flattenSubAccounts(glAccounts);

      that.backdated = transaction.backdated;

      that.model.ledgerId = transaction.ledgerId;
      that.model.backdatedPostingDate = moment(transaction.postingDate).toDate();
      that.model.transactionType = transaction.contingent ? CONTINGENT_ACCOUNT_NAME : 'NORMAL';
      that.model.operations = transaction.transactionUnits.map(unit => {
        const account = that.allAccounts.find(account => account.id === unit.ledgerAccountId);
        return {
          id: unit.id,
          account: account,
          entryType: unit.entryType,
          creditAmount: unit.entryType === 'CREDIT' ? unit.amount : 0,
          debitAmount: unit.entryType === 'DEBIT' ? unit.amount : 0,
          fullCode: account.fullCode,
          remarks: unit.remarks
        };
      });

      that.applyAccountsFilter();

      // invoking setting of backdating range, because the backdated value is fetched from the server
      await setBackdatingDateRange();
    };

    that.$onInit = async () => {
      await dataUpdates();
      await setBackdatingDateRange();

      that.transactionId = parseInt($routeParams.transactionId);
      if (that.transactionId) {
        that.modificationMode = true;
        await fetchDataForModification();
        $scope.createTransactionForm.$setPristine();
      } else {
        await fetchGlAccounts();
      }
      await that.validatePostingDate();
    };

    that.categoryChanged = () => {
      that.model.operations = ((that.category && that.category.ledgerAccountFullCodes) || [])
        .map(fullCode => {
          const account = that.allAccounts.find(account => account.fullCode === fullCode);
          return {
            account,
            debitAmount: 0,
            creditAmount: 0,
            fullCode,
          };
        });
    };

    that.addNewUnit = () => {
      that.model.operations.push({});
    };

    that.removeUnit = (index) => {
      this.model.operations = this.model.operations.filter((op, i) => i !== index);
    };

    that.validatePostingDate = async () => {
      const postingDate = that.backdated ? that.model.backdatedPostingDate : that.branchSystemDate;
      const holidays = await holidayService.fetchHoliday(that.branchId, moment(postingDate), 'ALL');
      this.model.postingDateValid = holidays.length === 0;
    }

    that.inputModeChanged = async (newInputMode, oldInputMode) => {
      if (that.hasOperations() || that.hasUploadedFile()) {
        const confirmed = await confirmation(`Changing mode will disregard all changes. Do you want to proceed?`);
        if (!confirmed) {
          that.inputMode = oldInputMode;
          return;
        }
      }

      that.useCategories = that.inputMode === 'MANUAL';
      that.useCategoriesChanged();
      that.resetForm();
    }

    that.hasUploadedFile = () => {
      return that.contentFile != null && that.contentFile.length > 0;
    }

    that.downloadSampleFile = () => {
      const sampleFileUrl = window.URL.createObjectURL(BATCH_TRANSACTION_SAMPLE_FILE);
      const a = document.createElement('a');
      a.href = sampleFileUrl;
      a.download = 'BatchCreateLedgerTransaction';
      a.click();
    }

    that.simulateButtonEnabled = () => {
      return that.hasUploadedFile()
        && !that.hasOperations()
        && (!that.backdated || (that.model.backdatedPostingDate && that.model.postingDateValid));
    }

    that.simulate = async () => {
      const result = await glTransactionService.simulateBatchTransaction({
        ledgerId: that.ledgerId,
        fileId: that.contentFile[0].id,
        interbranch: false,
        accountGroups: that.model.transactionType === CONTINGENT_ACCOUNT_NAME ? [CONTINGENT_ACCOUNT_NAME] : NON_CONTINGENT_ACCOUNT_GROUP
      });

      if (!result || !result.output) {
        return;
      }
      that.model.operations = result.output;
      await glTransactionService.displaySimulationErrors(result.output);
    }

    that.actionButtonsEnabled = () => {
      return that.modificationMode
        || that.inputMode === 'MANUAL'
        && that.model.transactionType === 'NORMAL'
        && !that.useCategories;
    }
  }
});
