import nxModule from 'nxModule';
import $ from 'jquery';

import {IController} from 'angular';

let classesAdded = 0;
const noScrollClassName = 'no-scroll';

class NoScroll implements IController {
  $onInit(): void {
    classesAdded++;
    $('body').addClass(noScrollClassName);
  }

  $onDestroy(): void {
    classesAdded--;

    if(classesAdded === 0) {
      $('body').removeClass(noScrollClassName);
    }
  }
}

nxModule.component('noScroll', {
  controller: NoScroll
});