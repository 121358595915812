import {LoanPDCStatus} from '../../../service/loan-pdc.types';

export const getStatusStyle = (status: LoanPDCStatus): string => {
  switch (status) {
    case LoanPDCStatus.DUE:
    case LoanPDCStatus.OVERDUE:
    case LoanPDCStatus.ERROR:
    case LoanPDCStatus.REJECTED:
    case LoanPDCStatus.RETURNED:
      return 'red';
    case LoanPDCStatus.PENDING_CLEARING:
    case LoanPDCStatus.UNPOSTED:
    case LoanPDCStatus.POSTPONED:
      return 'orange';
    case LoanPDCStatus.POSTED:
      return 'green';
    case LoanPDCStatus.CANCELED:
      return 'grey';
  }
};