import {AmortizationSchedule} from "components/service/loan.types";

export interface CreditScoreInput {
  interestRate: number,
  principalAmount: number,
  paymentIntervalDefinitionId: number,
  loanTypeId: number,
  customerId: number,
  grantDate: string,
  totalAmortizationNumber: number,
  firstAmortizationDueDate: string
}

export interface CreditScore {
  prediction: number,
  bestThresholdLGB: number,
  accountsHistory: number,
  creditHistory: number,
  demographicData: number,
  loanApplicationData: number
}

export type NonPredictionKeys = keyof Omit<CreditScore, 'prediction' | 'bestThresholdLGB'>;

export interface Feature {
  feature: string,
  value: number
}

interface UsedData {
  tableName: string,
  description: string,
  examples: string,
  size: string,
  records: number
}

interface AwsInstance {
  description: number,
  instanceSize: string,
  vCPU: number,
  memory: number,
  instanceStorage: string,
  networkBandwith: number,
  EDSBandwith: number,
}

interface TrainingTime {
  task: string,
  time: number
}

export interface Metric {
  metric: string,
  score: number
}

interface ConfusionMatrix {
  field: string,
  value: number
}

export interface CreditScoreDescription {
  totalDatabaseSize: string,
  usedData: UsedData[],
  lightGBMmodel: string,
  awsInstance: AwsInstance,
  trainingTime: TrainingTime[],
  metrics: Metric[],
  confusionMatrix: ConfusionMatrix[],
  featureImportance: { [key: string]: Feature[] }
}

export interface LoanApplicationDetails {
  typeId: number,
  customerId: number,
  paymentIntervalDefinitionId: number,
  grantDate: string,
  firstAmortizationDueDate: string;
  productName?: string,
  creationType: string,
  officerName?: string,
  loanSecurity?: string,
  principalAmount: number,
  interestRate: number,
  paymentIntervalName?: string,
  maturityDate: string,
  totalAmortizationNumber: number,
  amortizationSchedule: AmortizationSchedule,

  customerFirstName?: string,
  customerLastName?: string,
  customerEffectiveName?: string,
  customerNumber?: string,
  customerGender?: string,
  customerCivilStatus?: string,
  customerBirthDate?: string,
  customerBirthPlace?: string,
  customerMobilePhoneNumber?: string,
}

export const colorRanges: [number, string][] = [
  [-4, 'bad'],
  [-1, 'poor'],
  [0, 'average'],
  [1, 'good'],
  [4, 'excellent']
];

export const findColor = (value: number): string => {
  const color = colorRanges.find(scale => scale[0] > value);
  if (color) {
    return color[1];
  }

  return 'outstanding';
}

