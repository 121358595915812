import React, {ReactElement} from 'react';
import RequiredFieldAsterisk from 'RequiredFieldAsterisk';
import styles from './TreeLabel.scss';

export interface TreeLabelProps {
  label: string;
  required?: boolean;
  error?: boolean;
}

const TreeLabel = (props: TreeLabelProps): ReactElement => {
  return <div className={styles.label}>
    {props.label} {props.required && <span className={props.error ? styles.error : ''}><RequiredFieldAsterisk /></span>}
  </div>;
};

export default TreeLabel;
