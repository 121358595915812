import nxModule from 'nxModule';
import _ from 'lodash';
import $ from 'jquery'
import templateUrl from './multiselect.template.html';

class Multiselect {

  constructor($scope, $element) {
    this.$scope = $scope;
    this.$element = $element;
    this.showDropdown = false;

    this.hideDropdownListener = (e) => {
      const $clickedItem = $(e.target);

      if ($clickedItem.closest(this.$element).length > 0) {
        return;
      }

      this.$scope.$apply(() => this.showDropdown = false);
    };
  }

  $onInit() {
    $(document).on('click', this.hideDropdownListener);

    this.ngModelCtrl.$isEmpty = (value) => {
      return _.isEmpty(value);
    };
  }

  $onDestroy() {
    $(document).off('click', this.hideDropdownListener);
  }

  $onChanges(changes) {
    if (changes.hasOwnProperty('items')) {
      this.deepCopyItems = this.items ? _.cloneDeep(this.items) : [];
      this.updateItems();
      this.onChangeNgModel();
    }
    if (changes.hasOwnProperty('ngModel') && changes['ngModel'] != null) {
      this.updateItems();
    }
  }

  onChangeNgModel(){
    const viewValueItems = this.getSelectedItems();
    if(viewValueItems.length){
      this.ngModelCtrl.$setViewValue(viewValueItems);
    }
  }

  isSelectAllOption() {
    return !!this.selectAllOption && this.deepCopyItems?.length > 0;
  }

  getUnfolded() {
    return !!this.unfolded;
  }

  getLabelProperty() {
    return this.labelProperty || 'label';
  }

  getValueProperty() {
    return this.valueProperty || 'value';
  }

  getSelectedItems() {
    return this.deepCopyItems ? this.deepCopyItems.filter(item => item.checked).map(item => item[this.getValueProperty()]) : [];
  }

  /**
   * update selected items and notify parent through ng-change
   */
  updateNgModel() {
    this.ngModelCtrl.$setViewValue(this.getSelectedItems());
  }

  updateItems() {
    if (!this.deepCopyItems || !this.ngModel) return;

    for (let item of this.deepCopyItems) {
      item.checked = _.includes(this.ngModel, item[this.getValueProperty()]);
    }

    this.selectAllSelected = (this.items?.length > 0 && this.ngModel?.length === this.items?.length);
  }

  selectAllChange() {
    this.deepCopyItems.forEach(el => el.checked = this.selectAllSelected);
    this.updateNgModel();
  }

  onCheckboxChange() {
    this.updateNgModel();
    this.selectAllSelected = this.items?.length > 0 && this.getSelectedItems().length === this.items.length;
  }

  renderLabel() {
    if (this.selectAllSelected) {
      return 'All items selected';
    }

    const selectedItems = this.getSelectedItems();
    const length = selectedItems.length;
    if (length === 1) {
      const value = selectedItems[0];
      const item = _.find(this.deepCopyItems, i => i[this.getValueProperty()] === value);
      return item ? item[this.getLabelProperty()] : '';
    } else {
      return `${length} items selected`;
    }
  }

}

nxModule.component('multiselect', {
  templateUrl,
  require: {
    ngModelCtrl: 'ngModel'
  },
  bindings: {
    items: '<',
    ngModel: '<',
    ngDisabled: '<',
    ngRequired: '<',
    labelProperty: '<',
    valueProperty: '<',
    unfolded: '<',
    selectAllOption: '<',
    validate: '<'
  },
  controller: Multiselect
});