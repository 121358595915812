import {FeeDefinition} from 'components/service/fees/fee.types';
import {Charge, ProductFeeInput} from 'components/service/loan-type.types';
import nxModule from 'nxModule';

export default class LoanFeeService {

  getChargeFromFeeDefinition(fee: FeeDefinition): ProductFeeInput {
    const loanBoard = fee.extraOptions?.LOAN_BOARD;
    const fixedAmount = loanBoard?.defaultFixedAmount ?? fee.fixedAmount;
    const percentageAmount = loanBoard?.defaultPercentageAmount ?? fee.percentageAmount;

    const rate = fee.calculationMethod === 'FIXED_AMOUNT' ? fixedAmount : percentageAmount;
    return {
      rate: rate ?? 0,
      type: fee.calculationMethod
    }
  }
}

nxModule.service('loanFeeService', LoanFeeService);
