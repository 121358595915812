import {CustomerCache} from 'components/service/customer.cache.types';
import {AttachedFile} from 'components/service/file.service';
import _ from 'lodash';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './activate-accounts.template.html';

class ActivateAccounts {
  private contentFile: AttachedFile[] = [];
  private readonly sampleFile = new Blob([`AccountNumber
CB01-22-230889-1
`], {type: 'text/csv'});

  constructor(private confirmationTemplate: ConfirmationTemplate, private command: CommandService, private $route: NxRouteService, private popup: Popup, private customerCache: CustomerCache) { }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to activate accounts?',
    });

    if(confirmed) {
      const executed = await this.command.execute<{fileId: number}, {executionErrors: Record<string, string>}>('ActivateAccounts', {
        fileId: this.contentFile[0].id
      }).toPromise();

      if(executed.approvalRequired) {
        return;
      }

      const executionErrors = executed.output.executionErrors;
      if (executionErrors && Object.keys(executionErrors).length > 0) {
        const header = '<p><span class="red"><strong>WARNING!</strong> Accounts listed below resulted in errors and were not updated:</span></p>';
        const body: string[][] = [];
        _.forEach(executionErrors,err => {
          body.push(Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
        });
        const errorDetails = [header, ...body].join('');
        await this.popup({
          header: 'Upload result',
          text: errorDetails,
          renderHtml: true
        });
      }

      if (this.customerCache.loadedCustomerId) {
        this.customerCache.depositAccounts(this.customerCache.loadedCustomerId).refetch();
      }

      this.$route.reload();
    }
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'ActivateAccounts';
    a.click();
  }

}

nxModule.component('activateAccounts', {
  templateUrl,
  controller: ActivateAccounts
});
