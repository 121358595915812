import {ClickAwayListener} from '@material-ui/core';
import {NxGenericTextField} from '@nextbank/ui-components';
import clsx from 'clsx';
import {TreeNode} from 'inspire-tree';
import React, {ReactElement, useCallback, useState} from 'react';
import {TreeNodeViewComponentFactory} from 'tree/editableTree/EditableSubtreeView';
import ChevronIcon from 'tree/multiselectTree/icon-chevron.svg';
import SelectSubtreeList from 'tree/SelectSubtreeList';
import TreeWrapper, {ChildrenProps, TreeWrapperProps} from 'tree/TreeWrapper';
import styles from './SingleSelectTree.scss';

export type SingleSelectTreeProps<VALUE> = Omit<TreeWrapperProps<VALUE>, 'onChange' | 'value' | 'onBlur' | 'checkedValues' | 'children' | 'variant'> & {
  TreeNodeView: TreeNodeViewComponentFactory<VALUE>;
  onChange: (node: VALUE) => unknown;
  onBlur?: () => unknown;
  label: string;
  disabled?: boolean;
  error?: string;
  required?: boolean;
  inputLabel?: string;
  selectedValue?: VALUE;
  name: string;
};

const getSelectedValue = function<VALUE>(nodes: TreeNode[], mapping: Map<string, VALUE>): VALUE[] {
  return nodes
    .flatMap(node => {
      const values = [];
      if(node.selected()) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        values.push(mapping.get(node.id)!);
      }

      values.push(...getSelectedValue(node.getChildren(), mapping));

      return values;
    });
};
const SingleSelectTree = function <VALUE>(props: SingleSelectTreeProps<VALUE>): ReactElement {
  const {disabled = false, TreeNodeView} = props;
  const [open, setOpen] = useState(false);

  const endAdornment = <div className={clsx(styles.icon, open ? styles.open : '')}>
    <ChevronIcon/>
  </div>;

  const ListComponent = useCallback(({treeNodes, mapping}: ChildrenProps<VALUE>): ReactElement =>
      <SelectSubtreeList<VALUE> treeNodes={treeNodes}
                                mapping={mapping}
                                disabled={disabled}
                                TreeNodeView={TreeNodeView}
                                variant="SINGLE_SELECT"
                                onClick={(treeNode: TreeNode): void => {
                                  treeNode.toggleSelect()
                                }}/>
    , [disabled, TreeNodeView]);

  return <ClickAwayListener onClickAway={(): void => {
    setOpen(false);
  }}>
    <div className={
      clsx(styles.wrapper,
        props.disabled ? styles.disabled : '',
        props.error ? styles.error : ''
      )}>

      <div onClick={(e: React.MouseEvent): void => {
        const target = e.target;
        if(!(target instanceof Element)) {
          return;
        }

        if(target.closest('.MuiFormControl-root')) {
          setOpen(state => !state);
        }
      }}
         className={clsx(styles.inputWrapper, styles.fullWidth)}
         tabIndex={-1}
      >
        <NxGenericTextField disabled={disabled}
                            label={props.label}
                            positionAbsoluteError={false}
                            name={props.name}
                            onBlur={(): void => {
                               if(!props.onBlur) {
                                 return;
                               }
                               props.onBlur();
                             }}
                            value={props.inputLabel}
                            error={props.error}
                            endAdornment={endAdornment}
                            required={props.required} />
      </div>
      {open && <div tabIndex={-1}>
        <TreeWrapper<VALUE> {...props}
          className={styles.treeWrapper}
          nodes={props.nodes}
          selectedValue={props.selectedValue}
          variant="singleselect"
          onChange={(nodes: TreeNode[], mapping: Map<string, VALUE>): void => {
            props.onChange(getSelectedValue(nodes, mapping)[0]);
            setOpen(false);
          }}>
        {ListComponent}
        </TreeWrapper>
      </div>
      }
    </div>
  </ClickAwayListener>
}

export default React.memo(SingleSelectTree) as typeof SingleSelectTree;