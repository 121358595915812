import nxModule from 'nxModule';
import {BranchService} from "../../../service/branch.service";

import templateUrl from './process-backdated-transactions.template.html';
import {Branch} from "management/BranchTypes";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import Notification from "shared/utils/notification";

class BackdatedTransactionsProcessor {
  branch: Branch | null = null;
  branches: Branch[] = [];

  constructor(private confirmationTemplate: ConfirmationTemplate, private $route: NxRouteService,
              private branchService: BranchService, private command: CommandService,
              private notification: Notification) {}

  async $onInit() {
    this.branches = await this.branchService.toPromise();
  }

  async execute() {
    if(!this.branch) {
      return;
    }

    const confirmed = await this.confirmationTemplate({
      question: `Do you want to process backdated GL transactions for branch ${this.branch.name}?`,
    });

    if(confirmed) {
      const response = await this.command.execute('ProcessBackdatedTransactions', {
        id: this.branch.id
      }).success(() => {
        this.notification.show('Successfully scheduled: ProcessBackdatedTransactions');
      }, true).toPromise();

      if(!response.approvalRequired) {
        this.$route.reload();
      }
    }
  }
}

nxModule.component('processBackdatedTransactions', {
  templateUrl,
  controller: BackdatedTransactionsProcessor
});
