import nxModule from 'nxModule';
import * as ng from 'angular';
import templateUrl from './update-cif-branch.template.html';
import {BranchService} from "../../../service/branch.service";
import { CustomerSearchResult } from 'components/customer/profile/customer-search-result.types';
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";
import {PageResult} from "tools/HttpTypes";
import {NxRouteService} from "routes/NxRouteService";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import {RequestFormatter} from "shared/utils/requestFormatter.types";


type CifSelectionMode = 'file' | 'manual-input';

interface CustomerDetails {
  name: string;
  customerNumber: string;
  sourceBranch: string;
  customerType: string;
  customerStatus: string;
}

type CustomerDescription = Partial<CustomerDetails> | Pick<CustomerDetails, 'customerNumber'>;

class UpdateCifBranch {
  cifSelectionModes: {label: string, value: CifSelectionMode}[] = [
    {
      label: 'File',
      value: 'file'
    }, {
      label: 'Manual Input',
      value: 'manual-input'
    }
  ];

  branches: Branch[] = [];
  sourceBranch: Branch | null = null;
  targetBranch: Branch | null = null;
  cifSelectionMode: CifSelectionMode | null = null;
  customersToBeUpdated: CustomerDescription[] = [];
  searchCifNumber: string = "";
  innerFile: string = '';

  get file(): string {
    return this.innerFile;
  }

  set file(text: string) {
    this.innerFile = text;

    this.customersToBeUpdated = (text ?? '')
        .trim()
        .split('\n')
        .map(line => line.trim())
        .map(customerNumber => ({
            customerNumber,
        }));
  }

  constructor(private http: HttpService, private $scope: ng.IScope, private $route: NxRouteService,
              private branchService: BranchService, private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService, private popup: Popup, private requestFormatter: RequestFormatter) {
  }

  async $onInit(): Promise<void> {
    this.branches = await this.branchService.toPromise();
  }

  onCifSelectionChanged(): void {
    this.customersToBeUpdated = [];
  }

  async onSourceBranchChanged(oldValue: Branch): Promise<void> {
      if (this.customersToBeUpdated.length > 0){
        const confirmed = await this.confirmationTemplate({
            question: `Changing the source branch from ${oldValue?.name} to ${this.sourceBranch?.name} will clear the following selections. Do you wish to proceed?`,
            details: [
              {label: 'Cif numbers', description: this.customersToBeUpdated.map(c => c.customerNumber).toString()},
            ]
          });
          if(confirmed){
            this.customersToBeUpdated = [];
          }else{
              this.sourceBranch = oldValue;
          }
      }
  }

  async addCustomer(): Promise<void> {
    if(!this.sourceBranch) {
      return;
    }

    if (this.checkIfExisting(this.searchCifNumber)) {
      this.popup({
        text: `Cif number ${this.searchCifNumber} is already included in the list.`
      });

      return;
    }
    const searchCriteria = {
        customerNumber: this.searchCifNumber,
        customerId: null,
        documentNumber: null,
        name: null,
        birthDate: null,
        productNumber: null,
        checkNumber: null,
        statuses: ['ACTIVE', 'PROSPECT'],
        customerTypes: ['INDIVIDUAL', 'CORPORATE'],
        registrationDate: null
      };

    const data = await this.http.get<PageResult<CustomerSearchResult>>(
        `/customers${this.requestFormatter.convertToQueryParams(searchCriteria)}`,
        {nxLoaderText: 'Searching for customer...'}
        ).toPromise();

    if (data && data.result && data.result.length === 1 && data.result[0].branchId === this.sourceBranch.id) {
      this.completeDataAndAddToPool(data.result[0]);
    } else {
      this.popup({
          text: `No customer found with cif number [${this.searchCifNumber}] and source branch [${this.sourceBranch.name}].`
        }
      );
    }
  }

  completeDataAndAddToPool(customerSearchResult: CustomerSearchResult): void {
     const customerDetails: CustomerDescription = {
      sourceBranch: this.sourceBranch?.name,
      ...customerSearchResult
    };
    this.customersToBeUpdated.push(customerDetails);
    this.searchCifNumber = "";
  }

  checkIfExisting(customerNumber: string): boolean {
      return this.customersToBeUpdated.find(c => c.customerNumber === customerNumber) !== undefined;
  }

  removeCustomer(index: number): void {
    this.customersToBeUpdated.splice(index, 1);
  }


  async execute(): Promise<void> {
    if(!this.sourceBranch || !this.targetBranch) {
        this.popup({text: `Source branch and target branch are required.`});
        return;
    }

    if(this.sourceBranch.id === this.targetBranch.id) {
      this.popup({text: `Source branch ${this.sourceBranch.name} and target branch ${this.targetBranch.name} are the same.`});
      return;
    }

    const customerNumbers = this.customersToBeUpdated.map(p => p.customerNumber);

    const targetBranchId = this.targetBranch.id;
    const sourceBranchId = this.sourceBranch.id;

    const payload = {
      targetBranchId: targetBranchId,
      sourceBranchId: sourceBranchId,
      customerNumbers: customerNumbers
    };

    const confirmed = await this.confirmationTemplate({
      question: 'These cif numbers will be moved to another branch. Do you wish to proceed?',
      details: [
        {label: 'Cif numbers', description: customerNumbers.toString()},
        {label: 'Branch name', description: this.targetBranch.name}
      ]
    });

    if(confirmed) {
      await this.command.execute('UpdateCifBranch', payload).toPromise();
      await this.popup({
        text: `Mother branch of customer(s) successfully changed to ${this.targetBranch.name}`,
      });
      this.$route.reload();
    }
  }

  downloadSample(): void {
    const sampleFileUrl = window.URL.createObjectURL(new Blob(["122148\n122150"],
      {type: 'text/plain'})
    );

    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = "sample-update-cif-branch.txt";
    a.click();
  }

  getBranchLabel(branch: Branch): string {
    return `${branch.name} : ${branch.systemDate}`;
  }
}

nxModule.component('updateCifBranch', {
  templateUrl,
  controller: UpdateCifBranch
});
