import {IScope} from 'angular';
import templateUrl from 'components/customer/loans/collateral/loan-collateral.template.html';
import {LoanCollateral} from 'components/service/loan.types';
import {Collateral} from 'customer/collateral/Collateral';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import Notification from 'shared/utils/notification';

class LoanCollateralComponent {
  protected customerId!: number;
  protected editMode!: boolean;
  private loanId!: number;
  private loanCollaterals!: LoanCollateral[];
  private allCollaterals!: Collateral[];
  private loanSecurityId!: number;


  protected selectedCollateralId!: number;

  constructor(private http: HttpService, private $scope: IScope, private notification: Notification) {
  }

  async $onInit(): Promise<void> {
    this.allCollaterals = await this.http.get<Collateral[]>(`/customers/${this.customerId}/collaterals/`).toPromise();
  }

  getApplicableCollaterals(): Collateral[] {
    return this.allCollaterals ? this.allCollaterals.filter(c => c.securityId === this.loanSecurityId) : [];
  }

  addCollateral(): void {
    const existingIndex = this.loanCollaterals?.findIndex(lc => lc.collateralId === this.selectedCollateralId);
    if(existingIndex >= 0) {
      this.notification.show('Warning', 'Selected collateral has already been added');
      return;
    }

    if(!this.loanCollaterals) {
      this.loanCollaterals = [];
    }
    const collateral = this.allCollaterals.find(c => c.id === this.selectedCollateralId);
    const files = collateral?.fileIds?.map(fileId => {
      return {id: fileId};
    });

    this.loanCollaterals.push({
      collateralId: this.selectedCollateralId,
      loanId: this.loanId,
      name: collateral?.name,
      fileIds: collateral?.fileIds
    });
  }

  removeCollateral(collateral: LoanCollateral) : void {
    const existingIndex = this.loanCollaterals?.findIndex(lc => lc.collateralId === collateral.collateralId);
    this.loanCollaterals.splice(existingIndex, 1);
  }
}

nxModule.component('loanCollateral', {
  templateUrl,
  bindings: {
    customerId: '<',
    loanCollaterals: '=',
    loanSecurityId: '<',
    editMode: '<'
  },
  controller: LoanCollateralComponent
});
