import nxModule from "nxModule";
import templateUrl from './customer-loans-edit-co-makers.template.html';
import {Loan} from "components/service/loan.types";
import {IFormController, ILocationService} from "angular";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import Notification from "shared/utils/notification";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class CustomerLoansEditComaker {
  private loan!: Loan;
  private form!: IFormController;

  constructor(private $location : ILocationService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private customerCache: CustomerCache,
              private notification: Notification) {

  }

  async save() : Promise<void> {
    const confirmed = await this.confirmationTemplate({
      information: `The update will not affect forms which were already printed prior to this action.`,
      question: `Are you sure you want to proceed?`
    });

    if (!confirmed) {
      return;
    }

    const hasChanges = !(this.form.$pristine && this.form.$pristine);

    if (!hasChanges) {
      this.notification.show("No changes were made to the loan co-makers.");
      this.redirectBack();
      return;
    }

    await this.command.execute('EditLoanCoMakers', {
      loanId: this.loan.id,
      coMakers: this.loan.coMakers
    }).toPromise();

    await this.customerCache.profile(this.loan.customerId).refetch();
    await this.customerCache.loans(this.loan.customerId).refetch();
    this.redirectBack();
  }

  redirectBack() : void {
    this.$location.path(`/customer/${this.loan.customerId}/loans/${this.loan.id}`);
  }
}
nxModule.component('customerLoanEditCoMakers', {
  templateUrl,
  controller: CustomerLoansEditComaker,
  bindings: {
    loan: '<'
  }
});
