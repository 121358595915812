// noinspection DuplicatedCode

import nxModule from 'nxModule';
import templateUrl from './batch-product-unlock.template.html';
import _ from "lodash";
import {NxRouteService} from "routes/NxRouteService";
import {ProductLockCache} from "components/service/product-lock/product-lock.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {AttachedFile} from "components/service/file.service";

class BatchProductUnlock {
  private contentFile: AttachedFile[] = [];
  private remarks!: string;

  private readonly fileExample = [
    'product_number',
    '001-0100-00001-0',
    '002-0200-00002-1'
  ].join('\n');

  private readonly sampleFile = new Blob([this.fileExample], {type: 'text/csv'});

  constructor(private readonly command: CommandService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly popup: Popup,
              private readonly $route: NxRouteService,
              private readonly productLockCache: ProductLockCache) {

  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Do you want to process uploaded products?',
    });

    if (!confirmed) {
      return;
    }

    const executed = await this.command.execute<unknown, {executionErrors: [string, string][], productIds?: number[]}>('BatchProductUnlock', {
      fileId: this.contentFile[0].id,
      remarks: this.remarks
    }).toPromise();

    const executionErrors = executed.output.executionErrors;
    if (executionErrors && !_.isEmpty(executionErrors)) {
      const header = '<p><span class="red"><strong>WARNING!</strong> Products listed below resulted in errors and will not be unlocked:</span></p>';
      const body: string[] = [];
      _.forEach(executionErrors, err => {
        body.push(...Object.entries(err).map(([errMessage, errValue]) => `<strong>${errMessage}</strong>: ${errValue}<br>`));
      });
      const errorDetails = [header, ...body].join('');
      await this.popup({
        header: 'Upload result',
        text: errorDetails,
        renderHtml: true
      });
    }
     const productIds = executed.output.productIds;
     if (productIds && !_.isEmpty(productIds)) {
       productIds.forEach((p: number) => this.productLockCache.withParam(p).evict());
     }
    this.$route.reload();
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'BatchProductUnlock.csv';
    a.click();
  }
}

nxModule.component('batchProductUnlock', {
  templateUrl,
  controller: BatchProductUnlock
});
