import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import moment from 'moment';

const templateUrl = require('./partial-withdrawal-check.template.html');
nxModule.component('customerTermDepositPartialWithdrawalCheck', {
  templateUrl: templateUrl,
  controller: function ($route, $filter, $location, command, http, authentication, customerCache, productDefinitionService,
                        termDepositsService, confirmationTemplate, depositoryAccountCache, branchService, termDepositCalculator,
                        checkMicrService, termDepositPartialWithdrawalService, notification, feeDefinitionsCache) {
    const that = this;
    const customerId = $route.current.params['customerId'];
    const depositId = $route.current.params['depositId'];

    that.formatErrorColumns =  [
      { title: 'Errors', field: 'errors'}
    ];

    // Deposit data & interest forecast
    that.deposit = {};
    that.interestForecast = {};
    that.maxWithdrawalAmount = null;
    that.beforeRolloverFees = null;
    that.afterRolloverFees = null;
    that.isSimulated = false;

    // Depository accounts
    that.depositoryAccounts = undefined;
    that.accountSelectConfig = {
      placeholder: 'Select depository account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    that.override = {
      grossInterest: null,
      withholdingTax: null,
      netInterest: null,
      maxWithdrawalAmount: null
    };

    // Command input
    that.request = {
      entryType: 'DEBIT',
      productId: depositId,
      customerId: customerId,
      override: null,
      amount: null,
      depositoryAccountId: null,
      checkNumber: null,
      micrNumber: null,
      validFrom: null,
      payee: null,
      remarks: null,
      interestOverride: {
        grossInterest: null,
        withholdingTax: null
      }
    };

    that.$onInit = async () => {
      const [deposits, depositTypes, productDefinitions, depositoryAccounts, customerProfile, branches, feeDefinitions, forecast] = await Promise.all([
        customerCache.termDeposits(customerId).toPromise(),
        termDepositsService.toPromise(),
        productDefinitionService.toPromise(),
        depositoryAccountCache.toPromise(),
        customerCache.profile(customerId).toPromise(),
        branchService.toPromise(),
        feeDefinitionsCache.toPromise(),
        http.post(`/products/deposits/${depositId}/interest`, {mode: 'TERMINATION'}).toPromise()
      ]);

      const deposit = _.find(deposits, d => Number(d.id) === Number(depositId));
      that.deposit = {
        ...deposit,
        depositType: _.find(depositTypes, {id: deposit.typeId}),
        productDefinition: _.find(productDefinitions, {id: deposit.definitionId})
      };

      that.request.remarks = `Partial withdrawal from ${deposit.productNumber}`;

      that.depositoryAccounts = _.filter(depositoryAccounts, a => a.brstn && a.accountNumber && a.branchId === authentication.context.branchId && a.accountType === 'CHECKING');

      that.request.payee = customerProfile.effectiveName;

      const authBranch = _.find(branches, {id: authentication.context.branchId});
      that.request.validFrom = moment(authBranch.systemDate).toDate();

      that.feeDefinitions = feeDefinitions;

      that.interestForecast = {
        ...forecast,
        productId: depositId,
        terminationFees: forecast.terminationFees.map(fee => termDepositPartialWithdrawalService.mapPartialWithdrawalFeeDetails(feeDefinitions, fee))
      };

      //set override values
      that.override = {
        ...that.override,
        grossInterest: that.interestForecast.interest,
        withholdingTax: that.interestForecast.withholdingTax,
        netInterest: that.interestForecast.netInterest
      }
      that.maxWithdrawalAmount = that.calculateMaxWithdrawalAmount();
      that.override = {
        ...that.override,
        maxWithdrawalAmount: that.maxWithdrawalAmount
      };
    }

    that.calculateMaxWithdrawalAmount = (isOverride = false) => {
      const totalTerminationFee = termDepositPartialWithdrawalService.sum(that.interestForecast.terminationFees, isOverride);
      const totalBeforeRolloverFee = that.beforeRolloverFees ? termDepositPartialWithdrawalService.sum(that.beforeRolloverFees, isOverride) : 0;
      const totalAfterRolloverFee = that.afterRolloverFees ? termDepositPartialWithdrawalService.sum(that.afterRolloverFees, isOverride) : 0;

      return termDepositPartialWithdrawalService.calculateMaxWithdrawalAmount(
        that.deposit.balance,
        that.deposit.depositType.minimalBalance,
        that.interestForecast.netInterest,
        totalTerminationFee,
        totalBeforeRolloverFee,
        totalAfterRolloverFee);
    }

    that.setMaxWithdrawalAmount = (isOverride) => {
      if(isOverride){
        that.override.maxWithdrawalAmount = that.calculateMaxWithdrawalAmount(true);
      } else {
        that.maxWithdrawalAmount = that.calculateMaxWithdrawalAmount();
      }
    }

    that.setRolloverAmount = (rolloverAmount, isOverride = false) => {
      if(isOverride){
        that.override.netRolloverAmount = rolloverAmount;
      } else {
        that.netRolloverAmount = rolloverAmount;
      }
    }

    that.setDefaultWithdrawalAmount = () => {
      if(that.overrideCheckbox && !that.request.amount) {
        that.request.amount = that.maxWithdrawalAmount;
      }
    }

    that.setOverrideMaxWithdrawalAmount = () => {
      that.override.maxWithdrawalAmount = that.calculateMaxWithdrawalAmount(true);
    }

    that.updateMicr = () => {
      that.request.micrNumber = checkMicrService.createMicr(
        that.request.checkNumber,
        Number(that.request.depositoryAccountId),
        that.depositoryAccounts
      );
    };

    that.onDepositoryAccountChange = () => {
      // Reset account-related input properties
      that.request.brstn = null;
      that.request.accountNumber = null;
      // Find depository account & setup properties
      const account = _.find(that.depositoryAccounts, a => Number(a.id) === Number(that.request.depositoryAccountId));
      if (account) {
        that.request.brstn = account.brstn;
        that.request.accountNumber = account.accountNumber;
      }
      that.updateMicr();
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.requireSimulation = () => {
      that.isSimulated = false;
    }

    that.isSimulatePartialWithdrawalDisabled = () => {
      return that.transactionForm.$invalid;
    }

    that.isPartialWithdrawalDisabled = () => {
      return !that.isSimulated || that.transactionForm.$invalid;
    }

    that.prepareRequest = (isSimulationRequest = false) => {
      if (that.overrideCheckbox) {
        return {
          ...that.request,
          terminationFeesOverride: termDepositPartialWithdrawalService.mapFees(that.interestForecast.terminationFees, true),
          beforeRolloverFeesOverride: termDepositPartialWithdrawalService.mapFees(that.beforeRolloverFees, true),
          afterRolloverFeesOverride: termDepositPartialWithdrawalService.mapFees(that.afterRolloverFees, true),
          interestOverride: {
            grossInterest: new BigNumber(that.interestForecast.interest).isEqualTo(that.override.grossInterest) ? that.interestForecast.interest : that.override.grossInterest,
            withholdingTax: new BigNumber(that.interestForecast.withholdingTax).isEqualTo(that.override.withholdingTax) ? that.interestForecast.withholdingTax : that.override.withholdingTax
          },
          amount: that.override.amount
        }
      }

      return {
        ...that.request,
        terminationFeesOverride: termDepositPartialWithdrawalService.mapFees(that.interestForecast.terminationFees),
        beforeRolloverFeesOverride: isSimulationRequest ? null : termDepositPartialWithdrawalService.mapFees(that.beforeRolloverFees),
        afterRolloverFeesOverride: isSimulationRequest ? null : termDepositPartialWithdrawalService.mapFees(that.afterRolloverFees),
        interestOverride: null
      }
    }

    that.calculateRolloverAmount = (isOverride = false) => {
      const overrideWithdrawAmount = that.override.amount ? that.override.amount : 0;
      const withdrawAmount =  isOverride ? overrideWithdrawAmount : that.request.amount;

      return termDepositPartialWithdrawalService.calculateRolloverAmount(
        that.deposit.balance,
        that.interestForecast.netInterest,
        withdrawAmount,
        termDepositPartialWithdrawalService.sum(that.interestForecast.terminationFees, isOverride),
        termDepositPartialWithdrawalService.sum(that.beforeRolloverFees, isOverride),
        termDepositPartialWithdrawalService.sum(that.afterRolloverFees, isOverride));
    }

    that.simulatePartialWithdrawal = async () => {
      try {
        const request = that.prepareRequest(true);
        const {output} = await command.execute('SimulateDepositPartialWithdrawalCheck', request, {nxLoaderText: 'Simulating partial withdrawal'}).toPromise();

        const simulatedTerminationFees = output.preterminationFees.map(fee => termDepositPartialWithdrawalService.mapPartialWithdrawalFeeDetails(that.feeDefinitions, fee));
        const simulatedBeforeRolloverFees = output.rolloverServiceOutput.beforeRolloverFees.map(fee => termDepositPartialWithdrawalService.mapPartialWithdrawalFeeDetails(that.feeDefinitions, fee));
        const simulatedAfterRolloverFees = output.rolloverServiceOutput.afterRolloverFees.map(fee => termDepositPartialWithdrawalService.mapPartialWithdrawalFeeDetails(that.feeDefinitions, fee));

        that.interestForecast.terminationFees = termDepositPartialWithdrawalService.applySimulatedPartialWithdrawalFee(that.interestForecast.terminationFees, simulatedTerminationFees, that.overrideCheckbox);
        that.beforeRolloverFees = termDepositPartialWithdrawalService.applySimulatedPartialWithdrawalFee(that.beforeRolloverFees, simulatedBeforeRolloverFees, that.overrideCheckbox);
        that.afterRolloverFees = termDepositPartialWithdrawalService.applySimulatedPartialWithdrawalFee(that.afterRolloverFees, simulatedAfterRolloverFees, that.overrideCheckbox);

        that.setMaxWithdrawalAmount(that.overrideCheckbox);
        that.setRolloverAmount(output.rolloverServiceOutput.netRolloverAmount, that.overrideCheckbox);

        that.isSimulated = true;
        that.simulationErrors = that.prepareFormatErrors(output.error);
      } catch (error) {
        notification.show("Error", "Failed to simulate partial withdrawal.")
        console.error(error);
      }
    }

    that.partialWithdrawal = async () => {
      const rolloverAmount = that.overrideCheckbox
        ? that.calculateRolloverAmount(true)
        : that.calculateRolloverAmount();

      const request = that.prepareRequest();
      const confirmed = await confirmationTemplate({
        question: `Do you want to withdraw ${$filter('nxCurrency')(that.request.amount)} and rollover the deposit?`,
        details: [
          {label: 'Product number', description: that.deposit.productNumber},
          {label: 'Rollover amount', description: $filter('nxCurrency')(rolloverAmount)},
          {label: 'Check number', description: that.request.checkNumber},
          {label: 'Payee', description: that.request.payee},
          {label: 'Valid from', description: $filter('prettyDate')(that.request.validFrom)}
        ],
        warning: 'This operation cannot be reverted safely.<br>Please make sure that the data is correct.'
      });
      if (!confirmed) return;

      try{
        await command.execute('DepositPartialWithdrawalCheck', request, {nxLoaderText: 'Performing partial withdrawal'}).toPromise();
        customerCache.termDeposits(customerId).refetch();
        that.redirectBack();
      } catch (error) {
        notification.show("Error", "Failed to perform partial withdrawal.")
        console.error(error);
      }
    };

    that.prepareFormatErrors = (error) => {
      return error ? [{errors: error}] : [];
    }

    that.recalculateOverrideGrossInterestAndMaxWithdrawalAmount = () => {
      that.override.grossInterest = termDepositCalculator.recalculateGrossInterest(that.override.netInterest, that.override.withholdingTax);
      that.override.maxWithdrawalAmount = that.calculateMaxWithdrawalAmount(true);
    };
  }
});
