import {
    NxButton,
    NxButtonVariant,
    NxCheckbox,
    NxInput,
    NxRow,
    NxRowPosition,
    NxStack,
    NxTable,
    NxTableColumn,
} from "@nextbank/ui-components";
import React, {ChangeEvent, ReactElement, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router";
import {useCommand} from "command/CommandService";
import {ColumnConfigurationDetails, UpdateReportConfigurationInput} from "report/ReportType";
import {ReportConfigurationProps} from "report/ReportConfiguationContainer";
import {cloneDeep} from "lodash";

const columns: NxTableColumn<ColumnConfigurationDetails>[] = [
  {
    title: "Column name",
    field: "humanReadableName",
    width: 150,
  }];

const ReportColumnConfigurationView = ({reportCode, configuration}: ReportConfigurationProps): ReactElement => {
  const history = useHistory();
  const execute = useCommand();
  const [queryText, setQueryText] = useState<string>('');
  const [allConfigurations, setAllConfigurations] = useState<ColumnConfigurationDetails[]>(configuration);
  const nextOrderNo = useRef<number>(Math.max(...configuration.map(c => c.orderNo)) + 1)

  const filteredRows = useMemo(() => {
    return allConfigurations.filter(s => s.humanReadableName && s.humanReadableName.toLowerCase().includes(queryText.toLowerCase()));
  }, [queryText, allConfigurations]);

  const changeCheckbox = async (data: ColumnConfigurationDetails, e: ChangeEvent<HTMLInputElement>) : Promise<void> => {
    const changed = cloneDeep(allConfigurations.find(c => c.id === data.id));
    if (!changed) {
      return;
    }
    changed.enabled = e.target.checked;
    if (e.target.checked) {
        changed.orderNo = nextOrderNo.current++;
    }
    execute<UpdateReportConfigurationInput, void>({
      name: 'UpdateReportConfiguration',
      input: {
        reportCode: reportCode,
        configuration: [changed]
      }
    });
    setAllConfigurations(allConfigurations => allConfigurations.map(a => {
      if (a.id !== data.id) {
        return a;
      }
      return {
        ...a,
        enabled: e.target.checked,
        orderNo: changed.orderNo
      };
    }));
  };

  const ConfigureColumns = ({data}: { data: ColumnConfigurationDetails }): ReactElement =>
    <NxCheckbox checked={data.enabled} onChange={(e: ChangeEvent<HTMLInputElement>): Promise<void> => changeCheckbox(data, e)}/>;

  return <NxStack>
    <NxInput
      label="Search"
      value={queryText}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => setQueryText(e.target.value)}
    />
    <NxTable
      columns={columns}
      rowActions={[ConfigureColumns]}
      data={filteredRows}
    />
    <NxRow position={NxRowPosition.END}>
      <NxButton variant={NxButtonVariant.CLOSE}
                onClick={(): void => history.push(`/admin/report-configuration`)}>
        Go back
      </NxButton>
    </NxRow>
  </NxStack>;
};
export default ReportColumnConfigurationView;
