import nxModule from 'nxModule';
import {UserCache, UserDetails} from './user.cache';
import _ from 'lodash';
import Authentication from "shared/utils/authentication";
import RolesCache from "components/service/roles.cache";
import {HttpService} from "shared/utils/httpService";

export class UserService {
  constructor(private http: HttpService,
              private userCache: UserCache,
              private authentication: Authentication,
              private rolesCache: RolesCache) {
  }

  authDetails(userId: number) {
    return this.http.get(`/management/users/${userId}/auth`).toPromise();
  }

  async getUserName(userId: number): Promise<string | null> {
    const users: UserDetails[] = await this.userCache.withParam().toPromise();
    const user: UserDetails | undefined = users.find(user => Number(user.id) === Number(userId));
    return user ? user.effectiveName : null;
  }

  async getBranchPermittedUsers(permissionName: string, branchIds: number[], currentUserId?: number): Promise<UserDetails[]> {
    const [users, roles] = await Promise.all([this.userCache.withParam().toPromise(), this.rolesCache.toPromise()]);
    const rolesWithPermission = roles.filter(role => _.some(role.permissions, {name: permissionName}))
      .map(role => role.id);

    return users.filter(user => (currentUserId && (user.id === currentUserId)) || _.intersection(user.branchIds, branchIds).length > 0)
      .filter(user => {
        const userRoleIds = user.roles.map(role => role.id);
        return _.intersection(rolesWithPermission, userRoleIds).length > 0;
      });
  }

  getLoanOfficersAssignedToCurrentUserBranches(currentOfficerId: number): Promise<UserDetails[]> {
    return this.getBranchPermittedUsers( 'LOAN_OFFICER', this.authentication.context.branchIds, currentOfficerId);
  }

  getCollectorsAssignedToCurrentUserBranches(currentCollectorId: number): Promise<UserDetails[]> {
    return this.getBranchPermittedUsers('COLLECTOR', this.authentication.context.branchIds, currentCollectorId);
  }
}

nxModule.service('userService', UserService);
