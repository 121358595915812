import nxModule from 'nxModule';
import {confirmationMessage} from '../sss/sss.utils';

const templateUrl = require('./sss-online-employer-payment.template.html');
nxModule.component('sssOnlineEmployerPayment', {
  templateUrl,
  bindings: {
    'payorKind': '=',
    'onSaveAction': '<',
    'cancelAction': '<',
  },
  controller: function (http, command, popup, confirmation, $filter, systemDateService) {
    this.$onInit = () => {
      systemDateService.getCurrentUserBranchSystemDate().then(date => this.systemDate = date);

      this.fetchPayment = () => http.get(`/sss/online-payment/payor-kind/EMPLOYER/prn/${this.paymentReferenceNumber}`)
        .success(payment => {
          this.payment = payment;
        })
        .error(err => {
          popup({text: err.errorMessage, header: 'Error'});
        });

      this.save = async () => {
        const message = confirmationMessage({
          amount: this.payment.amount,
          startingPeriod: this.payment.applicableMonth,
          endingPeriod: this.payment.applicableMonth,
          moneyFormatter: $filter('nxCurrency')
        });

        const proceed = await confirmation(message, true);
        if (proceed) {
          command.execute('PayOnlineSSS', {
            "ssNumber": this.payment.ssNumber,
            "paymentReferenceNumber": this.payment.paymentReferenceNumber,
            "amount": this.payment.amount,
            "startingPeriod": this.payment.applicableMonth,
            "endingPeriod": this.payment.applicableMonth,
            "paymentDate": this.systemDate.format('YYYY-MM-DDTHH:mm:ss')
          }).success(() => this.onSaveAction());
        }
      }
    }
  }
});
