import 'rxjs/add/operator/combineLatest';

import nxModule from 'nxModule';
import './security-general.style.less';


import templateUrl from './security-general.template.html';

import systemPropertyService from '../../../../../react/system/systemPropertyService';

class SecurityGeneral {
  constructor(commandAccessChecker, $route, $filter, command, rolesCache) {
    this.command = command;
    this.$route = $route;

    this.properties = [];
    this.changeAllowed = false;

    Promise.resolve()
      .then(async () => {
      const systemProperties = systemPropertyService.getPropertyDescriptors();
      const roles = await rolesCache.toPromise();
      this.properties = systemProperties.map(({code, value,}) => {
        switch (code) {
          case 'USER_IDLE_TIME_MINUTES':
            return {
              code,
              type: 'number',
              min: 0,
              max: 1440,
              unit: 'min',
              value: parseInt(value),
            };
          case 'USER_ALLOWED_FAILED_LOGIN_ATTEMPTS':
            return {
              code,
              type: 'number',
              min: 1,
              value: parseInt(value)
            };
          case 'USER_ALLOWED_FAILED_PIN_ATTEMPTS':
            return {
              code,
              type: 'number',
              min: 1,
              value: parseInt(value)
            };
          case 'REQUIRED_CASH_ROLES':
            return {
              code,
              type: 'multiselect',
              multiValue: value.split(',')
                .filter(val => val) // skip non existent roles
                .map(val => parseInt(val)), // don't pass parseInt directly, as its second argument is radix...
              possibleValues: roles.map(role => ({
                value: role.id,
                label: role.description ? role.description : $filter('prettyEnum')(role.name)
              }))
            };
          case 'PASSWORD_MIN_LENGTH':
            return {
              code,
              type: 'number',
              min: 1,
              value: parseInt(value)
            };
          case 'PASSWORD_COMPLEXITY':
            return {
              code,
              type: 'multiselect',
              multiValue: value.split(',')
                .filter(val => val), // skip non existent values
              possibleValues: ['ONE_NUMBER', 'ONE_LOWERCASE_LETTER', 'ONE_UPPERCASE_LETTER', 'ONE_SPECIAL_CHARACTER', 'NEVER_USED_BEFORE']
                .map(value => ({
                  value,
                  label: $filter('translate')(value, 'PASSWORD_COMPLEXITY')
                }))
            };
          case 'EXPIRE_PASSWORD_AFTER_NR_OF_DAYS':
            return {
              code,
              type: 'number',
              value: parseInt(value),
              tooltip: 'Value of zero disables password expiration',
              min: 0
            };
          case 'ENABLE_CUSTOMER_VIEWING_RESTRICTIONS':
            return {
              code,
              type: 'select',
              value: value,
              possibleValues: [{
                value: 'TRUE',
                label: 'Yes',
              }, {
                value: 'FALSE',
                label: 'No'
              }]
            };
          case 'PASSWORD_EXPIRATION_WARNING_NR_OF_DAYS':
            return {
              code,
              type: 'number',
              value: parseInt(value),
              tooltip: 'Value of zero disables the warning',
              min: 0
            };
          case 'EXPIRE_DEVICE_PIN_AFTER_NR_OF_DAYS':
            return {
              code,
              type: 'number',
              value: parseInt(value),
              tooltip: 'Value of zero disables device pin expiration',
              min: 0
            };
          case 'BLOCK_SIGNATURE_CARD_PREVIEW_WHEN_ACCOUNT_INACTIVE':
            return {
              code,
              type: 'select',
              value: value,
              possibleValues: [{
                value: 'TRUE',
                label: 'Yes',
              }, {
                value: 'FALSE',
                label: 'No'
              }]
            };
        }

        return null;
      }).filter(val => val); // remove nulls
    });

    this.checkerSubscription = commandAccessChecker.canExecuteCommandObservable()
      .subscribe(canExecuteCommand => this.changeAllowed = canExecuteCommand('ChangeSecurityProperties'));
  }

  serializePropertyValue(property) {
    if('value' in property) {
      return property.value;
    }

    if('multiValue' in property) {
      return  property.multiValue.join(",");
    }

    throw Error("Unsupported serializable property: " + property);
  }

  save() {
    const codeToValue = this.properties.reduce((acc, property) => ({
      [property.code]: this.serializePropertyValue(property),
      ...acc
    }), {});

    this.command.execute('ChangeSecurityProperties', {codeToValue,})
      .success(done => {
        window.location.reload();
      });
  }

  $onDestroy() {
    if (this.checkerSubscription) {
      this.checkerSubscription.unsubscribe();
    }
  }
}

nxModule.component('securityGeneral', {
  templateUrl,
  controller: SecurityGeneral
});
