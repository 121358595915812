import nxModule from "nxModule";
import templateUrl from "components/customer/loans/documents/loan-edit-documents.template.html";
import {Loan} from "components/service/loan.types";
import {ILocationService} from "angular";
import {LoanCache} from "components/service/loan.cache";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class LoanEditDocuments {
  loan!: Loan;

  constructor(
    private $location: ILocationService,
    private loanCache: LoanCache,
    private customerCache: CustomerCache,
    private command: CommandService
  ) {
  }

  async update(): Promise<void> {
    await this.command.execute('EditLoanDocuments', {
      productId: this.loan.id,
      loanDocuments: this.loan.loanDocuments
    }).toPromise();

    await Promise.all([
      this.customerCache.loans(this.loan.customerId).refetch(),
      this.loanCache.loan(this.loan.id).refetch()
    ]);
    this.goBack();
  }

  cancel(): void {
    this.goBack();
  }

  goBack(): void {
    this.$location.path(`/customer/${this.loan.customerId}/loans/${this.loan.id}`);
  }
}

nxModule.component('loanEditDocuments', {
  templateUrl,
  controller: LoanEditDocuments,
  bindings: {
    loan: '<'
  }
});
