import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import nxModule from 'nxModule';
import AccessType from 'constants/customerAccessType';


const defaultResponseFilterer = (response, branchId) => response.filter(o => o.branchId === branchId);

class CacheAccessService {
  constructor(customerAccessService, authentication, productDefinitionService) {
    this.customerAccessService = customerAccessService;
    this.authentication = authentication;
    this.productDefinitionService = productDefinitionService;
  }

  /**
   * Restricts access to data from LocalCache based on currently active user.
   * Internal implementation relies heavily on proxies. Once method toObservable is invoked
   * on returned LocalCache, stream of products will be filtered only to contain products, user has access
   * to. Any changes in access will result in new values emitted.
   */
  withRestrictedAccess(cache, customerId, accessType, responseFilter = defaultResponseFilterer) {
    return new Proxy(cache, {
        get: (target, name) => {
          if (name !== 'toObservable' && name !== 'toPromise') {
            return target[name];
          }

          const customerAccessStream = this.customerAccessService
            .ofType(accessType)
            .onAccessChanged()
            // when customer changes, deny access to cache for a previous one
            // this situation may happen when you cache with restricted access (fullAccess=false)
            // without context of a customer (this should never happen)
            .takeWhile(value => String(value.customerId) === String(customerId))
            .map(value => value.access)
            .concat(Observable.of(false));

          return () => {
            const obs = target.toObservable()
              .combineLatest(this.productDefinitionService.toObservable(), (productsOrCreditLines, productDefinitions) => {
                const productDefinitionMap = new Map(productDefinitions.map(i => [i.id, i]));
                if (accessType === AccessType.GROUP) {
                  return productsOrCreditLines;
                }

                return productsOrCreditLines.filter(productsOrCreditLine => {
                  if(productsOrCreditLine.creditLineNumber) {
                    return true;
                  }

                  const product = productsOrCreditLine;
                  const productDefinition = productDefinitionMap.get(product.definitionId);
                  if (productDefinition.productGroup !== 'LOAN' || this.authentication.permissions['CST_EMPLOYEE_LOANS_READ']) {
                    return true;
                  }

                  return !productDefinition.employeeProduct;
                });
              })
              .combineLatest(customerAccessStream, (products, access) => {
                if (access) {
                  return products;
                }

                return responseFilter(products, this.authentication.context.branchId);
              });

            if(name === 'toObservable') {
              return obs;
            }

            return obs
              .first()
              .toPromise();
          }
        }
      }
    )
  }
}

nxModule.service('cacheAccessService', CacheAccessService);