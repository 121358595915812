import nxModule from "nxModule";
import templateUrl from './red-flag-disposition.template.html';
import {HttpService} from "shared/utils/httpService";
import './red-flag-disposition.style.less';
import {NxIFilterService} from "components/technical/angular-filters";
import {ModalApi} from "components/technical/modal/modal.component";
import {CommandService} from "shared/utils/command/command.types";

interface ReportParams {
  branchId: number[];
  date_range: {
    from: string;
    to: string;
  },
  redFlagTag: string[];
}

interface Data {
  branch: string;
  date: string;
  time: string;
  cifNumber: string;
  cifType: string;
  customerName: string;
  accountNumber: string;
  accountName: string;
  operationType: string;
  expectedTransactionCount: number;
  actualTransactionCount: number;
  transactionAmount: number;
  postedBy: string;
  approvedBy: string;
  sourceOfFunds: string;
  sourceOfIncome: string;
  taggingReason: string;
  operationIds: number[];
  redFlagTagType: string;
  disposition: string;
  remarks: string;
}

interface ModalInput {
  disposition?: string;
  remarks?: string
}

class RedFlagDisposition {
  private filterConfig = {
    reportCode: 'RedFlagAlertsMonitoringReport',
    buttons: {
      filter: {
        isVisible: true,
        isDisabled: false,
        text: 'View',
        action: async (): Promise<void> => await this.fetchRedFlag()
      }
    }
  }

  private params: Partial<ReportParams> = {};
  private data: Data[] = [];

  private updateDispositionModal!: ModalApi;
  private modalInput?: ModalInput;

  private readonly dispositionOptions = [
    {
      label: 'Clean',
      value: 'CLEAN'
    },
    {
      label: 'Suspicious',
      value: 'SUSPICIOUS'
    }
  ];

  constructor(
    private http: HttpService,
    private $filter: NxIFilterService,
    private command: CommandService
  ) {
  }

  onModalReady = ({ api }: {api: ModalApi}) : void => {
    this.updateDispositionModal = api;
  };

  async fetchRedFlag(): Promise<void> {
    const url = `/regulatory/red-flag-alerts?branchIds=${this.params.branchId}&dateFrom=${this.params.date_range?.from}&dateTo=${this.params.date_range?.to}&reasonForTagging=${this.params.redFlagTag}`
    this.data = await this.http.get<Data[]>(url).toPromise();
  }

  translateOperation(operationCode: string): string {
    return this.$filter('translate')(operationCode, 'OPERATION_NAME');
  }

  async displayModal(data: Data): Promise<void> {
    this.modalInput = {
      disposition: data.disposition,
      remarks: data.remarks
    };
    const result = await this.updateDispositionModal.show();

    if (result?.accepted) {
      await this.updateRedFlagDisposition(data, this.modalInput);
    }
  }

  async updateRedFlagDisposition(data: Data, input: ModalInput): Promise<void> {
    const response = await this.command.execute('UpdateRedFlagDisposition', {
      operationIds: data.operationIds,
      redFlagTagType: data.redFlagTagType,
      disposition: input.disposition,
      remarks: input.remarks,
      taskDescription: data
    }).toPromise();

    if (!response?.approvalRequired) {
      await this.fetchRedFlag();
    }
  }
}

nxModule.component('redFlagDisposition', {
  templateUrl,
  controller: RedFlagDisposition
});