import nxModule from 'nxModule';
import {productAvailability} from 'constants/productDefinition';
import Authentication from "shared/utils/authentication";
import {ProductDefinition} from "components/service/product.types";
import {CustomerType} from "components/customer/profile/customer-profile.types";

export class ProductService {
  constructor(private authentication: Authentication) {
  }

  availableForSale(product: {productDefinition: ProductDefinition}): boolean {
    const availableForAllBranches =
      product.productDefinition.productAvailability === productAvailability.allBranches.value;

    const availableOnlyForSelectedBranches =
      product.productDefinition.productAvailability === productAvailability.selectedBranches.value;

    const availableForCurrentBranch = !!product.productDefinition.availableInBranchIds
      && product.productDefinition.availableInBranchIds.includes(this.authentication.context.branchId);

    return availableForAllBranches
      || (availableOnlyForSelectedBranches
        && availableForCurrentBranch);
  }

  availableForCustomerType(customerTypeConstraints: ProductDefinition['customerTypeConstraints'], customerType: CustomerType) {
    if (!customerTypeConstraints || customerTypeConstraints.length == 0)
      return true;

    return customerTypeConstraints.includes(customerType);
  }
}

nxModule.service('productService', ProductService);