import nxModule from 'nxModule';

const templateUrl = require('./general-ledger.template.html');
nxModule.component('generalLedger', {
  templateUrl,
  controller: function() {
    this.titles = {
      categories: 'Transaction Categories',
      chartOfAccounts: 'Chart of accounts',
      mapping: 'Transaction Mapping',
      miscMapping: 'Misc transaction Mapping',
      oracleHandoffMapping: 'Oracle handoff'
    };
  }
});
