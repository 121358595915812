import {CustomerCache} from 'components/service/customer.cache.types';
import {AttachedFile} from 'components/service/file.service';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './update-officers.template.html';

class UpdateOfficers {
  private readonly domains: Record<string, string>[] =[
    {
      label: 'Deposit account',
      type: 'ACCOUNT'
    },
    {
      label: 'Loan',
      type: 'LOAN'
    },
    {
      label: 'Group',
      type: 'GROUP'
    },
    {
      label: 'Center',
      type: 'CENTER'
    }
  ];
  private readonly userPermissions: Record<string, string>[] = [
    {
      label: 'Officer',
      permission: 'LOAN_OFFICER'
    },
    {
      label: 'Collector',
      permission: 'COLLECTOR'
    }
  ];
  private domain: string = 'LOAN'
  private selectedUserPermission: string[] = [];
  private contentFile : AttachedFile[] | null = [];

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private $route: NxRouteService,
              private customerCache: CustomerCache) {

  }

  private downloadSampleFile(): void {
    let blobParts: BlobPart[] = [];
    switch (this.domain) {
      case 'LOAN':
      case 'ACCOUNT':
      blobParts = [`product_number,username
CB01-22-230889-1,nx-admin`];
      break;
      case 'GROUP':
      blobParts = [`group_name,username
group1,nx-admin`];
      break;
      case 'CENTER':
    blobParts = [`center_name,username
center1,nx-admin`];
      break;
    }
    const sampleFileUrl = window.URL.createObjectURL(new Blob(blobParts, {type: 'text/csv'}));
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'update-officers.csv';
    a.click();
  }

  private onDomainChange(): void {
    this.contentFile = null;
  }

  private async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Are you sure you want to proceed?',
    });
    if (!confirmed || this.contentFile == null) {
      return;
    }

    await this.command.execute('BatchUpdateOfficers', {
      fileId: this.contentFile[0].id,
      domain: this.domain,
      permissions: this.selectedUserPermission
    }).toPromise();

    if (this.customerCache.loadedCustomerId) {
      await this.customerCache.loans(this.customerCache.loadedCustomerId).refetch();
      await this.customerCache.depositAccounts(this.customerCache.loadedCustomerId).refetch();
      await this.customerCache.termDeposits(this.customerCache.loadedCustomerId).refetch();
    }

    this.$route.reload();
  }
}

nxModule.component("updateOfficers", {
  controller: UpdateOfficers,
  templateUrl
})