import {CustomerProfile, CustomerStatus} from '../../customer/profile/customer-profile.types';
import {RecursivePartial} from 'shared/utils/RecursivePartial';

export enum AgentApplicationStatus {
  APPLICATION_FORM_COMPLETE = 'APPLICATION_FORM_COMPLETE',
  WAITING_FOR_ACCOUNT_ACTIVATION = 'WAITING_FOR_ACCOUNT_ACTIVATION',
  DOCUMENTARY_REQUIREMENTS_SUBMITTED = 'DOCUMENTARY_REQUIREMENTS_SUBMITTED',
  OCULAR_VISIT_CONDUCTED = 'OCULAR_VISIT_CONDUCTED',
  CLIENT_ELIGIBILITY_EVALUATED = 'CLIENT_ELIGIBILITY_EVALUATED',
  APPROVED_PENDING_TRAINING = 'APPROVED_PENDING_TRAINING',
  APPROVED_FOR_DEPLOYMENT = 'APPROVED_FOR_DEPLOYMENT',
  AGENT_CREATED = 'AGENT_CREATED',
  DISAPPROVED = 'DISAPPROVED',
  APPLICANT_BACK_OUT = 'APPLICANT_BACK_OUT'
}

export const newStatuses = Object.freeze([
  AgentApplicationStatus.DOCUMENTARY_REQUIREMENTS_SUBMITTED,
  AgentApplicationStatus.OCULAR_VISIT_CONDUCTED,
  AgentApplicationStatus.CLIENT_ELIGIBILITY_EVALUATED,
  AgentApplicationStatus.DISAPPROVED,
  AgentApplicationStatus.APPLICANT_BACK_OUT]
);

export const accountActivatedStatuses = Object.freeze([
  AgentApplicationStatus.APPROVED_PENDING_TRAINING,
  AgentApplicationStatus.APPROVED_FOR_DEPLOYMENT,
  AgentApplicationStatus.DISAPPROVED,
  AgentApplicationStatus.APPLICANT_BACK_OUT]
);

export enum AgentApplicationSystemStatus {
  NEW = 'NEW',
  SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED',
  CLOSED = 'CLOSED'
}

export type AgentCandidacy = {
  id: number;
  code: string;
  brdReportId: string;
  branchId: number;
  createdOn: Date;
  agentId: number;
  effectiveName: string,
  customerId: number;
  customer: RecursivePartial<CustomerProfile>;
  status: AgentApplicationStatus;
  systemStatus: AgentApplicationSystemStatus;
}

export type AgentCandidacyDetails = {
  branchName: string;
}
