import {NgTableParams} from 'ng-table';
import nxModule from 'nxModule';
import templateUrl from './lookup-center.template.html';
import {CenterSearchCriteria, CenterService} from "../../service/center/center.service";
import {Center} from "../../service/center/center.types";
import {ILocationService} from "angular";
import {UserCache, UserDetails} from "../../service/users/user.cache";
import _ from "lodash";
import {Branch} from "management/BranchTypes";
import {BranchService} from "components/service/branch.service";
import Authentication from "shared/utils/authentication";
import {BranchWorkingDayCache} from 'components/service/working-days-cache';

class LookupCenter {

  private branches: Branch[] = [];
  private branchNames: Record<number, string> = {};
  private params: CenterSearchCriteria = {};
  private searchPerformed = false;
  private centers: Center[] | null = null;
  private tableConfig?: NgTableParams<Center>;
  private isWorkingDay!: boolean;
  private users: Record<number, UserDetails> = {};

  constructor(private readonly $location: ILocationService,
              private readonly authentication: Authentication,
              private readonly branchService: BranchService,
              private readonly branchWorkingDayCache: BranchWorkingDayCache,
              private readonly centerService: CenterService,
              private readonly userCache: UserCache) {
  }

  async $onInit(): Promise<void> {
    const [branchesArr, workingDay, usersArr] = await Promise.all([
      this.branchService.toPromise(),
      this.branchWorkingDayCache.withParam(this.authentication.context.branchId).toPromise(),
      this.userCache.withParam().toPromise()
    ]);

    this.branches = branchesArr.filter(b => this.authentication.context.branchIds.includes(b.id));
    this.branches.forEach(b => this.branchNames[b.id] = b.name);
    this.users = _.keyBy(usersArr, user => user.id);

    this.params.branchIds = this.branches.map(b => b.id); // select all by default

    this.isWorkingDay = workingDay && workingDay.status === 'STARTED';
  }

  /**
   * Center creation is available if:
   *
   * 1. User has appropriate permission
   * 2. Working day is STARTED
   * 3. Center lookup was already performed
   * 4. Search was performed using center name
   *
   * @return true if new center can be created
   */
  enableNewCenterButton(): boolean {
    return this.authentication.permissions['NEW_CUSTOMER_WRITE']
      && this.isWorkingDay
      && this.searchPerformed
      && !!this.params.name;
  }

  enableSearch(): boolean {
    return !!this.params.name
      || !!this.params.referenceNumber
      || !!this.params.loanOfficerName
      || !!this.params.loanCollectorName
      || !!this.params.branchIds?.length;
  }

  emptyResults(): boolean {
    return this.centers !== null && this.centers.length === 0;
  }

  showResults(): boolean {
    return this.searchPerformed && !this.emptyResults();
  }

  async search(): Promise<void> {
    const allBranchesSelected = this.params.branchIds?.length === this.branches.length;
    this.centers = await this.centerService.fetchCenters({
      ...this.params,
      branchIds: allBranchesSelected ? [] : this.params.branchIds
    });
    this.searchPerformed = true;

    if (!this.centers) {
      return;
    }

    if (this.centers.length === 1) {
      const center = this.centers[0];
      this.openCenter(center);
    } else {
      this.tableConfig = new NgTableParams({
        page: 1,
        count: 20,
      }, {
        counts: [],
        paginationMinBlocks: 3,
        paginationMaxBlocks: 8,
        dataset: this.centers
      });
    }
  }

  createCenter(): void {
    this.$location.path('/centers/create');
  }

  openCenter(center: Center): void {
    this.$location.path(`/center/${center.id}/groups`);
  }
}

nxModule.component('lookupCenter', {
  templateUrl,
  controller: LookupCenter
});
