import nxModule from 'nxModule';

const templateUrl = require('./inline-panel.template.html');

nxModule.component('inlinePanel', {
  templateUrl,
  bindings: {
    flexRow: '<',
    onHide: '&'
  },
  transclude: true,
  controller: function($scope,) {
    const self = this;

    self.hideAction = $event => {
      $event.stopPropagation();
      self.onHide();
    };
  }
});