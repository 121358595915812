import nxModule from 'nxModule';


nxModule.factory('lookupHelper', function () {
  let that = this;

  let service = {};

  that.maskChars = null;

  service.removeSpecialCharsFromMask = (productNumber, productMask) => {
    //Initializing set of mask characters
    //ui-mask provide 9, A, * as special characters for describing mask
    if(that.maskChars === null) {
      that.maskChars = new Set();
      let mask = productMask;
      mask = mask.split('9').join('');
      mask = mask.split('A').join('');
      mask = mask.split('*').join('');
      for(let specChar of mask.split('')) {
        that.maskChars.add(specChar);
      }
    }

    //Removing all mask characters from product number
    for(let maskSpecChar of that.maskChars) {
      productNumber = productNumber.split(maskSpecChar).join('');
    }
    return productNumber;

  };


  return service;
});