import nxModule from 'nxModule';

const templateUrl = require('./push-failed-eod.template.html');

nxModule.component('pushFailedEod', {
  templateUrl,
  controller: function (confirmationTemplate, popup, http, $filter, $route, rolesCache, branchService) {
    const that = this;
    that.batchJobTypes = ['BANK_EOD', 'BATCH_EOD_WITH_SOD'];
    that.selectedJobType = undefined;

    branchService.toObservable()
      .first()
      .subscribe(branches => that.branches = branches);

    that.execute = () => {
      confirmationTemplate({
        question: `Do you want to push failed end of day for branch ${this.branch.name}?`,
        yesCallback: () => http.post(`/batch-jobs/push-job/${this.branch.id}/${this.selectedJobType}`).success((ret) => {
          popup({text:`Pushed job ${this.selectedJobType} on branch ${this.branch.name}`, callback: () => $route.reload()});
        })
      })
    };

  }
});
