import nxModule from 'nxModule';
import _ from 'lodash';
import {mergedLedgers} from './gl.utils';

const templateUrl = require('./gl-account-list.template.html');
nxModule.component('glAccountList', {
  templateUrl,
  bindings: {
    detailLink: '@',
  },
  controller: function($scope, $location, $q, authentication, glLedgerService, branchService) {
    const self = this;

    self.branchIds = authentication.context.branchIds;
    self.ledgers = [];

    self.$onInit = async () => {
      const ledgers = await mergedLedgers({glLedgerService, branchService});
      self.ledgers = ledgers.map((ledger, index) => (
        {
          index: index + 1,
          name: ledger.template.name,
          branchId: ledger.branch.id,
          branchName: ledger.branch.name,
          branchCode: ledger.branch.code,
          id: ledger.id,
        }
      ));
    }

    /**
     * Returns list of ledgers filtered with user branch assignments.
     */
    self.getUserLedgers = () => {
      if (!self.branchIds || self.branchIds.length === 0) return [];
      return _.filter(self.ledgers, (ledger) => {
        return _.includes(self.branchIds, ledger.branchId);
      })
    };
  },
});
