import nxModule from 'nxModule';
import * as ng from 'angular';
import templateUrl from './update-loan-transaction-type.template.html';
import {HttpService} from "../../../../shared/utils/httpService";
import {NxRouteService} from "routes/NxRouteService";
import Popup from "shared/common/popup";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

class UpdateLoanTransactionTypeComponent {
  private readonly sampleFile = new Blob([`1234-123-123,AL
2345-123-123,BD`], {type: 'text/csv'});

  fileId: number | null = null;

  constructor(private http: HttpService, private $scope: ng.IScope, private $route: NxRouteService,
              private confirmationTemplate: ConfirmationTemplate, private command: CommandService, private popup: Popup) {
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'loan-transaction-type-example.csv';
    a.click();
  }

  async execute(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Loans will be updated. Do you wish to proceed?`,
    });

    if(confirmed) {
      const response = await this.command.execute('BatchUpdateLoanTransactionType', {
        fileId: this.fileId,
      }).toPromise();

      if (!response.approvalRequired) {
        this.$route.reload();
      }
    }
  }
}

nxModule.component('updateLoanTransactionType', {
  templateUrl,
  controller: UpdateLoanTransactionTypeComponent
});
