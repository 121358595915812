import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';
import {creditingStrategyMapping, maturityStrategyMapping} from 'constants/deposit';

nxModule.factory('termDepositsService', (http, CacheFactory, config) => {
  let cache = new LocalCache({
    provider: () => http.get('/products/deposits/types'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.termDepositTypes,
    cacheName: 'termDepositTypes',
  });


  cache.getMaturityStrategyLabel = (value) => {
    return maturityStrategyMapping[value];
  };

  cache.getCreditingStrategyLabel = (value) => {
    return creditingStrategyMapping[value];
  };

  cache.enrichWithStrategiesDescription = (termDepositProduct) => {
    return Object.assign({}, termDepositProduct,
      {
        maturityStrategies: termDepositProduct.maturityStrategies.map(strategy => ({
          label: maturityStrategyMapping[strategy],
          value: strategy,
        })),
        creditingStrategies: termDepositProduct.creditingStrategies.map(strategy => ({
          label: creditingStrategyMapping[strategy],
          value: strategy,
        })),
      });
  };

  return cache;
});
