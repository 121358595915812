import {ILocationService} from 'angular';
import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';
import {HttpService} from 'shared/utils/httpService';
import {RecursivePartial} from 'shared/utils/RecursivePartial';

import systemPropertyService from '../../../../../react/system/systemPropertyService';
import {CustomerProfile, CustomerSubtype, CustomerType, IncomeSource} from '../customer-profile.types';
import {ProfileUtilityService} from '../profile-utility.service';

import templateUrl from './new-customer-page.template.html';

type CustomerTypeSettings = {type: CustomerType, subtype: CustomerSubtype | null};

class NewCustomerPage {

  protected module!: string;

  private profile: RecursivePartial<CustomerProfile> = {};
  private customerTypes: {settings: CustomerTypeSettings, description: string}[] = [
    {settings: {type: 'INDIVIDUAL', subtype: null}, description: 'Individual'},
    {settings: {type: 'INDIVIDUAL', subtype: 'BDA'}, description: 'Individual - w/ basic deposit account only'},
    {settings: {type: 'INDIVIDUAL', subtype: 'RESTRICTED'}, description: 'Individual - Restricted '},
    {settings: {type: 'CORPORATE', subtype: null}, description: 'Corporate'}
  ];
  private corporateCustomerEnabled: boolean = false;
  private customerTypeSettings!: CustomerTypeSettings | undefined;

  constructor(
    private http: HttpService,
    private $location: ILocationService,
    private profileUtilityService: ProfileUtilityService
  ) {
  }

  async $onInit(): Promise<void> {
    const [, profile] = await Promise.all([
      this.initCorporateCustomerSupport(),
      this.profileUtilityService.initCustomerProfile()
    ]);
    this.profile = profile;
    // Compare subtypes with loose equality since they can be null or undefined
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.customerTypeSettings = this.customerTypes.map(s => s.settings).find(ct => ct.type === this.profile.customerType && ct.subtype == this.profile.customerSubtype);
  }

  async initCorporateCustomerSupport(): Promise<void> {
    this.corporateCustomerEnabled = systemPropertyService.getProperty('CIF_CORPORATE_SUPPORT') === 'TRUE';
  }

  onCustomerTypeSettingsChange(): void {
    this.profile.customerType = this.customerTypeSettings?.type;
    this.profile.customerSubtype = this.customerTypeSettings?.subtype ?? undefined;
    this.updateTotalIncome();
  }

  updateTotalIncome(): void {
    if (!this.profile.incomeSources) {
      return;
    }

    const incomeSources: IncomeSource[] = this.profile.incomeSources as IncomeSource[];
    if (this.profile.customerType === 'INDIVIDUAL' && this.profile.individualData) {
      this.profile.individualData.totalAnnualIncome = sum(incomeSources.map(income => income.annualIncome));

    }
    if (this.profile.customerType === 'CORPORATE' && this.profile.corporateData) {
      this.profile.corporateData.totalAnnualIncome = sum(incomeSources.map(income => income.annualIncome));
    }
  }
}

nxModule.component('newCustomerPage', {
  templateUrl,
  controller: NewCustomerPage,
  bindings: {
    module: '<'
  }
});
