import React, {ReactElement} from "react";
import {AxiosError} from "axios";
import {isHttpError} from "tools/HttpTypes";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";

interface Props {
  error: AxiosError;
  pageHeader: string;
}

const HttpErrorHandler = (props: Props): ReactElement => {
  const axiosError: unknown = props.error;
  if (isHttpError(axiosError) && axiosError.backendError) {
    return <NxPage><NxHeader>{props.pageHeader}</NxHeader>
    <span>Cannot proceed. {axiosError.backendError.errorMessage}</span></NxPage>;
  }
  return <NxPage><NxHeader>{props.pageHeader}</NxHeader> <span>Cannot proceed. {props.error.message}</span></NxPage>;
}

export default HttpErrorHandler;