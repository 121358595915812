import {ILocationService, ITimeoutService} from 'angular';
import {CustomerCache} from 'components/service/customer.cache.types';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {CustomerProfile} from '../customer-profile.types';
import './customer-profile-page.print.less';
import templateUrl from './customer-profile-page.template.html';

class ProfilePage {
  // route param injected
  private customerId!: number;

  private profile!: CustomerProfile;
  private singlePage: boolean = false;

  constructor(private $location: ILocationService,
              private $timeout: ITimeoutService,
              private customerCache: CustomerCache,
              private command: CommandService,
              private confirmation: Confirmation,
              private $route: NxRouteService) {
  }

  async $onInit(): Promise<void> {
    this.profile = await this.customerCache.profile(this.customerId, true, true).toPromise();
  }

  goToMergeWith(): void {
    this.$location.path(`/customer/${this.customerId}/profile/merge`);
  }

  printCustomerProfile(): void {
    const singlePage = this.singlePage;
    this.singlePage = true;
    this.$timeout(() => {
      window.print();
      this.singlePage = singlePage;
    });
  }

  async reactivate(): Promise<void> {
    const confirmed = await this.confirmation('Are you sure you want to reactivate this customer?');
    if (!confirmed) {
      return;
    }

    const response = await this.command.execute('ReactivateCustomer',
      {customerId: this.customerId},
      {nxLoaderText: 'Reactivating customer'}).toPromise();
    if (response) {
      this.customerCache.refetch();
      this.$route.reload();
    }
  }
}

nxModule.component('customerProfilePage', {
  templateUrl,
  controller: ProfilePage,
  bindings: {
    customerId: '<'
  }
});
