import {
  AutomaticDebitAgreementConfigMode,
  AutomaticDebitAgreementPreview
} from 'account/ada/AutomaticDebitAgreementTypes';
import {IScope} from 'angular';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import templateUrl from './ada-auto-configuration-preview.template.html';

class AdaAutoConfigurationPreview {
  private view!: AutomaticDebitAgreementConfigMode;
  private customerId!: number;
  private definitionId!: number;
  private conditionText!: string;

  private preview?: AutomaticDebitAgreementPreview;

  constructor(
    private http: HttpService,
    private $scope: IScope
  ) {
  }

  async $onInit(): Promise<void> {
    this.$scope.$watchCollection('[$ctrl.customerId, $ctrl.definitionId]', async() => {
      this.preview = await this.fetchAdaAutoConfigurationPreview();
    })
  }

  private fetchAdaAutoConfigurationPreview = async (): Promise<AutomaticDebitAgreementPreview | undefined> => {
    if (!this.customerId || !this.definitionId) {
      return;
    }

    const url = `/products/ada/simulate?mode=${this.view}&customerId=${this.customerId}&definitionId=${this.definitionId}`;
    return await this.http.get<AutomaticDebitAgreementPreview>(url).toPromise();
  }
}

nxModule.component('adaAutoConfigurationPreview', {
  templateUrl,
  controller: AdaAutoConfigurationPreview,
  bindings: {
    view: '<',
    customerId: '<',
    definitionId: '<',
    conditionText: '<'
  }
});
