export const loanApplyOnProductHooks = Object.freeze([
  'LOAN_RELEASE',
  'LOAN_PAYMENT',
  'LOAN_AMORTIZATION',
  'LOAN_PRETERMINATION',
  'FORMULA'
]);

export const loanArbitraryFeesApplyOnHooks = Object.freeze([
  'LOAN_RELEASE',
  'LOAN_AMORTIZATION',
]);

export const loanApplyOnProductHooksPretermination = Object.freeze([
  'LOAN_PRETERMINATION',
  'FORMULA'
]);

export const loanApplyOnProductHooksPastDue = Object.freeze([
  'LOAN_PAYMENT',
]);

export const loanApplyOnProductHooksMaturity = Object.freeze([
  'LOAN_PAYMENT',
]);
