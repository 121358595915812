import nxModule from 'nxModule';

class SSSPensionService {
  constructor(http) {
    this.http = http;
  }

  getSummary() {
    return this.http.get('/sss/pensions/summary').toPromise();
  }

  getPensionsByFileId(fileId) {
    if (!fileId || fileId < 1) {
      return new Promise((resolve) => resolve([]));
    }

    return this.http.get(`/sss/pensions?fileId=${fileId}`).toPromise();
  }
}

nxModule.service('sssPensionService', SSSPensionService);
