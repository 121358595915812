import nxModule from 'nxModule';
import {HttpService, HttpServiceResponse, NxIRequestShortcutConfig} from "shared/utils/httpService";
import {Operation} from "components/service/operation.types";
import {PageResult} from "tools/HttpTypes";

export default class OperationService {
  constructor(private http: HttpService) {
  }

  fetchOperationDetails(operationId: number, options?: NxIRequestShortcutConfig): HttpServiceResponse<Operation> {
    return this.http.get(`/products/operations/${operationId}`, options);
  }

  fetchOperationsByCriteria(params?: NxIRequestShortcutConfig): Promise<PageResult<Operation>>  {
    return this.http.get<PageResult<Operation>>('/products/operations', params).toPromise();
  }
}

nxModule.service('operationService', OperationService);
