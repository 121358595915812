import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './official-receipt-booklet-create.template.html';
import {IFormController, ILocationService} from 'angular';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";

export interface Input {
  branchId: number;
  startNumber: number;
  bookletsCount: number;
}

class OfficialReceiptBookletsCreate {

  protected form!: IFormController;
  private branchId!: number;
  private input!: Input;

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService) {
  }

  async $onInit(): Promise<void> {
    this.branchId = Number(this.$route.current.params['branchId']);
  }

  async create(): Promise<void> {
    const request = _.cloneDeep(this.input);
    request.branchId = this.branchId;
    const confirmed = await this.confirmationTemplate({
      question: 'Create Official Receipt Booklets?',
      details: [
        {label: 'Start number', description: this.input.startNumber.toString()},
        {label: 'Booklets count', description: this.input.bookletsCount.toString()}
      ]
    });

    if (confirmed) {
      await this.command.execute('CreateOfficialReceiptBooklets', request).toPromise();
      this.redirectBack();
    }
  }

  redirectBack(): void {
    this.$location.path(`/admin/official-receipt-branches/${this.branchId}`);
  }
}

nxModule.component('officialReceiptBookletsCreate', {
  templateUrl: templateUrl,
  controller: OfficialReceiptBookletsCreate
});
