import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./check-deposit.template.html');
nxModule.component('customerAccountsCheckDeposit', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, dict, customerCache, productDefinitionService, confirmation, command) {
    const that = this;

    that.checkModel = {};

    const customerId = $route.current.params['customerId'];
    that.productId = $route.current.params['accountId'];

    customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
          depositAccounts.map(da => {
            const p = _.find(products, {id: da.definitionId});
            return Object.assign(da, {
              productName: p ? p.productName : '<Unknown product>'
            });
          })
        // that.productId is a string
      ).subscribe(accounts => that.depositAccount = _.find(accounts, (a) => String(a.id) === that.productId));

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${that.productId}`);

    that.deposit = async () => {
      const proceed = await confirmation(`Do you want to deposit ${$filter('nxCurrency')(that.checkModel.amount)}?`);

      if (proceed) {
        const response = await command.execute('DepositCheckToAccount', that.checkModel, {nxLoaderText: 'Depositing funds...'}).toPromise();

        if (response && !response.approvalRequired) {
          customerCache.depositAccounts(customerId).refetch();
          customerCache.depositAccountGroups(customerId).refetch();
          that.redirectBack();
        }
      }
    };
  }
});
