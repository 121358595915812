import nxModule from 'nxModule';
import './general-health-check.component.less';
import templateUrl from './general-health-check.template.html';

class GeneralHealthCheck {}

nxModule.component('generalHealthCheck', {
  templateUrl,
  bindings: {
    checks: '<',
    hideOk: '<'
  },
  controller: GeneralHealthCheck
});