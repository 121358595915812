import React, {ReactNode} from 'react';
import {Snackbar} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type NotificationType = 'error';

type Message = {
  text: string;
  type?: NotificationType;
  autoHideDuration?: number;
}

interface State {
  open: boolean;
  message: Message | null;
}

let ref: Notification | null = null;

export default (msg: Message): void => {
  if(!ref) {
    console.warn('Notification not rendered. Discarding message');
    return;
  }

  ref.addMessage(msg);
};

export class Notification extends React.Component<Record<string, unknown>, State> {
  private queue: Message[] = [];
  state: State = {
    open: false,
    message: null
  };

  constructor(props: Record<string, unknown>) {
    super(props);

    ref = this;
  }

  addMessage(message: Message): void {
    this.queue.push(message);

    if(!this.state.open) {
      this.processQueue();
    }
  }

  processQueue(): void {
    if (this.queue.length > 0) {
      this.setState({
        open: true,
        message: this.queue.shift() ?? null
      });
    }
  }

  handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      open: false
    });
  };

  handleExited = (): void => {
    this.processQueue();
  };

  render(): ReactNode {
    return <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={this.state.open}
      autoHideDuration={this.state?.message?.autoHideDuration ?? 3000}
      onClose={this.handleClose}
      onExited={this.handleExited}
      message={this.state.message ? this.state.message.text : undefined}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={this.handleClose}
        >
          <CloseIcon/>
        </IconButton>
      }
    />;
  }
}