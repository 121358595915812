import nxModule from 'nxModule';

import templateUrl from './customer-page.template.html';

nxModule.component('customerPage', {
  templateUrl,
  transclude: true,
  bindings: {
    mode: '<'
  }
});