import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./cash-deposit.template.html');
nxModule.component('customerAccountsCashDeposit', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, customerCache, productDefinitionService, userCounterService, confirmation,
                        depositAccountTypeService, command) {
    let that = this;
    that.depositAccount = {};

    let customerId = $route.current.params['customerId'];
    let productId = $route.current.params['accountId'];

    const acctSub = customerCache.depositAccounts(customerId).toObservable()
      .combineLatest(productDefinitionService.toObservable(), (depositAccounts, products) =>
        depositAccounts.map(da => {
          const p = _.find(products, {id: da.definitionId});
          return Object.assign(da, {
            productName: p ? p.productName : '<Unknown product>'
          });
        })
      ).first()
      // productId is a string
      .subscribe(accounts => that.depositAccount = _.find(accounts, (a) => String(a.id) === productId));


    that.amountSelected = (amount) => {
      that.selectedAmount = amount;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/accounts/${productId}`);

    that.deposit = () => {
      confirmation(`Do you want to deposit ${$filter('nxCurrency')(that.selectedAmount)}?`, () => {
        command.execute('DepositCashToAccount', {
          "productId": productId,
          "amount": that.selectedAmount,
          "entryType": "CREDIT",
          "remarks": this.remarks || null
        }, {nxLoaderText: 'Depositing funds...'}).success((res) => {
          customerCache.depositAccounts(customerId).refetch();
          customerCache.depositAccountGroups(customerId).refetch();
          userCounterService.refresh();
          that.redirectBack();
        });
      });
    };

    that.$onDestroy = () => {
      acctSub.unsubscribe();
    }
  }
});
