import nxModule from 'nxModule';
import _ from 'lodash';
import {statusMappings} from '../../../../react/management/WorkingDayType';

const templateUrl = require('./administration-branches.template.html');

nxModule.component('administrationBranches', {
  templateUrl,
  bindings: {
    detailLink: '@'
  },
  controller: function ($location, branchService, organizationCache, workingDaysCache, confirmation, command, $route) {

    this.statusMappings = statusMappings;

    this.rootOrganization = null;

    this.addNew = () => {
      $location.path('/admin/organization/branches/create');
    };

    this.edit = (id) => {
      $location.path('/admin/organization/branches/' + id);
    };

    this.delete = async (id) => {
      const proceed = await confirmation(`Do you want to delete the selected branch?`);
      if (proceed) {
        const response = await command.execute('DeleteBranch', { id }).toPromise();
        branchService.refetch();
        $route.reload();
      }
    };

    this.subscription = branchService.toObservable()
      .combineLatest(organizationCache.toObservable(), (branches, organizations) => {
          this.rootOrganization = _.find(organizations, {'root': true});
          return branches.map(branch => {
            return Object.assign(branch, {organization: _.find(organizations, {id: branch.organizationId})});
          });
        }
      )
      .combineLatest(workingDaysCache.toObservable(), (branches, workingDays) => {
        for (let i = 0; i < branches.length; i++) {
          branches[i].status = {};
          for (let y = 0; y < workingDays.length; y++) {
            if (branches[i].id === workingDays[y].branchId) {
              branches[i].status.code = workingDays[y].status;
              branches[i].systemDate = workingDays[y].systemDate;
              branches[i].clearingDay = workingDays[y].clearingDay ? 'Yes' : 'No';
            }
          }
          for (let z = 0; z < this.statusMappings.length; z++) {
            if (this.statusMappings[z].code === branches[i].status.code) {
              branches[i].status.label = this.statusMappings[z].label;
            }
          }
        }
        this.branches = branches.sort((a, b) => a.name.localeCompare(b.name));
      })
      .subscribe();

    this.$onDestroy = () => {
      this.subscription.unsubscribe();
    }
  }
});
