import nxModule from 'nxModule';
import _ from 'lodash';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import 'rxjs/add/operator/combineLatest';

const templateUrl = require('./check-termination.template.html');
nxModule.component('customerTermDepositCheckTermination', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $filter, command, http, customerCache, productDefinitionService, confirmation,
                        notification, depositoryAccountCache, branchService, authentication,
                        feeDefinitionsCache, termDepositsService, termDepositCalculator, termDepositOverrideService, checkMicrService) {
    const that = this;
    that.deposit = {};
    that.banks = {};
    that.depositType = null;

    // Checking account config
    that.selectConfig = {
      placeholder: 'Select account',
      searchField: ['accountName'],
      valueField: 'id',
      labelField: 'accountName',
      maxItems: 1
    };

    const customerId = $route.current.params['customerId'];
    const depositId = ~~$route.current.params['depositId'];

    // Interest forecast
    that.interestForecast = {};
    that.terminationAmount = null;

    that.override = termDepositOverrideService.Override = {};
    that.request = termDepositOverrideService.CheckTerminateDepositRequest = {};

    const branchSub = branchService.toObservable().subscribe(branches => {
      const branchId = authentication.context.branchId;
      const authBranch = _.find(branches, {id: branchId});
      if (authBranch) {
        const systemDate = moment(authBranch.systemDate).toDate();
        that.systemDate = systemDate;
        that.request.validFrom = systemDate;
      }
    });

    const depositoryAccountSub = depositoryAccountCache.toObservable().subscribe(depositoryAccounts => {
      that.checkingAccounts = depositoryAccounts.filter(a => a.accountType === 'CHECKING' && a.brstn && a.branchId === authentication.context.branchId);
    });

    // Fetch term deposit interest forecast
    that.readInterestForecast = (depositId) => {
      Promise.all([http.post(`/products/deposits/${depositId}/interest`, {mode: 'TERMINATION'}).toPromise(),
        feeDefinitionsCache.toPromise()])
        .then(([forecast, feesDef]) => {
          //decorate fee with name
          forecast.terminationFees.forEach(f => {
            const fd = feesDef.find(fd => fd.id === f.feeDefinitionId);
            if (fd) {
              f.name = fd.feeName;
              f.bankExpense = fd.bankExpense;
              f.newAmount = f.amount;
            }
          });

          that.interestForecast = forecast;

          that.override.grossInterest = that.interestForecast.interest;
          that.override.withholdingTax = that.interestForecast.withholdingTax;
          that.override.netInterest = that.interestForecast.netInterest;
          that.interestForecast.productId = depositId;

          that.terminationAmount = that.calculateTerminationAmount();
          that.override.amount = that.terminationAmount;
        })
        .catch(() => {
          notification.show("Error", "Failed to load interest forecast.")
        });
    };

    that.calculateTerminationAmount = () => {
      let terminationAmount = new BigNumber(0)
        .plus(that.termDeposit.balance)
        .plus(that.override.netInterest);
      //subtract termination fee
      that.interestForecast.terminationFees
        .filter(f => !f.bankExpense)
        .forEach(f => {
          const amount = f.newAmount;
          terminationAmount = terminationAmount.minus(amount);
        });

      if(that.depositType.deductCreditedInterestOnPretermination) {
        terminationAmount = terminationAmount
          .minus(that.interestForecast.interestCredited)
          .plus(that.interestForecast.appliedWithholdingTax);
      }
      return terminationAmount.dp(2).toNumber();
    };

    customerCache.termDeposits(customerId).toObservable()
      .map(deposits => _.find(deposits, {id: depositId}))
      .combineLatest(productDefinitionService.toObservable(), (deposit, products) => {
        return Object.assign(deposit, {
          "productDefinition": _.find(products, {id: deposit.definitionId})
        });
      })
      .combineLatest(customerCache.profile(customerId).toObservable(), (deposit, customer) => {
        that.request.payee = customer.effectiveName;
        return deposit;
      })
      .combineLatest(termDepositsService.toObservable(), (deposit, depositTypes) => {
        that.depositType = _.find(depositTypes, {id: deposit.typeId});
        return deposit;
      })
      .subscribe(deposit => {
        that.termDeposit = deposit;
        that.interestForecast = that.readInterestForecast(deposit.id);
        that.request.remarks = 'Termination of deposit no. ' + that.termDeposit.productNumber;
      });

    that.redirectBack = () => $location.path(`/customer/${customerId}/term-deposits/${depositId}`);

    that.recalculate = () => {
      that.override.grossInterest = termDepositCalculator.recalculateGrossInterest(that.override.netInterest, that.override.withholdingTax);
      that.override.amount = that.calculateTerminationAmount();
    };

    that.terminationFeeChanged = () => {
      that.override.amount = that.calculateTerminationAmount();
    };

    that.updateMicr = () => {
      that.request.micrNumber = checkMicrService.createMicr(
        that.request.checkNumber,
        Number(that.request.depositoryAccountId),
        that.checkingAccounts
      );
    }

    that.terminate = () => {
      confirmation(`Do you want to withdraw ${$filter('nxCurrency')(that.override.amount)} and terminate deposit?`, () => {
        that.request = termDepositOverrideService.prepareRequest({
          forecast: that.interestForecast,
          override: that.override,
          terminationAmount: that.terminationAmount,
          isOverridden: that.overrideCheckbox,
          request: that.request
        });
        that.request.bankId = _.find(that.checkingAccounts, {id: Number(that.request.depositoryAccountId)}).bankId;
        command.execute('CheckDepositPretermination', that.request, {nxLoaderText: 'Withdrawing funds...'}).success(() => {
          customerCache.termDeposits(customerId).refetch();
          that.redirectBack();
        });
      });
    };

    that.$onDestroy = () => {
      depositoryAccountSub.unsubscribe();
      branchSub.unsubscribe();
    };
  }
});
