import {NxButtonLink, NxButtonVariant, NxTable, NxTableColumn} from '@nextbank/ui-components';
import commandAccessService from 'command/commandAccessService';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import React, {ReactElement} from 'react';
import RouteDefinition from 'routes/RouteDefinition';

export type LinkListRoute = Pick<RouteDefinition, 'name' | 'path' | 'commandAccess'>;

const columns: NxTableColumn<LinkListRoute>[] = [
  {
    title: "Page",
    field: "name",
    width: 150,
  }];

const LinkList = (props: {links: LinkListRoute[], header: string}) : ReactElement => {
  const Open = ({data}: {data: LinkListRoute}): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={data.path}>Open</NxButtonLink>;

  return <NxPage>
    <NxHeader>{props.header}</NxHeader>
    <NxTable
      columns={columns}
      sortable={false}
      rowActions={[Open]}
      data={props.links.filter(link => {
        if(!link.commandAccess) {
          return true;
        }

        return commandAccessService.canExecute(link.commandAccess);
      })}
      isLoading={false}
    />
  </NxPage>;
};

export default LinkList;