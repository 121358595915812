import {NxLoader} from '@nextbank/ui-components';
import useAxios from 'axios-hooks';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import {IdResponse} from 'components/service/product.types';
import CollateralForm, {Collateral, CollateralInput} from 'customer/collateral/Collateral';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import React, {ReactElement} from 'react';
import {useHistory, useParams} from 'react-router';

const UpdateCollateral = (): ReactElement => {
  const {customerId, collateralId} = useParams<{customerId: string, collateralId: string}>();
  const history = useHistory();
  const execute = useCommand();

  const [{data: collateral}] = useAxios<Collateral>(`/customers/collaterals/${collateralId}`);

  if(!collateral) {
    return <NxPage><NxHeader>Update collateral</NxHeader><NxLoader/></NxPage>;
  }

  return <NxPage>
    <NxHeader>Update collateral</NxHeader>
    <CollateralForm
      collateral={{
        id: collateral.id,
        customerId: Number(customerId),
        appraisalDate: collateral.appraisalDate,
        appraisalValue: collateral.appraisalValue,
        fileIds: collateral.fileIds,
        name: collateral.name,
        description: collateral.description,
        remarks: collateral.remarks,
        securityId: collateral.securityId
      }}
      submitAction={async (collateral: CollateralInput) : Promise<void> => {
        const response: CommandOutputWrapper<IdResponse> = await execute<CollateralInput, IdResponse>({
          name: 'UpdateCollateral',
          input: {
            id: collateral.id,
            customerId: collateral.customerId,
            securityId: collateral.securityId,
            name: collateral.name,
            description: collateral.description,
            remarks: collateral.remarks,
            appraisalDate: collateral.appraisalDate,
            appraisalValue: collateral.appraisalValue,
            fileIds: collateral.fileIds
          }
        });

        if (!response.approvalRequired) {
          history.push(`/customer/${customerId}/collaterals`);
        }
      }}
      confirmationMessage='Are you sure that you want to update this collateral?'
      actionName='Update'
      commandAccessName='UpdateCollateral'/>
  </NxPage>;
}

export default UpdateCollateral;