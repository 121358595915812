import axios from 'axios';
import config from '../config';
import fingerprintService from '../system/fingerprintService';
import {StatusDetails} from './status/StatusType';

type StatusSubscriber = (status: StatusDetails) => unknown;

class StatusService {
  private lastStatus!: Promise<StatusDetails>;
  private subscribers: StatusSubscriber[] = [];
  private timeout: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    this.refresh();
  }

  /**
   Status is refreshed periodically based on 'statusRefreshInterval' config property.
   This method is also exposed so it can be refreshed manually (i.e. when userCounter is changed)
   */
  async refresh(): Promise<void> {

    try {
      if(this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }

      this.lastStatus = this.fetchStatus();
      const status = await this.lastStatus;

      for (const subscriber of this.subscribers) {
        try {
          subscriber(status);
        } catch (e) {
          console.error('Error calling status subscriber', e);
        }
      }
    } finally {
      this.timeout = setTimeout(() => this.refresh(), config.statusRefreshInterval);
    }
  }

  private async fetchStatus(): Promise<StatusDetails> {
    const fingerprint = await fingerprintService.getFingerprint();
    const response = await axios.post<StatusDetails>('/management/status', {
        fingerprint,
      }
    );

    return response.data;
  }

  registerForStatusUpdate(callback: StatusSubscriber): void  {
    this.subscribers.push(callback);

    this.lastStatus.then(callback);
  }

  getStatus(): Promise<StatusDetails> {
    return this.lastStatus;
  }
}

const statusService = new StatusService();

export default statusService;
export const serviceType = StatusService;