import {SelectConfig} from 'components/common/types/selectize.types';
import nxModule from 'nxModule';

import templateUrl from './group-data.template.html';
import {Center, CenterLeader} from "../../../../service/center/center.types";
import {CenterService, TransferCustomerGroupToAnotherCenterInput} from "../../../../service/center/center.service";
import _ from "lodash";
import {Branch} from "management/BranchTypes";
import systemPropertyService from "system/systemPropertyService";
import {ILocationService, IScope} from "angular";
import {BranchService} from "components/service/branch.service";
import {AccountType} from "components/service/account.types";
import {Account} from "components/service/product.types";
import {Dict} from "shared/common/dict.types";
import {CustomerDepositService} from "components/service/customer-deposit.service.types";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from 'shared/utils/command/command.types';
import {UserCache} from "components/service/users/user.cache";
import RolesCache from "components/service/roles.cache";
import {User} from "user/UserTypes";
import Authentication from 'shared/utils/authentication';
import {PropertyConfigService} from "components/service/property-config.service";
import {CustomerGroup} from "customer/CustomerTypes";

interface EnrichedCustomerGroup extends CustomerGroup {
  branchId?: number;
  center?: Center;
}

class GroupDataController {
  profile! : EnrichedCustomerGroup;
  officers! : any[];
  cbuAccounts : Account[] = [];
  autogeneratedGroupName!: boolean;
  editMode!: boolean;
  loanOfficersRequired!: boolean;
  loanOfficersVisible!: boolean;
  protected allLoanCollectors!: User[];
  protected allUsers!: User[];
  protected centerLoanOfficerName?: string;
  protected centerLoanCollectorName?: string;
  private cbuAccountTypeIds!: number[];
  private selectAccountConfig!: SelectConfig;
  private branches: Record<number, Branch> = {};

  constructor(
    private readonly dict: Dict,
    private readonly $scope: IScope,
    private readonly customerDepositService: CustomerDepositService,
    private readonly depositAccountTypeService: DepositAccountTypeService,
    private readonly $location: ILocationService,
    private readonly confirmation: Confirmation,
    private readonly centerService: CenterService,
    private readonly branchService: BranchService,
    private readonly userCache: UserCache,
    private readonly rolesCache: RolesCache,
    private readonly command: CommandService,
    private readonly authentication: Authentication,
    private readonly propertyConfigService: PropertyConfigService
  )  {

    this.selectAccountConfig = {
      placeholder: 'Select account',
      searchField: ['productNumber'],
      valueField: 'id',
      labelField: 'label',
      maxItems: 1,
      allowEmptyOption: true
    };

  }

  async $onInit() : Promise<void> {
    const [accountTypes, users, roles] = await Promise.all([
      this.depositAccountTypeService.toPromise(),
      this.userCache.withParam().toPromise(),
      this.rolesCache.toPromise()
    ]);
    this.loanOfficersRequired = this.propertyConfigService.required('customer', 'group', 'loan_officers');
    this.loanOfficersVisible = this.propertyConfigService.visible('customer', 'group', 'loan_officers');
    const that = this;

    this.cbuAccountTypeIds = accountTypes.filter((accountType: AccountType) => accountType.purpose === 'CONTRACTUAL_SAVINGS_CBU')
      .map((accountType: AccountType) => accountType.id!);

    const collectorRoleIds = roles.filter(r => r.permissions?.some(p => p.name === 'COLLECTOR'))
      .map(r => r.id);

    const profileBranch = this.editMode ? this.profile.branchId : this.authentication.context.branchId;
    this.allUsers = users.filter(u => u.branchId === profileBranch && !u.technical);
    this.allLoanCollectors = users.filter(u => u.branchId === profileBranch)
      .filter(u => u.roles.some(r => collectorRoleIds.includes(r.id)));

    this.$scope.$watchCollection('$ctrl.profile.chiefBorrowerId', async () => {
      if(that.profile?.chiefBorrowerId) {
        that.customerDepositService.searchDeposits(
          [that.profile.chiefBorrowerId],
          ['ACTIVE', 'INACTIVE'],
          false,
          (accounts: Account[]) =>  {
            that.cbuAccounts = accounts.filter(a => that.cbuAccountTypeIds.includes(a.typeId));
          }
        );
      } else {
        this.cbuAccounts = [];
        that.profile.groupFundAccountId = undefined;
      }
    });

    if (this.profile.center) {
      const centerLoanOfficer = users.find(u => u.id === this.profile.center?.loanOfficerId);
      this.centerLoanOfficerName = centerLoanOfficer?.effectiveName;

      const centerCollector = users.find(u => u.id === this.profile.center?.loanCollectorId);
      this.centerLoanCollectorName = centerCollector?.effectiveName;
      this.branches = _.keyBy(await this.branchService.toPromise(), branch => branch.id);
    }

    this.autogeneratedGroupName = systemPropertyService.getProperty('AUTOMATED_GROUP_NAME_GENERATION') === 'TRUE';
  }

  getBranchName(branchId: number): string {
    return this.branches[branchId]?.name;
  }

  hasValidLoanOfficerConfiguration(): boolean {
    if(!this.loanOfficersRequired) {
      return true;
    }
    return !!this.profile?.loanCollectorId
      || !!this.profile?.accountDeveloperId
      || !!this.profile?.areaManagerId;
  }

  filterCenterPickerResult = (center: Center): boolean => center.id !== this.profile?.center?.id;

  transferCustomerGroupToAnotherCenter = async (newCenter: Center): Promise<void> => {
    const confirmed = await this.confirmation(`You are about to transfer this customer group to another center [name: ${newCenter.name}, reference no.:  ${newCenter.id}]. Do you want to proceed?`);
    if (!confirmed) {
      return;
    }

    const input: TransferCustomerGroupToAnotherCenterInput = {
      customerGroupId: this.profile.id,
      newCenterId: newCenter.id
    }
    const response = await this.command.execute('TransferCustomerGroupToAnotherCenter', input,
      {nxLoaderText: 'Transferring customer group to another center...'}).toPromise();
    if (!response.approvalRequired) {
      this.profile.center = newCenter;
    }
  };
}

nxModule.component('groupData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'editMode': '=',
    'form': '='
  },
  controller: GroupDataController
});
