import nxModule from 'nxModule';
import _ from 'lodash';
import {Subscription} from "rxjs/Subscription";

const templateUrl = require('./print-header.template.html');
nxModule.component('printHeader', {
  templateUrl: templateUrl,
  bindings: {
    'branchId': '<',
    'printName': '<'
  },

  controller: function ($scope, authentication, branchService, organizationCache, singleUserCache) {

    const that = this;
    that.userId = authentication.context.userId;
    that.time = new Date();

    const subscription = new Subscription();

    $scope.$watch("$ctrl.branchId", () => {
      if(!that.branchId) {
        return;
      }

      subscription.add(branchService.toObservable()
        .combineLatest(organizationCache.toObservable(), (branches, organizations) =>
          branches.map(
            branch => Object.assign(branch, {organization: _.find(organizations, {id: branch.organizationId})}))
        ).subscribe(branches => that.branch = _.find(branches, {id: that.branchId})));
    });

    let userSubscription = singleUserCache.user(authentication.context.id).toObservable().subscribe(user => {
      that.user = user;
      that.user.fullName = `${user.firstName} ${user.middleName || ''} ${user.lastName}`;
    });
    subscription.add(userSubscription);

    that.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

});
