import nxModule from 'nxModule';

import templateUrl from './process-start-of-day.template.html';

class StartOfDayProcessor {

  constructor(confirmationTemplate, $route, branchService, command, popup) {

    this.confirmationTemplate = confirmationTemplate;
    this.$route = $route;
    this.branchService = branchService;
    this.command = command;
    this.popup = popup;

    this.branchIds = {};
    this.branches = [];
  }

  async execute() {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to process start of day batch steps for selected branches?`
    });

    if (confirmed) {
      const response = await this.command.execute('ProcessStartDayStepsBatch', {branchIds: this.branchIds}).toPromise();
      if (response) {
        const errors = response.output.filter(o => o.errorMessage);
        if (errors.length > 0) {
          const message = errors.map(e => `Branch ID: ${e.branchId}, Error message: <em>${e.errorMessage}</em>`).join('<br/>');
          this.popup({text: message, renderHtml: true});
        } else {
          this.popup({text: 'Process start days steps scheduled for selected branches successfully.'});
        }
      }
    }
  }

  async $onInit() {
    this.branches = await this.branchService.toPromise();
  }
}

nxModule.component('processStartOfDay', {
  templateUrl,
  controller: StartOfDayProcessor
});

