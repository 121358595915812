import AccountsConfiguration from 'account/AccountsConfiguration';
import BatchCreditOnUsChecks from 'account/BatchCreditOnUsChecks';
import ChangeAccountType from 'account/ChangeAccountType';
import CreateAlias from 'account/CreateAlias';
import {CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import {allRoutes} from 'custom-field/CustomFieldRoutes';
import InstapayTransfer from 'external-transfers/InstapayTransfer';
import RouteDefinition from 'routes/RouteDefinition';
import AutomaticDebitAgreementConfig from "account/ada/AutomaticDebitAgreementConfig";

const routes: RouteDefinition[] = [
  {
    name: 'Deposit Accounts',
    path: '/customer/:customerId/accounts'
  },
  {
      name: 'InstaPay transfer',
      path: '/customer/:customerId/accounts/:accountId/instapay-transfer',
      Component: InstapayTransfer
  },
  {
      name: 'Batch credit on-us checks',
      path: '/customer/:customerId/accounts/:accountId/batch-credit-on-us-checks',
      Component: BatchCreditOnUsChecks
  },
  {
    name: 'Change account type',
    path: '/customer/:customerId/accounts/:accountId/change-account-type',
    commandAccess: 'ChangeAccountType',
    Component: ChangeAccountType
  },
  {
    name: 'Create alias',
    path: '/customer/:customerId/accounts/:accountId/create-alias',
    commandAccess: 'CreateAccountAlias',
    Component: CreateAlias
  },
  {
    name: 'Automatic debit agreement config',
    path: '/customer/:customerId/accounts/:accountId/config-ada',
    commandAccess: 'ConfigureAutomaticDebitAgreement',
    Component: AutomaticDebitAgreementConfig
  },
  {
    name: 'Deposit Account configuration',
    path: '/admin/deposit-accounts',
    Component: AccountsConfiguration
  },
  ...allRoutes('/admin/deposit-accounts', CustomFieldGroup.ACCOUNT, false)
];

export default routes;
