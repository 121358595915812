import nxModule from 'nxModule';

const templateUrl = require('./organization.template.html');
nxModule.component('organization', {
  templateUrl : templateUrl,
  controller: function ($scope, $location) {

    $scope.isActive = function(route) {
      return route === $location.path();
    }
  }
});
