import nxModule from 'nxModule';
import _ from 'lodash';
import {automaticTransferStrategies} from "constants/loan";

import templateUrl from './loan-automatic-transfer.template.html';
import './loan-automatic-transfer.style.less';

class LoanAutomaticTransfer {
  constructor(customerCache, accountProductsCache) {
    this.customerCache = customerCache;
    this.accountProductsCache = accountProductsCache;
    this.accounts = [];

    const renderer = (data, escape) => {
      return `<div>${escape(data.description)}</div>`;
    };

    this.accountSelectConfig = {
      placeholder: 'Select account',
      searchField: ['description'],
      valueField: 'id',
      render: {
        option: renderer,
        item: renderer
      },
      maxItems: 1
    };

    this.transferStrategies = automaticTransferStrategies;
  }

  async fetchAccounts(customerId) {
    const accountsP = this.customerCache.depositAccounts(customerId).toPromise();
    const accountTypesP = this.accountProductsCache.toPromise();
    const relatedAccountsP = this.customerCache.relatedDepositAccounts(customerId).toPromise();
    const [accounts, accountTypes, relatedAccounts] = await Promise.all([accountsP, accountTypesP, relatedAccountsP]);
    const allAccounts = [...accounts, ...relatedAccounts];
    const accountWithTypes = allAccounts.map(acc => {
      const type = accountTypes.find(t => t.id === acc.typeId);
      const typeDescription = type.productDefinition.description ?? '';
      return {
        ...acc,
        type,
        description: `${acc.productNumber} ${typeDescription}`
      };
    });

    this.accounts = accountWithTypes.filter(acc => acc.status === 'ACTIVE' && ['SAVINGS', 'COMBO'].includes(acc.type.accountSubtype));
  }

  isEditModeEnabled() {
    return _.defaultTo(this.editMode, true);
  }

  $onInit() {
    this.fetchAccounts(this.customerId);
  }
}

nxModule.component('loanAutomaticTransfer', {
  templateUrl,
  bindings: {
    customerId: '<',
    automaticTransfer: '=',
    editMode: '<'
  },
  controller: LoanAutomaticTransfer
});