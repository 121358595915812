import {isFieldRequired, useValidationSchema} from '@nextbank/ui-components';
import {useField, useFormikContext} from 'formik';
import React, {ReactElement, useCallback} from 'react';
import EditableTree, {TreeProps} from 'tree/editableTree/EditableTree';
import {NxTreeNode} from 'tree/NxTreeNode';

export {NxTreeNode};

export type NxFormikTreeContainerProps<Value> = Omit<TreeProps<Value>, 'onChange' | 'value' | 'onBlur'> & {
  name: string;
};

const NxFormikEditableTree = function<Value>(props: NxFormikTreeContainerProps<Value>): ReactElement {
  const context = useFormikContext();
  const validationSchema = useValidationSchema();
  const [field, meta] = useField(props);
  const {validateOnChange, setFieldValue} = context;

  const onChange = useCallback((value: NxTreeNode<Value>[]): void => {
    setFieldValue(props.name, value, validateOnChange);
  }, [validateOnChange, setFieldValue, props.name]);

  return <EditableTree<Value>
    {...props}
    value={field.value}
    error={meta.error}
    onChange={onChange}
    disabled={props.disabled || context.isSubmitting}
    required={props.required || isFieldRequired(validationSchema, field.name)}
  />;
};

export default NxFormikEditableTree;