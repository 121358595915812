import nxModule from 'nxModule';
import LocalCache from 'shared/utils/localCache';

nxModule.factory('actionCategoryCache', (http, CacheFactory, config) =>
  new LocalCache({
    provider: () => http.get('/ledger/actions/categories'),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.actionCategory,
    cacheName: 'actionCategory'
  })
);
