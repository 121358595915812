import nxModule from 'nxModule';

const templateUrl = require('./checks-list.template.html');
nxModule.component('checksList', {
  templateUrl: templateUrl,
  controller: function (authentication) {
    const that = this;

    // add displayMode default
    that.$onInit = () => {
      that.displayMode = authentication.permissions['CHK_INCOMING_CHECK_READ'] ?  'INCOMING' : 'OUTGOING';
    };

    that.setDisplayMode = (mode) => {
      that.displayMode = mode;
    }
  }
});
