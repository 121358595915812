import nxModule from 'nxModule';

import templateUrl from './push-failed-ledger-sync.template.html';

class PushFailedLedgerSync {
  constructor(confirmationTemplate, popup, http, $filter, $route, rolesCache, branchService) {
      branchService.toPromise()
        .then(branches => this.branches = branches);

      this.confirmationTemplate = confirmationTemplate;
      this.popup = popup;
      this.http = http;
  }

  execute() {
    this.confirmationTemplate({
      question: `Do you want to push failed ledger sync for branch ${this.branch.name}?`,
      yesCallback: async () => {
        const ret = await this.http.post(`/batch-jobs/push-job/${this.branch.id}/LEDGER_SYNC`).toPromise();
        this.popup({text:`Pushed ledger sync`, callback: () => $route.reload()});
    }});
  }
}

nxModule.component('pushFailedLedgerSync', {
  templateUrl,
  controller: PushFailedLedgerSync
});
