import nxModule from 'nxModule';
import {AccessRuleDefinition} from "components/service/access/access-rule.types";
import {AccessRuleService} from "components/service/access/access-rule.service";

import templateUrl from './product-definition-predicate.template.html';
import {ProductDefinitionService} from "components/service/product-definition.service";
import {ProductDefinition} from "components/service/product.types";

class ProductDefinitionPredicate {

  // bindings
  private readonly ruleDefinitions: AccessRuleDefinition[] = [];
  private readonly accessInput: string = '';
  private readonly commandGroup: string = '';
  private inputDefinitions: Record<string, string>[] = [{}];
  private predicates: Record<string, Record<string, number[]>> = {};

  private type: string = '';
  private value: number[] = [];
  private inputDefinition: string = '';
  private productDefinitionMap?: Record<string, number>;

  constructor(private readonly accessRuleService: AccessRuleService,
              private readonly productDefinitionService: ProductDefinitionService) {
  }

  async $onInit(): Promise<void> {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    this.value = this.predicates[this.type][this.inputDefinition];

    const productDefinitions = await this.productDefinitionService.toPromise();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.productDefinitionMap = productDefinitions
      .filter((pd: ProductDefinition) => pd.productGroup === this.commandGroup)
      .map((pd: ProductDefinition) => ({
        label: pd.productName,
        value: pd.id
      }));
  }

  valueChanged(): void {
    this.predicates[this.type][this.inputDefinition] = this.value;
  }

  inputDefinitionChanged(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }
}

nxModule.component('productDefinitionPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    ngDisabled: '<',
    ruleDefinitions: '<',
    accessInput: '<',
    commandGroup: '<',
    type: '<'
  },
  controller: ProductDefinitionPredicate
});
