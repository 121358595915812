import nxModule from 'nxModule';

import templateUrl from './agents-devices.template.html';
import {ActionStyling, ActionType, Columns, Sorting} from '../../../common/dynamic-list/dynamic-list.component';
import _ from 'lodash';
import './agents-devices.style.less';
import {AgentsDevicesCache} from "components/agent-banking/service/agents-devices.cache.types";
import {ILocationService} from "angular";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import {Dict} from "shared/common/dict.types";
import {Device} from "components/agent-banking/details/devices/device-details.component";
import {ModalApi} from "components/technical/modal/modal.component";

class AgentsDevices {
  public agentId!: number;
  public branchId!: number;
  public agentDevices!: (Device & Partial<{
    status: string;
    edit: () => void;
    deactivate: () => void;
    creationDateFormatted: string;
    modificationDateFormatted: string;
    activationDateFormatted: string;
    warrantyExpiryDateFormatted: string;
    telcoProviderIdFormatted: string;
  }>)[];
  public deactivateModal?: ModalApi;
  public deactivateRemarks?: string;

  readonly sorting: Sorting = {
    code: 'asc'
  };

  readonly columns: Columns = [
    {title: 'No'},
    {title: 'Code', field: 'code'},
    {title: 'Status', field: 'status'},
    {title: 'Phone serial number', field: 'serialNumber'},
    {title: 'Bluetooth printer serial number', field: 'bluetoothPrinterSerialNumber'},
    {title: 'Creation date', field: 'creationDateFormatted'},
    {title: 'Modification date', field: 'modificationDateFormatted'},
    {title: 'Activation date', field: 'activationDateFormatted'},
    {title: 'Warranty expiry date', field: 'warrantyExpiryDateFormatted'},
    {title: 'Telco provider', field: 'telcoProviderIdFormatted'},
    {title: 'Remarks', field: 'remarks'},
    {
      actions: [{
        label: 'Edit',
        field: 'edit',
        type: ActionType.BUTTON,
        style: ActionStyling.WARNING,
        access: 'UpdateDevice'
      }, {
        label: 'Deactivate',
        field: 'deactivate',
        type: ActionType.BUTTON,
        style: ActionStyling.DANGER,
        access: 'DeactivateDevice'
      }]
    }
  ];

  constructor(private command: CommandService,
              private confirmation: Confirmation,
              private $route: NxRouteService,
              private agentsDevicesCache: AgentsDevicesCache,
              private $location: ILocationService,
              private dict: Dict,
              private $filter: NxIFilterService) {
  }

  async $onInit(): Promise<void> {
    await this.dict.onLoadingCompleteAsync();
    this.agentDevices = _.cloneDeep(await this.agentsDevicesCache.withParam(this.agentId).toPromise());
    this.agentDevices.forEach(d => {
      d.status = d.activated ? 'Active' : 'Inactive';
      if (d.activated) {
        d.edit = () => this.edit(d.id);
        d.deactivate = () => this.deactivate(d.id);
      }

      d.creationDateFormatted = this.$filter('prettyDateTime')(d.creationDate);
      d.modificationDateFormatted = this.$filter('prettyDateTime')(d.modificationDate);
      d.activationDateFormatted = this.$filter('prettyDateTime')(d.activatedOn);
      d.warrantyExpiryDateFormatted = this.$filter('prettyDate')(d.warrantyExpiryDate);
      d.telcoProviderIdFormatted = this.dict.getDescription("TELCO_PROVIDER", d.telcoProviderId);
    })
  }

  onDeactivateModalReady = ({api}: {api: ModalApi}) => {
    this.deactivateModal = api;
  };

  async deactivate(deviceId: number): Promise<void> {
    const {accepted} = await this.deactivateModal?.show();
    if (accepted) {
      const response = await this.command.execute('DeactivateDevice',
          {
            deviceId: deviceId,
            remarks: this.deactivateRemarks
          }).toPromise();
      if (response && !response.approvalRequired) {
        this.agentsDevicesCache.withParam(this.agentId).evict();
        this.$route.reload();
      }
    }

    this.deactivateRemarks = undefined;
  }

  edit(deviceId: number) {
    this.$location.path(`/agent-banking/agents/${this.branchId}/agent/${this.agentId}/devices/${deviceId}`);
  }
}

nxModule.component('agentsDevices', {
  templateUrl: templateUrl,
  bindings: {
    agentId: '<',
    branchId: '<'
  },
  controller: AgentsDevices
});
