import nxModule from 'nxModule';
import _ from 'lodash';
import {Loan} from 'components/service/loan.types';

import templateUrl from './customer-loan-create-details.template.html';
import './customer-loan-create-details.style.less';
import {renewedLoanCreationTypes} from "constants/loan";
import {ILocationService, ITimeoutService} from "angular";
import {NxRouteService} from 'routes/NxRouteService';
import LoanProductsCache from "components/administration/loan/common/loan-products.cache";

class CustomerLoanCreateDetails {
  // bindings
  private loan!: Loan;
  private loanTypeId!: number;
  private singlePage: boolean = false;
  private editMode!: boolean;
  private overrideDates!: boolean;
  shouldOverrideDates = () => _.defaultTo(this.overrideDates, false);

  private loanProduct: unknown = null;
  private feesAutoUpdated: string = '';
  private displayWarningIcon: boolean = false;

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private $timeout: ITimeoutService,
              private loanProductsCache: LoanProductsCache) {
  }

  async $onInit(): Promise<void> {
    const loanProducts = await this.loanProductsCache.toPromise();
    this.loanProduct = _.find(loanProducts, p => p.id == this.loanTypeId);
  }

  onWarningUpdated = (isDisplayed: boolean): void => {
    this.displayWarningIcon = isDisplayed;
  };

  onFeesAutoUpdated = (message: string): void => {
    this.feesAutoUpdated = message;
  };

  isEditModeEnabled(): boolean {
    return _.defaultTo(this.editMode, true);
  }

  isRestructuredLoan(): boolean {
    return this.loan.creationType === 'RESTRUCTURED';
  }

  isReconstructedLoan(): boolean {
    return this.loan.creationType === 'RECONSTRUCTED';
  }

  isConsolidatedLoan(): boolean {
    return this.loan.creationType === 'CONSOLIDATION';
  }

  isRenewedLoan(): boolean {
    return !!(this.loan.renewedLoanId && renewedLoanCreationTypes.includes(this.loan.creationType));
  }
}

nxModule.component('customerLoanCreateDetails', {
  templateUrl,
  bindings: {
    loan: '=',
    loanTypeId: '<',
    singlePage: '<',
    editMode: '<',
    overrideDates: '<'
  },
  controller: CustomerLoanCreateDetails
});
