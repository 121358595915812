import nxModule from 'nxModule';
import moment from 'moment';
import $ from 'jquery';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';

import './history-miscellaneous-transactions.style.less';

const templateUrl = require('./history-miscellaneous-transactions.template.html');

nxModule.component('historyMiscellaneousTransactions', {
  templateUrl: templateUrl,
  controller: function ($filter, $scope, $route, http, modalPrintPreviewService, commandAccessChecker, command, userCounterService, systemDateService) {
    const that = this;
    const reportCode = 'ActionDataReport';
    that.reprintActive = {
      "SSS_MISC_TRANSACTION_VALIDATION_SLIP" : false,
      "MISCELLANEOUS_VALIDATION_SLIP" : false,
      "MISC_CHECK_DEPOSIT_TO_BANK_VALIDATION_SLIP" : false
    };

    commandAccessChecker.canExecuteCommandAsync()
      .then(canExecuteCommand => that.canExecuteCommand = canExecuteCommand);

    // action to revert command name
    that.revertibleActions = {
      'CHECK_DEPOSIT_TO_BANK': 'CheckDepositToBankRevert',
      'CHECK_TRANSFER_TO_CASHIER': 'CheckTransferToCashierRevert',
      'CASH_WITHDRAWAL_FROM_BANK': 'MiscCashFromBankRevert',
      'CASH_DEPOSIT_TO_BANK': 'MiscCashToBankRevert',
      'CASH_IN': 'CashInRevert',
      'CASH_OUT': 'CashOutRevert',
      'DISTRIBUTED_CASH_IN_SOURCE': 'DistributedCashInRevert',
      'DISTRIBUTED_CASH_OUT_SOURCE': 'DistributedCashOutRevert',
      'OFFICIAL_RECEIPT_IN_CASH': 'OfficialReceiptInCashRevert',
      'OFFICIAL_RECEIPT_IN_CHECK': 'OfficialReceiptInCheckRevert'
    };

    that.revertibleCommands = {
      'MiscCashIn': 'MiscCashInRevert',
      'MiscInterbranchCashIn': 'MiscInterbranchCashInRevert',
      'MiscCashOut': 'MiscCashOutRevert',
      'MiscInterbranchCashOut': 'MiscInterbranchCashOutRevert',
      'MiscCashTransferToTeller': 'MiscCashTransferToTellerRevert',
      'MiscCashTransferToCashier': 'MiscCashTransferToCashierRevert',
      'MiscInterbranchCashTransfer': 'MiscInterbranchCashTransferRevert',
      'MiscPosCashWithdrawal': 'MiscPosCashWithdrawalRevert',
      'MiscCheckIn': 'MiscCheckInRevert',
      'MiscCheckDepositToBank': 'MiscCheckDepositToBankRevert',
      'MiscInterbranchCheckDepositToBank': 'MiscInterbranchCheckDepositToBankRevert',
      'MiscInterbranchCreateCashiersCheck': 'MiscInterbranchCreateCashiersCheckRevert',
      'MiscInterbranchEncashCashiersCheck': 'MiscInterbranchEncashCashiersCheckRevert',
      'MiscInterbranchCashToBank': 'MiscInterbranchCashToBankRevert',
      'MiscInterbranchCashFromBank': 'MiscInterbranchCashFromBankRevert',
      'MiscCreateCashiersCheck': 'MiscCreateCashiersCheckRevert',
      'MiscEncashCashiersCheck': 'MiscEncashCashiersCheckRevert',
      'MiscAtmCashIn': 'MiscAtmCashInRevert',
      'MiscAtmCashOut': 'MiscAtmCashOutRevert',
      'MiscAtmCashWithdrawal': 'MiscAtmCashWithdrawalRevert',
      'MiscOfficialReceiptInCash': 'MiscOfficialReceiptInCashRevert',
      'MiscOfficialReceiptInCheck': 'MiscOfficialReceiptInCheckRevert',
      'MiscInterbranchOfficialReceiptInCash': 'MiscInterbranchOfficialReceiptInCashRevert',
      'MiscInterbranchOfficialReceiptInCheck': 'MiscInterbranchOfficialReceiptInCheckRevert',
      'MiscCheckTransferToCashier': 'MiscCheckTransferToCashierRevert',
      'MiscCheckDepositToBranch': 'MiscCheckDepositToBranchRevert'
    };

    that.getRevertCommandName = item => {
      const fallbackCommandName = that.revertibleActions[item.actionType];
      const extraInfo = item.extraInfo;
      if(extraInfo.commandType) {
        return that.revertibleCommands[extraInfo.commandType] || fallbackCommandName;
      }

      return fallbackCommandName;
    };

    that.isRevertibleActionSelected = item => {
      const revertCommandName = that.getRevertCommandName(item);
      if(!revertCommandName || moment(item.registrationDate).isBefore(that.branchSystemDate)) {
        return false;
      }

      if(item.status === 'REJECTED') {
        return false;
      }

      if(!item.extraInfo.commandId) {
        return false;
      }

      return that.canExecuteCommand && that.canExecuteCommand(revertCommandName);
    };

    that.revertAction = async item => {
      const { accepted } = await that.modalApi.show();
      if(!accepted) {
        this.revertRemarks = null;
        return;
      }

      await command.execute(that.getRevertCommandName(item), {
          commandId: item.extraInfo.commandId,
          actionDataId: item.id,
          remarks: this.revertRemarks
        }).toPromise();

      userCounterService.refresh();
      that.tableConfig.reload();
      this.revertRemarks = null;
    };

    const isSSSPaymentAction = (actionType) => {
      switch(actionType) {
        case "PAY_ONLINE_SSS":
        case "PAY_OFFLINE_SSS":
          return true;
        default:
          return false;
      }
    };

    const isCheckDepositToBankAction = (actionType) => {
      return ["CHECK_DEPOSIT_TO_BANK", "DISTRIBUTED_CHECK_DEPOSIT_TO_BANK_SOURCE", "DISTRIBUTED_CHECK_DEPOSIT_TO_BANK_TARGET"].includes(actionType);
    };

    const actionPrintType = (actionType) => {
      if (isSSSPaymentAction(actionType)) {
        return "SSS_MISC_TRANSACTION_VALIDATION_SLIP";
      } else if (isCheckDepositToBankAction(actionType)) {
        return "MISC_CHECK_DEPOSIT_TO_BANK_VALIDATION_SLIP";
      } else {
        return "MISCELLANEOUS_VALIDATION_SLIP";
      }
    };

    that.isPrintActive = (action) => {
      const printType = actionPrintType(action.actionType);
      return that.reprintActive[printType];
    };

    modalPrintPreviewService.canReprint('MISCELLANEOUS_VALIDATION_SLIP', (active) => {
      that.reprintActive['MISCELLANEOUS_VALIDATION_SLIP'] = active;
    });
    modalPrintPreviewService.canReprint('SSS_MISC_TRANSACTION_VALIDATION_SLIP', (active) => {
      that.reprintActive['SSS_MISC_TRANSACTION_VALIDATION_SLIP'] = active;
    });

    modalPrintPreviewService.canReprint('MISC_CHECK_DEPOSIT_TO_BANK_VALIDATION_SLIP', (active) => {
      that.reprintActive['MISC_CHECK_DEPOSIT_TO_BANK_VALIDATION_SLIP'] = active;
    });

    that.printValidation = async (action) => {
      const printType = actionPrintType(action.actionType);
      await modalPrintPreviewService.showAsync({
        printDescription: printType,
        printProviderInput: {
          actionDataId: action.id
        }
      });
    };

    const createActionDetails = (action) => {
      const details = [];
      const pushDetail = (label, value, nullLabel = '-') => {
        details.push({label: label, value: value !== '' && value !== null ? value : nullLabel});
      };

      if (isSSSPaymentAction(action.actionType)) {
        pushDetail('Transaction no', _.get(action.extraInfo, 'sssTransactionNumber'));
      } else {
        pushDetail('Transaction no', action.id);
      }
      pushDetail('Date and time', $filter('prettyDateTime')(action.registrationDate));
      pushDetail('Source branch', action.sourceBranchName);
      pushDetail('Target branch', action.targetBranchName);
      pushDetail('User initiating', action.initiatingUserName);
      pushDetail('Target user', action.targetUserName);
      pushDetail('Transaction type', $filter('prettyEnum')(action.actionType));
      pushDetail('Remarks', action.remarks || action.rejectionRemarks);
      pushDetail('Status', $filter('prettyEnum')(action.status));
      pushDetail('Debit (Amount Received)', $filter('nxCurrency')(action.debitAmount));
      pushDetail('Credit (Amount Paid)', $filter('nxCurrency')(action.creditAmount));
      return details;
    };

    that.$onInit = () => {
      that.filterConfig = {
        reportCode : reportCode,
        buttons: {
          filter : {
            isVisible: true,
            isDisabled: false,
            action: () => that.filter(),
            text: 'View'
          }
        }
      };

      const today = moment();
      that.filters = {
        date_range: {
          from: today.clone().subtract(2, 'days').format('YYYY-MM-DD'),
          to: today.format('YYYY-MM-DD')
        }
      };

      // Call service for default filter config
      that.tableConfig = new NgTableParams({
        count: 50
      }, {
        counts: [],
        paginationMaxBlocks: 8,
        paginationMinBlocks: 3,
        getData: async params => {
          that.selectedBranchId = that.filters.branchId ? that.filters.branchId : null;
          const queryParams = $.param({
            pageNo: params.page() - 1,
            pageSize: params.count(),
            branchId: that.selectedBranchId,
            userId: that.filters.userId ? that.filters.userId : null,
            registrationDateFrom: _.get(that.filters, 'date_range.from'),
            registrationDateTo: _.get(that.filters, 'date_range.to')
          }, true);
          // Create pawn items search promise

          const items = await http.get(`/ledger/actions/history?${queryParams}`).toPromise();
          _.forEach(items.result, i => {
            i.details = createActionDetails(i);
          });
          that.branchSystemDate = await systemDateService.getSystemDateByBranchId(that.selectedBranchId);
          params.total(items.totalCount);
          return items.result;
        }
      });


      $scope.$watch('$ctrl.filters.branchId', () => {
        if (that.filters.branchId) {
          that.updateSystemDateReference();
        }
      });
    };

    that.updateSystemDateReference = async () => {
      that.selectedBranchId = that.filters.branchId;
      that.branchSystemDate = await systemDateService.getSystemDateByBranchId(that.selectedBranchId);
      const dateReference = that.branchSystemDate;
      that.filters.date_range.from = dateReference.clone().subtract(2, 'days').format('YYYY-MM-DD');
      that.filters.date_range.to = dateReference.format('YYYY-MM-DD');
    };


    that.filter = () => {
      // when user is on 10th page and changes filters e.g. date range which results in lack
      // of data for 10th page, table is not rendered at all, so he cannot change page and has no results
      // which suggests there is no data for this filters, but there is (just less than 10 pages)
      that.tableConfig.page(1);
      that.tableConfig.reload();
      that.hideInlinePanel();
    };

    that.itemClicked = (item, $event) => {
      $event.stopPropagation();
      that.selectedActionId = item.idx;
    };

    that.hideInlinePanel = () => {
      that.selectedActionId = null;
    };


    that.onModalApiReady = ({api,}) => {
      that.modalApi = api;
    };
  }
});

