import RouteDefinition from "routes/RouteDefinition";
import UpdateCheckNumber from "check/managment/UpdateCheckNumber";

const routes: RouteDefinition[] = [
  {
    name: 'Update check number',
    path: '/check/:checkId/update-number',
    commandAccess: 'UpdateCheckNumber',
    Component: UpdateCheckNumber
  },
];

export default routes;
