import {defaultResolver} from "./resolvers";
import angular from "angular-route";

export const inventoryRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/inventory/checks', {
    template: '<checks-list/>',
    label: 'Checks',
  }], ['/inventory/checks/outgoing/post-manual-check', {
    template: '<post-outgoing-check/>',
    label: 'Post manual inward check',
  }], ['/inventory/checks/outgoing/batch-register-inward-check', {
    template: '<batch-register-inward-check/>',
    label: 'Batch register inward checks',
  }], ['/inventory/batch-clearing', {
    template: '<batch-clearing-page/>',
    label: 'Batch clearing',
  }], ['/inventory/batch-clearing/clearing-history/:batchId', {
    template: '<batch-clearing-history batch-id="$resolve.batchId"/>',
    label: 'Batch clearing result details',
    resolve: {
      ...defaultResolver('batchId')
    }
  }], ['/inventory/batch-pdc-payment', {
    template: '<batch-pdc-payment/>',
    label: 'Batch PDC payment',
  }], ['/inventory/stop-orders', {
    template: '<stop-order-list/>',
    label: 'Stop orders',
  }], ['/inventory/stop-orders/active', {
    template: '<active-stop-order-list/>',
    label: 'Active stop orders',
  }]
];