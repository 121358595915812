import nxModule from 'nxModule';

import templateUrl from './transaction-quota.template.html';

class TransactionQuota {
  quotas!: any;
  quotaTitle!: string
}

nxModule.component('transactionQuota', {
  templateUrl: templateUrl,
  bindings: {
    quotaTitle: '<',
    quotas: '='
  },
  controller: TransactionQuota
});
