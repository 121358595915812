import nxModule from 'nxModule';
import {HttpService} from "shared/utils/httpService";

export type EntryType = 'DEBIT' | 'CREDIT';

export interface MiscTransactionTemplateUnit {
  id: number;
  accountFullCode?: string;
  tagType?: string;
  templateName: string;
  entryType: EntryType;
  branchType: 'SOURCE_BRANCH' | 'TARGET_BRANCH'
}

export interface LedgerTag {
  id: number;
  tagType: string;
  delegate: boolean;
  code: string;
  params: Partial<LedgerTagParams>;
  accountCode: string;
}

export interface LedgerTagParams {
  productStatus: string;
  productTypeID: string;
  feeDefinitionID: string;
  depositoryAccountID: string;
  referenceBranchID: string;
  misCode: string;
  assetCounterSubtypeID: string;
  ledgerAccountLabel: string;
}

export class MiscTransactionMappingService {
  constructor(private readonly http: HttpService) {
  }

  getTransactionUnits(): Promise<MiscTransactionTemplateUnit[]> {
    return this.http.get<MiscTransactionTemplateUnit[]>('/misc/transaction-units').toPromise();
  }

  getLedgerTags(): Promise<LedgerTag[]> {
    return this.http.get<LedgerTag[]>('/ledger/tags').toPromise();
  }

}

nxModule.service('miscTransactionMappingService', MiscTransactionMappingService);