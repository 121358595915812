import nxModule from 'nxModule';
import _ from 'lodash';
import {
  AccessRule,
  AccessRuleCache,
  AccessRuleDefinition,
  CommandAccessRuleDefinition,
  CommandGroup,
  CommandPredicates
} from "./access-rule.types";
import Authentication from "shared/utils/authentication";
import {HttpService} from "shared/utils/httpService";
import {NxIFilterService} from "components/technical/angular-filters";
import {AccessRuleDefinitionCache} from "components/service/access/access-rule-definition.cache.types";


export class AccessRuleService {

  constructor(
    private accessRuleCache: AccessRuleCache,
    private accessRuleDefinitionCache: AccessRuleDefinitionCache,
    private authentication: Authentication,
    private http: HttpService,
    private $filter: NxIFilterService
  ) {
  }

  async accessRuleForLoggedInUserRole(cmd: string): Promise<AccessRule | undefined> {
    const accessRules: AccessRule[] = await this.accessRuleCache.withParam(this.authentication.context.roleIds).toPromise();
    return accessRules.find(accessRule => accessRule.command === cmd);
  }

  async getCommandAccessRulesDefinition(commandGroup?: CommandGroup): Promise<CommandAccessRuleDefinition[]> {
    const accessRulesDef = await this.accessRuleDefinitionCache.toPromise();

    if (commandGroup) {
      return accessRulesDef.filter(def => def.commandGroup === commandGroup);
    }

    return accessRulesDef;
  }

  async getAccessRulesByRoleAndCommandGroup(roleId: number, commandGroup: CommandGroup): Promise<AccessRule[]> {
    const accessRules: AccessRule[] = await this.accessRuleCache.withParam([roleId]).toPromise();
    return accessRules.filter(accessRule => accessRule.commandGroup === commandGroup);
  }

  async getCommandPredicates(commandGroup?: CommandGroup): Promise<CommandPredicates> {
    const commandAccessRulesDef: CommandAccessRuleDefinition[] = await this.getCommandAccessRulesDefinition(commandGroup);
    const availablePredicates: CommandPredicates = {};

    commandAccessRulesDef.forEach(accessRuleDefinition => {
      const predicates = accessRuleDefinition.ruleDefinitions.map(def => def.predicates).flat();
      availablePredicates[accessRuleDefinition.command] = _.keyBy(predicates, 'name');
    });

    return availablePredicates;
  }

  async getRuleById(ruleId: number): Promise<AccessRule> {
    const accessRule = <AccessRule> await this.http.get(`/access/rules/${ruleId}`).toPromise();
    accessRule.displayName = this.$filter('translateEnum')(accessRule.command, 'COMMAND');
    return accessRule;
  }

  getInputDefinitionSelection(ruleDefinitions: AccessRuleDefinition[], predicateType: string): Record<string, string>[] {
    return ruleDefinitions
      .filter(def => def.predicates.some(predicate => predicate.name === predicateType))
      .map(def => {
        return {
          label: def.inputName.replace(/_/g, " ").toUpperCase(),
          value: def.inputName
        }
      });
  }
}

nxModule.service('accessRuleService', AccessRuleService);
