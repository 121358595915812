import nxModule from 'nxModule';

const templateUrl = require('./group-loans-assign.template.html');
nxModule.component('groupLoansAssign', {
  templateUrl: templateUrl,
  controller: function ($route, $location, http, groupCustomerCache, command, confirmation) {
    let that = this;
    let customerId = $route.current.params['customerId'];
    let batchId = $route.current.params['batchId'];
    that.batch = null;
    that.batches = [];

    that.request = {batchId};

    const batchSub = groupCustomerCache.batch(customerId).toObservable().subscribe(batches => {
      if (batches.length === 0) return;

      const batch = _.find(batches, b => b.id == batchId);
      const customerIds = batch.loans.map(l => l.customerId);

      http.get(`/products/loans/group-customer/matching?customerIds=${customerIds}`)
        .success(groupCustomers =>
          // exclude selected customer group
          that.groupCustomers = _.filter(groupCustomers, c => c.id != customerId)
        );
    });

    that.redirectBack = () => $location.path(`/customer/${customerId}/group-loans/${batchId}`);

    that.assign = () => confirmation('Do you want to assign the loan batch to the new group?', () =>
      command.execute('AssignLoanBatchToGroup', that.request)
        .success(() => {
          groupCustomerCache.refetch();
          that.redirectBack();
        })
    );

    that.$onDestroy = () => {
      batchSub.unsubscribe();
    };
  }
});