import nxModule from 'nxModule';
import {UserCache} from "components/service/users/user.cache";
import {AgentsBranchCache} from "components/agent-banking/service/agents-branch.cache.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {HttpService} from "shared/utils/httpService";

export class AgentPhoneNumberService {
  constructor(private http: HttpService,
              private userCache: UserCache,
              private agentsBranchCache: AgentsBranchCache,
              private customerCache: CustomerCache) {
  }

  async refreshUserPhoneNumber(customerId: number) : Promise<void> {
    const agent = await this.http.get(`/agents/management/customer/${customerId}/details`).toPromise();
    if (agent) {
      this.userCache.withParam().refetch();
    }
  }

  async refreshCustomerPhoneNumber(branchId: number, userId: number): Promise<void> {
    const agents = await this.agentsBranchCache.withParam(branchId).toPromise();
    const agent = agents.find(a => a.userId === userId);
    if (agent) {
      this.customerCache.profile(agent.customerId).refetch();
    }
  }

}

nxModule.service('agentPhoneNumberService', AgentPhoneNumberService);
