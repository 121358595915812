import nxModule from 'nxModule';
import {IDynamicTableColDef, ISortingValues, NgTableParams} from "ng-table";

import templateUrl from './dynamic-list.template.html';
import './dynamic-list.style.less'
import _ from "lodash";
import {IFilterService} from "angular";

export enum ListType {
  NORMAL = 'NORMAL',
  ERROR = 'ERROR'
}

export enum ActionType {
  LINK = "LINK",
  BUTTON = "BUTTON"
}

export enum ActionStyling {
  PRIMARY = 'btn-primary',
  INFO = 'btn-info',
  DANGER = 'btn-danger',
  WARNING = 'btn-warning',
  SUCCESS = 'btn-success'
}

export type ActionColumnDescription = {
  access?: string;
  label: string;
  type: ActionType,
  style?: ActionStyling,
  field: string | string[] // for nested path
};

type TextColumn = (IDynamicTableColDef & {field?: string | string[]})
type ActionColumn = {actions: ActionColumnDescription[]}

type Column = TextColumn | ActionColumn;
export type Columns = Column[];
export type Sorting = ISortingValues;

class DynamicList  {
  private listType!: ListType;
  private columns!: Columns;
  private data!: any[];
  private sorting!: Sorting;
  private tableConfig!: NgTableParams<any>;
  private accessChecker!: (access: string) => boolean;
  private generateRowIds!: boolean | undefined;

  constructor(private depositAccountTypeService: any, private interestBoardService: any,
              private $filter: IFilterService, private commandAccessChecker: any) {}

  async $onInit() {
    this.accessChecker = await this.commandAccessChecker.canExecuteCommandAsync();
    this.initTable();
    this.generateRowIds = this.generateRowIds === undefined ? true : this.generateRowIds;
  }

  initTable() {
    const data : any[] = this.data || [];
    this.tableConfig = new NgTableParams({
      count: data.length,
      sorting: this.sorting || {}
    }, {
      counts: [],
      dataset: data
    });
  }

  $onChanges() {
    this.initTable();
  }

  getValue(row: any, field: string | string[]): any {
    if(typeof field === 'string') {
      return row[field];
    } else {
      return _.get(row, field);
    }
  }

  hasAccess(row: any, action: ActionColumnDescription): boolean {
    if(!action) {
      return true;
    }

    if(!action.access) {
      return true;
    }

    if(!this.accessChecker) {
      return false;
    }

    return this.accessChecker(action.access);
  }
}

nxModule.component('dynamicList', {
  templateUrl,
  bindings: {
    columns: '<',
    data: '<',
    sorting: '<',
    listType: '<',
    generateRowIds: '<'
  },
  controller: DynamicList
});
