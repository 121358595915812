import {ILocationService} from 'angular';
import {PaymentIntervalDefinition} from 'components/administration/loan/intervals/payment-interval.types';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {Confirmation} from 'shared/common/confirmation.types';
import {CommandService} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';

import templateUrl from './payment-interval-list.template.html';

class PaymentIntervalList {
  paymentIntervals: PaymentIntervalDefinition[] = [];

  constructor(private $route: NxRouteService, private http: HttpService, private confirmation: Confirmation,
              private command: CommandService, private $location: ILocationService) {
  }

  async $onInit(): Promise<void> {
    this.paymentIntervals = await this.http.get<PaymentIntervalDefinition[]>(`/products/loans/intervals`).toPromise();
  }

  async remove(paymentInterval: PaymentIntervalDefinition): Promise<void> {
    const proceed = await this.confirmation(`Do you want to remove ${paymentInterval.name}?`);
    if (proceed) {
      await this.command.execute('DisablePaymentIntervalDefinition', {
        paymentIntervalDefinitionId: paymentInterval.id
      }).toPromise();
      this.$route.reload();
    }
  }

  private goBack(): void {
    this.$location.path('/admin/loan-products');
  }

}
nxModule.component('paymentIntervalList', {
  templateUrl,
  controller: PaymentIntervalList
});
