import _ from "lodash";
import {Print, PrintAttribute, PrintAttributeType} from "print/PrintType";

export const getAttrBoolValue = (print: Print, attr: PrintAttributeType): boolean => {
  const attribute = getAttr(print, attr);
  return attribute && attribute.value === 'true';
};

export const getAttr = (print: Print, attr: PrintAttributeType): PrintAttribute => {
  if (print) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return _.find(print.attributes, {type: attr});
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return undefined;
};


export const reorderLines = (print: Print): void => {
  print.lines = _.sortBy((print.lines || []), ['field', 'id']);
};