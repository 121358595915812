import nxModule from 'nxModule';
import templateUrl from './customer-merge-customers.template.html';
import './customer-merge-customers.style.less';
import _ from 'lodash';

class MergeCustomers {
  constructor(notification, customerCache, branchService, http, $location, confirmation, command, groupCustomerCache, popup, permissionService) {
    this.notification = notification;
    this.customerCache = customerCache;
    this.branchService = branchService;
    this.http = http;
    this.$location = $location;
    this.confirmation = confirmation;
    this.command = command;
    this.groupCustomerCache = groupCustomerCache;
    this.popup = popup;
    this.permissionService = permissionService;
    this.customersToMerge = [];
    this.sourceCustomerCoMadeLoans = new Map();
  }


  $onInit() {
    this.initCustomerData();
  }

  async initCustomerData() {
    const [profile, loans]= await Promise.all([
      this.fetchCustomerProfile(this.customerId),
      this.customerCache.loans(this.customerId).toPromise()
    ]);

    this.customerProfile = profile;
    this.customerType = this.customerProfile.customerType;
    this.customerLoansWithCoMaker = loans.filter(l => !_.isEmpty(l.coMakers)).map(l => l.id);

    this.mergedCustomers = this.customerProfile.mergedCustomers ?
      await Promise.all(this.customerProfile.mergedCustomers.map(cstId => this.fetchFormattedCustomerProfile(cstId))) : [];
  }

  chooseAndFormatAddress(addresses) {
    if (addresses && addresses.length > 0) {
      // choose primary address or the first one
      const chosenAddress = addresses.find(address => address.primary === true) || addresses.find(() => true);

      return ''
        .concat(chosenAddress.street ? chosenAddress.street.toString() + ', ' : '')
        .concat(chosenAddress.barangay ? chosenAddress.barangay.toString() + ', ' : '')
        .concat(chosenAddress.city ? chosenAddress.city.toString() + ', ' : '')
        .concat(chosenAddress.province ? chosenAddress.province.toString() + ', ' : '')
        .concat(chosenAddress.postalCode ? chosenAddress.postalCode.toString() + ', ' : '')
    }
  }

  fetchCustomerProfile(id) {
    return this.http.get(`/customers/${id}?allowInactive=true&includeCoMadeLoans=true`).toPromise();
  }

  async fetchFormattedCustomerProfile(id) {
    const cstProfile = await this.fetchCustomerProfile(id);
    const branches = await this.branchService.toPromise();

    return {
      id: cstProfile.id,
      customerNumber: cstProfile.customerNumber,
      branchId: cstProfile.branchId,
      branchName: branches.find(branch => branch.id === cstProfile.branchId).name,
      effectiveName: cstProfile.effectiveName,
      registrationOrBirthDate: cstProfile.individualData ? cstProfile.individualData.birthDate
        : cstProfile.corporateData.registrationDate,
      incorporationOrBirthPlace: cstProfile.individualData ? cstProfile.individualData.birthPlace
        : cstProfile.corporateData.placeOfIncorporation,
      address: this.chooseAndFormatAddress(cstProfile.addresses)
    };
  }

  async addCustomerToMergingContext(customer) {
    if (Number(customer.id) === Number(this.customerId)) {
      this.notification.show('Warning', 'Cannot merge with the same customer.');
      return;
    }

    if (this.customersToMerge.find(cst => cst.id === customer.id)) {
      this.notification.show('Warning', 'Customer has already been added.');
      return;
    }

    if (customer.customerType === 'GROUP') {
      this.notification.show('Warning', 'Merging of GROUP customers is not allowed.');
      return;
    }

    if(this.hasReachedMaxComakerTagLimit(customer)) {
      const sourceCoMadeLoanTotal = this.getSourceCoMadeLoanTotal(this.sourceCustomerCoMadeLoans.get(customer.id));
      const message = `Allowable maximum co-maker tag limit will be reached. ${customer.effectiveName} has another ${sourceCoMadeLoanTotal} co-made loans.`;
      this.removeCoMadeLoansOf(customer.id);
      this.popup({header: 'Cannot merge selected customer', text: message});
      return;
    }

    this.customersToMerge.push(await this.fetchFormattedCustomerProfile(customer.id));
  }

  hasReachedMaxComakerTagLimit(sourceCustomer) {
    if (!this.customerProfile.maxComakerTagLimit) {
      return;
    }

    if (sourceCustomer.coMadeLoanIds) {
      this.sourceCustomerCoMadeLoans.set(sourceCustomer.id, sourceCustomer.coMadeLoanIds)
    }

    const sourcesCoMadeLoanTotal = this.getSourceCoMadeLoanTotal([...this.sourceCustomerCoMadeLoans.values()].flatMap(id => id));
    return sourcesCoMadeLoanTotal + this.customerProfile.coMadeLoanIds?.length > this.customerProfile.maxComakerTagLimit;
  }

  getSourceCoMadeLoanTotal(sourceCoMadeLoanIds) {
    if (_.isEmpty(sourceCoMadeLoanIds)) {
      return 0;
    }
    return [... new Set(sourceCoMadeLoanIds)].filter(l => !this.customerLoansWithCoMaker.includes(l) && !this.customerProfile.coMadeLoanIds?.includes(l)).length;
  }

  removeCoMadeLoansOf(customerId) {
    this.sourceCustomerCoMadeLoans.delete(customerId);
  }

  async merge() {
    if (await this.confirmation(this.getConfirmationMessage(), true)) {
      const request = {
        sourceCustomerIds: this.customersToMerge.map(cst => cst.id),
        targetCustomerId: this.customerId
      };

      await this.command.execute('MergeCustomers', request).toPromise();

      this.customerCache.refetch();
      this.groupCustomerCache.refetch();
      this.permissionService.evict();

      this.redirectBack();
    }
  }

  getConfirmationMessage() {
    const profileInfo = `Do you want to merge the following duplicate CIFs to
        ${this.customerProfile.effectiveName} ${this.customerProfile.customerNumber}?`;
    const mergeInfo = this.customersToMerge.map(cst => `<br/><br/>- ${cst.effectiveName} ${cst.customerNumber}`);

    return profileInfo.concat(mergeInfo);
  }

  remove(customerId) {
    this.removeCoMadeLoansOf(customerId);
    this.customersToMerge = this.customersToMerge.filter(cst => cst.id !== customerId);
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/profile`);
  }
}

nxModule.component('customerMergeCustomers', {
  templateUrl,
  bindings: {
    customerId: '<'
  },
  controller: MergeCustomers
});
