import nxModule from 'nxModule';

import templateUrl from './boolean-predicate.template.html';
import {AccessRuleDefinition} from '../../../../service/access/access-rule.types';
import {AccessRuleService} from '../../../../service/access/access-rule.service';

class BooleanPredicate {

  type!: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private predicates!: unknown[];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private value: boolean;
  private inputDefinitions: Record<string, string>[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private inputDefinition: string;

  // bindings
  private ruleDefinitions!: AccessRuleDefinition[];
  private accessInput!: string;

  constructor(private readonly accessRuleService: AccessRuleService) {}

  $onInit(): void {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.value = this.predicates[this.type][this.inputDefinition];
  }

  valueChanged(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.predicates[this.type][this.inputDefinition] = this.value;
  }

  inputDefinitionChanged(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }
}

nxModule.component('booleanPredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    ngDisabled: '<',
    type: '<',
    ruleDefinitions: '<',
    accessInput: '<'
  },
  controller: BooleanPredicate
});
