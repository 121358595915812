import nxModule from 'nxModule';

import templateUrl from './start-the-day.template.html';
import {WorkingDaysCache} from "../../../service/working-days-cache";
import {BranchService} from "../../../service/branch.service";
import {WorkingDay} from "../../../../../react/management/WorkingDayType";
import './start-the-day-new.component';
import './start-the-day-progress.component';
import {Branch} from "management/BranchTypes";

interface StartDayAction {
  clearingDay: boolean;
  bspApproval: boolean;
  userId: number;
  branchId: number;
  startCash: number;
  startCoci: number;
}



class StartTheDay {
  branchId!: number;
  branch!: Branch | null;
  workingDay: WorkingDay | null = null;

  constructor (private workingDaysCache: WorkingDaysCache,
               private branchService: BranchService,
  ) { }

  async $onInit(): Promise<void> {
    const [workingDays, branches] = await Promise.all([
      this.workingDaysCache.toPromise(),
      this.branchService.toPromise()
    ]);

    this.workingDay = workingDays.find(w => w.branchId === this.branchId) ?? null;
    this.branch = branches.find(b => b.id === this.branchId) ?? null;
  }

}

nxModule.component('startTheDay', {
  templateUrl,
  bindings: {
    branchId: '<'
  },
  controller: StartTheDay
});
