import nxModule from 'nxModule';

import templateUrl from './batch-sss-pension-summary.template.html';

class BatchSssPensionSummary {
  constructor($location) {
    this.$location = $location;
  }

  goBack() {
    this.$location.path('/batch/sss-pension');
  }
}


nxModule.component('batchSssPensionSummary', {
  templateUrl,
  bindings: {
    'fileId': '<',
    'pensions': '<'
  },
  controller: BatchSssPensionSummary
});