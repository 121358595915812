import jwtDecode from 'jwt-decode';
import LocationUtil from '../tools/LocationUtil';
import {getStorageName, storage} from './tokenStorage';
import {NxToken, TokenDetails} from './TokenTypes';

const TOKEN_STORAGE_KEY = 'token';

const locationUtil = new LocationUtil();
class TokenStore {
  private tokenDetails: TokenDetails | null = null;
  private tokenUpdateListeners: ((tokenDetails: TokenDetails) => unknown)[] = [];

  public loadToken(): void {
    /*
      Data stored in sessionStorage on the login page cannot be accessed due to navigation happening
      after successful token generation. As a result, login page uses localStorage and we need to handle two cases:
      - when token is stored in localStorage - grab token and clear localStorage
      - when token is stored in sessionStorage - just grab token
      Race condition between setting token on the loginPage and reading from localStorage doesn't occur due to the fact that
      removing token also creates an event;
    */
    const moveTokenToSessionStorage = (token: string): void => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      sessionStorage.setItem(TOKEN_STORAGE_KEY, token);
    };

    const cachedTokenString = localStorage.getItem(TOKEN_STORAGE_KEY) ||
      sessionStorage.getItem(TOKEN_STORAGE_KEY);
    if(!cachedTokenString) {
      throw new Error('Token not present');
    }

    const nxToken = JSON.parse(cachedTokenString);
    if(!TokenStore.isTokenValid(nxToken)) {
      console.error('Invalid token', nxToken);
      throw new Error(`Invalid token`);
    }

    if(getStorageName() === 'sessionStorage') {
      moveTokenToSessionStorage(cachedTokenString);
    }

    this.tokenDetails = TokenStore.readTokenDetails(nxToken as NxToken);
    this.listenOnDifferentTabLoggingIn();
  }

  getTokenDetails(): TokenDetails {
    const details: TokenDetails | null = this.tokenDetails;
    if(!details) {
      throw new Error('Missing token');
    }

    return details;
  }

  clearToken(): void {
    this.removeStorageListener();
    storage().removeItem(TOKEN_STORAGE_KEY);
  }

  private static extractPermissions(grantedPermissions: string[]): Record<string, boolean> {
    if (!Array.isArray(grantedPermissions)) {
      return {};
    }

    const permissions: Record<string, boolean> = {};
    for(const permission of grantedPermissions) {
      permissions[permission] = true;
    }

    return permissions;
  }

  private static readTokenDetails(nxToken: NxToken): TokenDetails {
    const permissions = TokenStore.extractPermissions(nxToken.permissions);
    return {
      permissions: permissions,
      roleIds: nxToken.roleIds,
      branchIds: nxToken.branchIds,
      userBranchId: nxToken.branchId,
      token: nxToken.token,
      userId: nxToken.userId,
      expiration: nxToken.expiration
    };
  }

  static isTokenValid(token: NxToken): boolean {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedToken = jwtDecode(token.token) as any;
      return decodedToken && decodedToken.exp > new Date().getTime() / 1000;
    } catch (e) {
      console.error('Invalid token', e);
      return false;
    }
  }

  private async refreshOnTokenChange(e: StorageEvent): Promise<void> {
    const {storageArea, key,} = e;
    if (storageArea === localStorage && key === TOKEN_STORAGE_KEY && getStorageName() === 'sessionStorage') {
      console.info('Someone else changed token. Signing out.');

      const target = locationUtil.getCurrentRoutePath();
      locationUtil.redirectToLoginPage({
        error: 'Page opened in a new tab',
        ...(target ? {target,} : null)
      });
    }
  }

  private removeStorageListener(): void {
    window.removeEventListener('storage', this.refreshOnTokenChange);
  }

  private listenOnDifferentTabLoggingIn(): void {
    window.addEventListener('storage', this.refreshOnTokenChange);
  }

  updateToken(nxToken: NxToken): void {
    this.removeStorageListener();
    const tokenDetails = TokenStore.readTokenDetails(nxToken);
    storage().setItem(TOKEN_STORAGE_KEY, JSON.stringify(nxToken));

    this.listenOnDifferentTabLoggingIn();
    this.tokenDetails = tokenDetails;

    for(const cb of this.tokenUpdateListeners) {
      cb(tokenDetails);
    }
  }

  onTokenUpdated(cb: () => unknown): void {
    this.tokenUpdateListeners.push(cb);
  }

  isSessionExpired(): boolean {
    const tokenDetails = this.tokenDetails;
    return !tokenDetails || !tokenDetails.expiration || tokenDetails.expiration < new Date();
  }
}

export default new TokenStore();
export const ServiceType = TokenStore;