import nxModule from 'nxModule';

const templateUrl = require('./update-product-ownership.template.html');

nxModule.component('updateProductOwnership', {
  templateUrl,
  controller: function (confirmationTemplate, popup, $filter, $route, command, customerCache) {
    const that = this;
    that.customer = {};
    that.command = {};

    that.onProductChange = (productNumber) => {
      that.command.productNumbers = [productNumber];
    };

    that.onCustomerChange = (customer) => {
      that.customer = customer;
      that.command.customerId = customer.id;
    };

    that.execute = () => {
      confirmationTemplate({
        question: 'Do you want to update product owner?',
        details: [
          {label: 'Product number', description: that.productNumber},
          {label: 'Customer name', description: that.customer.effectiveName}
        ],
        yesCallback: () => command.execute('UpdateProductOwner', that.command).success(() => {
          if(customerCache.loadedCustomerId) {
            customerCache.loans(customerCache.loadedCustomerId).evict();
            customerCache.depositAccounts(customerCache.loadedCustomerId).evict();
            customerCache.termDeposits(customerCache.loadedCustomerId).evict();
          }
          popup({text: `Product owner successfully changed to ${that.customer.effectiveName}`,
            callback: () => $route.reload()}
          );
        }, true)
      })
    };
  }
});
