import nxModule from 'nxModule';
import {AttachedFile} from '../../service/file.service';
import {PageRequest, PageResult} from "tools/HttpTypes";

interface AtmBatchFile {
  id: number;
  attachedFileId: number;
  remarks?: string;
  network: string;
  fileType: string;
  uploadedOn: string;
  uploadedBy: number;
}

export interface AtmBatchFileOutput {
  atmBatchFile: AtmBatchFile,
  file: AttachedFile,
  rowCount: number,
  uploadedBy: string;
}

export class AtmBatchAcquirersService {
  constructor(private http: any) { }

  readAtmBatchFilesOutput(pageRequest: PageRequest): Promise<PageResult<AtmBatchFileOutput>> {
    return this.http.get(`/atm/batch-files?pageNo=${pageRequest.pageNo}&pageSize=${pageRequest.pageSize}`).toPromise();
  }
}

nxModule.service('atmBatchAcquirersService', AtmBatchAcquirersService);