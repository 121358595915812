import {Columns} from 'components/common/dynamic-list/dynamic-list.component';
import nxModule from 'nxModule';

import templateUrl from './perform-loan-moratorium.template.html';
import {HttpService} from "shared/utils/httpService";
import {PaymentIntervalDefinition} from "components/administration/loan/intervals/payment-interval.types";
import {LoanMoratoriumParameters} from "components/batch/loans/loan-moratorium.types";
import Popup from "shared/common/popup";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {NxRouteService} from "routes/NxRouteService";
import {CommandAccessChecker, CommandService} from 'shared/utils/command/command.types';

interface SingleResult {
  loanId: number;
  productNumber: string;
  errorCode: number;
  errorMessage: string;
  successful: boolean;
}

class PerformLoanMoratorium {
  protected parameters: Partial<LoanMoratoriumParameters> = {
    loanMoratoriumStartDate: null,
    moveAmortizations: true,
    intervalCount: null,
    fileId: undefined,
    interestAdjustmentType:  'NONE',
    interestStaggerEndDate: null,
    principalAdjustmentType: 'NONE',
    principalStaggerEndDate: null,
    customFeeAdjustmentType: 'NONE',
    paymentIntervalDefinitionId: null,
    amortizationMovementType: 'PAYMENT_INTERVAL',
    recalculatePenalties: true
  };

  protected readonly interestChangeOptions = [
    {
      label: 'None',
      value: 'NONE',
    },
    {
      label: 'Move additional interest to first amortization after grace period',
      value: 'MOVE_TO_FIRST_AMORTIZATION',
    },
    {
      label: 'Move additional interest to last amortization of the loan',
      value: 'MOVE_TO_LAST_AMORTIZATION',
    },
    {
      label: 'Stagger additional interest to amortizations in given date range',
      value: 'STAGGER',
    }
  ];

  protected readonly principalChangeOptions = [
    {
      label: 'None',
      value: 'NONE',
    },
    {
      label: 'Stagger principal from amortizations in the loan moratorium period',
      value: 'STAGGER',
    }
  ];

  protected readonly customFeeChangeOptions = [
    {
      label: 'None',
      value: 'NONE',
    },
    {
      label: 'Move custom fees to first amortization after grace period',
      value: 'MOVE_TO_FIRST_AMORTIZATION',
    },
    {
      label: 'Move custom fees to last amortization of the loan',
      value: 'MOVE_TO_LAST_AMORTIZATION',
    }
  ];

  private readonly sampleFile = new Blob([`1234-123-123
2345-123-123`], {type: 'text/csv'});

  private paymentIntervals: PaymentIntervalDefinition[] = [];


  readonly ProcessingResultColumns: Columns = [
    { title: 'No' },
    { title: 'Loan number', field: 'productNumber' },
    { title: 'Success', field: 'successful' },
    { title: 'Error code', field: 'errorCode' },
    { title: 'Error message', field: 'errorMessage' }
  ];
  processingResults?: SingleResult[];

  constructor(private confirmationTemplate: ConfirmationTemplate, private $route: NxRouteService,
              private command: CommandService,
              private popup: Popup,
              private http: HttpService,
              private commandAccessChecker: CommandAccessChecker) {}

  async $onInit() : Promise<void> {
    this.paymentIntervals = await this.http.get<PaymentIntervalDefinition[]>(`/products/loans/intervals`).toPromise();

    const accessChecker = await this.commandAccessChecker.canExecuteCommandAsync();
    if(!accessChecker('PerformLoanMoratorium')) {
      this.popup({
        text: `Loan moratorium can only be executed by user with permission to execute restricted commands.`
      });
      this.$route.path('/support');
    }
  }

  downloadSampleFile(): void {
    const sampleFileUrl = window.URL.createObjectURL(this.sampleFile);
    const a = window.document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'loan-moratorium-product-numbers.csv';
    a.click();
  }

  async execute() {
    this.processingResults = [];
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed?`,
    });

    if(confirmed) {
      const {output, approvalRequired} = await this.command.execute<unknown, SingleResult[]>('PerformLoanMoratorium', this.parameters).toPromise();
      if (approvalRequired) {
        return;
      }
      this.processingResults = output
    }
  }
}

nxModule.component('performLoanMoratorium', {
  templateUrl,
  controller: PerformLoanMoratorium
});
