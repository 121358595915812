import nxModule from 'nxModule';

import templateUrl from './account-group-members.template.html';

class AccountGroupMembers {
  constructor(){}
}

nxModule.component('accountGroupMembers', {
  controller: AccountGroupMembers,
  templateUrl,
  bindings: {
    accountGroup: '<'
  }
});