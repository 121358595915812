import nxModule from "nxModule";
import templateUrl from "./collections-payment-group.template.html";
import {NgTableParams} from "ng-table";
import {CollectionsGroup} from "components/dashboard/collections/payment/collections-payment.component";

class CollectionsPaymentGroup {
  getRowNo(index: number, tableConfig: NgTableParams<CollectionsGroup>): number {
    const currentPage = tableConfig.page() - 1;
    const rowsPerPage = tableConfig.count();
    return (currentPage * rowsPerPage) + index + 1
  }
}

nxModule.component('collectionsPaymentGroup', {
  templateUrl,
  bindings: {
    'tableConfig': '=',
    'showGroupMembersModal': '&'
  },
  controller: CollectionsPaymentGroup
});
