import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/combineAll';
import nxModule from 'nxModule';
import $ from "jquery";
import _ from 'lodash';

const templateUrl = require('./group-loans-disbursment.template.html');
nxModule.component('groupLoansDisbursment', {
  templateUrl: templateUrl,
  controller: function ($route, $location, $scope, http, notification, groupCustomerCache, userCache, authentication, reportService) {
    let that = this;

    that.groupLoan = {};
    that.reportContent = undefined;
    that.batchId = $route.current.params['groupLoanId'];
    that.customerId = $route.current.params['customerId'];
    that.releaser = undefined;
    that.releaseDate = undefined;
    that.users = undefined;
    that.allUsers = undefined;
    that.signatures = {};
    that.hasData = true;

    const reportCode = "group-loan-disbursment";
    let batchSub;

    const usersSub = userCache.withParam().toObservable().subscribe(users => {
      that.allUsers = users.map(user => {
        Object.assign(user, {fullName: `${user.lastName}, ${user.firstName} ${user.middleName ? user.middleName : ''}`});
        return user;
      });
      that.users = _.filter(that.allUsers, {'branchId': authentication.context.branchId});

      let currentUser = _.find(that.allUsers, {id: authentication.context.id});
      that.signatures.preparedByName = currentUser.fullName;

      batchSub = groupCustomerCache.batch(that.customerId)
        .toObservable()
        .subscribe(batches => {
          const batch = batches.find(batch => String(batch.id) === that.batchId);
          if (!batch) {
            console.warn("Couldn't find batch!, id: ", that.batchId);
            return;
          }

          that.releaser = _.find(that.allUsers, {id: batch.releasedById});
          if (that.releaser && !that.signatures.releasedByName) {
            that.signatures.releasedByName = that.releaser.fullName;
            that.releaseDate = batch.releaseDate;
          }
        });
    });

    that.buildParameters = () => {
      return {
        groupLoanId: Number(that.batchId),
        approvedBy: that.signatures.approvedByName,
        checkedBy: that.signatures.checkedByName
      };
    }

    that.preview = () => {
      http.http({
        url: `/reports/${reportCode}/json`,
        method: 'POST',
        responseType: 'json',
        data: $.param(that.buildParameters()),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      })
        .success(reportContent => {
          that.reportContent = reportContent;
          that.hasData = that.checkIfHasData(that.reportContent);
          if(that.hasData) {
            //Assign values to report header
            that.reportContent[0].reportDescription["Approved by"] = that.signatures.approvedByName;
            that.reportContent[0].reportDescription["Checked by"] = that.signatures.checkedByName;
          }

        });
    };

    that.checkIfHasData = (reports) => {
      return _(reports).map((r) => r.rows).flatten().value().length > 0
    };

    that.downloadXls = () => {
      reportService.downloadXls({
        reportCode: reportCode,
        params: that.buildParameters()
      });
    };

    that.close = () => {
      $location.path(`/customer/${that.customerId}/group-loans/${that.batchId}`);
    };

    that.print = () => {
      window.print();
    };

    that.$onDestroy = () => {
      usersSub.unsubscribe();
      batchSub.unsubscribe();
    };
  }
});

