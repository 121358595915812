import nxModule from 'nxModule';

import templateUrl from './agent-payment-mode.template.html';
import {AccessRuleDefinition} from '../../../../service/access/access-rule.types';
import {AccessRuleService} from '../../../../service/access/access-rule.service';

class AgentPaymentModePredicate {

  readonly type: string = '';
  readonly name: string = 'Payment mode';
  private predicates: Record<string, Record<string, string>> = {};
  private value: string = '';
  private inputDefinitions: Record<string, string>[] = [{}];
  private inputDefinition: string = '';
  // bindings
  private readonly ruleDefinitions: AccessRuleDefinition[] = [];
  private readonly accessInput: string = '';

  readonly paymentModes: ReadonlyArray<{ value: string, label: string }> = Object.freeze([
    {
      value: 'CASH',
      label: 'Cash'
    },
    {
      label: 'Transfer',
      value: 'TRANSFER'
    }
  ]);

  constructor(private readonly accessRuleService: AccessRuleService) {
  }

  $onInit(): void {
    this.inputDefinitions = this.accessRuleService.getInputDefinitionSelection(this.ruleDefinitions, this.type);
    this.inputDefinition = this.accessInput;
    this.value = this.predicates[this.type][this.inputDefinition];
  };

  valueChanged(): void {
    this.predicates[this.type][this.inputDefinition] = this.value;
  };

  inputDefinitionChanged(): void {
    if (this.inputDefinition !== this.accessInput && this.predicates[this.type].hasOwnProperty(this.accessInput)) {
      delete this.predicates[this.type][this.accessInput];
      this.valueChanged();
    }
  }
}

nxModule.component('agentPaymentModePredicate', {
  templateUrl,
  bindings: {
    predicates: '=',
    ngDisabled: '<',
    ruleDefinitions: '<',
    accessInput: '<',
    type: '<'
  },
  controller: AgentPaymentModePredicate
});
