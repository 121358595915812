import nxModule from 'nxModule';

nxModule.factory('eventBus', () => {
    return {
        listeners: {},

        trigger: function (eventType) {
            console.log("Triggering: " + eventType + "...");
            if (eventType == null) {
                throw "EventType is null or undefined!";
            }
            var eventListeners = this.listeners[eventType];
            if (eventListeners !== undefined) {
                _.each(eventListeners, function (listener) {
                    let stopwatch = new Date().getTime();
                    listener.callback(eventType);
                    let duration = new Date().getTime() - stopwatch;
                    console.log("Triggered: " + eventType + " for: " + listener.caption + " in: " + duration + "ms");
                });
            }
        },

        register: function (eventType, listenerCallback, listenerCaption) {
            if (eventType == null) {
                throw "EventType is null or undefined!";
            }
            var list = this.listeners[eventType];
            if (list === undefined) {
                list = [];
                this.listeners[eventType] = list;
            }
            list.push({callback: listenerCallback, caption: listenerCaption});
            console.log("Registered event: " + eventType + " (" + listenerCaption + ")");
        },

        events: {
            CUSTOMER_LOADED: "CUSTOMER_LOADED",
            GL_LOADED: "GL_LOADED"
        }
    };
});