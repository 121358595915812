import React, {ReactElement} from 'react'
import {VariableType} from "./PrintType"
import {User} from "user/UserTypes"

import * as Yup from 'yup';
import {
  NxAutocompleteDataProviderOutput,
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikAutocomplete,
  NxFormikButton,
  NxFormikSubmitButton,
  NxFormikTextarea,
  NxLoader,
  NxRow,
  NxRowPosition,
  NxStack
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import useAxios from "axios-hooks";
import BaseSchema from "yup/lib/schema";
import {FullTextSearchResult} from 'customer/CustomerTypes';
import axios from "axios";
import {PageResult} from "tools/HttpTypes";

export interface CustomVariable {
  name: string;
  type: VariableType;
  label: string;
  required: boolean;
  maxLength?: number;
}

type CustomVariableValue = string | number | null;
type FormFieldValue = string | {id: number} | null;

export interface PrintCustomVariableListProps {
  variables: CustomVariable[];
  onVariablesProvided: (values: Record<string, CustomVariableValue>) => Promise<unknown>;
  onClose: () => Promise<void>;
}

const createFieldSchema = (variable: CustomVariable): BaseSchema  => {
  if (['USER', 'CUSTOMER'].includes(variable.type)) {
    return variable.required ? Yup.mixed().required() : Yup.mixed().nullable();
  } else {
    return variable.required ? Yup.string().required() : Yup.string().nullable();
  }
};

const unwrapFormFields = (variables: CustomVariable[], input: Record<string, FormFieldValue>): Record<string, CustomVariableValue> => {
  return Object.fromEntries(
    variables
      .map(variable => {
        const variableName = variable.name;
        const variableValue = input[variableName];
        if(variable.type === 'TEXT') {
          if(typeof variableValue !== 'string') {
            throw new Error(`Wrong type of ${variableName}. Expected text`);
          }

          return [variableName, variableValue];
        }

        if(variableValue === null) {
          return [variableValue, null];
        }

        if(typeof variableValue === 'string') {
          throw new Error(`Wrong variable ${variable.name} value - text`);
        }

        return [variable.name, variableValue.id];
      })
  );
};

const filterUserByName = (name: string, users: User[]): User[]  => users.filter((str) => {
    return str.effectiveName.includes(name);
  });

const userEntityLabelProvider = (user: User): string => {
  return user.effectiveName;
};

const customerFullTextSearchEntityLabelProvider = (fullTextSearchResult: FullTextSearchResult): string => {
  return fullTextSearchResult.effectiveName;
};

const PrintCustomVariableList = (props: PrintCustomVariableListProps): ReactElement => {
  const [{data: users, loading}] = useAxios<User[]>(`/management/users`);

  if(loading || !users){
    return <NxLoader />;
  }

  const initialValues = Object.fromEntries(
    props.variables.map(variable => [variable.name, ['USER', 'CUSTOMER'].includes(variable.type) ? null : ''])
  );

  const schema = Yup.object().shape(
    Object.fromEntries(
        props.variables.map(variable => [variable.name, createFieldSchema(variable)]))
  );

  return <NxFormik<Record<string, FormFieldValue>>
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (input: Record<string, FormFieldValue>): Promise<void> => {
          const providedVariables = unwrapFormFields(props.variables, input);
          await props.onVariablesProvided(providedVariables);
        }} >
        {(): ReactElement => {
          return <NxForm>
            <NxStack>
              {
                props.variables.map(variable => {
                  if (variable.type === 'USER') {
                    const dataProvider = (name: string): Promise<NxAutocompleteDataProviderOutput<User>> => {
                      return Promise.resolve({
                          // move max options to autocomplete
                          values: filterUserByName(name, users).slice(0, 20)
                      });
                    };

                    return <NxFormikAutocomplete
                      key={variable.name}
                      dataProvider={dataProvider}
                      labelProvider={userEntityLabelProvider}
                      label={variable.label}
                      name={variable.name} />;
                  } else if (variable.type === 'CUSTOMER'){
                    const dataProvider = async (text: string): Promise<NxAutocompleteDataProviderOutput<FullTextSearchResult>> => {
                      if(text == null || text.trim().length < 1){
                        return {values: []};
                      }

                      const response = await axios.post<PageResult<FullTextSearchResult>>(`/customers/full-text-search`, {
                        effectiveName: text
                      });

                      return {
                        values: response.data.result.map(result => {
                          return {
                            ...result,
                            id: result.id
                          };
                        })
                      };
                    };

                    return <NxFormikAutocomplete
                      key={variable.name}
                      dataProvider={dataProvider}
                      labelProvider={customerFullTextSearchEntityLabelProvider}
                      label={variable.label}
                      name={variable.name}/>;
                  } else if (variable.type === 'TEXT') {
                    return <NxFormikTextarea
                      key={variable.name}
                      label={variable.label}
                      name={variable.name}
                      rows={2}
                      maxLength={variable.maxLength ? variable.maxLength : undefined}/>;
                  }
                })
              }
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={props.onClose}
                >
                  Close
                </NxButton>
                <NxFormikSubmitButton>
                  Ok
                </NxFormikSubmitButton>
              </NxRow>
            </NxStack>
          </NxForm>;
        }
      }
    </NxFormik>;
};

export default PrintCustomVariableList;