import {BranchService} from 'components/service/branch.service';
import {OutgoingCheckBatchFileDetails} from 'components/service/check.type';
import {Branch} from 'management/BranchTypes';
import {NgTableParams} from 'ng-table';
import nxModule from 'nxModule';
import Authentication from 'shared/utils/authentication';
import {HttpService} from 'shared/utils/httpService';
import {PageResult} from 'tools/HttpTypes';
import './disposition.style.less';
import templateUrl from './dispositions.template.html';

class Dispositions {
  private selectedDate: string | null = null;
  private tableConfig: NgTableParams<OutgoingCheckBatchFileDetails>;

  constructor(private branchService: BranchService,
              private http: HttpService,
              private authentication: Authentication) {

    this.tableConfig = new NgTableParams({
      page: 1,
      count: 20
    }, {
      counts: [],
      total: 0,
      paginationMinBlocks: 3,
      paginationMaxBlocks: 8,
      getData: async (params: NgTableParams<OutgoingCheckBatchFileDetails>): Promise<OutgoingCheckBatchFileDetails[]> => {
        if (!this.selectedDate) {
          return [];
        }
        const request = {
          pageNo: params.page() - 1,
          pageSize: params.count()
        };
        const response = await this.http.get<PageResult<OutgoingCheckBatchFileDetails>>(
          `/checks/outgoing/batch-files?pageNo=${request.pageNo}&pageSize=${request.pageSize}&uploadedOn=${this.selectedDate}&parserFormat=PCHC_DIRECT_CLEARING`
        ).toPromise();
        params.total(response.totalCount);
        return response.result;
      }
    });
  }

  async $onInit(): Promise<void> {
    const branches = await this.branchService.toPromise();
    const branch: Branch | undefined = branches.find(branch => branch.id === this.authentication.context.branchId);
    if (branch) {
      this.selectedDate = branch.systemDate;
    }
    this.tableConfig.reload();
  }

  filter(): void {
    this.tableConfig.reload();
  }

  async download(batchFile: OutgoingCheckBatchFileDetails): Promise<void> {
    const response = await this.http.get<Blob>(`/checks/outgoing/batch-files/download/${batchFile.fileId}`, {responseType: 'blob'}).toPromise();
    const file = window.URL.createObjectURL(response);
    const filename = batchFile.fileName.substring(0, batchFile.fileName.lastIndexOf('.')) || batchFile.fileName;

    const a = document.createElement('a');
    a.download = filename + '-Posted.MTF' ?? 'Disposition-Posted.MTF';
    a.href = file;
    a.click();
    window.URL.revokeObjectURL(file);
  }

}

nxModule.component('dispositions', {
  templateUrl: templateUrl,
  controller: Dispositions
});