import moment from 'moment';
import _ from 'lodash';
import {Observable} from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import {NgTableParams} from 'ng-table';

import nxModule from 'nxModule';
import {Statuses} from '../common/gl-transaction.service';
import {defaultHeaderLabels, mergedLedger} from '../common/gl.utils';
import {updateRefreshTime} from 'components/common/refresh-button/refresh-button.component';
import './gl-transaction-list.style.less';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';


const templateUrl = require('./gl-transaction-list.template.html');

nxModule.component('glTransactionList', {
  templateUrl,
  controller: function ($scope, $routeParams, $location, glLedgerService, branchService,
                        breadcrumbs, glTransactionService, userCache, confirmation,
                        notification, popup, reportModal) {
    const self = this;
    self.ledgerId = parseInt($routeParams.accountId);
    self.header = [];

    self.statuses = Object.keys(Statuses)
      .map(statusName => ({
          value: statusName,
          label: Statuses[statusName]
      }));

    self.users = [];

    self.selectConfig = {
      placeholder: 'Anyone',
      searchField: ['effectiveName'],
      valueField: 'id',
      labelField: ['effectiveName'],
      maxItems: 1
    };

    self.filter = {
      userId: null,
      status: self.statuses[0],
    };

    self.config = new NgTableParams({
        count: 15,
      }, {
        counts: [],
        paginationMaxBlocks: 8, // max pager pages
        paginationMinBlocks: 3, // min pager pages
        getData: async params => {
          if (!self.filter.date) return Promise.resolve();

          const transactionsPromise = glTransactionService.fetchTransactions({
            ledgerId: self.ledgerId,
            userId: self.filter.userId,
            systemDateFrom: self.filter.date.from,
            systemDateTo: self.filter.date.to,
            status: self.filter.status.value,
            pageNo: params.page() - 1,
            pageSize: params.count(),
          }).toPromise();

          const transactionsObservable = Observable.fromPromise(transactionsPromise);

          return transactionsObservable.combineLatest(userCache.withParam(true).toObservable().take(1), (transactions, users) => {
            self.config.total(transactions.totalCount);
            updateRefreshTime($scope);

            return transactions.result.map((transaction, index) => {
              const user = _.find(users, user => user.id === transaction.userId);
              const rejectionUser = transaction.rejectionUserId ? _.find(users, user => user.id === transaction.rejectionUserId) : null;
              const formatFullDateTime = date => date ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : '-';
              const formatUser = (user) => user ? `${user.firstName} ${user.lastName}` : '-';
              return {
                id: transaction.id,
                index: index + 1,
                backdated: transaction.backdated,
                contingent: transaction.contingent,
                objectIds: transaction.objectIds,
                transactionDate: formatFullDateTime(transaction.registrationTime),
                status: transaction.status,
                systemDate: moment(transaction.systemDate).format('MM/DD/YYYY'),
                postingDate: transaction.status === 'PENDING' && !transaction.backdated ? '-' : moment(transaction.postingDate).format('MM/DD/YYYY'),
                transactionType: transaction.transactionType,
                user: formatUser(user),
                amount: transaction.amount,
                remarks: transaction.remarks,
                revokeAction: transaction.status === 'PENDING' && transaction.transactionType === 'MANUAL',
                transactionUnits: transaction.transactionUnits,
                rejectionTime: formatFullDateTime(transaction.rejectionTime),
                rejectionUser: formatUser(rejectionUser),
                interbranch: transaction.interbranch,
                interbranchSourceTransactionId: transaction.interbranchSourceTransactionId
              };
            });
          }).toPromise();
        }
      });

    self.mapTransactionType = (type) => {
      if (type === 'OPERATION') {
        return 'Automatic';
      } else if (type === 'MANUAL') {
        return 'Manual';
      } else if (type === 'LEDGER_ACTION') {
        return 'Miscellaneous';
      } else {
        return type;
      }
    };

    $scope.$watch('$ctrl.filter', () => {
      self.selectedTransaction = null;
      self.config.page(1);
      self.config.reload();
    }, true);

    const initializeDateFilterSettings = (ledger) => {
      const systemDate = ledger.branch.systemDate;
      const formattedDate = moment(systemDate).format('YYYY-MM-DD');
      self.filter.date = {
        from: formattedDate,
        to: formattedDate
      };
    };

    const dataUpdates = async () => {
      const ledger = await mergedLedger({
        glLedgerService,
        branchService,
        accountId: self.ledgerId
      });

      initializeDateFilterSettings(ledger);

      setBreadcrumbs(breadcrumbs, 'gl-transaction-list-label', ledger.template.name);

      self.branchId = ledger.branchId;
      self.header = defaultHeaderLabels(ledger);
    }

    self.refresh = () => {
      self.config.reload();
    };

    self.resolveLabel = statusKey =>
      Statuses[statusKey];

    self.revokeAction = async (item, $event=null) => {
      if($event) {
        $event.stopPropagation();
      }

      const proceed = await confirmation("Do you want to revoke the transaction?");

      if (proceed) {
        if (item.interbranch) {
          await glTransactionService.revokeInterbranchTransaction({
            sourceTransactionId: item.id
          });
        } else {
          await glTransactionService.revokeTransaction({
            ledgerId: self.ledgerId,
            id: item.id
          });
        }
        notification.show('Success', 'Transaction revoked successfully');
        self.refresh();
      }
    };

    self.print = async (item, $event) => {
      if($event) {
        $event.stopPropagation();
      }
      reportModal.display({
        reportCode: 'GeneralLedgerTicketReport',
        params: {transactionId: item.id, branchId: self.branchId},
        hideXls: true
      });
    };

    self.modify = (item) => {
      $location.path(`/general-ledger/transactions/${self.ledgerId}/modify/${item.id}`);
    };

    self.modifyInterbranch = (item) => {
      if (!item.interbranchSourceTransactionId) {
        $location.path(`/general-ledger/transactions/${self.ledgerId}/modify-interbranch/${item.id}`);
      } else {
        popup({
          header: 'Interbranch transaction',
          text: 'Interbranch transaction may be modified only from source transaction.'
        });
      }
    };

    self.hideDetailsPanel = () => {
      self.selectedTransaction = null;
    };

    self.rowClicked = (row, $event) => {
      $event.stopPropagation();
      self.selectedTransaction = row.id;
    };

    self.$onInit = async () => {
      await dataUpdates();
      const allUsers = await userCache.withParam().toPromise();
      self.users = allUsers
        .map(user => {
          return {id: user.id, effectiveName: `${user.firstName} ${user.lastName}`}
        });
    };
  }
});
