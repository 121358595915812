import {NxLoader,} from "@nextbank/ui-components";
import React, {ReactElement} from "react";
import {useParams} from "react-router";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import {ColumnConfigurationDetails} from "report/ReportType";
import {useReportDescriptors} from "report/ReportService";

export interface ReportConfigurationProps {
  configuration:  ColumnConfigurationDetails[],
  reportCode: string
}

export interface ReportColumnConfigurationContainerProps {
  viewType: React.Factory<ReportConfigurationProps>
}

const ReportColumnConfigurationContainer = ({viewType: View}: ReportColumnConfigurationContainerProps): ReactElement => {
  const params = useParams<{ reportCode: string }>();

  const [{
    data: configuration,
    loading
  }] = useAxios<ColumnConfigurationDetails[]>(`/reports/configuration/${params.reportCode}`);

  const [{
    data: reportDescriptors,
    loading: loadingDescriptors
  }] = useReportDescriptors();

  if (!configuration || loading || loadingDescriptors || !reportDescriptors) {
    return <NxPage><NxHeader>Report configuration</NxHeader><NxLoader/></NxPage>;
  }

  const reportDescriptor = reportDescriptors.find(rep => rep.code === params.reportCode);
  if(!reportDescriptor) {
    throw new Error(`Missing report ${params.reportCode}`);
  }

  return <NxPage>
    <NxHeader>Configuration of {reportDescriptor.name}</NxHeader>
    <View configuration={configuration} reportCode={params.reportCode}/>
  </NxPage>;
};
export default ReportColumnConfigurationContainer;
