import nxModule from 'nxModule';
import _ from "lodash";

const templateUrl = require('./pos-cash-withdrawal.template.html');
nxModule.component('posCashWithdrawal', {
  templateUrl: templateUrl,
  controller: function ($filter, $routeParams, authentication, actionCommand, branchService, miscCommandService) {

    const that = this;
    that.branch = null;
    that.transaction = {};

    const sub = branchService.toObservable().subscribe(branches => {
      that.branch = _.find(branches, {id: authentication.context.branchId});
    });

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      miscCommandService.executeCommand('MiscPosCashWithdrawal', that.transaction);
    };

    that.$onDestroy = () => sub.unsubscribe();
  }
});


