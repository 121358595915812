import {ILocationService} from 'angular';
import LoanProductsCache from 'components/administration/loan/common/loan-products.cache';
import {LoanType} from 'components/service/loan-type.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import nxModule from 'nxModule';
import {ActionType, Columns, Sorting} from '../../../common/dynamic-list/dynamic-list.component';

import templateUrl from './loan-product-list.template.html';

class LoanProductList {
  private loanProducts: LoanType[] = [];
  private readonly columns: Columns = [
    { title: 'No'},
    { title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    { title: 'Product name', field: 'productName', sortable: 'productName'},
    { title: 'Min. interest rate', field: 'minInterestRate'},
    { title: 'Max. interest rate', field: 'maxInterestRate'},
    { title: 'Minimum amount', field: 'minAmountFormatted' },
    { title: 'Maximum amount', field: 'maxAmountFormatted'},
    { title: 'Minimum term', field: 'minTerm'},
    { title: 'Maximum term', field: 'maxTerm'},
    { actions: [{
        access: 'UpdateLoanType',
        label: 'Edit',
        field: 'href',
        type: ActionType.LINK
      }],
    },
  ];

  private readonly sorting: Sorting = {
    productTypeCode: 'asc',
    productName: 'asc',
  };

  constructor(private loanProductsCache: LoanProductsCache, private $filter: NxIFilterService, private $location: ILocationService) { }

  async $onInit() {
    this.loanProducts = await this.loanProductsCache.toPromise();

    for(const loan of this.loanProducts) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loan.productName = loan.productDefinition.productName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loan.productTypeCode = loan.productDefinition.productTypeCode;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loan.minAmountFormatted = this.$filter('nxCurrency')(loan.minAmount);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loan.maxAmountFormatted = this.$filter('nxCurrency')(loan.maxAmount);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loan.href = `#!/admin/loan-products/types/${loan.id}`;
    }
  }

  private goBack(): void {
    this.$location.path('/admin/loan-products');
  }
}
nxModule.component('loanProductList', {
  templateUrl,
  controller: LoanProductList
});
