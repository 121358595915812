import nxModule from "nxModule";
import templateUrl from "./credit-scoring-modal-customer-details.template.html";
import './credit-scoring-modal-customer-details.styles.less'
import {LoanApplicationDetails} from "components/customer/credit-scoring/credit-scoring.types";

class CreditScoringModalCustomerDetails {
  loanApplicationDetails!: LoanApplicationDetails;
}

nxModule.component('creditScoringModalCustomerDetails', {
  templateUrl,
  bindings: {
    loanApplicationDetails: '<',
  },
  controller: CreditScoringModalCustomerDetails
});
