import {Columns} from 'components/common/dynamic-list/dynamic-list.component';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './remove-active-devices.template.html';

interface RemoveActiveDevicesCommandInput {
  usernames: string[]
}

interface RemoveActiveDevicesCommandOutput {
  removedDeviceUsernames: string[]
}

interface FormattedResult {
  username: string,
  message: string
}

class RemoveActiveDevices {
  protected usernames: string[] = [];

  readonly usernamesConfig = {
    placeholder: 'Add usernames',
    create: true
  };

  readonly resultColumns: Columns = [
    { title: 'No'},
    { title: 'Username', field: 'username'},
    { title: 'Result', field: 'message'}
  ];

  private result : FormattedResult[] | undefined;

  constructor(private confirmationTemplate: ConfirmationTemplate,
              private popup: Popup,
              private $route: NxRouteService,
              private command: CommandService) {}

  async remove() : Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Are you sure you want to proceed?`,
    });

    if(confirmed) {
      const {output} = await this.command.execute<RemoveActiveDevicesCommandInput, RemoveActiveDevicesCommandOutput>('RemoveActiveDevices', {
        usernames: this.usernames
      }).toPromise();

      this.result = this.processSummary(output.removedDeviceUsernames);
      this.usernames = [];
    }
  }

  processSummary(removedDeviceUsernames: string[]) : FormattedResult[]{
    return this.usernames.map(username => ({
      username: username,
      message: removedDeviceUsernames.includes(username) ? "deactivated" : "no device found"
    }));
  }
}

nxModule.component('removeActiveDevices', {
  templateUrl,
  controller: RemoveActiveDevices
});
