import nxModule from 'nxModule';
import _ from 'lodash';
import moment from "moment";

nxModule.factory('profileUpdateService', function (http, customerService, command) {

  function updatePhoneData(profile, type, phone) {
    if (phone && phone.number) {
      const foundPhone = _.find(profile.phones, {type: type});
      if (foundPhone && foundPhone.id) {
        //update existing
        foundPhone.number = phone.number;
        foundPhone.planId = phone.planId;
        foundPhone.monthlyExpenseId = phone.monthlyExpenseId;
      } else {
        //add phone
        profile.phones.push({
          number: phone.number,
          type: type,
          planId: phone.planId,
          monthlyExpenseId: phone.monthlyExpenseId,
          primary: false
        });
      }
    } else {
      _.remove(profile.phones, {type: type});
    }
    const primaryPhone = _.find(profile.phones, {primary: true});
    if (!primaryPhone && profile.phones.length > 0) {
      profile.phones[0].primary = true;
    }
  }

  function updateIncomeData(incomeData) {
    if (incomeData == null || incomeData.length === 0) return;

    incomeData.forEach(incomeSource => {
      if (!_.isEmpty(incomeSource.companyAddress)) {
        incomeSource.companyAddress.type = incomeSource.companyAddress.type || 'PERMANENT';
        incomeSource.companyAddress.primary = incomeSource.companyAddress.primary || true;
      }

      if (incomeSource.businessStartDate) {
        incomeSource.businessStartDate = moment(incomeSource.businessStartDate).format('YYYY-MM-DD');
      }
    });
  }

  /**
   * if profile and original is not equal return updated profile data in format acceptable by server
   * otherwise return null
   */
  function prepareRequest(profile, original) {
    const deepProfileClone = _.cloneDeep(profile);
    const deepOriginalClone = _.cloneDeep(original);

    const extractFilesIdsAndRemoveFileContent = (fileHolder, filesKey) => {
      let filesModels = fileHolder[filesKey];
      filesModels = filesModels || [];
      //cleanup model to make sure data will not be send back to server
      //we only care about the file id
      fileHolder[filesKey] = null;
      return filesModels.filter(f => f.id)
        .map(f => f.id);
    };

    if (!_.isEqual(deepProfileClone, deepOriginalClone)) {
      updatePhoneData(deepProfileClone, 'MOBILE', _.get(deepProfileClone, 'extractedPhones.mobile'));
      updatePhoneData(deepProfileClone, 'HOME', _.get(deepProfileClone, 'extractedPhones.home'));
      updatePhoneData(deepProfileClone, 'WORK', _.get(deepProfileClone, 'extractedPhones.work'));

      deepProfileClone['extractedPhones'] = null;
      deepOriginalClone['extractedPhones'] = null;

      updateIncomeData(deepProfileClone.incomeSources);

      if(deepProfileClone.customerType === 'INDIVIDUAL') {
        let signatureFileId = _.head(extractFilesIdsAndRemoveFileContent(deepProfileClone.individualData, 'signatureFile'));
        deepProfileClone.individualData.signatureFileId = signatureFileId;

        let pictureFileId = _.head(extractFilesIdsAndRemoveFileContent(deepProfileClone, 'pictureFile'));
        deepProfileClone.pictureFileId = pictureFileId;
      }

      deepProfileClone.idDocuments.forEach(function (doc) {
        doc.photoFileIds = extractFilesIdsAndRemoveFileContent(doc, 'photos');
      });

      if (deepProfileClone.customerSubtype === 'BDA') {
        // copy individual data to bdaIndividualData bean
        deepProfileClone.bdaIndividualData = deepProfileClone.individualData;
        deepProfileClone.individualData = null;
        deepProfileClone.restrictedIndividualData = null;
      } else if (deepProfileClone.customerSubtype === 'RESTRICTED'){
        deepProfileClone.restrictedIndividualData = deepProfileClone.individualData;
        // copy individual data to restrictedIndividualData bean
        deepProfileClone.bdaIndividualData = null;
        deepProfileClone.individualData = null;
      }

      customerService.encodeNewLinesInComments(deepProfileClone);

      return deepProfileClone;
    }
    return null;
  }

  return {
    update: (profile, original, isForceEdit) => {
      let customerData = prepareRequest(profile, original);
      if (!customerData && !isForceEdit) return null;
      if (!customerData) {
        customerData = profile; // User is in Force edit but did not update anything. Just Confirm Update
      }
      return command.execute('UpdateCustomer', customerData);
    },
    create: (profile) => {
      let customerData = prepareRequest(profile, {});
      if (customerData) {
        return command.execute('CreateCustomer', customerData);
      } else {
        return null;
      }
    },
    convertToCommandInput: (profile) => {
      if (profile.customerType === 'INDIVIDUAL') {
        profile.corporateData = null;
      } else {
        profile.individualData = null;
      }
      return prepareRequest(profile, {});
    }
  }
});
