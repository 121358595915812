import {ILocationService} from 'angular';
import DepositAccountTypeService from 'components/administration/deposit-account/common/deposit-account-type-service';
import {AccountType} from 'components/service/account.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import nxModule from 'nxModule';
import {ActionType, Columns, Sorting} from '../../../common/dynamic-list/dynamic-list.component';

import templateUrl from './deposit-account-list.template.html';

class DepositAccountList {
  private accounts: AccountType[] = [];
  private readonly columns: Columns = [
    {title: 'No'},
    {title: 'Product code', field: 'productTypeCode', sortable: 'productTypeCode'},
    {title: 'Product name', field: 'productName', sortable: 'productName'},
    {title: 'Product subtype', field: 'productSubtype', sortable: 'productSubtype'},
    {title: 'Interest rate', field: 'interestRange'},
    {title: 'Maintaining balance', field: 'maintainingBalanceFormatted'},
    {
      actions: [{
        access: 'UpdateAccountType',
        label: 'Edit',
        field: 'href',
        type: ActionType.LINK
      }],
    },
  ];

  private readonly sorting: Sorting = {
    productSubtype: 'asc',
    productTypeCode: 'asc',
    productName: 'asc'
  };

  constructor(private depositAccountTypeService: DepositAccountTypeService,
              private interestBoardService: unknown,
              private $location: ILocationService,
              private $filter: NxIFilterService) {
  }

  async $onInit() {
    this.accounts = await this.depositAccountTypeService.toPromise();
    for (const account of this.accounts) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.productName = account.productDefinition.productName;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.productTypeCode = account.productDefinition.productTypeCode;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.productSubtype = this.$filter('prettyEnum')(account.accountSubtype);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.interestRange = this.interestBoardService.formatInterestRateRangeForRateType(account.interestBoards);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.maintainingBalanceFormatted = this.$filter('nxCurrency')(account.maintainingBalance);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      account.href = `#!/admin/deposit-accounts/types/${account.id}`;
    }
  }

  goBack(): void {
    this.$location.path('/admin/deposit-accounts');
  }
}

nxModule.component('depositAccountList', {
  templateUrl,
  controller: DepositAccountList
});
