import nxModule from 'nxModule';

import templateUrl from './agents-settlement.template.html';
import {CustomerCache} from "components/service/customer.cache.types";
import {IScope} from "angular";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {Agent} from "components/agent-banking/service/agents-branch.cache.types";
import {Account} from "components/service/product.types";

class AgentsSettlement {
  public agent!: Agent;
  public selectedAccount?: Account;
  private accounts: Account[] = [];

  constructor(private customerCache: CustomerCache, private depositAccountTypeService: DepositAccountTypeService, private $scope: IScope) {
  }

  async $onInit(): Promise<void> {
    this.$scope.$watch('$ctrl.agent.customerId', async () => {
      if (this.agent?.customerId) {
        this.accounts = await this.fetchDepositAccounts();
        this.selectedAccount = this.getAgentAccount();
        this.accountChanged();
      }
    });
  }

  private getAgentAccount(): Account | undefined {
    if (this.agent.id) {
      return this.accounts.find(a => a.id === this.agent.accountId);
    }

    if (this.accounts.length === 1) {
      return this.accounts[0];
    }

    return undefined;
  }

  async fetchDepositAccounts(): Promise<(Account & {name?: string})[]> {
    const [depositAccounts, accountTypes] = await Promise.all([
      this.customerCache.depositAccounts(this.agent.customerId).toPromise(),
      this.depositAccountTypeService.toPromise()
    ]);

    return depositAccounts.map(account => {
      const type = accountTypes.find(type => type.id === account.typeId);
      if (type) {
        return {
          ...account,
          name: `${type.productDefinition.productName} [${account.productNumber}]`
        };
      }
      return account;
    });
  }

  accountChanged(): void {
    if (this.selectedAccount) {
      this.agent.accountId = this.selectedAccount.id;
    }
  }
}

nxModule.component('agentsSettlement', {
  templateUrl: templateUrl,
  bindings: {
    agent: '='
  },
  controller: AgentsSettlement
});
