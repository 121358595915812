import nxModule from 'nxModule';
import templateUrl from './pledge-installment.template.html';
import BigNumber from 'bignumber.js';
import moment from "moment";
import _ from "lodash";
import {IOnChangesObject} from "angular";

interface PledgeInstallment {
  depositId: number;
  lineNumber: number;
  amount: number;
  balance: number;
  totalAmountPaid: number;
  dueDate: Date;
  status: string;
  totalDepositAfter: number;
}

class PledgeInstallmentsComponent {

  private installments!: PledgeInstallment[];
  private simulationMode!: boolean;
  private selectedPledgeLineNumber?: number | null;
  private gracePeriodDays!: number;

  constructor() {
    this.selectedPledgeLineNumber = null;
  }

  $onChanges(changes: IOnChangesObject) : void {
    if (changes.installments && this.simulationMode) {
      this.installments.forEach(i => {
        i.totalDepositAfter = this.calculateTotalDeposit(i.lineNumber);
      });
    }
  }

  showPledgeEffectiveDueDate(id: number, $event: Event): void {
    if (!this.gracePeriodDays) {
      return;
    }

    $event.stopPropagation();
    this.selectedPledgeLineNumber = id;
  }

  hidePledgeEffectiveDueDate(): void {
    this.selectedPledgeLineNumber = null;
  }

  calculateTotalDeposit(lineNumber: number) : number {
    return _.sumBy(this.installments, (i) => i.lineNumber <= lineNumber ? i.amount : 0);
  }

  getColor(status: string) : Record<string, boolean> {
    return {
      'red': status === 'DUE' || status === 'OVERDUE',
      'green': status === 'PAID'
    }
  }

  getTotalPledgeAmount() : BigNumber {
    let totalAmount = new BigNumber(0);
    this.installments?.forEach(i => {
      totalAmount = totalAmount.plus(i.amount);
    });
    return totalAmount;
  }

  getTotalBalance() : BigNumber {
    let totalBalance = new BigNumber(0);
    this.installments?.forEach(i => {
      totalBalance = totalBalance.plus(i.balance);
    });
    return totalBalance;
  }

  getEffectiveDueDate(pledgeDate: string) {
    const date = moment(pledgeDate);
    date.add(this.gracePeriodDays, 'days');
    return date;
  }
}

nxModule.component('pledgeInstallments', {
  templateUrl: templateUrl,
  bindings: {
    installments: '<',
    simulationMode: '<',
    gracePeriodDays: '='
  },
  controller: PledgeInstallmentsComponent
});
