import _ from "lodash";
import nxModule from 'nxModule';

import templateUrl from './loan-product-advanced.template.html';
import {cicContractTypes} from 'constants/loan';

class LoanProductAdvancedForm {
  constructor($scope, dict) {
    this.$scope = $scope;
    this.dict = dict;
  }

  $onInit() {
    this.dict.onLoadingComplete(() => {
      this.loanSecurityClean = _.find(this.dict['LOAN_SECURITY'], {code: 'CLN'});
      this.updateLoanSecurityOptions();
    });

    this.$scope.$watch('$ctrl.loanProduct.collateralRequirement', () => this.updateLoanSecurityOptions());

    this.creationTypes = [{
      label: 'New Loan',
      value: 'NEW_LOAN'
    }, {
      label: 'Reloan',
      value: 'RELOAN'
    }, {
      label: 'Restructured',
      value: 'RESTRUCTURED'
    }];

    this.cicContractTypes = [...cicContractTypes];
  }

  updateLoanSecurityOptions() {
    if(!this.dict['LOAN_SECURITY']) {
      return;
    }

    switch (this.loanProduct.collateralRequirement) {
      case 'REQUIRED':
        this.loanSecurityOptions = this.dict['LOAN_SECURITY'].filter(loanSec => loanSec.id !== this.loanSecurityClean.id);
        break;
      case 'OPTIONAL':
        this.loanSecurityOptions = this.dict['LOAN_SECURITY'];
        break;
      case 'DISALLOWED':
      default:
        this.loanSecurityOptions = this.dict['LOAN_SECURITY'].filter(loanSec => loanSec.id === this.loanSecurityClean.id);
    }
  }
}

nxModule.component('loanProductAdvancedForm', {
  templateUrl,
  controller: LoanProductAdvancedForm,
  bindings: {
    loanProduct: '=',
    form: '='
  }
});
