import {CalculationMethod} from 'components/service/fees/fee.types';
import {ProductDefinition} from "./product.types";
import {NonWorkingDayAdjustmentStrategy} from "components/administration/common/product/types/product.types";
import {Letter} from "components/administration/loan/letter-type/letter.types";

export interface LoanType {
  id:                                        number;
  productDefinition:                         ProductDefinition;
  defaultInterestRate:                       number;
  minInterestRate:                           number;
  maxInterestRate:                           number;
  pastDueInterestCharge:                     Charge;
  pastDueInterestApplyOnMaturity:            boolean;
  defaultPastDueToInterestRate:              boolean;
  pastDueMaturityInterestCharge:             Charge;
  pastDueMaturityInterestApplyOn:            AmortizationHook;
  defaultPastDueMaturityToInterestRate:      boolean;
  interestCalculationMethod:                 string;
  withUIDLedger:                             boolean;
  asEarnedInterestCalculation:               boolean;
  asEarnedInterestAccrualPeriod:             AsEarnedInterestAccrualPeriod;
  asEarnedPreterminationInterestCalculation: boolean;
  defaultInterestCalculationParameter:       any;
  amortizationType:                          string;
  minAmount:                                 number;
  maxAmount:                                 number;
  minTerm:                                   number;
  maxTerm:                                   number;
  minTermAdjustment:                         number;
  maxTermAdjustment:                         number;
  paymentIntervalOptions:                    number[];
  paymentDirection:                          string;
  calculateDefaultFirstPaymentDate:          boolean;
  validateMinFirstPaymentDate:               boolean;
  duePaymentHierarchy:                       string[];
  advancePaymentHierarchy:                   string[];
  maxFirstPaymentPostponement:               number;
  backdatingAllowed:                         boolean;
  cycleCounting:                             boolean;
  cycles:                                    number[];
  docStamp:                                  Charge;
  notarialFee:                               Charge;
  applicationFee:                            Charge;
  creditInvestigationFee:                    Charge;
  extraBankFee:                              Charge;
  serviceCharge:                             Charge;
  membershipFee:                             Charge;
  idFee:                                     Charge;
  penaltyApplyOnMaturity:                    ApplyOnMaturityType;
  penaltyMaturity:                           Charge;
  penaltyMaturityApplyOn:                    AmortizationHook;
  penaltyMaturityCollectionType:             ChargeCollectionType;
  insuranceFeeDeductionStrategy:             string;
  insuranceFee:                              Charge;
  insuranceServiceFee:                       Charge;
  insuranceProcessingFee:                    Charge;
  insuranceSavingsAccountId:                 number;
  cbuCharge:                                 Charge;
  pfCharge:                                  Charge;
  tpCharge:                                  Charge;
  individualCbuAccountTypeId:                number;
  individualPfAccountTypeId:                 number;
  individualTpAccountTypeId:                 number;
  corporateCbuAccountTypeId:                 number;
  corporatePfAccountTypeId:                  number;
  corporateTpAccountTypeId:                  number;
  defaultAmortizationNumber:                 number;
  defaultCreationType:                       string;
  defaultClassId:                            number;
  defaultPurposeId:                          number;
  defaultSecurityId:                         number;
  defaultEconomicActivityId:                 number;
  defaultLoanIndustryPurposeId:              number;
  defaultMisGroupId:                         number;
  defaultMicrofinanceClassificationId:       number;
  defaultLoanBorrowerTypeId:                 number;
  defaultLoanTransactionTypeId:              number;
  defaultCicContractType:                    string;
  principalRoundingScale:                    RoundingScale;
  principalRoundingMode:                     RoundingMode;
  interestRoundingScale:                     RoundingScale;
  interestRoundingMode:                      RoundingMode;
  cbuRoundingScale:                          RoundingScale;
  cbuRoundingMode:                           RoundingMode;
  pfRoundingScale:                           RoundingScale;
  pfRoundingMode:                            RoundingMode;
  tpRoundingScale:                           RoundingScale;
  tpRoundingMode:                            RoundingMode;
  totalAmortizationRoundingScale:            RoundingScale;
  totalAmortizationRoundingMode:             RoundingMode;
  docStampRoundingScale:                     RoundingScale;
  docStampRoundingMode:                      RoundingMode;
  notarialFeeRoundingScale:                  RoundingScale;
  notarialFeeRoundingMode:                   RoundingMode;
  applicationFeeRoundingScale:               RoundingScale;
  applicationFeeRoundingMode:                RoundingMode;
  creditInvestigationFeeRoundingScale:       RoundingScale;
  creditInvestigationFeeRoundingMode:        RoundingMode;
  extraBankFeeRoundingScale:                 RoundingScale;
  extraBankFeeRoundingMode:                  RoundingMode;
  membershipFeeRoundingScale:                RoundingScale;
  membershipFeeRoundingMode:                 RoundingMode;
  idFeeRoundingScale:                        RoundingScale;
  idFeeRoundingMode:                         RoundingMode;
  serviceChargeRoundingScale:                RoundingScale;
  serviceChargeRoundingMode:                 RoundingMode;
  insuranceFeeRoundingScale:                 RoundingScale;
  insuranceFeeRoundingMode:                  RoundingMode;
  insuranceServiceFeeRoundingScale:          RoundingScale;
  insuranceServiceFeeRoundingMode:           RoundingMode;
  insuranceProcessingFeeRoundingScale:       RoundingScale;
  insuranceProcessingFeeRoundingMode:        RoundingMode;
  roundingDifferenceTarget:                  string;
  curePeriod:                                number;
  microfinance:                              boolean;
  termCalculationMethod:                     string;
  manualMetadata:                            boolean;
  performanceThresholdDays:                  number;
  contractualSavingsAssignmentStrategy:      string;
  feeDefinitions:                            any[];
  allowAccrual:                              boolean;
  accrualPeriod:                             string;
  collateralRequirement:                     string;
  honorHolidays:                             boolean;
  loanHolidays:                              any[];
  creditLineAvailable:                       boolean;
  allowRenewal:                              boolean;
  renewalMinPaidPrincipalPercentage?:        number;
  allowReconstruction:                       boolean;
  interestType:                              InterestType;
  withLinkedDepositAccount:                  boolean;
  holidayAmortizationAdjustment:             NonWorkingDayAdjustmentStrategy;
  moveDuplicatedAmortizationHoliday:         boolean;
  saturdayAmortizationAdjustment:            NonWorkingDayAdjustmentStrategy;
  moveDuplicatedAmortizationSaturday:        boolean;
  sundayAmortizationAdjustment:              NonWorkingDayAdjustmentStrategy;
  moveDuplicatedAmortizationSunday:          boolean;
  lastPaymentDateAdjustment:                 NonWorkingDayAdjustmentStrategy;
  letters:                                   Letter[];
  useInterestRateBoard:                      boolean;
  interestRateBoard?:                        InterestRateBoard;
  sendEmailDocumentsOnRelease:               boolean;
}

export interface Charge {
  rate: number;
  type: ChargeType;
}

export interface ProductFeeInput {
  rate: number;
  type: CalculationMethod;
}

export type AmortizationHook = 'ALL_AMORTIZATIONS'
  | 'LAST_AMORTIZATION';

export type ApplyOnMaturityType = 'ALL_AMORTIZATIONS'
                                | 'ALL_AMORTIZATIONS_EXCEPT_LAST'
                                | 'NONE';

export type ChargeCollectionType = 'PROPORTIONAL_TO_DAYS_DELAYED'
  | 'ALWAYS_IN_FULL';

export type ChargeType = 'FIXED_AMOUNT'
                       | 'PERCENTAGE_OF_ORIGINAL_INTERESTS'
                       | 'PERCENTAGE_OF_ORIGINAL_PRINCIPAL'
                       | 'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL'
                       | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS'
                       | 'PERCENTAGE_OF_OUTSTANDING_PRINCIPAL'
                       | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS_AND_PRINCIPAL'
                       | 'PERCENTAGE_OF_OUTSTANDING_INTERESTS_PRINCIPAL_AND_PENALTIES'
                       | 'PERCENTAGE_OF_APPLIED_INSTALLMENT_PENALTIES'
                       | 'PERCENTAGE_OF_INSURANCE_FEE'
                       | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS'
                       | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_PRINCIPAL'
                       | 'PERCENTAGE_OF_ORIGINAL_INSTALLMENT_INTERESTS_AND_PRINCIPAL'
                       | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS'
                       | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_PRINCIPAL'
                       | 'PERCENTAGE_OF_OUTSTANDING_INSTALLMENT_INTERESTS_AND_PRINCIPAL';

export type RoundingScale = 'ONE_PESO'
                           |'HALF_PESO'
                           |'CENTAVO';

export type RoundingMode = 'HALF_UP'
                         | 'ROUND_UP'
                         | 'ROUND_DOWN';

export type AsEarnedInterestAccrualPeriod = 'DAILY' | 'MONTH_END';

export enum InterestType {
  ANNUAL_RATE = 'ANNUAL_RATE',
  ADD_ON_RATE = 'ADD_ON_RATE'
}

export enum InterestCalculationMethod {
  SIMPLE = 'SIMPLE',
  COMPOUND = 'COMPOUND'
}


export interface InterestRateBoardCell {
  minTerm?: number;
  maxTerm?: number;
  interestRate?: number;
}

export interface InterestRateBoard {
  allowOverride: boolean;
  cells: InterestRateBoardCell[];
}
