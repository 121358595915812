import React, {ReactElement} from 'react';
import styles from './TreeNodeListWrapper.scss';

export interface TreeNodeListWrapperProps {
  children: ReactElement[]
}

const TreeNodeListWrapper = (props: TreeNodeListWrapperProps): ReactElement => {
  return <ol className={styles.list}>{props.children}</ol>;
};


export default TreeNodeListWrapper;