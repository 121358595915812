import nxModule from 'nxModule';
import templateUrl from './zoomable-picture.template.html';
import './zoomable-picture.style.less'

class ZoomablePictureComponent {
  sourceUrl!: string;
  remarks!: string;
  description!: string;
}

nxModule.component('zoomablePicture', {
  templateUrl,
  bindings: {
    sourceUrl: '<',
    remarks: '<',
    description: '<'
  },
  controller: ZoomablePictureComponent
});