import nxModule from 'nxModule';

import templateUrl from './atm-terminal-branches.template.html';

class AtmTerminalsBranchesController {
}

nxModule.component('atmTerminalBranches', {
  templateUrl,
  controller: AtmTerminalsBranchesController
});
