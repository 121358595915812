import nxModule from 'nxModule';
import BigNumber from 'bignumber.js';
import {Fee, FeeOverride} from 'components/service/product.types';

export interface InterestOverride {
  grossInterest: number,
  withholdingTax: number
}

export interface DepositInterestForecast {
  productId: number,
  interest: number,
  withholdingTax: number,
  netInterest: number;
  interestCredited: number,
  appliedWithholdingTax: number,
  terminationFees: [Fee & {newAmount: number}]
}

export interface Override {
  grossInterest: number,
  withholdingTax: number,
  netInterest: number,
  amount: number
}

export interface TerminateDepositRequest {
  amount: number,
  entryType: string,
  productId: number,
  terminationFeesOverride: FeeOverride[],
  interestOverride: InterestOverride | null
}

export interface Input {
  forecast: DepositInterestForecast;
  override?: Override;
  terminationAmount: number,
  isOverridden: boolean,
  request?: TerminateDepositRequest
}

export class TermDepositOverrideService {
  prepareRequest(input: Input): TerminateDepositRequest {
    let finalRequest = input.request;
    if (!finalRequest) {
      finalRequest = <TerminateDepositRequest>{};
    }

    finalRequest.entryType = 'DEBIT';
    finalRequest.interestOverride = <InterestOverride>{};
    finalRequest.productId = input.forecast.productId;

    const terminationFees = input.forecast.terminationFees.map(f => {
      return {
        feeDefinitionId: f.feeDefinitionId,
        applyOn: f.applyOn,
        applyPredicates: f.applyPredicates,
        paidUpfront: f.paidUpfront,
        amount: f.newAmount
      };
    });

    if (input.isOverridden) {
      finalRequest.amount = (!input.override || new BigNumber(input.terminationAmount).isEqualTo(input.override?.amount)) ? input.terminationAmount : input.override.amount;
      finalRequest.interestOverride.grossInterest = (!input.override || new BigNumber(input.forecast.interest).isEqualTo(input.override.grossInterest)) ? input.forecast.interest : input.override.grossInterest;
      finalRequest.interestOverride.withholdingTax = (!input.override || new BigNumber(input.forecast.withholdingTax).isEqualTo(input.override.withholdingTax)) ? input.forecast.withholdingTax : input.override.withholdingTax;
    } else {
      finalRequest.amount = input.terminationAmount;
      finalRequest.interestOverride = null;
    }
    finalRequest.terminationFeesOverride = terminationFees;
    return finalRequest;
  }
}

nxModule.service('termDepositOverrideService', TermDepositOverrideService);
