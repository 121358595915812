import angular from 'angular-route';
import {BranchService} from 'components/service/branch.service';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import PrintService from 'print/PrintService';
import {NxRouteService} from 'routes/NxRouteService';
import {CommandAccessChecker} from 'shared/utils/command/command.types';
import {HttpService} from 'shared/utils/httpService';
import {
  accessRuleDefinitionResolver,
  accessRuleResolver,
  areaIdResolver,
  branchResolver,
  commandAccessRulesDefResolver,
  commandGroupResolver,
  commandPredicatesResolver,
  printParametersResolver,
  productIdResolver,
  reportFileResolver,
  reportResolver,
  roleResolver,
  userResolver
} from './resolvers';
import {accessCheckByCommand} from './routeUtils';

const printService = new PrintService();
export const adminRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/admin/dynamic-report', {
    template: '<dynamic-report-list/>',
    label: 'Report Mapping'
  }], ['/admin/dynamic-report/:reportId', {
    template: `<dynamic-report report="$resolve.report"/>`,
    resolve: {
      ...reportResolver
    },
    label: 'dynamic-report-label'
  }], ['/admin/dynamic-report/:reportId/file/:reportFileId', {
    template: `<dynamic-report-file report="$resolve.report" report-file="$resolve.reportFile" />`,
    resolve: {
      ...reportResolver,
      ...reportFileResolver,
    },
    label: 'dynamic-report-file-label'
  }], ['/admin/dynamic-report/:reportId/file/:reportFileId/upload-download-mapping', {
    template: `<dynamic-report-mapping-upload report="$resolve.report" report-file="$resolve.reportFile" />`,
    resolve: {
      ...reportResolver,
      ...reportFileResolver
    },
    label: 'Upload/download mapping'
  }], ['/admin/deposit-accounts/reserve-product-numbers', {
    template: '<deposit-account-reservation/>',
    label: 'Reserve product number'
  }], ['/admin/deposit-accounts/types', {
    template: '<deposit-account-list/>',
    label: 'Deposit Account Types'
  }], ['/admin/deposit-accounts/types/:productId', {
    template: '<deposit-account-details product-id="$resolve.productId"/>',
    label: 'deposit-account-label',
    resolve: {
      ...productIdResolver
    }
  }], ['/admin/loan-products/letters', {
    template: '<letter-type-list/>',
    label: 'Loan letter types'
  }], ['/admin/loan-products/intervals', {
    template: '<payment-interval-list/>',
    label: 'Payment intervals'
  }], ['/admin/loan-products/mis-groups', {
    template: '<mis-group-list/>',
    label: 'Mis groups'
  }], ['/admin/loan-products/types', {
    template: '<loan-product-list/>',
    label: 'Loans'
  }], ['/admin/loan-products/types/create', {
    template: '<loan-product-details/>',
    label: 'Create New'
  }], ['/admin/loan-products/types/:productId', {
    template: '<loan-product-details/>',
    label: 'loan-product-label'
  }], ['/admin/loan-products/interval/create', {
    template: '<payment-interval-details edit-mode="false"/>',
    label: 'Loan Payment Interval'
  }], ['/admin/loan-products/interval/edit/:intervalId', {
    template: '<payment-interval-details edit-mode="true"/>',
    label: 'Loan Payment Interval'
  }], ['/admin/term-deposits/types', {
    template: '<term-deposit-list/>',
    label: 'Term deposits'
  }], ['/admin/term-deposits/types/:productId', {
    template: '<term-deposit-details product-id="$resolve.productId"/>',
    label: 'term-deposit-label',
    resolve: {
      ...productIdResolver
    },
    resolveRedirectTo: accessCheckByCommand('UpdateDepositType')
  }], ['/admin/security', {
    template: '<security/>',
    label: 'Security'
  }], ['/admin/security/roles/:roleId', {
    template: '<roles-config/>',
    label: 'role'   // Will be replaced by role name in controller
  }], ['/admin/security/roles/:roleId/access-rules/:group', {
    template: `<grouped-role-write-access-list
                    role-id="$resolve.role.id"
                    command-group="$resolve.commandGroup"
                    access-rules-definition="$resolve.commandAccessRuleDefs"
                 />`,
    label: 'grouped-access-rules-label',
    resolve: {
      ...roleResolver,
      ...commandGroupResolver,
      ...commandAccessRulesDefResolver,
      ...commandPredicatesResolver
    }
  }], ['/admin/security/roles/:roleId/access-rules/:group/new', {
    template: `<new-access-rule
                    command-group="$resolve.commandGroup"
                    access-rules-definition="$resolve.commandAccessRuleDefs"
                    role-id="$resolve.role.id"
                  />`,
    label: 'Add access rule',
    resolve: {
      ...roleResolver,
      ...commandGroupResolver,
      ...commandAccessRulesDefResolver
    }
  }], ['/admin/security/roles/:roleId/access-rules/:group/:ruleId', {
    template: `<role-write-access-details
                    role="$resolve.role"
                    command-group="$resolve.commandGroup"
                    access-rule="$resolve.accessRule"
                    access-rule-definition="$resolve.accessRulesDef"
                    available-predicates="$resolve.predicates"
                  />`,
    label: 'Edit access rule',
    resolve: {
      ...roleResolver,
      ...accessRuleResolver,
      ...commandGroupResolver,
      ...commandPredicatesResolver,
      ...accessRuleDefinitionResolver
    }
  }], ['/admin/logged-in-users', {
    template: '<logged-in-users/>',
    label: 'Logged in users'
  }],
  // profile config
  ['/admin/compliance', {
    template: '<compliance-config/>',
    label: 'Compliance'
  }],
  // organization
  ['/admin/organization', {
    template: '<organization/>',
    label: "Organization"
  }], ['/admin/organization/branches', {
    template: '<organization/>'
  }], ['/admin/organization/branches/create', {
    template: '<branch-details/>',
    label: 'Create new branch'
  }], ['/admin/organization/branches/:branchId', {
    template: '<branch-details/>',
    label: 'Branch details'
  }], ['/admin/organization/areas/create', {
    template: '<area-details/>',
    label: 'Create new area'
  }], ['/admin/organization/areas/:areaId', {
    template: '<area-details area-id="$resolve.areaId"/>',
    label: 'Area details',
    resolve: {
      ...areaIdResolver
    }
  }],
  ['/admin/organization/users/:branchId', {
    template: '<users-list branch="$resolve.branch"/>',
    label: 'users-list-label',
    resolve: {
      ...branchResolver,
    }
  }], ['/admin/organization/users/:branchId/user/:userId', {
    template: `<users-details branches="$resolve.branches"
                                can-execute-command="$resolve.canExecuteCommand"
                                forced-credentials-update="false" />`,
    // Overridden in component
    label: 'users-details-label',
    resolve: {
      branches: /* @ngInject */ (branchService: BranchService): Promise<Branch[]> => branchService.toPromise(),
      canExecuteCommand: /* @ngInject */ (commandAccessChecker: CommandAccessChecker): ReturnType<CommandAccessChecker['canExecuteCommandAsync']> => commandAccessChecker.canExecuteCommandAsync()
    }
  }], ['/admin/organization/users/:branchId/user/:userId/credentials-update', {
    template: `<users-details branches="$resolve.branches"
                                can-execute-command="$resolve.canExecuteCommand"
                                forced-credentials-update="true" />`,
    // Overridden in component
    label: 'users-details-label',
    resolve: {
      branches: /* @ngInject */ (branchService: BranchService): Promise<Branch[]> => branchService.toPromise(),
      canExecuteCommand: /* @ngInject */ (commandAccessChecker: CommandAccessChecker): ReturnType<CommandAccessChecker['canExecuteCommandAsync']> => commandAccessChecker.canExecuteCommandAsync()
    }
  }], ['/admin/organization/users/:branchId/user/:userId/move-to-branch', {
    template: '<users-change-branch user="$resolve.user" branch="$resolve.branch" links="$resolve.links"/>',
    label: 'Move between branches',
    resolve: {
      ...userResolver,
      ...branchResolver,
      links: /* @ngInject */ ($route: NxRouteService): unknown => {
        const {branchId, userId} = $route.current.params;
        return {
          cancel: `/admin/organization/users/${branchId}/user/${userId}`,
          success: `/admin/organization/users/${branchId}`
        };
      }
    }
  }], ['/admin/organization/users/:branchId/user/:userId/mfa', {
    template: '<mfa-configuration-page user="$resolve.user" branch="$resolve.branch" links="$resolve.links"/>',
    label: 'Multi-Factor Authentication Configuration',
    resolve: {
      ...userResolver,
      ...branchResolver,
      links: /* @ngInject */ ($route: NxRouteService): unknown => {
        const {branchId, userId} = $route.current.params;
        return {
          return: `/admin/organization/users/${branchId}/user/${userId}`,
        };
      },
    }
  }], ['/admin/organization/depository-accounts', {
    template: '<organization/>',
    label: "Organization"
  }], ['/admin/organization/depository-accounts/categories', {
    template: '<depository-account-category-list/>',
    label: "Depository account categories"
  }], ['/admin/organization/depository-accounts/create', {
    template: '<depository-account-details/>',
    label: 'Create depository account'
  }], ['/admin/organization/depository-accounts/:accountId/update', {
    template: '<depository-account-details/>'
  }], ['/admin/organization/holidays/:branch', {
    template: '<holiday-page/>',
    // Overridden in component
    label: 'holidays-label'
  }],

  // General Ledger
  ['/admin/general-ledger', {
    template: '<general-ledger/>',
    label: 'General Ledger'
  }], ['/admin/general-ledger/categories/new', {
    template: '<transactions-category-details description="New category" edit-mode="false"/>',
    label: 'Create new category'
  }], ['/admin/general-ledger/categories/:categoryId', {
    template: '<transactions-category-details description="Edit category" edit-mode="true" category="$resolve.category"/>',
    label: 'Edit category',
    resolve: {
      category: /* @ngInject */ (actionCategoryCache: unknown, $route: NxRouteService): Promise<unknown> => {
        const categoryId = $route.current.params['categoryId'];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return actionCategoryCache.toPromise().then(categories => _.find(categories, c => c.id == categoryId));
      }
    }
  }], ['/admin/atm-terminals', {
    template: '<atm-terminal-branches/>',
    label: 'ATM Terminals'
  }], ['/admin/atm-terminals/:branchId', {
    template: '<atm-terminal-list branch="$resolve.branch"/>',
    label: 'atm-terminal-list-label',
    resolve: {
      ...branchResolver,
    }
  }], ['/admin/atm-terminals/:branchId/atm-terminal/create', {
    template: '<atm-terminal-details/>',
    label: 'New ATM terminal'
  }], ['/admin/atm-terminals/:branchId/atm-terminal/:atmId', {
    template: '<atm-terminal-details/>',
    label: 'Edit ATM terminal'
  }], ['/admin/prints', {
    template: `<prints-page header-label="Description:" header-title="Printing">
                    <prints-list non-parametrized-prints="$resolve.nonParametrizedPrints" available-print-parameters="$resolve.availablePrintParameters"/>
                 </prints-page>`,
    label: 'Printing',
    resolve: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nonParametrizedPrints: /* @ngInject */ (printsCache: unknown): Promise<unknown> => printsCache.toPromise()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(allPrints => allPrints.filter(print => {
          const printParameters = _.get(print, 'parameters', {});
          return _.isEmpty(printParameters);
        })),
      availablePrintParameters:  /* @ngInject */ (http: HttpService) => http.get('/print/parameters').toPromise(),
    }
  }], ['/admin/prints/:printCode', {
    template: `<prints-page header-label="Description:" header-title="Print Details">
                    <query-param-guard query-param-filter="$resolve.paramFilter">
                      <prints-details
                        print-parameters="$resolve.printParameters"
                        print="$resolve.print"
                      />
                    </query-param-guard>
                 </prints-page>`,
    label: 'Prints details',
    resolve: {
      ...printParametersResolver,
      print:  /* @ngInject */ async ($route: NxRouteService): Promise<unknown> => {
        const printCode = $route.current.params.printCode;
        const pathParams = $route.current.pathParams;
        const allParams = $route.current.params;
        const printParams = _.omitBy(allParams, (value, key) => key in pathParams);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const print = await printService.getPrint({printCode, printParameters: printParams});

        // no params -> system will pick a default print and we can return it for editing
        if (_.isEmpty(printParams)) {
          return print;
        }

        // system returned default print because it didnt find params,
        // clear out id so that we will create a new print
        if (!_.isEqual(print.parameters, printParams)) {
          return {
            ...print,
            id: null,
            parameters: printParams
          };
        }

        return print;
      }
    }
  }], ['/admin/prints/:printCode/parameters', {
    template: `<prints-page
                      header-label="Description:"
                      header-title="Print per product"
                      >
                  <prints-customization
                    print-parameters="$resolve.printParameters"
                    print-code="$resolve.printCode"
                    base-print="$resolve.basePrint"
                  />
               </prints-page>`,
    label: 'Prints customization',
    resolve: {
      ...printParametersResolver,
      printCode: /* @ngInject */ ($route: NxRouteService): unknown => $route.current.params['printCode'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      basePrint: /* @ngInject */ ($route: NxRouteService, printsCache: unknown): Promise<unknown> => printsCache.toPromise()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(allPrints => allPrints.find(print => {
          if (print.code !== $route.current.params['printCode']) {
            return false;
          }

          return _.isEmpty(_.get(print, 'parameters', {}));
        }))
    }
  }], ['/admin/official-receipt-branches', {
    template: `<official-receipt-booklet-branches/>`,
    label: 'Official Receipt'
  }], ['/admin/official-receipt-branches/:branchId', {
    template: '<official-receipt-booklet-list branch="$resolve.branch"/>',
    label: 'official-receipt-booklet-list-label',
    resolve: {
      ...branchResolver,
    }
  }], ['/admin/official-receipt-branches/:branchId/create', {
    template: '<official-receipt-booklets-create>',
    label: 'Create Official Receipt Booklets'
  }], ['/admin/official-receipt-branches/:branchId/assign/:id', {
    template: '<official-receipt-booklet-assign>',
    label: 'Assign Official Receipt Booklet'
  }]
];
