import {Letter} from 'components/administration/loan/letter-type/letter.types';
import {AccessRuleService} from 'components/service/access/access-rule.service';
import {
  AccessRule,
  CommandAccessRuleDefinition,
  CommandGroup,
  CommandPredicates
} from 'components/service/access/access-rule.types';
import {BranchService} from 'components/service/branch.service';
import {CenterService} from 'components/service/center/center.service';
import {LoanService} from 'components/service/loan.service';
import {Loan} from 'components/service/loan.types';
import RolesCache from 'components/service/roles.cache';
import {UserCache, UserDetails} from 'components/service/users/user.cache';
import {Customer} from 'customer/CustomerTypes';
import _ from 'lodash';
import {Branch} from 'management/BranchTypes';
import {NxRouteService} from 'routes/NxRouteService';
import {HttpService} from 'shared/utils/httpService';
import PermissionService, {PermissionContext} from 'shared/utils/permission.service';
import {Role} from 'user/UserTypes';

export const defaultResolver = (key: string, toNumber: boolean = false): Record<string, ($route: NxRouteService) => unknown> => ({
  [key]: /* @ngInject */ ($route: NxRouteService): unknown => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const resolvedValue = $route.current.params[key];
    return toNumber ? Number(resolvedValue) : resolvedValue;
  }
});

export const checkPermissions = (requiredPermissions: string[], permissionContext?: ($route: NxRouteService) => PermissionContext): (($route: NxRouteService, permission: PermissionService) => Promise<string | undefined>) => {
  return /* @ngInject */ ($route: NxRouteService, permissionService: PermissionService): Promise<string | undefined> =>
    permissionService.hasPermission(requiredPermissions, permissionContext ? permissionContext($route) : undefined)
      .then(hasPermission => hasPermission ? undefined : '/error-page/ACCESS');
};

export const accountIdResolver = defaultResolver('accountId', true);
export const accountTypeIdResolver = defaultResolver('typeId', true);
export const agentIdResolver = defaultResolver('agentId', true);
export const applicationIdResolver = defaultResolver('applicationId');
export const areaIdResolver = defaultResolver('areaId', true);
export const centerIdResolver = defaultResolver('centerId', true);
export const branchIdResolver = defaultResolver('branchId', true);
export const candidacyIdResolver = defaultResolver('candidacyId', true);
export const customerIdResolver = defaultResolver('customerId', true);
export const groupCustomerIdResolver = defaultResolver('groupCustomerId', true);
export const depositIdResolver = defaultResolver('depositId', true);
export const deviceIdResolver = defaultResolver('deviceId', true);
export const groupIdResolver = defaultResolver('groupId', true);
export const groupLoanIdResolver = defaultResolver('groupLoanId');
export const productIdResolver = defaultResolver('productId', true);
export const reportCodeResolver = defaultResolver('reportCode');
export const reportTypeResolver = defaultResolver('reportType');
export const loanIdResolver = defaultResolver('loanId', true);
export const loanTypeIdResolver = defaultResolver('loanTypeId');
export const operationIdResolver = defaultResolver('operationId');
export const typeResolver = defaultResolver('type');
export const checkbookIdResolver = defaultResolver('checkbookId');

export const accessRuleDefinitionResolver = {
  accessRulesDef: /* @ngInject */ async ($route: NxRouteService, accessRuleService: AccessRuleService): Promise<CommandAccessRuleDefinition> => {
    const commandGroup = $route.current.params['group'] as CommandGroup;
    const rule: AccessRule = await accessRuleService.getRuleById(Number($route.current.params['ruleId']));
    const command = rule.command;
    const accessRuleDefs = await  accessRuleService.getCommandAccessRulesDefinition(commandGroup);
    return accessRuleDefs.find(def => def.command === command)!;
  }
};

export const accessRuleResolver = {
  accessRule: /* @ngInject */ ($route: NxRouteService, accessRuleService: AccessRuleService): Promise<AccessRule> => {
    const ruleId: number = Number($route.current.params['ruleId']);
    return accessRuleService.getRuleById(ruleId);
  }
};

export const branchResolver = {
  branch: /* @ngInject */ (branchService: BranchService, $route: NxRouteService): Promise<Branch> => {
    const branchId = $route.current.params['branchId'] as string;
    return branchService.toPromise()
      .then(branches => branches.find(b => String(b.id) === branchId)!);
  }
};

export const commandPredicatesResolver = {
  predicates: /* @ngInject */ ($route: NxRouteService, accessRuleService: AccessRuleService): Promise<CommandPredicates> => {
    const commandGroup: CommandGroup = $route.current.params['group'] as CommandGroup;
    return accessRuleService.getCommandPredicates(commandGroup);
  }
};

export const commandGroupResolver = {
  commandGroup: /* @ngInject */ ($route: NxRouteService): CommandGroup => $route.current.params['group'] as CommandGroup
};

export const commandAccessRulesDefResolver = {
  commandAccessRuleDefs: /* @ngInject */ ($route: NxRouteService, accessRuleService: AccessRuleService): Promise<CommandAccessRuleDefinition[]> => {
    const commandGroup = $route.current.params['group'] as CommandGroup;
    return accessRuleService.getCommandAccessRulesDefinition(commandGroup);
  }
};

export const customerResolver = {
  customer: /* @ngInject */ ($route: NxRouteService, customerCache: unknown): Promise<Customer> => {
    const customerId = $route.current.params['customerId'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return customerCache.profile(customerId).toPromise();
  }
};

export const loanResolver = {
  loan: /* @ngInject */ (loanService: LoanService, $route: NxRouteService): Promise<Loan> => {
    const loanId = $route.current.params['loanId'];
    const customerId = $route.current.params['customerId'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return loanService.getLoanForCustomer(customerId, loanId);
  },
  letters:  /* @ngInject */ ($route: NxRouteService, http: HttpService): Promise<Letter[]> | null => {
    const loanId = $route.current.params['loanId'];
    if (loanId) {
      return http.get<Letter[]>(`/products/loans/${loanId}/letters`).toPromise();
    }
    return null;
  }
};

export const printParametersResolver = {
  printParameters:  /* @ngInject */ ($route: NxRouteService, http: HttpService): Promise<unknown> => {
    const printCode = $route.current.params['printCode'];
    return http.get('/print/parameters').toPromise()
      .then((allPrintParameters: ({ [key: string]: { parameters: any } })) => {
        const foundParameters = Object.entries(allPrintParameters)
          .find(([key, value]) => key === printCode);

        // [key, value] array
        if (!foundParameters) {
          throw new Error(`Cannot find parameters for given print: ${printCode}`);
        }

        return foundParameters[1].parameters;
      });
  }
};
export const productIdFromAccountIdResolver = {
  productId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['accountId'])
};

export const productIdFromDepositIdResolver = {
  productId: /* @ngInject */ ($route: NxRouteService): number => Number($route.current.params['depositId'])
};

export const reportFileResolver = {
  reportFile: /* @ngInject */ (dynamicReportFileService: unknown, $route: NxRouteService): Promise<unknown> => {
    const reportId = $route.current.params['reportId'];
    const fileId = $route.current.params['reportFileId'];
    if (fileId === 'create') {
      return Promise.resolve({
        mapping: [],
        dynamicReportId: reportId
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dynamicReportFileService.getReportFile(reportId, fileId);
  },
};

export const reportResolver = {
  report: /* @ngInject */ (dynamicReportService: unknown, $route: NxRouteService): Promise<unknown> => {
    const reportId = $route.current.params['reportId'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return reportId === 'create' ? Promise.resolve({ files: [] }) : dynamicReportService.getReport(reportId);
  },
};

export const roleResolver = {
  role: /* @ngInject */ ($route: NxRouteService, rolesCache: RolesCache): Promise<Role> => {
    const roleId: number = Number($route.current.params['roleId']);
    return rolesCache.toPromise()
      .then(roles => roles.find(role => role.id === roleId)!);
  }
};

export const userResolver = {
  user: /* @ngInject */ (userCache: UserCache, $route: NxRouteService): Promise<UserDetails> => {
    return userCache.withParam().toPromise()
      .then(users => _.find(users, u => u.id === Number($route.current.params['userId']))!);
  }
};

export const centerResolver = {
  center: /* @ngInject */ ($route: NxRouteService, centerService: CenterService): null | Promise<unknown> => {
    const centerId = $route.current.params['centerId'] as string;
    if (centerId) {
      return centerService.fetchCenter(Number(centerId));
    }
    return null;
  }
};
