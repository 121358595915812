export default {
  'general-ledger': {
    type: 'GENERAL_LEDGER',
    label: 'General ledger reports'
  },
  'deposit-accounts': {
    type: 'DEPOSIT_ACCOUNTS',
    label: 'Deposit accounts reports'
  },
  'term-deposits': {
    type: 'TERM_DEPOSITS',
    label: 'Term deposits reports'
  },
  'loans': {
    type: 'LOANS',
    label: 'Loans reports'
  },
  'other': {
    type: 'OTHER',
    label: 'Other reports'
  },
  'regulatory': {
    type: 'REGULATORY',
    label: 'Regulatory reports'
  },
  'dynamic': {
    type: 'DYNAMIC',
    label: 'Dynamic reports'
  },
  'security': {
    type: 'SECURITY',
    label: 'Security reports'
  },
  'customer-statements': {
    type: 'CUSTOMER_STATEMENTS',
    label: 'Statements reports'
  },
  'agent-banking': {
    type: 'AGENT',
    label: 'Agent banking reports'
  }
}