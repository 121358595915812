import nxModule from 'nxModule';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {StatusService} from "../status/status.service";
import CmcService from "../../../../react/system/CmcService";


class CmcUpdateService {

  constructor(statusService: StatusService, private cmcService: CmcService) {
    statusService.getStatusObs()
      .subscribe(status => {
        const cmcToken = status.cmc;
        if(!cmcToken) {
          return;
        }

        const decodedToken = jwtDecode<JwtPayload>(cmcToken);
        const newestChangedCmcIssuedAt = decodedToken.iat;
        const storedCmc = cmcService.getCmcToken();
        if(storedCmc === null) {
          this.updateCmc(cmcToken);
          return;
        }

        const storedToken = jwtDecode<JwtPayload>(storedCmc);
        const currentChangedCmcIssuedAt = storedToken.iat;
        if(!currentChangedCmcIssuedAt) {
          this.updateCmc(cmcToken);
          return;
        }

        if(newestChangedCmcIssuedAt && newestChangedCmcIssuedAt > currentChangedCmcIssuedAt) {
          this.updateCmc(cmcToken);
          return;
        }
      });
  }

  updateCmc(newToken: string): void {
    this.cmcService.setCmcToken(newToken);
    console.info('Updated cmc', newToken);
  }
}

nxModule.service('cmcUpdateService', CmcUpdateService);