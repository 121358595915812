import {
  NxAutocompleteDataProviderOutput,
  NxButtonVariant,
  NxCashInput,
  NxInput,
  NxFormik,
  NxFormikAutocomplete,
  NxFormikInput,
  NxFormikMultiSelect,
  NxFormikSubmitButton,
  NxLoader,
  NxRow,
  NxRowPosition,
  NxStack
} from '@nextbank/ui-components';
import tokenStore from 'authentication/tokenStore';
import axios from 'axios';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import _ from 'lodash';
import {useCollection as useBranchCollection} from 'management/BranchService';
import statusService from 'management/statusService';
import HttpErrorDisplay from 'misc-transaction/encash-cashiers-check/HttpErrorHandler';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement, useEffect} from 'react';
import {useHistory} from 'react-router';
import {PageResult} from 'tools/HttpTypes';
import * as Yup from 'yup';
import {SchemaOf} from 'yup';

interface EncashCashiersCheckProps {
  isInterBranch: boolean;
}

interface CashierCheck {
  id:                 number;
  checkType:          string;
  checkClearingGroup: string;
  status:             string;
  branchId:           number;
  postDated:          boolean;
  validFrom:          string;
  amount:             number;
  number:             string;
  bankId:             number;
  incoming:           boolean;
  registeredOn:       string;
  remarks:            string;
  targetBranchIds:    number[];
  purpose:            string;
  issuedBy:           number;
  issuedOn:           string;
  hookType:           string;
  hookId:             number;
}

interface FormInput {
  sourceBranchIds: number[];
  check: CashierCheck;
  remarks: string;
}

interface CommandInput {
    checkId: number;
    remarks: string;
}

const EncashCashierCheckFormSchema: SchemaOf<Partial<FormInput>> = Yup.object().shape({
  sourceBranchIds: Yup.array().optional(),
  check: Yup.mixed().required("Check is required"),
  remarks: Yup.string().required()
});

const InterbranchEncashCashierCheckFormSchema: SchemaOf<Partial<FormInput>> = Yup.object().shape({
  sourceBranchIds: Yup.array()
    .required("Source Branch is required")
    .min(1),
  check: Yup.mixed().required("Check is required"),
  remarks: Yup.string().required()
});

const readCheckPurposeLabel = (purpose: string | undefined): string | undefined => {
  if (!purpose) return '';

  return {
    'REGULAR_WITHDRAWAL': 'Withdrawal',
    'INTEREST_WITHDRAWAL': 'Interest withdrawal',
    'PRINCIPAL_WITHDRAWAL': 'Principal withdrawal',
    'LOAN_RELEASE': 'Loan release'
  }[purpose];
}

const EncashCashiersCheck = (props: EncashCashiersCheckProps): ReactElement => {
  const history = useHistory();
  const execute = useCommand();

  const [{data : branches, loading: branchesLoading, error: branchesError}, fetchBranches] = useBranchCollection({manual: true});

  useEffect(() => {
    if(props.isInterBranch){
      fetchBranches();
    }
  }, [props.isInterBranch, fetchBranches]);

  const pageHeader = props.isInterBranch ? 'Encash interbranch cashier\'s check' : 'Encash cashier\'s check';

  if (branchesError) {
    return <HttpErrorDisplay error={branchesError} pageHeader={pageHeader}/>
  }

  if (props.isInterBranch && (branchesLoading || !branches)) {
    return <NxPage><NxHeader>{pageHeader}</NxHeader><NxLoader /></NxPage>;
  }

  return <NxPage>
    <NxHeader>{pageHeader}</NxHeader>
    <NxFormik<Partial<FormInput>>
      initialValues={{
          remarks: ''
      }}
      validationSchema={props.isInterBranch ? InterbranchEncashCashierCheckFormSchema : EncashCashierCheckFormSchema}
      onSubmit={async (input: FormInput): Promise<void> => {
        const command = props.isInterBranch ? 'MiscInterbranchEncashCashiersCheck': 'MiscEncashCashiersCheck';

        const response: CommandOutputWrapper<void> = await execute<CommandInput, void>({
          name: command,
          input: {
            checkId: input.check!.id,
            remarks: input.remarks
          }
        });

        await statusService.refresh();

        if(!response.approvalRequired){
          history.goBack();
        }
      }}
    >
      {({isSubmitting, isValid, values}): ReactElement => {
        //Fetching Cashier Check Scenario
        // 1. Encash Cashier check, we fetch the cashier check using 2 api calls
        //   1.1 fetch non interbranch check on the selected branch
        //   1.2 fetch interbranch check on the selected branch excluding LOAN RELEASE checks
        // 2. Encash Interbranch Cashier check
        //   2.1 fetch all interbranch check regardless of the branch excluding LOAN RELEASE checks
        const fetchChecks = async(text: string): Promise<NxAutocompleteDataProviderOutput<CashierCheck>> => {
          if(text == null || text.trim().length < 1){
            return {values: []};
          }

          let branchIdsParam;
          if(props.isInterBranch) {
            branchIdsParam = {};
          } else if(values.sourceBranchIds?.length === 0) {
            branchIdsParam = {branchIds: -1};
          } else if(values.sourceBranchIds?.[0]){
            branchIdsParam = {};
          } else {
            branchIdsParam = {branchIds: values.sourceBranchIds};
          }

          // LOAN_RELEASE are excluded for interbranch
          const {data: cashierChecks} = await axios.get<PageResult<CashierCheck>>(`/checks/cashiers`, {
            params: {
              interbranchChecks: props.isInterBranch ? 'TRUE' : 'FALSE',
              excludePurpose: props.isInterBranch ? ['LOAN_RELEASE'] : null,
              status: 'ISSUED',
              pageSize: 50,
              targetBranchId: !props.isInterBranch ? tokenStore.getTokenDetails().userBranchId : null,
              ...branchIdsParam,
              numberWildcard: text,
            }
          });

          let pendingChecks = cashierChecks.result;

          if(!props.isInterBranch) {
            // for non-interbranch, interbranch cc with purpose of LOAN_RELEASE are fetched separately and then merged to the issued CCs for non-interbranch.
            const {data: nonInterbranchChecks} = await axios.get<PageResult<CashierCheck>>(`/checks/cashiers`, {
            params: {
              purpose: 'LOAN_RELEASE',
              status: 'ISSUED',
              interbranchChecks: true,
              pageSize: 50,
              branchIds: [tokenStore.getTokenDetails().userBranchId],
              numberWildcard: text,
            }});
            pendingChecks.push(...nonInterbranchChecks.result);
            pendingChecks = _.orderBy(pendingChecks, 'issuedOn', 'desc');
         }

          return {
            values: pendingChecks.map(result => {
                return {
                    ...result,
                    id: result.id
                };
            })
          };
        }

        return <NxForm>
          <NxStack>
            {props.isInterBranch && branches &&
              <NxFormikMultiSelect
                name='sourceBranchIds'
                label='Source Branch'
                dataTest={{base: 'sourceBranch', menuLabel: 'branchName', menu: 'branchList', input: 'cbBranchSelected'}}
                options={branches.map(b => ({
                  label: b.name,
                  value: b.id
                }))}/>
            }

            <NxFormikAutocomplete
              name='check'
              label='Check Number'
              dataProvider={fetchChecks}
              labelProvider={(check: CashierCheck): string => check.number}/>

            <NxCashInput
              name='totalAmount'
              label='Total Amount'
              value= {values.check?.amount}
              disabled/>

            <NxInput
              name='issuedOn'
              label="Issued on"
              value={values.check?.issuedOn.toString()}
              disabled />

            <NxInput
              name='purpose'
              label="Purpose"
              value={readCheckPurposeLabel(values.check?.purpose)}
              disabled />

            <NxInput
              name='cashierRemarks'
              label="Cashier remarks"
              value={values.check?.remarks}
              disabled />

            <NxFormikInput
              name='remarks'
              label="Remarks"/>

            <NxRow position={NxRowPosition.END}>
              <NxCancelButton dataTest='buttonCancel' />
              <NxFormikSubmitButton
                dataTest='buttonSave'
                variant={NxButtonVariant.SAVE}>
                  Ok
              </NxFormikSubmitButton>
            </NxRow>
          </NxStack>
        </NxForm>
      }}
    </NxFormik>
  </NxPage>;
};

export default EncashCashiersCheck;