import React, {ReactElement} from "react";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import {useHistory, useParams} from "react-router";
import useAxios from "axios-hooks";
import {
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikInput,
  NxFormikSubmitButton,
  NxGap,
  NxInput,
  NxLoader,
  NxRow,
  NxStack
} from "@nextbank/ui-components";
import {Check} from "components/service/check.type";
import * as Yup from "yup";
import NxForm from "form/NxForm";
import Alert from "alert/Alert";
import {CommandOutputWrapper} from "command/CommandTypes";
import {useCommand} from "command/CommandService";
import NxCancelButton from 'NxCancelButton';

interface FormInput {
  newCheckNumber?: string;
}

interface UpdateCheckNumberInput {
  checkId: number;
  newCheckNumber: string;
}

const UpdateCheckNumberFormSchema: Yup.SchemaOf<FormInput> = Yup.object().shape({
  newCheckNumber: Yup.string()
    .required()
    .label("New check number")
});

const UpdateCheckNumber = (): ReactElement => {
  const {checkId} = useParams<{checkId: string}>();
  const [{data: check}] = useAxios<Check>(`/checks/${checkId}`);
  const execute = useCommand();
  const history = useHistory();

  if (!check) {
    return <NxPage>
      <NxHeader>Update Check Number</NxHeader>
      <NxLoader/>
    </NxPage>;
  }

  return <NxPage>
    <NxHeader>Update Check Number</NxHeader>
    <NxFormik<FormInput>
      initialValues={{}}
      validationSchema={UpdateCheckNumberFormSchema}
      onSubmit={async (input: FormInput): Promise<void> => {
        if (!input.newCheckNumber) {
          return;
        }
        const response: CommandOutputWrapper<void> = await execute<UpdateCheckNumberInput, void>({
          name: 'UpdateCheckNumber',
          input: {
            checkId: check.id,
            newCheckNumber: (input.newCheckNumber)
          }
        });

        if (!response.approvalRequired) {
          history.goBack();
        }
      }}>
      {({submitForm}): ReactElement => {
        return <NxForm>
          <NxStack>
            <NxInput label="Current check number" value={check?.number} disabled />
            <NxFormikInput label="New check number" name="newCheckNumber" />
            <Alert severity="info">
              The new number must be issued from the same account. It can be from the same or different checkbook.
            </Alert>
            <NxRow>
              <NxCancelButton />
              <NxFormikSubmitButton
                onClick={submitForm}>
                Confirm
              </NxFormikSubmitButton>
            </NxRow>
          </NxStack>
        </NxForm>
      }}
    </NxFormik>
  </NxPage>
}

export default UpdateCheckNumber;
