import {NxTable, NxTableColumn, NxTableColumnAlignment, NxTableColumnType} from '@nextbank/ui-components';
import {Loan} from 'components/service/loan.types';
import React, {ReactElement} from 'react';
import CurrencyFormatter from 'tools/CurrencyFormatter';
import DateFormatter from 'tools/DateFormatter';

interface AmortizationScheduleProps {
  loan: Loan,
  showOutstandingPrincipal: boolean
}

interface AmortizationRow {
  lineNumber: string;
  dueDate: string;
  principalAmount: string;
  interestAmount: string;
  cbuChargeAmount: string;
  pfChargeAmount: string;
  tpChargeAmount: string;
  customFeesAmount: string;
  amortizationAmount: string;
  loanOutstandingBalance: string;
}

const columns: NxTableColumn<AmortizationRow>[] = [{
  title: "No",
  field: "lineNumber",
  type: NxTableColumnType.NUMERIC,
  align: NxTableColumnAlignment.LEFT
}, {
  title: "Due date",
  field: "dueDate"
}, {
  title: "Principal",
  field: "principalAmount",
}, {
  title: "Interests",
  field: "interestAmount",
}, {
  title: "CBU",
  field: "cbuChargeAmount",
}, {
  title: "PF",
  field: "pfChargeAmount",
}, {
  title: "TP",
  field: "tpChargeAmount",
}, {
  title: "Custom fees",
  field: "customFeesAmount",
}, {
  title: "Total",
  field: "amortizationAmount",
}, {
  title: "Outstanding balance",
  field: "loanOutstandingBalance",
}];

const AmortizationSchedulePreview = (props: AmortizationScheduleProps): ReactElement => {
  const currencyFormatter = new CurrencyFormatter();
  const dateFormatter = new DateFormatter();
  const mappedAmortizations : AmortizationRow[] = props.loan.amortizationSchedule.list.map(a => {
    return {
    'lineNumber': a.lineNumber.toString(),
    'dueDate': dateFormatter.prettyDateWithDay(a.dueDate),
    'principalAmount': props.showOutstandingPrincipal ? currencyFormatter.format(a.principalBalance) + " / " + currencyFormatter.format(a.principalAmount) : currencyFormatter.format(a.principalAmount),
    'interestAmount': currencyFormatter.format(a.interestAmount),
    'cbuChargeAmount': currencyFormatter.format(a.cbuChargeAmount, true),
    'pfChargeAmount': currencyFormatter.format(a.pfChargeAmount, true),
    'tpChargeAmount': currencyFormatter.format(a.tpChargeAmount, true),
    'customFeesAmount': currencyFormatter.format(a.customFeesAmount),
    'amortizationAmount': currencyFormatter.format(a.amortizationAmount),
    'loanOutstandingBalance': currencyFormatter.format(a.loanOutstandingBalance)
    }
  });
  mappedAmortizations.push({
    'lineNumber': 'Total:',
    'dueDate': '',
    'principalAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.principal),
    'interestAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.interest),
    'cbuChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.cbuCharge, true),
    'pfChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.pfCharge, true),
    'tpChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.tpCharge, true),
    'customFeesAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.customFees),
    'amortizationAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalAmount?.total),
    'loanOutstandingBalance': ''
  }, {
    'lineNumber': 'Outstanding:',
    'dueDate': '',
    'principalAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.principal),
    'interestAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.interest),
    'cbuChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.cbuCharge, true),
    'pfChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.pfCharge, true),
    'tpChargeAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.tpCharge, true),
    'customFeesAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.customFees),
    'amortizationAmount': currencyFormatter.format(props.loan.amortizationSchedule.totalBalance?.total),
    'loanOutstandingBalance': ''
  });

  return <>
    <NxTable
      columns={columns}
      data={mappedAmortizations}
      sortable={false}
    />
  </>;
};

export default AmortizationSchedulePreview