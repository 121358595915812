import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './batch-register-inward-check.template.html';
import {NxIFilterService} from "components/technical/angular-filters";
import {CommandService} from "shared/utils/command/command.types";
import {ILocationService} from "angular";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import Popup from "shared/common/popup";
import {AttachedFile} from "components/service/file.service";

type FileFormat = 'BDO' | 'ROBINSONS_SAVINGS_BANK' | 'RCBC' | 'LBP' | 'BPI' | 'PCHC_RURAL_BANK' | 'PCHC_DIRECT_CLEARING';

interface File {
  format: FileFormat;
  performClearing: boolean;
  fileId: number;
}

class BatchRegisterInwardCheck {
  clearingFile: AttachedFile[] = [];

  formats: { value: FileFormat, description: string }[] = [
    {value: 'BDO', description: 'BDO'},
    {value: 'ROBINSONS_SAVINGS_BANK', description: 'Robinsons Savings Bank'},
    {value: 'RCBC', description: 'RCBC'},
    {value: 'LBP', description: 'Land Bank of the Philippines'},
    {value: 'BPI', description: 'Bank of the Philippine Islands'},
    {value: 'PCHC_RURAL_BANK', description: 'PCHC-Rural Bank'},
    {value: 'PCHC_DIRECT_CLEARING', description: 'PCHC-Direct Clearing'}
  ];

  input: (Omit<File, 'fileId'> & {fileId?: number}) = {
    format: 'BDO',
    performClearing: false,
  };

  constructor(private readonly $filter: NxIFilterService,
              private readonly $location: ILocationService,
              private readonly command: CommandService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly popup: Popup) {
  }

  async postChecks(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to upload inward clearing file?`,
      details: [
        {label: 'File format', description: this.input.format},
        {label: 'Perform clearing', description: this.$filter('prettyBoolean')(this.input.performClearing)},
      ]
    });

    if (!confirmed) {
      return;
    }

    this.input.fileId = this.clearingFile[0].id;
    const executed = await this.command.execute<unknown, {registrationErrors: Record<string, string>}>('BatchRegisterInwardCheck', this.input).toPromise();

    if (!executed) {
      return;
    }

    const {registrationErrors} = executed.output;
    if (!_.isEmpty(registrationErrors)) {
      let errorDetails = '<p><span class="red"><strong>WARNING!</strong> Checks listed below resulted in errors and will not be cleared:</span></p>';
      _.forOwn(registrationErrors, (errMessage, checkNumber) => {
        errorDetails += `<strong>${checkNumber}</strong>: ${errMessage}<br>`
      });

      await this.popup({
        header: 'Upload result',
        text: errorDetails,
        renderHtml: true
      });
    }

    this.redirectBack();
  }

  redirectBack(): void {
    this.$location.path('/inventory/checks');
  }
}

nxModule.component('batchRegisterInwardCheck', {
  templateUrl: templateUrl,
  controller: BatchRegisterInwardCheck
});
