import InspireTree from 'inspire-tree';
import InspireTreeDOM from 'inspire-tree-dom';
import 'inspire-tree-dom/dist/inspire-tree-light.min.css';

import nxModule from 'nxModule';
import _ from 'lodash';

import './ent.style.less';
import templateUrl from './ent.template.html';

class Ent {
  constructor($element, $scope) {
    this.$element = $element;
    this.treeVisible = false;
    this.selectedNode = null; // has node, not value of ngModel
    this.$scope = $scope;
    this.editable = true;
  }

  nodeVisibilityOpt({node}) {
    if(!this.nodeVisibility) {
      return () => true; // by default show all nodes
    }

    return this.nodeVisibility({node});
  }

  $onInit() {
    this.treeVisible = this.onlyTree;
    this.tree = new InspireTree({
      selection: {
        disableDirectDeselection: !this.editable,
      },
      editable: this.editable,
    });
  }

  normalizedTreeModel() {
    const prepareTreeNode = sourceNode => ({
      id: sourceNode.id,
      text: this.nodeLabel ? this.nodeLabel(sourceNode) : sourceNode[this.colDefs[0].field],
      children: (sourceNode.children || [])
        .filter(node => this.nodeVisibilityOpt({node}))
        .map(prepareTreeNode),
      originalData: sourceNode,
      itree: {
        state: {
          selectable: this.selectOnlyLeaves ? (sourceNode.children || []).length === 0 : true,
        },
      }
    });

    return Object.values(this.treeData || [])
      .filter(node => this.nodeVisibilityOpt({node}))
      .map(prepareTreeNode);
  }

  changeValue() {
    this.treeVisible = !this.treeVisible;
    if(this.treeVisible) {
      this.selectModelValue();
    }
  }

  onSearchChanged() {
    if(this.searchInput) {
      this.tree.search(this.searchInput);
      return;
    }

    this.tree.clearSearch();
  }

  modelValue() {
    return this.ngModel.$modelValue;
  }

  clearSelection() {
    const externalNode = this.modelValue();
    if(externalNode) {
      const node = this.tree.node(externalNode.id);
      node.deselect();
    }
    this.triggerNgModelUpdate(null);
  }

  selectModelValue() {
    const externalNode = this.modelValue();
    if(externalNode) {
      const node = this.tree.node(externalNode.id);
      const selectionEvents = ['node.selected', 'node.deselected'];
      this.tree.mute(selectionEvents);

      node.expandParents();
      node.select();

      this.selectedNode = node;

      this.tree.mute([]);
    }
  }


  setValidity(node, valid) {
    if (valid) {
      node.itree.a.attributes = {};
    } else {
      node.itree.a.attributes = {"class" : "ng-invalid"};
    }
    node.select();
  };

  updateValidity(treeNode) {
    //check if parent don't have this node duplicate
    let valid = true;
    let children = [];
    if (treeNode.itree.parent) {
      children = treeNode.itree.parent.children;
    } else {
      children = this.tree.model || [];
    }
    const childrenValues = {};
    for(let treeNodeChild of children) {
      const count = childrenValues[treeNodeChild.text] || 0;
      childrenValues[treeNodeChild.text] = count + 1;
    }

    let atLeastOneInvalid = false;
    //mark each of the child as valid or invalid based on the number of text occurance
    children.forEach(c => {
      const count = childrenValues[c.text];
      const valid = count === 1;
      if (!atLeastOneInvalid && !valid) {
        atLeastOneInvalid = true;
      }
      this.setValidity(c, valid);
    });
    this.ngModel.$setValidity("validChildDuplicates", !atLeastOneInvalid);
    //workaround to force the itree-dom to refresh after updating itree.X.attributes
    //the removed node doesn't have a select function which make sanse
    //and we don't won't to select removed one
    if (treeNode.select) treeNode.select();
  }


  triggerNgModelUpdate(newNode) {
    this.ngModel.$setTouched();
    this.ngModel.$setViewValue(newNode ? newNode.originalData : null);
    this.selectedNode = newNode;
  };

  $onChanges(changes) {
    const previousTreeData = _.get(changes, 'treeData.previousValue', {});
    const currentTreeData = _.get(changes, 'treeData.currentValue', {});
    // here I'm trying to defer initialization of a tree as its rendering engine is asynchronous
    if(changes.treeData && changes.treeData.isFirstChange() && !_.isEmpty(currentTreeData)) {
      return;
    }

    // I'm explicitly checking for emptiness as two instances of empty objects
    // using different constructors may not be equal
    if(_.isEmpty(previousTreeData) && _.isEmpty(currentTreeData)) {
      return true;
    }

    if(!_.isEqual(previousTreeData, currentTreeData)) {
      this.tree.load(this.normalizedTreeModel());
    }

  }

  $postLink() {
    this.$element.click(_evt => this.ngModel.$setTouched());

    this.ngModel.$render = () => {
      const externalValue = this.modelValue();
      if(externalValue) {
        this.selectedNode = this.tree.node(externalValue.id);
        return;
      }

      this.selectedNode = null;
    };

    const root = this.$element.find('.ent__tree-element');
    new InspireTreeDOM(this.tree, {
      target: root.get(0),
    });

    const nodeTextUpdater = (treeNode, newValue) => {
      Object.assign(treeNode.originalData, newValue);
      treeNode.set('text', this.nodeLabel(newValue));
      treeNode.toggleEditing();
      treeNode.markDirty();
      this.tree.applyChanges();
    };

    this.tree.on('node.edited', (node, oldValue, newValue) => {
      this.$scope.$apply(() => {
        this.updateValidity(node);
        this.nodeEdited({
          node: node.originalData,
          oldValue: oldValue,
          newValue: newValue
        });
      });
    });

    this.tree.on('node.state.changed', (node, property, oldValue, newValue) => {
      if(property !== 'editing') {
        return;
      }

      const editingEnabled = newValue;
      if(!editingEnabled) {
        return;
      }

      if(this.nodeEditingStarted) {
        this.nodeEditingStarted({
          node: node.originalData,
          updateNode: newValue => nodeTextUpdater(node, newValue),
          cancelEdit: () => node.toggleEditing()
        });
      }
    });

    this.tree.on('node.added', (node) => {
      this.$scope.$apply(() => {
        this.updateValidity(node);
        let parent = null;
        if(node.itree.parent) {
          parent = node.itree.parent.originalData;
        }
        node.originalData = this.nodeAdded({
          parent: parent,
          text: node.text,
        });

        if(this.nodeEditingStarted) {
          this.nodeEditingStarted({
            node: node.originalData,
            updateNode: newValue => nodeTextUpdater(node, newValue),
            cancelEdit: () => node.toggleEditing()
          });
        }
      });
    });

    this.tree.on('node.removed', (node, nodeParent) => {
      this.$scope.$apply(() => {
        this.updateValidity(node);
        let parent = null;
        if (nodeParent) {
          parent = nodeParent.originalData;
        }
        this.nodeRemoved({
          parent: parent,
          removedNode: node.originalData
        });
      });
    });

    this.tree.on('node.selected', (node, _isLoadEvent) => {
      if(!this.editable) {
        this.$scope.$apply(() => {
          this.triggerNgModelUpdate(node);
          this.treeVisible = false;
          this.searchInput = "";
          this.tree.clearSearch();
        });
      }
    });

    this.tree.on('model.loaded', _nodes => {
      if(this.editable) {
        this.tree.expandDeep();
      } else {
        this.selectModelValue();
      }
    });

    if(this.treeData) {
      this.tree.load(this.normalizedTreeModel());
    }
  }
}


nxModule.component('ent', {
  templateUrl,
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    treeData: '<', // [{id: 'a', children: []}, {id: 'b', children: []}]
    nodeLabel: '<', // to specify label for a tree node, pass there a function. It will receive node from treeData as an argument.
    selectOnlyLeaves: '<',
    search: '<', // enables search, by default disabled
    editable: '<', // enables editable, by default disabled,
    onlyTree: '<', // true to show also options to clear and edit selected value
    nodeVisibility: '<', // nodeVisibility(node) - control if node and his children shold be visble or not
    nodeEdited: '&', // nodeEdited(node, oldValue, newValue)
    nodeEditingStarted: '&', // nodeEditingStarted(node, updateNode(node), cancelEdit)
    nodeAdded: '&', // nodeAdded(parent, text)
    nodeRemoved: '&', // nodeRemoved(parent, removedNode)
    ngDisabled: '<'
  },
  controller: Ent
});
