import {
  ComplianceRedFlagConfigPerCustomerType,
  RED_FLAG_TAG_TYPE
} from 'components/administration/compliance/compliance-red-flag-config-types';
import {ComplianceRedFlagConfigCache} from 'components/administration/compliance/service/compliance-red-flag-config.cache';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import templateUrl from './compliance-config.template.html';
import {HttpService} from 'shared/utils/httpService';
import {ComplianceConfigCache} from 'components/administration/compliance/service/compliance-config.cache';
import {CommandService} from 'shared/utils/command/command.types';
import {Dict} from 'shared/common/dict.types';
import _ from 'lodash';
import ConfirmationTemplate from 'shared/common/confirmationTemplate';

const NON_COMMENSURATE: RED_FLAG_TAG_TYPE = 'NON_COMMENSURATE';
const VOLUMINOUS_AGGREGATE_TRANSACTION: RED_FLAG_TAG_TYPE = 'VOLUMINOUS_AGGREGATE_TRANSACTION';
const CUSTOMER_RISK_LEVEL: string = 'CUSTOMER_RISK_LEVEL';

export type ComplianceConfigType = {
  lowRiskMonthSchedule: number;
  normalRiskMonthSchedule: number;
  highRiskMonthSchedule: number;
  unusualTransactionAmountThreshold: number;
  individualDailyMultipleTransactionThreshold: number;
  bdaDailyMultipleTransactionThreshold: number;
  restrictedDailyMultipleTransactionThreshold: number;
  corporateDailyMultipleTransactionThreshold: number;
  shortTermAccountThreshold: number;
  shortTermDepositThreshold: number;
}

class ComplianceConfig {

  private complianceConfig!: ComplianceConfigType;
  private nonCommensurateConfig!: ComplianceRedFlagConfigPerCustomerType;
  private voluminousAggregateTransactionConfig!: ComplianceRedFlagConfigPerCustomerType;
  private riskLevelIdLow!: number;
  private riskLevelIdNormal!: number;
  private riskLevelIdHigh!: number;

  constructor(private command: CommandService,
              private dict: Dict,
              private http: HttpService,
              private complianceConfigCache: ComplianceConfigCache,
              private complianceRedFlagConfigCache: ComplianceRedFlagConfigCache,
              private confirmationTemplate: ConfirmationTemplate,
              private $route: NxRouteService
  ) {
  }

  async $onInit(): Promise<void> {
    const [complianceConfigCache, complianceRedFlagConfigCache] = await Promise.all([
      this.complianceConfigCache.toPromise(),
      this.complianceRedFlagConfigCache.toPromise(),
      this.dict.onLoadingCompleteAsync()
    ]);

    this.riskLevelIdLow = this.dict.getId(CUSTOMER_RISK_LEVEL, 'LOW');
    this.riskLevelIdNormal = this.dict.getId(CUSTOMER_RISK_LEVEL, 'NORMAL');
    this.riskLevelIdHigh = this.dict.getId(CUSTOMER_RISK_LEVEL, 'HIGH');
    const nonCommensurate = complianceRedFlagConfigCache.filter(c => c.redFlagTagType === NON_COMMENSURATE);
    const voluminuosAggregateTransaction = complianceRedFlagConfigCache.filter(c => c.redFlagTagType === VOLUMINOUS_AGGREGATE_TRANSACTION);

    this.complianceConfig = {
      lowRiskMonthSchedule: complianceConfigCache.forceUpdateSchedule[this.riskLevelIdLow],
      normalRiskMonthSchedule: complianceConfigCache.forceUpdateSchedule[this.riskLevelIdNormal],
      highRiskMonthSchedule:  complianceConfigCache.forceUpdateSchedule[this.riskLevelIdHigh],
      unusualTransactionAmountThreshold: complianceConfigCache.unusualTransactionAmountThreshold,
      individualDailyMultipleTransactionThreshold: complianceConfigCache.individualDailyMultipleTransactionThreshold,
      bdaDailyMultipleTransactionThreshold: complianceConfigCache.bdaDailyMultipleTransactionThreshold,
      restrictedDailyMultipleTransactionThreshold: complianceConfigCache.restrictedDailyMultipleTransactionThreshold,
      corporateDailyMultipleTransactionThreshold: complianceConfigCache.corporateDailyMultipleTransactionThreshold,
      shortTermAccountThreshold: complianceConfigCache.shortTermAccountThreshold,
      shortTermDepositThreshold: complianceConfigCache.shortTermDepositThreshold
    };

    if (nonCommensurate) {
      const grouped = _.groupBy(nonCommensurate,
        c => c.customerSubtype ? `${c.customerType}_${c.customerSubtype}` : c.customerType);
      this.nonCommensurateConfig = {
        individual: grouped['INDIVIDUAL'],
        individualBDA: grouped['INDIVIDUAL_BDA'],
        individualRestricted: grouped['INDIVIDUAL_RESTRICTED'],
        corporate: grouped['CORPORATE'],
        complianceConfigsToDelete: []
      };
    }

    if (voluminuosAggregateTransaction) {
      const grouped = _.groupBy(voluminuosAggregateTransaction,
        c => c.customerSubtype ? `${c.customerType}_${c.customerSubtype}` : c.customerType);
      this.voluminousAggregateTransactionConfig = {
        individual: grouped['INDIVIDUAL'],
        individualBDA: grouped['INDIVIDUAL_BDA'],
        individualRestricted: grouped['INDIVIDUAL_RESTRICTED'],
        corporate: grouped['CORPORATE'],
        complianceConfigsToDelete: []
      };
    }
  }

  async reload(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Changes will be discarded. Do you wish to proceed?'
    });
    if (!confirmed) {
      return;
    }
    this.$route.reload();
  }

  async saveConfig(): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: 'Compliance configuration will be updated. Do you wish to proceed?'
    });
    if (!confirmed) {
      return;
    }

    const complianceConfig = {
      forceUpdateSchedule: {
        [this.riskLevelIdLow]: this.complianceConfig.lowRiskMonthSchedule,
        [this.riskLevelIdNormal]: this.complianceConfig.normalRiskMonthSchedule,
        [this.riskLevelIdHigh]: this.complianceConfig.highRiskMonthSchedule
      },
      unusualTransactionAmountThreshold: this.complianceConfig.unusualTransactionAmountThreshold,
      individualDailyMultipleTransactionThreshold: this.complianceConfig.individualDailyMultipleTransactionThreshold,
      bdaDailyMultipleTransactionThreshold: this.complianceConfig.bdaDailyMultipleTransactionThreshold,
      restrictedDailyMultipleTransactionThreshold: this.complianceConfig.restrictedDailyMultipleTransactionThreshold,
      corporateDailyMultipleTransactionThreshold: this.complianceConfig.corporateDailyMultipleTransactionThreshold,
      shortTermAccountThreshold: this.complianceConfig.shortTermAccountThreshold,
      shortTermDepositThreshold: this.complianceConfig.shortTermDepositThreshold
    };
    const request = {
      complianceConfig: complianceConfig,
      complianceRedFlagConfigs: [
        ...this.nonCommensurateConfig.individual,
        ...this.nonCommensurateConfig.individualBDA,
        ...this.nonCommensurateConfig.corporate,
        ...this.nonCommensurateConfig.individualRestricted,
        ...this.voluminousAggregateTransactionConfig.individual,
        ...this.voluminousAggregateTransactionConfig.individualBDA,
        ...this.voluminousAggregateTransactionConfig.corporate,
        ...this.voluminousAggregateTransactionConfig.individualRestricted
      ],
      complianceRedFlagConfigsToDelete: [
        ...this.nonCommensurateConfig.complianceConfigsToDelete,
        ...this.voluminousAggregateTransactionConfig.complianceConfigsToDelete
      ]
    };
    const response = await this.command.execute('UpdateComplianceConfig', request).toPromise();
    if (!response.approvalRequired) {
      await Promise.all([this.complianceConfigCache.refetch(), this.complianceRedFlagConfigCache.refetch()]);
      this.$route.reload();
    }
  }
}

nxModule.component('complianceConfig', {
  templateUrl,
  controller: ComplianceConfig
});
