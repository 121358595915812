import nxModule from 'nxModule';

import {IController} from "angular";
import {Task} from "./task.types";
import './approval-action.style.less';
import templateUrl from './approval-action.template.html';


class ApprovalActionController implements IController {
  task!: Task;
  approvalAction!: (task: Task, $event: unknown, level?: number) => void;
  buttonLabel!: string;
  levelLabel!: string;
  levels!: number[];
}

nxModule.component('approvalAction', {
  templateUrl,
  bindings: {
    task: '<',
    approvalAction: '<',
    buttonLabel: '@',
    levelLabel: '@',
    levels: '<',
    buttonClass: '@'
  },
  controller: ApprovalActionController
});