import nxModule from 'nxModule';
import {NgTableParams} from 'ng-table';
import _ from 'lodash';

import templateUrl from './checkbook-details.template.html';
import './checkbook-details.style.less';
import {Product} from '../../../service/product.types';
import {PageResult} from 'tools/HttpTypes';
import {Checkbook, CheckbookClearingMode, CheckbookEntry, CheckbookEntryStatus} from 'check/CheckbookType';
import {ILocationService} from "angular";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {ModalPrintPreviewService} from "components/service/print/modal-print-preview.service";
import {HttpService} from "shared/utils/httpService";
import {NxIFilterService} from "components/technical/angular-filters";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import {DepositoryAccountCache} from 'components/service/misc-transaction/depository-account.cache.types';
import {CheckbookCache} from "components/service/checkbook-cache.types";

class CheckbookDetails {

  public checkStatuses: {label: string, id: string}[];
  private checkbooks: Checkbook[] = [];
  private checkbookId!: number;
  private checkbook!: Checkbook;
  private customerId!: number;
  private checkStatus!: string;
  private product!: Product;
  private depositoryAccounts!: _.Dictionary<{accountNumber: string}>;
  private checkbookDetailsConfig!: NgTableParams<CheckbookEntry>;
  private isPrintActive = false;

  constructor(
    private http: HttpService,
    private $filter: NxIFilterService,
    private $location: ILocationService,
    private checkbookCache: CheckbookCache,
    private customerCache: CustomerCache,
    private confirmationTemplate: ConfirmationTemplate,
    private popup: Popup,
    private command: CommandService,
    private modalPrintPreviewService: ModalPrintPreviewService,
    private depositoryAccountCache: DepositoryAccountCache
  ) {

    this.checkStatuses = Object.values(CheckbookEntryStatus).map(status => ({
      label: $filter('translate')(status, 'CHECKBOOK_ENTRY_STATUS'),
      id: status
    }));
  }

  async $onInit(): Promise<void> {
    await this.initializeCheckbooks();
    const depositoryAccounts = await this.depositoryAccountCache.toPromise();
    this.depositoryAccounts = _.keyBy(depositoryAccounts, 'id');
    await this.selectCheckbook();
    this.isPrintActive = await this.modalPrintPreviewService.canReprintAsync('DEPOSIT_ACCOUNT_CHECKBOOK');
  }

  async initializeCheckbooks() : Promise<void> {
    const checkbooks: PageResult<(Checkbook & {label?: string})> = await this.checkbookCache.withParam(this.product.id).toPromise();

    // Extend result checkbook with label
    // If checkbook has non-default status add it to label
    this.checkbooks = _.map(checkbooks.result, c => {
      c.label = c.number;
      if (c.status !== 'ISSUED') c.label = `${c.number} (${this.$filter('prettyEnum')(c.status)})`;
      return c;
    });

    // If there is more than 1 checkbook -> set first available as selected
    if (this.checkbooks?.length > 0) this.checkbookId = this.checkbooks[0].id;

    this.checkbookDetailsConfig = new NgTableParams({
      count: 15,
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async (params: NgTableParams<CheckbookEntry>) => {
        return await this.reloadEntries(params);
      }
    });
  }

  async selectCheckbook(): Promise<void> {
    if (this.checkbookId) {
      this.checkbook = <Checkbook>this.checkbooks.find(chk => this.checkbookId === chk.id);
      const usedChecks = <number> await this.http.get(`/checks/checkbooks/${this.checkbookId}/count/used`).toPromise();
      this.checkbook.hasUsedChecks = usedChecks > 0;
    } else {
      // Empty checkbookId means all checkbook selected
      this.checkbook = <Checkbook>{};
    }

    this.reloadTable();
  }

  createCheckbookDetails(checkbook: Checkbook): {label: string, description: string}[] {
    return [
      {label: 'Number', description: checkbook.number},
      {label: 'Issued on', description: this.$filter('prettyDate')(checkbook.issuedOn)},
      {label: 'First check', description: String(checkbook.startNumber)},
      {label: 'Last check', description: String(checkbook.endNumber)}
    ];
  }

  async refreshCheckbooks(): Promise<void> {
    this.checkbookCache.withParam(this.product.id).evict();
    this.customerCache.depositAccounts(this.customerId).evict();
    await this.initializeCheckbooks();
    await this.selectCheckbook();
  }

  async remove(checkbookId: number): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to remove checkbook?`,
      warning: 'This operation cannot be reverted.<br>Please make sure that the checkbook data is correct.',
      details: this.createCheckbookDetails(<Checkbook>this.checkbook),
    });

    if (confirmed) {
      const response = await this.command.execute('RemoveCheckbook', {id: checkbookId}).toPromise();
      if (!response.approvalRequired) {
        this.popup({text: `Checkbook removed`});
        await this.refreshCheckbooks();
      }
    }
  }

  async cancel(checkbookId: number): Promise<void> {
    const confirmed = await this.confirmationTemplate({
      question: `Do you want to cancel checkbook?`,
      details: this.createCheckbookDetails(<Checkbook>this.checkbook)
    });

    if (confirmed) {
      const response = await this.command.execute('CancelCheckbook', {id: checkbookId}).toPromise();
      if (!response.approvalRequired) {
        this.popup({text: `Checkbook status changed to: Canceled`});
        await this.refreshCheckbooks();
      }
    }
  }

  edit(checkbookId: number): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.product.id}/checkbook/${checkbookId}`);
  }

  async reloadEntries(params: NgTableParams<CheckbookEntry>): Promise<CheckbookEntry[]> {
    const dataEndpoint = `/checks/checkbooks/entries?productId=${this.product.id}`;

    const pageResult: PageResult<CheckbookEntry> = <PageResult<CheckbookEntry>> await this.http.post(dataEndpoint, {
      productId: this.product.id,
      checkbookIds: this.checkbookId ? [this.checkbookId] : null,
      statuses: this.checkStatus ? [this.checkStatus] : null,
      checkbookOwner: 'CUSTOMER',
      pageNo: params.page() - 1,
      pageSize: params.count()
    }, {nxLoaderText: 'Loading checkbook entries'}).toPromise();
    params.total(pageResult.totalCount);
    const entries = pageResult.result;

    // add MICR number
    this.enrichWithMicrNumbers(entries);
    return entries;
  }

  enrichWithMicrNumbers(entries: CheckbookEntry[]): void {
    entries.forEach( e => {
      if (e.clearingMode === CheckbookClearingMode.CONDUIT) {
        const depositoryAccount = this.depositoryAccounts[e.depositoryAccountId]
        if (depositoryAccount != null) {
          e.micr = e.checkNumber.padStart(10, '0') + e.brstn + depositoryAccount.accountNumber;
        }
      } else {
        e.micr = e.checkNumber.padStart(10, '0') + e.brstn + e.accountNumber;
      }
    })
  }

  reloadTable(): void {
    this.checkbookDetailsConfig.page(1);
    this.checkbookDetailsConfig.reload();
  }

  print(): void {
    this.modalPrintPreviewService.showAsync({
      printDescription: 'DEPOSIT_ACCOUNT_CHECKBOOK',
      printProviderInput:{
        checkbookId: this.checkbookId,
        accountId: this.product.id,
        checkStatus: this.checkStatus,
        page: this.checkbookDetailsConfig.page() - 1,
        pageSize: this.checkbookDetailsConfig.count()
      }
    });
  }

  getMicrText(item: CheckbookEntry): string {
    // item was processed in enrichWithMicrNumbers but we resolve micr
    // if checkbook has no depository account id then it was issued as PCHC direct clearing check. Please check product account number format
    // otherwise, please check depository account config
    return item.micr ?? "Unable to resolve MICR. Please check PCHC config / depository account";
  }
}

nxModule.component('checkbookDetails', {
  templateUrl,
  transclude: true,
  bindings: {
    product: '<',
    customerId: '<'
  },
  controller: CheckbookDetails
});
