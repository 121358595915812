export type NonWorkingDayAdjustmentStrategy = 'KEEP_DATE' | 'PREVIOUS_WORKING_DAY' | 'NEXT_WORKING_DAY';

export interface NonWorkingDayAdjustmentTranslation {
  value: NonWorkingDayAdjustmentStrategy,
  label: string
}

const NON_WORKING_DAY_ADJUSTMENT_STRATEGIES: ReadonlyArray<NonWorkingDayAdjustmentTranslation> = Object.freeze([
  {
    value: <NonWorkingDayAdjustmentStrategy>'KEEP_DATE',
    label: 'Keep the date'
  },
  {
    label: 'Set to previous working day',
    value: <NonWorkingDayAdjustmentStrategy>'PREVIOUS_WORKING_DAY'
  },
  {
    label: 'Set to next working day',
    value: <NonWorkingDayAdjustmentStrategy>'NEXT_WORKING_DAY'
  }
]);

export default NON_WORKING_DAY_ADJUSTMENT_STRATEGIES;