import nxModule from 'nxModule';
import _ from 'lodash';

import '../common/date-granted.component'

const templateUrl = require('./customer-loans-edit-metadata.template.html');
nxModule.component('customerLoansEditMetadata', {
  templateUrl: templateUrl,
  controller: function ($route, $location, dict, customerCache, command,
                        customerService, fileService, loanService, confirmation) {
    let that = this;

    this.dict = dict;
    that.validateFiles = false;

    let loanId = $route.current.params['loanId'];
    let customerId = $route.current.params['customerId'];

    that.$onInit = async () => {
      const loans = await customerCache.loans(customerId).toPromise();
      const loan = _.find(loans, (a) => String(a.id) === loanId);
      that.clonedLoan = _.cloneDeep(loan);
    };

    that.redirectBack = () => {
      $location.path(`/customer/${customerId}/loans/${loanId}`)
    };

    that.performanceCodes = [{
      label: 'Performing',
      code: 'PERFORMING'
    }, {
      label: 'Non-Performing',
      code: 'NON_PERFORMING'
    }, {
      label: 'Written Off',
      code: 'WRITTEN_OFF'
    }];

    that.pastDueClassificationCodes = [{
      label: 'Unclassified',
      code: 'UNCLASSIFIED'
    }, {
      label: 'Especially Mentioned',
      code: 'ESPECIALLY_MENTIONED'
    }, {
      label: 'Substandard',
      code: 'SUBSTANDARD'
    }, {
      label: 'Doubtful',
      code: 'DOUBTFUL'
    }, {
      label: 'Loss',
      code: 'LOSS'
    }];

    that.selectedPerformanceValid = () => {
      return this.clonedLoan.metadata.performance !== 'WRITTEN_OFF' || this.clonedLoan.status === 'PAST_DUE_WRITE_OFF';
    };

    that.save = () => {
      confirmation('Do you want to update loan metadata?', () => {
        let req;

        if (that.clonedLoan.metadata.manualMetadata) {
          // if manual metadata - include all metadata properties
          req = that.clonedLoan.metadata;
        } else {
          req = {
            manualMetadata: that.clonedLoan.metadata.manualMetadata
          };
        }

        req.productId = Number(loanId);

        command.execute('EditLoanMetadata', req).success(() => {
          customerCache.loans(customerId).refetch();
          that.redirectBack();
        });
      });
    };
  }
});