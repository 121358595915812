import nxModule from 'nxModule';

import templateUrl from './atm-terminal-list.template.html';
import './atm-terminal-list.style.less'
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';
import {Branch} from "management/BranchTypes";
import {ILocationService} from "angular";
import {AtmTerminal, AtmTerminalBranchCache} from "components/atm/service/atm-terminal-branch.cache.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import {HttpService} from "shared/utils/httpService";
import Authentication from "shared/utils/authentication";
import {Confirmation} from "shared/common/confirmation.types";
import {AssetCounter} from "components/technical/status/user-counter.service";

class AtmTerminalList {
  private branch!: Branch;
  private detailLink!: string;
  private atmTerminals: (AtmTerminal & {totalCash?: string})[] = [];

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmation: Confirmation,
              private command: CommandService,
              private http: HttpService,
              private $filter: NxIFilterService,
              private authentication: Authentication,
              private breadcrumbs: unknown,
              private atmTerminalBranchCache: AtmTerminalBranchCache) {
  }

  $onInit = async () => {
    setBreadcrumbs(this.breadcrumbs, 'atm-terminal-list-label', this.branch.name);

    this.detailLink = `/admin/atm-terminals/${this.branch.id}`;
    this.atmTerminals =  await this.atmTerminalBranchCache.withParam(this.branch.id).toPromise();

    // Add ATM total cash
    if (this.authentication.permissions['MNG_ATM_BALANCE_READ']) {
      const atmTerminalsCounters = await this.http.get<AssetCounter[]>('/management/atm-terminals/counters/', {
        nxLoaderSkip: true,
        params: {branchId: this.branch.id}
      }).toPromise();

      this.atmTerminals.forEach(atm => {
        const counter = atmTerminalsCounters.find(c => c.domainId === atm.id)!;
        atm.totalCash = this.$filter('nxCurrency')(counter.totalCash);
      });
    }
  };

  create = () => {
    this.$location.path(`${this.detailLink}/atm-terminal/create`);
  };

  edit = (atmId: number) => {
    this.$location.path(`${this.detailLink}/atm-terminal/${atmId}`);
  };

  delete = async (atmId: number) => {
    const confirmed = await this.confirmation('Are you sure you want to delete ATM terminal?');
    if (confirmed) {
      await this.command.execute('DeleteAtmTerminal', {id: atmId}).toPromise();
      await this.atmTerminalBranchCache.withParam(this.branch.id).refetch();
      this.$route.reload();
    }
  };
}

nxModule.component('atmTerminalList', {
  templateUrl,
  bindings: {
    branch: '<',
  },
  controller: AtmTerminalList
});
