import nxModule from 'nxModule';
import moment from "moment";
import systemPropertyService from '../../../../react/system/systemPropertyService';
import _ from 'lodash';

export const Statuses = {
  ALL: 'All',
  PENDING: 'Pending',
  PROCESSED: 'Posted',
  REJECTED: 'Revoked',
};

export class GlTransactionService {
  constructor(http, command, branchService, authentication, popup) {
    this.http = http;
    this.command = command;
    this.branchService = branchService;
    this.authentication = authentication;
    this.popup = popup;
  }

  fetchTransactions({
    ledgerId,
    userId,
    systemDateFrom,
    systemDateTo,
    status,
    pageNo,
    pageSize,
  }) {

    if(status === 'ALL') {
      status = null;
    }

    return this.http.get(`/ledger/${ledgerId}/transactions/paged`, {
      params: {
        ledgerId,
        userId,
        systemDateFrom,
        systemDateTo,
        status,
        pageNo,
        pageSize,
      }
    });
  }

  fetchLedgerTransaction(transactionId) {
    return this.http.get(`/ledger/transactions/${transactionId}`).toPromise();
  }

  createTransaction({ledgerId, backdated, backdatedPostingDate,remarks, contingent, transactionType, transactionUnits, categoryId}) {
    return this.command.execute(backdated ? 'CreateBackdatedLedgerTransaction' : 'CreateManualLedgerTransaction', {
      ledgerId,
      backdated,
      backdatedPostingDate,
      remarks,
      contingent,
      transactionType,
      transactionUnits,
      categoryId
    }, {nxLoaderText: 'Creating Transaction...'});
  }

  updateTransaction(commandInput) {
    return this.command.execute('UpdateLedgerTransaction',  commandInput, {nxLoaderText: 'Updating Transaction...'}).toPromise();
  }

  revokeTransaction(input) {
    return this.command.execute('RejectManualLedgerTransaction', input, {nxLoaderText: 'Revoking Transaction...'}).toPromise();
  }

  revokeInterbranchTransaction(input) {
    return this.command.execute('InterbranchRejectLedgerTransaction', input, {nxLoaderText: 'Revoking Interbranch Transaction...'}).toPromise();
  }

  async createOrUpdateInterbranchTransaction(requestInput, modificationMode = false, backdated = false) {
    const interbranchManualCommand = modificationMode ? 'InterbranchUpdateLedgerTransaction' : 'InterbranchCreateLedgerTransaction';
    const backdatedManualCommand = 'InterbranchCreateBackdatedLedgerTransaction';
    const commandToExecute = backdated ?  backdatedManualCommand : interbranchManualCommand;
    return await this.command.execute(commandToExecute, requestInput).toPromise();
  }

  async calculateBackdatedPostingDateProperties(modificationMode) {
    const branches = await this.branchService.toPromise();

    const branch = branches.find(b => Number(b.id) === Number(this.authentication.context.branchId));
    const branchSystemDate = branch.systemDate;
    const daysCount = systemPropertyService.getProperty('LEDGER_MAX_BACKDATING_DAYS');

    let maxBackdatedPostingDate;
    if (modificationMode) {
      maxBackdatedPostingDate = moment(branch.postingDate).format('YYYY-MM-DD');
    } else {
      maxBackdatedPostingDate = moment(branch.postingDate).subtract(1, 'day').format('YYYY-MM-DD');
    }

    const minBackdatedPostingDate = moment(branch.postingDate).subtract(daysCount, 'day').format('YYYY-MM-DD');

    return {
      minBackdatedPostingDate: minBackdatedPostingDate,
      maxBackdatedPostingDate: maxBackdatedPostingDate,
      branchSystemDate: branchSystemDate
    };
  }

  async simulateBatchTransaction(request) {
    return  await this.command.execute('SimulateCreateLedgerTransactionByBatch', request).toPromise();
  }

  async displaySimulationErrors(rows) {
    const rowsWithErrors = rows.filter(o => !_.isEmpty(o.errors));
    if (!_.isEmpty(rowsWithErrors)) {
      const header = '<p><span class="red"><strong>WARNING!</strong> Rows listed below resulted in errors:</span></p>';
      const body = [];
      _.forEach(rowsWithErrors, r => {
        _.forEach(r.errors, e => {
          body.push(`<strong>Row ${r.lineNo}</strong>: ${e}<br>`);
        })
      });
      const errorDetails = [header, ...body].join('');
      await this.popup({
        header: 'Upload result',
        text: errorDetails,
        renderHtml: true
      });
    }
  }

}

nxModule.service('glTransactionService', GlTransactionService);
