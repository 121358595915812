import _ from 'lodash';
import tokenStore from "../../authentication/tokenStore";
import {TokenDetails} from "../../authentication/TokenTypes";

class CredentialsUpdateService {
  isForceCredentialsUpdate(): boolean {
    const tokenDetails: TokenDetails = tokenStore.getTokenDetails();
    const permissionsArray = Object.keys(tokenDetails.permissions);
    const forcedCredentialsUpdatePermissions = ['MNG_PASSWORD_WRITE', 'MNG_PIN_WRITE'];

    const otherPermission = _.difference(permissionsArray, forcedCredentialsUpdatePermissions);

    // 1. permissions should be strictly less than or equal 2 - backend only sets these two permissions, nothing else.
    // 2. using difference makes sure that if there are no other permissions aside from the elements
    // defined in forcedCredentialsUpdatePermissions means that it is not for forced credentials update.
    return permissionsArray.length <= 2 && otherPermission.length === 0;
  }

  getRedirectUrl(): string {
    const tokenDetails: TokenDetails = tokenStore.getTokenDetails();
    return `/admin/organization/users/${tokenDetails.userBranchId}`
      + `/user/${tokenDetails.userId}/credentials-update?showPasswordTab=true`;
  }
}

export default CredentialsUpdateService;