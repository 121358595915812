import {NxButtonLink, NxButtonVariant, NxTable, NxTableColumn,} from "@nextbank/ui-components";
import React, {ReactElement, useMemo} from "react";
import NxPage from "form/NxPage";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import {ColumnConfigurationDetails} from "report/ReportType";
import {useReportDescriptors} from "report/ReportService";

export interface ReportDescriptor {
  code: string;
  name: string;
  visible: boolean;
}

const columns: NxTableColumn<ReportDescriptor>[] = [
{
  title: "Report name",
  field: "name",
  width: 150,
}];

const ReportConfigurationList = (): ReactElement => {

  const [{data: reports}] = useReportDescriptors();
  const [{data: configurableReports}] = useAxios<string[]>('/reports/configurable');
  const ConfigureColumns = ({data}: {data: ReportDescriptor}): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`/admin/report-configuration/${data.code}/columns`}>Columns</NxButtonLink>;
 const ConfigureOrder = ({data}: {data: ReportDescriptor}): ReactElement =>
    <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`/admin/report-configuration/${data.code}/order`}>Order</NxButtonLink>;

  const reportList = useMemo(() => {
      if (!reports || !configurableReports) {
        return;
      }

      const configurableReportsSet = new Set(configurableReports);
      return reports.filter(report => report.visible && configurableReportsSet.has(report.code));
    }, [reports, configurableReports]);

  return <NxPage>
    <NxHeader>Report configuration details</NxHeader>
    <NxTable
      columns={columns}
      rowActions={[ConfigureColumns, ConfigureOrder]}
      data={reportList ?? []}
      isLoading={reportList === null}
      />
  </NxPage>;
};

export default ReportConfigurationList;