import nxModule from 'nxModule';
import _ from 'lodash';
import {ActionCategory} from "../../../administration/transactions/action-category.types";
import {IController, IFormController, ILocationService} from 'angular';
import {Loan, WaiveRequest} from '../../../service/loan.types';
import {LoanPreTerminateService, PaymentRequest, PreTerminationPaymentRequest} from "./loan-pre-terminate.types";
import './loan-pre-terminate-by-gl.style.less'
import templateUrl from './loan-pre-terminate-by-gl.template.html';
import {Branch} from "management/BranchTypes";
import {CustomerCache} from "components/service/customer.cache.types";
import Notification from "shared/utils/notification";
import DepositAccountTypeService from "components/administration/deposit-account/common/deposit-account-type-service";
import {BranchService} from "components/service/branch.service";
import {NxIFilterService} from "components/technical/angular-filters";
import {NxRouteService} from "routes/NxRouteService";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

interface PreTerminationByGLRequest {
  waiveRequest: Partial<WaiveRequest>;
  loanPaymentRequest: PreTerminationPaymentRequest;
}

class LoanPreTerminateByGL implements IController {
  private readonly customerId: string;
  private readonly loanId: string;
  private loan!: Loan;

  waiveForm!: IFormController;
  title: string;
  branch!: Branch;
  category!: ActionCategory;
  officialReceipt: string = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  remarks: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  paymentRequest: Partial<PaymentRequest>;
  waiveRequest: Partial<WaiveRequest> = {waiveParts: []};
  amortizationIds: number[] = [];
  maxValues: unknown = {};

  constructor(private $location: ILocationService,
              private $route: NxRouteService,
              private $filter: NxIFilterService,
              private customerCache: CustomerCache,
              private notification: Notification,
              private http: HttpService,
              private command: CommandService,
              private confirmation: Confirmation,
              private depositAccountTypeService: DepositAccountTypeService,
              private loanPreTerminateService: LoanPreTerminateService,
              private branchService: BranchService) {
    this.customerId = this.$route.current.params['customerId'] as string;
    this.loanId = this.$route.current.params['loanId'] as string;
    this.title = this.$filter('uppercase')(this.$filter('translate')('pre-terminate-by-gl', 'LOAN'));
  }

  $onInit = async (): Promise<void> => {
    const [loans, branches] = await Promise.all([this.customerCache.loans(this.customerId).toPromise(), this.branchService.toPromise()]);
    this.loan = loans.find(l => l.id === Number(this.loanId))!;
    this.branch = branches.find(b => b.id === this.loan.branchId)!;
    this.amortizationIds = _.map(this.loan.amortizationSchedule.list, a => a.id);
    this.maxValues = {...this.loan.amortizationSchedule.totalBalance};
  };

  getRequestBody = (): PreTerminationByGLRequest => {
    const preTerminationPaymentRequest = this.loanPreTerminateService.createPaymentRequest(this.paymentRequest, this.officialReceipt);
    return {
      waiveRequest: this.waiveRequest,
      loanPaymentRequest: {
        ...preTerminationPaymentRequest,
        categoryId: this.category.id,
        remarks: this.remarks
      }
    };
  };

  async preTerminate(): Promise<void> {
    const message = this.loanPreTerminateService.getMessage({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      total: this.paymentRequest.amount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      totalWaive: this.waiveRequest.total.totalWaive
    });

    if (await this.confirmation(message)) {
      const response = await this.command.execute('PreterminateLoanByGL', this.getRequestBody(), {
        nxLoaderText: 'Performing operation...'
      }).toPromise();

      if (response && !response.approvalRequired) {
        this.customerCache.loans(this.customerId).refetch();
        this.redirectBack();
      }
    }
  }

  isPreTerminateDisabled(loanPreTerminateForm: IFormController): boolean {
    return !loanPreTerminateForm.$valid || !_.get(this.category, 'id') || !this.loan
  }

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loanId}`)
  }
}

nxModule.component('loanPreTerminateByGl', {
  templateUrl,
  controller: LoanPreTerminateByGL
});