import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import CASA_CLASS_CODES from '../customer/common/types/casa-class-codes.ts';
import systemPropertyService from '../../../react/system/systemPropertyService';

// For any new filter please define types in angular-filter.ts

nxModule.filter('max', function () {
  return function (input, max) {
    var out = [];
    if (input !== null && input !== undefined) {
      for (var i = 0; i < input.length && i < max; i++) {
        out.push(input[i]);
      }
    }
    return out;
  };
});

//@deprecated
nxModule.filter('percent', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      if (typeof input !== 'number') {
        throw "Percent filter error: '" + input + "' is not a number!";
      }
      return (input / 100) + '%';
    }
    return "";
  };
});

nxModule.filter('rate', () => {
  return input => {
    if (input !== null && input !== undefined) {
      return `${input}%`;
    }
    return "-";
  }
});

nxModule.filter('nxCurrency', function (formattingService) {
  const currencySymbol = systemPropertyService.getProperty('DEFAULT_CURRENCY');

  return function (input, args) {
    let currency = null;
    let negativeBrackets = null;
    let dashOnZero = null;
    let noSuffix = null;

    if(args && _.isPlainObject(args)) {
      currency = args.currency;
    } else {
      negativeBrackets = args && args.includes('negativeBrackets');
      dashOnZero = args && args.includes('dashOnZero');
      noSuffix = args && args.includes('noSuffix');
    }

    if (input !== null && input !== undefined) {
      const inputNumber = input instanceof BigNumber ? input.toNumber() : input;

      if (typeof inputNumber !== 'number') {
        throw "Currency filter error: '" + inputNumber + "' is not a number!";
      }

      const number = Number(inputNumber);
      const suffix = noSuffix ? '' : ` ${currencySymbol}`;

      if (dashOnZero && number === 0) return '-';
      if (currency) return `${formattingService.formatNumber(number.toFixed(2))} ${currency}`;
      if (negativeBrackets && number < 0) return `(${formattingService.formatNumber((-number).toFixed(2))}${suffix})`;
      else return formattingService.formatNumber(number.toFixed(2)) + suffix;
    }
    return "-";
  };
});

nxModule.filter('micr', function() {
  return function (input, separator) {
    let stringMicr = '' + input;
    if(stringMicr.length !== 31) {
      // check number has improper length
      return stringMicr;
    }

    separator = separator || "\u2448";

    return `${stringMicr.substr(0, 10)}${separator}${stringMicr.substr(10, 9)}${separator}${stringMicr.substr(19)}`;
  }
});

nxModule.filter('nxDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("YYYY-MM-DD");
    }
    return "";
  };
});

nxModule.filter('prettyDateWithDay', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM Do YYYY, ddd");
    }
    return "";
  };
});

nxModule.filter('prettyDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM Do YYYY");
    }
    return "";
  };
});

nxModule.filter('prettyYearMonth', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM YYYY");
    }
    return "";
  };
});

nxModule.filter('prettyDateTime', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("MMM Do YYYY, h:mm:ss a");
    }
    return "";
  };
});

nxModule.filter('prettyTime', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("h:mm:ss a");
    }
    return "";
  };
});

nxModule.filter('prettyTimeOnly', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment('1970-01-01 ' + input).format("h:mm:ss a");
    }
    return "";
  };
});


nxModule.filter('apiDate', function () {
  return function (input) {
    if (input !== null && input !== undefined) {
      return moment(input).format("YYYY-MM-DD");
    }
    return "";
  };
});

/**
 * Can be used in ng-repeat to simulate a loop. Example:
 * ng-repeat="i in 10 | foreach"
 */
nxModule.filter('foreach', function () {
  return function (input) {
    var array = []
    var max = parseInt(input);
    for (var i = 0; i < max; i++)
      array.push(i);
    return array;
  };
});

/**
 * Converts from enum ONE_UGLY_ENUM to: One ugly enum.
 */
nxModule.filter('prettyEnum', function () {
  return function (input) {
    if (!input || input.length < 2) return input;

    const s = input.replace(/_/g, ' ').toLowerCase();
    return s[0].toUpperCase() + s.substring(1);
  };
});

/**
 * Converts from enum PrettyCamelCase to: Pretty camel case.
 */
nxModule.filter('startCase', function () {
  return function (input) {
    let result = _.startCase(input);
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  };
});

/**
 * Converts boolean value to Yes or No
 */
nxModule.filter('prettyBoolean', function () {
  return function (input) {
    return input ? 'Yes' : 'No';
  };
});

nxModule.filter('prettyAddress', ['dict', function (dict) {
  return function (address, includeCountry) {
    let country;

    if(!address) {
      return '';
    }

    if (includeCountry) {
      dict.onLoadingComplete(() => {
        country = dict['COUNTRY'].find((country) => country.id === address.countryId).description;
      });
    }

    return [  address.street,
              address.barangay,
              address.city,
              address.province,
              address.postalCode,
              country ].filter(Boolean).join(', ');
  }
}]);

nxModule.filter('prettyCasaClassCode', () =>
  casaClassCodeValue => {
    const casaClassCode = CASA_CLASS_CODES.find(val => val.value === casaClassCodeValue);
    return casaClassCode ? casaClassCode.label : undefined;
  }
);
