import nxModule from 'nxModule';

import templateUrl from './account-group-list.template.html';
import {NgTableParams} from 'ng-table';

class AccountGroupList {
  constructor($route, $location, command, authentication, confirmation, accountProductsCache, accountGroupService, customerCache) {
    this.$route = $route;
    this.$location = $location;
    this.command = command;
    this.authentication = authentication;
    this.confirmation = confirmation;
    this.accountProductsCache = accountProductsCache;
    this.customerCache = customerCache;
    this.accountGroupService = accountGroupService;

    this.assignmentMode = false;
  }

  async $onInit() {
    if (this.customerId && this.accountId) {
      this.assignmentMode = true;
      const accounts = await this.customerCache.depositAccounts(this.customerId).toPromise();
      this.account = accounts.find(a => a.id === this.accountId);
      this.typeId = Number(this.account.typeId);
    } else {
      this.typeId = Number(this.$route.current.params['typeId']);
    }

    const accountTypes = await this.accountProductsCache.toPromise();
    this.accountType = accountTypes.find(at => at.id === this.typeId);

    this.filter = {};

    if (this.accountType) {
      this.initConfig();
    }
  }

  createNewGroup() {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}/group/${this.typeId}/create`);
  }

  updateGroup(groupId) {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}/group/${this.typeId}/update/${groupId}`)
  }

  async deleteGroup(groupId) {
    const proceed = await this.confirmation(`Are you sure you want to delete group ${groupId}? All members will be removed from this group and the interest rate could be different for each member accounts.`);
    if (proceed) {
      await this.command.execute('DeleteAccountGroup', {id: groupId}).toPromise();
      this.reloadTable();
    }
  }

  async addAccountToGroup(group) {
    const proceed = await this.confirmation(`Account ${this.account.productNumber} will be added to group ${group.name}. Do you wish to proceed?`);
    if (proceed) {
      const request = {
        accountId: this.accountId,
        groupId: group.id
      };
      await this.command.execute('AddAccountGroupMember', request).toPromise();
      this.customerCache.depositAccounts(this.customerId).refetch();
      this.customerCache.depositAccountGroups(this.customerId).refetch();
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}`);
  }

  // wrapper function used in searching (to be more specific since it's being used in search button)
  onSearch() {
    this.reloadTable();
  }

  reloadTable() {
    this.tableConfig.page(1);
    this.tableConfig.reload();
  }

  initConfig() {
    this.tableConfig = new NgTableParams({
      count: 15
    }, {
      counts: [],
      paginationMaxBlocks: 8,
      paginationMinBlocks: 3,
      getData: async params => {
        const response = await this.accountGroupService.fetchAccountGroupsByCriteria({
          pageNo: params.page() - 1,
          pageSize: params.count(),
          typeId: this.typeId,
          groupName: this.searchName
        });

        params.total(response.totalCount);

        return response.result;
      }
    });
  }

}

nxModule.component('accountGroupList', {
  templateUrl,
  controller: AccountGroupList,
  bindings: {
    customerId: '<',
    accountId: '<'
  }
});