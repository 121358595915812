import {ActionCategory} from 'components/administration/transactions/action-category.types';
import {BatchRemittanceHistoryService} from 'components/batch/remittance/batch-remittance-history.service';
import {PaymentMethod, paymentMethods} from 'constants/paymentMethods';
import moment from 'moment';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {CommandService} from 'shared/utils/command/command.types';
import templateUrl from './batch-remittance-upload.template.html';

type BranchOfService = 'PHILIPPINE_ARMY' | 'GENERAL_HEADQUARTERS' | 'PHILIPPINE_AIR_FORCE' | 'PHILIPPINE_NAVY_OR_MARINE' |
  'PHILIPPINE_COAST_GUARD' | 'PENSIONER';

type fileType = 'CIVILIAN' | 'ACTIVE_SERVICE' | 'PENSIONER';

type BatchRemittanceUploadInput = {
  remittanceMethod: string,
  accountNumber: string,
  uploadedFileId: number,
  remarks: string,
  applicableMonthLastDay: string,
  officialReceipt: string,
  loanCategoryId: number,
  loanCategoryLedgerAccountCode: string,
  depositCategoryId: number,
  depositCategoryLedgerAccountCode: string,
  accountCategoryId: number,
  accountCategoryLedgerAccountCode: string,
  branchOfService: BranchOfService,
  fileType: fileType
}

interface BatchRemittanceUploadOutput {
  batchFileId: number
}

export const branchOfServiceDefs = Object.freeze([
  {
    label: 'Philippine army',
    value: 'PHILIPPINE_ARMY'
  },
  {
    label: 'General headquarters',
    value: 'GENERAL_HEADQUARTERS'
  },
  {
    label: 'Philippine air force',
    value: 'PHILIPPINE_AIR_FORCE'
  },
  {
    label: 'Philippine coast guard',
    value: 'PHILIPPINE_COAST_GUARD'
  },
  {
    label: 'Pensioner',
    value: 'PENSIONER'
  },
  {
    label: 'Philippine navy or marine',
    value: 'PHILIPPINE_NAVY_OR_MARINE'
  }
]);

export class BatchRemittanceUploadComponent {
  private batchRemittanceUploadData: Partial<BatchRemittanceUploadInput>;

  private paymentMethods: ReadonlyArray<PaymentMethod>;

  private glPaymentLoanCategory: ActionCategory | undefined;
  private glPaymentDepositCategory: ActionCategory | undefined;
  private glPaymentAccountCategory: ActionCategory | undefined;

  private applicableMonthMaxDate: Date;
  private applicableMonthFirstDay: string;

  private branchOfService = branchOfServiceDefs;

  private fileTypes : readonly {label: string; value: string;}[] = Object.freeze([{
    label: 'Civilian',
    value: 'CIVILIAN'
  },
  {
    label: 'Active service',
    value: 'ACTIVE_SERVICE'
  }])

  constructor(private command: CommandService,
              private batchRemittanceHistoryService: BatchRemittanceHistoryService,
              private $route: NxRouteService) {
    this.paymentMethods = paymentMethods;
    this.batchRemittanceUploadData = {
      remarks: ""
    };
    this.applicableMonthFirstDay = "";
    this.applicableMonthMaxDate = new Date('9999-12-31');
  }

  paymentMethodChanged() : void {
    this.glPaymentLoanCategory = undefined;
    this.glPaymentDepositCategory = undefined;
    this.glPaymentAccountCategory = undefined;
    this.glPaymentLoanCategoryChanged();
    this.glPaymentDepositCategoryChanged();
    this.glPaymentAccountCategoryChanged();
    this.batchRemittanceUploadData.accountNumber = undefined;
  }

  glPaymentLoanCategoryChanged() : void {
    this.batchRemittanceUploadData.loanCategoryId = this.glPaymentLoanCategory?.id;
    this.batchRemittanceUploadData.loanCategoryLedgerAccountCode = this.glPaymentLoanCategory?.ledgerAccountFullCodes[0];
  }

  glPaymentDepositCategoryChanged(): void {
    this.batchRemittanceUploadData.depositCategoryId = this.glPaymentDepositCategory?.id;
    this.batchRemittanceUploadData.depositCategoryLedgerAccountCode = this.glPaymentDepositCategory?.ledgerAccountFullCodes[0];
  }

  glPaymentAccountCategoryChanged(): void {
    this.batchRemittanceUploadData.accountCategoryId = this.glPaymentAccountCategory?.id;
    this.batchRemittanceUploadData.accountCategoryLedgerAccountCode = this.glPaymentAccountCategory?.ledgerAccountFullCodes[0];
  }

  async resetForm(): Promise<void> {
    this.batchRemittanceUploadData = {
      remarks: ""
    };
    this.glPaymentLoanCategory = undefined;
    this.glPaymentDepositCategory = undefined;
    this.glPaymentAccountCategory = undefined;
  }
  async executeBatchRemittance(): Promise<void> {
    const endOfApplicableMonthMoment = moment(this.applicableMonthFirstDay).endOf('month');
    this.batchRemittanceUploadData.applicableMonthLastDay = endOfApplicableMonthMoment.format("YYYY-MM-DD");

    const response = await this.command.execute<Partial<BatchRemittanceUploadInput>, BatchRemittanceUploadOutput>('ProcessRemittanceFile', this.batchRemittanceUploadData).toPromise();
    if(response) {
      await this.resetForm();
    }
    this.batchRemittanceHistoryService.getHistory()?.reload();
  }
}

  nxModule.component('batchRemittanceUpload', {
  templateUrl: templateUrl,
  controller: BatchRemittanceUploadComponent
});
