import nxModule from 'nxModule';

// TODO: THIS DIRECTIVE DOES NOT WORK IF USED IN 'TRANSCLUDE'

//This directive will append ng-options programmatically during compilation
//based on the https://docs.angularjs.org/guide/compiler, section: "intercept the initial compilation of the element"
nxModule.directive('booleanOptions', function($compile){
  return {
    priority: 5000, // compiles first
    terminal: true, // prevent lower priorty directives to compiler after it
    compile: function(element, attr) {
      attr.$set('ngOptions', 'o.value as o.label for o in [{ label: "No", value: false }, { label: "Yes", value: true }]')
      //compile everything except directives with priority >= 5000
      let linkFn = $compile(element, null, 5000);
      return function(scope, $element, $attr) {
        linkFn(scope, (cloned, scope) => {
          //for transcluded element it is required to replace $element with clone
          //since original one has ngOptions attribute but not compiled
          $element.replaceWith(cloned);
        });
      }
    }
  };
});
