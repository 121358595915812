import nxModule from 'nxModule';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import _ from 'lodash';
import AccessType from 'constants/customerAccessType';
import systemPropertyService from '../../../../react/system/systemPropertyService';

/**
 * Manages access to particular type of customers.
 * Allows for getting current access and requesting for new a one.
 * Exposes stream of access changes.
 */
class CustomerAccessTypeService {
  constructor({authentication, command, http, activeCustomerStream}) {
    this.command = command;
    this.http = http;
    this.authentication = authentication;
    this.accessChangedSubject = new ReplaySubject(1);
    this.root = {};

    activeCustomerStream.map(customer => {
      const viewingRestrictions = systemPropertyService.getProperty('ENABLE_CUSTOMER_VIEWING_RESTRICTIONS');
      if (viewingRestrictions) {
        return {
          viewingRestrictionsEnabled: viewingRestrictions !== 'FALSE',
          ...customer,
        };
      }

      return {
        ...customer,
        viewingRestrictions: true
      };
    })
    .subscribe((...next) => this.onCustomerUpdated(...next));
  }

  onCustomerUpdated({customerId, viewingRestrictionsEnabled}) {
    const access = !viewingRestrictionsEnabled;
    this.root = {customerId, requestAccessInProgress: false, granted: access};
    this.accessChangedSubject.next({customerId, access,});
  }

  onAccessChanged() {
    // don't emit duplicated items
    return this.accessChangedSubject
      .distinctUntilChanged(_.isEqual);
  }

  async requestAccess() {
    if(!this.root.customerId) {
      console.error('Illegal usage - no customer selected');
    }

    this.root = {
      customerId: this.root.customerId,
      requestAccessInProgress: true,
      requestedAccess: true
    };

    const root = this.root;
    try {
      const response = await this.command.execute('GrantCustomerAccess', {
        customerId: parseInt(root.customerId),
        userId: this.authentication.context.id
      }).toPromise();
      root.commandId = response.commandId;
      if (response.approvalRequired) {
        return;
      }

      root.granted = true;
      root.rejected = false;
      this.accessChangedSubject.next({customerId: root.customerId, access: true});
    } finally {
      root.requestAccessInProgress = false;
    }
  }

  requestedAccess() {
    return this.root.requestedAccess;
  }

  accessRejected() {
    return this.root.rejected;
  }

  fullAccess() {
    return !this.root.customerId || this.root.granted;
  }

  async checkAccess() {
    const root = this.root;
    if(!root.commandId || root.requestAccessInProgress || root.granted || root.rejected) {
      return;
    }

    const accessResponse = await this.http.get('/access/customer-access', {
      params: {
        commandId: root.commandId
      }
    }).toPromise();

    if(accessResponse.granted || accessResponse.rejected) {
      Object.assign(root, accessResponse);
      this.accessChangedSubject.next({customerId: root.customerId, access: accessResponse.granted});
    }

    return accessResponse;
  }
}

const createAccessTypeService = ({activeStream, ...args}) => {
  return new CustomerAccessTypeService({
    activeCustomerStream: activeStream,
    ...args,
  });
};

/**
 * Service has one method - ofType, which returns service for specific access type - group or nongroup. Returned service
 * has accessors with information on current access level - full or branch only, information on if access was requested,
 * was granted or rejected.
 */
nxModule.factory('customerAccessService', (authentication, http, command, activeCustomerService) => {
  const args = {
    authentication,
    http,
    command,
  };

  const accessTypeServices = {};
  for(let type of Object.values(AccessType)) {
    accessTypeServices[type] = createAccessTypeService({
      ...args,
      activeStream: activeCustomerService.ofType(type).customerIdChanged(),
    });
  }

  return {
    ofType: (accessType) => {
      const service = accessTypeServices[accessType];
      if(service) {
        return service;
      }

      console.error("Access type not supported:", accessType);
    }
  };
});