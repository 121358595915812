export enum AgentTransaction {
  AGENT_ACCOUNT_CASH_DEPOSIT = 'AgentAccountCashDeposit',
  AGENT_ACCOUNT_CASH_WITHDRAWAL = 'AgentAccountCashWithdrawal',
  AGENT_ACCOUNT_BALANCE_INQUIRY = 'AgentAccountBalanceInquiry',
  BILL_PAYMENT = 'BillPayment',
  TOP_UP_PAYMENT = 'TopUpPayment',
  PREPAID_PAYMENT = 'PrepaidPayment'
}

export interface AgentFeeDefinition {
  fixedAmount: number;
  extraOptions: Record<any, any>;
}
