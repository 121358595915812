import nxModule from "nxModule";

import moment from "moment";
import Popup from "shared/common/popup";

import ReportService from "../../../../react/report/ReportService"

import templateUrl from "./custom-report-detail.template.html";
import { CustomReportFilterConfig, CustomReportFilterDetail } from "./custom-report-filter.component";

interface ReportDetails {
  name: string;
  type: string;
}

interface ValidSystemDate {
  date: string | number | string[] | Date | moment.Moment; 
  isValid: boolean;
}

class CustomReportDetail {
  private reportService: ReportService = new ReportService(); 
  private popup: Popup;
  
  private reportName: string = "Custom report"; 
  private reportType: string = "Custom";
  
  title: string = "Custom reports details"; 
  
  filter: CustomReportFilterDetail;
  filterConfig: CustomReportFilterConfig;

  constructor($routeParams: ng.route.IRouteParamsService, popup: Popup) {
    this.popup = popup;
    this.title = `${ $routeParams.reportCode.replace(/_/g, " ") } Report`;

    this.filterConfig = {
      button: {
        text: "Generate", 
        isDisabled: false, 
        click: this.generateReport.bind(this), 
      }, 
      dateFilter: {
        change: this.changeDate.bind(this), 
      }
    };

    this.filter = {
      name: "Date", 
      code: moment()
    }; 

    const {name: reportName, type: reportType} = this.getReportDetails(); 
    this.reportName = reportName; 
    this.reportType = reportType; 
    this.changeDate()
  }

  getReportDetails(): ReportDetails {
    const titleArr = this.title.split("-");
    const trimmmedText = titleArr[0].trim(); 

    let reportName = `${trimmmedText} - On Demand`;
    let reportType = reportName.includes("ADB") ? "ADB" : titleArr[1].trim().split(" ")[0].trim();
    
    if (!trimmmedText.toLowerCase().includes("report")) {
      reportName = `${trimmmedText} Report - On Demand`;
    }

    return {
      name: reportName, 
      type: reportType
    }
  }

  getSystemDate(): ValidSystemDate {
    let selectedDateStr = $("date.custom-filter-date > input").val() || "";
    let selectedDate = moment(selectedDateStr); 
    const selectedYear = selectedDate.year(); 

    let isValid = true;

    switch (this.reportType) {      
      case "Monthly": 
        selectedDateStr = moment(`${selectedYear}-${selectedDate.month() + 1}-${selectedDate.daysInMonth()}`).format("YYYY-MM-DD");
        break;

      case "Yearly": 
        isValid = false;

        const today = moment();
        const todayYear = today.year(); 
        
        const lastDayOfYear = moment(`${todayYear}-12-31`); 
        const firstDayOfYear = moment(`${todayYear}-01-01`);

        const lastDayOfYearSelected = moment(`${selectedYear}-12-31`); 
        const firstDayOfYearSelected = moment(`${selectedYear}-01-01`);

        if (selectedDate.toString() === firstDayOfYear.toString()) {
          selectedDateStr = `${selectedYear - 1}-12-31`; 
          isValid = true;
          
        } else if (today.year() == selectedYear && (today <= lastDayOfYear)) {
          selectedDateStr = "";

        } else if (today.year() > selectedYear && (selectedDate <= lastDayOfYearSelected && selectedDate >= firstDayOfYearSelected)) {
          selectedDateStr = lastDayOfYear.format("YYYY-MM-DD"); 
          isValid = true;

        }
        break;

      case "ADB":
        selectedDateStr = `${selectedYear}-${selectedDate.month() + 2}-${selectedDate.date()}`;
        break;
    }

    return {date: selectedDateStr, isValid};
  }

  changeDate(): void { 
    const {isValid} = this.getSystemDate();
    $("button[name='download']").prop("disabled", !isValid);
  }

  async generateReport(): Promise<void> {
    let {name: reportName, type: reportType} = this.getReportDetails();
    let {date: systemDate} = this.getSystemDate();

    let reportParameters = { 
      "system_date": systemDate, 
      "include_backdated": true 
    }; 

    if (systemDate === "") { 
      this.popup({ text: "Request can't be sent to reporting tool. Please check the selected date." });  

    } else {
      this.popup({ text: "Request sent to reporting tool." });
      await this.reportService.generateFileReport({ reportName, parameters : reportParameters }); 
    }
  }
}

nxModule.component('customReportDetail', {
  templateUrl,
  controller: CustomReportDetail
});
