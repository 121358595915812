import nxModule from 'nxModule';
import templateUrl from "./agent-device-details.template.html";
import {Branch} from "management/BranchTypes";
import {ILocationService} from "angular";
import {Agent, AgentsBranchCache} from "components/agent-banking/service/agents-branch.cache.types";
import {AgentsDevicesCache} from "components/agent-banking/service/agents-devices.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import {BranchService} from 'components/service/branch.service';
import {Device} from "components/agent-banking/details/devices/device-details.component";

class AgentDeviceDetailsComponent {

  branchId!: number;
  agentId!: number;
  deviceId!: number;
  branch!: Branch;
  agent!: Agent;
  device!: Device;

  constructor(private branchService: BranchService,
              private agentsBranchCache: AgentsBranchCache,
              private agentsDevicesCache: AgentsDevicesCache,
              private command: CommandService,
              private $location: ILocationService,
              private confirmation: Confirmation) {}

  async $onInit(): Promise<void> {
    const [branches, agents, devices] = await Promise.all([
      this.branchService.toPromise(),
      this.agentsBranchCache.withParam(this.branchId).toPromise(),
      this.agentsDevicesCache.withParam(this.agentId).toPromise()
    ]);

    this.branch = branches.find(b => b.id === this.branchId)!;
    this.agent = agents.find(a => a.id === this.agentId)!;
    this.device = devices.find(d => d.id === this.deviceId)!;
  }

  async update(): Promise<void> {
    const confirmed = await this.confirmation('Are you sure you want to update this device?');
    if (confirmed) {
      const response = await this.command.execute('UpdateDevice', this.device).toPromise();
      if (response && !response.approvalRequired) {
        this.agentsDevicesCache.withParam(this.agentId).refetch();
        this.redirectBack();
      }
    }
  }

  redirectBack(): void {
    this.$location.path(`/agent-banking/agents/${this.branchId}/agent/${this.agentId}`);
  }
}

nxModule.component('agentDeviceDetails', {
  templateUrl: templateUrl,
  bindings: {
    branchId: '<',
    agentId: '<',
    deviceId: '<',
  },
  controller: AgentDeviceDetailsComponent
});
