import _ from 'lodash';
import nxModule from 'nxModule';
import $ from 'jquery';

import {cicContractTypes, loanCreationTypes} from 'constants/loan';

import templateUrl from './group-loans-create-form.template.html';

class GroupLoansCreateForm {
  constructor(groupLoansCreateService, $element,) {
    this.groupLoansCreateService = groupLoansCreateService;
    this.$element = $element;
  }

  $onInit() {
    this.creationTypes = [...loanCreationTypes];
    this.cicContractTypes = [...cicContractTypes];

    this.memberApplications = [this.defaultMember, ...this.members];
    this.newMemberData = {};
    this.memberApplications.forEach(member => {
      this.newMemberData[member.id] = member?.newLoan ? this.copyExistingLoan(member) : this.newLoan(member.id, this.product);
    });
  }

  copyExistingLoan(member) {
    return {
      loan: angular.copy(member.newLoan),
      newProduct: angular.copy(member.newProduct)
    }
  }

  newLoan(customerId, product) {
    return {
      loan: {
        loanTypeId: product.id,
        customerId: Number.parseInt(customerId),
        creationType: product.defaultCreationType,
        automaticTransferAgreement: {
          transferStrategy: 'NONE'
        }
      },
      newProduct: product
    }
  }

  removeMember(memberToRemove) {
    this.memberApplications = this.memberApplications.filter(member => member.id !== memberToRemove.id);
    delete this.newMemberData[memberToRemove.id];
  }

  formsPropsCopy(memberData) {
    return {loan: memberData.loan};
  }

  copyDefaultConfigurationToAll() {
    for(let [key, memberData] of Object.entries(this.newMemberData)) {
      if(key === String(this.defaultMember.id)) {
        continue;
      }
      this.copyDefaultConfigurationToHere(memberData);
    }
  }

  copyDefaultConfigurationToHere(memberData) {
    const formModel = this.formsPropsCopy(this.newMemberData[this.defaultMember.id]);
    const clonedFormModel = _.clone(formModel);
    _.merge(memberData, _.omit(clonedFormModel, ['loan.customerId', 'loan.creationType', 'loan.principalAmount']));
  }

  formInvalid() {
    return Object.entries(this.newMemberData)
      .filter(([id, _]) => id !== String(this.defaultMember.id))
      .map(([_, value]) => value)
      .some(newMemberData => {
        return !newMemberData.createParametersForm ||
          newMemberData.createParametersForm.$invalid ||
          !newMemberData.createLaiDetailsForm ||
          newMemberData.createLaiDetailsForm.$invalid ||
          !newMemberData.overrideForm ||
          newMemberData.overrideForm.$invalid ||
          newMemberData.headerForm.$invalid ||
          !newMemberData.loanDocumentsForm ||
          newMemberData.loanDocumentsForm.$invalid;
      })
  }

  formReady() {
    this.memberApplications.filter(member => member.id !== this.defaultMember.id)
      .forEach(member => {
        const originalMember = this.members.find(originalMember => originalMember.id === member.id);
        const memberData = this.newMemberData[member.id];
        originalMember.newLoan = memberData.loan;
        originalMember.newProduct = memberData.newProduct;

        // I'm not unsubscribing from this observable on navigating away from this component - I need loan calculation in group-loans-create view
        this.groupLoansCreateService.calculateLoan(memberData.loan)
          .subscribe(loanCalculation => {
            originalMember.newLoanCalculation = loanCalculation;
          });
      });

    const defaultConfig = this.newMemberData[this.defaultMember.id];
    this.defaultMember.newLoan = defaultConfig.loan;
    this.defaultMember.newProduct = defaultConfig.newProduct;
    this.members.forEach(member => member.applying = false);

    this.formSubmitAction();
  }


  $postLink() {
    const $formEl = this.$element.find('.group-loans-create-form');
    // all clicks on accordion header (marked with data-toggle="collapse") will cause accordion to expand and collapse
    // due to the presence of inputs, buttons and selects within header, clicking on them will cause the same expanding behaviour,
    // which is clearly non intentional, so when such events are triggered, we cancel them
    $formEl.on('click', event => {
      // we need to cancel bubbling to prevent expanding of accordion
      const shouldPreventPropagation = $(event.target)
        .closest('glue-container, button, select, input').length;

      if (shouldPreventPropagation) {
        event.stopPropagation();
      }

    });
  }
}

nxModule.component('groupLoansCreateForm', {
  templateUrl,
  bindings: {
    'defaultMember': '=',
    'members': '<',
    'product': '<',
    'customerId': '<',
    'groupProfile': '<',
    'formSubmitAction': '<',
    'formCancelAction': '<'
  },
  controller: GroupLoansCreateForm
});
