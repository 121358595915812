import nxModule from 'nxModule';

const templateUrl = require('./users-branches.template.html');

nxModule.component('usersBranches', {
  templateUrl,
  controller: function () {

  }
});
