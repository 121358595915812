import {IScope} from 'angular';
import {CustomerCache} from 'components/service/customer.cache.types';
import {
  CustomFieldCategory,
  CustomFieldDefinition,
  CustomFieldGroup,
  CustomFieldRestriction
} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import Authentication from 'shared/utils/authentication';
import templateUrl from './custom-field-input.template.html';

type CustomFieldDefinitionWithCategories = CustomFieldDefinition & {children: CustomFieldCategory[]};

class CustomFieldInput {

  group!: CustomFieldGroup;
  assignedCategoryIds: number[] = [];
  selectedCustomFieldCategory: Record<number, Partial<CustomFieldCategory>> = {};
  customFieldValues: Record<number, string> = {};
  availableCustomFields!: number[];
  customFieldCategoryDefinitions!: CustomFieldDefinitionWithCategories[];
  nonCategoryCustomFields!: CustomFieldDefinition[];
  branchId!: number;
  customerId!: number;
  editMode!: boolean;
  customFieldsByRestrictedField: Record<number, number[]> = {};
  restrictionsByField: Record<number, CustomFieldRestriction[]> = {};
  customerCategoryIds: number[] = [];
  allDefinitionIds: number[] = [];
  allCustomFieldCategoryDefinitions: CustomFieldDefinitionWithCategories[] = [];
  allNonCategorizedCustomFieldDefinitions: CustomFieldDefinition[] = [];

  constructor(private $scope: IScope,
              private authentication: Authentication,
              private customerCache: CustomerCache) {
    $scope.$watchGroup(['$ctrl.availableCustomFields'], () => this.configureCustomFields());
  }

  async configureCustomFields(): Promise<void> {
    if (this.availableCustomFields || this.assignedCategoryIds) {
      const [allDefinitions, restrictions] = await Promise.all([
        customFieldService.readDefinitions({groups: [this.group], enabled: true}),
        customFieldService.readRestrictions({restrictedFieldIds: this.availableCustomFields})
      ]);

      this.allDefinitionIds = allDefinitions.map(definition => definition.id);

      const categoryDefinitions = this.getCategoryDefinitions(allDefinitions);
      const categoryDefinitionsIds = categoryDefinitions.map(c => c.id).filter(id => id);
      const categoryValues = await customFieldService.readCategories(categoryDefinitionsIds);

      // retrieve all custom fields values for customer
      // skip for default application in group/batch loans
      // and only get categories from customer if the selected group is a product group.
      if (this.group !== 'CUSTOMER' && this.customerId && this.customerId > 0) {
        const [customer, customFieldValuesForCustomer] = await Promise.all([
        this.customerCache.profile(this.customerId).toPromise(),
        customFieldService.readValuesMap({customerId: this.customerId})
      ]);
        this.customerCategoryIds = customer.categoryIds;
        for (const categoryValue of categoryValues) {
          if (categoryValue.customFieldDefinitionId) {
            categoryValue.value = customFieldValuesForCustomer[categoryValue.customFieldDefinitionId] ?? 'Value for this category is still unset';
          }
        }
      }

      for (const definition of categoryDefinitions) {
        if (definition.id) {
          definition.children = categoryValues.filter(c => c.definitionId === definition.id && c.enabled);
        }
      }

      const definitionByCategoryMap = this.createDefinitionIdByCategoryMap(categoryDefinitions);

      this.allCustomFieldCategoryDefinitions = categoryDefinitions.filter(root => this.isCategoryAvailable(root)
        || this.isCategoryAssigned(definitionByCategoryMap, root));

      if (this.assignedCategoryIds && this.allCustomFieldCategoryDefinitions.length > 0) {
        for (const categoryId of this.assignedCategoryIds) {
          const definitionId = definitionByCategoryMap.get(categoryId);
          if (definitionId) {
            this.selectedCustomFieldCategory[definitionId] = {id: categoryId};
          }
        }
        this.selectedProductCategoryChange();
      }

      this.allCustomFieldCategoryDefinitions = this.allCustomFieldCategoryDefinitions.filter(c => {
        return this.isAvailableInBranch(c);
      });

      this.allNonCategorizedCustomFieldDefinitions = allDefinitions.filter(c => c.type !== 'CATEGORY')
        .filter(c => this.isAvailableInBranch(c))
        .filter(c => (c.id && this.availableCustomFields.includes(c.id)));

      this.customFieldsByRestrictedField = restrictions.reduce((map, restriction) => {
        map[restriction.restrictedFieldId] = map[restriction.restrictedFieldId] || [];
        if (!map[restriction.restrictedFieldId].includes(restriction.customFieldId)) {
          map[restriction.restrictedFieldId].push(restriction.customFieldId);
        }
        return map;
      }, Object.create(null));

      this.restrictionsByField = restrictions.reduce((map, restriction) => {
        map[restriction.customFieldId] = map[restriction.customFieldId] || [];
        map[restriction.customFieldId].push(restriction);
        return map;
      }, Object.create(null));

      this.restrictCategory();
      this.restrictNonCategorizedCustomFields();
    }
  }

  isAvailableInBranch(definition: CustomFieldDefinition): boolean {
    return !definition.availableInBranchIds || definition.availableInBranchIds.includes(this.branchId || this.authentication.context.branchId);
  }

  isCategoryAssigned(map: Map<number, number>, definition: CustomFieldDefinitionWithCategories): boolean {
    return this.assignedCategoryIds && this.assignedCategoryIds.some(c => map.get(c) === definition.id);
  }

  isCategoryAvailable(root: CustomFieldDefinitionWithCategories): boolean {
    return this.availableCustomFields && this.availableCustomFields.includes(root.id);
  }

  getCategoryDefinitions(allEnabledDefinitions: CustomFieldDefinition[]): CustomFieldDefinitionWithCategories[] {
    return allEnabledDefinitions.filter(
        c => c.type === 'CATEGORY')
      .map(c => {
        return {
          ...c,
          children: []
        };
      });
  }

  hasChildren(category: CustomFieldCategory): boolean {
    return category.children && category.children.length > 0;
  }

  createDefinitionIdByCategoryMap(definitions: CustomFieldDefinitionWithCategories[]): Map<number, number> {
    const map = new Map();
    definitions.forEach(d => this.persistLeafToMap(d.children, map));
    return map;
  }

  persistLeafToMap(categories: CustomFieldCategory[], map: Map<number, number>): void {
    for (const category of categories) {
      if (category.id && category.definitionId && !this.hasChildren(category)) {
        map.set(category.id, category.definitionId);
        continue;
      }
      this.persistLeafToMap(category.children, map);
    }
  }

  selectedProductCategoryChange(): void {
    this.setAssignedCategoryIds();
    this.restrictCategory();
    this.restrictNonCategorizedCustomFields();
  }

  setAssignedCategoryIds(): void {
    this.assignedCategoryIds = Object.keys(this.selectedCustomFieldCategory)
      .map(k => Number(k))
      .filter(rootId => this.selectedCustomFieldCategory[rootId])
      .map(rootId => this.selectedCustomFieldCategory[rootId].id)
      .filter((n): n is number => !!n);
  }

  productCategoryLabel(category: CustomFieldCategory): string {
    return category.value;
  }

  showOnlyEnabled({node}: {node: {enabled: boolean}}): boolean {
    return node.enabled;
  }

  editModeEnabled(): boolean {
    return this.editMode;
  }

  restrictCategory() : void {
    this.customFieldCategoryDefinitions = this.allCustomFieldCategoryDefinitions.filter(categoryDefinition => {
      if(!this.customFieldsByRestrictedField[categoryDefinition.id]) {
        return true;
      }
      const hasSetCategory = this.selectedCustomFieldCategory[categoryDefinition.id] != null;
      const restrictionDefaults: number[] = [];
      const passedAllRestrictions = this.customFieldsByRestrictedField[categoryDefinition.id].every(customFieldId => {
        const restriction = this.restrictionsByField[customFieldId].find(r => {
          return categoryDefinition.id === r.restrictedFieldId
            &&  r.customFieldCategoryId
            && [...(this.assignedCategoryIds ?? []), ...(this.customerCategoryIds ?? [])].includes(r.customFieldCategoryId);
        })
        if (!restriction) {
          delete this.selectedCustomFieldCategory[categoryDefinition.id];
          return false;
        }

        if (restriction.restrictedFieldDefault && restriction.restrictedFieldDefault.categoryId) {
          restrictionDefaults.push(restriction.restrictedFieldDefault.categoryId);
        }
        return true;
      })

      if (!hasSetCategory && passedAllRestrictions && Object.keys(restrictionDefaults).length === 1) {
        this.selectedCustomFieldCategory[categoryDefinition.id] = {id: restrictionDefaults[0]}
        this.setAssignedCategoryIds();
      }
      return passedAllRestrictions;
    })
  }

  restrictNonCategorizedCustomFields(): void {
    this.nonCategoryCustomFields = this.allNonCategorizedCustomFieldDefinitions.filter(categoryDefinition => {
      if (!this.customFieldsByRestrictedField[categoryDefinition.id]) {
        return true;
      }
      this.customFieldValues = this.customFieldValues ?? {};
      const hasSetCategory = this.customFieldValues[categoryDefinition.id] != null;
      const restrictionDefaults: string[] = [];
      const passedAllRestrictions = this.customFieldsByRestrictedField[categoryDefinition.id].every(customFieldId => {
        const restriction = this.restrictionsByField[customFieldId].find(r => {
          return categoryDefinition.id === r.restrictedFieldId
            &&  r.customFieldCategoryId
            && [...(this.assignedCategoryIds ?? []), ...(this.customerCategoryIds ?? [])].includes(r.customFieldCategoryId);
        });

        if (!restriction) {
          delete this.customFieldValues[categoryDefinition.id];
          return false;
        }
        if (restriction.restrictedFieldDefault && restriction.restrictedFieldDefault.value) {
          restrictionDefaults.push(restriction.restrictedFieldDefault.value);
        }
        return true;
      })

      if (!hasSetCategory && passedAllRestrictions && Object.keys(restrictionDefaults).length === 1) {
        this.customFieldValues[categoryDefinition.id] = restrictionDefaults[0];
      }
      return passedAllRestrictions;
    })
  }
}

nxModule.component('customFieldInput', {
  templateUrl,
  bindings: {
    group: '<',
    availableCustomFields: '<',
    assignedCategoryIds: '=',
    customFieldValues: '=',
    branchId: '=',
    customerId: '<',
    editMode: '='
  },
  controller: CustomFieldInput
});
