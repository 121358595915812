import nxModule from 'nxModule';
import ParameterizedLocalCache from '../../../../shared/utils/parameterized-local-cache';

nxModule.factory('creditLineLoansCache', (http, CacheFactory, config) =>
  new ParameterizedLocalCache({
    provider: (creditLineIds) => http.get(`/credit-lines/loans?creditLineIds=${creditLineIds}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.creditLineLoans,
    cacheName: 'creditLineLoans'
  })
);