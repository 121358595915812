import BigNumber from 'bignumber.js';
import moment from 'moment';
import nxModule from 'nxModule';

import {ssNumberPattern} from '../sss-offline-payment/sss-offline-payment.utils';

const templateUrl = require('./sss-change-individual-prn.template.html');

class SssChangeIndividualPrn {
  constructor($location, $filter, http, actionCommand, command, popup, modalPrintPreviewService) {
    this.command = command;
    this.popup = popup;
    this.$filter = $filter;
    this.$location = $location;
    this.modalPrintPreviewService = modalPrintPreviewService;
    this.ssPattern = ssNumberPattern('CONTRIBUTION');
    this.actionCommand = actionCommand;

    this.availablePayorTypes = [
      'NON_WORKING_SPOUSE',
      'OVERSEAS_WORKER',
      'SELF_EMPLOYED_MEMBER',
      'VOLUNTARY_MEMBER',
    ];

    this.onPayorTypeChange = async () => {
      this.monthlyAmount = null;
      const response = await http.get(`/sss/contributions/${this.payorType}`).toPromise();
      this.contributions = response.amounts.map(amount => ({
        value: amount,
        label: $filter('nxCurrency')(amount)
      }));
    };

    this.getAvailableContributions = () => {
      if(!this.payorType) {
        return this.contributions;
      }

      if(this.payorType === 'OVERSEAS_WORKER') {
        return this.contributions.filter(cont => cont.value > 500);
      }

      return this.contributions;
    };

    this.contributions = [];

    this.cancelChanges = actionCommand.cancelChanges;
  }

  onStartingPeriodChange() {
    if (!this.endingPeriod) {
      this.endingPeriod = this.startingPeriod.clone();
    }
  };

  calculateMonthSpan() {
    if(!this.startingPeriod || !this.endingPeriod) {
      return null;
    }

    return moment(this.endingPeriod).diff(this.startingPeriod, 'months') + 1; // diff between jan-jan should be one
  }

  calculateTotalContributionAmount() {
    return this.multiplyByMonthSpan(this.monthlyAmount);
  }

  multiplyByMonthSpan(amount) {
    const monthDiff = this.calculateMonthSpan();
    if(!monthDiff || (amount === null || amount === undefined)) {
      return null;
    }

    return new BigNumber(amount)
      .times(monthDiff)
      .toNumber();
  }

  calculateTotalAmount() {
    const amount = this.calculateTotalContributionAmount();
    if(amount === null || amount === undefined) {
      return 0;
    }

    return new BigNumber(amount)
      .plus(new BigNumber(this.flexiFundAmount || 0))
      .toNumber();
  }

  calculateTotalAmountText() {
    const amount = this.calculateTotalContributionAmount();
    if(amount === null || amount === undefined) {
      return "";
    }

    return this.$filter('nxCurrency')(this.calculateTotalAmount());
  }

  changePRN() {
    this.command.execute('GenerateIndividualNewPrnSss', {
      "payorType": this.payorType,
      "ssNumber": this.ssNumber,
      "startingPeriod": this.startingPeriod.format('YYYY-MM'),
      "endingPeriod": this.endingPeriod.format('YYYY-MM'),
      "amount": this.calculateTotalAmount(),
      "flexiFundAmount": this.flexiFundAmount || 0,
    }).success(res => {
      const {commandId, output: out} = res;
      this.popup({
        text: this.buildMessage(out),
        renderHtml: true,
        callback: async () => {
         await this.modalPrintPreviewService.showAsync({
            printDescription: 'SSS_GENERATE_PRN_VALIDATION_SLIP',
            printProviderInput: {
              commandId: commandId,
            },
            callback: () => {
              this.$location.path("/dashboard/miscellaneous-transactions");
            }});
        }
      });
    });
  }

  buildMessage(out) {
    const message = [];

    if (out.errorMessage) {
      message.push(`Error: ${out.errorMessage}`);
    }

    if (out.prn) {
      message.push(`New PRN: ${out.prn}`);
    }

    if (out.prnAmount) {
      message.push(`PRN Amount: ${this.$filter('nxCurrency')(out.prnAmount)}`);
    }

    if (out.ecAmount) {
      message.push(`EC Amount: ${this.$filter('nxCurrency')(out.ecAmount)}`);
    }

    if (out.ssAmount) {
      message.push(`SS Amount: ${this.$filter('nxCurrency')(out.ssAmount)}`);
    }

    if (out.providentAmount) {
      message.push(`Provident Amount: ${this.$filter('nxCurrency')(out.providentAmount)}`);
    }

    if (out.flexiFundAmount) {
      message.push(`Flexi-fund Amount: ${this.$filter('nxCurrency')(out.flexiFundAmount)}`);
    }

    return message.join('<br/>');
  }
}

nxModule.component('sssChangeIndividualPrn', {
  templateUrl,
  controller: SssChangeIndividualPrn
});
