import nxModule from 'nxModule';

import templateUrl from './transfer-placement.template.html';
import {ILocationService} from "angular";
import {Confirmation} from "shared/common/confirmation.types";
import {NxIFilterService} from "components/technical/angular-filters";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {CommandService} from "shared/utils/command/command.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {Product} from "components/service/product.types";

type CommandInput = {
  sourceProductId: number,
  productId: number,
  customerId: number,
  entryType: string,
  amount: number,
  remarks: string
}

class TransferPlacement {
  productId!: number;
  customerId!: number;
  private commandInput: Partial<CommandInput> = {};
  private casaProducts: Product[] = [];
  private warningMessage: string = '';

  private casaProductsSelectizeConfig = {
    placeholder: 'Select deposit account',
    searchField: ['name', 'label'],
    valueField: 'id',
    labelField: 'label',
    maxItems: 1
  };

  constructor(private $location: ILocationService,
              private customerCache: CustomerCache,
              private command: CommandService,
              private confirmation: Confirmation,
              private $filter: NxIFilterService,
              private productDefinitionService: ProductDefinitionService) {
  }

  async $onInit() {
    await this.initCasaProducts();
  }

  async initCasaProducts() {
    const casaProducts = await this.customerCache.depositAccounts(this.customerId).toPromise();
    const productDefinitions = await this.productDefinitionService.toPromise();
    this.casaProducts = casaProducts
      .filter(casa => casa.status === 'ACTIVE')
      .map(product => {
        const productDefinition = productDefinitions.find(pd => Number(pd.id) === Number(product.definitionId));
        return {label: `${product.productNumber} ${productDefinition ? productDefinition.productName : ''}`, ...product};
      });

    if (this.casaProducts.length < 1) {
      this.warningMessage = "The owner of given term deposit do not have any active deposit accounts to transfer funds from.";
    }
  }

  chosenAccount(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.casaProducts.find(account => Number(account.id) === Number(this.commandInput.sourceProductId)).productNumber;
  }

  async doTransfer() {
    this.commandInput.productId = this.productId;
    this.commandInput.customerId = this.customerId;
    this.commandInput.entryType = 'CREDIT';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (await this.confirmation(`Are you sure you want to transfer ${this.$filter('nxCurrency')(this.commandInput.amount)} from ${this.chosenAccount()}?`)) {
      await this.command.execute('DepositTransferPlacement', this.commandInput).toPromise();

      this.customerCache.depositAccounts(this.customerId).refetch();
      this.customerCache.termDeposits(this.customerId).refetch();
      this.redirectBack();
    }
  }

  redirectBack() {
    this.$location.path(`/customer/${this.customerId}/term-deposits/${this.productId}`);
  }
}

nxModule.component('transferPlacement', {
  templateUrl,
  bindings: {
    productId: '<',
    customerId: '<'
  },
  controller: TransferPlacement
});