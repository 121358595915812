import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

const templateUrl = require('./misc-cash-disbursement.template.html');
nxModule.component('miscCashDisbursement', {
  templateUrl: templateUrl,
  controller: function (actionCommand, glMappingsService) {
    const that = this;
    that.selectConfig = {
      placeholder: 'Select debit account',
      searchField: ['name', 'fullCode'],
      valueField: 'fullCode',
      labelField: 'name',
      maxItems: 1
    };

    that.transaction = {units: []};

    const sub = glMappingsService.accounts.toObservable().subscribe(accounts => {
        that.accounts = _.filter(accounts, a => _.includes(['ASSET', 'EXPENSE'], a.accountGroup));
      }
    );

    const calculateDebitSum = () => {
      const units = that.transaction.units;
      if (!units || units.length === 0) return 0;
      return that.transaction.units.reduce((sum, item) => sum.plus(item['amount'] || 0), new BigNumber(0)).toNumber();
    };

    that.onUnitChange = () => {
      that.transaction.amount = calculateDebitSum();
    };

    that.addNewAccount = () => {
      if (!that.transaction.units) that.transaction.units = [];
      that.transaction.units.push({entryType: 'DEBIT', amount: 0});
      that.onUnitChange();
    };

    that.removeAccount = (accountCode) => {
      that.transaction.units.splice(_.findIndex(that.transaction.units, accountCode), 1);
      that.onUnitChange();
    };

    that.cancelChanges = () => {
      actionCommand.cancelChanges();
    };

    that.save = () => {
      actionCommand.execute('MISC_CASH_DISBURSEMENT', this.transaction);
    };

    that.$onInit = () => {
      that.addNewAccount();
    };

    that.$onDestroy = () => {
      sub.unsubscribe();
    }
  }
});
