import nxModule from 'nxModule';

import './security-machine-specific.style.less';
import templateUrl from './security-machine-specific.template.html';

class SecurityMachineSpecific {
  constructor(cmcService, notification, command, fingerprintService) {
    this.cmcService = cmcService;
    this.notification = notification;
    this.command = command;
    this.fingerprintService = fingerprintService;

    this.cmc = this.cmcService.getCmc();
  }

  async save() {
    const fingerprint = await this.fingerprintService.getFingerprint();
    const response = await this.command.execute('SetCmc', {
      cmc: this.cmc,
      fingerprint: fingerprint
    }).toPromise();

    if(!response.approvalRequired && response.output) {
      this.cmcService.setCmcToken(response.output);
    }
  }
}

nxModule.component('securityMachineSpecific', {
  templateUrl,
  controller: SecurityMachineSpecific
});