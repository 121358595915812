import nxModule from 'nxModule';
import _ from "lodash";
import BigNumber from "bignumber.js";
import {createLabels, filterOutContingencyAccounts} from "../../general-ledger/common/gl.utils";
import './ledger-units.style.less';

import templateUrl from './ledger-units.template.html';
import systemPropertyService from '../../../../react/system/systemPropertyService';

class LedgerUnits {
  constructor($scope, glMappingsService) {
    this.$scope = $scope;
    this.glMappingsService = glMappingsService;
    this.supportsLedger = false;
    this.ledgerAccounts = [];
    this.userDefinedUnits = [];
  }

  onAmountChange() {
    this.amount = 0;
    if (!_.isEmpty(this.userDefinedUnits)) {
      this.amount = this.userDefinedUnits.reduce((sum, unit) => sum.plus(unit['amount'] || 0), new BigNumber(0)).toNumber();
    }
  }

  addUnit() {
    const unitEntryType = this.entryType === 'CREDIT' ? 'DEBIT' : 'CREDIT';
    this.userDefinedUnits.push({entryType: unitEntryType, amount: 0});
  }

  removeUnit(unit) {
    this.userDefinedUnits.splice(_.findIndex(this.userDefinedUnits, unit), 1);
    // In case of removal of last unit -> add empty one since at least 1 custom unit must be present
    if (!this.userDefinedUnits || this.userDefinedUnits.length === 0) {
      this.addUnit();
    }

    this.onAmountChange();
  }

  syncUnits() {
    const predefinedUnit = {
      amount: this.amount,
      entryType: this.entryType
    };
    // Refresh referenced units collection
    if (this.blocked) {
      this.userDefinedUnits = this.blockedUnits;
    }
    this.units = _.union([predefinedUnit], this.userDefinedUnits);
    this.onAmountChange();
  }

  $onInit() {
    this.supportsLedger = systemPropertyService.getProperty('LEDGER_SUPPORT') === 'TRUE';

    this.ledgerAccountSub = this.glMappingsService.accounts.toObservable();
    if (this.hideContingent === true) {
      this.ledgerAccountSub = this.ledgerAccountSub.pipe(
        filterOutContingencyAccounts
      );
    }

    this.ledgerAccountSub = this.ledgerAccountSub.pipe(
      createLabels
    ).subscribe(ledgerAccounts => {
      this.ledgerAccounts = ledgerAccounts;
    });

    this.$scope.$watch('$ctrl.userDefinedUnits', () => this.syncUnits(), true);

    this.$scope.$watch('$ctrl.blockedUnits', () => {
      this.blocked = !_.isEmpty(this.blockedUnits) || this.alwaysBlocked;
      this.syncUnits();
    });

    this.addUnit();
    this.syncUnits();
  }

  $onDestroy() {
    this.ledgerAccountSub.unsubscribe();
  }
}

nxModule.component('ledgerUnits', {
  templateUrl,
  bindings: {
    // Reference to the units array held in outer scope
    'units': '=',
    // Reference to total amount of transaction (predefined unit value)
    'amount': '=',
    // Defines weather predefined unit is CREDIT or DEBIT
    'entryType': '<',
    // Fixed account label
    'fixedAccountLabel': '<',
    // Fixed units - if set user can not change or add other category
    'blockedUnits': '=',
    // Tells whether Contingent account should be hidden in dropdown
    'hideContingent': '<',
    // Always block units regardless of blockedUnit value
    'alwaysBlocked': '<'
  },
  controller: LedgerUnits
});
