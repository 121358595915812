import {ReactElement} from "react";
import * as React from "react";
import {
  NxPopup,
  NxQuery,
  NxQueryResult, NxStack,
  NxTable,
  NxTableColumn
} from "@nextbank/ui-components";
import axios from "axios";
import {PageResult} from "tools/HttpTypes";
import styles from './CheckPreparationBatch.scss';


interface CheckPreparationHistory {
  cifNumber: string;
  customerName: string;
  productName: string;
  checkAmount: number;
  depositoryBank: string;
  accountName: string;
  checkNumber: string;
  active: boolean;
}

interface CheckPreparationHistoryRow extends CheckPreparationHistory {
  no: number;
  formattedAmount: string;
}

interface CheckPreparationBatchProps {
  batchId: number | undefined;
  showPopup: boolean;
  onClose: () => void;
  batchType: 'FOR_RELEASE' | 'FOR_OUTGOING';
}

const columns: NxTableColumn<CheckPreparationHistoryRow>[] = [{
  title: "No",
  field: "no",
}, {
  title: "CIF number",
  field: "cifNumber",
}, {
  title: "Customer name",
  field: "customerName",
},{
  title: "Loan type",
  field: "productName",
}, {
  title: "Check amount",
  field: "formattedAmount",
}, {
  title: "Bank",
  field: "depositoryBank",
}, {
  title: "Account name",
  field: "depositoryAccountName",
}, {
  title: "Check number",
  field: "checkNumber",
}, {
  title: "Status",
  field: "status",
}];

const CheckPreparationBatch = (props: CheckPreparationBatchProps): ReactElement => {
  return <NxPopup header={`Batch ${props.batchId}`}
                  open={props.showPopup} onClose={props.onClose}>
      <NxStack>
        <div className={styles.popup}>
          <NxTable
            columns={columns}
            data={async (query: NxQuery): Promise<NxQueryResult<CheckPreparationHistoryRow>> => {
              const {data} = await axios.get<PageResult<CheckPreparationHistory>>(`/check-preparation/history/details`, {
                params: {
                  batchId: props.batchId,
                  batchType: props.batchType,
                  pageSize: query.pageSize,
                  pageNo: query.page
                }
              });
              const result: CheckPreparationHistoryRow[] = data.result.map((check, index) => {
                return {
                  ...check,
                  no: query.pageSize * data.pageNo + index + 1,
                  formattedAmount: check.checkAmount.toLocaleString(),
                  status: check.active ? 'Active' : 'Deleted'
                };
              });

              return {
                ...data,
                result: result
              };
            }}/>
        </div>
      </NxStack>
  </NxPopup>
};

export default CheckPreparationBatch;