import nxModule from 'nxModule';

import templateUrl from './prints-page.template.html';

nxModule.component('printsPage', {
  templateUrl,
  bindings: {
    headerLabel: '@',
    headerTitle: '@'
  },
  transclude: true
});