import {ICacheFactoryService} from 'angular';
import config from 'config';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import ParameterizedLocalCache from '../../shared/utils/parameterized-local-cache';

type GeoLocationCache = ParameterizedLocalCache<unknown, GeoLocation[]>;
export default GeoLocationCache;

export type GeoLocationSorting = 'ORDER' | 'ALPHABETICAL';

export type GeoLocationRequest = {
  minDepth: number;
  maxDepth: number;
  nested: boolean;
  sorting: GeoLocationSorting;
}

export type GeoLocation = {
  id: number,
  parentId: number,
  code: string,
  psgcCode: string,
  name: string,
  level: number;
  children: GeoLocation[]
}

nxModule.factory('geoLocationCache', (http: HttpService, CacheFactory: ICacheFactoryService) =>
  new ParameterizedLocalCache({
    provider: (request: GeoLocationRequest) =>
      http.get(`/dictionaries/geo/locations?minDepth=${request.minDepth}&maxDepth=${request.maxDepth}&fetchNested=${request.nested}&sorting=${request.sorting}`),
    cacheFactory: CacheFactory,
    cacheTime: config.cache.agents,
    cacheName: 'transactionQuota',
    cacheStorage: 'memory'
  })
);
