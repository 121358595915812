import nxModule from 'nxModule';

import templateUrl from './update-loan-maturity-date.template.html';
import {Loan} from '../../../service/loan.types';
import moment from "moment";
import {Branch} from "management/BranchTypes";
import {NxRouteService} from "routes/NxRouteService";
import {NxIFilterService} from "components/technical/angular-filters";
import {IScope} from "angular";
import {CustomerCache} from "components/service/customer.cache.types";
import Popup from "shared/common/popup";
import {BranchService} from "components/service/branch.service";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";
import {LoanService} from "components/service/loan.service";

interface Request {
  loanId: number;
  maturityDate: string;
}

export const RECREATE_DISCOUNT_CHARGES_LEDGER_MESSAGE: string =
  'Loan has discount charges ledger, please make sure to recreate the discount charges ledger after updating maturity date.';

class UpdateLoanMaturityDate {

  private readonly recreateDiscountChargesLedgerMessage: string = RECREATE_DISCOUNT_CHARGES_LEDGER_MESSAGE;

  private loan: Loan | null = null;
  private maturityDate: Date | null = null;
  private branches: Branch[] = [];
  private minDate: Date | null = null;
  private term: number | null = null;
  private searchProductNumber: string = '';

  constructor(private readonly command: CommandService,
              private readonly $route: NxRouteService,
              private readonly confirmationTemplate: ConfirmationTemplate,
              private readonly $filter: NxIFilterService,
              private readonly http: HttpService,
              private readonly branchService: BranchService,
              private readonly popup: Popup,
              private readonly loanService: LoanService,
              private readonly $scope: IScope,
              private readonly customerCache: CustomerCache
  ) {
  }

  async $onInit(): Promise<void> {
    this.branches = await this.branchService.toPromise();
    this.maturityDate = null;

    this.$scope.$watch('$ctrl.maturityDate', () => {
      if (this.maturityDate && this.loan) {
        this.term = moment(this.maturityDate).diff(moment(this.loan.grantDate), 'days');
      }
    });

  }

  async searchProduct(): Promise<void> {
    this.clearData();

    const criteria = {
      productNumber: [this.searchProductNumber.trim()],
      exactProductNumber: true
    };

    const response = await this.http.post(`/products/search`, criteria, {nxLoaderText: 'Searching for product...'}).toPromise();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const product = response.result[0];
    if (!product) {
      this.popup({
        text: `No product found with product number ${this.searchProductNumber}`
      });
      return;
    }

    this.loan = await this.loanService.getLoanForCustomer(product.customerId, product.id);
    if (!this.loan) {
      this.popup({
        text: `Product with product number ${this.searchProductNumber} is not a loan.`
      });

      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const secondToLastAmortization = this.loan.amortizationSchedule.list.find(a => a.lineNumber === this.loan.totalAmortizationNumber - 1);
    const effectiveMinDate = secondToLastAmortization ? secondToLastAmortization.dueDate : this.loan.grantDate;
    this.minDate = moment(effectiveMinDate).add(1, 'days').toDate();
    this.maturityDate = this.minDate;
  }

  async updateMaturityDate(): Promise<void> {
    if(!this.maturityDate) {
      throw new Error('No maturity date');
    }

    if(!this.loan) {
      throw new Error('No loan');
    }

    if(!this.term) {
      throw new Error('Missing term');
    }

    const details = [
      {label: "Maturity Date", description: this.$filter('nxDate')(this.maturityDate)},
      {label: "Term", description: this.term}
    ];

    if (this.loan.uidLedger) {
      details.push({label: "Last UID Ledger date", description: this.$filter('nxDate')(this.maturityDate)});
    }

    const proceed = await this.confirmationTemplate({
      question: 'Maturity date of the loan will be updated, do you wish to proceed? Please review all the details below.',
      details
    });

    if (!proceed) {
      return;
    }

    const request: Request = {
      loanId: this.loan.id,
      maturityDate: this.$filter('nxDate')(this.maturityDate)
    };

    await this.command.execute('UpdateLoanMaturityDate', request).toPromise();
    // wait till command finishes
    await this.customerCache.loans(this.loan.customerId).refetch();
    this.$route.reload();
  }

  private clearData(): void {
    this.loan = null;
    this.maturityDate = null;
    this.term = null;
    this.minDate = null;
  }

}

nxModule.component('updateLoanMaturityDate', {
  templateUrl,
  controller: UpdateLoanMaturityDate
});
