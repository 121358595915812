import {
  NxButton,
  NxButtonVariant,
  NxFormik,
  NxFormikCashInput,
  NxFormikDatePicker,
  NxFormikInput,
  NxFormikSelect,
  NxFormikSubmitButton,
  NxLoader,
  NxPopup,
  NxRow,
  NxRowPosition,
  NxStack
} from '@nextbank/ui-components';
import useAxios from 'axios-hooks';
import CommandAccess from 'command/CommandAccess';
import {Dictionary} from 'dictionary/DictionaryType';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import React, {ReactElement, useMemo, useState} from 'react';
import {useHistory} from 'react-router';
import NxFormikFileUpload from 'tools/file-upload/NxFormikFileUpload';
import {HttpError} from 'tools/HttpTypes';
import * as Yup from 'yup';

export interface CollateralInput {
  id?: number;
  customerId: number;
  securityId?: number;
  name?: string;
  description?: string;
  remarks?: string;
  appraisalValue?: number;
  appraisalDate?: string;
  fileIds?: number[];
}

export interface Collateral {
  id: number;
  customerId: number;
  securityId: number;
  name: string;
  description?: string;
  remarks?: string;
  appraisalValue?: number;
  appraisalDate?: string;
  fileIds?: number[];
}

const RequiredId = Yup.number()
  .required()
  .min(1);

const CollateralFormSchema: Yup.SchemaOf<CollateralInput> = Yup.object().shape({
  id: Yup.number().positive().optional(),
  customerId: RequiredId,
  securityId: RequiredId,
  name: Yup.string().required('Collateral name must not be empty'),
  description: Yup.string()
    .typeError('Description must be a valid string')
    .optional(),
  remarks: Yup.string().optional(),
  appraisalValue: Yup.number()
    .positive('Appraisal value must be greater than 0')
    .typeError('Appraisal value must be greater than 0')
    .optional(),
  appraisalDate: Yup.string().typeError('Appraisal date format is invalid').optional(),
  fileIds: Yup.array()
    .min(1,'Input value is not a valid ID')
    .required()
});

export interface CollateralProps {
  collateral: CollateralInput,
  submitAction: (collateral: CollateralInput) => Promise<void>,
  confirmationMessage: string,
  actionName: string,
  commandAccessName: string
}

const CollateralForm = (props: CollateralProps): ReactElement => {

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
  const history = useHistory();
  const [{data: dictionaries, loading}] = useAxios<Dictionary[], HttpError>('/dictionaries?code=LOAN_SECURITY');

  const loanSecurities = useMemo(() => {
    if(!dictionaries || dictionaries.length !== 1) {
      return [];
    }
    return dictionaries[0].entries
      .filter(e => e.code !== "CLN")
      .map(e => {
      const englishEntry = e.values.find(v => v.languageCode === 'en');
      return {
        label: englishEntry?.value,
        value: e.id
      }
    });
  }, [dictionaries]);

  if(loading || !loanSecurities?.length) {
    return <NxPage><NxHeader>Collaterals</NxHeader><NxLoader/></NxPage>
  }

  return <>
      <NxFormik<CollateralInput>
        initialValues={{
          id: props.collateral.id,
          customerId: props.collateral.customerId,
          name: props.collateral.name,
          description: props.collateral.description ?? undefined,
          remarks: props.collateral.remarks ?? undefined,
          securityId: props.collateral.securityId,
          appraisalDate: props.collateral.appraisalDate ?? undefined,
          appraisalValue: props.collateral.appraisalValue ?? undefined,
          fileIds: props.collateral.fileIds
        }}
        validationSchema={CollateralFormSchema}
        onSubmit={(values : CollateralInput) : Promise<void> => props.submitAction(values)}
      >
        {({
            isValid,
            isSubmitting,
            submitForm,
          }): ReactElement => {

          return <NxForm>
            <NxStack>
              <NxFormikSelect<number>
                name='securityId'
                label='Type'
                options={loanSecurities}/>
              <NxFormikInput label='Name' name='name'/>
              <NxFormikInput label='Description' name='description'/>
              <NxFormikInput label='Remarks' name='remarks'/>
              <NxFormikDatePicker label='Appraisal date' name='appraisalDate'/>
              <NxFormikCashInput label='Appraisal value' name='appraisalValue'/>
              <NxFormikFileUpload label={'Collateral'}
                                  name={'fileIds'}
                                  allowDuplicates={false}/>

              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={(): void => setShowCancelPopup(true)}
                >
                  Cancel
                </NxButton>
                <CommandAccess commandName={props.commandAccessName}>
                  <NxButton onClick={(): void => setShowPopup(true)}
                            disabled={!isValid || isSubmitting}>
                    {props.actionName}
                  </NxButton>
                </CommandAccess>
              </NxRow>
            </NxStack>

            <NxPopup header='Confirm'
                     open={showPopup && isValid}
                     description={props.confirmationMessage}>
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={() : void => setShowPopup(false)}>
                  No
                </NxButton>
                <NxFormikSubmitButton
                  variant={NxButtonVariant.SAVE}
                  onClick={async (): Promise<void> => {
                    setShowPopup(false);
                    submitForm();
                  }}>
                  Yes
                </NxFormikSubmitButton>
              </NxRow>
            </NxPopup>

            <NxPopup header='Confirm'
                     open={showCancelPopup}
                     description='Do you want to cancel? Cancelling will discard all changes'>
              <NxRow position={NxRowPosition.END}>
                <NxButton variant={NxButtonVariant.CLOSE}
                          onClick={() : void => setShowCancelPopup(false)}>
                  No
                </NxButton>
                <NxButton
                  variant={NxButtonVariant.CONTAINED}
                  onClick={(): void => {
                    setShowCancelPopup(false);
                    history.goBack();
                  }}>
                  Yes
                </NxButton>
              </NxRow>
            </NxPopup>
          </NxForm>
        }
      }
    </NxFormik>
  </>;
}

export default CollateralForm;
