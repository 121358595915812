import nxModule from 'nxModule';
import templateUrl from './credit-on-us-check.template.html';
import {Account, ProductDefinition} from 'components/service/product.types';
import {ILocationService} from "angular";
import {NxIFilterService} from "components/technical/angular-filters";
import {ProductDefinitionService} from "components/service/product-definition.service";
import {Confirmation} from "shared/common/confirmation.types";
import {CommandService} from 'shared/utils/command/command.types';
import {CustomerCache} from 'components/service/customer.cache.types';

interface Check {
  amount: number;
  micrNumber: string;
  remarks: string;
  validFrom: string;
}

class CustomerAccountsCreditOnUsCheck {
  private customerId!: number;
  private accountId!: number;
  private check!: Check;
  private account: Account | undefined;

  constructor(
    private $location: ILocationService,
    private $filter: NxIFilterService,
    private customerCache: CustomerCache,
    private productDefinitionService: ProductDefinitionService,
    private confirmation: Confirmation,
    private command: CommandService
  ) {
  }

  async $onInit(): Promise<void> {
    const [accounts, productDefinitions] = await Promise.all([
      this.customerCache.depositAccounts(this.customerId).toPromise(),
      this.productDefinitionService.toPromise()
    ]);

    accounts.forEach((acc : Account) => {
      acc.productDefinition = <ProductDefinition>productDefinitions.find((d: ProductDefinition) => d.id === acc.definitionId);
    });

    this.account = accounts.find((a: Account) => a.id === this.accountId);
  }

  async credit() : Promise<void> {
    const proceed = await this.confirmation(`Do you want to credit on-us check with ${this.$filter('nxCurrency')(this.check.amount)}?`);

    if (proceed) {
      const dateFilter : (d: string) => string = this.$filter('nxDate');
      const response = await this.command.execute('CreditOnUsCheck', {
        productId: this.accountId,
        amount: this.check.amount,
        validFrom: dateFilter(this.check.validFrom),
        micrNumber: this.check.micrNumber,
        remarks: this.check.remarks
      }, {nxLoaderText: 'Crediting check'}).toPromise();

      if (response && !response.approvalRequired) {
        await this.customerCache.depositAccounts(this.customerId).refetch();
        this.redirectBack();
      }
    }
  };

  redirectBack(): void {
    this.$location.path(`/customer/${this.customerId}/accounts/${this.accountId}`);
  }
}

nxModule.component('customerAccountsCreditOnUsCheck', {
  templateUrl,
  controller: CustomerAccountsCreditOnUsCheck,
  bindings: {
    customerId: '<',
    accountId: '<'
  }
});
