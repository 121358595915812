import nxModule from 'nxModule';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/distinctUntilChanged';
import _ from 'lodash';
import AccessType from 'constants/customerAccessType';

/**
 * Oracle returning currently active customer.
 * Exposes a stream of active customer changes.
 */
export class ActiveCustomerTypeService {
  customerIdSubject = new ReplaySubject(1);

  updateCustomerId(customerId: number | null) {
    this.customerIdSubject.next({customerId: customerId ? String(customerId) : null});
  }

  customerIdChanged() {
    return this.customerIdSubject.distinctUntilChanged(_.isEqual);
  }
}

export interface ActiveCustomerService {
  ofType: (accessType: string) => ActiveCustomerTypeService;
}

nxModule.factory('activeCustomerService', () => {
  const activeCustomerTypeService: Record<string, ActiveCustomerTypeService> = {};
  for(const type of Object.values(AccessType)) {
    activeCustomerTypeService[type] = new ActiveCustomerTypeService()
  }

  return {
    ofType: (accessType: string) => {
      const service = activeCustomerTypeService[accessType];
      if(service) {
        return service;
      }

      console.error("Access type not supported:", accessType);
    }
  };
});